import React, { Component } from 'react'

import { Player, BigPlayButton, ControlBar, Shortcut } from 'video-react'
import { CommonBannerStyle, BannerHeight } from '../styleComponent/styleComponent'
import VideoPlayer from '../../containers/sm/components/post/video-player'

export default class CommonBanner extends Component {
  state = {
    videoHeight: 825,
    bannerHeight: 95,
  }

  getVideoHeight = () => {
    const selector = document.querySelector('.header-responsive')
    const headerHeight = selector?.offsetHeight
    headerHeight &&
      this.setState({
        videoHeight: window.innerHeight - headerHeight,
        bannerHeight: headerHeight,
      })
  }

  componentDidMount() {
    this.getVideoHeight()
    window.addEventListener('resize', this.getVideoHeight)
  }

  componentWillUnmount() {
    this.getVideoHeight()
    window.removeEventListener('resize', this.getVideoHeight)
  }
  render() {
    const { data } = this.props
    const { videoHeight, bannerHeight } = this.state

    return (
      <>
        {data && data.length > 0 && (
          <>
            {data[0].metatype === 'image' ? (
              <BannerHeight className="banner-height--style banner-height-image" height={bannerHeight}>
                <div className="banner-size banner--size-height">
                  <CommonBannerStyle
                    img={
                      data[0].url?.length > 0
                        ? encodeURI(data[0].url[0].largeUrl)
                        : encodeURI(data[0].thumbnail?.length > 0 && data[0].thumbnail[0].largeUrl)
                    }
                  />
                </div>
              </BannerHeight>
            ) : (
              <div className="home-video-banner banner-height-video">
                <div className="banner-size" style={{ height: `${videoHeight - 32}px` }}>
                  {/* <Player
                    controls={false}
                    muted
                    autoPlay={true}
                    playsInline
                    aspectRatio={`1852:${videoHeight}`} // [NOTE]: This line needed
                    loop
                    poster={data[0].thumbnail?.length > 0 && data[0].thumbnail[0].url}
                    src={data[0].url?.length > 0 && data[0].url[0].url}
                  >
                    <Shortcut shortcuts={this.newShortcuts} clickable={false} />
                    <BigPlayButton className="visible-none" />
                    <ControlBar disableCompletely className="my-class" />
                  </Player> */}

                  <VideoPlayer
                    src={data[0].url?.length > 0 && data[0].url[0].url}
                    muted
                    autoPlay
                    loop
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </>
    )
  }
}
