import React, { useState, useEffect, useRef } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'
import { envConfig } from '../../config'

const libraries = ['places']

const PlacesAutocomplete = ({ query, setQuery, setSuggestions, ...props }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: envConfig.MAP_API_KEY,
    libraries,
  })

  const [location, setLocation] = useState({ lat: 49.2827, lng: -123.1207 })

  const autocompleteServiceRef = useRef(null)
  const placesServiceRef = useRef(null)

  useEffect(() => {
    if (isLoaded && navigator.geolocation) {
      const onSuccess = position => {
        setLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
        fetchNearbyCities(position.coords.latitude, position.coords.longitude)
      }

      const onError = () => {
        fetchNearbyCities(location.lat, location.lng)
      }

      navigator.geolocation.getCurrentPosition(onSuccess, onError)
    }
  }, [isLoaded])

  const fetchNearbyCities = (lat, lng) => {
    if (!placesServiceRef.current && window.google) {
      placesServiceRef.current = new window.google.maps.places.PlacesService(document.createElement('div'))
    }

    const request = {
      location: new window.google.maps.LatLng(lat, lng),
      radius: 500000,
      type: ['locality'],
    }

    placesServiceRef.current.nearbySearch(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        Promise.all(
          results.map(
            place =>
              new Promise(resolve => {
                placesServiceRef.current.getDetails({ placeId: place.place_id }, (details, status) => {
                  if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    const addressComponents = details.address_components
                    const city = addressComponents.find(comp => comp.types.includes('locality'))?.long_name || ''
                    const country = addressComponents.find(comp => comp.types.includes('country'))?.long_name || ''

                    resolve({
                      description: details.formatted_address,
                      completeLabel: details.formatted_address,
                      city,
                      country,
                      place_id: place.place_id,
                    })
                  } else {
                    resolve(null)
                  }
                })
              })
          )
        ).then(places => setSuggestions(places.filter(Boolean)))
      } else {
        setSuggestions([])
      }
    })
  }

  const debounce = (func, delay) => {
    let timeoutId
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        func.apply(null, args)
      }, delay)
    }
  }

  const fetchAutocompleteSuggestions = input => {
    if (!autocompleteServiceRef.current && window.google) {
      autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService()
    }

    autocompleteServiceRef.current.getPlacePredictions({ input }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        Promise.all(
          predictions.map(
            prediction =>
              new Promise(resolve => {
                placesServiceRef.current.getDetails({ placeId: prediction.place_id }, (details, status) => {
                  if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    const addressComponents = details.address_components
                    const city = addressComponents.find(comp => comp.types.includes('locality'))?.long_name || ''
                    const country = addressComponents.find(comp => comp.types.includes('country'))?.long_name || ''

                    resolve({
                      description: prediction.description,
                      completeLabel: prediction.description,
                      city,
                      country,
                      place_id: prediction.place_id,
                    })
                  } else {
                    resolve(null)
                  }
                })
              })
          )
        ).then(places => setSuggestions(places.filter(Boolean)))
      } else {
        setSuggestions([])
      }
    })
  }

  const handleInputChange = e => {
    const inputValue = e.target.value
    setQuery(inputValue)

    if (inputValue.length > 0) {
      debounce(fetchAutocompleteSuggestions, 300)(inputValue)
    } else {
      fetchNearbyCities(location.lat, location.lng)
    }
  }

  const handleFocus = () => {
    if (query.length === 0) {
      fetchNearbyCities(location.lat, location.lng)
    }
  }

  if (!isLoaded) {
    return <div>Loading...</div>
  }

  return <input {...props} value={query} onChange={handleInputChange} onFocus={handleFocus} />
}

export default PlacesAutocomplete
