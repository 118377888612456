import React, { memo, useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { Grid } from '@material-ui/core'

import { CommonModal } from './commonModal'
import { timeEnum } from '../../util/enums/enums'
import { timeLeftForAuction, bidHistoryTime, boatShowCardDateTimeFormat } from '../../helpers/dateTimeHelper'
import { nameFormatter, displayDefaultValue, displayDefaultNumericValue } from '../../helpers/string'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { noBidHistoryMessage } from '../../util/enums/enums'
import { secretName } from '../../helpers/jsxHelper'
import { CommonTooltip } from '../CommonTooltip'

export const AuctionBidBox = memo(props => {
  const { open, close, boatAuctionRoom } = props

  const {
    endTime,
    bidDuration,
    auctionBids,
    auctionStartingBid,
    auctionCurrentBid,
    boat: {
      images: [boatImage],
      adId,
      yearBuilt,
      boatType,
      price,
    },
  } = boatAuctionRoom

  const [timeLeft, setTimeLeft] = useState(timeLeftForAuction(endTime))

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(timeLeftForAuction(endTime))
    }, 1000)
    return () => clearTimeout(timer)
  })

  return (
    <CommonModal open={open} close={close} title="Bid History" className="inner--bid--list--modal">
      <div className="inner--bid--common--modal--container">
        <Grid container>
          <Grid sm={7}>
            <div className="d-flex inner--bid--common--modal--section">
              <span className="d-flex justify-content-center align-items-center inner--auction--room--time--left--title inner--bid--common--modal--time">
                {'TIME LEFT'}
              </span>
              <div className="d-flex flex-column justify-content-center align-items-center inner--bid--time--section inner--bid--common--modal--time">
                <span className="inner--bid--time--info">{timeLeft[timeEnum.DAYS.toLowerCase()]}</span>
                <span className="inner--bid--time--info--title">{timeEnum.DAYS.toUpperCase()}</span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center inner--bid--time--section inner--bid--common--modal--time">
                <span className="inner--bid--time--info">{timeLeft[timeEnum.HOURS.toLowerCase()]}</span>
                <span className="inner--bid--time--info--title">{timeEnum.HOURS.toUpperCase()}</span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center inner--bid--time--section inner--bid--common--modal--time">
                <span className="inner--bid--time--info">{timeLeft[timeEnum.MINUTES.toLowerCase()]}</span>
                <span className="inner--bid--time--info--title">{timeEnum.MINUTES.toUpperCase()}</span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center inner--bid--time--section inner--bid--common--modal--time inner--bid--time--last--section">
                <span className="inner--bid--time--info">{timeLeft[timeEnum.SECONDS.toLowerCase()]}</span>
                <span className="inner--bid--time--info--title">{timeEnum.SECONDS.toUpperCase()}</span>
              </div>
            </div>

            <div className="inner--bid--duration">
              <span>
                Bids : <span className="font-weight-600">{displayDefaultNumericValue(auctionBids?.length)}</span>
              </span>
              <span>
                Duration : <span className="font-weight-600">{bidDuration}</span>
              </span>
            </div>

            <div>
              <Table bordered className="inner--bid--modal--table">
                <thead>
                  <tr>
                    <th className="inner--bid--modal--table--bidder">
                      {'Bidder'}
                      <CommonTooltip component={ctProps => (
                        <img
                          data-tooltip-id={ctProps.id} 
                          data-tooltip-content="For privacy protection, we conceal some of the characters in the name."
                          src={require('../../assets/images/boatInner/bidder.svg')}
                          className="inner--bid--modal--bidder--img"
                          alt="Bidder"
                        />
                      )} />
                    </th>
                    <th className="inner--bid--modal--table--amount">Bid Amount</th>
                    <th className="inner--bid--modal--table--time">Bid TIme</th>
                  </tr>
                </thead>

                <tbody>
                  {auctionBids?.length > 0 ? (
                    auctionBids.map(bid => (
                      <tr key={bid.id}>
                        <td className="inner--bid--modal--table--bidder">
                          {displayDefaultValue(nameFormatter([bid.user.firstName, bid.user.lastName]))}
                          <span className="bid--country-name">
                            ({bid.user?.address?.length > 0 && bid.user.address[0].country})
                          </span>
                        </td>

                        <CurrencyContextConsumer>
                          {({ currentCurrency }) => (
                            <td className="inner--bid--modal--table--amount">
                              {getConvertedPrice(bid.price, currentCurrency)}
                            </td>
                          )}
                        </CurrencyContextConsumer>

                        <td className="inner--bid--modal--table--time">
                          <div className="d-flex flex-column">
                            <span>{bidHistoryTime(bid.createdAt)}</span>
                            <span>{boatShowCardDateTimeFormat(bid.createdAt)}</span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div className="no--bids--yet">
                      <span>{noBidHistoryMessage}</span>
                    </div>
                  )}
                </tbody>
              </Table>
            </div>
          </Grid>

          <Grid sm={5} className="inner--bid--modal--right--div">
            <div>
              <div className="inner--bid--modal--right--div--img">
                <img loading="lazy" src={boatImage?.url} alt="boat-icon" />
              </div>
              <div>
                <div className="d-flex flex-column inner--bid--modal--adid--div">
                  <span className="inner--bid--modal--adid">Ad ID: {adId}</span>
                  <span className="inner--bid--modal--boat--name">
                    {yearBuilt} {boatType.name}
                  </span>
                </div>

                <CurrencyContextConsumer>
                  {({ currentCurrency }) => (
                    <div>
                      <div className="d-flex flex-column inner--bid--modal--original--price">
                        <span className="inner--bid--modal--price--title">{'ORIGINAL PRICE'}</span>
                        <span className="inner--bid--modal--price--info">{getConvertedPrice(price, currentCurrency)}</span>
                      </div>

                      <div className="d-flex flex-column inner--bid--modal--starting--bid">
                        <span className="inner--bid--modal--price--title">{'STARTING BID'}</span>
                        <span className="inner--bid--modal--price--info">
                          {getConvertedPrice(auctionStartingBid, currentCurrency)}
                        </span>
                      </div>

                      <div className="d-flex flex-column">
                        <span className="inner--bid--modal--price--title">{'CURRENT BID'}</span>
                        <span className="inner--bid--modal--price--info">
                          {getConvertedPrice(auctionCurrentBid, currentCurrency)}
                        </span>
                      </div>
                    </div>
                  )}
                </CurrencyContextConsumer>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="inner--bid--common--modal--second--container">
        <span>{'If two people bid the same amount, the first bid has priority'}</span>
      </div>
    </CommonModal>
  )
})
