import { Layout } from '../../components'
import ImageBanner from '../../components/mainBanner/ImageBanner'
import { Col, Image, Row } from 'react-bootstrap'
import classNames from 'classnames'
import data from './data'
import _styles from '../../styles/info-screens.module.scss'
import styles from './styles.module.scss'

const AboutUs = ({}) => {
  return (
    <Layout noFooterMargin>
      <ImageBanner
        h1
        title="About Us"
        subTitle="Are you looking to join the world's premier online marketplace for buying and selling boats?"
      />

      <div className={_styles.secPadding}>
        <div className="container100">
          <Row>
            <Col md={6}>
              <h2 className={_styles.fz_h}>About Us</h2>
              <p className={classNames(_styles.fz_p, 'mt-30')}>
                AdamSea stands as a top global online marketplace for the marine industry. It brings together buyers and sellers
                of both new and used boats, yachts, and watercrafts. Our skilled team is made up of experienced professionals
                who focus on fostering worldwide connections and business opportunities.
              </p>
            </Col>

            <Col md={6} className="text-right">
              <Image
                src={require('../../assets/images/info-screens/about-us--intro-side.png')}
                alt="About"
                fluid
                style={{ maxHeight: 300 }}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className={_styles.secPadding}>
        <div className="container100">
          <Row>
            {data.map(col => (
              <Col md={6} lg={4}>
                <div style={{ padding: '20px 0' }}>
                  <div>
                    <Image src={col.icon} alt={col.title} fluid />
                  </div>
                  <h3 className={classNames(_styles.fz_h, 'mt-20')}>{col.title}</h3>
                  <p className={_styles.fz_p}>{col.text}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs
