import React, { useEffect, useRef, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { useLoadScript } from '@react-google-maps/api'
import { getPlaceInformation } from '../../helpers/geoLocation'
import './map.scss'
import { envConfig } from '../../config'
import { labelRequired } from '../styleComponent/styleComponent'
import OutsideClickHandler from 'react-outside-click-handler'

const SearchComplete = ({
  id,
  name,
  getPlaceName,
  types,
  className,
  placeHolder,
  restrictCountry,
  isCountrySelector,
  requiredFieldLabel,
  style
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: envConfig.MAP_API_KEY,
    libraries: ['places'],
  })

  const placeSelectHandler = place => {
    const formatedResult = getPlaceInformation(place)

    isCountrySelector
      ? place.formatted_address && getPlaceName(place.formatted_address)
      : getPlaceName && getPlaceName(formatedResult)
  }

  const [isSearchHasValue, setIsSearchHasValue] = useState(false)
  const [isLabelShow, setIsLabelShow] = useState(true)

  const inputRef = useRef(null)

  const toggleEditing = () => {
    setIsLabelShow(false)
    const el = document?.querySelector('.auto-complete-with-placeholder')
    return el?.querySelector('.form-control')?.focus()
  }

  function handleChange(e) {
    setIsSearchHasValue(e.target.value.length > 0)
  }

  if (!isLoaded) return null

  return (
    <div className="auto-complete-with-placeholder text-input-mobile-view auto-complete-boat-service" onClick={toggleEditing}>
      <Autocomplete
        style={style}
        onPlaceSelected={placeSelectHandler}
        id={id}
        name={name}
        types={[types]}
        placeholder={requiredFieldLabel ? '' : placeHolder}
        className={className}
        componentRestrictions={restrictCountry}
        onChange={requiredFieldLabel && handleChange}
        textInputProps={{
          onFocus: () => inputRef?.current?.focus(),
        }}
        inputAutocompleteValue="off"
      />

      {requiredFieldLabel && (
        <OutsideClickHandler
          onOutsideClick={() => {
            !isSearchHasValue && setIsLabelShow(true)
          }}
        >
          {isLabelShow && (
            <label
              for="some-id"
              class={`${
                isSearchHasValue ? 'placeholder--none' : 'placeholder-block'
              }  textfield__label textfield__label--required required--placeholder--label`}
            >
              {requiredFieldLabel}
            </label>
          )}
        </OutsideClickHandler>
      )}
    </div>
  )
}

SearchComplete.defaultProps = {
  id: '',
  name: '',
  types: '(regions)',
  className: '',
  placeHolder: 'Search country or city',
  restrictCountry: {},
  isCountrySelector: false,
  requiredFieldLabel: null,
  style:{}
}

export default SearchComplete
