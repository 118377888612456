import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../../styles/manageDashboardTableResponsive.scss'
import { Loader, manufactureData } from '../../components'
import './dealers.scss'
import { TableCard, DashboardLayout } from '../../components'
import { getAllDealers, acceptOrRejectDealerRequest } from '../../redux/actions/dealersAction'
import { pagination } from '../../util/enums/enums'
import { nameFormatter } from '../../helpers/string'
import { getPagesValue, redirectToUserProfile } from '../../helpers/jsxHelper'
import { clearManufacturerFlag, clearSearchDashboard } from '../../redux/actions'

class ManageDealers extends Component {
  state = {
    dealersAdded: false,
  }

  componentDidUpdate() {
    const { getAllDealers, currentUser } = this.props
    const { dealersAdded } = this.state
    if (currentUser?.id && !dealersAdded) {
      getAllDealers({
        page: getPagesValue(),
        limit: pagination.PAGE_RECORD_LIMIT,
      })
      this.setState({ dealersAdded: true })
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const { acceptRejectRequestSuccess, getAllDealers, clearManufacturerFlag } = nextProps

    if (acceptRejectRequestSuccess) {
      clearManufacturerFlag()
      getAllDealers({ page: pagination.PAGE_COUNT, limit: pagination.MARKET_PAGE_LIMIT })
    }
  }

  render() {
    const {
      isLoading,
      history,
      currentUser,
      manufacturerDealers,
      acceptOrRejectDealerRequest,
      getAllDealers,
      totalDealers,
      indexSearchData,
      isSearched,
      totalSearchedData,
      clearSearchDashboard,
    } = this.props

    const columns = [
      {
        Header: 'Name',
        accessor: 'dealer',
        Cell: ({ value }) => (
          <span
            className="react--table--row--dealer--name cursor-pointer"
            onClick={() => redirectToUserProfile(value, history)}
          >
            {nameFormatter([value?.firstName, value?.lastName])}
          </span>
        ),
      },
      {
        Header: 'Country',
        accessor: 'dealer',
        Cell: ({ value }) => (
          <span className="react--table--name--row">{value?.address?.length > 0 && value.address[0].country}</span>
        ),
      },
    ]

    return (
      <DashboardLayout>
        {columns && (
          <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table">
            <TableCard
              currentUser={currentUser}
              isDashboardCard
              manufactureData={isSearched ? indexSearchData : manufacturerDealers}
              isSearch
              isDealerData
              title="Manage Dealers"
              noButton
              columns={columns}
              loading={isLoading}
              acceptOrRejectDealerRequest={acceptOrRejectDealerRequest}
              className="manage--dealers--table manage--table--section--alignment"
              manufactureDealerAction={getAllDealers}
              manufactureDealerTotal={isSearched ? totalSearchedData : totalDealers}
              clearSearchDashboard={clearSearchDashboard}
            />
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  manufacturerDealers: state.dealersReducer?.manufacturerDealers,
  isLoading: state.dealersReducer?.isLoading,
  totalDealers: state.dealersReducer?.totalDealers,
  acceptRejectRequestSuccess: state.loginReducer?.acceptRejectRequestSuccess,
  currentUser: state.loginReducer?.currentUser,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  isSearched: state?.dashboardReducer?.isSearched,
})

const mapDispatchToProps = dispatch => ({
  getAllDealers: data => dispatch(getAllDealers(data)),
  acceptOrRejectDealerRequest: data => dispatch(acceptOrRejectDealerRequest(data)),
  clearManufacturerFlag: () => dispatch(clearManufacturerFlag()),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageDealers)
