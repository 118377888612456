import React, { Component, Fragment } from 'react'
import { Grid } from '@material-ui/core'
import { dimension, defaultBoatHomeImage, defaultImage } from '../../util/enums/enums'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { ShowAllLink } from '../helper/showAllLink'
import { CityWiseComponent } from '../cityWiseComponent/cityWiseComponent'

export default class SellAroundCards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: props.showAllSellCard ? dimension.showAllSellAround.height : dimension.sellAround.height,
      width: props.showAllSellCard ? dimension.showAllSellAround.width : dimension.sellAround.width,
    }
  }
  widthZoom = () => {
    const width = getRatioZoomResponsive(
      dimension,
      this.props.showAllSellCard ? 'showAllSellAround' : 'sellAround',
      '.zoom-container'
    )
    const height = getHeightZoomResponsive(
      dimension,
      this.props.showAllSellCard ? 'showAllSellAround' : 'sellAround',
      '.zoom-container'
    )

    this.setState({ width: width.width, gridClassName: width.className, margin: width.margin, height })
  }
  componentDidMount() {
    this.widthZoom()
    window.addEventListener('resize', this.widthZoom)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.widthZoom)
  }

  getCityWiseBoatsHandler = value => {
    const { clearCityWiseBoats } = this.props
    clearCityWiseBoats()
    // value && window.open(`/city-wise-boats/${value.cityName}/${value.country}`, '_blank')
    value && window.open(`/city-wise-boats/${value.country}`, '_blank')
  }

  render() {
    const { data, limit, isShowAll, showAllText } = this.props
    const { width, height, gridClassName, margin } = this.state

    return (
      <div className="city-from-country">
        <Grid container>
          <div className={`zoom-container grid-vigit-name-place`}>
            {data &&
              data.length > 0 &&
              data.map((value, i) => (
                <Fragment key={value.id}>
                  {limit > i && (
                    <CityWiseComponent
                      value={value}
                      img={encodeURI(value.countryImage?.url || defaultBoatHomeImage)}
                      getCityWiseBoats={() => this.getCityWiseBoatsHandler(value)}
                      city={value.cityName}
                      country={value.country}
                      width={width}
                      gridClassName={gridClassName}
                      height={height}
                      margin={margin}
                      minimumPrice={value.minimumPrice}
                    />
                  )}
                </Fragment>
              ))}
          </div>
          {isShowAll && (
            <ShowAllLink
              data={data}
              itemsLength={limit}
              className="sell-arround-world"
              totalLength={data.length}
              url={`/boats-for-sell-around-the-world`}
              showAllText={showAllText}
            />
          )}
        </Grid>
      </div>
    )
  }
}
SellAroundCards.defaultProps = {
  isShowAll: true,
}
