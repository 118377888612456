import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
// import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
// import { logger } from 'redux-logger'
import rootReducer from './reducers/rootReducer'
import rootSaga from './sagas/rootSaga'

const sagaMiddleware = createSagaMiddleware()

// const persistConfig = {
//   key: 'root',
//   storage,
//   blacklist: ['errorReducer'],
// }

export default function configureStore() {
  const store = createStore(
    // persistReducer(persistConfig, rootReducer),
    rootReducer,
    applyMiddleware(sagaMiddleware)
  )

  sagaMiddleware.run(rootSaga)

  // const persistor = persistStore(store)

  return { store }
}
