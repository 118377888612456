import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { envConfig } from '../../config'

const HelmetMetaData = ({ title, description, image, quote, hashtag, keywords, canonical }) => {
  let location = useLocation()
  let [currentUrl, setCurrentUrl] = useState(envConfig.WEB_ORIGIN?.replace(/\/$/, '') + String(location.pathname).replace('index.html', ''))

  useEffect(() => {
    setCurrentUrl(envConfig.WEB_ORIGIN?.replace(/\/$/, '') + String(location.pathname).replace('index.html', ''))
  }, [location.pathname])

  const fetchLoading = useSelector(state => state.seoReducer.fetchLoading)

  return (
    <Helmet>
      {fetchLoading === false || <title>{title}</title>}
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#000000" />
      <meta name="_token" content="" />
      <link rel="canonical" href={currentUrl} />
      {fetchLoading === false || <meta name="keywords" content={keywords} />}
      {fetchLoading === false || <link rel="amphtml" href={currentUrl || canonical} />}
      {fetchLoading === false || <meta name="title" content={title} />}
      {fetchLoading === false || <meta property="quote" content={quote} />}
      {fetchLoading === false || <meta name="description" content={description} />}
      {fetchLoading === false || <meta property="image" content={image} />}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      {fetchLoading === false || <meta property="og:title" content={title} />}
      {fetchLoading === false || <meta property="og:quote" content={quote} />}
      {fetchLoading === false || <meta property="og:hashtag" content={hashtag} />}
      <meta content="image/*" property="og:image:type" />
      {fetchLoading === false || <meta property="og:image" content={image} />}
      {/* <meta property="og:image:secure" content={image} /> */}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      {fetchLoading === false || <meta property="og:url" content={currentUrl} />}
      <meta property="og:site_name" content="AdamSea" />
      {fetchLoading === false || <meta property="og:description" content={description} />}
      <meta property="fb:app_id" content={envConfig.RAZZLE_FACEBOOK_DEV_APPID} />
    </Helmet>
  )
}

HelmetMetaData.defaultProps = {
  description: `AdamSea is an Online Marine Market Place for Buyers and Sellers. outstanding selection of
    boats and yacht for sale. Online rent and charter. online marina and storage. Hair online yacht services`,
  hashtag: '#adamsea',
  image: '',
  title: 'AdamSea - World is yours to explore',
  quote: '',
}

export default HelmetMetaData
