import React, { useEffect, useState } from 'react'

import { CommonTooltip } from '../../components/CommonTooltip'
import { UserProfileBoatsStyle } from '../../components/styleComponent/styleComponent'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { viewArticles } from '../../helpers/boatHelper'
import { dimension } from '../../util/enums/enums'
import { nameFormatter } from '../../helpers/string'
import { formattedDateArticle } from '../../helpers/dateTimeHelper'
import ReadMore from '../../components/helper/truncate'

import '../../assets/css/component/articleGrid.scss'

export const ArticleGrid = React.memo(({ article }) => {
  const { id, files, title, pageVisits, likes, user, shortDescription, createdAt } = article

  const [height, setHeight] = useState(dimension.userBoatProfile.height)
  const [className, setClassName] = useState('grid--new--2')
  const [margin, setMargin] = useState(12)
  const [width, setWidth] = useState(dimension.articleGrid.width)

  const zoomWidth = () => {
    const width = getRatioZoomResponsive(dimension, 'articleGrid', '.zoom-container')
    setWidth(width.width)
    const height = getHeightZoomResponsive(dimension, 'articleGrid', '.zoom-container')

    setHeight(height)
    width && setClassName(width.className)
    width && setMargin(width.margin)
  }

  useEffect(() => {
    zoomWidth()
    window.addEventListener('resize', zoomWidth)
    return () => {
      window.removeEventListener('resize', zoomWidth)
    }
  }, [])

  return (
    <div className={`adamsea--articles-grid-main ${className}`} style={{ width: width, marginRight: margin }}>
      <div onClick={() => viewArticles(id, title)} className="adamsea--articles--name">
        {title}
      </div>
      <div className="posted--by-article">
        <span className="by--user">By : {nameFormatter([user?.firstName, user?.lastName])}</span>
        <span className="seperator">|</span>
        <span className="by--user">{formattedDateArticle(createdAt)}</span>
      </div>
      <div className="adamsea--articles-image">
        <UserProfileBoatsStyle
          className="adamsea--articles-image-bg w-100 cursor-pointer"
          onClick={() => viewArticles(id, title)}
          img={files?.length > 0 && files[0].url}
          height={height}
        />

        <div className="adamsea--article-icon-div">
            <CommonTooltip component={ctProps => (
              <div data-tooltip-id={ctProps.id} data-tooltip-content="View" className="adamsea--article-icon">
                <img loading="lazy" src={require('../../assets/images/user/reading.png')} alt="View" />
                <span className="count">{pageVisits?.length}</span>
              </div>
            )} />
            <CommonTooltip component={ctProps => (
              <div data-tooltip-id={ctProps.id} data-tooltip-content="Likes" className="adamsea--article-icon">
                <img loading="lazy" src={require('../../assets/images/user/heart.png')} alt="Likes" />
                <span className="count">{likes?.length}</span>
              </div>
            )} />
        </div>

        <div className="article--desc--style">
          <div>{shortDescription}</div>
        </div>
      </div>
    </div>
  )
})

ArticleGrid.defaultProps = {
  className: '',
}
