import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getBoatByType } from '../../redux/actions'
import UserContext from '../../UserContext'
import { shareModule, titleEnums } from '../../util/enums/enums'
import BoatListingsWithMap from '../../components/gridComponents/BoatListingsWithMap'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import { shareWithUsers, showAllTitle } from '../../helpers/jsxHelper'

class ShowAll extends Component {
  state = {
    type: '',
    fieldName: '',
    name: '',
    isFlag: true,
    isData: true,
    content: null,
  }

  static contextType = UserContext

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isFlag, isData, type } = prevState
    const { match } = nextProps
    const { params } = match && match

    if (params && params.hasOwnProperty('type') && params.type && isFlag) {
      switch (params.type) {
        case 'featured-boats':
          return { fieldName: 'featureStatus', type: params.type, isFlag: false }
        case 'must-buy-boats':
          return { fieldName: 'mustBuyStatus', type: params.type, isFlag: false }
        case 'best-deal-boats':
          return { fieldName: 'bestDealStatus', type: params.type, isFlag: false }
        default:
      }
    }

    if (isData && type) {
      switch (type) {
        case 'featured-boats':
          return { name: 'Feature Boats', isData: false, content: 'AdamSea is a premier online marine marketplace that connects buyers and sellers from around the globe. We offer the best quality boats for sale. Simply pick up your boat and buy it online.' }

        case 'must-buy-boats':
          return { name: 'Must Buy Boats', isData: false }

        case 'best-deal-boats':
          return { name: 'Best Deal Recommended Boats', isData: false, content: 'AdamSea offers the best deals on boats in the marine marketplace, providing an exceptional selection of boats at competitive prices, and the lowest prices in the market.' }

        default:
          return
      }
    }

    return null
  }

  fetchTotalRecord = () => {
    const { type } = this.state
    const { totalFeatureBoats, totalBestDealBoats, totalMustBuyBoats } = this.props

    switch (type) {
      case 'featured-boats':
        return totalFeatureBoats
      case 'must-buy-boats':
        return totalMustBuyBoats
      case 'best-deal-boats':
        return totalBestDealBoats
      default:
        return
    }
  }

  fetchData = () => {
    const { type } = this.state
    const { featureBoats, mustBuyBoats, bestDealBoats } = this.props

    switch (type) {
      case 'featured-boats':
        return featureBoats
      case 'must-buy-boats':
        return mustBuyBoats
      case 'best-deal-boats':
        return bestDealBoats
      default:
        return
    }
  }

  shareBoatWithUsers = data => {
    const { shareDetailsWithUsers } = this.props
    const object = shareWithUsers(data, shareModule.boat)
    shareDetailsWithUsers(object)
  }

  render() {
    const { getBoatByType, isPageLoader } = this.props
    const { fieldName, name, type } = this.state

    const isFeature = type === 'featured-boats'
    const isBestDeal = type === 'best-deal-boats'
    const isLittleBoat = type === 'must-buy-boats'

    const category = isFeature
      ? titleEnums.isFeature
      : isBestDeal
      ? titleEnums.isBestDeal
      : isLittleBoat && titleEnums.isLittleBoat
    const title = showAllTitle(category, this.fetchTotalRecord() || 0, name)

    return (
      <BoatListingsWithMap
        showAllTitle={title}
        isPageLoader={isPageLoader}
        boatsTypeCount={this.fetchTotalRecord() || 0}
        boatsType={name}
        content={this.state.content}
        shareWithUsers={data => this.shareBoatWithUsers(data)}
        boats={this.fetchData()}
        action={getBoatByType}
        value={{ input: { fieldName } }}
      />
    )
  }
}

const mapStateToProps = state => ({
  featureBoats: state.boatReducer.featureBoats,
  bestDealBoats: state.boatReducer.bestDealBoats,
  mustBuyBoats: state.boatReducer.mustBuyBoats,
  totalFeatureBoats: state.boatReducer.totalFeatureBoats,
  totalBestDealBoats: state.boatReducer.totalBestDealBoats,
  totalMustBuyBoats: state.boatReducer.totalMustBuyBoats,
  isPageLoader: state.boatReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  getBoatByType: data => dispatch(getBoatByType(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowAll)
