import {
  CREATE_BOAT_SHOW,
  GET_USER_BOAT_SHOWS,
  CLEAR_BOAT_SHOW_FLAG,
  GET_ALL_BOAT_SHOW,
  UPDATE_BOAT_SHOW,
  GET_SINGLE_BOAT_SHOW,
  DELETE_BOAT_SHOW,
  CLEAR_EDIT_BOAT_SHOW,
  SEARCH_BOAT_SHOW,
  TOGGLE_BOAT_SHOW_STATUS,
} from '../actionTypes'

export const createBoatShow = data => ({
  type: CREATE_BOAT_SHOW,
  payload: data,
})

export const getUserBoatShows = data => ({
  type: GET_USER_BOAT_SHOWS,
  payload: data,
})

export const clearBoatShowFlag = () => ({
  type: CLEAR_BOAT_SHOW_FLAG,
})

export const getAllBoatShow = data => ({
  type: GET_ALL_BOAT_SHOW,
  payload: data,
})

export const updateBoatShow = data => ({
  type: UPDATE_BOAT_SHOW,
  payload: data,
})

export const searchBoatShow = data => ({
  type: SEARCH_BOAT_SHOW,
  payload: data,
})

export const getBoatShowById = data => ({
  type: GET_SINGLE_BOAT_SHOW,
  payload: data,
})

export const deleteBoatShow = data => ({
  type: DELETE_BOAT_SHOW,
  payload: data,
})

export const clearEditBoatShow = () => ({
  type: CLEAR_EDIT_BOAT_SHOW,
})

export const toggleBoatShowStatus = data => ({
  type: TOGGLE_BOAT_SHOW_STATUS,
  payload: data,
})
