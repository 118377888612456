import { put, takeLatest, all } from 'redux-saga/effects'
import { graphqlClient } from '../../helpers/graphqlClient'
import {
  CREATE_REVIEW_SUCCESS,
  CREATE_REVIEW_FAILURE,
  CREATE_REVIEW,
  GET_REVIEW_BY_MODULE_ID_SUCCESS,
  GET_REVIEW_BY_MODULE_ID_FAILURE,
  GET_REVIEW_BY_MODULE_ID,
  ADD_FEEDBACK,
  ADD_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_FAILURE,
  CLEAR_FEEDBACK_FLAG,
  CLEAR_REVIEW_FLAG,
  NOTIFICATION_MESSAGE,
  ERROR_NOTIFICATION_MESSAGE,
} from '../actionTypes'
import { createReview, getReviewsByModuleId, addFeedBack } from '../../graphql/ReviewSchema'
import { notificationEnum, dynamicError } from '../../util/enums/notificationEnum'
import { mutation, query } from './apiHelper'

function crateReviewApi(data) {
  const input = data.values ? data.values : data
  const inProfile = data.inProfile ? data.inProfile : false
  return mutation(createReview, { input, inProfile })
}

function* createReviewData(action) {
  try {
    const data = yield crateReviewApi(action.payload)
    yield put({ type: CREATE_REVIEW_SUCCESS, payload: data })
    yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.CREATE_REVIEW })
  } catch (e) {
    yield put({ type: CREATE_REVIEW_FAILURE, e })
  }
  yield put({ type: CLEAR_REVIEW_FLAG })
}

function* createReviewSaga() {
  yield takeLatest(CREATE_REVIEW, createReviewData)
}

function getReviewsByModuleIdApi(input) {
  return query(getReviewsByModuleId, input)
}

function* getReviewsByModuleIdData(action) {
  try {
    const res = yield getReviewsByModuleIdApi(action.payload)
    yield put({ type: GET_REVIEW_BY_MODULE_ID_SUCCESS, payload: res.data.getReviewsByModuleId })
  } catch (e) {
    yield put({ type: GET_REVIEW_BY_MODULE_ID_FAILURE, e })
  }
}

function* getReviewsByModuleIdSaga() {
  yield takeLatest(GET_REVIEW_BY_MODULE_ID, getReviewsByModuleIdData)
}

//add feedback
function addFeedBackApi(data) {
  return mutation(addFeedBack, { input: data })
}

function* addFeedBackData(action) {
  try {
    const res = yield addFeedBackApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: ADD_FEEDBACK_FAILURE, e: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    } else if (res.data) {
      yield put({ type: ADD_FEEDBACK_SUCCESS, payload: res })
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.FEEDBACK_SUBMITTED })
    }
  } catch (e) {
    yield put({ type: ADD_FEEDBACK_FAILURE, e })
  }
  yield put({ type: CLEAR_FEEDBACK_FLAG })
}

function* addFeedBackSaga() {
  yield takeLatest(ADD_FEEDBACK, addFeedBackData)
}

export default function* reviewSaga() {
  yield all([createReviewSaga(), getReviewsByModuleIdSaga(), addFeedBackSaga()])
}
