import React, { Component, useEffect } from 'react'
import { comLicenceAccessibleRole, userRoles } from '../../util/enums/enums'
import { checkRentUserType } from '../../helpers/jsxHelper'
import { ButtonComponent } from '../form/Button'
import { connect } from 'react-redux'
import {
  chooseForEstimatedCost,
  chooseForYachtFinancing,
  isChosenForEstimatedCost,
  isChosenForYachtFinancing,
} from '../../redux/actions'
import { getLocalStorageItem } from '../../helpers/storageHelper'

const UserDocumentInfoComp = props => {
  const { id, documentVerification, firstName, role, provider } = props

  const isAdmin = getLocalStorageItem('adminLoginDetected')

  useEffect(() => {
    if (props.isChosenForEstimatedCost && id) {
      props.isChosenForEstimatedCost({ userId: id });
    }
  }, [id]);

  useEffect(() => {
    if (id && props.chooseForEstimatedCost_success) {
      props.isChosenForEstimatedCost({ userId: id });
    }
  }, [id, props.chooseForEstimatedCost_success])

  useEffect(() => {
    if (props.isChosenForYachtFinancing && id) {
      props.isChosenForYachtFinancing({ userId: id })
    }
  }, [id])

  useEffect(() => {
    if (id && props.chooseForYachtFinancing_success) {
      props.isChosenForYachtFinancing({ userId: id })
    }
  }, [id, props.chooseForYachtFinancing_success])

  return (
    <div className="mt-4 user--profile--has--provided">
      <h5 className="mb-15 text-capitalize font-16 user--has--provided">{firstName} has provided</h5>

      <div className="mb-15 d-flex align-items-center doc--verification--icon--text">
        <img
          src={
            documentVerification?.governmentIdVerified
              ? require('../../components/userProfile/image/vector.png')
              : require('../../components/userProfile/image/cross-icon.svg')
          }
          className="icon-25 mr-2 filter-black"
          alt="Verification icon"
        />
        <h5 className="font-weight-300 rentInner-userTextH4 mb-0 user-profile-desc font-16 color-black">Identity</h5>
      </div>

      <div className="mb-15 d-flex align-items-center doc--verification--icon--text">
        <img
          src={
            documentVerification?.emailVerified
              ? require('../../components/userProfile/image/vector.png')
              : require('../../components/userProfile/image/cross-icon.svg')
          }
          className="icon-25 mr-2 filter-black"
          alt="Verification icon"
        />
        <h5 className="font-weight-300 rentInner-userTextH4 mb-0 user-profile-desc font-16 color-black">Email</h5>
      </div>

      <div className="mb-15 d-flex align-items-center doc--verification--icon--text">
        <img
          src={
            documentVerification?.mobileVerified
              ? require('../../components/userProfile/image/vector.png')
              : require('../../components/userProfile/image/cross-icon.svg')
          }
          className="icon-25 mr-2 filter-black"
          alt="Verification icon"
        />
        <h5 className="font-weight-300 rentInner-userTextH4 mb-0 user-profile-desc font-16 color-black">Mobile Number</h5>
      </div>

      {role?.aliasName &&
        (comLicenceAccessibleRole.includes(role.aliasName) ||
          checkRentUserType(role?.aliasName, provider && provider.alias)) && (
          <div className="mb-15 d-flex align-items-center doc--verification--icon--text">
            <img
              src={
                documentVerification.commercialLicenceVerified
                  ? require('../../components/userProfile/image/vector.png')
                  : require('../../components/userProfile/image/cross-icon.svg')
              }
              className="icon-25 mr-2 filter-black"
              alt="Verification icon"
            />
            <h5 className="font-weight-300 rentInner-userTextH4 mb-0 user-profile-desc font-16 color-black">
              {role.aliasName === userRoles.SURVEYOR ? 'Certified' : 'Licence'}
            </h5>
          </div>
        )}

      {isAdmin && role?.aliasName === userRoles.SERVICE_AND_MAINTENANCE && (
        <div className="mt-20 mb-15 d-flex flex-wrap justify-content-center align-items-center" style={{ gap: 20 }}>
          <button
            type="button"
            className={props.isChosenForEstimatedCost_data ? 'btn btn-danger' : 'btn btn-warning'}
            onClick={() => props.chooseForEstimatedCost({ userId: id, chosen: !props.isChosenForEstimatedCost_data })}
            disabled={props.chooseForEstimatedCost_loading || props.isChosenForEstimatedCost_loading}
          >
            {props.isChosenForEstimatedCost_data ? 'Remove from Estimated Cost' : 'Choose for Estimated Cost'}
          </button>

          <button
            type="button"
            className={props.isChosenForYachtFinancing_data ? 'btn btn-danger' : 'btn btn-warning'}
            onClick={() => props.chooseForYachtFinancing({ userId: id, chosen: !props.isChosenForYachtFinancing_data })}
            disabled={props.chooseForYachtFinancing_loading || props.isChosenForYachtFinancing_loading}
          >
            {props.isChosenForYachtFinancing_data ? 'Remove from Yacht Financing' : 'Choose for Yacht Financing'}
          </button>
        </div>
      )}
    </div>
  )
}

export const UserDocumentInfo = connect(state => ({
  chooseForEstimatedCost_loading: state.boatServiceReducer.chooseForEstimatedCost_loading,
  chooseForEstimatedCost_success: state.boatServiceReducer.chooseForEstimatedCost_success,
  isChosenForEstimatedCost_loading: state.boatServiceReducer.isChosenForEstimatedCost_loading,
  isChosenForEstimatedCost_data: state.boatServiceReducer.isChosenForEstimatedCost_data,
  chooseForYachtFinancing_loading: state.boatServiceReducer.chooseForYachtFinancing_loading,
  chooseForYachtFinancing_success: state.boatServiceReducer.chooseForYachtFinancing_success,
  isChosenForYachtFinancing_loading: state.boatServiceReducer.isChosenForYachtFinancing_loading,
  isChosenForYachtFinancing_data: state.boatServiceReducer.isChosenForYachtFinancing_data,
}), dispatch => ({
  chooseForEstimatedCost: data => dispatch(chooseForEstimatedCost(data)),
  isChosenForEstimatedCost: data => dispatch(isChosenForEstimatedCost(data)),
  chooseForYachtFinancing: data => dispatch(chooseForYachtFinancing(data)),
  isChosenForYachtFinancing: data => dispatch(isChosenForYachtFinancing(data)),
}))(UserDocumentInfoComp)
