import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Layout } from '../../components'

import '../../styles/common.scss'
import './privacyPolicy.scss'
import { Box, Stack} from '@mui/material'
import { footerScreenImages, icons } from '../../util/enums/enums'

const PrivacyPolicy = props => {
    return (
        <Layout>
           <div className="container100">
                <Row>
                    <Col sm={12}><h1 className="mt-30 mb-0 r-h1">Privacy Policy</h1></Col>
                </Row>
                <Row>
                    <Col className='m-auto' md={6} sm={12}>
                    <Stack>
                        <p className='policy-title' style={{lineHeight: 'initial'}}>Commitment to Prioritizing<br/><span className='text-primary'>User policy</span></p>
                        <p className='policy-text'>The Internet is an amazing tool, and we are beginning to realize that it has the power to change the way we live. With only a few mouse clicks, you can follow the news, look up data, buy goods and services, and communicate with others around the world. It's important to ADAMSEA INC. to help our customers retain their privacy while utilizing, all that our website has to offer.</p>
                    </Stack>
                    </Col>
                    <Col md={6} sm={12}>
                        <img className='text-center' laoding="lzy" width={452} src={footerScreenImages.commitment_priotizing} alt='Commitment'/>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={12}>
                        <div className='d-flex flex-row'>
                            <img className='mx-3' width={75} src={icons.data_protection_commitment} alt='Protection'/>
                            <Stack>
                                <p className='policy-subtitle'>Data Protection Commitment</p>
                                <p className='policy-text'>We believe your business is no one else's. Your privacy is important to you and to us. So, we'll protect the data you share with us. To protect your privacy, ADAMSEA INC. follows different principles in accordance with worldwide practices for customer privacy and data protection. </p>
                            </Stack>
                        </div>
                    </Col>
                    <Col md={6} sm={12}>
                    <div className='d-flex flex-row'>
                            <img className='mx-3' width={75} src={icons.data_handling_measures} alt='Security'/>
                            <Stack>
                                <p className='policy-subtitle'>Data Handling and Security Measures</p>
                                <p className='policy-text'>Your name, mailing address, phone number, email address, or any other data will never be sold or otherwise made available to a third party. We will use state-of-the-art security measures to keep your data safe from unauthorized users.</p>
                            </Stack>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col sm={12} className='bg-gradient-blue my-4'>NOTICE</Col>
                    <Col className="text-center m-auto" md={4} sm={12}>
                        <img width={274} loading='lazy' src={footerScreenImages.notice_vector} alt='Notice'/>
                    </Col>
                    <Col md={8}>
                        <p className='policy-text'>We collect personal data depending on how and why you are interacting with us. And when we need the data that personally identifies you (personal data) or allows us to contact you, Generally, this data is requested when you create a Registration ID on the site or when you download free software, participate in a contest, order email newsletters or sign up for the premium site with restricted access. We use your Personal data primarily for the following reasons:</p>
                        <ul id='policy-bullet-text'>
                            <li>To make the use of the website easier for you by eliminating the need to enter the data more than once.</li>
                            <li>To assist you in quickly finding goods, services, or data.</li>
                            <li>To aid us in creating content most relevant to you.</li>
                            <li>To notify you of any product upgrades, special offers, data updates data, and other new services from ADAMSEA INC.CONSENT</li>
                        </ul>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} className='bg-gradient-blue my-4'>CONSENT</Col>
                    <Col className="text-center m-auto" md={4} sm={12}>
                        <img width={274} loading='lazy' src={footerScreenImages.consent_vector} alt='Consent'/>
                    </Col>
                    <Col md={8} sm={12}>
                    <ul id='policy-bullet-text'>
                            <li>If you choose not to register or provide your personal data, you can still use the majority of the website but you will not be able to access sections that require registration.</li>
                            <li>By signing up for different services, such as our electronic newsletters, you will be able to choose the type of data you want to receive from us if you decide to register. You can choose the option saying that you do not want to receive marketing messages from ADAMSEA INC if you do not want us to contact you regarding additional offers regarding ADAMSEA INC. goods, programs, events, or services by email, postal mail, or telephone.</li>
                            <li>Adamsea Inc.. occasionally allows other companies to offer our registered customers data about their goods and services, using e-mail only. If you do not want to receive these offers, you may select the option stating that you do not wish to receive marketing materials from third parties.</li>
                            <li>Where you have provided your consent to our processing your Data, you have the right to withdraw your consent at any time.</li>
                        </ul>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} className='bg-gradient-blue my-4'>ACCESS</Col>
                    <Col className="text-center m-auto" md={4} sm={12}>
                        <img width={274} loading='lazy' src={footerScreenImages.access_vector} alt='Access'/>
                    </Col>
                    <Col md={8} sm={12}>
                        <p className='policy-text'>We will provide you with the means to ensure that your personal and confidential data is correct and up to date. You may review and update this data at any time at the Visitor Center. There, you can:</p>
                    <ul id='policy-bullet-text'>
                            <li>View and modify the personal and confidential data you have already provided us.</li>
                            <li>Tell us whether you want us to send you marketing data, or whether you want any third-party/parties to send you their offers by postal mail.</li>
                            <li>Subscribe to our electronic newsletters to learn more about our services and products.</li>
                            <li>Register. Once you register yourself, you would not need to register again. Wherever you go on our website, your data will be saved with you.</li>
                        </ul>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} className='bg-gradient-blue my-4'>SECURITY</Col>
                    <Col md={4} sm={12}>
                        <img width={274} loading='lazy' src={footerScreenImages.security_vector} alt='Security'/>
                    </Col>
                    <Col md={8} sm={12}>
                        <p className='policy-text'>ADAMSEA INC. has taken strong measures to protect the security of your personal data and to ensure that your choices for its intended use are honored. We take strong precautions to protect your data from loss, misuse, unauthorized access or disclosure, alteration, or destruction.<br/>We guarantee your e-commerce transactions to be 100% safe and secure. When you place your orders or access your personal account information, you are utilizing a secure server software SSL, which encrypts your personal data before it's sent over the Internet. SSL is one of the safest encryption technologies available.<br/>In addition, your transactions are guaranteed under the Fair Credit Billing Act. This Act states that your bank cannot hold you liable for more than $50.00 in fraudulent credit card charges. If your bank does hold you liable for $50.00 or less, we will cover your liability provided the unauthorized, fraudulent use of your credit card resulted through no fault of your own and from purchases made from us over our secure server. In the event of unauthorized use of your credit card, you must notify your credit card provider in accordance with its reporting rules and procedures.<br/>ADAMSEA INC. strictly protects the security of your personal data and honors your preferences for its intended use. We carefully protect your data against theft, loss, misuse, unauthorized access or disclosure, alteration, and destruction.
Your personal data is never shared outside the company without your permission, except under the conditions explained above. Inside the company, data is stored in password-controlled servers with limited access. Your data may be stored and processed in Canada or any other country where ADAMSEA INC., its subsidiaries, affiliates, or agents are located.
You also have a significant role in protecting your data. No one can see or edit your personal data without knowing your username and password, so do not share these with others.<br/>NOTICE TO PARENTS<br/>
Parents or guardians: we want to help you guard your children's privacy. We encourage you to talk to your children about safe and responsible use of their Personal Data while using the Internet. Our website does not publish any content intended for children; hence ADAMSEA INC. disclaims any liability under any circumstances in the event of misuse of personal data by children.<br/>ELECTRONIC PRODUCT REGISTRATION<br/>
When you buy and sell a product, we may ask you to register your purchase electronically. When you do, we merge your registration data with any data you've already left with us (we call that data your personal profile). If you haven't previously registered with us, we create a personal profile for you from your product registration data. If you ever want to review or update that data, you can visit the Profile Center, click on Update Profile, and edit any of the Personal Data in your profile. If you haven't already created a Registration ID, we will ask you to do so. This ensures that only you can access your data.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className='bg-gradient-blue my-4'>NOTICE TO PARENTS</Col>
                    <Col md={4} sm={12}>
                        <img width={274} loading='lazy' src={footerScreenImages.notice_to_parent_vector} alt='Notice'/>
                    </Col>
                    <Col md={8} sm={12}>
                        <p className='policy-text'>Parents or guardians: we want to help you guard your children's privacy. We encourage you to talk to your children about safe and responsible use of their Personal Data while using the Internet. Our website does not publish any content intended for children; hence ADAMSEA INC. disclaims any liability under any circumstances in the event of misuse of personal data by children.</p>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} className='bg-gradient-blue my-4'>ELECTRONIC PRODUCT REGISTRATION</Col>
                    <Col md={4} sm={12}>
                        <img width={274} loading='lazy' src={footerScreenImages.electronic_registration_vector} alt='Registration'/>
                    </Col>
                    <Col md={8} sm={12}>
                        <p className='policy-text'>When you buy and sell a product, we may ask you to register your purchase electronically. When you do, we merge your registration data with any data you've already left with us (we call that data your personal profile). If you haven't previously registered with us, we create a personal profile for you from your product registration data. If you ever want to review or update that data, you can visit the Profile Center, click on Update Profile, and edit any of the Personal Data in your profile. If you haven't already created a Registration ID, we will ask you to do so. This ensures that only you can access your data.</p>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} className='bg-gradient-blue my-4'>CUSTOMER PROFILES</Col>
                    <Col md={4} sm={12}>
                        <img width={274} loading='lazy' src={footerScreenImages.customer_profiles_vector} alt='Customer profile'/>
                    </Col>
                    <Col md={8} sm={12}>
                        <p className='policy-text'>As mentioned above, every registered user has a distinct personal profile. Each profile is assigned a unique personal identification number, which helps us to ensure that only you can access your profile.
When you register with our website, we create your profile, assign a personal identification number, and then send this personal identification number back to your hard drive in the form of a cookie, which is a very small bit of code. This code is exclusively assigned to you. It serves as your ticket for your easy navigation throughout allowing you to post, buy & sell products and request free newsletters. Even if you switch computers, you won't need to re-register – just use your Registration ID to identify yourself.</p>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} className='bg-gradient-blue my-4'>COMMUNITY GUIDELINES</Col>
                    <Col md={4} sm={12}>
                        <img width={274} loading='lazy' src={footerScreenImages.community_guidelines_vector} alt='Community guidelines'/>
                    </Col>
                    <Col md={8} sm={12}>
                        <p className='policy-text'>Adamsea.com is a reflection of our diverse community of cultures, ages, and beliefs. We have spent a lot of time thinking about the different points of view that create a safe and open environment for everyone.<br/>We created the Community Guidelines so you can help us foster and protect this amazing community. By using Adamsea.com’s communication and sharing platform that is SEACHAT AND SEA SHARE, you agree to these guidelines and our Terms of Use. We are committed to these guidelines and we hope you are too. Overstepping these boundaries may result in deleted content, disabled accounts, or other restrictions. We do this only after weighing the public interest value against the risk of harm and we look to international human rights standards to make these judgments:</p>
                        <ul id='policy-bullet-text'>
                            <li>Post photos and videos that are appropriate for a diverse audience.
We know that there are times when people might want to share nude images that are artistic or creative in nature, but for a variety of reasons, we don’t allow nudity on Adamsea.com.
People like to share photos or videos of their children. For safety reasons, there are times when we may remove images that show nude or partially-nude children. Even when this content is shared with good intentions, it could be used by others in unanticipated ways.</li>
<li>Follow the law.<br/>Adamsea.com is not a site to encourage or support acts of terrorism, criminal activity, or hate organizations. Selling or buying non-medical or prescription medicines is likewise prohibited, as is providing sexual services, as well as private sales of alcohol, tobacco, and guns. We also delete anything that attempts to trade, organize the trade of, donate, gift, or request non-medical drugs, as well as content that either admits to personal use of non-medical drugs (unless it is done so in the context of recovery) or coordinates or encourages its use. Instagram also forbids the private sale of live animals, despite brick-and-mortar establishments being able to do so. No one is allowed to plan the theft of endangered animals or the sale of their components.<br/>Remember to always follow the law when offering to sell or buy other regulated goods. Accounts promoting online gambling, online real money games of skill or online lotteries must get our prior written permission before using any of our products.
We do not tolerate the exchange of explicit material involving minors or the threat to publish others' private photos.</li>
                        <li>Respect other members of the Adamsea community.<br/>We want to foster a positive, diverse community. We remove content that contains credible threats or hate speech, content that targets private individuals to degrade or shame them, personal information meant to blackmail or harass someone, and repeated unwanted messages. We do generally allow stronger conversation around people who are featured in the news or have a large public audience due to their profession or chosen activities.
<br/>It's never OK to encourage violence or attack anyone based on their race, ethnicity, national origin, sex, gender, gender identity, sexual orientation, religious affiliation, disabilities, or diseases. When hate speech is being shared to challenge it or to raise awareness, we may allow it. In those instances, we ask that you express your intent clearly.
<br/>Serious threats of harm to public and personal safety aren't allowed. This includes specific threats of physical harm as well as threats of theft, vandalism, and other financial harm. We carefully review reports of threats and consider many things when determining whether a threat is credible.</li>
                        </ul>
                        <p className='policy-text font-weight-bold'>Help us keep the community strong:</p>
                        <ul id='policy-bullet-text'>
                            <li>Each of us is an important part of the Adamsea community. If you see something that you think may violate our guidelines, please help us by using our built-in reporting option. We have a global team that reviews these reports and works as quickly as possible to remove content that doesn’t meet our guidelines. Even if you or someone you know doesn’t have an account on Adamsea.com, you can still file a report. When you complete the report, try to provide as much information as possible, such as links, usernames, and descriptions of the content, so we can find and review it quickly. We may remove entire posts if either the imagery or associated captions violate our guidelines.</li>
                            <li>You may find content you don’t like, but doesn’t violate the Community Guidelines. If that happens, you can unfollow or block the person who posted it. If there's something you don't like in a comment on one of your posts, you can delete that comment</li>
                            <li>Many disputes and misunderstandings can be resolved directly between members of the community. If one of your photos or videos was posted by someone else, you could try commenting on the post and asking the person to take it down. If that doesn’t work, you can file a report.</li>
                            <li>We may work with law enforcement, including when we believe that there’s risk of physical harm or threat to public safety.</li>
                        </ul>
                    </Col>
                </Row>
                <Box className='my-4 text-center'>
                    <p className='policy-subtitle'>What we do with the data you share</p>
                    <p className='policy-text'>When you join us, you provide us with your contact data, including your name and email address. We use this data to send you updates about your order, questionnaires to measure your satisfaction with our service, and announcements about new and exciting services that we offer. When you order from us, we ask for your credit card number and billing address. We use this data only to bill you for the product(s) you ordered at that time. For your convenience, we do save billing data in case you want to order from us again, but we don't use this data again without your permission.
We occasionally hire other companies to provide limited services on our behalf, including packaging, mailing and delivering purchases, answering customer questions about products or services, sending postal mail and processing event registration. We will only provide those companies the data they need to deliver the service, and they are prohibited from using that data for any other purpose.
ADAMSEA INC. will disclose your personal data, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on ADAMSEA INC. or the site; (b) protect and defend the rights or property of ADAMSEA INC and its family of Websites, and, (c) act in urgent circumstances to protect the personal safety of users of ADAMSEA INC. its websites, or the public.</p>

                </Box>
                <Box className='my-4 text-center'>
                <p className='policy-subtitle'>Enforcement</p>
                <p className='policy-text'>Please email us at legal@adamsea.com if you have reason to think that ADAMSEA INC. has not upheld these standards. We will try our best to quickly identify and fix the issue. Make sure the Subject line contains the words "Privacy Policy."</p>
                </Box>

                <Row className='py-4'>
                    <Col md={8} sm={12}>
                        <Stack>
                            <p className='policy-subtitle'>Changes to this PRIVACY POLICY</p>
                            <p className='policy-text'>Please note that this Privacy Policy may be amended from time to time. You should verify back for the most recent version before relying on any of the provisions in this privacy policy. We will provide notice of material changes to the policy, either by posting a notice on our website, by sending an email, or some other reasonable method.</p>
                        </Stack>
                    </Col>
                    <Col md={4} sm={12}>
                        <img width={274} loading='lazy' src={footerScreenImages.change_policy_vector} alt='Change Policy'/>
                    </Col>
                </Row>
           </div>
        </Layout>
    )
}

export default PrivacyPolicy
