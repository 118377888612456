import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getIsDeleMessage, getIsGetMessage, getIsNewMessageRead, getIsTextMessage, getSelectUser, getTextareaHeight, getThreadMessage } from '../../redux/selector/chat'

import { getUniqueListBy, groupByChatDateWise } from '../../util/chat'
import { Loader } from '../loader/loader'
import { MessagesWrapper } from './messagesWrapper'
import { SearchThreadText } from './searchThread'
import { EmptyThread } from './emptyThread.js'
import classNames from 'classnames'
import { socket } from '../../socket'
import { socketEvent } from '../../util/enums/socketEnums'
import { getChatUsers, getMessageByChatId } from '../../redux/actions/chatAction'


export const ActiveThreadChat = () => {
    const [search, setSearch] = useState('')
    const [dataThreads, setDataThreads] = useState({})
    const [shouldGoToBottom,setShouldGoToBottom]=useState(true);
    const [isFirstTime, setIsFirstTime] = useState(true)
    const dispatch = useDispatch();
    
    let threadMessagesRef = useRef()
    let threadEl = useRef()
    const activeThreadMessages = useSelector(getThreadMessage)
    const selectUser = useSelector(getSelectUser)
    const [prevScrollHeight, setPrevScrollHeight] = useState(threadMessagesRef?.current?.scrollHeight)
    
    const isNewMessageRead = useSelector(getIsNewMessageRead)
    let isGetMessage = useSelector(getIsGetMessage)
    const isDeleMessage = useSelector(getIsDeleMessage)
    const textareaHeight = useSelector(getTextareaHeight)

    const scrollToBottom = () => {
        if (threadMessagesRef && threadEl.current && !isDeleMessage) {
            threadMessagesRef.current.scrollTop = threadMessagesRef.current.scrollHeight
            // threadMessagesRef.current.scrollTo(0, threadMessagesRef.current.scrollHeight)
        }
    }


    const handleScroll=()=>{
        if(activeThreadMessages.length>0){
            const chatId = activeThreadMessages[activeThreadMessages.length-1].metaData.chatId
            if(threadMessagesRef.current.scrollTop ===0 ){
                setShouldGoToBottom(false);
                dispatch(getChatUsers({chatId}))
                dispatch(getMessageByChatId({ chatId ,messageListId:activeThreadMessages[activeThreadMessages.length-1].id ,limit:25}))
                setPrevScrollHeight(threadMessagesRef.current.scrollHeight)
            }
            // threadMessagesRef.current.scrollTop=threadMessagesRef.current.scrollHeight-prevScrollHeight
        }
    }
    
    useEffect(()=>{
        threadMessagesRef.current.addEventListener("scroll",handleScroll)

        return ()=> threadMessagesRef?.current?.removeEventListener("scroll",handleScroll)
    },[activeThreadMessages])

    useEffect(() => {
        setSearch('')
    }, [selectUser?.chatId])


    

    useEffect(() => {
        if(!activeThreadMessages) return
        const finalData = groupByChatDateWise({ activeThreadMessages, search })
        setDataThreads(finalData)
    }, [activeThreadMessages, isNewMessageRead, search])

    useEffect(() => {
        if (shouldGoToBottom && isFirstTime && Object.keys(dataThreads).length) {
                scrollToBottom()
                setIsFirstTime(false)
        }
    }, [isGetMessage ])

    useEffect(()=>{
        if (shouldGoToBottom && Object.keys(dataThreads).length) {
            scrollToBottom()
        }else{
            setShouldGoToBottom(true);
            threadMessagesRef.current.scrollTop=threadMessagesRef?.current?.scrollHeight-prevScrollHeight
        }
    },[dataThreads])

    return (
        <>
           {activeThreadMessages?.length > 0 && <SearchThreadText search={search} setSearch={setSearch} />}

            <div
                className={classNames('right--chat--main--div',{
                    'right--chat--main--div__no-search': !activeThreadMessages?.length,
                })}
                ref={threadMessagesRef}
                style={{height:`calc(100% - ${textareaHeight+38}px)`}}
            >
                <div className="right--chat--div--spacing right-chat-view-message-space">
                    <EmptyThread isThreadLength={activeThreadMessages?.length} />

                    {activeThreadMessages?.length > 0 && <div className='chat-thread-alignment' ref={threadEl}>
                        <div className='empty-space-menage'></div>
                        {Object.keys(dataThreads)?.filter(Boolean).reverse().map((threadMessage) => {
                            return getUniqueListBy(dataThreads[threadMessage], 'id').reverse()?.map((item, i) => {
                                return (
                                    <React.Fragment key={`${item.id}--${i}`}>
                                        {i === 0 && <div className='chat--date'><span className='chat--date--label'>{threadMessage}</span></div>}
                                        <MessagesWrapper  {...item} />
                                    </React.Fragment>
                                )
                            })
                        })}
                    </div>}
                </div>
            </div>
        </>
    )
}
