import {
  GET_SALES_ENGINE_BY_BUYER,
  GET_SALES_ENGINE_BY_BUYER_SUCCESS,
  GET_SALES_ENGINE_BY_BUYER_FAILURE,
  GET_SALES_ENGINE_BY_SELLER,
  GET_SALES_ENGINE_BY_SELLER_SUCCESS,
  GET_SALES_ENGINE_BY_SELLER_FAILURE,
  GET_SALES_ENGINE_BY_SURVEYOR,
  GET_SALES_ENGINE_BY_SURVEYOR_SUCCESS,
  GET_SALES_ENGINE_BY_SURVEYOR_FAILURE,
  GET_SALES_ENGINE_BY_SHIPPER,
  GET_SALES_ENGINE_BY_SHIPPER_SUCCESS,
  GET_SALES_ENGINE_BY_SHIPPER_FAILURE,
  GET_SINGLE_SALES_ENGINE,
  GET_SINGLE_SALES_ENGINE_SUCCESS,
  GET_SINGLE_SALES_ENGINE_FAILURE,
  CLEAR_SALES_ENGINES,
  CREATE_SALES_ENGINE,
  CREATE_SALES_ENGINE_SUCCESS,
  CREATE_SALES_ENGINE_FAILURE,
  CLEAR_CREATE_SALES_ENGINE_FLAG,
  ASSIGN_SURVEYOR,
  ASSIGN_SURVEYOR_SUCCESS,
  ASSIGN_SURVEYOR_FAILURE,
  PAYMENT_REQUEST,
  PAYMENT_REQUEST_SUCCESS,
  PAYMENT_REQUEST_FAILURE,
  GET_AGREEMENTS_CONTENTS,
  GET_AGREEMENTS_CONTENTS_SUCCESS,
  GET_AGREEMENTS_CONTENTS_FAILURE,
  CHECK_AGREEMENT,
  CHECK_AGREEMENT_SUCCESS,
  CHECK_AGREEMENT_FAILURE,
  SALES_ENGINE_ASSIGN_SHIPPER_REQUEST,
  SALES_ENGINE_ASSIGN_SHIPPER_REQUEST_SUCCESS,
  SALES_ENGINE_ASSIGN_SHIPPER_REQUEST_FAILURE,
  CLEAR_GET_SINGLE_SALES_ENGINE_FLAG,
  SALES_ENGINE_SURVEYOR_ACCEPT_BUYER,
  SALES_ENGINE_SURVEYOR_ACCEPT_BUYER_SUCCESS,
  SALES_ENGINE_SURVEYOR_ACCEPT_BUYER_FAILURE,
  SALES_ENGINE_SURVEYOR_DECLINE_BUYER,
  SALES_ENGINE_SURVEYOR_DECLINE_BUYER_SUCCESS,
  SALES_ENGINE_SURVEYOR_DECLINE_BUYER_FAILURE,
  CREATE_SURVEYOR_REPORT,
  CREATE_SURVEYOR_REPORT_SUCCESS,
  CREATE_SURVEYOR_REPORT_FAILURE,
  CLEAR_REPORT_FLAG,
  GET_SURVEYOR_REPORT_SUCCESS,
  GET_SURVEYOR_REPORT_FAILURE,
  GET_SURVEYOR_REPORT,
  SHIPPER_ACCEPT_SHIPMENT_REQUEST,
  SHIPPER_ACCEPT_SHIPMENT_REQUEST_SUCCESS,
  SHIPPER_ACCEPT_SHIPMENT_REQUEST_FAILURE,
  SHIPPER_DECLINE_SHIPMENT_REQUEST,
  SHIPPER_DECLINE_SHIPMENT_REQUEST_SUCCESS,
  SHIPPER_DECLINE_SHIPMENT_REQUEST_FAILURE,
  ADD_SHIPMENT_PROPOSAL,
  ADD_SHIPMENT_PROPOSAL_SUCCESS,
  ADD_SHIPMENT_PROPOSAL_FAILURE,
  GET_SINGLE_SHIPMENT_PROPOSAL,
  GET_SINGLE_SHIPMENT_PROPOSAL_SUCCESS,
  GET_SINGLE_SHIPMENT_PROPOSAL_FAILURE,
  UPDATE_SHIPMENT_PROPOSAL,
  UPDATE_SHIPMENT_PROPOSAL_SUCCESS,
  UPDATE_SHIPMENT_PROPOSAL_FAILURE,
  GET_ALL_SHIPMENT_PROPOSAL,
  GET_ALL_SHIPMENT_PROPOSAL_SUCCESS,
  GET_ALL_SHIPMENT_PROPOSAL_FAILURE,
  CLEAR_SALES_ENGINE_SHIPPER_DASHBOARD_FLAG,
  ADD_NEGOTIABLE_PRICE,
  ADD_NEGOTIABLE_PRICE_SUCCESS,
  ADD_NEGOTIABLE_PRICE_FAILURE,
  CLEAR_SALES_ENGINE_MY_BOAT_FLAG,
  ADD_SURVEY_OPTION,
  ADD_SURVEY_OPTION_SUCCESS,
  ADD_SURVEY_OPTION_FAILURE,
  CLEAR_SALES_ENGINE_SURVEYOR_DASHBOARD_FLAG,
  CLEAR_PAYMENT_FLAG,
  SKIP_SHIPMENT,
  SKIP_SHIPMENT_SUCCESS,
  SKIP_SHIPMENT_FAILURE,
  DECLINE_SURVEYOR,
  DECLINE_SURVEYOR_SUCCESS,
  DECLINE_SURVEYOR_FAILURE,
  CLEAR_SALES_ENGINE_SHIPMENT_FLAG,
  ASSIGN_AGENT,
  ASSIGN_AGENT_SUCCESS,
  ASSIGN_AGENT_FAILURE,
  CLEAR_AGENT_FLAG,
  GET_SALES_ENGINE_BY_AGENT,
  GET_SALES_ENGINE_BY_AGENT_SUCCESS,
  GET_SALES_ENGINE_BY_AGENT_FAILURE,
  GET_DOCUMENT_LINKS,
  GET_DOCUMENT_LINKS_SUCCESS,
  GET_DOCUMENT_LINKS_FAILURE,
  CLEAR_SALES_ENGINE_PAYMENT_FLAG,
  ADD_BOAT_SHIPMENT_LOCATION,
  ADD_BOAT_SHIPMENT_LOCATION_SUCCESS,
  ADD_BOAT_SHIPMENT_LOCATION_FAILURE,
  GET_COST_ESTIMATE,
  GET_COST_ESTIMATE_SUCCESS,
  GET_COST_ESTIMATE_FAILURE,
  START_SHIPMENT,
  START_SHIPMENT_SUCCESS,
  START_SHIPMENT_FAILURE,
  COMPLETE_SHIPMENT,
  COMPLETE_SHIPMENT_SUCCESS,
  COMPLETE_SHIPMENT_FAILURE,
  ADD_SHIPPER_DOCUMENTS,
  ADD_SHIPPER_DOCUMENTS_SUCCESS,
  ADD_SHIPPER_DOCUMENTS_FAILURE,
  CLEAR_SHIPPER_DOCUMENT_FLAG,
  CLEAR_SHIPPER_REQUEST_FLAG,
  DISCARD_AGENT,
  DISCARD_AGENT_SUCCESS,
  DISCARD_AGENT_FAILURE,
  ASSIGN_SURVEYOR_CLEAR_FLAG,
  GET_SALES_ENGINE_AGENTS,
  GET_SALES_ENGINE_AGENTS_SUCCESS,
  GET_SALES_ENGINE_AGENTS_FAILURE,
  PRINT_PAYMENT_RECEIPT_SUCCESS,
  PRINT_PAYMENT_RECEIPT_FAILURE,
  PRINT_PAYMENT_RECEIPT,
  GET_SALES_ENGINE_STATIC_CONTENT,
  GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS,
  GET_SALES_ENGINE_STATIC_CONTENT_FAILURE,
  GET_SURVEY_DOCUMENT_DATA_SUCCESS,
  GET_SURVEY_DOCUMENT_DATA_FAILURE,
  GET_SURVEY_DOCUMENT_DATA,
  GET_SALES_ENGINE_BY_BOAT,
  GET_SALES_ENGINE_BY_BOAT_SUCCESS,
  GET_SALES_ENGINE_BY_BOAT_FAILURE,
  BUYER_REVIEW_SURVEY_REPORT_SUCCESS,
  BUYER_REVIEW_SURVEY_REPORT_FAILURE,
  CLEAR_REVIEW_REPORT_FLAG,
  BUYER_REVIEW_SURVEY_REPORT,
  UPDATE_SURVEYOR_REPORT,
  UPDATE_SURVEYOR_REPORT_SUCCESS,
  UPDATE_SURVEYOR_REPORT_FAILURE,
  SALES_ENGINE_STEP_CHANGE,
  SALES_ENGINE_STEP_CHANGE_SUCCESS,
  SALES_ENGINE_STEP_CHANGE_FAILURE,
  GET_DEALERS_SALES_ENGINE_SUCCESS,
  GET_DEALERS_SALES_ENGINE_FAILURE,
  GET_DEALERS_SALES_ENGINE,
  GET_SHIPPER_DOCUMENTS_SUCCESS,
  GET_SHIPPER_DOCUMENTS_FAILURE,
  CLEAR_SHIPPER_DOCUMENTS_FLAG,
  GET_SHIPPER_DOCUMENTS,
  UPDATE_STOCK_QUANTITY_SUCCESS,
  UPDATE_STOCK_QUANTITY_FAILURE,
  UPDATE_STOCK_QUANTITY,
  UPDATE_SHIPPER_DOCUMENTS_FAILURE,
  UPDATE_SHIPPER_DOCUMENTS_SUCCESS,
  UPDATE_SHIPPER_DOCUMENTS,
  REQUEST_AGENT,
  REQUEST_AGENT_SUCCESS,
  REQUEST_AGENT_FAILURE,
  CLEAR_DISCARD_AGENT_FLAG,
  CLEAR_SALES_ENGINE_DASHBOARD_FLAG,
  SALES_ENGINE_PAYMENT_DETAILS,
  SALES_ENGINE_PAYMENT_DETAILS_SUCCESS,
  SALES_ENGINE_PAYMENT_DETAILS_FAILURE,
  GET_COMMISSIONS,
  GET_COMMISSIONS_SUCCESS,
  GET_COMMISSIONS_FAILURE,
  GET_COMMISSIONS__CLEAR,
} from '../actionTypes'

const initialState = {
  salesEngine: {},
  getSalesEngineSuccess: false,
  getSalesEngineError: false,

  salesEngines: [],
  buyerSalesEngines: [],
  surveyorSalesEngines: [],
  shipperSalesEngines: [],
  createSuccess: false,
  createError: false,

  salesEnginesBoats: [],

  nearestSurveyors: [],

  requestSurveyorSuccess: false,
  requestSurveyorError: false,
  declineSurveyorSuccess: false,
  declineSurveyorError: false,
  declineSurveyorLoading: false,

  agreementsContents: [],
  isAgreementChecked: false,

  paymentProcessing: false,
  paymentSuccess: false,
  paymentError: false,

  surveyorAcceptSuccess: false,
  surveyorAcceptError: false,
  surveyorDeclineSuccess: false,
  surveyorDeclineError: false,

  surveyorReport: {},

  createNegotiablePriceSuccess: false,
  createNegotiablePriceError: false,

  addSurveyOptionSuccess: false,
  addSurveyOptionError: false,

  skipShipmentSuccess: false,
  skipShipmentCount: 0,
  skipShipmentError: false,
  shipmentLocationAddedSuccess: false,
  shipmentLocationAddedCount: 0,
  shipmentLocationAddedError: false,

  shipperAcceptShipperRequestSuccess: false,
  shipperAcceptShipperRequestError: false,
  shipperDeclineShipperRequestSuccess: false,
  shipperDeclineShipperRequestError: false,
  shipmentProposals: [],
  addShipmentProposalSuccess: false,
  addShipmentProposalError: false,
  agents: [],
  salesEngineByAgent: [],
  isAgentLoading: false,

  costEstimateSuccess: false,
  costEstimateError: false,
  costEstimation: {},
  assignShipperSuccess: false,
  isLoading: false,

  assignSurveyorLoading: false,

  paymentReceiptUrl: '',
  getPaymentReceiptSuccess: false,
  getPaymentReceiptError: false,
  salesEngineStaticContents: [],
  surveyDocument: {},

  reviewReportSuccess: false,
  reviewReportFailure: false,

  reportLoading: false,
  dealersSalesEngine: [],
  isDealersSalesEngineLoading: false,

  shipperDocument: {},
  shipperDocumentLoading: false,

  agentTotal: 0,

  requestAgentSuccess: false,
  requestAgentError: false,
  shipperDocumentSuccess: false,
  shipperDocumentError: false,

  discardSuccess: false,
  discardError: false,

  getDocumentSuccess: false,

  getBySurveyorSuccess: false,
  getBySurveyorError: false,

  getByShipperSuccess: false,
  getByAgentError: false,

  payments: [],
  transectionsSuccess: false,
  transectionsError: false
}

export const salesEngineReducer = (state = initialState, action) => {
  const { salesEngines, salesEngine, salesEngineByAgent } = state

  switch (action.type) {
    //BUYER
    case GET_SALES_ENGINE_BY_BUYER:
      return {
        ...state,
        isLoading: true,
      }

    case GET_SALES_ENGINE_BY_BUYER_SUCCESS:
      return {
        ...state,
        buyerSalesEngines: action.payload.items,
        salesEnginesTotal: action.payload.total,
        isLoading: false,
      }

    case GET_SALES_ENGINE_BY_BUYER_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    //SELLER
    case GET_SALES_ENGINE_BY_SELLER:
      return {
        ...state,
        salesEnginesBoats: [],
        isLoading: true,
      }

    case GET_SALES_ENGINE_BY_SELLER_SUCCESS:
      return {
        ...state,
        salesEnginesBoats: action.payload.items,
        salesEnginesBoatsTotal: action.payload.total,
        isLoading: false,
      }

    case GET_SALES_ENGINE_BY_SELLER_FAILURE:
      return {
        ...state,
        salesEnginesBoats: [],
        isLoading: false,
      }

    //SURVEYOR
    case GET_SALES_ENGINE_BY_SURVEYOR:
      let loadDetails = {
        isLoading: true,
        surveyorTotal: 0,
      }
      if(action.isSearchAction) {
        loadDetails = {}
      }
      return {
        ...state,
        ...loadDetails

      }

    case GET_SALES_ENGINE_BY_SURVEYOR_SUCCESS:
      return {
        ...state,
        salesEngines: action.payload.items,
        surveyorSalesEngines: action.payload.items,
        surveyorTotal: action.payload.total,
        isLoading: false,
        getBySurveyorSuccess: true,
      }

    case GET_SALES_ENGINE_BY_SURVEYOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        surveyorTotal: 0,
        getBySurveyorError: true,
      }

    //SHIPPER
    case GET_SALES_ENGINE_BY_SHIPPER:
      let loadShipperDetails = {
        isLoading: true,
      }
      if(action.isSearchAction) {
        loadShipperDetails = {}
      }
      return {
        ...state,
        ...loadShipperDetails,
      }

    case GET_SALES_ENGINE_BY_SHIPPER_SUCCESS:
      return {
        ...state,
        shipperSalesEngines: action.payload.items,
        shipperTotal: action.payload.total,
        isLoading: false,
        getByShipperSuccess: true,
      }

    case GET_SALES_ENGINE_BY_SHIPPER_FAILURE:
      return {
        ...state,
        isLoading: false,
        getByShipperError: true,
      }

    //GET SINGLE SALES ENGINE
    case GET_SINGLE_SALES_ENGINE:
      return {
        ...state,
        getSalesEngineSuccess: false,
        getSalesEngineError: false,
        salesEngine: {},
        isLoading: true,
      }

    case GET_SINGLE_SALES_ENGINE_SUCCESS:
      return {
        ...state,
        salesEngine: action.payload,
        getSalesEngineSuccess: true,
        isLoading: false,
      }

    case GET_SINGLE_SALES_ENGINE_FAILURE:
      return {
        ...state,
        getSalesEngineError: true,
        isLoading: false,
      }

    // CREATE SALES ENGINE
    case CREATE_SALES_ENGINE:
      return {
        ...state,
        salesEngine: {},
        createSuccess: false,
        createError: false,
        errors: '',
        createSalesEngineLoading: true,
      }

    case CREATE_SALES_ENGINE_SUCCESS:
      return {
        ...state,
        salesEngine: action.payload,
        createSuccess: true,
        createSalesEngineLoading: false,
      }

    case CREATE_SALES_ENGINE_FAILURE:
      return {
        ...state,
        salesEngine: {},
        createError: true,
        errors: action.errors && action.errors.length && action.errors[0].message,
        createSalesEngineLoading: false,
      }

    //ASSIGN SURVEYOR
    case ASSIGN_SURVEYOR:
      return {
        ...state,
        requestSurveyorSuccess: false,
        requestSurveyorError: false,
        assignSurveyorLoading: true,
      }

    case ASSIGN_SURVEYOR_SUCCESS:
      return {
        ...state,
        salesEngine: action.payload,
        requestSurveyorSuccess: true,
        assignSurveyorLoading: false,
      }

    case ASSIGN_SURVEYOR_FAILURE:
      return {
        ...state,
        requestSurveyorError: true,
        assignSurveyorLoading: false,
      }

    case ASSIGN_SURVEYOR_CLEAR_FLAG:
      return {
        ...state,
        requestSurveyorError: false,
        assignSurveyorLoading: false,
      }

    //DECLINE SURVEYOR
    case DECLINE_SURVEYOR:
      return {
        ...state,
        declineSurveyorSuccess: false,
        declineSurveyorError: false,
        declineSurveyorLoading: true,
      }

    case DECLINE_SURVEYOR_SUCCESS:
      return {
        ...state,
        salesEngine: action.payload,
        declineSurveyorSuccess: true,
        declineSurveyorLoading: false,
      }

    case DECLINE_SURVEYOR_FAILURE:
      return {
        ...state,
        declineSurveyorError: true,
        declineSurveyorLoading: false,
      }

    // PAYMENT REQUEST
    case PAYMENT_REQUEST:
      return {
        ...state,
        paymentProcessing: true,
        paymentSuccess: false,
        paymentError: false,
      }

    case PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        paymentProcessing: false,
        paymentSuccess: true,
        salesEngine: {
          ...action.payload.salesEngine,
          // buyerPaymentDetail: action.payload.payment
        },
      }

    case PAYMENT_REQUEST_FAILURE:
      return {
        ...state,
        paymentProcessing: false,
        paymentError: true,
      }

    case CLEAR_PAYMENT_FLAG:
      return {
        ...state,
        paymentSuccess: false,
        paymentError: false,
        paymentProcessing: false,
      }

    // GET ALL SHIPMENT
    case SALES_ENGINE_ASSIGN_SHIPPER_REQUEST:
      return {
        ...state,
      }

    case SALES_ENGINE_ASSIGN_SHIPPER_REQUEST_SUCCESS:
      return {
        ...state,
        salesEngine: action.payload,
        assignShipperSuccess: true,
      }
    case SALES_ENGINE_ASSIGN_SHIPPER_REQUEST_FAILURE:
      return {
        ...state,
      }

    case CLEAR_SHIPPER_REQUEST_FLAG:
      return {
        ...state,
        assignShipperSuccess: false,
      }

    // GET ALL AGREEMENTS CONTENTS
    case GET_AGREEMENTS_CONTENTS:
      return {
        ...state,
        agreementsContents: [],
      }

    case GET_AGREEMENTS_CONTENTS_SUCCESS:
      return {
        ...state,
        agreementsContents: action.payload,
      }

    case GET_AGREEMENTS_CONTENTS_FAILURE:
      return {
        ...state,
        agreementsContents: [],
      }

    // CHECK AGREEMENT
    case CHECK_AGREEMENT:
      return {
        ...state,
        isAgreementChecked: false,
        agreementLoading: true,
      }

    case CHECK_AGREEMENT_SUCCESS:
      return {
        ...state,
        agreementLoading: false,
        isAgreementChecked: true,
        salesEngine: action.payload,
      }

    case CHECK_AGREEMENT_FAILURE:
      return {
        ...state,
        isAgreementChecked: false,
        agreementLoading: false,
      }

    // SALES_ENGINE_SURVEYOR_ACCEPT_BUYER
    case SALES_ENGINE_SURVEYOR_ACCEPT_BUYER:
      return {
        ...state,
        surveyorAcceptSuccess: false,
        surveyorAcceptError: false,
      }

    case SALES_ENGINE_SURVEYOR_ACCEPT_BUYER_SUCCESS:
      return {
        ...state,
        salesEngine: action.payload,
        surveyorAcceptSuccess: true,
      }

    case SALES_ENGINE_SURVEYOR_ACCEPT_BUYER_FAILURE:
      return {
        ...state,
        surveyorAcceptError: true,
      }

    // SALES_ENGINE_SURVEYOR_DECLINE_BUYER
    case SALES_ENGINE_SURVEYOR_DECLINE_BUYER:
      return {
        ...state,
        surveyorDeclineSuccess: false,
        surveyorDeclineError: false,
      }

    case SALES_ENGINE_SURVEYOR_DECLINE_BUYER_SUCCESS:
      return {
        ...state,
        surveyorDeclineSuccess: true,
      }

    case SALES_ENGINE_SURVEYOR_DECLINE_BUYER_FAILURE:
      return {
        ...state,
        surveyorDeclineError: true,
      }

    case CREATE_SURVEYOR_REPORT:
      return {
        ...state,
        createReportSuccess: false,
        createReportFailure: false,
        createReportLoading: true,
      }
    case CREATE_SURVEYOR_REPORT_SUCCESS:
      return {
        ...state,
        createReportSuccess: true,
        createReportFailure: false,
        createReportLoading: false,
      }
    case CREATE_SURVEYOR_REPORT_FAILURE:
      return {
        ...state,
        createReportSuccess: false,
        createReportFailure: true,
        createReportLoading: false,
      }

    case UPDATE_SURVEYOR_REPORT:
      return {
        ...state,
        surveyorReport: {},
        updateReportSuccess: false,
        updateReportFailure: false,
      }
    case UPDATE_SURVEYOR_REPORT_SUCCESS:
      return {
        ...state,
        updateReportSuccess: true,
        updateReportFailure: false,
      }
    case UPDATE_SURVEYOR_REPORT_FAILURE:
      return {
        ...state,
        updateReportSuccess: false,
        updateReportFailure: true,
      }

    case GET_SURVEYOR_REPORT:
      return {
        ...state,
        // isLoading: true,
        getReportSuccess: false,
        getReportError: false,
        surveyorReport: {},
        reportLoading: true,
      }

    case GET_SURVEYOR_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getReportSuccess: true,
        getReportError: false,
        reportLoading: false,
        surveyorReport: action.payload.data.getSurveyorReportBySalesEngine,
      }

    case GET_SURVEYOR_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        getReportSuccess: false,
        getReportError: true,
        reportLoading: false,
      }

    // SHIPPER ACCEPT/DECLINE SHIPMENT REQUEST
    case SHIPPER_ACCEPT_SHIPMENT_REQUEST:
      return {
        ...state,
        shipperAcceptShipperRequestSuccess: false,
        shipperAcceptShipperRequestError: false,
      }
    case SHIPPER_ACCEPT_SHIPMENT_REQUEST_SUCCESS:
      return {
        ...state,
        shipperAcceptShipperRequestSuccess: true,
        salesEngines: salesEngines.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              shipperRequested: action.payload.shipperRequested,
            }
          }
          return item
        }),
      }
    case SHIPPER_ACCEPT_SHIPMENT_REQUEST_FAILURE:
      return {
        ...state,
        shipperAcceptShipperRequestError: true,
      }

    case SHIPPER_DECLINE_SHIPMENT_REQUEST:
      return {
        ...state,
        shipperDeclineShipperRequestSuccess: false,
        shipperDeclineShipperRequestError: false,
      }
    case SHIPPER_DECLINE_SHIPMENT_REQUEST_SUCCESS:
      return {
        ...state,
        shipperDeclineShipperRequestSuccess: true,
      }
    case SHIPPER_DECLINE_SHIPMENT_REQUEST_FAILURE:
      return {
        ...state,
        shipperDeclineShipperRequestError: true,
      }

    // SHIPMENT PROPOSAL
    case ADD_SHIPMENT_PROPOSAL:
      return {
        ...state,
        addShipmentProposalSuccess: false,
        addShipmentProposalError: false,
      }
    case ADD_SHIPMENT_PROPOSAL_SUCCESS:
      return {
        ...state,
        addShipmentProposalSuccess: true,
      }
    case ADD_SHIPMENT_PROPOSAL_FAILURE:
      return {
        ...state,
        salesEngine: action.payload,
        addShipmentProposalError: true,
      }

    case GET_SINGLE_SHIPMENT_PROPOSAL:
      return {
        ...state,
      }
    case GET_SINGLE_SHIPMENT_PROPOSAL_SUCCESS:
      return {
        ...state,
      }
    case GET_SINGLE_SHIPMENT_PROPOSAL_FAILURE:
      return {
        ...state,
      }

    case UPDATE_SHIPMENT_PROPOSAL:
      return {
        ...state,
      }
    case UPDATE_SHIPMENT_PROPOSAL_SUCCESS:
      return {
        ...state,
      }
    case UPDATE_SHIPMENT_PROPOSAL_FAILURE:
      return {
        ...state,
      }

    case GET_ALL_SHIPMENT_PROPOSAL:
      return {
        ...state,
        shipmentProposals: [],
        shipmentProposalsLoading: true,
      }
    case GET_ALL_SHIPMENT_PROPOSAL_SUCCESS:
      return {
        ...state,
        shipmentProposals: action.payload,
        shipmentProposalsLoading: false,
      }
    case GET_ALL_SHIPMENT_PROPOSAL_FAILURE:
      return {
        ...state,
        shipmentProposals: [],
        shipmentProposalsLoading: false,
      }

    // ADD NEGOTIABLE PRICE
    case ADD_NEGOTIABLE_PRICE:
      return {
        ...state,
        createNegotiablePriceSuccess: false,
        createNegotiablePriceError: false,
      }

    case ADD_NEGOTIABLE_PRICE_SUCCESS:
      return {
        ...state,
        salesEngine: action.payload,
        createNegotiablePriceSuccess: true,
      }

    case ADD_NEGOTIABLE_PRICE_FAILURE:
      return {
        ...state,
        createNegotiablePriceError: true,
      }

    // ADD SURVEY OPTION
    case ADD_SURVEY_OPTION:
      return {
        ...state,
        addSurveyOptionSuccess: false,
        addSurveyOptionError: false,
      }

    case ADD_SURVEY_OPTION_SUCCESS:
      return {
        ...state,
        salesEngine: action.payload,
        addSurveyOptionSuccess: true,
      }

    case ADD_SURVEY_OPTION_FAILURE:
      return {
        ...state,
        addSurveyOptionError: true,
      }

    // ADD SURVEY OPTION
    case SKIP_SHIPMENT:
      return {
        ...state,
        skipShipmentSuccess: false,
        skipShipmentError: false,
      }

    case SKIP_SHIPMENT_SUCCESS:
      return {
        ...state,
        salesEngine: action.payload,
        skipShipmentSuccess: true,
        skipShipmentCount: state.skipShipmentCount + 1
      }

    case SKIP_SHIPMENT_FAILURE:
      return {
        ...state,
        skipShipmentError: true,
      }

    // request agent
    case REQUEST_AGENT:
      return {
        ...state,
        isLoading: true,
        requestAgentSuccess: false,
        requestAgentError: false,
      }

    case REQUEST_AGENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requestAgentSuccess: true,
        salesEngine: action.payload,
      }

    case REQUEST_AGENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestAgentError: true,
      }

    // assign agent
    case ASSIGN_AGENT:
      return {
        ...state,
        isLoading: true,
        assignAgentSuccess: false,
        assignAgentError: false,
      }

    case ASSIGN_AGENT_SUCCESS:
      return {
        ...state,
        assignAgentSuccess: true,
        isLoading: false,
        assignAgentError: false,
        salesEngineByAgent: salesEngineByAgent.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              agentRequested: action.payload.agentRequested,
              agent: action.payload.agent,
            }
          }
          return item
        }),
      }

    case ASSIGN_AGENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        assignAgentSuccess: false,
        assignAgentError: true,
      }

    //get sales engine by agent
    case GET_SALES_ENGINE_BY_AGENT:
      let loadAgentDetails = {
        isAgentLoading: true,
        salesEngineByAgent: [],
        agentTotal: 0,
      }
      if(action.isSearchAction) {
        loadAgentDetails = {}
      }
      return {
        ...state,
        ...loadAgentDetails,
        getByAgentSuccess: false,
        getByAgentError: false,
      }
    case GET_SALES_ENGINE_BY_AGENT_SUCCESS:
      return {
        ...state,
        isAgentLoading: false,
        getByAgentSuccess: true,
        getByAgentError: false,
        salesEngineByAgent: action.payload.getSalesEngineByAgent.items,
        agentTotal: action.payload.getSalesEngineByAgent.total,
      }
    case GET_SALES_ENGINE_BY_AGENT_FAILURE:
      return {
        ...state,
        isAgentLoading: false,
        getByAgentSuccess: false,
        getByAgentError: true,
        agentTotal: 0,
      }

    case CLEAR_SALES_ENGINE_DASHBOARD_FLAG:
      return {
        ...state,
        getByAgentSuccess: false,
        getByAgentError: false,
        isAgentLoading: false,
        getBySurveyorSuccess: false,
        getBySurveyorError: false,
        getByShipperSuccess: false,
        getByShipperError: false,
      }

    // GET DOCUMENTS LINKS
    case GET_DOCUMENT_LINKS:
      return {
        ...state,
      }

    case GET_DOCUMENT_LINKS_SUCCESS:
      return {
        ...state,
        salesEngine: { ...action.payload, paymentInformation: salesEngine.paymentInformation },
      }

    case GET_DOCUMENT_LINKS_FAILURE:
      return {
        ...state,
      }

    // GET DOCUMENTS LINKS
    case ADD_BOAT_SHIPMENT_LOCATION:
      return {
        ...state,
        shipmentLocationAddLoading: true,
        shipmentLocationAddedSuccess: false,
        shipmentLocationAddedError: false,
      }

    case ADD_BOAT_SHIPMENT_LOCATION_SUCCESS:
      return {
        ...state,
        salesEngine: action.payload,
        shipmentLocationAddLoading: false,
        shipmentLocationAddedSuccess: true,
        shipmentLocationAddedCount: state.shipmentLocationAddedCount + 1,
      }

    case ADD_BOAT_SHIPMENT_LOCATION_FAILURE:
      return {
        ...state,
        shipmentLocationAddLoading: false,
        shipmentLocationAddedError: true,
        shipmentLocationAddedSuccess: false,
      }

    case GET_COST_ESTIMATE:
      return {
        ...state,
        costEstimateSuccess: false,
        costEstimateError: false,
      }

    case GET_COST_ESTIMATE_SUCCESS:
      return {
        ...state,
        costEstimateSuccess: true,
        costEstimation: action.payload,
      }

    case GET_COST_ESTIMATE_FAILURE:
      return {
        ...state,
        costEstimateError: true,
      }

    // CLEAR ALL FLAGS

    case CLEAR_CREATE_SALES_ENGINE_FLAG:
      return {
        ...state,
        createSuccess: false,
        createError: false,
        createSalesEngineLoading: false,
      }

    case CLEAR_GET_SINGLE_SALES_ENGINE_FLAG:
      return {
        ...state,
        getSalesEngineSuccess: false,
        getSalesEngineError: false,
      }

    case CLEAR_SALES_ENGINE_MY_BOAT_FLAG:
      return {
        ...state,
        createNegotiablePriceSuccess: false,
        createNegotiablePriceError: false,
        addSurveyOptionSuccess: false,
        addSurveyOptionError: false,
      }

    case CLEAR_AGENT_FLAG:
      return {
        ...state,
        assignAgentSuccess: false,
        assignAgentError: false,
        requestAgentSuccess: false,
        requestAgentError: false,
        agentSuccess: false,
        agentError: false,
      }

    case CLEAR_SALES_ENGINE_PAYMENT_FLAG:
      return {
        ...state,
        isAgreementChecked: false,
      }

    case CLEAR_SALES_ENGINE_SHIPMENT_FLAG:
      return {
        ...state,
        skipShipmentSuccess: false,
        skipShipmentError: false,
        shipmentLocationAddedSuccess: false,
        shipmentLocationAddedError: false,
      }

    // CLEAR ALL DASHBOARD FLAGS

    case CLEAR_SALES_ENGINES:
      return {
        ...state,
        salesEngines: [],
      }

    case CLEAR_SALES_ENGINE_SURVEYOR_DASHBOARD_FLAG:
      return {
        ...state,
        surveyorAcceptSuccess: false,
        surveyorAcceptError: false,
        surveyorDeclineSuccess: false,
        surveyorDeclineError: false,
      }

    case CLEAR_REPORT_FLAG:
      return {
        ...state,
        submitReportSuccess: false,
        submitReportError: false,
        createReportSuccess: false,
        createReportFailure: false,
        updateReportSuccess: false,
        updateReportFailure: false,
        getReportSuccess: false,
        getReportError: false,
      }

    case CLEAR_SALES_ENGINE_SHIPPER_DASHBOARD_FLAG:
      return {
        ...state,
        shipperAcceptShipperRequestSuccess: false,
        shipperAcceptShipperRequestError: false,
        shipperDeclineShipperRequestSuccess: false,
        shipperDeclineShipperRequestError: false,
        addShipmentProposalSuccess: false,
        addShipmentProposalError: false,
        startShipmentSuccess: false,
        startShipmentFailure: false,
        completeShipmentSuccess: false,
        completeShipmentFailure: false,
      }

    case START_SHIPMENT:
      return {
        ...state,
        startShipmentSuccess: false,
        startShipmentFailure: false,
      }
    case START_SHIPMENT_SUCCESS:
      return {
        ...state,
        startShipmentSuccess: true,
        startShipmentFailure: false,
        salesEngine: action.payload.shipmentStart,
      }
    case START_SHIPMENT_FAILURE:
      return {
        ...state,
        startShipmentSuccess: false,
        startShipmentFailure: true,
      }

    case COMPLETE_SHIPMENT:
      return {
        ...state,
        completeShipmentSuccess: false,
        completeShipmentFailure: false,
      }
    case COMPLETE_SHIPMENT_SUCCESS:
      return {
        ...state,
        completeShipmentSuccess: true,
        completeShipmentFailure: false,
        salesEngine: action.payload.shipmentComplete,
      }
    case COMPLETE_SHIPMENT_FAILURE:
      return {
        ...state,
        completeShipmentSuccess: false,
        completeShipmentFailure: true,
      }

    case ADD_SHIPPER_DOCUMENTS:
      return {
        ...state,
        shipperDocumentSuccess: false,
        shipperDocumentError: false,
      }
    case ADD_SHIPPER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        shipperDocumentSuccess: true,
      }
    case ADD_SHIPPER_DOCUMENTS_FAILURE:
      return {
        ...state,
        shipperDocumentError: true,
      }

    case CLEAR_SHIPPER_DOCUMENT_FLAG:
      return {
        ...state,
        shipperDocumentSuccess: false,
        shipperDocumentError: false,
      }

    case UPDATE_SHIPPER_DOCUMENTS:
      return {
        ...state,
        shipperDocumentSuccess: false,
        shipperDocumentError: false,
      }
    case UPDATE_SHIPPER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        shipperDocumentSuccess: true,
      }

    case UPDATE_SHIPPER_DOCUMENTS_FAILURE:
      return {
        ...state,
        shipperDocumentError: true,
      }

    case DISCARD_AGENT:
      return {
        ...state,
        isLoading: true,
      }

    case DISCARD_AGENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        salesEngine: action.payload,
        discardSuccess: true,
      }

    case DISCARD_AGENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        discardError: true,
      }

    case CLEAR_DISCARD_AGENT_FLAG:
      return {
        ...state,
        discardError: false,
        discardSuccess: false,
      }

    case GET_SALES_ENGINE_AGENTS:
      return {
        ...state,
        isLoading: true,
        isAgentsLoading: true,
        agents: [],
      }

    case GET_SALES_ENGINE_AGENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAgentsLoading: false,
        agents: action.payload.items,
      }

    case GET_SALES_ENGINE_AGENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAgentsLoading: false,
        agents: [],
      }

    case PRINT_PAYMENT_RECEIPT:
      return {
        ...state,
        paymentReceiptUrl: '',
        isLoading: true,
      }

    case PRINT_PAYMENT_RECEIPT_SUCCESS:
      window.open(action.payload, '_blank')
      return {
        ...state,
        paymentReceiptUrl: action.payload,
        isLoading: false,
        getPaymentReceiptSuccess: true,
      }

    case PRINT_PAYMENT_RECEIPT_FAILURE:
      return {
        ...state,
        paymentReceiptUrl: '',
        isLoading: false,
        getPaymentReceiptError: true,
      }

    case GET_SALES_ENGINE_STATIC_CONTENT:
      return {
        ...state,
      }

    case GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS:
      return {
        ...state,
        salesEngineStaticContents: action.payload,
      }

    case GET_SALES_ENGINE_STATIC_CONTENT_FAILURE:
      return {
        ...state,
      }

    case GET_SURVEY_DOCUMENT_DATA:
      return {
        ...state,
      }

    case GET_SURVEY_DOCUMENT_DATA_SUCCESS:
      return {
        ...state,
        surveyDocument: action.payload,
      }

    case GET_SURVEY_DOCUMENT_DATA_FAILURE:
      return {
        ...state,
      }

    case GET_SALES_ENGINE_BY_BOAT:
      return {
        ...state,
        salesEnginesBoats: [],
        isLoading: true,
      }

    case GET_SALES_ENGINE_BY_BOAT_SUCCESS:
      return {
        ...state,
        salesEnginesBoats: action.payload.items,
        totalSalesEnginesBoats: action.payload.total,
        isLoading: false,
      }

    case GET_SALES_ENGINE_BY_BOAT_FAILURE:
      return {
        ...state,
        salesEnginesBoats: [],
        isLoading: false,
      }

    case BUYER_REVIEW_SURVEY_REPORT:
      return {
        ...state,
        isLoading: true,
      }

    case BUYER_REVIEW_SURVEY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        salesEngine: action.payload,
        reviewReportSuccess: true,
      }

    case BUYER_REVIEW_SURVEY_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        reviewReportFailure: true,
      }

    case CLEAR_REVIEW_REPORT_FLAG:
      return {
        ...state,
        reviewReportFailure: false,
        reviewReportSuccess: false,
      }

    case SALES_ENGINE_STEP_CHANGE:
      return {
        ...state,
        isLoading: true,
      }

    case SALES_ENGINE_STEP_CHANGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        salesEngine: action.payload,
      }

    case SALES_ENGINE_STEP_CHANGE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case GET_DEALERS_SALES_ENGINE:
      return {
        ...state,
        isDealersSalesEngineLoading: true,
      }

    case GET_DEALERS_SALES_ENGINE_SUCCESS:
      return {
        ...state,
        isDealersSalesEngineLoading: false,
        dealersSalesEngine: action.payload.getDealersManufacturers.items,
        dealersSalesEngineTotal: action.payload.getDealersManufacturers.total,
      }

    case GET_DEALERS_SALES_ENGINE_FAILURE:
      return {
        ...state,
        isDealersSalesEngineLoading: false,
      }

    case GET_SHIPPER_DOCUMENTS:
      return {
        ...state,
        shipperDocumentLoading: true,
        shipperDocument: {},
        getDocumentSuccess: false,
      }

    case GET_SHIPPER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        shipperDocumentLoading: false,
        shipperDocument: action.payload.getShipperDocuments,
        getDocumentSuccess: true,
      }

    case GET_SHIPPER_DOCUMENTS_FAILURE:
      return {
        ...state,
        shipperDocumentLoading: false,
        shipperDocument: {},
      }

    case CLEAR_SHIPPER_DOCUMENTS_FLAG:
      return {
        ...state,
        shipperDocumentLoading: false,
        getDocumentSuccess: false,
      }

    case UPDATE_STOCK_QUANTITY:
      return {
        ...state,
        isLoading: true,
      }

    case UPDATE_STOCK_QUANTITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        salesEngine: action.payload,
      }

    case UPDATE_STOCK_QUANTITY_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case SALES_ENGINE_PAYMENT_DETAILS:
      return {
        ...state,
        payments: [],
        // isLoading: true,
        transectionsSuccess: false,
        transectionsError: false
      }

    case SALES_ENGINE_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
        // isLoading: false,
        transectionsSuccess: true,
        transectionsError: false
      }
    case SALES_ENGINE_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        payments: [],
        // isLoading: false,
        transectionsSuccess: false,
        transectionsError: true
      }

    case GET_COMMISSIONS: {
      return {
        ...state,
        commissions: {
          loading: true,
          success: false,
          failure: false,
          data: null,
        },
      }
    }

    case GET_COMMISSIONS_SUCCESS: {
      return {
        ...state,
        commissions: {
          ...state.commissions,
          success: true,
          data: action.payload,
        },
      }
    }

    case GET_COMMISSIONS_FAILURE: {
      return {
        ...state,
        commissions: {
          ...state.commissions,
          failure: true,
        },
      }
    }

    case GET_COMMISSIONS__CLEAR: {
      return {
        ...state,
        commissions: {
          ...state.commissions,
          loading: false,
          success: false,
          failure: false,
        },
      }
    }

    default:
      return state
  }
}
