import { useState } from 'react'

export default function ReadMore({ text, maxLength, className }) {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded)
  }

  const truncatedText = text.length > maxLength ? text.substring(0, maxLength) + '...' : text

  return (
    <div>
      <span>{isExpanded ? text : truncatedText}</span>{' '}
      {text.length > maxLength && !isExpanded && (
        <span onClick={toggleReadMore} className={className}>
          read more
        </span>
      )}
    </div>
  )
}
