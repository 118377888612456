import React, { useState, useContext, memo, useEffect } from 'react'

import { Grid } from '@material-ui/core'
import moment from 'moment'

import UserContext from '../../UserContext'
import { BidPopup } from '../../helpers/bidPopup'
import { AuctionBidBox } from '../modal/AuctionBidBox'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { ButtonComponent } from '../form/Button'
import { timeLeftForAuction, checkDateIsAfterCurrent, checkDateIsBeforeCurrent } from '../../helpers/dateTimeHelper'
import { timeEnum, auctionRoomStatus, seeAllAuctionBidsAccessibleRoles } from '../../util/enums/enums'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { displayDefaultValue } from '../../helpers/string'
import { cityCountryNameFormatter, getAddress } from '../../helpers/jsxHelper'
import AddDepositModal from './AddDepositModal'

export const AuctionBox = memo(props => {
  const {
    boatAuctionRoom,
    isBidNow,
    bidHandler,
    createAuctionBid,
    isError,
    errorMessage,
    clearErrorMessageShow,
    boat,
    addDepositForBid,
    openDepositModal,
    depositModalHandler,
    showBitNowButton,
  } = props

  const { currentUser } = useContext(UserContext)

  const [bidBoxModal, setBidBoxModal] = useState(false)

  const bidBoxModalHandler = () => setBidBoxModal(!bidBoxModal)

  const [timeLeft, setTimeLeft] = useState(timeLeftForAuction(boatAuctionRoom.endTime))
  const [timeStart, setTimeStart] = useState(timeLeftForAuction(boatAuctionRoom?.startTime))

  const isAuth = getLocalStorageItem('isAuthenticated')

  const isComingSoon = boatAuctionRoom?.status === auctionRoomStatus.COMING_SOON
  const dayDuration = moment(boatAuctionRoom?.endTime).diff(moment(boatAuctionRoom?.startTime), 'days')
  const hoursDuration = moment(boatAuctionRoom?.endTime).diff(moment(boatAuctionRoom?.startTime), 'hours')
  const minutesDuration = moment(boatAuctionRoom?.endTime).diff(moment(boatAuctionRoom?.startTime), 'minutes')
  const duration = dayDuration ? `${dayDuration} Days` : hoursDuration ? `${hoursDuration} Hours` : `${minutesDuration} Minutes`
  const {
    commonAddress: { city, country },
  } = getAddress(boatAuctionRoom?.auctionWinner?.address) || {}

  useEffect(() => {
    const timer = setTimeout(() => {
      isComingSoon
        ? setTimeStart(timeLeftForAuction(boatAuctionRoom?.startTime))
        : setTimeLeft(timeLeftForAuction(boatAuctionRoom.endTime))
    }, 1000)
    return () => clearTimeout(timer)
  })

  return (
    <>
      <BidPopup
        open={isBidNow}
        onClose={bidHandler}
        submitValues={createAuctionBid}
        auctionRoom={boatAuctionRoom}
        isError={isError}
        errorMessage={errorMessage}
        clearErrorMessageShow={clearErrorMessageShow}
        currentUser={currentUser}
      />

      <AddDepositModal
        open={openDepositModal}
        close={depositModalHandler}
        clearErrorMessageShow={clearErrorMessageShow}
        boatAuctionRoom={boatAuctionRoom}
        addDepositForBid={addDepositForBid}
      />

      <div
        className="boat--inner--bid--login--section w-85vw boat--inner--bid--section ml-auto mr-auto"
      >
        <Grid container className="width-100 inner--auction--section">
          <Grid item sm={12}>
            <div className="boat-inner-auction-content">
              <div className="boat-inner-auction-action">
                {/* first section start */}
                <div
                  className={`${
                    isComingSoon ? 'coming--auction--bid--title--div' : 'auction--bid--div'
                  } d-flex flex-column justify-content-start`}
                >
                  <div className="d-flex align-items-center">
                    <div className="in--auction--title--img">
                      <img loading="lazy" src={require('../../assets/images/boatInner/auction.png')} alt="Auction" className="h-100" />
                    </div>
                    <div className={`${isComingSoon ? 'in--auction-comming-soon' : ''} in--auction--title`}>
                      <span>{displayDefaultValue(boatAuctionRoom?.status)}</span>
                    </div>
                  </div>
                </div>
                {/* first section start */}

                {/* second section start */}
                <div
                  className={`${isComingSoon ? 'coming--auction--bid--time--div' : 'auction--bid--div auction-bid-div-center'}`}
                >
                  {boatAuctionRoom && (
                    <div className="d-flex justify-content-center">
                      <span className="d-flex justify-content-center align-items-center inner--auction--room--time--left--title inner--auction--room--main--time--left--title">
                        {''}
                        {boatAuctionRoom?.status === auctionRoomStatus.COMING_SOON ? 'STARTING TIME' : 'TIME LEFT'}
                      </span>

                      <div className="d-flex flex-column justify-content-center align-items-center inner--bid--time--section">
                        <span className="inner--bid--time--info">
                          {isComingSoon ? timeStart[timeEnum.DAYS.toLowerCase()] : timeLeft[timeEnum.DAYS.toLowerCase()]}
                        </span>
                        <span className="inner--bid--time--info--title">{timeEnum.DAYS.toUpperCase()}</span>
                      </div>

                      <div className="d-flex flex-column justify-content-center align-items-center inner--bid--time--section">
                        <span className="inner--bid--time--info">
                          {isComingSoon ? timeStart[timeEnum.HOURS.toLowerCase()] : timeLeft[timeEnum.HOURS.toLowerCase()]}
                        </span>
                        <span className="inner--bid--time--info--title">{timeEnum.HOURS.toUpperCase()}</span>
                      </div>

                      <div className="d-flex flex-column justify-content-center align-items-center inner--bid--time--section">
                        <span className="inner--bid--time--info">
                          {isComingSoon ? timeStart[timeEnum.MINUTES.toLowerCase()] : timeLeft[timeEnum.MINUTES.toLowerCase()]}
                        </span>
                        <span className="inner--bid--time--info--title">{timeEnum.MINUTES.toUpperCase()}</span>
                      </div>

                      <div className="d-flex flex-column justify-content-center align-items-center inner--bid--time--section inner--bid--time--last--section">
                        <span className="inner--bid--time--info">
                          {isComingSoon ? timeStart[timeEnum.SECONDS.toLowerCase()] : timeLeft[timeEnum.SECONDS.toLowerCase()]}
                        </span>
                        <span className="inner--bid--time--info--title">{timeEnum.SECONDS.toUpperCase()}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* second section end */}

                {/* third section start */}
                <div className="auction--bid--div">
                  {seeAllAuctionBidsAccessibleRoles.includes(boatAuctionRoom?.status) && (
                    <div className="d-flex align-items-center auction-bid-div-center-first justify-content-end">
                      <div>
                        {currentUser &&
                          boat.seller &&
                          currentUser.id !== boat.seller.id &&
                          checkDateIsBeforeCurrent(boatAuctionRoom.startTime) &&
                          checkDateIsAfterCurrent(boatAuctionRoom.endTime) &&
                          showBitNowButton && (
                            <div className="d-flex mb-4">
                              <ButtonComponent
                                className="h-auto as--view--outline btn--padding btn--font auction--bid--now--button mr-3"
                                onClick={depositModalHandler}
                              >
                                {boatAuctionRoom.depositAdded ? 'DEPOSIT ADDED' : 'ADD DEPOSIT'}
                              </ButtonComponent>

                              <ButtonComponent
                                className="h-auto as--view--outline btn--padding btn--font auction--bid--now--button"
                                onClick={bidHandler}
                                disabled={
                                  !checkDateIsBeforeCurrent(boatAuctionRoom.startTime) ||
                                  !checkDateIsAfterCurrent(boatAuctionRoom.endTime) ||
                                  !boatAuctionRoom.depositAdded
                                }
                              >
                                {'BID NOW'}
                              </ButtonComponent>
                            </div>
                          )}
                        <div className="d-flex justify-content-end flex-column">
                          <div>
                            {boatAuctionRoom?.auctionWinner && (
                              <div className="boat-inner-auction-action--winner">
                                <span className="boat-inner-auction-action--winner-name">
                                  Winner : {boatAuctionRoom?.auctionWinner?.firstName}
                                </span>
                                <span className="boat-inner-auction-action--winner-location">
                                  ({cityCountryNameFormatter(city, country)})
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="d-flex justify-content-end auction-bid-div-center-first">
                            <span className="see--other--bids cursor-pointer mr-0" onClick={bidBoxModalHandler}>
                              {'SEE OTHER BIDS'} ({boatAuctionRoom.auctionBids?.length})
                            </span>
                            <AuctionBidBox open={bidBoxModal} close={bidBoxModalHandler} boatAuctionRoom={boatAuctionRoom} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* third section end */}
              </div>
              <CurrencyContextConsumer>
                {({ currentCurrency }) => (
                  <div className="boat-inner-auction-detail">
                    <div className="auction-detail-inner">
                      <h6 className="title-sm mb-0 title-description">{'Bid Duration'}</h6>
                      <p className="sub-title-sm inner--auction--bid--duration">
                        {boatAuctionRoom?.auctionWinner ? duration : boatAuctionRoom.bidDuration}
                      </p>
                    </div>

                    <div className="auction-detail-inner">
                      <h6 className="title-sm mb-0 title-description">{'Original Price'}</h6>
                      <p className="sub-title-sm">
                        {boatAuctionRoom.boat && getConvertedPrice(boatAuctionRoom.boat.price, currentCurrency)}
                      </p>
                    </div>

                    <div className="auction-detail-inner">
                      <h6 className="title-sm mb-0 title-description">{'Start Price'}</h6>
                      <p className="sub-title-sm">{getConvertedPrice(boatAuctionRoom.startPrice, currentCurrency)}</p>
                    </div>

                    <div className="auction-detail-inner">
                      <h6 className="title-sm mb-0 title-description">{'Min. Raised Amount'}</h6>
                      <p className="sub-title-sm">{getConvertedPrice(boatAuctionRoom.minimumRaisedAmount, currentCurrency)}</p>
                    </div>

                    <div className="auction-detail-inner">
                      <h6 className="title-sm mb-0 title-description">
                        {boatAuctionRoom?.auctionWinner ? 'Auction Winning Price' : 'Auction Current Bid'}
                      </h6>
                      <p className="sub-title-sm">{getConvertedPrice(boatAuctionRoom.auctionCurrentBid, currentCurrency)}</p>
                    </div>
                  </div>
                )}
              </CurrencyContextConsumer>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
})
