import React, { useState } from 'react'
import { Grid } from '@material-ui/core'

import { CurrencyContextConsumer } from '../../CurrencyContext'
import { CommonModal } from '../modal/commonModal'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'

export const SurveyorPaymentDetailPopup = props => {
  const { open, onClose, salesEngine, isShipper, currentUserId } = props
  const total = salesEngine?.surveyor?.pricePerFt * salesEngine?.boat?.lengthInFt
  const boatLength = salesEngine?.boat?.lengthInFt
  const shipperProposal =
    isShipper &&
    salesEngine?.shipperRequested?.length &&
    salesEngine?.shipperRequested.find(item => item?.shipper?.id === currentUserId)
  const getShipperPaymentFee = (price, currentCurrency) => {
    const transferFee = (price * 2.5) / 100
    return getConvertedPrice(transferFee, currentCurrency)
  }
  const shipperAssigned = isShipper && salesEngine?.paymentInformation?.shipperTotalPrice
  const totalShipmentData = (price, currentCurrency) => {
    const transferFee = (price * 2.5) / 100
    return getConvertedPrice(transferFee + price, currentCurrency)
  }
  return (
    <CommonModal
      open={open}
      close={onClose}
      title="Payment Details"
      className="inner--bid--popup--section section--detail-pop-up"
    >
      {isShipper ? (
        <div>
          <CurrencyContextConsumer>
            {({ currentCurrency }) => (
              <div className="section--detail-pop-up-detail">
                <p>
                  <span className="bold-label">Proposal Amount</span>
                  <span className="bold-value">
                    {getConvertedPrice(salesEngine?.paymentInformation?.shipperSubtotalPrice, currentCurrency)}
                  </span>
                </p>
                <p>
                  <span className="bold-label">Payment Transfer Fee</span>
                  <span className="bold-value">
                    {getConvertedPrice(salesEngine?.paymentInformation?.paymentTransferFee / 100, currentCurrency)}
                  </span>
                </p>
                <p>
                  <span className="bold-label">Total Amount</span>
                  <span className="bold-value request--payment--info--text">
                    {getConvertedPrice(salesEngine?.paymentInformation?.shipperTotalPrice, currentCurrency)}
                  </span>
                </p>
              </div>
            )}
          </CurrencyContextConsumer>
        </div>
      ) : (
        <div className="auction-custom-ui">
          <CurrencyContextConsumer>
            {({ currentCurrency }) => (
              <div className="section--detail-pop-up-detail">
                <p>
                  <span className="bold-label">Boat Length</span>
                  <span className="bold-value">{boatLength} ft</span>{' '}
                </p>
                <p>
                  <span className="bold-label">Survey per feet</span>
                  <span className="bold-value">{getConvertedPrice(salesEngine?.surveyor?.pricePerFt, currentCurrency)}</span>
                </p>
                <p>
                  <span className="bold-label">
                    Survey cost <span className="bold-label-wrap">(Per feet price x Boat Length)</span>{' '}
                  </span>
                  <span className="bold-value request--payment--info--text">{getConvertedPrice(total, currentCurrency)}</span>
                </p>
              </div>
            )}
          </CurrencyContextConsumer>
        </div>
      )}
    </CommonModal>
  )
}
