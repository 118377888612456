import React, { Component } from 'react'
import Rating from 'react-rating'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { Progress } from 'react-sweet-progress'

import '../../containers/rentInner/rentInner.scss'
import { percentageFormate } from '../../util/utilFunctions'
import RatingComponent from '../rating/Rating'

class InnerRattingProfile extends Component {
  countRatingPercentage = (star, total) => {
    return star && (100 * star) / total
  }

  renderProgressBar = (star, total) => {
    return (
      <div className="md-progress-profile mt-3">
        <div
          className="progress-bar-profile"
          role="progressbar"
          style={{ width: this.countRatingPercentage(star, total) }}
        ></div>
      </div>
    )
  }

  renderRatingByReviews = () => {
    const { reviews } = this.props

    let data = {
      total: reviews.length,
      stars: {
        oneStar: 0,
        twoStar: 0,
        threeStar: 0,
        fourStar: 0,
        fiveStar: 0,
      },
    }

    reviews &&
      reviews.length > 0 &&
      reviews.map(review => {
        if (0 > review.rating && review.rating <= 1) {
          data.stars.oneStar += 1
        } else if (review.rating > 1 && review.rating <= 2) {
          data.stars.twoStar += 1
        } else if (review.rating > 2 && review.rating <= 3) {
          data.stars.threeStar += 1
        } else if (review.rating > 3 && review.rating <= 4) {
          data.stars.fourStar += 1
        } else if (review.rating > 4 && review.rating <= 5) {
          data.stars.fiveStar += 1
        }
      })

    return (
      <div className="user--profile--right--rating--div">
        <div className="d-flex align-items-center">
          <div className="text-grey d-flex align-items-center">
            {/* <i className="adam-star-filled mr-3 rating-clr"></i> */}
            <span className="mr-3">
              <h5 className="font-weight-400 font-16 rentInner-userTextH4 dark-silver text-center mb-0">5</h5>
            </span>
          </div>
          {this.renderProgressBar(data.stars.fiveStar, data.total)}
        </div>
        <div className="d-flex align-items-center">
          <div className="text-grey d-flex align-items-center">
            {/* <i className="adam-star-filled mr-3 rating-clr"></i> */}
            <span className="mr-3">
              <h5 className="font-weight-400 rentInner-userTextH4 dark-silver text-center mb-0 font-16">4</h5>
            </span>
          </div>

          {this.renderProgressBar(data.stars.fourStar, data.total)}
        </div>
        <div className="d-flex align-items-center">
          <div className="text-grey d-flex align-items-center">
            {/* <i className="adam-star-filled mr-3 rating-clr"></i> */}
            <span className="mr-3">
              <h5 className="font-weight-400 rentInner-userTextH4 dark-silver text-center mb-0 font-16">3</h5>
            </span>
          </div>

          {this.renderProgressBar(data.stars.threeStar, data.total)}
        </div>
        <div className="d-flex align-items-center">
          <div className="text-grey d-flex align-items-center">
            {/* <i className="adam-star-filled mr-3 rating-clr"></i> */}
            <span className="mr-3">
              <h5 className="font-weight-400 rentInner-userTextH4 dark-silver text-center mb-0 font-16">2</h5>
            </span>
          </div>

          {this.renderProgressBar(data.stars.twoStar, data.total)}
        </div>
        <div className="d-flex align-items-center">
          <div className="text-grey d-flex align-items-center">
            {/* <i className="adam-star-filled mr-3 rating-clr"></i> */}
            <span className="mr-3">
              <h5 className="font-weight-400 rentInner-userTextH4 dark-silver text-center mb-0 font-16">1</h5>
            </span>
          </div>

          {this.renderProgressBar(data.stars.oneStar, data.total)}
        </div>
      </div>
    )
  }

  render() {
    const { data } = this.props
    return (
      <div className="review-summary-profile">
        <span className="user-profile-review-count">{data && percentageFormate(data.averageRating)}</span>
        <span className="review-star lg d-block mb-18">
          <RatingComponent className="rating-clr" rating={data && data.averageRating} />
        </span>
        <h5 className="font-weight-400 rentInner-userTextH4 dark-silver text-center font-16 mb-0 user--profile--based--on--review">
          Based on {data && data.count} Reviews
        </h5>
        <hr className="user--profile--based--review--hr" />

        {this.renderRatingByReviews()}
      </div>
    )
  }
}

export default InnerRattingProfile
