import {
  CREATE_ARTICLE,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FAILURE,
  GET_USER_ALL_ARTICLES,
  GET_USER_ALL_ARTICLES_FAILURE,
  GET_USER_ALL_ARTICLES_SUCCESS,
  ERROR_MESSAGE_SHOW,
  GET_SINGLE_ARTICLE,
  GET_SINGLE_ARTICLE_FAILURE,
  GET_SINGLE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAILURE,
  DELETE_ARTICLE,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAILURE,
  CHANGE_ARTICLE_STATUS,
  CHANGE_ARTICLE_STATUS_SUCCESS,
  CHANGE_ARTICLE_STATUS_FAILURE,
  CLEAR_ARTICLE_FLAG,
  NOTIFICATION_MESSAGE,
  INFO_NOTIFICATION_MESSAGE,
  ERROR_NOTIFICATION_MESSAGE,
} from '../actionTypes'
import {
  createArticle,
  getAllArticles,
  getArticleById,
  updateArticle,
  deleteArticle,
  articleChangeStatus,
} from '../../graphql/articleSchema'
import { put, takeLatest, all } from 'redux-saga/effects'
import { notificationEnum, dynamicError } from '../../util/enums/notificationEnum'
import { query, mutation } from './apiHelper'

function createArticleApi(data) {
  const article = { ...data }
  return mutation(createArticle, { input: article })
}

function* createArticleData(action) {
  try {
    const res = yield createArticleApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
      yield put({ type: CREATE_ARTICLE_FAILURE, e: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    } else if (res.data) {
      yield put({ type: CREATE_ARTICLE_SUCCESS, payload: res })
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.ARTICLE_CREATE })
    }
  } catch (e) {
    yield put({ type: ERROR_MESSAGE_SHOW, payload: e })
    yield put({ type: CREATE_ARTICLE_FAILURE, e })
  }
}

function* createArticleSaga() {
  yield takeLatest(CREATE_ARTICLE, createArticleData)
}

function getAllArticlesApi(data) {
  return query(getAllArticles, data)
}

function* getAllArticlesData(action) {
  try {
    const data = yield getAllArticlesApi(action.payload)
    yield put({ type: GET_USER_ALL_ARTICLES_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_USER_ALL_ARTICLES_FAILURE, e })
  }
}

function* getAllArticlesSaga() {
  yield takeLatest(GET_USER_ALL_ARTICLES, getAllArticlesData)
}

//get single article

function getSingleArticleApi(data) {
  return query(getArticleById, { id: data.id })
}

function* getSingleArticleData(action) {
  try {
    const res = yield getSingleArticleApi(action.payload)

    if (res?.data?.getArticleById) {
      yield put({ type: GET_SINGLE_ARTICLE_SUCCESS, payload: res.data.getArticleById })
    } else if (res?.errors) {
      yield put({ type: GET_SINGLE_ARTICLE_FAILURE, errors: res.errors })
    }
  } catch (e) {
    yield put({ type: GET_SINGLE_ARTICLE_FAILURE, e })
  }
}

function* getSingleArticleSaga() {
  yield takeLatest(GET_SINGLE_ARTICLE, getSingleArticleData)
}

//get single article

function deleteArticleApi(id) {
  return query(deleteArticle, { id })
}

function* deleteArticleData(action) {
  try {
    const res = yield deleteArticleApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: DELETE_ARTICLE_FAILURE, e: res.errors })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    } else if (res.data) {
      yield put({ type: DELETE_ARTICLE_SUCCESS, payload: res })
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.ARTICLE_DELETE })
    }
  } catch (e) {
    yield put({ type: DELETE_ARTICLE_FAILURE, e })
  }
}

function* deleteArticleSaga() {
  yield takeLatest(DELETE_ARTICLE, deleteArticleData)
}

//update article

function updateArticleApi(data) {
  return mutation(updateArticle, { input: data })
}

function* updateArticleData(action) {
  try {
    const res = yield updateArticleApi(action.payload)
    // console.log(res);
    if (res.errors?.length) {
      yield put({ type: UPDATE_ARTICLE_FAILURE, e: res.errors })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    } else if (res.data) {
      yield put({ type: UPDATE_ARTICLE_SUCCESS, payload: res })
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.ARTICLE_UPDATE })
    }
  } catch (e) {
    yield put({ type: UPDATE_ARTICLE_FAILURE, e })
  }
}

function* updateArticleSaga() {
  yield takeLatest(UPDATE_ARTICLE, updateArticleData)
}

function changeArticleStatusApi(input) {
  return mutation(articleChangeStatus, input)
}

function* changeArticleStatus(action) {
  try {
    const res = yield changeArticleStatusApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: CHANGE_ARTICLE_STATUS_FAILURE, e: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    } else if (res.data) {
      yield put({ type: CHANGE_ARTICLE_STATUS_SUCCESS, payload: res.data.articleChangeStatus })
    }
  } catch (e) {
    yield put({ type: CHANGE_ARTICLE_STATUS_FAILURE, e })
  }
  yield put({ type: CLEAR_ARTICLE_FLAG })
}

function* changeArticleStatusSaga() {
  yield takeLatest(CHANGE_ARTICLE_STATUS, changeArticleStatus)
}

export default function* articleSaga() {
  yield all([
    createArticleSaga(),
    getAllArticlesSaga(),
    getSingleArticleSaga(),
    updateArticleSaga(),
    deleteArticleSaga(),
    changeArticleStatusSaga(),
  ])
}
