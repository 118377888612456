import React, { useEffect, useContext } from 'react'
import { Layout } from '../../components'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { defaultProfileIcon, userRoles, shareModule, noShipperMessage, skeletonType } from '../../util/enums/enums'
import '../../components/dialog/EstimateCost.scss'
import { getUsersByCountry } from '../../redux/actions'
import { redirectToUserProfile, cityCountryNameFormatter } from '../../helpers/jsxHelper'
import SharePopup from '../../components/share/SharePopup'
import { displayDefaultNumericValue } from '../../helpers/string'
import UserContext from '../../UserContext'
import { SkeletonLoader } from '../../components/loader/SkeletonLoader'
import { useDispatch } from 'react-redux'
import ChatUserCreate from '../../components/chats/ChatCreateUse'
import classNames from 'classnames'
import { getId, getOnlineUsers } from '../../util/utilFunctions'
import { SET_ONLINE_USERS } from '../../redux/actionTypes'

const BoatShipper = ({ boatShipperList, getUsersByCountry, isLoading, history, setOnlineUsers, onlineUsers }) => {
  const { match } = useContext(UserContext)
const  dispatch  = useDispatch()
  const [selectedIndex, setSelectedIndex] = React.useState(null)

  useEffect(() => {
    getUsersByCountry({ roleType: userRoles.YACHT_SHIPPER })
  }, [])

  const handleClick = index => {
    setSelectedIndex(selectedIndex !== index ? index : null)
  }

  useEffect(() => {
    boatShipperList?.length && getOnlineUsers(boatShipperList.map(getId), setOnlineUsers);
  }, [boatShipperList]);

  const {
    params: { boatId, country },
  } = match

  return (
    // will continue it later

    <Layout className="boat-shipper boat--shipper w-85vw ml-auto mr-auto adam-top-spa-res">

      <div>
        <div className={`shipper--main--div ${isLoading ? 'd-flex flex-wrap' : ''}`}>
          {isLoading ? (
            <SkeletonLoader type={skeletonType.userInfo} itemsLength={10} count={6} />
          ) : (
            <>
              <div className="shipper--main--title--div">
                <span className="shipper--main--title">AdamSea Shippers list</span>
              </div>
              <Grid container className="inner--boat--shipper--section" spacing={6}>
                {boatShipperList && boatShipperList.length > 0 ? (
                  boatShipperList.map(item => (
                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3} className="boat--shipper--div">
                      <div className="shipper--info--div">
                        <Grid item sm={12} className="d-flex" style={{ flexDirection: "column" }}>
                          <Grid item sm={12} className="shipper--user--img--section">
                            <Grid item sm={6} className='d-flex' style={{ flexDirection: "column", alignItems: "center"}}>
                              <div className="shipper--img position-relative">
                                <img
                                  src={item.image?.url || defaultProfileIcon}
                                  alt="Profile"
                                  className="rounded-circle h-100 w-100"
                                  onClick={() => redirectToUserProfile(item, history)}
                                />
                                <div className="stepper-user-online">
                                  <div className={classNames("online-div-user", {
                                    'disconnect': !onlineUsers[getId(item)],
                                  })}></div>
                                </div>
                              </div>
                              <div>
                                <span
                                  onClick={() => redirectToUserProfile(item, history)}
                                  className="shipper--name font-weight-bold color-black cursor-pointer py-2">
                                  {item.firstName}
                                </span>
                              </div>
                            </Grid>
                            <Grid item sm={6} className='d-flex' style={{ flexDirection: "column", alignItems: "center"}}>
                              {item.companyLogo?.url && (
                                <div className="shipper--company--logo shipper--info">
                                  <img loading="lazy" src={item.companyLogo.url} alt="Logo" className="h-100 w-100" />
                                </div>
                              )}
                              <div>
                                <span className="color-black font-weight-bold boat--shipper--city d-flex align-items-center pb-2">
                                  {item && item.companyName}
                                </span>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid item sm={12} className="shipper--user--img--section">
                            <Grid item sm={6}>
                              <div>
                                <hr className='w-50 m-auto' style={{ borderTop: '1px solid #DDDDDD' }} />
                              </div>
                            </Grid>
                            <Grid item sm={6}>
                              <div>
                                <hr className='w-50 m-auto' style={{ borderTop: '1px solid #DDDDDD' }} />
                              </div>
                            </Grid>
                          </Grid>
                          <Grid item sm={12} className="d-flex shipper--user--img--section">
                            <Grid item sm={6} className="d-flex w-100 shipper--share--div" style={{ alignItems: 'center' }}>
                              <div className="inner-shippers-list">
                                <ChatUserCreate id={item.id} image={item.image?.url} />
                                <SharePopup
                                  handleClick={() => handleClick(item.id)}
                                  index={item.id}
                                  selectedIndex={selectedIndex}
                                  moduleId={item.id}
                                  isShipperList
                                  moduleType={shareModule.USER}
                                />
                              </div>
                              <p className="shipper--country--city color-black pt-2 text-center">
                                {cityCountryNameFormatter(item.address?.[0]?.city, item.address?.[0]?.country)}
                              </p>
                            </Grid>
                            <Grid item sm={6} className='d-flex align-items-center flex-direction-column shipper--share--div'>
                              <div className='d-flex align-items-center'>
                                <img width={14} src={require('../../assets/images/black-star.svg')} alt="star" />
                                <strong className='ml-1' style={{ fontSize: '17px' }}>{displayDefaultNumericValue(item?.reviews?.reviews?.length)}</strong>
                              </div>
                              <span className='shipper--country--city pt-2'>Reviews</span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  ))
                ) : (
                  <Grid item sm={12}>
                    <div className="no-record-found">{noShipperMessage}</div>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

// export default BoatShipper;

const mapStateToProps = state => ({
  boatShipperList: state.boatReducer?.yachtShipper,
  isLoading: state.boatReducer?.isLoading,
  onlineUsers: state.chatReducer.onlineUsers,
})

const mapDispatchToProps = dispatch => ({
  getUsersByCountry: data => dispatch(getUsersByCountry(data)),
  setOnlineUsers: users => dispatch({ type: SET_ONLINE_USERS, payload: users })
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatShipper)
