import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch } from '@material-ui/core'

import { pagination } from '../../../util/enums/enums'
import {
  getUserMarinaStorage,
  deleteMarinaStorage,
  clearMarinaFlag,
  changeMarinaStorageStatus,
} from '../../../redux/actions/marinaAndStorageAction'
import { confirmSubmitHandler } from '../../../helpers/confirmationPopup'
import { DashboardLayout } from '../../../components/layout/dashboardLayout'

import '../../../styles/manageDashboardTableResponsive.scss'
import { viewMarinaDetails } from '../../../helpers/boatHelper'
import { TableCard, Field } from '../../../components'
import { nameFormatter, verifiedCheck } from '../../../helpers/string'
import { ButtonComponent } from '../../../components/form/Button'
import { getPagesValue } from '../../../helpers/jsxHelper'
import { clearSearchDashboard } from '../../../redux/actions'

class ManageMarinaStorage extends Component {
  componentDidMount() {
    const { getUserMarinaStorage, createSuccess, marinaStorage, updateSuccess, clearMarinaFlag } = this.props
    getUserMarinaStorage({
      page: getPagesValue(),
      limit: pagination.PAGE_RECORD_LIMIT,
    })
  }

  editMarina = data => {
    const { history } = this.props
    history.push('add-marina-storage', { id: data })
  }

  static getDerivedStateFromProps(props, state) {
    const { getSuccess, clearMarinaFlag, deleteSuccess, getUserMarinaStorage } = props
    if (deleteSuccess) {
      window.location.reload()
    }

    if (getSuccess) {
      clearMarinaFlag()
    }

    return null
  }

  render() {
    const {
      loading,
      marinaStorage,
      deleteSuccess,
      changeMarinaStorageStatus,
      deleteMarinaStorage,
      totalMarinaStorage,
      getUserMarinaStorage,
      indexSearchData,
      isSearched,
      totalSearchedData,
      clearSearchDashboard,
    } = this.props

    const columns = [
      {
        Header: 'Ad ID',
        accessor: 'adId',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Country',
        accessor: 'address',
        Cell: ({ value }) => <>{value?.length > 0 && value[0].country}</>,
      },
      {
        Header: 'Ad Status',
        accessor: 'adStatus',
        Cell: ({ value }) => (
          <span className={`bg-green-color font-13 text-capitalize m-auto ${verifiedCheck(value)}`}>
            {verifiedCheck(value)}
          </span>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: data => (
          <div className="d-flex justify-content-center">
            <Field
              type="switch"
              checked={data.row.status}
              value={data.row.status}
              onChange={() => changeMarinaStorageStatus({ id: data.original.id, column: 'status', value: !data.row.status })}
            />
          </div>
        ),
      },
      {
        headerClassName: 'sales--engine--view reset-rt-th',
        Cell: data => (
          <div className="d-flex flex-row justify-content-around action">
            <ButtonComponent className="btn mr-2" color="edit" onClick={() => this.editMarina(data.original.id)}>
              Edit
            </ButtonComponent>
            <ButtonComponent className="btn mr-2" color="view" onClick={() => viewMarinaDetails(data.original)}>
              View
            </ButtonComponent>
            <ButtonComponent
              className="btn mr-2"
              color="danger"
              onClick={() => confirmSubmitHandler(deleteMarinaStorage, data.original.id, undefined, 'Are you sure you want to delete this ad? If you choose to delete, it cannot be retrieved again.')}
            >
              Delete
            </ButtonComponent>
          </div>
        ),
      },
    ]
    return (
      <DashboardLayout>
        {columns && (
          <div className="manage-dashboard-table manage--marina-dashboard-storage dashboard--table--main--section dashboard--index--table">
            <TableCard
              isSearch
              title="Manage Marina & Storage"
              button={{ name: 'Add Marina & Storage', url: 'add-marina-storage' }}
              columns={columns}
              data={isSearched ? indexSearchData : marinaStorage}
              loading={loading}
              className="manage--boat--table"
              total={isSearched ? totalSearchedData : totalMarinaStorage}
              action={getUserMarinaStorage}
              refresh={deleteSuccess}
              clearSearchDashboard={clearSearchDashboard}
            />
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.marinaAndStorageReducer && state.marinaAndStorageReducer.loading,
  marinaStorage: state.marinaAndStorageReducer.marinaStorage,
  getSuccess: state.marinaAndStorageReducer && state.marinaAndStorageReducer.getSuccess,
  deleteSuccess: state.marinaAndStorageReducer && state.marinaAndStorageReducer.deleteSuccess,
  createSuccess: state.marinaAndStorageReducer && state.marinaAndStorageReducer.createSuccess,
  updateSuccess: state.marinaAndStorageReducer && state.marinaAndStorageReducer.updateSuccess,
  totalMarinaStorage: state.marinaAndStorageReducer && state.marinaAndStorageReducer.totalMarinaStorage,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  isSearched: state?.dashboardReducer?.isSearched,
})

const mapDispatchToProps = dispatch => ({
  getUserMarinaStorage: data => dispatch(getUserMarinaStorage(data)),
  deleteMarinaStorage: data => dispatch(deleteMarinaStorage(data)),
  clearMarinaFlag: () => dispatch(clearMarinaFlag()),
  changeMarinaStorageStatus: data => dispatch(changeMarinaStorageStatus(data)),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageMarinaStorage)
