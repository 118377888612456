import React, { useState, useEffect } from 'react'
import moment from 'moment'

import './withClearCache.scss'

import packageJson from '../../package.json'
import { ButtonComponent } from '../components/form/Button'
import { getLocalStorageItem, setLocalStorageItem } from '../helpers/storageHelper'

const buildDateGreaterThan = (latestDate, currentDate) => {
  const momLatestDateTime = moment(latestDate)
  const momCurrentDateTime = moment(currentDate)

  return momLatestDateTime.isAfter(momCurrentDateTime)
}

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuild, setIsLatestBuildAvailable] = useState(false)

    useEffect(() => {
      const latestVersion = packageJson.buildDate
      const currentVersion = +getLocalStorageItem('buildDate') || ''

      // check if version is latest
      if (buildDateGreaterThan(latestVersion, currentVersion)) {
        setIsLatestBuildAvailable(true)
      } else if (!currentVersion) {
        setLocalStorageItem('buildDate', latestVersion)
      }
    }, [])

    useEffect(() => {
      isLatestBuild && refreshCacheAndReload()
    }, [isLatestBuild])

    const refreshCacheAndReload = () => {
      setIsLatestBuildAvailable(false)

      if (caches) {
        caches.keys().then(names => {
          for (const name of names) {
            caches.delete(name)
          }
        })
      }

      //update latest version to localStorage
      const latestVersion = packageJson.buildDate
      setLocalStorageItem('buildDate', latestVersion)

      // delete browser cache and hard reload
      window.location.reload(true)
    }

    return (
      <>
        {isLatestBuild && (
          <div class="version-manager-pop-up">
            <p>New version is available, Please update to latest version.</p>
            <ButtonComponent onClick={refreshCacheAndReload} variant="fill">
              Ok
            </ButtonComponent>
          </div>
        )}
        <Component {...props} />
      </>
    )
  }

  return ClearCacheComponent
}

export default withClearCache
