import { gql } from 'apollo-boost'

export const GET_PRESIGNED_URL = gql`
  query GetPresignedUrl($input: GetPresignedUrlInput!) {
    fileUpload_getPresignedUrl(input: $input) {
      key
      url
    }
  }
`

export const CREATE_META_RECORD = gql`
  mutation CreateMetaRecord($input: CreateMetaRecordInput!) {
    fileUpload_createMetaRecord(input: $input)
  }
`
