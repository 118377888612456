import React, { Fragment, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import styles from './AgentInfoCard.module.scss'
import { defaultProfileIcon, userRoles } from '../../util/enums/enums'
import { defaultImage } from '../../util/enums/enums'
import { ShareWithChatAndUser } from '../share/ShareWithChatAndUser'
import { cityCountryNameFormatter, redirectToUserProfile } from '../../helpers/jsxHelper'
import InnerRatingModal from '../reviewRating/InnerRatingModal'
import '../../containers/salesEngine/AgentInfo.scss'
import classNames from 'classnames'
import { getId, getOnlineUsers } from '../../util/utilFunctions'
import { useDispatch, useSelector } from 'react-redux'
import { SET_ONLINE_USERS } from '../../redux/actionTypes'
import ChatCreateUse from '../chats/ChatCreateUse'

export const AgentInfoCard = props => {
  const {
    agent,
    setAgent,
    salesEngine,
    isSingle,
    isAgentCarousel,
    handleChange,
    className,
    handleReSet,
    inListing,
    isShipper,
    isSeller,
    isBuyer,
    history,
    discardAgentProcess,
    isAgentSingle,
    enableReview,
    afterDocumentReview,
    isAgent,
    shipperDocumentUploaded,
  } = props

  const isAgentAssigned = salesEngine.agent && agent ? agent.id === salesEngine.agent.id : false
  const agentType = salesEngine?.seller?.role?.aliasName === userRoles.BOAT_MANUFACTURER ? 'Dealer' : 'Agent'

  const requestedAgent = salesEngine?.agentRequested === agent?.id

  const currentUser = useSelector(state => state.loginReducer.currentUser)
  const onlineUsers = useSelector(state => state.chatReducer.onlineUsers)
  const dispatch = useDispatch()

  useEffect(() => {
    getOnlineUsers([getId(agent)], users => dispatch({ type: SET_ONLINE_USERS, payload: users }))
  }, [agent])

  if (!agent) {
    return null
  }

  return (
    <div className={styles.card}>
      {agent.isVerified && isAgentCarousel && (
        <div className={styles.verifiedIcon}>
          <img src={require('../userProfile/image/verif.541c6b40.svg')} alt="Verified" />
        </div>
      )}

      <div className={styles.cardCol}>
        <div>
          <div className="d-flex justify-content-center align-items-center">
            <div>
              <div className={styles.cardUserPic}>
                <img
                  src={agent.image?.url || defaultProfileIcon}
                  className="h-100 width-100 rounded-circle"
                  alt="Profile"
                  onClick={() => redirectToUserProfile(agent, history)}
                />
                <div className="stepper-user-online">
                  <div className={classNames('online-div-user', { disconnect: !onlineUsers[getId(agent)] })}></div>
                </div>
              </div>
              <div className={styles.userName}>{agent.firstName}</div>
            </div>
            <div className="ml-2">
              <ChatCreateUse bookRentCard id={agent.id} image={agent.image?.url} />
            </div>
          </div>
          <div className={styles.address}>{cityCountryNameFormatter(agent.address[0].city, agent.address[0].country)}</div>
        </div>
      </div>
      <div className={styles.cardCol}>
        <div className={styles.companyLogo}>
          <img loading="lazy" src={agent?.companyLogo?.url || defaultImage} alt="Logo" />
        </div>
        {agent.companyName && <div>{agent.companyName}</div>}

        <div className="mt-3">
          {isSingle && !isShipper && (
            <button
              className={`${
                isAgentAssigned || requestedAgent ? 'selected-btn' : ''
              } btn btn-black rounded-full logo-with-select-btn`}
              onClick={() => {
                !isAgentAssigned && !requestedAgent
                  ? setAgent(agent.id)
                  : discardAgentProcess({ id: salesEngine.id, isCancelled: true })
                handleReSet()
              }}
            >
              {isAgentAssigned ? `Cancel ${agentType}` : requestedAgent ? `Cancel ${agentType}` : `Select ${agentType}`}
            </button>
          )}

          {!inListing && !isShipper && !isSeller && isBuyer && (
            <button
              className={`${isAgentAssigned && 'selected-btn'} btn btn-black rounded-full logo-with-select-btn `}
              onClick={() => {
                handleChange({ id: salesEngine.id })
              }}
            >
              Change Agent
            </button>
          )}

          {!isAgent && afterDocumentReview ? (
            <div className="d-flex align-items-center justify-content-center">
              {enableReview && isBuyer && !shipperDocumentUploaded && (
                <div>
                  <InnerRatingModal
                    initVals={agent.reviews?.reviews?.find(r => getId(currentUser) === getId(r.user))}
                    module={agent}
                    userModule={salesEngine?.boat}
                    subModuleId={salesEngine?.id}
                  />
                </div>
              )}
            </div>
          ) : (
            enableReview &&
            isBuyer && (
              <div>
                <InnerRatingModal
                  initVals={agent.reviews?.reviews?.find(r => getId(currentUser) === getId(r.user))}
                  module={agent}
                  userModule={salesEngine?.boat}
                  subModuleId={salesEngine?.id}
                />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )

  return (
    <Fragment>
      {agent && (
        <div className={`${className} agent-listings ${isSingle ? 'multiple--agent--group' : 'single--agent--group'}`}>
          <div className="stepper-agent-info-div float-right stepper-info-boat">
            <div className="mt--3 pl-3 pr-3 agent--listings--carousel">
              <Grid container spacing={2} className="agent--listings--row">
                <Grid
                  item
                  md={isAgentCarousel ? 8 : isSingle ? 6 : isAgentSingle ? 5 : afterDocumentReview ? 4 : 3}
                  className={isAgentCarousel ? '' : isSingle ? 'agent--first-grid' : 'selected--first-grid'}
                >
                  <div className="agent-info-list-with-image">
                    <div className="stepper-agent-img-div position-relative">
                      <img
                        src={agent.image?.url || defaultProfileIcon}
                        className="h-100 width-100 rounded-circle"
                        alt="Profile"
                        onClick={() => redirectToUserProfile(agent, history)}
                      />
                      <div className="stepper-user-online">
                        <div
                          className={classNames('online-div-user', {
                            disconnect: !onlineUsers[getId(agent)],
                          })}
                        ></div>
                      </div>
                    </div>
                    <div className="agent-info-list">
                      <div className="agent-info-name">
                        {' '}
                        {agent.firstName} {agent.lastName}
                      </div>
                      {agent.address?.length > 0 && (
                        <div className="agent-info-mobile dark-silver">
                          {cityCountryNameFormatter(agent.address[0].city, agent.address[0].country)}
                        </div>
                      )}
                      {agent.isVerified && isAgentCarousel && (
                        <div className="mb-2">
                          <img
                            src={require('../userProfile/image/verified.png')}
                            className="stepper-user-profile-icon-div mr-2"
                            alt="Verified"
                          />
                          <span className="rentInner-userTextH4  font-16 dark-silver">Verified</span>
                        </div>
                      )}
                      <div className="agent-info-icon d-flex">
                        <ShareWithChatAndUser userId={agent.id} imageUrl={agent.image?.url} />
                      </div>
                      {agentType && (
                        <div className="mb-2">
                          <div className="header-user-role dark-silver header--user--role--div">
                            {String(agentType).toUpperCase()}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>

                {!isAgentCarousel && (
                  <Grid
                    item
                    md={afterDocumentReview ? 2 : 3}
                    className={isSingle ? 'agent--second-grid agent--verify' : 'selected--second-grid agent--verify'}
                  >
                    {agent.isVerified && (
                      <div className="agent-div-margin-top">
                        <img
                          src={require('../userProfile/image/verified.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Verified"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Verified</span>
                      </div>
                    )}
                    {agent.email && (
                      <div className="mt-2 mb-0 agent-div-margin-top">
                        <img
                          src={require('../userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Email"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Email address</span>
                      </div>
                    )}
                    {agent.mobileNumber && (
                      <div className="mt-2 mb-0 agent-div-margin-top">
                        <img
                          src={require('../userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Phone Number"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Phone number</span>
                      </div>
                    )}
                  </Grid>
                )}

                <Grid
                  item
                  md={isAgentCarousel ? 4 : isAgentSingle ? 2 : afterDocumentReview ? 3 : 3}
                  className={
                    isAgentCarousel
                      ? ''
                      : isSingle
                      ? 'agent--third-grid'
                      : afterDocumentReview
                      ? 'selected--third-grid d-flex align-items-center justify-content-center align-items-center'
                      : 'selected--third-grid d-flex align-items-center'
                  }
                >
                  <div className="logo-with-select d-flex flex-column">
                    <div className="d-flex justify-content-center">
                      <div className="logo-box logo--align-right no-bg no-border">
                        <img loading="lazy" src={agent?.companyLogo?.url || defaultImage} height={50} width={50} alt="Logo" />
                      </div>
                    </div>
                    {agent.companyName && (
                      <div className="company-name font-16 agent-company-name text-center font-weight-bold">
                        {agent.companyName}
                      </div>
                    )}

                    {!inListing && !isShipper && !isSeller && isBuyer && isAgentAssigned && (
                      <div className="request--accepted">
                        <span className="text-center Verified">Accepted</span>
                      </div>
                    )}

                    {isSingle && !isShipper && (
                      <button
                        className={`${
                          isAgentAssigned || requestedAgent ? 'selected-btn' : ''
                        } btn  btn-sales-engine-secondary logo-with-select-btn`}
                        onClick={() => {
                          !isAgentAssigned && !requestedAgent
                            ? setAgent(agent.id)
                            : discardAgentProcess({ id: salesEngine.id, isCancelled: true })
                          handleReSet()
                        }}
                      >
                        {isAgentAssigned ? `Cancel` : requestedAgent ? 'Cancel' : `Select`}
                      </button>
                    )}
                  </div>
                </Grid>
                {!isAgent && afterDocumentReview ? (
                  <Grid item md={3} className="d-flex align-items-center justify-content-center">
                    {enableReview && isBuyer && !shipperDocumentUploaded && (
                      <div>
                        <InnerRatingModal module={agent} userModule={salesEngine?.boat} />
                      </div>
                    )}
                  </Grid>
                ) : (
                  enableReview &&
                  isBuyer && (
                    <div>
                      <InnerRatingModal module={agent} userModule={salesEngine?.boat} />
                    </div>
                  )
                )}

                {!inListing && !isShipper && !isSeller && isBuyer && (
                  <Grid item xs={isAgentSingle ? 2 : 3}>
                    <div className="logo-with-select">
                      <button
                        className={`${isAgentAssigned && 'selected-btn'} btn  btn-sales-engine-secondary logo-with-select-btn `}
                        onClick={() => {
                          handleChange({ id: salesEngine.id })
                        }}
                      >
                        Change
                      </button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}
AgentInfoCard.defaultProps = {
  isSingle: true,
  className: '',
  inListing: false,
  isShipper: false,
  isAgentSingle: false,
  isAgentCarousel: false,
}
