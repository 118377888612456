import { GET_USER_LIST, SHARE_DETAILS_WITH_USERS } from '../actionTypes'

export const getUserList = data => ({
  type: GET_USER_LIST,
  payload: data,
})

export const shareDetailsWithUsers = data => ({
  type: SHARE_DETAILS_WITH_USERS,
  payload: data,
})
