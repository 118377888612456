import { useEffect, useRef } from 'react'

export default function VideoPlayer({ src, ...videoProps }) {
  const videoRef = useRef(null)
  const playerRef = useRef(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (src && videoRef.current) {
        const isMpd = src.endsWith('.mpd')

        if (isMpd) {
          const dashjs = require('dashjs')
          playerRef.current = dashjs.MediaPlayer().create()
          playerRef.current.initialize(videoRef.current, src, videoProps.autoPlay ?? false)
        } else {
          videoRef.current.src = src

          if (src.endsWith('.mov')) {
            videoRef.current.type = 'video/mp4'
          }
        }
      }

      return () => {
        if (playerRef.current) {
          playerRef.current.reset()
        }
      }
    }
  }, [src])

  return <video {...videoProps} playsInline ref={videoRef} />
}
