import React from 'react'
import { SkeletonComponent } from './SkeletonComponent'

export const SkeletonUserInfo = ({ isVerticle, count, isCircle, itemsLength }) => {
  return [...Array(itemsLength)].map((_, index) => {
    return (
      <div className={`skeleton--user-profile ${isVerticle ? 'skeleton--verticle-profile' : 'skeleton--horizontal-profile'}`}>
        <div className="skeleton--user-icon-div">
          <SkeletonComponent className="skeleton--user-icon" height={80} width={80} circle={isCircle} />
        </div>
        <div className="skeleton--user-content">
          <SkeletonComponent className="skeleton--user-content" height={20} count={count} />
        </div>
      </div>
    )
  })
}

SkeletonUserInfo.defaultProps = {
  count: 4,
  isVerticle: true,
  isCircle: true,
}
