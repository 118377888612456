import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getChatsList, getChatUsersList, getFriendList, getFriendSearchList, getIsFriendView, getIsGroupChatCreateScreen, getIsLeftSideLoader, getUserListSearch } from '../../redux/selector/chat'
import { LeftUserList } from './leftUserList'
import { ChatMultiSelect } from './chatMultiSelect'
import classNames from 'classnames'
import { Loader } from '../loader/loader'

export const LeftSideUserList = ({ height }) => {

    const userLists = useSelector(getChatUsersList)
    const isGroup = useSelector(getIsGroupChatCreateScreen)
    const isLeftSideLoader = useSelector(getIsLeftSideLoader)
    const friendList = useSelector(getFriendList)
    const isFriendView = useSelector(getIsFriendView)
    const userListSearch = useSelector(getUserListSearch)
    const chatLists = useSelector(getChatsList)
    const [finalList,setFinalList]=useState(chatLists)
    // const finalList = chatLists

    useEffect(()=>{
        setFinalList(userListSearch?userLists:isFriendView ? friendList:chatLists)
    },[chatLists,userListSearch,userLists,isFriendView,friendList])
 

    return (
        <div
            className={classNames("left--chat--main--div", {
                'left--chat--main--group': isGroup,
            })}>
            <>
            {isLeftSideLoader && !isGroup &&<div className='left-side-loader'> <Loader isSmall /></div>}
                {isGroup ?
                    <ChatMultiSelect height={height} />
                    : finalList.map((i,index) => <React.Fragment key={`${i.id}-${index}-${i.name}`}> <LeftUserList  {...i} /></React.Fragment>)}
            </>

        </div>
    )
}
