import React, { useState } from 'react'
import { FormControlLabel, Radio, RadioGroup, Button } from '@material-ui/core'
import { Formik, Form, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import SearchComplete from '../map/SearchComplete'
import '../../containers/marinaStorage/marinaStorage.scss'
import CommonBanner from '../mainBanner/commonBanner'
import { NotifyModal } from '../modal/notifyModal'
import { notifyMeEnums } from '../../util/enums/enums'
import { renderSelectOptions, requireMessage } from '../../helpers/string'
import SingleSelect from '../helper/singleSelect'
import HeroSearch from '../../containers/marinaStorage/hero-search'

export const StyledRadio = props => {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<span className="icon-test  checked-icon" />}
      icon={<span className="icon-test" />}
      {...props}
    />
  )
}

export const MarinaStorageSearchCard = ({ types, services, search, marinaBanner, notifyInput, notifyMe }) => {
  const setPlaceHandler = ({ country, city }, setFieldValue) => {
    setFieldValue('country', country)
    setFieldValue('city', city)
  }

  const [openDialog, setOpenDialog] = useState(false)

  const modalHandler = e => {
    e && e.preventDefault()
    setOpenDialog(!openDialog)
  }

  const notifyMeHandler = (formValues, values) => {
    notifyMe({
      ...formValues,
      ...values,
      type: notifyMeEnums.MARINA,
      serviceProvide: values.serviceProvide ? values.serviceProvide.value : '',
    })

    modalHandler()
  }

  return (
    <Formik
      initialValues={{
        country: '',
        city: '',
        serviceProvide: '',
        provider: types?.length && types[0].id,
      }}
      validationSchema={Yup.object().shape({
        country: Yup.string().required(requireMessage('Location')),
      })}
      enableReinitialize
      onSubmit={values => {
        const providerAlias = types?.length && types.find(item => item.id === values.provider)
        search({
          ...values,
          providerAlias: providerAlias ? providerAlias?.alias : '',
          serviceProviderAlias: values.serviceProvide ? values.serviceProvide.label : '',
          serviceProvide: values.serviceProvide ? values.serviceProvide.value : '',
        })
      }}
      render={({ touched, errors, values, setFieldValue, handleSubmit }) => (
        <Form className="bg-transparent-form marina-home-search-card" onSubmit={e => e.preventDefault()}>
          <div className="bg-blue marina-storage-bg">
            <CommonBanner data={marinaBanner} />
            <div className="w-100 h-100 marina-storage-card-position">
              <div className="marina-storage-card-2">
                <HeroSearch />
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  )
}
