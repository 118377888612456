import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'

import InnerReview from './InnerReview'
import { KeyboardArrowRight } from '@material-ui/icons'
import UserProfileAllReviews from './userProfileAllReviews'

class InnerReviews extends Component {
  state = {
    open: false,
  }

  modalHandler = () => {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  render() {
    const { iconColor, data } = this.props
    const { open } = this.state

    return (
      <>
        <Grid container>
          {data &&
            data.length > 0 &&
            data.slice(0, 5).map((value, i) => {
              return (
                <Grid key={value.id} item xs={this.props.xs} sm={this.props.sm} className="review--container">
                  <InnerReview value={value} xs={this.props.xs} sm={this.props.sm} iconColor={iconColor} />
                </Grid>
              )
            })}

          {data && data.length > 0 && (
            <UserProfileAllReviews
              open={open}
              close={this.modalHandler}
              reviews={{ reviews: data }}
              title="All Reviews"
              className="notify-content-override user-profile--review"
            ></UserProfileAllReviews>
          )}
        </Grid>
        {data && data.length > 5 && (
          <h2 className="mt-2 hover-text-only cursor-pointer">
            <span
              className="show-link mb-0 text-decoration-unset hover-text-only review--show--all"
              onClick={this.modalHandler}
            >
              See all {data.length} reviews
              <KeyboardArrowRight />
            </span>
          </h2>
        )}
      </>
    )
  }
}

export default InnerReviews
