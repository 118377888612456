import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'

import { userRoles, noSurveyorMessage, skeletonType } from '../../util/enums/enums'
import { Layout } from '../../components'
import '../../components/dialog/EstimateCost.scss'
import { getUsersByCountry } from '../../redux/actions'
import { SurveyorList } from '../../components/salesEngine/SurveyorList'

import './BoatSurveyor.scss'
import UserContext from '../../UserContext'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { SkeletonLoader } from '../../components/loader/SkeletonLoader'

const BoatSurveyor = ({ getUsersByCountry, surveyorUser, userTotal, isLoading }) => {
  const { match } = useContext(UserContext)

  const {
    params: { boatId, country },
  } = match

  useEffect(() => {
    getUsersByCountry({ roleType: userRoles.SURVEYOR, boatId, country })
  }, [])

  return (
    <Layout className="boat--surveyor-layout boat--shipper w-85vw ml-auto mr-auto">
      {isLoading ? (
        <div className="d-flex flex-wrap">
          <SkeletonLoader type={skeletonType.userInfo} isVerticle={false} itemsLength={10} count={9} />
        </div>
      ) : (
        <>
          <div className="shipper--main--title--div">
            <span className="shipper--main--title">Found {(surveyorUser?.length || 0)} surveyors located near the boat in {country}</span>
          </div>

          {userTotal > 0 ? (
            <div className="boat--inner-surveyor surveyor-list-nearest-carousel">
              {surveyorUser?.map(item => {
                return <SurveyorList userInfo={item} isBranchImage />
              })}
            </div>
          ) : (
            noSurveyorMessage(country)
          )}
        </>
      )}
    </Layout>
  )
}

const mapStateToProps = state => ({
  surveyorUser: state.boatReducer?.users,
  userTotal: state.boatReducer?.userTotal,
  isLoading: state.boatReducer?.isLoading,
})

const mapDispatchToProps = dispatch => ({
  getUsersByCountry: data => dispatch(getUsersByCountry(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatSurveyor)
