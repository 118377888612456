import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'
import { Grid } from '@material-ui/core'
import {
  salesEngineAssignShipper,
  paymentRequest,
  skipShipment,
  addBoatShipmentLocation,
  getSingleSalesEngine,
  getAllShipmentProposal,
  getPaymentReceipt,
  getDocumentUrl,
  getShipperDocument,
  getAuctionByBoatId,
} from '../../../redux/actions'
import PaymentOption from '../../../components/salesEngine/paymentOption'
import GoogleMap from '../../../components/map/map'
import { ShipperCarousel, paymentTypes } from '../../../util/enums/enums'
import { PaymentInformation, SellerInformation, UserInformationCard } from '../../../components'
import './SalesEngineStepper.scss'
import { getAddress, getLatLngFromGeometric } from '../../../helpers/jsxHelper'
import { SuccessNotify } from '../../../helpers/notification'
import { CommonModal } from '../../../components/modal/commonModal'
import { ShipperRequestList } from '../../../components/salesEngine/ShipperRequestList'
import { CarouselWrapper } from '../../../components/carouselComponent/carouselWrapper'
import { CommonSalesEnginTitle } from '../../../components/salesEngine/CommonSalesEnginTitle'
import { AgentInfoCard } from '../../../components/salesEngine/AgentInfoCard'
import { ButtonComponent } from '../../../components/form/Button'
import { getAllPageInfoByType } from '../../../redux/actions/pageInfoByTypeAction'
import GoogleMarker from '../../../components/map/marker'
import { getShipmentMarker, getSingleBoatMarker } from '../../../helpers/boatHelper'
import { salesEngineStatus } from '../SalesEngineHelper'
import BoatInformation from '../../../components/salesEngine/BoatInformation'
import { getWithStripeFee } from '../../../util/utilFunctions'
import { PaymentDetail } from '../../../components/salesEngine/PaymentDetail'
import IconSEArrowRight from './right-arrow-svgrepo-com'

class Shipment extends Component {
  state = {
    selectShipmentLocation: false,
    isUpdate: false,
    isOpen: false,
    shipmentLocationData: null,
    checkUser: false,
    shipperDocumentUploaded: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      shipmentLocationAddedSuccess,
      assignShipperSuccess,
      salesEngine,
      skipShipmentSuccess,
      shipperDocument,
      isSeller,
      isBuyer,
      getDocumentSuccess,
    } = nextProps

    if (assignShipperSuccess) {
      getSingleSalesEngine({ id: salesEngine.id })
    }

    if (skipShipmentSuccess && salesEngine?.isShipperSkip) {
      return {
        isOpen: false,
      }
    }

    if (getDocumentSuccess) {
      const shipperDocumentId = shipperDocument?.id
      const shipperDocumentUploaded = isSeller && shipperDocumentId
      const checkUser = isSeller && !shipperDocumentId
      return {
        checkUser,
        shipperDocumentUploaded,
      }
    }

    return null
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.shipmentLocationAddedSuccess &&
      this.props.shipmentLocationAddedCount !== prevProps.shipmentLocationAddedCount
    ) {
      SuccessNotify('Shipment Location Added Successfully.')
    }

    if (!this.state.isUpdate && this.props.salesEngine.shipmentLocation?.geometricLocation) {
      const { geometricLocation } = this.props.salesEngine.shipmentLocation
      const { type, __typename, ...newGeometricLocation } = geometricLocation
      const latLng = getLatLngFromGeometric(newGeometricLocation)
      this.setState({ isUpdate: true, latLng })
    }
  }

  componentDidMount() {
    const { salesEngine, getAllShipmentProposal, getShipperDocument, getAuctionByBoatId } = this.props
    getAllShipmentProposal(salesEngine.id)

    if (salesEngine?.buySellProcess.includes(salesEngineStatus.shipperPaymentCompleted)) {
      getShipperDocument({ id: salesEngine.id })
    }

    if (salesEngine.shipmentLocation?.geometricLocation) {
      const { geometricLocation } = salesEngine.shipmentLocation
      const { type, __typename, ...newGeometricLocation } = geometricLocation
      const latLng = getLatLngFromGeometric(newGeometricLocation)
      this.setState({ isUpdate: true, selectShipmentLocation: true, latLng })
    }

    if (salesEngine.isShipperSkip) {
      this.setState({ selectShipmentLocation: true })
    }

    salesEngine.boat?.isAuctionRoomCreated && getAuctionByBoatId({ id: salesEngine.boat?.id })
  }

  locationModalHandler = () => {
    const { salesEngine, skipShipment } = this.props

    if (salesEngine?.isShipperSkip) {
      skipShipment({ id: salesEngine.id, isShipperSkip: false })
    }

    this.setState(preState => ({ selectShipmentLocation: true }))
  }

  confirmationModal = () => {
    this.props.skipShipment({ id: this.props.salesEngine.id, isShipperSkip: true })
    this.setState(preState => ({ selectShipmentLocation: false }))
  }

  removeShipper = ({ id }) => {
    const { salesEngineAssignShipper } = this.props
    salesEngineAssignShipper({ shipperId: '', id, removeShipper: true })
  }

  fetchMapInfo = result => {
    const {
      salesEngine: { id },
    } = this.props
    const input = {
      address: result.address1,
      city: result.city,
      country: result.country,
      postalCode: result.zip,
      state: result.state,
      geometricLocation: result.geometricLocation,
    }
    const shipmentLocationData = { id, input }
    this.setState({ shipmentLocationData })
  }

  render() {
    const {
      selectShipmentLocation,
      isUpdate,
      latLng,
      isOpen,
      shipmentLocationData,
      checkUser,
      shipperDocumentUploaded,
      shipperDocumentId,
    } = this.state
    const {
      salesEngine,
      shipmentProposals,
      isBuyer,
      isSeller,
      paymentRequest,
      skipShipment,
      currentUser,
      history,
      salesEngineAssignShipper,
      getAllPageInfoByType,
      getPaymentReceipt,
      getDocumentUrl,
      shipperDocument,
      addBoatShipmentLocation,
      isAgent,
      paymentProcessing,
      boatAuctionRoom,
    } = this.props

    const shipperPaymentData =
      shipmentProposals?.length > 0 && shipmentProposals.find(item => item.shipper?.id === salesEngine?.shipper?.id)

    const {
      commonAddress: { geometricLocation = null, city, country },
    } = getAddress(salesEngine.boat.address)

    const amountWithFee = getWithStripeFee(salesEngine?.paymentInformation?.shipperSubtotalPrice)

    return (
      <Container
        fluid
        className={`${
          !selectShipmentLocation || ((!isBuyer || isSeller) && !shipperDocument?.id) ? 'h-100' : ''
        } pl-0 pr-0 salesEngine--shipper--container`}
      >
        <Grid container className={!selectShipmentLocation || ((!isBuyer || isSeller) && !shipperDocument?.id) ? 'h-100' : ''}>
          {!salesEngine.shipperPayment && !salesEngine.shipper && isBuyer ? (
            <Grid item xs={12}>
              <div className="shipment--pick--question">How would you like to receive your Boat ?</div>
              <div className="shipment--pick--boat--btn">
                {isBuyer && (
                  <button
                    type="button"
                    className={'btn btn-lg ' + (salesEngine.isShipperSkip ? 'btn-black' : 'btn-ghost')}
                    onClick={this.confirmationModal}
                    disabled={salesEngine.selectedShipper}
                  >
                    Pickup your Boat
                  </button>
                )}
                {!isSeller && (
                  <button
                    type="button"
                    className={
                      'btn btn-lg ' + (!salesEngine.isShipperSkip && selectShipmentLocation ? 'btn-black' : 'btn-ghost')
                    }
                    onClick={this.locationModalHandler}
                  >
                    Deliver my Boat
                  </button>
                )}
              </div>
            </Grid>
          ) : (
            (!isBuyer || isSeller) &&
            !shipperDocument?.id && (
              <div className="h-100 w-100">
                <h3 style={{ textAlign: 'center', fontSize: 22 }}>How to release the boat</h3>
                <p className="mt-4 buyer--proceeding--shipment">
                  The buyer is proceeding with the shipment process. Once the payment is completed, a QR code will be generated.
                  The shipper or boat mover will pick up the boat directly from you. You need to scan and validate the QR code
                  from the shipper before releasing the boat. If the scan is invalid, do not release the boat and contact
                  AdamSea immediately.
                </p>
                <p className="buyer--proceeding--shipment">Note: Download the AdamSea App to scan the QR code.</p>
              </div>
            )
          )}

          {salesEngine.isShipperSkip && !selectShipmentLocation && (
            <Grid item xs={12}>
              <div className="congratulation--content--msg">
                {/* <div className="congratulation--max--width">
                  <div className="congratulation--massage">Congratulation..! Your process has been Successfully Done.</div>
                </div> */}

                <Grid container className="mt-5 shipment--pickup--info--section">
                  <Grid item xs={4} className="shipment--pickup--left--section">
                    <div className="address--shipment--note">Please collect your boat from the below address.</div>
                    <div className="address--shipment--final p-0">
                      {salesEngine.boat &&
                        salesEngine.boat.address &&
                        salesEngine.boat.address.length > 0 &&
                        salesEngine.boat.address[0].address1}
                    </div>
                    {this.props.qrCodeContent}
                  </Grid>
                  {salesEngine.boat && (
                    <Grid item xs={8} className="shipment--pick--map">
                      <GoogleMarker
                        isRedirectToMap
                        geometricLocation={geometricLocation}
                        isInnerPage={false}
                        className=""
                        markers={getSingleBoatMarker(salesEngine.boat, false, true)}
                        height={50}
                        width={'100%'}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <div className="mt-5">
                      <h2>What to do next</h2>
                      <h3>Steps to pick up your boat from the seller</h3>
                      <p>1. Download the boat QR code or log in to your AdamSea app where the code will be available.</p>
                      <p>
                        2. The seller will scan the same boat QR code when you arrive to pick up the boat. Make sure to keep a
                        copy with you.
                      </p>
                      <p>3. Once the QR code scan is completed, the boat seller can hand over the boat to you.</p>
                      <p>
                        <strong>Note:</strong> If you miss scanning the boat QR code or if the QR code is invalid, the boat
                        seller will not be able to hand over the boat. This transaction will be considered invalid, and you will
                        need to contact AdamSea via live chat.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          )}

          <Grid item xs={12} md={salesEngine?.shipper && isBuyer && this.props.payments?.length > 0 ? 6 : 12}>
            {isBuyer &&
              this.props.payments?.length > 0 &&
              this.props.payments.map(item => (
                <PaymentDetail salesEngine={salesEngine} isShipperPaymentDetail paymentItem={item} />
              ))}

            {salesEngine.shipper && (shipperDocumentUploaded || isBuyer) && (
              <Grid item xs={12} className="shipper-user--information xxx">
                <UserInformationCard
                  md={salesEngine?.shipper && isBuyer && this.props.payments?.length > 0 && 12}
                  isShipper
                  shipperPaymentData={shipperPaymentData}
                  className={'assign-single-list mx-auto'}
                  userInfo={salesEngine.shipper}
                  isBuyer={isBuyer}
                  salesEngine={salesEngine}
                  payment={this.props.payments && this.props.payments[0]}
                  history={history}
                  handleChange={this.removeShipper}
                  proposalDocument={shipperPaymentData.proposalDocument}
                  getDocumentUrl={getDocumentUrl}
                  enableReview={shipperDocument?.id && true}
                  isAgent={isAgent}
                  shipperDocumentUploaded={shipperDocumentUploaded}
                />
              </Grid>
            )}
          </Grid>

          {salesEngine?.shipper && isBuyer && this.props.payments?.length > 0 && (
            <>
              <Grid xs={12} md={1} />
              <Grid item xs={12} md={5}>
                <h3 style={{ fontSize: 24 }}>What’s Next</h3>
                <p>
                  {salesEngine?.shipper?.firstName} will pick up the boats from the seller and start the delivery process. Once
                  completed, he will upload the following documents to be sent to you:
                </p>
                <ol>
                  <li>Bill of lading</li>
                  <li>Certificate of origin</li>
                  <li>Insurance certificate</li>
                  <li>Receipt for shipment</li>
                  <li>Invoice for delivery payment</li>
                  <li>Any other necessary documents</li>
                </ol>
              </Grid>
            </>
          )}

          {shipperDocument?.id && salesEngine?.agent && (isSeller || isBuyer) && (
            <Grid item xs={12} md={8} lg={6} className="mx-auto shipment--after--agent--section" style={{ marginTop: 60 }}>
              <AgentInfoCard
                agent={salesEngine?.agent}
                className={'assign-single-list'}
                isSingle={false}
                history={history}
                salesEngine={salesEngine}
                isShipper
                enableReview
                afterDocumentReview
                isAgent={isAgent}
                isBuyer
                shipperDocumentUploaded={shipperDocumentUploaded}
              />
            </Grid>
          )}

          {isBuyer && !salesEngine.shipper && !salesEngine.isShipperSkip && selectShipmentLocation && (
            <>
              <Grid item xs={12} className="shipment--Map--position" style={{ marginTop: 60 }}>
                <GoogleMap
                  shipmentMap
                  isRedirectToMap
                  geometricLocation={geometricLocation}
                  shipmentAddressLabel="Add Address"
                  className="googleMap-position"
                  latLng={latLng}
                  fetch={result => this.fetchMapInfo(result)}
                  height={'100%'}
                  width={100}
                  placeHolder="Shipment Location"
                  columnName={'address'}
                  isUpdate={isUpdate}
                  isFullGrid
                  isRegister
                  zoomControl={false}
                  crossCountryAllowed
                />
                <Grid container>
                  <Grid item xs={12}>
                    <div className="shipper--send--location--div">
                      <button
                        className="btn btn-lg btn-o-black btn-o-ghost-black"
                        onClick={() => addBoatShipmentLocation(shipmentLocationData)}
                        disabled={this.props.shipmentLocationAddLoading}
                      >
                        {isUpdate
                          ? 'Send updated address with the shippers and boat movers'
                          : 'Share your address with the shippers and boat movers'}
                        <IconSEArrowRight height="1em" className="ml-3" style={{ animation: 'flowH 2s linear infinite' }} />
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {(isBuyer || shipperDocumentUploaded) && salesEngine.shipper && (
            <Grid item xs={12} style={{ marginTop: 60 }}>
              <PaymentOption
                paymentRequest={paymentRequest}
                paymentTo={paymentTypes.shipperPayment}
                price={shipperPaymentData?.price}
                currentUser={currentUser}
                salesEngine={salesEngine}
                paymentDone={salesEngine.shipperPayment}
                disable={salesEngine.shipperPayment || salesEngine.shipper}
                getAllPageInfoByType={getAllPageInfoByType}
                getPaymentReceipt={getPaymentReceipt}
                totalPayment={amountWithFee}
                shipperDocument={shipperDocument}
                getDocumentUrl={getDocumentUrl}
                paymentProcessing={paymentProcessing}
                isBuyer={isBuyer}
                isSeller={isSeller}
                auctionId={
                  boatAuctionRoom && (boatAuctionRoom?.id !== null || boatAuctionRoom?.id !== undefined)
                    ? boatAuctionRoom?.id
                    : null
                }
                isFromAuction={
                  boatAuctionRoom && (boatAuctionRoom?.depositAmount === null || boatAuctionRoom?.depositAmount === undefined)
                    ? false
                    : true
                }
              />
            </Grid>
          )}
        </Grid>

        {salesEngine.shipper && (checkUser || isBuyer) && (
          <Grid container spacing={3} style={{ marginTop: 60 }}>
            {salesEngine.boat && <BoatInformation salesEngine={salesEngine} />}

            <SellerInformation
              isPaymentDone={salesEngine.boatPayment}
              salesEngine={salesEngine}
              isSeller={isSeller}
              history={history}
              isBuyer={isBuyer}
            />
          </Grid>
        )}

        {isBuyer && !salesEngine.shipper && !salesEngine.shipperPayment && selectShipmentLocation && isUpdate && (
          <Grid container spacing={3} style={{ marginTop: 60 }}>
            <Grid item xs={12}>
              <div>
                <h2 className="shipment-pickup-title">Select one of the boat delivery shippers and boat movers</h2>
                <div className="shipment-pickup-locs">
                  <div className="shipment-pickup-loc">
                    <strong>Pickup</strong> {salesEngine?.boat?.address?.[0]?.city}, {salesEngine?.boat?.address?.[0]?.country}
                  </div>
                  <div className="shipment-pickup-loc-div"></div>
                  <div className="shipment-pickup-loc">
                    <strong>Delivery</strong> {salesEngine?.shipmentLocation?.city}, {salesEngine?.shipmentLocation?.country}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              {this.props.shipmentProposalsLoading ? null : shipmentProposals && shipmentProposals.length > 0 ? (
                <CarouselWrapper items={ShipperCarousel} top={30}>
                  {shipmentProposals.map(item => {
                    return (
                      item.shipper && (
                        <ShipperRequestList
                          history={history}
                          price={item.price}
                          distance={item.distance}
                          proposalDocument={item.proposalDocument}
                          userInfo={item.shipper}
                          className="surveyor-list-nearest--class--name"
                          salesEngine={salesEngine}
                          shipperAcceptRequest={salesEngineAssignShipper}
                          getDocumentUrl={getDocumentUrl}
                        />
                      )
                    )
                  })}
                </CarouselWrapper>
              ) : (
                <div className="no-shipper-alert">
                  {' '}
                  <span className="alert alert-info-user">No shipper has sent a proposal yet. Please wait until they do.</span>
                </div>
              )}
            </Grid>
          </Grid>
        )}

        {salesEngine?.shipperPayment && (checkUser || isBuyer) && (
          <div className="flex-1 mx-auto" style={{ maxWidth: 1024, marginTop: 60 }}>
            <PaymentInformation />
          </div>
        )}
      </Container>
    )
  }
}
const mapStateToProps = state => ({
  shipmentProposals: state.salesEngineReducer.shipmentProposals,
  shipmentProposalsLoading: state.salesEngineReducer.shipmentProposalsLoading,
  shipmentLocationAddLoading: state.salesEngineReducer.shipmentLocationAddLoading,
  shipmentLocationAddedSuccess: state.salesEngineReducer.shipmentLocationAddedSuccess,
  assignShipperSuccess: state.salesEngineReducer.assignShipperSuccess,
  skipShipmentSuccess: state.salesEngineReducer?.skipShipmentSuccess,
  currentUser: state.loginReducer && state.loginReducer.currentUser,
  shipperDocument: state?.salesEngineReducer?.shipperDocument,
  paymentProcessing: state.salesEngineReducer?.paymentProcessing,
  getDocumentSuccess: state.salesEngineReducer?.getDocumentSuccess,
  boatAuctionRoom: state.boatReducer.boatAuctionRoom,
  payments: state.salesEngineReducer?.payments,
  shipmentLocationAddedCount: state.salesEngineReducer?.shipmentLocationAddedCount,
  skipShipmentCount: state.salesEngineReducer?.skipShipmentCount,
})

const mapDispatchToProps = dispatch => ({
  salesEngineAssignShipper: data => dispatch(salesEngineAssignShipper(data)),
  paymentRequest: data => dispatch(paymentRequest(data)),
  skipShipment: data => dispatch(skipShipment(data)),
  addBoatShipmentLocation: data => dispatch(addBoatShipmentLocation(data)),
  getSingleSalesEngine: data => dispatch(getSingleSalesEngine(data)),
  getAllShipmentProposal: data => dispatch(getAllShipmentProposal(data)),
  getAllPageInfoByType: data => dispatch(getAllPageInfoByType(data)),
  getPaymentReceipt: data => dispatch(getPaymentReceipt(data)),
  getDocumentUrl: data => dispatch(getDocumentUrl(data)),
  getShipperDocument: data => dispatch(getShipperDocument(data)),
  getAuctionByBoatId: data => dispatch(getAuctionByBoatId(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Shipment)
