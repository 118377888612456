import { put, takeLatest, all } from 'redux-saga/effects'
import { getAllSeoMetaTagsQuery } from '../../graphql/seoSchema'
import { graphqlClientPublic, graphqlClient } from '../../helpers/graphqlClient'
import {
  GET_SEO_PAGES,
  GET_SEO_PAGES_FAILURE,
  GET_SEO_PAGES_SUCCESS,
} from '../actionTypes'

function getAllSeoMetaTags(input) {
  return graphqlClientPublic.query({
    query: getAllSeoMetaTagsQuery,
    variables: {
      ...input,
      limit: 1000,
      page: 1,
      active: 'allTags',
    },
  })
}

function* fetchAllSeoMetaTags(action) {
  try {
    const res = yield getAllSeoMetaTags(action.payload)
    yield put({ type: GET_SEO_PAGES_SUCCESS, payload: res.data?.getAllSeoMetaTags?.items || [] })
  } catch (error) {
    yield put({ type: GET_SEO_PAGES_FAILURE, error })
  }
}

function* fetchAllSeoMetaTagsSaga() {
  yield takeLatest(GET_SEO_PAGES, fetchAllSeoMetaTags)
}

export default function* seoSaga() {
  yield all([fetchAllSeoMetaTagsSaga()])
}
