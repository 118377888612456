import { CREATE_CONTACT_US_COMPLAINT, CREATE_CONTACT_US_SERVICE, CREATE_CONTACT_US_SUGGESTION, GET_ALL_USER_FAQ } from '../actionTypes'

export const getAllUserFaq = data => ({
  type: GET_ALL_USER_FAQ,
  payload: data,
})

export const createSuggestion = data => ({
  type: CREATE_CONTACT_US_SUGGESTION,
  payload: data,
})

export const createService = data => ({
  type: CREATE_CONTACT_US_SERVICE,
  payload: data,
})

export const createComplaint = data => ({
  type: CREATE_CONTACT_US_COMPLAINT,
  payload: data,
})
