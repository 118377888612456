import {
  GET_ALL_BOAT_LOOKUPS,
  GET_ALL_BOAT_LOOKUPS_SUCCESS,
  GET_ALL_BOAT_LOOKUPS_FAILURE,
  GET_ALL_BOATS_BY_USER,
  GET_ALL_BOATS_BY_USER_SUCCESS,
  GET_ALL_BOATS_BY_USER_FAILURE,
  SEARCH_BOAT,
  SEARCH_BOAT_SUCCESS,
  SEARCH_BOAT_FAILURE,
  CLEAR_SEARCH_BOAT_FLAG,
  GET_BOAT_TYPE,
  GET_BOAT_TYPE_SUCCESS,
  GET_BOAT_TYPE_FAILURE,
  RECENT_SEARCH,
  RECENT_SEARCH_SUCCESS,
  RECENT_SEARCH_FAILURE,
  MULTI_SEARCH,
  MULTI_SEARCH_SUCCESS,
  MULTI_SEARCH_FAILURE,
  GET_SINGLE_BOAT_SUCCESS,
  GET_SINGLE_BOAT_FAILURE,
  TOGGLE_BOAT_STATUS_SUCCESS,
  TOGGLE_BOAT_STATUS_FAILURE,
  DELETE_BOAT_SUCCESS,
  GET_BOAT_BY_TYPE_SUCCESS,
  GET_BOAT_BY_TYPE_FAILURE,
  GET_LATEST_BOATS_SUCCESS,
  GET_POPULAR_BOATS_SUCCESS,
  GET_TOP_RATED_BOATS_SUCCESS,
  UPDATE_BOAT_SUCCESS,
  ADD_BOAT_SUCCESS,
  CLEAR_BOAT_ADD_FLAG,
  CLEAR_BOAT_UPDATE_FLAG,
  UPDATE_BOAT_FAILURE,
  ADD_BOAT_FAILURE,
  CLEAR_EXISTING_BOAT,
  CLEAR_SEARCHED_RESULTS,
  GET_CITY_WISE_BOATS_SUCCESS,
  CLEAR_CITY_WISE_BOATS,
  GET_CATEGORY_WISE_BOATS_SUCCESS,
  CLEAR_CATEGOEY_WISE_BOATS,
  CREATE_AUCTION_ROOM,
  CREATE_AUCTION_ROOM_SUCCESS,
  CREATE_AUCTION_ROOM_FAILURE,
  CLEAR_AUCTION_FLAG,
  GET_USER_AUCTION_ROOMS,
  GET_USER_AUCTION_ROOMS_FAILURE,
  GET_USER_AUCTION_ROOMS_SUCCESS,
  GET_ALL_AUCTION_BID,
  GET_ALL_AUCTION_BID_SUCCESS,
  GET_ALL_AUCTION_BID_FAILURE,
  CLEAR_BID_FLAG,
  GET_ALL_AUCTION_ROOMS,
  GET_ALL_AUCTION_ROOMS_SUCCESS,
  GET_ALL_AUCTION_ROOMS_FAILURE,
  GET_BOAT_BY_TYPE,
  CREATE_AUCTION_BID,
  CREATE_AUCTION_BID_SUCCESS,
  CREATE_AUCTION_BID_FAILURE,
  CLEAR_FLAG_AUCTION_BID,
  BOAT_BY_CITIES,
  BOAT_BY_CITIES_SUCCESS,
  BOAT_BY_CITIES_FAILURE,
  BOAT_BY_CITIES_CLEAR,
  GET_SINGLE_BOAT,
  GET_BOAT_SEARCH_MINIMUM_VALUE,
  GET_BOAT_SEARCH_MINIMUM_VALUE_SUCCESS,
  GET_BOAT_SEARCH_MINIMUM_VALUE_FAILURE,
  GET_BOAT_COST_ESTIMATION,
  GET_BOAT_COST_ESTIMATION_SUCCESS,
  GET_BOAT_COST_ESTIMATION_FAILURE,
  MAIL_BOAT_COST_ESTIMATION,
  MAIL_BOAT_COST_ESTIMATION_SUCCESS,
  MAIL_BOAT_COST_ESTIMATION_FAILURE,
  ADD_LIKE,
  ADD_LIKE_SUCCESS,
  ADD_LIKE_FAILURE,
  CLEAR_ESTIMATED_MAIL,
  GET_AUCTION_ROOM_BY_BID_USER,
  GET_AUCTION_ROOM_BY_BID_USER_SUCCESS,
  GET_AUCTION_ROOM_BY_BID_USER_FAILURE,
  GET_AUCTION_BY_BOAT_ID,
  GET_AUCTION_BY_BOAT_ID_SUCCESS,
  GET_AUCTION_BY_BOAT_ID_FAILURE,
  GET_CATEGORY_WISE_BOATS,
  GET_CATEGORY_WISE_BOATS_FAILURE,
  GET_CITY_WISE_BOATS,
  GET_CITY_WISE_BOATS_FAILURE,
  GET_LATEST_BOATS,
  GET_LATEST_BOATS_FAILURE,
  GET_POPULAR_BOATS,
  GET_POPULAR_BOATS_FAILURE,
  GET_TOP_RATED_BOATS,
  GET_TOP_RATED_BOATS_FAILURE,
  GET_USERS_BY_COUNTRY,
  GET_USERS_BY_COUNTRY_SUCCESS,
  GET_USERS_BY_COUNTRY_FAILURE,
  GET_BOAT_CONVERTED_COST_ESTIMATION_SUCCESS,
  GET_BOAT_CONVERTED_COST_ESTIMATION_FAILURE,
  GET_BOAT_CONVERTED_COST_ESTIMATION,
  GET_AUCTION_SALES_ENGINE_FAILURE,
  CLEAR_AUCTION_SALES_ENGINE,
  GET_AUCTION_SALES_ENGINE,
  GET_AUCTION_SALES_ENGINE_SUCCESS,
  CHECK_FOR_QUANTITY_QUERY_FAILURE,
  CLEAR_CHECK_FOR_QUANTITY_QUERY,
  CHECK_FOR_QUANTITY_QUERY,
  CHECK_FOR_QUANTITY_QUERY_SUCCESS,
  ADD_DEPOSIT_FOR_BID_SUCCESS,
  ADD_DEPOSIT_FOR_BID_FAILURE,
  ADD_DEPOSIT_FOR_BID,
  CLEAR_ADD_DEPOSIT_FOR_BID,
  GET_ALL_FOOTER_LINK,
  GET_ALL_FOOTER_LINK_SUCCESS,
  GET_ALL_FOOTER_LINK_FAILURE,
  CLEAR__ADD_LIKE,
  GET_YACHTS,
  GET_YACHTS_SUCCESS,
  GET_YACHTS_FAILURE,
} from '../actionTypes'
import _ from 'lodash'
import { filterLikesReducerArray, camelCase } from '../../helpers/string'
import { boatUnderVarification } from '../../util/enums/enums'
const InitialState = {
  loading: false,
  success: false,
  error: false,
  boat: {},
  userBoats: [],
  totalUserBoats: null,
  auctionSuccess: false,
  auctionError: false,
  isVarification: false,

  searchedBoats: [],
  searchedBoatsCount: null,

  boatTypeSuccess: false,
  boatTypes: [],
  boatTypeError: false,
  isSearched: false,

  recentSearchResults: [],
  multipleSearchResults: {},

  isBoatCreated: false,
  isBoatUpdated: false,
  isBoatDeleted: false,
  isBoatCreateAndUpdateError: false,

  featureBoats: [],
  totalFeatureBoats: null,
  bestDealBoats: [],
  totalBestDealBoats: null,
  mustBuyBoats: [],
  totalMustBuyBoats: null,

  latestBoats: [],
  totalLatestBoats: null,
  popularBoats: [],
  totalPopularBoats: null,
  topRatedBoats: [],
  totalTopRatedBoats: null,
  cityWiseBoats: [],
  totalCityWiseBoats: null,
  totalCategoryWiseBoats: null,

  boatByCityLoad: false,
  boatByCitySuccess: false,
  boatByCityError: {},
  boatByCityData: [],

  boatSearchValues: {},

  getByBidUserSuccess: false,
  getByBidUserError: false,

  getBoatSuccess: false,
  getBoatError: false,

  isError: false,

  createBidSuccess: false,
  createBidFailure: false,
  boatAuctionRoom: {},
  adminBoatTypes: [],

  getAuctionSalesEngineSuccess: false,
  getAuctionSalesEngineFailure: false,

  isCheckedQuantity: false,

  isDepositSuccess: false,
  isDepositError: false,
  isDepositePaymentLoading: false,
}

export const boatReducer = (state = InitialState, action) => {
  const { boat } = state

  switch (action.type) {
    case GET_ALL_BOAT_LOOKUPS:
      return {
        ...state,
        success: false,
        error: false,
        boatLookups: [],
      }

    case GET_ALL_BOAT_LOOKUPS_SUCCESS:
      const boatLookUps = action.data.data.getBoatLookUps

      let filteredBoatLookUps = {
        boatStatus: [],
        boatParking: [],
        hullMaterial: [],
        fuelType: [],
        engineDrives: [],
        engineStroke: [],
        boatType: [],
        mainSaloonConvertible: [],
        mainSaloon: [],
        amenitiesItems: [],
        accessoriesItems: [],
        navigationEq: [],
        manufacturerBoatStatus: [],
        hullColor: [],
        boatBrands: [],
        boatKind: [],
        kayakType: [],
        kayakStatus: [],
      }

      boatLookUps.forEach(item => {
        switch (item.typeName) {
          case 'Boat Status':
            filteredBoatLookUps.boatStatus.push(item)
            break
          case 'Boat Parking':
            filteredBoatLookUps.boatParking.push(item)
            break
          case 'Hull Material':
            filteredBoatLookUps.hullMaterial.push(item)
            break
          case 'Fuel Type':
            filteredBoatLookUps.fuelType.push(item)
            break
          case 'Engine Drives':
            filteredBoatLookUps.engineDrives.push(item)
            break
          case 'Engine Stroke':
            filteredBoatLookUps.engineStroke.push(item)
            break
          case 'Main Saloon':
            filteredBoatLookUps.mainSaloon.push(item)
            break
          case 'Main Saloon Convertible':
            filteredBoatLookUps.mainSaloonConvertible.push(item)
            break
          case 'Boat Type':
            filteredBoatLookUps.boatType.push(item)
            break
          case 'Amenities':
            filteredBoatLookUps.amenitiesItems.push(item)
            break
          case 'Accessories':
            filteredBoatLookUps.accessoriesItems.push(item)
            break
          case 'Navigation Equipment':
            filteredBoatLookUps.navigationEq.push(item)
            break
          case 'Boat Status For Manufacturer':
            filteredBoatLookUps.manufacturerBoatStatus.push(item)
            break
          case 'Boat Brand':
            filteredBoatLookUps.boatBrands.push(item)
            break
          case 'Hull Color':
            filteredBoatLookUps.hullColor.push(item)
            break
          case 'Boat Kind':
            filteredBoatLookUps.boatKind.push(item)
            break
          case 'Kayak Type':
            filteredBoatLookUps.kayakType.push(item)
            break
          case 'Kayak Status':
            filteredBoatLookUps.kayakStatus.push(item)
            break
          default:
            break
        }
      })

      filteredBoatLookUps.engineStroke = _.orderBy(
        filteredBoatLookUps.engineStroke,
        data => {
          return parseInt(data.lookUp.alias)
        },
        ['asc']
      )

      return {
        ...state,
        success: true,
        error: false,
        boatLookups: filteredBoatLookUps,
      }

    case GET_ALL_BOAT_LOOKUPS_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }

    case GET_ALL_BOATS_BY_USER:
      return {
        ...state,
        success: false,
        byUserSuccess: false,
        error: false,
        loading: true,
      }

    case GET_ALL_BOATS_BY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        byUserSuccess: true,
        userBoats: action.payload.items,
        totalUserBoats: action.payload.total,
      }

    case GET_ALL_BOATS_BY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        byUserSuccess: false,
        error: true,
      }

    case GET_ALL_FOOTER_LINK:
      return {
        ...state,
        footerLinkSuccess: false,
        error: false,
        isPageLoader: true,
      }

    case GET_ALL_FOOTER_LINK_SUCCESS:
      return {
        ...state,
        footerLinkSuccess: true,
        footerBoatsLink: action.payload.items,
        totalFooterBoatsLink: action.payload.total,
        isPageLoader: false,
      }

    case GET_ALL_FOOTER_LINK_FAILURE:
      return {
        ...state,
        footerLinkSuccess: false,
        error: true,
        isPageLoader: false,
      }

    case SEARCH_BOAT:
      return {
        ...state,
        isPageLoader: true,
      }
    case SEARCH_BOAT_SUCCESS:
      return {
        ...state,
        isSearched: true,
        searchedBoats: action.payload.items,
        searchedBoatsCount: action.payload.total,
        isPageLoader: false,
      }

    case SEARCH_BOAT_FAILURE:
      return {
        ...state,
        isSearched: false,
        isPageLoader: false,
      }

    case CLEAR_SEARCH_BOAT_FLAG:
      return {
        ...state,
        isSearched: false,
      }

    case GET_BOAT_TYPE:
      return {
        ...state,
        boatTypeSuccess: false,
        boatTypes: [],
        boatTypeError: false,
      }
    case GET_BOAT_TYPE_SUCCESS:
      return {
        ...state,
        boatTypeSuccess: true,
        boatTypes: !action.isAdmin ? action.payload.items : state.boatTypes,
        adminBoatTypes: action.isAdmin ? action.payload.items : state.adminBoatTypes,
        boatTypeError: false,
      }

    case GET_BOAT_TYPE_FAILURE:
      return {
        ...state,
        boatTypeSuccess: false,
        boatTypeError: true,
      }

    case RECENT_SEARCH:
      return {
        ...state,
        recentSearchResults: [],
      }

    case RECENT_SEARCH_SUCCESS:
      return {
        ...state,
        recentSearchResults: action.payload,
      }

    case RECENT_SEARCH_FAILURE:
      return {
        ...state,
        recentSearchResults: [],
      }

    case MULTI_SEARCH:
      return {
        ...state,
        multipleSearchResults: {},
      }

    case MULTI_SEARCH_SUCCESS:
      return {
        ...state,
        multipleSearchResults: action.payload,
      }

    case MULTI_SEARCH_FAILURE:
      return {
        ...state,
        multipleSearchResults: {},
      }

    case GET_SINGLE_BOAT:
      return {
        ...state,
        boat: {},
        isVarification: false,
      }

    case GET_SINGLE_BOAT_SUCCESS:
      return {
        ...state,
        boat: action.payload,
        getBoatSuccess: true,
        isVarification: false,
      }

    case GET_SINGLE_BOAT_FAILURE:
      return {
        ...state,
        boat: {},
        getBoatError: true,
        isVarification: action.errors?.some(x => x.message === boatUnderVarification),
      }
    case GET_USERS_BY_COUNTRY:
      return {
        ...state,
        users: [],
        isLoading: true,
        isUsersByCountryLoading: true,
      }

    case GET_USERS_BY_COUNTRY_SUCCESS:
      const name = camelCase(action.roleType)
      const total = `${name}Length`

      return {
        ...state,
        users: action.payload.items,
        [name]: action.payload.items,
        userTotal: action.payload.total,
        [total]: action.payload.total,
        isLoading: false,
        isUsersByCountryLoading: false,
      }

    case GET_USERS_BY_COUNTRY_FAILURE:
      return {
        ...state,
        users: [],
        isLoading: true,
        isUsersByCountryLoading: false,
      }

    case TOGGLE_BOAT_STATUS_SUCCESS:
      return {
        ...state,
      }

    case ADD_BOAT_SUCCESS:
      return {
        ...state,
        isBoatCreated: true,
      }

    case ADD_BOAT_FAILURE:
      return {
        ...state,
        isBoatCreateAndUpdateError: true,
      }

    case CLEAR_BOAT_ADD_FLAG:
      return {
        ...state,
        isBoatCreated: false,
        isBoatCreateAndUpdateError: false,
      }

    case DELETE_BOAT_SUCCESS:
      const { userBoats } = state
      return {
        ...state,
        isBoatDeleted: true,
        userBoats: userBoats.filter(boat => boat.id !== action.payload.id),
      }

    case UPDATE_BOAT_SUCCESS:
      return {
        ...state,
        isBoatUpdated: true,
      }

    case UPDATE_BOAT_FAILURE:
      return {
        ...state,
        isBoatCreateAndUpdateError: true,
      }

    case CLEAR_BOAT_UPDATE_FLAG:
      return {
        ...state,
        isBoatCreated: false,
        isBoatUpdated: false,
        isBoatDeleted: false,
        isBoatCreateAndUpdateError: false,
        getBoatSuccess: false,
        getBoatError: false,
      }

    case CLEAR_EXISTING_BOAT:
      return {
        ...state,
        boat: {},
      }
    case GET_BOAT_BY_TYPE:
      return {
        ...state,
        featureBoats: [],
        isPageLoader: true,
      }

    // GET BOAT BY TYPE
    case GET_BOAT_BY_TYPE_SUCCESS:
      switch (action.fieldName) {
        case 'featureStatus':
          return {
            ...state,
            featureBoats: action.payload.items,
            totalFeatureBoats: action.payload.total,
            isPageLoader: false,
          }
        case 'bestDealStatus':
          return {
            ...state,
            bestDealBoats: action.payload.items,
            totalBestDealBoats: action.payload.total,
            isPageLoader: false,
          }
        case 'mustBuyStatus':
          return {
            ...state,
            mustBuyBoats: action.payload.items,
            totalMustBuyBoats: action.payload.total,
            isPageLoader: false,
          }

        default:
          return {
            ...state,
          }
      }

    case GET_BOAT_BY_TYPE_FAILURE:
      return {
        ...state,
        isPageLoader: false,
      }
    case GET_LATEST_BOATS:
      return {
        ...state,
        isPageLoader: true,
      }
    case GET_LATEST_BOATS_SUCCESS:
      return {
        ...state,
        latestBoats: action.payload.items,
        totalLatestBoats: action.payload.total,
        isPageLoader: false,
      }
    case GET_LATEST_BOATS_FAILURE:
      return {
        ...state,
        isPageLoader: false,
      }

    case GET_POPULAR_BOATS:
      return {
        ...state,
        isPageLoader: true,
      }
    case GET_POPULAR_BOATS_SUCCESS:
      return {
        ...state,
        popularBoats: action.payload.items,
        totalPopularBoats: action.payload.total,
        isPageLoader: false,
      }
    case GET_POPULAR_BOATS_FAILURE:
      return {
        ...state,
        isPageLoader: false,
      }

    case GET_TOP_RATED_BOATS:
      return {
        ...state,
        isPageLoader: true,
      }
    case GET_TOP_RATED_BOATS_SUCCESS:
      return {
        ...state,
        topRatedBoats: action.payload.items,
        totalTopRatedBoats: action.payload.total,
        isPageLoader: false,
      }
    case GET_TOP_RATED_BOATS_FAILURE:
      return {
        ...state,
        isPageLoader: false,
      }

    case CLEAR_SEARCHED_RESULTS:
      return {
        ...state,
        searchedBoats: [],
        multipleSearchResults: {},
      }

    case GET_CITY_WISE_BOATS:
      return {
        ...state,
        isPageLoader: true,
      }

    case GET_CITY_WISE_BOATS_SUCCESS:
      return {
        ...state,
        cityWiseBoats: action.payload.items,
        totalCityWiseBoats: action.payload.total,
        isPageLoader: false,
      }

    case GET_CITY_WISE_BOATS_FAILURE:
      return {
        ...state,
        isPageLoader: false,
      }

    case CLEAR_CITY_WISE_BOATS:
      return {
        ...state,
        cityWiseBoats: [],
        totalCityWiseBoats: 0,
      }

    case GET_CATEGORY_WISE_BOATS:
      return {
        ...state,
        isPageLoader: true,
      }
    case GET_CATEGORY_WISE_BOATS_SUCCESS:
      return {
        ...state,
        categoryWiseBoat: action.payload.items,
        totalCategoryWiseBoats: action.payload.total,
        isPageLoader: false,
      }
    case GET_CATEGORY_WISE_BOATS_FAILURE:
      return {
        ...state,
        isPageLoader: false,
      }
    case CLEAR_CATEGOEY_WISE_BOATS:
      return {
        ...state,
        categoryWiseBoat: [],
        totalCategoryWiseBoats: 0,
      }

    case CREATE_AUCTION_ROOM:
      return {
        ...state,
        isLoading: true,
        isError: false,
        auctionSuccess: false,
      }
    case CREATE_AUCTION_ROOM_SUCCESS:
      return {
        ...state,
        auctionSuccess: true,
        isLoading: false,
      }
    case CREATE_AUCTION_ROOM_FAILURE:
      return {
        ...state,
        auctionError: true,
        isLoading: false,
        isError: true,
      }

    case CLEAR_AUCTION_FLAG:
      return {
        ...state,
        auctionSuccess: false,
        auctionError: false,
        getAuctionSuccess: false,
        getAuctionError: false,
        isError: false,
      }
    case GET_USER_AUCTION_ROOMS:
      return {
        ...state,
        loading: true,
        getAuctionSuccess: false,
        getAuctionError: false,
        userAuctions: [],
      }
    case GET_USER_AUCTION_ROOMS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAuctionSuccess: true,
        getAuctionError: false,
        userAuctions: action.payload.data.getAuctionRoomsByUser.items,
        userAuctionsTotal: action.payload.data.getAuctionRoomsByUser.total,
      }
    case GET_USER_AUCTION_ROOMS_FAILURE:
      return {
        ...state,
        loading: false,
        getAuctionSuccess: false,
        getAuctionError: true,
      }
    case GET_ALL_AUCTION_BID:
      return {
        ...state,
        loading: true,
        allBids: [],
        getBidSuccess: false,
        getBidFailure: false,
      }
    case GET_ALL_AUCTION_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        allBids: action.payload.items,
        allBidsTotal: action.payload.total,
        getBidSuccess: true,
        getBidFailure: false,
      }
    case GET_ALL_AUCTION_BID_FAILURE:
      return {
        ...state,
        loading: false,
        allBids: [],
        getBidSuccess: false,
        getBidFailure: true,
      }
    case CLEAR_BID_FLAG:
      return {
        ...state,
        getBidSuccess: false,
        getBidFailure: false,
      }
    case GET_ALL_AUCTION_ROOMS:
      return {
        ...state,
        getAuctionSuccess: false,
        isLoader: true,
        getAuctionError: true,
        allAuctionRooms: [],
        allAuctionRoomCounts: 0,
      }
    case GET_ALL_AUCTION_ROOMS_SUCCESS:
      return {
        ...state,
        getAuctionSuccess: false,
        getAuctionError: true,
        isLoader: false,
        allAuctionRooms: action.payload.data.getAllAuctionRoom.items,
        allAuctionRoomCounts: action.payload.data.getAllAuctionRoom.total,
      }
    case GET_ALL_AUCTION_ROOMS_FAILURE:
      return {
        ...state,
        getAuctionSuccess: false,
        getAuctionError: true,
        isLoader: false,
      }

    case CREATE_AUCTION_BID:
      return {
        ...state,
        createBidSuccess: false,
        createBidFailure: false,
        isError: false,
      }

    case CREATE_AUCTION_BID_SUCCESS:
      return {
        ...state,
        createBidSuccess: true,
      }

    case CREATE_AUCTION_BID_FAILURE:
      return {
        ...state,
        createBidFailure: true,
        isError: true,
      }

    case CLEAR_FLAG_AUCTION_BID:
      return {
        ...state,
        createBidSuccess: false,
        createBidFailure: false,
      }

    case BOAT_BY_CITIES:
      return {
        ...state,
        boatByCityLoad: true,
      }

    case BOAT_BY_CITIES_SUCCESS:
      return {
        ...state,
        boatByCityLoad: false,
        boatByCitySuccess: true,
        boatByCityData: action.payload.items,
      }

    case BOAT_BY_CITIES_FAILURE:
      return {
        ...state,
        boatByCityLoad: false,
        boatByCitySuccess: false,
        boatByCityError: action.error,
      }

    case BOAT_BY_CITIES_CLEAR:
      return {
        ...state,
        boatByCityLoad: false,
        boatByCitySuccess: false,
        boatByCityData: [],
        boatByCityError: {},
      }

    case GET_BOAT_SEARCH_MINIMUM_VALUE:
      return {
        ...state,
        boatSearchValues: {},
      }

    case GET_BOAT_SEARCH_MINIMUM_VALUE_SUCCESS:
      return {
        ...state,
        boatSearchValues: action.payload[0],
      }

    case GET_BOAT_SEARCH_MINIMUM_VALUE_FAILURE:
      return {
        ...state,
      }

    case GET_BOAT_COST_ESTIMATION:
      return {
        ...state,
        boatCostEstimation: {},
      }
    case GET_BOAT_COST_ESTIMATION_SUCCESS:
      return {
        ...state,
        boatCostEstimation: action.payload.getCostEstimation,
      }
    case GET_BOAT_COST_ESTIMATION_FAILURE:
      return {
        ...state,
        boatCostEstimation: {},
      }
    case MAIL_BOAT_COST_ESTIMATION:
      return {
        ...state,
        isMailCost: false,
        isMailCostFail: false,
      }
    case MAIL_BOAT_COST_ESTIMATION_SUCCESS:
      return {
        ...state,
        isMailCost: true,
        isMailCostFail: false,
      }
    case MAIL_BOAT_COST_ESTIMATION_FAILURE:
      return {
        ...state,
        isMailCost: false,
        isMailCostFail: true,
      }
    case CLEAR_ESTIMATED_MAIL:
      return {
        ...state,
        isMailCost: false,
        isMailCostFail: false,
      }

    case ADD_LIKE:
      return {
        ...state,
      }

    case ADD_LIKE_SUCCESS:
      return {
        ...state,
        boat: {
          ...boat,
          likes: filterLikesReducerArray(boat.likes, action.payload, action.moduleType),
        },
      }

    case ADD_LIKE_FAILURE:
      return {
        ...state,
        addLikeError: true,
      }

    case CLEAR__ADD_LIKE:
      return {
        ...state,
        addLikeError: false,
      }

    case GET_AUCTION_ROOM_BY_BID_USER:
      return {
        ...state,
        isLoading: true,
        userAuctions: [],
      }
    case GET_AUCTION_ROOM_BY_BID_USER_SUCCESS:
      return {
        ...state,
        getByBidUserSuccess: true,
        isLoading: false,
        userAuctions: action.payload,
        totalUserAuctions: action.payload.total,
      }
    case GET_AUCTION_ROOM_BY_BID_USER_FAILURE:
      return {
        ...state,
        getByBidUserError: true,
        isLoading: false,
        userAuctions: [],
      }

    case GET_AUCTION_BY_BOAT_ID:
      return {
        ...state,
        boatAuctionRoom: {},
      }

    case GET_AUCTION_BY_BOAT_ID_SUCCESS:
      return {
        ...state,
        boatAuctionRoom: action.payload,
      }

    case GET_AUCTION_BY_BOAT_ID_FAILURE:
      return {
        ...state,
      }

    case GET_BOAT_CONVERTED_COST_ESTIMATION:
      return {
        ...state,
        isPdfLoading: true,
      }

    case GET_BOAT_CONVERTED_COST_ESTIMATION_SUCCESS:
      const { boatCostEstimation } = state
      return {
        ...state,
        isPdfLoading: false,
        boatCostEstimation: {
          ...boatCostEstimation,
          pdf: {
            url: action.payload.url,
          },
        },
      }

    case GET_BOAT_CONVERTED_COST_ESTIMATION_FAILURE:
      return {
        ...state,
        isPdfLoading: false,
      }

    case GET_AUCTION_SALES_ENGINE:
      return {
        ...state,
        auctionSalesEngineId: '',
      }

    case GET_AUCTION_SALES_ENGINE_SUCCESS:
      return {
        ...state,
        auctionSalesEngineId: action.payload,
        getAuctionSalesEngineSuccess: true,
      }

    case GET_AUCTION_SALES_ENGINE_FAILURE:
      return {
        ...state,
        auctionSalesEngineId: '',
        getAuctionSalesEngineFailure: true,
      }

    case CLEAR_AUCTION_SALES_ENGINE:
      return {
        ...state,
        auctionSalesEngineId: '',
        getAuctionSalesEngineSuccess: false,
        getAuctionSalesEngineFailure: false,
      }

    case CHECK_FOR_QUANTITY_QUERY:
      return {
        ...state,
        isCheckedQuantity: false,
      }

    case CHECK_FOR_QUANTITY_QUERY_SUCCESS:
      return {
        ...state,
        isCheckedQuantity: true,
        boat: {
          ...boat,
          ...action.payload,
        },
      }

    case CHECK_FOR_QUANTITY_QUERY_FAILURE:
      return {
        ...state,
        isCheckedQuantity: false,
      }

    case CLEAR_CHECK_FOR_QUANTITY_QUERY:
      return {
        ...state,
        isCheckedQuantity: false,
      }

    case ADD_DEPOSIT_FOR_BID:
      return {
        ...state,
        isDepositSuccess: false,
        isDepositError: false,
        isDepositePaymentLoading: true,
      }

    case ADD_DEPOSIT_FOR_BID_SUCCESS:
      return {
        ...state,
        isDepositSuccess: true,
        isDepositePaymentLoading: false,
      }

    case ADD_DEPOSIT_FOR_BID_FAILURE:
      return {
        ...state,
        isDepositError: true,
        isDepositePaymentLoading: false,
      }

    case CLEAR_ADD_DEPOSIT_FOR_BID:
      return {
        ...state,
        isDepositSuccess: false,
        isDepositError: false,
        isDepositePaymentLoading: false,
      }

    case GET_YACHTS: {
      return {
        ...state,
        yachtsLoading: true,
        yachtsSuccess: false,
        yachtsFailure: false,
      }
    }

    case GET_YACHTS_SUCCESS: {
      console.log(GET_YACHTS_SUCCESS)
      return {
        ...state,
        yachts: action.payload,
        yachtsLoading: false,
        yachtsSuccess: true,
      }
    }
    
    case GET_YACHTS_FAILURE: {
      return {
        ...state,
        yachtsLoading: false,
        yachtsFailure: true,
      }
    }

    default:
      return state
  }
}
