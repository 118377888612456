import React, { useEffect, useState } from 'react'

import { dimension } from '../../util/enums/enums'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import { SkeletonMapGridStyle } from '../styleComponent/styleComponent'
import { SkeletonComponent } from './SkeletonComponent'

export const SkeletonMapGrid = ({ itemsLength, isWishlist }) => {
  const [height, setHeight] = useState(dimension.featureBoat.height)
  const [width, setWidth] = useState(dimension.featureBoat.width)

  const getWidth = () => {
    const className = isWishlist ? '.dashboard-layout-responsive' : '.grid--w-100--map'
    const widthR = getRatio(dimension, 'showMapRatio', className)
    const heightR = getHeightRatio(dimension, 'showMapRatio', className)
    widthR && setWidth(widthR)
    heightR && setHeight(heightR)
  }

  useEffect(() => {
    getWidth()
  }, [])

  return (
    <div className="skeleton--map-grid">
      {[...Array(itemsLength)].map((_, index) => {
        return (
          <div key={index} className="skeleton--map-grid-item">
            <div className="skeleton--map-image">
              <SkeletonComponent className="skeleton--map-image-inner" width={width} height={height} />
            </div>

            <SkeletonMapGridStyle width={width} height={height} className="skeleton--map-content">
              <SkeletonComponent className="skeleton--map-title" height={20} />
              <SkeletonComponent className="skeleton--map-address" height={30} />
              <SkeletonComponent className="skeleton--map-rate" height={20} />
              <SkeletonComponent className="skeleton--map-manufacture" height={12} />
            </SkeletonMapGridStyle>
          </div>
        )
      })}
    </div>
  )
}

SkeletonMapGrid.defualtProps = {
  itemsLength: 4,
  isWishlist: false,
}
