import { GET_ALL_USER_GUIDE, GET_ALL_USER_GUIDE_FAILURE, GET_ALL_USER_GUIDE_SUCCESS } from '../actionTypes'
import { getAllUserGuide } from '../../graphql/userGuideSchema'
import { put, takeLatest, all } from 'redux-saga/effects'
import { graphqlClient } from '../../helpers/graphqlClient'

function getAllUserGuidApi(data) {
  return graphqlClient
    .query({
      query: getAllUserGuide,
      fetchPolicy: 'no-cache',
      variables: { page: data.page, limit: data.limit },
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(e => {
      throw e
    })
}

function* getAllUserGuideData(action) {
  try {
    const data = yield getAllUserGuidApi(action.payload)
    yield put({ type: GET_ALL_USER_GUIDE_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_ALL_USER_GUIDE_FAILURE, e })
  }
}

function* getAllUserGuideSaga() {
  yield takeLatest(GET_ALL_USER_GUIDE, getAllUserGuideData)
}

export default function* userGuideSaga() {
  yield all([getAllUserGuideSaga()])
}
