import { GET_ALL_MEDIA_ARTICLES, GET_ALL_MEDIA_RECORD } from '../actionTypes'

export const getAllMediaArticles = data => ({
  type: GET_ALL_MEDIA_ARTICLES,
  data,
})
export const getAllMedia = data => ({
  type: GET_ALL_MEDIA_RECORD,
  payload: data,
})
