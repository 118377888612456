import React from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import { Grid, Button } from '@material-ui/core'
import * as Yup from 'yup'

import { CommonModal } from './commonModal'
import { dateStringFormate } from '../../util/utilFunctions'
import { Field } from '../ws/Field'
import ErrorFocus from '../ws/ErrorFocus'
import { requireMessage } from '../../helpers/string'
import { disablePastDates } from '../../helpers/dateTimeHelper'

import './createAuctionRoomModal.scss'
import { ButtonComponent } from '../form/Button'
import ErrorComponent from '../error/errorComponent'
import { dateValidator } from '../../util/customValidator'
import moment from 'moment'
export const CreateAuctionRoomModal = props => {
  const { open, close, selectedBoatId, createAuctionRoom, isError, errorMessage, clearErrorMessageShow } = props

  const initValues = {
    startTime: '',
    endTime: '',
    startPrice: '',
    minimumRaisedAmount: '',
  }

  return (
    <>
      <CommonModal
        open={open}
        close={close}
        title="Auction Details"
        className="auction--detail--popup"
        overflowVisible
        overflowAuto={false}
      >
        <>
          <div className="auction-custom-ui w-500">
            <Formik
              initialValues={initValues}
              onSubmit={values => {
                clearErrorMessageShow()
                values.startPrice = parseInt(values.startPrice)
                values.minimumRaisedAmount = parseInt(values.minimumRaisedAmount)
                values.boat = selectedBoatId
                createAuctionRoom(values)
              }}
              validationSchema={Yup.object().shape({
                startTime: Yup.date()
                  .min(
                    props.timerSettings ? moment().add(props.timerSettings.auctionAddTime, props.timerSettings.auctionAddUnit).toDate() : moment().toDate(), 
                    'Start date must be after ' + (props.timerSettings ? `${props.timerSettings.auctionAddTime} ${props.timerSettings.auctionAddUnit}` : 'current date')
                  )
                  .required(requireMessage('Start date')),
                // endTime: Yup.date().min(Yup.ref('startTime'), 'End date must be after Start date.').required(requireMessage('End date')),
                startPrice: Yup.string().required('Start Price field is required.'),
                minimumRaisedAmount: Yup.string().required('Amount field is required.'),
              })}
              validate={dateValidator}
              render={({ formikBag, errors, setFieldValue, values, handleSubmit }) => (
                <Form>
                  <ErrorFocus />
                  <Grid container>
                    <Grid item sm={12} className="mb-2 auction--detail--fields">
                      <label className="mb-1 font-16 font-weight-500 auction--detail--label required">Start Date</label>
                      <Field
                        type="select-date-time"
                        isValidDate={(currentDate) => disablePastDates(currentDate)}
                        isUpdate={false}
                        isUtc={false}
                        value={values.startTime}
                        onChange={value => {
                          setFieldValue('startTime', dateStringFormate(value))
                        }}
                        error={errors.startTime ? errors.startTime : null}
                        name="startTime"
                      />
                      <ErrorMessage component="div" name="startTime" className="error-message" />
                    </Grid>

                    {/* <Grid item sm={12} className="mb-2 auction--detail--fields">
                                            <label className="mb-1 font-16 font-weight-500 auction--detail--label required">End Date</label>

                                            <Field
                                                type="select-date-time"
                                                isValidDate={disablePastDates}
                                                isUpdate={false}
                                                isUtc={false}
                                                value={values.endTime}
                                                // maxDate={new Date("02-29-2022")}
                                                // maxDate={addDays(new Date(), 5)}
                                                error={
                                                    errors.endTime
                                                        ? errors.endTime
                                                        : null
                                                }
                                                onChange={value => {
                                                    setFieldValue("endTime", dateStringFormate(value));
                                                }}
                                                name="endTime"
                                            />
                                            <ErrorMessage
                                                component="div"
                                                name="endTime"
                                                className="error-message"
                                            />
                                        </Grid> */}

                    <Grid item sm={12} className="mb-2 auction--detail--fields">
                      <label className="mb-1 font-16 font-weight-500 auction--detail--label required">Start Price (US$)</label>
                      <input
                        name="startPrice"
                        value={values.startPrice}
                        type="number"
                        className="form-control"
                        onChange={e => setFieldValue('startPrice', e.target.value)}
                      ></input>
                      <ErrorMessage component="div" name="startPrice" className="error-message" />
                    </Grid>

                    <Grid item sm={12} className="mb-3 auction--detail--fields auction--minimum--amount">
                      <label className="mb-1 font-16 font-weight-500 auction--detail--label required">
                        Minimum Amount Raised (US$)
                      </label>
                      <input
                        name="minimumRaisedAmount"
                        value={values.minimumRaisedAmount}
                        type="number"
                        className="form-control"
                        onChange={e => setFieldValue('minimumRaisedAmount', e.target.value)}
                      ></input>
                      <ErrorMessage component="div" name="minimumRaisedAmount" className="error-message" />
                    </Grid>

                    <Grid item sm={12} className="auction--detail--buttons">
                      <div className="d-flex justify-content-end">
                        <ButtonComponent
                          className=" h-auto as--success--outline btn--padding mr-3 create--auction--room--modal"
                          disabled={props.loading}
                          onClick={handleSubmit}
                        >
                          Save
                        </ButtonComponent>

                        <ButtonComponent
                          className=" h-auto as--cancel--outline btn--padding create--auction--room--modal"
                          onClick={close}
                        >
                          Cancel
                        </ButtonComponent>
                      </div>
                    </Grid>

                    {isError && <ErrorComponent errors={errorMessage} />}
                  </Grid>
                </Form>
              )}
            />
          </div>
        </>
      </CommonModal>
    </>
  )
}
