import React from 'react'
import { useSelector } from 'react-redux'
import '../footer/footer.scss'
import { Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { Layout } from '../layout/layout'
import DynamicPageHeader from '../dynamic-page/DynamicPageHeader'
import useZohoSalesIQ from '../../hooks/useZohoSalesIQ'
import { getLocalStorageItem, hideZohoChatCookies, removeZohoChatWidget } from '../../helpers/storageHelper'

const  Help = props => {
    const { history } = props

    const {initChatWidget, removeChatWidget} = useZohoSalesIQ()

    React.useEffect(()=>{
      initChatWidget();
      return () => {
        removeChatWidget()
      }
    },[])
    return (
      <>
        <Layout>
          <div className="contactFooter-bg help-padding help-page">
            <DynamicPageHeader title="Hello..!" subTitle="What can we help you with?" />
            <div className="pt-2">
              <Container maxWidth="lg">
                <Grid container spacing={2} className="help-row">
                  <Grid item xs={12} sm={12} md={6} lg={4} onClick={() => history?.push('/fraud-protection')}>
                    <div className="p-10 position-relative help-section">
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <div className="helper-img">
                            <img
                              src={require('../../assets/images/help/fraud-protection.png')}
                              alt="fraud-protection"
                              className="help-image-height width-100"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <div>
                            <h5 className="custom-size">Fraud Protection</h5>
                            <div className="d-flex flex-column help-description">
                              <span>Be safe</span>
                              <span>General guidelines and precautions</span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} onClick={() => history?.push('/user-guide')}>
                    <div className="p-10 position-relative help-section">
                      <Grid container spacing={3} className="cursor-pointer">
                        <Grid item xs={3}>
                          <div className="helper-img">
                            <img
                              src={require('../../assets/images/help/user-guide.png')}
                              alt="User Guide"
                              className="help-image-height width-100"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <div>
                            <h5 className="custom-size">User Guide</h5>
                            <div className="d-flex flex-column help-description">
                              <span>Processes explained</span>
                              <span>Clear your doubts</span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} onClick={() => history?.push('/downloads')}>
                    <div className="p-10 position-relative help-section">
                      <Grid container spacing={3} className="cursor-pointer">
                        <Grid item xs={3}>
                          <div className="helper-img">
                            <img
                              src={require('../../assets/images/help/downloads.png')}
                              alt="Downloads"
                              className="help-image-height width-100"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <div>
                            <h5 className="custom-size">Downloads</h5>
                            <div className="d-flex flex-column help-description">
                              <span>Resources</span>
                              <span>Guidelines & FAQs</span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} onClick={() => history?.push('/buying-process')}>
                    <div className="p-10 position-relative help-section">
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <div className="helper-img">
                            <img
                              src={require('../../assets/images/help/buying-process.png')}
                              alt="Buying Process"
                              className="help-image-height width-100"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <div>
                            <h5 className="custom-size">Buying Process</h5>
                            <div className="d-flex flex-column help-description">
                              <span>Step by step instructions</span>
                              <span>Detailed explanations</span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} onClick={() => history?.push('/selling-process')}>
                    <div className="p-10 position-relative help-section">
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <div className="helper-img">
                            <img
                              src={require('../../assets/images/help/selling-process.png')}
                              alt="Selling"
                              className="help-image-height width-100"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <div>
                            <h5 className="custom-size">Selling process</h5>
                            <div className="d-flex flex-column help-description">
                              <span>Selling process simplified</span>
                              <span>Steps explained</span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} onClick={() => history?.push('/faq')}>
                    <div className="p-10 position-relative help-section">
                      <Grid container spacing={3} className="cursor-pointer">
                        <Grid item xs={3}>
                          <div className="helper-img">
                            <img
                              src={require('../../assets/images/help/faqs.png')}
                              alt="FAQ"
                              className="help-image-height width-100"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <div>
                            <h5 className="custom-size">FAQs</h5>
                            <div className="d-flex flex-column help-description">
                              <span>All your questions answered </span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        </Layout>
      </>
    )
  }

export default Help
