import React from 'react'

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'

import { envConfig } from '../../config'
import { googleDirectionUrl } from '../../helpers/boatHelper'

const GoogleMarkerChat = props => {
  const { url, position ,isRedirectToMap,gotoDirectionText} = props
  
  const geometricLocationObj={"coordinates":[position.lng,position.lat]}

  return (
    <>

    {isRedirectToMap && (
      <div className="search--as--move--map-div-ui search--as--move--section chat-go-to-direction">
        <a className="inner--map-direction" href={googleDirectionUrl(geometricLocationObj)} target="_blank">
          {gotoDirectionText}
        </a>
      </div>
    )}

    <div className="map-class position-relative">
      <Map
        mapContainerStyle={{ height: '100%' }}
        className={`map--full--screen--icon`}
        google={props.google}
        initialCenter={position}
        fullscreenControl= {false}  
        streetViewControl={false}
        defaultCenter={position}
        streetViewControlOptions={{
          position: props.google.maps.ControlPosition.RIGHT_BOTTOM,
        }}
        >
        <Marker
          position={position}
          icon={{
            url: url,
            scaledSize: new google.maps.Size(24, 24), // scaled size
            origin: new google.maps.Point(0, 0), // origin
            anchor: new google.maps.Point(24, 24) // anchor
          }}
          />
      </Map>
    </div>
          </>

  )
}

export default GoogleApiWrapper({
  apiKey: envConfig.MAP_API_KEY,
  language: 'en',
})(GoogleMarkerChat)

GoogleMarkerChat.defaultProps = {
  markers: [],
  isMapControl: false,
  highlightBoatId: '',
  fetchResultOnMapChange: () => null,
  isMultiple: false,
  isInnerPage: true,
  isRedirectToMap: true,
  geometricLocation: null,
  fullscreenControl: false,
  gotoDirectionText: 'Go to direction',
}
