import React, { Component } from 'react'
import { connect } from 'react-redux'

import { DashboardLayout, TableCard } from '../../components'

import '../../styles/common.scss'
import { getAllAuctionBids } from '../../redux/actions'
import { pagination, auctionRoomStatus } from '../../util/enums/enums'
import '../../styles/manageDashboardTableResponsive.scss'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { getPagesValue, redirectToUserProfile } from '../../helpers/jsxHelper'

class ManageAuctionBids extends Component {
  constructor(props) {
    super(props)
    this.state = {
      auctionId: props.location && props.location.state && props.location.state.auctionId,
    }
  }
  componentDidMount() {
    const { getAllAuctionBids } = this.props
    const { auctionId } = this.state

    if (auctionId) {
      const data = {
        page: getPagesValue(),
        limit: pagination.PAGE_RECORD_LIMIT,
        auctionId: auctionId,
      }
      getAllAuctionBids(data)
    }
  }
  render() {
    const { history } = this.props

    const columns = [
      {
        Header: 'User',
        accessor: 'user',
        Cell: data => (
          <span className="auction-bids-username" onClick={() => redirectToUserProfile(data.original.user, history)}>
            {`${data.original.user.firstName} ${data.original.user.lastName}`}{' '}
          </span>
        ),
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: data => (
          <CurrencyContextConsumer>
            {({ currentCurrency }) => <span>{getConvertedPrice(data.value, currentCurrency)} </span>}
          </CurrencyContextConsumer>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: data =>
          data.original.auctionRoom && data.original.auctionRoom.auctionWinner ? (
            data.original.user.id === data.original.auctionRoom.auctionWinner.id ? (
              <span className="bg-green-color font-13 text-capitalize m-auto Verified">{auctionRoomStatus.WIN}</span>
            ) : (
              <span className="bg-green-color font-13 text-capitalize m-auto Unverified">{auctionRoomStatus.LOSE}</span>
            )
          ) : (
            <span className="bg-green-color font-13 text-capitalize m-auto in--process">{auctionRoomStatus.IN_PROCESS}</span>
          ),
      },
    ]

    const { loading, allBids, getAllAuctionBids, allBidsTotal } = this.props
    return (
      <DashboardLayout>
        {columns && (
          <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table">
            <TableCard
              isSearch
              title="Manage Auction Room Bids"
              columns={columns}
              data={allBids}
              loading={loading}
              className="manage--boat--table"
              noButton
              action={getAllAuctionBids}
              total={allBidsTotal}
            />
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.boatReducer && state.boatReducer.loading,
  allBids: state.boatReducer && state.boatReducer.allBids,
  allBidsTotal: state.boatReducer && state.boatReducer.allBidsTotal,
})

const mapDispatchToProps = dispatch => ({
  getAllAuctionBids: data => dispatch(getAllAuctionBids(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAuctionBids)
