import { put, takeLatest, all, takeEvery } from 'redux-saga/effects'

import {
  GET_ALL_JOBS,
  GET_ALL_JOBS_FAILURE,
  GET_ALL_JOBS_SUCCESS,
  CLEAR_JOBS_FLAG,
  APPLY_FOR_JOB,
  APPLY_FOR_JOB_FAILURE,
  APPLY_FOR_JOB_SUCCESS,
  SEARCH_JOBS,
  SEARCH_JOBS_SUCCESS,
  SEARCH_JOBS_FAILURE,
  GET_JOBS_CONTENT_SUCCESS,
  GET_JOBS_CONTENT_FAILURE,
  GET_JOBS_CONTENT,
  GET_SIGLE_JOB_FAILURE,
  GET_SIGLE_JOB_SUCCESS,
  GET_SIGLE_JOB,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAILURE,
  NOTIFICATION_MESSAGE,
} from '../actionTypes'
import { getAllJobs, applyForJob, searchJob, getJobsContent, getJobById, getAllCountryList } from '../../graphql/jobsSchema'
import { query } from './apiHelper'
import { graphqlClient } from '../../helpers/graphqlClient'
import { SuccessNotify } from '../../helpers/notification'
import { toast } from 'react-toastify'

function getAllJobsApi(input) {
  return graphqlClient
    .query({
      query: getAllJobs,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function applyForJobApi(input) {
  return query(applyForJob, { input })
}

function searchJobsApi(input) {
  return query(searchJob, input)
}

function getJobsContentApi() {
  return query(getJobsContent)
}

function getAllCountryListApi(input) {
  return query(getAllCountryList, input)
}

function getJobByIdApi(input) {
  return graphqlClient
    .query({
      query: getJobById,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function* getAllJobsData(action) {
  try {
    const res = yield getAllJobsApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: GET_ALL_JOBS_FAILURE, e: res.errors })
    } else if (res.data) {
      yield put({ type: GET_ALL_JOBS_SUCCESS, payload: res.data.getAllJobs, total: res.data.getAllJobs.total })
    }
  } catch (e) {
    yield put({ type: GET_ALL_JOBS_FAILURE, e })
  }

  yield put({ type: CLEAR_JOBS_FLAG })
}

function* applyForJobData(action) {
  try {
    const res = yield applyForJobApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: APPLY_FOR_JOB_FAILURE, e: res.errors })
    } else if (res.data) {
      toast.success(`CV sent successfully!`)
      yield put({ type: APPLY_FOR_JOB_SUCCESS, payload: res.data.createApplyJob })
    }
  } catch (e) {
    yield put({ type: APPLY_FOR_JOB_FAILURE, e })
  }

  yield put({ type: CLEAR_JOBS_FLAG })
}

function* searchJobsData(action) {
  try {
    const res = yield searchJobsApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: SEARCH_JOBS_FAILURE, e: res.errors })
    } else if (res.data) {
      yield put({ type: SEARCH_JOBS_SUCCESS, payload: res.data.searchJob })
    }
  } catch (e) {
    yield put({ type: SEARCH_JOBS_FAILURE, e })
  }

  yield put({ type: CLEAR_JOBS_FLAG })
}
function* getSingleJobData(action) {
  try {
    const res = yield getJobByIdApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: GET_SIGLE_JOB_FAILURE, e: res.errors })
    } else if (res.data) {
      yield put({ type: GET_SIGLE_JOB_SUCCESS, payload: res.data.editJob })
    }
  } catch (e) {
    yield put({ type: GET_SIGLE_JOB_FAILURE, e })
  }
}

function* getJobsContentData(action) {
  try {
    const res = yield getJobsContentApi(action.payload)
    yield put({ type: GET_JOBS_CONTENT_SUCCESS, payload: res.data.getCareerContent })
  } catch (error) {
    yield put({ type: GET_JOBS_CONTENT_FAILURE, error })
  }
}

function* getAllCountryListData(action) {
  try {
    const res = yield getAllCountryListApi(action.payload)
    yield put({ type: GET_COUNTRY_LIST_SUCCESS, payload: res.data.getAllCountries })
  } catch (error) {
    yield put({ type: GET_COUNTRY_LIST_FAILURE, error })
  }
}

function* getAllJobsSaga() {
  yield takeLatest(GET_ALL_JOBS, getAllJobsData)
}

function* applyForJobSaga() {
  yield takeLatest(APPLY_FOR_JOB, applyForJobData)
}

function* searchJobsSaga() {
  yield takeEvery(SEARCH_JOBS, searchJobsData)
}
function* getSingleJobSaga() {
  yield takeEvery(GET_SIGLE_JOB, getSingleJobData)
}

function* getJobsContentSaga() {
  yield takeLatest(GET_JOBS_CONTENT, getJobsContentData)
}

function* getAllCountryListSaga() {
  yield takeLatest(GET_COUNTRY_LIST, getAllCountryListData)
}

export default function* jobsSaga() {
  yield all([
    getAllJobsSaga(),
    applyForJobSaga(),
    getSingleJobSaga(),
    searchJobsSaga(),
    getJobsContentSaga(),
    getAllCountryListSaga(),
  ])
}
