import {
  ADD_BRANCH,
  GET_ALL_BRANCHES,
  DELETE_BRANCH,
  CLEAR_BRANCH_FLAG,
  UPDATE_BRANCH,
  CHANGE_BRANCH_STATUS,
  GET_SINGLE_BRANCH,
} from '../actionTypes'

export const addBranch = data => ({
  type: ADD_BRANCH,
  payload: data,
})

export const getAllBranches = data => {
  return {
    type: 'GET_ALL_BRANCHES',
    payload: data,
  }
}

export const getSingleBranch = data => ({
  type: GET_SINGLE_BRANCH,
  payload: data,
})

export const updateBranch = data => ({
  type: UPDATE_BRANCH,
  payload: data,
})

export const deleteBranch = data => ({
  type: DELETE_BRANCH,
  payload: data,
})

export const changeBranchStatus = data => ({
  type: CHANGE_BRANCH_STATUS,
  payload: data,
})

export const clearBranchFlag = () => ({
  type: CLEAR_BRANCH_FLAG,
})
