import React, { Component } from 'react'
import { Grid, Box } from '@material-ui/core'
import { connect } from 'react-redux'
import ImageGallery from 'react-image-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import 'react-image-gallery/styles/css/image-gallery.css'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import StarIcon from '@material-ui/icons/Star'

import GoogleMarker from '../../components/map/marker'
import { Layout, Loader } from '../../components'
import './boatServiceInner.scss'
import { editYachtService, addLikes, pageVisit, getRecentlyAddedServices, addServiceTag } from '../../redux/actions'
import { AiOutlineLeftCircle, AiOutlineRightCircle } from 'react-icons/ai'
import {
  icons,
  likeModuleEnum,
  defaultProfileIcon,
  wishlistModuleEnum,
  defaultImage,
  textCustomerReview,
  serviceInnerIconImages,
  showAllService,
  pagination,
  skeletonType,
  marketTypes,
  shareModule,
} from '../../util/enums/enums'
import { snakeCase, sliderImageHelper, nameFormatter, thousandFormatter } from '../../helpers/string'
import { addFeedback, getReviewByModuleId } from '../../redux/actions/ReviewAction'
import moment from 'moment'
import { HomPageVideoStyle } from '../../components/styleComponent/styleComponent'
import ReadMore from '../../components/helper/truncate'
import { getSingleServiceMarker } from '../../helpers/boatHelper'
import { prepareGalleryData, percentageFormate, getId, getImgUrl } from '../../util/utilFunctions'
import {
  cityCountryNameFormatter,
  redirectToUserProfile,
  getAddress,
  getAvgRating,
  checkModuleVerification,
  verificationRedirection,
} from '../../helpers/jsxHelper'
import { ShareAndWishlist } from '../../components/share/ShareAndWishlist'
import { FeedBackForm } from '../../components/feedback/FeedBackForm'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import { CommonTooltip } from '../../components/CommonTooltip'
import { VideoModel } from '../../components/videoComponent/videoModel'
import RatingComponent from '../../components/rating/Rating'
import ReviewRating from '../../components/reviewRating/ReviewRating'
import SmallCard from '../../components/boat/smallCard'
import { LikeComponent } from '../../components/like/LikeComponent'
import { getModulePdf } from '../../redux/actions/pdfAction'
import BoatServicesAdded from '../../components/gridComponents/boatAddedServices'
import UserContext from '../../UserContext'
import { UserCardWithFeedback } from '../../components/user/UserCardWithFeedback'
import { SkeletonLoader } from '../../components/loader/SkeletonLoader'
import { popUpMessage } from '../../helpers/confirmationPopup'
import { getUserReportByModule } from '../../redux/actions/reportAction'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { ButtonComponent } from '../../components/form/Button'
import InnerRatingModal from '../../components/reviewRating/InnerRatingModal'
import { get, isEmpty } from 'lodash'
import CopyAdId from '../../components/CopyAdId'
import LoginModal from '../../components/modal/loginModal'
import classNames from 'classnames'
import UserCardService from '../../components/user/UserCardService'

class BoatServiceInner extends Component {
  state = {
    yachtId: '',
    bottom: 110,
    isGallery: false,
    galleryIdx: 0,
    visible: false,
    relatedVideoPopUp: false,
    underVerification: false,
    verificationStatus: {},
    newThumbnail: '',
    adminAccess: false,
    serviceSelected: {},
  }

  relatedVideoHandler = () => {
    this.setState(prevState => ({
      relatedVideoPopUp: !prevState.relatedVideoPopUp,
    }))
  }
  static contextType = UserContext

  renderLeftNav(onClick) {
    return (
      <button
        type="button"
        className="image-gallery-icon image-gallery-left-nav service--inner--banner--arrow service--inner--banner--left--arrow"
        aria-label="Next Slide"
        onClick={onClick}
      >
        <img loading="lazy" src={require('../../assets/images/serviceInner/leftArrow.png')} alt="Arrow icon" className="" />
      </button>
    )
  }

  renderRightNav(onClick) {
    return (
      <button
        type="button"
        className="image-gallery-icon image-gallery-right-nav service--inner--banner--arrow service--inner--banner--right--arrow"
        aria-label="Next Slide"
        onClick={onClick}
      >
        <img loading="lazy" src={require('../../assets/images/serviceInner/rightArrow.png')} alt="Arrow icon" className="" />
      </button>
    )
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isReview, getReviewByModuleId, match, errorMessage, singleError } = nextProps
    const { params } = match
    const { yachtId } = prevState

    let newState = {}

    if (singleError) {
      const underVerification = checkModuleVerification(marketTypes.YACHT_SERVICE, errorMessage)

      if (underVerification) {
        newState = {
          ...newState,
          underVerification: true,
          verificationStatus: underVerification,
        }
      }
    }

    if (isReview) {
      yachtId && getReviewByModuleId({ userModuleId: yachtId })
    }

    if (params && params.id) {
      newState = {
        ...newState,
        yachtId: params.id,
      }
    }

    return newState
  }

  async componentDidMount() {
    const { yachtId } = this.state
    const { editYachtService, getReviewByModuleId, pageVisit, getRecentlyAddedServices, adminLoginDetected } = this.props

    !adminLoginDetected &&
      yachtId &&
      pageVisit({ module: yachtId, type: likeModuleEnum.YACHT_SERVICE, sessionId: getLocalStorageItem('sessionId') })

    const bottomHeight = document.querySelector('.image-gallery-thumbnails')
    const bottom = bottomHeight && bottomHeight.offsetHeight + 15
    bottom && this.setState({ bottom })
    yachtId && editYachtService(yachtId)
    yachtId && getReviewByModuleId({ userModuleId: yachtId })

    yachtId &&
      getRecentlyAddedServices({
        page: pagination.PAGE_COUNT,
        limit: pagination.PAGE_RECORD_LIMIT,
        id: yachtId,
      })

    this.setState({
      adminAccess: getLocalStorageItem('adminLoginDetected'),
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { yachtService, isReview } = this.props
    const { newThumbnail } = prevState

    if (yachtService && yachtService.youtubeUrl !== '' && newThumbnail === '') {
      this.createThumbnail(yachtService.youtubeUrl)
    }

    if (isReview) {
      document.querySelector('.rating-reviews-cont')?.scrollIntoView({ block: 'center' })
    }

    if (this.state.adminAccess && isEmpty(this.state.serviceSelected) && yachtService?.taggedServiceProvide?.length) {
      this.setState({
        serviceSelected: yachtService.taggedServiceProvide.reduce((acc, cur) => {
          acc[getId(cur)] = 1
          return acc
        }, {}),
      })
    }
  }

  createThumbnail = video => {
    if (video !== undefined) {
      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      var result = video?.match(regExp)
      if (result === null || result === undefined) {
        this.setState({ newThumbnail: defaultImage })
      } else {
        var youtube_video_id = result.pop()
        if (youtube_video_id?.length == 11) {
          var video_thumbnail = `//img.youtube.com/vi/${youtube_video_id}/0.jpg`
          this.setState({ newThumbnail: video_thumbnail })
        }
      }
    }
  }

  toggleGalleryHandler = () => this.setState(prevState => ({ isGallery: !prevState.isGallery }))

  toggleMenu = () => {
    this.setState(prevState => ({ visible: !prevState.visible }))
  }

  redirectHandler = () => {
    const { history } = this.props
    verificationRedirection('/boat-service', history)
  }

  render() {
    const {
      history,
      currentUser,
      yachtService,
      addFeedback,
      addLikes,
      recentlyAddedService,
      totalRecentlyAddedService,
      getPdfSuccess,
      pdf,
      getModulePdf,
      loading,
      getUserReportByModule,
      reviews,
    } = this.props
    const { isGallery, galleryIdx, visible, relatedVideoPopUp, underVerification, verificationStatus, newThumbnail } =
      this.state
    const {
      commonAddress: { city, country },
    } = getAddress(yachtService.user && yachtService.user.address)
    const {
      commonAddress: { geometricLocation = null, city: boatServiceCity, country: boatServiceCountry },
    } = getAddress(yachtService?.address)

    const { addServiceTag_loading, addServiceTag_success, addServiceTag_failure } = this.props

    // const reviews = yachtService?.ratedReviews || {}

    return (
      <Layout className="boat-service-inner-responsive not--scroll-auto adam-ser-inner">
        {underVerification && popUpMessage(verificationStatus?.message, 'Alert', true, null, null, this.redirectHandler)}
        {(yachtService && !yachtService.id) || loading ? (
          <Grid container className="container-fluid">
            <SkeletonLoader type={skeletonType.innerPage} />
          </Grid>
        ) : (
          <>
            <Grid
              container
              className="container-fluid clr-fluid sticky-on-top mb-3 top-space-mob-view-service service--inner--header inner-sticky-on-top"
            >
              <div className="m-auto h-100 w-85vw service-inner-space service--inner--header--spacing align-items-center">
                <Grid item className="h-70 d-flex pr-0 pt-5 pb-3 pl-0" sm={6}>
                  <div className="width-100 align-self-center">
                    <h1 className="d-flex align-items-center mb-0">
                      <span className="service-boat-name color-black">
                        {(yachtService.user && yachtService.user.companyName) || 'ServiceInner'}
                      </span>
                    </h1>
                    {/* <div className="d-flex align-items-center">
                      <img
                        src={require('../../assets/images/boatInner/location.png')}
                        alt="Location"
                        className="header--location--img"
                      />
                      <p className="font-14 mb-0">
                        {yachtService.address && yachtService.address.length
                          ? cityCountryNameFormatter(boatServiceCity, boatServiceCountry)
                          : ''}
                      </p>
                    </div> */}
                  </div>
                </Grid>

                {reviews && reviews.reviews && (
                  <Grid item sm={6} className="pt-5">
                    <div className="font-12 newRatingBlack">
                      <div>
                        <div>{reviews.reviews.length}</div>
                        <div>Reviews</div>
                      </div>
                      <div style={{ marginLeft: '1em', paddingLeft: '1em', borderLeft: '1px solid #000' }}>
                        <div>
                          <StarIcon style={{ width: '1em', height: '1em', fontSize: '1.25em' }} />{' '}
                          {percentageFormate(getAvgRating(reviews.reviews))}
                        </div>
                        <div>Rating</div>
                      </div>
                    </div>
                  </Grid>

                  // <Grid item className="h-70 star-rating-inner-side" sm={5}>
                  //   <div className="d-flex justify-content-end align-items-center h-100 pl-0 pr-0">
                  //     <div className="d-flex flex-column h-100 justify-content-evenly">
                  //       <div className="d-flex flex-column">
                  //         <div className="d-flex align-items-center  service--inner--header--rating">
                  //           <div className="d-flex mr-2 mb-1 d-m-o">
                  //             <RatingComponent
                  //               className="rating-clr rating-small-size"
                  //               rating={percentageFormate(getAvgRating(reviews.reviews))}
                  //             />
                  //           </div>
                  //           <h6 className="m-0 rated-reviews-inner-avg font-weight-400 dark-silver">
                  //             {percentageFormate(getAvgRating(reviews.reviews))}
                  //           </h6>
                  //         </div>

                  //         <span className="rated-reviews-inner font-weight-400 gray-light">
                  //           <span className="rated-reviews-inner-count mr-1">{reviews.reviews.length}</span>
                  //           {textCustomerReview}
                  //         </span>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </Grid>
                )}
              </div>
            </Grid>

            <div className="boat-service-inner-container">
              <Grid container style={{ marginTop: 100 }}>
                <Grid item xs={12} md={6}>
                  <div className="boat-service-inner-carousel position-relative">
                    {/* <LikeComponent
                      likes={yachtService.likes}
                      moduleId={yachtService.id}
                      likeModule={likeModuleEnum.YACHT_SERVICE}
                      addLikes={addLikes}
                    />
                    <div className="position-absolute inner-banner-top-social-div service--inner--views--div">
                      <div className="inner-banner-views-div mr-2 d-flex font13 gray-dark img-show-text boat--inner--banner--likes rent--inner--views--div">
                        <div className="banner-count-text inner--banner--count">
                          {yachtService.pageVisits && thousandFormatter(yachtService.pageVisits.length)} VIEWS
                        </div>
                      </div>

                      <div className="inner-banner-views-div d-flex font13 gray-dark img-show-text boat--inner--banner--likes rent--inner--views--div">
                        <div className="d-flex justify-content-center align-items-center mr-2  inner--banner--icons-div mr-2">
                          <CommonTooltip
                            component={ctProps => (
                              <div
                                data-tooltip-id={ctProps.id}
                                data-tooltip-content="Photos"
                                className="inner--banner--icons d-flex service--inner--likes--section"
                              >
                                <img
                                  loading="lazy"
                                  src={require('../../assets/images/rentInner/image-count.png')}
                                  className="h-100"
                                  alt="Count"
                                />
                              </div>
                            )}
                          />
                        </div>
                        <div className="banner-count-text inner--banner--count">{yachtService?.images?.length}</div>
                      </div>
                    </div> */}

                    <div className="d-flex justify-content-center">
                      {yachtService?.images?.length > 0 && (
                        <div className="boatServiceGalleryWrp">
                          {yachtService.images.length > 5 && (
                            <div
                              className="boatServiceGalleryCount"
                              onClick={() => {
                                this.setState({ isGallery: true, galleryIdx: 5 })
                              }}
                            >
                              + {yachtService.images.length - 5}
                            </div>
                          )}

                          <div className="boatServiceGalleryItemsWrp">
                            {yachtService.images
                              .map(getImgUrl)
                              .filter(Boolean)
                              .slice(0, 5)
                              .map((img, i) => (
                                <div
                                  className="boatServiceGalleryItem"
                                  onClick={() => {
                                    this.setState({ isGallery: true, galleryIdx: i })
                                  }}
                                >
                                  <img src={img} alt="" />
                                </div>
                              ))}
                          </div>

                          <div
                            className="banner-b-btns-wrp justify-content-end"
                            style={{
                              marginTop: yachtService.images.length > 5 || yachtService.images.length % 2 !== 0 ? 45 : 15,
                            }}
                          >
                            <div className="banner-b-btn">{thousandFormatter(yachtService.pageVisits?.length || 0)} VIEWS</div>
                            <LikeComponent
                              likes={yachtService.likes}
                              moduleId={yachtService.id}
                              likeModule={likeModuleEnum.YACHT_SERVICE}
                              addLikes={addLikes}
                              noAbsolute
                            />

                            <ShareAndWishlist
                              moduleId={yachtService.id}
                              moduleType={wishlistModuleEnum.YACHT_SERVICE}
                              ownerId={yachtService?.user?.id}
                              getPdfSuccess={getPdfSuccess}
                              pdf={pdf}
                              getModulePdf={getModulePdf}
                              isInner
                              getUserReportByModule={getUserReportByModule}
                              noTopSpace
                              hideFlag
                              hidePDF
                              isServiceInner
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div className="boat-service-inner-gallery service--inner--gallary">
                      {yachtService?.images?.length > 0 && (
                        <ImageGallery
                          items={sliderImageHelper(yachtService.images)}
                          showFullscreenButton={false}
                          showPlayButton={false}
                          onClick={this.toggleGalleryHandler}
                          onThumbnailClick={(_, i) => this.setState(prevState => ({ ...prevState, galleryIdx: i }))}
                          onSlide={i => this.setState(prevState => ({ ...prevState, galleryIdx: i }))}
                          useTranslate3D={false}
                          slideOnThumbnailOver
                        />
                      )}
                    </div> */}
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item sm={12} className="boat-service-inner-detail">
                      <div>
                        <div className="d-flex align-items-center inner--boat--systems-title service--inner--title--div">
                          <span className="inner--main--title service--inner--main--title">Our Service</span>
                        </div>

                        <p className="text-justify a-inner-fix service--inner--desc service--inner--desc--text color-black">
                          <ReadMore className="text-justify" lines={9}>
                            {yachtService.service}
                          </ReadMore>
                        </p>
                      </div>
                    </Grid>
                    {/* <Grid item sm={12}>
                         <div className="">
                          <div className="card border mb-2 user-card-responsive service--inner--user--card">
                            <div className="card-body mt-0 service--inner--user--div">
                              <div className="service--inner--user--info">
                                <Grid container>
                                  <Grid item sm={8} className="d-flex align-items-center">
                                    {yachtService && yachtService.user && (
                                      <div className="service--inner--user--img user-profile-online-section">
                                        <img
                                          className="rounded-circle cursor-pointer w-100 h-100"
                                          src={yachtService.user.image?.url || defaultProfileIcon}
                                          alt="profile icon"
                                          onClick={() => redirectToUserProfile(yachtService.user, history)}
                                        />
                                        <div className="user-online">
                                          <div className="online-div-user"></div>
                                        </div>
                                      </div>
                                    )}
                                    <div className="d-flex service--inner--user--name">
                                      <div className="author-info d-flex flex-column">
                                        <span className="name-service-user font-weight-500 gray-dark">
                                          <span className="mr-1">
                                            {yachtService.user && nameFormatter([yachtService.user.firstName, yachtService.user.lastName])}
                                          </span>
                                        </span>

                                        <span className=" font-weight-400 dark-silver service--inner--user--city">
                                          {yachtService.user && cityCountryNameFormatter(city, country)}
                                        </span>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item sm={4} className="d-flex align-items-center justify-content-center">
                                    {yachtService &&
                                      yachtService.user &&
                                      yachtService.user.companyLogo && (
                                        <div className="service--inner-company--logo">
                                          <img
                                            className="w-100 h-100"
                                            src={yachtService.user.companyLogo}
                                            alt="company logo"
                                          />

                                        </div>
                                      )}
                                  </Grid>
                                </Grid>
                              </div>
                              <div className="">
                                <ul className="list-group f-14 d-flex flex-row">
                                  <li className="list-group-item dark-silver border-0 pb-0 pl-0 service--inner--user--role service--inner--user--desc">
                                    <div className=" mr-2 inner--user--info--icon">
                                      <img
                                        src={require("../../assets/images/rentInner/user.png")}
                                        className="h-100"
                                        alt=""
                                      />
                                    </div>
                                    {yachtService &&
                                      yachtService.user &&
                                      yachtService.user.role &&
                                      yachtService.user.role.role}
                                  </li>
                                  <li className="list-group-item dark-silver border-0 pb-0 service--inner--user--date service--inner--user--desc">
                                    <div className=" mr-2 inner--user--info--icon">
                                      <img
                                        src={require("../../assets/images/rentInner/place.png")}
                                        className="h-100"
                                        alt=""
                                      />
                                    </div>
                                    On AdamSea since {moment(yachtService && yachtService.user && yachtService.user.createdAt).format("MMM, YYYY")}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>

                      <Grid item sm={12} className="mt-2 service--inner--feedback">
                        <div className="p-0">
                          <div className="mb-0 text-right width-100 d-flex justify-content-end align-items-center light-green">
                            <div className="d-flex align-items-center cursor-pointer" onClick={() => this.toggleMenu()}>
                              <div className="mr-2 feedback--section--img" >
                                <img
                                  src={require("../../assets/images/boatInner/serviceinner-like.png")}
                                  alt=""
                                  className="feedback-section light-green inner-feedback-img"
                                />
                              </div>
                              <span className="feedback-section-text font-weight-400 light-green">
                                ADD FEEDBACK
                          </span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      {visible && <Grid item xs={12}>
                        <FeedBackForm
                          open={visible}
                          closeModal={this.toggleMenu}
                          submitValues={addFeedback}
                        />
                      </Grid>} */}

                    {yachtService && yachtService.user && (
                      <UserCardService
                        seller={yachtService.user}
                        boat={{
                          rating_count: get(yachtService, 'user.reviews.reviews.length', 0),
                          rating: getAvgRating(get(yachtService, 'user.reviews.reviews', [])) || 0,
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {/* <hr /> */}
              <Grid container style={{ marginTop: 100 }}>
                <Grid item xs={12}>
                  <div style={{ margin: '0 20px 20px 0', padding: '20px 25px', background: '#eae9e6', borderRadius: 16 }}>
                    <div className="d-flex align-items-center inner--boat--systems-title">
                      <div className="inner--title--icons service--inner--title--icons">
                        <img
                          loading="lazy"
                          src={require('../../assets/images/boatInner/Services We Provide.svg')}
                          alt="Accessories"
                          className="d-flex h-100"
                        />
                      </div>
                      <span className="inner--main--title">Services We Provide</span>
                    </div>

                    {yachtService && yachtService.serviceProvide && yachtService.serviceProvide.length > 0 && (
                      <div className="col-md-12 inner--boat--amenities--div pl-0">
                        <Grid container>
                          <div className="boat-feature-items service-features-items">
                            {yachtService &&
                              yachtService.serviceProvide &&
                              yachtService.serviceProvide.length &&
                              yachtService.serviceProvide.map((item, index) => {
                                return (
                                  <div
                                    className={
                                      'boat-feature-item' +
                                      (this.state.adminAccess
                                        ? ' service--inner--boat--facilities-admin-hoverable' +
                                          (this.state.serviceSelected[getId(item)]
                                            ? ' service--inner--boat--facilities-admin-hoverable-active'
                                            : '')
                                        : '')
                                    }
                                    onClick={() => {
                                      if (this.state.adminAccess) {
                                        this.setState(({ serviceSelected }) => {
                                          if (serviceSelected[getId(item)]) {
                                            delete serviceSelected[getId(item)]
                                          } else {
                                            serviceSelected[getId(item)] = 1
                                          }

                                          return { serviceSelected }
                                        })
                                      }
                                    }}
                                  >
                                    <SmallCard
                                      index={index}
                                      // renderIcon={item && icons[snakeCase(item.name.replace(/[{()}]/g, ""))]}
                                      elseRenderImage={item.thumbnailIcon?.url}
                                      title={item.name}
                                      height="25px"
                                    />
                                  </div>
                                )
                              })}
                          </div>
                        </Grid>
                      </div>
                    )}
                  </div>

                  {this.state.adminAccess && !!Object.keys(this.state.serviceSelected).length && (
                    <div>
                      <ButtonComponent
                        className="success"
                        color="orange"
                        onClick={() => {
                          this.props.addServiceTag({
                            serviceId: getId(yachtService),
                            serviceProvideId: Object.keys(this.state.serviceSelected),
                          })
                        }}
                        loader={this.props.addServiceTag_loading}
                      >
                        Add Service Tag
                      </ButtonComponent>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container className="mt-5">
                    <Grid item xs={12} className="service--inner--location--div">
                      <div className="boat--service--facility--section">
                        <div className="d-flex align-items-center inner--service--div--title">
                          <div className="inner--title--icons service--inner--title--icons">
                            <img
                              loading="lazy"
                              src={require('../../assets/images/boatInner/Facility Location.svg')}
                              className="d-flex h-100"
                              alt="Location"
                            />
                          </div>
                          <span className="inner--main--title service--inner--main--title">
                            {'Facility Location'}
                            <span className="ml-2 service--inner--main--country">
                              {cityCountryNameFormatter(boatServiceCity, boatServiceCountry)}
                            </span>
                          </span>
                        </div>

                        <div className="boat-inner-map-div">
                          {yachtService && (
                            <GoogleMarker
                              height={40}
                              isInnerPage={false}
                              className="boat-inner-map-height"
                              markers={getSingleServiceMarker(yachtService)}
                              geometricLocation={geometricLocation}
                              isRedirectToMap
                            />
                          )}
                        </div>
                      </div>
                    </Grid>
                    {yachtService?.youtubeUrl && (
                      <Grid item xs={12} className="service--inner--related--videos">
                        <div className="mt-5 align-items-end justify-content-between pt-3">
                          <div className="d-flex align-items-center inner--service--div--title">
                            <div className="inner--title--icons service--inner--title--icons">
                              <img
                                loading="lazy"
                                src={require('../../assets/images/boatInner/Related Videos.svg')}
                                className="d-flex h-100"
                                alt="Related videos"
                              />
                            </div>
                            <span className="inner--main--title service--inner--main--title"> Related Videos</span>
                          </div>

                          <>
                            <HomPageVideoStyle
                              img={newThumbnail}
                              style={{ width: 'unset !important' }}
                              className="boat-inner-related-video service--inner--related--video--section cursor-pointer"
                              onClick={this.relatedVideoHandler}
                            >
                              <PlayCircleOutlineIcon className="playVideo-icon" />
                            </HomPageVideoStyle>
                            <VideoModel
                              videoFlag={relatedVideoPopUp}
                              videoUrl={yachtService.youtubeUrl}
                              thumbnail={newThumbnail}
                              closeVideo={this.relatedVideoHandler}
                            />
                          </>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {/* <hr /> */}
              <Grid container className="last-service" style={{ marginTop: 100 }}>
                <Grid item xs={12} md={6} className="service--inner--left--div">
                  <Grid container>
                    <Grid item sm={12}>
                      <div className="mb-3 service--inner--feature--div--spacing mt-0">
                        <div className="d-flex align-items-center inner--boat--systems-title service--inner--title--spacing service--inner-title--div--spacing">
                          <div className="inner--title--icons service--inner--title--icons">
                            <img
                              loading="lazy"
                              src={require('../../assets/images/boatInner/Our Features & Advantages.svg')}
                              className="d-flex h-100"
                              alt="Features"
                            />
                          </div>
                          <span className="inner--main--title service--inner--main--title"> Our Features & Advantages</span>
                        </div>

                        <p className="text-justify a-inner-fix marina--inner--service--desc  service--inner--features--desc recent-service-description service--inner--desc--text color-black">
                          <ReadMore className="text-justify">{yachtService.featuresAndAdvantages}</ReadMore>
                        </p>
                      </div>
                    </Grid>
                    {/* <Grid item sm={12}>
                      <div className="mt-20 mb-3 service--inner--feature--div--spacing">
                        <div className="d-flex align-items-center inner--boat--systems-title service--inner--title--spacing service--inner-title--div--spacing">
                          <div className="inner--title--icons service--inner--title--icons">
                            <img
                              loading="lazy"
                              src={require('../../assets/images/boatInner/Team Commitment.svg')}
                              className="d-flex h-100"
                              alt="Commitment"
                            />
                          </div>
                          <span className="inner--main--title service--inner--main--title"> Team Commitment</span>
                        </div>

                        <p className="text-justify a-inner-fix marina--inner--service--desc  service--inner--features--desc recent-service-description service--inner--desc--text color-black">
                          <ReadMore className="text-justify">{yachtService.teamCommitment}</ReadMore>
                        </p>
                      </div>
                    </Grid> */}
                    <Grid item sm={12}>
                      <div className="mt-5 mb-3 service--inner--feature--div--spacing">
                        <div className="d-flex align-items-center inner--boat--systems-title service--inner--title--spacing service--inner-title--div--spacing">
                          <div className="inner--title--icons service--inner--title--icons">
                            <img
                              loading="lazy"
                              src={require('../../assets/images/boatInner/Our Quality of Service.svg')}
                              className="d-flex h-100"
                              alt="Amenties"
                            />
                          </div>
                          <span className="inner--main--title service--inner--main--title"> Our Quality of Service</span>
                        </div>

                        <p className="text-justify a-inner-fix marina--inner--service--desc  service--inner--features--desc recent-service-description  service--inner--desc--text color-black">
                          <ReadMore className="text-justify">{yachtService.qualityOfService}</ReadMore>
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="service--inner--right--div d-flex">
                  <Grid item sm={12}>
                    <Grid item sm={12} className="pb-0 boat--inner--payment--div marina--inner--payment--div">
                      <div className="payment-grid row mt-4">
                        <div className="col-12">
                          <div className="d-flex align-items-center">
                            <div className="inner--title--icons service--inner--title--icons">
                              <img
                                loading="lazy"
                                src={require('../../assets/images/boatInner/Payment and Services Booking.svg')}
                                className="d-flex h-100"
                                alt="Payment"
                              />
                            </div>
                            <span className="inner--main--title service--inner--payment--booking">
                              Payment and Services Booking
                            </span>
                          </div>

                          <p className="marina--inner--payment--subtitle service--inner--payment--booking--subtitle color-black">
                            {`${yachtService?.user?.companyName}`} provides offline payment options.
                          </p>
                        </div>
                        {yachtService?.user?.paymentMethodTypes?.length > 0 &&
                          yachtService.user.paymentMethodTypes.map(item => (
                            <div className="col-6 p-0">
                              <div className="inner-payment-div">
                                <div className="inner--payment--div">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <img
                                      src={item.icon?.url}
                                      className="inner-payment-cash-icon inner--payment--method"
                                      alt={item.name}
                                    />
                                  </div>
                                </div>
                                <span className="text-center title-description gray-dark marina--inner--payment--method">
                                  {item.name}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>

                      {/* <div className="d-flex payment-grid mt-4 marina--inner--payment--grid marina--inner--payment--options">
                          <div className="col-6 p-0">
                            <div className="inner-payment-div">
                              <div className="inner--payment--div">
                                <div className="d-flex justify-content-center align-items-center">
                                  <img
                                    src={require("../../assets/images/boatInner/credit-card.png")}
                                    className="inner-payment-cash-icon inner--payment--method"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <span className="text-center title-description gray-dark marina--inner--payment--method">
                                Credit Card Payment
                               </span>
                            </div>
                          </div>
                          <div className="col-6 p-0">
                            <div className="inner-payment-div">
                              <div className="inner--payment--div">
                                <div className="d-flex justify-content-center align-items-center">
                                  <img
                                    src={require("../../assets/images/boatInner/cash-on-arrival.png")}
                                    className="inner-payment-cash-icon inner--payment--method"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <span className="text-center title-description gray-dark marina--inner--payment--method">
                                Cash on Arrival
                               </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex payment-grid mt-4">
                          <div className="col-6 p-0">
                            <div className="inner-payment-div">
                              <div className="inner--payment--div">
                                <div className="d-flex justify-content-center align-items-center">
                                  <img
                                    src={require("../../assets/images/marinaStorageInner/delivery-payment.png")}
                                    className="inner-payment-cash-icon inner--payment--method"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <span className="text-center title-description gray-dark marina--inner--payment--method">
                                Payment after Delivery
                               </span>
                            </div>
                          </div>
                          <div className="col-6 p-0">
                            <div className="inner-payment-div">
                              <div className="inner--payment--div">
                                <div className="d-flex justify-content-center align-items-center">
                                  <img
                                    src={require("../../assets/images/marinaStorageInner/down-payment.png")}
                                    className="inner-payment-cash-icon inner--payment--method"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <span className="text-center title-description gray-dark marina--inner--payment--method">
                                50% Down Payment
                               </span>
                            </div>
                          </div>
                        </div> */}
                    </Grid>
                  </Grid>

                  {/* <Grid item sm={4}></Grid> */}
                </Grid>
              </Grid>
              <div className="service--inner--review--section rating-section" style={{ marginTop: 100 }}>
                {(() => {
                  const innerRatingModal = currentUser &&
                    yachtService &&
                    yachtService.user &&
                    getId(currentUser) !== getId(yachtService.user) &&
                    currentUser.accountActivated &&
                    currentUser.isProfilecompleted &&
                    currentUser.isVerified && (
                      <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        {(() => {
                          const initVals = reviews?.reviews?.find(r => getId(currentUser) === getId(r.user))

                          return (
                            <InnerRatingModal
                              key={reviews}
                              module={yachtService.user}
                              userModule={yachtService}
                              moduleType={shareModule.YACHT_SERVICE}
                              initVals={initVals}
                              AddReviewButton={({ onClick }) => (
                                <ButtonComponent className="success" color="orange" onClick={onClick}>
                                  {initVals ? 'Edit' : 'Add'} Review
                                </ButtonComponent>
                              )}
                            />
                          )
                        })()}
                      </div>
                    )

                  return reviews && reviews.reviews ? (
                    <ReviewRating
                      reviews={reviews}
                      currentUser={currentUser}
                      boat={yachtService}
                      noTopMargin
                      innerRatingModal={innerRatingModal}
                    />
                  ) : (
                    innerRatingModal
                  )
                })()}
              </div>

              {yachtService?.user && (
                <div className="inner--add--feedback w-100 d-flex flex-column align-items-end">
                  {yachtService?.user?.id !== currentUser.id && (
                    <div
                      className={`text-right f-14 mb-0 cursor-pointer add-feedback-mt add--feedback color hover-scale-1_1`}
                      style={{ padding: 15 }}
                      onClick={() => {
                        currentUser?.id
                          ? this.setState(prev => ({ feedbackModal: !prev.feedbackModal }))
                          : this.setState(prev => ({ loginModal: !prev.loginModal }))
                      }}
                    >
                      <img
                        loading="lazy"
                        src={require(`../../assets/images/boatInner/serviceinner-like.png`)}
                        className="inner-feedback-img mr-2"
                        alt="Feedback"
                      />
                      <span className="add-feedback-text">Send us feedback</span>
                    </div>
                  )}

                  <LoginModal
                    open={this.state.loginModal}
                    close={() => this.setState(prev => ({ loginModal: !prev.loginModal }))}
                  />
                  <FeedBackForm
                    open={this.state.feedbackModal}
                    closeModal={() => this.setState(prev => ({ feedbackModal: !prev.feedbackModal }))}
                    submitValues={addFeedback}
                  />

                  <div className="font-weight-500 inner--adid--section">
                    <span className="inner-adid font-weight-400 color-black">
                      <CopyAdId adId={yachtService.adId} />
                    </span>

                    <ShareAndWishlist
                      moduleId={yachtService.id}
                      moduleType={wishlistModuleEnum.YACHT_SERVICE}
                      ownerId={yachtService?.user?.id}
                      getPdfSuccess={getPdfSuccess}
                      pdf={pdf}
                      getModulePdf={getModulePdf}
                      isInnerm
                      getUserReportByModule={getUserReportByModule}
                      noTopSpace
                      hideShare
                      hideSave
                    />
                  </div>
                </div>
              )}

              {totalRecentlyAddedService > 0 && (
                <>
                  <div style={{ marginTop: 100 }}>
                    <Box
                      className="section-heading mt-0 w-100 inner--recent--added--service adam-grid-4"
                      fontSize={24}
                      fontWeight={500}
                      letterSpacing={0.5}
                    >
                      Recent Added Services in {getLocalStorageItem('country')}
                    </Box>
                    <div className="w-100 d-flex inner--recent--added--service--div">
                      <BoatServicesAdded
                        boatAddedServices={recentlyAddedService}
                        iconColor="iconColor-boatInner"
                        itemLength={4}
                        total={totalRecentlyAddedService}
                        showType={showAllService.recentlyAdded}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            {yachtService?.images?.length && isGallery && (
              <ModalGateway>
                <Modal onClose={this.toggleGalleryHandler}>
                  <Carousel views={prepareGalleryData(yachtService.images, galleryIdx)} />
                </Modal>
              </ModalGateway>
            )}
          </>
        )}
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  yachtService: state.boatServiceReducer && state.boatServiceReducer.editYachtService,
  getBoatServicePdfSuccess: state.boatServiceReducer && state.boatServiceReducer.getBoatServicePdfSuccess,
  boatServicePdf: state.boatServiceReducer && state.boatServiceReducer.boatServicePdf,
  currentUser: state.loginReducer && state.loginReducer.currentUser,
  isReview: state.reviewReducer && state.reviewReducer.isReview,
  reviews: state.reviewReducer && state.reviewReducer.reviews,
  getPdfSuccess: state.pdfReducer && state.pdfReducer.getPdfSuccess,
  // loading: state.pdfReducer && state.pdfReducer.isLoading,
  pdf: state.pdfReducer && state.pdfReducer.pdf,
  recentlyAddedService: state.boatServiceReducer?.recentlyAddedService,
  totalRecentlyAddedService: state.boatServiceReducer?.totalRecentlyAddedService,
  singleError: state.boatServiceReducer?.singleError,
  errorMessage: state.errorReducer.errorMessage,
  adminLoginDetected: state.loginReducer?.adminLoginDetected,
  addServiceTag_loading: state.boatServiceReducer?.addServiceTag_loading,
  addServiceTag_success: state.boatServiceReducer?.addServiceTag_success,
  addServiceTag_failure: state.boatServiceReducer?.addServiceTag_failure,
})

const mapDispatchToProps = dispatch => ({
  editYachtService: data => dispatch(editYachtService(data)),
  addLikes: data => dispatch(addLikes(data)),
  pageVisit: data => dispatch(pageVisit(data)),
  addFeedback: data => dispatch(addFeedback(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
  getReviewByModuleId: data => dispatch(getReviewByModuleId(data)),
  getModulePdf: data => dispatch(getModulePdf(data)),
  getRecentlyAddedServices: data => dispatch(getRecentlyAddedServices(data)),
  getUserReportByModule: data => dispatch(getUserReportByModule(data)),
  addServiceTag: data => dispatch(addServiceTag(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatServiceInner)
