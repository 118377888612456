import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { FaEyeSlash } from 'react-icons/fa'
import { FaEye } from 'react-icons/fa'
import * as Yup from 'yup'

import { clearForgotPassword, resetPassword, getManagePassWordContent } from '../../redux/actions'
import { SuccessNotify } from '../../helpers/notification'
import './profile.scss'
import { cancelHandler } from '../../helpers/routeHelper'
import { DashboardLayout } from '../../components/layout/dashboardLayout'
import ErrorComponent from '../../components/error/errorComponent'
import { renderErrorMessage, validatePassword, passwordStrength } from '../../helpers/jsxHelper'
import ErrorFocus from '../../components/ws/ErrorFocus'
import { Grid } from '@material-ui/core'
import PasswordValidation from '../../components/passwordValidations/passwordValidation'
import { passwordStrengthEnum } from '../../util/enums/enums'
import { ButtonComponent } from '../../components/form/Button'
import { CommonModal } from '../../components/modal/commonModal'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

class ChangePassword extends Component {
  componentDidMount() {
    const { getManagePassWordContent } = this.props
    getManagePassWordContent()
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.isPasswordReset) {
      setTimeout(() => {
        SuccessNotify('Reset Password Successfully')
      }, 1000)
      nextProps.history.push('/dashboard')
    }
    return null
  }

  state = {
    isDisplayHint: false,
    errorMessages: {},
    type: <AiOutlineEye />,
    isPswText: 'password',
    isShowEye: false,
    contentModal: false,
  }

  openContentModal = () => {
    this.setState(prevState => ({ contentModal: !prevState.contentModal }))
  }

  createMarkup = data => {
    return { __html: data }
  }

  validate = (e, setValue, email) => {
    const { errorMessages } = this.state
    const password = e.target.value
    const errors = validatePassword(password, email)

    this.setState({
      errorMessages: errors,
    })

    const pwdStrength = passwordStrength(password, setValue)
    this.setState({
      passwordStrength: pwdStrength,
      isDisplayHint: true,
    })
  }

  passwordToggle = () => {
    this.setState(prevState => ({
      type: !prevState.isShowEye ? <AiOutlineEye /> : <AiOutlineEyeInvisible />,
      isPswText: !prevState.isShowEye ? 'password' : 'text',
      isShowEye: !prevState.isShowEye,
    }))
  }

  render() {
    const { resetPassword, history, errorMessage, isError, managePasswordContent } = this.props
    const { isDisplayHint, errorMessages, passwordStrength, type, isPswText, contentModal } = this.state
    return (
      <DashboardLayout>
        <div className="change--password">
          <Formik
            initialValues={{
              oldPassword: '',
              newPassword: '',
            }}
            validationSchema={Yup.object().shape({
              oldPassword: Yup.string().required('Current password is required'),
              newPassword: Yup.string().required('New password is required'),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                .required('Confirm new password is required'),
            })}
            onSubmit={values => {
              if (
                !errorMessages.symbolOrNumberCount &&
                !errorMessages.passwordLength &&
                passwordStrength !== passwordStrengthEnum.POOR
              ) {
                resetPassword({ oldPassword: values.oldPassword, password: values.newPassword })
              }
            }}
            render={({ errors, status, values, setFieldValue }) => (
              <>
                <Grid container>
                  <Grid sm={7} className="password--change--confirm--div">
                    <div>
                      <Form>
                        <ErrorFocus />
                        <div className="change--password--main--title--div">
                          <span className="change--password--main--title">Change password</span>
                        </div>

                        <div className="password--change--fields">
                          <div className="mb-3 width-100 change--password--info change--current--password--info">
                            <label htmlFor="oldPassword" className="">
                              Current password
                            </label>
                            <div className="eye--input">
                              <Field
                                name="oldPassword"
                                type={isPswText}
                                className={'form-control password--spacing' + (errors.oldPassword ? ' is-invalid' : '')}
                              />
                              {!errors.oldPassword && (
                                <span className="eye--input-icon" onClick={this.passwordToggle}>
                                  {type}
                                </span>
                              )}
                            </div>
                            <ErrorMessage name="oldPassword" component="div" className="d-flex invalid-feedback" />
                          </div>
                          <div className="mb-3 width-100 change--password--info">
                            <label htmlFor="newPassword" className="">
                              New password
                            </label>
                            <div className="eye--input">
                              <Field
                                name="newPassword"
                                type={isPswText}
                                onChange={e => {
                                  setFieldValue('newPassword', e.target.value)
                                  this.validate(e, setFieldValue, '')
                                }}
                                className={'form-control password--spacing' + (errors.newPassword ? ' is-invalid' : '')}
                              />

                              {!errors.newPassword && (
                                <span className="eye--input-icon" onClick={this.passwordToggle}>
                                  {type}
                                </span>
                              )}
                            </div>
                            <ErrorMessage name="newPassword" component="div" className="d-flex invalid-feedback" />
                            {isDisplayHint && <PasswordValidation errorMessages={errorMessages} />}
                          </div>
                          <div className="width-100 confirm--password--info confirm--password--last--div">
                            <label htmlFor="confirmPassword" className="">
                              Confirm new password
                            </label>
                            <Field
                              name="confirmPassword"
                              type={isPswText}
                              className={'form-control password--spacing' + (errors.confirmPassword ? ' is-invalid' : '')}
                            />
                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                          </div>

                          <div className="width-100 mb-3">
                            <ButtonComponent
                              variant="outline"
                              color="success"
                              className="h-auto btn--padding btn--font justify-content-center login--sign--up--btn"
                              type="submit"
                            >
                              Change password
                            </ButtonComponent>
                          </div>
                          {isError && <ErrorComponent errors={errorMessage} />}
                        </div>
                      </Form>
                    </div>
                  </Grid>

                  {managePasswordContent?.id && (
                    <Grid sm={5} className="password--change--right--box">
                      <div>
                        <div className="change--password--right--img">
                          <img
                            src={managePasswordContent?.icon?.length > 0 && managePasswordContent.icon[0].url}
                            className="h-100 w-100"
                            alt='Icon'
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <span className="title-text password--right--div--title">{managePasswordContent.title}</span>
                          {values.newPassword && (
                            <span className="title-text password--right--div--subtitle">
                              Your account security:{' '}
                              <span
                                className={
                                  passwordStrength && passwordStrength === passwordStrengthEnum.STRONG
                                    ? 'error-message-fullfill'
                                    : passwordStrength === passwordStrengthEnum.AVERAGE
                                    ? 'psw--Average'
                                    : 'error-message'
                                }
                              >
                                {passwordStrength ? passwordStrength : passwordStrengthEnum.POOR}
                              </span>
                            </span>
                          )}
                          <span
                            lassName="title-text password--right--div--desc"
                            dangerouslySetInnerHTML={this.createMarkup(managePasswordContent.content)}
                          />

                          {/* <hr className="password--right--div--hr" /> */}
                          {/* <div className="password--right--div--btn--section">
                            <button
                              className="btn btn-secondary btn-dark cancel--profile--btn w-auto password--right--div--btn"
                              onClick={() => this.openContentModal()}
                            >
                              {managePasswordContent.buttonText}
                            </button>
                          </div> */}
                        </div>
                      </div>
                      <CommonModal
                        className="sales-engine-header-help-dialog dashboard--table--help--section"
                        open={contentModal}
                        close={this.openContentModal}
                        title="Other Description"
                      >
                        <div dangerouslySetInnerHTML={this.createMarkup(managePasswordContent.otherDescription)} />
                        <ButtonComponent
                          className="m-auto h-auto as--cancel--outline btn--padding btn--font "
                          onClick={this.openContentModal}
                        >
                          Close
                        </ButtonComponent>
                      </CommonModal>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          />
        </div>
      </DashboardLayout>
    )
  }
}
const mapStateToProps = state => ({
  isError: state.loginReducer.isError,
  errorMessage: state.errorReducer.errorMessage,
  forgotPasswordSuccess: state.loginReducer.forgotPasswordSuccess,
  isPasswordReset: state.loginReducer.isPasswordReset,
  managePasswordContent: state.loginReducer?.managePasswordContent,
})

const mapDispatchToProps = dispatch => ({
  resetPassword: data => dispatch(resetPassword(data)),
  clearForgotPassword: () => dispatch(clearForgotPassword()),
  getManagePassWordContent: () => dispatch(getManagePassWordContent()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
