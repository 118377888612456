import { put, takeLatest, all } from 'redux-saga/effects'

import { graphqlClient } from '../../helpers/graphqlClient'
import {
  BOAT_RENT_BOOKING,
  BOAT_RENT_BOOKING_SUCCESS,
  BOAT_RENT_BOOKING_FAILURE,
  GET_USER_BOOKINGS,
  GET_USER_BOOKINGS_SUCCESS,
  GET_USER_BOOKINGS_FAILURE,
} from '../actionTypes'
import { bookRentBoat, getUserRentBoatBooking } from '../../graphql/boatRentBookingSchema'

function bookRentBoatApi(input) {
  return graphqlClient
    .mutate({
      mutation: bookRentBoat,
      variables: { input },
    })
    .then(res => res)
    .catch(error => {
      if (error.networkError) {
        return error.networkError.result
      }
    })
}

function getUserBookingsApi() {
  return graphqlClient
    .query({
      query: getUserRentBoatBooking,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      if (error.networkError) {
        return error.networkError.result
      }
    })
}

function* bookRentBoatData(action) {
  const res = yield bookRentBoatApi(action.payload)
  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({
      type: BOAT_RENT_BOOKING_SUCCESS,
      payload: res.data,
    })
  } else {
    yield put({ type: BOAT_RENT_BOOKING_FAILURE, error: res.errors })
  }
}

function* getUserBookingsData() {
  const res = yield getUserBookingsApi()
  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({
      type: GET_USER_BOOKINGS_SUCCESS,
      payload: res.data,
    })
  } else {
    yield put({ type: GET_USER_BOOKINGS_FAILURE, error: res })
  }
}

function* bookRentBoatSaga() {
  yield takeLatest(BOAT_RENT_BOOKING, bookRentBoatData)
}

function* getUserBookingsSaga() {
  yield takeLatest(GET_USER_BOOKINGS, getUserBookingsData)
}

export default function* boatRentBookingSaga() {
  yield all([bookRentBoatSaga(), getUserBookingsSaga()])
}
