import React, { Component } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'

import { envConfig } from '../config'

const stripePromise = loadStripe(envConfig.STRIPE_PUBLISHABLE_KEY)

export default function (ComposedComponent) {
  class WithStripe extends Component {
    render() {
      return (
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => <ComposedComponent {...this.props} stripe={stripe} elements={elements} />}
          </ElementsConsumer>
        </Elements>
      )
    }
  }

  return WithStripe
}

const rentStripePromise = loadStripe(envConfig.RENT_STRIPE_PUBLISHABLE_KEY)

export function withRentStripe(ComposedComponent) {
  return class withRentStripe extends Component {
    render() {
      return (
        <Elements stripe={rentStripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => <ComposedComponent {...this.props} stripe={stripe} elements={elements} />}
          </ElementsConsumer>
        </Elements>
      )
    }
  }
}
