import React, { Component } from 'react'
import { connect } from 'react-redux'

import { TableCard } from '../../components'
import UserContext from '../../UserContext'

import '../../styles/common.scss'
import { getUserAuctionRooms, clearAuctionFlag, clearSearchDashboard } from '../../redux/actions'
import { pagination, auctionRoomStatus } from '../../util/enums/enums'
import { DashboardLayout } from '../../components/layout/dashboardLayout'
import '../../styles/manageDashboardTableResponsive.scss'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { auctionTime } from '../../helpers/dateTimeHelper'
import { getPagesValue } from '../../helpers/jsxHelper'

class ManageAuctionRoom extends Component {
  componentDidMount() {
    const { getUserAuctionRooms } = this.props
    const data = {
      page: getPagesValue(),
      limit: pagination.PAGE_RECORD_LIMIT,
    }
    getUserAuctionRooms(data)
  }

  static getDerivedStateFromProps(nextProps) {
    const { auctionSuccess, clearAuctionFlag } = nextProps

    if (auctionSuccess) {
      clearAuctionFlag()
    }
    return null
  }

  static contextType = UserContext
  render() {
    const { history } = this.context
    const { isSearched, clearSearchDashboard, indexSearchData, totalSearchedData } = this.props
    const columns = [
      {
        Header: 'Model',
        accessor: 'boat.boatName',
      },
      {
        Header: 'Ad ID',
        accessor: 'boat.adId',
      },
      {
        Header: 'Start Time',
        accessor: 'startTime',
        Cell: ({ value }) => <span>{auctionTime(value)}</span>,
      },
      {
        Header: 'End Time',
        accessor: 'endTime',
        Cell: ({ value }) => <span>{auctionTime(value)}</span>,
      },
      {
        Header: 'Boat Price',
        accessor: 'boat.price',
        Cell: ({ value }) => (
          <CurrencyContextConsumer>
            {({ currentCurrency }) => <span>{value && getConvertedPrice(value, currentCurrency)} </span>}
          </CurrencyContextConsumer>
        ),
      },
      {
        Header: 'Current Bid Price',
        accessor: 'auctionCurrentBid',
        Cell: ({ value }) => (
          <CurrencyContextConsumer>
            {({ currentCurrency }) => <span>{value ? getConvertedPrice(value, currentCurrency) : '-'} </span>}
          </CurrencyContextConsumer>
        ),
      },
      {
        Header: 'Ad Status',
        accessor: 'adStatus',
        Cell: data => (
          <>
            {data.original.status === auctionRoomStatus.APPROVED ? (
              <span className={`Verified text-capitalize m-auto `}>{data.original.status}</span>
            ) : data.original.status === auctionRoomStatus.DECLINED ? (
              <span className={`Decline text-capitalize m-auto `}>{data.original.status}</span>
            ) : data.original.status === auctionRoomStatus.AWARDED ? (
              <span className={`in--process text-capitalize m-auto `}>{data.original.status}</span>
            ) : (
              <span className={`Unverified text-capitalize m-auto `}>
                {data.original.status ? data.original.status : auctionRoomStatus.UNVERIFIED}
              </span>
            )}
          </>
        ),
      },
      {
        headerClassName: 'sales--engine--view reset-rt-th',
        Cell: data => (
          <div className="d-flex flex-row justify-content-around">
            {data.original.status && (
              <button
                type="button"
                onClick={() =>
                  history.push('/manage-auction-bids', {
                    auctionId: data.original.id,
                  })
                }
                className="btn btn-outline-info"
              >
                View Bids
              </button>
            )}
          </div>
        ),
      },
    ]

    const { loading, userAuctions, userAuctionsTotal, getUserAuctionRooms } = this.props

    return (
      <DashboardLayout>
        {columns && (
          <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table dashboard--index--table">
            <TableCard
              isSearch
              title="Manage Auction Rooms"
              columns={columns}
              data={isSearched ? indexSearchData : userAuctions}
              loading={loading}
              className="manage--boat--table"
              noButton
              total={isSearched ? totalSearchedData : userAuctionsTotal}
              action={getUserAuctionRooms}
              clearSearchDashboard={clearSearchDashboard}
            />
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.boatReducer && state.boatReducer.loading,
  userAuctions: state.boatReducer && state.boatReducer.userAuctions,
  userAuctionsTotal: state.boatReducer && state.boatReducer.userAuctionsTotal,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
  isSearched: state?.dashboardReducer?.isSearched,
})

const mapDispatchToProps = dispatch => ({
  getUserAuctionRooms: data => dispatch(getUserAuctionRooms(data)),
  clearAuctionFlag: () => dispatch(clearAuctionFlag()),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAuctionRoom)
