import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Grid, Box } from '@material-ui/core'
import { pagination, dimension } from '../../util/enums/enums'
import { Layout } from '../layout/layout'
import { getAllAuctionRooms } from '../../redux/actions'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import { AuctionCarousel } from '../carouselComponent/homePageCarousel/auctionCarousal'
import { FaSpinner } from 'react-icons/fa'

class ShowAllAuctionRooms extends React.Component {
  state = {
    height: 200,
    width: 200,
    page: 1,
    boats: [],
    boatsLoadedPage: 0,
  }

  auctionCarouselItem = {
    isBrowser: 4,
    isTablet: 4,
    isMobile: 1,
  }

  componentDidMount() {
    const { getAllAuctionRooms } = this.props

    const width = getRatio(dimension, 'auctionRoom', '.auction-room')
    const height = getHeightRatio(dimension, 'auctionRoom', '.auction-room')
    this.setState({ width, height })

    getAllAuctionRooms({
      page: this.state.page,
      limit: pagination.PAGE_RECORD_LIMIT,
    })

    window.addEventListener('scroll', this.onPageScroll)
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLoader } = this.props

    if (
      !isLoader &&
      this.props.allAuctionRooms?.length &&
      this.state.boatsLoadedPage < this.state.page &&
      prevProps.allAuctionRooms !== this.props.allAuctionRooms
    ) {
      this.setState({
        boats: [...this.state.boats, ...this.props.allAuctionRooms],
        boatsLoadedPage: this.state.boatsLoadedPage + 1,
      })
    }

    if (prevState?.page !== this.state.page) {
      this.props.getAllAuctionRooms({
        page: this.state.page,
        limit: pagination.PAGE_RECORD_LIMIT,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onPageScroll)
  }

  onPageScroll = () => {
    if (
      this.state.boats?.length &&
      this.state.page < this.props.allAuctionRoomCounts / pagination.PAGE_RECORD_LIMIT &&
      !this.props.isLoader
    ) {
      const el = document.querySelector('.page-scroll-div')

      if (el.getBoundingClientRect().bottom <= window.innerHeight + 150) {
        this.setState({ page: this.state.page + 1 })
      }
    }
  }

  render() {
    const { allAuctionRooms, allAuctionRoomCounts, isLoader } = this.props
    const { boats } = this.state

    return (
      <Fragment>
        <Layout className="home-page home-layout-page page-scroll-div">
          <div className="container100 auction-room mt-3 show--all-auction--room">
            <Grid container className="auction--show--all--section">
              <Grid item xs={12} className="auction--show--all--heading--grid ">
                {!!allAuctionRoomCounts && (
                  <Box
                    className="section-heading mt-3 auction--show--all--heading"
                    fontSize={24}
                    fontWeight={500}
                    letterSpacing={0.5}
                  >
                    {/* Auction Room */}
                    {allAuctionRoomCounts} boats in Auction in our Market
                  </Box>
                )}
              </Grid>

              {!isLoader && <Grid item xs={12}>
                <p className="mb-30" style={{ marginTop: 70 }}>Buyers can bid on a wide range of boats from AdamSea in a very secure environment. Start bidding and buying online.</p>
              </Grid>}

              {boats && boats.length ? (
                <AuctionCarousel items={this.auctionCarouselItem} carouselData={boats} itemsLength={boats.length} />
              ) : (
                !isLoader && (
                  <Grid item xs={12}>
                    No Record Found
                  </Grid>
                )
              )}
            </Grid>

            {isLoader && (
              <div className="py-5 text-center">
                <FaSpinner className="anim-spin mr-2" />
                {boats.length ? 'Loading More' : 'Loading'}...
              </div>
            )}
          </div>
        </Layout>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  allAuctionRooms: state.boatReducer && state.boatReducer.allAuctionRooms,
  allAuctionRoomCounts: state.boatReducer && state.boatReducer.allAuctionRoomCounts,
  isLoader: state.boatReducer && state.boatReducer.isLoader,
})

const mapDispatchToProps = dispatch => ({
  getAllAuctionRooms: data => dispatch(getAllAuctionRooms(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllAuctionRooms)
