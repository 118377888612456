import React from 'react'
import Container from '@material-ui/core/Container'
import './DynamicPageHeader.scss'

const DynamicPageHeader = ({ title, subTitle }) => {
  return (
    <Container maxWidth="lg" className="header-title dynamic-heading">
      <div>
        <div className="help-title">
          {title && <h1 className="mb-0 heading-title">{title}</h1>}
          {subTitle && <h2 className="heading-subtitle">{subTitle}</h2>}
        </div>
      </div>
    </Container>
  )
}

export default DynamicPageHeader
