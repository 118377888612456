import React, { Component, Fragment } from 'react'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { dimension } from '../../util/enums/enums'
import { SingleTopRatedMarina } from '../marinaStorage/singleTopRatedMarina'
import { ShowAllLink } from '../helper/showAllLink'

class TopRatedMarina extends Component {
  state = {
    height: 360,
    width: 280,
  }

  widthZoom = () => {
    const width = getRatioZoomResponsive(dimension, 'topRatedMarina', '.zoom-container')
    const height = getHeightZoomResponsive(dimension, 'topRatedMarina', '.zoom-container')

    this.setState({ width: width.width, gridClassName: width.className, margin: width.margin, height })
  }

  componentDidMount() {
    this.widthZoom()
    window.addEventListener('resize', this.widthZoom)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.widthZoom)
  }

  render() {
    const { height, width, gridClassName, margin } = this.state
    const { topRatedMarina, limit, total, showAllText } = this.props

    return (
      <>
        <div className="zoom-container most-popular-main  top-rated-grid-marina  marina-ads-sections">
          {topRatedMarina &&
            topRatedMarina.map((item, index) => {
              return (
                <Fragment key={item.id}>
                  {index < limit && (
                    <SingleTopRatedMarina
                      value={item}
                      width={width}
                      height={height}
                      index={index}
                      margin={margin}
                      gridClassName={gridClassName}
                    />
                  )}
                </Fragment>
              )
            })}
        </div>
        <ShowAllLink
          data={topRatedMarina}
          total={total}
          itemsLength={limit}
          className="marina-boat-top-show-all"
          showAllText={showAllText}
          url="/show-all-marina-and-storage/topRated"
        />
      </>
    )
  }
}

export default TopRatedMarina
