import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../../styles/manageDashboardTableResponsive.scss'
import { TableCard, DashboardLayout, dealerData } from '../../components'
import SearchUser from '../../components/popUp/searchUser'
import { pagination, userRoles } from '../../util/enums/enums'
import { clearSearchDashboard, getUsersByCountry } from '../../redux/actions'
import {
  dealerRequestsManufacturer,
  searchManufacturerRequest,
  getDealersSentRequests,
  requestMultipleManufacturer,
} from '../../redux/actions/dealersAction'
import { nameFormatter } from '../../helpers/string'
import { getPagesValue, redirectToUserProfile } from '../../helpers/jsxHelper'
import { getLocalStorageItem } from '../../helpers/storageHelper'

class RequestedManufacturer extends Component {
  state = {
    open: false,
  }

  componentDidMount() {
    const { getUsersByCountry, getDealersSentRequests } = this.props
    getUsersByCountry({ roleType: userRoles.BOAT_MANUFACTURER })
    getDealersSentRequests({
      page: getPagesValue(),
      limit: pagination.MARKET_PAGE_LIMIT,
    })
  }

  modalHandler = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  requestManufacturer = id => {
    const { dealerRequestsManufacturer } = this.props
    dealerRequestsManufacturer({ manufacturerId: id })
  }

  searchManufacturer = searchTerm => {
    const { searchManufacturerRequest } = this.props
    searchManufacturerRequest({ searchTerm })
  }

  render() {
    const {
      history,
      requestSuccess,
      multipleRequestSuccess,
      dealerRequestsManufacturer,
      indexSearchData,
      totalSearchedData,
      isSearched,
      clearSearchDashboard,
    } = this.props
    const userId = getLocalStorageItem('userId')

    const columns = [
      {
        Header: 'Name',
        Cell: value => (
          <span
            className="react--table--row--dealer--name cursor-pointer"
            onClick={() => redirectToUserProfile(value?.original, history)}
          >
            {nameFormatter([value?.original.firstName, value?.original.lastName])}
          </span>
        ),
      },
      {
        Header: 'Status',
        accessor: 'dealerRequests',
        Cell: data => {
          const dealerStatus =
            data.original.dealerRequests?.length && data.original.dealerRequests.find(item => item.dealer.id === userId)
          return (
            <span className="react--table--name--row">
              {dealerStatus ? (dealerStatus.accepted ? 'Accepted' : 'Not Accepted') : '-'}
            </span>
          )
        },
      },
    ]

    const { open } = this.state
    const {
      currentUser,
      searchedManufacturers,
      dealersSentRequestsData,
      isLoading,
      requestMultipleManufacturer,
      totalDealersSentRequestsData,
      getDealersSentRequests,
    } = this.props
    return (
      <DashboardLayout>
        <SearchUser
          isOpen={open}
          onClose={this.modalHandler}
          searchedUser={searchedManufacturers}
          isLoading={isLoading}
          currentUser={currentUser}
          requestManufacturer={id => this.requestManufacturer(id)}
          searchManufacturerRequest={data => this.searchManufacturer(data)}
          requestSuccess={requestSuccess}
          multipleRequestSuccess={multipleRequestSuccess}
          requestMultipleManufacturer={requestMultipleManufacturer}
        />
        <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table">
          <TableCard
            isDashboardCard
            currentUser={currentUser}
            manufactureData={dealersSentRequestsData}
            isSearch
            isManufacturerData
            title="Manage Manufacturer"
            columns={columns}
            loading={isLoading}
            button={{ name: 'Search Manufacturer' }}
            data={isSearched ? indexSearchData : dealersSentRequestsData}
            openModal
            setModalOpen={this.modalHandler}
            className="manage--dealers--table manage--table--section--alignment"
            manufactureDealerAction={getDealersSentRequests}
            total={isSearched ? totalSearchedData : totalDealersSentRequestsData}
            clearSearchDashboard={clearSearchDashboard}
          />
        </div>
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer?.currentUser,
  boatManufacturer: state.boatReducer?.boatManufacturer,
  searchedManufacturers: state.dealersReducer?.searchedManufacturers,
  dealersSentRequestsData: state.dealersReducer?.dealersSentRequestsData,
  isLoading: state.dealersReducer?.isLoading,
  requestSuccess: state?.dealersReducer?.requestSuccess,
  multipleRequestSuccess: state?.dealersReducer?.multipleRequestSuccess,
  totalDealersSentRequestsData: state.dealersReducer?.totalDealersSentRequestsData,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  isSearched: state?.dashboardReducer?.isSearched,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
})

const mapDispatchToProps = dispatch => ({
  getUsersByCountry: data => dispatch(getUsersByCountry(data)),
  dealerRequestsManufacturer: data => dispatch(dealerRequestsManufacturer(data)),
  requestMultipleManufacturer: data => dispatch(requestMultipleManufacturer(data)),
  searchManufacturerRequest: data => dispatch(searchManufacturerRequest(data)),
  getDealersSentRequests: data => dispatch(getDealersSentRequests(data)),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestedManufacturer)
