import { gql } from 'apollo-boost'

export const paymentMethodList = gql`
  {
    paymentMethodList
  }
`

export const createPaymentMethods = gql`
  mutation createPaymentMethods($input: PaymentMethodInput!) {
    createPaymentMethods(input: $input)
  }
`

export const removePaymentMethod = gql`
  mutation removePaymentMethod($paymentId: String!) {
    removePaymentMethod(paymentId: $paymentId)
  }
`

export const createPayment = gql`
  mutation createPayment($input: CreatePaymentInput!) {
    createPayment(input: $input)
  }
`
