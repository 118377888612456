import React from 'react'
import cn from 'classnames'

/**
 * @param {React.SVGProps<SVGSVGElement>} props
 */
export default function IconBoat(props) {
  return (
    <svg
      {...props}
      className={cn('as-media-icon', props.className)}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="currentColor"
    >
      <path d="M18.5708 10.8046L17.5684 10.4035V8.15019C17.5663 6.31129 16.0767 4.82169 14.2378 4.81959H13.3614V2.13439C13.353 0.74629 11.6807 0.13799 10.4984 0.17439C9.31537 0.13869 7.64447 0.74699 7.63397 2.13439V4.81959H6.75757C4.91937 4.82239 3.43047 6.31199 3.42837 8.15019C3.43327 8.83689 4.47417 8.83759 4.47837 8.15019C4.47977 6.89229 5.49897 5.87239 6.75757 5.86959H14.2392C15.4978 5.87169 16.517 6.89159 16.5184 8.15019V9.98209L12.0839 8.20479C11.064 7.79879 9.92787 7.79879 8.90797 8.20479L2.42597 10.8046C1.36057 11.2673 0.759267 12.4069 0.978367 13.5472L1.36407 15.2874C2.00947 18.5704 4.92847 20.9098 8.27377 20.8244H12.7258C16.0711 20.9098 18.9901 18.5704 19.6355 15.2874L20.0212 13.5472C20.2396 12.4062 19.6369 11.2666 18.5708 10.8046ZM12.3114 4.81959H8.68467L8.68257 4.09929C9.21597 3.73459 9.85297 3.55259 10.4984 3.58059C11.1431 3.55329 11.7787 3.73529 12.3114 4.09929V4.81959ZM12.3135 2.89599C11.1746 2.40109 9.82357 2.40179 8.68467 2.89599V2.13439C8.77217 1.42739 9.89917 1.20759 10.4984 1.22439C11.0997 1.20689 12.2218 1.42809 12.3135 2.13439V2.89599ZM9.96777 19.7744H8.27237C5.40657 19.8794 2.91877 17.8606 2.38257 15.0606L1.99687 13.3197C1.88137 12.6806 2.21667 12.0443 2.81027 11.7797C4.09897 11.331 8.57617 9.33949 9.96777 9.00349V19.7744ZM18.9943 13.3197C18.9824 13.3064 18.6072 15.0627 18.603 15.0606C18.0787 17.8697 15.5797 19.8731 12.7244 19.7744H11.0234V9.00139C11.2586 8.92369 16.4827 11.1294 16.8495 11.2477C16.8572 11.2435 18.1732 11.7783 18.1802 11.7783C18.7745 12.0429 19.1105 12.6799 18.9943 13.3197Z" />
      <path d="M7.01032 15.1502C9.26502 15.0928 9.26362 11.7888 7.01032 11.7314C4.75632 11.7888 4.75632 15.0935 7.01032 15.1502ZM7.01032 12.7807C7.87272 12.7842 7.87272 14.096 7.01032 14.0995C6.14792 14.096 6.14792 12.7849 7.01032 12.7807Z" />
      <path d="M13.9864 11.7319C11.7324 11.7886 11.7324 15.094 13.9864 15.1507C16.2411 15.0926 16.2397 11.79 13.9864 11.7319ZM13.9864 14.0993C13.124 14.0958 13.124 12.784 13.9864 12.7805C14.8488 12.7847 14.8481 14.0951 13.9864 14.0993Z" />
    </svg>
  )
}
