import gql from 'graphql-tag'
import { AdminUser, AddressType, ImageType } from './schemaTypes/schemaTypes'
export const getAllReviews = gql`
{
    getAllReviews{
      items {
        moduleId {
          id
          firstName
          lastName
        }
        id
        user {
          address ${AddressType}
          firstName
          lastName
          id
          _id
          image ${ImageType}
        }
        rating
        reviews
        reviewStatus
        createdAt
        isAbusive
      }
      total
  }
}
`
