import React, { useState, useEffect } from 'react'
import ItemsCarousel from 'react-items-carousel'
import { isBrowser, isTablet } from 'react-device-detect'

import { ArrowCarousel } from '../styleComponent/styleComponent'
import { arrows } from '../../util/enums/enums'
import { getCarousalGrid, getCarousalIcon } from '../../helpers/jsxHelper'

export const CarouselWrapper = ({
  children,
  items,
  btnColor,
  top,
  isZoom,
  isArrow,
  classes,
  gutter,
  responsiveNumber,
  height,
  slidesToScroll,
}) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const [arrowsFinal, setArrowsFinal] = useState(0)
  const [gridNumber, setGridNumber] = useState(isBrowser ? items.isBrowser : isTablet ? items.isTablet : items.isMobile)
  const zoomWidth = () => {
    const width = document.querySelector('.section-heading')
    const actualWidth = width && width.offsetWidth
    const number = isZoom
      ? getCarousalGrid(actualWidth, responsiveNumber)
      : isBrowser
      ? items.isBrowser
      : isTablet
      ? items.isTablet
      : items.isMobile
    setGridNumber(number)
  }
  const carousalIcon = () => {
    const carousalArrow = getCarousalIcon()
    const arrowsWidth = carousalArrow ? carousalArrow : 36
    setArrowsFinal(arrowsWidth)
  }

  useEffect(() => {
    zoomWidth()
    carousalIcon()
    window.addEventListener('resize', zoomWidth)
    window.addEventListener('resize', carousalIcon)
    return () => {
      window.removeEventListener('resize', zoomWidth)
      window.removeEventListener('resize', carousalIcon)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <ItemsCarousel
      // Placeholder configurations
      enablePlaceholder
      // numberOfPlaceholderItems={isBrowser ? items.isBrowser : isTablet ? items.isTablet : items.isMobile}
      numberOfPlaceholderItems={gridNumber}
      minimumPlaceholderTime={1000}
      className="carousel-arrow"
      // Carousel configurations
      numberOfCards={gridNumber}
      // numberOfCards={isBrowser ? items.isBrowser : isTablet ? items.isTablet : items.isMobile}
      gutter={gutter}
      classes={classes}
      showSlither={false}
      firstAndLastGutter={false}
      slidesToScroll={slidesToScroll}
      // Active item configurations
      requestToChangeActive={activeItemIndex => setActiveItemIndex(activeItemIndex)}
      activeItemIndex={activeItemIndex}
      activePosition={'center'}
      chevronWidth={24}
      rightChevron={
        <>
          {isArrow && (
            <ArrowCarousel
              top={height / 2 - arrowsFinal / 2}
              width={arrowsFinal}
              height={arrowsFinal}
              className="carousel--right--arrow"
            >
              <img src={require('./angle-right.svg')} height={32} />
            </ArrowCarousel>
          )}
        </>
      }
      leftChevron={
        <>
          {isArrow && (
            <ArrowCarousel
              top={height / 2 - arrowsFinal / 2}
              width={arrowsFinal}
              height={arrowsFinal}
              className="carousel--left--arrow"
            >
              <img src={require('./angle-left.svg')} height={32} />
            </ArrowCarousel>
          )}
        </>
      }
      outsideChevron={false}
    >
      {children}
    </ItemsCarousel>
  )
}

CarouselWrapper.defaultProps = {
  customWidthItem: 1,
  btnColor: '',
  isZoom: false,
  isArrow: true,
  slidesToScroll: 1,
  gutter: 16,
  responsiveNumber: {
    lg: 5,
    md: 4,
    sm: 3,
  },
  items: {
    isBrowser: 5,
    isTablet: 4,
    isMobile: 2,
  },
  top: 40,
  height: 150,
}
