import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import cn from 'classnames'
import { getId, getOnlineUsers } from '../../util/utilFunctions'
import { defaultProfileIcon } from '../../util/enums/enums'
import { SET_ONLINE_USERS } from '../../redux/actionTypes'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
import UserContext from '../../UserContext'
import ChatUserCreate from '../../components/chats/ChatCreateUse'
import { getServiceProfileForYachtFinancing } from '../../redux/actions'
import styles from './UserCardYachtFin.module.scss'

export default function UserCardYachtFin() {
  const { history } = useContext(UserContext)

  const dispatch = useDispatch()

  const user = useSelector(state => state.boatServiceReducer.getServiceProfileForYachtFinancing_data)
  const onlineUsers = useSelector(state => state.chatReducer.onlineUsers)

  useEffect(() => {
    dispatch(getServiceProfileForYachtFinancing())
  }, [])

  useEffect(() => {
    getId(user) && getOnlineUsers([getId(user)], payload => dispatch({ type: SET_ONLINE_USERS, payload }))
  }, [user])

  if (!user) {
    return null
  }

  return (
    <Grid item xs={12} md={4}>
      <div className="boat--inner--shipping--title d-flex justify-content-center align-items-center">
        <div className="inner--main--title inner-btn-title inner-btn-title-yacht-fin underline--on--hover h-100">
          <div className={styles.userCardCont}>
            <div className={styles.userCardWrp}>
              <div className={styles.userCard}>
                <div className={styles.userInfoWrp}>
                  <div className={styles.userImg} onClick={() => redirectToUserProfile(user, history)}>
                    <img src={user.image?.url || defaultProfileIcon} alt="profile pic" />
                    <div className="stepper-user-online">
                      <div className={cn('online-div-user', { disconnect: !onlineUsers[getId(user)] })} />
                    </div>
                  </div>

                  <div>
                    <div className={styles.userName}>{user.firstName} </div>

                    <div className={styles.userDetailsActionsWrp}>
                      <div>
                        <ChatUserCreate id={user.id} image={user.image?.url} />
                      </div>

                      <div className={styles.vSep} />

                      <div>Yacht Financing</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  )
}
