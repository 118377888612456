import React, { Component } from 'react'
import Button from '@material-ui/core/Button'

import { snakeCase } from '../../helpers/string'
import { icons, TripTypeCategory } from '../../util/enums/enums'
import { CarouselWrapper } from './carouselWrapper'

class CarousalFormBoatTitle extends Component {
  state = {
    activeItemIndex: 0,
    selectedBoatId: '',
  }

  componentDidMount() {
    const { fetchBoatType } = this.props
    const { selectedBoatId } = this.state

    fetchBoatType(TripTypeCategory.shared, selectedBoatId)
    this.setState({
      activeItemIndex: 0,
      selectedBoatId: '5da838c0131dda1ab49a1583',
    })
  }

  selectedBoatHandler = lookup => {
    const { fetchBoatType } = this.props
    const { selectedBoatId } = this.state

    let type

    if (lookup.alias === 'Rent Per Hour') {
      type = TripTypeCategory.rentPerHour
    }
    if (lookup.alias === 'Private Trip') {
      type = TripTypeCategory.private
    }
    if (lookup.alias === 'Shared Trip') {
      type = TripTypeCategory.shared
    }
    fetchBoatType(type, selectedBoatId)
    this.setState({ selectedBoatId: lookup.id })
  }

  changeActiveItem = activeItemIndex => this.setState({ activeItemIndex })

  renderItems = data => {
    const { selectedBoatId } = this.state

    return data.map((value, index) => {
      const { lookUp } = value

      return (
        <div
          className={
            lookUp && selectedBoatId && selectedBoatId === lookUp.id
              ? 'boat--title-active--type'
              : 'boat--title-without-active--type'
          }
          key={index}
        >
          <div
            className={
              lookUp && lookUp.alias === 'Rent Per Hour'
                ? 'rent--per--hour--type search--boat--type--rent'
                : 'search--boat--type--rent'
            }
          >
            <Button
              className={'p-0 ' + (lookUp && selectedBoatId && selectedBoatId === lookUp.id ? 'boatTitle-onChange' : '')}
              onClick={() => this.selectedBoatHandler(lookUp)}
            >
              <div className="boat--title-rent--position">
                <div className="rent-icon-color">
                  <img loading="lazy" alt="Rent" src={lookUp && icons[snakeCase(lookUp.alias)]} className="rent-card-icons" />
                </div>
                <span className="boat--title--alias">{(lookUp && lookUp.alias) || value.alias}</span>
              </div>
            </Button>
          </div>
        </div>
      )
    })
  }

  render() {
    const { data } = this.props
    const items = {
      isBrowser: 3,
      isTablet: 3,
      isMobile: 3,
    }
    return (
      <div className="carousel--rent--type">
        <CarouselWrapper
          items={items}
          gutter={{ lg: 12, md: 12, sm: 12 }}
          classes={{ wrapper: 'w-100' }}
        >
          {data && data.length > 0 && this.renderItems(data)}
        </CarouselWrapper>
      </div>
    )
  }
}

export default CarousalFormBoatTitle
