export function extractSubdomainPath(url) {
  if (!url || !isValidUrl(url)) {
    return null
  }
  const urlObj = new URL(url)
  const path = urlObj.pathname
  return path
}

function isValidUrl(url) {
  const pattern = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[\:?\d]*)\S*$/
  return pattern.test(url)
}
