import React, { Component } from 'react'
import { ButtonComponent } from '../form/Button'

export default class WishListButton extends Component {
  render() {
    const { removeOnclick, viewOnclick } = this.props
    return (
      <>
        <ButtonComponent className="btn wishlist--remove--btn" color="view" onClick={removeOnclick}>
          <div className="wishlist--remove--img">
            <img loading="lazy" src={require('../../assets/images/wishlist/delete.svg')} alt="Remove" />
          </div>
        </ButtonComponent>
      </>
    )
  }
}
