import React, { useContext } from 'react'

import '../../containers/globalSearch/globalSearch.scss'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
import { getUserByIdApi } from '../../redux/sagas/loginSaga'
import UserContext from '../../UserContext'
import { defaultImage } from '../../util/enums/enums'

const SearchUserCard = props => {
  const { src, name, country, userId } = props
  const { history } = useContext(UserContext)

  return (
    <div className="search--user search--user-width">
      <div className="search--user--img cursor-pointer" onClick={() => {
        getUserByIdApi(userId).then((res) => {
          redirectToUserProfile(res.data.getUser, history, 'blank')
        }).catch((err) => {})}
      }>
        <img loading="lazy" alt='Search' src={src || defaultImage} className="global--search--header--section--icon--img" />
      </div>
      <div className="search--user--data">
        <div className="search--user--data--name">
          <p className="mb-0">{name}</p>
        </div>
        <div className="search--user--data--country">
          <p className="mb-0">{country}</p>
        </div>
      </div>
    </div>
  )
}

export default SearchUserCard
