const AppDlBtnCard = ({ logoUrl, text }) => {
  return (
    <div className="addboat-dlapp-wrp font-14">
      <div className="addboat-dlapp-content">
        <div className="addboat-dlapp--logo">
          <img loading="lazy" src={logoUrl} alt="AS Chat Logo" />
        </div>
        <div className="addboat-dlapp--text">
          <p dangerouslySetInnerHTML={{ __html: text }} />
        </div>
        <div className="addboat-dl-app-btns">
          <a href="https://www.apple.com/in/app-store/" target="_blank" className="addboat-dl-app-btn">
            <img loading="lazy" src={require('../../assets/images/appstore.png')} alt="Download AS Chat App on App Store" />
          </a>
          <a href="https://www.apple.com/in/app-store/" target="_blank" className="addboat-dl-app-btn">
            <img loading="lazy" src={require('../../assets/images/playstore.png')} alt="Download AS Chat App on App Store" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default AppDlBtnCard
