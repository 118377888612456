import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import { Grid } from '@material-ui/core'
import * as Yup from 'yup'

import ErrorFocus from '../components/ws/ErrorFocus'
import { CommonModal } from '../components/modal/commonModal'
import { Field } from '../components'
import { requireMessage } from './string'
import { ButtonComponent } from '../components/form/Button'
import ErrorComponent from '../components/error/errorComponent'
import { isBuyerEligible } from './boatHelper'
import { getConvertedPrice, getUsdPrice, getLocalCurrencyPrice } from './currencyConverterHelper'
import { getLocalStorageItem } from './storageHelper'
import { priceFormat } from '../util/utilFunctions'

export const BidPopup = props => {
  const [initValues] = useState({
    price: '',
  })

  const currentCurrency = getLocalStorageItem('currentCurrency')
  const { open, onClose, submitValues, auctionRoom, isError, errorMessage, clearErrorMessageShow, history, currentUser } = props

  const { id: auctionRoomId, startPrice, minimumRaisedAmount, auctionCurrentBid } = auctionRoom

  const bidPrice = auctionCurrentBid ? auctionCurrentBid + minimumRaisedAmount : startPrice + minimumRaisedAmount
  const convertedBidPrice = getLocalCurrencyPrice(bidPrice)

  return (
    <CommonModal open={open} close={onClose} title="Add Bid" className="inner--bid--popup--section">
      <div className="auction-custom-ui">
        <Formik
          initialValues={initValues}
          onSubmit={values => {
            clearErrorMessageShow()
            if (isBuyerEligible(currentUser, history)) {
              let priceInUsd = values.price
              if (currentCurrency !== 'USD') {
                priceInUsd = getUsdPrice(values.price, currentCurrency)
              }
              submitValues({ ...values, auctionRoom: auctionRoomId, price: priceInUsd })
            }
          }}
          validationSchema={Yup.object().shape({
            price: Yup.number()
              .required(requireMessage('Price'))
              .positive()
              .moreThan(convertedBidPrice - 1, `Price must be greater than or equal to ${priceFormat(convertedBidPrice)}`),
          })}
          render={({ setFieldValue, values, handleSubmit }) => (
            <Form>
              <ErrorFocus />
              <Grid container>
                <Grid item sm={12} className="mb-3">
                  <Field
                    label={`Price (${currentCurrency})`}
                    name="price"
                    // helpText={helpTextEnum.priceInput}
                    value={values.price}
                    type="text"
                    className="form-control"
                    onChangeText={e => setFieldValue('price', +e.target.value || '')}
                    required
                  />
                  <ErrorMessage className="invalid-feedback ant-typography-danger d-block" name={'price'} component="span" />
                </Grid>

                <Grid item sm={12}>
                  <div className="d-flex justify-content-center">
                    <ButtonComponent className=" h-auto as--success--outline btn--padding btn--font" onClick={handleSubmit}>
                      {'Bid Now'}
                    </ButtonComponent>
                  </div>
                </Grid>

                {isError && <ErrorComponent type="alert-info" errors={errorMessage} withoutComma />}
              </Grid>
            </Form>
          )}
        />
      </div>
    </CommonModal>
  )
}
