import React, { Component } from 'react'
import { connect } from 'react-redux'

import { categoryWiseBoats } from '../../../redux/actions'
import { shareModule, titleEnums } from '../../../util/enums/enums'
import BoatListingsWithMap from '../../../components/gridComponents/BoatListingsWithMap'
import { shareDetailsWithUsers } from '../../../redux/actions/shareAction'
import { shareWithUsers, showAllTitle } from '../../../helpers/jsxHelper'

import '../../../styles/responsive.scss'

class CategoryWiseBoats extends Component {
  state = {
    categoryId: '',
    showMap: false,
    name: '',
  }

  static getDerivedStateFromProps(nextProps) {
    const { match } = nextProps
    const { params } = match && match

    if (params && params.hasOwnProperty('categoryId') && params.categoryId) {
      return {
        categoryId: params.categoryId,
      }
    }

    return null
  }

  shareBoatWithUsers = data => {
    const { shareDetailsWithUsers } = this.props
    const object = shareWithUsers(data, shareModule.boat)
    shareDetailsWithUsers(object)
  }

  render() {
    const {
      categoryWiseBoat,
      totalCategoryWiseBoats,
      categoryWiseBoats,
      isPageLoader,
      match: { params },
    } = this.props
    const { categoryId, showMap } = this.state
    const name = params?.categoryType || ''

    const title = showAllTitle(titleEnums.isCategoryWiseBoat, totalCategoryWiseBoats || 0, name)
    return (
      <>
        {categoryId && (
          <BoatListingsWithMap
            showAllTitle={title}
            isPageLoader={isPageLoader}
            boatsTypeCount={totalCategoryWiseBoats || 0}
            boatsType={categoryWiseBoat && categoryWiseBoat.length > 0 && categoryWiseBoat[0].boatType.name}
            showMap={showMap}
            toggleMapHandler={this.toggleMapHandler}
            boats={categoryWiseBoat}
            shareWithUsers={data => this.shareBoatWithUsers(data)}
            action={categoryWiseBoats}
            value={{ categoryId, isManufacturer: true }}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  categoryWiseBoat: state.boatReducer.categoryWiseBoat,
  totalCategoryWiseBoats: state.boatReducer.totalCategoryWiseBoats,
  isPageLoader: state.boatReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  categoryWiseBoats: data => dispatch(categoryWiseBoats(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryWiseBoats)
