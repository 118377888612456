import { GET_CATEGORY_WISE_VIDEOS, GET_CATEGORY_WISE_VIDEOS_SUCCESS, GET_CATEGORY_WISE_VIDEOS_FAILURE } from '../actionTypes'

const InitialState = {
  isSuccess: false,
  isError: false,
  categoryVideos: [],
}
export const videoReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_WISE_VIDEOS:
      return {
        ...state,
        isSuccess: false,
        isError: false,
        categoryVideos: [],
      }
    case GET_CATEGORY_WISE_VIDEOS_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isError: false,
        categoryVideos: action.payload.data.getAllVideosList.items,
      }
    case GET_CATEGORY_WISE_VIDEOS_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isError: true,
        categoryVideos: [],
      }

    default:
      return state
  }
}
