import React, { Component } from 'react'
import { connect } from 'react-redux'

import { SkeletonLoader } from '../../loader/SkeletonLoader'
import { applyForJob, getSingleJob, getAllCountries } from '../../../redux/actions/jobsAction'
import { skeletonType } from '../../../util/enums/enums'
import { ButtonComponent } from '../../form/Button'
import { ApplyJobPopUp } from './applyJobPopUp'
import { Layout } from '../../layout/layout'
import { careersJobImages, tabArrow, arrows } from '../../../util/enums/enums'
import './singleJobDetail.scss'
import { NextIconCarrer } from '../../styleComponent/styleComponent'
import UserContext from '../../../UserContext'
import { getLocalStorageItem } from '../../../helpers/storageHelper'
import LoginModal from '../../modal/loginModal'
import { getId } from '../../../util/utilFunctions'

class SingleJobDetail extends Component {
  state = {
    applyNow: false,
    jobId: {},
    searchTerm: '',
    isSingle: true,
    loginModal: false,
  }
  static contextType = UserContext

  componentDidMount() {
    const {
      match: { params: id },
      getSingleJob,
      getAllCountries,
    } = this.props
    this.setState({ jobId: id })
    getAllCountries({ page: 1, limit: 400 })
    id && getSingleJob(id)
  }

  createMarkup = text => {
    return { __html: text }
  }

  applyJobHandler = () => {
    const userId = getId(this.props.currentUser)

    if (!userId) {
      this.handleLoginModal()
    } else {
      this.setState(prevState => ({ applyNow: !prevState.applyNow }))
    }
  }

  handleLoginModal = () => {
    this.setState(({ loginModal }) => ({ loginModal: !loginModal }))
  }

  render() {
    const { singleJob, applyForJob, countries } = this.props
    const { history } = this.context
    const { applyNow, jobId } = this.state

    return (
      <>
        <Layout className="single-job-layout">
          <LoginModal open={this.state.loginModal} close={this.handleLoginModal} />

          <ApplyJobPopUp
            open={applyNow}
            onClose={this.applyJobHandler}
            submitValues={data => applyForJob(data)}
            job={singleJob}
            countries={countries}
            history={history}
          />

          <div className='dynamic-page-container'>
            <div className="container100">
              {singleJob?.id && (
                <div className="single-job-wrapper">
                  <div className="single-job-label">
                    <div className="single-tab-title-disc">
                      <div className="prev-rotete" onClick={() => history.push('/careers')}>
                        <NextIconCarrer className="rotete-icon" img={arrows.backArrow} />
                      </div>
                      <div className="job-grid-label-value">
                        <h1 className="job-grid-label r-h1 mb-0 font-bold">Job Profile: {singleJob?.title}</h1>
                      </div>
                    </div>
                    <div className="single-job-tab-btn">
                      <ButtonComponent variant="fill" color="success" className="single-job-btn" onClick={this.applyJobHandler}>
                        Apply
                      </ButtonComponent>
                    </div>
                  </div>
                  <div className="single-job-content">
                    <div className="w-50 content-job-label-value">
                      <NextIconCarrer className="content-tab-arrow" img={tabArrow} />
                      <div className="content-job-label">Country</div>
                      <div className="content-job-value">{!!singleJob?.country ? singleJob?.country?.name : 'Any'}</div>
                    </div>
                    <div className="w-50 content-job-label-value">
                      <NextIconCarrer className="content-tab-arrow" img={tabArrow} />
                      <div className="content-job-label">Job Reference</div>
                      <div className="content-job-value">{singleJob?.reference}</div>
                    </div>
                    <div className="w-50 content-job-label-value">
                      <NextIconCarrer className="content-tab-arrow" img={tabArrow} />
                      <div className="content-job-label">Town/City</div>
                      <div className="content-job-value">{!!singleJob?.city ? singleJob?.city : 'Any'}</div>
                    </div>
                    <div className="w-50 content-job-label-value">
                      <NextIconCarrer className="content-tab-arrow" img={tabArrow} />
                      <div className="content-job-label">Job Salary Package</div>
                      <div className="content-job-value">{singleJob?.salaryPackage}</div>
                    </div>
                    <div className="w-100 content-job-label-value">
                      <NextIconCarrer className="content-tab-arrow" img={tabArrow} />
                      <div className="content-job-label">Candidate Profile</div>
                      <div className="content-job-value">
                        <div dangerouslySetInnerHTML={this.createMarkup(singleJob?.candidateProfile)} />
                      </div>
                    </div>
                    <div className="w-100 content-job-label-value">
                      <NextIconCarrer className="content-tab-arrow" img={tabArrow} />
                      <div className="content-job-label">Application Information</div>
                      <div className="content-job-value">
                        <div dangerouslySetInnerHTML={this.createMarkup(singleJob?.applicationInformation)} />
                      </div>
                    </div>
                    <div className="w-100 content-job-label-value">
                      <NextIconCarrer className="content-tab-arrow" img={tabArrow} />
                      <div className="content-job-label">Job Description</div>
                      <div className="content-job-value">
                        <div dangerouslySetInnerHTML={this.createMarkup(singleJob?.description)} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer?.currentUser,
  isLoading: state.jobsReducer?.isLoading,
  singleJob: state.jobsReducer?.singleJob,
  countries: state.jobsReducer?.countries,
})

const mapDispatchToProps = dispatch => ({
  getSingleJob: id => dispatch(getSingleJob(id)),
  applyForJob: data => dispatch(applyForJob(data)),
  getAllCountries: data => dispatch(getAllCountries(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SingleJobDetail)
