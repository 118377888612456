import React, { useEffect, useState } from 'react'
import { Field } from '../../../components';

const TagsInput = (props) => {
    const [input, setInput] = useState('');
    const [tags, setTags] = useState([]);
    const [isKeyReleased, setIsKeyReleased] = useState(false);

    useEffect(() => {
        if (props.value !== undefined || props.value !== null) {
            if (props.value && props.value.length > 1) {
                setTags(props.value.split(','))
            } else if (props.value && props.value.length > 0) {
                setTags([props.value])
            }
        }
    }, [])

    const onChange = (e) => {
        const { value } = e.target;
        setInput(value);
    };

    const onKeyDown = (e) => {
        const { key } = e;
        const trimmedInput = input.trim();
        if (key === 'Enter' && trimmedInput.length === 0) {
            e.preventDefault();
            return false
        }

        if (key === 'Enter' && trimmedInput.length && !tags.includes(trimmedInput)) {
            e.preventDefault();
            let tagsArr = [...tags, trimmedInput]
            setTags(tagsArr);
            setInput('');
            props.handleChange(tagsArr)
        }

        if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
            const tagsCopy = [...tags];
            const poppedTag = tagsCopy.pop();
            e.preventDefault();
            setTags(tagsCopy);
            setInput(poppedTag);
            props.handleChange(tagsCopy)
        }
        setIsKeyReleased(false);
    };

    const onKeyUp = () => {
        setIsKeyReleased(true);
    }

    const deleteTag = (e, index) => {
        e.preventDefault();
        let tagsArr = [...tags].filter((tag, i) => i !== index)
        setTags(tagsArr)
        props.handleChange(tagsArr)
    }

    return (
        <div className="tag-wrap">
            <Field
                name="inputtags"
                helpText="You can include a maximum of 10 keywords to help other users discover your post or profile in the AdamSea search."
                type="text"
                label="Meta Tags"
                value={input}
                className="form-control"
                onChangeText={onChange}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                disabled={tags.length >= 10}
            />
            {tags.map((tag, index) => (
                <div className='tag' key={index}>
                    <div className="tag-item">
                        {tag}
                        <button onClick={(e) => deleteTag(e, index)}>x</button>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TagsInput
