import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getBoatRentLookUps, getTypeWiseLookup, searchBoatRent } from '../../../redux/actions'
import BoatListingsWithMap from '../../../components/gridComponents/BoatListingsWithMap'
import { urlParamsToObject } from '../../../helpers/routeHelper'
import { lookupTypes, rentBoatTripForCard } from '../../../util/enums/enums'

class SearchRentBoats extends Component {
  state = {
    values: null,
  }

  componentDidMount() {
    this.updateValuesFromUrl()
    this.props.getBoatRentLookUps(lookupTypes.TRIP_TYPE)
    this.props.getTypeWiseLookup('Trip')
    this.props.getTypeWiseLookup('Trip Type')
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.updateValuesFromUrl()
    }
  }

  updateValuesFromUrl = () => {
    const { location } = this.props
    const urlParams = new URLSearchParams(location.search)
    let { address, ...values } = urlParamsToObject(urlParams)
    values = { ...values, total: +values.total, page: values.page ? +values.page : 1 }
    this.setState({ values })
  }

  render() {
    const { values } = this.state
    const { searchedBoatRent, totalSearchedBoatRent, searchBoatRent, isPageLoader } = this.props

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        isRent
        boats={searchedBoatRent}
        boatsType={totalSearchedBoatRent ? `Find more ${rentBoatTripForCard[values && values.type]}` : 'No Boats Found'}
        boatsTypeCount={totalSearchedBoatRent || 0}
        action={searchBoatRent}
        value={values}
      />
    )
  }
}

const mapStateToProps = state => ({
  searchedBoatRent: state.rentReducer.searchedBoatRent,
  totalSearchedBoatRent: state.rentReducer.totalSearchedBoatRent,
  isPageLoader: state.rentReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  searchBoatRent: data => dispatch(searchBoatRent(data)),
  getBoatRentLookUps: data => dispatch(getBoatRentLookUps(data)),
  getTypeWiseLookup: data => dispatch(getTypeWiseLookup(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchRentBoats)
