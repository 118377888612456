import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'
import { Grid } from '@material-ui/core'

import { paymentOptions, userRoles, responsiveCarousel, surveyCarousel, paymentTypes } from '../../../util/enums/enums'
import { paymentRequest, requestSurveyor, declineSurveyor, getUsersByCountry, getPaymentReceipt } from '../../../redux/actions'
import { PaymentInformation, SellerInformation, UserInformationCard } from '../../../components'
import PaymentOption from '../../../components/salesEngine/paymentOption'

import './SalesEngineStepper.scss'
import { SurveyorList } from '../../../components/salesEngine/SurveyorList'
import { CarouselWrapper } from '../../../components/carouselComponent/carouselWrapper'
import { PaymentDetail } from '../../../components/salesEngine/PaymentDetail'
import { getAllPageInfoByType } from '../../../redux/actions/pageInfoByTypeAction'
import BoatInformation from '../../../components/salesEngine/BoatInformation'
import { getWithStripeFee } from '../../../util/utilFunctions'

class SurveyMyBoat extends Component {
  state = {
    paymentMethod: paymentOptions[0].name,
    requestedSurveyorIndex: '',
    declineSurveyorIndex: '',
  }

  componentDidMount() {
    const {
      getUsersByCountry,
      salesEngine: { boat },
      isSeller,
    } = this.props
    const {
      address: [{ country }],
      id: boatId,
    } = boat

    if (boat?.address?.length && boat.address[0].country) {
      getUsersByCountry({ roleType: userRoles.SURVEYOR, boatId, country: country })
    }
  }

  requestSurveyorHandler = (surveyorBranchId, requestedSurveyorIndex) => {
    const { requestSurveyor, salesEngine } = this.props
    this.setState({ requestedSurveyorIndex })
    if (salesEngine && salesEngine.id) {
      requestSurveyor({ id: salesEngine.id, surveyorId: surveyorBranchId })
    }
  }

  declineSurveyorHandler = (surveyorBranchId, declineSurveyorIndex) => {
    const { declineSurveyor, salesEngine } = this.props
    this.setState({ declineSurveyorIndex })
    if (salesEngine && salesEngine.id) {
      declineSurveyor({ id: salesEngine.id, surveyorId: surveyorBranchId })
    }
  }

  selectPaymentMethodHandler = name => {
    this.setState({ paymentMethod: name })
  }

  isSurveyorRequested = surveyorBranchId => {
    const { salesEngine } = this.props
    return salesEngine.requestedSurveyor.includes(surveyorBranchId)
  }

  getHeight = element => {
    const { height } = this.state
    if (element && !height) {
      this.setState({ height: element.firstElementChild.clientHeight })
    }
  }

  getTotalSurveyorCost = () => {
    const { salesEngine } = this.props
    const boatLength = salesEngine?.boat?.lengthInFt
    const surveyorUnitPrice = salesEngine?.surveyor?.pricePerFt

    return boatLength * surveyorUnitPrice
  }

  renderItems = nearestSurveyors => {
    const { salesEngine, assignSurveyorLoading, declineSurveyorLoading, history } = this.props
    const { requestedSurveyorIndex, declineSurveyorIndex } = this.state
    return nearestSurveyors
      .filter(nearestSurveyors => nearestSurveyors?.isVerified === true)
      .map((item, i) => {
        return (
          <div ref={this.getHeight} key={item.id}>
            <SurveyorList
              userInfo={item}
              showSurveyorButtons
              declineSurveyorHandler={this.declineSurveyorHandler}
              isSurveyorRequested={this.isSurveyorRequested}
              requestSurveyorHandler={this.requestSurveyorHandler}
              salesEngine={salesEngine}
              history={history}
              assignSurveyorLoading={assignSurveyorLoading}
              index={i}
              requestedSurveyorIndex={requestedSurveyorIndex}
              declineSurveyorLoading={declineSurveyorLoading}
              declineSurveyorIndex={declineSurveyorIndex}
              isBranchImage
              isSalesEngine
            />
          </div>
        )
      })
  }
  render() {
    const {
      nearestSurveyors,
      salesEngine,
      paymentRequest,
      isSeller,
      declineSurveyor,
      currentUser,
      isAgent,
      history,
      surveyor,
      isBuyer,
      getAllPageInfoByType,
      getPaymentReceipt,
      paymentProcessing,
      payments,
      isUsersByCountryLoading,
    } = this.props
    const { height } = this.state
    const selectedSurveyor = surveyor?.find(item => item.id === salesEngine?.surveyor?.user?.id)

    const amountWithFee = getWithStripeFee(salesEngine?.paymentInformation?.surveyorSubtotalPrice)

    return (
      <>
        {!isAgent && !isSeller && !salesEngine.surveyorPayment && !salesEngine.surveyorAccepted && nearestSurveyors && (
          <div className="surveyor-list-nearest-carousel surveyor-grid-3">
            {isUsersByCountryLoading ||
              (nearestSurveyors && nearestSurveyors.length > 0 ? (
                this.renderItems(nearestSurveyors)
              ) : (
                <span>There is no nearby surveyor available.</span>
              ))}
          </div>
        )}
        <Grid>
          <Container fluid className="pl-0 pr-0">
            <Grid container className="my-5">
              <Grid item xs={12} lg={8} className="mx-auto">
                {this.props.timerComponent}
              </Grid>
              <Grid item xs={12} style={{ marginTop: 80 }}></Grid>
              <Grid item xs={12} lg={6} className="mx-auto">
                {salesEngine.surveyor && (
                  <UserInformationCard
                    isSurveyor
                    isSurveyorWithBranch
                    userInfo={salesEngine.surveyor.user}
                    surveyorInfo={salesEngine.surveyor}
                    isBuyer={isBuyer}
                    isSeller={isSeller}
                    salesEngine={salesEngine}
                    declineSurveyor={declineSurveyor}
                    history={history}
                    isSurveyReviewEnable
                  />
                )}
              </Grid>
              {isSeller && (
                <>
                  <Grid item xs={12} lg={1}></Grid>
                  <Grid item xs={12} lg={5}>
                    <p style={{ fontSize: 20, fontWeight: 'bold' }}>
                      Before the survey starts, you need to verify the surveyor.
                    </p>
                    <ol>
                      <li>Download the AdamSea app for iOS or Android.</li>
                      <li>Log in to your account using your username and password.</li>
                      <li>Scan the surveyor's QR code.</li>
                      <li>Ensure the QR code is valid.</li>
                    </ol>
                    <p>
                      <strong>Important:</strong> Each boat inspection has a different QR code. If the QR code is invalid, do
                      not allow the surveyor to conduct the survey and contact AdamSea support.
                    </p>
                  </Grid>
                </>
              )}
            </Grid>

            {isBuyer &&
              payments?.length > 0 &&
              payments.map(item => <PaymentDetail salesEngine={salesEngine} isSurveyorPaymentDetail paymentItem={item} />)}

            {!isSeller && !salesEngine.surveyorPayment && !salesEngine.isSurveyorSkip && salesEngine.surveyorAccepted && (
              <PaymentOption
                paymentRequest={paymentRequest}
                currentUser={currentUser}
                paymentDone={salesEngine.surveyorPayment}
                paymentTo={paymentTypes.surveyorPayment}
                salesEngine={salesEngine}
                disable={salesEngine.surveyorAccepted && !salesEngine.surveyorPayment}
                price={this.getTotalSurveyorCost()}
                getAllPageInfoByType={getAllPageInfoByType}
                isSurveyorPaymentDetail={salesEngine?.surveyor?.pricePerFt}
                getPaymentReceipt={getPaymentReceipt}
                totalPayment={amountWithFee}
                paymentProcessing={paymentProcessing}
                isSeller={isSeller}
                isBuyer={isBuyer}
              />
            )}

            <Grid container spacing={3}>
              <BoatInformation salesEngine={salesEngine} />

              <SellerInformation
                isPaymentDone={salesEngine.boatPayment}
                salesEngine={salesEngine}
                isSeller={isSeller}
                history={history}
                isBuyer={isBuyer}
              />
            </Grid>

            {(salesEngine.surveyorPayment || !salesEngine?.shipperPayment) && (
              <div className="mt-5 mx-auto" style={{ maxWidth: 1024 }}>
                <PaymentInformation />
              </div>
            )}
          </Container>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer && state.loginReducer.currentUser,
  costEstimation: state.salesEngineReducer.costEstimation,
  requestSurveyorSuccess: state.salesEngineReducer.requestSurveyorSuccess,
  assignSurveyorLoading: state.salesEngineReducer.assignSurveyorLoading,
  declineSurveyorLoading: state.salesEngineReducer.declineSurveyorLoading,
  nearestSurveyors: state.boatReducer?.users,
  isUsersByCountryLoading: state.boatReducer?.isUsersByCountryLoading,
  surveyor: state.boatReducer?.surveyor,
  paymentProcessing: state.salesEngineReducer?.paymentProcessing,
  payments: state.salesEngineReducer?.payments,
})

const mapDispatchToProps = dispatch => ({
  getUsersByCountry: data => dispatch(getUsersByCountry(data)),
  paymentRequest: data => dispatch(paymentRequest(data)),
  requestSurveyor: data => dispatch(requestSurveyor(data)),
  declineSurveyor: data => dispatch(declineSurveyor(data)),
  getAllPageInfoByType: data => dispatch(getAllPageInfoByType(data)),
  getPaymentReceipt: data => dispatch(getPaymentReceipt(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SurveyMyBoat)
