import { GET_ALL_DOWNLOADS_DATA, GET_ALL_DOWNLOADS_DATA_FAILURE, GET_ALL_DOWNLOADS_DATA_SUCCESS } from '../actionTypes'

const InitialState = {
  getSuccess: false,
  getError: false,
  isLoading: false,

  downloads: [],
}

export const downloadsReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_ALL_DOWNLOADS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case GET_ALL_DOWNLOADS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getSuccess: true,
        downloads: action.payload.items,
      }

    case GET_ALL_DOWNLOADS_DATA_FAILURE:
      return {
        ...state,
        getError: true,
        isLoading: false,
      }

    default:
      return state
  }
}
