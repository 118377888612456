import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import { getStaticPages, clear_getStaticPages } from '../../redux/actions/pageInfoByTypeAction'
import { Layout } from '../../components'
import { DYNAMIC_PAGES_META } from '../../util/enums/enums'
// import DynamicPageHeader from '../../components/dynamic-page/DynamicPageHeader'

const DynamicPage = ({ match: { params }, getStaticPages, staticPages, clear_getStaticPages }) => {
  const history = useHistory()

  const [pageMeta, setPageMeta] = useState(null)
  const [pageData, setPageData] = useState(null)

  useEffect(() => {
    if (params.pageName) {
      if (DYNAMIC_PAGES_META[params.pageName]) {
        setPageMeta(DYNAMIC_PAGES_META[params.pageName])
      } else {
        history.replace('/')
      }
    }
  }, [params])

  useEffect(() => {
    pageMeta && getStaticPages(pageMeta.filter)
  }, [pageMeta])

  useEffect(() => {
    if (staticPages?.success) {
      if (staticPages.data[0]) {
        setPageData(staticPages.data[0])
      } else {
        history.replace('/')
      }
    }

    if (staticPages?.success || staticPages?.failure) {
      clear_getStaticPages()
    }
  }, [staticPages])

  // const title = pageData?.title || pageMeta?.title
  // const subTitle = pageMeta?.subTitle

  return (
    <Layout>
      <div className="dynamic-page-container">
        {/* <DynamicPageHeader title={title} subTitle={subTitle} /> */}

        <Container maxWidth="lg" className="help-section-content">
          <div className="dynamic-content" dangerouslySetInnerHTML={{ __html: pageData?.pageContent }}></div>
        </Container>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => ({
  staticPages: state.pageInfoByTypeReducer.staticPages,
})

const mapDispatchToProps = dispatch => ({
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DynamicPage)
