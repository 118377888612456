import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../../styles/manageDashboardTableResponsive.scss'
import { TableCard, DashboardLayout } from '../../components'
import { ButtonComponent } from '../../components/form/Button'
import { getDealersRequests } from '../../redux/actions'
import { acceptOrRejectDealerRequest } from '../../redux/actions/dealersAction'
import { getPagesValue } from '../../helpers/jsxHelper'
import { pagination } from '../../util/enums/enums'

class DealersRequest extends Component {
  state = {
    dataAdded: false,
  }

  componentDidUpdate() {
    const { currentUser, getDealersRequests } = this.props
    const { dataAdded } = this.state
    if (currentUser?.id && !dataAdded) {
      getDealersRequests({
        page: getPagesValue(),
        limit: pagination.PAGE_RECORD_LIMIT,
      })
      this.setState({ dataAdded: true })
    }
  }

  render() {
    const { dealerRequests, acceptOrRejectDealerRequest, getDealersRequests, dealerRequestsTotal } = this.props

    const columns = [
      {
        Header: 'Name',
        accessor: 'dealer',
        Cell: ({ value }) => (
          <span className="react--table--name--row">
            {value?.firstName} {value?.lastName}
          </span>
        ),
      },
      {
        Header: 'Country',
        accessor: 'dealer',
        Cell: ({ value }) => (
          <span className="react--table--name--row">{value?.address?.length > 0 && value.address[0].country}</span>
        ),
      },
      {
        Header: 'Action',
        accessor: 'dealer',
        headerClassName: 'sales--engine--view',
        Cell: ({ value }) => (
          <div className="d-flex flex-row justify-content-around action">
            <ButtonComponent
              className="btn mr-2"
              color="edit"
              onClick={() => acceptOrRejectDealerRequest({ columnName: 'accepted', value: true, dealerId: value.id })}
            >
              Accept
            </ButtonComponent>
            <ButtonComponent
              className="btn mr-2"
              color="danger"
              onClick={() => acceptOrRejectDealerRequest({ columnName: 'accepted', value: false, dealerId: value.id })}
            >
              Reject
            </ButtonComponent>
          </div>
        ),
      },
    ]

    return (
      <DashboardLayout>
        <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table">
          <TableCard
            isSearch
            title="Dealers Request"
            columns={columns}
            data={dealerRequests}
            acceptOrRejectDealerRequest={acceptOrRejectDealerRequest}
            noButton
            className="manage--dealers--table"
            total={dealerRequestsTotal}
            action={getDealersRequests}
          />
        </div>
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer?.currentUser,
  dealerRequests: state.loginReducer?.dealerRequests,
  dealerRequestsTotal: state.loginReducer?.dealerRequestsTotal,
})

const mapDispatchToProps = dispatch => ({
  getDealersRequests: () => dispatch(getDealersRequests()),
  acceptOrRejectDealerRequest: data => dispatch(acceptOrRejectDealerRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DealersRequest)
