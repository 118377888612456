export const envConfig = {
  BASE_URL: process.env.RAZZLE_BASE_URL,
  BASE_CHAT: process.env.RAZZLE_BASE_CHAT,
  CHAT_IMAGE_UPLOAD_URL: process.env.RAZZLE_CHAT_IMAGE_UPLOAD_URL,
  // WEB_HOST: process.env.WEB_HOST,
  WEB_ORIGIN: process.env.RAZZLE_WEB_ORIGIN,
  WEB_ORIGIN_SLASH: (process.env.RAZZLE_WEB_ORIGIN || '').replace(/\/?$/, '/'),
  MQTT_ENDPOINT: process.env.RAZZLE_MQTT_ENDPOINT,
  CHAT_ENDPOINT: process.env.RAZZLE_CHAT_ENDPOINT,
  MAP_API_KEY: process.env.RAZZLE_MAP_API_KEY,
  RECAPTCHA_SITE_KEY: process.env.RAZZLE_RECAPTCHA_SITE_KEY,
  IMAGE_UPLOAD_URL: process.env.RAZZLE_IMAGE_UPLOAD_URL,
  STRIPE_PUBLISHABLE_KEY: process.env.RAZZLE_STRIPE_PUBLISHABLE_KEY,
  BUILD_TARGET: process.env.BUILD_TARGET,
  PORT: process.env.PORT,
  CHAT_SOCKET: process.env.RAZZLE_CHAT_SOCKET,
  RENT_STRIPE_PUBLISHABLE_KEY: process.env.RAZZLE_RENT_STRIPE_PUBLISHABLE_KEY,
  RAZZLE_SEO_TEST_URL: process.env.RAZZLE_SEO_TEST_URL,
  RAZZLE_FACEBOOK_DEV_APPID: process.env.RAZZLE_FACEBOOK_DEV_APPID,
  SENTRY_DSN: process.env.RAZZLE_SENTRY_DSN,
  ZOHO_API_KEY: process.env.RAZZLE_ZOHO_API_KEY,

  PAYPAL_CLIENT_ID: process.env.RAZZLE_PAYPAL_CLIENT_ID,
  PAYPAL_MERCHANT_ID: process.env.RAZZLE_PAYPAL_MERCHANT_ID,
  PAYPAL_ENV: process.env.RAZZLE_PAYPAL_ENV,

  SM_ENABLE: process.env.RAZZLE_SM_ENABLE === 'TRUE',
}
