import React, { useState, useContext } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Button } from '@material-ui/core'

import { Field } from '../ws/Field'
import ErrorFocus from '../ws/ErrorFocus'
import { setLocalStorageItem } from '../../helpers/storageHelper'
import { currencyEnum } from '../../util/enums/currencyEnums'
import { getCurrentCurrency } from '../../helpers/currencyConverterHelper'
import UserContext from '../../UserContext'
import { CommonModal } from './commonModal'
import SingleSelect from '../helper/singleSelect'
import { renderSelectOptions } from '../../helpers/string'
import { ButtonComponent } from '../form/Button'

export const CurrencyConverterModal = props => {
  const { open, closeModal } = props

  const { setCurrentCurrency } = useContext(UserContext)

  const [initValue] = useState({
    selectedCurrency: '',
  })

  return (
    <>
      <CommonModal
        open={open}
        close={closeModal}
        className="currency-convert-modal"
        title={`Current Currency: ${getCurrentCurrency()}`}
        overflowVisible
        overflowAuto={false}
      >
        <Formik
          initialValues={initValue}
          onSubmit={values => {
            const selectedValue = values.selectedCurrency ? values.selectedCurrency.value : ''
            setCurrentCurrency(selectedValue)
            setLocalStorageItem('currentCurrency', selectedValue)
            closeModal()
          }}
          validationSchema={Yup.object().shape({
            selectedCurrency: Yup.string().required('Please select currency first.'),
          })}
          render={({ values, setFieldValue, handleSubmit }) => (
            <Form>
              <ErrorFocus />
              <div className="d-flex flex-column w-100 shipment-proposal-desc-div currency--converter--section">
                <SingleSelect
                  label="Selected Currency"
                  name="selectedCurrency"
                  placeholder="Select Currency"
                  selectedOption={values.selectedCurrency}
                  options={renderSelectOptions(currencyEnum, 'name', 'label')}
                  onChange={value => setFieldValue('selectedCurrency', value)}
                  required
                />
                <ErrorMessage component="div" name="selectedCurrency" className="error-message" />

                <div className="d-flex justify-content-center">
                  <ButtonComponent
                    className=" h-auto as--success--outline btn--padding btn--font currency--converter--btn"
                    onClick={handleSubmit}
                  >
                    Convert
                  </ButtonComponent>
                </div>
              </div>
              <div className="convert--currency--tip">
                <span className="currency--tip-label">Tip:</span>
                <span className="currency--tip-tip">All kind of transactions in AdamSea market will be only US $ Currency</span>
              </div>
            </Form>
          )}
        />
      </CommonModal>
    </>
  )
}
