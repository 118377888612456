import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getMarinaByType } from '../../redux/actions'
import BoatListingsWithMap from '../../components/gridComponents/BoatListingsWithMap'

class ShowAllMarinaAndStorageSimilar extends Component {
  componentDidMount() {
    const {
      match: { params },
      getMarinaByType,
    } = this.props

    params?.id && getMarinaByType({ id: params.id })
  }

  render() {
    const {
      isPageLoader,
      total,
      getMarinaByType,
      marinaByType,
      match: { params },
    } = this.props

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        isMarinaStorage
        boatsTypeCount={total || 0}
        boatsType="Marina & Storage Similar"
        boats={marinaByType}
        action={getMarinaByType}
        value={{ id: params?.id }}
      />
    )
  }
}

const mapStateToProps = state => ({
  isPageLoader: state?.marinaAndStorageReducer?.isLoading,
  marinaByType: state?.marinaAndStorageReducer.marinaByType,
  total: state?.marinaAndStorageReducer?.total,
})

const mapDispatchToProps = dispatch => ({
  getMarinaByType: data => dispatch(getMarinaByType(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllMarinaAndStorageSimilar)
