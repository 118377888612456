import React from 'react'

// import { Player } from 'video-react';
import ReactPlayer from 'react-player'
import { defaultImage } from '../../util/enums/enums'

export class Video extends React.Component {
  state = {
    isClick: false,
    oneClick: true,
  }
  static getDerivedStateFromProps(nextProps, nextState) {
    const { isClick, oneClick } = nextState
    if (isClick && oneClick) {
      const el = document.querySelector('.react-player__play-icon')
      el && el.click()
      return {
        oneClick: false,
      }
    }
    return null
  }
  componentDidMount() {
    this.findIconElement()
  }
  findIconElement = () => {
    this.interval = setInterval(() => {
      const className = document.querySelector('.react-player__play-icon')
      className && this.stop()
    }, 1000)
    setTimeout(() => {
      clearInterval(this.interval)
    }, 8000)
  }

  stop() {
    this.setState({ isClick: true })
    clearInterval(this.interval)
  }

  render() {
    const { videoUrl, thumbnail, closeVideo, isInline, controls, loop } = this.props
    return (
      <>
        {videoUrl && (
          <ReactPlayer
            playsinline
            url={videoUrl}
            playing
            controls={controls}
            onEnded={isInline && closeVideo}
            light={thumbnail || defaultImage}
            loop={loop}
          />
        )}
      </>
    )
  }
}
// [NOTE] : Need to remove after testing @ghanshyam
// function Video(props) {
// 	return (
// 		<Player
// 			controls={false}
// 			playsInline
// 			poster={props.thumbnail}
// 			autoPlay
// 			src={props.videoUrl}
// 		/>
// 	);
// }

Video.defaultProps = {
  thumbnail: defaultImage,
  isInline: false,
  controls: true,
  loop: false,
}
