import {
  CLEAR_MARINA_FLAG,
  ADD_MARINA_AND_STORAGE,
  GET_ALL_MARINA,
  GET_TYPE_WISE_LOOKUP,
  GET_EXPLORE_MARINA,
  GET_USER_MARINA_STORAGE,
  GET_RECENTLY_ADDED_MARINA_STORAGE,
  GET_SINGLE_MARINA_STORAGE,
  UPDATE_MARINA_STORAGE,
  CLEAR_EDIT_MARINA_FLAG,
  DELETE_MARINA_STORAGE,
  GET_MOST_VIEWED_MARINA_STORAGE,
  SEARCH_MARINA_AND_STORAGE,
  GET_MORE_SERVICE,
  GET_TOP_RATED_MARINA_STORAGE,
  GET_MARINA_STORAGE_All_SERVICES,
  GET_MARINA_BY_TYPE,
  CHANGE_MARINA_STATUS,
  DELETE_MARINA_STORAGE_SUCCESS,
} from '../actionTypes'

export const clearMarinaFlag = () => ({
  type: CLEAR_MARINA_FLAG,
})

export const createMarinaStorage = data => ({
  type: ADD_MARINA_AND_STORAGE,
  payload: data,
})

export const getAllMarina = () => ({
  type: GET_ALL_MARINA,
})

export const getUserMarinaStorage = data => ({
  type: GET_USER_MARINA_STORAGE,
  payload: data,
})

export const getTypeWiseLookup = data => ({
  type: GET_TYPE_WISE_LOOKUP,
  payload: data,
})

export const getExploreMarina = data => ({
  type: GET_EXPLORE_MARINA,
  payload: data,
})

export const GetRecentlyAddedMarinaAndStorage = data => ({
  type: GET_RECENTLY_ADDED_MARINA_STORAGE,
  payload: data,
})

export const getSingleMarina = data => ({
  type: GET_SINGLE_MARINA_STORAGE,
  payload: data,
})

export const updateMarina = data => ({
  type: UPDATE_MARINA_STORAGE,
  payload: data,
})

export const clearEditMarinaFlag = () => ({
  type: CLEAR_EDIT_MARINA_FLAG,
})

export const deleteMarinaStorage = data => ({
  type: DELETE_MARINA_STORAGE,
  payload: data,
})

export const getMostViewedMarinaStorage = data => ({
  type: GET_MOST_VIEWED_MARINA_STORAGE,
  payload: data,
})
export const searchMarinaStorage = data => ({
  type: SEARCH_MARINA_AND_STORAGE,
  payload: data,
})

export const getMoreServices = data => ({
  type: GET_MORE_SERVICE,
  payload: data,
})

export const getTopRatedMarinaAndStorage = data => ({
  type: GET_TOP_RATED_MARINA_STORAGE,
  payload: data,
})

export const getMarinaAndStorageAllServices = data => ({
  type: GET_MARINA_STORAGE_All_SERVICES,
  payload: data,
})

export const getMarinaByType = data => ({
  type: GET_MARINA_BY_TYPE,
  payload: data,
})

export const changeMarinaStorageStatus = data => ({
  type: CHANGE_MARINA_STATUS,
  payload: data,
})
