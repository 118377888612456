import React, { useEffect, useState } from 'react'

import { Formik, Form, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'react-bootstrap'

import './EstimateCost.scss'
import { percentageFormate, estimationPercentageFormate, getOnlineUsers, getId } from '../../util/utilFunctions'
import { nameFormatter, requireMessage } from '../../helpers/string'
import { CommonModal } from '../modal/commonModal'
import { Grid } from '@material-ui/core'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { ButtonComponent } from '../form/Button'
import { getBoatTypeName, redirectToUserProfile } from '../../helpers/jsxHelper'
import { connect } from 'react-redux'
import { getServiceProfileForEstimatedCost } from '../../redux/actions/boatServiceAction'
import ChatUserCreate from '../chats/ChatCreateUse'
import { useHistory } from 'react-router-dom'
import { SET_ONLINE_USERS } from '../../redux/actionTypes'
import classNames from 'classnames'

const DialogCard = props => {
  const [initValue] = useState({ email: '' })

  const history = useHistory()

  const {
    isOpen,
    onClose,
    boatCostEstimation,
    mailFlagHandler,
    isMailEnabled,
    sendMail,
    boatId,
    isLoading,
    loadingMail,
    boat,
    isPdfLoading,
    setOnlineUsers,
    onlineUsers,
  } = props

  const {
    boatData,
    boatPrice,
    surveyorCost,
    surveyorPrice,
    adminPrice,
    paymentTransferFee,
    totalPrice,
    surveyorName,
    adminFee,
    pdf,
  } = boatCostEstimation

  useEffect(() => {
    boatId && props.showServiceProf && props.getServiceProfileForEstimatedCost({ boatId })
  }, [boatId])

  const serviceProf = props.getServiceProfileForEstimatedCost_data

  useEffect(() => {
    getId(serviceProf) && getOnlineUsers([getId(serviceProf)], setOnlineUsers)
  }, [serviceProf])

  return (
    <CommonModal className="inner--estimate--cost--div" title="Estimate Cost" open={isOpen} close={() => onClose(false)} isAnimatedCloseIcon>
      <Formik
        initialValues={initValue}
        onSubmit={values => {
          sendMail({ id: boatId, email: values.email })
          loadingMail()
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required(requireMessage('Email')),
        })}
        render={({ setFieldValue }) => (
          <Form>
            <div className="inner--estimate--content">
              {boatCostEstimation && boatData && (
                <Grid container>
                  <Grid item md={5}>
                    <div className="estimation--cost--image">
                      {boat?.images?.length && boat.images[0].url ? (
                        <img loading="lazy" src={boat.images[0].url} className="w-100 h-100" alt="Boat" />
                      ) : (
                        <> </>
                      )}
                    </div>
                    <div className="estimate--cost--status--info d-flex flex-column">
                      {/* <span className="estimate--cost--boat--name">
                        {boat.yearBuilt} {getBoatTypeName(boat)}
                      </span>
                      <CurrencyContextConsumer>
                        {({ currentCurrency }) => (
                          <span className="estimate--cost--boat--price">{getConvertedPrice(boatPrice, currentCurrency)}</span>
                        )}
                      </CurrencyContextConsumer>
                      <h6 className="title-sm mb-0 title-description font-weight-500">BOAT STATUS</h6>
                      <p className="boat-inner-info-desc mt-0 estimate--cost--info--result">{boatData.boatStatus}</p>
                      <h6 className="title-sm mb-0 title-description font-weight-500">LISTED BY</h6>
                      <p className="boat-inner-info-desc mt-0 estimate--cost--boat--listed--by estimate--cost--info--result">{boatData.listedBy}</p>
                      <h6 className="title-sm mb-0 title-description font-weight-500">BOAT LOCATION</h6> */}
                      <div className="d-flex label-with--city-country align-items-center">
                        <img
                          className="estimate--cost--location--icon"
                          src={require('../../assets/images/marker/Location-Marker-Icon.png')}
                        />
                        <span className="boat--info--location estimate--cost--location--info">{boatData.boatLocation}</span>
                      </div>
                      <span className="mt-3 estimate--cost--ad--id">Ad ID: {boatData.adId}</span>
                    </div>
                  </Grid>
                  {props.showServiceProf && serviceProf && (
                    <Grid item md={7}>
                      <div className="serviceProf_wrp">
                        <div className="serviceProf">
                          <div className="serviceProf_col">
                            <div className="serviceProf_image" onClick={() => {
                              redirectToUserProfile(serviceProf, history)
                            }}>
                              <img src={serviceProf.image?.url} alt={nameFormatter([serviceProf.firstName, serviceProf.lastName])} />
                              <div className={classNames('serviceProf_image_online', { disconnect: !onlineUsers[getId(serviceProf)] })}></div>
                            </div>
                            <div className="serviceProf_name">{nameFormatter([serviceProf.firstName])}</div>
                            <div className="d-flex align-items-center">
                              <div>
                                <div><ChatUserCreate id={serviceProf.id} image={serviceProf.image?.url} noFriendIcon chatTooltipContent="Chat with Boat Finance" onChatClick={() => onClose(true)} /></div>
                                <div className="font-14">Chat with us</div>
                              </div>
                              <div className="serviceProf_serv">Boat Finance</div>
                            </div>
                          </div>
                          {/* <div className="serviceProf_col">
                            <div className="serviceProf_image">
                              <img src={serviceProf.companyLogo?.url} alt={serviceProf.companyName || ''} />
                            </div>
                            <div className="serviceProf_name">{serviceProf.companyName}</div>
                          </div> */}
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
              )}
              <Grid container className="estimate--cost--total--detail">
                <Grid item sm={7}>
                  <table className="table color-black estimate--cost--table--div mb-0">
                    <thead>
                      <tr>
                        <th>Items</th>
                        <th className="text-center">Cost</th>
                      </tr>
                    </thead>
                    <CurrencyContextConsumer>
                      {({ currentCurrency }) => (
                        <tbody className="estimate--cost--table">
                          <tr>
                            <td>Original Item Cost</td>
                            <td className="text-right">{getConvertedPrice(boatPrice, currentCurrency)}</td>
                          </tr>
                          {Number(surveyorPrice) > 0 && (
                            <tr>
                              <td>
                                Surveyor Cost <span className="boat-length-col">( Boat Length * Cost per ft )</span>
                              </td>
                              <td className="text-right">{getConvertedPrice(surveyorPrice, currentCurrency)}</td>
                            </tr>
                          )}
                          <tr>
                            <td>Service Fees</td>
                            <td className="text-right">{getConvertedPrice(adminPrice, currentCurrency)}</td>
                          </tr>
                          {boat && boat.isTaxEnabled && boat.tax && (
                            <tr>
                              <td>Tax Fees</td>
                              <td className="text-right">
                                Included(<span className="darkBlue">{estimationPercentageFormate(boat.tax)}</span> %)
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>Payment Transfer Fees</td>
                            <td className="text-right">
                              {getConvertedPrice(paymentTransferFee, currentCurrency, true, true)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Total Estimated Cost</b>
                            </td>
                            <td className="text-right">
                              <b>{getConvertedPrice(totalPrice, currentCurrency)}</b>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </CurrencyContextConsumer>
                  </table>
                </Grid>
                <Grid item sm={5}>
                  <div className="h-100 d-flex flex-column justify-content-between">
                    <div className="estimate--cost--surveyor--info">
                      <div className="d-flex flex-column estimate--cost--surveyor--info--title">
                        <span>Get an approximate price for this boat</span>
                        <span>(Shipping cost not included)</span>
                      </div>
                      <div className="d-flex flex-column estimate--cost--surveyor--info--desc">
                        {Number(surveyorPrice) > 0 && (
                          <CurrencyContextConsumer>
                            {({ currentCurrency }) => (
                              <>
                                <div className="font-14">Surveyor Cost calculated Per ft of the boat (Price is based on the lowest fee offered by a surveyor located within surveying distance from the boat)</div>
                                <div className="mt-4 fsz-16"><strong style={{ fontSize: '1.05em' }}>Surveyor</strong> <br/> {surveyorName}</div>
                                <div className="mt-2 fsz-16"><strong style={{ fontSize: '1.05em' }}>Boat Length (in ft)</strong> <br/> {boat?.lengthInFt} ft</div>
                                <div className="mt-2 fsz-16"><strong style={{ fontSize: '1.05em' }}>Surveyor cost Per ft</strong> <br/> {getConvertedPrice(surveyorPrice / boat?.lengthInFt, currentCurrency)}</div>
                                {/* {boat && boat.isTaxEnabled && boat.tax && (
                                  <div><strong style={{ fontSize: '1.1em' }}>Tax Fees</strong> <br/> {estimationPercentageFormate(boat.tax)} %</div>
                                )} */}
                              </>
                            )}
                          </CurrencyContextConsumer>
                        )}
                      </div>
                    </div>
                    <div className="estimate--cost--surveyor--info pr-0">
                      <div className="estimated-email d-flex estimate--cost--surveyor--mail--div">
                        {isMailEnabled && (
                          <>
                            <div className="text-left w-100">
                              <div className="d-flex">
                                <div className="estimate--cost--add--mail w-100">
                                  <Field
                                    name="email"
                                    type="text"
                                    className="form-control h-100"
                                    placeholder="Add your email"
                                    onChangeText={e => setFieldValue('email', e.target.value)}
                                  />
                                </div>
                                {!isLoading ? (
                                  <ButtonComponent
                                    className="h-auto as--success--outline btn--padding btn--font
                                                                         estimate--cost--email--submit--btn"
                                    type="submit"
                                  >
                                    Send
                                  </ButtonComponent>
                                ) : (
                                  <ButtonComponent
                                    className="h-auto as--success--outline btn--padding btn--font
                                                                        estimate--cost--email--submit--btn"
                                  >
                                    <Spinner animation="border" />
                                  </ButtonComponent>
                                )}
                              </div>
                              <ErrorMessage component="div" name="email" className="error-message" />
                            </div>
                          </>
                        )}
                      </div>
                      <div className="d-flex">
                        <div className="mr-2 d-flex">
                          {isPdfLoading ? (
                            <div className="estimate--cost--btn mr-2 cursor-pointer">
                              <Spinner animation="border" size="sm" />{' '}
                            </div>
                          ) : (
                            <a className="estimate--cost--btn mr-2 cursor-pointer" href={pdf?.url} download target="_blank">
                              {'DOWNLOAD'}
                            </a>
                          )}
                        </div>
                        <div className="d-flex">
                          <a
                            className="estimate--cost--btn cursor-pointer"
                            href="#"
                            id="send_email_a"
                            onClick={mailFlagHandler}
                          >
                            {'EMAIL'}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <div className="mt-3 estimate--cost--note">
                <span className="text-left light-silver boat--estimation--note">
                  * Total estimated cost is an approximate and may change accordingly
                </span>
              </div>
            </div>
            <hr className="mb-0" />
            <div className="inner--estimate--content inner--estimate--second--div">
              <p className="font-weight-light gray-light boat-estimation-desc boat--estimation--desc">
                Never send or wire money to sellers or buyers. This includes mailing a cheque or using payment services like
                PayPal, Amazon Payments, Google Wallet/ Checkout, Canada Post, any international post, Western Union or
                MoneyGram. Typically, scammers will use these services and will even falsify documents from a legitimate
                company. It is best to avoid them entirely and keep things local in your AdamSea.com account! AdamSea will not
                be responsible for any transactions that may take place out of AdamSea.com.
              </p>
              <p className="text-right-dark estimate--cost--powered--by--div">
                <span className="estimate--powered--by gray-light">Powered by </span>
                <span className="darkBlue estimate--powered--by--adamsea">&nbsp; AdamSea</span>
              </p>
            </div>
          </Form>
        )}
      />
    </CommonModal>
  )
}

export default connect(state => ({
  getServiceProfileForEstimatedCost_loading: state.boatServiceReducer.getServiceProfileForEstimatedCost_loading,
  getServiceProfileForEstimatedCost_data: state.boatServiceReducer.getServiceProfileForEstimatedCost_data,
  onlineUsers: state.chatReducer.onlineUsers,
}), dispatch => ({
  getServiceProfileForEstimatedCost: data => dispatch(getServiceProfileForEstimatedCost(data)),
  setOnlineUsers: users => dispatch({ type: SET_ONLINE_USERS, payload: users }),
}))(DialogCard)
