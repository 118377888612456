export const dateValidator = values => {
  let errors = {}
  // console.log(values);

  // if (values.startTime < new Date(Date.now() + 2 * (24 * 60 * 60 * 1000)).toISOString()) {
  //   errors.startTime = 'Please select a start date after 2 days'
  // }
  // console.log(errors);

  return errors
}
