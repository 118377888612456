import React from 'react'
import cn from 'classnames'

/**
 * @param {React.SVGProps<SVGSVGElement>} props
 */
export default function IconClose(props) {
  return (
    <svg
      {...props}
      className={cn('as-media-icon', props.className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1 15L15 1" />
      <path d="M15 15L1 1" />
    </svg>
  )
}
