import React from 'react'
import { connect } from 'react-redux'
import UserContext from '../../UserContext'
import { getAllBoatShow } from '../../redux/actions/boatShowAction'
import BoatListingsWithMap from './BoatListingsWithMap'
import { pagination } from '../../util/enums/enums'

class ShowAllBoatShow extends React.Component {
  static contextType = UserContext

  render() {
    const { allBoatShows, allBoatShowCount, isPageLoader, getAllBoatShow } = this.props
    const { country } = this.context

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        isBoatShow
        boatsTypeCount={allBoatShowCount || 0}
        boatsType={`${allBoatShowCount || 0} Boat Show`}
        isNotHome={false}
        boats={allBoatShows}
        action={getAllBoatShow}
        value={{ country }}
      />
    )
  }
}

const mapStateToProps = state => ({
  allBoatShows: state.boatShowReducer && state.boatShowReducer.allBoatShows,
  allBoatShowCount: state.boatShowReducer && state.boatShowReducer.allBoatShowCount,
  isPageLoader: state.boatShowReducer && state.boatShowReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  getAllBoatShow: value => dispatch(getAllBoatShow(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllBoatShow)
