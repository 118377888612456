import { useCallback, useEffect, useRef, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { getPosts as qGetPosts } from '../lib/queries'
import { useSMDb } from '../contexts/smdb-ctx'
import Posts from '../components/common/posts'
import styles from './feeds.module.scss'

export default function Feeds() {
  const { get: smdbGet, merge: smdbMerge } = useSMDb()

  const [isPostsLoading, setIsPostsLoading] = useState(false)
  const [posts, setPosts] = useState([])

  const paginationRef = useRef({})
  const postsRef = useRef()

  const getPosts = useCallback(async pagination => {
    setIsPostsLoading(true)

    try {
      const res = await qGetPosts(pagination)
      setPosts(posts => [...posts, ...res.items])
      paginationRef.current = { page: res.page, token: res.token }
    } catch (err) {
      enqueueSnackbar(err)
    }

    setIsPostsLoading(false)
  }, [])

  useEffect(() => {
    setPosts([])
    getPosts({})
  }, [getPosts])

  const postCreated = smdbGet('CreatePost', 'created')

  useEffect(() => {
    if (postCreated) {
      setPosts([])
      getPosts({})
    }
  }, [postCreated])

  useEffect(() => {
    for (const post of posts) {
      const { likesCount, liked, topLikes, commentsCount, topComments, saved } = post
      smdbMerge(post._id, { likesCount, liked, topLikes, commentsCount, topComments, saved })

      const { followersCount, followingsCount, isFollowing, postsCount } = post.user
      smdbMerge(post.user._id, { followersCount, followingsCount, isFollowing, postsCount })
    }
  }, [posts, smdbMerge])

  const handleScroll = useCallback(() => {
    if (!postsRef.current) return

    const bottom = postsRef.current.getBoundingClientRect().bottom
    const windowHeight = window.innerHeight

    if (bottom <= windowHeight && posts.length && !isPostsLoading) {
      getPosts({ page: paginationRef.current.page + 1, token: paginationRef.current.token })
    }
  }, [posts, isPostsLoading])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <div className={styles.feedsCont}>
      <div className={styles.feedsSec}>
        <div className={styles.feedsWrp} ref={postsRef}>
          <Posts items={posts} loading={isPostsLoading} />
        </div>
      </div>

      <div className={styles.rightSec}></div>
    </div>
  )
}
