import { toast } from 'react-toastify'
import {
  CREATE_REVIEW,
  CREATE_REVIEW_SUCCESS,
  CREATE_REVIEW_FAILURE,
  GET_REVIEW_BY_MODULE_ID,
  GET_REVIEW_BY_MODULE_ID_SUCCESS,
  GET_REVIEW_BY_MODULE_ID_FAILURE,
  CLEAR_REVIEW_FLAG,
  ADD_FEEDBACK,
  ADD_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_FAILURE,
  CLEAR_FEEDBACK_FLAG,
} from '../actionTypes'

const InitialState = {
  isReview: false,
  createSuccess: false,
  createError: false,
  reviews: [],

  addFeedbackSuccess: false,
  addFeedbackError: false,
}

export const reviewReducer = (state = InitialState, action) => {
  switch (action.type) {
    case CREATE_REVIEW:
      return {
        ...state,
        isReview: false,
        createSuccess: false,
        createError: false,
      }
    case CREATE_REVIEW_SUCCESS:
      toast.success('Review posted successfully')
      return {
        ...state,
        newReview: action.payload,
        createSuccess: true,
        isReview: true,
      }
    case CREATE_REVIEW_FAILURE:
      return {
        ...state,
        createError: true,
        isReview: false,
      }

    case CLEAR_REVIEW_FLAG:
      return {
        ...state,
        isReview: false,
      }

    case GET_REVIEW_BY_MODULE_ID:
      return {
        ...state,
        reviews: null,
      }
    case GET_REVIEW_BY_MODULE_ID_SUCCESS:
      return {
        ...state,
        reviews: action.payload,
      }
    case GET_REVIEW_BY_MODULE_ID_FAILURE:
      return {
        ...state,
      }

    //add feedback
    case ADD_FEEDBACK:
      return {
        ...state,
        addFeedbackSuccess: false,
        addFeedbackError: false,
      }
    case ADD_FEEDBACK_SUCCESS:
      return {
        ...state,
        addFeedbackSuccess: true,
        addFeedbackError: false,
      }
    case ADD_FEEDBACK_FAILURE:
      return {
        ...state,
        addFeedbackSuccess: false,
        addFeedbackError: true,
      }

    //clear Feedback
    case CLEAR_FEEDBACK_FLAG:
      return {
        addFeedbackSuccess: false,
        addFeedbackError: false,
      }
    default:
      return state
  }
}
