import {
  CREATE_RENT_BOAT,
  CLEAR_BOAT_RENT_FLAG,
  GET_BOAT_RENT_LOOKUPS,
  GET_USER_BOAT_RENTS,
  DELETE_BOAT_RENT,
  GET_RENTS_INNER_BOAT,
  GET_ALL_BOAT_RENT_TYPES,
  GET_ALL_RENT_TYPES,
  CLEAR_DELETE_BOAT_RENT_FLAG,
  CLEAR_SEARCH_BOAT_RENT_FLAG,
  UPDATE_RENT_BOAT,
  GET_RENT_TRIP_CITY_WISE,
  UPDATE_RENT_BOAT_TRIP_DETAILS,
  CLEAR_UPDATE_TRIP_FLAG,
  CHANGE_RENT_BOAT_STATUS,
  CLEAR_BOAT_RENT_STATUS_FLAG,
  CLEAR_BOAT_RENT_DATA,
  GET_TYPE_WISE_LOOKUP,
  GET_ALL_TRIP_TYPES,
  CREATE_RENT_BOOKING,
  CLEAR_CREATE_RENT_BOOKING,
  RENT_BOOKING_PAYMENT,
  GET_RENT_BOOKINGS_BY_CUSTOMER,
  GET_RENT_TICKET,
  CLEAR_GET_RENT_TICKET_FLAGS,
  CANCEL_RENT_BOOKING,
  CLEAR__CANCEL_RENT_BOOKING__FLAG,
  CLEAR__GET_RENT_BOOKINGS_BY_CUSTOMER__FLAG,
  CHECK_IF_CAN_BOOK_RENT,
  CLEAR__CHECK_IF_CAN_BOOK_RENT__FLAG,
  GET_RENT_BOAT_TRIP_DETAILS,
  CLEAR__GET_RENT_BOAT_TRIP_DETAILS,
  CLEAR__UPDATE_RENT_BOAT_TRIP_DETAILS,
  GET_RENT_PAYOUTS,
  CLEAR__GET_RENT_PAYOUTS__FLAG,
} from '../actionTypes'

export function createBoatRent(data) {
  return {
    type: CREATE_RENT_BOAT,
    payload: data,
  }
}

export function clearBoatRentFlag() {
  return {
    type: CLEAR_BOAT_RENT_FLAG,
  }
}

export function clearBoatRentData() {
  return {
    type: CLEAR_BOAT_RENT_DATA,
  }
}

export function clearSearchBoatRentFlag() {
  return {
    type: CLEAR_SEARCH_BOAT_RENT_FLAG,
  }
}

export function getBoatRentLookUps() {
  return {
    type: GET_BOAT_RENT_LOOKUPS,
  }
}

export function getAllBoatRentOfUser(data) {
  return {
    type: GET_USER_BOAT_RENTS,
    payload: data,
  }
}

export function getRentInnerBoat(data) {
  return {
    type: GET_RENTS_INNER_BOAT,
    payload: data,
  }
}

export function getAllRentTypes(data) {
  return {
    type: GET_ALL_RENT_TYPES,
    payload: data,
  }
}

export function deleteBoatRent(data) {
  return {
    type: DELETE_BOAT_RENT,
    payload: data,
  }
}

export function getAllBoatRentTripTypes(data) {
  return {
    type: GET_ALL_BOAT_RENT_TYPES,
    payload: data,
  }
}

export const clearBoatRentDeleteFlag = () => ({
  type: CLEAR_DELETE_BOAT_RENT_FLAG,
})

export function updateBoatRent(data) {
  return {
    type: UPDATE_RENT_BOAT,
    payload: data,
  }
}

export const getRentTripCityWise = data => ({
  type: GET_RENT_TRIP_CITY_WISE,
  payload: data,
})

export const getRentBoatTripDetails = data => ({
  type: GET_RENT_BOAT_TRIP_DETAILS,
  payload: data,
})

export const clear_getRentBoatTripDetails = () => ({
  type: CLEAR__GET_RENT_BOAT_TRIP_DETAILS,
})

export const updateRentBoatTripDetails = data => ({
  type: UPDATE_RENT_BOAT_TRIP_DETAILS,
  payload: data,
})

export const clear_updateRentBoatTripDetails = () => ({
  type: CLEAR__UPDATE_RENT_BOAT_TRIP_DETAILS,
})

export const clearUpdateTripFlag = () => ({
  type: CLEAR_UPDATE_TRIP_FLAG,
})

export const changeBoatRentStatus = data => ({
  type: CHANGE_RENT_BOAT_STATUS,
  payload: data,
})

export const clearRentBoatStatusFlag = () => ({
  type: CLEAR_BOAT_RENT_STATUS_FLAG,
})

export const getTypeWiseLookup = data => ({
  type: GET_TYPE_WISE_LOOKUP,
  payload: data,
})

export const getAllTripTypes = data => ({
  type: GET_ALL_TRIP_TYPES,
  payload: data,
})

export const rentBookingPayment = data => ({
  type: RENT_BOOKING_PAYMENT,
  payload: data,
})

export const checkIfCanBookRent = data => ({
  type: CHECK_IF_CAN_BOOK_RENT,
  payload: data,
})

export const clear_checkIfCanBookRent_flag = () => ({
  type: CLEAR__CHECK_IF_CAN_BOOK_RENT__FLAG,
})

export const createRentBooking = data => ({
  type: CREATE_RENT_BOOKING,
  payload: data,
})

export const clearCreateRentBooking = () => ({
  type: CLEAR_CREATE_RENT_BOOKING,
})

export const getRentBookingsByCustomer = data => ({
  type: GET_RENT_BOOKINGS_BY_CUSTOMER,
  payload: data,
})

export const clear_getRentBookingsByCustomer_flag = () => ({
  type: CLEAR__GET_RENT_BOOKINGS_BY_CUSTOMER__FLAG,
})

export const getRentTicket = bookingId => ({
  type: GET_RENT_TICKET,
  payload: bookingId,
})

export const clearGetRentTicketFlags = () => ({
  type: CLEAR_GET_RENT_TICKET_FLAGS,
})

export const cancelRentBooking = id => ({
  type: CANCEL_RENT_BOOKING,
  payload: id,
})

export const clear_cancelRentBooking_flag = () => ({
  type: CLEAR__CANCEL_RENT_BOOKING__FLAG,
})

export const getRentPayouts = data => ({
  type: GET_RENT_PAYOUTS,
  payload: data,
})

export const clear_getRentPayouts_flag = () => ({
  type: CLEAR__GET_RENT_PAYOUTS__FLAG,
})
