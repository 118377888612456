import React, { useState, useEffect } from 'react'
import { Link, Grid } from '@material-ui/core'
import OutsideClickHandler from 'react-outside-click-handler'

import SharePopup from './SharePopup'
import { WishlistComponent } from '../wishlist/WishlistComponent'
import { CommonTooltip } from '../CommonTooltip'
import ReportModal from '../modal/reportModal'
import { getLocalStorageItem, setLocalStorageItem } from '../../helpers/storageHelper'
import { redirectRouteHandler } from '../../helpers/routeHelper'
import { flagError, iconBoldImages } from '../../util/enums/enums'
import { ErrorNotify } from '../../helpers/notification'
import LoginModal from '../modal/loginModal'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'

export const ShareAndWishlist = React.memo(props => {
  const [selectedIndex, setSelectedIndex] = useState(null)

  const handleClick = index => {
    setSelectedIndex(selectedIndex !== index ? index : null)
  }
  const singleHandleClick = () => {
    setTimeout(() => {
      setSelectedIndex(null)
    }, 50)
  }

  const [openFlag, setOpenFlag] = useState(false)
  const [loginModal, setLoginModal] = useState(false)

  const handlerLoginModal = () => setLoginModal(!loginModal)

  const userId = getLocalStorageItem('userId')

  const openReportModal = () => {
    if (!userId) {
      handlerLoginModal()
      return
    }

    const adminAccess = getLocalStorageItem('adminLoginDetected')
    if (adminAccess) {
      ErrorNotify(flagError)
    } else {
      setOpenFlag(true)
    }
  }

  const {
    moduleId,
    moduleType,
    ownerId,
    shareWithUsers,
    getPdfSuccess,
    pdf,
    getModulePdf,
    isInner,
    isClickble,
    getUserReportByModule,
    hideShare,
    hideSave,
    hideFlag,
    hidePDF,
  } = props

  useEffect(() => {
    getUserReportByModule && getUserReportByModule({ moduleId, user: userId })
  }, [getUserReportByModule])

  return (
    <>
      <LoginModal open={loginModal} close={handlerLoginModal} />
      <Grid
        item
        className={`rent-inner-rating-section  align-items-center ${
          props.noTopSpace || 'rentt-servise-top-bottom-space'
        } pr-0 pl-0 inner--boat--header--div`}
        sm={2}
      >
        <div className="ml-auto mr-auto  widget-media text-center">
          <ul className={`icon-effect icon-effect-1a d-flex ${isClickble ? 'clickable-icon' : 'disbled--icon'}`}>
            {hideShare || (
              <li className="cursor-pointer">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    selectedIndex === moduleId && setSelectedIndex(null)
                  }}
                >
                  <CommonTooltip
                    component={ctProps => (
                      <Link
                        data-tooltip-id={ctProps.id}
                        data-tooltip-content="Share"
                        className={
                          'icon d-flex justify-content-center inner--header--social--icon' +
                          (props.isServiceInner ? ' icon-rev' : '')
                        }
                        onClick={() => isClickble && handleClick(moduleId)}
                      >
                        {/* <i className="dark-silver adam-share header-inner-icons" /> */}
                        {props.isServiceInner ? (
                          <img loading="lazy" src={iconBoldImages.share_black} alt="adamsea" />
                        ) : (
                          <img loading="lazy" src={iconBoldImages.share} alt="adamsea" />
                        )}
                      </Link>
                    )}
                  />

                  <SharePopup
                    handleClick={value => handleClick(value)}
                    singleHandleClick={() => singleHandleClick()}
                    index={moduleId}
                    selectedIndex={selectedIndex}
                    shareWithUsers={shareWithUsers}
                    isInnerMarket={false}
                    useOwnIcon
                    moduleId={moduleId}
                    moduleType={moduleType}
                    isInner={isInner}
                  />
                </OutsideClickHandler>
              </li>
            )}

            {hideSave || (
              <li className="inner--header--heart--icon cursor-pointer">
                <WishlistComponent
                  moduleId={moduleId}
                  moduleType={moduleType}
                  isInnerPage
                  isClickble={isClickble}
                  isServiceInner={props.isServiceInner}
                />
              </li>
            )}

            {hideFlag || (
              <>
                {ownerId && ownerId !== userId && (
                  <li className="cursor-pointer" onClick={() => isClickble && openReportModal()}>
                    <CommonTooltip
                      component={ctProps => (
                        <Link
                          data-tooltip-id={ctProps.id}
                          data-tooltip-content="Flag"
                          to="/"
                          className="icon icon-rev d-flex justify-content-center inner--header--social--icon"
                        >
                          {/* <i className="dark-silver adam-flag-3 header-inner-icons" /> */}
                          <img loading="lazy" src={iconBoldImages.flag} alt="adamsea" />
                        </Link>
                      )}
                    />
                  </li>
                )}

                <ReportModal
                  open={openFlag}
                  moduleId={moduleId}
                  moduleType={moduleType}
                  closeModal={() => isClickble && setOpenFlag(false)}
                />
              </>
            )}

            {hidePDF || (
              <li className="cursor-pointer">
                <CommonTooltip
                  component={ctProps => (
                    <Link
                      data-tooltip-id={ctProps.id}
                      data-tooltip-content="Download PDF"
                      to="/"
                      onClick={() =>
                        isClickble &&
                        getModulePdf({ id: moduleId, type: moduleType, currency: getLocalStorageItem('currentCurrency') })
                      }
                      className="icon icon-rev d-flex justify-content-center inner--header--social--icon"
                    >
                      {/* <i className="dark-silver adam-pdf header-inner-icons" /> */}
                      <img loading="lazy" src={iconBoldImages.pdf} alt="adamsea" />
                    </Link>
                  )}
                />
              </li>
            )}
          </ul>
        </div>
      </Grid>
    </>
  )
})
ShareAndWishlist.defaultProps = {
  isInner: false,
  isClickble: true,
}
