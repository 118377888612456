import React, { useEffect, useState } from 'react'
import { dimension } from '../../util/enums/enums'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { UserProfileBoatsStyle } from '../styleComponent/styleComponent'
import '../../assets/css/component/userArticales.scss'
import { viewArticles } from '../../helpers/boatHelper'
import { CommonTooltip } from '../CommonTooltip'

export const UserArticales = React.memo(({ artical }) => {
  const { id, files, title, description, pageVisits, likes, shortDescription } = artical

  const [height, setHeight] = useState(dimension.userBoatProfile.height)
  const [className, setClassName] = useState('grid--new--4')
  const [margin, setMargin] = useState(12)
  const [width, setWidth] = useState(dimension.userBoatProfile.width)

  const zoomWidth = () => {
    const width = getRatioZoomResponsive(dimension, 'userBoatProfile', '.zoom-container')
    setWidth(width.width)
    const height = getHeightZoomResponsive(dimension, 'userBoatProfile', '.zoom-container')

    setHeight(height)
    width && setClassName(width.className)
    width && setMargin(width.margin)
  }

  useEffect(() => {
    zoomWidth()
    window.addEventListener('resize', zoomWidth)
    return () => {
      window.removeEventListener('resize', zoomWidth)
    }
  }, [])

  const createMarkup = text => {
    return { __html: text }
  }
  return (
    <>
      {artical && (
        <div className={`user--articles-main ${className}`} style={{ width: width, marginRight: margin }}>
          <div className="user--articles-image">
            <UserProfileBoatsStyle
              className="user--articles-image-bg w-100"
              onClick={() => viewArticles(id, title)}
              img={files?.length > 0 && files[0].url}
              height={height}
            >
              <div className="user--artical-icon-div">
                  <CommonTooltip component={ctProps => (
                    <div data-tooltip-id={ctProps.id} data-tooltip-content="View" className="user--artical-icon">
                      <img loading="lazy" src={require('../../assets/images/user/reading.png')} alt="View" />
                      <span className="count">{pageVisits?.length}</span>
                    </div>
                  )} />
                  <CommonTooltip component={ctProps => (
                    <div data-tooltip-id={ctProps.id} data-tooltip-content="Likes" className="user--artical-icon">
                      <img loading="lazy" src={require('../../assets/images/user/heart.png')} alt="Like" />
                      <span className="count">{likes?.length}</span>
                    </div>
                  )} />
              </div>
            </UserProfileBoatsStyle>
            <div onClick={() => viewArticles(id, title)} className="user--articles--name">
              {title}
            </div>
            {shortDescription && (
              <div className="article--desc--style">
                <div>
                  <p style={{fontSize: '12px'}} className='f-12' dangerouslySetInnerHTML={createMarkup(shortDescription)} />
                </div>
              </div>
            )}
            <div onClick={() => viewArticles(id, title)} className="user--articles--read-more font-weight-bold color-black">
              Read more <img style={{padding: '1px'}} width={12} alt="right arrow" src={require('../../assets/images/Arrow/show-more-right-arrow.svg')}/>
            </div>
          </div>
        </div>
      )}
    </>
  )
})

UserArticales.defaultProps = {
  className: '',
}
