import gql from 'graphql-tag'
import { ImageType } from './schemaTypes/schemaTypes'

export const categoryWiseVideos = gql`
    query getAllVideosList($input: GetMarketMediaInput!) {
        getAllVideosList(input: $input){
            items{
            id
            url ${ImageType}
            title
            type
            metatype
            thumbnail ${ImageType}
            mediaOrder
            description
            createdAt
            isBanner
            status
            }
        }
    }`
