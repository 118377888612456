import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getId } from '../../util/utilFunctions'
import { rentBoatTripForCard } from '../../util/enums/enums'
import HeroSearch from '../../containers/rent/hero-search'
import ShortSearch from './short-search'

export default function RentShortSearch() {
  const { pathname, search } = useLocation()

  const trips = useSelector(state => state.boatRentReducer?.boatRentLookUps?.trip)
  const tripTypes = useSelector(state => state.rentReducer?.tripTypeLookups)

  const [fields, setFields] = useState([])

  useEffect(() => {
    if (pathname === '/search-rent-boats' && trips?.length && tripTypes?.length) {
      const searchParams = new URLSearchParams(search)

      const country = searchParams.get('country')
      const city = searchParams.get('city')
      const address = searchParams.get('address')
      const tripAlias = searchParams.get('type')
      const tripTypeId = searchParams.get('tripType')

      const fields = []

      fields.push(country || city ? address || [city, country].filter(Boolean).join(', ') : 'Anywhere')

      if (tripAlias) {
        const trip = trips.find(t => t.lookUp.alias === rentBoatTripForCard[tripAlias])?.lookUp.alias
        fields.push(trip || 'Any Trip')
      } else {
        fields.push('Any Trip')
      }

      if (tripTypeId) {
        const tripType = tripTypes.find(t => getId(t) === tripTypeId)?.alias
        fields.push(tripType || 'Any Type')
      } else {
        fields.push('Any Type')
      }

      setFields(fields)
    } else {
      setFields(['Anywhere', 'Any Trip', 'Any Type'])
    }
  }, [pathname, search, trips, tripTypes])

  return <ShortSearch fields={fields} HeroSearch={HeroSearch} />
}
