import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { ErrorMessage, Formik, Form } from 'formik'
import { Box, Card, CardContent, Icon } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { Field, Loader } from '../../../components'
import { cancelHandler } from '../../../helpers/routeHelper'

import './branch.scss'
import { Row, Col } from 'react-bootstrap'
import { DashboardLayout } from '../../../components/layout/dashboardLayout'
import { getAddMedia, handleSingleFileUpload } from '../../../helpers/s3FileUpload'
import {
  createSurveyorReport,
  clearReportFlag,
  getDocumentLinks,
  getDocumentUrl,
  getSurveyorReport,
  updateSurveyorReport,
} from '../../../redux/actions'
import { SuccessNotify } from '../../../helpers/notification'
import ErrorFocus from '../../../components/ws/ErrorFocus'
import { ButtonComponent } from '../../../components/form/Button'
import { MEDIA_NAME, MEDIA_TYPE } from '../../../util/enums/enums'
import { requireMessage } from '../../../helpers/string'
import { getLocalStorageItem } from '../../../helpers/storageHelper'
import { notificationEnum } from '../../../util/enums/notificationEnum'
import IconSEArrowLeft from '../../salesEngine/process/left-arrow-svgrepo-com'

class CreateSurveyorReport extends Component {
  constructor(props) {
    super(props)

    this.state = {
      disabled: false,
      getReport: false,
      reportData: {
        images: [],
        addMedia: {
          report: [],
          boatVerifications: [],
          otherDocument: [],
          images: [],
          video: [],
        },
        removeMedia: [],
        report: '',
        boatVerifications: '',
        otherDocument: '',
        comment: '',
      },
    }
  }
  componentDidMount() {
    const {
      getSurveyorReport,
      match: { params },
    } = this.props
    const userId = getLocalStorageItem('userId')
    getSurveyorReport({ surveyorId: userId, salesEngineId: params?.salesEngineId })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      createReportSuccess,
      updateReportSuccess,
      clearReportFlag,
      history,
      getDocumentLinks,
      match: { params },
    } = nextProps

    if (createReportSuccess || updateReportSuccess) {
      getDocumentLinks({ id: params?.salesEngineId, type: 'Surveyor' })
      clearReportFlag()
      setTimeout(() => SuccessNotify('Report Submitted successfully'), 100)
      params?.branchId && history.push(`/my-requests?branchId=${params?.branchId}`)
    }
  }

  disableButton = () => {
    this.setState(prevState => ({ disabled: !prevState.disabled }))
  }

  render() {
    const {
      history,
      createSurveyorReport,
      getDocumentUrl,
      surveyorReport,
      reportLoading,
      updateSurveyorReport,
      createReportLoading,
      match: { params },
    } = this.props
    const { reportData, loading, disabled } = this.state

    return (
      <DashboardLayout>
        {loading || reportLoading ? (
          <Loader isPageLoader />
        ) : (
          <div className="pl-3 pr-3">
            <Formik
              initialValues={
                surveyorReport?.id
                  ? {
                      ...surveyorReport,
                      addMedia: {
                        report: [],
                        boatVerifications: [],
                        otherDocument: [],
                        images: [],
                        video: [],
                      },
                      removeMedia: [],
                    }
                  : reportData
              }
              validationSchema={Yup.object().shape({
                comment: Yup.string().required(requireMessage('Comment')),
                report: Yup.string().required(requireMessage('Report')),
                boatVerifications: Yup.string().required(requireMessage('Boat Verification')),
                video: Yup.string().required(requireMessage('Video')),
                images: Yup.string().required(requireMessage('Images')),
              })}
              onSubmit={values => {
                if (params?.salesEngineId) {
                  values.salesEngineId = params.salesEngineId
                  values.addMedia = getAddMedia(values.addMedia)

                  const { boatVerifications, otherDocument, report, images, video, __typename, surveyorId, ...newValues } =
                    values
                  createSurveyorReport(newValues)
                }
              }}
              render={({ errors, setFieldValue, values }) => (
                <Form>
                  <ErrorFocus />
                  <div className="pl-2 pt-3 pb-3">
                    <Box fontSize={32} fontWeight={700}>
                      Upload Survey Reports
                    </Box>
                  </div>
                  <Card className="w-100 inspection--details">
                    <CardContent>
                      <Row className="m-0">
                        <Col className="mb-2">
                          <div className="mb-2">
                            <Field
                              label="Comment"
                              name="comment"
                              type="textarea"
                              required
                              value={values.comment}
                              className="form-control"
                              onChangeText={e => setFieldValue('comment', e.target.value)}
                            />
                            <ErrorMessage component="div" name="comment" className="error-message text-center" />
                          </div>
                        </Col>
                        <Col className="mb-2">
                          <div className="mb-2">
                            <Field
                              label="Upload Survey Report"
                              buttonText="Upload Document"
                              name="report"
                              type="single-document"
                              required
                              value={values.report?.id ? [values.report] : values.report}
                              className="form-control"
                              onChangeText={setFieldValue}
                              deleteIds={values.removeMedia}
                              addMedia={values.addMedia}
                              mediaName={MEDIA_NAME.SURVEYORREPORT}
                              getDocumentUrl={getDocumentUrl}
                            />
                            <ErrorMessage component="div" name="report" className="error-message text-center" />
                          </div>
                        </Col>
                      </Row>
                      <Row className="m-0">
                        <Col className="mt-2">
                          <div className="mb-2">
                            <Field
                              label="Upload Images"
                              id="images"
                              name="images"
                              type="multi-upload-image"
                              setFieldValue={setFieldValue}
                              required
                              values={values.images}
                              deleteIds={values.removeMedia}
                              addMedia={values.addMedia}
                              mediaName={MEDIA_NAME.SURVEYORREPORTIMAGES}
                            />
                            <ErrorMessage component="div" name="images" className="error-message text-center" />
                          </div>
                          <div className="mt-2">
                            <Field
                              label="Upload Other Document If Any"
                              buttonText="Upload Document"
                              name="otherDocument"
                              type="single-document"
                              value={
                                values.otherDocument
                                  ? values.otherDocument?.id
                                    ? [values.otherDocument]
                                    : values.otherDocument
                                  : ''
                              }
                              className="form-control"
                              onChangeText={setFieldValue}
                              deleteIds={values.removeMedia}
                              addMedia={values.addMedia}
                              mediaName={MEDIA_NAME.SURVEYOROTHERDOCUMENT}
                              getDocumentUrl={getDocumentUrl}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-2">
                            <Field
                              label="Upload Boat Verifications"
                              buttonText="Upload Document"
                              name="boatVerifications"
                              type="single-document"
                              value={values.boatVerifications?.id ? [values.boatVerifications] : values.boatVerifications}
                              className="form-control"
                              required
                              onChangeText={setFieldValue}
                              deleteIds={values.removeMedia}
                              addMedia={values.addMedia}
                              mediaName={MEDIA_NAME.SURVEYORBOATVERIFICATION}
                              getDocumentUrl={getDocumentUrl}
                            />
                            <ErrorMessage component="div" name="boatVerifications" className="error-message text-center" />
                          </div>
                          <div className="mt-5">
                            <Field
                              label="Upload Video"
                              onChangeText={setFieldValue}
                              name="video"
                              id={'video'}
                              type="video"
                              value={values.video}
                              disableButton={this.disableButton}
                              className="form-control"
                              deleteIds={values.removeMedia}
                              addMedia={values.addMedia}
                              mediaName={MEDIA_NAME.SURVEYORREPORTVIDEO}
                              getDocumentUrl={getDocumentUrl}
                              required
                            />
                            <ErrorMessage component="div" name="video" className="error-message text-center" />
                          </div>
                        </Col>
                      </Row>
                    </CardContent>
                  </Card>
                  <div className="d-flex justify-content-center mt-4 mb-4 craete--surveyor--buttons">
                    <button
                      className="btn btn-o-black btn-o-ghost-black mr-3"
                      onClick={() => cancelHandler(history)}
                    >
                      <IconSEArrowLeft height="1em" className="mr-2" /> Back
                    </button>
                    <button
                      disabled={disabled || createReportLoading}
                      className="btn btn-black"
                      type="submit"
                    >
                      {surveyorReport?.id ? 'Update' : 'Submit to buyer'}
                    </button>
                  </div>
                </Form>
              )}
            ></Formik>
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  createReportSuccess: state.salesEngineReducer?.createReportSuccess,
  createReportLoading: state.salesEngineReducer?.createReportLoading,
  updateReportSuccess: state.salesEngineReducer?.updateReportSuccess,
  reportLoading: state.salesEngineReducer?.reportLoading,
  getReportSuccess: state.salesEngineReducer?.getReportSuccess,
  surveyorReport: state.salesEngineReducer?.surveyorReport,
})

const mapDispatchToProps = dispatch => ({
  createSurveyorReport: data => dispatch(createSurveyorReport(data)),
  clearReportFlag: () => dispatch(clearReportFlag()),
  getDocumentLinks: data => dispatch(getDocumentLinks(data)),
  getDocumentUrl: data => dispatch(getDocumentUrl(data)),
  getSurveyorReport: data => dispatch(getSurveyorReport(data)),
  updateSurveyorReport: data => dispatch(updateSurveyorReport(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurveyorReport)
