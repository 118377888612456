import { Box } from '@material-ui/core'
import { ErrorMessage, Form, Formik } from 'formik'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { DashboardLayout, Field, TableCard } from '../../components'
import { ButtonComponent } from '../../components/form/Button'
import { CommonModal } from '../../components/modal/commonModal'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
import {
  clear_getRentBoatTripDetails,
  clear_updateRentBoatTripDetails,
  getRentBoatTripDetails,
  updateRentBoatTripDetails,
} from '../../redux/actions'
import {
  helpTextEnum,
  rentBoatTripForCard,
  RentBookingStatusBadgeClassname,
  RentTripStatusLabels,
} from '../../util/enums/enums'
import { copyObj, isPrivate, isShared, isHourly, getRentStatusMod } from '../../util/utilFunctions'
import '../../assets/css/component/manageRentBoat.scss'

const tripAliases = rentBoatTripForCard

const tripCatgs = {
  [tripAliases.shared]: 'shared',
  [tripAliases.private]: 'private',
  [tripAliases.rentPerHour]: 'rentPerHour',
  shared: tripAliases.shared,
  private: tripAliases.private,
  rentPerHour: tripAliases.rentPerHour,
}

const tripLabels = {
  [tripAliases.shared]: 'Passenger',
  [tripAliases.private]: 'Trip',
  [tripAliases.rentPerHour]: 'Boat',
}

const ManageTripDetails = ({
  history,
  location: { pathname, search, state: { boatDetails, pageState = { view: 'slots' } } = {} },
  getTripDetails,
  getTripDetails_data,
  clear_getTripDetails,
  updateTripDetails,
  updateTripDetails_data,
  clear_updateTripDetails,
}) => {
  const [refresh, setRefresh] = useState(0)
  const [tripAlias, setTripAlias] = useState('')
  const [slotsTableData, setSlotsTableData] = useState([])
  const [bookingsTableData, setBookingsTableData] = useState([])
  const [showBookingsTable, setShowBookingsTable] = useState(false)
  const [tz, setTz] = useState('')
  const [titleContent, setTitleContent] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({ page: 1, limit: 20 })
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [message, setMessage] = useState({ text: '', type: '' })

  useEffect(() => {
    boatDetails && setTripAlias(boatDetails.trip?.alias || '')
  }, [boatDetails])

  useEffect(() => {
    boatDetails && getTripDetails({ boatId: boatDetails.id })
  }, [boatDetails, refresh, showBookingsTable])

  useEffect(() => {
    if (getTripDetails_data.success) {
      const _tz = getTripDetails_data.data.timezoneName
      setTz(_tz)

      const _slots = (getTripDetails_data.data.slots || []).map(({ __typename, ...s }) => ({
        ...s,
        bookings: (getTripDetails_data.data.bookings || []).filter(b => String(b.slot) === String(s._id)),
      }))

      setSlotsTableData(_slots)

      switch (pageState.view) {
        case 'slots': {
          setTitleContent([])
          setShowBookingsTable(false)
          setBookingsTableData([])
          break
        }

        case 'bookings': {
          const slot = _slots.find(s => s._id === pageState.slotId)

          if (slot) {
            setTitleContent([
              [
                { label: 'Start Date', value: moment.utc(slot.startDate).format('MMM DD, YYYY') },
                { label: 'Start Time', value: moment.utc(slot.startTime).format('hh:mm A') },
                { label: 'End Date', value: moment.utc(slot.endDate).format('MMM DD, YYYY') },
                { label: 'End Time', value: moment.utc(slot.endTime).format('hh:mm A') },
              ],
            ])

            setShowBookingsTable(true)
            setBookingsTableData(slot.bookings)
          } else {
            setTitleContent([])
            setShowBookingsTable(false)
            setBookingsTableData([])
            history.replace({ pathname, state: { boatDetails, pageState: { view: 'slots' } } })
          }

          break
        }

        default: {
          setTitleContent([])
          setShowBookingsTable(false)
          setBookingsTableData([])
          history.replace({ pathname, state: { boatDetails, pageState: { view: 'slots' } } })
        }
      }
    }

    if (getTripDetails_data.failure) {
      toast.error(getTripDetails_data.message || 'Error while fetching trip details', { autoClose: 5000 })
    }

    if (getTripDetails_data.success || getTripDetails_data.failure) {
      clear_getTripDetails()
    }
  }, [getTripDetails_data])

  useEffect(() => {
    if (updateTripDetails_data.success) {
      toast.success(updateTripDetails_data.message, { autoClose: 5000 })
      setModalOpen(false)
      setModalData(null)
      setMessage({ type: '', text: '' })
      setRefresh(r => r + 1)
    }

    if (updateTripDetails_data.failure) {
      setMessage({
        text: updateTripDetails_data.message,
        type: 'error',
      })
    }

    if (updateTripDetails_data.success || updateTripDetails_data.failure) {
      clear_updateTripDetails()
    }
  }, [updateTripDetails_data])

  useEffect(() => {
    const page = new URLSearchParams(search).get('page') || 1
    const tableData = pageState.view === 'slots' ? slotsTableData : bookingsTableData

    setPaginationConfig(cfg => ({
      ...cfg,
      page: page * cfg.limit <= tableData.length ? page : Math.ceil(tableData.length / cfg.limit),
    }))
  }, [search, slotsTableData, bookingsTableData])

  useEffect(() => {
    setPaginationConfig(cfg => ({ ...cfg, page: 1 }))
  }, [pageState])

  if (!boatDetails) {
    history.replace('/manage-boat-rents')
    return null
  }

  const getSlotsTableColumns = () =>
    [
      {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: data => moment.utc(data.value).format('MMM DD, YYYY'),
      },
      {
        Header: 'Start Time',
        accessor: 'startTime',
        Cell: data => moment.utc(data.value).format('hh:mm A'),
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        Cell: data => moment.utc(data.value).format('MMM DD, YYYY'),
      },
      {
        Header: 'End Time',
        accessor: 'endTime',
        Cell: data => moment.utc(data.value).format('hh:mm A'),
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: data => getConvertedPrice(data.value),
      },
      !isPrivate(tripAlias) && {
        Header: `Total ${tripLabels[tripAlias]}s`,
        accessor: 'total',
        minWidth: 150,
      },
      {
        Header: isPrivate(tripAlias) ? 'Available' : `Available ${tripLabels[tripAlias]}s`,
        accessor: 'available',
        minWidth: 150,
        Cell: ({ value }) => (isPrivate(tripAlias) ? (value === 1 ? 'Available' : 'In Rent') : value),
      },
      {
        minWidth: 175,
        Cell: data => (
          <div className="d-flex justify-content-center">
            {data.original.bookings.length ? (
              <ButtonComponent
                className="btn mr-2"
                color={data.original.bookings.some(b => b.isCancelled) ? 'danger' : 'view'}
                onClick={() => {
                  setTitleContent([
                    [
                      { label: 'Start Date', value: moment.utc(data.original.startDate).format('MMM DD, YYYY') },
                      { label: 'Start Time', value: moment.utc(data.original.startTime).format('hh:mm A') },
                      { label: 'End Date', value: moment.utc(data.original.endDate).format('MMM DD, YYYY') },
                      { label: 'End Time', value: moment.utc(data.original.endTime).format('hh:mm A') },
                    ],
                  ])

                  setBookingsTableData(data.original.bookings)
                  setShowBookingsTable(true)

                  history.replace({
                    pathname,
                    state: { boatDetails, pageState: { view: 'bookings', slotId: data.original._id } },
                  })
                }}
              >
                View Bookings
              </ButtonComponent>
            ) : (
              <>
                <ButtonComponent
                  className="btn mr-2"
                  color="edit"
                  onClick={() => {
                    setModalData(data.original)
                    setModalOpen(true)
                  }}
                >
                  Edit
                </ButtonComponent>
                <ButtonComponent className="btn" color="danger" onClick={() => deleteSlot(data.original)}>
                  Delete
                </ButtonComponent>
              </>
            )}
          </div>
        ),
      },
    ].filter(Boolean)

  const getBookingsTableCols = () => [
    {
      Header: 'Booked Date',
      accessor: 'bookedAt',
      Cell: data => moment(data.value).format('MMM DD, YYYY'),
    },
    {
      Header: 'Booked Time',
      accessor: 'bookedAt',
      Cell: data => moment(data.value).format('hh:mm A'),
    },
    {
      Header: 'Customer',
      Cell: ({ original }) => (
        <a
          href="javascript:void(0)"
          className="Verified"
          style={{ backgroundColor: '#007bff' }}
          onClick={() => redirectToUserProfile(original.customer, history)}
        >
          {original.customer.firstName || ''} {original.customer.lastName || ''}
        </a>
      ),
    },
    {
      Header: 'Ticket ID',
      accessor: 'ticketId',
      minWidth: 200,
    },
    {
      Header: 'Passengers',
      accessor: 'units',
    },
    {
      Header: 'Total Cost',
      accessor: 'payment.totalAmount',
      Cell: data => getConvertedPrice(data.value),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value, original: { isQRScanned, isCancelled } }) => {
        const status = getRentStatusMod(value, isQRScanned, isCancelled)
        return <span className={RentBookingStatusBadgeClassname[status]}>{RentTripStatusLabels[status]}</span>
      },
    },
  ]

  const getInitValues = tz => {
    const utcOffset = moment().tz(tz).utcOffset()

    const startDate = modalData?.startDate ? moment(modalData.startDate).tz(tz).subtract(utcOffset, 'minutes') : null
    const startTime = modalData?.startTime ? moment(modalData.startTime).tz(tz).subtract(utcOffset, 'minutes') : null
    const endDate = modalData?.endDate ? moment(modalData.endDate).tz(tz).subtract(utcOffset, 'minutes') : null
    const endTime = modalData?.endTime ? moment(modalData.endTime).tz(tz).subtract(utcOffset, 'minutes') : null

    return {
      ...modalData,
      startDate,
      startTime,
      endDate,
      endTime,
      price: modalData?.price || null,
      total: modalData?.total || 1,
    }
  }

  const validationSchema = yup.object().shape({
    startDate: yup.string().required('Start Date is required').nullable(),
    startTime: yup.string().required('Start Time is required').nullable(),
    endDate: yup.string().required('End Date is required').nullable(),
    endTime: yup.string().required('End Time is required').nullable(),
    price: yup
      .number()
      .typeError('Price must be a number')
      .required('Price is required')
      .moreThan(0, `Price must be greater than 0`)
      .nullable(),
    total: yup
      .number()
      .typeError(`Total ${tripLabels[tripAlias]}s must be a number`)
      .required(`Total ${tripLabels[tripAlias]}s is required`)
      .min(1, `${tripLabels[tripAlias]}s must be greater than 0`)
      .nullable(),
  })

  const updateSlots = (values, actions) => {
    let err = false

    const utcOffset = moment().tz(tz).utcOffset()

    const sd = moment(values.startDate).utc().add(utcOffset, 'minutes').startOf('day')
    const st = moment(values.startTime).utc().add(utcOffset, 'minutes').startOf('minutes')
    const ed = moment(values.endDate).utc().add(utcOffset, 'minutes').startOf('day')
    const et = moment(values.endTime).utc().add(utcOffset, 'minutes').startOf('minutes')

    const s = sd.clone().hour(st.hour()).minute(st.minute()).startOf('minute')
    const e = ed.clone().hour(et.hour()).minute(et.minute()).startOf('minute')

    const t = moment.utc().add(utcOffset, 'minutes')

    if (sd.isBefore(t, 'days')) {
      actions.setFieldError('startDate', 'Start Date must be today or later')
      err = true
    } else if (s.isBefore(t)) {
      actions.setFieldError('startTime', 'Start Time must be later than current time')
      err = true
    }

    if (e.isBefore(s)) {
      actions.setFieldError('endDate', 'End Date must be later than Start Date')
      actions.setFieldError('endTime', 'End Time must be later than Start Time')
      err = true
    }

    if (isShared(tripAlias) && values.total > boatDetails.maximumGuest) {
      actions.setErrors({
        total: `The total guest must be less than or equal to your boat's capacity of ${boatDetails.maximumGuest}`,
      })
      err = true
    }

    if (err) return

    setMessage({ type: '', text: '' })

    const sVal = s.toISOString()
    const eVal = e.toISOString()

    const vals = {
      _id: values._id,
      type: tripCatgs[tripAlias],
      startDate: sVal,
      startTime: sVal,
      endDate: eVal,
      endTime: eVal,
      price: parseFloat(values.price),
      total: isPrivate(tripAlias) ? 1 : parseInt(values.total),
      available: isPrivate(tripAlias) ? 1 : parseInt(values.total),
    }

    updateTripDetails({
      id: boatDetails.id,
      data: { tripDetails: vals },
    })
  }

  const modalOnClose = () => {
    setModalOpen(false)
    setModalData(null)
    setMessage({ type: '', text: '' })
  }

  const deleteSlot = ({ _id }) => {
    let tripDetails = copyObj(slotsTableData).map(({ bookings, ...s }) => s)
    tripDetails = tripDetails.filter(t => t._id === _id).map(t => ({ ...t, available: -1 }))

    updateTripDetails({
      id: boatDetails.id,
      data: { tripDetails },
    })
  }

  const onBack = () => {
    setTitleContent([])
    setShowBookingsTable(false)
    setBookingsTableData([])
    history.replace({ pathname, state: { boatDetails, pageState: { view: 'slots' } } })
  }

  const getPaginatedData = (data, { page = 1, limit = 20 } = {}) => {
    return data.slice((page - 1) * limit, page * limit)
  }

  return (
    <DashboardLayout>
      <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table">
        <TableCard
          className="manage--boat--table"
          title={titleJSX(boatDetails, showBookingsTable && onBack)}
          headerContent={
            <>
              <h3 className="h3">Trip Details</h3>

              {titleContent.map(sec => (
                <div className="d-flex">
                  {sec.map(line => (
                    <div className="d-flex" style={{ marginLeft: '25px' }}>
                      <span style={{ fontWeight: 'bold' }}>
                        {line.label}
                        <br />
                        <span style={{ color: '#061e9c', fontSize: '1.1em' }}>{line.value}</span>
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </>
          }
          button={{ name: 'Add Time Slot' }}
          openModal
          setModalOpen={() => setModalOpen(true)}
          columns={showBookingsTable ? getBookingsTableCols() : getSlotsTableColumns()}
          data={
            showBookingsTable
              ? getPaginatedData(bookingsTableData, paginationConfig)
              : getPaginatedData(slotsTableData, paginationConfig)
          }
          loading={getTripDetails_data.loading || updateTripDetails_data.loading}
          total={showBookingsTable ? bookingsTableData.length : slotsTableData.length}
          action={setPaginationConfig}
        />
      </div>

      {modalOpen && (
        <CommonModal
          open={modalOpen}
          close={modalOnClose}
          title={(modalData ? 'Edit' : 'Add') + ' Trip Detail'}
          className="manage--boat-rent-modal-root"
        >
          <Box>
            <Formik
              initialValues={getInitValues(tz)}
              validationSchema={validationSchema}
              validateOnChange
              onSubmit={updateSlots}
              enableReinitialize
            >
              {({ setFieldValue, values, handleSubmit }) => (
                <>
                  <div className="rent--form--detail-modal">
                    <Form>
                      <Row>
                        <Col xs={6}>
                          <div className="mb-2">
                            <label className="required mr-1">
                              <span>Trip Starting Date</span>
                            </label>

                            <Field
                              label="Trip Starting Date"
                              type="select-date-time"
                              name="startDate"
                              isTimeFormatFalse
                              timezoneName={tz}
                              placeholder="Trip Starting Date"
                              value={values.startDate}
                              className="mb-0"
                              isUpdate
                              required
                              onChange={val => setFieldValue('startDate', val)}
                            />

                            <ErrorMessage name="startDate" component="div" className="error-message" />
                          </div>
                        </Col>

                        <Col xs={6}>
                          <div className="mb-2">
                            <label className="required mr-1">
                              <span>Trip Starting Time</span>
                            </label>

                            <Field
                              label="Trip Starting Time"
                              type="select-date-time"
                              name="startTime"
                              isDateFormatFalse
                              timezoneName={tz}
                              placeholder="Trip Starting Time"
                              value={values.startTime}
                              className="mb-0"
                              isUpdate
                              required
                              onChange={val => setFieldValue('startTime', val)}
                            />

                            <ErrorMessage name="startTime" component="div" className="error-message" />
                          </div>
                        </Col>

                        <Col xs={6}>
                          <div className="mb-2">
                            <label className="required mr-1">
                              <span>Trip End Date</span>
                            </label>

                            <Field
                              label="Trip End Date"
                              type="select-date-time"
                              name="endDate"
                              isTimeFormatFalse
                              timezoneName={tz}
                              placeholder="Trip End Date"
                              value={values.endDate}
                              className="mb-0"
                              isUpdate
                              required
                              onChange={val => setFieldValue('endDate', val)}
                            />

                            <ErrorMessage name="endDate" component="div" className="error-message" />
                          </div>
                        </Col>

                        <Col xs={6}>
                          <div className="mb-2">
                            <label className="required mr-1">
                              <span>Trip Finishing Time</span>
                            </label>

                            <Field
                              label="Trip Finishing Time"
                              type="select-date-time"
                              name="endTime"
                              timezoneName={tz}
                              isDateFormatFalse
                              placeholder="Trip Finishing Time"
                              value={values.endTime}
                              className="mb-0"
                              isUpdate
                              required
                              onChange={val => setFieldValue('endTime', val)}
                            />

                            <ErrorMessage name="endTime" component="div" className="error-message" />
                          </div>
                        </Col>

                        <Col xs={6}>
                          <div className="mb-2">
                            <Field
                              label={tripAlias === tripAliases.shared ? 'Price per Person US$' : 'Trip Price US$'}
                              helpText={helpTextEnum.priceInput}
                              type="number"
                              name="price"
                              placeholder={tripAlias === tripAliases.shared ? 'Price per Person' : 'Trip Price'}
                              value={values.price}
                              required
                              onChangeText={e => setFieldValue('price', e.target.value)}
                            />

                            <ErrorMessage name="price" component="div" className="error-message" />
                          </div>
                        </Col>

                        {isPrivate(tripAlias) || (
                          <Col xs={6}>
                            <div className="mb-2">
                              <Field
                                label="Maximum number of Passengers"
                                type="number"
                                name="total"
                                placeholder="Maximum number of Passengers"
                                value={values.total}
                                required
                                onChangeText={e => setFieldValue('total', e.target.value)}
                              />

                              <ErrorMessage name="total" component="div" className="error-message" />
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Form>
                  </div>

                  <div className="manage--rb--btn-footer justify-content-between">
                    <div>
                      <div>
                        <div>
                          <strong>TAX NOTE:</strong>
                        </div>
                        <div>You will need to include the VAT or GST tax in the prices.</div>
                      </div>

                      <div style={{ marginTop: 12 }}>
                        {message.text && message.type === 'error' && <div className="boat--rent--err-msg">{message.text}</div>}
                      </div>
                    </div>

                    <ButtonComponent
                      color="success"
                      variant="outline"
                      className="boat--rent--manage--details"
                      onClick={handleSubmit}
                    >
                      {modalData ? 'Update' : 'Add'}
                    </ButtonComponent>
                  </div>
                </>
              )}
            </Formik>
          </Box>
        </CommonModal>
      )}
    </DashboardLayout>
  )
}

const mapStateToProps = state => ({
  getTripDetails_data: state.boatRentReducer.getTripDetails_data,
  updateTripDetails_data: state.boatRentReducer.updateTripDetails_data,
})

const mapDispatchToProps = dispatch => ({
  getTripDetails: data => dispatch(getRentBoatTripDetails(data)),
  clear_getTripDetails: () => dispatch(clear_getRentBoatTripDetails()),
  updateTripDetails: data => dispatch(updateRentBoatTripDetails(data)),
  clear_updateTripDetails: data => dispatch(clear_updateRentBoatTripDetails(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageTripDetails)

const titleJSX = (boatDetails, back) => (
  <div className="d-flex flex-column">
    {/* <div className="my--request--inner--title">
      <span>Trip Details</span>
    </div> */}

    <div className="request--common--detail--section">
      <div className="d-flex">
        <div>
          <div className="request--common--detail--div">
            <span className="request--common--detail--title">
              Ad ID: <span className="request--common--detail--data">{boatDetails.adId}</span>
            </span>
          </div>
          <div className="request--common--detail--div">
            <span className="request--common--detail--title">
              Trip: <span className="request--common--detail--data">{boatDetails.trip.alias}</span>
            </span>
          </div>
          <div className="request--common--detail--div">
            <span className="request--common--detail--title">
              Trip Type: <span className="request--common--detail--data">{boatDetails.tripType.alias}</span>
            </span>
          </div>
        </div>
      </div>

      {back && (
        <div className="mt-4 request--common--detail--div">
          <button className="btn btn-outline-primary py-1 px-4" onClick={back}>
            Back
          </button>
        </div>
      )}
    </div>
  </div>
)
