import * as Sentry from "@sentry/react";
import { BrowserRouter as Router } from 'react-router-dom'
import React from 'react'
import { render, hydrate } from 'react-dom'
import withClearCache from './hoc/withClearCache'
import configureStore from './redux/store'
import { Provider } from 'react-redux'
import App from './App'
import { envConfig } from "./config";

const MainApp = withClearCache(App)
const { store, persistor } = configureStore()

const renderMethod = module.hot ? render : hydrate

Sentry.init({
  dsn: envConfig.SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({}),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


renderMethod(
  <Router>
    <Provider store={store}>
      <MainApp />
    </Provider>
  </Router>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
