export const data = [
    {
        title: 'Direct Connection with Buyers ',
        text: `One of the key advantages of joining our platform as a manufacturer is the ability to connect directly with buyers from around the world. You can highlight the unique features and specifications of your boats, share detailed images and videos, and provide comprehensive product descriptions to attract potential customers. This direct engagement allows you to build strong relationships with buyers and provide them with the information they need to make informed purchase decisions.`
    },
    {
        title: `Maximize Your Brand's Impact`,
        text: `AdamSea offers a range of marketing and promotional opportunities to further enhance your brand visibility. Through targeted advertising campaigns, featured listings, and partnerships with industry influencers, we can help amplify your brand's reach and generate increased exposure for your boats. Our community of boat enthusiasts, sellers, and service providers creates opportunities for collaboration, knowledge sharing, and industry networking, fostering a supportive and dynamic environment.`
    },
    {
        title: 'Elevate Your Manufacturing Business',
        text: `Our platform also offers valuable insights and analytics to help you optimize your marketing strategies and track the performance of your listings. You'll have access to data on buyer preferences, market trends, and sales performance, empowering you to make informed decisions and refine your manufacturing and marketing strategies accordingly.
        Partnering with AdamSea.com as a manufacturer not only allows you to expand your customer base and increase sales but also provides a platform where you can interact with fellow industry professionals.`
    },
]