export const socketEvent= {
  Connect : 'connect',
  Connection : 'connection',
  Disconnect : 'disconnect',
  Message : 'message', 
  End : 'end',
  LastActive : 'user_last_active',
  Subscribe : 'subscribe',
  Unsubscribe : 'unsubscribe',
  Send : 'send',
  Update : 'update',
  Welcome : 'welcome',
  Heartbeat : 'heartbeat',
  JoinChannel : 'join-channel',
  LeaveChannel : 'leave-channel',
  CreateChannel : 'create-channel', 
  updatedChannel : 'updated-channel', 
  ChatSocket : 'chat-socket',
  LiveChatCounts : 'live-chat-counts',
  DeleteMessage : 'delete-message',
  DeliverMessage : 'deliver-message',
  UserChats : 'user-chats',
  ReadMessages : 'read-messages',
  LiveLocation : 'live-location',
  OfflineUserCounts:'offline-user-counts',
  AddOrRemoveUser:'add-or-remove-user'
}