export const currencyEnum = [
  { label: 'USD', name: 'United States Dollar (USD)' },
  { label: 'EUR', name: 'Euro (EUR)' },
  { label: 'CAD', name: 'Canadian Dollar (CAD)' },
  { label: 'INR', name: 'Indian Rupee (INR)' },
  { label: 'AED', name: 'UAE Dirham (AED)' },
  { label: 'GBP', name: 'British Pound (GBP)' },
  { label: 'ARS', name: 'Argentine Peso (ARS)' },
  { label: 'AUD', name: 'Australian Dollar (AUD)' },
  { label: 'BGN', name: 'Bulgarian Lev (BGN)' },
  { label: 'BRL', name: 'Brazilian Real (BRL)' },
  { label: 'BSD', name: 'Bahamas Dollar (BSD)' },
  { label: 'CLP', name: 'Chilean Peso (CLP)' },
  { label: 'CNY', name: 'Chinese Renminbi (CNY)' },
  { label: 'COP', name: 'Colombian Peso (COP)' },
  { label: 'CZK', name: 'Czech Koruna (CZK)' },
  { label: 'DOP', name: 'Dominican Republic Peso (DOP)' },
  { label: 'EGP', name: 'Egyptian Pound (EGP)' },
  { label: 'FJD', name: 'Fijian Dollar (FJD)' },
  { label: 'GTQ', name: 'Guatemalan Quetzal (GTQ)' },
  { label: 'HKD', name: 'Hong Kong Dollar (HKD)' },
  // { label: 'HRK', name: 'Croatian Kuna (HRK)' },
  { label: 'HUF', name: 'Hungarian Forint (HUF)' },
  { label: 'IDR', name: 'Indonesian Rupiah (IDR)' },
  { label: 'ILS', name: 'Israeli Shekel (ILS)' },
  { label: 'ISK', name: 'Iceland Krona (ISK)' },
  { label: 'JPY', name: 'Japanese Yen (JPY)' },
  { label: 'KRW', name: 'South Korean Won (KRW)' },
  { label: 'KZT', name: 'Kazakhstan Tenge (KZT)' },
  { label: 'MXN', name: 'Mexican Peso (MXN)' },
  { label: 'MYR', name: 'Malaysian Ringgit (MYR)' },
  { label: 'NOK', name: 'Norwegian Krone (NOK)' },
  { label: 'NZD', name: 'New Zealand Dollar (NZD)' },
  { label: 'PAB', name: 'Panamanian Balboa (PAB)' },
  { label: 'PEN', name: 'Peruvian New Sol (PEN)' },
  { label: 'PHP', name: 'Philippine Peso (PHP)' },
  { label: 'PKR', name: 'Pakistani Rupee (PKR)' },
  { label: 'PLN', name: 'Polish Zloty (PLN)' },
  { label: 'PYG', name: 'Paraguay Guarani (PYG)' },
  { label: 'RON', name: 'Romanian Leu (RON)' },
  { label: 'RUB', name: 'Russian Ruble (RUB)' },
  { label: 'SAR', name: 'Saudi Riyal (SAR)' },
  { label: 'SEK', name: 'Swedish Krona (SEK)' },
  { label: 'SGD', name: 'Singapore Dollar (SGD)' },
  { label: 'THB', name: 'Thai Baht (THB)' },
  { label: 'TRY', name: 'Turkish New Lira (TRY)' },
  { label: 'TWD', name: 'Taiwanese Dollar (TWD)' },
  { label: 'UAH', name: 'Ukrainian Hryvnia (UAH)' },
  { label: 'UYU', name: 'Uruguayan Peso (UYU)' },
  { label: 'ZAR', name: 'South African Rand (ZAR)' },
  { label: "BND", name: "Brunei Dollar (BND)" },

  // { label: "AFN", name: "Afghanistan Afghani (AFN)" },
  // { label: "ALL", name: "Albanian Lek (ALL)" },
  // { label: "DZD", name: "Algerian Dinar (DZD)" },
  // { label: "ADP", name: "Andorran Peseta (ADP)" },
  // { label: "AOA", name: "Angolan Kwanza (AOA)" },
  // { label: "XCD", name: "Anguillan Dollar (XCD)" },
  // { label: "XCD", name: "Antiguan & Barbudan Dollar (XCD)" },
  // { label: "AMD", name: "Armenian Dram (AMD)" },
  // { label: "AWG", name: "Aruban Guilder (AWG)" },
  // { label: "AZN", name: "Azerbaijani New Manat (AZN)" },
  // { label: "BHD", name: "Bahraini Dinar (BHD)" },
  // { label: "BDT", name: "Bangladesh Taka (BDT)" },
  // { label: "BBD", name: "Barbadian Dollar (BBD)" },
  // { label: "BYR", name: "Belarusian Ruble (BYR)" },
  // { label: "BYN", name: "Belarusian Ruble (BYN)" },
  // { label: "BZD", name: "Belizean Dollar (BZD)" },
  // { label: "XOF", name: "Benin Franc (XOF)" },
  // { label: "BMD", name: "Bermudian Dollar (BMD)" },
  // { label: "BTN", name: "Bhutan Ngultrum (BTN)" },
  // { label: "BOB", name: "Bolivian Boliviano (BOB)" },
  // { label: "BAM", name: "Bosnia & Herzegovinan Marka (BAM)" },
  // { label: "BWP", name: "Botswanan Pula (BWP)" },
  // { label: "GBP", name: "British Indian Ocean Pound (GBP)" },
  // { label: "BND", name: "Brunei Dollar (BND)" },
  // { label: "XOF", name: "Burkina Faso Franc (XOF)" },
  // { label: "BIF", name: "Burundi Franc (BIF)" },
  // { label: "KHR", name: "Cambodian Riel (KHR)" },
  // { label: "XAF", name: "Cameroon Franc (XAF)" },
  // { label: "EUR", name: "Canary Islands Euro (EUR)" },
  // { label: "GBP", name: "Canton & Enderbury Is. Pound (GBP)" },
  // { label: "CVE", name: "Cape Verde Escudo (CVE)" },
  // { label: "KYD", name: "Cayman Islands Dollar (KYD)" },
  // { label: "XAF", name: "Central African Republic Franc (XAF)" },
  // { label: "XAF", name: "CFA Franc BEAC (XAF)" },
  // { label: "XAF", name: "Chad Franc (XAF)" },
  // { label: "AUD", name: "Christmas Island Dollar (AUD)" },
  // { label: "AUD", name: "Cocos (Keeling) Is. Dollar (AUD)" },
  // { label: "KMF", name: "Comoran Franc (KMF)" },
  // { label: "XAF", name: "Congo Republic Franc (XAF)" },
  // { label: "CDF", name: "Congolese Franc (CDF)" },
  // { label: "NZD", name: "Cook Islands Dollar (NZD)" },
  // { label: "CRC", name: "Costa Rican Colon (CRC)" },
  // { label: "CUP", name: "Cuban Peso (CUP)" },
  // { label: "DKK", name: "Danish Krone (DKK)" },
  // { label: "DJF", name: "Djiboutian Franc (DJF)" },
  // { label: "XCD", name: "Dominica Dollar (XCD)" },
  // { label: "XCD", name: "East Caribbean Dollar (XCD)" },
  // { label: "USD", name: "East Timor Dollar (USD)" },
  // { label: "ECS", name: "Ecuadorean Sucre (ECS)" },
  // { label: "SVC", name: "El Salvador Colon (SVC)" },
  // { label: "XAF", name: "Equatorial Guinea Franc (XAF)" },
  // { label: "ERN", name: "Eritrean Nakfa (ERN)" },
  // { label: "ETB", name: "Ethiopian Birr (ETB)" },
  // { label: "FKP", name: "Falkland Islands Pound (FKP)" },
  // { label: "DKK", name: "Faroe Islands Krone (DKK)" },
  // { label: "XPF", name: "French Polynesian Franc (XPF)" },
  // { label: "XAF", name: "Gabon Franc (XAF)" },
  // { label: "GMD", name: "Gambian Dalasi (GMD)" },
  // { label: "GEL", name: "Georgian Lari (GEL)" },
  // { label: "GIP", name: "Gibraltar Pound (GIP)" },
  // { label: "DKK", name: "Greenland Krone (DKK)" },
  // { label: "XCD", name: "Grenadan Dollar (XCD)" },
  // { label: "USD", name: "Guam Dollar (USD)" },
  // { label: "GBP", name: "Guernsey Pound (GBP)" },
  // { label: "XOF", name: "Guinea-Bissau Franc (XOF)" },
  // { label: "GNF", name: "Guinean Franc (GNF)" },
  // { label: "GYD", name: "Guyanese Dollar (GYD)" },
  // { label: "HTG", name: "Haitian Gourde (HTG)" },
  // { label: "HNL", name: "Honduras Lempira (HNL)" },
  // { label: "IRR", name: "Iranian Rial (IRR)" },
  // { label: "IQD", name: "Iraqi Dinar (IQD)" },
  // { label: "IEP", name: "Irish Punt (IEP)" },
  // { label: "GBP", name: "Isle of Man Pound (GBP)" },
  // { label: "XOF", name: "Ivory Coast Franc (XOF)" },
  // { label: "JMD", name: "Jamaican Dollar (JMD)" },
  // { label: "JEP", name: "Jersey Pound (JEP)" },
  // { label: "USD", name: "Johnston Atoll Dollar (USD)" },
  // { label: "JOD", name: "Jordanian Dinar (JOD)" },
  // { label: "KES", name: "Kenyan Shilling (KES)" },
  // { label: "AUD", name: "Kiribati Dollar (AUD)" },
  // { label: "KWD", name: "Kuwaiti Dinar (KWD)" },
  // { label: "KGS", name: "Kyrgyzstan Som (KGS)" },
  // { label: "LAK", name: "Laotian Kip (LAK)" },
  // { label: "LBP", name: "Lebanese Pound (LBP)" },
  // { label: "LSL", name: "Lesotho Loti (LSL)" },
  // { label: "LRD", name: "Liberian Dollar (LRD)" },
  // { label: "LYD", name: "Libyan Dinar (LYD)" },
  // { label: "CHF", name: "Liechtenstein Franc (CHF)" },
  // { label: "MOP", name: "Macau Pataca (MOP)" },
  // { label: "MKD", name: "Macedonian Denar (MKD)" },
  // { label: "MGA", name: "Madagascar Ariary (MGA)" },
  // { label: "MWK", name: "Malawian Kwacha (MWK)" },
  // { label: "MVR", name: "Maldives Rufiyaa (MVR)" },
  // { label: "XOF", name: "Mali Republic Franc (XOF)" },
  // { label: "EUR", name: "Martinique Euro (EUR)" },
  // { label: "MRO", name: "Mauritanian Ouguiya (MRO)" },
  // { label: "MUR", name: "Mauritian Rupee (MUR)" },
  // { label: "EUR", name: "Mayotte Euro (EUR)" },
  // { label: "USD", name: "Micronesian Dollar (USD)" },
  // { label: "USD", name: "Midway Islands Dollar (USD)" },
  // { label: "MDL", name: "Moldovan Leu (MDL)" },
  // { label: "EUR", name: "Monaco Euro (EUR)" },
  // { label: "MNT", name: "Mongolian Tugrik (MNT)" },
  // { label: "EUR", name: "Montenegro Euro (EUR)" },
  // { label: "XCD", name: "Montserrat Dollar (XCD)" },
  // { label: "MAD", name: "Moroccan Dirham (MAD)" },
  // { label: "MZN", name: "Mozambique New Metical (MZN)" },
  // { label: "MMK", name: "Myanmar Kyat (MMK)" },
  // { label: "NAD", name: "Namibian Dollar (NAD)" },
  // { label: "AUD", name: "Nauru Dollar (AUD)" },
  // { label: "NPR", name: "Nepalese Rupee (NPR)" },
  // { label: "ANG", name: "Netherlands Antillean Guilder (ANG)" },
  // { label: "GHS", name: "New Ghana Cedi (GHS)" },
  // { label: "SDG", name: "New Sudanese Pound (SDG)" },
  // { label: "NIO", name: "Nicaraguan Cordoba (NIO)" },
  // { label: "XOF", name: "Niger Republic Franc (XOF)" },
  // { label: "NGN", name: "Nigerian Naira (NGN)" },
  // { label: "NZD", name: "Niue Dollar (NZD)" },
  // { label: "AUD", name: "Norfolk Island Dollar (AUD)" },
  // { label: "KPW", name: "North Korean Won (KPW)" },
  // { label: "USD", name: "Northern Mariana Is. Dollar (USD)" },
  // { label: "CNH", name: "Offshore Chinese Renminbi (CNH)" },
  // { label: "OMR", name: "Omani Rial (OMR)" },
  // { label: "USD", name: "Palau Dollar (USD)" },
  // { label: "PGK", name: "Papua New Guinea Kina (PGK)" },
  // { label: "NZD", name: "Pitcairn Islands Dollar (NZD)" },
  // { label: "USD", name: "Puerto Rican Dollar (USD)" },
  // { label: "QAR", name: "Qatari Rial (QAR)" },
  // { label: "RWF", name: "Rwandan Franc (RWF)" },
  // { label: "EUR", name: "Saint Pierre & Miquelon Euro (EUR)" },
  // { label: "EUR", name: "San Marino Euro (EUR)" },
  // { label: "STD", name: "Sao Tome & Principe Dobra (STD)" },
  // { label: "XOF", name: "Senegalese Franc (XOF)" },
  // { label: "RSD", name: "Serbian Dinar (RSD)" },
  // { label: "SCR", name: "Seychelles Rupee (SCR)" },
  // { label: "SLL", name: "Sierra Leone Leone (SLL)" },
  // { label: "SBD", name: "Solomon Islands Dollar (SBD)" },
  // { label: "SOS", name: "Somali Shilling (SOS)" },
  // { label: "LKR", name: "Sri Lankan Rupee (LKR)" },
  // { label: "SHP", name: "St. Helena Pound (SHP)" },
  // { label: "XCD", name: "St. Kitts & Nevis Dollar (XCD)" },
  // { label: "XCD", name: "St. Lucia Dollar (XCD)" },
  // { label: "XCD", name: "St. Vincent Dollar (XCD)" },
  // { label: "SDD", name: "Sudanese Dinar (SDD)" },
  // { label: "SRD", name: "Suriname Dollar (SRD)" },
  // { label: "SZL", name: "Swaziland Lilangeni (SZL)" },
  // { label: "CHF", name: "Swiss Franc (CHF)" },
  // { label: "SYP", name: "Syrian Pound (SYP)" },
  // { label: "TJS", name: "Tajikistani Somoni (TJS)" },
  // { label: "TZS", name: "Tanzanian Shilling (TZS)" },
  // { label: "THO", name: "Thai Baht Onshore (THO)" },
  // { label: "XOF", name: "Togo Republic Franc (XOF)" },
  // { label: "NZD", name: "Tokelau Dollar (NZD)" },
  // { label: "TOP", name: "Tonga Paanga (TOP)" },
  // { label: "TTD", name: "Trinidad & Tobago Dollar (TTD)" },
  // { label: "TND", name: "Tunisian Dinar (TND)" },
  // { label: "TRL", name: "Turkish Old Lira (TRL)" },
  // { label: "TMT", name: "Turkmenistan Manat (TMT)" },
  // { label: "USD", name: "Turks & Caicos Dollar (USD)" },
  // { label: "AUD", name: "Tuvalu Dollar (AUD)" },
  // { label: "UGX", name: "Ugandan Shilling (UGX)" },
  // { label: "UZS", name: "Uzbekistani Soum (UZS)" },
  // { label: "VUV", name: "Vanuatu Vatu (VUV)" },
  // { label: "EUR", name: "Vatican Euro (EUR)" },
  // { label: "VEE", name: "Venezuela Bolivar Essentials (VEE)" },
  // { label: "VEF", name: "Venezuelan Bolivar Fuerte (VEF)" },
  // { label: "VND", name: "Vietnamese Dong (VND)" },
  // { label: "WST", name: "West Samoan Tala (WST)" },
  // { label: "YER", name: "Yemeni Rial (YER)" },
  // { label: "ZMK", name: "Zambian Kwacha (ZMK)" },
  // { label: "ZWL", name: "Zimbabwean Dollar (ZWL)" },
]
