import * as schemas from '../../graphql/notificationsSchema'
import { createSlice } from '../helper'

const notificationsSlice = createSlice('notifications', {
  read: {
    type: 'query',
    schema: schemas.read,
  },
  markAsRead: {
    type: 'mutation',
    schema: schemas.markAsRead,
  },
  remove: {
    type: 'mutation',
    schema: schemas.remove,
  },
})

export default notificationsSlice
