import {
  GET_CATEGORIES_WISE_BANNER_SUCCESS,
  GET_CATEGORIES_WISE_BANNER_FAILURE,
  GET_CATEGORIES_WISE_BANNER,
} from '../actionTypes'
import { graphqlClient } from '../../helpers/graphqlClient'
import { put, takeLatest, all } from 'redux-saga/effects'
import { getBannerByModuleQuery } from '../../graphql/boatSchema'

export function getHomeBannersApi(input) {
  return graphqlClient
    .query({
      query: getBannerByModuleQuery,
      variables: { input },
    })
    .then(res => {
      return res
    })
    .catch(error => {
      throw error
    })
}

export function* getHomeBannersData(action) {
  const { fieldName, ...newAction } = action.payload
  newAction.isBanner = true
  try {
    const data = yield getHomeBannersApi(newAction)
    data.fieldName = action.payload.fieldName
    yield put({ type: GET_CATEGORIES_WISE_BANNER_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_CATEGORIES_WISE_BANNER_FAILURE, error, payload: action.payload.fieldName })
  }
}

export function* getHomeBannerSaga() {
  yield takeLatest(GET_CATEGORIES_WISE_BANNER, getHomeBannersData)
}
