import classNames from 'classnames'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { chatTimeFormat } from '../../helpers/dateTimeHelper'
import { MessagesAudioAndText } from './messageAudioAndText'
import { MessagesImagesAndVideo } from './messagesImagesVideos'
import KeyboardArrowDownIcon from '@material-ui/icons/ExpandMore';
import PlaceIcon from '@material-ui/icons/Place';
import OutsideClickHandler from 'react-outside-click-handler';
import { deleteMessageChatByIDAction, setReadMessage, setUserList } from '../../redux/actions/chatAction'
import { useDispatch, useSelector } from 'react-redux'
import { getChatsME, getSelectUser } from '../../redux/selector/chat'
import { chatType } from '../../util/chat'
import GoogleMarkerChat from '../chats/googleMarkerChat'
import { MessageType, MessageTypeEnum } from '../../util/enums/enums'
import { socket } from '../../socket'
import { socketEvent } from '../../util/enums/socketEnums'

export const MessagesWrapper = ({  id,isOneTimeMessage,isReadByAll,isDelivered,location,mediaUrl,messageBody,messageType,metaData, parentMsgId,reactions,sender,status,createdAt, updatedAt}) => {
    const dispatch = useDispatch()
    const [isAllMessageOpen, setIsAllMessageOpen] = useState(false)
    const currentUser = useSelector(getChatsME)
    const selectUser = useSelector(getSelectUser)
    const toggleAllMessageHandler = () => setIsAllMessageOpen(isAllMessageOpen => !isAllMessageOpen)

    const me = currentUser.id === (sender?.id  )
    let images=null;
    let audioVideo= null;
    let text= null;

    switch (messageType) {
        case  MessageTypeEnum.DOCUMENT:
        case MessageTypeEnum.IMAGE:
            images=[mediaUrl]
            break;
        case MessageTypeEnum.AUDIO:
            audioVideo=[mediaUrl??""]
        case MessageTypeEnum.VIDEO :
            images=[mediaUrl??""]
                break;
        case MessageTypeEnum.TEXT:
                    text=messageBody
                    break;
        default:
            break;
    }


    return (
        <React.Fragment>
            <div className={classNames('chat-right-wrapper', {
                'chat-right-wrapper--right': me,
                'chat-right-wrapper--left': !me,
                'chat-right-wrapper--normal': selectUser.chatType === chatType.normalchat,
            })}>
                <div className={classNames('chat-thread-max', {
                    'recieve--messages--section': me,
                    'sent--messages--section': !me
                })}>
                    <div className={classNames('', {
                        "chat--right--message-transparent": images?.length || audioVideo,
                        "chat--right--message-images": images?.length,
                        "chat--right--message-audioVideo": audioVideo,
                        "chat--right--message": me,
                        "chat--right--message--single": images?.length === 1,
                        'chat--right--message--group': selectUser.chatType === chatType.groupChat && me,
                        'chat--right--message--group--name': selectUser.chatType === chatType.groupChat,
                        "chat--left--message": !me,
                        'chat-thread--video--no-padding':messageType === MessageTypeEnum.VIDEO,
                        'chat-file-document-thread': messageType === MessageTypeEnum.DOCUMENT,
                    })}>
                        {
                            me &&

                        <div className='chat--right--message--upIcon'

                        onClick={ me &&  toggleAllMessageHandler}>
                            <div className="chat--nav chat--dropdown--all-messages cursor-pointer">
                                <OutsideClickHandler display="contents" onOutsideClick={() => { setIsAllMessageOpen(false) }}>
                                    {isAllMessageOpen && (
                                        <div className="chat--delete--dropdown">
                                            <ul className="chat--delete--dropdown--ul">
                                                <li onClick={() =>{
                                                    socket.emit(socketEvent.DeleteMessage,JSON.stringify({chatId:metaData.chatId,msgIds:[id]}))
                                                    dispatch(deleteMessageChatByIDAction([id]))
                                                }
                                            }
                                            >Delete Message</li>
                                            </ul>
                                        </div>
                                    )}
                                </OutsideClickHandler>
                            </div>
                            <KeyboardArrowDownIcon className='cursor-pointer' />
                        </div>
                                }

                        {location?.coordinates?.length>0 ? <div className='chat-map'>
                            {selectUser.chatType === chatType.groupChat && !me && <div className='chat-thread--user'>{sender?.name}</div>}
                            <GoogleMarkerChat
                                isInnerPage={false}
                                isNotChat={false}
                                geometricLocation={location?.coordinates}
                                url={<div className='chat-map--icon'><PlaceIcon fontSize="small" /></div>}
                                position={{ lat: location?.coordinates[0], lng: location?.coordinates[1] }}
                            />
                        </div> : MessageTypeEnum.IMAGE===messageType ||MessageTypeEnum.VIDEO===messageType ||MessageTypeEnum.DOCUMENT===messageType ?
                            <MessagesImagesAndVideo {...{ images, messageType ,metaData, sender, me }} /> :

                            <MessagesAudioAndText {...{ audioVideo, text, sender, me }} />}
                        <span className='chat-time'>

                            {chatTimeFormat(createdAt)}
                            {me && <>
                                {isReadByAll ?
                                    <img loading="lazy" src={require('../../assets/images/chat/sentGreen.svg')} alt="chat" />
                                    :
                                    isDelivered ?
                                        <img loading="lazy" src={require('../../assets/images/chat/sent.svg')} alt="chat" />
                                         :
                                         <i className="fa fa-check" aria-hidden="true"></i>
                                }
                            </>}
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
