import {
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAILURE,
  CLEAR_BRANCH_FLAG,
  GET_ALL_BRANCHES,
  GET_ALL_BRANCHES_SUCCESS,
  GET_ALL_BRANCHES_FAILURE,
  DELETE_BRANCH_SUCCESS,
  ADD_BRANCH,
  UPDATE_BRANCH,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAILURE,
  GET_SINGLE_BRANCH,
  GET_SINGLE_BRANCH_SUCCESS,
  GET_SINGLE_BRANCH_FAILURE,
  CHANGE_BRANCH_STATUS,
  CHANGE_BRANCH_STATUS_SUCCESS,
  CHANGE_BRANCH_STATUS_FAILURE,
  DELETE_BRANCH,
} from '../actionTypes'

const InitialState = {
  loading: false,

  isAdded: false,
  isAddedError: false,

  isUpdated: false,
  isUpdateError: false,

  isBranchDeleted: false,
  statusChanged: false,

  branch: {},
  branches: [],
}

export const branchReducer = (state = InitialState, action) => {
  const { branches } = state

  switch (action.type) {
    case ADD_BRANCH:
      return {
        ...state,
        isAdded: false,
        isAddedError: false,
      }

    case ADD_BRANCH_SUCCESS:
      return {
        ...state,
        isAdded: true,
      }

    case ADD_BRANCH_FAILURE:
      return {
        ...state,
        isAddedError: true,
      }

    case GET_SINGLE_BRANCH:
      return {
        ...state,
        branch: {},
      }

    case GET_SINGLE_BRANCH_SUCCESS:
      return {
        ...state,
        branch: action.payload,
      }

    case GET_SINGLE_BRANCH_FAILURE:
      return {
        ...state,
        branch: {},
      }

    case UPDATE_BRANCH:
      return {
        ...state,
        isUpdated: false,
        isUpdateError: false,
      }

    case UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        isUpdated: true,
      }

    case UPDATE_BRANCH_FAILURE:
      return {
        ...state,
        isUpdateError: true,
      }

    case CLEAR_BRANCH_FLAG:
      return {
        ...state,
        isAdded: false,
        isAddedError: false,
        isUpdated: false,
        isUpdateError: false,
        isBranchDeleted: false,
        statusChanged: false,
      }

    case GET_ALL_BRANCHES:
      return {
        ...state,
        loading: true,
      }

    case GET_ALL_BRANCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        branches: action.payload.items,
        branchesTotal: action.payload.total,
      }

    case GET_ALL_BRANCHES_FAILURE:
      return {
        ...state,
        loading: false,
      }

    case DELETE_BRANCH:
      return {
        ...state,
        isBranchDeleted: false,
      }

    case DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        isBranchDeleted: true,
        branches: branches.filter(item => item.id !== action.payload.id),
      }

    case CHANGE_BRANCH_STATUS:
      return {
        ...state,
        statusChanged: false,
      }

    case CHANGE_BRANCH_STATUS_SUCCESS:
      return {
        ...state,
        statusChanged: true,
        branches: branches.map(item => (item.id === action.payload.id ? action.payload : item)),
      }

    case CHANGE_BRANCH_STATUS_FAILURE:
      return {
        ...state,
        statusChanged: false,
      }

    default:
      return state
  }
}
