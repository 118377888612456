import React from 'react'
import moment from 'moment'
import Datetime from 'react-datetime'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import { saveAs } from 'file-saver'

import { getLocalInfo, getLocalStorageItem, setLocalStorageItem } from '../helpers/storageHelper'
import {
  userRoles,
  ratingEnums,
  rentBoatTripForCard,
  RentTripStatusLabels,
  RentBookingStatus,
  STRIPE_FEE_FIXED,
  STRIPE_FEE_NATIONAL_PERC,
  STRIPE_FEE_INTERNATIONAL_PERC,
} from './enums/enums'
import { stringValidation } from '../helpers/yupHelper'
import { SuccessNotify } from '../helpers/notification'
import { graphqlClient } from '../helpers/graphqlClient'
import { getAllOnlineUsers_query } from '../graphql/chat'
import { envConfig } from '../config'

export function renderOptions(options) {
  let optionArray = []
  for (let i = 1; i <= options; i++) {
    optionArray.push(i)
  }
  return optionArray.map(item => {
    return (
      <option key={item} value={item}>
        {item}
      </option>
    )
  })
}
export function renderOptionsWithKey(options) {
  return (
    options &&
    options.map(item => {
      return (
        <option key={item.id} value={item.id}>
          {item.alias}
        </option>
      )
    })
  )
}

export function dateStringFormate(date) {
  return moment(date).toISOString()
}

export function dateStringFormateByHour(date) {
  return moment(date).format('hh:mm a')
}
export const dimensionAspectRatio = (width, height) => {
  return width / height
}
let yesterday = Datetime.moment().subtract(1, 'day')

export const valid = function (current) {
  return current.isAfter(yesterday)
}

export const priceFormat = function (price, toFixed = 2) {
  const formatedPrice = price.toFixed(toFixed).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  return numberWithCommas(formatedPrice.replace(/\.00$/, ''))
}

export const transferFeeFormat = function (price) {
  const formatedPrice = price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  return numberWithCommas(formatedPrice.replace(/\.00$/, ''))
}

export const numberFormat = function (price) {
  return price.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const percentageFormate = function (data) {
  return data ? parseFloat(data).toFixed(1) : 0
}

export const estimationPercentageFormate = function (data) {
  return data ? parseFloat(data).toFixed(0) : 0
}

export const numberWithCommas = price => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function prepareGalleryData(items, imageIndex = 0) {
  const images =
    items &&
    items.length &&
    items.map(item => {
      return {
        src: getImgUrl(item),
      }
    })

  images.unshift(images[imageIndex])
  images.splice(imageIndex + 1, 1)

  return images
}

export function prepareSurveyReportImages(items) {
  const images = items && items.length && items.map(item => item?.url)
  return images
}

export function typeWiseTripLabel(type) {
  return type === 'Shared Trip' ? 'Person' : type === 'Private Trip' ? 'Trip' : 'Hour'
}

export function commonBoatType(alias) {
  return <span className="new-build new-build-bg-home boat--with--map--used">{alias}</span>
  // return alias === "USED" ? (
  //   <span className="new-build new-build-bg-home boat--with--map--used">USED</span>
  // ) : alias === "New Build" ? (
  //   <span className="brand-new-tag boat--with--map--used">New Build</span>
  // ) : alias === "Under Construction" ? (
  //   <span className="under-construction-tag">Under Construction</span>
  // ) : alias === "Pre-Owned" ? (
  //   <span className="new-build new-build-bg-home boat--with--map--used">Pre-Owned</span>
  // ) : (
  //         <span className="brand-new-tag boat--with--map--used">BRAND NEW</span>
  //       );
}

export function commonBoatTypeProfile(value) {
  return value && value.boatStatus && value.boatStatus.alias === 'USED' ? (
    <span className="new-build new-build-bg-home boat--with--map--used">USED</span>
  ) : (
    <span className="brand-new-tag boat--with--map--used">BRAND NEW</span>
  )
}

export function commonBoatShowAllType(data) {
  return data && data.boatStatus && data.boatStatus.alias === 'USED' ? (
    <span className="new-build new-build-bg-home boat--with--map--used">USED</span>
  ) : (
    <span className="brand-new-tag boat--with--map--used">BRAND NEW</span>
  )
}

export function commonMarinaType(provider) {
  return provider && provider.alias === 'Marina' ? (
    <span className="marina-new-tag ">MARINA</span>
  ) : provider && provider.alias === 'Marina & Storage' ? (
    <span className="marina-new-tag">MARINA & STORAGE</span>
  ) : (
    <span className="marina-new-tag">STORAGE</span>
  )
}

export function commonRentType(value) {
  return <div className="rent--tag--grid tag---white">{value.trip?.alias?.replace(/ Trip$/, '')} Trip</div>

  // return value && value.tripType && value.tripType.tripId && value.tripType.tripId.length === 1 ? (
  //   <div className="rent--tag--grid tag---white">Rent Per Hour Trip</div>
  // ) : (
  //   <div className="rent--tag--grid tag---white">Private / Shared Trip</div>
  // )
}

export function commonRecommendedRentType(trip) {
  return trip && trip.alias === 'Rent Per Hour' ? (
    <div className="rent-per-hour-tag position-absolute boat-grid-badge">Rent per hour</div>
  ) : trip && trip.alias === 'Shared Trip' ? (
    <div className="shared-trip-tag position-absolute small-shared boat-grid-badge">Shared Trip</div>
  ) : (
    <div className="private-trip-tag position-absolute boat-grid-badge">private trip</div>
  )
}

export function rentTypeAddress(trip, address, tripAddress) {
  return trip && trip.alias === 'Rent Per Hour' ? <div>{address}</div> : <div>{tripAddress}</div>
}

export function rentTypeName(trip, boatName, tripType, boatLength) {
  return tripType && tripType.tripId && tripType.tripId.length === 1 ? (
    <div>{boatName}</div>
  ) : (
    <div>
      {boatLength} {boatLength && ' Ft / '}
      {tripType && tripType.name}
    </div>
  )
}

export function reviewType(userRole = getLocalInfo('role')) {
  switch (userRole.aliasName) {
    case userRoles.MEMBER:
      return [ratingEnums.communication, ratingEnums.recommended, ratingEnums.goodValue, ratingEnums.goodQuality]

    case userRoles.BOAT_OWNER:
      return [
        ratingEnums.communication,
        ratingEnums.recommended,
        ratingEnums.service,
        ratingEnums.organization,
        ratingEnums.goodValue,
        ratingEnums.goodQuality,
      ]

    case userRoles.BOAT_MANUFACTURER:
      return [ratingEnums.communication, ratingEnums.recommended, ratingEnums.goodValue, ratingEnums.goodQuality]

    case userRoles.SURVEYOR:
      return [
        ratingEnums.communication,
        ratingEnums.recommended,
        ratingEnums.goodValue,
        ratingEnums.surveyQuality,
        ratingEnums.timeRespond,
      ]

    case userRoles.YACHT_SHIPPER:
    case userRoles.AGENT:
    case userRoles.BROKER_AND_DEALER:
      return [
        ratingEnums.communication,
        ratingEnums.recommended,
        ratingEnums.goodValue,
        ratingEnums.servicesQuality,
        ratingEnums.timeRespond,
      ]

    case userRoles.RENT_AND_CHARTER:
      return [ratingEnums.organization, ratingEnums.recommended, ratingEnums.goodValue, ratingEnums.service, ratingEnums.safety]

    case userRoles.SERVICE_AND_MAINTENANCE:
    case userRoles.MARINA_AND_STORAGE:
      return [
        ratingEnums.organization,
        ratingEnums.recommended,
        ratingEnums.valueForMoney,
        ratingEnums.service,
        ratingEnums.safety,
      ]

    default:
      return {}
  }
}

export function ratingUserWiseValidation(userRole) {
  const communicationMessage = stringValidation.required('Please add your communication rating.')
  const goodValueMessage = stringValidation.required('Please add your good value rating.')
  const goodQualityMessage = stringValidation.required('Please add your good quality rating.')
  const organizationMessage = stringValidation.required('Please add your organization rating.')
  const serviceMessage = stringValidation.required('Please add your service rating.')
  const servicesQualityMessage = stringValidation.required('Please add your services quality rating.')
  const timeRespondMessage = stringValidation.required('Please add your time respond rating.')
  const safetyMessage = stringValidation.required('Please add your safety rating.')
  const valueForMoneyMessage = stringValidation.required('Please add your value for money rating.')
  const surveyQualityMessage = stringValidation.required('Please add your value for survey quality.')

  switch (userRole && userRole.aliasName) {
    case userRoles.MEMBER:
      return {
        communication: communicationMessage,
        goodValue: goodValueMessage,
        goodQuality: goodQualityMessage,
      }

    case userRoles.BOAT_OWNER:
      return {
        communication: communicationMessage,
        goodValue: goodValueMessage,
        organization: organizationMessage,
        goodQuality: goodQualityMessage,
        service: serviceMessage,
      }

    case userRoles.BOAT_MANUFACTURER:
      return {
        communication: communicationMessage,
        goodValue: goodValueMessage,
        goodQuality: goodQualityMessage,
      }

    case userRoles.SURVEYOR:
      return {
        communication: communicationMessage,
        goodValue: goodValueMessage,
        surveyQuality: surveyQualityMessage,
        timeRespond: timeRespondMessage,
      }

    case userRoles.YACHT_SHIPPER:
    case userRoles.AGENT:
    case userRoles.BROKER_AND_DEALER:
      return {
        communication: communicationMessage,
        goodValue: goodValueMessage,
        servicesQuality: servicesQualityMessage,
        timeRespond: timeRespondMessage,
      }

    case userRoles.RENT_AND_CHARTER:
      return {
        goodValue: goodValueMessage,
        organization: organizationMessage,
        service: serviceMessage,
        safety: safetyMessage,
      }

    case userRoles.SERVICE_AND_MAINTENANCE:
    case userRoles.MARINA_AND_STORAGE:
      return {
        organization: organizationMessage,
        service: serviceMessage,
        valueForMoney: valueForMoneyMessage,
        safety: safetyMessage,
      }

    default:
      return {}
  }
}

export function reviewTypeProfile(seller) {
  return seller && seller.role && seller.role.role === 'RENT & CHARTER' ? (
    <div className="ratingQuality width-100 d-flex justify-content-around flex-column align-items-center">
      <span className="review-quality">Organization </span>
      <span className="review-quality">Recommended</span>
      <span className="review-quality">Value for money</span>
      <span className="review-quality">Services</span>
      <span className="review-quality">Safety</span>
    </div>
  ) : seller && seller.role && seller.role.role === 'MEMBER' ? (
    <div className="ratingQuality width-100 d-flex justify-content-around flex-column align-items-center">
      <span className="review-quality">Communication</span>
      <span className="review-quality">Recommended</span>
      <span className="review-quality">Good value</span>
      <span className="review-quality">Good quality </span>
    </div>
  ) : seller && seller.role && seller.role.role === 'BOAT OWNER' ? (
    <div className="ratingQuality width-100 d-flex justify-content-around flex-column align-items-center">
      <span className="review-quality">Communication </span>
      <span className="review-quality">Recommended</span>
      <span className="review-quality">Organization </span>
      <span className="review-quality">Service</span>
      <span className="review-quality">Good value </span>
    </div>
  ) : seller && seller.role && seller.role.role === 'BROKER & DEALER' ? (
    <div className="ratingQuality width-100 d-flex justify-content-around flex-column align-items-center">
      <span className="review-quality">Communication</span>
      <span className="review-quality">Recommended</span>
      <span className="review-quality">Good value</span>
      <span className="review-quality">Services quality</span>
    </div>
  ) : seller && seller.role && seller.role.role === 'SERVICE & MAINTENANCE' ? (
    <div className="ratingQuality width-100 d-flex justify-content-around flex-column align-items-center">
      <span className="review-quality">Organization </span>
      <span className="review-quality">Recommended</span>
      <span className="review-quality">Value for money</span>
      <span className="review-quality">Services</span>
      <span className="review-quality">Safety</span>
    </div>
  ) : seller && seller.role && seller.role.role === 'BOAT MANUFACTURER' ? (
    <div className="ratingQuality width-100 d-flex justify-content-around flex-column align-items-center">
      <span className="review-quality">Communication </span>
      <span className="review-quality">Recommended</span>
      <span className="review-quality">Organization </span>
      <span className="review-quality">Service</span>
      <span className="review-quality">Good value </span>
    </div>
  ) : seller && seller.role && seller.role.role === 'SURVEYOR' ? (
    <div className="ratingQuality width-100 d-flex justify-content-around flex-column align-items-center">
      <span className="review-quality">Communication </span>
      <span className="review-quality">Recommended </span>
      <span className="review-quality">Good value</span>
      <span className="review-quality">Survey quality</span>
    </div>
  ) : seller && seller.role && seller.role.role === 'YACHT SHIPPER' ? (
    <div className="ratingQuality width-100 d-flex justify-content-around flex-column align-items-center">
      <span className="review-quality">Communication </span>
      <span className="review-quality">Recommended </span>
      <span className="review-quality">Good value</span>
      <span className="review-quality">Survey quality</span>
    </div>
  ) : seller && seller.role && seller.role.role === 'MARINA & STORAGE' ? (
    <div className="ratingQuality width-100 d-flex justify-content-around flex-column align-items-center">
      <span className="review-quality">Organization </span>
      <span className="review-quality">Recommended</span>
      <span className="review-quality">Value for money</span>
      <span className="review-quality">Services</span>
      <span className="review-quality">Safety</span>
    </div>
  ) : seller && seller.role && seller.role.role === 'BROKER AND DEALER' ? (
    <div className="ratingQuality width-100 d-flex justify-content-around flex-column align-items-center">
      <span className="review-quality">Organization </span>
      <span className="review-quality">Recommended</span>
      <span className="review-quality">Value for money</span>
      <span className="review-quality">Services</span>
      <span className="review-quality">Safety</span>
    </div>
  ) : null
}

export function commonRentTypeLabel(value) {
  return value && value.tripType && value.tripType.tripId && value.tripType.tripId.length === 1
    ? 'Rent Per Hour Trip'
    : ' Private / Shared Trip'
}
export function renderSingleSelectOptions(options) {
  let optionArray = []
  for (let i = 1; i <= options; i++) {
    optionArray.push(i)
  }
  return optionArray.map(item => {
    return {
      label: item,
      value: item,
    }
  })
}

export function downloadSurveyImagesZipFile(items = [], { zipFilename = 'surveyImages', filenamePrefix = 'image' } = {}) {
  let zip = new JSZip()
  let count = 0

  // const images = prepareSurveyReportImages(items)

  items.forEach(function (imgObject, index) {
    // imgObject?.key ||

    const ext = String(imgObject.origUrl || imgObject.url).split('?').shift().split('.').pop()
    const filename = `${filenamePrefix}-${index}.${ext}`

    JSZipUtils.getBinaryContent(imgObject.origUrl || imgObject.url, function (err, data) {
      if (err) {
        throw err
      }
      zip.file(filename, data, { binary: true })
      count++
      if (count == items.length) {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, zipFilename + '.zip')
        })
      }
    })
  })
}

export function downloadFile(url, filename) {
  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`)
      }
      return response.blob()
    })
    .then(blob => {
      const fileUrl = URL.createObjectURL(blob)
      const ext = url.split('.').pop().split(/[#?]/)[0]
      const anchor = document.createElement('a')
      anchor.href = fileUrl
      anchor.download = filename ? filename + (ext ? '.' + ext : '') : url
      anchor.click()
      URL.revokeObjectURL(fileUrl)
    })
    .catch(error => console.error('Error downloading file:', error))
}

export const filterNonEmptyKeys = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key]) {
      acc[key] = obj[key]
    }
    return acc
  }, {})
}

export const copyObj = obj => JSON.parse(JSON.stringify(obj))

export const isPrivate = alias => alias === rentBoatTripForCard.private
export const isShared = alias => alias === rentBoatTripForCard.shared
export const isHourly = alias => alias === rentBoatTripForCard.rentPerHour

export const getId = obj => String(obj?.id || obj?._id || '')

export const getRentStatusMod = (status, isQRScanned, isCancelled) => {
  if (isCancelled) return RentBookingStatus.Cancelled
  if (status === RentBookingStatus.Pending && isQRScanned) return RentBookingStatus.QRScanned
  return RentBookingStatus[status]
}

export const getWithStripeFee = subTotal => {
  const totalNational = (subTotal + STRIPE_FEE_FIXED) / (1 - STRIPE_FEE_NATIONAL_PERC)
  const totalInternational = (subTotal + STRIPE_FEE_FIXED) / (1 - STRIPE_FEE_INTERNATIONAL_PERC)
  const feeNational = totalNational - subTotal
  const feeInternational = totalInternational - subTotal
  return { feeNational, feeInternational, totalNational, totalInternational }
}

export const placeCurrLast = x => {
  x = String(x).replace(/\s/g, '')

  const cur = (x.match(/[a-zA-Z]+/)?.pop() || '').toUpperCase()

  if (cur === 'CAD') {
    return '$' + x.replace(cur, '') + ' CAD'
  }

  return x.replace(cur, '') + ' ' + cur
}

export const copyCodeToClipboard = text => {
  navigator.clipboard?.writeText(text)
  SuccessNotify('Copied')
}

export const getOnlineUsers = (ids, cb) => {
  ids = ids?.filter(Boolean) || []
  if (!ids.length) return

  graphqlClient
    .query({
      query: getAllOnlineUsers_query,
      variables: { userIds: ids },
    })
    .then(res => {
      cb && cb(res.data.getUsersDetails)
    })
}

export const getImgUrl = img => img?.largeUrl || img?.url

export const getDomainUrl = (url = '') => {
  return url
}

/**
 * @param {-1,0,1} status
 */
export const setCookiePolicyStatus = (status = 0) => {
  setLocalStorageItem('cookiePolicy', +status)
}

export const getCookiePolicyStatus = () => {
  return +getLocalStorageItem('cookiePolicy')
}

export function sortStringsBySearch(list, searchString) {
  return list.sort((a, b) => {
    const aStartsWith = a.startsWith(searchString)
    const bStartsWith = b.startsWith(searchString)

    const aIncludes = a.includes(searchString)
    const bIncludes = b.includes(searchString)

    if (aStartsWith && !bStartsWith) {
      return -1
    } else if (!aStartsWith && bStartsWith) {
      return 1
    } else if (aIncludes && !bIncludes) {
      return -1
    } else if (!aIncludes && bIncludes) {
      return 1
    } else {
      return a.localeCompare(b)
    }
  })
}

export function sortObjectsBySearch(list, searchKeys, searchString) {
  return list.sort((a, b) => {
    function getPriority(obj) {
      for (let i = 0; i < searchKeys.length; i++) {
        const key = searchKeys[i]
        if (obj[key] && obj[key].toLowerCase().startsWith(searchString.toLowerCase())) {
          return { type: 'starts', priority: i }
        }
        if (obj[key] && obj[key].toLowerCase().includes(searchString.toLowerCase())) {
          return { type: 'contains', priority: i }
        }
      }
      return { type: 'none', priority: searchKeys.length }
    }

    const aPriority = getPriority(a)
    const bPriority = getPriority(b)

    if (aPriority.type === 'starts' && bPriority.type !== 'starts') {
      return -1
    }
    if (aPriority.type !== 'starts' && bPriority.type === 'starts') {
      return 1
    }
    if (aPriority.type === 'starts' && bPriority.type === 'starts') {
      return aPriority.priority - bPriority.priority
    }

    if (aPriority.type === 'contains' && bPriority.type !== 'contains') {
      return -1
    }
    if (aPriority.type !== 'contains' && bPriority.type === 'contains') {
      return 1
    }
    if (aPriority.type === 'contains' && bPriority.type === 'contains') {
      return aPriority.priority - bPriority.priority
    }

    return 0
  })
}
