import { envConfig } from '../config'
import { getLocalStorageItem } from './storageHelper'

const CHAT_ENDPOINT = envConfig.CHAT_ENDPOINT

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

// initiate chat/topic
export const initiateChat = async (memberId = '') => {
  try {
    const loggedInUserId = getLocalStorageItem('userId')
    if (loggedInUserId && memberId && loggedInUserId !== memberId) {
      const response = await fetch(`${CHAT_ENDPOINT}channel`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          members: [loggedInUserId, memberId],
        }),
      })

      return await response.json()
    }
    return
  } catch (err) {
    console.log(err)
    return null
  }
}

export const blockUnblockUser = async (channelId, userId) => {
  try {
    const response = await fetch(`${CHAT_ENDPOINT}channel/${channelId}/block-user/${userId}`, {
      method: 'POST',
      headers,
    })

    return await response.json()
  } catch (err) {
    console.log(err)
    return err
  }
}

export const fetchChannelConversation = async channelId => {
  try {
    const response = await fetch(`${CHAT_ENDPOINT}channel/channel-conversation/${channelId}`, {
      method: 'GET',
      headers,
    })
    const messages = await response.json()
    return messages
  } catch (err) {
    console.log(err)
    return err
  }
}
