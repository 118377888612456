import { createSelector } from 'reselect';

export const chatSelect = (state) => state?.chatReducer;

export const getChatUsersList = createSelector(chatSelect, (chat) => chat.userList);
export const getChatUsers = createSelector(chatSelect, (chat) => chat.users);
export const getFriendList = createSelector(chatSelect, (chat) => chat.friendList);
export const getFriendSearchList = createSelector(chatSelect, (chat) => chat.friendSearchList);
export const getUserListSearch = createSelector(chatSelect, (chat) => chat.userListSearch);
export const getChatsList = createSelector(chatSelect, (chat) => chat.chatList);
export const getChats = createSelector(chatSelect, (chat) => chat.chats);
export const getChatsME = createSelector(chatSelect, (chat) => chat.currentUser);
export const getHasChatCreated = createSelector(chatSelect, (chat) => chat.hasChatCreated);
export const getFirstTimeCreateChatId = createSelector(chatSelect, (chat) => chat.firstTimeCreateChatId);
export const getIsReadMessage = createSelector(chatSelect, (chat) => chat.isReadMessage);
export const getSelectUser = createSelector(chatSelect, (chat) => chat.selectUser);
export const getMessagePayload = createSelector(chatSelect, (chat) => chat.messagePayload);
export const getThreadMessage = createSelector(chatSelect, (chat) => chat.threadMessage);
export const getIsGetMessage = createSelector(chatSelect, (chat) => chat.isGetMessage);
export const getIsNewMessageRead = createSelector(chatSelect, (chat) => chat.isNewMessageRead);
export const getIsMessageLoading = createSelector(chatSelect, (chat) => chat.isMessageLoading);
export const getIsTextMessage = createSelector(chatSelect, (chat) => chat.isTextMessage);
export const getIsCreatedChatMessage = createSelector(chatSelect, (chat) => chat.isCreatedChatMessage);
export const getIsFriendView = createSelector(chatSelect, (chat) => chat.isFriendView);
export const getIsChatLogout = createSelector(chatSelect, (chat) => chat.isLogout);
export const getIsGroupChatCreateScreen = createSelector(chatSelect, (chat) => chat.isGroupChatCreateScreen);
export const getSelectedGroupIds = createSelector(chatSelect, (chat) => chat.selectedGroupIds);
export const getIsAddGroupName = createSelector(chatSelect, (chat) => chat.isAddGroupName);
export const getUserListPage= createSelector(chatSelect, (chat) => chat.userListPage);
export const getIsEditGroupScreen= createSelector(chatSelect, (chat) => chat.isEditGroupScreen);
export const getIsUpdateGroup = createSelector(chatSelect, (chat) => chat.isUpdateGroup);
export const getIsChatShow = createSelector(chatSelect, (chat) => chat.isChatShow);
export const getIsBlockDisabled = createSelector(chatSelect, (chat) => chat.isBlockDisabled);
export const getIsGalleryOpen = createSelector(chatSelect, (chat) => chat.isGalleryOpen);
export const getIsLeftSideLoader = createSelector(chatSelect, (chat) => chat.isLeftSideLoader);
export const getIsFirstTime = createSelector(chatSelect, (chat) => chat.isFirstTime);
export const getIsDeleMessage = createSelector(chatSelect, (chat) => chat.isDeleMessage);
export const getIsToggleManage = createSelector(chatSelect, (chat) => chat.isToggleManage);
export const getUserProfileChatId = createSelector(chatSelect, (chat) => chat.userProfileChatId);
export const getTextareaHeight = createSelector(chatSelect, (chat) => chat.textareaHeight);