import React, { useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import Truncate from 'react-truncate'
import Rating from 'react-rating'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

import { defaultImage, marketTypes } from '../../../util/enums/enums'
import { formattedDate } from '../../../helpers/dateTimeHelper'
import { Icons } from '../../icons'
import UserContext from '../../../UserContext'
import { viewRentBoatHandler } from '../../../helpers/boatHelper'
import { getConvertedPrice } from '../../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../../CurrencyContext'
import RatingComponent from '../../rating/Rating'

export const RentBoatSearchResults = ({ data }) => {
  const {
    image,
    boatName,
    trip,
    descriptionOfOurTrip,
    createdAt,
    user,
    manufacturer,
    maximumGuest,
    boatLength,
    rating,
    price,
  } = data

  return (
    <Row onClick={() => viewRentBoatHandler(data)}>
      <Col xs={2}>
        <div className="d-flex justify-content-center m-auto">
          <div className="boat-search-result-img d-flex justify-content-center flex-column align-items-center">
            <img loading="lazy" src={(image && image[0]) || defaultImage} alt="boat" className="h-100 width-100" />
          </div>
        </div>
      </Col>

      <Col xs={6}>
        <div className="d-flex flex-column justify-content-around h-100">
          <div>
            <span className="boat-title">{boatName}</span>
            {trip && <span className="ml-2 boat-country font-12 search-result-trip-type-bg text-white p-1">{trip.alias}</span>}
          </div>
          <div>
            <span className="boat-country">
              <Truncate lines={2} ellipsis={<span>..</span>}>
                {descriptionOfOurTrip}
              </Truncate>
            </span>
          </div>
          <div>
            <span className="font-14 font-weight-400 search-div-date">
              {`${formattedDate(createdAt)} -`} {user && `Posted By ${user.firstName} ${user.lastName}`}
            </span>
          </div>
          <div>
            <span className="font-14 font-weight-500 search-div-country">{manufacturer}</span>
          </div>

          <div className="directionRow">
            {/* <div className="alignCenter mr-3">
                            <Icons data="adam-clock-2 location-icon-rent" />
                            <span className="small-text">{tripDuration} Hrs</span>
                        </div> */}
            <div className="alignCenter mr-3">
              <Icons data="adam-user-group location-icon-rent" />
              <span className="small-text">{maximumGuest} </span>
            </div>
            <div className="alignCenter mr-3">
              <Icons data="adam-dimension-1 location-icon-rent" />
              <span className="small-text">{boatLength} ft</span>
            </div>
          </div>
        </div>
      </Col>

      <Col xs={1}>
        <div className="d-flex justify-content-center search-result-market-type-section">
          <span className="font-weight-400 search-result-market-type">{marketTypes.RENT_BOAT}</span>
        </div>
      </Col>

      <Col xs={1}>
        <div className="d-flex justify-content-center">
          <div className="d-flex">
            <div className="mr-1 search-result-rate-section-margin">
              <RatingComponent className="rating-clr search-result-rate-section" rating={rating} />
            </div>
            {/* <span className="rating-count">{rating}</span> */}
          </div>
        </div>
      </Col>

      <Col xs={2}>
        <CurrencyContextConsumer>
          {({ currentCurrency }) => (
            <div className="d-flex justify-content-center">
              <span className="search-result-price-section">{getConvertedPrice(price, currentCurrency)} Per Person</span>
            </div>
          )}
        </CurrencyContextConsumer>
      </Col>
    </Row>
  )
}
