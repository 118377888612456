import { Grid, Paper } from "@material-ui/core";
import { Stack } from "@mui/material";
import { Layout } from "../../components";
import '../integrityCompliance/integrityCompliance.scss'
import { footerScreenImages, icons } from "../../util/enums/enums";

export default function FraudProtection() {
    return (
        <Layout>
            <div className="container100 py-5">
                <Grid container spacing={4}>
                    <Grid items xs={12}>
                        <h1 className="r-h1">Fraud Protection</h1>
                    </Grid>
                    <Grid className='d-flex justify-content-center align-items-center m-auto py-5' items md={6} sm={12}>
                        <div className='position-relative'>
                            <img width={368} height={227} className='rounded' loading="lazy" src={footerScreenImages.int_and_comp_white_rope} alt='White rope'/>
                            <img className='position-absolute dotted-yellow--bg-int-comp' loading="lazy" src={footerScreenImages.bottom_right_yellow_dot} alt='Dots'/>
                        </div>
                    </Grid>
                    <Grid className="my-5" items md={6} sm={12}>
                        <span className="int-comp-text">
                            Purchasing online, especially with high-dollar transactions like these, is nerve-wracking. Sellers wonder if they will really get their money and buyers wonder if their money is well spent on a safe and seaworthy vessel. AdamSea does everything possible to protect both buyers and sellers from fraud. At AdamSea, it is in our best interest to help serve our customers. We provide thousands of listings every week and we are proud to say that most of our users have had very satisfying experiences.
                        </span>
                    </Grid>
                    <Grid className="my-5" items md={6} sm={12}>
                        <p className="int-comp-subtitle">Vigilance and Research Recommended for Buyers and Sellers</p>
                        <span className="int-comp-text">
                            We are constantly monitoring our advertisements and eliminating fraudulent postings and scams on a regular basis. However, some of them are not completely evident and thus may result in fraudulent ads posted on our site. Buyers and sellers should be vigilant in conducting their own research whenever they choose to purchase or sell a vessel, boat or yacht, among other offerings on this site.
                        </span>
                    </Grid>
                    <Grid className='m-auto py-5 text-center' items md={6} sm={12}>
                        <img loading='lazy' src={footerScreenImages.connecting_marine_cmt} alt='Boat'/>
                    </Grid>
                    <Grid items sm={12} md={6}>
                        <div style={{ minHeight: '350px' }} className='info-card'>
                            <Stack>
                                <img className="mr-5" loading='lazy' width={64} src={icons.free_seller_listings} alt="free seller listings" />
                                <span className='broker--info--subtitle text-primary my-3'>Free Seller Listings</span>
                                <span className='broker--info--detail'>Sellers are not required to pay to list on AdamSea, only to pay a percentage of the sale price when the boat sells. This applies to single sellers of used boats, broker sellers, and new build manufacturers. If you are asked to pay to list your boat, beware of fraud.</span>
                            </Stack>
                        </div>
                    </Grid>
                    <Grid items sm={12} md={6}>
                        <div style={{ minHeight: '350px' }} className='info-card'>
                            <Stack>
                                <img className="mr-5" loading='lazy' width={64} src={icons.buyer_security_alert} alt='Buyer'/>
                                <span className='broker--info--subtitle text-primary my-3'>Buyer Security Alert</span>
                                <span className='broker--info--detail'>Buyers besides paying the purchase price, also pay for surveying through AdamSea. Paying the shipper for the pickup and delivery of the boat is arrangements made through AdamSea to coordinate the activities. Only authorized surveyors and shippers are contacted through AdamSea. If you are contacted by someone outside the system, be aware and alert for fraud opportunities. read more about our Terms of Use.</span>
                            </Stack>
                        </div>
                    </Grid>

                    <Grid className="d-flex flex-column align-items-center" sm={12}>
                        <div className="int-comp-heading d-flex justify-content-center align-items-center text-center p-5">
                            Our unique sales engine provides transparency and fraud protection. It tracks every step of the sales process, including offers, negotiations, and communications between surveyors and shippers. Additionally, our payment system safeguards funds until delivery and conditions are met or refunds the buyer.
                        </div>
                        <div className="blue--hr"></div>
                    </Grid>
                </Grid>

                <Paper className="py-5" elevation={0}>
                    <div className="compliance--div py-4">
                        <img width={70} src={icons.protecting_your_txn} alt='Protection'/>
                        <Stack className="pl-5">
                            <span className="int-comp-subtitle">Protecting Your Transactions</span>
                            <span className='int-comp-text'>Never send or wire money to sellers or buyers. This includes mailing a cheque or using payment services like PayPal, Amazon Payments, Google Wallet/ Checkout, Canada Post, any international post, Western Union, or MoneyGram. Typically, scammers will use these services and will even falsify documents from a legitimate company. It is best to avoid them entirely and keep things local in your AdamSea.com account! AdamSea will not be responsible for any transactions that may take place outside of AdamSea.com.</span>
                        </Stack>
                    </div>
                    <div className="compliance--div py-4">
                        <img width={70} src={icons.protecting_privacy_policy} alt='Protection'/>
                        <Stack className="pl-5">
                            <span className="int-comp-subtitle">Protecting Privacy and Security</span>
                            <span className='int-comp-text'>We carefully vet and screen all of our users before they are allowed to transact on our platform. This includes verifying their identities, as well Be aware not to give out personal or banking information (e.g. social insurance number, bank account number, or Credit Card details) over the Internet. AdamSea.com does not offer any transaction or payment for the market of Yacht Services, Marina, and storage.as their business licenses and certifications, where applicable.</span>
                        </Stack>
                    </div>
                    <div className="compliance--div py-4">
                        <img width={70} src={icons.secure_market_place} alt='Secure'/>
                        <Stack className="pl-5">
                            <span className="int-comp-subtitle">Secure Marketplace</span>
                            <span className='int-comp-text'>AdamSea.com connects buyers and sellers in search of a marine vessel. AdamSea.com guarantees the security of any transactions run under its own Sales Engine, it endorses sales and purchases. but It does not provide any form of financing and type of money payment contracts with its buyers or sellers.</span>
                        </Stack>
                    </div>
                    <div className="compliance--div py-4">
                        <img width={70} src={icons.trust_and_transparency} alt='Trust'/>
                        <Stack className="pl-5">
                            <span className="int-comp-subtitle">Trust and Transparency</span>
                            <span className='int-comp-text'>In order to ensure that all transactions are conducted fairly and transparently, we use secure payment systems that protect both the E-mails claiming to be a part of the AdamSea.ca association should be ignored as well as reported to us. Even if the e-mail address contains the AdamSea.com name and logo, this does not mean it is from one of our representatives.buyer and seller. We also provide dispute resolution services to help resolve any conflicts that may arise.</span>
                        </Stack>
                    </div>
                </Paper>
            </div>
        </Layout>
    )
}
