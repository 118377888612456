import { put, takeLatest, all } from 'redux-saga/effects'
import { graphqlClient } from '../../helpers/graphqlClient'
import { categoryWiseAd } from '../../graphql/advertisementSchema'
import {
  GET_CATEGORY_WISE_ADVERTISEMENTS_SUCCESS,
  GET_CATEGORY_WISE_ADVERTISEMENTS_FAILURE,
  GET_CATEGORY_WISE_ADVERTISEMENTS,
} from '../actionTypes'

function getAdvertisementApi(data) {
  return graphqlClient
    .query({
      query: categoryWiseAd,
      variables: {
        category: data,
      },
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(e => {
      throw e
    })
}

function* getAdvertisementData(action) {
  try {
    const data = yield getAdvertisementApi(action.payload)
    yield put({ type: GET_CATEGORY_WISE_ADVERTISEMENTS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_CATEGORY_WISE_ADVERTISEMENTS_FAILURE, e })
  }
}

function* getAdvertisementSaga() {
  yield takeLatest(GET_CATEGORY_WISE_ADVERTISEMENTS, getAdvertisementData)
}

export default function* advertisementSaga() {
  yield all([getAdvertisementSaga()])
}
