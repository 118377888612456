import gql from 'graphql-tag'

export const getAllUserFaq = gql`
  query getAllFaqList($page: Int, $isAdmin: Boolean) {
    getAllFaqList(page: $page, isAdmin: $isAdmin) {
      items {
        status
        id
        description
        question
        __typename
      }
      __typename
    }
  }
`

export const createSuggestionSchema = gql`
  mutation createSuggestion($input: SuggestionInput!) {
    createSuggestion(input: $input) {
      fullName
    }
  }
`
export const createServiceSchema = gql`
  mutation createCustomerService($input: CustomerServiceInput!) {
    createCustomerService(input: $input) {
      fullName
    }
  }
`

export const createComplaintSchema = gql`
  mutation createComplaint($input: ComplaintInput!) {
    createComplaint(input: $input) {
      fullName
    }
  }
`