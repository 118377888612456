import { Container, Row, Col } from "react-bootstrap"
import { Layout } from "../../components"
import ImageBanner from "../../components/mainBanner/ImageBanner"
import { footerScreenImages, icons } from "../../util/enums/enums"

import './integrityCompliance.scss'
import { Paper, Stack } from "@mui/material"

const IntegrityCompliance = () => {
    return (
        <Layout>
            <div className="container100">
                <Row className="py-5">
                    <Col><h1 className="r-h1">integrity and Compliance</h1></Col>
                </Row>
                <Row className="py-5">
                    <Col className='d-flex justify-content-center align-items-center m-auto' lg={6}>
                        <div className='position-relative'>
                            <img width={368} height={227} className='rounded' loading="lazy" src={footerScreenImages.int_and_comp_white_rope} alt='White rope'/>
                            <img className='position-absolute dotted-yellow--bg-int-comp' loading="lazy" src={footerScreenImages.bottom_right_yellow_dot} alt='Dots'/>
                        </div>
                    </Col>
                    <Col>
                        <Stack>
                            <p className="int-comp-subtitle"><span className="text-primary">At AdamSea,</span> we take integrity <br />and compliance seriously. </p>
                            <span className="int-comp-text">We believe in transparency in sales transactions, and that's why we use our innovative sales engine, which provides a
                                step-by-step selling process that ensures the safety of all stakeholders involved, whether it's the seller, buyer, shipper, or surveyor.</span>

                        </Stack>
                    </Col>

                </Row>
                <Row>
                    <Col sm={6}>
                        <Stack className='my-5 position-relative'>
                            <span className='int-comp-title'>Streamlined Boat Sales</span>
                            <span className='int-comp-text py-3'>Our sales engine is designed to be accessible regardless of where you are in the world. All parties involved in the sales engine can see the details of all the steps in real-time, ensuring complete transparency throughout the entire process.<br />We also understand that compliance is crucial when buying or selling a used boat. That's why Adam Sea provides optional trusted surveyor services for each used boat being sold to ensure that the boat complies with the buyer requirements and seller descriptions. The surveyors are highly trained professionals who have years of experience in the industry, ensuring that you receive the best possible service.</span>
                        </Stack>
                    </Col>
                    <Col sm={6} className="d-flex justify-content-center align-items-center">
                        <img loading="lazy" src={footerScreenImages.stream_lined_boat_sales} alt='Boat sales'/>
                    </Col>
                </Row>
                <div className="int-comp-heading d-flex justify-content-center align-items-center text-center p-5">
                    When it comes to integrity and compliance, you can trust Adam Sea to provide the highest level of transparency and professionalism throughout the entire buying or selling process.
                </div>
                <Paper className="py-5" elevation={0}>
                    <div className="compliance--div py-4">
                        <img width={70} src={icons.committed_to_trust} alt='Commitment'/>
                        <Stack className="pl-5">
                            <span className="int-comp-subtitle">Committed to Trust</span>
                            <span className='int-comp-text'>Adam Sea values the trust of its customers, and as such, we take compliance and integrity very seriously. We have established policies and procedures in place to ensure that all of our users are conducting business in a fair and ethical manner.</span>
                        </Stack>
                    </div>
                    <div className="compliance--div py-4">
                        <img width={70} src={icons.user_verification_standard} alt='Verification'/>
                        <Stack className="pl-5">
                            <span className="int-comp-subtitle">User Verification Standards</span>
                            <span className='int-comp-text'>We carefully vet and screen all of our users before they are allowed to transact on our platform. This includes verifying their identities, as well as their business licenses and certifications, where applicable.</span>
                        </Stack>
                    </div>
                    <div className="compliance--div py-4">
                        <img width={70} src={icons.policy_enforcement} alt='Policy'/>
                        <Stack className="pl-5">
                            <span className="int-comp-subtitle">Policy Enforcement</span>
                            <span className='int-comp-text'>We require all users to comply with our terms of service and community guidelines, which outline the standards of behavior we expect from our users. Violations of these policies can result in suspension or termination of their accounts.</span>
                        </Stack>
                    </div>
                    <div className="compliance--div py-4">
                        <img width={70} src={icons.safe_transactions} alt='Transaction'/>
                        <Stack className="pl-5">
                            <span className="int-comp-subtitle">Safe Transactions</span>
                            <span className='int-comp-text'>In order to ensure that all transactions are conducted fairly and transparently, we use secure payment systems that protect both the buyer and seller. We also provide dispute resolution services to help resolve any conflicts that may arise.</span>
                        </Stack>
                    </div>
                    <div className="compliance--div py-4">
                        <img width={70} src={icons.industry_ethics_advocates} alt='Industries'/>
                        <Stack className="pl-5">
                            <span className="int-comp-subtitle">Industry Ethics Advocates</span>
                            <span className='int-comp-text'>Our commitment to compliance and integrity extends beyond just our platform. We strive to promote ethical business practices throughout the industry, and work with regulatory bodies and industry associations to promote best practices and standards.</span>
                        </Stack>
                    </div>
                </Paper>
            </div>
        </Layout>
    )
}

export default IntegrityCompliance
