import { put, takeLatest, all } from 'redux-saga/effects'
import { graphqlClient } from '../../helpers/graphqlClient'
import { categoryWiseVideos } from '../../graphql/VideoSchema'
import { GET_CATEGORY_WISE_VIDEOS_SUCCESS, GET_CATEGORY_WISE_VIDEOS_FAILURE, GET_CATEGORY_WISE_VIDEOS } from '../actionTypes'

function categoryWiseVideosApi(input) {
  return graphqlClient
    .query({
      query: categoryWiseVideos,
      variables: { input },
    })
    .then(res => {
      return res
    })
    .catch(e => {
      throw e
    })
}

function* categoryWiseVideosData(action) {
  try {
    const data = yield categoryWiseVideosApi(action.payload)
    yield put({ type: GET_CATEGORY_WISE_VIDEOS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_CATEGORY_WISE_VIDEOS_FAILURE, e })
  }
}

function* categoryWiseVideosSaga() {
  yield takeLatest(GET_CATEGORY_WISE_VIDEOS, categoryWiseVideosData)
}

export default function* videoSaga() {
  yield all([categoryWiseVideosSaga()])
}
