import { Box, Grid } from "@material-ui/core";
import { Layout } from "../../components";
import '../surveyourInfo/surveyorInfo.scss'
import { footerScreenImages, icons } from "../../util/enums/enums";
import { Stack } from "@mui/material";

export default function SellingProcess() {
    return (
        <Layout>
            <div className="container100 py-5">
                <Grid container spacing={4}>
                    <Grid items xs={12}>
                        <h1 className="r-h1">Selling Process</h1>
                    </Grid>
                    <Grid className="m-auto" items md={5}>
                        <Stack>
                            <p className="surveyor-subtitle">The AdamSea <span className="text-primary">sales engine</span> provides a seamless and secure selling process for boat owners, brokers, and manufacturers. </p>
                            <span className="surveyor-text">By following a strict process, we ensure transparency and safety for every transaction.</span>
                            <img className="mt-5" width={400} loading='lazy' src={icons.horizontal_dotted_line} alt='Dotted line'/>
                        </Stack>
                    </Grid>
                    <Grid items md={5}>
                        <img loading='lazy' width={338} src={footerScreenImages.selling_process_rocket} alt='Selling Process'/>
                    </Grid>
                    <Grid items sm={12}>
                        <Box className='my-5'>
                            <p className="surveyor-title">Sales Engine</p>
                            <img style={{ width: '100%' }} src={footerScreenImages.sales_engine_process} loading='lazy' alt='Sales engine'/>
                        </Box>
                    </Grid>
                    <Grid className="mt-5" items sm={12}>
                        <p className="surveyor-subtitle text-primary">How to sell your boats online in a few easy steps</p>
                    </Grid>
                    <Grid style={{marginLeft:'5rem'}} items md={5}>
                        <div className="selling-process-step-card float-right">
                            <Stack>
                                <img className="my-2" src={icons.first_step} alt='First step'/>
                                <p className="surveyor-text">Listing your watercraft on our platform and connecting with potential buyers to agree on a final price. From there, the buyer has the option to choose a surveyor to inspect the boat, and the surveyor will have a QR code that can be scanned by the boat owner using AS Chats for security purposes. Based on the surveyor's report, the buyer can decide to proceed with the purchase or not.</p>
                            </Stack>
                        </div>
                    </Grid>
                    <Grid items md={6}>
                        <img className="d-sm-block d-none" style={{ marginTop: '9rem' }} width={300} height={225} src={icons.top_right_dotted_line} alt='Dotted line'/>
                    </Grid>
                    <Grid items md={6}>
                        <img className="float-right d-sm-block d-none" style={{ marginTop: '9rem' }} width={300} height={225} src={icons.top_left_dotted_line} alt='Dotted line'/>
                    </Grid>
                    <Grid items md={5}>
                        <div className="selling-process-step-card">
                            <Stack>
                                <img className="my-2" width={79} src={icons.second_step} alt='Second step'/>
                                <p className="surveyor-text">Once the buyer decides to purchase the boat, he can complete the payment using a credit card, and the funds will be held by AdamSea. The buyer can then choose from a certified list of shippers, and the assigned shipper will pick up the boat from the seller. The buyer will have to scan the shipper's QR code using AS Chats for security reasons.</p>
                            </Stack>
                        </div>
                    </Grid>
                    <Grid style={{marginLeft:'5rem'}} items md={5}>
                        <div className="selling-process-step-card float-right">
                            <Stack>
                                <img className="my-2" width={79} src={icons.third_step} alt='Third step'/>
                                <p className="surveyor-text">Finally, the shipper must upload the shipment documents on the platform, notifying all involved parties. Once the shipment is on the way, AdamSea will release the funds to the seller and shipper. With the AdamSea sales engine, buyer have complete control over the selling process, including choosing the price, surveyor, and shipper.</p>
                            </Stack>
                        </div>
                    </Grid>

                </Grid>
            </div>
        </Layout>
    )
}
