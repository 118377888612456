import { currencyEnum } from '../util/enums/currencyEnums'
import { getLocalInfo, getLocalStorageItem } from './storageHelper'
import { nameFormatter } from './string'
import { placeCurrLast, priceFormat, transferFeeFormat } from '../util/utilFunctions'

export const searchBy = require('country-js')

export const getCurrentCurrency = () => {
  if (getLocalStorageItem('currentCurrency')) {
    const { name } = currencyEnum.find(item => item.label === getLocalStorageItem('currentCurrency')) || currencyEnum[0]
    return name
  }
  return ''
}

export const getPriceAsPerCurrency = (selectedCurrency = 'USD') => {
  const currencyRates = getLocalInfo('currencyRates')
  return selectedCurrency && currencyRates && currencyRates[selectedCurrency] ? currencyRates[selectedCurrency] : 1
}

export const getConvertedPrice = (
  price,
  selectedCurrency = null,
  priceLabel = true,
  isTransferFee = false,
  isFormFormate = false
) => {
  if (!+price) return;

  selectedCurrency = selectedCurrency || getLocalStorageItem('currentCurrency')

  const priceAsPerCurrency = getPriceAsPerCurrency(selectedCurrency)

  if (isFormFormate) {
    return placeCurrLast(priceLabel
      ? nameFormatter([selectedCurrency, priceFormat(price * priceAsPerCurrency, 0)])
      : priceFormat(price * priceAsPerCurrency, 0))
  }

  if (isTransferFee) {
    return placeCurrLast(priceLabel
      ? nameFormatter([selectedCurrency, transferFeeFormat(price * priceAsPerCurrency)])
      : transferFeeFormat(price * priceAsPerCurrency))
  }

  return placeCurrLast(priceLabel
    ? nameFormatter([selectedCurrency, priceFormat(price * priceAsPerCurrency)])
    : priceFormat(price * priceAsPerCurrency))
}

export const getUsdPrice = (price, selectedCurrency) => {
  const priceAsPerCurrency = getPriceAsPerCurrency(selectedCurrency)
  return Number(price / priceAsPerCurrency)
}

export const getLocalCurrencyPrice = price => {
  const selectedCurrency = getLocalStorageItem('currentCurrency')

  const priceAsPerCurrency = getPriceAsPerCurrency(selectedCurrency)
  return Number((price * priceAsPerCurrency).toFixed(2))
}
