import {io} from 'socket.io-client'
import { envConfig } from './config';
import { getLocalStorageItem } from './helpers/storageHelper';
import { parse } from "cookie";

const initSocket = (token)=>{
  return io(envConfig.CHAT_SOCKET,{
    autoConnect: true,
    reconnection: true,
    reconnectionAttempts:10,
    forceNew:true,
    extraHeaders: {
      Authorization: token,
    },
    withCredentials:true,
    // transports: ["websocket"],
  });
}
let socket=initSocket(getLocalStorageItem('token'))
const COOKIE_NAME = "AWSALB";



socket.io.on("open", () => {
  socket.io.engine.transport.on("pollComplete", () => {
    const request = socket.io.engine.transport.pollXhr.xhr;
    const cookieHeader = request.getResponseHeader("set-cookie");
    if (!cookieHeader) {
      return;
    }
    cookieHeader.forEach(cookieString => {
      if (cookieString.includes(`${COOKIE_NAME}=`)) {
        const cookie = parse(cookieString);
        socket.io.opts.extraHeaders = {
          cookie: `${COOKIE_NAME}=${cookie[COOKIE_NAME]}`
        }
      }
    });
  });
});


  const listenForTokenChanges = () => {
    window.addEventListener("storage", (event) => {
        const newAccessToken = getLocalStorageItem('token');
        if (newAccessToken) {
          if (socket) {
            socket.disconnect();
          }
          socket = initSocket(newAccessToken);
        }
    });
  };
  
  export { listenForTokenChanges ,socket};

