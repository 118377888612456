import React, { useEffect, useState } from 'react'
import '../footer/footer.scss'
import { Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Layout } from '../layout/layout'
import SingleSelect from '../helper/singleSelect'
import { renderSelectOptions, requireMessage, trimmedValue } from '../../helpers/string'
import { stringValidation } from '../../helpers/yupHelper'
import { getAllCountries } from '../../redux/actions/jobsAction'
import { ButtonComponent } from '../form/Button'
import { Field } from '../ws/Field'
import { getTypeWiseLookup, getUserRoles } from '../../redux/actions'
import { lookupTypes, providerOptions } from '../../util/enums/enums'
import { createComplaint } from '../../redux/actions/userFaqAction'
import { SuccessNotify } from '../../helpers/notification'
import Captcha from '../helper/captcha'

const ContactUsComplaint = props => {
  const [initValues, setInitValues] = useState({
    country: '',
    formUserType: '',
    fullName: '',
    email: '',
    mobileNumber: '',
    role: '',
    complaintAbout: '',
    isMarketTransaction: '',
    description: '',
    desiredOutcome: '',
  })
  const { countries, userRoles, complaintTypes, currentUser, createComplaint, history } = props

  const [recaptchaRef, setRecaptchaRef] = useState(null)

  useEffect(() => {
    const { getUserRoles, getAllCountries, getTypeWiseLookup, currentUser } = props
    Object.keys(currentUser).length > 0 &&
      setInitValues({
        ...initValues,
        country: currentUser?.address && currentUser?.address.length > 0 ? { label: currentUser?.address[0]?.country } : '',
        fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
        email: currentUser?.email,
        mobileNumber: currentUser?.mobileNumber,
        role: { label: currentUser?.role.role, value: currentUser?.role.id },
        complaintAbout:
          complaintTypes && complaintTypes.length > 0 ? { label: complaintTypes[0].alias, value: complaintTypes[0].id } : '',
      })
    getAllCountries({ page: 1, limit: 400 })
    getTypeWiseLookup(lookupTypes.COMPLAINT_ABOUT)
    getUserRoles()
  }, [currentUser])

  const marketTransactionTypes = [
    { id: 'Yes', value: 'Yes' },
    { id: 'No', value: 'No' },
  ]

  return (
    <>
      <Layout>
        <div className="contactFooter-bg contact-us-top-space-header">
          <div className="footer--container--content--section">
            {/* <div className="inner"> */}
            <Container maxWidth="lg" className="contact--container--section">
              <h1 className="r-h1 mt-50 mb-0" style={{ textTransform: 'none' }}>Submit a complaint</h1>
              <p className="mb-4 pt-4">
                Please send us details about the incident you would like to report. Our Complaint Center will analyze your issue
                and take the appropriate measure to make sure this does not occur again in the future.
              </p>
              <p style={{ color: 'red' }} className="mb-1">
                *REQUIRED FIELD
              </p>
              <hr className="mt-0" />

              <Container style={{ padding: 0 }}>
                <Formik
                  initialValues={{ ...initValues }}
                  onSubmit={async (values, { resetForm }) => {
                    const token = await recaptchaRef.current.execute()
                    
                    if (token) {
                      let data = {
                        country: values.country.label,
                        userRole: values.role.value,
                        complaintAbout: values.complaintAbout.label,
                        fullName: values.fullName,
                        email: values.email,
                        mobileNumber: values.mobileNumber,
                        description: values.description,
                        desiredOutcome: values.desiredOutcome,
                      }
                      if (values.formUserType !== '') {
                        data.formUserType = values.formUserType.toLowerCase()
                      }
                      if (values.isMarketTransaction !== '') {
                        if (values.isMarketTransaction === 'Yes') {
                          data.isMarketTransaction = true
                        } else {
                          data.isMarketTransaction = false
                        }
                      }
                      createComplaint(data)
                      setTimeout(() => SuccessNotify('Form successfully submitted'), 100)
                      resetForm()
                      history.goBack()
                    }
                  }}
                  validationSchema={Yup.object().shape({
                    country: Yup.string().required(requireMessage('Country')),
                    fullName: stringValidation.required(requireMessage('Full Name')),
                    email: Yup.string().required(requireMessage('Email')),
                    role: Yup.string().required(requireMessage('Role')),
                    complaintAbout: Yup.string().required(requireMessage('Complaint')),
                    description: Yup.string().required(requireMessage('Description')),
                    desiredOutcome: Yup.string().required(requireMessage('Desired Outcome')),
                  })}
                  render={({ setFieldValue, values, handleSubmit, errors }) => (
                    <Form className="form-left-side-space">
                      {/* <ErrorFocus /> */}
                      <Grid container className="formvalue-width">
                        <Grid item sm={12} className="grid-flex-method-topic  bottom-space-contactus-page">
                          <div className="boatShow-field grid-flex-method align-items-center">
                            <label className="footer-form-label">
                              <span className="required">*</span> Country
                            </label>
                            <div className="footer-form-select-width">
                              <SingleSelect
                                selectedOption={values.country}
                                options={renderSelectOptions(countries, 'name', 'id')}
                                placeholder={'Select County'}
                                onChange={select => {
                                  setFieldValue('country', select)
                                }}
                                styles={{
                                  singleValue: (provided, state) => {
                                    const fontSize = '14px'
                                    return { ...provided, fontSize, color: '#495057' }
                                  },
                                  placeholder: (provided, state) => {
                                    const fontSize = '14px'
                                    return { ...provided, fontSize, color: '#495057' }
                                  },
                                }}
                              />
                              <ErrorMessage component="div" name="country" className="error-message" />
                            </div>
                          </div>
                        </Grid>

                        <Grid item sm={12} className="mb-3 d-flex align-items-center">
                          <label className="footer-form-label">Are You</label>
                          <div style={{ flex: '1' }}>
                            <div className="position-relative register--provider--section">
                              <Field
                                id={'formUserType'}
                                name={'formUserType'}
                                type="radio"
                                value={values.formUserType}
                                onChangeText={e => {
                                  setFieldValue('formUserType', e.target.value)
                                }}
                                options={providerOptions}
                                styles={{ border: 'none' }}
                                flex
                              />
                            </div>
                          </div>
                        </Grid>

                        <Grid item sm={12} className="grid-flex-method  bottom-space-contactus-page align-items-center">
                          <label className="footer-form-label">
                            <span className="required">*</span> Full Name
                          </label>
                          <div style={{ flex: '1' }} className="input-field-space contactus-page-form">
                            <Field
                              name="fullName"
                              value={values.fullName}
                              type="text"
                              className="form-control"
                              onChangeText={e => setFieldValue('fullName', e.target.value)}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'fullName'}
                              component="span"
                            />
                          </div>
                        </Grid>

                        <Grid item sm={12} className="align-items-center grid-flex-method  bottom-space-contactus-page">
                          <label className="footer-form-label">
                            <span className="required">*</span> Email Address
                          </label>
                          <div style={{ flex: '1' }} className="input-field-space contactus-page-form">
                            <Field
                              name="email"
                              value={values.email}
                              type="text"
                              className="form-control"
                              onChangeText={e => setFieldValue('email', e.target.value)}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'email'}
                              component="span"
                            />
                          </div>
                        </Grid>

                        <Grid item sm={12} className="align-items-center grid-flex-method  bottom-space-contactus-page">
                          <label className="footer-form-label">
                            Mobile Number
                          </label>
                          <div style={{ flex: '1' }} className="input-field-space contactus-page-form">
                            <Field
                              name="mobileNumber"
                              value={values.mobileNumber}
                              type="text"
                              className="form-control"
                              onChangeText={e => setFieldValue('mobileNumber', e.target.value)}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'mobileNumber'}
                              component="span"
                            />
                          </div>
                        </Grid>

                        <Grid item sm={12} className="grid-flex-method-topic  bottom-space-contactus-page">
                          <div className="boatShow-field grid-flex-method align-items-center">
                            <label className="footer-form-label">
                              <span className="required">*</span> I Am A(An)
                            </label>
                            <div className="footer-form-select-width">
                              <SingleSelect
                                selectedOption={values.role}
                                options={renderSelectOptions(
                                  userRoles.filter(el => el.type !== 'administration'),
                                  'role',
                                  'id'
                                )}
                                placeholder={'Select Role'}
                                onChange={select => {
                                  setFieldValue('role', select)
                                }}
                                styles={{
                                  singleValue: (provided, state) => {
                                    const fontSize = '14px'
                                    return { ...provided, fontSize, color: '#495057' }
                                  },
                                  placeholder: (provided, state) => {
                                    const fontSize = '14px'
                                    return { ...provided, fontSize, color: '#495057' }
                                  },
                                }}
                              />
                              <ErrorMessage component="div" name="role" className="error-message" />
                            </div>
                          </div>
                        </Grid>

                        <Grid item sm={12} className="grid-flex-method-topic  bottom-space-contactus-page">
                          <div className="boatShow-field grid-flex-method align-items-center">
                            <label className="footer-form-label">
                              <span className="required">*</span> Your Complaint Area
                            </label>
                            <div className="footer-form-select-width">
                              <SingleSelect
                                selectedOption={values.complaintAbout}
                                options={renderSelectOptions(complaintTypes, 'alias', 'id')}
                                placeholder={'Select Complaint'}
                                onChange={select => {
                                  setFieldValue('complaintAbout', select)
                                }}
                                styles={{
                                  singleValue: (provided, state) => {
                                    const fontSize = '14px'
                                    return { ...provided, fontSize, color: '#495057' }
                                  },
                                  placeholder: (provided, state) => {
                                    const fontSize = '14px'
                                    return { ...provided, fontSize, color: '#495057' }
                                  },
                                }}
                              />
                              <ErrorMessage component="div" name="complaintAbout" className="error-message" />
                            </div>
                          </div>
                        </Grid>

                        <Grid item sm={12} className="mb-3 d-flex align-items-center">
                          <label className="footer-form-label">
                            {' '}
                            Are you complaining <br /> about transaction in our market
                          </label>
                          <div style={{ flex: '1' }}>
                            <div className="position-relative register--provider--section">
                              <Field
                                id={'isMarketTransaction'}
                                name={'isMarketTransaction'}
                                type="radio"
                                value={values.isMarketTransaction}
                                onChangeText={e => {
                                  setFieldValue('isMarketTransaction', e.target.value)
                                }}
                                options={marketTransactionTypes}
                                styles={{ border: 'none' }}
                                flex
                              />
                            </div>
                          </div>
                        </Grid>

                        <Grid item sm={12} className="grid-flex-method bottom-space-contactus-page ">
                          <label className="footer-form-label">
                            <span className="required">*</span> Your Complaint
                          </label>
                          <div style={{ flex: '1' }} className="contactus-page-form-suggestion input-field-space">
                            <Field
                              id={'description'}
                              name={'description'}
                              type="textarea"
                              rows={4}
                              className="contactus-page-form "
                              value={values.description}
                              onChangeText={e => {
                                setFieldValue('description', e.target.value)
                              }}
                              onBlur={e => setFieldValue('description', trimmedValue(e.target.value))}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'description'}
                              component="span"
                            />
                          </div>
                        </Grid>

                        <Grid item sm={12} className="grid-flex-method bottom-space-contactus-page ">
                          <label className="footer-form-label">
                            <span className="required">*</span> Desired Outcome
                          </label>
                          <div style={{ flex: '1' }} className="contactus-page-form-suggestion input-field-space">
                            <Field
                              id={'desiredOutcome'}
                              name={'desiredOutcome'}
                              type="textarea"
                              rows={4}
                              value={values.desiredOutcome}
                              onChangeText={e => {
                                setFieldValue('desiredOutcome', e.target.value)
                              }}
                              onBlur={e => setFieldValue('desiredOutcome', trimmedValue(e.target.value))}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'desiredOutcome'}
                              component="span"
                            />
                          </div>
                        </Grid>

                        <Grid item sm={12} className="apply-btn-mt bottom-space-contactus-page">
                          <div className="d-flex" style={{ float: 'right' }}>
                            <ButtonComponent
                              className=" h-auto  btn--padding submit-btn-contactus btn--font btn-outline-info"
                              onClick={handleSubmit}
                            >
                              {'Submit'}
                            </ButtonComponent>
                            <ButtonComponent
                              className=" h-auto as--success--outline btn--padding btn--font"
                              onClick={() => history.goBack()}
                            >
                              {'Cancel'}
                            </ButtonComponent>
                          </div>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                  enableReinitialize={true}
                />
              </Container>
              <div className="mt-2">
                <p>
                  AdamSea filters emails for security reasons.By clicking on " Submit ", you consent to this action in
                  accordance with our Terms of Use & Privacy Policy.
                </p>
              </div>
            </Container>
          </div>
        </div>
        <Captcha getRefValue={setRecaptchaRef} />
      </Layout>
    </>
  )
}

const mapStateToProps = state => ({
  userRoles: state.loginReducer?.roles,
  countries: state.jobsReducer?.countries,
  complaintTypes: state.loginReducer?.complaintTypes,
  currentUser: state.loginReducer?.currentUser,
})

const mapDispatchToProps = dispatch => ({
  getUserRoles: () => dispatch(getUserRoles()),
  getAllCountries: data => dispatch(getAllCountries(data)),
  getTypeWiseLookup: data => dispatch(getTypeWiseLookup(data)),
  createComplaint: data => dispatch(createComplaint(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsComplaint)
