import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getSalesEngineByAgent,
  getSingleSalesEngine,
  assignAgent,
  discardAgentProcess,
  clearDiscardAgentFlag,
  userVisitSalesEngineDashboard,
} from '../../../redux/actions/salesEngineAction'
import { Loader, PaginationBar, TableCard } from '../../../components'

import '../../../styles/manageDashboardTableResponsive.scss'
import { pagination, skeletonType } from '../../../util/enums/enums'
import { ButtonComponent } from '../../../components/form/Button'
import { CurrencyContextConsumer } from '../../../CurrencyContext'
import { getConvertedPrice } from '../../../helpers/currencyConverterHelper'
import { SalesEngineDashboardCardListing } from '../../../components/salesEngine/SalesEngineDashboardCardListing'
import { CommonSalesEnginTitle } from '../../../components/salesEngine/CommonSalesEnginTitle'
import { getPagesValue } from '../../../helpers/jsxHelper'
import { searchInDashboard } from '../../../redux/actions'
import { DashboardSearchSection } from '../../../components/salesEngine/DashboardSearchSection'
import { SkeletonLoader } from '../../../components/loader/SkeletonLoader'

class AgentSalesEngine extends Component {
  componentDidUpdate(prevProps) {
    const {
      getSalesEngineSuccess,
      salesEngine,
      history,
      discardSuccess,
      getSalesEngineByAgent,
      manufacturerId,
      clearDiscardAgentFlag,
      requestAgentSuccess,
      getByAgentSuccess,
      isAgentLoading,
      userVisitSalesEngineDashboard,
    } = this.props

    if (getByAgentSuccess && !isAgentLoading) {
      let data = {}
      if (manufacturerId) {
        data = { manufacturerId }
      }
      userVisitSalesEngineDashboard(data)
    }

    if (getSalesEngineSuccess && salesEngine && salesEngine.id) {
      history && history.push(`/sales-engine-process/${salesEngine.id}`)
    }

    if (discardSuccess || requestAgentSuccess) {
      clearDiscardAgentFlag()
      let params = { limit: pagination.AGENT_PAGE_LIMIT, page: pagination.PAGE_COUNT }
      if (manufacturerId) {
        params = {
          ...params,
          manufacturerId,
        }
      }
      getSalesEngineByAgent(params)
    }
  }
  constructor(props) {
    super(props)
    this.state = {
      columns: [
        {
          Header: 'Model',
          accessor: 'boat.boatName',
        },
        {
          Header: 'Seller',
          accessor: 'seller.firstName',
        },
        {
          Header: 'Buyer',
          accessor: 'buyer.firstName',
        },
        {
          Header: 'Price',
          accessor: 'boat.price',
          Cell: ({ value }) => (
            <CurrencyContextConsumer>
              {({ currentCurrency }) => <span>{getConvertedPrice(value, currentCurrency)}</span>}
            </CurrencyContextConsumer>
          ),
        },
        {
          Header: 'Ad ID',
          accessor: 'boat.adId',
        },
        {
          headerClassName: 'sales--engine--view reset-rt-th',
          Cell: ({ original: { id } }) => (
            <div className="d-flex flex-row justify-content-around">
              <ButtonComponent
                type="button"
                variant="fill"
                onClick={() => props.getSingleSalesEngine({ id })}
                className="sales-engine--view"
              >
                View
              </ButtonComponent>
            </div>
          ),
        },
      ],
      searchTerm: '',
    }
  }

  componentDidMount() {
    const { getSalesEngineByAgent, manufacturerId } = this.props
    let params = {
      limit: pagination.AGENT_PAGE_LIMIT,
      page: getPagesValue(),
    }
    if (manufacturerId) {
      params = {
        ...params,
        manufacturerId,
      }
    }
    getSalesEngineByAgent(params)
  }

  setSearchValue = value => {
    this.setState({ searchTerm: value })
  }

  render() {
    const { columns, searchTerm } = this.state
    const {
      salesEngineByAgent,
      isDealer,
      isAgent,
      currentUser,
      isAgentLoading,
      history,
      getSalesEngineByAgent,
      agentTotal,
      assignAgent,
      discardAgentProcess,
      manufacturerId,
    } = this.props

    return (
      <>
        <div className="manage-dashboard-table">
          <div className="agent--main--title--section pb-0 d-flex">
            {/* <CommonSalesEnginTitle title={isDealer ? 'My deals' : 'My Requests'} /> */}
            {salesEngineByAgent.length > 0 && (
              <div className="total--request--div pt-0">
                <p className="total--request">{`Total ${agentTotal} Requests`}</p>
              </div>
            )}
            <DashboardSearchSection
              action={getSalesEngineByAgent}
              manufacturerId={manufacturerId}
              setSearchValue={this.setSearchValue}
            />
            <span></span>
          </div>
          {salesEngineByAgent?.length ? (
            columns && (
              <SalesEngineDashboardCardListing
                history={history}
                salesEngines={salesEngineByAgent}
                isAgent
                currentUserId={currentUser.id}
                isLoading={isAgentLoading}
                location={history.location}
                acceptRequestByAgent={assignAgent}
                discardAgentProcess={discardAgentProcess}
              />
            )
          ) : (
            !isAgentLoading && <div className="no--record-found">No record Found.</div>
          )}
        </div>

        {!isAgentLoading && agentTotal > pagination.AGENT_PAGE_LIMIT && (
          <div className="mt-3">
            <PaginationBar
              action={getSalesEngineByAgent}
              value={{ manufacturerId }}
              totalRecords={agentTotal}
              pageLimit={pagination.AGENT_PAGE_LIMIT}
              searchTerm={searchTerm}
            />
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser,
  salesEngineByAgent: state.salesEngineReducer?.salesEngineByAgent,
  isAgentLoading: state.salesEngineReducer?.isAgentLoading,
  getSalesEngineSuccess: state.salesEngineReducer?.getSalesEngineSuccess,
  salesEngine: state.salesEngineReducer?.salesEngine,
  agentTotal: state.salesEngineReducer.agentTotal,
  discardSuccess: state.salesEngineReducer.discardSuccess,
  requestAgentSuccess: state.salesEngineReducer.requestAgentSuccess,
  getByAgentSuccess: state.salesEngineReducer.getByAgentSuccess,
})

const mapDispatchToProps = dispatch => ({
  getSalesEngineByAgent: (data, isSearchAction) => dispatch(getSalesEngineByAgent(data, isSearchAction)),
  getSingleSalesEngine: data => dispatch(getSingleSalesEngine(data)),
  assignAgent: (data, managePageRequest = true) => dispatch(assignAgent(data, managePageRequest)),
  discardAgentProcess: (data, managePageRequest = true) => dispatch(discardAgentProcess(data, managePageRequest)),
  clearDiscardAgentFlag: () => dispatch(clearDiscardAgentFlag()),
  userVisitSalesEngineDashboard: data => dispatch(userVisitSalesEngineDashboard(data)),
  searchInDashboard: data => dispatch(searchInDashboard(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentSalesEngine)
