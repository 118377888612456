import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import RangeSlider from 'react-range-slider-input'
import OutsideClickHandler from 'react-outside-click-handler'
import { IoIosClose, IoIosPin, IoIosSearch } from 'react-icons/io'
import cn from 'classnames'
import { getId, numberWithCommas } from '../../util/utilFunctions'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import CurrencyContext from '../../CurrencyContext'
import PlacesAutocomplete from '../../components/places-autocomplete'
import styles from './hero-search.module.scss'

const FIELD = {
  LOCATION: 1,
  TYPE: 2,
  LENGTH: 3,
  PRICE: 4,
  YEAR: 5,
}

export default function HeroSearch({ inHeader = false, close }) {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const rangeData = useSelector(state => state.boatReducer.boatSearchValues)
  const boatTypes = useSelector(state => state.boatReducer.adminBoatTypes)

  const { currentCurrency } = useContext(CurrencyContext)

  const [activeField, setActiveField] = useState(null)
  const [locationSuggestions, setLocationSuggestions] = useState([])
  const [searchInputValues, setSearchInputValues] = useState({})
  const [fieldValues, setFieldValues] = useState({})

  const addCommas = useCallback(n => (n ? numberWithCommas(n) : 0), [])

  const convertCurrency = useCallback(
    price => getConvertedPrice(price || 0, currentCurrency, false, false, true),
    [currentCurrency]
  )

  const setSearchInputValue = useCallback((name, value) => {
    setSearchInputValues(prev => ({ ...prev, [name]: value }))
  }, [])

  const setFieldValue = useCallback((name, value) => {
    setFieldValues(prev => ({ ...prev, [name]: value }))
  }, [])

  const onPlaceSelected = useCallback(place => {
    setSearchInputValue(FIELD.LOCATION, place.description)
    setFieldValue(FIELD.LOCATION, place)
    setActiveField(FIELD.TYPE)
  }, [])

  const handleSearch = useCallback(() => {
    const params = new URLSearchParams({
      country: fieldValues[FIELD.LOCATION]?.country || '',
      city: fieldValues[FIELD.LOCATION]?.city || '',
      address: fieldValues[FIELD.LOCATION]?.completeLabel || '',
      boatType: fieldValues[FIELD.TYPE]?.id || '',
      boatAlias: fieldValues[FIELD.TYPE]?.name || '',
      minLength: fieldValues[FIELD.LENGTH]?.[0] || '',
      maxLength: fieldValues[FIELD.LENGTH]?.[1] || '',
      minPrice: fieldValues[FIELD.PRICE]?.[0] || '',
      maxPrice: fieldValues[FIELD.PRICE]?.[1] || '',
      minYearBuild: fieldValues[FIELD.YEAR]?.[0] || '',
      maxYearBuild: fieldValues[FIELD.YEAR]?.[1] || '',
    })

    if (pathname === '/search-boats') {
      history.push(`/search-boats?${params.toString()}`)
    } else {
      window.open(`/search-boats?${params.toString()}`)
    }
  }, [history, pathname, fieldValues])

  useEffect(() => {
    if (pathname === '/search-boats' && boatTypes?.length) {
      const searchParams = new URLSearchParams(search)

      const country = searchParams.get('country')
      const city = searchParams.get('city')
      const address = searchParams.get('address')
      const boatTypeId = searchParams.get('boatType')
      const minLength = searchParams.get('minLength')
      const maxLength = searchParams.get('maxLength')
      const minPrice = searchParams.get('minPrice')
      const maxPrice = searchParams.get('maxPrice')
      const minYearBuild = searchParams.get('minYearBuild')
      const maxYearBuild = searchParams.get('maxYearBuild')

      if (country || city) {
        setSearchInputValue(FIELD.LOCATION, address || [city, country].filter(Boolean).join(', '))
        setFieldValue(FIELD.LOCATION, { country, city })
      }

      if (boatTypeId) {
        const boatType = boatTypes.find(t => getId(t) === boatTypeId)
        setFieldValue(FIELD.TYPE, boatType)
      }

      if (minLength && maxLength) {
        setFieldValue(FIELD.LENGTH, [+minLength, +maxLength])
      }

      if (minPrice && maxPrice) {
        setFieldValue(FIELD.PRICE, [+minPrice, +maxPrice])
      }

      if (minYearBuild && maxYearBuild) {
        setFieldValue(FIELD.YEAR, [+minYearBuild, +maxYearBuild])
      }
    }
  }, [pathname, search, boatTypes])

  return (
    <OutsideClickHandler onOutsideClick={() => setActiveField(null)}>
      <div className={cn(styles.heroSearch, inHeader && styles.heroSearch_inHeader)}>
        <div className={cn(styles.fieldsWrp, styles.fieldTripsWrp, activeField && styles.fieldsWrpActive)}>
          <div className={cn(styles.fieldWrp, activeField === FIELD.LOCATION && styles.fieldActive)}>
            <div
              className={styles.fieldHead}
              onClick={() => {
                setActiveField(FIELD.LOCATION)
                document.querySelector(`.${styles.fieldHeadInp}_googleAutocomplete`)?.focus()
              }}
            >
              <div className={styles.fieldHeadContent}>
                <div className={styles.fieldHeadLabel}>
                  <div>Where</div>
                  <div className={styles.fieldResetBtnWrp}>
                    <button
                      className={styles.fieldResetBtn}
                      disabled={activeField !== FIELD.LOCATION || !fieldValues[FIELD.LOCATION]}
                      onClick={() => {
                        setSearchInputValue(FIELD.LOCATION, null)
                        setFieldValue(FIELD.LOCATION, null)
                      }}
                    >
                      <IoIosClose />
                    </button>
                  </div>
                </div>
                <div className={styles.fieldHeadSubtextWrp}>
                  <PlacesAutocomplete
                    query={searchInputValues[FIELD.LOCATION] || ''}
                    setQuery={query => setSearchInputValue(FIELD.LOCATION, query)}
                    setSuggestions={setLocationSuggestions}
                    placeholder="Boat Location"
                    className={cn(styles.fieldHeadInp, `${styles.fieldHeadInp}_googleAutocomplete`)}
                  />
                </div>
              </div>
            </div>

            {activeField === FIELD.LOCATION && locationSuggestions.length > 0 && (
              <div className={styles.fieldDdWrp}>
                <div className={styles.fieldDdContent}>
                  <ul className={styles.fieldLocList}>
                    {locationSuggestions.map(sugg => (
                      <li key={sugg.place_id} className={styles.fieldLocListItem} onClick={() => onPlaceSelected(sugg)}>
                        <span className={styles.fieldLocIcon}>
                          <IoIosPin />
                        </span>{' '}
                        <span className={styles.fieldLocText}>{sugg.description}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className={cn(styles.fieldWrp, activeField === FIELD.TYPE && styles.fieldActive)}>
            <div className={styles.fieldHead} onClick={() => setActiveField(FIELD.TYPE)}>
              <div className={styles.fieldHeadContent}>
                <div className={styles.fieldHeadLabel}>
                  <div>Boat</div>
                  <div className={styles.fieldResetBtnWrp}>
                    <button
                      className={styles.fieldResetBtn}
                      disabled={activeField !== FIELD.TYPE || !fieldValues[FIELD.TYPE]}
                      onClick={() => setFieldValue(FIELD.TYPE, null)}
                    >
                      <IoIosClose />
                    </button>
                  </div>
                </div>
                <div className={styles.fieldHeadSubtextWrp}>
                  {!fieldValues[FIELD.TYPE] ? (
                    <div className={styles.fieldHeadSubtextPlaceholder}>Select Type</div>
                  ) : (
                    <div className={styles.fieldHeadSubtextValue}>{fieldValues[FIELD.TYPE].name}</div>
                  )}
                </div>
              </div>
            </div>

            {activeField === FIELD.TYPE && (
              <div className={styles.fieldDdWrp}>
                <div className={styles.fieldDdContent}>
                  <div className={styles.fieldBoatTypeList}>
                    {boatTypes.map(t => (
                      <div
                        className={cn(
                          styles.fieldBoatTypeListItem,
                          fieldValues[FIELD.TYPE]?.id === t.id && styles.fieldBoatTypeListItemActive
                        )}
                      >
                        <button
                          className={styles.fieldBoatTypeListItemBtn}
                          onClick={() => {
                            setFieldValue(FIELD.TYPE, t)
                            setActiveField(FIELD.LENGTH)
                          }}
                        >
                          {t.name}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={cn(styles.fieldWrp, activeField === FIELD.LENGTH && styles.fieldActive)}>
            <div className={styles.fieldHead} onClick={() => setActiveField(FIELD.LENGTH)}>
              <div className={styles.fieldHeadContent}>
                <div className={styles.fieldHeadLabel}>
                  <div>Length</div>
                  <div className={styles.fieldResetBtnWrp}>
                    <button
                      className={styles.fieldResetBtn}
                      disabled={activeField !== FIELD.LENGTH || !fieldValues[FIELD.LENGTH]}
                      onClick={() => setFieldValue(FIELD.LENGTH, null)}
                    >
                      <IoIosClose />
                    </button>
                  </div>
                </div>
                <div className={styles.fieldHeadSubtextWrp}>
                  {!fieldValues[FIELD.LENGTH] ? (
                    <div className={styles.fieldHeadSubtextPlaceholder}>Add Length</div>
                  ) : (
                    <div className={styles.fieldHeadSubtextValue}>
                      {addCommas(fieldValues[FIELD.LENGTH][0])} - {addCommas(fieldValues[FIELD.LENGTH][1])}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {activeField === FIELD.LENGTH && (
              <div className={styles.fieldDdWrp}>
                <div className={styles.fieldDdContent}>
                  <div className={styles.fieldDdTitle}>Add Length (ft)</div>
                  <div className={styles.fieldRangeLabels}>
                    <span>{addCommas(fieldValues[FIELD.LENGTH]?.[0] ?? rangeData.minLengthInFt)} ft</span>
                    <span>{addCommas(fieldValues[FIELD.LENGTH]?.[1] ?? rangeData.maxLengthInFt)} ft</span>
                  </div>
                  <div className={styles.fieldRangeInpWrp}>
                    <RangeSlider
                      min={rangeData.minLengthInFt}
                      max={rangeData.maxLengthInFt}
                      value={[
                        fieldValues[FIELD.LENGTH]?.[0] ?? rangeData.minLengthInFt,
                        fieldValues[FIELD.LENGTH]?.[1] ?? rangeData.maxLengthInFt,
                      ]}
                      onInput={value => setFieldValue(FIELD.LENGTH, value)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={cn(styles.fieldWrp, activeField === FIELD.PRICE && styles.fieldActive)}>
            <div className={styles.fieldHead} onClick={() => setActiveField(FIELD.PRICE)}>
              <div className={styles.fieldHeadContent}>
                <div className={styles.fieldHeadLabel}>
                  <div>Price {currentCurrency}</div>
                  <div className={styles.fieldResetBtnWrp}>
                    <button
                      className={styles.fieldResetBtn}
                      disabled={activeField !== FIELD.PRICE || !fieldValues[FIELD.PRICE]}
                      onClick={() => setFieldValue(FIELD.PRICE, null)}
                    >
                      <IoIosClose />
                    </button>
                  </div>
                </div>
                <div className={styles.fieldHeadSubtextWrp}>
                  {!fieldValues[FIELD.PRICE] ? (
                    <div className={styles.fieldHeadSubtextPlaceholder}>Add Price</div>
                  ) : (
                    <div className={styles.fieldHeadSubtextValue}>
                      {addCommas(convertCurrency(fieldValues[FIELD.PRICE][0]))} -{' '}
                      {addCommas(convertCurrency(fieldValues[FIELD.PRICE][1]))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {activeField === FIELD.PRICE && (
              <div className={styles.fieldDdWrp}>
                <div className={styles.fieldDdContent}>
                  <div className={styles.fieldDdTitle}>Add Price ({currentCurrency})</div>
                  <div className={styles.fieldRangeLabels}>
                    <span>{addCommas(convertCurrency(fieldValues[FIELD.PRICE]?.[0] ?? rangeData.minPrice))}</span>
                    <span>{addCommas(convertCurrency(fieldValues[FIELD.PRICE]?.[1] ?? rangeData.maxPrice))}</span>
                  </div>
                  <div className={styles.fieldRangeInpWrp}>
                    <RangeSlider
                      min={rangeData.minPrice}
                      max={rangeData.maxPrice}
                      value={[
                        fieldValues[FIELD.PRICE]?.[0] ?? rangeData.minPrice,
                        fieldValues[FIELD.PRICE]?.[1] ?? rangeData.maxPrice,
                      ]}
                      onInput={value => setFieldValue(FIELD.PRICE, value)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={cn(styles.fieldWrp, activeField === FIELD.YEAR && styles.fieldActive)}>
            <div className={styles.fieldHead} onClick={() => setActiveField(FIELD.YEAR)}>
              <div className={styles.fieldHeadContent}>
                <div className={styles.fieldHeadLabel}>
                  <div>Built</div>
                  <div className={styles.fieldResetBtnWrp}>
                    <button
                      className={styles.fieldResetBtn}
                      disabled={activeField !== FIELD.YEAR || !fieldValues[FIELD.YEAR]}
                      onClick={() => setFieldValue(FIELD.YEAR, null)}
                    >
                      <IoIosClose />
                    </button>
                  </div>
                </div>
                <div className={styles.fieldHeadSubtextWrp}>
                  {!fieldValues[FIELD.YEAR] ? (
                    <div className={styles.fieldHeadSubtextPlaceholder}>Add Year</div>
                  ) : (
                    <div className={styles.fieldHeadSubtextValue}>
                      {fieldValues[FIELD.YEAR][0]} - {fieldValues[FIELD.YEAR][1]}
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.fieldSubmitBtnWrp}>
                <button
                  className={styles.fieldSubmitBtn}
                  onClick={e => {
                    e.stopPropagation()
                    setActiveField(null)
                    handleSearch()
                    close?.()
                  }}
                >
                  <IoIosSearch />
                </button>
              </div>
            </div>

            {activeField === FIELD.YEAR && (
              <div className={styles.fieldDdWrp}>
                <div className={styles.fieldDdContent}>
                  <div className={styles.fieldDdTitle}>Add Year Built</div>
                  <div className={styles.fieldRangeLabels}>
                    <span>{fieldValues[FIELD.YEAR]?.[0] ?? rangeData.minYearBuild}</span>
                    <span>{fieldValues[FIELD.YEAR]?.[1] ?? rangeData.maxYearBuild}</span>
                  </div>
                  <div className={styles.fieldRangeInpWrp}>
                    <RangeSlider
                      min={rangeData.minYearBuild}
                      max={rangeData.maxYearBuild}
                      value={[
                        fieldValues[FIELD.YEAR]?.[0] ?? rangeData.minYearBuild,
                        fieldValues[FIELD.YEAR]?.[1] ?? rangeData.maxYearBuild,
                      ]}
                      onInput={value => setFieldValue(FIELD.YEAR, value)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}
