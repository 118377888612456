import React from 'react'
import useZohoSalesIQ from '../../hooks/useZohoSalesIQ'

const ZohoChatWidget = () => {
    const { initChatWidget, removeChatWidget } = useZohoSalesIQ()

    React.useEffect(() => {
        initChatWidget();
        return () => {
            removeChatWidget()
        }
    }, [])
    return null
}

export default ZohoChatWidget
