import {
  SEND_CONFIRM_MAIL_LINK,
  SEND_CONFIRM_MAIL_LINK_SUCCESS,
  CLEAR_EMAIL_FLAGS,
  CLEAR_DASHBOARD_FLAG,
  GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS_SUCCESS,
  GET_MODULE_WISE_BANNERS,
  GET_MODULE_WISE_BANNERS_SUCCESS,
  GET_MODULE_WISE_BANNERS_FAILURE,
  GET_DASHBOARD_COUNT,
  GET_DASHBOARD_COUNT_SUCCESS,
  GET_DASHBOARD_COUNT_FAILURE,
  GET_EXPERIENCES,
  GET_EXPERIENCES_SUCCESS,
  GET_EXPERIENCES_FAILURE,
  NOTIFY_ME,
  NOTIFY_ME_SUCCESS,
  NOTIFY_ME_FAILURE,
  GET_CURRENCY_RATES,
  GET_CURRENCY_RATES_SUCCESS,
  GET_CURRENCY_RATES_FAILURE,
  SET_CURRENT_CURRENCY,
  CREATE_WISHLIST,
  CREATE_WISHLIST_SUCCESS,
  CREATE_WISHLIST_FAILURE,
  REMOVE_WISHLIST,
  REMOVE_WISHLIST_SUCCESS,
  REMOVE_WISHLIST_FAILURE,
  GET_WISHLISTS,
  GET_WISHLISTS_SUCCESS,
  GET_WISHLISTS_FAILURE,
  CLEAR_NOTIFY_ME_FLAG,
  CLEAR_WISHLIST_FLAG,
  SEND_CONFIRM_MAIL_LINK_FAILURE,
  CONVERT_WISHLISTS_FROM_SESSION_TO_USER,
  CONVERT_WISHLISTS_FROM_SESSION_TO_USER_SUCCESS,
  CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FAILURE,
  CLEAR_CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FLAG,
  MENU_CHANGE,
  GLOBAL_SEARCH,
  GLOBAL_SEARCH_SUCCESS,
  GLOBAL_SEARCH_FAILURE,
  CLEAR_GLOBAL_SEARCH,
  ADD_FLAG_REPORT,
  ADD_FLAG_REPORT_SUCCESS,
  ADD_FLAG_REPORT_FAILURE,
  CLEAR_FLAG_REPORT,
  EMAIL_VERIFICATION_CHECK,
  CLOSE_EMAIL_VERIFICATION_MODAL,
  IS_VISIT_FIRST_TIME,
  CLEAR_IS_VISIT_FIRST_TIME,
  SET_DASHBOARD_UNAUTHORIZED_ACCESS,
  CLEAR_DASHBOARD_UNAUTHORIZED_ACCESS_FLAG,
  GET_ALL_PAYMENT_METHODS,
  GET_ALL_PAYMENT_METHODS_SUCCESS,
  GET_ALL_PAYMENT_METHODS_FAILURE,
  INDEX_SEARCH,
  INDEX_SEARCH_SUCCESS,
  CLEAR_INDEX_SEARCH,
  MODULE_PAGE_VISIT_SUCCESS,
  MODULE_PAGE_VISIT,
  MODULE_PAGE_VISIT_FAILURE,
  CLEAR_MODULE_PAGE_VISIT,
  GET_COUNTRY_DETAILS,
  GET_COUNTRY_DETAILS_SUCCESS,
  GET_COUNTRY_DETAILS_FAILURE,
} from '../actionTypes'
import { getLocalInfo, getLocalStorageItem, setLocalStorageItem } from '../../helpers/storageHelper'
import { activationType } from '../../util/enums/enums'

const dashBoardSideBarData = [
  {
    name: 'Back to Market',
    imgPath: require('../../containers/dashboard/image/home.svg'),
    selected: true,
    url: '',
    role: ['common', '!rent-and-charter'],
  },
  {
    name: 'Back to Market',
    imgPath: require('../../containers/dashboard/image/home.svg'),
    selected: true,
    url: 'rent',
    role: ['rent-and-charter'],
  },
  {
    name: 'Dashboard',
    imgPath: require('../../containers/dashboard/image/dashboard.png'),
    url: 'dashboard',
    role: ['common'],
  },
  {
    name: 'User Profile',
    imgPath: require('../../containers/dashboard/image/user.png'),
    url: 'profile',
    role: ['common'],
  },
  {
    name: 'Branch',
    imgPath: require('../../containers/dashboard/image/branch.png'),
    url: 'manage-branches',
    role: ['surveyor'],
  },
  {
    name: 'Manage Rentals',
    imgPath: require('../../containers/dashboard/image/rent.png'),
    url: 'manage-boat-rents',
    role: ['rent-and-charter'],
  },
  {
    name: 'Services',
    imgPath: require('../../containers/dashboard/image/service.png'),
    url: 'add-boat-service',
    role: ['service-and-maintenance'],
  },
  {
    name: 'Marina & Storage',
    imgPath: require('../../containers/dashboard/image/marina.png'),
    url: 'manage-marina-storage',
    role: ['marina-and-storage'],
  },
  {
    name: 'Add and Manage Boats',
    imgPath: require('../../containers/dashboard/image/manage-boat.png'),
    url: 'manage-boats',
    role: ['boat-owner', 'broker-and-dealer', 'boat-manufacturer'],
  },
  {
    name: 'Dealers',
    imgPath: require('../../containers/dashboard/image/broker.png'),
    url: 'manage-dealers',
    role: ['boat-manufacturer'],
  },

  {
    name: 'Auction Rooms',
    imgPath: require('../../containers/dashboard/image/auction-room.png'),
    url: 'manage-auction-rooms',
    role: ['boat-owner', 'broker-and-dealer'],
  },

  {
    name: 'My Bookings',
    imgPath: require('../../containers/dashboard/image/whishlist.png'),
    url: 'my-bookings',
    role: ['common'],
  },
  {
    name: 'Manage Articles',
    imgPath: require('../../containers/dashboard/image/article.png'),
    url: 'manage-articles',
    role: ['common'],
  },
  {
    name: 'My Deals',
    imgPath: require('../../containers/dashboard/image/broker.png'),
    url: 'my-deals',
    role: ['broker-and-dealer'],
  },
  // {
  //   name: "Advertisements",
  //   imgPath: require("../../containers/dashboard/image/advertisement.png"),
  //   url: ",
  //   role: ["common"]
  // },
  {
    name: 'Boat Shows',
    imgPath: require('../../containers/dashboard/image/boats.png'),
    url: 'manage-boat-shows',
    role: ['common'],
  },
  {
    name: 'Agreements & Files',
    imgPath: require('../../containers/dashboard/image/agreement.png'),
    url: '',
    role: ['boat-manufacturer', 'yacht-shipper', 'surveyor'],
  },
  // TODO :: DOUBT TO ASK
  // {
  //   name: "Change Password",
  //   imgPath: require("../../containers/dashboard/image/password.png"),
  //   url: "change-password",
  //   role: ["common"]
  // },
  // {
  //   name: "Logout",
  //   imgPath: require("../../containers/dashboard/image/logout.png"),
  //   url: "logout",
  //   role: ["common"]
  // }
]

const InitialState = {
  isMailSent: false,
  isOTPSentToMobile: false,

  cityLists: [],
  statusSuccess: false,
  statusError: false,
  success: false,
  error: false,
  experience: [],

  currencyRates: getLocalInfo('currencyRates'),
  currentCurrency: getLocalStorageItem('currentCurrency'),

  notifyInput: {},
  notifiedSuccess: false,
  notifiedError: false,

  allWishlists: {},
  addWishlistSuccess: false,
  addWishlistError: false,
  removeWishlistSuccess: false,
  convertWishlists: false,
  isError: false,
  totalWishlists: getLocalStorageItem('totalWishlists'),
  dashBoardSideBarData: dashBoardSideBarData,

  globalSearchData: {},

  flagReportSuccess: false,
  flagReportError: false,
  message: '',

  emailVerificationPopup: false,
  isVisitFirstTime: true,

  unauthorizedAccess: false,
  unauthorizedAccessMessage: '',

  paymentTypes: [],

  searchSuccess: false,

  indexSearchData: [],
  modulePageVisitSuccess: false,
  modulePageVisitError: false,
  isModulePageLoading: false,
  searchLoading: false,
  countryDetails: {},
}

export const dashboardReducer = (state = InitialState, action) => {
  switch (action.type) {
    case SEND_CONFIRM_MAIL_LINK:
      return {
        ...state,
        isError: false,
        isMailSent: false,
        isOTPSentToMobile: false,
        message: '',
        isVisitFirstTime: false,
      }

    case SEND_CONFIRM_MAIL_LINK_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isMailSent: action.activationType === activationType.EMAIL,
        isOTPSentToMobile: action.activationType === activationType.MOBILE,
      }

    case SEND_CONFIRM_MAIL_LINK_FAILURE:
      return {
        ...state,
        isError: true,
        isMailSent: false,
        isOTPSentToMobile: false,
      }

    case CLEAR_EMAIL_FLAGS:
      return {
        ...state,
        isMailSent: false,
        isOTPSentToMobile: false,
      }

    case CLEAR_DASHBOARD_FLAG:
      return {
        ...state,
        success: false,
        error: false,
        statusSuccess: false,
        statusError: false,
      }

    case GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS_SUCCESS:
      return {
        ...state,
        cityLists: action.payload.items,
        total: action.total,
      }

    case GET_MODULE_WISE_BANNERS:
      const fieldName = action.payload.fieldName
      return {
        ...state,
        [fieldName]: [],
      }

    case GET_MODULE_WISE_BANNERS_SUCCESS:
      const fieldNameSuccess = action.fieldName
      const adName = action.ads
      const banners = action.payload.data.getBannerByModule.items

      const topBanners = action.payload.data.getBannerByModule.items.filter(item => item.mediaPosition === 'TOP')
      const middleBanners = action.payload.data.getBannerByModule.items.filter(item => item.mediaPosition === 'MIDDLE')

      return {
        ...state,
        [fieldNameSuccess]: topBanners,
        [adName]: middleBanners,
      }

    case GET_MODULE_WISE_BANNERS_FAILURE:
      const fieldNameFail = action.fieldName
      return {
        ...state,
        [fieldNameFail]: [],
      }
    case GET_DASHBOARD_COUNT:
      return {
        ...state,
        dashboardCount: {},
      }

    case GET_DASHBOARD_COUNT_SUCCESS:
      return {
        ...state,
        dashboardCount: action.payload,
      }

    case GET_DASHBOARD_COUNT_FAILURE:
      return {
        ...state,
        dashboardCount: {},
      }

    // start
    case MODULE_PAGE_VISIT:
      return {
        ...state,
        modulePageVisitSuccess: false,
        modulePageVisitError: false,
        isModulePageLoading: true,
      }

    case MODULE_PAGE_VISIT_SUCCESS:
      return {
        ...state,
        modulePageVisitSuccess: true,
        modulePageVisitError: false,
      }

    case MODULE_PAGE_VISIT_FAILURE:
      return {
        ...state,
        modulePageVisitSuccess: false,
        modulePageVisitError: true,
      }

    case CLEAR_MODULE_PAGE_VISIT:
      return {
        ...state,
        modulePageVisitSuccess: false,
        modulePageVisitError: false,
        isModulePageLoading: false,
      }
    // end

    case GET_EXPERIENCES:
      return {
        ...state,
        success: false,
        error: true,
      }

    case GET_EXPERIENCES_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        experience: action.payload.data.getExperiences,
      }

    case GET_EXPERIENCES_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }

    case NOTIFY_ME:
      return {
        ...state,
        notifiedSuccess: false,
        notifiedError: false,
        notifyInput: {},
      }

    case NOTIFY_ME_SUCCESS:
      return {
        ...state,
        notifiedSuccess: true,
        notifyInput: action.payload,
      }

    case NOTIFY_ME_FAILURE:
      return {
        ...state,
        notifiedError: true,
      }

    case CLEAR_NOTIFY_ME_FLAG:
      return {
        ...state,
        notifiedSuccess: false,
        notifiedError: false,
      }

    case CREATE_WISHLIST:
      return {
        ...state,
        addWishlistSuccess: false,
        addWishlistError: false,
      }

    case CREATE_WISHLIST_SUCCESS:
      action.payload &&
        action.payload.hasOwnProperty('totalCounts') &&
        setLocalStorageItem('totalWishlists', action.payload.totalCounts)

      return {
        ...state,
        addWishlistSuccess: true,
        totalWishlists: action.payload.totalCounts,
      }

    case CREATE_WISHLIST_FAILURE:
      return {
        ...state,
        addWishlistError: true,
      }

    case REMOVE_WISHLIST:
      return {
        ...state,
        removeWishlistSuccess: false,
      }

    case REMOVE_WISHLIST_SUCCESS:
      action.payload &&
        action.payload.hasOwnProperty('totalCounts') &&
        setLocalStorageItem('totalWishlists', action.payload.totalCounts)

      return {
        ...state,
        removeWishlistSuccess: true,
        totalWishlists: action.payload.totalCounts,
      }

    case REMOVE_WISHLIST_FAILURE:
      return {
        ...state,
        removeWishlistSuccess: false,
      }

    case CLEAR_WISHLIST_FLAG:
      return {
        ...state,
        removeWishlistSuccess: false,
        addWishlistSuccess: false,
        addWishlistError: false,
      }

    case GET_WISHLISTS:
      return {
        ...state,
        removeWishlistSuccess: false,
      }

    case GET_WISHLISTS_SUCCESS:
      const { __typename, ...payload } = action.payload
      const all = [...payload.boat, ...payload.marina, ...payload.boatrent, ...payload.yacht, ...payload.boatshow]

      payload.hasOwnProperty('totalCounts') && setLocalStorageItem('totalWishlists', payload.totalCounts)

      return {
        ...state,
        allWishlists: { ...payload, all },
        totalWishlists: payload.totalCounts,
      }

    case GET_WISHLISTS_FAILURE:
      return {
        ...state,
      }

    case CONVERT_WISHLISTS_FROM_SESSION_TO_USER:
      return {
        ...state,
        convertWishlists: false,
      }

    case CONVERT_WISHLISTS_FROM_SESSION_TO_USER_SUCCESS:
      return {
        ...state,
        convertWishlists: true,
      }

    case CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FAILURE:
      return {
        ...state,
      }

    case CLEAR_CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FLAG:
      return {
        ...state,
        convertWishlists: false,
      }

    case GET_CURRENCY_RATES:
      return {
        ...state,
        currencyRates: {},
      }

    case GET_CURRENCY_RATES_SUCCESS:
      return {
        ...state,
        currencyRates: action.payload.data,
      }

    case GET_CURRENCY_RATES_FAILURE:
      return {
        ...state,
      }

    case SET_CURRENT_CURRENCY:
      return {
        ...state,
        currentCurrency: action.payload,
      }
    case MENU_CHANGE:
      return {
        ...state,
        dashBoardSideBarData: action.payload.length ? action.payload : state.dashBoardSideBarData,
        subMenu: action.subMenu,
      }

    // GLOBAL-SEARCH
    case GLOBAL_SEARCH:
      return {
        ...state,
        globalSearchData: {},
        searchLoading: true,
      }

    case GLOBAL_SEARCH_SUCCESS:
      return {
        ...state,
        globalSearchData: action.payload,
        searchSuccess: true,
        searchTerm: action.searchTerm,
        totalArticleData: action.payload.articles.total,
        totalBoatShowsData: action.payload.boatShows.total,
        totalBoatsData: action.payload.boats.total,
        totalMarinaStorageData: action.payload.marinaStorage.total,
        totalRentBoatData: action.payload.rentBoats.total,
        totalNewBoatsData: action.payload.newBoats.total,
        totalUsedBoatsData: action.payload.usedBoats.total,
        totalUsersData: action.payload.users.total,
        totalYachtServiceData: action.payload.yachtService.total,
      }

    case GLOBAL_SEARCH_FAILURE:
      return {
        ...state,
      }

    // GLOBAL-SEARCH
    case GET_COUNTRY_DETAILS:
      return {
        ...state,
        countryDetails: {},
      }

    case GET_COUNTRY_DETAILS_SUCCESS:
      return {
        ...state,
        countryDetails: action.payload,
      }

    case GET_COUNTRY_DETAILS_FAILURE:
      return {
        ...state,
        countryDetails: {},
      }

    case CLEAR_GLOBAL_SEARCH:
      return {
        ...state,
        searchSuccess: false,
        searchLoading: false,
      }

    // FLAG-REPORT
    case ADD_FLAG_REPORT:
      return {
        ...state,
        isError: false,
        flagReportSuccess: false,
        flagReportError: false,
      }

    case ADD_FLAG_REPORT_SUCCESS:
      return {
        ...state,
        flagReportSuccess: true,
      }

    case ADD_FLAG_REPORT_FAILURE:
      return {
        ...state,
        isError: true,
        flagReportError: true,
      }

    case CLEAR_FLAG_REPORT:
      return {
        ...state,
        flagReportSuccess: false,
        flagReportError: false,
      }

    case EMAIL_VERIFICATION_CHECK:
      return {
        ...state,
        emailVerificationPopup: !action.payload ?? false,
      }

    case CLOSE_EMAIL_VERIFICATION_MODAL:
      return {
        ...state,
        emailVerificationPopup: false,
        // isVisitFirstTime: false,
      }

    case IS_VISIT_FIRST_TIME:
      return {
        ...state,
        isVisitFirstTime: false,
      }

    case CLEAR_IS_VISIT_FIRST_TIME:
      return {
        ...state,
        isVisitFirstTime: true,
      }

    case SET_DASHBOARD_UNAUTHORIZED_ACCESS:
      return {
        ...state,
        unauthorizedAccess: true,
        unauthorizedAccessMessage: action.payload,
      }

    case CLEAR_DASHBOARD_UNAUTHORIZED_ACCESS_FLAG:
      return {
        ...state,
        unauthorizedAccess: false,
        unauthorizedAccessMessage: '',
      }

    case GET_ALL_PAYMENT_METHODS:
      return {
        ...state,
        paymentTypes: [],
      }

    case GET_ALL_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentTypes: action.payload.items,
      }

    case GET_ALL_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        paymentTypes: [],
      }

    case INDEX_SEARCH:
      return {
        ...state,
        isLoading: true,
      }

    case INDEX_SEARCH_SUCCESS:
      const searchType = action.payload[action.searchType]
      return {
        ...state,
        isSearched: true,
        indexSearchData: searchType?.items,
        totalSearchedData: searchType?.total,
      }

    case CLEAR_INDEX_SEARCH:
      return {
        ...state,
        isSearched: false,
        isLoading: false,
        indexSearchData: [],
        totalSearchedData: 0,
      }

    default:
      return state
  }
}
