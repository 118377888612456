import React from 'react'

import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import classNames from 'classnames'

export const PaymentDetail = ({
  salesEngine,
  isDepositPayment,
  isBoatPaymentDetail,
  isSurveyorPaymentDetail,
  isShipperPaymentDetail,
  depositePrice,
  payment,
  paymentItem,
  className = '',
}) => {
  const totalPayment = (paymentItem?.amount || 0) - (depositePrice || 0)

  let paymentId = paymentItem?.paymentId || '0000000000'

  if (payment != null) {
    if (isBoatPaymentDetail) {
      paymentId = payment.filter(el => {
        return (
          el.paymentType !== null &&
          (el.paymentType === 'boat_payment' ||
            el.paymentType === 'captured_payment' ||
            el.paymentType === 'uncaptured_payment' ||
            el.paymentType === 'shipper_payment')
        )
      })[0].paymentId
    } else {
      paymentId = payment.filter(el => el.paymentType !== null && el.paymentType === 'shipper_payment')[0].paymentId
    }
  }

  const surveyorPaymentStatus = () => {
    if (paymentItem?.isRefunded) return 'Payment Refunded'
    if (paymentItem?.isRefundable) return 'Payment Will Be Refunded'

    return 'Surveyor Payment Done'
  }

  return (
    <div className={classNames('buyer-payment-success-detail', className)}>
      <div className="payment-success-label-with-value">
        <div className="payment-success-label">Transaction ID</div>
        <div className="payment-success-value">{paymentId}</div>
      </div>
      <div className="payment-success-label-with-value">
        <div className="payment-success-label">From</div>
        <div className="payment-success-value">
          {[salesEngine?.buyer?.firstName, salesEngine?.buyer?.lastName].filter(Boolean).join(' ')}
        </div>
      </div>
      <div className="payment-success-label-with-value">
        <div className="payment-success-label">Amount</div>
        <div className="payment-success-value">
          <CurrencyContextConsumer>
            {({ currentCurrency }) => getConvertedPrice(totalPayment, currentCurrency)}
          </CurrencyContextConsumer>
        </div>
      </div>
      <div className="payment-success-label-with-value">
        <div className="payment-success-label">Status</div>
        <div
          className={`payment-success-value ${
            paymentItem?.isRefundable || paymentItem?.isRefunded ? 'error-payment' : 'success-payment'
          }`}
        >
          {isDepositPayment
            ? 'Deposit Payment Done'
            : isBoatPaymentDetail
            ? 'Boat Payment Done'
            : isShipperPaymentDetail
            ? 'Shipper Payment Done'
            : surveyorPaymentStatus()}
        </div>
      </div>
    </div>
  )
}
