import React, { useState } from 'react'
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core'
import { AgentInfoCard } from './AgentInfoCard'
import { CarouselWrapper } from '../carouselComponent/carouselWrapper'
import { assignAgentCheckBox } from '../../containers/salesEngine/SalesEngineHelper'
import { HelpIconComponent } from '../helper/helpIconComponent'
import { agentHelpText } from '../../util/enums/enums'
import { ButtonComponent } from '../form/Button'
import { CommonTooltip } from '../CommonTooltip'

export const AgentSection = props => {
  const { salesEngine, assignAgentSuccess, discardAgentProcess, agentType, isBuyer, history } = props

  const [isShow, setShow] = useState(salesEngine?.agentRequested || salesEngine?.agent)
  const [isChange, setIsChange] = useState(false)

  const handleChange = () => {
    setIsChange(!isChange)
  }

  const salesEngineCarousel = {
    isBrowser: 3,
    isTablet: 2,
    isMobile: 1,
  }

  const unSelectedAgent = props.agents?.filter(
    item => item.id !== salesEngine?.agentRequested && item.id !== salesEngine?.agent?.id
  )
  const selectedAgent = props.agents?.filter(
    item => item.id === salesEngine?.agentRequested || item.id === salesEngine?.agent?.id
  )

  const finalAgent = [...selectedAgent, ...unSelectedAgent]

  return (
    <Grid container>
      <Grid item xs={12}>
        {!isShow && salesEngine && (
          <div className={isChange ? 'mb-0 py-5 assign-single-selected' : 'py-5'}>
            {props.agents && props.agents.length > 0 ? (
              <div className="d-flex justify-content-center align-items-center agent--align-center">
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={isShow}
                      classes={{ root: 'assign-agent-checkbox', checked: 'assign-agent-checked' }}
                      onChange={() => {
                        handleChange()
                        setShow(!isShow)
                        isShow && discardAgentProcess({ id: salesEngine.id })
                      }}
                    />
                  }
                /> */}
                <CommonTooltip
                  component={ctProps => (
                    <div data-tooltip-id={ctProps.id} data-tooltip-place="top" data-tooltip-content={agentHelpText}>
                      <button
                        className="btn btn-lg btn-o-black"
                        style={{ borderRadius: 16 }}
                        onClick={() => {
                          handleChange()
                          setShow(!isShow)
                          isShow && discardAgentProcess({ id: salesEngine.id })
                        }}
                      >
                        Would you like to assign an {String(agentType).toLowerCase()} to help you to buy this boat
                        {/* <HelpIconComponent helpText={agentHelpText} containerClass="single-select--help" /> */}
                      </button>
                    </div>
                  )}
                ></CommonTooltip>
              </div>
            ) : (
              <FormControlLabel control={<></>} label={`Not available ${agentType} for this boat`} />
            )}
          </div>
        )}

        {isShow && !isChange && (
          <Grid container>
            <Grid item xs={12} md={6} lg={5}>
              <AgentInfoCard
                history={history}
                agent={salesEngine.agent}
                className={isChange && 'assign-single-list'}
                isAgentSingle
                isSingle={false}
                salesEngine={salesEngine}
                isChanged={isChange}
                handleChange={handleChange}
                isBuyer={isBuyer}
              />
            </Grid>
          </Grid>
        )}

        {isShow && (!salesEngine?.agent || isChange) && props.agents && props.agents.length > 0 && (
          <div className="sales--engin-assign-agent">
            <CarouselWrapper items={salesEngineCarousel} top={30}>
              {finalAgent.map(item => {
                return (
                  <AgentInfoCard
                    history={history}
                    isAgentCarousel
                    agent={item}
                    setAgent={props.setAgent}
                    salesEngine={salesEngine}
                    handleReSet={handleChange}
                    inListing
                    discardAgentProcess={discardAgentProcess}
                  />
                )
              })}
            </CarouselWrapper>
          </div>
        )}
      </Grid>
    </Grid>
  )
}
