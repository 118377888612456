import React, { useState, useEffect } from 'react'

import { SectionWithShowMapStyleBoat, MapMarinaGridStyleGrid } from '../styleComponent/styleComponent'
import SharePopup from '../share/SharePopup'
import { cityCountryNameFormatter } from '../../helpers/jsxHelper'
import { wishlistModuleEnum, shareModule, dimension, defaultImage, boatVerifyMessage } from '../../util/enums/enums'

import { WishlistComponent } from '../wishlist/WishlistComponent'
import RatingComponent from '../rating/Rating'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import ImageGalleryComponent from '../imageHelper/imageGalleryComponent'
import { percentageFormate, commonMarinaType } from '../../util/utilFunctions'
import { textTruncate } from '../../helpers/string'
import WishListButton from '../staticComponent/wishListButton'
import { WarnNotify } from '../../helpers/notification'
import './marinaStorageMapCard.scss'
import { viewMarinaDetails } from '../../helpers/boatHelper'

export const MarinaStorageMapCard = props => {
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [isArrow, setIsArrow] = useState(true)

  const {
    marinaStorage: {
      address: [{ city, country }],
      facilities,
      rating,
      images,
      id,
      name,
      provider,
      adStatus,
    },
    viewMarina,
    index,
    isWishlist,
    removeFromList,
    sliceTo,
  } = props

  const handleClick = index => {
    setSelectedIndex(selectedIndex !== index ? index : null)
  }
  const [height, setHeight] = useState(dimension.featureBoat.height)
  const [width, setWidth] = useState(dimension.featureBoat.width)

  const zoomWidth = () => {
    const className = isWishlist ? '.dashboard-layout-responsive' : '.grid--w-100--map'
    const widthR = getRatio(dimension, 'showMapRatio', className)
    const heightR = getHeightRatio(dimension, 'showMapRatio', className)
    widthR && setWidth(widthR)
    heightR && setHeight(heightR)
  }

  useEffect(() => {
    zoomWidth()
    window.addEventListener('resize', zoomWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={`marina--boat--card--show--gird ${!isWishlist ? 'cursor-pointer' : ''}`}
      onMouseEnter={() => props.onBoatHover(props.marinaStorage)}
      onMouseLeave={props.onBoatHover}
    >
      <div
        className="marina--boat--card--show--img"
        style={{ width: `${width}px` }}
        onMouseLeave={() => {
          setIsArrow(false)
        }}
        onMouseOver={() => {
          setIsArrow(true)
        }}
      >
        {isWishlist ? (
          <SectionWithShowMapStyleBoat
            className="cursor-pointer"
            width={width}
            height={height}
            img={(images && images.length && encodeURI(images[0]?.url)) || defaultImage}
            onClick={() => (adStatus ? viewMarina() : WarnNotify(boatVerifyMessage))}
          />
        ) : (
          <>
            {images && images.length > 0 && (
              <ImageGalleryComponent
                width={width}
                height={height}
                onClick={viewMarina}
                isArrow={isArrow}
                sliceTo={sliceTo}
                images={images}
              />
            )}
          </>
        )}

        <div className="card-action marina--with--map--card--action">
          <div className="opacity--0"></div>
          <div className="share-icon">
            <WishlistComponent moduleId={id} moduleType={wishlistModuleEnum.MARINA} />
            {/* <SharePopup
              handleClick={() => handleClick(index)}
              index={index}
              selectedIndex={selectedIndex}
              moduleId={id}
              moduleType={shareModule.MARINA}
              sharedUrl={viewMarinaDetails(props.marinaStorage, true, true)}
            /> */}
          </div>
        </div>
      </div>
      <MapMarinaGridStyleGrid width={width} className="boat--main-with-btn">
        <div className="marina--boat--card--details--main d-flex flex-row justify-content-between w-100">
          <div
            className={isWishlist ? 'marina--wishlist-calc cursor-default' : 'w-100'}
            onClick={() => !isWishlist && viewMarina()}
          >
            {commonMarinaType(provider)}
            <div className="marina--map--card--company--name">{name}</div>
            <div className="marina--card--place--city--color common--boat--card--details--spacing">
              {cityCountryNameFormatter(city, country)}
            </div>
            <h5 className="marina--boat--card--desc">{textTruncate(facilities, 80)}</h5>

            <div className="d-flex align-center flex-none common--boat--card--details--spacing">
              <div className="marina--card--rating--margin">
                <RatingComponent rating={rating} className="rating-clr" />
              </div>
              {/* <span className="rating-count">({percentageFormate(rating)})</span> */}
            </div>
          </div>
          {isWishlist && (
            <div className="manage-boat-card-button marina--manage--boat--card--btn">
              <WishListButton viewOnclick={viewMarina} removeOnclick={removeFromList} />
            </div>
          )}
        </div>
      </MapMarinaGridStyleGrid>
    </div>
  )
}

MarinaStorageMapCard.defaultProps = {
  isWishlist: false,
  onBoatHover: () => null,
  sliceTo: 0,
}
