import { gql } from 'apollo-boost'
import {
  WishlistResponse,
  Like,
  PageVisit,
  RentType,
  yachtWishlists,
  boatWishlists,
  marinaWishlists,
  rentWishlists,
  boatShowWishlists,
  ImageType,
  AddressType,
  Role,
  LookUp,
  TypeCrud,
  TripDetailsType,
  BoatRent,
} from './schemaTypes/schemaTypes'

export const sendEmailLink = gql`
  mutation sendEmailLink($userName: String!, $activationType: Float!, $viaCall: Boolean) {
    sendEmailLink(userName: $userName, activationType: $activationType, viaCall: $viaCall) {
      message
      statusCode
      user {
        id
        mobileNumber
        firstName
        lastName
        email
      }
    }
  }
`

export const getGlobalMinimumPriceBoats = gql`
  query getGlobalMinimumPriceBoats($limit: Float) {
    getGlobalMinimumPriceBoats(limit: $limit) {
      items {
        _id
        minimumPrice
        countryImage {
          id
          url
        }
        cityName
        country
        count
      }
      total
    }
  }
`

export const getExperiences = gql`
  query getExperiences ($moduleName: String!) {
    getExperiences (moduleName: $moduleName){
    id
    user {
      firstName
      lastName
      image ${ImageType}
      companyName
      reviews{
        reviews{
            rating
            reviews
            createdAt
        }
    }
    }
    moduleName
    images
    description
    name
    destination
    rating
    experienceRef
  }
}
`

export const pageVisit = gql`
  mutation modulePageVisit($input: PageVisitInput!) {
    modulePageVisit(input: $input)
      ${PageVisit}
  }
`

export const addLikes = gql`
  mutation addLikes($input: LikeInput!) {
    addLikes(input: $input)
      ${Like}
  }
`

export const getCurrencyRates = gql`
  {
    getCurrencyRates {
      id
      data
    }
  }
`

export const searchTermNotify = gql`
  mutation userSearchTermNotify($input: SearchTermInput!) {
    userSearchTermNotify(input: $input) {
      firstName
      lastName
      email
    }
  }
`

// Wishlist
export const createWishlist = gql`
  mutation createWishlist($input: WishlistInput!) {
    createWishlist(input: $input)
      ${WishlistResponse}
  }
`

export const removeWishList = gql`
  mutation removeWishList($input: WishlistInput!) {
    removeWishList(input: $input)
      ${WishlistResponse}
  }
`

export const getWishlistByUser = gql`
  query getWishlistByUser($sessionId: String) {
      getWishlistByUser (sessionId: $sessionId) {
        totalCounts
        boat ${boatWishlists}
        yacht ${yachtWishlists}
        marina ${marinaWishlists}
        boatrent ${rentWishlists}
        boatshow ${boatShowWishlists}
      }
  }`

export const addUserOfLikeBySession = gql`
  mutation addUserOfLikeBySession($sessionId: String!) {
    addUserOfLikeBySession(sessionId: $sessionId) ${Like}
  }`

export const addUserOfWishListedBySession = gql`
  mutation addUserOfWishListedBySession($sessionId: String!) {
    addUserOfWishListedBySession(sessionId: $sessionId) {
      totalCounts
      boat ${boatWishlists}
      yacht ${yachtWishlists}
      marina ${marinaWishlists}
      boatrent ${rentWishlists}
      boatshow ${boatShowWishlists}
    }
  }`

export const globalSearch = gql`
  query globalSearch($query: String!, $page: Int, $limit: Int) {
    globalSearch(query: $query, page: $page, limit: $limit)
  }
`

export const createOrUpdateFlagreport = gql`
  mutation createOrUpdateFlagreport($input: FlagreportInput!) {
    createOrUpdateFlagreport(input: $input) {
      id
      moduleId
      moduleType
      reportType
      message
      user {
        id
        firstName
      }
      createdAt
    }
  }
`

export const getAllPaymentMethodTypes = gql`
  {
    getAllPaymentMethodTypes {
      items {
        id
        name
      }
    }
  }
`

export const dashboardSearch = gql`
query dashboardSearch($input: PaginatedSearchInput!, $page: Int, $limit: Int) {
  dashboardSearch(input: $input, page: $page, limit: $limit) {
    boats {
      items{
        id
        adId
        boatName
        price
        adStatus
        isAuctionRoomCreated
        isSalesEngineCreated
        winnerAssigned
        yearBuilt
        salesEngineStatus
        boatType ${TypeCrud}
        manufacturedBy ${LookUp}
        engineHp
        seller {
            id
            role ${Role}
        }
        boatStatus ${LookUp}
        auctionRoom {
          status
        }
        createdAt
        stockQuantity
        salesEngineStatus
      }
      total
    }
    rentBoats {
      items ${BoatRent}
      total
    }
    sellerSalesEngineBoat {
      items{
        id
        adId
        boatName
        price
        adStatus
        isAuctionRoomCreated
        isSalesEngineCreated
        winnerAssigned
        yearBuilt
        salesEngineStatus
        boatType ${TypeCrud}
        manufacturedBy ${LookUp}
        engineHp
        seller {
            id
            role ${Role}
        }
        boatStatus ${LookUp}
        auctionRoom {
          status
        }
        createdAt
        stockQuantity
        salesEngineStatus
      }
      total
    }
    marinaStorage {
      items{
        id
        serviceDescription
        images {
          smallUrl
        }
        name
        serviceProvide ${TypeCrud}
        provider{
          alias
        }
        user {
          id
          companyName
          role ${Role}
        }
      }
      total
    }
    yachtService {
      items{
        id
        images {
          smallUrl
        }
        service
        serviceProvide ${TypeCrud}
        user {
          id
          role ${Role}
          companyName
        }
      }
      total
    }
    boatShows {
      items {
        title
        status
        adStatus
        adId
        createdAt
        address {
          city
          country
        }
        endDate
        startDate
        showDescription
        boatshowImages {
          smallUrl
        }
        user {
          id
          role ${Role}
        }
      }
      total
    }
    articles {
      items {
        id
        title
        description
        adId,
        createdAt,
        status
        articleApproved,
        user {
          firstName
          lastName
        }
        files {
          smallUrl
        }
      }
      total
    }

    buyerSalesEngine {
      items {
        id
        salesEngineStatus
        boat {
            boatType ${TypeCrud}
            adId
        }
        seller {
          id
          firstName
          lastName
          role {
            id
            role
            aliasName
          }
        }
        createdAt
        buySellProcess
        isPaymentDue
        isArchive
        surveyorPayment
        boatPayment
        shipperPayment
        isAuctionSalesEngine
      }
      total
    }
    sellerSalesEngine {
      items {
        id
        salesEngineStatus
        boat {
          boatType ${TypeCrud}
          adId
        }
        seller {
          id
          firstName
          lastName
          role {
            id
            role
            aliasName
          }
        }
        createdAt
        buySellProcess
        isPaymentDue
        isArchive
        surveyorPayment
        boatPayment
        shipperPayment
        isAuctionSalesEngine
      }
      total
    }
  }
}
`

export const getCountryDetailsSchema = gql`
  query getCountryDetail($countryName: String!) {
    getCountryDetail(countryName: $countryName) {
      id
      name
      salesEngineStatus
      ISOCode
      countryPhoneCode
      boatOwerStatus
      brokerAndDealerStatus
      boatManufacturerStatus
      serviceStatus
      marinaStorageStatus
      rentStatus
      sellerStatus
      ShipperStatus
      mamberStatus
      bookNowStatus
      surveyorStatus
      agentStatus
      auctionStatus
    }
  }
`
