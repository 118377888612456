import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { dimensionAspectRatio } from '../../util/utilFunctions'
import { CityWiseComponent } from '../cityWiseComponent/cityWiseComponent'

const images = [
  require('../../assets/images/rentInner/slide-1.jpg'),
  require('../../assets/images/rentInner/slide-2.jpg'),
  require('../../assets/images/rentInner/slide-3.jpg'),
  require('../../assets/images/rentInner/slide-4.jpg'),
  require('../../assets/images/rentInner/slide-5.jpg'),
  require('../../assets/images/rentInner/slide-6.jpg'),
]

export const RentBoatTripCitiesCard = ({ data, getCityWiseRentBoats, limit }) => {
  const [cityWidth, setCityWidth] = useState(320)
  const [cityHeight, setCityHeight] = useState(330)
  const result =
    data?.length &&
    data.map((item, i) => ({
      image: item.countryImage?.url || images[i],
      city: item.city,
      country: item.country,
      minimumPrice: item.minimumPrice,
    }))

  const getNewWidth = () => {
    const width = document.querySelector('.section-heading')
    const actualWidth = width && width.offsetWidth / 5 - 15
    const aspectRatio = dimensionAspectRatio(295, 320)
    const newWidth = actualWidth / aspectRatio - 5
    setCityWidth(newWidth)
  }

  const getNewHeight = () => {
    const width = document.querySelector('.section-heading')
    const actualWidth = width && width.offsetWidth / 5
    const aspectRatio = dimensionAspectRatio(295, 320)
    const newHeight = actualWidth / aspectRatio - 5
    setCityHeight(newHeight)
  }
  useEffect(() => {
    getNewWidth()
    getNewHeight()
    window.addEventListener('resize', getNewWidth)
    window.addEventListener('resize', getNewHeight)
    return () => {
      window.removeEventListener('resize', getNewWidth)
      window.removeEventListener('resize', getNewHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="city-from-country city--country--grid">
      <Grid container spacing={2} className='glo-mob-rent-city-card'>
        {result &&
          result.length > 0 &&
          result.map((item, i) => (
            <>
              {limit > i && (
                <Grid item xs={12} sm={4} md={true} key={item.id} className="cursor-pointer glo-mob-rent-city-sub-card">
                  <div>
                    <CityWiseComponent
                      value={item}
                      img={encodeURI(item.image)}
                      getCityWiseBoats={() => getCityWiseRentBoats(item)}
                      city={item.city}
                      country={item.country}
                      // width={cityWidth}
                      height={cityHeight}
                      className="city-component-height"
                      minimumPrice={item.minimumPrice}
                    />
                  </div>
                </Grid>
              )}
            </>
          ))}
      </Grid>
    </div>
  )
}
