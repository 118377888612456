import React, { useContext } from 'react'
import { InputBase } from '@material-ui/core'
import { getPagesValue } from '../../helpers/jsxHelper'
import { pagination } from '../../util/enums/enums'
import { debounce } from 'lodash'
import UserContext from '../../UserContext'

export const DashboardSearchSection = ({ action, className, manufacturerId, branchId, setSearchValue }) => {
  const { history } = useContext(UserContext)

  const debounceSearchAction = debounce(async searchTerm => {
    searchAction(searchTerm)
  }, 1000)

  const resetPage = () => {
    const urlParams = new URLSearchParams(history.location.search)
    let searchParams;
    if (urlParams.has('page')) {
      searchParams = location.search.split('page')
    }
    const search = searchParams?.length
      ? `${searchParams[0]}page=1`
      : `${location.search}&page=1`

    history.replace({
      pathname: location.pathname,
      search,
      state: location.state,
    })
  }

  const searchAction = value => {
    resetPage()
    let params = {
      limit: pagination.AGENT_PAGE_LIMIT,
      page: getPagesValue(),
    }
    if (manufacturerId) {
      params = {
        ...params,
        manufacturerId,
      }
    }
    if (branchId) {
      params = {
        ...params,
        branchId,
      }
    }

    if (value) {
      action({ ...params, searchTerm: value.trim() }, true)
      setSearchValue(value.trim())
    } else {
      action(params, true)
      setSearchValue('')
    }
  }

  return (
    <div className={`search--data--input h-100 ${className}`}>
      <InputBase placeholder="Search Here..." className="search-input" onChange={e => debounceSearchAction(e.target.value)} />
      <img loading="lazy" src={require('../../assets/images/salesEngine/search.svg')} alt="Search" className="search--icon" />
    </div>
  )
}
