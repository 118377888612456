import React from 'react'
import cn from 'classnames'

/**
 * @param {React.SVGProps<SVGSVGElement>} props
 */
export default function IconMenuVDots(props) {
  return (
    <svg
      {...props}
      className={cn('as-media-icon', props.className)}
      width="4"
      height="18"
      viewBox="0 0 4 18"
      fill="currentColor"
    >
      <circle cx="2" cy="16" r="2" transform="rotate(-90 2 16)" />
      <circle cx="2" cy="9" r="2" transform="rotate(-90 2 9)" />
      <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" />
    </svg>
  )
}
