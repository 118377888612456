import { put, takeLatest, all } from 'redux-saga/effects'
import {
  GET_ALL_MEDIA_ARTICLES_SUCCESS,
  GET_ALL_MEDIA_ARTICLES_FAILURE,
  GET_ALL_MEDIA_ARTICLES,
  GET_ALL_MEDIA_RECORD,
  GET_ALL_MEDIA_RECORD_SUCCESS,
  GET_ALL_MEDIA_RECORD_FAILURE,
} from '../actionTypes'
import { graphqlClient } from '../../helpers/graphqlClient'
import { getAllMediaArticles, getAdamseaMedia } from '../../graphql/mediaArticleSchema'

function getAllMediaArticlesApi(data) {
  return graphqlClient
    .query({
      query: getAllMediaArticles,
      variables: data.data,
    })
    .then(res => res)
    .catch(error => {
      if (error.networkError) {
        return error.networkError.result
      }
    })
}

function getAllMediaApi(data) {
  return graphqlClient
    .query({
      query: getAdamseaMedia,
      variables: data,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      if (error.networkError) {
        return error.networkError.result
      }
    })
}

function* getAllMediaArticle(data) {
  const res = yield getAllMediaArticlesApi(data)
  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({
      type: GET_ALL_MEDIA_ARTICLES_SUCCESS,
      payload: res.data.getAllArticles,
      total: res.data.getAllArticles.total,
    })
  } else {
    yield put({ type: GET_ALL_MEDIA_ARTICLES_FAILURE, error: res.errors })
  }
}

function* getAllMedia(data) {
  const res = yield getAllMediaApi(data.payload)
  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({
      type: GET_ALL_MEDIA_RECORD_SUCCESS,
      payload: res.data.getAdamseaMedia,
    })
  } else if (res && res.errors) {
    yield put({ type: GET_ALL_MEDIA_RECORD_FAILURE, error: res.errors })
  }
}

function* getAllMediaArticlesSaga() {
  yield takeLatest(GET_ALL_MEDIA_ARTICLES, getAllMediaArticle)
}

function* getAllMediaSaga() {
  yield takeLatest(GET_ALL_MEDIA_RECORD, getAllMedia)
}

export default function* mediaArticlesSaga() {
  yield all([getAllMediaArticlesSaga(), getAllMediaSaga()])
}
