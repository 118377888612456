import { ApolloClient, InMemoryCache, HttpLink, split } from 'apollo-boost'
import io from 'socket.io-client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { setContext } from 'apollo-link-context'
import * as fetch from 'isomorphic-fetch'
import { WebSocket } from "ws";
import { getLocalStorageItem } from './storageHelper'
import { envConfig } from '../config'
import { socket } from '../socket';

const BASE_URL = envConfig.BASE_URL
const BASE_CHAT = envConfig.BASE_CHAT
const CHAT_SOCKET = envConfig.CHAT_SOCKET
const RAZZLE_SEO_TEST_URL = envConfig.RAZZLE_SEO_TEST_URL || envConfig.BASE_URL

const httpLink = new HttpLink({
  uri: BASE_URL,
  fetch: fetch,
})
const httpLinkChat = new HttpLink({
  uri: BASE_CHAT,
  fetch: fetch,
})

const authLink = setContext((_, { headers }) => {
  const token = getLocalStorageItem('token')
  return {
    headers: {
      ...headers,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      authorization: token ? `${token}` : '',
    },
  }
})

const authLinkChat = setContext((_, { headers }) => {
  const token = getLocalStorageItem('token')
  return {
    headers: {
      ...headers,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      authorization: token ? token : '',
    },
  }
})

export const graphqlClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export const graphqlClientChat = new ApolloClient({
  link: authLinkChat.concat(httpLinkChat),
  cache: new InMemoryCache(),
})

const httpLinkPublic = new HttpLink({
  uri: RAZZLE_SEO_TEST_URL,
  fetch,
  credentials: "omit"
})
export const graphqlClientPublic = new ApolloClient({
  link: authLink.concat(httpLinkPublic),
  cache: new InMemoryCache(),
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
}

const wsLink = new GraphQLWsLink(createClient({
  url: BASE_CHAT.replace('https','wss'),
  connectionParams: () => {
    return { authorization: getLocalStorageItem('token') }
  },
  webSocketImpl: WebSocket,
}));
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLinkChat,
);

export const graphqlClientChatWsLink = new ApolloClient({
  link: authLinkChat.concat(splitLink),
  cache: new InMemoryCache(),
});
export const resetStore = () => {
  graphqlClient.cache.reset()
  graphqlClientChatWsLink.cache.reset()
  console.log('here resetStore');
  // While log out
  socket?.off('connect');
  socket?.off('disconnect');
  socket?.off('pong');
}
