import React from 'react'

import { CommonModal } from './commonModal'
import { displayDefaultValue } from '../../helpers/string'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'

import './branchDetailModal.scss'
import { CurrencyContextConsumer } from '../../CurrencyContext'

export const BranchDetailModal = props => {
  const { open, close, value } = props

  const {
    branchName,
    contactName,
    address: [{ country, city }],
    mobileNumber,
  } = value || {}

  return (
    <>
      <CommonModal open={open} close={close} title="Branch Details" className="branch-detail-section">
        <>
          {value && (
            <div className="branch-detail-content">
              <div className="branch-detail-column">
                <label>Branch Name :</label> <span>{contactName && displayDefaultValue(branchName)}</span>
              </div>
              <div className="branch-detail-column">
                <label>Country :</label> <span>{country && displayDefaultValue(country)}</span>
              </div>
              <div className="branch-detail-column">
                <label>City :</label> <span>{city && displayDefaultValue(city)}</span>
              </div>
              <div className="branch-detail-column">
                <label>Contact Name :</label> <span>{contactName && displayDefaultValue(contactName)}</span>
              </div>
              <div className="branch-detail-column">
                <label>Contact Number :</label> <span>{mobileNumber && displayDefaultValue(mobileNumber)}</span>
              </div>
            </div>
          )}
        </>
      </CommonModal>
    </>
  )
}
