import React from 'react'
import Select from 'react-select'

import './multiSelect.scss'
import { HelpIconComponent } from './helpIconComponent'
import { Box, Grid } from '@material-ui/core'

class MultiSelect extends React.Component {
  render() {
    const { name, selectedOption, onChangeValue, options, placeholder, helpText, label, maxOptions, required } = this.props

    return (
      <>
        {label && (
          <Grid xs={12} item className="multi--help-position-style">
            <Box fontSize={20} letterSpacing={1} fontWeight={600} className="font-sm-label">
              <span className={`help--icon--position--lb-tool ${required ? 'required' : ''}`}>
                {label}
                <HelpIconComponent helpText={helpText} containerClass="mt-1 mb-1" />
              </span>
            </Box>
          </Grid>
        )}

        <div className="multi-select-div">
          <Select
            {...this.props}
            isMulti
            name={name}
            placeholder={placeholder}
            value={selectedOption}
            closeMenuOnSelect={false}
            onChange={value => onChangeValue(value || [])}
            options={maxOptions && selectedOption.length === maxOptions ? [] : options}
            classNamePrefix="multi--select-control"
            noOptionsMessage={() =>
              maxOptions && selectedOption.length === maxOptions
                ? "You've reached the max options value"
                : 'No options available'
            }
          />
        </div>
      </>
    )
  }
}

export default MultiSelect
MultiSelect.defaultProps = {
  placeholder: '',
  maxOptions: null,
  required: false,
}
