import React, { useState, useEffect } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { positiveNumberHelper, requireMessage, numberSeparate } from '../../helpers/string'
import { Grid, Box, Button } from '@material-ui/core'
import { Field } from '../ws/Field'
import ErrorFocus from '../ws/ErrorFocus'
import { helpTextEnum, MEDIA_NAME } from '../../util/enums/enums'
import { CommonModal } from '../modal/commonModal'
import { ButtonComponent } from '../form/Button'
import { getAddMedia } from '../../helpers/s3FileUpload'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { getUsdPrice, getLocalCurrencyPrice } from '../../helpers/currencyConverterHelper'
import IconSEArrowRight from '../../containers/salesEngine/process/right-arrow-svgrepo-com'

export const ShipmentProposal = props => {
  const { open, closeModal, value, onSubmit, shipperReport, getDocumentUrl } = props

  const [initValue, setInitValue] = useState({
    price: '',
    proposalDocument: '',
    addMedia: {
      proposalDocument: [],
    },
    removeMedia: [],
  })

  useEffect(() => {
    value && setInitValue({ ...initValue, ...value })
  }, [value])
  useEffect(() => {
    if (shipperReport) {
      setInitValue({ price: getLocalCurrencyPrice(shipperReport?.price), proposalDocument: shipperReport?.proposalDocument })
    }
  }, shipperReport)
  const currentCurrency = getLocalStorageItem('currentCurrency')

  return (
    <CommonModal title="Send a quote" open={open} className="shipment--proposal-modal-ui" close={closeModal}>
      <Formik
        initialValues={
          shipperReport
            ? {
                ...initValue,
                addMedia: {
                  proposalDocument: [],
                },
                removeMedia: [],
              }
            : {
                price: '',
                proposalDocument: '',
                addMedia: {
                  proposalDocument: [],
                },
                removeMedia: [],
              }
        }
        onSubmit={values => {
          const { proposalDocument, ...restValues } = values

          if (currentCurrency !== 'USD') {
            restValues.price = getUsdPrice(restValues.price, currentCurrency)
          } else {
            restValues.price = +restValues.price
          }
          restValues.addMedia = getAddMedia(restValues.addMedia)
          onSubmit(restValues)
        }}
        validationSchema={Yup.object().shape({
          price: Yup.number().required(requireMessage('Price')).positive(positiveNumberHelper('Price')),
          proposalDocument: Yup.string().required(requireMessage('Proposal Document')),
        })}
        render={({ values, setFieldValue, errors, handleSubmit }) => (
          <Form className="overflow-hidden">
            <ErrorFocus />
            <div className="shipment--proposal-content-ui">
              <Grid container>
                <Grid item sm={12}>
                  <Field
                    label={`Delivery total price (${currentCurrency})`}
                    // helpText={helpTextEnum.priceInput}
                    name="price"
                    type="text"
                    value={values.price}
                    className="form-control"
                    onChangeText={e => setFieldValue('price', e.target.value || '')}
                    required
                  />
                  <ErrorMessage component="div" name="price" className="error-message" />
                </Grid>
                <Grid item sm={12} className="mt-5 document--mt">
                  <Field
                    label="Upload a quote"
                    buttonText="Upload quote"
                    name="proposalDocument"
                    type="shipment-document"
                    value={values.proposalDocument}
                    addMedia={values.addMedia}
                    deleteIds={values.removeMedia}
                    className="form-control"
                    onChangeText={setFieldValue}
                    mediaName={MEDIA_NAME.SHIPPERPROPOSAL}
                    getDocumentUrl={getDocumentUrl}
                    required
                  />
                  <ErrorMessage component="div" name="proposalDocument" className="error-message proposal--document--error" />
                </Grid>
              </Grid>
            </div>
            <div className="shipment--proposal--footer-modal">
              {/* <ButtonComponent className="mr-2" color="cancel" onClick={closeModal}>
                Cancel
              </ButtonComponent> */}

              <button className="btn btn-lg btn-o-black" onClick={handleSubmit}>
                Send to buyer <IconSEArrowRight height="1em" className="ml-3" style={{ animation: 'flowH 2s linear infinite' }} />
              </button>
            </div>
          </Form>
        )}
      />
    </CommonModal>
  )
}
