import React, { Component } from 'react'
import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { sendConfirmationLink, clearForgotPassword, onMenuItemClick } from '../../redux/actions'

import {
  dashboardTabs,
  activationType,
  requiredProfileUpdate,
  urlsWithoutPermissions,
  dashboardSidebarMyStoreSubMenu,
  myStoreIdAccessibleRoles,
  userRoles,
} from '../../util/enums/enums'
import { SuccessNotify } from '../../helpers/notification'
import UserContext from '../../UserContext'
import './dashboard.scss'
import './fixDashboard.scss'
import '../../styles/dashboardResponsive.scss'
import ConfirmationEmail from '../../components/modal/confirmationEmail'

class DashboardSidebar extends Component {
  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = {
      openDialog: false,
      isOpen: false,
      isMyStoreOpen: false,
    }
  }

  toggleHandler = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  myStoreToggleHandler = () => {
    this.setState(prevState => ({ isMyStoreOpen: !prevState.isMyStoreOpen }))
  }

  componentDidMount() {
    const { forgotPasswordSuccess, clearForgotPassword } = this.props
    if (forgotPasswordSuccess) {
      clearForgotPassword()
      SuccessNotify('Password Changed Successfully')
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { forgotPasswordSuccess, clearForgotPassword } = nextProps

    if (forgotPasswordSuccess) {
      clearForgotPassword()
      SuccessNotify('Password Changed Successfully')
    }
    return null
  }

  modalHandler = () => {
    this.setState(preState => ({ openDialog: !preState.openDialog }))
  }

  redirectionHandler = (url, tabName = '') => {
    const {
      boats,
      branch,
      buyItNow,
      articles,
      boatRents,
      services,
      marinaAndStorage,
      salesEngine,
      salesEngineArchive,
      auctionRooms,
      boatShows,
      myRequests,
      changePassword,
      userProfile,
      logOut,
      auctionRoomBidList,
    } = dashboardTabs
    const { currentUser, onMenuItemClick } = this.props
    const { documentVerification } = currentUser
    const { history } = this.context

    onMenuItemClick([], tabName)
    const urls = [
      boats.url,
      branch.url,
      articles.url,
      buyItNow.url,
      boatRents.url,
      services.url,
      marinaAndStorage.url,
      salesEngine.url,
      salesEngineArchive.url,
      auctionRooms.url,
      boatShows.url,
      auctionRoomBidList.url,
      myRequests.url,
    ]

    if (documentVerification && !documentVerification.emailVerified && urls.includes(url)) {
      this.modalHandler()
    } else if (currentUser.isProfilecompleted || urlsWithoutPermissions.includes(url)) {
      history.push(`/${url}`)
    } else {
      SuccessNotify(requiredProfileUpdate)
    }
  }

  onMenuItemClick = (data, selectedIndex) => {
    const { dashBoardSideBarData, onMenuItemClick } = this.props

    const newData = dashBoardSideBarData.map((data, index) => {
      if (selectedIndex === index) {
        data.selected = true
        return data
      } else {
        data.selected = false
        return data
      }
    })

    onMenuItemClick(newData)

    this.redirectionHandler(data.url)
  }

  render() {
    const { sendConfirmationLink, currentUser, dashBoardSideBarData, subMenu } = this.props
    const { agreementsAndFiles } = dashboardTabs
    const { openDialog, isOpen, isMyStoreOpen } = this.state
    const { email } = currentUser
    const { myStore, myRequests, myStoreArchive, bidList } = dashboardSidebarMyStoreSubMenu

    return (
      <>
        {openDialog && (
          <ConfirmationEmail
            open={openDialog}
            close={this.modalHandler}
            email={email}
            sendLink={data => sendConfirmationLink({ userName: data.email, activationType: activationType.EMAIL })}
          />
        )}

        <div className="dashboard-sidebar-align dashboard-sidebar-sticky">
          <div className="pr-0 dashboard-sidebar">
            <div>
              {dashBoardSideBarData.length &&
                dashBoardSideBarData.map((data, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => this.onMenuItemClick(data, index)}
                      className={data.selected ? 'dashboard-selected-div' : 'dashboard-left-div-link'}
                    >
                      <div
                        className={
                          (data.role.includes(currentUser.role && currentUser.role.aliasName) ||
                            (data.role.includes('common') &&
                              !data.role.includes(currentUser.role && '!' + currentUser.role.aliasName))) &&
                          data.name !== agreementsAndFiles.title
                            ? 'displayBlock dashboard-right-div-option-border'
                            : 'displayHide'
                        }
                      >
                        <div className="dashboard-links cursor-pointer">
                          <div
                            className={`dashboard-font custom-padding d-flex align-items-center ${
                              (index === 0 || data.name === 'Back to Market') && 'dashboard--left--div--main--btn--section'
                            }`}
                          >
                            <div
                              className={`d-flex align-items-center ${
                                (index === 0 || data.name === 'Back to Market') &&
                                'pl-2 pr-2 pt-1 pb-1 dashboard-left-div-main-btn'
                              }`}
                            >
                              {/* <div className="custom-mobile-margin">
                                <img
                                  className="mr-2 dashboard-side-icon d-flex dashboard-left-div-icons"
                                  src={data.imgPath}
                                  alt={data.name}
                                />
                              </div> */}
                              <span className="title-text dashboard-sidebar-text">{data.name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>

            <div className="dashboard-links cursor-pointer">
              <div className="dashboard-font custom-padding d-flex align-items-center">
                <div className="d-flex width-100">
                  <div className="width-100 d-flex flex-column dashboard-setting-div dashboard-sidebar-box">
                    <div className="width-100 d-flex justify-content-between align-items-center custom-width-mobile">
                      <div className="setting-icon">
                        <span className="title-text dashboard-sidebar-text font-bold">AdamSea Store</span>
                      </div>
                    </div>
                    <div className="drop-down-sidebar">
                      <NavLink
                        to={'/sales-engines'}
                        activeClassName="nav--selected"
                        className="title-text dashboard-sidebar-text light-silver"
                      >
                        {'My Store'}
                      </NavLink>
                      <NavLink
                        to={'/sales-engine-archives'}
                        activeClassName="nav--selected"
                        className="title-text dashboard-sidebar-text light-silver"
                      >
                        {'My Store Archive'}
                      </NavLink>
                      <NavLink
                        to={'/auction-room-bid-list'}
                        activeClassName="nav--selected"
                        className="title-text dashboard-sidebar-text light-silver"
                      >
                        {'Manage Auctions'}
                      </NavLink>
                      {myStoreIdAccessibleRoles.includes(currentUser?.role?.aliasName) && (
                        <NavLink
                          to={'/my-requests'}
                          activeClassName="nav--selected"
                          className="title-text dashboard-sidebar-text light-silver"
                        >
                          {'My Requests'}
                        </NavLink>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard-links cursor-pointer">
              <div className="dashboard-font custom-padding d-flex align-items-center">
                <div className="d-flex width-100">
                  <div className="width-100 d-flex flex-column dashboard-setting-div dashboard-sidebar-box">
                    <div className="width-100 d-flex justify-content-between align-items-center custom-width-mobile">
                      <div className="setting-icon">
                        <span className="title-text dashboard-sidebar-text font-bold">Settings</span>
                      </div>
                    </div>
                    <div className="drop-down-sidebar">
                      <NavLink
                        to="/manage-payment"
                        activeClassName="nav--selected"
                        className="title-text dashboard-sidebar-text light-silver"
                      >
                        {'Payments'}
                      </NavLink>

                      {currentUser?.role?.aliasName !== userRoles.MEMBER && (
                        <NavLink
                          to="/add-payment"
                          activeClassName="nav--selected"
                          className="title-text dashboard-sidebar-text light-silver"
                        >
                          Add Payment
                        </NavLink>
                      )}

                      <NavLink
                        to={
                          currentUser?.role?.aliasName === userRoles.RENT_AND_CHARTER
                            ? '/manage-rent-payouts'
                            : '/manage-payouts'
                        }
                        activeClassName="nav--selected"
                        className="title-text dashboard-sidebar-text light-silver"
                      >
                        {'Manage Payouts'}
                      </NavLink>

                      <NavLink
                        to="/change-password"
                        activeClassName="nav--selected"
                        className="title-text dashboard-sidebar-text light-silver"
                      >
                        {'Password'}
                      </NavLink>

                      <NavLink
                        to="/close-account"
                        activeClassName="nav--selected"
                        className="title-text dashboard-sidebar-text light-silver"
                      >
                        {'Close Account'}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.loginReducer.isAuthenticated,
  currentUser: state.loginReducer.currentUser,
  dashBoardSideBarData: state.dashboardReducer.dashBoardSideBarData,
  subMenu: state.dashboardReducer.subMenu,
  forgotPasswordSuccess: state.loginReducer.forgotPasswordSuccess,
})

const mapDispatchToProps = dispatch => ({
  sendConfirmationLink: data => dispatch(sendConfirmationLink(data)),
  onMenuItemClick: (data, subMenu) => dispatch(onMenuItemClick(data, subMenu)),
  clearForgotPassword: () => dispatch(clearForgotPassword()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSidebar)
