import gql from 'graphql-tag'
import { AdminUser, BoatRent, AddressType, ImageType } from './schemaTypes/schemaTypes'

export const bookRentBoat = gql`
  mutation bookRentBoat($input: BoatRentBookInput!) {
    bookRentBoat(input: $input) {
      type
      startDate
      endDate
      startTime
      unit
      endTime
      members
    }
  }
`

export const getUserRentBoatBooking = gql`{
 getUserRentBoatBooking {
    boat {
        address ${AddressType}
        id
        captainName
        whatToBring
        descriptionOfOurTrip
        images ${ImageType}
    }
    type
    startDate
    endDate
    startTime
    endTime
    members
    user {
        id
    }
}
}
`
