import React, { useState, useEffect } from 'react'
import { Notification } from '..'
import Header from '../header/header'
import Footer from '../footer/footer'
import MobileMenu from '../header/MobileMenu'
import DashboardSidebar from '../../containers/dashboard/dashboardSidebar'
import { useDispatch, useSelector } from 'react-redux'
import { getIsChatShow, getIsGalleryOpen, getSelectUser } from '../../redux/selector/chat'
import AdamseaChat from '../../containers/chat/adamseaChat'
import OutsideClickHandler from 'react-outside-click-handler'
import { chatToggle } from '../../redux/actions/chatAction'
import { socket } from '../../socket'
import { socketEvent } from '../../util/enums/socketEnums'

export const DashboardLayout = props => {
  const [height, setHeight] = useState(95)
  const dispatch = useDispatch()
  const isChatShow = useSelector(getIsChatShow)
  const isGalleryOpen = useSelector(getIsGalleryOpen)
  const selectUser = useSelector(getSelectUser)
  const [dashboardMenu, setdashboardmenu] = useState('true')

  const zoomWidth = () => {
    setTimeout(() => {
      const selector = document.querySelector('.header-responsive')
      const height = selector && selector.offsetHeight
      height && setHeight(height)
    })
  }

  useEffect(() => {
    zoomWidth()
    window.addEventListener('resize', zoomWidth)
    return () => {
      window.removeEventListener('resize', zoomWidth)
    }
  }, [])

  return (
    <div>
      <>
        <Header mobileMenu_dashboardnavbar={dashboardMenu} />
        <Notification />

        <OutsideClickHandler
          display="contents"
          onOutsideClick={element => {
            if (element?.target?.className === 'width-height-icon-bell' && isChatShow) return
            if (!isGalleryOpen) {
              socket.emit(socketEvent.LeaveChannel,selectUser?.chatId)
              dispatch(chatToggle(false))
            }
          }}
        >
          {isChatShow && <AdamseaChat height={height} />}
        </OutsideClickHandler>

        <main
          ref={zoomWidth}
          className={`dashboard-layout-responsive adam-dash-mob-res ${props.className}`}
          style={{ marginTop: height }}
        >
          <div className="width-100 d-flex dashboard-align position-relative">
            <div className="dashboard-mobile">
              <DashboardSidebar />
            </div>
            <div className="dashboard-right-div-bg">{props.children}</div>
          </div>
        </main>
        <hr className="mt-0 mb-0" />
        <div className="dashboard-footer">{!props.isFooter && <Footer />}</div>
      </>
    </div>
  )
}
DashboardLayout.defaultProps = {
  className: '',
}
