import { FaRegClipboard } from 'react-icons/fa'
import { CommonTooltip } from './CommonTooltip'
import { copyCodeToClipboard } from '../util/utilFunctions'

const CopyAdId = ({ adId }) => (
  <>
    <span style={{ display: 'inline-flex', padding: '0.5em 1em', borderRadius: 9999, border: '1px solid #0003', whiteSpace: 'nowrap' }}>
      Ad ID: {adId}
      <CommonTooltip
        component={ctProps => (
          <span data-tooltip-id={ctProps.id} data-tooltip-content="Copy Ad Id">
            <FaRegClipboard
              className="pl-1 mb-1 svg-1em"
              style={{ cursor: 'pointer' }}
              onClick={() => copyCodeToClipboard(adId)}
            />
          </span>
        )}
      />
    </span>
  </>
)

export default CopyAdId
