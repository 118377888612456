export const getMarinaType = data => {
  switch (data.alias) {
    case 'Marina Provide':
      return data.id

    case 'Boat Storage Provide':
      return data.id

    case 'Both':
      return data.id

    default:
      break
  }
}
