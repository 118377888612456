import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import { Field, Loader } from '../../components'
import { clearBoatRentFlag, getPaymentReceipt, getCommissions, getCommissions_clear } from '../../redux/actions'
import moment from 'moment'
import { getAddress } from '../../helpers/jsxHelper'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { isHourly, isPrivate, isShared } from '../../util/utilFunctions'
import { clearRentPagesFlag, getRentPages } from '../../redux/actions/pageInfoByTypeAction'
import cn from 'classnames'
import styles from './BookingAdPage.module.scss'
import { useHistory } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { footerLinks } from '../../util/enums/enums'
import { CommonModal } from '../../components/modal/commonModal'

const BookingAdPage = ({ isPopup = false, ...props }) => {
  const {
    getRentPages,
    clearRentPagesFlag,
    rentPagesSuccess,
    rentPagesFailure,
    rentPages,
    boatRentInnerData,
    createRentBookingLoading,
    getCommissions,
    getCommissions_clear,
    commissions,
  } = props

  const history = useHistory()

  const {
    commonAddress: { city: boatCity, country: boatCountry },
    gatheringPoint: { city: gatheringCity },
  } = getAddress(boatRentInnerData.address)

  const [content, setContent] = useState({})
  const [tz, setTz] = useState('')
  const [tripAlias, setTripAlias] = useState('')
  const [tripTypeAlias, setTripTypeAlias] = useState('')
  const [tripDetails, setTripDetails] = useState([])
  const [dateVal, setDateVal] = useState('')
  const [maxAvailable, setMaxAvailable] = useState(0)
  const [selectedDate, setSelectedDate] = useState('')
  const [noOfUnitsOpen, setNoOfUnitsOpen] = useState(false)
  const [selectedSlots, setSelectedSlots] = useState([])
  const [selectedSlot, setSelectedSlot] = useState('')
  const [count, setCount] = useState(1)
  const [rentServiceFee, setRentServiceFee] = useState(0)
  const [paymentPolicyPopupOpen, setPaymentPolicyPopupOpen] = useState(false)
  const [cancellationPolicyPopupOpen, setCancellationPolicyPopupOpen] = useState(false)
  const [moreTripsModalOpen, setMoreTripsModalOpen] = useState(false)

  useEffect(() => {
    getRentPages()
    getCommissions()
  }, [])

  useEffect(() => {
    dateVal || (tz && setDateVal(moment().tz(tz)))
  }, [tz])

  useEffect(() => {
    if (rentPagesSuccess && rentPages) {
      const pages = {}
      rentPages.forEach(p => (pages[p.titleSlug] = p))
      setContent(pages)
    }

    if (rentPagesSuccess || rentPagesFailure) clearRentPagesFlag()
  }, [rentPagesSuccess])

  useEffect(() => {
    if (commissions?.success && commissions.data) {
      setRentServiceFee(commissions.data?.Rent_Service_Fee)
    }

    if (commissions?.success || commissions?.failure) getCommissions_clear()
  }, [commissions])

  useEffect(() => {
    const _tz = boatRentInnerData.timezoneName
    setTz(_tz)

    const _utcOffset = moment().tz(_tz).utcOffset()

    setTripAlias(boatRentInnerData.trip.alias)
    setTripTypeAlias(boatRentInnerData.tripType.alias)

    const _tripDetails = (boatRentInnerData.tripDetails || [])
      .map(({ __typename, id, ...s }) => ({
        ...s,
        _id: id || s._id,
        startDate: moment(s.startDate).tz(_tz).subtract(_utcOffset, 'minutes'),
        startTime: moment(s.startTime).tz(_tz).subtract(_utcOffset, 'minutes'),
        endDate: moment(s.endDate).tz(_tz).subtract(_utcOffset, 'minutes'),
        endTime: moment(s.endTime).tz(_tz).subtract(_utcOffset, 'minutes'),
      }))
      .filter(slot => !slot.expired && slot.available && moment().tz(_tz).isBefore(slot.startDate))
      .sort((s1, s2) => s1.startDate.valueOf() - s2.startDate.valueOf())

    setTripDetails(_tripDetails)

    const initSlotDate = _tripDetails.find(s => s.price === boatRentInnerData.price)?.startDate
    initSlotDate && setSelectedDate(initSlotDate)
    initSlotDate && setDateVal(initSlotDate)

    const _setMaxAvailable = Math.max(..._tripDetails.map(trip => trip.available))
    setMaxAvailable(_setMaxAvailable)
  }, [boatRentInnerData])

  useEffect(() => {
    selectedDate && tripDetails.length && checkAvailability()
  }, [selectedDate, tripDetails])

  const checkAvailability = () => {
    setDateVal(selectedDate)

    const _slots = tripDetails.filter(s => s.startDate.isSame(selectedDate, 'day') && s.available >= count)
    setSelectedSlots(_slots)
    setSelectedSlot(_slots[0])
  }

  const calcTotalAmount = baseAmount => {
    const _serviceFee = baseAmount * (rentServiceFee / 100)

    const pGoal = baseAmount + _serviceFee
    const fFixed = 0.3
    const fPerc = 0.029
    const fPerc2 = fPerc + 0.006

    const _totalAmount = (pGoal + fFixed) / (1 - fPerc)
    const _totalAmount2 = (pGoal + fFixed) / (1 - fPerc2)

    const _stripeFee = _totalAmount - (baseAmount + _serviceFee)
    const _stripeFee2 = _totalAmount2 - (baseAmount + _serviceFee)

    return _totalAmount
  }

  return (
    <>
      <div className="rent-booking-design m-0">
        <div className={cn('check-avail-box', isPopup && styles.checkAvailBoxPopup)}>
          {!!selectedSlots.length && (
            <h2 className={styles.minPriceLabel}>
              From {getConvertedPrice(boatRentInnerData.price)}{' '}
              <span style={{ fontWeight: 'normal', fontSize: '0.7em' }}>
                {isShared(tripAlias) ? 'Person' : isPrivate(tripAlias) ? 'Trip' : 'Hour'}
              </span>
            </h2>
          )}

          <p className={cn('mt-1', !selectedSlots.length && 'text-center font-bold')}>
            {tripTypeAlias} starts from {boatCity}, {boatCountry}
          </p>

          {!!selectedSlots.length && (
            <>
              <p className="mt-3 font-bold">Select Date and Guests</p>

              <div className="mt-3 d-flex">
                <div style={{ flex: 1, fontSize: '1.2em' }}>
                  <Field
                    label="Date"
                    type="select-date"
                    isTimeFormatFalse
                    timezoneName={tz}
                    placeholder="Date"
                    value={selectedDate}
                    className="mb-0"
                    isUpdate
                    required
                    onChange={setSelectedDate}
                    customDateFormat="dddd, MMMM DD, YYYY"
                    highlightDates={tripDetails.map(slot => slot.startDate.startOf('day'))}
                    isValidDate={cur => !moment(cur).isBefore(moment(), 'days')}
                  />
                </div>

                <div className="ml-3 d-flex flex-wrap flex-column align-items-center">
                  <button className={styles.noOfUnitsTogBtn} onClick={() => setNoOfUnitsOpen(open => !open)}>
                    {count} {isHourly(tripAlias) ? 'Watercraft' : isPrivate(tripAlias) ? 'Guest' : 'Guest'}
                    {count > 1 ? 's' : ''}
                  </button>

                  <div className="position-relative w-100">
                    <OutsideClickHandler onOutsideClick={() => setNoOfUnitsOpen(false)}>
                      {noOfUnitsOpen && (
                        <div className={styles.noOfUnitsPopup}>
                          <h2 className={cn('review-pay-head', styles.reviewPayHead)}>
                            {isHourly(tripAlias) ? 'Watercrafts' : isPrivate(tripAlias) ? 'Group Size' : 'Guests'}
                          </h2>

                          <div className="d-flex align-items-center">
                            <button
                              className="mx-4 btn-o-black rounded-full"
                              style={{ aspectRatio: 1 }}
                              onClick={() => setCount(count => (count > 1 ? count - 1 : 1))}
                            >
                              <RemoveIcon style={{ fontSize: '1em' }} />
                            </button>

                            <span className="text-black h5 m-0 d-flex justify-content-center" style={{ minWidth: '25px' }}>
                              {count}
                            </span>

                            <button
                              className="mx-4 btn-o-black rounded-full"
                              style={{ aspectRatio: 1 }}
                              onClick={() => {
                                setCount(count => {
                                  if (isPrivate(tripAlias)) {
                                    return count < boatRentInnerData.maximumGuest ? count + 1 : boatRentInnerData.maximumGuest
                                  } else {
                                    console.log(maxAvailable)
                                    return count < maxAvailable ? count + 1 : maxAvailable
                                  }
                                })
                              }}
                            >
                              <AddIcon style={{ fontSize: '1em' }} />
                            </button>
                          </div>
                        </div>
                      )}
                    </OutsideClickHandler>
                  </div>
                </div>
              </div>

              <div
                className="mt-4 badge badge-pill badge-ghost my-3"
                style={{ fontSize: '.9em', textTransform: 'unset !important' }}
              >
                {isPrivate(tripAlias)
                  ? `Trip capacity is ${boatRentInnerData.maximumGuest} guests`
                  : 'Maximum ' +
                    (isShared(tripAlias) ? `${maxAvailable} guest` : `${maxAvailable} watercraft`) +
                    (maxAvailable > 1 ? 's' : '') +
                    ' available for booking'}
              </div>
            </>
          )}

          <div className="mt-4">
            {selectedSlots.length ? (
              <>
                {(isPopup ? selectedSlots : selectedSlots.slice(0, 1)).map(s => (
                  <div
                    className={cn(
                      'slot-choose-box',
                      styles.slotChooseBox,
                      selectedSlot._id === s._id && styles.slotChooseBoxActive
                    )}
                    key={s._id}
                    onClick={() => setSelectedSlot(s)}
                  >
                    <div className="slot-choose-dets">
                      <p className="slot-price-title">{s.startTime.format('h:mma')} Tour</p>
                      <p className="mt-4 slot-price">
                        {tripTypeAlias} Tour gathering point at {gatheringCity}, trip starts at {s.startTime.format('h:mma')}
                      </p>
                      <p className="mt-4 slot-price">
                        {count} {isHourly(tripAlias) ? boatRentInnerData.tripType?.alias : 'Person'}
                        {count > 1 ? 's' : ''} &times; {getConvertedPrice(s.price)}
                      </p>
                      <p className="slot-price-total">
                        Total {getConvertedPrice(calcTotalAmount(isPrivate(tripAlias) ? s.price : s.price * count))}
                      </p>
                      <p className="mt-1 slot-price">(Price includes taxes, service fee and transfer fee)</p>
                      <span className="mt-3 p-2 rounded-full" style={{ display: 'inline-block', border: '1px solid #000' }}>
                        {tripAlias}
                      </span>
                    </div>
                  </div>
                ))}

                {!isPopup && selectedSlots.length > 1 && (
                  <div className={cn('mt-3', styles.seeMoreTripsBtn)}>
                    <span onClick={() => setMoreTripsModalOpen(true)}>See all {selectedSlots?.length} tours options</span>

                    <CommonModal open={moreTripsModalOpen} close={() => setMoreTripsModalOpen(false)} title="Tours">
                      <BookingAdPage {...props} isPopup />
                    </CommonModal>
                  </div>
                )}

                <div className="mt-3 d-flex">
                  <button
                    className="flex-1 btn btn-rounded btn-black"
                    onClick={() =>
                      history.push(
                        `/review-booking/${
                          boatRentInnerData.id
                        }?selectedDate=${selectedDate.toString()}&count=${count}&selectedSlotId=${selectedSlot._id}`
                      )
                    }
                    style={{ padding: '14px 16px', borderRadius: 999, fontSize: 18 }}
                  >
                    Book now
                  </button>
                </div>

                <div className="mt-4">
                  <div>
                    <div className={styles.rentBookingPolicyLink} onClick={() => setPaymentPolicyPopupOpen(true)}>
                      Payment Policy
                    </div>
                    <CommonModal
                      open={paymentPolicyPopupOpen}
                      close={() => setPaymentPolicyPopupOpen(false)}
                      title="Payment Policy"
                      htmlData={content && content[footerLinks.rent.paymentPolicy]?.pageContent}
                    />
                  </div>

                  <div className="mt-3">
                    <div className={styles.rentBookingPolicyLink} onClick={() => setCancellationPolicyPopupOpen(true)}>
                      Cancellation Policy
                    </div>
                    <CommonModal
                      open={cancellationPolicyPopupOpen}
                      close={() => setCancellationPolicyPopupOpen(false)}
                      title="Cancellation Policy"
                      htmlData={content && content[footerLinks.rent.cancellationPolicy]?.pageContent}
                    />
                  </div>
                </div>
              </>
            ) : (
              <p className="text-center">All trips for this boat or watercraft are full.</p>
            )}
          </div>
        </div>
      </div>

      {createRentBookingLoading && <Loader isPageLoader />}
    </>
  )
}

const mapStateToProps = state => ({
  isAuthorized: state.loginReducer.isAuthorized,
  currentUser: state.loginReducer && state.loginReducer.currentUser,
  rentPages: state.pageInfoByTypeReducer?.rentPages,
  rentPagesSuccess: state.pageInfoByTypeReducer?.rentPagesSuccess,
  rentPagesFailure: state.pageInfoByTypeReducer?.rentPagesFailure,
  isLoading: state.boatRentReducer && state.boatRentReducer.isLoading,
  editRentBoatSuccess: state.boatRentReducer && state.boatRentReducer.editRentBoatSuccess,
  boatRentInnerData: state.boatRentReducer && state.boatRentReducer.boatRentInnerData,
  commissions: state.salesEngineReducer.commissions,
  countryDetails: state.dashboardReducer.countryDetails,
})

const mapDispatchToProps = dispatch => ({
  getRentPages: () => dispatch(getRentPages()),
  clearRentPagesFlag: () => dispatch(clearRentPagesFlag()),
  clearBoatRentFlag: () => dispatch(clearBoatRentFlag()),
  getPaymentReceipt: data => dispatch(getPaymentReceipt(data)),
  getCommissions: data => dispatch(getCommissions(data)),
  getCommissions_clear: data => dispatch(getCommissions_clear(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingAdPage)
