import React, { Component } from 'react'
import { userRoles, userRoleTypes } from '../../util/enums/enums'

export const UserPersonalInfo = props => {
  const { role, isVerified, reviews, total, totalArticles, totalBoatShows, horizontal } = props
  const moduleText = getText(role)

  return (
    <div
      className={
        'user-profile-info-top-space user-profile-info-top-space-up h-100 justify-content-around' +
        (horizontal ? ' d-flex' : ' grid grid-cols-2')
      }
      style={horizontal ? {} : { gap: 12 }}
    >
      {moduleText && (
        <div className="d-flex align-items-center doc--verification--icon--text">
          <h5
            className="py-1 font-bold rentInner-userTextH4 mb-0 user-profile-desc font-16 color-black text-center"
            style={{ flex: 1 }}
          >
            <div>
              <img
                loading="lazy"
                src={require('../../components/userProfile/image/boat.svg')}
                className="icon-25 mr-1 filter-black"
                alt="Boat"
              />{' '}
              {total}
            </div>
            <div className="mt-1">{moduleText.toLocaleLowerCase().includes('marina') ? 'Marinas' : moduleText}</div>
          </h5>
        </div>
      )}

      {/* <div className="mb-15 d-flex align-items-center doc--verification--icon--text">
        <img
          src={
            isVerified
              ? require('../../components/userProfile/image/verified.png')
              : require('../../components/userProfile/image/cross-icon.svg')
          }
          className="icon-25 mr-2"
          alt=""
        />
        <h5 className="font-bold rentInner-userTextH4 mb-0 user-profile-desc font-16">
          {isVerified ? 'Verified' : 'Not Verified'}
        </h5>
      </div> */}

      {role?.aliasName !== userRoles.MEMBER && (
        <>
          {horizontal && <div style={{ border: '1px solid #f4f4f4' }} />}

          <div className="d-flex align-items-center doc--verification--icon--text">
            <h5
              className="py-1 font-bold rentInner-userTextH4 mb-0 user-profile-desc font-16 color-black text-center"
              style={{ flex: 1 }}
            >
              <div>
                <img
                  loading="lazy"
                  src={require('../../components/userProfile/image/review.svg')}
                  className="icon-25 mr-1 filter-black"
                  alt="Review"
                />{' '}
                {reviews && reviews.reviews && reviews.reviews.length}
              </div>
              <div className="mt-1">Reviews</div>
            </h5>
          </div>
        </>
      )}

      {(horizontal || moduleText) && <div style={{ border: '1px solid #f4f4f4' }} />}
      {!horizontal && moduleText && <div style={{ border: '1px solid #f4f4f4' }} />}

      <div className="d-flex align-items-center doc--verification--icon--text">
        {/* <i className="fas fa-file-lines mr-2"></i> */}
        <h5
          className="py-1 font-bold rentInner-userTextH4 mb-0 user-profile-desc font-16 color-black text-center"
          style={{ flex: 1 }}
        >
          <div>
            <img
              loading="lazy"
              src={require('../../components/userProfile/image/article.svg')}
              className="icon-25 mr-1 filter-black"
              alt="Review"
            />{' '}
            {totalArticles}
          </div>
          <div className="mt-1">Articles</div>
        </h5>
      </div>

      {!horizontal && !moduleText && <div style={{ border: '1px solid #f4f4f4' }} />}

      {role?.aliasName !== userRoles.MEMBER && (
        <>
          {(horizontal || !moduleText) && <div style={{ border: '1px solid #f4f4f4' }} />}

          <div className="d-flex align-items-center doc--verification--icon--text">
            <h5
              className="py-1 font-bold rentInner-userTextH4 mb-0 user-profile-desc font-16 color-black text-center"
              style={{ flex: 1 }}
            >
              <div>
                <img
                  loading="lazy"
                  src={require('../../components/userProfile/image/boat-show.svg')}
                  className="icon-25 mr-1 filter-black"
                  alt="Boat"
                />{' '}
                {totalBoatShows}
              </div>
              <div className="mt-1">Boat shows</div>
            </h5>
          </div>
        </>
      )}
    </div>
  )
}

const getText = role => {
  if (role?.type === userRoleTypes.SELLER) {
    return 'Boats'
  } else if (role?.aliasName === userRoles.SURVEYOR) {
    return 'Branches'
  } else if (role?.aliasName === userRoles.MEMBER) {
    return 'Boat Shows'
  } else if (role?.aliasName === userRoles.MARINA_AND_STORAGE) {
    return ' Marina & Storages'
  } else if (role?.aliasName === userRoles.RENT_AND_CHARTER) {
    return 'Rentals'
  } else {
    return ''
  }
}
