import React, { useEffect, useState } from 'react'

import { SkeletonGridStyle } from '../styleComponent/styleComponent'
import { dimension } from '../../util/enums/enums'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { SkeletonComponent } from './SkeletonComponent'

export const SkeletonGrid = ({ itemsLength }) => {
  const [ratioObject, setRatioObject] = useState(dimension.homeRecommended.width)

  const [height, setHeight] = useState(dimension.homeRecommended.height)

  const getWidth = () => {
    const elementWidth = getRatioZoomResponsive(dimension, 'homeRecommended', '.skeleton--grid')
    const elementHeight = getHeightZoomResponsive(dimension, 'homeRecommended', '.skeleton--grid')

    setRatioObject(elementWidth)
    setHeight(elementHeight)
  }

  useEffect(() => {
    getWidth()
  }, [])

  return (
    <div className="skeleton--grid">
      {[...Array(itemsLength)].map((_, index) => {
        return (
          <SkeletonGridStyle key={index} className="skeleton--grid-item" margin={ratioObject.margin}>
            <SkeletonComponent className="skeleton--grid-image" width={ratioObject.width} height={height} />
            <SkeletonComponent className="skeleton--grid-title" width={ratioObject.width} height={20} />
            <SkeletonComponent className="skeleton--grid-address" width={ratioObject.width} height={40} />
            <SkeletonComponent className="skeleton--grid-rate" width={ratioObject.width} height={20} />
          </SkeletonGridStyle>
        )
      })}
    </div>
  )
}

SkeletonGrid.defualtProps = {
  itemsLength: 4,
}
