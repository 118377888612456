import { MessageType } from "graphql-ws";
import {
  GET_USERS_LIST,
  GET_CHAT_LIST,
  GET_CHAT_ME,
  CREATE_CHAT_BY_ID,
  CREATE_MESSAGE_BY_CHAT_ID,
  GET_MESSAGE_BY_CHAT_ID,
  SET_MESSAGE,
  SET_SELECT_USER,
  GET_NEW_MESSAGE_BY_CHAT_ID,
  GET_NEW_MESSAGE_BY_CHAT_ID_SUCCESS,
  READ_MESSAGE_BY_CHAT_ID,
  DELETE_SINGLE_USER_CHAT,
  DELETE_SINGLE_MESSAGE_CHAT,
  GET_NEW_MESSAGE_BY_CHAT_ID_ME_SUCCESS,
  CLEAR_CHAT_THREAD,
  CHAT_IMAGES_UPLOADED,
  CHAT_VIDEO_UPLOADED_FAILD,
  START_MESSAGE_BY_CHAT_ID,
  CLEAR_CHAT_WHILE_LOG_OUT,
  CLEAR_CHAT_CREATED_CHAT,
  SET_ONLINE_USER,
  SET_GROUP_CHAT_FLAG,
  SET_SELECTED_GROUP_IDS,
  SET_GROUP_NAME_SCREEN,
  SET_USER_LIST_PAGE,
  BLOCK_USER,
  EDIT_GROUP_SCREEN,
  ADD_OR_REMOVE_USERS_FROM_GROUP,
  GET_FRIEND_USERS_LIST,
  BACK_TO_CHAT_LIST,
  CHAT_TOGGLE,
  INITIAL_CHAT_ID,
  SET_MODAL_VIEW,
  SET_FIRST_TIME_CHAT,
  CLEAR_USER_SEARCH,
  SET_TOGGLE_HEADER_MANAGE,
  SET_TEXT_AREA_HEIGHT,
  GET_MESSAGE_BY_CHAT_ID_SUCCESS,
  ADD_MESSAGE_BY_CHAT_ID,
  SET_READ_MESSAGE,
  SET_DELIVERED_MESSAGE,
  SET_UNREAD_COUNT,
  SET_USER,
  IS_TEXT_FALSE,
  UPDATE_MESSAGE_ID,
  SET_LASTACTIVE_TIME,
  UPDATE_CHAT,
  SET_FIRSTTIMECREATECHATIDNULL,
  SET_FRIEND_LIST,
  SET_CHAT_LIST,
  GET_CHAT_USERS,
  SET_SELECT_USER_AFTER_ADDORREMOVE,
} from "../actionTypes";

export const getUserListsByCurrentUser = (payload) => ({
  type: GET_USERS_LIST,
  payload: {appUserId:'', ...payload},
})
export const getFriendUsersByCurrentUser = (payload) => ({
  type: GET_FRIEND_USERS_LIST,
  payload,
})

export const setFriendList = (payload)=>({
type:SET_FRIEND_LIST,
payload
})

export const setChatList = (payload)=>({
  type:SET_CHAT_LIST,
  payload
})

export const getChatListsByCurrentUser = (payload) => ({
  type: GET_CHAT_LIST,
  payload,
})
export const getChatMe = () => ({
  type: GET_CHAT_ME,
})
export const createChatById = (payload) => ({
  type: CREATE_CHAT_BY_ID,
  payload,
})

export const setInitialChat = (payload) => ({
  type: INITIAL_CHAT_ID,
  payload,
})
/**
 * 
 * @param {chatId, text image, audioVideo, meta, referenceMsgId} payload 
 * @returns 
 */
export const createMessageByChatId = (payload) => ({
  type: CREATE_MESSAGE_BY_CHAT_ID,
  payload,
})
export const addOrRemoveUsersFromChat = (payload) => ({
  type: ADD_OR_REMOVE_USERS_FROM_GROUP,
  payload,
})
export const updateChat=(payload)=>({
  type:UPDATE_CHAT,
  payload
})
export const getMessageByChatId = (payload) => ({
  type: GET_MESSAGE_BY_CHAT_ID,
  payload,
})

export const addMessageByChatId = (payload)=>{
  const {chatId,...rest}=payload;
  const newPayload = {...rest}
  return {
  type: ADD_MESSAGE_BY_CHAT_ID,
  payload: newPayload
}
}

export const addIdInMessage = (payload)=>({
  type:UPDATE_MESSAGE_ID,
  payload
})

export const clearUserSearch = () => ({
  type: CLEAR_USER_SEARCH,
})
export const getChatUsers = (payload)=>({
  type:GET_CHAT_USERS,
  payload
})
export const clearChatThreadIfNotChatId = (payload) => ({
  type: CLEAR_CHAT_THREAD,
  payload,
})
export const getNewMessageByChatId = (payload) => ({
  type: GET_NEW_MESSAGE_BY_CHAT_ID,
  payload,
})
export const setNewMessageByChatIdME = (payload) => ({
  type: GET_NEW_MESSAGE_BY_CHAT_ID_ME_SUCCESS,
  payload,
})
export const setNewMessageByChatId = (payload) => ({
  type: GET_NEW_MESSAGE_BY_CHAT_ID_SUCCESS,
  payload,
})
export const setLastActiveTime =(payload)=>({
  type:SET_LASTACTIVE_TIME,
  payload,
})

export const setAddRemoveUser = (payload)=>({
  type:SET_SELECT_USER_AFTER_ADDORREMOVE,
  payload
})

export const startMessageByChatId = () => ({
  type: START_MESSAGE_BY_CHAT_ID,
})
export const setSelectUser = (payload) => ({
  type: SET_SELECT_USER,
  payload,
})
export const setMessagePayload = (payload) => ({
  type: SET_MESSAGE,
  payload,
})
export const setTextareaHeight = (payload) => ({
  type: SET_TEXT_AREA_HEIGHT,
  payload,
})
export const readNewMessageByChatId = (payload) => ({
  type: READ_MESSAGE_BY_CHAT_ID,
  payload,
})
export const deleteChatByID = (payload) =>({
  type: DELETE_SINGLE_USER_CHAT,
  payload,
})
export const setUserList = (payload)=>({
  type:SET_USER,
  payload
})
export const clearChatById = (payload)=>({
  type:CLEAR_CHAT_THREAD,
  payload
})
export const deleteMessageChatByIDAction = (payload) =>({
  type: DELETE_SINGLE_MESSAGE_CHAT,
  payload,
})
export const setDeliveredMessage=(payload)=>{
  return {
    type:SET_DELIVERED_MESSAGE,
    payload
  }
}
export const setReadMessage = (payload)=>({
    type:SET_READ_MESSAGE,
    payload,
  }
)
export const setUnreadCount = (payload)=>({
  type:SET_UNREAD_COUNT,
  payload
})
export const blockUserByID = (payload) =>({
  type: BLOCK_USER,
  payload,
})
export const chatUploadImages = () => ({
  type: CHAT_IMAGES_UPLOADED
})

export const isTextFalse = ()=>({
  type: IS_TEXT_FALSE
})
export const chatVideoUploadFail = () => ({
  type: CHAT_VIDEO_UPLOADED_FAILD
})

export const clearChatWhileLogout = () => ({
  type: CLEAR_CHAT_WHILE_LOG_OUT
})

export const clearCratedChat = () => ({
  type: CLEAR_CHAT_CREATED_CHAT
})

export const setOnlineOfflineUser = (payload) => ({
  type: SET_ONLINE_USER,
  payload,
})

export const setGroupChatFlag = (payload) => ({
  type: SET_GROUP_CHAT_FLAG,
  payload,
})

export const setSelectedGroupIds = (payload) => ({
  type: SET_SELECTED_GROUP_IDS,
  payload,
})

export const setFirsttimechatidNull=()=>(
  {
  type:SET_FIRSTTIMECREATECHATIDNULL,

  }
)

export const setIsAddGroupName = (payload) => ({
  type: SET_GROUP_NAME_SCREEN,
  payload,
})

export const setUserLisPage = (payload) => ({
  type: SET_USER_LIST_PAGE,
  payload,
})

export const editGroupScreen = (payload) => ({
  type: EDIT_GROUP_SCREEN,
  payload,
})
export const backToChatList = (payload) => ({
  type: BACK_TO_CHAT_LIST,
  payload,
})

export const chatToggle = (payload) => ({
  type: CHAT_TOGGLE,
  payload,
})
export const setImageModalView = (payload) => ({
  type: SET_MODAL_VIEW,
  payload,
})
export const setIsFirstTimeChat = (payload) => ({
  type: SET_FIRST_TIME_CHAT,
  payload,
})
export const setIsToggleManage = (payload) => ({
  type: SET_TOGGLE_HEADER_MANAGE,
  payload,
})