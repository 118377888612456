import React, { Component } from 'react'
import Rating from 'react-rating'

import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

import './rating.scss'

class RatingComponent extends Component {
  render() {
    const { rating, className } = this.props
    return (
      <Rating
        className={className}
        initialRating={rating}
        fullSymbol={<StarIcon />}
        emptySymbol={<StarBorderIcon />}
        placeholderSymbol={<StarBorderIcon />}
        readonly
      />
    )
  }
}

export default RatingComponent

RatingComponent.defaultProps = {
  className: '',
  rating: 0,
}
