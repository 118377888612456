import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import { BoatMediaPost } from './boatMediaPost'

export class BoatMediaPosts extends React.Component {
  render() {
    const { xs, sm, boatMediaPosts, dimension, itemsLength } = this.props
    return (
      <>
        <Grid container>
          {boatMediaPosts && boatMediaPosts.length ? (
            boatMediaPosts.map((value, index) => {
              return (
                <Grid key={value.id} item xs={xs} sm={sm}>
                  <BoatMediaPost value={value} xs={xs} sm={sm} dimension={dimension} />
                </Grid>
              )
            })
          ) : (
            <Grid item xs={12}>
              {' '}
              No Record Found{' '}
            </Grid>
          )}
        </Grid>
      </>
    )
  }
}
BoatMediaPosts.defaultProps = {
  dimension: {
    width: 380,
    height: 280,
  },
}
