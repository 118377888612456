import React, { useState, useEffect } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { requireMessage } from '../../helpers/string'
import { Card, CardContent, Grid, Box, Button, makeStyles, Backdrop, Modal, Fade } from '@material-ui/core'
import { Field } from '../ws/Field'
import { cancelHandler } from '../../helpers/routeHelper'
import ErrorFocus from '../ws/ErrorFocus'
import { CommonModal } from '../modal/commonModal'
import { ButtonComponent } from '../form/Button'
import '../../assets/css/component/shipmentDocument.scss'
import { MEDIA_NAME } from '../../util/enums/enums'
import { getAddMedia } from '../../helpers/s3FileUpload'
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  link: {
    margin: theme.spacing(3),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export const ShipmentDocument = props => {
  const classes = useStyles()

  const { open, closeModal, value, submitDocument, getDocumentUrl } = props

  const [initValue, setInitValue] = useState({
    billOfLoading: '',
    certificateOfOrigin: '',
    insuranceCertificate: '',
    receivedForShipment: '',
    invoiceOfShipmentPayment: '',
    otherDocument: '',
    addMedia: {
      billOfLoading: [],
      certificateOfOrigin: [],
      insuranceCertificate: [],
      receivedForShipment: [],
      invoiceOfShipmentPayment: [],
      otherDocument: [],
    },
    removeMedia: [],
  })

  useEffect(() => {
    value && setInitValue(value)
  }, [value])

  return (
    <CommonModal title="Shipment Document" open={open} close={closeModal} className="shipment--document--upload--modal">
      <Formik
        initialValues={initValue}
        onSubmit={values => {
          const {
            billOfLoading,
            certificateOfOrigin,
            insuranceCertificate,
            receivedForShipment,
            invoiceOfShipmentPayment,
            otherDocument,
            ...submitValues
          } = values
          submitValues.addMedia = getAddMedia(submitValues.addMedia)
          submitDocument(submitValues)
        }}
        validationSchema={Yup.object().shape({
          billOfLoading: Yup.string().required(requireMessage('Bill of lading')),
          certificateOfOrigin: Yup.string().required(requireMessage('Upload certificate of origin')),
          insuranceCertificate: Yup.string().required(requireMessage('Upload insurance certificate')),
          receivedForShipment: Yup.string().required(requireMessage('Upload received for shipment')),
          invoiceOfShipmentPayment: Yup.string().required(requireMessage('Upload invoice of shipment payment')),
        })}
        render={({ values, setFieldValue, handleSubmit }) => (
          <Form className="shipment-image-popup-width">
            <ErrorFocus />
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Field
                  label="Bill Of Lading"
                  name="billOfLoading"
                  type="shipment-document"
                  value={values.billOfLoading}
                  addMedia={values.addMedia}
                  deleteIds={values.removeMedia}
                  className="form-control"
                  onChangeText={setFieldValue}
                  mediaName={MEDIA_NAME.BILLOFLOADING}
                  getDocumentUrl={getDocumentUrl}
                  required
                />

                <ErrorMessage component="div" name="billOfLoading" className="error-message" />
              </Grid>
              <Grid item sm={6}>
                <Field
                  label="Upload Certificate Of Origin"
                  name="certificateOfOrigin"
                  type="shipment-document"
                  value={values.certificateOfOrigin}
                  addMedia={values.addMedia}
                  deleteIds={values.removeMedia}
                  className="form-control"
                  mediaName={MEDIA_NAME.CERTIFICATEOFORIGIN}
                  onChangeText={setFieldValue}
                  getDocumentUrl={getDocumentUrl}
                  required
                />
                <ErrorMessage component="div" name="certificateOfOrigin" className="error-message" />
              </Grid>
              <Grid item sm={6}>
                <Field
                  label="Upload Insurance Certificate"
                  name="insuranceCertificate"
                  type="shipment-document"
                  value={values.insuranceCertificate}
                  mediaName={MEDIA_NAME.INSURANCECERTIFICATE}
                  addMedia={values.addMedia}
                  deleteIds={values.removeMedia}
                  className="form-control"
                  onChangeText={setFieldValue}
                  getDocumentUrl={getDocumentUrl}
                  required
                />
                <ErrorMessage component="div" name="insuranceCertificate" className="error-message" />
              </Grid>
              <Grid item sm={6}>
                <Field
                  label="Upload Received For Shipment"
                  name="receivedForShipment"
                  type="shipment-document"
                  value={values.receivedForShipment}
                  mediaName={MEDIA_NAME.RECEIVEDFROMSHIPMENT}
                  addMedia={values.addMedia}
                  deleteIds={values.removeMedia}
                  className="form-control"
                  getDocumentUrl={getDocumentUrl}
                  onChangeText={setFieldValue}
                  required
                />
                <ErrorMessage component="div" name="receivedForShipment" className="error-message" />
              </Grid>
              <Grid item sm={6}>
                <Field
                  label="Upload Invoice Of Shipment Payment"
                  name="invoiceOfShipmentPayment"
                  type="shipment-document"
                  addMedia={values.addMedia}
                  deleteIds={values.removeMedia}
                  mediaName={MEDIA_NAME.INVOICEOFSHIPMENTPAYMENT}
                  value={values.invoiceOfShipmentPayment}
                  className="form-control"
                  onChangeText={setFieldValue}
                  getDocumentUrl={getDocumentUrl}
                  required
                />
                <ErrorMessage component="div" name="invoiceOfShipmentPayment" className="error-message" />
              </Grid>
              <Grid item sm={6}>
                <Field
                  label="Upload Other Document If Any"
                  name="otherDocument"
                  type="shipment-document"
                  value={values.otherDocument}
                  className="form-control"
                  mediaName={MEDIA_NAME.SHIPMENTDOCUMENTOTHERDOCUMENT}
                  getDocumentUrl={getDocumentUrl}
                  addMedia={values.addMedia}
                  deleteIds={values.removeMedia}
                  onChangeText={setFieldValue}
                />
              </Grid>
              <div className="d-flex justify-content-center w-100 shipper--btn">
                <ButtonComponent onClick={handleSubmit}>Submit</ButtonComponent>

                <ButtonComponent color="cancel" onClick={closeModal}>
                  Cancel
                </ButtonComponent>
              </div>
            </Grid>
          </Form>
        )}
      />
    </CommonModal>
  )
}
