/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getRecommendedTrips,
  getRentBoatMostPopularTripAction,
  getRentBoatByTripAction,
  getBoatRentLookUps,
} from '../../../redux/actions'
import { pagination, showAll, lookupTypes, rentBoatTrip, rentBoatTripForCard } from '../../../util/enums/enums'
import UserContext from '../../../UserContext'
import BoatListingsWithMap from '../../../components/gridComponents/BoatListingsWithMap'
import { shareDetailsWithUsers } from '../../../redux/actions/shareAction'

class RentTypeBoat extends Component {
  state = {
    showMap: false,
    type: '',
    action: null,
    isFlag: true,
    isAction: true,
  }

  static contextType = UserContext
  static getDerivedStateFromProps(nextProps, prevState) {
    const { match, getBoatRentLookUps, lookUpSuccess, getRentBoatByTripAction, country } = nextProps

    const { isFlag, type, isAction } = prevState
    const { params } = match && match

    if (isFlag && country) {
      getBoatRentLookUps(lookupTypes.TRIP_TYPE)
      return { isFlag: false }
    }

    if (type && lookUpSuccess && isAction) {
      switch (type) {
        case showAll.sharedTrip:
          getRentBoatByTripAction({
            page: pagination.PAGE_COUNT,
            limit: pagination.PAGE_RECORD_LIMIT,
            country,
            trip: rentBoatTrip.SHARED_TRIP,
          })

          return {
            name: 'Rent Shared Trip',
            isAction: false,
          }

        case showAll.privateTrip:
          getRentBoatByTripAction({
            page: pagination.PAGE_COUNT,
            limit: pagination.PAGE_RECORD_LIMIT,
            country,
            trip: rentBoatTrip.PRIVATE_TRIP,
          })

          return {
            name: 'Rent Private Trip',
            isAction: false,
          }

        case showAll.tripsPerHour:
          getRentBoatByTripAction({
            page: pagination.PAGE_COUNT,
            limit: pagination.PAGE_RECORD_LIMIT,
            country,
            trip: rentBoatTrip.RENT_PER_HOUR,
          })

          return {
            name: 'Rent Trips Per Hour',
            isAction: false,
          }
      }
    }

    if (params && params.type) {
      return {
        type: params.type,
      }
    }

    return null
  }

  fetchValue = () => {
    const { type } = this.state
    const { country } = this.props

    switch (type) {
      case showAll.sharedTrip:
        return {
          country,
          trip: rentBoatTripForCard.shared,
        }

      case showAll.privateTrip:
        return {
          country,
          trip: rentBoatTripForCard.private,
        }

      case showAll.tripsPerHour:
        return {
          country,
          trip: rentBoatTripForCard.rentPerHour,
        }

      default:
        return
    }
  }

  fetchTotal = () => {
    const { type } = this.state
    const { privateTripsTotal, rentSharedTripsTotal, tripsPerHourTotal } = this.props
    if (type) {
      switch (type) {
        case showAll.privateTrip:
          return privateTripsTotal
        case showAll.sharedTrip:
          return rentSharedTripsTotal
        case showAll.tripsPerHour:
          return tripsPerHourTotal
        default:
          return
      }
    }
  }

  fetchData = () => {
    const { type } = this.state
    const { privateTrips, rentSharedTrips, recommendedTripsPerHour } = this.props
    switch (type) {
      case showAll.privateTrip:
        return privateTrips

      case showAll.sharedTrip:
        return rentSharedTrips

      case showAll.tripsPerHour:
        return recommendedTripsPerHour

      default:
        return
    }
  }

  render() {
    const { name } = this.state
    const { isPageLoader } = this.props

    const { getRentBoatByTripAction } = this.props
    const argument = this.fetchValue()

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        boatsTypeCount={this.fetchTotal() || 0}
        boatsType={name}
        isRent
        boats={this.fetchData()}
        action={getRentBoatByTripAction}
        value={argument}
      />
    )
  }
}

const mapStateToProps = state => ({
  recommendedTrips: state.rentReducer.recommendedTrips,
  totalRecommendedTrips: state.rentReducer.totalRecommendedTrips,
  privateTrips: state.rentReducer.privateTrips,
  rentMostPopularTrips: state.rentReducer && state.rentReducer.rentMostPopularTrips,
  rentMostPopularTripsTotal: state.rentReducer && state.rentReducer.rentMostPopularTripsTotal,
  rentSharedTrips: state.rentReducer && state.rentReducer.rentSharedTrips,
  recommendedTripsPerHour: state.rentReducer.recommendedTripsPerHour,
  tripsPerHourTotal: state.rentReducer.tripsPerHourTotal,
  privateTripsTotal: state.rentReducer.privateTripsTotal,
  rentSharedTripsTotal: state.rentReducer.rentSharedTripsTotal,
  tripLists: state.boatRentReducer && state.boatRentReducer.boatRentLookUps && state.boatRentReducer.boatRentLookUps.trip,
  lookUpSuccess: state.boatRentReducer && state.boatRentReducer.lookUpSuccess,
  country: state.loginReducer && state.loginReducer.currentLocation,
  isPageLoader: state.rentReducer && state.rentReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  getRecommendedTrips: data => dispatch(getRecommendedTrips(data)),
  getRentBoatMostPopularTripAction: data => dispatch(getRentBoatMostPopularTripAction(data)),
  getRentBoatByTripAction: data => dispatch(getRentBoatByTripAction(data)),
  getBoatRentLookUps: data => dispatch(getBoatRentLookUps(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RentTypeBoat)
