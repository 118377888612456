import React from 'react'
import cn from 'classnames'

/**
 * @param {React.SVGProps<SVGSVGElement>} props
 */
export default function IconArticle(props) {
  return (
    <svg
      {...props}
      className={cn('as-media-icon', props.className)}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="currentColor"
    >
      <path d="M5.59999 16.1521H14C14.6825 16.15 14.6895 15.1049 14 15.1021H5.59999C4.91469 15.1049 4.91189 16.1493 5.59999 16.1521Z" />
      <path d="M5.60021 11.9519H12.6002C13.2834 11.9498 13.289 10.9047 12.6002 10.9019H5.60021C4.91491 10.9047 4.91281 11.9491 5.60021 11.9519Z" />
      <path d="M9.80021 7.75215C10.4855 7.74935 10.4876 6.70495 9.80021 6.70215C9.57131 6.70215 5.65901 6.70215 5.60021 6.70215C4.91491 6.70495 4.91281 7.74935 5.60021 7.75215C5.65061 7.75075 9.58041 7.75355 9.80021 7.75215Z" />
      <path d="M19.7969 6.64392C18.5747 3.92092 16.236 1.72922 13.5935 0.392217C12.5946 0.0289166 11.6342 0.231217 10.5002 0.168917C8.26368 0.148617 6.02788 0.256417 3.80398 0.492317C2.56078 0.635817 1.58288 1.62072 1.44708 2.86392C1.02568 6.88052 1.03758 11.4662 1.22658 15.3729C1.24198 15.6515 1.47158 15.8692 1.75018 15.8699C2.05118 15.8713 2.29198 15.6165 2.27518 15.3162C2.08828 11.4627 2.07778 6.94562 2.49008 2.98362C2.57128 2.22482 3.16768 1.62282 3.92578 1.53392C6.94908 1.28122 10.1971 1.00402 13.1791 1.35542C15.4282 2.49432 17.3917 4.33042 18.5866 6.55642C17.3826 6.61032 16.1765 6.56762 14.9802 6.41782C13.4381 6.26592 13.4115 4.70212 13.359 3.46522C13.3394 3.17542 13.0888 2.95562 12.799 2.97522C11.9065 3.12432 12.4882 4.45362 12.4315 5.02762C12.7934 8.10202 16.5111 7.57142 18.8022 7.61692C18.8778 10.0746 18.8456 13.2029 18.721 15.4422C18.5061 17.0123 18.9856 19.1487 17.0571 19.4546C12.7031 19.8928 8.29798 19.8928 3.94398 19.4539C3.17538 19.3755 2.56848 18.7679 2.49008 17.9993C2.46488 17.7018 2.19118 17.4841 1.89508 17.5254C1.60808 17.5653 1.40718 17.8299 1.44708 18.1176C1.57308 19.3741 2.56568 20.3688 3.82218 20.4976C8.25738 20.9442 12.7437 20.9456 17.1789 20.5004C19.7479 20.191 19.6492 17.6059 19.771 15.4961C19.8844 12.9278 19.9796 9.37672 19.7969 6.64392Z" />
    </svg>
  )
}
