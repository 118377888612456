import { put, takeLatest, all } from 'redux-saga/effects'

import { GET_ALL_DOWNLOADS_DATA, GET_ALL_DOWNLOADS_DATA_FAILURE, GET_ALL_DOWNLOADS_DATA_SUCCESS } from '../actionTypes'
import { query } from './apiHelper'
import { getAllDownloads } from '../../graphql/downloadsSchema'

function getAllDownloadsApi(data) {
  return query(getAllDownloads, data)
}

function* getAllDownloadsData(action) {
  try {
    const res = yield getAllDownloadsApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: GET_ALL_DOWNLOADS_DATA_FAILURE, e: res.errors })
    } else if (res.data) {
      yield put({ type: GET_ALL_DOWNLOADS_DATA_SUCCESS, payload: res.data.getAllDownloads })
    }
  } catch (e) {
    yield put({ type: GET_ALL_DOWNLOADS_DATA_FAILURE, e })
  }
}

function* getAllDownloadsSaga() {
  yield takeLatest(GET_ALL_DOWNLOADS_DATA, getAllDownloadsData)
}

export default function* downloadsSaga() {
  yield all([getAllDownloadsSaga()])
}
