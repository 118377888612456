/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { KeyboardArrowRight } from '@material-ui/icons'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Rating from 'react-rating'
import { isMobile } from 'react-device-detect'

import UserContext from '../../UserContext'
import InnerRattingProfile from '../staticComponent/innerRattingProfile'
import { displayDefaultReview } from '../../helpers/string'
import { pagination } from '../../util/enums/enums'
import { reviewType } from '../../util/utilFunctions'
import { getUserById, getItemsByUser } from '../../redux/actions'
import { createReview, getReviewByModuleId } from '../../redux/actions/ReviewAction'
import UserProfileAllReviews from '../gridComponents/userProfileAllReviews'
import { getUserAllArticle } from '../../redux/actions/articlesAction'
import { ReviewSection } from '../userProfile/reviewSection'
import InnerRatingModal from './InnerRatingModal'

import '../../components/home/icon.scss'
import '../userProfile/userProfile.scss'

const InnerRating = props => {
  const { match } = useContext(UserContext)

  const [ratingError, setRatingError] = useState(false)

  const [visible, setVisible] = useState(false)

  const [open, setOpen] = useState(false)

  const closeModal = () => {
    setOpen(!open)
  }

  const closeReviewModal = () => {
    setVisible(!visible)
  }

  const [review] = useState({
    rating: 0,
    reviews: '',
    reviewWithTypes: [],
    communication: 0,
    recommended: 0,
    organization: 0,
    goodValue: 0,
    goodQuality: 0,
    service: 0,
    servicesQuality: 0,
    surveyQuality: 0,
    valueForMoney: 0,
    safety: 0,
  })

  useEffect(() => {
    const { getUserById, getReviewByModuleId, getItemsByUser, getUserAllArticle, user } = props
    const { id } = user
    const { params } = match
    id && getUserById(id, true)
    id && getReviewByModuleId({ moduleId: id, userModuleId: params?.id })
    id && getItemsByUser({ userId: id })
    id &&
      getUserAllArticle({
        userId: id,
        page: pagination.PAGE_COUNT,
        limit: pagination.MARKET_PAGE_LIMIT,
      })
  }, [])

  useEffect(() => {
    const { user, match } = props
    const { id } = user
    const { params } = match || {}

    const { getReviewByModuleId, isReview } = props

    isReview && id && getReviewByModuleId({ moduleId: id, userModuleId: params?.id })
  }, [props.isReview])

  const { currentUser, reviews, isAddReview } = props
  const renderTypeWiseRatings = (values, setFieldValue) => {
    const { userProfileDetail, module } = props
    const ratingTypes =
      userProfileDetail && userProfileDetail.role ? reviewType(userProfileDetail.role) : reviewType(module?.seller?.role)

    return (
      <div className="ratingQuality width-100 d-flex justify-content-around flex-column">
        {ratingTypes &&
          ratingTypes.length > 0 &&
          ratingTypes.map(item => (
            <div className="d-flex">
              <span className="review-quality profile-add-review">{item.name}</span>
              <Rating
                className="rating-clr  profile-add-rating"
                initialRating={0}
                onClick={value => {
                  setFieldValue(item.key, value)
                }}
                emptySymbol={<StarBorderIcon />}
                placeholderSymbol={<StarIcon />}
                fullSymbol={<StarIcon />}
                placeholderRating={values[item.key]}
              />
            </div>
          ))}
      </div>
    )
  }

  return (
    <>
      {!isAddReview && <InnerRatingModal module={module} />}

      {reviews && reviews.reviews && (
        <div className="d-flex user--profile--review--section w-100 inner--review--show--section">
          {/* review left section start */}
          <div className="mb-18 user--added--review--section" style={{ margin: isMobile ? 15 : 0 }}>
            <div>
              {reviews.reviews.length > 0 && (
                <h4 className="color-black mb-4 font-16 user--profie--review--count">{reviews.reviews.length} reviews</h4>
              )}

              {reviews.reviews.slice(0, 3).map((item, index) => {
                return <ReviewSection key={item.id} review={item} isBorder={reviews.reviews.length - 1 === index} />
              })}

              <UserProfileAllReviews
                open={open}
                close={closeModal}
                reviews={reviews}
                title="All Reviews"
                className="notify-content-override user-profile--review"
              ></UserProfileAllReviews>

              {reviews.reviews.length > 3 && (
                <div className="mt-50">
                  <h2>
                    <Link
                      to={'#'}
                      className="show-link mb-0 text-decoration-unset hover-text-only show--all"
                      onClick={closeModal}
                    >
                      See all {reviews.reviews.length} reviews
                      <KeyboardArrowRight />
                    </Link>
                  </h2>
                </div>
              )}
            </div>
          </div>
          {/* review left section end */}
          {/* review right section start */}
          <div className="rentInnner-userCard-radius user--added--review--count--section" style={{ margin: isMobile ? 25 : 0 }}>
            <InnerRattingProfile
              iconColor="iconColor-boatInner"
              btnColor="boatInner-btnBg"
              btnBlue="boatInner-btnOrange"
              // moduleId={id}
              reviewUser={props.userProfileDetail}
              userId={currentUser.id}
              data={displayDefaultReview(reviews.averageRating)}
              reviews={reviews.reviews}
            />
          </div>
          {/* review right section end */}
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser || {},
  userProfileDetail: state.loginReducer.userProfileDetail || {},
  createSuccess: state.reviewReducer && state.reviewReducer.createSuccess,
  isReview: state.reviewReducer && state.reviewReducer.isReview,
  reviews: state.reviewReducer && state.reviewReducer.reviews,
  articles: state.articleReducer.articles,
  totalArticles: state.articleReducer.totalArticles,
})
const mapDispatchToProps = dispatch => ({
  getUserById: (data, userProfile) => dispatch(getUserById(data, userProfile)),
  getReviewByModuleId: data => dispatch(getReviewByModuleId(data)),
  getItemsByUser: data => dispatch(getItemsByUser(data)),
  getUserAllArticle: data => dispatch(getUserAllArticle(data)),
  createReview: data => dispatch(createReview(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InnerRating)
