import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Grid, Box } from '@material-ui/core'

import { Layout } from '../../components/layout/layout'
import ShowBoatCards from '../../components/gridComponents/showBoatCards'

import { getAllBoatShow, searchBoatShow } from '../../redux/actions/boatShowAction'
import { pagination, moduleCategory, mediaCategory, dimension } from '../../util/enums/enums'
import { getCategoryWiseVideos } from '../../redux/actions/VideoAction'
import UserContext from '../../UserContext'

import './boatShow.scss'
import { getModuleWiseBanners, notifyMe } from '../../redux/actions'
import CommonBanner from '../../components/mainBanner/commonBanner'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import ServicesMarinaStorage from '../../components/staticComponent/servicesMarinaStorage'
import BoatShowSearchCard from './boatShowSearchCard'
import { VideoComponent } from '../../components/videoComponent/videoComponent'
import { VideoModel } from '../../components/videoComponent/videoModel'
import '../../../src/styles/boatShowResponsive.scss'
import { objectToQuerystring } from '../../helpers/routeHelper'
import { SuccessNotify } from '../../helpers/notification'
import { clear_getStaticPages, getStaticPages } from '../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'
class BoatShow extends Component {
  state = {
    setVideoFlag: false,
    videoUrl: '',
    videoThumbnail: '',
    videoWidth: dimension.boatShowVideo.width,
    videoHeight: dimension.boatShowVideo.height,
  }

  static contextType = UserContext

  zoomWidth = () => {
    const videoWidth = getRatio(dimension, 'boatShowVideo', '.section-heading')

    const videoHeight = getHeightRatio(dimension, 'boatShowVideo', '.section-heading')

    this.setState({ videoWidth, videoHeight })
  }
  componentDidMount() {
    const { country } = this.context
    const { getAllBoatShow, getCategoryWiseVideos, getModuleWiseBanners } = this.props

    getModuleWiseBanners({
      type: mediaCategory.boatShow.type,
      fieldName: mediaCategory.boatShow.fieldName,
      isBanner: mediaCategory.boatShow.isBanner,
    })

    getCategoryWiseVideos({
      type: moduleCategory.BOAT_SHOW,
      metatype: 'video',
    })
    const input = {
      page: pagination.PAGE_COUNT,
      limit: pagination.MARKET_PAGE_LIMIT,
      country,
    }

    getAllBoatShow(input)
    this.zoomWidth()
    window.addEventListener('resize', this.zoomWidth)

    this.props.getStaticPages({ slug: 'boat-show-homepage-bottom-528' })
  }
  componentDidUpdate() {
    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.zoomWidth)
  }
  playVideo = (url, thumbnail) => {
    this.setState(prevState => ({
      setVideoFlag: !prevState.setVideoFlag,
      videoUrl: url,
      videoThumbnail: thumbnail,
    }))
  }

  closeVideo = () => {
    this.setState({ setVideoFlag: false })
  }

  searchBoatShowHandler = values => {
    const { history } = this.props
    const queryResult = objectToQuerystring(values)

    history.push(`/search-boat-show${queryResult && queryResult}`)
  }

  render() {
    const { allBoatShows, categoryVideos, boatShowBanner, notifyMe, notifyInput } = this.props
    const { setVideoFlag, videoUrl, videoThumbnail, videoWidth, videoHeight } = this.state

    return (
      <Layout isHeader="boat-show-logo" className="boatshow-layout-page">
        <div className="row width-100 ml-0">
          <div className="col hidden position-relative border-solid-gray pr-0 pl-0">
            {boatShowBanner && boatShowBanner.length > 0 && <CommonBanner data={boatShowBanner} />}
            <div className="boat-show-search-card">
              <BoatShowSearchCard search={this.searchBoatShowHandler} notifyMe={notifyMe} notifyInput={notifyInput} />
            </div>
          </div>
        </div>

        <div className="container100">
          <Grid container>
            {/* <Grid item xs={12} className="boat-media-parent-comp">
              <h1 className="r-h1 mb-0">
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  Explore AdamSea
                </Box>
              </h1>
              <ServicesMarinaStorage type="boatShow" className="mb-20" />
            </Grid> */}

            {allBoatShows && allBoatShows.length > 0 && (
              <Grid item xs={12} className=" boat-show-main-div">
                <ShowBoatCards isShowAll showLength={8} showBoatCards={allBoatShows} showAllText="boat shows" />
              </Grid>
            )}

            {categoryVideos && categoryVideos.length > 0 && (
              <Grid item xs={12}>
                <Box className="section-heading hide-card-homepage" fontSize={24} fontWeight={500} letterSpacing={0.5}></Box>

                <Grid item xs={12} className="video-section-bottom-space">
                  <Grid container spacing={2} className="video-component-grid-layout video-gapping-space-common">
                    {categoryVideos &&
                      categoryVideos.map((video, index) => {
                        if (index < 3) {
                          return (
                            <Fragment key={video.id}>
                              <VideoComponent
                                className="boat-show-video-section"
                                setVideoUrlHandler={thum =>
                                  this.playVideo(
                                    video?.url?.[0]?.url,
                                    video.thumbnail?.length > 0 ? video?.thumbnail?.[0]?.url : thum
                                  )
                                }
                                video={video}
                                videoWidth={videoWidth}
                                videoHeight={videoHeight}
                              />
                            </Fragment>
                          )
                        }
                      })}
                  </Grid>
                  <VideoModel
                    videoFlag={setVideoFlag}
                    videoUrl={videoUrl}
                    thumbnail={videoThumbnail}
                    closeVideo={this.closeVideo}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          {get(this.props.staticPages, 'data[0].pageContent', '') && (
            <div style={{ marginTop: 30, padding: '16px 24px', borderRadius: 8, background: '#dff0f2' }}>
              <div className="d-flex">
                <div style={{ flexShrink: 0 }}>
                  <img src={require('../../assets/images/BOAT_SHOW.svg')} alt="bell" height={28} style={{ height: 28 }} />
                </div>
                <div style={{ margin: 0, marginLeft: 20, fontSize: 16 }}>
                  {get(this.props.staticPages, 'data[0].pageContent', '')}
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  allBoatShows: state.boatShowReducer && state.boatShowReducer.allBoatShows,
  categoryVideos: state.videoReducer && state.videoReducer.categoryVideos,
  allBoatShowCount: state.boatShowReducer && state.boatShowReducer.allBoatShowCount,
  boatShowBanner: state.dashboardReducer && state.dashboardReducer[mediaCategory.boatShow.fieldName],
  notifyInput: state.dashboardReducer.notifyInput,
  staticPages: state.pageInfoByTypeReducer.staticPages,
})

const mapDispatchToProps = dispatch => ({
  getAllBoatShow: value => dispatch(getAllBoatShow(value)),
  getCategoryWiseVideos: data => dispatch(getCategoryWiseVideos(data)),
  getModuleWiseBanners: type => dispatch(getModuleWiseBanners(type)),
  notifyMe: data => dispatch(notifyMe(data)),
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatShow)
