import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getRecommendedTrips,
  getRentBoatMostPopularTripAction,
  getRentBoatByTripAction,
  getBoatRentLookUps,
} from '../../../redux/actions'
import { showAll } from '../../../util/enums/enums'
import UserContext from '../../../UserContext'
import BoatListingsWithMap from '../../../components/gridComponents/BoatListingsWithMap'
import { shareDetailsWithUsers } from '../../../redux/actions/shareAction'

class Recommended extends Component {
  state = {
    type: '',
    isAction: true,
    name: '',
  }

  static contextType = UserContext
  static getDerivedStateFromProps(nextProps, prevState) {
    const { match, getRentBoatMostPopularTripAction, getRecommendedTrips } = nextProps

    const { isAction } = prevState
    const { params } = match && match

    if (params && params.type === showAll.recommended && isAction) {
      return {
        isAction: false,
        action: getRecommendedTrips,
        name: 'Rent Recommended',
      }
    }

    if (params && params.type === showAll.mostPopular && isAction) {
      return {
        isAction: false,
        action: getRentBoatMostPopularTripAction,
        name: 'Rent Most Popular',
      }
    }

    if (params && params.type) {
      return {
        type: params.type,
      }
    }
    return null
  }

  fetchData = () => {
    const { type } = this.state

    const { recommendedTrips, rentMostPopularTrips } = this.props

    switch (type) {
      case showAll.recommended:
        return recommendedTrips

      case showAll.mostPopular:
        return rentMostPopularTrips

      default:
        return
    }
  }

  render() {
    const { name, action } = this.state

    const { totalRecommendedTrips, isPageLoader, rentMostPopularTripsTotal } = this.props
    const { country } = this.context
    const totalRecords = totalRecommendedTrips ? totalRecommendedTrips : rentMostPopularTripsTotal
    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        boatsTypeCount={totalRecords || 0}
        boatsType={name}
        isRent
        boats={this.fetchData()}
        action={action}
        value={{ country }}
      />
    )
  }
}

const mapStateToProps = state => ({
  recommendedTrips: state.rentReducer.recommendedTrips,
  totalRecommendedTrips: state.rentReducer.totalRecommendedTrips,

  rentMostPopularTrips: state.rentReducer && state.rentReducer.rentMostPopularTrips,
  rentMostPopularTripsTotal: state.rentReducer && state.rentReducer.rentMostPopularTripsTotal,

  tripLists: state.boatRentReducer && state.boatRentReducer.boatRentLookUps && state.boatRentReducer.boatRentLookUps.trip,
  lookUpSuccess: state.boatRentReducer && state.boatRentReducer.lookUpSuccess,
  country: state.loginReducer && state.loginReducer.currentLocation,
  isPageLoader: state.rentReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  getRecommendedTrips: data => dispatch(getRecommendedTrips(data)),
  getRentBoatMostPopularTripAction: data => dispatch(getRentBoatMostPopularTripAction(data)),
  getRentBoatByTripAction: data => dispatch(getRentBoatByTripAction(data)),
  getBoatRentLookUps: data => dispatch(getBoatRentLookUps(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Recommended)
