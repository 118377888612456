import React, { Component } from 'react'
import { connect } from 'react-redux'

import { DashboardLayout, TableCard } from '../../../components'
import AgentSalesEngine from './AgentSalesEngine'
import { clearSearchDashboard } from '../../../redux/actions'

import '../../../styles/manageDashboardTableResponsive.scss'
import { getDealersSalesEngine, getSingleSalesEngine } from '../../../redux/actions'
import { ButtonComponent } from '../../../components/form/Button'
import { getPagesValue } from '../../../helpers/jsxHelper'
import { pagination } from '../../../util/enums/enums'
import { clear_getStaticPages, getStaticPages } from '../../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'

class SalesEngineDeals extends Component {
  state = {}

  componentDidMount() {
    const { getDealersSalesEngine } = this.props
    getDealersSalesEngine({
      page: getPagesValue(),
      limit: pagination.PAGE_RECORD_LIMIT,
    })
    this.props.getStaticPages({ slug: 'do-you-need-help-broker-and-dealer-my-deals-137' })
  }

  constructor(props) {
    super(props)
    this.state = {
      columns: [
        {
          Header: 'Manufacturer',
          accessor: 'firstName',
          Cell: data => (
            <span>
              {data.original.firstName} {data.original.lastName}
            </span>
          ),
        },
        {
          Header: 'Sales Engine',
          accessor: 'id',
          headerClassName: 'sales--engine--view',
          Cell: data => (
            <div className="d-flex flex-row justify-content-around">
              <ButtonComponent
                type="button"
                variant="fill"
                onClick={() => props.history.push(`deals/${data.original.id}`)}
                className="sales-engine--view"
              >
                View
              </ButtonComponent>
            </div>
          ),
        },
      ],
    }
  }

  render() {
    const {
      history,
      dealersSalesEngine,
      dealersSalesEngineTotal,
      getDealersSalesEngine,
      clearSearchDashboard,
      indexSearchData,
      totalSearchedData,
      isSearched,
      isDealersSalesEngineLoading,
    } = this.props
    const { columns } = this.state

    return (
      <DashboardLayout className="sales-engin-layout-design ">
        <>
          {columns && (
            <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table">
              <TableCard
                isSearch
                title="My Deals"
                columns={columns}
                className="manage--boat--table manage--table--section--alignment"
                data={isSearched ? indexSearchData : dealersSalesEngine}
                loading={isDealersSalesEngineLoading}
                isSalesEngine
                noButton
                action={getDealersSalesEngine}
                total={isSearched ? totalSearchedData : dealersSalesEngineTotal}
                clearSearchDashboard={clearSearchDashboard}
                helpModalContent={get(this.props.staticPages, 'data[0].pageContent', '')}
              />
            </div>
          )}
        </>
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  dealersSalesEngine: state.salesEngineReducer?.dealersSalesEngine,
  isDealersSalesEngineLoading: state.salesEngineReducer?.isDealersSalesEngineLoading,
  dealersSalesEngineTotal: state.salesEngineReducer?.dealersSalesEngineTotal,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  isSearched: state?.dashboardReducer?.isSearched,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
  staticPages: state.pageInfoByTypeReducer.staticPages,
})

const mapDispatchToProps = dispatch => ({
  getDealersSalesEngine: data => dispatch(getDealersSalesEngine(data)),
  getSingleSalesEngine: data => dispatch(getSingleSalesEngine(data)),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEngineDeals)
