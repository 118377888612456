import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'

import '../../containers/salesEngine/process/SalesEngineStepper.scss'
import { defaultProfileIcon } from '../../util/enums/enums'
import { getAddress, cityCountryNameFormatter, redirectToUserProfile, mileToKilometers } from '../../helpers/jsxHelper'
import { ButtonComponent } from '../form/Button'
import '../../containers/salesEngine/UserInformationCard.scss'
import { ShareWithChatAndUser } from '../share/ShareWithChatAndUser'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import InnerRatingModal from '../reviewRating/InnerRatingModal'
import { nameFormatter } from '../../helpers/string'
import { prettifyPhoneNumber } from '../../helpers/validationHelper'
import cn from 'classnames'
import { getId, getOnlineUsers } from '../../util/utilFunctions'
import { useDispatch, useSelector } from 'react-redux'
import { SET_ONLINE_USERS } from '../../redux/actionTypes'

export const UserInformationCard = props => {
  const {
    userInfo,
    surveyorInfo,
    declineSurveyor,
    salesEngine,
    className,
    isSurveyorWithBranch,
    isBuyer,
    history,
    isSurveyor,
    isShipper,
    handleChange,
    shipperPaymentData,
    getDocumentUrl,
    proposalDocument,
    enableReview,
    isAgent,
    shipperDocumentUploaded,
    isSurveyReviewEnable,
    payment,
  } = props

  const dispatch = useDispatch()

  const currentUser = useSelector(state => state.loginReducer.currentUser)
  const onlineUsers = useSelector(state => state.chatReducer.onlineUsers)

  useEffect(() => {
    getOnlineUsers([getId(surveyorInfo || userInfo)], users => dispatch({ type: SET_ONLINE_USERS, payload: users }))
  }, [surveyorInfo, userInfo])

  const isSurveyorWithSubBranch = isSurveyor && !surveyorInfo.isMainBranch

  const { commonAddress } = getAddress(isSurveyorWithSubBranch ? surveyorInfo?.address : userInfo?.address)
  const price = isShipper ? shipperPaymentData?.price : isSurveyor && isSurveyorWithBranch && surveyorInfo?.pricePerFt
  const distance = isShipper ? shipperPaymentData?.distance : isSurveyor && isSurveyorWithBranch && surveyorInfo.distance

  return (
    <Grid item xs={12} md={props.md || (isSurveyor ? 12 : 6)} className={`${className} surveyor--listings--content--grid`}>
      <div className="surveyor--listings--content">
        <div className="text-center mb-4 font-bold">{surveyorInfo?.companyName || userInfo.companyName}</div>
        <div className="d-flex align-items-center justify-content-around" style={{ gap: 40 }}>
          <div className="agent-info-list-with-image agent-info-list-with-image--survey">
            <div
              className="stepper-agent-img-div sales--engine--agent--img position-relative"
              onClick={() => redirectToUserProfile(userInfo, history)}
            >
              <img
                src={isSurveyorWithSubBranch ? surveyorInfo?.image?.url : userInfo.image?.url || defaultProfileIcon}
                className="h-100 width-100"
                style={{ objectFit: 'cover' }}
                alt="Profile"
              />
              <div className="stepper-user-online">
                <div
                  className={cn('online-div-user', {
                    disconnect: !onlineUsers[getId(surveyorInfo || userInfo)],
                  })}
                ></div>
              </div>
            </div>
            <div className="agent-info-list">
              <div className="agent-info-name">
                {isSurveyor ? surveyorInfo.contactName : nameFormatter([userInfo.firstName, userInfo.lastName])}
              </div>
              {/* <div className="verified--surveyor">
              <img
                src={require('../userProfile/image/verified.png')}
                className="stepper-user-profile-icon-div mr-2"
                alt="Verified"
              />
            </div> */}
              <div className="agent-info-icon d-flex justify-content-center">
                <ShareWithChatAndUser userId={userInfo.id} imageUrl={userInfo.image?.url} noShare />
              </div>
              {isSurveyor && (
                <span className="header-user-role dark-silver header--user--role--div header-user-role--tl">SURVEYOR</span>
              )}
              {isShipper && <span className="header-user-role dark-silver header--user--role--div">SHIPPER</span>}
            </div>
          </div>

          <div>
            {(userInfo.documentVerification || (isShipper && shipperPaymentData)) && (
              <div>
                {price && (
                  <>
                    <CurrencyContextConsumer>
                      {({ currentCurrency }) => (
                        <div>
                          <div className="surveyor--value">{getConvertedPrice(price, currentCurrency)}</div>
                          <div className="surveyor--label">{isShipper ? 'Cost of the Shipper' : 'Survey cost per feet'}</div>
                        </div>
                      )}
                    </CurrencyContextConsumer>
                    <div style={{ width: '50%', borderBottom: '1px solid #0005', margin: '10px auto' }} />
                  </>
                )}
                {distance && (
                  <>
                    <div>
                      <div className="surveyor--value">{isShipper ? distance : mileToKilometers(distance || 0) + ' km'}</div>
                      <div className="surveyor--label">Distance to the boat</div>
                    </div>
                    <div style={{ width: '50%', borderBottom: '1px solid #0005', margin: '10px auto' }} />
                  </>
                )}
                {/* <div className="provided">{userInfo.firstName} has provided</div>
                <div className="surveyor--verified-doc">
                  <div className="surveyor--verified-doc--w100">
                    {userInfo.documentVerification.emailVerified && (
                      <div className="survey--icon--with-verification">
                        <img
                          src={require('../userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Profile"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Email</span>
                      </div>
                    )}

                    {userInfo.documentVerification.governmentIdVerified && (
                      <div className="survey--icon--with-verification">
                        <img
                          src={require('../userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Profile"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Identity</span>
                      </div>
                    )}
                  </div>
                  <div className="surveyor--verified-doc--w100">
                    {userInfo.documentVerification.mobileVerified && (
                      <div className="survey--icon--with-verification">
                        <img
                          src={require('../userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Profile"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Mobile Number</span>
                      </div>
                    )}

                    {userInfo.documentVerification.commercialLicenceVerified && (
                      <div className="survey--icon--with-verification">
                        <img
                          src={require('../userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Profile"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Licence</span>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            )}
            <div className="mt-10 surveyor--city--country text-center font-bold">
              {cityCountryNameFormatter(commonAddress.city, commonAddress.country)}
            </div>
          </div>
        </div>
        {!salesEngine.surveyorPayment && isSurveyor && (
          <div className="d-flex justify-content-center mt-20">
            <button
              className="btn btn-lg btn-o-black btn-o-ghost-black"
              onClick={() =>
                declineSurveyor({
                  id: salesEngine.id,
                  surveyorId: salesEngine.surveyor && salesEngine.surveyor.id,
                })
              }
            >
              Change surveyor
            </button>
          </div>
        )}
        <div className="d-flex justify-content-center mt-4 mb-2">
          {isSurveyor && salesEngine.surveyorPayment && isBuyer && isSurveyReviewEnable && (
            <>
              {!!surveyorInfo.mobileNumber && (
                <div className="btn mr-3" style={{ border: '1px solid #0002', cursor: 'default' }}>
                  <a>
                    <i className="fas fa-phone-alt"></i> {prettifyPhoneNumber(surveyorInfo.mobileNumber)}
                  </a>
                </div>
              )}
              <InnerRatingModal
                initVals={userInfo.reviews?.reviews?.find(r => getId(currentUser) === getId(r.user))}
                module={userInfo}
                userModule={salesEngine?.boat}
                userRole={userInfo?.role}
                subModuleId={salesEngine?.id}
              />
            </>
          )}
          {isShipper && isBuyer && (
            <>
              <div className="logo-with-select mr-3">
                <button
                  className="btn btn-o-black"
                  onClick={() => {
                    handleChange({ id: salesEngine.id })
                  }}
                >
                  Change My Shipper
                </button>
              </div>
            </>
          )}
          {salesEngine.shipperPayment && isShipper && !isAgent && (
            <>
              <InnerRatingModal
                initVals={userInfo.reviews?.reviews?.find(r => getId(currentUser) === getId(r.user))}
                module={userInfo}
                userModule={salesEngine?.boat}
                userRole={userInfo?.role}
                subModuleId={salesEngine?.id}
              />
              {!shipperDocumentUploaded && (
                <div>
                  <button
                    className="btn btn-o-black ml-3"
                    onClick={() => getDocumentUrl({ id: proposalDocument?.id, filename: 'Shipment Proposal' })}
                  >
                    Download Proposal
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        {salesEngine.shipperPayment && isShipper && !isAgent && (
          <div className="shipment--cost--div">
            <CurrencyContextConsumer>
              {({ currentCurrency }) => (
                <p className="shipment--cost">Total Shipment Cost: {getConvertedPrice(payment?.amount)}</p>
              )}
            </CurrencyContextConsumer>
          </div>
        )}
      </div>
    </Grid>
  )
}

UserInformationCard.defaultProps = {
  className: '',
  isSurveyorWithBranch: false,
}
