import { put, takeLatest, all } from 'redux-saga/effects'
import {
  //User
  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  //  Chat
  GET_CHAT_LIST,
  GET_CHAT_LIST_SUCCESS,
  GET_CHAT_LIST_FAILURE,
  //  Me
  GET_CHAT_ME,
  GET_CHAT_ME_SUCCESS,
  GET_CHAT_ME_FAILURE,
  // Create Chat by id
  CREATE_CHAT_BY_ID,
  SUCCESS_CREATED_CHAT_BY_ID,
  CREATE_CHAT_BY_ID_FAILURE,
  // Create Message by Chat id
  CREATE_MESSAGE_BY_CHAT_ID,
  CREATE_MESSAGE_BY_CHAT_ID_SUCCESS,
  CREATE_MESSAGE_BY_CHAT_ID_FAILURE,

  GET_MESSAGE_BY_CHAT_ID,
  GET_MESSAGE_BY_CHAT_ID_SUCCESS,
  GET_MESSAGE_BY_CHAT_ID_FAILURE,
  // Read Message by Chat id
  READ_MESSAGE_BY_CHAT_ID,
  READ_MESSAGE_BY_CHAT_ID_SUCCESS,
  READ_MESSAGE_BY_CHAT_ID_FAILURE,
  // Clear Chat by Chat id
  DELETE_SINGLE_USER_CHAT,
  DELETE_SINGLE_USER_CHAT_SUCCESS,
  DELETE_SINGLE_USER_CHAT_FAILURE,
// Clear Message on Chat by Chat id
  DELETE_SINGLE_MESSAGE_CHAT,
  //Block User
  BLOCK_USER,
  BLOCK_USER_FAILURE,
  BLOCK_USER_SUCCESS,
  ADD_OR_REMOVE_USERS_FROM_GROUP,
  ADD_OR_REMOVE_USERS_FROM_GROUP_SUCCESS,
  ADD_OR_REMOVE_USERS_FROM_GROUP_FAILURE,
  GET_FRIEND_USERS_LIST,
  GET_FRIEND_USERS_LIST_FAILURE,
  GET_FRIEND_USERS_LIST_SUCCESS,
  CLEAR_CHAT_THREAD,
  CLEAR_CHAT_THREAD_SUCCESS,
  CLEAR_CHAT_THREAD_FAILURE,
  UPDATE_CHAT_FAILURE,
  UPDATE_CHAT_SUCCESS,
  UPDATE_CHAT,
  GET_CHAT_USERS_SUCCESS,
  GET_CHAT_USERS_FAILURE,
  GET_CHAT_USERS,

} from '../actionTypes'
import { graphqlClient, graphqlClientChat } from '../../helpers/graphqlClient'
import { mutationWithClient as mutation } from './apiHelper'

import { getChatUserLists, getChatLists, getMe, createChatByID, deleteChatByID, clearChatByID,createMessageByChatID, getMessageByChatID, readMessageByChatID, deleteMessageChatByID, blockUserByID, addOrRemoveUsersFromChat, getUserFriendListGraphQl, updateChat, getChatUsers } from '../../graphql/chat'

/**
 *  Get Search Users list
 * @param { page, limit, search } data 
 * @returns 
 */



function getChatUsersApi(data){
  return graphqlClientChat
  .query({
    query: getChatUsers,
    variables: { ...data },
    fetchPolicy: 'no-cache',
  })
  .then(res => res)
  .catch(error => {
    if (error.networkError) {
      return error.networkError.result
    }
  })
}

function* getChatAllUsersList(action) {
  const res = yield getChatUsersApi(action.payload)
  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({
      type: GET_CHAT_USERS_SUCCESS,
      payload: res.data.chatUsers,
    })
  } else {
    yield put({ type: GET_CHAT_USERS_FAILURE, error: res.errors })
  }
}

function* getChatUsersSaga() {
  yield takeLatest(GET_CHAT_USERS, getChatAllUsersList)
}


function getChatUsersListApi(data) {
  return graphqlClientChat
    .query({
      query: getChatUserLists,
      variables: { ...data },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      if (error.networkError) {
        return error.networkError.result
      }
    })
}

function* getChatUsersList(action) {
  const {appUserId, ...finalPayload} = action.payload
  const res = yield getChatUsersListApi(finalPayload)
  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({
      type: GET_USERS_LIST_SUCCESS,
      payload: res.data.getUsers,
      appUserId: action.payload.appUserId,
    })
  } else {
    yield put({ type: GET_USERS_LIST_FAILURE, error: res.errors })
  }
}

function* getChatUsersListSaga() {
  yield takeLatest(GET_USERS_LIST, getChatUsersList)
}

/**
 *  Get Chat list
 * @param { page, limit, search } data 
 * @returns 
 */
function getChatListApi(data) {

  return graphqlClientChat
    .query({
      query: getChatLists,
      variables: { ...data },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      if (error.graphQLErrors) {
        return error.graphQLErrors
      }
      if (error.networkError) {
        return error.networkError.result
      }
    })
}

function* getChatList(action) {
  const res = yield getChatListApi(action.payload)
  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({
      type: GET_CHAT_LIST_SUCCESS,
      payload: res.data.chatList,
    })
  } else {
    yield put({ type: GET_CHAT_LIST_FAILURE, error: res.errors })
  }
}

function* getChatListSaga() {
  yield takeLatest(GET_CHAT_LIST, getChatList)
}
/**
 *  Get Chat ME
 * @returns 
 */
function getChatMeApi() {
  return graphqlClientChat
    .query({
      query: getMe,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      if (error.graphQLErrors) {
        return error.graphQLErrors
      }
      if (error.networkError) {
        return error.networkError.result
      }
    })
}

function* getChatMe() {
  const res = yield getChatMeApi()
  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({
      type: GET_CHAT_ME_SUCCESS,
      payload: res.data.me,
    })
  } else {
    yield put({ type: GET_CHAT_ME_FAILURE, error: res.errors })
  }
}

function* getChatMeSaga() {
  yield takeLatest(GET_CHAT_ME, getChatMe)
}
/**
 * Create Chat
 */
function createChatApi(data) {
  return mutation(createChatByID, { ...data })
}

function* createChatData(action) {
  try {
    const res = yield createChatApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: CREATE_CHAT_BY_ID_FAILURE, error: res.errors })

    } else if (res.data) {
      const tempPayload=res?.data
      yield put({ type: SUCCESS_CREATED_CHAT_BY_ID, payload: tempPayload?.createChat?.data })

    }
  } catch (e) {
    yield put({ type: CREATE_CHAT_BY_ID_FAILURE, error: e })
  }
}


function* createChatSaga() {
  yield takeLatest(CREATE_CHAT_BY_ID, createChatData)
}

function clearChatByIDApi(data) {
  return mutation(clearChatByID, { ...data })
}
function* clearChatData(action) {
  try {
    const res = yield clearChatByIDApi(action.payload)  
    if (res.errors?.length) {
      yield put({ type: CLEAR_CHAT_THREAD_FAILURE, error: res.errors })

    } else if (res.data) {
      yield put({ type: CLEAR_CHAT_THREAD_SUCCESS, payload: res.data.deleteChats })

    }
  } catch (e) {
    yield put({ type: CLEAR_CHAT_THREAD_FAILURE, error: e })
  }
}

function* clearChatSaga() {
  yield takeLatest(CLEAR_CHAT_THREAD, clearChatData)
}




/**
 * Clear Single user Chat by Chat id
 */
function deleteUserChatApi(data) {
  return mutation(deleteChatByID, { ...data })
}

function* deleteUserChatData(action) {
  try {
    const res = yield deleteUserChatApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: DELETE_SINGLE_USER_CHAT_FAILURE, error: res.errors })

    } else if (res.data) {
      yield put({ type: DELETE_SINGLE_USER_CHAT_SUCCESS, payload: res.data.deleteChats })

    }
  } catch (e) {
    yield put({ type: DELETE_SINGLE_USER_CHAT_FAILURE, error: e })
  }
}

function* deleteUserChatSaga() {
  yield takeLatest(DELETE_SINGLE_USER_CHAT, deleteUserChatData)
}

/**
 * Clear Single Message on Chat by Chat id
 */
// function deleteChatMessageApi(data) {
//   return mutation(deleteMessageChatByID, { ...data })
// }

// function* deleteChatMessageData(action) {
//   try {
//     const res = yield deleteChatMessageApi(action.payload)
//     if (res.errors?.length) {
//       yield put({ type: DELETE_SINGLE_MESSAGE_CHAT_FAILURE, error: res.errors })

//     } else if (res.data) {
//       yield put({ type: DELETE_SINGLE_MESSAGE_CHAT_SUCCESS, payload: res.data.deleteMessage })

//     }
//   } catch (e) {
//     yield put({ type: DELETE_SINGLE_USER_CHAT_FAILURE, error: e })
//   }
// }

// function* deleteChatMessageSaga() {
//   yield takeLatest(DELETE_SINGLE_MESSAGE_CHAT, deleteChatMessageData)
// }

/**
 * Block User
 */
function blockUserApi(data) {
  return mutation(blockUserByID, { ...data })
}

function* blockUserData(action) {
  try {
    const res = yield blockUserApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: BLOCK_USER_FAILURE, error: res.errors })

    } else if (res.data) {
      yield put({ type: BLOCK_USER_SUCCESS, payload: res.data.blockUnblockUser })

    }
  } catch (e) {
    yield put({ type: BLOCK_USER_FAILURE, error: e })
  }
}

function* blockUserSaga() {
  yield takeLatest(BLOCK_USER, blockUserData)
}

/**
 * Create Message by Chat id
 */
function createMessageByChatApi(data) {
  return mutation(createMessageByChatID, { ...data })
}

function* createMessageByChatData(action) {
  try {
    const res = yield createMessageByChatApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: CREATE_MESSAGE_BY_CHAT_ID_FAILURE, error: res.errors })
      
    } else if (res.data) {
      yield put({
        type: CREATE_MESSAGE_BY_CHAT_ID_SUCCESS, payload: res.data.createMessage, oldMeta: action.payload.metaData,
      })
      
    }
  } catch (e) {
    yield put({ type: CREATE_MESSAGE_BY_CHAT_ID_FAILURE, error: e })
  }
}

function* createMessageByChatSaga() {
  yield takeLatest(CREATE_MESSAGE_BY_CHAT_ID, createMessageByChatData)
}

/**
 * Get Message by chat id
 */
function getChatMessageByIdApi(data) {
  return graphqlClientChat
    .query({
      query: getMessageByChatID,
      fetchPolicy: 'no-cache',
      variables: { ...data }
    })
    .then(res => res)
    .catch(error => {
      if (error.networkError) {
        return error.networkError.result
      }
    })
}

function* getChatMessageById(action) {
  const res = yield getChatMessageByIdApi(action.payload)
  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({
      type: GET_MESSAGE_BY_CHAT_ID_SUCCESS,
      payload: res.data.messageList,
    })
  } else {
    yield put({ type: GET_MESSAGE_BY_CHAT_ID_FAILURE, error: res?.errors })
  }
}

function* getChatMessageByIdSaga() {
  yield takeLatest(GET_MESSAGE_BY_CHAT_ID, getChatMessageById)
}



/**
 * ADD OR REMOVE User From Group
 */
function addOrRemoveUsersFromChatApi(data) {
  return mutation(addOrRemoveUsersFromChat, { ...data })
}

function* addOrRemoveUsersFromChatApiData(action) {
  try {
    const res = yield addOrRemoveUsersFromChatApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: ADD_OR_REMOVE_USERS_FROM_GROUP_FAILURE, error: res.errors })

    } else if (res.data) {
      yield put({
        type: ADD_OR_REMOVE_USERS_FROM_GROUP_SUCCESS, payload: res.data.addOrRemoveUsersFromChat,
      })

    }
  } catch (e) {
    yield put({ type: ADD_OR_REMOVE_USERS_FROM_GROUP_FAILURE, error: e })
  }
}

function* addOrRemoveUsersFromChatSaga() {
  yield takeLatest(ADD_OR_REMOVE_USERS_FROM_GROUP, addOrRemoveUsersFromChatApiData)
}



function updateChatApi(data) {
  return mutation(updateChat, data)
}

function* updateChatApiData(action) {
  try {
    const res = yield updateChatApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: UPDATE_CHAT_FAILURE, error: res.errors })

    } else if (res.data) {
      yield put({
        type: UPDATE_CHAT_SUCCESS, payload: res.data.updateChat,
      })

    }
  } catch (e) {
    yield put({ type: UPDATE_CHAT_FAILURE, error: e })
  }
}

function* updateChatSaga() {
  yield takeLatest(UPDATE_CHAT, updateChatApiData)
}

/**
 *  Get getUserFriendList list
 */
function getUserFriendListApi() {
  return graphqlClient
    .query({
      query: getUserFriendListGraphQl,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      if (error.networkError) {
        return error.networkError.result
      }
    })
}

function* getUserFriendListData(action) {
  const res = yield getUserFriendListApi(action.payload)
  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({
      type: GET_FRIEND_USERS_LIST_SUCCESS,
      payload: res.data.getUserFriendList,
    })
  } else {
    yield put({ type: GET_FRIEND_USERS_LIST_FAILURE, error: res.errors })
  }
}

function* getUserFriendListSaga() {
  yield takeLatest(GET_FRIEND_USERS_LIST, getUserFriendListData)
}

export default function* getChatUserListsSaga() {
  yield all([
    createChatSaga(),
    getChatUsersListSaga(),
    getChatUsersSaga(),
    getChatListSaga(),
    getChatMeSaga(),
    createMessageByChatSaga(),
    clearChatSaga(),
    deleteUserChatSaga(),
    blockUserSaga(),
    getChatMessageByIdSaga(),
    getUserFriendListSaga(),
    addOrRemoveUsersFromChatSaga(),
    updateChatSaga()
  ])
}
