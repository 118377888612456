//[WIP] :Not Review @ghanshyam

import React from 'react'
import { timeLeftForAuction } from '../../helpers/dateTimeHelper'
import { timeEnum } from '../../util/enums/enums'

export class Countdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      timeLeft: timeLeftForAuction(props?.endTime),
    }
  }

  componentDidMount() {
    const { endTime } = this.props
    const timeLeft = timeLeftForAuction(endTime)
    this.setState({ timeLeft })
  }

  componentDidUpdate() {
    const { endTime, complete } = this.props
    const timeLeft = timeLeftForAuction(endTime)
    const { days, minutes, seconds, hours } = timeLeft

    if(days === 0 && minutes === 0 && seconds === 0 && hours === 0) {
      complete && complete()
    }

    setTimeout(() => {
      this.setState({ timeLeft })
    }, 1000)
  }

  render() {
    const { timeLeft } = this.state

    return (
      <>
        <div className={'auction-time-box'}>
          <span className="auction-value">{timeLeft[timeEnum.DAYS.toLowerCase()]}</span>
          <span className="auction-text">DAY</span>
        </div>
        <div className={'auction-time-box'}>
          <span className="auction-value">{timeLeft[timeEnum.HOURS.toLowerCase()]}</span>
          <span className="auction-text">HRS</span>
        </div>
        <div className={'auction-time-box'}>
          <span className="auction-value">{timeLeft[timeEnum.MINUTES.toLowerCase()]}</span>
          <span className="auction-text">Mins</span>
        </div>
        <div className={'auction-time-box'}>
          <span className="auction-value">{timeLeft[timeEnum.SECONDS.toLowerCase()]}</span>
          <span className="auction-text">Secs</span>
        </div>
      </>
    )
  }
}
