import React from 'react'
import cn from 'classnames'

/**
 * @param {React.SVGProps<SVGSVGElement>} props
 */
export default function IconShieldSolid(props) {
  return (
    <svg
      {...props}
      className={cn('as-media-icon', props.className)}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
    >
      <path d="M13.9051 3.08988L9.7801 1.54488C9.3526 1.38738 8.6551 1.38738 8.2276 1.54488L4.1026 3.08988C3.3076 3.38988 2.6626 4.31988 2.6626 5.16738V11.2424C2.6626 11.8499 3.0601 12.6524 3.5476 13.0124L7.6726 16.0949C8.4001 16.6424 9.5926 16.6424 10.3201 16.0949L14.4451 13.0124C14.9326 12.6449 15.3301 11.8499 15.3301 11.2424V5.16738C15.3376 4.31988 14.6926 3.38988 13.9051 3.08988ZM11.6101 7.28988L8.3851 10.5149C8.2726 10.6274 8.1301 10.6799 7.9876 10.6799C7.8451 10.6799 7.7026 10.6274 7.5901 10.5149L6.3901 9.29988C6.1726 9.08238 6.1726 8.72238 6.3901 8.50488C6.6076 8.28738 6.9676 8.28738 7.1851 8.50488L7.9951 9.31488L10.8226 6.48738C11.0401 6.26988 11.4001 6.26988 11.6176 6.48738C11.8351 6.70488 11.8351 7.07238 11.6101 7.28988Z" />
    </svg>
  )
}
