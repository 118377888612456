import {
  GET_USER_REPORT_FOR_MODULE,
  GET_USER_REPORT_FOR_MODULE_SUCCESS,
  GET_USER_REPORT_FOR_MODULE_FAILURE,
} from '../actionTypes'

const InitialState = {
  success: false,
  error: false,
  report: {},
}
export const reportReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_USER_REPORT_FOR_MODULE:
      return {
        ...state,
        success: false,
        error: false,
        report: {},
      }
    case GET_USER_REPORT_FOR_MODULE_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        report: action.payload,
      }
    case GET_USER_REPORT_FOR_MODULE_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        report: {},
      }

    default:
      return state
  }
}
