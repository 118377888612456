import { put, takeLatest, all, takeEvery } from 'redux-saga/effects'
import {
  USER_REGISTER,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  CLEAR_UPDATE_FLAG,
  GET_USER_ROLES,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAILURE,
  USER_EMAIL_VERIFY,
  USER_EMAIL_VERIFY_SUCCESS,
  USER_EMAIL_VERIFY_FAILURE,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  CLEAR_USER_VERIFY_FLAGS,
  FORGOT_PASSWORD_MAIL,
  FORGOT_PASSWORD_MAIL_SUCCESS,
  FORGOT_PASSWORD_MAIL_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CLEAR_FORGOT_PASSWORD_MAIL_FLAG,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  ERROR_MESSAGE_SHOW,
  GET_ALL_PAGE_INFO_BY_TYPE_FAILURE,
  GET_ALL_PAGE_INFO_BY_TYPE,
  GET_ALL_PAGE_INFO_BY_TYPE_SUCCESS,
  CHANGE_USER_ACCOUNT_STATUS,
  CHANGE_USER_ACCOUNT_STATUS_SUCCESS,
  CHANGE_USER_ACCOUNT_STATUS_FAILURE,
  GET_USER_LOCATION,
  GET_USER_LOCATION_SUCCESS,
  GET_USER_LOCATION_FAILURE,
  GET_USER_PAYMENT_INFO,
  ADD_USER_PAYMENT_INFO,
  GET_USER_PAYMENT_INFO_SUCCESS,
  GET_USER_PAYMENT_INFO_FAILURE,
  ADD_USER_PAYMENT_INFO_SUCCESS,
  ADD_USER_PAYMENT_INFO_FAILURE,
  CLEAR_USER_PAYMENT_INFO_FLAG,
  ADD_REQUEST_FOR_AGREEMENT_ID,
  ADD_REQUEST_FOR_AGREEMENT_ID_SUCCESS,
  ADD_REQUEST_FOR_AGREEMENT_ID_FAILURE,
  CLEAR_AGREEMENT_ID_REQUEST_FLAG,
  GET_USER_BY_AGREEMENT_ID,
  GET_USER_BY_AGREEMENT_ID_SUCCESS,
  GET_USER_BY_AGREEMENT_ID_FAILURE,
  UPDATE_USER_DETAILS_BY_AGREEMENT_ID,
  UPDATE_USER_DETAILS_BY_AGREEMENT_ID_SUCCESS,
  UPDATE_USER_DETAILS_BY_AGREEMENT_ID_FAILURE,
  ASSIGN_AGREEMENT_ID,
  ASSIGN_AGREEMENT_ID_SUCCESS,
  ASSIGN_AGREEMENT_ID_FAILURE,
  ADMIN_LOGIN_AS_USER_SUCCESS,
  ADMIN_LOGIN_AS_USER_FAILURE,
  ADMIN_LOGIN_AS_USER,
  GET_DOCUMENT_URL,
  GET_DOCUMENT_URL_SUCCESS,
  GET_DOCUMENT_URL_FAILURE,
  CLEAR_DOCUMENT_URL,
  CLEAR_IS_VISIT_FIRST_TIME,
  GET_ITEMS_BY_USER_SUCCESS,
  GET_ITEMS_BY_USER_FAILURE,
  GET_ITEMS_BY_USER,
  GET_SITE_CONFIGURATIONS,
  GET_SITE_CONFIGURATIONS_SUCCESS,
  GET_SITE_CONFIGURATIONS_FAILURE,
  GET_MANAGE_PASSWORD_CONTENT_FAILURE,
  GET_MANAGE_PASSWORD_CONTENT_SUCCESS,
  GET_MANAGE_PASSWORD_CONTENT,
  SUBSCRIBE_USER,
  SUBSCRIBE_USER_SUCCESS,
  SUBSCRIBE_USER_FAILURE,
  ERROR_NOTIFICATION_MESSAGE,
  GET_TRANSACTION_BY_USER,
  GET_TRANSACTION_BY_USER_SUCCESS,
  GET_TRANSACTION_BY_USER_FAILURE,
  FETCH_ALL_CHANNELS,
  FETCH_ALL_CHANNELS_SUCCESS,
  FETCH_ALL_CHANNELS_FAILURE,
  ADD_REMOVE_FRIEND,
  ADD_REMOVE_FRIEND_SUCCESS,
  ADD_REMOVE_FRIEND_FAILURE,
  GET_USER_STRIPE_DETAILS,
  GET_USER_STRIPE_DETAILS_SUCCESS,
  GET_USER_STRIPE_DETAILS_FAILURE,
  UPDATE_USER_STRIPE_PROFILE_COMPLETED,
  UPDATE_USER_STRIPE_PROFILE_COMPLETED_SUCCESS,
  UPDATE_USER_STRIPE_PROFILE_COMPLETED_FAILURE,
  GET_TRANSACTION_BY_USER_PAYOUTS,
  GET_TRANSACTION_BY_USER_PAYOUTS_FAILURE,
  GET_TRANSACTION_BY_USER_PAYOUTS_SUCCESS,
  LOGIN_WITH_PAYPAL,
  LOGIN_WITH_PAYPAL_FAILURE,
  LOGIN_WITH_PAYPAL_SUCCESS,
  LOGIN_WITH_PAYPAL_CLEAR,
  ACTIVATE_PARTNERSHIP_CODE,
  ACTIVATE_PARTNERSHIP_CODE_CLEAR,
  ACTIVATE_PARTNERSHIP_CODE_SUCCESS,
  ACTIVATE_PARTNERSHIP_CODE_FAILURE,
} from '../actionTypes'
import { graphqlClient } from '../../helpers/graphqlClient'
import {
  registerUser,
  loginUser,
  updateUser,
  getAllRoles,
  activateUser,
  getUser,
  forgotPasswordMail,
  changePassword,
  getAllPageInformationByType,
  resetPasswordQuery,
  changeUserAccountStatus,
  getUserLocation,
  forgotPassword,
  getUserPaymentInformation,
  addUpdatePaymentInformation,
  createAgreementIdRequest,
  getUserByAgreementId,
  updateUserDetailsByAgreementId,
  assignAgreementId,
  getAutoLoginDetail,
  getItemsByUserQuery,
  getAllManageSiteConfiguration,
  getDocumentUrl,
  getManagePasswordContent,
  subscribeUserSchema,
  getTransactionDetailsByUserSchema,
  fetchAllChannelSchema,
  addRemoveFriendSchema,
  getStripeProfileLinkSchema,
  updateStripeProfileCompletedSchema,
  loginWithPaypalSchema,
  activatePartnershipCodeQuery,
} from '../../graphql/userSchema'
import { setLoggedUser, clearLocalStorage, setLocalStorageItem, setUserLocationDetails, getLocalStorageItem } from '../../helpers/storageHelper'
import { mutation, query } from './apiHelper'
import { dynamicError } from '../../util/enums/notificationEnum'
import { get } from 'lodash'
import axios from 'axios'
import { toast } from 'react-toastify'

function registerApi(input) {
  return graphqlClient
    .mutate({
      mutation: registerUser,
      variables: { input },
    })
    .then(res => {
      return res
    })
    .catch(error => {
      return error.networkError.result
    })
}

function loginApi(input) {
  return graphqlClient
    .mutate({
      mutation: loginUser,
      variables: { input },
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}

function updateApi(input) {
  return graphqlClient
    .mutate({
      mutation: updateUser,
      variables: { input },
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}

function forgetPasswordMailApi(input) {
  return graphqlClient
    .mutate({
      mutation: forgotPasswordMail,
      variables: input,
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}

function resetPasswordApi(input) {
  return graphqlClient
    .mutate({
      mutation: resetPasswordQuery,
      variables: input,
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}

function userRolesApi() {
  return graphqlClient
    .query({
      query: getAllRoles,
    })
    .then(res => res)
    .catch(error => error)
}

function getAllPageInformationByTypeApi(data) {
  return graphqlClient
    .query({
      query: getAllPageInformationByType,
      variables: data.payload,
    })
    .then(res => res)
    .catch(error => error)
}

function verifyUserEmailApi(input) {
  return graphqlClient
    .mutate({
      mutation: activateUser,
      variables: input,
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}

export function getUserByIdApi(input) {
  return graphqlClient
    .query({
      query: getUser,
      variables: { id: input },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}
function getItemsByUserApi({ type, ...input } = {}) {
  return graphqlClient
    .query({
      query: getItemsByUserQuery(type),
      variables: { ...input },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}
function getSiteConfigurationsApi() {
  return graphqlClient
    .query({
      query: getAllManageSiteConfiguration,
    })
    .then(res => res)
    .catch(error => error)
}

function forgotPasswordApi(input) {
  return graphqlClient
    .mutate({
      mutation: forgotPassword,
      variables: { input },
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}

function changeUserAccountStatusApi(input) {
  return graphqlClient
    .mutate({
      mutation: changeUserAccountStatus,
      variables: input,
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}

async function userLocationApi(input) {
  const { data } = await axios.get('https://api.ipify.org/?format=json');
  return await graphqlClient
    .query({
      query: getUserLocation,
      variables: { ip: data?.ip },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function getUserPaymentInformationApi(input) {
  return graphqlClient
    .query({
      query: getUserPaymentInformation,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function userPaymentInformationApi(input) {
  return graphqlClient
    .mutate({
      mutation: addUpdatePaymentInformation,
      variables: { input },
    })
    .then(res => res)
    .catch(error => error)
}

function requestForAgreementIdApi(input) {
  return graphqlClient
    .mutate({
      mutation: createAgreementIdRequest,
      variables: { input },
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}

function getUserByAgreementIdApi(input) {
  return graphqlClient
    .query({
      query: getUserByAgreementId,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function updateUserDetailsByAgreementIdApi(input) {
  return graphqlClient
    .mutate({
      mutation: updateUserDetailsByAgreementId,
      variables: { agreementId: input.agreementId, input: input.data },
    })
    .then(res => res)
    .catch(error => {
      throw error
    })
}

function assignAgreementIdApi(input) {
  return graphqlClient
    .mutate({
      mutation: assignAgreementId,
      variables: { agreementId: input.agreementId },
    })
    .then(res => res)
    .catch(error => {
      throw error
    })
}

function AdminUserLoginApi(input) {
  console.log(input)
  return query(getAutoLoginDetail, { autoLoginToken: input })
}

function documentUrlApi(input) {
  return query(getDocumentUrl, { mediaId: input })
}

function getManagePasswordContentApi() {
  return query(getManagePasswordContent)
}

function* userRegister(action) {
  try {
    const res = yield registerApi(action.payload)

    if (res.errors && res.errors.length) {
      yield put({ type: USER_REGISTER_FAILURE, res })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    } else {
      res.data.createUser?.user && setLoggedUser(res.data.createUser?.user)
      yield put({ type: USER_REGISTER_SUCCESS, payload: res.data.createUser })
    }
  } catch (error) {
    yield put({ type: USER_REGISTER_FAILURE, error })
  }
  yield put({ type: CLEAR_IS_VISIT_FIRST_TIME })
}

function* userLogin(action) {
  try {
    const res = yield loginApi(action.payload)
    if (res.errors && res.errors.length) {
      yield put({ type: USER_LOGIN_FAILURE, res })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    } else {
      setLoggedUser(res.data.loginUser)
      yield put({ type: USER_LOGIN_SUCCESS, payload: res.data.loginUser })
    }
  } catch (error) {
    yield put({ type: USER_LOGIN_FAILURE, error })
    yield put({ type: ERROR_MESSAGE_SHOW, payload: error })
  }
  yield put({ type: CLEAR_IS_VISIT_FIRST_TIME })
}

function* userLogout() {
  try {
    clearLocalStorage()
    yield put({ type: USER_LOGOUT_SUCCESS })
  } catch (error) {
    yield put({ type: USER_LOGOUT_FAILURE, error })
  }
}

function* forgetPasswordMail(action) {
  try {
    const res = yield forgetPasswordMailApi(action.payload)
    if (res.errors && res.errors.length) {
      yield put({ type: FORGOT_PASSWORD_MAIL_FAILURE, res })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    } else {
      yield put({
        type: FORGOT_PASSWORD_MAIL_SUCCESS,
        payload: res.data.forgetPasswordMail,
      })
      yield put({ type: CLEAR_FORGOT_PASSWORD_MAIL_FLAG })
    }
  } catch (error) {
    yield put({ type: FORGOT_PASSWORD_MAIL_FAILURE, error })
  }
}

function* resetPassword(action) {
  try {
    const res = yield resetPasswordApi(action.payload)
    if (res.errors && res.errors.length) {
      yield put({ type: RESET_PASSWORD_FAILURE, res })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    } else {
      yield put({
        type: RESET_PASSWORD_SUCCESS,
        payload: res.data.resetPassword,
      })
    }
  } catch (error) {
    yield put({ type: RESET_PASSWORD_FAILURE, error })
  }
}

function* userUpdate(action) {
  try {
    const res = yield updateApi(action.payload)
    if (res.errors && res.errors.length) {
      yield put({ type: USER_UPDATE_FAILURE, res })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    } else {
      setLoggedUser(res.data.updateProfile, true)
      yield put({ type: USER_UPDATE_SUCCESS, payload: res.data.updateProfile })
      yield put({ type: CLEAR_UPDATE_FLAG })
    }
  } catch (error) {
    yield put({ type: USER_UPDATE_FAILURE, error })
    yield put({ type: CLEAR_UPDATE_FLAG })
  }
}

function getStripeProfileLinkApi() {
  return graphqlClient
    .mutate({
      mutation: getStripeProfileLinkSchema,
    })
    .then(res => res)
    .catch(error => {
      return error.networkError.result
    })
}

function* getUserRoles() {
  try {
    const res = yield userRolesApi()
    yield put({ type: GET_USER_ROLES_SUCCESS, payload: res.data.getAllRoles })
  } catch (error) {
    yield put({ type: GET_USER_ROLES_FAILURE, error })
  }
}

function* getUserPageInformationByType(data) {
  try {
    const res = yield getAllPageInformationByTypeApi(data)
    yield put({ type: GET_ALL_PAGE_INFO_BY_TYPE_SUCCESS, payload: res.data.getAllPageInformationByType })
  } catch (error) {
    yield put({ type: GET_ALL_PAGE_INFO_BY_TYPE_FAILURE, error })
  }
}

function* verifyUserEmail(action) {
  try {
    const res = yield verifyUserEmailApi(action.payload)

    if (res.data?.activateUser) {
      yield put({
        type: USER_EMAIL_VERIFY_SUCCESS,
        payload: res.data.activateUser,
        activationType: action.payload.activationType,
      })
    } else {
      yield put({
        type: USER_EMAIL_VERIFY_FAILURE,
        payload: get(res, 'errors[0].message', '') || 'Cannot verify email',
        activationType: action.payload.activationType,
      })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    }
  } catch (error) {
    yield put({
      type: USER_EMAIL_VERIFY_FAILURE,
      payload: get(e, 'networkError.result.errors[0].message', '') || 'Cannot verify email',
      activationType: action.payload.activationType,
    })
  }
  yield put({ type: CLEAR_USER_VERIFY_FLAGS })
}

function* getUserById(action) {
  try {
    const res = yield getUserByIdApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: GET_USER_BY_ID_FAILURE, error: res.errors })
      if (!action.userProfile) {
        yield put({ type: USER_LOGOUT, error: res.errors })
      }
    } else if (res?.data) {
      yield put({ type: GET_USER_BY_ID_SUCCESS, payload: res.data.getUser, userProfile: action.userProfile })
      !action.userProfile && setLoggedUser(res.data.getUser, true)
    }
  } catch (error) {
    yield put({ type: GET_USER_BY_ID_FAILURE, error })
  }
}
function* getItemsByUser(action) {
  try {
    const res = yield getItemsByUserApi(action.payload)

    yield put({ type: GET_ITEMS_BY_USER_SUCCESS, payload: res.data.getItemsByUserId, total: res.data.getItemsByUserId.total })
  } catch (error) {
    yield put({ type: GET_ITEMS_BY_USER_FAILURE, error })
  }
}
function* getSiteConfigurationsData(action) {
  try {
    const res = yield getSiteConfigurationsApi(action.payload)
    yield put({ type: GET_SITE_CONFIGURATIONS_SUCCESS, payload: res.data.getSiteConfigurations })
  } catch (error) {
    yield put({ type: GET_SITE_CONFIGURATIONS_FAILURE, error })
  }
}

function* forgotPasswordData(action) {
  try {
    const res = yield forgotPasswordApi(action.payload)
    if (res.errors && res.errors.length) {
      yield put({ type: FORGOT_PASSWORD_FAILURE, res })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    } else {
      yield put({ type: FORGOT_PASSWORD_SUCCESS, payload: res.data })
    }
  } catch (error) {
    yield put({ type: FORGOT_PASSWORD_FAILURE, error })
  }
}

function* changeUserAccountStatusData(action) {
  console.log(action)
  try {
    const res = yield changeUserAccountStatusApi(action.payload)
    console.log(res)
    if (res.errors && res.errors.length) {
      yield put({ type: CHANGE_USER_ACCOUNT_STATUS_FAILURE, error: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    } else {
      yield put({ type: CHANGE_USER_ACCOUNT_STATUS_SUCCESS, payload: res })
    }
  } catch (error) {
    yield put({ type: CHANGE_USER_ACCOUNT_STATUS_FAILURE, error })
  }
}

function* userLocation(action) {
  try {
    const res = yield userLocationApi(action.payload)
    if (res.data.getUserLocation) {
      setUserLocationDetails(res.data.getUserLocation)
      yield put({ type: GET_USER_LOCATION_SUCCESS, payload: res.data.getUserLocation })
    }
  } catch (error) {
    yield put({ type: GET_USER_LOCATION_FAILURE, error })
  }
}

function* getUserPaymentInfo(action) {
  try {
    const res = yield getUserPaymentInformationApi(action.payload)
    yield put({ type: GET_USER_PAYMENT_INFO_SUCCESS, payload: res.data.getUserPaymentInformation })
  } catch (error) {
    yield put({ type: GET_USER_PAYMENT_INFO_FAILURE, error })
  }
}

function* userPaymentInfo(action) {
  try {
    const res = yield userPaymentInformationApi(action.payload)
    yield put({ type: ADD_USER_PAYMENT_INFO_SUCCESS, payload: res.data.userPaymentInformation })
  } catch (error) {
    yield put({ type: ADD_USER_PAYMENT_INFO_FAILURE, error })
  }
  yield put({ type: CLEAR_USER_PAYMENT_INFO_FLAG })
}

function* requestForAgreementIdData(action) {
  try {
    const res = yield requestForAgreementIdApi(action.payload)
    if (res.data) {
      yield put({ type: ADD_REQUEST_FOR_AGREEMENT_ID_SUCCESS, payload: res.data })
    } else if (res.errors && res.errors.length) {
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    }
  } catch (e) {
    yield put({ type: ADD_REQUEST_FOR_AGREEMENT_ID_FAILURE, e })
  }
  yield put({ type: CLEAR_AGREEMENT_ID_REQUEST_FLAG })
}

function* getUserByAgreementIdData(action) {
  try {
    const res = yield getUserByAgreementIdApi(action.payload)
    if (res.data) {
      yield put({ type: GET_USER_BY_AGREEMENT_ID_SUCCESS, payload: res.data })
    } else if (res.graphQLErrors && res.graphQLErrors.length) {
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.graphQLErrors })
      yield put({ type: GET_USER_BY_AGREEMENT_ID_FAILURE, e: res.graphQLErrors })
    }
  } catch (error) {
    yield put({ type: GET_USER_BY_AGREEMENT_ID_FAILURE, error })
  }
  yield put({ type: CLEAR_AGREEMENT_ID_REQUEST_FLAG })
}

function* updateUserDetailsByAgreementIdData(action) {
  try {
    const res = yield updateUserDetailsByAgreementIdApi(action.payload)
    yield put({ type: UPDATE_USER_DETAILS_BY_AGREEMENT_ID_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: UPDATE_USER_DETAILS_BY_AGREEMENT_ID_FAILURE, error })
  }
}

function* assignAgreementIdData(action) {
  try {
    const res = yield assignAgreementIdApi(action.payload)
    yield put({ type: ASSIGN_AGREEMENT_ID_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: ASSIGN_AGREEMENT_ID_FAILURE, error })
  }
}

function* AdminUserLogin(action) {
  console.log(action)
  try {
    clearLocalStorage()
    const res = yield AdminUserLoginApi(action.payload)
    console.log(action)
    console.log(res)
    if (res && res.data && res.data.getAutoLoginDetail) {
      setLoggedUser(res.data.getAutoLoginDetail)
      setLocalStorageItem('adminLoginDetected', true)
      yield put({ type: ADMIN_LOGIN_AS_USER_SUCCESS, payload: res.data.getAutoLoginDetail })
    } else if (res && res.errors) {
      yield put({ type: ADMIN_LOGIN_AS_USER_FAILURE, payload: res.errors })
    }
  } catch (error) {
    yield put({ type: ADMIN_LOGIN_AS_USER_FAILURE, error })
  }
}

function* documentUrl(action) {
  try {
    const res = yield documentUrlApi(action.payload?.id || action.payload)

    if (res && res.data && res.data.getDocumentUrl) {
      yield put({ type: GET_DOCUMENT_URL_SUCCESS, payload: res.data.getDocumentUrl, filename: action.payload?.filename })
    } else if (res && res.errors) {
      yield put({ type: GET_DOCUMENT_URL_FAILURE, payload: res.errors })
    }
  } catch (error) {
    yield put({ type: GET_DOCUMENT_URL_FAILURE, error })
  }

  yield put({ type: CLEAR_DOCUMENT_URL })
}

function* getManagePasswordContentData(action) {
  try {
    const res = yield getManagePasswordContentApi(action.payload)

    if (res && res.data) {
      yield put({ type: GET_MANAGE_PASSWORD_CONTENT_SUCCESS, payload: res.data.getManagePasswordContent })
    } else if (res && res.errors) {
      yield put({ type: GET_MANAGE_PASSWORD_CONTENT_FAILURE, payload: res.errors })
    }
  } catch (error) {
    yield put({ type: GET_DOCUMENT_URL_FAILURE, error })
  }

  yield put({ type: CLEAR_DOCUMENT_URL })
}

function* subscribeUserApi(action) {
  try {
    const res = yield mutation(subscribeUserSchema, action.payload)

    if (res.errors?.length) {
      yield put({ type: SUBSCRIBE_USER_FAILURE, e: get(res, 'errors[0].message', '') || 'Cannot subscribe to user!' })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, e: get(res, 'errors[0].message', '') || 'Cannot subscribe to user!' })
    } else if (res.data) {
      yield put({ type: SUBSCRIBE_USER_SUCCESS, payload: res.data.subscribe })
    }
  } catch (e) {
    yield put({ type: SUBSCRIBE_USER_FAILURE, e: get(e, 'networkError.result.errors[0].message', '') || 'Cannot subscribe to user!', })
  }
}

function* addRemoveUserFriendApi(action) {
  try {
    const res = yield mutation(addRemoveFriendSchema, { friendId: action.payload })

    if (res.errors?.length) {
      yield put({ type: ADD_REMOVE_FRIEND_FAILURE, e: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    } else if (res?.data) {
      yield put({ type: ADD_REMOVE_FRIEND_SUCCESS, payload: res.data.addRemoveFriend })
    }
  } catch (e) {
    yield put({ type: ADD_REMOVE_FRIEND_FAILURE, e })
  }
}

function* getTransactionDetailsByUserApi(action) {
  try {
    const res = yield query(getTransactionDetailsByUserSchema, action.payload)

    if (res.errors?.length) {
      yield put({ type: GET_TRANSACTION_BY_USER_FAILURE, e: res.errors })
    } else if (res?.data?.getTransactionDetailsByUser) {
      yield put({ type: GET_TRANSACTION_BY_USER_SUCCESS, payload: res.data.getTransactionDetailsByUser })
    }
  } catch (e) {
    yield put({ type: GET_TRANSACTION_BY_USER_FAILURE, e })
  }
}

function* getTransactionPayoutsDetailsByUserApi(action) {
  try {
    const res = yield query(getTransactionDetailsByUserSchema, action.payload)

    if (res.errors?.length) {
      yield put({ type: GET_TRANSACTION_BY_USER_PAYOUTS_FAILURE, e: res.errors })
    } else if (res?.data?.getTransactionDetailsByUser) {
      yield put({ type: GET_TRANSACTION_BY_USER_PAYOUTS_SUCCESS, payload: res.data.getTransactionDetailsByUser })
    }
  } catch (e) {
    yield put({ type: GET_TRANSACTION_BY_USER_PAYOUTS_FAILURE, e })
  }
}

function* fetchAllChannelApi(action) {
  try {
    const res = yield query(fetchAllChannelSchema, action.payload)

    if (res.errors?.length) {
      yield put({ type: FETCH_ALL_CHANNELS_FAILURE, e: res.errors })
    } else if (res?.data?.fetchAllChannel) {
      yield put({ type: FETCH_ALL_CHANNELS_SUCCESS, payload: res.data.fetchAllChannel })
    }
  } catch (e) {
    yield put({ type: FETCH_ALL_CHANNELS_FAILURE, e })
  }
}

function* getStripeProfileLink() {
  try {
    const res = yield getStripeProfileLinkApi()
    const result = res?.data?.genrateStripeProfileLink || {}

    if (result.data) {
      yield put({
        type: GET_USER_STRIPE_DETAILS_SUCCESS,
        payload: result.data,
      })
    } else {
      yield put({
        type: GET_USER_STRIPE_DETAILS_FAILURE,
        payload: result?.message || 'Cannot generate Stripe profile link!',
      })
    }
  } catch (e) {
    yield put({
      type: GET_USER_STRIPE_DETAILS_FAILURE,
      payload: 'Cannot generate Stripe profile link!',
    })
  }
}

function* getStripeProfileLinkSaga() {
  yield takeLatest(GET_USER_STRIPE_DETAILS, getStripeProfileLink)
}

function updateStripeProfileCompletedApi(input) {
  return graphqlClient.mutate({
    mutation: updateStripeProfileCompletedSchema,
    variables: { user_id: input },
  })
}

function* updateStripeProfileCompleted(action) {
  try {
    const res = yield updateStripeProfileCompletedApi(action.payload)
    if (res?.data?.updateStripeProfileCompleted?.data === 'true')
      yield put({
        type: UPDATE_USER_STRIPE_PROFILE_COMPLETED_SUCCESS,
        payload: true,
      })
    else yield put({ type: UPDATE_USER_STRIPE_PROFILE_COMPLETED_FAILURE, e })
  } catch (e) {
    yield put({ type: UPDATE_USER_STRIPE_PROFILE_COMPLETED_FAILURE, e })
  }
}

function* updateStripeProfileCompletedSage() {
  yield takeLatest(UPDATE_USER_STRIPE_PROFILE_COMPLETED, updateStripeProfileCompleted)
}

function* registerUserSaga() {
  yield takeLatest(USER_REGISTER, userRegister)
}

function* loginUserSaga() {
  yield takeLatest(USER_LOGIN, userLogin)
}

function* logoutUserSaga() {
  yield takeLatest(USER_LOGOUT, userLogout)
}

function* forgetPasswordMailSaga() {
  yield takeLatest(FORGOT_PASSWORD_MAIL, forgetPasswordMail)
}

function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD, resetPassword)
}

function* updateUserSaga() {
  yield takeLatest(USER_UPDATE, userUpdate)
}

function* getUserRolesSaga() {
  yield takeLatest(GET_USER_ROLES, getUserRoles)
}

function* getAllPageInformationByTypeSaga() {
  yield takeLatest(GET_ALL_PAGE_INFO_BY_TYPE, getUserPageInformationByType)
}

function* verifyUserEmailSaga() {
  yield takeLatest(USER_EMAIL_VERIFY, verifyUserEmail)
}

function* getUserByIdSaga() {
  yield takeEvery(GET_USER_BY_ID, getUserById)
}
function* forgotPasswordSaga() {
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordData)
}

function* changeUserStatusSaga() {
  yield takeLatest(CHANGE_USER_ACCOUNT_STATUS, changeUserAccountStatusData)
}

function* userLocationSaga() {
  yield takeLatest(GET_USER_LOCATION, userLocation)
}

function* getUserPaymentInformationSaga() {
  yield takeLatest(GET_USER_PAYMENT_INFO, getUserPaymentInfo)
}

function* userPaymentInformationSaga() {
  yield takeLatest(ADD_USER_PAYMENT_INFO, userPaymentInfo)
}

function* requestForAgreementIdSaga() {
  yield takeLatest(ADD_REQUEST_FOR_AGREEMENT_ID, requestForAgreementIdData)
}

function* getUserByAgreementIdSaga() {
  yield takeLatest(GET_USER_BY_AGREEMENT_ID, getUserByAgreementIdData)
}

function* updateUserDetailsByAgreementIdSaga() {
  yield takeLatest(UPDATE_USER_DETAILS_BY_AGREEMENT_ID, updateUserDetailsByAgreementIdData)
}

function* assignAgreementIdSaga() {
  yield takeLatest(ASSIGN_AGREEMENT_ID, assignAgreementIdData)
}

function* AdminUserLoginSaga() {
  yield takeLatest(ADMIN_LOGIN_AS_USER, AdminUserLogin)
}

function* documentUrlSaga() {
  yield takeLatest(GET_DOCUMENT_URL, documentUrl)
}

function* getItemsByUserSaga() {
  yield takeEvery(GET_ITEMS_BY_USER, getItemsByUser)
}

function* getSiteConfigurationsSaga() {
  yield takeEvery(GET_SITE_CONFIGURATIONS, getSiteConfigurationsData)
}

function* getManagePasswordContentSaga() {
  yield takeEvery(GET_MANAGE_PASSWORD_CONTENT, getManagePasswordContentData)
}

function* subscribeUserSaga() {
  yield takeLatest(SUBSCRIBE_USER, subscribeUserApi)
}

function* addRemoveUserFriendSaga() {
  yield takeLatest(ADD_REMOVE_FRIEND, addRemoveUserFriendApi)
}

function* getTransactionDetailsByUserSaga() {
  yield takeLatest(GET_TRANSACTION_BY_USER, getTransactionDetailsByUserApi)
}

function* getTransactionPayoutsDetailsByUserSaga() {
  yield takeLatest(GET_TRANSACTION_BY_USER_PAYOUTS, getTransactionPayoutsDetailsByUserApi)
}

function* fetchAllChannelSaga() {
  yield takeLatest(FETCH_ALL_CHANNELS, fetchAllChannelApi)
}

function* loginWithPaypalSaga() {
  yield takeLatest(LOGIN_WITH_PAYPAL, loginWithPaypalApi)
}

function* loginWithPaypalApi(action) {
  try {
    const res = yield query(loginWithPaypalSchema, action.payload)

    if (res.errors?.length) {
      yield put({ type: LOGIN_WITH_PAYPAL_FAILURE })
    } else if (res?.data?.loginWithPaypal) {
      yield put({ type: LOGIN_WITH_PAYPAL_SUCCESS, payload: res.data.loginWithPaypal })
    }
  } catch (e) {
    yield put({ type: LOGIN_WITH_PAYPAL_FAILURE })
  }

  yield put({ type: LOGIN_WITH_PAYPAL_CLEAR })
}

function* activePartnershipCodeApi(action) {
  try {
    const res = yield mutation(activatePartnershipCodeQuery, action.payload)

    if (res.data.partnershipCode_activate) {
      yield put({ type: ACTIVATE_PARTNERSHIP_CODE_SUCCESS })
      yield put({ type: GET_USER_BY_ID, payload: getLocalStorageItem('userId') })
    } else {
      yield put({ type: ACTIVATE_PARTNERSHIP_CODE_FAILURE, payload: { errMsg: 'Expired code' } })  
    }
  } catch (err) {
    yield put({ type: ACTIVATE_PARTNERSHIP_CODE_FAILURE, payload: { errMsg: 'Invalid code' } })
  }
  
  yield put({ type: ACTIVATE_PARTNERSHIP_CODE_CLEAR })
}

function* activePartnershipCodeSaga() {
  yield takeLatest(ACTIVATE_PARTNERSHIP_CODE, activePartnershipCodeApi)
}

export default function* loginSaga() {
  yield all([
    registerUserSaga(),
    loginUserSaga(),
    logoutUserSaga(),
    forgetPasswordMailSaga(),
    resetPasswordSaga(),
    updateUserSaga(),
    getUserRolesSaga(),
    verifyUserEmailSaga(),
    getUserByIdSaga(),
    forgotPasswordSaga(),
    getAllPageInformationByTypeSaga(),
    changeUserStatusSaga(),
    userLocationSaga(),
    getUserPaymentInformationSaga(),
    userPaymentInformationSaga(),
    requestForAgreementIdSaga(),
    getUserByAgreementIdSaga(),
    updateUserDetailsByAgreementIdSaga(),
    assignAgreementIdSaga(),
    AdminUserLoginSaga(),
    documentUrlSaga(),
    getItemsByUserSaga(),
    getSiteConfigurationsSaga(),
    getManagePasswordContentSaga(),
    subscribeUserSaga(),
    addRemoveUserFriendSaga(),
    getTransactionDetailsByUserSaga(),
    getTransactionPayoutsDetailsByUserSaga(),
    fetchAllChannelSaga(),
    getStripeProfileLinkSaga(),
    updateStripeProfileCompletedSage(),
    loginWithPaypalSaga(),
    activePartnershipCodeSaga(),
  ])
}
