import React, { useEffect, useState } from 'react'
import { Notification } from '../../components'
import Header from '../header/header'
import Footer from '../footer/footer'
import AdamseaChat from '../../containers/chat/adamseaChat'
import { getIsChatShow, getIsGalleryOpen, getSelectUser  } from '../../redux/selector/chat'
import {useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { chatToggle } from '../../redux/actions/chatAction'
import { socket } from '../../socket'
import { socketEvent } from '../../util/enums/socketEnums'

export const Layout = props => {
  const dispatch = useDispatch()
  const { className, isHeader } = props
  const [height, setHeight] = useState(95)
  const isChatShow = useSelector(getIsChatShow)
  const selectUser = useSelector(getSelectUser)
  const isGalleryOpen = useSelector(getIsGalleryOpen)

  const zoomWidth = () => {
    const selector = document.querySelector('.header-responsive')
    const height = selector && selector.offsetHeight
    height && setHeight(height)
    // height && window.scrollTo(0, 0)
  }
  useEffect(() => {
    zoomWidth()
    window.addEventListener('resize', zoomWidth)

    return () => {
      window.removeEventListener('resize', zoomWidth)
    }
  }, [])

  const getHeight = el => {
    const bodyEl = el?.closest('#root')
    const headerEl = bodyEl?.querySelector('.header-responsive')
    const heightEl = headerEl?.offsetHeight
    setHeight(heightEl)
  }
  return (
    <>
      <Header customClassName={isHeader} />
      <Notification />
      <OutsideClickHandler display="contents" onOutsideClick={(element) => {
        if(element?.target?.className ==='width-height-icon-bell' && isChatShow) return
          if (!isGalleryOpen) {
            socket.emit(socketEvent.LeaveChannel,selectUser?.chatId)
            dispatch(chatToggle(false))
          }
        }}>
        <>
          {isChatShow && <AdamseaChat height={height} />}
         </>
      </OutsideClickHandler>
      <main ref={el => getHeight(el)} style={{ marginTop: height }} className={className}>
        {props.children}
      </main>
      {!props.isFooter && <Footer noFooterMargin={props.noFooterMargin} />}
    </>
  )
}

Layout.defaultProps = {
  isHeader: '',
  isPageLoader: false,
  showMap: false,
}
