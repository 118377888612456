import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserContext from '../../UserContext'
import { getTypeWiseService } from '../../redux/actions'
import './boatServices.scss'
import BoatListingsWithMap from '../../components/gridComponents/BoatListingsWithMap'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import { titleEnums } from '../../util/enums/enums'
import { showAllTitle } from '../../helpers/jsxHelper'

class BoatServiceView extends Component {
  state = {
    typeId: '',
    typeName: '',
  }
  static contextType = UserContext
  static getDerivedStateFromProps(nextProps) {
    const { match } = nextProps
    const { params } = match && match

    if (params && params.typeId) {
      return {
        typeId: params.typeId,
        typeName: params.typeName,
      }
    }
    return null
  }

  render() {
    const { typeWiseServices, getTypeWiseService, totalTypeWiseServices, isPageLoader } = this.props
    const { typeId, typeName } = this.state
    const { country } = this.context
    const name = `${totalTypeWiseServices || 0} Companies doing ${typeName} listed in AdamSea service market`
    const title = showAllTitle(titleEnums.isExploreService, totalTypeWiseServices || 0, name)
    return (
      <>
        {typeId && (
          <BoatListingsWithMap
            showAllTitle={title}
            isBoatService
            boatsTypeCount={totalTypeWiseServices || 0}
            isPageLoader={isPageLoader}
            // boatsType="Recently added boat services"
            boatsType={name}
            viewService={id => this.viewService(id)}
            boats={typeWiseServices}
            action={getTypeWiseService}
            value={{ typeId, country }}
            mapGridClassName="service--show--all--grid adam-grid-4"
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  typeWiseServices: state.boatServiceReducer && state.boatServiceReducer.typeWiseServices,
  providedServices: state.boatServiceReducer && state.boatServiceReducer.providedServices,
  totalTypeWiseServices: state.boatServiceReducer && state.boatServiceReducer.totalTypeWiseServices,
  isPageLoader: state.boatServiceReducer && state.boatServiceReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  getTypeWiseService: data => dispatch(getTypeWiseService(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatServiceView)
