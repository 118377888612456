import moment from 'moment'
import searchFilter from '../helpers/filterUtil'
export const isToday = (momentDate) => {
    return moment(momentDate).isSame(moment(), "day")

}
export const isYesterday = (momentDate) => {
    return moment(momentDate).isSame(moment().subtract(1, 'day'), "day")
}
export const chatDateWiseLabel = (date, isTime=false) => isToday(moment(date)) ?  isTime ? moment(date).format('hh:mm a') :  'Today' : isYesterday(moment(date)) ? 'Yesterday' : moment(date).format('MM/DD/YYYY')
export const convertRimeFromSecond =(s) => (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s

export const groupByChatDateWise = ({activeThreadMessages, search}) => {
    if (!activeThreadMessages.length) return {}
    const data = searchFilter(activeThreadMessages, search, 'messageBody')
    return data.reduce((chats, thread) => {
        const date = chatDateWiseLabel(thread?.createdAt)
        if (!chats[date]) {
            chats[date] = [];
        }
        chats[date].push(thread);
        return chats;
    }, {});
};

export const getUniqueListBy =(arr, key) => {
    return [...new Map(arr?.map(item => [item[key], item])).values()]
}

export const chatType = {
    groupChat: 'Group',
    normalchat: 'OneToOne',
}

export  const getThumbnailForVideo= async (videoUrl) =>{
  const video = document.createElement("video");
  const canvas = document.createElement("canvas");
  video.style.display = "none";
  canvas.style.display = "none";

  // Trigger video load
  await new Promise((resolve, reject) => {
    video.addEventListener("loadedmetadata", () => {
      video.width = video.videoWidth;
      video.height = video.videoHeight;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // Seek the video to 25%
      video.currentTime = video.duration * 0.25;
    });
    video.addEventListener("seeked", () => resolve());
    video.src = videoUrl;
  });

  // Draw the thumbnailz
  canvas
    .getContext("2d")
    .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  const imageUrl = canvas.toDataURL("image/png");
  return imageUrl;
}

