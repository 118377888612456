import React, { Component } from 'react'

import { getLocalStorageItem } from '../helpers/storageHelper'

export default function (ComposedComponent) {
  class Authentication extends Component {
    componentDidMount() {
      this.isAuthCheck(this.props)
    }

    componentDidUpdate(prevProps) {
      this.isAuthCheck(prevProps)
    }

    isAuthCheck = props => {
      const isAuth = getLocalStorageItem('isAuthenticated')

      if (!isAuth) {
        props.history &&
          props.history.replace('/login', { callbackLink: props.history.location && props.history.location.pathname })
      }
      return
    }

    render() {
      const isAuth = getLocalStorageItem('isAuthenticated')

      return <>{isAuth && <ComposedComponent {...this.props} />}</>
    }
  }

  return Authentication
}
