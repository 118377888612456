import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { defaultProfileIcon } from '../../util/enums/enums'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
import '../../containers/salesEngine/SellerInformation.scss'
import { ShareWithChatAndUser } from '../share/ShareWithChatAndUser'
import InnerRatingModal from '../reviewRating/InnerRatingModal'
import cn from 'classnames'
import { getId, getOnlineUsers } from '../../util/utilFunctions'
import { connect, useSelector } from 'react-redux'
import { SET_ONLINE_USERS } from '../../redux/actionTypes'
import { prettifyPhoneNumber } from '../../helpers/validationHelper'
import styles from './SellerInfo.module.scss'
import { FaStar } from 'react-icons/fa'

const SellerInformationComp = props => {
  const { isSeller, salesEngine, history, xs, isPaymentDone, isBuyer, setOnlineUsers, onlineUsers } = props

  const currentUser = useSelector(state => state.loginReducer.currentUser)

  let userInfo

  if (salesEngine) {
    userInfo = isSeller ? salesEngine.buyer : salesEngine.seller
  }

  const userType = isSeller ? 'BUYER' : 'SELLER'

  useEffect(() => {
    getOnlineUsers([getId(userInfo)], setOnlineUsers)
  }, [userInfo])

  return (
    <Grid item xs={xs}>
      <div className="inner--main--title mb-3">
        {getId(userInfo) === getId(salesEngine.buyer) ? (
          'Boat buyer'
        ) : (
          <>
            Boat listed by <span style={{ textTransform: 'capitalize' }}>{userInfo?.role?.role?.toLowerCase()}</span>
          </>
        )}
      </div>
      <div className={cn(styles.card, 'text-center')}>
        <div className={styles.cardRow}>
          <div className={styles.userPic}>
            <img
              src={(userInfo && userInfo.image?.url) || defaultProfileIcon}
              alt={userInfo?.firstName || 'Profile Pic'}
              onClick={() => redirectToUserProfile(userInfo, history)}
            />
            <div className="stepper-user-online">
              <div className={cn('online-div-user', { disconnect: !onlineUsers[getId(userInfo)] })}></div>
            </div>
          </div>
          <div>
            <div className={styles.userName}>{userInfo?.firstName}</div>
          </div>
        </div>
        <div className={styles.cardRow}>
          <div className="d-flex">
            <ShareWithChatAndUser noShare userId={userInfo?.id} imageUrl={userInfo.image?.url} />
          </div>
          <div className={styles.vr} />
          <div className={styles.userReviews}>
            <div>
              <strong>1</strong> Reviews
            </div>
          </div>
          <div className={styles.vr} />
          <div className={styles.userRWrp}>
            <div className={styles.userRValue}>
              <FaStar style={{ height: '1em' }} /> {salesEngine?.boat.rating !== null ? salesEngine?.boat.rating.toFixed(1) : 0}
            </div>
          </div>
        </div>
        <div className={cn(styles.cardRow, 'flex-wrap')}>
          {isPaymentDone && isBuyer && (
            <div>
              <InnerRatingModal
                initVals={userInfo.reviews?.reviews?.find(r => getId(currentUser) === getId(r.user))}
                module={userInfo}
                userRole={userInfo?.role}
                userModule={salesEngine?.boat}
                subModuleId={salesEngine?.id}
              />
            </div>
          )}
          {(salesEngine?.surveyorPayment || salesEngine?.boatPayment) && !!userInfo.mobileNumber && (
            <div className={styles.userPhone}>
              <i className="fas fa-phone-alt"></i> {prettifyPhoneNumber(userInfo.mobileNumber)}
            </div>
          )}
        </div>
      </div>
    </Grid>
  )

  // return (
  //   <>
  //     <Grid item xs={xs} className="stepper-info-boat stepper-info-boat-margin">
  //       <div className="seller--stepper-div-padding h-100">
  //         <div className="seller--stepper-left">
  //           <div className="d-flex justify-content-center align-items-center">
  //             <div className="mb-3">
  //               <div className="stepper-userImg rounded-circle user-profile-online-section position-relative">
  //                 <img
  //                   className="rounded-circle width-100 h-100"
  //                   src={(userInfo && userInfo.image?.url) || defaultProfileIcon}
  //                   alt="profile icon"
  //                   onClick={() => redirectToUserProfile(userInfo, history)}
  //                 />
  //                 <div className="stepper-user-online">
  //                   <div
  //                     className={classNames('online-div-user', {
  //                       disconnect: !onlineUsers[getId(userInfo)],
  //                     })}
  //                   ></div>
  //                 </div>
  //               </div>
  //               <div className="user--profile-social-icon-main">
  //                 <ShareWithChatAndUser noShare userId={userInfo?.id} imageUrl={userInfo.image?.url} />
  //               </div>
  //               {!isSeller && userInfo?.companyLogo?.url && (
  //                 <div className="logo-box seller-logo-div">
  //                   <img loading="lazy" src={userInfo.companyLogo.url} height={50} width={50} alt="company logo" />
  //                 </div>
  //               )}

  //               {!isSeller && salesEngine?.seller && userInfo?.companyName && (
  //                 <div className="mb-1 d-flex align-items-center justify-content-center seller-logo-div--text font-16 color-black">
  //                   {userInfo.companyName}
  //                 </div>
  //               )}
  //               {isPaymentDone && isBuyer && (
  //                 <div className="surveyor--add--review--modal mt-3">
  //                   <InnerRatingModal module={userInfo} userRole={userInfo?.role} userModule={salesEngine?.boat} />
  //                 </div>
  //               )}
  //             </div>
  //           </div>
  //         </div>
  //         <div className="seller--stepper-right">
  //           <div>
  //             <div className="mb-4 seller--mb-left">
  //               <div className="seller--verification-align">
  //                 {userInfo?.firstName && userInfo?.lastName && (
  //                   <div className="mb-1 d-flex align-items-center justify-content-center seller-logo-div--text">
  //                     <span className="font-18 font-bold" style={{ color: 'black' }}>
  //                       {userInfo.firstName} {userInfo.lastName}
  //                     </span>
  //                   </div>
  //                 )}
  //               </div>
  //               <div className="seller--verification-align">
  //                 <span className="text-transform-capitalize font-14 font-bold">{userInfo?.role?.role}</span>
  //               </div>
  //               <div className="seller--verification-align">
  //                 <img
  //                   src={require('../userProfile/image/verified.png')}
  //                   className="stepper-user-profile-icon-div mr-2"
  //                   alt="Verified"
  //                   style={{ filter: 'brightness(0)' }}
  //                 />
  //                 <span className="font-14">Verified</span>
  //               </div>
  //             </div>
  //           </div>
  //           <span className="user-info-label">CONTACT THE {userType}</span>
  //           {(salesEngine?.surveyorPayment || salesEngine?.boatPayment) && !!userInfo.mobileNumber && (
  //             <div className="user--phone" style={{ marginTop: 20 }}>
  //               <a>
  //                 <i className="fas fa-phone-alt"></i> {prettifyPhoneNumber(userInfo.mobileNumber)}
  //               </a>
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //     </Grid>
  //   </>
  // )
}
SellerInformationComp.defaultProps = {
  xs: 4,
}

const mapStateToProps = state => ({ onlineUsers: state.chatReducer.onlineUsers })
const mapDispatchToProps = dispatch => ({ setOnlineUsers: users => dispatch({ type: SET_ONLINE_USERS, payload: users }) })

export const SellerInformation = connect(mapStateToProps, mapDispatchToProps)(SellerInformationComp)
