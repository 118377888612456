import React from 'react'
import { CommonAdamSeaStyle } from '../styleComponent/styleComponent'
import { percentageFormate } from '../../util/utilFunctions'
import RatingComponent from '../rating/Rating'
import { defaultProfileIcon } from '../../util/enums/enums'
import '../../assets/css/component/experience.scss'
import { textTruncate } from '../../helpers/string'
export class ReviewCard extends React.Component {
  render() {
    const { height, value } = this.props
    const { user, images, name, description, rating } = value
    return (
      <>
        <div className="user--experience--logo--with--img">
          <CommonAdamSeaStyle
            height={height}
            className="user--experience--with--img"
            img={encodeURI(images && images.length && images[0]?.url)}
          />
          <div className="user--experience--logo">
            <img loading="lazy" src={(user && user.companyLogo) || defaultProfileIcon} alt={name} />
          </div>
        </div>

        <div className="user--experience--main">
          <div className="user--experience--with--review">
            <div className="user--experience--name--with--city">
              <div className="user--experience--name">{name}</div>
              <div className="user--experience--city">
                <span>Ahmedabad, India</span>
              </div>
            </div>
            <div className="user--experience--review">
              <RatingComponent rating={rating} className="rating-clr" />
              {/* <span className="rating-count-rent">({percentageFormate(rating)})</span> */}
            </div>
          </div>

          <div className="user--experience--description">{textTruncate(description, 122)}</div>
          <div className="user--experience--company--name">{user && user.companyName}</div>
        </div>
      </>
    )
  }
}

ReviewCard.defaultProps = {
  isRent: false,
  isMarina: false,
  isService: false,
}
