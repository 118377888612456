import React, { PureComponent } from 'react'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import { CommonAdamSeaStyle } from '../styleComponent/styleComponent'
import SharePopup from '../share/SharePopup'

import { viewMarinaDetails } from '../../helpers/boatHelper'
import { wishlistModuleEnum, shareModule, defaultProfileIcon, defaultImage } from '../../util/enums/enums'
import { WishlistComponent } from '../wishlist/WishlistComponent'

import { textTruncate } from '../../helpers/string'
import '../../assets/css/component/topRatedMarina.scss'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
export class SingleTopRatedMarina extends PureComponent {
  state = {
    selectedIndex: null,
  }

  handleClick = index => {
    const { selectedIndex } = this.state
    this.setState({
      selectedIndex: selectedIndex !== index ? index : null,
    })
  }

  render() {
    const { selectedIndex } = this.state
    const { index, value, width, height, gridClassName, className, margin } = this.props
    const {
      images,
      pageVisits,
      facilities,
      id,
      name,
      user: { firstName, lastName },
    } = value

    return (
      <div style={{ width: width, marginRight: margin }} className={` marina-div-grid-content ${gridClassName} ${className}`}>
        <div className="boat-box top--service--grid--main mt-2">
          <div className="boat-box-shadow ">
            <div className="card-action top-service-share-icon">
              <div className="company--logo--recently--img">
                <img loading="lazy" src={value.user?.image?.url ? encodeURI(value.user.image.url) : defaultProfileIcon} alt="Profile" />
              </div>
              <div className="share-icon">
                <WishlistComponent moduleId={id} moduleType={wishlistModuleEnum.MARINA} />
                {/* <SharePopup
                  handleClick={() => this.handleClick(index)}
                  index={index}
                  selectedIndex={selectedIndex}
                  moduleId={id}
                  moduleType={shareModule.MARINA}
                /> */}
              </div>
            </div>
            <div className="top--service--main--img--style marina-img-height-without-rated" style={{ height: `${height}px` }}>
              <CommonAdamSeaStyle
                onClick={() => viewMarinaDetails(value)}
                height={height}
                width={width}
                className="w-100 top--service--img--style marina-img-height-without-rated"
                img={images && images.length > 0 ? encodeURI(images[0]?.url) : defaultImage}
              />
              <div className="top--service--view--style">
                <span className="span--view">
                  <VisibilityOutlinedIcon /> {pageVisits && pageVisits.length}
                </span>
              </div>
            </div>
            <div onClick={() => viewMarinaDetails(value)} className="boat-info">
              <h5 className="team--commitment--text">{name}</h5>
              <div className="boat--service--viewed-desc line-clamp font-13 color-black">{textTruncate(facilities, 95)}</div>
              <div className="color-black py-1 font-13 font-weight-500">
                By <a href={redirectToUserProfile(value.user, null, null, true)} target="_blank" onClick={e => e.stopPropagation()}>{firstName} {lastName}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
