import React, { useEffect, useState } from 'react'
import { ReviewCard } from './reviewCard'
import { getCarousalGrid, getMarginGrid } from '../../helpers/jsxHelper'
import { dimensionAspectRatio } from '../../util/utilFunctions'
import { dimension, responsiveCarousel } from '../../util/enums/enums'
import { CarouselWrapper } from '../carouselComponent/carouselWrapper'

const ReviewCards = props => {
  const [width, setWidth] = useState(dimension.experienceUs.width)
  const [gutter, setGutter] = useState(dimension.experienceUs.width)
  const [height, setHeight] = useState(dimension.experienceUs.height)

  const getCarousalHeight = () => {
    const { dimensionKey, responsiveNumber } = props
    const width = document.querySelector('.section-heading')
    const dividedBy = width && getCarousalGrid(width.offsetWidth, responsiveNumber)
    const actualWidth = width && width.offsetWidth / dividedBy
    const margin = width && getMarginGrid(width.offsetWidth, { lg: 60, md: 40, sm: 30 })
    setGutter(margin)
    const aspectRatio = dimensionAspectRatio(dimension[dimensionKey].width, dimension[dimensionKey].height)
    const actualHeight = actualWidth / aspectRatio - 5

    setWidth(actualWidth)
    setHeight(actualHeight)
  }
  useEffect(() => {
    getCarousalHeight()
    window.addEventListener('resize', getCarousalHeight)
    return () => {
      window.removeEventListener('resize', getCarousalHeight)
    }
  })

  const { experience, responsiveNumber } = props

  return (
    <div className="zoom--container--experience">
      <CarouselWrapper isZoom height={height} responsiveNumber={responsiveNumber} gutter={gutter}>
        {experience &&
          experience.map(value => {
            return <ReviewCard value={value} key={value.id} height={height} experience={experience} />
          })}
      </CarouselWrapper>
    </div>
  )
}

export default ReviewCards

ReviewCards.defaultProps = {
  responsiveNumber: responsiveCarousel.experienceUs,
  dimensionKey: 'experienceUs',
}
