import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  viewBoatHandler,
  viewRentBoatHandler,
  viewMarinaDetails,
  viewServiceBoatHandler,
  viewArticles,
} from '../../helpers/boatHelper'
import { defaultProfileIcon, marketTypes, noDataResult } from '../../util/enums/enums'
import './header.scss'
import '../../styles/headerResponsive.scss'
import { globalSearch } from '../../redux/actions'
import { getAddress, redirectToUserProfile } from '../../helpers/jsxHelper'
import { Loader } from '../loader/loader'
import { getSingleBoatApi, getSingleBoatSellerApi } from '../../redux/sagas/boatSaga'
import { editYachtServiceApi } from '../../redux/sagas/boatServiceSaga'
import { getSingleMarinaApi } from '../../redux/sagas/marinaAndStorageSaga'
import { getUserByIdApi } from '../../redux/sagas/loginSaga'
import { sortObjectsBySearch } from '../../util/utilFunctions'

const SearchDropdown = props => {
  const { query, setRecentQuery, recentSearchResults, globalSearchData, history, searchSuccess, searchLoading } = props

  const [isResult, setIsResult] = useState(false)
  const [displayedBoats, setDisplayedBoats] = useState(null)
  const [displayedNewBoat, setDisplayedNewBoat] = useState(null)
  const [displayedUsedBoat, setDisplayedUsedBoat] = useState(null)
  const [displayedMarina, setDisplayedMarina] = useState(null)
  const [displayedRentBoats, setDisplayedRentBoats] = useState(null)
  const [displayedyachtService, setDisplayedyachtService] = useState(null)
  const [displayedboatShows, setDisplayedboatShows] = useState(null)
  const [displayedArticles, setDisplayedArticles] = useState(null)
  const [displayedUsers, setDisplayedUsers] = useState(null)
  const [selectedMarketType, setSelectedMarketType] = useState(marketTypes.BOAT)

  useEffect(() => {
    setDisplayedBoats(null)
    setDisplayedNewBoat(null)
    setDisplayedUsedBoat(null)
    setDisplayedMarina(null)
    setDisplayedRentBoats(null)
    setDisplayedyachtService(null)
    setDisplayedboatShows(null)
    setDisplayedArticles(null)
    setDisplayedUsers(null)
    if (globalSearchData) {
      const { boats, marinaStorage, rentBoats, yachtService, boatShows, users, newBoats, usedBoats, articles } =
        globalSearchData
      if (
        boats?.items?.length ||
        newBoats?.items?.length ||
        usedBoats?.items?.length ||
        marinaStorage?.items?.length ||
        rentBoats?.items?.length ||
        yachtService?.items?.length ||
        boatShows?.items?.length ||
        articles?.items?.length ||
        users?.items?.length
      ) {
        setIsResult(true)
        if (boats.items.length > 0) {
          setDisplayedBoats(boats.items)
        }
        if (newBoats.items.length > 0) {
          setDisplayedNewBoat(newBoats.items)
        }
        if (usedBoats.items.length > 0) {
          setDisplayedUsedBoat(usedBoats.items)
        }
        if (marinaStorage.items.length > 0) {
          setDisplayedMarina(marinaStorage.items)
        }
        if (rentBoats.items.length > 0) {
          setDisplayedRentBoats(rentBoats.items)
        }
        if (yachtService.items.length > 0) {
          setDisplayedyachtService(yachtService.items)
        }
        // if (boatShows.items.length > 0 ) {
        //   setDisplayedboatShows(boatShows.items)
        // }
        if (articles.items.length > 0) {
          setDisplayedArticles(articles.items)
        }
        if (users.items.length) {
          setDisplayedUsers(users.items)
        }
      } else if (
        searchSuccess &&
        (!boats?.items?.length ||
          !newBoats?.items?.length ||
          !usedBoats?.items?.length ||
          !marinaStorage?.items?.length ||
          !rentBoats?.items?.length ||
          !yachtService?.items?.length ||
          !boatShows?.items?.length ||
          !articles?.items?.length ||
          !users?.items?.length)
      ) {
        setDisplayedBoats(null)
        setDisplayedNewBoat(null)
        setDisplayedUsedBoat(null)
        setDisplayedMarina(null)
        setDisplayedRentBoats(null)
        setDisplayedyachtService(null)
        setDisplayedboatShows(null)
        setDisplayedArticles(null)
        setDisplayedUsers(null)
      } else if (
        searchSuccess &&
        !boats?.items?.length &&
        !newBoats?.items?.length &&
        !usedBoats?.items?.length &&
        !marinaStorage?.items?.length &&
        !rentBoats?.items?.length &&
        !yachtService?.items?.length &&
        !boatShows?.items?.length &&
        !articles?.items?.length &&
        !users?.items?.length
      ) {
        setIsResult(false)
      } else if (recentSearchResults) {
        setIsResult(false)
      }
    } else {
      setIsResult(false)
      setDisplayedBoats(null)
      setDisplayedNewBoat(null)
      setDisplayedUsedBoat(null)
      setDisplayedMarina(null)
      setDisplayedRentBoats(null)
      setDisplayedyachtService(null)
      setDisplayedboatShows(null)
      setDisplayedArticles(null)
      setDisplayedUsers(null)
    }
  }, [globalSearchData, recentSearchResults, searchSuccess])

  const typeWiseResultHandler = type => {
    setSelectedMarketType(type)
    displayBoatHandler(globalSearchData, type)
  }

  const displayBoatHandler = (
    data = {
      boats: [],
      marinaStorage: [],
      rentBoats: [],
      yachtService: [],
      boatShows: [],
      users: [],
      newBoats: [],
      usedBoats: [],
      articles: [],
    },
    type = selectedMarketType
  ) => {
    let results = []
    let newResults = []
    let usedResults = []
    let rentResults = []
    let marinaResults = []
    let yatchResult = []
    let boatShowResults = []
    let articlesResult = []
    let userResults = []

    const { boats, marinaStorage, rentBoats, yachtService, boatShows, users, newBoats, usedBoats, articles } = data
    setDisplayedBoats(null)
    setDisplayedNewBoat(null)
    setDisplayedUsedBoat(null)
    setDisplayedMarina(null)
    setDisplayedRentBoats(null)
    setDisplayedyachtService(null)
    setDisplayedboatShows(null)
    setDisplayedArticles(null)
    setDisplayedUsers(null)
    switch (type) {
      case marketTypes.BOAT:
        results = boats?.items.length > 0 ? results.concat(boats.items) : []
        setDisplayedBoats(results)
        newResults = newBoats?.items.length > 0 ? newResults.concat(newBoats.items) : []
        setDisplayedNewBoat(newResults)
        usedResults = usedBoats?.items.length > 0 ? usedResults.concat(usedBoats.items) : []
        setDisplayedUsedBoat(usedResults)
        rentResults = rentBoats?.items.length > 0 ? rentResults.concat(rentBoats.items) : []
        setDisplayedRentBoats(rentResults)
        yatchResult = yachtService?.items.length > 0 ? yatchResult.concat(yachtService.items) : []
        setDisplayedyachtService(yatchResult)
        marinaResults = marinaStorage?.items.length > 0 ? marinaResults.concat(marinaStorage.items) : []
        setDisplayedMarina(marinaResults)
        // boatShowResults = boatShows?.items.length > 0 ? boatShowResults.concat(boatShows.items) : []
        // setDisplayedboatShows(boatShowResults)
        articlesResult = articles?.items.length > 0 ? articlesResult.concat(articles.items) : []
        setDisplayedArticles(articlesResult)
        userResults = users?.items.length > 0 ? userResults.concat(users.items) : []
        setDisplayedUsers(userResults)
        break
      case marketTypes.NEW_BOAT:
        results = newBoats?.items.length > 0 ? results.concat(newBoats.items) : results
        setDisplayedNewBoat(results)
        break
      case marketTypes.USED_BOAT:
        results = usedBoats?.items.length > 0 ? results.concat(usedBoats.items) : results
        setDisplayedUsedBoat(results)
        break
      case marketTypes.RENT_BOAT:
        results = rentBoats?.items.length > 0 ? results.concat(rentBoats.items) : []
        setDisplayedRentBoats(results)
        break
      case marketTypes.YACHT_SERVICE:
        results = yachtService?.items.length > 0 ? results.concat(yachtService.items) : []
        setDisplayedyachtService(results)
        break
      case marketTypes.MARINA_AND_STORAGE:
        results = marinaStorage?.items.length > 0 ? results.concat(marinaStorage.items) : []
        setDisplayedMarina(results)
        break
      // case marketTypes.BOAT_SHOW:
      //   results = boatShows?.items.length > 0 ? results.concat(boatShows.items) : []
      //   setDisplayedboatShows(results)
      //   break
      case 'articles':
        results = articles?.items.length > 0 ? results.concat(articles.items) : []
        setDisplayedArticles(results)
        break
      case 'users':
        results = users?.items.length > 0 ? results.concat(users.items) : []
        setDisplayedUsers(results)
        break

      default:
        break
    }
    return
  }

  const viewHandler = (item, selectedMarketType) => {
    switch (selectedMarketType) {
      case marketTypes.BOAT:
        getSingleBoatApi({ id: item.id, basic: true })
          .then(boatRes => {
            getSingleBoatSellerApi({ boatId: item.id }).then(sellerRes => {
              viewBoatHandler({ ...boatRes.data.editBoat, seller: sellerRes.data.editBoat_seller })
            })
          })
          .catch(console.log)
        break
      case marketTypes.NEW_BOAT:
        getSingleBoatApi({ id: item.id, basic: true })
          .then(boatRes => {
            getSingleBoatSellerApi({ boatId: item.id }).then(sellerRes => {
              viewBoatHandler({ ...boatRes.data.editBoat, seller: sellerRes.data.editBoat_seller })
            })
          })
          .catch(console.log)
        break
      case marketTypes.USED_BOAT:
        getSingleBoatApi({ id: item.id, basic: true })
          .then(boatRes => {
            getSingleBoatSellerApi({ boatId: item.id }).then(sellerRes => {
              viewBoatHandler({ ...boatRes.data.editBoat, seller: sellerRes.data.editBoat_seller })
            })
          })
          .catch(console.log)
        break
      case marketTypes.RENT_BOAT:
        viewRentBoatHandler(item)
        break
      case marketTypes.YACHT_SERVICE:
        editYachtServiceApi(item.id, true)
          .then(res => {
            viewServiceBoatHandler(res.data.EditYachtService)
          })
          .catch(console.log)
        break
      case marketTypes.MARINA_AND_STORAGE:
        getSingleMarinaApi(item.id, true)
          .then(res => {
            viewMarinaDetails(res.data.editMarina)
          })
          .catch(console.log)
        break
      case 'articles':
        viewArticles(item.id, item.name)
        break

      default:
        break
    }
  }

  const buttonClassHandler = type => (type === selectedMarketType ? 'btn-primary' : 'btn-outline-primary')

  const renderTabs = () => (
    <div className="d-flex flex-row flex-wrap dropdown-button-searches">
      <button
        type="button"
        className={`btn my-2 mr-2 explore-boat-options-dropdown ${buttonClassHandler(marketTypes.BOAT)}`}
        onClick={() => typeWiseResultHandler(marketTypes.BOAT)}
      >
        <img
          src={require('../../assets/images/search/all-search.png')}
          className="global-search-header-icon-dropdown"
          alt="all-search"
        />
        {'All'}
      </button>
      <button
        type="button"
        className={`btn my-2 mr-2 explore-boat-options-dropdown ${buttonClassHandler(marketTypes.NEW_BOAT)}`}
        onClick={() => typeWiseResultHandler(marketTypes.NEW_BOAT)}
      >
        <img
          src={require('../../assets/images/search/new-search.png')}
          className="global-search-header-icon-dropdown"
          alt="New"
        />
        {'New'}
      </button>
      <button
        type="button"
        className={`btn my-2 mr-2 explore-boat-options-dropdown ${buttonClassHandler(marketTypes.USED_BOAT)}`}
        onClick={() => typeWiseResultHandler(marketTypes.USED_BOAT)}
      >
        <img
          src={require('../../assets/images/search/used-search.png')}
          className="global-search-header-icon-dropdown"
          lat="Used"
        />
        {'Used'}
      </button>
      <button
        type="button"
        className={`btn my-2 mr-2 explore-boat-options-dropdown ${buttonClassHandler(marketTypes.RENT_BOAT)}`}
        onClick={() => typeWiseResultHandler(marketTypes.RENT_BOAT)}
      >
        <img
          src={require('../../assets/images/search/rent-search.png')}
          className="global-search-header-icon-dropdown"
          alt="Rent"
        />
        {'Rent'}
      </button>

      <button
        type="button"
        className={`btn my-2 mr-2 explore-boat-options-dropdown ${buttonClassHandler(marketTypes.YACHT_SERVICE)}`}
        onClick={() => typeWiseResultHandler(marketTypes.YACHT_SERVICE)}
      >
        <img
          src={require('../../assets/images/search/service-search.png')}
          className="global-search-header-icon-dropdown"
          lat="Service"
        />
        {'Service'}
      </button>

      <button
        type="button"
        className={`btn my-2 mr-2 explore-boat-options-dropdown ${buttonClassHandler(marketTypes.MARINA_AND_STORAGE)}`}
        onClick={() => typeWiseResultHandler(marketTypes.MARINA_AND_STORAGE)}
      >
        <img
          src={require('../../assets/images/search/marina-search.png')}
          className="global-search-header-icon-dropdown"
          alt="Marina"
        />
        {'Marina'}
      </button>

      {/* <button
        type="button"
        className={`btn my-2 mr-2 explore-boat-options-dropdown ${buttonClassHandler(marketTypes.BOAT_SHOW)}`}
        onClick={() => typeWiseResultHandler(marketTypes.BOAT_SHOW)}
      >
        {'Boat Show'}
      </button> */}
      <button
        type="button"
        className={`btn my-2 mr-2 explore-boat-options-dropdown ${buttonClassHandler('articles')}`}
        onClick={() => typeWiseResultHandler('articles')}
      >
        <img
          src={require('../../assets/images/search/articles-search.png')}
          className="global-search-header-icon-dropdown"
          alt="Articles"
        />
        {'Articles'}
      </button>
      <button
        type="button"
        className={`btn my-2 mr-2 explore-boat-options-dropdown ${buttonClassHandler('users')}`}
        onClick={() => typeWiseResultHandler('users')}
      >
        <img
          src={require('../../assets/images/search/user-search.png')}
          className="global-search-header-icon-dropdown"
          lat="Users"
        />
        {'Users'}
      </button>
    </div>
  )

  const renderResults = () => (
    <>
      <h4 className="title-text mt-2 mb-2 f-16 main-search-dropdown-result-title main-search-dropdown-result-margin">
        Results
      </h4>
      <div>
        {displayedBoats && displayedBoats.length > 0 ? (
          <div>
            <div className="header-search-div">
              {displayedBoats.map(boat => {
                const {
                  commonAddress: { city, country },
                } = getAddress(boat?.address) || {}
                const { images, image } = boat

                const boatImage = images && images.length ? images[0]?.url : image ? image?.url : null

                return (
                  <div
                    className="cityTitle d-flex flex-row mb-3 search-dropdown-div-margin"
                    key={boat.id}
                    onClick={() => viewHandler(boat)}
                  >
                    <div className="header-search-dropdown-image">
                      <img
                        loading="lazy"
                        height="55px"
                        width="55px"
                        src={boatImage}
                        alt="icon"
                        className="mr-2 header-search-dropdown"
                      />
                    </div>

                    <div className="d-flex flex-column justify-content-center">
                      <span className="mt-0 home-boat-name-font f-15 gray-dark search-dropdown-boat-name">{boat.boatName}</span>

                      <span className="mt-0 home-boat-name-font font13 dark-silver search-dropdown-boat-country">
                        {city} | {country} | {selectedMarketType}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
            <Link to={`/search-result?query=${query && query}`} className="search-dropdown-see-all-btn">
              see all
            </Link>
          </div>
        ) : (
          <span className="image-location mt-0 home-boat-name-font main-search-dropdown-result-title">No Results</span>
        )}
      </div>
    </>
  )

  const renderRecentSearch = () => (
    <div className="result-list search-dropdown-result-list p-0">
      <div className="search--data--main-sec" style={{ paddingBottom: '0' }}>
        <div className="search-drop-result-wrap">
          {sortObjectsBySearch(
            [
              ...(displayedBoats || []).map(data => ({
                ...data,
                __type: marketTypes.BOAT,
                __defImage: defaultProfileIcon,
              })),
              ...(displayedNewBoat || []).map(data => ({
                ...data,
                __type: marketTypes.NEW_BOAT,
                __defImage: defaultProfileIcon,
              })),
              ...(displayedUsedBoat || []).map(data => ({
                ...data,
                __type: marketTypes.USED_BOAT,
                __defImage: defaultProfileIcon,
              })),
              ...(displayedMarina || []).map(data => ({
                ...data,
                __type: marketTypes.MARINA_AND_STORAGE,
              })),
              ...(displayedRentBoats || []).map(data => ({
                ...data,
                name: data.subtitle,
                subtitle: data.description,
                __type: marketTypes.RENT_BOAT,
                __defImage: defaultProfileIcon,
              })),
              ...(displayedyachtService || []).map(data => ({
                ...data,
                __type: marketTypes.YACHT_SERVICE,
                __defImage: defaultProfileIcon,
              })),
              ...(displayedArticles || []).map(data => ({
                ...data,
                subtitle: data.description,
                __type: 'articles',
                __defImage: defaultProfileIcon,
              })),
              ...(displayedUsers || []).map(data => ({
                ...data,
                subtitle: String(data.subtitle).replace('SERVICE & MAINTENANCE', 'BOAT SERVICE'),
                __type: 'articles',
                __defImage: defaultProfileIcon,
                __handler: () => {
                  getUserByIdApi(data.id)
                    .then(res => redirectToUserProfile(res.data.getUser, history, 'blank'))
                    .catch(err => {})
                },
              })),
            ],
            ['name', 'subtitle', 'description'],
            query
          ).map(item => (
            <div className="search--result--sec-wrap">
              <div
                className="search--result--sec d-flex cursor-pointer"
                onClick={item.__handler || (() => viewHandler(item, item.__type))}
              >
                <div className="search--result--image--div">
                  <img loading="lazy" src={item.image || item.__defImage} className="search--magnify--icon" alt="Profile" />
                </div>
                <div className="search--result--name--module d-flex flex-column">
                  <div className="search--result--name--div">
                    <p className="search--result--name mb-0">{item.name}</p>
                  </div>
                  <div className="search--result--module--div">
                    <p className="search--result--module mb-0">{item.subtitle}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* {displayedBoats?.length > 0 &&
            displayedBoats.map((item, index) => {
              if (index < 5) {
                return (
                  <div className="search--result--sec-wrap">
                    <div
                      className="search--result--sec d-flex cursor-pointer"
                      onClick={() => viewHandler(item, marketTypes.BOAT)}
                    >
                      <div className="search--result--image--div">
                        <img
                          loading="lazy"
                          src={item.image || defaultProfileIcon}
                          className="search--magnify--icon"
                          alt="Profile"
                        />
                      </div>
                      <div className="search--result--name--module d-flex flex-column">
                        <div className="search--result--name--div">
                          <p className="search--result--name mb-0">{item.name}</p>
                        </div>
                        <div className="search--result--module--div">
                          <p className="search--result--module mb-0">{item.subtitle}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })} */}

          {/* New Boat */}
          {/* {displayedNewBoat?.length > 0 &&
            displayedNewBoat.map((item, index) => {
              if (index < 5) {
                return (
                  <div className="search--result--sec-wrap">
                    <div
                      className="search--result--sec d-flex cursor-pointer"
                      onClick={() => viewHandler(item, marketTypes.NEW_BOAT)}
                    >
                      <div className="search--result--image--div">
                        <img
                          loading="lazy"
                          src={item.image || defaultProfileIcon}
                          className="search--magnify--icon"
                          alt="Profile"
                        />
                      </div>
                      <div className="search--result--name--module d-flex flex-column">
                        <div className="search--result--name--div">
                          <p className="search--result--name mb-0">{item.name}</p>
                        </div>
                        <div className="search--result--module--div">
                          <p className="search--result--module mb-0">{item.subtitle}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })} */}

          {/* Used Boat */}
          {/* {displayedUsedBoat?.length > 0 &&
            displayedUsedBoat.map((item, index) => {
              if (index < 5) {
                return (
                  <div className="search--result--sec-wrap">
                    <div
                      className="search--result--sec d-flex cursor-pointer"
                      onClick={() => viewHandler(item, marketTypes.USED_BOAT)}
                    >
                      <div className="search--result--image--div">
                        <img
                          loading="lazy"
                          src={item.image || defaultProfileIcon}
                          className="search--magnify--icon"
                          alt="Profile"
                        />
                      </div>
                      <div className="search--result--name--module d-flex flex-column">
                        <div className="search--result--name--div">
                          <p className="search--result--name mb-0">{item.name}</p>
                        </div>
                        <div className="search--result--module--div">
                          <p className="search--result--module mb-0">{item.subtitle}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })} */}

          {/* Mariana & Storage */}
          {/* {displayedMarina?.length > 0 &&
            displayedMarina.map((item, index) => {
              if (index < 5) {
                return (
                  <div className="search--result--sec-wrap">
                    <div
                      className="search--result--sec d-flex cursor-pointer"
                      onClick={() => viewHandler(item, marketTypes.MARINA_AND_STORAGE)}
                    >
                      <div className="search--result--image--div">
                        <img loading="lazy" src={item.image} className="search--magnify--icon" alt="Search" />
                      </div>
                      <div className="search--result--name--module d-flex flex-column">
                        <div className="search--result--name--div">
                          <p className="search--result--name mb-0">{item.name}</p>
                        </div>
                        <div className="search--result--module--div">
                          <p className="search--result--module mb-0">{item.subtitle}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })} */}

          {/* Rent Boats */}
          {/* {displayedRentBoats?.length > 0 &&
            displayedRentBoats.map((item, index) => {
              if (index < 5) {
                return (
                  <div className="search--result--sec-wrap">
                    <div
                      className="search--result--sec d-flex cursor-pointer"
                      onClick={() => viewHandler(item, marketTypes.RENT_BOAT)}
                    >
                      <div className="search--result--image--div">
                        <img
                          loading="lazy"
                          src={item.image || defaultProfileIcon}
                          className="search--magnify--icon"
                          alt="Profile"
                        />
                      </div>
                      <div className="search--result--name--module d-flex flex-column">
                        <div className="search--result--name--div">
                          <p className="search--result--name mb-0">{item.subtitle}</p>
                        </div>
                        <div className="search--result--module--div">
                          <p className="search--result--module mb-0">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })} */}

          {/* Yatch Services */}
          {/* {displayedyachtService?.length > 0 &&
            displayedyachtService.map((item, index) => {
              if (index < 5) {
                return (
                  <div className="search--result--sec-wrap">
                    <div
                      className="search--result--sec d-flex cursor-pointer"
                      onClick={() => viewHandler(item, marketTypes.YACHT_SERVICE)}
                    >
                      <div className="search--result--image--div">
                        <img
                          loading="lazy"
                          src={item.image || defaultProfileIcon}
                          className="search--magnify--icon"
                          alt="Search icon"
                        />
                      </div>
                      <div className="search--result--name--module d-flex flex-column">
                        <div className="search--result--name--div">
                          <p className="search--result--name mb-0">{item.name}</p>
                        </div>
                        <div className="search--result--module--div">
                          <p className="search--result--module mb-0">{item.subtitle}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })} */}

          {/* Boat Show */}
          {/* {displayedboatShows?.length > 0 && (
            displayedboatShows.map((item, index) => {
              if (index < 5) {
                return (
                  <div className="search--result--sec-wrap">
                    <div className="search--result--sec d-flex cursor-pointer" onClick={() => viewHandler(item, marketTypes.BOAT_SHOW)}>
                      <div className="search--result--image--div">
                        <img loading="lazy" src={item.image || defaultProfileIcon} className="search--magnify--icon" />
                      </div>
                      <div className="search--result--name--module d-flex flex-column">
                        <div className="search--result--name--div">
                          <p className="search--result--name mb-0">{item.name}</p>
                        </div>
                        <div className="search--result--module--div">
                          <p className="search--result--module mb-0">{item.subtitle}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })
          )} */}

          {/* Articles */}
          {/* {displayedArticles?.length > 0 &&
            displayedArticles.map((item, index) => {
              if (index < 5) {
                return (
                  <div className="search--result--sec-wrap">
                    <div className="search--result--sec d-flex cursor-pointer" onClick={() => viewHandler(item, 'articles')}>
                      <div className="search--result--image--div">
                        <img
                          loading="lazy"
                          src={item.image || defaultProfileIcon}
                          className="search--magnify--icon"
                          alt="Profile"
                        />
                      </div>
                      <div className="search--result--name--module d-flex flex-column">
                        <div className="search--result--name--div">
                          <p className="search--result--name mb-0">{item.name}</p>
                        </div>
                        <div className="search--result--module--div">
                          <p className="search--result--module mb-0">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })} */}

          {/* {displayedUsers?.length > 0 &&
            displayedUsers.map((item, index) => {
              if (index < 5) {
                return (
                  <div className="search--result--sec-wrap">
                    <div
                      className="search--result--sec d-flex cursor-pointer"
                      onClick={() => {
                        getUserByIdApi(item.id)
                          .then(res => {
                            redirectToUserProfile(res.data.getUser, history, 'blank')
                          })
                          .catch(err => {})
                      }}
                    >
                      <div className="search--result--image--div">
                        <img
                          loading="lazy"
                          src={item?.image || defaultProfileIcon}
                          alt="Profile"
                          className="search--magnify--icon"
                        />
                      </div>
                      <div className="search--result--name--module d-flex flex-column">
                        <div className="search--result--name--div">
                          <p className="search--result--name mb-0">{item.name}</p>
                        </div>
                        <div className="search--result--module--div">
                          <p className="search--result--module mb-0">{item.subtitle}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })} */}
        </div>
      </div>
      {(displayedBoats?.length > 0 ||
        displayedNewBoat?.length > 0 ||
        displayedUsedBoat?.length > 0 ||
        displayedMarina?.length > 0 ||
        displayedRentBoats?.length > 0 ||
        displayedyachtService?.length > 0 ||
        displayedboatShows?.length > 0 ||
        displayedArticles?.length > 0 ||
        displayedUsers?.length > 0) && (
        <div className="search--show--all--link--sec" onClick={() => history.push(`/search?searchTerm=${query}`)}>
          <div className="d-flex search--show--all--link">
            <p className="search--show--all--link--text cursor-pointer">
              <i class="fas fa-search search--show--all--link--icon"></i> See All Results For "{query}"
            </p>
          </div>
        </div>
      )}
      {selectedMarketType !== marketTypes.BOAT &&
        (displayedNewBoat?.length === 0 ||
          displayedUsedBoat?.length === 0 ||
          displayedMarina?.length === 0 ||
          displayedRentBoats?.length === 0 ||
          displayedyachtService?.length === 0 ||
          displayedboatShows?.length === 0 ||
          displayedArticles?.length === 0 ||
          displayedUsers?.length === 0) && (
          <div className="alignCenter justify-center">
            <p className="global--search--no--result">
              <img
                src={require('../../assets/images/search/no_data_found.jpeg')}
                className="global-search-header-icon-dropdown-no-result"
                alt="No-data"
              />
              {noDataResult}
            </p>
          </div>
        )}
    </div>
  )

  return (
    <div className="dropdownWrp">
      <div className="dropdownContainerWrp">
        <div className="dropdownContainer search-boat-header main-search-dropdown p-0">
          {searchLoading ? (
            <Loader isSearchLoader className="position-relative" />
          ) : isResult ? (
            <div className="col-12">
              {/* <h5 className="title-text mt-2 main-search-dropdown-title">Explore AdamSea</h5> */}

              {renderTabs()}
            </div>
          ) : (
            <div className="alignCenter justify-center pt-4">
              <p className="global--search--no--result mb-0">
                <img
                  src={require('../../assets/images/search/no_data_found.jpeg')}
                  className="global-search-header-icon-dropdown-no-result"
                  alt="No-data"
                />
                {noDataResult}
              </p>
            </div>
          )}

          {renderRecentSearch()}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  globalSearchData: state.dashboardReducer.globalSearchData,
  recentSearchResults: state.boatReducer.recentSearchResults,
  searchSuccess: state.dashboardReducer.searchSuccess,
  searchLoading: state.dashboardReducer.searchLoading,
})

const mapDispatchToProps = dispatch => ({
  globalSearch: data => dispatch(globalSearch(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchDropdown)
