import { Grid } from '@material-ui/core'
import { Box, Stack } from '@mui/material'
import { Layout } from '../../components'
import { Row, Col, Image } from 'react-bootstrap'
import ImageBanner from '../../components/mainBanner/ImageBanner'
import { footerScreenImages } from '../../util/enums/enums'
import { icons } from '../../util/enums/enums'

import '../brokerInfo/brokerInfo.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import _styles from '../../styles/info-screens.module.scss'

export default function BuilderManufacturer() {
  return (
    <Layout>
      <div className="container100">
        <Grid className="py-5">
          <Row>
            <Col sm={12}>
              <h1 className="r-h1">Boat Builders And Manufacturers</h1>
            </Col>
          </Row>

          <Row>
            <Col sm={12} lg={6}>
              <span className="broker--info--title">Partnering with AdamSea</span>
              <span className="broker--info--text">
                AdamSea provides an excellent platform for boat builders and manufacturers to showcase their craftsmanship to a
                global audience. No matter the size or type of watercraft you manufacture, you can list it on AdamSea and sell
                directly to individual buyers or dealers. With our innovative sales engine, the selling process is swift and
                secure, ensuring the safety of all parties involved. Working with AdamSea as a manufacturer is not based on a
                paid membership; rather, it is based on partnership.
              </span>
            </Col>
            <Col className="d-flex justify-content-end align-items-center" lg={6}>
              <div className="position-relative">
                <img
                  className="position-absolute dotted-yellow--bg"
                  loading="lazy"
                  src={footerScreenImages.yellow_dots_bg}
                  alt="Dots"
                />
                <img
                  className="rounded"
                  loading="lazy"
                  width={493}
                  height={300}
                  src={footerScreenImages.builder_and_mfg}
                  alt="Builders"
                />
              </div>
            </Col>
          </Row>
        </Grid>
        <Box className="my-4">
          <p className="broker--info--title">Sales Engine</p>
          <img style={{ width: '100%' }} src={footerScreenImages.mfg_sales_engine_process} alt="Sales engine" loading="lazy" />
        </Box>
        <Grid container className="pt-3">
          <Grid item sm={12} md={6} className="my-3">
            <div className="info-card">
              <Stack>
                <img className="mr-5" loading="lazy" width={64} src={icons.enhance_com_and_control} alt="Enhancements" />
                <span className="broker--info--subtitle my-3">Enhance Communication and Sales Control</span>
                <span className="broker--info--detail">
                  As a boat builder or manufacturer, you can use AS Chat and AdamSea Share to communicate with everyone, from
                  individual buyers to dealers interested in your products. Our platform enables you to open your store and sell
                  in bulk or units, control your inventory and stock, and monitor every single step of every sale on the
                  platform.
                </span>
              </Stack>
            </div>
          </Grid>
          <Grid item sm={12} md={6} className="my-3">
            <div className="info-card">
              <Stack>
                <img className="mr-5" loading="lazy" width={64} src={icons.cruise_to_success} alt="Cruise to success" />
                <span className="broker--info--subtitle my-3">Cruise to Success</span>
                <span className="broker--info--detail">
                  With AdamSea, boat builders and manufacturers can enjoy a hassle-free selling process, while showcasing their
                  products to a global audience. Whether you are a small handmade canoe manufacturer or a large luxury yacht
                  builder, AdamSea is the platform for you to grow your business and expand your reach around the world.
                </span>
              </Stack>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={classNames(_styles.secPadding)}>
        <div className="container100">
          <h2 className={_styles.fz_h}>How boat manufacture sells in AdamSea</h2>
          <p className={_styles.fz_p + ' mt-20'}>
            <Link to="/register/boat-manufacturer" className="btn btn-primary rounded-full">Sign up as boat manufacturer</Link>
          </p>

          <ol className={'mt-30 ' + _styles.fz_ul_lg}>
            <li>
              <div>
                List all your boat models, sell them in bulk or in single item, assign your dealership in different countries
                and manage your boats inventory.
              </div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/boat-manu-1.png')} alt="List all your boat models" fluid />
              </div>
            </li>
            <li>
              <div>Receive one or more buyer requests for the same boat inventory.</div>
              <div className={_styles.fz_p}>The buyer who completes the order on the inventory first will get the order.</div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/boat-manu-2.png')} alt="Receive one or more buyer requests for the same boat inventory." fluid />
              </div>
            </li>
            <li>
              <div>Track which stages the buyer reaches in buying the boat inventory.</div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/boat-manu-3.png')} alt="Track which stages the buyer reaches in buying the boat inventory" fluid />
              </div>
            </li>
            <li>
              <div>When the shipper picks up the boat from the seller, AdamSea releases the boat payment directly to seller.</div>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  )
}
