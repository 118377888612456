import {
  CREATE_RENT_BOAT,
  CREATE_RENT_BOAT_SUCCESS,
  CREATE_RENT_BOAT_FAILURE,
  GET_BOAT_RENT_LOOKUPS,
  GET_BOAT_RENT_LOOKUPS_SUCCESS,
  GET_BOAT_RENT_LOOKUPS_FAILURE,
  GET_USER_BOAT_RENTS,
  GET_USER_BOAT_RENTS_SUCCESS,
  GET_USER_BOAT_RENTS_FAILURE,
  DELETE_BOAT_RENT,
  DELETE_BOAT_RENT_SUCCESS,
  DELETE_BOAT_RENT_FAILURE,
  CLEAR_BOAT_RENT_FLAG,
  GET_RENT_CATEGORY_WISE_BOATS_SUCCESS,
  GET_RENT_CATEGORY_WISE_BOATS,
  GET_RENT_CATEGORY_WISE_BOATS_FAILURE,
  GET_RENTS_INNER_BOAT_FAILURE,
  GET_RENTS_INNER_BOAT_SUCCESS,
  GET_RENTS_INNER_BOAT,
  GET_ALL_BOAT_RENT_TYPES,
  GET_ALL_BOAT_RENT_TYPES_SUCCESS,
  GET_ALL_BOAT_RENT_TYPES_FAILURE,
  GET_ALL_RENT_TYPES,
  GET_ALL_RENT_TYPES_SUCCESS,
  GET_ALL_RENT_TYPES_FAILURE,
  CLEAR_DELETE_BOAT_RENT_FLAG,
  UPDATE_RENT_BOAT,
  UPDATE_RENT_BOAT_SUCCESS,
  UPDATE_RENT_BOAT_FAILURE,
  GET_RENT_TRIP_CITY_WISE,
  GET_RENT_TRIP_CITY_WISE_SUCCESS,
  GET_RENT_TRIP_CITY_WISE_FAILURE,
  UPDATE_RENT_BOAT_TRIP_DETAILS,
  UPDATE_RENT_BOAT_TRIP_DETAILS__SUCCESS,
  UPDATE_RENT_BOAT_TRIP_DETAILS__FAILURE,
  CLEAR_UPDATE_TRIP_FLAG,
  ADD_LIKE,
  ADD_LIKE_SUCCESS,
  ADD_LIKE_FAILURE,
  CHANGE_RENT_BOAT_STATUS,
  CHANGE_RENT_BOAT_STATUS_SUCCESS,
  CHANGE_RENT_BOAT_STATUS_FAILURE,
  CLEAR_BOAT_RENT_STATUS_FLAG,
  CLEAR_BOAT_RENT_DATA,
  GET_RENT_TICKET,
  GET_RENT_TICKET_SUCCESS,
  GET_RENT_TICKET_FAILURE,
  CLEAR_GET_RENT_TICKET_FLAGS,
  CANCEL_RENT_BOOKING,
  CANCEL_RENT_BOOKING_SUCCESS,
  CANCEL_RENT_BOOKING_FAILURE,
  CLEAR__CANCEL_RENT_BOOKING__FLAG,
  GET_RENT_BOAT_TRIP_DETAILS,
  GET_RENT_BOAT_TRIP_DETAILS__SUCCESS,
  GET_RENT_BOAT_TRIP_DETAILS__FAILURE,
  CLEAR__GET_RENT_BOAT_TRIP_DETAILS,
  CLEAR__UPDATE_RENT_BOAT_TRIP_DETAILS,
  GET_RENT_PAYOUTS,
  GET_RENT_PAYOUTS_SUCCESS,
  GET_RENT_PAYOUTS_FAILURE,
  CLEAR__GET_RENT_PAYOUTS__FLAG,
} from '../actionTypes'
import { filterLikesReducerArray } from '../../helpers/string'
import { toast } from 'react-toastify'
import { emptyState } from '../../util/enums/enums'

const InitialState = {
  isLoading: false,
  success: false,
  error: false,
  boatRents: [],
  totalBoatRents: null,
  boatRentLookUps: [],
  boatRentTypes: [],
  allBoatRentTypes: [],
  deleteSuccess: false,

  boatRentInnerData: {},
  rentTripCityWise: [],
  validationError: false,

  changeStatusSuccess: false,
  changeStatusError: false,

  rentTicket: null,
  rentTicketLoading: false,
  rentTicketSuccess: false,
  rentTicketFailure: false,

  getTripDetails_data: emptyState(),
  updateTripDetails_data: emptyState(),

  getRentPayoutsLoading: false,
  getRentPayoutsSuccess: false,
  getRentPayoutsFailure: false,
  getRentPayoutsData: [],
}

export const boatRentReducer = (state = InitialState, action) => {
  const { boatRentInnerData } = state

  switch (action.type) {
    case CREATE_RENT_BOAT:
      return {
        ...state,
        success: false,
        error: false,
        validationError: false,
      }

    case CREATE_RENT_BOAT_SUCCESS:
      return {
        ...state,
        createSuccess: true,
        error: false,
      }

    case CREATE_RENT_BOAT_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        validationError: true,
      }

    case CLEAR_BOAT_RENT_FLAG:
      return {
        ...state,
        createSuccess: false,
        deleteSuccess: false,
        success: false,
        error: false,
        editRentBoatSuccess: false,
        editRentBoatError: false,
      }
    case GET_BOAT_RENT_LOOKUPS:
      return {
        ...state,
        success: false,
        error: false,
        boatRentLookUps: [],
        lookUpSuccess: false,
      }
    case GET_BOAT_RENT_LOOKUPS_SUCCESS:
      const boatRentLookUps = action.payload.data.getBoatRentLookUps

      let filteredBoatRentLookUps = {
        trip: [],
        tripType: [],
        decksAndEnvironment: [],
        tripDeposit: [],
      }

      boatRentLookUps.forEach(item => {
        switch (item.typeName) {
          case 'Trip':
            filteredBoatRentLookUps.trip.push(item)
            break

          case 'Deck & entertainment':
            filteredBoatRentLookUps.decksAndEnvironment.push(item)
            break

          case 'Trip Type':
            filteredBoatRentLookUps.tripType.push(item)
            break

          case 'Trip Deposit':
            filteredBoatRentLookUps.tripDeposit.push(item)
            break

          default:
            break
        }
      })

      return {
        ...state,
        success: true,
        error: false,
        boatRentLookUps: filteredBoatRentLookUps,
        lookUpSuccess: true,
      }
    case GET_BOAT_RENT_LOOKUPS_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        lookUpSuccess: false,
      }

    case GET_USER_BOAT_RENTS:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      }
    case GET_USER_BOAT_RENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: false,
        updatedSuccess: false,
        boatRents: action.payload.data.rentBoatByUser.items,
        totalBoatRents: action.payload.data.rentBoatByUser.total,
      }
    case GET_USER_BOAT_RENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: true,
      }
    case GET_ALL_RENT_TYPES:
      return {
        ...state,
        success: false,
        error: false,
      }
    case GET_ALL_RENT_TYPES_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        boatRentTypes: !action.isAdmin ? action.payload.data.getAllRentTypes.items : state.boatRentTypes,
        allBoatRentTypes: action.isAdmin ? action.payload.data.getAllRentTypes.items : state.allBoatRentTypes,
        totalBoatRents: action.payload.data.getAllRentTypes.total,
      }
    case GET_ALL_RENT_TYPES_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }

    case DELETE_BOAT_RENT:
      return {
        ...state,
        deleteSuccess: false,
        error: false,
      }
    case DELETE_BOAT_RENT_SUCCESS:
      const { boatRents } = state

      return {
        ...state,
        boatRents: boatRents.filter(item => item.id !== action.payload.id),
        deleteSuccess: true,
        error: false,
      }
    case DELETE_BOAT_RENT_FAILURE:
      return {
        ...state,
        deleteSuccess: false,
        error: true,
      }

    case CLEAR_DELETE_BOAT_RENT_FLAG:
      return {
        ...state,
        deleteSuccess: false,
      }

    case GET_RENT_CATEGORY_WISE_BOATS:
      return {
        ...state,
        categoryWiseBoat: [],
        totalCategoryWiseBoats: 0,
        isPageLoader: true,
      }
    case GET_RENT_CATEGORY_WISE_BOATS_SUCCESS:
      return {
        ...state,
        rentCategoryWiseBoat: action.payload.items,
        totalCategoryWiseBoats: action.payload.total,
        isPageLoader: false,
      }
    case GET_RENT_CATEGORY_WISE_BOATS_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        isPageLoader: false,
      }

    case CLEAR_BOAT_RENT_DATA:
      return {
        ...state,
        boatRentInnerData: {},
      }

    // Boat Rent Inner
    case GET_RENTS_INNER_BOAT:
      return {
        ...state,
        editRentBoatSuccess: false,
        editBoatRentError: false,
        isLoading: true,
        boatRentInnerData: {},
      }
    case GET_RENTS_INNER_BOAT_SUCCESS:
      return {
        ...state,
        editRentBoatSuccess: true,
        editBoatRentError: false,
        isLoading: false,
        boatRentInnerData: action.payload.data.editRentBoat,
      }
    case GET_RENTS_INNER_BOAT_FAILURE:
      return {
        ...state,
        editRentBoatSuccess: false,
        editBoatRentError: true,
        isLoading: false,
        boatRentInnerData: {},
      }

    //boat rent trip types

    case GET_ALL_BOAT_RENT_TYPES:
      return {
        ...state,
        getTypesSuccess: false,
        getTypesError: false,
      }
    case GET_ALL_BOAT_RENT_TYPES_SUCCESS:
      return {
        ...state,
        getTypesSuccess: true,
        getTypesError: false,
      }
    case GET_ALL_BOAT_RENT_TYPES_FAILURE:
      return {
        ...state,
        getTypesSuccess: false,
        getTypesError: true,
      }

    case UPDATE_RENT_BOAT:
      return {
        ...state,
        updatedSuccess: false,
        error: false,
        validationError: false,
      }

    case UPDATE_RENT_BOAT_SUCCESS:
      return {
        ...state,
        updatedSuccess: true,
        error: false,
      }

    case UPDATE_RENT_BOAT_FAILURE:
      return {
        ...state,
        updatedSuccess: false,
        error: true,
        validationError: true,
      }

    case GET_RENT_TRIP_CITY_WISE:
      return {
        ...state,
        success: false,
        error: false,
        rentTripCityWise: [],
      }

    case GET_RENT_TRIP_CITY_WISE_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        rentTripCityWise: action.payload.items,
      }

    case GET_RENT_TRIP_CITY_WISE_FAILURE:
      return {
        ...state,
        success: false,
        error: false,
      }

    /**
     * ================================
     *
     * ================================
     * get rent boat data
     * --------------------------------
     */

    case GET_RENT_BOAT_TRIP_DETAILS:
      return {
        ...state,
        getTripDetails_data: {
          ...emptyState(),
          data: state.getTripDetails_data.data,
          loading: true,
        },
      }

    case GET_RENT_BOAT_TRIP_DETAILS__SUCCESS:
      return {
        ...state,
        getTripDetails_data: {
          ...state.getTripDetails_data,
          data: action.payload,
          message: 'Trip details fetched successfully',
          success: true,
        },
      }

    case GET_RENT_BOAT_TRIP_DETAILS__FAILURE:
      return {
        ...state,
        getTripDetails_data: {
          ...state.getTripDetails_data,
          message: action.payload || 'Cannot get trip details',
          failure: true,
        },
      }

    case CLEAR__GET_RENT_BOAT_TRIP_DETAILS:
      return {
        ...state,
        getTripDetails_data: {
          ...emptyState(),
          data: state.getTripDetails_data.data,
        },
      }

    /**
     * ================================
     *
     * ================================
     * update rent boat trip details
     * --------------------------------
     */

    case UPDATE_RENT_BOAT_TRIP_DETAILS:
      return {
        ...state,
        updateTripDetails_data: {
          ...emptyState(),
          loading: true,
        },
      }

    case UPDATE_RENT_BOAT_TRIP_DETAILS__SUCCESS:
      return {
        ...state,
        updateTripDetails_data: {
          ...state.updateTripDetails_data,
          message: 'The trip details have been successfully updated.',
          success: true,
        },
      }

    case UPDATE_RENT_BOAT_TRIP_DETAILS__FAILURE:
      return {
        ...state,
        updateTripDetails_data: {
          ...state.updateTripDetails_data,
          message: action.payload || 'Cannot update trip details',
          failure: true,
        },
      }

    case CLEAR__UPDATE_RENT_BOAT_TRIP_DETAILS:
      return {
        ...state,
        updateTripDetails_data: {
          ...emptyState(),
        },
      }

    /**
     * ================================
     */

    case ADD_LIKE:
      return {
        ...state,
      }

    case ADD_LIKE_SUCCESS:
      return {
        ...state,
        boatRentInnerData: {
          ...boatRentInnerData,
          likes: filterLikesReducerArray(boatRentInnerData.likes, action.payload, action.moduleType),
        },
      }

    case ADD_LIKE_FAILURE:
      return {
        ...state,
      }

    case CHANGE_RENT_BOAT_STATUS:
      return {
        ...state,
        changeStatusLoading: true,
        changeStatusSuccess: false,
        changeStatusError: false,
      }
    case CHANGE_RENT_BOAT_STATUS_SUCCESS:
      return {
        ...state,
        changeStatusSuccess: true,
        changeStatusError: false,
      }
    case CHANGE_RENT_BOAT_STATUS_FAILURE:
      return {
        changeStatusSuccess: false,
        changeStatusError: true,
      }
    case CLEAR_BOAT_RENT_STATUS_FLAG:
      return {
        ...state,
        changeStatusLoading: false,
        changeStatusSuccess: false,
        changeStatusError: false,
      }

    case GET_RENT_TICKET:
      return {
        ...state,
        rentTicketLoading: true,
        rentTicketSuccess: false,
        rentTicketFailure: false,
      }

    case GET_RENT_TICKET_SUCCESS:
      return {
        ...state,
        rentTicket: action.payload,
        rentTicketSuccess: true,
        rentTicketFailure: false,
      }

    case GET_RENT_TICKET_FAILURE:
      return {
        ...state,
        rentTicketSuccess: false,
        rentTicketFailure: true,
      }

    case CLEAR_GET_RENT_TICKET_FLAGS:
      return {
        ...state,
        rentTicketLoading: false,
        rentTicketSuccess: false,
        rentTicketFailure: false,
      }

    case CANCEL_RENT_BOOKING:
      return {
        ...state,
        cancelRentBookingLoading: true,
        cancelRentBookingSuccess: false,
        cancelRentBookingFailure: false,
      }

    case CANCEL_RENT_BOOKING_SUCCESS:
      toast.success('Booking cancelled successfully!', { autoClose: 5000 })

      return {
        ...state,
        cancelRentBookingLoading: false,
        cancelRentBookingSuccess: true,
        cancelRentBookingFailure: false,
      }

    case CANCEL_RENT_BOOKING_FAILURE:
      toast.error(action.payload, { autoClose: 5000 })

      return {
        ...state,
        cancelRentBookingLoading: false,
        cancelRentBookingSuccess: false,
        cancelRentBookingFailure: true,
      }

    case CLEAR__CANCEL_RENT_BOOKING__FLAG:
      return {
        ...state,
        cancelRentBookingLoading: false,
        cancelRentBookingSuccess: false,
        cancelRentBookingFailure: false,
      }

    case GET_RENT_PAYOUTS:
      return {
        ...state,
        getRentPayoutsLoading: true,
        getRentPayoutsSuccess: false,
        getRentPayoutsFailure: false,
      }

    case GET_RENT_PAYOUTS_SUCCESS:
      return {
        ...state,
        getRentPayoutsSuccess: true,
        getRentPayoutsFailure: false,
        getRentPayoutsData: action.payload,
      }

    case GET_RENT_PAYOUTS_FAILURE:
      toast.error(action.payload, { autoClose: 5000 })

      return {
        ...state,
        getRentPayoutsSuccess: false,
        getRentPayoutsFailure: true,
      }

    case CLEAR__GET_RENT_PAYOUTS__FLAG:
      return {
        ...state,
        getRentPayoutsLoading: false,
        getRentPayoutsSuccess: false,
        getRentPayoutsFailure: false,
      }

    default:
      return state
  }
}
