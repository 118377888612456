import gql from 'graphql-tag'
import {
  GeometricLocation,
  RatingReview,
  RentType,
  LookUp,
  Boat,
  BoatRent,
  SellerUser,
  PageVisit,
  AddressType,
  ImageType,
  TripDetailsType,
  RentBookingType,
} from './schemaTypes/schemaTypes'

export const searchBoatRent = gql`
  mutation searchBoatRent($input: BoatRentSearchInput!) {
    searchBoatRent(input: $input) {
      items ${BoatRent}
      total
    }
  }
`

export const getRentBoatsByTrip = gql`
  query getRentBoatsByTrip(
    $page: Int
    $limit: Int
    $trip: String!
    $country: String!,
    $latLng: CoordinateInput
  ) {
    getRentBoatsByTrip(
      page: $page
      limit: $limit
      trip: $trip
      country: $country,
      latLng: $latLng
    ) {
      items {
        id
        model
        available
        price
        address ${AddressType}
        images ${ImageType}
        rating
        trip ${RentType}
        tripType ${RentType}
        tripDuration
        total
        boatLength
        maximumGuest
      }
      total
    }
  }
`

export const getMostPopularBoatRents = gql`
  query getMostPopularBoatRents(
    $page: Int
    $limit: Int
    $country: String!,
    $latLng: CoordinateInput
  ) {
    getMostPopularBoatRents(page: $page, limit: $limit, country: $country, latLng: $latLng) {
      items {
        address ${AddressType}
        adStatus
        id
        available
        pageVisits ${PageVisit}
        model
        price
        rating
        captainName
        createdAt
        images ${ImageType}
        trip {
          id
          alias
        }
        tripType {
          id
          alias
        }
        tripDuration
        boatLength
      }
      total
    }
  }
`

export const recommendedBoatRents = gql`
  query recommendedBoatRents($country: String!, $page: Int, $limit: Int, $latLng: CoordinateInput) {
    recommendedBoatRents(country: $country, page: $page, limit: $limit, latLng: $latLng) {
      items {
        id
        adId
        address ${AddressType}
        available
        images ${ImageType}
        boatLayout ${ImageType}
        adStatus
        boatRentStatus
        captainName
        whatToBring
        model
        descriptionOfOurTrip
        policy
        deposit {
          id
          alias
        }
        youtubeLinkVideo
        pageVisits ${PageVisit}
        boatLength
        bedroomAndCabins
        maximumGuest
        bathrooms
        seatsOnBoat
        price
        deckAndEntertainment{
          id
          alias
        }
        trip ${RentType}
        tripType ${RentType}
        tripDetails ${TripDetailsType}
        reviews ${RatingReview}
        total
        user {
          id
          firstName
        }
        rating
        startTime
        endTime
        tripDuration
        createdAt
      }
      total
    }
  }
`

export const getAllRentTripType = gql`
  query getAllRentTypes(
    $page: Float!
    $limit: Int!
    $isAdmin: Boolean = false
  ) {
    getAllRentTypes(page: $page, limit: $limit, isAdmin: $isAdmin) {
      items {
        id
        name
        thumbnailIcon ${ImageType}
        tripId
        createdAt
        updatedAt
      }
      total
    }
  }
`

export const getBoatRentTripCities = gql`
  {
    getBoatRentTripCities {
      _id
      city
      country
      countryImage {
        id
        url
      }
      minimumPrice
    }
  }
`

export const getRentCityWiseBoats = gql`
  query getBoatRentTripCityWise(
    $latLng: CoordinateInput
    $country: String!
  ) {
    getBoatRentTripCityWise(
      latLng: $latLng
      country: $country
    ) {
      items {
        id
        adId
        address ${AddressType}
        adStatus
        available
        boatRentStatus
        captainName
        whatToBring
        model
        descriptionOfOurTrip
        policy
        deposit {
          id
          alias
        }
        images ${ImageType}
        youtubeLinkVideo
        boatLayout ${ImageType}
        pageVisits ${PageVisit}
        boatLength
        bedroomAndCabins
        maximumGuest
        bathrooms
        seatsOnBoat
        price
        deckAndEntertainment{
          id
          alias
        }
        trip ${RentType}
        tripType ${RentType}

        reviews ${RatingReview}

        user {
          id
          firstName
        }
        rating
        startTime
        endTime
        tripDuration
        createdAt
      }
      total
    }
  }
`

export const getAllTripTypes_query = gql`
  query getAllTripTypes(
    $limit: Int,
    $page: Int,
    $isAdmin: Boolean = false,
    $typeId: String = "5da839bf131dda1ab49a1592",
    $country: String
  ) {
    getAllTripTypes(
      limit: $limit,
      page: $page,
      isAdmin: $isAdmin,
      typeId: $typeId,
      country: $country
    ) {
      id
      alias
      icon ${ImageType}
      tripId {
        id
        alias
      }
    }
  }
`

export const checkIfCanBookRentQuery = gql`
  query checkIfCanBookRent($boatId: String!, $slotId: String!, $units: Int!, $reqId: String!) {
    checkIfCanBookRent(boatId: $boatId, slotId: $slotId, units: $units, reqId: $reqId)
  }
`

export const createRentBookingQuery = gql`
  mutation createRentBooking($input: RentBookingInput!) {
    createRentBooking(input: $input) {
      id
    }
  }
`

export const getRentBookingsByCustomerQuery = gql`
  query getRentBookingsByCustomer($customer: String!, $isQRScanned: Boolean, $reqId: Float!, $page: Int!, $limit: Int! $searchTerm: String) {
    getRentBookingsByCustomer(customer: $customer, isQRScanned: $isQRScanned, reqId: $reqId, page: $page, limit: $limit, searchTerm: $searchTerm) {
      items ${RentBookingType}
      total
    }
  }
`
