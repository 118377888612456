import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import OtpInput from 'react-otp-input'
import { gql } from 'apollo-boost'
import { toast } from 'react-toastify'
import { graphqlClient } from '../../helpers/graphqlClient'
import { CommonModal } from '../modal/commonModal'
import styles from './ChangeEmailModal.module.scss'
import { get } from 'lodash'
import { Button } from '@material-ui/core'

export default function ChangeEmailModal({ open, close, changeEmailLoading, setChangeEmailLoading }) {
  const currentUser = useSelector(state => state.loginReducer.currentUser)

  const [oldEmailOtpSent, setOldEmailOtpSent] = useState(false)
  const [otp, setOtp] = useState('')
  const [oldEmailVerified, setOldEmailVerified] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [newEmailOtpSent, setNewEmailOtpSent] = useState(false)
  const [err, setErr] = useState('')

  useEffect(() => {
    changeEmail_requestOTPForExisting()
  }, [])

  const changeEmail_requestOTPForExisting = async () => {
    setErr('')
    setChangeEmailLoading(true)

    try {
      const res = await graphqlClient.mutate({
        mutation: gql`
          mutation changeEmail_requestOTPForExisting {
            changeEmail_requestOTPForExisting
          }
        `,
      })

      if (res.data.changeEmail_requestOTPForExisting) {
        setOldEmailOtpSent(true)
      } else {
        throw null
      }
    } catch (e) {
      const err = get(e, 'networkError.result.errors[0].message', 'Cannot change email at this moment')
      toast.error(err)
      close()
    } finally {
      setChangeEmailLoading(false)
    }
  }

  const changeEmail_verifyOTPForExisting = async otp => {
    setErr('')
    setChangeEmailLoading(true)

    try {
      const res = await graphqlClient.mutate({
        mutation: gql`
          mutation changeEmail_verifyOTPForExisting($otp: String!) {
            changeEmail_verifyOTPForExisting(otp: $otp)
          }
        `,
        variables: { otp },
      })

      if (res.data.changeEmail_verifyOTPForExisting) {
        setOldEmailVerified(true)
        setOtp('')
      } else {
        throw null
      }
    } catch (e) {
      const err = get(e, 'networkError.result.errors[0].message', 'Cannot change email at this moment')
      setErr(err)
    } finally {
      setChangeEmailLoading(false)
    }
  }

  const changeEmail_requestOTPForNew = async newEmail => {
    setErr('')
    setChangeEmailLoading(true)

    try {
      const res = await graphqlClient.mutate({
        mutation: gql`
          mutation changeEmail_requestOTPForNew($newEmail: String!) {
            changeEmail_requestOTPForNew(newEmail: $newEmail)
          }
        `,
        variables: { newEmail },
      })

      if (res.data.changeEmail_requestOTPForNew) {
        setNewEmailOtpSent(true)
      } else {
        throw null
      }
    } catch (e) {
      const err = get(e, 'networkError.result.errors[0].message', 'Cannot change email at this moment')
      setErr(err)
    } finally {
      setChangeEmailLoading(false)
    }
  }

  const changeEmail_verifyOTPForNew = async (newEmail, otp) => {
    setErr('')
    setChangeEmailLoading(true)

    try {
      const res = await graphqlClient.mutate({
        mutation: gql`
          mutation changeEmail_verifyOTPForNew($newEmail: String!, $otp: String!) {
            changeEmail_verifyOTPForNew(newEmail: $newEmail, otp: $otp)
          }
        `,
        variables: { newEmail, otp },
      })

      if (res.data.changeEmail_verifyOTPForNew) {
        toast.success('Email successfully changed')
        close()
        window.location.reload()
      } else {
        throw null
      }
    } catch (e) {
      const err = get(e, 'networkError.result.errors[0].message', 'Cannot change email at this moment')
      setErr(err)
    } finally {
      setChangeEmailLoading(false)
    }
  }

  return (
    <CommonModal open={open} close={close} title="Change Email" hideCloseIcon>
      <div className={styles.modalContent}>
        {!oldEmailVerified ? (
          <>
            <p style={{ padding: '20px 24px', borderRadius: 999, background: 'rgb(236, 229, 219)' }}>We have sent an OTP to your existing email address {currentUser.email}</p>
            <p style={{ marginTop: 30, padding: '12px 16px', borderRadius: 999, background: '#eee' }}>Enter OTP</p>
            <div>
              <OtpInput
                className={styles.otpInputCont}
                containerStyle={{ display: 'inline-flex', gap: 16, minWidth: 0, marginTop: 10 }}
                numInputs={6}
                separator="-"
                shouldAutoFocus
                value={otp}
                isDisabled={!oldEmailOtpSent || changeEmailLoading}
                onChange={otp => {
                  setOtp(otp)

                  if (otp?.length === 6) {
                    changeEmail_verifyOTPForExisting(otp)
                  }
                }}
              />
            </div>
          </>
        ) : (
          <>
            <p style={{ marginTop: 30, padding: '12px 16px', borderRadius: 999, background: '#eee' }}>Enter your new email</p>
            <div>
              <input
                type="email"
                placeholder="New Email Address"
                autoFocus
                className={styles.emailInput}
                value={newEmail}
                onChange={e => (setErr(false), setNewEmailOtpSent(false), setNewEmail(e.target.value))}
                disabled={changeEmailLoading}
              />
            </div>
            <div>
              <button
                type="button"
                className={styles.emailBtn}
                onClick={() => changeEmail_requestOTPForNew(newEmail)}
                disabled={changeEmailLoading}
              >
                Send OTP
              </button>
            </div>

            {newEmailOtpSent && (
              <>
                <p className={styles['email-otp-text']} style={{ marginTop: 30, padding: '16px 20px', borderRadius: 999, background: '#eee' }}>Enter OTP sent to your new Email Address</p>
                <OtpInput
                  className={styles.otpInputCont}
                  containerStyle={{ display: 'inline-flex', gap: 16, minWidth: 0, marginTop: 10 }}
                  numInputs={6}
                  separator="-"
                  shouldAutoFocus
                  value={otp}
                  isDisabled={!newEmailOtpSent || changeEmailLoading}
                  onChange={otp => {
                    setOtp(otp)

                    if (otp?.length === 6) {
                      changeEmail_verifyOTPForNew(newEmail, otp)
                    }
                  }}
                />
              </>
            )}
          </>
        )}

        {err && <p className={styles.errMsg}>{err}</p>}
      </div>
    </CommonModal>
  )
}
