const data = [
  {
    icon: require('../../assets/images/info-screens/about-us--data-icon-1.svg'),
    title: 'Trouble-free experience',
    text: `Our distinctive sales engine and user-friendly platform prioritize transparency, security, and ease for all parties involved. This ensures a smooth and trouble-free experience.`,
  },

  {
    icon: require('../../assets/images/info-screens/about-us--data-icon-2.svg'),
    title: 'Outstanding service',
    text: `We aim to create lasting relationships with our users, offering outstanding service and support throughout their journey with us.`,
  },

  {
    icon: require('../../assets/images/info-screens/about-us--data-icon-3.svg'),
    title: 'Sustainability',
    text: `we hold a strong commitment to sustainability and environmental stewardship in the marine sector. We encourage responsible boating practices and work to reduce our carbon footprint.`,
  },
]

export default data
