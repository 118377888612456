import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { FaEyeSlash, FaEye } from 'react-icons/fa'

import { Layout } from '../../components/layout/layout'
import { clearAuthorizationFlag, forgotPassword, clearForgotPassword, clearErrorMessageShow } from '../../redux/actions'
import { SuccessNotify } from '../../helpers/notification'
import { Notification } from '../../components'
import '../login/login.scss'
import { renderErrorMessage, validatePassword, passwordStrength } from '../../helpers/jsxHelper'
import ErrorFocus from '../../components/ws/ErrorFocus'
import { popUpMessage } from '../../helpers/confirmationPopup'
import ErrorComponent from '../../components/error/errorComponent'
import PasswordValidation from '../../components/passwordValidations/passwordValidation'
import { passwordStrengthEnum, footerLinks } from '../../util/enums/enums'
import { CommonModal } from '../../components/modal/commonModal'
import { getAllPageInfoByType } from '../../redux/actions/pageInfoByTypeAction'
import Captcha from '../../components/helper/captcha'
import { getId } from '../../util/utilFunctions'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

class ForgetPassword extends Component {
  state = {
    user: {
      token: '',
      password: '',
      confirmPassword: '',
    },
    type: 'password',
    wording: <AiOutlineEye />,
    errorMessages: {},
    userName: '',

    recaptchaRef: null,
    isVerifyCaptch: false,
  }

  componentDidMount() {
    const { clearErrorMessageShow } = this.props
    clearErrorMessageShow()
  }

  changeState = () => {
    var oldState = this.state.type
    var isTextOrHide = oldState === 'password'
    var newState = isTextOrHide ? 'text' : 'password'
    var newWord = isTextOrHide ? <AiOutlineEyeInvisible /> : <AiOutlineEye />
    this.setState({
      type: newState,
      wording: newWord,
    })
  }

  validate = (e, setValue, email) => {
    const { errorMessages } = this.state
    const password = e.target.value
    const errors = validatePassword(password, email)

    this.setState({
      errorMessages: errors,
    })

    const pwdStrength = passwordStrength(password, setValue)

    this.setState({
      passwordStrength: pwdStrength,
      isDisplayHint: true,
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { forgotPasswordSuccess, isPasswordResetError, history, clearForgotPassword, currentUser } = nextProps
    const { user } = prevState
    const { location } = history

    if (getLocalStorageItem('isAuthenticated')) {
      window.location.href = '/logout?redirect_to=' + encodeURIComponent(window.location.href)
      return {}
    }

    if (location && location.search && !forgotPasswordSuccess) {
      const urlParams = new URLSearchParams(location.search)

      if (urlParams.has('token')) {
        const token = urlParams.get('token')
        const firstName = urlParams.get('firstName')
        const lastName = urlParams.get('lastName')
        user.token = token
        return {
          userName: `${firstName} ${lastName}`,
          user,
        }
      }
    } else if (forgotPasswordSuccess) {
      clearForgotPassword()
      setTimeout(() => SuccessNotify('Your password reset successfully'), 100)
      history.push('/login')
    } else if (isPasswordResetError) {
      popUpMessage('invalid token provided', 'Error')
    }

    return null
  }

  privacyPolicyHandler = () => {
    const { getAllPageInfoByType } = this.props
    getAllPageInfoByType({ title: footerLinks.Privacy_Policy, isLargeModal: true })
  }

  termsHandler = () => {
    const { getAllPageInfoByType } = this.props
    getAllPageInfoByType({ title: footerLinks.Terms_Of_Use, isLargeModal: true })
  }

  createMarkup = data => {
    return { __html: data }
  }

  render() {
    const { user, errorMessages, passwordStrength, isDisplayHint, recaptchaRef, isVerifyCaptch, userName } = this.state
    const { forgotPassword, clearErrorMessageShow, isError, errorMessage } = this.props

    return (
      <>
        <Layout>
          <Notification />
          <div style={{ padding: 20 }} />
          <Grid container alignItems="center">
            <Grid item sm={12}>
              <Formik
                initialValues={user}
                validationSchema={Yup.object().shape({
                  password: Yup.string().required('Password field is required'),
                  confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    .required('Confirm password field is required'),
                })}
                onSubmit={async values => {
                  const token = await recaptchaRef.current.execute()
                  if (token) {
                    this.setState({ isVerifyCaptch: false })

                    clearErrorMessageShow()
                    const { confirmPassword, ...newValues } = values
                    if (
                      !errorMessages.symbolOrNumberCount &&
                      !errorMessages.passwordLength &&
                      passwordStrength !== passwordStrengthEnum.POOR
                    ) {
                      forgotPassword(newValues)
                    }
                  } else {
                    this.setState({ isVerifyCaptch: true })
                  }
                }}
                render={({ errors, status, touched, setFieldValue, handleSubmit }) => (
                  <div className="form-holder font-16">
                    <div className="form-content min-height-unset">
                      <div className="form-items forget-password-form p-5">
                        Hi, {userName}
                        <h3 className="text-left font-weight-500">Update Your Password</h3>
                        <Form className="">
                          <ErrorFocus />
                          <div className="position-relative">
                            <input
                              id="password"
                              name="password"
                              type={this.state.type}
                              className={
                                'form-control mt-10 password--spacing' +
                                (errors.password && touched.password ? ' is-invalid' : '')
                              }
                              style={{ fontSize: '1em' }}
                              placeholder="New Password"
                              onChange={e => {
                                setFieldValue('password', e.target.value)
                                this.validate(e, setFieldValue)
                              }}
                              required
                            ></input>
                            {!errors.password && (
                              <span className="form-side-icon forgot--password--icon" onClick={this.changeState}>
                                {this.state.wording}
                              </span>
                            )}

                            <div className="mt-2">
                              {isDisplayHint && (
                                <PasswordValidation errorMessages={errorMessages} passwordStrength={passwordStrength} />
                              )}
                            </div>

                            {/* <p className="mb-2 mt-2 text-left font-14 darkBlue">{errors.password}</p> */}

                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                          </div>
                          <div className="position-relative">
                            <input
                              id="confirmPassword"
                              name="confirmPassword"
                              type={this.state.type}
                              className={
                                'form-control mt-10 password--spacing' +
                                (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')
                              }
                              style={{ fontSize: '1em' }}
                              placeholder="Confirm Password"
                              onChange={e => setFieldValue('confirmPassword', e.target.value)}
                              required
                            ></input>

                            {(!errors.confirmPassword || !touched.confirmPassword) && (
                              <span className="form-side-icon forgot--password--icon" onClick={this.changeState}>
                                {this.state.wording}
                              </span>
                            )}
                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                          </div>
                          <Grid container className="mt-10">
                            <Grid item xs>
                              <div className="clearfix"></div>
                            </Grid>
                          </Grid>

                          <div className="clearfix mt-3" onClick={handleSubmit}>
                            <a id="submit" type="" className="btn btn-block btn-lg btn-primary w-auto" style={{ fontSize: '1em' }}>
                              Submit
                            </a>
                          </div>

                          <Captcha getRefValue={value => this.setState({ recaptchaRef: value })} />

                          {isError && <ErrorComponent errors={errorMessage} />}
                          {isVerifyCaptch && <div className="alert alert-danger">Invalid captach</div>}
                        </Form>
                        <div>
                          <span className="title-text font-14 cursor-pointer">
                            By clicking “Submit”, you confirm that you accept the
                            <span onClick={this.termsHandler} className="ml-1 darkBlue">
                              {'Terms '}
                            </span>
                            of Service &
                            <span onClick={this.privacyPolicyHandler} className="ml-1 darkBlue">
                              {'Privacy Policy'}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser,
  isPasswordReset: state.loginReducer.isPasswordReset,
  isPasswordResetError: state.loginReducer.isPasswordResetError,
  forgotPasswordSuccess: state.loginReducer.forgotPasswordSuccess,
  isError: state.loginReducer.isError,
  errorMessage: state.errorReducer.errorMessage,
  infoData: state.pageInfoByTypeReducer?.pageInfoByType,
})

const mapDispatchToProps = dispatch => ({
  forgotPassword: data => dispatch(forgotPassword(data)),
  clearAuthFlag: () => dispatch(clearAuthorizationFlag()),
  clearForgotPassword: () => dispatch(clearForgotPassword()),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  getAllPageInfoByType: data => dispatch(getAllPageInfoByType(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)
