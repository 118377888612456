import './Payment.scss'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import RSelect from 'react-select'
import { Formik, Form, ErrorMessage } from 'formik'
import { CardExpiryElement, CardNumberElement, CardCvcElement } from '@stripe/react-stripe-js'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import ErrorFocus from '../../components/ws/ErrorFocus'
import { withRentStripe } from '../../hoc/withStripe'
import { CARD_ELEMENT_OPTIONS } from '../../util/enums/stripeEnum'
import { checkIfCanBookRent, clear_checkIfCanBookRent_flag, rentBookingPayment } from '../../redux/actions'
import { Loader } from '../../components'
import { CommonModal } from '../../components/modal/commonModal'

const paymentOptions = [
  {
    value: 'Card',
    label: 'Credit or Debit Card',
  },
]

const cardInitValues = {
  cardNumber: false,
  cardExpiry: false,
  cardCvc: false,
  cardHolderName: '',
  agreement: false,
}

const Payment = ({
  stripe,
  elements,
  precheckData,
  rentBookingPayment,
  rentBookingPaymentLoading,
  agreementContent,
  precheck,
  precheckLoading,
  precheckSuccess,
  precheckFailure,
  clear_precheck,
  onSuccess,
}) => {
  /** @type {[paymentOptions[number], Function]} */
  const [paymentOption, setPaymentOption] = useState(null)
  const [cardHolderName, setCardHolderName] = useState('')
  const [agreementPopupData, setAgreementPopupData] = useState('')

  useEffect(() => {
    if (precheckSuccess) handleCardSubmit()
    if (precheckSuccess || precheckFailure) clear_precheck()
  }, [precheckSuccess, precheckFailure])

  const cardValidationSchema = () =>
    yup.object().shape({
      cardNumber: yup.boolean().oneOf([true], 'Card number is invalid'),
      cardExpiry: yup.boolean().oneOf([true], 'Card expiry is invalid'),
      cardCvc: yup.boolean().oneOf([true], 'Card CVC is invalid'),
      cardHolderName: yup.string().required('Card holder name is required'),
      agreement: yup.boolean().oneOf([true], 'Please accept the agreement'),
    })

  const handleCardElementChange = (e, setFieldError, setFieldValue) => {
    if (e.error) {
      setFieldError(e.elementType, e.error.message)
      setFieldValue(e.elementType, false)
    } else {
      setFieldError(e.elementType, '')
      setFieldValue(e.elementType, true)
    }
  }

  const handlePrecheck = values => {
    setCardHolderName(values.cardHolderName)
    precheck(precheckData)
  }

  const handleCardSubmit = async () => {
    rentBookingPayment(true)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: { name: cardHolderName },
    })

    rentBookingPayment(false)

    if (error) {
      toast.error(error.message, { autoClose: 5000 })
      return
    }

    onSuccess && onSuccess(paymentMethod.id)
  }

  return (
    <>
      <div className="rent-payment-design">
        <div className="pay-opt-box pay-field-box">
          {/* <label className="pay-opt-label pay-field-label">Pay with</label> */}

          {/* <div className="pay-opt-icons">
            <img loading="lazy" src={require('../../assets/images/salesEngine/visa.png')} alt="Visa" />
            <img loading="lazy" src={require('../../assets/images/salesEngine/american.png')} alt="American" />
            <img loading="lazy" src={require('../../assets/images/salesEngine/mc.png')} alt="Mc" />
            <img loading="lazy" src={require('../../assets/images/salesEngine/diner.png')} alt="Diner" />
            <img loading="lazy" src={require('../../assets/images/salesEngine/jcb.png')} alt="Jcb" />
            <img loading="lazy" src={require('../../assets/images/salesEngine/unionPay.png')} alt="Union Pay" />
            <img loading="lazy" src={require('../../assets/images/salesEngine/discover.png')} alt="Discover" />
          </div> */}

          {/* <RSelect
            className="mt-1 pay-opt-field pay-field"
            placeholder="Select Payment Option"
            options={paymentOptions}
            value={paymentOption}
            onChange={val => setPaymentOption(val)}
            styles={{ container: base => ({ ...base, flexBasis: '100%' }) }}
          /> */}
        </div>

        {/* {paymentOption?.value === 'Card' && (
          <> */}
        <Formik
          initialValues={{ ...cardInitValues }}
          enableReinitialize
          validationSchema={cardValidationSchema()}
          onSubmit={handlePrecheck}
        >
          {({ setFieldTouched, setFieldValue, setFieldError, values, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="pay-card-form bg-transparent">
              <ErrorFocus />

              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <div className="pay-card-field">
                    <label>Card Number</label>
                    <div className="pay-card-field-wrp">
                      <CardNumberElement
                        options={{ showIcon: true, style: CARD_ELEMENT_OPTIONS.style }}
                        onChange={e => handleCardElementChange(e, setFieldError, setFieldValue)}
                        onBlur={e => setFieldTouched(e.elementType, true)}
                      />
                    </div>
                    <ErrorMessage component="div" name="cardNumber" className="error-message" />
                  </div>
                </Col>

                <Col md={6} xl={4}>
                  <div className="pay-card-field">
                    <label>Expiry Date</label>
                    <div className="pay-card-field-wrp">
                      <CardExpiryElement
                        options={{ style: CARD_ELEMENT_OPTIONS.style }}
                        onChange={e => handleCardElementChange(e, setFieldError, setFieldValue)}
                        onBlur={e => setFieldTouched(e.elementType, true)}
                      />
                    </div>
                    <ErrorMessage component="div" name="cardExpiry" className="error-message" />
                  </div>
                </Col>

                <Col md={6} xl={2}>
                  <div className="pay-card-field">
                    <label>CVC</label>
                    <div className="pay-card-field-wrp">
                      <CardCvcElement
                        options={{ style: CARD_ELEMENT_OPTIONS.style }}
                        onChange={e => handleCardElementChange(e, setFieldError, setFieldValue)}
                        onBlur={e => setFieldTouched(e.elementType, true)}
                      />
                    </div>
                    <ErrorMessage component="div" name="cardCvc" className="error-message" />
                  </div>
                </Col>

                <Col xs={12} xl={6}>
                  <div className="pay-card-field">
                    <label>Card Holder Name</label>
                    <div className="pay-card-field-wrp">
                      <input
                        type="text"
                        name="cardHolderName"
                        className="reset-input"
                        placeholder="Card Holder Name"
                        value={values.cardHolderName}
                        onChange={e => {
                          setFieldValue('cardHolderName', e.target.value)
                          e.target.value || setFieldError('cardHolderName', 'Card Holder Name is required')
                        }}
                        onBlur={e => setFieldTouched('cardHolderName', true)}
                      />
                    </div>
                    <ErrorMessage component="div" name="cardHolderName" className="error-message" />
                  </div>

                  <div className="form-check mt-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={values.agreement}
                      onChange={e => setFieldValue('agreement', e.target.checked)}
                    />
                    <label className="form-check-label color-black">
                      I agree to the{' '}
                      <a style={{ textDecoration: 'underline' }} onClick={() => setAgreementPopupData(agreementContent)}>
                        Terms & Conditions
                      </a>
                      .
                    </label>
                  </div>

                  <ErrorMessage component="div" name="agreement" className="error-message" />
                </Col>

                <Col xs={6}>
                  <div className="d-flex justify-content-end align-items-end h-100">
                    <button type="submit" className="btn btn-primary width" style={{ padding: '13px 17px', fontSize: 20 }}>
                      Confirm &amp; Pay
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        {/* </>
        )} */}
      </div>

      <CommonModal
        open={!!agreementPopupData}
        close={() => setAgreementPopupData('')}
        title="Rent and Charter Agreement"
        htmlData={agreementPopupData}
        className="w-90"
      />

      {(precheckLoading || rentBookingPaymentLoading) && <Loader isPageLoader />}
    </>
  )
}

const mapStateToProps = state => ({
  precheckLoading: state.rentReducer.canBookRentLoading,
  precheckSuccess: state.rentReducer.canBookRentSuccess,
  precheckFailure: state.rentReducer.canBookRentFailure,
  rentBookingPaymentLoading: state.rentReducer.rentBookingPaymentLoading,
})

const mapDispatchToProps = dispatch => ({
  precheck: data => dispatch(checkIfCanBookRent(data)),
  clear_precheck: () => dispatch(clear_checkIfCanBookRent_flag()),
  rentBookingPayment: loading => dispatch(rentBookingPayment(loading)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRentStripe(Payment))
