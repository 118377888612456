import { chatType, getUniqueListBy } from '../../util/chat'
import searchFilter from '../../helpers/filterUtil.js'
import {
    //User
    GET_USERS_LIST,
    GET_USERS_LIST_SUCCESS,

    //  Chat
    GET_CHAT_LIST_SUCCESS,

    //  Me
    GET_CHAT_ME_SUCCESS,

    // Crate by chat id
    SUCCESS_CREATED_CHAT_BY_ID,
    SET_SELECT_USER,
    SET_MESSAGE,

    /**
     * Create Message by chat Id
    */
    CREATE_MESSAGE_BY_CHAT_ID,
    CREATE_MESSAGE_BY_CHAT_ID_SUCCESS,
    CREATE_MESSAGE_BY_CHAT_ID_FAILURE,
    /**
     * Get Message by chat Id
     */
    GET_MESSAGE_BY_CHAT_ID,
    GET_MESSAGE_BY_CHAT_ID_SUCCESS,
    GET_MESSAGE_BY_CHAT_ID_FAILURE,

    // Suscription
    GET_NEW_MESSAGE_BY_CHAT_ID_SUCCESS,

    CLEAR_CHAT_THREAD,
    CHAT_IMAGES_UPLOADED,
    START_MESSAGE_BY_CHAT_ID,
    CLEAR_CHAT_WHILE_LOG_OUT,
    CLEAR_CHAT_CREATED_CHAT,
    SET_ONLINE_USER,
    CREATE_CHAT_BY_ID,
    SET_GROUP_CHAT_FLAG,
    SET_SELECTED_GROUP_IDS,
    SET_GROUP_NAME_SCREEN,
    SET_USER_LIST_PAGE,
    SET_USER,
    DELETE_SINGLE_USER_CHAT_SUCCESS,
    EDIT_GROUP_SCREEN,
    ADD_OR_REMOVE_USERS_FROM_GROUP_SUCCESS,
    READ_MESSAGE_BY_CHAT_ID_SUCCESS,
    GET_FRIEND_USERS_LIST,
    GET_FRIEND_USERS_LIST_SUCCESS,
    BACK_TO_CHAT_LIST,
    CHAT_TOGGLE,
    BLOCK_USER_SUCCESS,
    BLOCK_USER_FAILURE,
    BLOCK_USER,
    INITIAL_CHAT_ID,
    SET_MODAL_VIEW,
    GET_FRIEND_USERS_LIST_FAILURE,
    GET_CHAT_LIST,
    GET_CHAT_LIST_FAILURE,
    SET_FIRST_TIME_CHAT,
    GET_USERS_LIST_FAILURE,
    CREATE_CHAT_BY_ID_FAILURE,
    CLEAR_USER_SEARCH,
    READ_MESSAGE_BY_CHAT_ID,
    READ_MESSAGE_BY_CHAT_ID_FAILURE,
    SET_TOGGLE_HEADER_MANAGE,
    SET_TEXT_AREA_HEIGHT,
    CHAT_VIDEO_UPLOADED_FAILD,
    SET_ONLINE_USERS,
    ADD_MESSAGE_BY_CHAT_ID,
    DELETE_SINGLE_MESSAGE_CHAT,
    SET_READ_MESSAGE,
    SET_DELIVERED_MESSAGE,
    SET_UNREAD_COUNT,
    CLEAR_CHAT_THREAD_SUCCESS,
    IS_TEXT_FALSE,
    UPDATE_MESSAGE_ID,
    SET_LASTACTIVE_TIME,
    UPDATE_CHAT_SUCCESS,
    SET_FIRSTTIMECREATECHATIDNULL,
    SET_FRIEND_LIST,
    SET_CHAT_LIST,
    GET_CHAT_USERS_SUCCESS,
    SET_SELECT_USER_AFTER_ADDORREMOVE,
} from '../actionTypes'
import { MessageTypeEnum } from '../../util/enums/enums'
import { socket } from '../../socket'
import { socketEvent } from '../../util/enums/socketEnums'

const initialState = {
    users: [],
    chatList: [],
    userList: [],
    friendList: [],
    friendSearchList: [],
    chats: [],
    threadMessage: [],
    currentUser: {},
    selectUser: {},
    onlineUsers: {},
    firstTimeCreateChatId: null,
    isReadMessage: false,
    messagePayload: '',
    isMessageLoading: false,
    isCreatedChatMessage: false,
    isFriendView: false,
    isLeftSideLoader: false,
    messageId: null,
    isTextMessage: false,
    hasChatCreated:false,
    isLogout: false,
    selectedGroupIds: [],
    isGroupChatCreateScreen: false,
    isEditGroupScreen: false,
    isUpdateGroup: false,
    isBlockDisabled: false,
    isDeleMessage: false,
    isChatCreatedFail: false,
    isChatShow: false,
    isToggleManage: false,
    isNewMessageRead: false,
    isGetMessage: false,
    isGalleryOpen: false,
    isFirstTime: true,
    userProfileChatId: null,
    userListPage: 1,
    chatListPage: 1,
    userListSearch: '',
    textareaHeight: 78,
}

export const chatReducer = (state = initialState, action) => {

    switch (action.type) {
        /**
         * Get User list by search with chat
         *
         */
        case CLEAR_USER_SEARCH:
            return {
                ...state,
                userListSearch: '',
                userList: [],
                users: [],
            }

        case GET_USERS_LIST:
            return {
                ...state,
                isLeftSideLoader: true,
                userListSearch: action.payload.searchTerm
            }

        case SET_CHAT_LIST:
            console.log(action.payload,"called");
            return {
                ...state,
                users: action.payload.list,
                userListSearch:action.payload.searchTerm,
                isLeftSideLoader: false,
                chats:action.payload.list,
                userList:action.payload.list,
            }


        case GET_USERS_LIST_SUCCESS:
            // const { users, chats } = action.payload
            // console.log(users,"users")
            // console.log(chats,"chats")
            // const finalResults = chats.map(chat => {
            //     const matchUser = action.payload.find(m => chat.members.map(m => String(m.id)).includes(m.id) && chat.chatType === chatType.normalchat);
            //     const otherUser = chat.members?.find((member) => member.id !== state.currentUser.id)
            //     return {
            //         ...chat,
            //         ...matchUser,
            //         chatId: chat.id,
            //         metaData: otherUser?.metaData,
            //         userId: otherUser?.id,
            //         //Need to check id
            //         id: chat?.id,
            //         ...(chat?.chatType === chatType.normalchat && { profileImage: otherUser?.profileImage }),
            //         appUserId: otherUser?.appUserId,
            //         isUserBlocked: false,
            //     }
            // })
            // const usersList = users.map((i) => {
            //     const otherUser = i.members?.find((member) => member.id !== state.currentUser.id)
            //     return {
            //         ...i,
            //         userId: i?.id,
            //         label: i.name,
            //         value: i.id,
            //         isUserBlocked: false,
            //         ...(i?.chatType === chatType.normalchat && { lastActiveTime: otherUser?.lastActiveTime }),
            //         ...(i?.chatType === chatType.normalchat && { image: otherUser?.image }),
            //         appUserId: i?.appUserId,
            //     }
            // })
            // const chatPrepareList = finalResults.length ? getUniqueListBy([...usersList, ...finalResults], 'id') : users
            // return {
            //     ...state,
            //     users: usersList,
            //     isLeftSideLoader: false,
            //     chats,
            //     ...(!state.isGroupChatCreateScreen && { userList: searchFilter(chatPrepareList, state.userListSearch, 'name') })
            // }

            const temp = state.chatList.filter((chat)=>{
                return (
                  chat?.name?.toLocaleLowerCase().includes(state.userListSearch.toLocaleLowerCase()))
                }).map(data => ({ ...data, appUserId: data.users?.find(u => u.appUserId !== state.currentUser.appUserId)?.appUserId }))
             const chatPrepareList = temp.length && !state.isGroupChatCreateScreen?getUniqueListBy([...action.payload, ...temp], 'appUserId'):action.payload

            return {
                ...state,
                users: chatPrepareList,
                isLeftSideLoader: false,
                // chatList:action.payload,
                chats:chatPrepareList,
                ...(!state.isGroupChatCreateScreen && { userList:searchFilter(chatPrepareList, state.userListSearch, 'name') })
            }

        case GET_CHAT_LIST_FAILURE:
        case GET_USERS_LIST_FAILURE:
            return {
                ...state,
                isLeftSideLoader: false,
                users: [],
                chatList: [],
                userList: [],
                chats: [],
            }


        /**
        * Get Current User details
        *
        */
        case READ_MESSAGE_BY_CHAT_ID:
            return {
                ...state,
                isReadMessage: true,
                userProfileChatId:null,
            }
        case READ_MESSAGE_BY_CHAT_ID_SUCCESS:
            return {
                ...state,
                isReadMessage: false,
            }
        case READ_MESSAGE_BY_CHAT_ID_FAILURE:
            return {
                ...state,
                isReadMessage: false,
            }
        case GET_CHAT_ME_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
                isLogout: false,
            }

        case SET_SELECT_USER_AFTER_ADDORREMOVE:
            let newUsersWithAction=state.selectUser.users;
            if(action.payload[0].action==="Add"){
                newUsersWithAction=[...newUsersWithAction,action.payload[0].users[0]]
            }else{
                newUsersWithAction=newUsersWithAction.filter((user)=>user.id !==action.payload[0].users[0].id)
            }
            const updateChatIndex= state.chatList.findIndex((chat)=>(chat.chatId===state.selectUser.chatId))
            let updateChatList = state.chatList
            if(updateChatIndex>=0){
                updateChatList[updateChatIndex]={...updateChatList[updateChatIndex],users:newUsersWithAction}
            }

            return{
                ...state,
                chatList:updateChatList,
                selectUser:{
                    ...state.selectUser,
                    users:newUsersWithAction
                }
            }

        /**
         *  Get Messages from current selected chat user
         */
        // case START_MESSAGE_BY_CHAT_ID:

        case ADD_MESSAGE_BY_CHAT_ID:
            return {
                ...state,
                threadMessage: [{...action.payload,...(!action.payload.createdAt&&{createdAt:new Date().toISOString()})},...state.threadMessage],
                isMessageLoading: true,
                userProfileChatId:null,
                isGetMessage: false,
            }
        case UPDATE_MESSAGE_ID:
            const newThreadWithId = state.threadMessage
            newThreadWithId[0]={...newThreadWithId[0],...action.payload}

            return {
                ...state,
                threadMessage: newThreadWithId,
                firstTimeCreateChatId:null,
                isMessageLoading: false,
                userProfileChatId:null,
                isGetMessage: false,
            }
        case GET_MESSAGE_BY_CHAT_ID:
            return {
                ...state,
                isMessageLoading: true,
                userProfileChatId:null,
                isGetMessage: false,
            }

        case GET_MESSAGE_BY_CHAT_ID_SUCCESS:

            return {
                ...state,
                threadMessage: [...state.threadMessage,...action.payload],
                hasChatCreated:false,
                isMessageLoading: false,
                isGetMessage: true,
            }

        case GET_MESSAGE_BY_CHAT_ID_FAILURE:
            return {
                ...state,
                isMessageLoading: false,
                isGetMessage: false,
                threadMessage: [],
            }

        /**
         *  Get Chat list for current user
         *
        */

        case GET_CHAT_LIST:
            return {
                ...state,
                isLeftSideLoader: true,
            }

        case GET_CHAT_LIST_SUCCESS:
            let chatList = action.payload.filter(i => i?.users?.some(u => u?.id !== state.currentUser.id)).map((i) => {
                const [{id}]=i.users.filter((user)=>user.id!==state.currentUser.id)
                return {
                    ...i,
                    chatId:i.id,
                    isOnline:i?.onlineUsers?.filter((ele)=>ele!==state.currentUser.id).length>0,
                    userId: id,
                    lastUpdateTime:i?.lastMsgId?.createdAt?? i?.updatedAt
                }
            })
            let updatedSelecteUser={}
            if(state.selectUser.chatId){
                updatedSelecteUser=chatList.find((chat)=>chat.chatId===state.selectUser.chatId)
            }
            chatList.sort((a,b)=>  new Date(b?.lastUpdateTime)-new Date(a?.lastUpdateTime))

            return {
                ...state,
                chatList,
                chats: action.payload,
                isUpdateGroup: false,
                isLeftSideLoader: false,
                isFirstTime: false,
                selectUser:updatedSelecteUser
            }

        /**
         * Create Chat By selected user id
         *  return  chat id
         *
         */
        case CREATE_CHAT_BY_ID:
            return {
                ...state,
                // firstTimeCreateChatId: null,
                isChatCreatedFail: false,
                userListSearch: '',
                selectedGroupIds: [],
                selectUser: action.payload.chatType === chatType.groupChat ? initialState.selectUser : {
                    ...state.selectUser,
                    imageUrl:action.payload?.imageUrl,
                    userId: action.payload.membersId?.find(Boolean),
                }
            }
        case SUCCESS_CREATED_CHAT_BY_ID:
            const hasChatCreated=state.chatList.filter((chat)=>(chat.chatId===action?.payload.id))
            const addChatId = {
                ...action.payload,
                // ...(action.payload.chatType === chatType.normalchat && { profileImage: otherUserMember?.profileImage }),
                chatId: action.payload?.id,
                isOnline: action.payload?.onlineUsers?.filter((user)=>user!==state.currentUser.id).length>0 ?? false,
                userId: action.payload?.lastActiveTimeList[0]?.userId,
                lastActiveTime:action.payload?.lastActiveTimeList[0]?.lastActive,
                // imageUrl:action.payload?.imageUrl?action.payload?.imageUrl:action.payload?.profileImage,
                lastUpdateTime:action.payload?.lastMsgId?.createdAt?? new Date().toISOString(),
                imageUrl:action.payload.imageUrl??state.selectUser.imageUrl,
                metaData: action.payload?.metaData,
                appUserId: action.payload?.appUserId,
                last4Emoji:action.payload?.last4Emoji,
                ...(action.payload?.chatType===chatType.groupChat&&{createdBy:{id:state.currentUser.id}})
            }
            let newCreateChats = [...state.chatList]
            let newMetaData
            if(!hasChatCreated.length>0){
                const otherNewUser = action.payload.users.filter((user)=>user.id!==state.currentUser.id);
                if(otherNewUser?.length>0){
                    newMetaData=otherNewUser[0].metaData
                }
                newCreateChats=[...[addChatId], ...state.chatList]
            }

            return {
                ...state,
                isFriendView: false,
                firstTimeCreateChatId: action.payload?.id,
                hasChatCreated:hasChatCreated.length>0?true:false,
                selectUser: {
                    ...state.selectUser,
                    ...action.payload,
                    chatId: action.payload.id,
                    userId: action.payload?.lastActiveTimeList[0]?.userId,
                    appUserId: action.payload?.appUserId,
                    // imageUrl:action.payload?.imageUrl?action.payload?.imageUrl:action.payload?.profileImage,
                    isOnline: action.payload?.onlineUsers?.filter((user)=>user!==state.currentUser.id).length>0 ?? false,
                    last4Emoji: action.payload?.last4Emoji,
                    ...(action.payload?.chatType===chatType.groupChat&&{createdBy:{id:state.currentUser.id}}),
                    ...(action.payload.chatType === chatType.normalchat && { imageUrl:hasChatCreated.length>0?hasChatCreated[0].imageUrl:action.payload.imageUrl??state.selectUser.imageUrl }),
                    ...(hasChatCreated.length>0 && hasChatCreated[0]),
                    ...(action.payload?.metaData ? {metaData: action.payload?.metaData}:{metaData:newMetaData})
                    // ...(action.payload.chatType === chatType.groupChat && { groupName: action.payload?.members?.map((i) => i.name) }),
                },
                chatList: newCreateChats,
                users: [],
                threadMessage: [],
            }

        case CREATE_CHAT_BY_ID_FAILURE:
            return {
                ...state,
                isChatCreatedFail: true,
            }
        case SET_FIRSTTIMECREATECHATIDNULL:
            return {
                ...state,
                firstTimeCreateChatId:null
            }
        /**
         * Create Message by Chat id
         */

        case CHAT_IMAGES_UPLOADED:
            return {
                ...state,
                isTextMessage: true,
            }
        case IS_TEXT_FALSE:
            return{
                ...state,
                isTextMessage: false,
            }

        case CHAT_VIDEO_UPLOADED_FAILD:
            return {
                ...state,
                isTextMessage: false,
            }

        case CREATE_MESSAGE_BY_CHAT_ID:

            const copyThreadMessage = [
                ...state.threadMessage,
                ...[{
                    id:"",
                    text: action.payload.messageType===MessageTypeEnum.TEXT ? action.payload.messageBody:"",
                    audioVideo: action.payload.messageType===MessageTypeEnum.AUDIO?action.payload.mediaUrl:"",
                    metaData: {
                        messageId: action.payload.metaData.messageId,
                        // isVideo: Boolean(action.payload.meta?.isVideo),
                    },
                    images: action.payload.messageType===MessageTypeEnum.IMAGE?action.payload.mediaUrl:"",
                    me: true,
                    createdAt: new Date(),
                    isRead: false,
                    isDelivered: false,
                }]]


            return {
                ...state,
                isMessageLoading: true,
                isGetMessage: false,
                userListSearch: '',
                isDeleMessage: false,
                // firstTimeCreateChatId: null,
                isTextMessage: !Boolean(action.payload.messageType===MessageTypeEnum.TEXT),
                messageId: action.payload.metaData.messageId,
                isCreatedChatMessage: false,
                threadMessage: copyThreadMessage,
                selectUser: {
                    ...state.selectUser,
                    messageId: action.payload.metaData.messageId,
                }
            }

        case CREATE_MESSAGE_BY_CHAT_ID_SUCCESS:
            const filterThread = state.threadMessage.filter((i) => !i.metaData?.messageId?.includes(action.oldMeta?.messageId))
            const copyThreadMessages = [...filterThread, ...[action.payload.message]]
            return {
                ...state,
                isMessageLoading: false,
                isCreatedChatMessage: true,
                isGetMessage: true,
                isTextMessage: false,
                threadMessage: copyThreadMessages,
            }

        case CREATE_MESSAGE_BY_CHAT_ID_FAILURE:
            return {
                ...state,
                isMessageLoading: false,
                isTextMessage: false,
                isCreatedChatMessage: false,
                isGetMessage: false,
            }
        /**
         *  Get new message by subscription
         *
         */


        case SET_LASTACTIVE_TIME:
        let filteredUser  = state.selectUser.lastActiveTimeList
        if(
            action.payload?.filter(chat=>chat.userId===state.selectUser?.userId).length>0
        ){
            filteredUser= action.payload?.filter(chat=>chat.userId===state.selectUser?.userId)
        }
            return {
                ...state,
                selectUser:{...state.selectUser,lastActiveTimeList:filteredUser},
                chatList: state.chatList.map((i) => {
                    const currentChat= action.payload?.filter(chat=>chat.userId===i?.userId)[0]
                    return {
                        ...i,
                        lastActiveTimeList: currentChat?[{...i?.lastActiveTimeList?.[0],lastActive:currentChat?.lastActive}]:i.lastActiveTimeList,
                    }
                })
            }

        case GET_NEW_MESSAGE_BY_CHAT_ID_SUCCESS:
            const messages = [...state.threadMessage]
            const isExist = action.payload?.meta?.messageId ? state.threadMessage.find((i) => i.metaData?.messageId?.includes(action.payload?.metaData?.messageId)) : null
            const index = messages.findIndex((obj => obj.metaData?.messageId?.includes(action.payload?.metaData?.messageId)));
            messages[index] = action.payload
            const copyThreadMessagesNew = isExist ? [...messages] : [...state.threadMessage, ...[action.payload]]
            return {
                ...state,
                isNewMessageRead: !state.isNewMessageRead,
                isDeleMessage: false,
                threadMessage: [state?.selectUser?.chatId].includes(action.payload?.metaData?.chatId) ? copyThreadMessagesNew : [...state.threadMessage],
            }

            case SET_UNREAD_COUNT:
                const setUnreadArray = [...state.chatList]
                        const unreadIndex = setUnreadArray.findIndex((obj => action.payload.chatId===obj.chatId));
                        if(unreadIndex>-1){
                            setUnreadArray[unreadIndex] = {
                                ...setUnreadArray[unreadIndex],
                                ...((!action.payload.unreadCounts ||action.payload.chatId!==state.selectUser.chatId)&&{unreadCounts:action.payload.unreadCounts}),
                                ...(action.payload.imageUrl && {imageUrl:action.payload.imageUrl}),
                                ...(action.payload.name && {name:action.payload.name}),
                                ...(action.payload.lastMsgId && {lastMsgId:action.payload.lastMsgId})
                            }
                        }


                return {
                    ...state,
                    isDeleMessage: false,
                    chatList: setUnreadArray,
                }

            case SET_READ_MESSAGE:
                const messages1 = [...state.threadMessage]
                action.payload.msgIds.forEach((msg)=>{
                    const index = messages1.findIndex((obj => msg===obj.id));
                    if(index>0){
                        messages1[index] = {
                            ...messages1[index],
                            isReadByAll:true,
                        }
                    }else if(messages1[0]?.id){
                        messages1[0] = {
                            ...messages1[0],
                            isReadByAll:true,
                        }
                    }

                })
                return {
                    ...state,
                    isDeleMessage: false,
                    threadMessage: messages1,
                }

            case SET_DELIVERED_MESSAGE:
                    const allMessages = [...state.threadMessage]
                    action.payload.msgIds.forEach((msg)=>{
                        const index = allMessages.findIndex((obj => msg===obj.id));
                        if(index>=0){
                            allMessages[index].isDelivered =true
                        }else{
                            if(state.selectUser.isOnline && allMessages.length>0 ){
                                allMessages[0].isDelivered =true
                            }
                        }
                    })


                    return {
                        ...state,
                        isDeleMessage: false,
                        threadMessage: allMessages,
                    }


        case SET_SELECT_USER:
            return {
                ...state,
                selectUser: action.payload,
                isDeleMessage: false,
                threadMessage: [],
            }

        case SET_ONLINE_USER:
            const filteredArray = action.payload?.filter(chat=>chat.chatId===state.selectUser.chatId)[0]
            return {
                ...state,
                selectUser: {
                    ...state.selectUser,
                    ...(state.selectUser?.id &&{ isOnline: filteredArray?filteredArray?.count?.filter((element) => element !== state.currentUser.id)?.length>0:state.selectUser.isOnline}),
                },
                chatList: state.chatList.map((i) => {
                    const currentChat= action.payload?.filter(chat=>chat.chatId===i.id)[0]
                    // if(currentChat?.count.includes(state.currentUser.id)){
                        return {
                            ...i,
                            isOnline: currentChat?currentChat?.count?.filter((element) => element !== state.currentUser.id)?.length>0:i.isOnline,
                        }
                    // }
                })
            }
        case SET_MESSAGE:
            return {
                ...state,
                messagePayload: action.payload,
            }
        case SET_TEXT_AREA_HEIGHT:
            return {
                ...state,
                textareaHeight: action.payload,
            }
        case SET_GROUP_CHAT_FLAG:
            return {
                ...state,
                isGroupChatCreateScreen: action.payload,
            }
        case SET_SELECTED_GROUP_IDS:
            return {
                ...state,
                selectedGroupIds: action.payload,
            }
        case SET_GROUP_NAME_SCREEN:
            return {
                ...state,
                isAddGroupName: action.payload,
            }
        case SET_USER_LIST_PAGE:
            return {
                ...state,
                userListPage: action.payload,
            }
        case ADD_OR_REMOVE_USERS_FROM_GROUP_SUCCESS:
            const chatUserLists = [...state.chatList]
            const chatsFilter = chatUserLists.filter((i) => i._id !== action.payload.id)
            const chatIdAdd = { ...action.payload, chatId: action.payload.id }
            const finalList = [...[chatIdAdd], ...chatsFilter]

            return {
                ...state,
                isUpdateGroup: true,
                isEditGroupScreen: false,
                userListSearch: '',
                isGroupChatCreateScreen: false,
                chatList: finalList,
                ...(action.payload.chatType === chatType.groupChat && { users: [] }),
                selectUser: {
                    ...state.selectUser,
                    ...action.payload,
                    name: action.payload?.name,
                    groupName: action.payload?.members?.map((i) => i.name),
                }
            }
        case UPDATE_CHAT_SUCCESS:
            return {
                ...state,
                isUpdateGroup: true,
                isEditGroupScreen: false,
                userListSearch: '',
                isGroupChatCreateScreen: false,
                // chatList:newUpdatedChat,
            }

        case EDIT_GROUP_SCREEN:
            const chatId = state.selectUser.chatId
            const { id: currentUserID } = state.currentUser
            const memberWithoutCurrentUser = state.selectUser?.users?.filter((i) => i.id!== currentUserID)
            const selectedGroupMember = memberWithoutCurrentUser.map((i) => {
                return {
                    ...i,
                    label: i.name,
                    id: i.id,
                    userId: i.id,
                    value: i.id,
                    chatId,
                }
            })

            return {
                ...state,
                isGroupChatCreateScreen: action.payload,
                // memberWithoutCurrentUser,
                isEditGroupScreen: action.payload,
                selectedGroupIds: selectedGroupMember,
                userList: [],
                users: [],
            }
        case CLEAR_CHAT_WHILE_LOG_OUT:
            return {
                ...state,
                ...initialState,
                isLogout: true,
            }
        case CLEAR_CHAT_CREATED_CHAT:
            return {
                ...state,
                isCreatedChatMessage: false,
            }

        case BACK_TO_CHAT_LIST:
            return {
                ...state,
                // may be remove
                chatList: [...state.chatList],
                userListSearch: '',
                isFriendView: action.payload,
            }
        /**
         * Get Contact list which current user added
         */
        case GET_FRIEND_USERS_LIST:
            return {
                ...state,
                isFriendView: false,
                isLeftSideLoader: true,
            }

        case SET_FRIEND_LIST:
        case GET_FRIEND_USERS_LIST_SUCCESS:
            const userIds = state.chatList?.map((i) => {
                return {
                    ...i,
                    appUserId: i.appUserId,
                    chatId: i.id,
                }
            })
            return {
                ...state,
                isFriendView: true,
                isLeftSideLoader: false,
                userListSearch: '',
                friendList: action.payload?.map((i) => {
                    const hasChatUser = userIds.find((u) => u.userIds?.includes(i.id))
                    return {
                        ...i,
                        isOnline: false,
                        image: i?.image?.url??i?.image,
                        isUserBlocked: false,
                        lastActiveTime: null,
                        appUserId: i?.id ?? i?._id,
                        name: `${i.firstName} ${i.lastName}`,
                        imageUrl:i.image?.url??i?.image,
                        userId: i.id,
                        ...(hasChatUser && {
                            chatId: hasChatUser.chatId,
                            isOnline: hasChatUser?.isOnline,
                            last4Emoji: hasChatUser?.last4Emoji,
                            metaData: hasChatUser?.metaData,
                            imageUrl:hasChatUser.image?.url??i?.image,
                            isUserBlocked: hasChatUser?.isUserBlocked,
                            chatType: chatType.normalchat,
                        }),
                    }
                }),
                selectUser: initialState.selectUser,
            }

        case GET_FRIEND_USERS_LIST_FAILURE:
            return {
                ...state,
                isLeftSideLoader: false,
            }

        case SET_USER:
            let chatExists=false
            let newArray = [...state.chatList]
            state?.chatList.forEach((obj,id)=> {
                if(obj.chatId===action.payload.chatId){
                    newArray[id]={...newArray[id],lastMsgId:{...newArray[id].lastMsgId,...action.payload},lastUpdateTime:new Date().toISOString(),...(action.payload.onlineUsers&&{isOnline:action.payload?.onlineUsers?.filter((user)=>user!==state.currentUser.id).length>0})}
                    chatExists=true;
                }
            })
            let newSelectedUser = {...state.selectUser}
            if(state.selectUser.chatId===action.payload.chatId && action.payload?.lastMsgId&& action.payload?.lastMsgId?.sender!==state.currentUser.id){
                // const newUsers=state.selectUser.user.filter((user))
                newSelectedUser={...state.selectUser,imageUrl:action.payload.imageUrl?action.payload.imageUrl:state.selectUser?.profileImage,isOnline:action.payload.onlineUsers?.filter((user)=>user!==state.currentUser.id).length>0}
            }
            newArray.sort((a,b)=>  new Date(b.lastUpdateTime)-new Date(a.lastUpdateTime))
            return {
                ...state,
                chatList:!chatExists?[{...action.payload,lastUpdateTime:action.payload?.lastMsgId?.createdAt?? action.payload?.updatedAt,id:action.payload.chatId,isOnline:action.payload?.onlineUsers?.filter((user)=>user!==state.currentUser.id).length>0 ?? false},...state.chatList]:newArray,
                selectUser:newSelectedUser
            }
        case DELETE_SINGLE_USER_CHAT_SUCCESS:
            const deleteChats =false ?
            state.chatList.map((i) => {
                if (action.payload.includes(i.chatId)) {
                    return {
                        ...i,
                        lastMessage: '',
                    }
                }
                return i
            }) : state.chatList.filter((i) => !action.payload.includes(i.chatId))
            return {
                ...state,
                threadMessage: [],
                ...(!action.payload.isClear && { selectUser: initialState.selectUser, }),
                chatList: deleteChats,
            }

        case DELETE_SINGLE_MESSAGE_CHAT:
            const newThread=state.threadMessage.filter((i) => !action.payload.includes(i.id))
            return {
                ...state,
                isDeleMessage: true,
                threadMessage: newThread,
            }

        /**
         * Block User
         *
         */
        case BLOCK_USER:
            return {
                ...state,
                isBlockDisabled: true,
            }
        case BLOCK_USER_SUCCESS:
            return {
                ...state,
                isBlockDisabled: false,
                chatList: state.chatList.map((i) => {
                    if(state.selectUser?.chatId === i?.chatId){
                        return {
                            ...i,
                            isBlocked: !i.isBlocked,
                        }
                    }
                    return i

                }),
                selectUser: {
                    ...state.selectUser,
                    isBlocked: !state.selectUser.isBlocked,
                }
            }
        case BLOCK_USER_FAILURE:
            return {
                ...state,
                isBlockDisabled: false,

            }
        case SET_MODAL_VIEW:
            return {
                ...state,
                isGalleryOpen: action.payload,
            }

        //  Clear Current thread
        case CLEAR_CHAT_THREAD_SUCCESS:
            return {
                ...state,
                threadMessage: [],
            }
        case CHAT_TOGGLE:

            return {
                ...state,
                isChatShow: action.payload,
                selectUser: initialState.selectUser,
                isFriendView: initialState.isFriendView,
            }
        case GET_CHAT_USERS_SUCCESS:
            const otherUser=action.payload.filter((user)=>user.id!==state.currentUser.id)

            const newSelectedUserObj=state.selectUser.chatType===chatType.groupChat?{
                ...state.selectUser,
                users:action.payload}:{
                    ...state.selectUser,
                    ...otherUser[0]}

            return {
                ...state,
                selectUser:newSelectedUserObj
            }

        case SET_TOGGLE_HEADER_MANAGE:
            return {
                ...state,
                isToggleManage: action.payload,
            }

        case SET_FIRST_TIME_CHAT:
            return {
                ...state,
                isFirstTime: action.payload,
            }
        case SET_ONLINE_USERS:
            return {
                ...state,
                onlineUsers: {
                    ...(state.onlineUsers || {}),
                    ...(action.payload?.length ? action.payload : []).reduce((o, u) => {
                        o[u.appUserId] = u.isOnline
                        return o
                    }, {})
                }
            }
        default:
            return state
    }
}
