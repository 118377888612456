import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getCityWiseBoats } from '../../../redux/actions'
import { shareModule, titleEnums } from '../../../util/enums/enums'
import BoatListingsWithMap from '../../../components/gridComponents/BoatListingsWithMap'
import { shareDetailsWithUsers } from '../../../redux/actions/shareAction'
import { shareWithUsers, showAllTitle } from '../../../helpers/jsxHelper'

class CityWiseBoats extends Component {
  state = {
    country: '',
  }

  static getDerivedStateFromProps(nextProps) {
    const { match } = nextProps
    const { params } = match && match

    if (params && params.hasOwnProperty('country') && params.country) {
      return {
        country: params.country,
      }
    }
    return null
  }

  shareBoatWithUsers = data => {
    const { shareDetailsWithUsers } = this.props
    const object = shareWithUsers(data, shareModule.boat)
    shareDetailsWithUsers(object)
  }

  render() {
    const { cityWiseBoats, getCityWiseBoats, totalCityWiseBoats, isPageLoader } = this.props
    const { country } = this.state

    const title = showAllTitle(titleEnums.isCityWiseBoat, totalCityWiseBoats || 0, `${country}`)

    return (
      <BoatListingsWithMap
        showAllTitle={title}
        isPageLoader={isPageLoader}
        boatsType={`${country}`}
        boatsTypeCount={totalCityWiseBoats}
        boats={cityWiseBoats}
        shareWithUsers={data => this.shareBoatWithUsers(data)}
        action={getCityWiseBoats}
        value={{ country, city: '' }}
      />
    )
  }
}

const mapStateToProps = state => ({
  cityWiseBoats: state.boatReducer.cityWiseBoats,
  totalCityWiseBoats: state.boatReducer.totalCityWiseBoats,
  isPageLoader: state.boatReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  getCityWiseBoats: data => dispatch(getCityWiseBoats(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CityWiseBoats)
