import React, { Fragment, useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'

import UserContext from '../../UserContext'
import SharePopup from '../share/SharePopup'
import { CommonTooltip } from '../CommonTooltip'
import { shareModule, iconBoldImages, chatLIcon } from '../../util/enums/enums'
import { v4 as uuid } from 'uuid'
import OutsideClickHandler from 'react-outside-click-handler'
import { initiateChat } from '../../helpers/mqttHelper'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { connect, useDispatch } from 'react-redux'
import { chatToggle, createChatById } from '../../redux/actions/chatAction'
import { chatType } from '../../util/chat'
import VerificationModal from '../../containers/verificationModal'
import LoginModal from '../modal/loginModal'
import { toggleCookiePolicyModal } from '../../redux/actions'

export const ShareWithChatAndUser = connect(
  state => ({
    currentUser: state?.loginReducer?.currentUser,
    cookiePolicyStatus: state?.loginReducer?.cookiePolicyStatus,
  }),
  dispatch => ({
    toggleCookiePolicyModal: data => dispatch(toggleCookiePolicyModal(data)),
  })
)(
  withRouter(
    ({ userId, className, history, name, currentUser, imageUrl, cookiePolicyStatus, toggleCookiePolicyModal, noShare }) => {
      const [selectedIndex, setSelectedIndex] = React.useState(null)
      const [loginModal, setLoginModal] = React.useState(false)

      const dispatch = useDispatch()

      const handlerLoginModal = () => setLoginModal(!loginModal)

      const {
        addOrRemoveFriend,
        currentUser: { friends },
      } = useContext(UserContext)

      const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)

      useEffect(() => {
        isVerificationModalOpen && setIsVerificationModalOpen(false)
      }, [isVerificationModalOpen])

      const handleInitiateChat = async (isRedirect = true) => {
        const res = await initiateChat(userId)

        if (res?.message === 'success' && history && isRedirect) {
          history.push('/chat')
        }
        return
      }

      const handleAddRemoveFriend = () => {
        handleInitiateChat(false)
        addOrRemoveFriend(userId)
      }

      const isFriend = friends?.map(friend => (typeof friend === 'object' ? friend?.id : friend)).includes(userId)

      return (
        <Fragment>
          <LoginModal open={loginModal} close={handlerLoginModal} />

          {getLocalStorageItem('userId') !== userId && (
            <>
              <div>
                <CommonTooltip
                  component={ctProps => (
                    <img
                      data-tooltip-id={ctProps.id}
                      data-tooltip-content="Chat"
                      src={chatLIcon}
                      className="user-profile-social-icon-div cursor-pointer mr-2"
                      alt="Chat"
                      style={{ transform: 'scale(1.1)' }}
                      onClick={() => {
                        if (!currentUser?.id) {
                          handlerLoginModal()
                          return
                        }
                        if (
                          !currentUser?.documentVerification?.emailVerified ||
                          !currentUser?.documentVerification?.mobileVerified
                        ) {
                          setIsVerificationModalOpen(true)
                          return
                        } else if (cookiePolicyStatus !== 1) {
                          toggleCookiePolicyModal(true)
                          return
                        }

                        dispatch(
                          createChatById({
                            members: [userId],
                            imageUrl: imageUrl ?? '',
                            // chatId:selectUser.chatId,
                            //  metaData:selectUser?.metaData,
                            description: '',
                            channelUrl: uuid(),
                            chatType: chatType.normalchat,
                          })
                        )

                        dispatch(chatToggle(true))
                      }}
                    />
                  )}
                />
              </div>
              <VerificationModal open={isVerificationModalOpen} content="before starting chat with someone" />
              <div>
                <CommonTooltip
                  component={ctProps => (
                    <img
                      data-tooltip-id={ctProps.id}
                      data-tooltip-content={isFriend ? 'Remove from my list' : 'Add to my list'}
                      src={isFriend ? iconBoldImages.removeUserPlus : iconBoldImages.userPlus}
                      className="user-profile-social-icon-div cursor-pointer mr-2"
                      alt={isFriend ? 'Remove from my list' : 'Add to my list'}
                      onClick={() => {
                        handleAddRemoveFriend()
                        // createChat()
                      }}
                      style={{ transform: 'scale(1.1)' }}
                    />
                  )}
                />
              </div>
            </>
          )}
          {!noShare && (
            <OutsideClickHandler onOutsideClick={() => setSelectedIndex(null)}>
              <CommonTooltip
                component={ctProps => (
                  <img
                    data-tooltip-id={ctProps.id}
                    data-tooltip-content="Share"
                    src={iconBoldImages.share_black}
                    className="user-profile-social-icon-div cursor-pointer"
                    alt="Share"
                    onClick={() => setSelectedIndex(userId)}
                  />
                )}
              />
              <SharePopup
                handleClick={() => setSelectedIndex(null)}
                useOwnIcon
                moduleType={shareModule.USER}
                moduleId={userId}
                index={userId}
                selectedIndex={selectedIndex}
                className={className}
              />
            </OutsideClickHandler>
          )}
        </Fragment>
      )
    }
  )
)

ShareWithChatAndUser.defaultProps = {
  className: '',
}
