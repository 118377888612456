import { Col, Row } from 'react-bootstrap'
import { DashboardLayout, Loader } from '../../../components'
import { userRoles } from '../../../util/enums/enums'
import { ButtonComponent } from '../../../components/form/Button'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { clearStripeAccountLink, handleStripeAccount, loginWithPaypalAction, updateStripeProfileCompleted } from '../../../redux/actions'
import { useEffect, useState } from 'react'
import { CLEAR__UPDATE_STRIPE_PROFILE_FAILURE } from '../../../redux/actionTypes'

import React from 'react'
import ReactDOM from 'react-dom'
import { envConfig } from '../../../config'
import { ErrorNotify } from '../../../helpers/notification'

const AACComponent = typeof window !== 'undefined' && window.paypal.PayoutsAAC.driver('react', {
  React,
  ReactDOM,
})

function AddPayment(props) {
  const {
    currentUser,
    handleStripeAccount,
    stripProfileLink,
    clearStripeAccountLink,
    updateStripeProfileCompleted,
    stripeProfileError,
    clearStripeError,
    loginWithPaypal,
  } = props
  const { history } = props

  const [err, setErr] = useState(false)

  useEffect(() => {
    if (stripProfileLink) {
      window?.open(stripProfileLink, '_blank')
      clearStripeAccountLink()
    }
  }, [stripProfileLink])

  useEffect(() => {
    if (stripeProfileError) {
      setErr(true)
      clearStripeError()
    }
  }, [stripeProfileError])

  useEffect(() => {
    if (currentUser?.id && history.location.state?.stripe === 'return') {
      updateStripeProfileCompleted(currentUser?.id)
      history.replace({ ...history.location, state: null })
    }
  }, [currentUser?.id, history.location.state])

  if (!currentUser?.id) {
    return <Loader isPageLoader />
  }

  const userType = currentUser.role

  const onPaypalLogin = async (response) => {
    if (response.err) {
      ErrorNotify('Cannot connect to Paypal at the moment')
    } else {
      loginWithPaypal({ code: response.body.code });
    }
  };

  return (
    <DashboardLayout>
      <div style={{ width: 'calc(100% - 50px)', margin: 'auto' }}>
        {userType?.aliasName !== userRoles.MEMBER && (
          <Row className="mt-50">
            <Col xs={12} md={4}>
              <h3 className="user--title" style={{ textAlign: 'center' }}>
                <span>
                  Payments and Payouts{' '}
                  <span
                    data-tooltip-id="app-common-tooltip-xl"
                    data-tooltip-place="bottom-start"
                    data-tooltip-html={
                      [userRoles.BOAT_OWNER, userRoles.BROKER_AND_DEALER, userRoles.BOAT_MANUFACTURER].includes(
                        userType.aliasName
                      )
                        ? `<div>
                            <div>To ensure you receive your payouts for each transaction promptly, please complete your Stripe account setup. You can continue to list and sell your boats on AdamSea in the meantime, and once your Stripe account is ready, you'll be able to receive your payouts without delay.</div>
                            <div style="margin-top: 15px"><strong>Please note:</strong> Upon the completion of a boat sale, the full payment amount will be temporarily held in the AdamSea account. This hold is to guarantee that the buyer receives the boat, whether directly or via a shipping service. In both scenarios, the boat owner is required to scan the transaction's QR code. If the buyer is collecting the boat personally, the owner should scan the QR code themselves. If a shipper is involved, they will scan the QR code on the owner's behalf.</div>
                            <div style="margin-top: 15px"><strong>Payout Release Conditions:</strong></div>
                            <ul style="margin-top: 15px">
                              <li><u>If the buyer collects the boat:</u> The payout will be released within three days after the QR code is scanned.</li>
                              <li><u>If a shipper collects the boat:</u> The shipper will take possession of the boat and handle the shipping documents. Once these documents are uploaded to AdamSea's shipment page, the payout will be released.</li>
                            </ul>
                          </div>`
                        : [userRoles.SURVEYOR].includes(userType.aliasName)
                        ? `<div>
                            <div>To ensure you receive your payouts for each transaction promptly, please complete your Stripe account setup. You can continue processing buyers' requests on AdamSea in the meantime, and once your Stripe account is ready, you'll be able to receive your payouts without delay.</div>
                            <div class="mt-1"><strong>Please note:</strong> once the buyer pays the cost of the boat survey, the full amount will be temporarily held in the AdamSea account. We hold the payment until the surveyor submits the report. The surveyor needs to submit the inspection reports and documents through the AdamSea account. Then, the payment will be transferred on the same day.</div>
                          </div>`
                        : [userRoles.YACHT_SHIPPER].includes(userType.aliasName)
                        ? `<div>
                            <div>To ensure you receive your payouts for each transaction promptly, please complete your Stripe account setup. You can continue processing buyers' requests on AdamSea in the meantime, and once your Stripe account is ready, you'll be able to receive your payouts without delay.</div>
                            <div class="mt-1"><strong>Please note:</strong> Once the buyer pays off the shipment amount, the full amount will be temporarily held in the AdamSea account. To receive the payout, the shipper needs to upload the required documents. Once all documents are submitted to the buyer through your AdamSea account, then payment will be transferred on the same day.</div>
                          </div>`
                        : `<div>With AdamSea Online Payments, customers can pay the full trip price online. This system enhances payment security and streamlines the booking process. Funds are released to you immediately after the trip's ticket QR code is scanned, ensuring the customer is on board. Typically, it takes 1-2 business days for the funds to reflect in your bank account.</div>`
                    }
                  >
                    <img src={require('../../../assets/images/ques.png')} alt="question" height={18} />
                  </span>
                </span>
              </h3>
            </Col>
            <Col xs={12} md={8}></Col>
            <Col xs={12} className="d-flex flex-column align-items-center">
              <div className="payment-btns-cont">
                <ButtonComponent
                  onClick={e => {
                    e.preventDefault()

                    if ([userRoles.MARINA_AND_STORAGE, userRoles.SERVICE_AND_MAINTENANCE].includes(userType.aliasName)) {
                      toast.info('Currently this function is disabled', { autoClose: 5000 })
                    } else {
                      handleStripeAccount()
                    }
                  }}
                  capitalize={false}
                  className="as--success--outline btn--padding btn--font mb-20 stripe-button mt-50"
                >
                  {!currentUser.is_stripe_profile_completed ? 'Connect with stripe account' : 'Manage stripe account'}
                </ButtonComponent>

                {/* <div className="aac-comp">
                  {currentUser.paypalId ? (
                    <>Paypal Connected: {currentUser.paypalId}</>
                  ) : (
                    <AACComponent
                      clientId={{ [envConfig.PAYPAL_ENV]: envConfig.PAYPAL_CLIENT_ID }}
                      merchantId={envConfig.PAYPAL_MERCHANT_ID}
                      env={envConfig.PAYPAL_ENV}
                      pageType="login"
                      onLogin={onPaypalLogin}
                    />
                  )}
                </div> */}
              </div>
              <div className="d-flex">
                <p className="text">
                  In order to receive your payout and to be part of the sales engine please create a Stripe account (AdamSea's
                  partner).
                </p>
              </div>

              {err && (
                <div className="stripe-profile-err-box" onClick={() => setErr(false)}>
                  Your Stripe account has not been updated yet. Please go back to Stripe to update your profile.
                </div>
              )}
            </Col>
          </Row>
        )}
      </div>

      {props.loginWithPaypalLoading && <Loader isPageLoader />}
    </DashboardLayout>
  )
}

const mapStateToProps = state => ({
  stripProfileLink: state.loginReducer.stripProfileLink,
  stripeProfileError: state.loginReducer.stripeProfileError,
  loginWithPaypalLoading: state.loginReducer.loginWithPaypalLoading,
  loginWithPaypalSuccess: state.loginReducer.loginWithPaypalSuccess,
  loginWithPaypalFailure: state.loginReducer.loginWithPaypalFailure,
})

const mapDispatchToProps = dispatch => ({
  updateStripeProfileCompleted: data => dispatch(updateStripeProfileCompleted(data)),
  handleStripeAccount: () => dispatch(handleStripeAccount()),
  clearStripeAccountLink: () => dispatch(clearStripeAccountLink()),
  clearStripeError: () => dispatch({ type: CLEAR__UPDATE_STRIPE_PROFILE_FAILURE }),
  loginWithPaypal: payload => dispatch(loginWithPaypalAction(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddPayment)
