import {
  GET_ALL_PAGE_INFORMATION_BY_TYPE,
  GET_ALL_PAGE_INFORMATION_BY_TYPE_FAILURE,
  GET_ALL_PAGE_INFORMATION_BY_TYPE_SUCCESS,
  GET_RENT_PAGES,
  GET_RENT_PAGES_SUCCESS,
  GET_RENT_PAGES_FAILURE,
  GET_SALES_ENGINE_PAGES,
  GET_SALES_ENGINE_PAGES_SUCCESS,
  GET_SALES_ENGINE_PAGES_FAILURE,
  GET_AGREEMENT_SUCCESS,
  GET_AGREEMENT_FAILURE,
  GET_AGREEMENT,
  GET_STATIC_PAGES,
  GET_STATIC_PAGES_SUCCESS,
  GET_STATIC_PAGES_FAILURE,
} from '../actionTypes'
import { put, takeLatest, all } from 'redux-saga/effects'
import { graphqlClient } from '../../helpers/graphqlClient'
import {
  getAgreementQuery,
  getAllPageInformationByType,
  getRentPages,
  getSalesEnginePages,
  getStaticPagesQuery,
} from '../../graphql/pageInfoByTypeSchema'

function getAllPageInformationApi(data) {
  return graphqlClient.query({
    query: getAllPageInformationByType,
    fetchPolicy: 'no-cache',
    variables: { title: data.title },
  })
}

function* getPageInfoByTypeData(action) {
  try {
    const data = yield getAllPageInformationApi(action.payload)
    yield put({
      type: GET_ALL_PAGE_INFORMATION_BY_TYPE_SUCCESS,
      payload: data,
      isModel: action.isModel,
      isLargeModal: action.payload?.isLargeModal,
    })
  } catch (e) {
    yield put({ type: GET_ALL_PAGE_INFORMATION_BY_TYPE_FAILURE, e })
  }
}

function* getPageInfoByTypeSaga() {
  yield takeLatest(GET_ALL_PAGE_INFORMATION_BY_TYPE, getPageInfoByTypeData)
}

function getRentPagesAPI() {
  return graphqlClient.query({
    query: getRentPages,
    fetchPolicy: 'no-cache',
  })
}

function* getRentPagesData() {
  try {
    let data = yield getRentPagesAPI()
    data = data?.data?.getRentPages
    if (data) yield put({ type: GET_RENT_PAGES_SUCCESS, payload: data })
    else yield put({ type: GET_RENT_PAGES_FAILURE })
  } catch (e) {
    yield put({ type: GET_RENT_PAGES_FAILURE })
  }
}

function* getRentPagesSaga() {
  yield takeLatest(GET_RENT_PAGES, getRentPagesData)
}

function getSalesEnginePagesAPI() {
  return graphqlClient.query({
    query: getSalesEnginePages,
    fetchPolicy: 'no-cache',
  })
}

function* getSalesEnginePagesData() {
  try {
    let data = yield getSalesEnginePagesAPI()
    data = data?.data?.getSalesEnginePages
    if (data) yield put({ type: GET_SALES_ENGINE_PAGES_SUCCESS, payload: data })
    else yield put({ type: GET_SALES_ENGINE_PAGES_FAILURE })
  } catch (e) {
    yield put({ type: GET_SALES_ENGINE_PAGES_FAILURE })
  }
}

function* getSalesEnginePagesSaga() {
  yield takeLatest(GET_SALES_ENGINE_PAGES, getSalesEnginePagesData)
}

function getAgreementAPI(input) {
  return graphqlClient.query({
    query: getAgreementQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

function* getAgreementData(action) {
  try {
    let data = yield getAgreementAPI(action.payload)
    data = data?.data?.getAgreement
    if (data) yield put({ type: GET_AGREEMENT_SUCCESS, payload: data })
    else yield put({ type: GET_AGREEMENT_FAILURE })
  } catch (e) {
    yield put({ type: GET_AGREEMENT_FAILURE })
  }
}

function* getAgreementSaga() {
  yield takeLatest(GET_AGREEMENT, getAgreementData)
}

function getStaticPagesAPI(input) {
  return graphqlClient.query({
    query: getStaticPagesQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

function* getStaticPagesData(action) {
  try {
    let data = yield getStaticPagesAPI(action.payload)
    data = data?.data?.getStaticPages
    if (data) yield put({ type: GET_STATIC_PAGES_SUCCESS, payload: data })
    else yield put({ type: GET_STATIC_PAGES_FAILURE })
  } catch (e) {
    yield put({ type: GET_STATIC_PAGES_FAILURE })
  }
}

function* getStaticPagesSaga() {
  yield takeLatest(GET_STATIC_PAGES, getStaticPagesData)
}

export default function* pageInfoByTypeSaga() {
  yield all([getPageInfoByTypeSaga(), getRentPagesSaga(), getSalesEnginePagesSaga(), getAgreementSaga(), getStaticPagesSaga()])
}
