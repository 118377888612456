import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getRentCityWiseBoats } from '../../../redux/actions'
import { pagination } from '../../../util/enums/enums'
import BoatListingsWithMap from '../../../components/gridComponents/BoatListingsWithMap'
import { cityCountryNameFormatter } from '../../../helpers/jsxHelper'
import { shareDetailsWithUsers } from '../../../redux/actions/shareAction'

class RentCityWiseBoats extends Component {
  state = {
    country: '',
    city: '',
  }

  static getDerivedStateFromProps(nextProps) {
    const { match } = nextProps
    const { params } = match && match

    if (params && params.hasOwnProperty('country') && params.country && params.hasOwnProperty('city') && params.city) {
      return {
        country: params.country,
        city: params.city,
      }
    }

    return null
  }

  componentDidMount() {
    const { country, city } = this.state
    const { getRentCityWiseBoats } = this.props

    getRentCityWiseBoats({ country, page: pagination.PAGE_COUNT, limit: pagination.PAGE_RECORD_LIMIT })
  }

  render() {
    const { cityWiseBoats, getRentCityWiseBoats, totalCityWiseBoats, isPageLoader } = this.props
    const { country, city } = this.state

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        isRent
        isFlag
        boatsType={`Explore ${totalCityWiseBoats || 0} Boats in ${country}`}
        boatsTypeCount={totalCityWiseBoats || 0}
        boats={cityWiseBoats}
        action={getRentCityWiseBoats}
        value={{ country, city }}
      />
    )
  }
}

const mapStateToProps = state => ({
  cityWiseBoats: state.rentReducer.cityWiseBoats,
  totalCityWiseBoats: state.rentReducer.totalCityWiseBoats,
  isPageLoader: state.rentReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  getRentCityWiseBoats: data => dispatch(getRentCityWiseBoats(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RentCityWiseBoats)
