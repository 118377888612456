import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Card, Row, Col, Button } from 'react-bootstrap'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { getUserPaymentInfo, addUserPaymentInfo, clearUserPaymentInfoFlag } from '../../../redux/actions'
import { SuccessNotify } from '../../../helpers/notification'
import { DashboardLayout } from '../../../components/layout/dashboardLayout'
import { requireMessage } from '../../../helpers/string'
import ErrorFocus from '../../../components/ws/ErrorFocus'
import { Field, Loader } from '../../../components'
import { popUpMessage } from '../../../helpers/confirmationPopup'
import { ButtonComponent } from '../../../components/form/Button'
import { stringValidation } from '../../../helpers/yupHelper'
import PaymentDetail from './PaymentDetail'
import './ManagePayment.scss'

class ManagePayment extends Component {
  state = {
    initValue: {
      holderName: null,
      accountNumber: '',
      bankName: null,
      branchName: null,
      transitCode: '',
      institutionNumber: '',
      branchAddress: null,
      swiftCode: '',
      fullAddress: null,
    },
  }

  static getDerivedStateFromProps(nextProps) {
    const {
      addedPaymentInfoSuccess,
      addedPaymentInfoError,
      getUserPaymentInfoSuccess,
      currentUserPaymentInfo,
      clearUserPaymentInfoFlag,
    } = nextProps

    if (addedPaymentInfoSuccess) {
      setTimeout(() => SuccessNotify('Payment info updated.'), 100)
      nextProps.history.push('/dashboard')
    } else if (addedPaymentInfoError) {
      popUpMessage('invalid inputs.', 'Error')
    }

    if (getUserPaymentInfoSuccess) {
      clearUserPaymentInfoFlag()
      return {
        initValue: {
          holderName: (currentUserPaymentInfo && currentUserPaymentInfo.holderName) || null,
          accountNumber: (currentUserPaymentInfo && currentUserPaymentInfo.accountNumber) || '',
          bankName: (currentUserPaymentInfo && currentUserPaymentInfo.bankName) || null,
          branchName: (currentUserPaymentInfo && currentUserPaymentInfo.branchName) || null,
          transitCode: (currentUserPaymentInfo && currentUserPaymentInfo.transitCode) || '',
          institutionNumber: (currentUserPaymentInfo && currentUserPaymentInfo.institutionNumber) || '',
          branchAddress: (currentUserPaymentInfo && currentUserPaymentInfo.branchAddress) || null,
          swiftCode: (currentUserPaymentInfo && currentUserPaymentInfo.swiftCode) || '',
          fullAddress: (currentUserPaymentInfo && currentUserPaymentInfo.fullAddress) || null,
        },
      }
    }

    return null
  }

  componentDidMount() {
    const { getUserPaymentInfo } = this.props
    getUserPaymentInfo()
  }

  cancelHandler = () => {
    const { history } = this.props
    history.push('/dashboard')
  }

  roleWiseBankInfoSchema = () => {
    return {
      holderName: stringValidation.required(requireMessage('Holder name')).ensure(),
      accountNumber: Yup.number().required(requireMessage('Account number')),
      bankName: stringValidation.required(requireMessage('Bank name')).ensure(),
      branchName: stringValidation.required(requireMessage('Branch name')).ensure(),
      transitCode: Yup.number().required(requireMessage('Transit code')),
      institutionNumber: Yup.number().required(requireMessage('Institution number')),
      branchAddress: stringValidation.required(requireMessage('Branch address')).ensure(),
      swiftCode: Yup.number().required(requireMessage('Swift code')),
      fullAddress: stringValidation.required(requireMessage('Full address of account holder')).ensure(),
    }
  }

  // Need for future use
  // renderAddress = (values, setFieldValue) => {
  //     const { countryCode } = this.props
  //     return (
  //         <Col>
  //             <Card className="card-parent card-content p-4 h-100">
  //                 <Card.Title className="ml-0 mt-0">Billing Address</Card.Title>
  //                 <Row>
  //                     <Col>
  //                         <div className="field dashboard-profile-label">
  //                             <Field
  //                                 label="Address 1"
  //                                 id={"address1"}
  //                                 name={"address1"}
  //                                 type="text"
  //                                 value={values.address1}
  //                                 onChangeText={e => {
  //                                     setFieldValue("address1", e.target.value);
  //                                 }}
  //                                 required
  //                             />
  //                             <ErrorMessage
  //                                 component="div"
  //                                 name="address1"
  //                                 className="error-message"
  //                             />
  //                         </div>
  //                     </Col>
  //                     <Col>
  //                         <div className="field dashboard-profile-label">
  //                             <Field
  //                                 label="Address 2"
  //                                 id={"address2"}
  //                                 name={"address2"}
  //                                 type="text"
  //                                 value={values.address2}
  //                                 onChangeText={e => {
  //                                     setFieldValue("address2", e.target.value);
  //                                 }}
  //                             />
  //                         </div>
  //                     </Col>
  //                 </Row>
  //                 <Row>
  //                     <Col>
  //                         <div className="field dashboard-profile-label">
  //                             <Field
  //                                 label="Street"
  //                                 id={"street"}
  //                                 name={"street"}
  //                                 type="text"
  //                                 value={values.street}
  //                                 onChangeText={e => {
  //                                     setFieldValue("street", e.target.value);
  //                                 }}
  //                                 required
  //                             />
  //                             <ErrorMessage
  //                                 component="div"
  //                                 name="street"
  //                                 className="error-message"
  //                             />
  //                         </div>
  //                     </Col>
  //                     <Col>
  //                         <div className="field dashboard-profile-label">
  //                             <Field
  //                                 label="City or Town"
  //                                 id={"city"}
  //                                 name={"city"}
  //                                 type="autocomplete"
  //                                 value={values.city}
  //                                 placeholder={values.city}
  //                                 onChangeText={place => {
  //                                     setFieldValue("city", place.city);
  //                                     setFieldValue("state", place.state);
  //                                     setFieldValue("country", place.country);
  //                                     setFieldValue("zip", place.postalCode);
  //                                 }}
  //                                 restrictCountry={countryCode}
  //                                 required
  //                             />
  //                             <ErrorMessage
  //                                 component="div"
  //                                 name="city"
  //                                 className="error-message"
  //                             />
  //                         </div>
  //                     </Col>
  //                 </Row>
  //                 <Row>
  //                     <Col>
  //                         <div className="field dashboard-profile-label">
  //                             <Field
  //                                 label="State"
  //                                 id={"state"}
  //                                 name={"state"}
  //                                 type="text"
  //                                 value={values.state}
  //                                 onChangeText={e => {
  //                                     setFieldValue("state", e.target.value);
  //                                 }}
  //                                 required
  //                                 disabled
  //                             />
  //                             <ErrorMessage
  //                                 component="div"
  //                                 name="state"
  //                                 className="error-message"
  //                             />
  //                         </div>
  //                     </Col>
  //                     <Col>
  //                         <div className="field dashboard-profile-label">
  //                             <Field
  //                                 label="Zip or Postal Code"
  //                                 id={"zip"}
  //                                 name={"zip"}
  //                                 type="text"
  //                                 value={values.zip}
  //                                 onChangeText={e => {
  //                                     setFieldValue("zip", e.target.value);
  //                                 }}
  //                             />
  //                             <ErrorMessage
  //                                 component="div"
  //                                 name="zip"
  //                                 className="error-message"
  //                             />
  //                         </div>
  //                     </Col>
  //                     <Col>
  //                         <div className="field dashboard-profile-label">
  //                             <Field
  //                                 label="P.O. Box"
  //                                 id={"postBox"}
  //                                 name={"postBox"}
  //                                 type="number"
  //                                 value={values.postBox}
  //                                 onChangeText={e => {
  //                                     setFieldValue("postBox", +e.target.value || "");
  //                                 }}
  //                             />
  //                             <ErrorMessage
  //                                 component="div"
  //                                 name="postBox"
  //                                 className="error-message"
  //                             />
  //                         </div>
  //                     </Col>
  //                 </Row>
  //                 <Row>
  //                     <Col>
  //                         <div className="field dashboard-profile-label">
  //                             <Field
  //                                 label="Country"
  //                                 id={"country"}
  //                                 name={"country"}
  //                                 type="text"
  //                                 value={values.country}
  //                                 onChangeText={e => {
  //                                     setFieldValue("country", e.target.value);
  //                                 }}
  //                                 disabled
  //                             />
  //                         </div>
  //                     </Col>
  //                 </Row>
  //             </Card>
  //         </Col>
  //     )
  // }

  renderPaymentInfo = (values, setFieldValue, handleSubmit) => {
    const { currentUserPaymentInfo } = this.props

    return (
      <Col className="manage--payment--spacing">
        <div className="manage--payment--title--div">
          <p className="manage--payment--title mb-0">Manage Payment</p>
        </div>
        <div className="manage--payment--title--section">
          <p className="manage--payment--title mb-0">Bank Information</p>
        </div>

        <Card className="card-parent card-content p-4">
          <Row>
            <Col item className="payment--bank--info--col">
              <div className="field pt-0 dashboard-profile-label">
                <Field
                  label="Account Holder Name"
                  className="payment--bank--info"
                  id={'holderName'}
                  name={'holderName'}
                  type="text"
                  value={values.holderName}
                  onChangeText={e => {
                    setFieldValue('holderName', e.target.value)
                  }}
                  required
                />
                <ErrorMessage component="div" name="holderName" className="error-message" />
              </div>
            </Col>
            <Col item className="payment--bank--info--col">
              <div className="field pt-0 dashboard-profile-label">
                <Field
                  label="Account Number"
                  className="payment--bank--info"
                  id={'accountNumber'}
                  name={'accountNumber'}
                  type="number"
                  value={values.accountNumber}
                  onChangeText={e => {
                    setFieldValue('accountNumber', +e.target.value || '')
                  }}
                  required
                />
                <ErrorMessage component="div" name="accountNumber" className="error-message" />
              </div>
            </Col>
            <Col item className="payment--bank--info--col">
              <div className="field pt-0 dashboard-profile-label">
                <Field
                  label="Bank Name"
                  className="payment--bank--info"
                  id={'bankName'}
                  name={'bankName'}
                  type="text"
                  value={values.bankName}
                  onChangeText={e => {
                    setFieldValue('bankName', e.target.value)
                  }}
                  required
                />
                <ErrorMessage component="div" name="bankName" className="error-message" />
              </div>
            </Col>
            <Col item className="payment--bank--info--col">
              <div className="field pt-0 dashboard-profile-label">
                <Field
                  label="Branch Name"
                  className="payment--bank--info"
                  id={'branchName'}
                  name={'branchName'}
                  type="text"
                  value={values.branchName}
                  onChangeText={e => {
                    setFieldValue('branchName', e.target.value)
                  }}
                  required
                />
                <ErrorMessage component="div" name="branchName" className="error-message" />
              </div>
            </Col>
            <Col item className="payment--bank--info--col">
              <div className="field pt-0 dashboard-profile-label">
                <Field
                  label="Transit Code"
                  className="payment--bank--info"
                  id={'transitCode'}
                  name={'transitCode'}
                  type="number"
                  value={values.transitCode}
                  onChangeText={e => {
                    setFieldValue('transitCode', +e.target.value || '')
                  }}
                  required
                />
                <ErrorMessage component="div" name="transitCode" className="error-message" />
              </div>
            </Col>
            <Col item className="payment--bank--info--col mb-0">
              <div className="field pt-0 dashboard-profile-label">
                <Field
                  label="Institution Number"
                  className="payment--bank--info"
                  id={'institutionNumber'}
                  name={'institutionNumber'}
                  type="number"
                  value={values.institutionNumber}
                  onChangeText={e => {
                    setFieldValue('institutionNumber', +e.target.value || '')
                  }}
                  required
                />
                <ErrorMessage component="div" name="institutionNumber" className="error-message" />
              </div>
            </Col>
            <Col item className="payment--bank--info--col mb-0">
              <div className="field pt-0 dashboard-profile-label">
                <Field
                  label="Swift Code"
                  className="payment--bank--info"
                  id={'swiftCode'}
                  name={'swiftCode'}
                  value={values.swiftCode}
                  type="number"
                  onChangeText={e => {
                    setFieldValue('swiftCode', +e.target.value || '')
                  }}
                  required
                />
                <ErrorMessage component="div" name="swiftCode" className="error-message" />
              </div>
            </Col>
            <Col item className="payment--bank--info--col mb-0">
              <div className="field pt-0 dashboard-profile-label">
                <Field
                  label="Branch Address"
                  className="payment--bank--info"
                  id={'branchAddress'}
                  name={'branchAddress'}
                  value={values.branchAddress}
                  type="textarea"
                  rows="3"
                  onChangeText={e => {
                    setFieldValue('branchAddress', e.target.value)
                  }}
                  required
                />
                <ErrorMessage component="div" name="branchAddress" className="error-message" />
              </div>
            </Col>
            <Col item className="payment--bank--info--col mb-0">
              <div className="field pt-0 dashboard-profile-label">
                <Field
                  label="Account Holder Address"
                  className="payment--bank--info"
                  id={'fullAddress'}
                  name={'fullAddress'}
                  type="textarea"
                  rows="3"
                  value={values.fullAddress}
                  onChangeText={e => {
                    setFieldValue('fullAddress', e.target.value)
                  }}
                  required
                />
                <ErrorMessage component="div" name="fullAddress" className="error-message" />
              </div>
            </Col>

            <Col item className="payment--bank--info--col mb-0 bank--info--submit--section">
              <div className="field pt-0 dashboard-profile-label">
                <ButtonComponent
                  variant="outline"
                  color="success"
                  className="h-auto btn--padding btn--font mr-3"
                  onClick={handleSubmit}
                >
                  {currentUserPaymentInfo?.id ? 'Update' : 'Save'}
                </ButtonComponent>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    )
  }

  render() {
    const { addUserPaymentInfo, currentUser } = this.props
    const { initValue } = this.state
    return currentUser && !currentUser.id ? (
      <Loader isPageLoader />
    ) : (
      <DashboardLayout>
        <Formik
          initialValues={initValue}
          enableReinitialize
          validationSchema={Yup.object().shape({
            ...this.roleWiseBankInfoSchema(),
          })}
          onSubmit={values => {
            addUserPaymentInfo(values)
          }}
          render={({ values, setFieldValue, handleSubmit }) => (
            <Container fluid>
              <Form>
                <ErrorFocus />

                <Row className="mb-3 d-flex flex-column mt-3">
                  {/* Bank Information */}
                  {/* {this.renderPaymentInfo(values, setFieldValue, handleSubmit)} */}
                </Row>
              </Form>
            </Container>
          )}
        />

        {/* Payment Details Listing */}
        <PaymentDetail />
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser,
  addedPaymentInfoSuccess: state.loginReducer.addedPaymentInfoSuccess,
  getUserPaymentInfoSuccess: state.loginReducer.getUserPaymentInfoSuccess,
  addedPaymentInfoError: state.loginReducer.addedPaymentInfoError,
  currentUserPaymentInfo: state.loginReducer.currentUserPaymentInfo || {},
  countryCode: state.loginReducer.countryCode,
})

const mapDispatchToProps = dispatch => ({
  getUserPaymentInfo: data => dispatch(getUserPaymentInfo(data)),
  addUserPaymentInfo: data => dispatch(addUserPaymentInfo(data)),
  clearUserPaymentInfoFlag: () => dispatch(clearUserPaymentInfoFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManagePayment)
