import { Grid } from "@material-ui/core";
import { Layout } from "../../components";
import '../../styles/footerScreens.scss'
import { footerScreenImages, icons } from "../../util/enums/enums";
import { Box, Stack } from "@mui/material";

export default function SurveyYourBoat() {
    return (
        <Layout>
            <div className="container100 py-5">
                <Grid container>
                    <Grid className="mb-3" items sm={12}>
                        <h1 className="r-h1">Survey Your Boat</h1>
                    </Grid>
                    <Grid className="mb-5" items md={6} sm={12}>
                        <img loading='lazy' src={icons.trusted_survey_boat_partner} alt='Trusted icon'/>
                    </Grid>
                    <Grid className="mb-5" items md={6} sm={12}>
                        <p className="info-subtitle">Your Trusted Boat Survey Partner</p>
                        <span className="info-text">At AdamSea, we understand that buying a boat is a significant investment. To ensure our users have the best experience and make informed decisions, we offer optional boat survey services. Our platform is committed to providing the highest level of transparency and safety for all parties involved.
                            We take pride in working with a network of highly qualified and experienced boat surveyors. Each surveyor on our platform is carefully vetted and possesses the necessary certifications and expertise to assess the condition of a boat accurately. Their qualifications ensure that our users receive professional and trustworthy evaluations of the vessels they are interested in.</span>
                    </Grid>
                    <Grid items md={4} sm={12}>
                        <Stack alignItems={'center'} className="m-2">
                            <img width={76} loading='lazy' src={icons.strong_recommendation} alt='Strong recommendation'/>
                            <p className='info-subtitle my-2'>Strong Recommendation</p>
                            <span className='info-text text-center'>While optional, we strongly recommend obtaining a boat survey. Buying a boat is a significant financial commitment, and this step is crucial. A thorough survey ensures the boat matches the seller's description.</span>
                        </Stack>
                    </Grid>
                    <Grid items md={4} sm={12}>
                        <Stack alignItems={'center'} className="m-2">
                            <img width={76} loading='lazy' src={icons.uncovering_hidden_issue} alt='Uncover issue'/>
                            <p className='info-subtitle my-2'>Uncovering Hidden Issues</p>
                            <span className='info-text text-center'> A thorough survey reveals hidden problems or defects and provides a detailed report on the boat's condition. This helps you make an informed decision and avoid potential future expenses.</span>
                        </Stack>
                    </Grid>
                    <Grid items md={4} sm={12}>
                        <Stack alignItems={'center'} className="m-2">
                            <img width={76} loading='lazy' src={icons.buying_peace_mind} alt='Buying'/>
                            <p className='info-subtitle my-2'>Buying with Peace of Mind</p>
                            <span className='info-text text-center'>A boat survey ultimately offers peace of mind, allowing you to proceed with your purchase confidently. It's a small investment compared to the potential savings and security it provides.</span>
                        </Stack>
                    </Grid>
                    <Grid sm={12}>
                        <Box className='my-5'>
                            <p className="as-com-title">Sales Engine</p>
                            <img style={{ width: '100%' }} src={footerScreenImages.sales_engine_process} loading='lazy' alt='Sales engine'/>
                        </Box>
                    </Grid>
                    <Grid sm={12} className="info-title text-center my-4">
                        Why choose Us ?
                    </Grid>
                    <Grid className="my-5 text-center" md={6} sm={12}>
                        <img loading="lazy" src={icons.safety_and_satisfaction} alt='Safety and Satisfaction'/>
                    </Grid>
                    <Grid className="my-5" md={6} sm={12}>
                        <Stack>
                            <p className="info-subtitle text-primary">Safety and Satisfaction</p>
                            <span className="info-text">We prioritize the safety and satisfaction of our users. When you choose to proceed with a boat survey on our platform, you can do so with confidence. We have implemented a secure payment system that releases funds to the surveyor only after the job is completed to your satisfaction. This payment mechanism guarantees that you receive the service you pay for and that the surveyor is compensated for their work promptly.</span>
                        </Stack>
                    </Grid>
                    <Grid className="my-5" md={6} sm={12}>
                        <Stack>
                            <p className="info-subtitle text-primary">Informed Decisions Made Easy</p>
                            <span className="info-text">To ensure that you have all the necessary information before making a decision, our boat survey services allow you to receive a comprehensive package. You will receive high-resolution images and videos of the boat, providing you with a visual understanding of its condition. Additionally, a detailed purchase survey report is prepared by the surveyor, summarizing their findings and recommendations. This report serves as a valuable resource for making an informed choice.</span>
                        </Stack>
                    </Grid>
                    <Grid className="my-5 text-center" md={6} sm={12}>
                        <img loading="lazy" src={icons.informed_decision_easy} alt='informed Decision easy'/>
                    </Grid>
                    <Grid md={6} sm={12}>
                        <img className="my-5 text-center" loading="lazy" src={icons.seamless_secure_buying} alt='Seamless secure buying' />
                    </Grid>
                    <Grid className='my-5' md={6} sm={12}>
                        <Stack>
                            <p className="info-subtitle">Seamless, Secure Boat Buying</p>
                            <span className="info-text">At AdamSea, we are committed to providing a seamless and secure boat buying experience. Our optional boat survey services, carried out by qualified professionals, are designed to give you the confidence and assurance you need when investing in a boat. Whether you choose to take advantage of our survey services or not, rest assured that your safety and satisfaction are our top priorities. With comprehensive information, including images, videos, and survey reports, we empower you to make the best decision for your boating journey.</span>
                        </Stack>
                    </Grid>
                </Grid>
            </div>
        </Layout>
    )
}
