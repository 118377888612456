import React from 'react'
import { connect } from 'react-redux'
import { handleStripeAccount } from '../../redux/actions'

class UserStripeProfileReauth extends React.Component {
    componentDidMount() {
        const { handleStripeAccount } = this.props
        handleStripeAccount()
    }
    
    static getDerivedStateFromProps(nextProps) {
        const { stripProfileLink } = nextProps
        if (stripProfileLink) {
            window && window.open(`${stripProfileLink}`, "_self")
        }
        return null
    }

    render() {
        return (
            <></>
        )
    }
}

const mapStateToProps = state => ({
    stripProfileLink: state.loginReducer.stripProfileLink,
})

const mapDispatchToProps = dispatch => ({
  handleStripeAccount: () => dispatch(handleStripeAccount())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserStripeProfileReauth)