import { useEffect, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { IoIosSearch } from 'react-icons/io'
import cn from 'classnames'
import GlobalSearch from './global-search'
import styles from './short-search.module.scss'

const POPUP = {
  HERO_SEARCH: 1,
  GLOBAL_SEARCH: 2,
}

export default function ShortSearch({ fields = [], HeroSearch }) {
  const [onTop, setOnTop] = useState(false)
  const [activePopup, setActivePopup] = useState(null)

  useEffect(() => {
    const handleScroll = () => {
      setActivePopup(null)
      setOnTop(!!window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <OutsideClickHandler onOutsideClick={() => setActivePopup(null)}>
      <div className={cn(styles.wrapper, onTop && !activePopup && styles.wrapperShrink)}>
        <div className={styles.fieldsNGlobalSearchWrp}>
          <div className={styles.fieldsWrp} onClick={() => setActivePopup(POPUP.HERO_SEARCH)}>
            {fields.map(f => (
              <div className={styles.fieldWrp}>{f}</div>
            ))}
          </div>

          <button className={styles.globalSearchBtn} onClick={() => setActivePopup(POPUP.GLOBAL_SEARCH)}>
            <IoIosSearch />
          </button>
        </div>

        {activePopup === POPUP.HERO_SEARCH && (
          <div className={styles.mainSearchWrp}>
            <HeroSearch inHeader close={() => setActivePopup(null)} />
          </div>
        )}

        {activePopup === POPUP.GLOBAL_SEARCH && (
          <div className={styles.mainSearchWrp}>
            <GlobalSearch inPopup />
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}
