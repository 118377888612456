import React from 'react'
import { connect } from 'react-redux'

import { getMostViewedBoatServices } from '../../redux/actions'
import BoatListingsWithMap from './BoatListingsWithMap'

class ShowAllBoatServiceGallery extends React.Component {
  render() {
    const { mostViewedBoatServices, mostViewedBoatServicesTotal, isPageLoader, getMostViewedBoatServices } = this.props

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        isBoatService
        boatsTypeCount={mostViewedBoatServicesTotal || 0}
        boatsType="Recently added boat services"
        boats={mostViewedBoatServices}
        action={getMostViewedBoatServices}
        showAllTitle={`Explore ${mostViewedBoatServicesTotal || 0} boat services rated by other users`}
      />
    )
  }
}

const mapStateToProps = state => ({
  mostViewedBoatServices: state.boatServiceReducer && state.boatServiceReducer.mostViewedBoatServices,
  mostViewedBoatServicesTotal: state.boatServiceReducer && state.boatServiceReducer.total,
  isPageLoader: state.boatServiceReducer && state.boatServiceReducer.isLoading,
})

const mapDispatchToProps = dispatch => ({
  getMostViewedBoatServices: data => dispatch(getMostViewedBoatServices(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllBoatServiceGallery)
