import { Box, Stack } from "@mui/material";
import { Layout } from "../../components";
import { Grid } from "@material-ui/core";
import { footerScreenImages } from "../../util/enums/enums";
import './adamseaCommunity.scss'


export default function AdamseaCommunity(){
    return(
        <Layout>
            <div className="container100">
                <Box className='d-flex justify-content-center flex-column text-center align-items-center py-5'>
                    <h1 className="as-com-title"><span className="text-primary">Connecting</span> Marine Enthusiasts Worldwide</h1>
                    <span className="as-com-text">The AdamSea community is a key component of our platform, as it enables users from around the world to connect with each other and share their knowledge and experience in the marine industry. </span>
                    <div className='blue--hr my-5'></div>
                </Box>
                <Grid  container spacing={4}>
                    <Grid className="text-center" item xs={12} md={6}>
                        <img width={262} src={footerScreenImages.connecting_marine_cmt} alt='connect'/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <p className="as-com-subtitle">Connecting the Marine Community</p>
                            <span className="as-com-text">Meanwhile, AdamSea Share is a social platform that brings together the entire AdamSea user community, providing a space for users to connect with each other, share stories and experiences, and stay up to date on the latest news and trends in the marine industry.</span>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <p className="as-com-subtitle">Social Platform</p>
                            <span className="as-com-text">Meanwhile, AdamSea Share is a social platform that brings together the entire AdamSea user community, providing a space for users to connect with each other, share stories and experiences, and stay up to date on the latest news and trends in the marine industry.</span>
                        </Stack>
                    </Grid>
                    <Grid className="text-center" item xs={12} md={6}>
                        <img width={262} src={footerScreenImages.social_plateform_cmty} alt='Social'/>
                    </Grid>
                    <Grid className="text-center" item xs={12} md={6}>
                        <img width={262} src={footerScreenImages.your_essential_rsrc} alt='Essential'/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>
                            <p className="as-com-subtitle">Your Essential Resource</p>
                            <span className="as-com-text">Whether you're a boat owner, a dealer, a surveyor, or a shipper, the AdamSea community is an invaluable resource for anyone looking to build their business and connect with others in the industry.</span>
                        </Stack>
                    </Grid>
                </Grid>
            </div>
        </Layout>
    )
}
