import React from 'react'

import { dimension, getRelatedListingType, userRoleTypes, userRoles } from '../../util/enums/enums'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { ShowAllLink } from '../helper/showAllLink'

import { UserProfileBoats } from '../userProfile/UserProfileBoats'
import { BoatGrid } from './boatGrid'
import { RecommendedMarinaStorages } from './recommendedMarinaStorages'
import { RentSharedTrips } from './rentSharedTrips'
import ShowBoatCards from './showBoatCards'
import BoatServicesAdded from './boatAddedServices'
import { SurveyorList } from '../salesEngine/SurveyorList'
import { KeyboardArrowRight } from '@material-ui/icons'

export class UserProfileListings extends React.Component {
  state = {
    width: dimension.userBoatProfile.width,
    height: dimension.userBoatProfile.height,
    gridClassName: `grid--new--${dimension['userBoatProfile'].divide}`,
    margin: dimension.userBoatProfile.margin,
  }

  widthZoom = () => {
    const width = getRatioZoomResponsive(dimension, 'userBoatProfile', '.zoom-container')
    const height = getHeightZoomResponsive(dimension, 'userBoatProfile', '.zoom-container')

    this.setState({ width: width.width, gridClassName: width.className, margin: width.margin, height })
  }

  componentDidMount() {
    this.widthZoom()
    window.addEventListener('resize', this.widthZoom)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.widthZoom)
  }

  render() {
    const { itemsLength, data, total, role, id, totalBoatShows } = this.props

    return (
      <>
        {role && role.type === userRoleTypes.SELLER && total > 0 && (
          <div className="home-page boat-show-with-map">
            <BoatGrid
              xs={12}
              sm={3}
              isTopLabel
              route
              itemsLength={4}
              boatGrid={data.boats}
              isUserProfile
              isBottomRating
              className="user--profile--boats--grid"
            />
          </div>
        )}

        {/* Rent */}
        {role && role.aliasName === userRoles.RENT_AND_CHARTER && (
          <div className="rent-show-all-grid rent-layout">
            {total > 0 && (
              <RentSharedTrips
                itemsLength={4}
                dimensionKey="rentUserProfileTrip"
                className="grid--rent--profile--trip"
                showType={data.rentUserProfileTrip}
                rentSharedTrips={data.boatRents}
                isUserProfile
              />
            )}
          </div>
        )}

        {/* Marina And Storage */}
        {role && role.aliasName === userRoles.MARINA_AND_STORAGE && (
          <div className="marina-storage-layout show-marina-storage-layout w-100">
            <div className="most-popular-main cursor-pointer marina-ads-sections marina--user-profile-listing">
              {total > 0 && (
                <RecommendedMarinaStorages
                  data={data?.marinas}
                  itemsLength={4}
                  dimensionKey="userMarinaMostPopular"
                  isShowLink={false}
                  isUserProfile
                />
              )}
            </div>
          </div>
        )}

        {/* Member */}
        {/* {role && role.aliasName === userRoles.MEMBER && (
          <ShowBoatCards xs={4} inProfile sm={4} showBoatCards={data?.boatShows} showLength={4} />
        )} */}

        {/* Service And Maintenance */}
        {role && role.aliasName === userRoles.SERVICE_AND_MAINTENANCE && (
          <div className="boat-service-layout">
            {total > 0 && (
              <BoatServicesAdded
                boatAddedServices={data.yachts}
                iconColor="iconColor-boatInner"
                itemLength={4}
                showType="Related Boats Services"
              />
            )}
          </div>
        )}

        {/* Surveyor */}
        {role && role.aliasName === userRoles.SURVEYOR && data?.surveyorBranches?.length > 0 && (
          <div className="surveyor-list-nearest-carousel surveyor-grid-3">
            {total > 0 &&
              data.surveyorBranches.slice(0, 5).map(item => {
                return <SurveyorList key={item.id} userInfo={item} isBranchList inProfile />
              })}
          </div>
        )}

        <ShowAllLink
          totalLength={total}
          className="boat--grid-show--all"
          data={total}
          itemsLength={itemsLength}
          url={`/related-boats/${id}/${role && role.aliasName}/${getRelatedListingType(role && role.aliasName)}`}
          showAllText={
            role && (
              role.aliasName === userRoles.SURVEYOR
              ? 'branches'
              : role.aliasName === userRoles.SERVICE_AND_MAINTENANCE
              ? 'boat services'
              : role.aliasName === userRoles.MEMBER
              ? 'boats'
              : role.aliasName === userRoles.MARINA_AND_STORAGE
              ? 'marina and storage facilities'
              : role.aliasName === userRoles.RENT_AND_CHARTER
              ? 'boats for rent'
              : role.type === userRoleTypes.SELLER
              ? 'boats for sale'
              : ''
            )
          }
        />
        {role && (role.aliasName === userRoles.MEMBER || data?.boatShows?.length > 0) && (
        <div className='mt-3'>
          <strong className="color-black section-heading section--heading-user--profile mt-0 article--listing--title font-32">Boat shows</strong>
          <ShowBoatCards xs={4} inProfile sm={4} showBoatCards={data?.boatShows} showLength={4} />
        {data?.boatShows &&  data.boatShows.length > 4 ? (
          <div className="w-100">
            <h5 className="mt-0">
              <a href={`/related-boats/${id}/${role && role.aliasName}/boat-shows`} target="_blank" className="boat--grid-show--all fontSize12 show-link mb-0 text-decoration-unset hover-text-only">
                See all {totalBoatShows}{' '}boat shows
                <KeyboardArrowRight />
              </a>
            </h5>
          </div>
        ) : (
          <></>
        )}
        </div>
        )}

      </>
    )
  }
}
UserProfileListings.defaultProps = {
  total: 0,
  totalBoatShows: 0
}
