import gql from 'graphql-tag'
import { ImageType } from './schemaTypes/schemaTypes'

export const categoryWiseAd = gql`
  query categoryWiseAd($category: String!) {
    categoryWiseAd(category: $category) {
      id
      title
      description
      link
      linkTitle
      imageUrl  ${ImageType}
    }
  }
`
