import React, { useState, useEffect } from 'react'

import { SectionWithShowMapStyleBoat, MapBoatGridStyleGrid } from '../styleComponent/styleComponent'
import { Icons } from '../icons'
import SharePopup from '../share/SharePopup'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { wishlistModuleEnum, shareModule, dimension, defaultImage } from '../../util/enums/enums'
import { displayDefaultNumericValue } from '../../helpers/string'

import { commonRentTypeLabel, percentageFormate } from '../../util/utilFunctions'
import { WishlistComponent } from '../wishlist/WishlistComponent'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import RatingComponent from '../rating/Rating'
import ImageGalleryComponent from '../imageHelper/imageGalleryComponent'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import { cityCountryNameFormatter } from '../../helpers/jsxHelper'
import './rentBoatMapCard.scss'
import { ButtonComponent } from '../form/Button'
import { viewBoatHandler, viewRentBoatHandler } from '../../helpers/boatHelper'
import WishListButton from '../staticComponent/wishListButton'

export const RentBoatMapCard = props => {
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [isArrow, setIsArrow] = useState(true)

  const { boat, onClick, index, isWishlist, removeFromList, sliceTo } = props

  const {
    id,
    model,
    images,
    rating,
    price,
    tripType,
    trip,
    maximumGuest,
    boatLength,
    address: [{ city, country }],
  } = boat

  const [height, setHeight] = useState(dimension.featureBoat.height)
  const [width, setWidth] = useState(dimension.featureBoat.width)

  const handleClick = index => {
    setSelectedIndex(selectedIndex !== index ? index : null)
  }

  const zoomWidth = () => {
    const className = isWishlist ? '.dashboard-layout-responsive' : '.grid--w-100--map'
    const widthR = getRatio(dimension, 'showMapRatio', className)
    const heightR = getHeightRatio(dimension, 'showMapRatio', className)
    widthR && setWidth(widthR)
    heightR && setHeight(heightR)
  }

  useEffect(() => {
    zoomWidth()
    window.addEventListener('resize', zoomWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="rent--boat--card--show--gird" onMouseEnter={() => props.onBoatHover(boat)} onMouseLeave={props.onBoatHover}>
      <div
        className="rent--boat--card--show--img"
        style={{ width: `${width}px` }}
        onMouseLeave={() => {
          setIsArrow(false)
        }}
        onMouseOver={() => {
          setIsArrow(true)
        }}
      >
        {isWishlist ? (
          <SectionWithShowMapStyleBoat
            width={width}
            height={height}
            img={(images && images.length && encodeURI(images[0]?.url)) || defaultImage}
            onClick={onClick}
          />
        ) : (
          <>
            {images && images.length > 0 && (
              <ImageGalleryComponent
                width={width}
                height={height}
                onClick={onClick}
                isArrow={isArrow}
                sliceTo={sliceTo}
                images={images}
              />
            )}
          </>
        )}
        <div className="card-action rent-card-action common--map--card--section">
          <div className="share-icon">
            <WishlistComponent moduleId={id} moduleType={wishlistModuleEnum.BOAT_RENT} />
            {/* <SharePopup
              handleClick={() => handleClick(index)}
              index={index}
              selectedIndex={selectedIndex}
              moduleId={id}
              moduleType={shareModule.BOAT_RENT}
              sharedUrl={viewRentBoatHandler(boat, true, true)}
            /> */}
          </div>
        </div>
      </div>
      <MapBoatGridStyleGrid width={width} className="rent--boat--card--details--main">
        <div className="rent--boat--value-map" onClick={onClick}>
          <div className="rent--boat--value--price">
            <CurrencyContextConsumer>
              {({ currentCurrency }) => (
                <span className="rent--card--show-map--price">{getConvertedPrice(price, currentCurrency)} Person</span>
              )}
            </CurrencyContextConsumer>
          </div>
          <div className="rent--boat--value-map-name">{model}</div>
          <div className="rent--boat-city-country">{cityCountryNameFormatter(city, country, ' • ')}</div>
          <div className="rent--boat--value-type">
            {trip?.alias} <div className="rent--with--map--trip--type">{tripType.alias}</div>
          </div>
          <div className="rent--boat--value-rating">
            <RatingComponent rating={rating} className="rating-clr" />
            <span className="rent--boat--value-count">({percentageFormate(rating)})</span>
          </div>
        </div>

        {isWishlist && (
          <div className="manage-boat-card-button">
            <WishListButton viewOnclick={() => viewRentBoatHandler(boat)} removeOnclick={removeFromList} />
          </div>
        )}
      </MapBoatGridStyleGrid>
    </div>
  )
}

RentBoatMapCard.defaultProps = {
  isWishlist: false,
  onBoatHover: () => null,
  sliceTo: 0,
}
