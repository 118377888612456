import { FormControl, Grid } from '@material-ui/core'
import { Field } from 'formik'
import React from 'react'
import { FormLabel } from 'react-bootstrap'
import { Layout } from '../layout/layout'
export default function ContactForm() {
  return (
    <div>
      <form action="contact.php" method="post">
        <div class="elem-group">
          <label for="name">Full Name</label>
          <input type="text" id="name" name="visitor_name" placeholder="John Doe" required />
        </div>
        <div class="elem-group">
          <label for="email">E-mail</label>
          <input type="email" id="email" name="visitor_email" placeholder="john.doe@email.com" required />
        </div>
        <div class="elem-group">
          <label for="department-selection">Topic</label>
          <select id="department-selection" name="concerned_department" required>
            <option value="">Select a Department</option>
            <option value="billing">Billing</option>
            <option value="marketing">Marketing</option>
            <option value="technical support">Technical Support</option>
          </select>
        </div>
        <div class="elem-group">
          <label for="title">Reason For Contacting Us</label>
          <input type="text" id="title" name="email_title" required placeholder="Unable to Reset my Password" />
        </div>
        <div class="elem-group">
          <label for="message">Suggestion</label>
          <textarea id="message" name="visitor_message" placeholder="Say whatever you want." required></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  )
}
