import React from 'react'
import '../../containers/salesEngine/SaleEngineLabelAndValue.scss'

export const SaleEngineLabelAndValue = React.memo(({ label, value, className, labelClass, valueClass }) => {
  return (
    <div className={`${className} common--label-with--value`}>
      <div className={`${labelClass} common--label-sales-engine color-black`}>{label}</div>
      <div className={`${valueClass} common--value-sales-engine`}>{value}</div>
    </div>
  )
})
SaleEngineLabelAndValue.defaultProps = {
  className: '',
  labelClass: '',
  valueClass: '',
}
