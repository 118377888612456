import React from 'react'
import { dimension } from '../../util/enums/enums'
import { CarouselWrapper } from './carouselWrapper'
import { ShowAllLink } from '../helper/showAllLink'
import { getCarousalGrid, getMarginGrid } from '../../helpers/jsxHelper'
import { dimensionAspectRatio } from '../../util/utilFunctions'
import { RentSharedTrip } from '../gridComponents/rentSharedTrip'

export class TripCarousels extends React.Component {
  state = {
    height: null,
    gutter: 40,
  }

  componentDidMount() {
    this.getCarousalHeight()

    window.addEventListener('resize', this.getCarousalHeight)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.getCarousalHeight)
  }
  getCarousalHeight = () => {
    const { dimensionKey, responsiveNumber, margin } = this.props

    const width = document.querySelector('.section-heading')
    const dividedBy = width && getCarousalGrid(width.offsetWidth, responsiveNumber)
    const actualWidth = width && width.offsetWidth / dividedBy
    const gutter = width && getMarginGrid(width.offsetWidth, margin)
    const aspectRatio = dimensionAspectRatio(dimension[dimensionKey].width, dimension[dimensionKey].height)
    const height = actualWidth / aspectRatio - 5
    this.setState({ height, gutter })
  }

  createChildren = () => {
    const { carouselData, itemsLength, isTopLabel } = this.props
    const { height } = this.state

    return (
      carouselData &&
      carouselData.length &&
      carouselData.map((value, index) => {
        return (
          itemsLength > index && (
            <RentSharedTrip height={height} index={index} value={value} isCarousal isTopLabel={isTopLabel} />
          )
        )
      })
    )
  }

  changeActiveItem = activeItemIndex => this.setState({ activeItemIndex })

  render() {
    const { height, gutter } = this.state
    const { items, carouselData, itemsLength, totalLength, showType, showAllText, responsiveNumber } = this.props
    return (
      <>
        <CarouselWrapper isZoom items={items} height={height} gutter={gutter} responsiveNumber={responsiveNumber}>
          {this.createChildren()}
        </CarouselWrapper>

        <ShowAllLink
          data={carouselData}
          itemsLength={itemsLength}
          totalLength={totalLength}
          showAllText={showAllText}
          url={`/show-all-rent/${showType}`}
        />
      </>
    )
  }
}

TripCarousels.defaultProps = {
  showType: '',
  margin: {
    lg: 15,
    md: 12,
    sm: 10,
  },
}
