import React, { Component } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { addNegotiablePrice } from '../../redux/actions'
import { Formik, ErrorMessage } from 'formik'
import { Field } from '..'
import { Button } from '@material-ui/core'
import * as Yup from 'yup'
import { requireMessage } from '../../helpers/string'
import { SuccessNotify } from '../../helpers/notification'
import ErrorFocus from '../ws/ErrorFocus'
import { popUpMessage } from '../../helpers/confirmationPopup'
import { helpTextEnum } from '../../util/enums/enums'
import { CommonSalesEnginTitle } from './CommonSalesEnginTitle'
import { ButtonComponent } from '../form/Button'
import { HelpIconComponent } from '../helper/helpIconComponent'
import { getLocalCurrencyPrice, getUsdPrice } from '../../helpers/currencyConverterHelper'
import { getLocalStorageItem } from '../../helpers/storageHelper'

class PriceNegotiable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      negotiablePrice: {
        id: props.salesEngine.id,
        price: getLocalCurrencyPrice(props.salesEngine.negotiatedBoatPrice) || '',
      },
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.createNegotiablePriceSuccess) {
      SuccessNotify('Sales Engine Negotiated Price added successfully.')
    } else if (nextProps.createNegotiablePriceError) {
      popUpMessage('Some error occurred while adding price.', 'Error')
    }

    return null
  }

  render() {
    const { addNegotiablePrice, salesEngine, priceClass } = this.props
    const { negotiablePrice } = this.state
    const currentCurrency = getLocalStorageItem('currentCurrency')
    return (
      <>
        <Formik
          initialValues={negotiablePrice}
          validationSchema={Yup.object().shape({
            price: Yup.number().required(requireMessage('price')).positive(),
          })}
          onSubmit={values => {
            if (values.price !== negotiablePrice.price) {
              let priceInUsd = values.price

              if (currentCurrency !== 'USD') {
                priceInUsd = getUsdPrice(values.price, currentCurrency)
              }

              addNegotiablePrice({ ...values, price: priceInUsd })
            }
          }}
          render={({ values, setFieldValue, handleSubmit, errors }) => (
            <Form onSubmit={e => e.preventDefault()} className={`${priceClass} between-information`}>
              <ErrorFocus />
              <Row className="m-0">
                <CommonSalesEnginTitle isReport title="New Total Price" />
                <HelpIconComponent helpText={helpTextEnum.negotiablePriceInput} containerClass="ml-2" />
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="d-flex sales--engine-final-price">
                    <span className="sales-engine-info-field flex-wrap d-flex justify-content-left align-items-center mr-2">
                      <Field
                        placeholder="Negotiable Price"
                        helpText={helpTextEnum.priceInput}
                        id={'price'}
                        name={'price'}
                        value={values.price}
                        required
                        type="number"
                        onChangeText={e => {
                          setFieldValue('price', +e.target.value || '')
                        }}
                        disabled={salesEngine.isSurveyorSkip ? salesEngine.boatPayment : salesEngine.surveyorPayment}
                      />
                      <ErrorMessage component="div" name="price" className="error-message"></ErrorMessage>
                    </span>

                    <ButtonComponent
                      onClick={handleSubmit}
                      color="danger"
                      className="final--price-btn"
                      disabled={salesEngine.isSurveyorSkip ? salesEngine.boatPayment : salesEngine.surveyorPayment}
                    >
                      Add New Price
                    </ButtonComponent>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        ></Formik>
      </>
    )
  }
}

const mapStateToProps = state => ({
  createNegotiablePriceSuccess: state.salesEngineReducer.createNegotiablePriceSuccess,
  createNegotiablePriceError: state.salesEngineReducer.createNegotiablePriceError,
})

const mapDispatchToProps = dispatch => ({
  addNegotiablePrice: data => dispatch(addNegotiablePrice(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PriceNegotiable)
