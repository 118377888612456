import { all, fork } from 'redux-saga/effects'
import loginSaga from './loginSaga'
import dashboardSaga from './dashboardSaga'
import marinaAndStorageSaga from './marinaAndStorageSaga'
import boatSaga from './boatSaga'
import branchSaga from './branchSaga'
import boatRentSaga from './boatRentSaga'
import articleSaga from './articleSaga'
import rentSaga from './rentSaga'
import boatShowSaga from './boatShowSaga'
import boatServiceSaga from './boatServiceSaga'
import videoSaga from './VideoSaga'
import reviewSaga from './ReviewSaga'
import advertisementSaga from './advertisementSaga'
import salesEngineSaga from './salesEngineSaga'
import mediaReviewSaga from './mediaReviewsSaga'
import mediaArticlesSaga from './mediaArticleSaga'
import userGuideSaga from './userGuideSaga'
import userFaqSaga from './userFaqSaga'
import pageInfoByTypeSaga from './pageInfoByTypeSaga'
import { getHomeBannerSaga } from './bannerSaga'
import boatRentBookingSaga from './BoatRentBookingSaga'
import shareSaga from './shareSaga'
import dealersSaga from './dealersSaga'
import paymentSaga from './paymentSaga'
import pdfSaga from './pdfSaga'
import reportSaga from './reportSaga'
import jobsSaga from './jobsSaga'
import downloadsSaga from './downloadsSaga'
import getChatUserListsSaga from './chatSaga'
import seoSaga from './seoSaga'
import notificationsSlice from '../slices/notificationsSlice'
import accountSlice from '../slices/accountSlice'

export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(dashboardSaga),
    fork(boatSaga),
    fork(marinaAndStorageSaga),
    fork(branchSaga),
    fork(boatRentSaga),
    fork(articleSaga),
    fork(rentSaga),
    fork(boatShowSaga),
    fork(boatServiceSaga),
    fork(videoSaga),
    fork(reviewSaga),
    fork(advertisementSaga),
    fork(salesEngineSaga),
    fork(mediaReviewSaga),
    fork(mediaArticlesSaga),
    fork(userGuideSaga),
    fork(userFaqSaga),
    fork(pageInfoByTypeSaga),
    fork(getHomeBannerSaga),
    fork(boatRentBookingSaga),
    fork(shareSaga),
    fork(dealersSaga),
    fork(paymentSaga),
    fork(pdfSaga),
    fork(reportSaga),
    fork(jobsSaga),
    fork(downloadsSaga),
    fork(getChatUserListsSaga),
    fork(seoSaga),
    fork(notificationsSlice.saga),
    fork(accountSlice.saga),
  ])
}
