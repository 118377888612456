import React, { useEffect, useRef, useState } from 'react'
import { uniqueId } from 'lodash'
import cn from 'classnames'
import styles from './tooltip.module.css'

const Tooltip = ({ className, children, content, position = 'top', visible }) => {
  const [id] = useState(uniqueId('commonTooltip'))
  const childRef = useRef(null)
  const timeoutRef = useRef(null)
  const clonedChild = React.cloneElement(children, { ref: childRef })

  useEffect(() => {
    if (id && visible) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        document.getElementById(id)?.scrollIntoView({ block: 'center' })
      }, 200)
    }
  }, [id, visible])

  return (
    <div className={cn(styles['tooltip-wrapper'], className)} id={id}>
      {clonedChild}
      {visible && <div className={cn(styles['tooltip-box'], styles[`tooltip-${position}`])}>{content}</div>}
    </div>
  )
}

export default Tooltip
