import moment from 'moment'
import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { ButtonComponent } from '../../../components/form/Button'
import { CurrencyContextConsumer } from '../../../CurrencyContext'
import { viewBoatHandler } from '../../../helpers/boatHelper'
import { getConvertedPrice } from '../../../helpers/currencyConverterHelper'
import { nameFormatter, TitleCase } from '../../../helpers/string'
import { toast } from 'react-toastify'

const CardList = props => {
  const [expnaded, setExpanded] = useState(false)
  return (
    <div className="card mb-3 manage-payment-card">
      <div className="card-body m-0">
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <ul className="d-flex justify-content-center align-items-center table-head-title">
              <li className="mr-5">
                <div className="arrow-down" onClick={() => setExpanded(!expnaded)}>
                  {!expnaded ? '+' : '-'}
                </div>
              </li>
              <li className="mr-5">
                <div className="payment-card-content">
                  <p className="m-0">Ad ID</p>
                  <div>
                    {props.data.payments && props.data.payments.length > 0 && props.data.payments[0]?.salesEngineId?.boat?.adId}
                  </div>
                </div>
              </li>
              <li className="mr-5">
                <div className="payment-card-content">
                  <p className="m-0">Sales Engine ID</p>
                  <div>{props.data.salesEngineId}</div>
                </div>
              </li>
            </ul>
          </div>
          <div className="">
            <ButtonComponent
              className="btn mr-2 payment--view--boat--btn"
              onClick={() =>
                props.data?.payments[0]?.salesEngineId?.boat && viewBoatHandler(props.data.payments[0].salesEngineId.boat)
              }
            >
              View Boat
            </ButtonComponent>
            <ButtonComponent
              className="btn payment--view--salesengine--btn"
              onClick={() => {
                props.history &&
                  props.data?.payments[0]?.salesEngineId?._id &&
                  props.history.push(`/sales-engine-process/${props.data.payments[0].salesEngineId._id}`)
              }}
            >
              View Sales Engine
            </ButtonComponent>
          </div>
        </div>
        {expnaded && (
          <div className="mt-3">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Stripe Transaction Id</th>
                  <th>SELLER</th>
                  <th>TOTAL COST</th>
                  <th>PAID TO</th>
                  <th>Status</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {props.data.payments &&
                  props.data.payments.length > 0 &&
                  props.data.payments.map(item => (
                    <tr key={item._id}>
                      <td className="align-middle">{moment(item.createdAt).format('ll')}</td>
                      <td className="align-middle">{item.paymentId}</td>
                      <td className="align-middle">
                        {nameFormatter([item.salesEngineId?.seller?.firstName, item.salesEngineId?.seller?.lastName])}
                      </td>
                      <td className="align-middle">
                        <CurrencyContextConsumer>
                          {({ currentCurrency }) => <span>{getConvertedPrice(item.amount, currentCurrency)} </span>}
                        </CurrencyContextConsumer>
                      </td>
                      <td className="align-middle">{item.receiverId?.role?.role}</td>
                      <td className="align-middle">
                        <div className="boatShow-action-button justify-content-start">
                          <span
                            className={`${
                              item.status === 'succeeded' ? 'bg-green-color' : 'bg-red-color'
                            } payment--status--detail`}
                          >
                            {TitleCase(item.status)}
                          </span>
                        </div>
                      </td>
                      <td>
                        <ButtonComponent
                          className={`btn mr-2 payment--view--boat--btn`}
                          onClick={() => {
                            if (item.receipt) {
                              window && window.open(`${item.receipt}`, '_blank')
                            } else {
                              toast.error('Receipt not found!', { autoClose: 5000 })
                            }
                          }}
                        >
                          Receipt
                        </ButtonComponent>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default withRouter(CardList)
