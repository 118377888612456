import { gql } from 'apollo-boost'

export const getUserFlagReportForModule = gql`
  query singleFlagReport($moduleId: String, $user: String) {
    singleFlagReport(moduleId: $moduleId, user: $user) {
      id
      moduleId
      moduleType
      reportType
      message
      country
      createdAt
      rating
    }
  }
`
