import React, { Component } from 'react'
import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'

import { UserProvider } from '../UserContext'
import { confirmSubmitHandler, PasswordExpirePopUp } from '../helpers/confirmationPopup'
import { getLocalStorageItem, setLocalStorageItem, isExpiredLocalStorage } from '../helpers/storageHelper'
import { searchBy } from '../helpers/currencyConverterHelper'
import {
  setCurrentLocation,
  logout,
  getUserLocation,
  getUserRoles,
  getCurrencyRates,
  setCurrentCurrency,
  createWishlist,
  removeWishlist,
  getAllWishlists,
  getUserById,
  addOrRemoveFriend,
} from '../redux/actions'
import { currencyEnum } from '../util/enums/currencyEnums'
import { createReview } from '../redux/actions/ReviewAction'
import { SuccessNotify } from '../helpers/notification'
import { CurrencyContextProvider } from '../CurrencyContext'
import { shareDetailsWithUsers } from '../redux/actions/shareAction'
import LoginModal from '../components/modal/loginModal'

export default function (ComposedComponent) {
  class WithProps extends Component {
    constructor(props) {
      super(props)
      this.state = {
        country: props.currentLocation,
        loginModal: false,
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.currentLocation !== prevState.country) {
        return {
          country: nextProps.currentLocation,
        }
      }
      if (!getLocalStorageItem('currentCurrency') && getLocalStorageItem('countryCode')) {
        const [{ currency }] = searchBy.search(getLocalStorageItem('countryCode'))

        // TODO:: Default currency if needed later on
        // const [{ label }] = currencyEnum

        if (currency && currency.currencyCode) {
          setLocalStorageItem('currentCurrency', currency.currencyCode)
          nextProps.setCurrentCurrency(currency.currencyCode)
        }
      }
      if (getLocalStorageItem('currentCurrency') && !nextProps.currentCurrency) {
        nextProps.setCurrentCurrency(getLocalStorageItem('currentCurrency'))
      }
      if (nextProps.notifiedSuccess) {
        SuccessNotify('Notified request send successfully.')
      }
      if (nextProps.addWishlistSuccess) {
        nextProps.getAllWishlists()
        SuccessNotify('Added to the wishlist successfully.')
      }
      if (nextProps.removeWishlistSuccess) {
        nextProps.getAllWishlists()
        SuccessNotify('Item removed from wishlist successfully.')
      }
      if (nextProps.shareSuccess) {
        SuccessNotify('Shared Successfully.')
      }
      return null
    }

    async componentDidMount() {
      const { getUserLocation, getUserById, getUserRoles, getCurrencyRates } = this.props

      if (isExpiredLocalStorage()) {
        // getGeoLocation(this.getValue, true)
        getUserLocation()
      }

      this.isAuthCheck()

      getLocalStorageItem('userId') && getUserById(getLocalStorageItem('userId'))
      getUserRoles()
      getCurrencyRates()
    }

    isAuthCheck = () => {
      const jwtToken = getLocalStorageItem('token')

      if (jwtToken) {
        const now = Date.now()
        const decoded = jwtDecode(jwtToken)

        if (now >= decoded.exp * 1000) {
          this.props.history.push('/login')
          PasswordExpirePopUp(this.doReLogin, null, 'Alert!', 'Session expired!, Please re-login to continue.')
        }
      }
      return
    }

    doReLogin = () => {
      const { logout } = this.props

      logout()
      // this.handlerLoginModal()
    }

    // getValue = (response) => {
    //     const { country } = response
    //     const { setCurrentLocation } = this.props

    //     setCurrentLocation(country)
    // }

    handlerLoginModal = () => this.setState(prevState => ({ loginModal: !prevState.loginModal }))

    render() {
      const { country, loginModal } = this.state
      const {
        roles,
        currentUser,
        history,
        match,
        logout,
        setCurrentCurrency,
        currentCurrency,
        getAllWishlists,
        createWishlist,
        removeWishlist,
        allWishlists,
        createReview,
        addWishlistSuccess,
        removeWishlistSuccess,
        shareDetailsWithUsers,
        addOrRemoveFriend,
      } = this.props

      return (
        <>
          <UserProvider
            value={{
              roles,
              currentUser,
              history,
              match,
              country,
              logout,
              currentCurrency,
              setCurrentCurrency,
              getAllWishlists,
              createWishlist,
              removeWishlist,
              allWishlists,
              createReview,
              addWishlistSuccess,
              removeWishlistSuccess,
              shareDetailsWithUsers,
              addOrRemoveFriend,
            }}
          >
            <CurrencyContextProvider value={{ currentCurrency }}>
              <LoginModal open={loginModal} close={this.handlerLoginModal} />
              <ComposedComponent {...this.props} />
            </CurrencyContextProvider>
          </UserProvider>
        </>
      )
    }
  }

  const mapStateToProps = state => ({
    roles: state.loginReducer.roles,
    currentUser: state.loginReducer.currentUser,
    currentLocation: state.loginReducer.currentLocation,
    currentCurrency: state.dashboardReducer.currentCurrency,
    notifiedSuccess: state.dashboardReducer.notifiedSuccess,
    addWishlistSuccess: state.dashboardReducer.addWishlistSuccess,
    removeWishlistSuccess: state.dashboardReducer.removeWishlistSuccess,
    allWishlists: state.dashboardReducer.allWishlists,
    shareSuccess: state.shareReducer && state.shareReducer.shareSuccess,
  })

  const mapDispatchToProps = dispatch => ({
    setCurrentLocation: data => dispatch(setCurrentLocation(data)),
    logout: () => dispatch(logout()),
    getUserLocation: data => dispatch(getUserLocation(data)),
    getUserRoles: data => dispatch(getUserRoles(data)),
    getCurrencyRates: () => dispatch(getCurrencyRates()),
    setCurrentCurrency: data => dispatch(setCurrentCurrency(data)),
    createWishlist: data => dispatch(createWishlist(data)),
    removeWishlist: data => dispatch(removeWishlist(data)),
    createReview: data => dispatch(createReview(data)),
    getAllWishlists: data => dispatch(getAllWishlists(data)),
    getUserById: data => dispatch(getUserById(data)),
    shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
    addOrRemoveFriend: data => dispatch(addOrRemoveFriend(data)),
  })

  return connect(mapStateToProps, mapDispatchToProps)(WithProps)
}
