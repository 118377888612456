import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { getAllJobs, searchJobs, getJobsContent } from '../../../redux/actions/jobsAction'
import { SkeletonLoader } from '../../loader/SkeletonLoader'
import { pagination, skeletonType } from '../../../util/enums/enums'
import { ButtonComponent } from '../../form/Button'
import { Layout } from '../../layout/layout'
import { JobCard } from './JobCard'
import { Field } from '../../ws/Field'
import './careers.scss'
import { PaginationBar } from '../../pagination/PaginationBar'
import { displayDefaultValue } from '../../../helpers/string'

class Careers extends Component {
  state = {
    applyNow: false,
    jobId: {},
    searchTerm: '',
  }

  componentDidMount() {
    const { getAllJobs, getJobsContent } = this.props
    getJobsContent()

    getAllJobs({
      page: pagination.PAGE_COUNT,
      limit: pagination.CARRER_LIMIT,
    })
  }

  createMarkup = text => {
    return { __html: text }
  }

  render() {
    const { isLoading, jobsTotal, jobs, getAllJobs, jobsContent, searchJobs } = this.props
    return (
      <>
        <Layout>
          <div className="dynamic-page-container">
            <div className="container100">
              <div className="carreer-card-wrapper">
                <h1 className="carreer-card-title">Careers</h1>
                <div className="carreer-card-disc-wrapper">
                  <div className="carreer-card-disc">
                    <span
                      className="text-justify"
                      dangerouslySetInnerHTML={this.createMarkup(displayDefaultValue(jobsContent?.description))}
                    ></span>
                  </div>
                  <div className="carreer-card-search">
                    <Field
                      type="text"
                      className="search-location-carreer"
                      placeholder="Search for a vacancy"
                      class
                      onChangeText={e => {
                        this.setState({ searchTerm: e.target.value })
                        e.target.value && searchJobs({ searchTerm: e.target.value })
                      }}
                    />
                    <ButtonComponent variant="fill" className="search-icon-carreer">
                      <i class="adam-search1"></i> Find
                    </ButtonComponent>
                  </div>
                </div>
              </div>
            </div>

            <div className='container100'>
              <Grid container spacing={0} className="careers-container">
                {isLoading && <SkeletonLoader type={skeletonType.table} itemsLength={8} />}
                {!isLoading && jobs?.length === 0 && <div className="no-data-available text-center w-100">No Careers Available</div>}
                {!isLoading && jobs?.length > 0 && jobs.slice(0, 8).map((job, index) => <JobCard index={index} job={job} />)}
              </Grid>
              {jobs?.length > 0 && jobsTotal > pagination.CARRER_LIMIT && (
                <div className="mt-5 boat-pagination careers-pagination-container w-100">
                  <PaginationBar
                    action={getAllJobs}
                    value={{
                      page: pagination.PAGE_COUNT,
                      limit: pagination.CARRER_LIMIT,
                    }}
                    pageLimit={pagination.CARRER_LIMIT}
                    totalRecords={jobsTotal}
                  />
                </div>
              )}
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.jobsReducer?.isLoading,
  jobs: state.jobsReducer?.jobs,
  jobsTotal: state.jobsReducer?.total,
  jobsContent: state.jobsReducer?.jobsContent,
})

const mapDispatchToProps = dispatch => ({
  getAllJobs: data => dispatch(getAllJobs(data)),
  searchJobs: data => dispatch(searchJobs(data)),
  getJobsContent: () => dispatch(getJobsContent()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Careers)
