import { GET_ALL_REVIEWS_SUCCESS, GET_ALL_REVIEWS_FAILURE } from '../actionTypes'

const InitialState = {
  reviews: [],
}

export const mediaReviewsReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_ALL_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.payload.items,
      }

    case GET_ALL_REVIEWS_FAILURE:
      return {
        ...state,
        reviews: [],
      }

    default:
      return state
  }
}
