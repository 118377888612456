import React, { PureComponent } from 'react'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import Truncate from 'react-truncate'
import { CommonAdamSeaStyle } from '../styleComponent/styleComponent'
import SharePopup from '../share/SharePopup'

import { viewServiceBoatHandler } from '../../helpers/boatHelper'
import { Grid } from '@material-ui/core'
import { wishlistModuleEnum, shareModule, defaultProfileIcon } from '../../util/enums/enums'
import { WishlistComponent } from '../wishlist/WishlistComponent'
import { textTruncate, nameFormatter } from '../../helpers/string'
import '../../assets/css/component/topRatedServices.scss'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
export class BoatServiceDetail extends PureComponent {
  state = {
    selectedIndex: null,
  }

  handleClick = index => {
    const { selectedIndex } = this.state
    this.setState({
      selectedIndex: selectedIndex !== index ? index : null,
    })
  }

  render() {
    const { selectedIndex } = this.state
    const { index, value, width, height } = this.props
    return (
      <div className="boat-box top--service--grid--main mt-2">
        <div className="boat-box-shadow ">
          <div className="card-action top-service-share-icon">
            <div className="company--logo--recently--img">
              {console.log(value, 'value')}
              <img 
              loading="lazy" 
              onClick={() => redirectToUserProfile(value?.user, this.context.history)}
              src={value.user?.image?.url || defaultProfileIcon} 
              alt="logo" />
            </div>
            <div className="share-icon">
              <WishlistComponent moduleId={value.id} moduleType={wishlistModuleEnum.YACHT_SERVICE} />
              {/* <SharePopup
                handleClick={() => this.handleClick(index)}
                index={index}
                selectedIndex={selectedIndex}
                moduleId={value.id}
                moduleType={shareModule.YACHT_SERVICE}
              /> */}
            </div>
          </div>
          <div className="top--service--main--img--style marina-img-height-without-rated" style={{ height: `${height}px` }}>
            <CommonAdamSeaStyle
              onClick={() => viewServiceBoatHandler(value)}
              height={height}
              width={width}
              className="w-100 top--service--img--style marina-img-height-without-rated"
              img={value.images && value.images.length && encodeURI(value.images[0]?.url)}
            />
            <div className="top--service--view--style">
              <span className="span--view">
                {value.pageVisits && value.pageVisits.length} VIEWS
              </span>
            </div>
          </div>
          <div onClick={() => viewServiceBoatHandler(value)} className="boat-info boat-service-info-layout">
            <h5 className="team--commitment--text">{value?.user?.companyName}</h5>
            <div className="boat--service--viewed-desc line-clamp font-13">{textTruncate(value.service, 95)}</div>
            <div className="color-black py-1 font-13 font-weight-500">By <a href={value?.user ? redirectToUserProfile(value?.user, null, null, true) : '#'} target="_blank" onClick={e => e.stopPropagation()}>{nameFormatter([value?.user?.firstName, value?.user?.lastName])}</a></div>
          </div>
        </div>
      </div>
    )
  }
}
