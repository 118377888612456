import { languages } from 'countries-list'

export const dashboardTabs = {
  userProfile: {
    title: 'User Profile',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'profile',
  },
  buyItNow: {
    title: 'Buy it Now',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'sales-engines?buyItNow=true',
  },
  boats: {
    title: 'Add and Manage Boats',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'manage-boats',
  },
  brokers: {
    title: 'Dealers',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'manage-dealers',
  },
  boatRents: {
    title: 'Manage Rentals',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'manage-boat-rents',
  },
  branch: {
    title: 'Manage Request and Branches',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'manage-branches',
  },
  services: {
    title: 'Service',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'add-boat-service',
  },
  marinaAndStorage: {
    title: 'Marina & Storage',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'manage-marina-storage',
  },
  auctionRooms: {
    title: 'Auction Rooms',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'manage-auction-rooms',
  },
  boatShows: {
    title: 'Boat Shows',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'manage-boat-shows',
  },
  contactLeads: {
    title: 'Contact Leads',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'contact-leads',
  },
  agreementsAndFiles: {
    title: 'Agreements & Files',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'agreement-file',
  },
  salesEngine: {
    title: 'My Store',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'sales-engines',
  },
  salesEngineArchive: {
    title: 'My Store Archive',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'sales-engine-archives',
  },
  wishlists: {
    title: 'Manage Wishlist',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'manage-wishlist',
  },
  articles: {
    title: 'Manage Articles',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'manage-articles',
  },
  advertisements: {
    title: 'Advertisements',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: '',
  },
  changePassword: {
    title: 'Change Password',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'change-password',
  },
  boatBookings: {
    title: 'My Bookings',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'my-bookings',
  },
  auctionRoomBidList: {
    title: 'Manage Auctions',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'auction-room-bid-list',
  },
  myRequests: {
    title: 'My Requests',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'my-requests',
  },
  myDeals: {
    title: 'My Deals',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'my-deals',
  },
  logOut: {
    title: 'Logout',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'logout',
  },
  manufacturerRequest: {
    title: 'Requested Manufacturer',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    url: 'request-manufacturer',
  },
  partnershipCode: {
    title: 'Partnership Code',
    description: 'Partnership Code',
  }
}

export const userRoles = {
  MEMBER: 'member',
  BOAT_OWNER: 'boat-owner',
  BROKER_AND_DEALER: 'broker-and-dealer',
  BOAT_MANUFACTURER: 'boat-manufacturer',
  YACHT_SHIPPER: 'yacht-shipper',
  SURVEYOR: 'surveyor',
  RENT_AND_CHARTER: 'rent-and-charter',
  SERVICE_AND_MAINTENANCE: 'service-and-maintenance',
  MARINA_AND_STORAGE: 'marina-and-storage',
  AGENT: 'agent',
}

export const userRoleTypes = {
  BUYER: 'buyer',
  SELLER: 'seller',
  ADDITIONAL_MARKET: 'additional-market',
  OTHER: 'other',
  AGENT: 'agent',
}

export const urlsWithoutPermissions = [
  dashboardTabs.changePassword.url,
  dashboardTabs.logOut.url,
  dashboardTabs.userProfile.url,
  '',
  'dashboard',
]

export const salesEngineAccessibleTypes = [userRoleTypes.BUYER, userRoleTypes.SELLER, userRoleTypes.OTHER, userRoleTypes.AGENT]

export const auctionRoomAccessibleRoles = [userRoles.BOAT_OWNER, userRoles.BROKER_AND_DEALER]

export const boatAttachIdAccessibleRoles = [userRoles.BROKER_AND_DEALER, userRoles.BOAT_MANUFACTURER]

export const myStoreIdAccessibleRoles = [
  userRoles.BOAT_OWNER,
  userRoles.BROKER_AND_DEALER,
  userRoles.BOAT_MANUFACTURER,
  // userRoles.SURVEYOR,
  userRoles.YACHT_SHIPPER,
  userRoles.AGENT,
]

export const comLicenceAccessibleRole = [
  userRoles.AGENT,
  userRoles.BROKER_AND_DEALER,
  userRoles.BOAT_MANUFACTURER,
  userRoles.YACHT_SHIPPER,
  userRoles.SURVEYOR,
  userRoles.SERVICE_AND_MAINTENANCE,
  userRoles.MARINA_AND_STORAGE,
]

export const companySectionAccessibleRole = [
  userRoles.AGENT,
  userRoles.BROKER_AND_DEALER,
  userRoles.BOAT_MANUFACTURER,
  userRoles.YACHT_SHIPPER,
  userRoles.SURVEYOR,
  userRoles.SERVICE_AND_MAINTENANCE,
  userRoles.MARINA_AND_STORAGE,
]

export const officeLocationAccessibleRole = [userRoles.SURVEYOR, userRoles.YACHT_SHIPPER]

export const boatInformation = {
  TITLE: 'Boat information',
  BOAT_TYPE: 'Boat Type',
  BOAT_NAME: 'Boat Model',
  BOAT_PARKING: 'Boat Parking',
  TRAILER: 'Trailer',
  YEARS_OF_BUILT: 'Years Of Built',
  MANUFACTURED_BY: 'Manufactured By',
  HULL_MATERIAL: 'Hull Material',
  HULL_COLOR: 'Hull Color',
  HOURS_USED: 'Hours Used',
  SEATS: 'Seats',
  FUEL_TYPE: 'FUEL TYPE',
  KIND: 'Kind',
  KAYAK_TYPE: 'Kayak Type',
  IN_STOCK: 'In Stock',
  STOCK_QUANTITY: 'Stock Quantity',
  WEIGHT_CAPACITY: 'Weight Capacity',
}

export const mechanicalSystem = {
  TITLE: 'Mechanical System',
  WATER_MAKER: 'WATER MAKER',
  BOW_THRUSTER: 'BOW THRUSTER',
  STEERING_SYSTEM: 'STEERING SYSTEM',
  STABILIZER_SYSTEM: 'STABILIZER SYSTEM',
  FUEL_TYPE: 'FUEL TYPE',
  OIL_WATER_SEPARATOR: 'OIL/WATER SEPARATOR',
  NO_OF_ENGINE: 'Number Of Engines',
  YEAR_MODEL: 'YEAR MODEL',
  ENGINE_MANUFACTURER: 'Engine ManuFacturer',
  FUEL_CAPACITY: 'Fuel Capacity Tank (liter)',
  ENGINE_MODEL: 'Engine Model',
  ENGINE_TYPES: 'Engine Types',
  YEAR_MODEL: 'Year Model',
  ENGINE_HP: 'Engine HP',
  FRESH_WATER: 'Fresh Water Tank (liter)',
  HOLDING_CAPACITY: 'Holding Capacity Tank (gallons)',
  ENGINE_DRIVES: 'Engine Drives',
  ENGINE_STROKE: 'Engine Stroke',
}

export const electricalSystem = {
  TITLE: 'Electrical System',
  OUTPUT: 'OUTPUT',
  GENERATORS: 'GENERATORS',
  EMERGENCY_GENERATORS: 'EMERGENCY GENERATORS',
  TYPES_OF_BATTERIES: 'TYPES OF BATTERIES',
  NO_OF_BATTERIES: 'NO OF BATTERIES',
  CONTENT: 'CONTENT',
}

export const boatEngine = {
  TITLE: 'Boat Engine',
}

export const boatDimensions = {
  TITLE: 'Dimensions',
  DECK_NUMBER: 'DECK',
  HEIGHT: 'HEIGHT',
  LENGTH: 'LENGTH',
  WIDTH: 'WIDTH',
  WEIGHT: 'WEIGHT',
  BEAM: 'BEAM',
  DRAFT: 'DRAFT',
  DISPLACEMENT: 'DISPLACEMENT',
  MAIN_SALOON: 'MAIN SALOON',
  MAIN_SALOON_CONVERTIBLE: 'MAIN SALOON CONVERTIBLE',
  NO_OF_HEADS: 'NO OF HEADS',
  NO_OF_CREW_CABIN: 'NO OF CREW CABIN',
  NO_OF_CREW_BIRTHS: 'NO OF CREW BIRTHS',
  NO_OF_CREW_HEADS: 'NO OF CREW HEADS',
}

export const getListOfYears = () => {
  let listOfYears = []
  const year = new Date().getFullYear()
  for (let i = 0; i <= 40; i++) {
    listOfYears = listOfYears.concat(year - i)
  }
  listOfYears.reverse()
  // for (let i = 0; i <= 10; i++) {
  //   listOfYears = listOfYears.concat(year + i);
  // }

  return listOfYears
}

export const auctionCarouselOptions = ['None', 'Atria', 'Callisto', 'Dione']

export const pagination = {
  PAGE_COUNT: 1,
  MARKET_PAGE_LIMIT: 10,
  CARRER_LIMIT: 8,
  PAGE_RECORD_LIMIT: 20,
  MANAGE_PAGE_LIMIT: 1000,
  SHIPPER_PAGE_LIMIT: 10,
  SURVEYOR_PAGE_LIMIT: 10,
  AGENT_PAGE_LIMIT: 10,
  ARTICLE_PAGE_LIMIT: 6,
  INDEX_PAGE_LIMIT: 6,
  POPULAR_LIMIT: 15,
  GLOBAL_SEARCH_LIMIT: 20,
  HEADER_SEARCH_BOX_LIMIT: 5,
}

export const icons = {
  fridge: 'adam-refrigeratorr',
  water_ski: 'adam-jetski-facing-right',
  ipod_docking_system: 'adam-audio-system',
  automatic_pilot: 'adam-user',
  satellite_communication: 'adam-radar',
  radar: 'adam-radar',
  data_interface: 'adam-cog-4',
  tv: 'adam-tv',
  jacuzzi: 'adam-bathtub',
  microwave: 'adam-microwave-3',
  stove: ' adam-stove',
  rest_room: 'adam-room-service',
  freezer: 'adam-refrigeratorr',
  bbq: ' adam-bbq',
  black_water: ' adam-water',
  shower: 'adam-shower',
  dish_washer: 'adam-dishwasher',
  washing_machine: 'adam-washing-machine1',
  unser: 'adam-lightbulb',
  helipad: 'adam-parking',
  bimini: 'adam-sailingboat',
  raft: 'adam-wood-raft',
  table: ' adam-table',
  windlass: 'adam-ship-window',
  tabs: 'adam-tabs',
  bow: ' adam-bowboat',
  platform: 'adam-kitchen',
  swimming: 'adam-swimming-pool',
  sundeck: 'adam-sunbed1',
  radar: 'adam-radar',
  compass: 'adam-compass',
  gps: 'adam-gps',
  instruments: 'adam-navigation-main',
  navigation: 'adam-big-lighthouse',
  communication: 'adam-communication',
  rent_per_hour: require('../../assets/images/rent/canoe_10779970.svg'),
  private_trip: require('../../assets/images/rent/speed-boat_9340652.svg'),
  shared_trip: require('../../assets/images/rent/yul961_12741316.svg'),
  favicon: require('../../assets/images/logo/favicon.png'),
  fishing_boat: 'adam-fishing-boat1',
  motor_yacht: 'adam-motoryacht',
  sailing_boat: 'adam-sailingboat',
  power_boat: 'adam-powerboat',
  bow_boat: 'adam-bowboat',
  super_yacht: 'adam-superyacht',
  canone: 'adam-small-canoe',
  kayak: 'adam-kayak',
  jet_ski: 'adam-jetski',
  cuddy_boat: 'adam-cuddyboat',
  wedding_party: 'adam-party',
  deep_sea_fishing: 'adam-fish-facing-right',
  sea_tour: 'adam-ocean-waves',
  private_party: 'adam-mask',
  diving_trip: 'adam-diving-helmet',
  night_trip: 'adam-star-inside-circle',
  birthday_party: 'adam-party-hat',
  cruise_tour: 'adam-cruise-ship',
  yacht_charter: 'yacht-charter',
  event: 'adam-fireworks',
  bbq_grill: 'adam-bbq',
  outside_shower: 'adam-shower1',
  navigation_lights: 'adam-long-lighthouse',
  navigation_instruments: 'adam-compass',
  painting_and_fiberglass: 'adam-hair-brush',
  moorings: 'adam-coins',
  marina_management: 'adam-management',
  marina_product: 'adam-profits',
  mobile_marina: 'adam-remote-control',
  provisioning_and_delivery: 'adam-delivery',
  woodworking: 'adam-wood-raft',
  bottoms_painiting: 'adam-photos',
  blister_repairing: 'adam-service-2',
  king_starboard_structures: 'adam-wood-board',
  full_boat_survey: 'adam-measure',
  buffing_and_waxing: 'adam-settings',
  scuba_diving_equipments: 'adam-swimming-glasses',
  tender_engine: 'adam-ship-engine',
  swimming_ladder: 'adam-swimming-pool',
  life_raft: 'adam-wood-raft',
  secondary_radar: 'adam-radar',
  tender: 'adam-service-2',
  kiteboard: 'adam-wood-board',
  wi_fi: 'adam-wifi2',
  kids_meal: 'adam-food',
  boat_solutions_bulb: require('../../assets/images/info-screens/boat-solution-bulb-icon.svg'),
  comprehensive_platform: require('../../assets/images/info-screens/comprehensive-platform.svg'),
  global_boat_browsing_ship: require('../../assets/images/info-screens/global-boat-browsing-ship.svg'),
  committed_to_trust: require('../../assets/images/info-screens/committed-to-trust.svg'),
  user_verification_standard: require('../../assets/images/info-screens/user-verification-standard.svg'),
  policy_enforcement: require('../../assets/images/info-screens/policy-enforcement.svg'),
  safe_transactions: require('../../assets/images/info-screens/safe-transactions.svg'),
  industry_ethics_advocates: require('../../assets/images/info-screens/industry-ethics-advocates.svg'),
  about_us_blue_bg_right_icon: require('../../assets/images/info-screens/about-us-right-icon-blue-bg.svg'),
  streaming_boat_sales: require('../../assets/images/info-screens/streaming-boatline-sales.svg'),
  networking_and_collaboration: require('../../assets/images/info-screens/networking-and-collabporation.svg'),
  blue_wave_svg: require('../../assets/images/info-screens/blue-wave.svg'),
  enhance_com_and_control: require('../../assets/images/info-screens/enhance-com-sales-control.svg'),
  cruise_to_success: require('../../assets/images/info-screens/cruise-to-success.svg'),
  smooth_boat_selling: require('../../assets/images/info-screens/smooth-boat-selling.svg'),
  accelarate_boat_sale: require('../../assets/images/info-screens/accelarate-boat-sale.svg'),
  sale_boat_hassle_free: require('../../assets/images/info-screens/sale-boat-hasle-free.svg'),
  slim_blue_right_arrow: require('../../assets/images/info-screens/slim-right-arrow.svg'),
  data_protection_commitment: require('../../assets/images/info-screens/data-protection-commitment.svg'),
  data_handling_measures: require('../../assets/images/info-screens/data-handling-measures.svg'),
  top_right_dotted_line: require('../../assets/images/info-screens/top-right-dotted-line.svg'),
  top_left_dotted_line: require('../../assets/images/info-screens/top-left-dotted-line.svg'),
  first_step: require('../../assets/images/info-screens/first-step.svg'),
  second_step: require('../../assets/images/info-screens/second-step.svg'),
  third_step: require('../../assets/images/info-screens/third-step.svg'),
  protecting_your_txn: require('../../assets/images/info-screens/protecting-your-txn.svg'),
  protecting_privacy_policy: require('../../assets/images/info-screens/protecting-privacy-policy.svg'),
  secure_market_place: require('../../assets/images/info-screens/secure-market-place.svg'),
  trust_and_transparency: require('../../assets/images/info-screens/trust-and-transparency.svg'),
  free_seller_listings: require('../../assets/images/info-screens/free-seller-listings.svg'),
  buyer_security_alert: require('../../assets/images/info-screens/buyer-security-alert.svg'),
  horizontal_dotted_line: require('../../assets/images/info-screens/horizontal-dotted-line.svg'),
  trusted_survey_boat_partner: require('../../assets/images/info-screens/trusted-boat-survey-partner.svg'),
  strong_recommendation: require('../../assets/images/info-screens/strong-recommendation.svg'),
  uncovering_hidden_issue: require('../../assets/images/info-screens/uncovering-hidden-issue.svg'),
  buying_peace_mind: require('../../assets/images/info-screens/buying-peace-of-mind.svg'),
  safety_and_satisfaction: require('../../assets/images/info-screens/safety-and-satisfaction.svg'),
  informed_decision_easy: require('../../assets/images/info-screens/informed-desicion-made-easy.svg'),
  seamless_secure_buying: require('../../assets/images/info-screens/seamless-secure-boat-buying.svg'),
  sales_process_my_boat: require('../../assets/images/info-screens/sales-process-my-boat.svg'),
  sales_process_survey_boat: require('../../assets/images/info-screens/sales-process-survey-boat.svg'),
  sales_process_survey_report: require('../../assets/images/info-screens/sales-process-survey-report.svg'),
  sales_process_boat_payment: require('../../assets/images/info-screens/sales-process-boat-payment.svg'),
  sales_process_shipment: require('../../assets/images/info-screens/sales-process-shipment.svg'),
}

export const iconImages = {
  water_marker: require('../../assets/images/boatInner/Water Marker.png'),
  ice_box: require('../../assets/images/boatInner/ICE Box.png'),
  under_water_lights: require('../../assets/images/boatInner/Under Water Lights.png'),
  bimini_top: require('../../assets/images/boatInner/Bamini Top.png'),
  boat_covers: require('../../assets/images/boatInner/Boat Cover.png'),
  cockpit_table: require('../../assets/images/boatInner/Cockpit Table.png'),
  trip_tabs: require('../../assets/images/boatInner/Trip Tab.png'),
  bow_thruster: require('../../assets/images/boatInner/Bow Thruster.png'),
  gangway: require('../../assets/images/boatInner/gangway.png'),
  stern_thruster: require('../../assets/images/boatInner/Stern Thruster.png'),
  davit: require('../../assets/images/boatInner/Davit.png'),
  teak_flooring: require('../../assets/images/boatInner/Teak Flooring.png'),
  echo_sounder: require('../../assets/images/boatInner/Echo Sounder.png'),
  speed_log: require('../../assets/images/boatInner/Speed Log.png'),
  weather_station: require('../../assets/images/boatInner/Weather Station.png'),
  satellite_tv_system: require('../../assets/images/boatInner/Setellite TV.png'),
  radar_primary: require('../../assets/images/boatInner/Radar Primary.png'),
  radar_secondary: require('../../assets/images/boatInner/Radar Secondary.png'),
}

export const marinaIconImages = {
  cover_storage: require('../../assets/images/marinaStorageInner/cover_storage.png'),
  open_air_storage: require('../../assets/images/marinaStorageInner/open_air_storage.png'),
  fuel_berth_and_gas: require('../../assets/images/marinaStorageInner/fuel_birth_and_gas.png'),
  berth_sailing: require('../../assets/images/marinaStorageInner/berth_sailing.png'),
  dry_stack: require('../../assets/images/marinaStorageInner/dry_stack.png'),
  jet_ski_storage: require('../../assets/images/marinaStorageInner/jet_ski_storage.png'),
  'boat_&_trailer_storage': require('../../assets/images/marinaStorageInner/boat_&_trailer_storage.png'),
  battery_charging: require('../../assets/images/marinaStorageInner/battery_charging.png'),
  dry_sailing: require('../../assets/images/marinaStorageInner/dry_sailing.png'),
  dry_storage: require('../../assets/images/marinaStorageInner/dry_storage.png'),
  engine_start: require('../../assets/images/marinaStorageInner/engine_start.png'),
  wet_storage: require('../../assets/images/marinaStorageInner/wet_storage.png'),
  dry_berth: require('../../assets/images/marinaStorageInner/dry_berth.png'),
  wet_berth: require('../../assets/images/marinaStorageInner/wet_berth.png'),
  kayak_storage: require('../../assets/images/marinaStorageInner/kayak_storage.png'),
}

export const serviceInnerIconImages = {
  bottom_painting: require('../../assets/images/serviceInner/Bottom_Painting.png'),
  crew_management: require('../../assets/images/serviceInner/Crew_Management.png'),
  full_boat_survey: require('../../assets/images/serviceInner/Full_Boat_Survey.png'),
  bottoms_paintings: require('../../assets/images/serviceInner/Bottoms_Paintings.png'),
  boat_mover_duplicate: require('../../assets/images/serviceInner/Boat_Mover_Duplicate.png'),
  king_starboard_structures: require('../../assets/images/serviceInner/King_Starboard_Structures.png'),
  fiber_glassing: require('../../assets/images/serviceInner/Fiber_Glassing.png'),
  boat_show_organizing: require('../../assets/images/serviceInner/Boat_Show_Organizing.png'),
  marine_engineer_consultant: require('../../assets/images/serviceInner/Marine_Engineer_Consultant.png'),
  marina_management: require('../../assets/images/serviceInner/Marina_Management.png'),
  maintenance: require('../../assets/images/serviceInner/Maintenance.png'),
  marina_product: require('../../assets/images/serviceInner/Marina_Product.png'),
  marina_training: require('../../assets/images/serviceInner/Marina_Training.png'),
  gel_coat_repairs: require('../../assets/images/serviceInner/Gel_Coat_Repairs.png'),
  mechanical_repairs: require('../../assets/images/serviceInner/Mechanical_Repairs.png'),
  mobile_marina: require('../../assets/images/serviceInner/Mobile_Marina.png'),
  electrical_repairs: require('../../assets/images/serviceInner/Electrical_Repairs.png'),
  moorings: require('../../assets/images/serviceInner/Moorings.png'),
  painting_and_fiberglass: require('../../assets/images/serviceInner/Painting_And_Fiberglass.png'),
  buffing_and_waxing: require('../../assets/images/serviceInner/Buffing_And_Waxing.png'),
  provisioning_and_delivery: require('../../assets/images/serviceInner/Provisioning_And_Delivery.png'),
  administration: require('../../assets/images/serviceInner/Administration.png'),
  scuba_hull_cleaning: require('../../assets/images/serviceInner/Scuba_Hull_Cleaning.png'),
  shrink_wrapping: require('../../assets/images/serviceInner/Shrink_Wrapping.png'),
  yacht_crew: require('../../assets/images/serviceInner/Yacht_Crew.png'),
  yacht_delivery: require('../../assets/images/serviceInner/Yacht_Delivery.png'),
  woodworking: require('../../assets/images/serviceInner/Woodworking.png'),
  bottoms_painting: require('../../assets/images/serviceInner/Bottoms_Painting.png'),
  boat_mover: require('../../assets/images/serviceInner/Boat_Mover.png'),
  boat_lettering: require('../../assets/images/serviceInner/Boat_Lettering.png'),
  barrier_coating: require('../../assets/images/serviceInner/Barrier_Coating.png'),
  blister_repairing: require('../../assets/images/serviceInner/Blister_Repairing.png'),
}

export const rentIconImages = {
  bathrooms_amenities: require('../../assets/images/rentInner/bathrooms_amenities.png'),
  bbq: require('../../assets/images/rentInner/bbq.png'),
  binoculers: require('../../assets/images/rentInner/binoculars.png'),
  breakfast: require('../../assets/images/rentInner/breakfast.png'),
  coffee_maker: require('../../assets/images/rentInner/coffee_maker.png'),
  fishing_equipments: require('../../assets/images/rentInner/fishing_equipments.png'),
  freezer: require('../../assets/images/rentInner/freezer.png'),
  fresh_towels: require('../../assets/images/rentInner/fresh_towels.png'),
  fresh_water_and_soft_drink: require('../../assets/images/rentInner/fresh_water_and_soft_drink.png'),
  inflatable_boat_on_board: require('../../assets/images/rentInner/inflatable_boat.png'),
  juicer: require('../../assets/images/rentInner/juicer.png'),
  kids_meal: require('../../assets/images/rentInner/kids_meal.png'),
  kitchen: require('../../assets/images/rentInner/kitchen.png'),
  meal_from_the_menu: require('../../assets/images/rentInner/meal_from_the_menu.png'),
  microwave_oven: require('../../assets/images/rentInner/microwave_oven.png'),
  mini_bar: require('../../assets/images/rentInner/mini_bar.png'),
  open_buffet_dinner: require('../../assets/images/rentInner/open_buffet_dinner.png'),
  open_buffet_lunch: require('../../assets/images/rentInner/open_buffet_lunch.png'),
  refrigerator: require('../../assets/images/rentInner/refrigerator.png'),
  safety_equipment: require('../../assets/images/rentInner/safety_equipment.png'),
  salon: require('../../assets/images/rentInner/salon.png'),
  soft_drink_and_refreshment: require('../../assets/images/rentInner/soft_drink.png'),
  toilet_paper: require('../../assets/images/rentInner/toilet_paper.png'),
  'tv/cd/mp3': require('../../assets/images/rentInner/tv_cd_mp3.png'),
  underwater_light: require('../../assets/images/rentInner/underwater_light.png'),
  washing_machine: require('../../assets/images/rentInner/washing_machine.png'),
  water_heater: require('../../assets/images/rentInner/water_heater.png'),
  water_slider: require('../../assets/images/rentInner/water_slide.png'),
  'wi-fi': require('../../assets/images/rentInner/wi_fi.png'),
}

export const footerScreenImages = {
  market_parked_boat: require('../../assets/images/info-screens/market-parked-boat.png'),
  bg_integrity_compliance: require('../../assets/images/info-screens/bg-integrity-compliance.png'),
  bg_privacy_policy: require('../../assets/images/info-screens/bg-privacy-policy.png'),
  yellow_curve: require('../../assets/images/info-screens/yellow-curve-shape.png'),
  blue_curve: require('../../assets/images/info-screens/blue-curve-shape.png'),
  bg_be_a_partner: require('../../assets/images/info-screens/bg-be-a-partner.png'),

  be_a_partner__about_cover: require('../../assets/images/info-screens/be-a-partner--about-cover.png'),
  beAPartner_aboutSec_handShake: require('../../assets/images/info-screens/be-a-partner--about-sec--hand-shake.svg'),
  beAPartner_aboutSec_card: require('../../assets/images/info-screens/be-a-partner--about-sec--card.svg'),
  beAPartner_blueBoats: require('../../assets/images/info-screens/be-a-partner--blue-boats.png'),

  beAPartner_way_buyers: require('../../assets/images/info-screens/be-a-partner--way--buyers.svg'),
  beAPartner_way_renters: require('../../assets/images/info-screens/be-a-partner--way--renters.svg'),
  beAPartner_way_rentees: require('../../assets/images/info-screens/be-a-partner--way--rentees.svg'),
  beAPartner_way_marinas: require('../../assets/images/info-screens/be-a-partner--way--marinas.svg'),
  beAPartner_way_builders: require('../../assets/images/info-screens/be-a-partner--way--builders.svg'),
  beAPartner_way_sellers: require('../../assets/images/info-screens/be-a-partner--way--sellers.svg'),
  beAPartner_way_profs: require('../../assets/images/info-screens/be-a-partner--way--profs.svg'),
  about_us_refer_friend: require('../../assets/images/info-screens/about-us-refer-friend.png'),
  bg_broker_info: require('../../assets/images/info-screens/bg-broker-info.png'),
  broker_parked_boat: require('../../assets/images/info-screens/broker-parked-boat.png'),
  yellow_dots_bg: require('../../assets/images/info-screens/yellow-dots-bg.png'),
  white_wave: require('../../assets/images/info-screens/white-wave.png'),
  builder_and_mfg: require('../../assets/images/info-screens/builder-and-mfg.png'),
  boat_owner_parked_boat: require('../../assets/images/info-screens/boat-owner-parked-boat.png'),
  surveyor_why_so_wait: require('../../assets/images/info-screens/surveyor-why-so-wait.png'),
  mfg_parked_boat: require('../../assets/images/info-screens/mg-col-img.png'),
  commitment_priotizing: require('../../assets/images/info-screens/commitment-priotitizing.png'),
  notice_vector: require('../../assets/images/info-screens/notice-vector.png'),
  consent_vector: require('../../assets/images/info-screens/consent-vector.png'),
  access_vector: require('../../assets/images/info-screens/access-vector.png'),
  security_vector: require('../../assets/images/info-screens/security-vector.png'),
  notice_to_parent_vector: require('../../assets/images/info-screens/notice-to-parent-vector.png'),
  electronic_registration_vector: require('../../assets/images/info-screens/electronic-regestration-vector.png'),
  customer_profiles_vector: require('../../assets/images/info-screens/customer-profile-vector.png'),
  community_guidelines_vector: require('../../assets/images/info-screens/community-guideline-vector.png'),
  change_policy_vector: require('../../assets/images/info-screens/change-to-privacy-vector.png'),
  connecting_marine_cmt: require('../../assets/images/info-screens/connecting-marine-cmt.png'),
  social_plateform_cmty: require('../../assets/images/info-screens/social-plateform-community.png'),
  your_essential_rsrc: require('../../assets/images/info-screens/your-essential-resource.png'),
  sales_engine_process: require('../../assets/images/info-screens/image-20240122-063840.png'),
  stream_lined_boat_sales: require('../../assets/images/info-screens/stream-lined-boat-sales.png'),
  int_and_comp_white_rope: require('../../assets/images/info-screens/int-and-compl-white-rope.png'),
  bottom_right_yellow_dot: require('../../assets/images/info-screens/bottom-right-yellow-dot.png'),
  selling_process_rocket: require('../../assets/images/info-screens/selling-process-rocket.png'),
  mfg_sales_engine_process: require('../../assets/images/info-screens/mfg-sales-engine-process.svg'),
  buying_process_my_boat: require('../../assets/images/info-screens/buying-process-my-boat.png'),
  buying_process_survey_boat: require('../../assets/images/info-screens/buying-process-survey-boat.png'),
  buying_process_survey_report: require('../../assets/images/info-screens/buying-process-survey-report.png'),
  buying_process_boat_payment: require('../../assets/images/info-screens/buying-process-boat-payment.png'),
  buying_process_shipment: require('../../assets/images/info-screens/buying-process-shipment.png'),
}

export const lookupTypes = {
  YOU_ARE_AN: 'You are An',
  SWP: 'SWP',
  TRIP_TYPE: 'Trip Type',
  CONTACT_PREFERENCE: 'Preferred Method Of Contact',
  PROFESSION: 'Profession',
  DOCUMENT_TYPES: 'Document Types',
  TRIP: 'Trip',
  SUGGESTION_TOPIC_AREA: 'Suggestion Topic Area',
  COMPLAINT_ABOUT: 'Complaint About',
}

export const resultMessage = {
  search: "We couldn't find anything matching your search. Try searching other keywords.",
  default: "We couldn't find anything.",
}

export const rentBoatTrip = {
  SHARED_TRIP: 'Shared Trip',
  PRIVATE_TRIP: 'Private Trip',
  RENT_PER_HOUR: 'Rent Per Hour',
}

export const totalRecords = {
  limit: 100,
}

export const moduleCategory = {
  RENT: 'rent',
  MARINA_STORAGE: 'marinaStorage',
  BOAT_SERVICE: 'boatService',
  BOAT_SHOW: 'boatShow',
  HOME: 'home',
  BOAT_MEDIA: 'boatMedia',
}

export const advertisementCategory = {
  RENT: 'rent',
  MARINA_STORAGE: 'marina & storage',
  BOAT_SERVICE: 'boat service',
  BOAT_SHOW: 'boatShow',
  HOME: 'home',
}

export const dimension = {
  sharedTrip: {
    width: 500,
    height: 340,
    divide: 3,
    mdDivide: 3,
    smDivide: 2,
    margin: {
      lg: 15,
      md: 12,
      sm: 10,
    },
  },
  privateTrip: {
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 20,
      md: 15,
      sm: 12,
    },
    width: 380,
    height: 280,
  },
  rentUserProfileTrip: {
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 15,
      md: 12,
      sm: 10,
    },
    width: 380,
    height: 280,
  },
  popularTrip: {
    divide: 5,
    mdDivide: 5,
    smDivide: 5,
    margin: {
      lg: 15,
      md: 12,
      sm: 10,
    },
    marginHeight: 0,
    width: 323,
    height: 251,
    respectRatioWidth: 323,
    respectRatioHeight: 251,
  },
  perHourTrip: {
    width: 380,
    height: 340,
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 15,
      md: 12,
      sm: 10,
    },
  },
  carousalRecommended: {
    width: 500,
    height: 340,
    divide: 3,
    mdDivide: 3,
    smDivide: 3,
    margin: {
      lg: 16,
      md: 12,
      sm: 10,
    },
  },
  experienceUs: {
    width: 343,
    height: 359,
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 80,
      md: 60,
      sm: 35,
    },
  },
  recommended: {
    divide: 3,
    margin: {
      lg: 16,
      md: 12,
      sm: 10,
    },
    marginHeight: 0,
    width: 500,
    height: 340,
    respectRatioWidth: 500,
    respectRatioHeight: 340,
  },
  sellAround: {
    width: 295,
    height: 320,
    divide: 5,
    mdDivide: 5,
    smDivide: 5,
    margin: {
      lg: 16,
      md: 12,
      sm: 10,
    },
  },
  showAllSellAround: {
    width: 295,
    height: 320,
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 20,
      md: 15,
      sm: 12,
    },
  },
  marinaStorageVideo: {
    divide: 3,
    margin: 18,
    marginHeight: 0,
    width: 500,
    height: 340,
  },
  rentVideo: {
    divide: 3,
    margin: 30,
    marginHeight: 0,
    width: 500,
    height: 380,
  },

  boatShowVideo: {
    divide: 3,
    margin: 18,
    marginHeight: 0,
    width: 500,
    height: 340,
  },
  boatServiceVideo: {
    divide: 3,
    margin: 30,
    marginHeight: 0,
    width: 500,
    height: 340,
  },
  homePageVideo: {
    divide: 3,
    margin: 30,
    marginHeight: 0,
    width: 500,
    height: 340,
  },
  marinaRegister: {
    divide: 1,
    margin: 0,
    marginHeight: 0,
    width: 1600,
    height: 250,
  },
  marinaRegisterDouble: {
    divide: 2,
    margin: 15,
    marginHeight: 0,
    width: 1600,
    height: 250,
  },
  marinaMostPopular: {
    width: 380,
    height: 280,
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 20,
      md: 15,
      sm: 12,
    },
  },
  userMarinaMostPopular: {
    width: 380,
    height: 280,
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 15,
      md: 12,
      sm: 10,
    },
  },
  topRatedMarina: {
    divide: 5,
    mdDivide: 5,
    smDivide: 5,
    margin: {
      lg: 30,
      md: 20,
      sm: 15,
    },
    width: 303,
    height: 386,
  },
  servicesMarinaStorage: {
    divide: 5,
    margin: 30,
    marginHeight: 0,
    width: 400,
    height: 130,
    respectRatioWidth: 130,
    respectRatioHeight: 90,
  },
  marinaMoreServices: {
    width: 230,
    height: 230,
    divide: 6,
    mdDivide: 6,
    smDivide: 6,
    margin: {
      lg: 30,
      md: 18,
      sm: 12,
    },
  },

  exploreAdamSeaRentServices: {
    divide: 6,
    mdDivide: 6,
    smDivide: 6,
    margin: {
      lg: 35,
      md: 25,
      sm: 15,
    },
    marginHeight: 0,
    width: 202,
    height: 202,
  },
  marinaMoreServicesView: {
    divide: 4,
    margin: 16,
    marginHeight: 0,
    width: 380,
    height: 280,
    respectRatioWidth: 380,
    respectRatioHeight: 280,
  },
  auctionRoom: {
    width: 360,
    height: 290,
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 40,
      md: 30,
      sm: 20,
    },
  },
  mediaArticles: {
    width: 360,
    height: 290,
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 40,
      md: 30,
      sm: 20,
    },
  },
  boatMediaMainArticles: {
    width: 360,
    height: 250,
    divide: 3,
    mdDivide: 3,
    smDivide: 3,
    margin: {
      lg: 40,
      md: 30,
      sm: 20,
    },
  },
  homeRecommended: {
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 20,
      md: 15,
      sm: 12,
    },
    marginHeight: 0,
    width: 380,
    height: 280,
    respectRatioWidth: 380,
    respectRatioHeight: 280,
  },
  homeMostPopular: {
    divide: 5,
    mdDivide: 5,
    smDivide: 5,
    margin: {
      lg: 30,
      md: 18,
      sm: 13,
    },
    marginHeight: 0,
    width: 275,
    height: 350,
    respectRatioWidth: 275,
    respectRatioHeight: 350,
  },
  boatUserProfile: {
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 15,
      md: 12,
      sm: 10,
    },
    width: 380,
    height: 280,
  },
  boatInnerGrid: {
    divide: 5,
    mdDivide: 5,
    smDivide: 5,
    margin: {
      lg: 15,
      md: 12,
      sm: 10,
    },
    width: 380,
    height: 280,
  },
  boatMediaArticle: {
    divide: 3,
    width: 360,
    height: 180,
    respectRatioHeight: 180,
    respectRatioWidth: 360,
    margin: 16,
    marginHeight: 0,
  },
  boatMediaArticle1: {
    divide: 4,
    margin: 30,
    marginHeight: 0,
    width: 380,
    height: 280,
    respectRatioWidth: 380,
    respectRatioHeight: 280,
  },
  boatMediaPost: {
    divide: 10,
    width: 100,
    height: 100,
    respectRatioHeight: 100,
    respectRatioWidth: 100,
    margin: 16,
    marginHeight: 0,
  },
  boatMediaVideo: {
    divide: 3,
    width: 500,
    height: 280,
    respectRatioHeight: 280,
    respectRatioWidth: 500,
    margin: 56,
    marginHeight: 0,
  },
  boatMediaMainDiv: {
    divide: 3,
    width: 500,
    height: 280,
    respectRatioHeight: 280,
    respectRatioWidth: 500,
    margin: 29,
    marginHeight: 0,
  },
  boatMediaCommonVideo: {
    divide: 3,
    width: 500,
    height: 340,
    respectRatioHeight: 340,
    respectRatioWidth: 500,
    margin: 55,
    marginHeight: 0,
  },
  userProfileListing: {
    divide: 4,
    width: 295,
    height: 220,
    respectRatioHeight: 220,
    respectRatioWidth: 295,
    margin: 10,
    marginHeight: 0,
  },
  showAllGrid: {
    divide: 4,
    mdDivide: 4,
    smDivide: 3,
    margin: {
      lg: 20,
      md: 15,
      sm: 12,
    },
    marginHeight: 0,
    width: 380,
    height: 280,
    respectRatioWidth: 380,
    respectRatioHeight: 280,
  },
  boatServiceReview: {
    divide: 4,
    margin: 16,
    marginHeight: 0,
    width: 380,
    height: 280,
    respectRatioWidth: 380,
    respectRatioHeight: 280,
  },
  boatShowItems: {
    divide: 4,
    width: 275,
    height: 310,
    respectRatioHeight: 310,
    respectRatioWidth: 275,
    margin: 16,
    marginHeight: 0,
  },
  boatInnerBoat: {
    width: 295,
    height: 230,
    divide: 5,
    margin: 16,
    marginHeight: 0,
  },
  mediaBoatVideo: {
    divide: 3,
    margin: 18,
    marginHeight: 0,
    width: 300,
    height: 160,
  },
  featureBoat: {
    divide: 5,
    mdDivide: 5,
    smDivide: 5,
    margin: {
      lg: 16,
      md: 12,
      sm: 10,
    },
    marginHeight: 0,
    width: 295,
    height: 230,
    respectRatioWidth: 295,
    respectRatioHeight: 230,
  },
  showMapRatio: {
    divide: 6.1,
    margin: 0,
    marginHeight: 0,
    width: 300,
    height: 235,
    respectRatioWidth: 300,
    respectRatioHeight: 235,
  },
  boatServices: {
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 20,
      md: 15,
      sm: 12,
    },
    marginHeight: 0,
    width: 380,
    height: 280,
    respectRatioWidth: 380,
    respectRatioHeight: 280,
  },
  topRatedBoatServices: {
    divide: 5,
    mdDivide: 5,
    smDivide: 5,
    margin: {
      lg: 40,
      md: 25,
      sm: 15,
    },
    marginHeight: 0,
    width: 275,
    height: 350,
    respectRatioWidth: 275,
    respectRatioHeight: 350,
  },
  boatShowGrid: {
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 35,
      md: 25,
      sm: 15,
    },
    width: 303,
    height: 386,
  },
  userBoatProfile: {
    divide: 4,
    mdDivide: 4,
    smDivide: 4,
    margin: {
      lg: 16,
      md: 12,
      sm: 10,
    },
    marginHeight: 0,
    width: 295,
    height: 230,
    respectRatioWidth: 295,
    respectRatioHeight: 230,
  },
  articleGrid: {
    divide: 2,
    mdDivide: 2,
    smDivide: 2,
    margin: {
      lg: 120,
      md: 100,
      sm: 95,
    },
    marginHeight: 0,
    width: 375,
    height: 230,
    respectRatioWidth: 375,
    respectRatioHeight: 230,
  },
}

export const defaultImage = require('../../assets/images/boatInner/2.jpg')

export const sharedAndPrivateTripTypes = {
  DEEP_SEA_FISHING: 'Deep see finishing',
  SEE_TOUR: 'See tour',
  PRIVATE_PARTY: ' Private party',
  WEDDING_PARTY: 'Wedding party',
  DRIVING_TRIP: ' Diving trip',
  OVER_NIGHT_TRIP: ' Over night trip',
  BIRTHDAY_PARTY: 'Birthday party',
  CRUISE_TRIP: 'Cruise trip',
  EVENT_TRIP: 'Event Trip',
  SUPER_YACHT_CHARTER: 'Super yacht charter',
  DINNER_CRUISE: 'Dinner Cruise',
  DINNING_AND_BAR_CRUISE: 'Dinning and Bar Cruise',
}

export const timePeriod = ['Hours', 'Days']

export const defaultLayout = require('../../assets/images/default/no_image_png_935205.png')

export const defaultBoatHomeImage = require('../../assets/images/default/Coaker’s-Walk.jpg')

export const providerOptions = [
  { id: 'Individual', value: 'Individual' },
  { id: 'Organization', value: 'Organization' },
]

export const providerEnum = {
  INDIVIDUAL: 'Individual',
  ORGANIZATION: 'Organization',
}

export const preferenceOptions = [
  { id: 'Email', value: 'Email' },
  { id: 'Call my tell number', value: 'Call my tell number' },
  { id: 'AdamSea Chat', value: 'AdamSea Chat' },
]

export const languageOptions = () => {
  let data = []

  for (const key in languages) {
    if (languages.hasOwnProperty(key)) {
      const element = languages[key]
      data.push({
        ...element,
        code: key,
        id: element.name,
        value: element.name,
      })
    }
  }
  return data
}

export const infoList = [
  'Buy from anywhere in the world',
  "Buy using AdamSea's Sales Engine platform",
  'Inspect your yacht before you buy it',
  'Negotiate prices online direct with the seller',
  'Access to our worldwide shipping partners',
  "Use our Secure 'Escrow' Account",
  'Assign your agent as a purchase assistant',
  'Add articles on AdamSea',
]

export const mediaCategory = {
  marinaStorage: {
    type: 'marinaStorage',
    fieldName: 'marinaStorageBanner',
    adField: 'marinaStorageBannerAds',
  },
  boatShow: {
    type: 'boatShow',
    fieldName: 'boatShowBanner',
    adField: 'boatShowBannerAds',
    isBanner: true,
  },
  boatService: {
    type: 'boatService',
    fieldName: 'boatServiceBanner',
    adField: 'boatServiceBannerAds',
  },
  boatMedia: {
    type: 'boatMedia',
    fieldName: 'boatMediaBanner',
    adField: 'boatMediaBannerAds',
  },
  home: {
    type: 'home',
    fieldName: 'homeBanner',
    adField: 'homeBannerAds',
  },
  rent: {
    type: 'rent',
    fieldName: 'rentBanner',
    adField: 'rentBannerAds',
  },
  article: {
    type: 'articleMedia',
    fieldName: 'articleBanner',
    adField: 'articleBannerAds',
  },
}

export const carouselArrow = {
  ratio: 24,
}

export const latLng = {}

export const marketTypes = {
  BOAT: 'BOAT',
  NEW_BOAT: 'NEW BOAT',
  USED_BOAT: 'USED BOAT',
  RENT_BOAT: 'RENT BOAT',
  YACHT_SERVICE: 'YACHT SERVICE',
  MARINA_AND_STORAGE: 'MARINA AND STORAGE',
  BOAT_SHOW: 'BOAT SHOW',
}

export const paymentOptions = [{ name: 'Credit Card' }]

export const bankToBankTransfer = 'Bank To Bank Transfer'

export const salesEngineAgreementType = {
  agreementContract: 'agreementContract',
  skipSurveySection: 'skipSurveySection',
  newBoatBuying: 'newBoatBuying',
  usedBoatBuying: 'usedBoatBuying',
  bankPaymentDetail: 'bankPaymentDetail',
  escapeInspection: 'escapeInspection',
  receivedItemNotice: 'receivedItemNotice',
  transactionGeneralRules: 'transactionGeneralRules',
  escrowPaymentDetails: 'escrowPaymentDetails',
  advertiseAgreement: 'advertiseAgreement',
}

export const showAll = {
  recommended: 'recommended',
  mostPopular: 'mostPopular',
  privateTrip: 'privateTrip',
  sharedTrip: 'sharedTrip',
  tripsPerHour: 'tripsPerHour',
}

export const showAllMarina = {
  recommended: 'recommended',
  mostPopular: 'mostPopular',
  topRated: 'topRated',
}

export const footerLinks = {
  Service_Agreement: 'service-agreement-854',
  Dispute_Rules: 'dispute-rules-243',
  Privacy_Policy: 'privacy-policy-518-946',
  Product_Listing_Policy: 'product-listing-policy-304',
  Terms_Of_Use: 'terms-of-use-672',
  Be_A_Partner: 'be-a-partner-700',
  CookiePolicy: 'cookie-policy-727',
  Shipper: 'shipper-477',
  ManuFacturer: 'manufacturer-187',
  Surveyor: 'surveyor-472',
  Survey_your_Boat: 'survey-your-boat-140',
  Our_Market: 'our-market-504',
  Our_Process: 'our-process-885',
  Integrity_and_Compliances: 'integrity-and-compliances-186',
  About_Us: 'about-us-276',
  Boat_Owner: 'boat-owner-956',
  Broker: 'broker-123',
  Boat_Builder_And_Manufacture: 'boat-builder-and-manufacture-563',
  Selling_Process: 'selling-process-417',
  Terms_and_Conditions: 'terms-and-conditions-185',
  rent: {
    reviewAndPay: 'review-and-pay-523',
    passengerInfo: 'passenger-information-253',
    paymentPolicy: 'payment-policy-666',
    cancellationPolicy: 'cancellation-policy-300',
    deposit: 'deposit-133',
    bookingConfirmed: 'booking-confirmed-111',
    bookingWhatToDo: 'what-to-do-820',
    agreement: 'rent-and-charter-agreement-508',
    bookingCancellation: 'booking-cancellation-736',
  },
  helpSection: {
    fraudProtection: 'fraud-protection-910',
    buyingProcess: 'buying-process-695',
    sellingProcess: 'selling-process-533',
  },
}

export const arrows = {
  left: require('../../assets/images/Arrow/leftA.png'),
  right: require('../../assets/images/Arrow/rightA.png'),
  backArrow: require('../../assets/images/career/left-arrow.png'),
}

export const rentIcon = {
  clock: require('../../assets/images/rent/clock.png'),
  user: require('../../assets/images/rent/user-3.png'),
  measure: require('../../assets/images/rent/measure-3.png'),
}

export const bellIcon = require('../../assets/images/header/bell.png')

export const chatIcon = require('../../assets/images/header/chat.svg')
export const chatLIcon = require('../../assets/images/header/chat-l.svg')

export const showAllService = {
  recentlyAdded: 'recentlyAdded',
}

export const profileFields = {
  'boat-owner': 13,
  member: 13,
  surveyor: 18,
  agent: 16,
  'yacht-shipper': 17,
  'rent-and-charter': 14,
  'broker-and-dealer': 16,
  'boat-manufacturer': 16,
  'marina-and-storage': 17,
  'service-and-maintenance': 17,
}

export const TripTypeCategory = {
  shared: 'shared',
  private: 'private',
  rentPerHour: 'rentPerHour',
}
export const imgExtension = [
  '.jpg',
  '.gif',
  '.png',
  '.gif',
  '.webp',
  '.jpeg',
  '.tif',
  '.tiff',
  '.bmp',
  '.JPG',
  '.GIF',
  '.PNG',
  '.GIF',
  '.WEBP',
  '.JPEG',
  '.TIF',
  '.TIFF',
  '.BMP',
  '.jfif',
]

export const docExtension = ['.docx', '.doc', '.pdf', '.txt', '.xlsx']

export const videoExtension = [
  '.WEBM',
  '.webm',
  '.MPG',
  '.mpg',
  '.MP2',
  '.mp2',
  '.MPEG',
  '.mpeg',
  '.MPE',
  '.mpe',
  '.MPV',
  '.mpv',
  '.OGG',
  '.ogg',
  '.MP4',
  '.mp4',
  '.M4P',
  '.m4p',
  '.M4V',
  '.m4v',
  '.AVI',
  '.avi',
  '.WMV',
  '.wmv',
  '.MOV',
  '.mov',
  '.QT',
  '.qt',
  '.FLV',
  '.flv',
  '.SWF',
  '.swf',
]

export const countryValidationMessage = (country = '') =>
  `Please change the country location${country && ` to ${country}`}, where your current new office is located`

export const timeToExpireSessionId = 2 * 60 * 60 * 1000 // (In hours)

export const ratingEnums = {
  organization: { key: 'organization', name: 'Organization' },
  communication: { key: 'communication', name: 'Communication' },
  recommended: { key: 'recommended', name: 'Recommended' },
  goodValue: { key: 'goodValue', name: 'Good value' },
  goodQuality: { key: 'goodQuality', name: 'Good Quality' },
  servicesQuality: { key: 'servicesQuality', name: 'Service Quality' },
  service: { key: 'service', name: 'Service' },
  surveyQuality: { key: 'surveyQuality', name: 'Survey quality' },
  valueForMoney: { key: 'valueForMoney', name: 'Value For Money' },
  safety: { key: 'safety', name: 'Safety' },
  timeRespond: { key: 'timeRespond', name: 'Time Respond' },
  goodSurvey: { key: 'goodSurvey', name: 'Good Survey' },
}

export const defaultProfileIcon = require('../../assets/images/default/Default-Profile.png')

export const likeModuleEnum = {
  BOAT: 'boat',
  BOAT_RENT: 'boatRent',
  MARINA: 'marina',
  YACHT_SERVICE: 'yachtService',
  BOAT_SHOW: 'boatShow',
  ARTICLE: 'article',
}

export const notifyMeEnums = {
  BOAT: 'boat',
  BOAT_RENT: 'boat-rent',
  MARINA: 'marina',
  BOAT_SHOW: 'boat-show',
  YACHT_SERVICE: 'boat-service',
  SURVEYOR: 'branch',
}

export const getRelatedListingType = aliasName => {
  switch (aliasName) {
    case userRoles.MEMBER:
      return notifyMeEnums.BOAT_SHOW

    case userRoles.RENT_AND_CHARTER:
      return notifyMeEnums.BOAT_RENT

    case userRoles.MARINA_AND_STORAGE:
      return notifyMeEnums.MARINA

    case userRoles.SERVICE_AND_MAINTENANCE:
      return notifyMeEnums.YACHT_SERVICE

    case userRoles.SURVEYOR:
      return notifyMeEnums.SURVEYOR

    default:
      return notifyMeEnums.BOAT
  }
}

export const wishlistModuleEnum = {
  BOAT: 'BOAT',
  BOAT_RENT: 'BOATRENT',
  MARINA: 'MARINA',
  YACHT_SERVICE: 'YACHT',
  BOAT_SHOW: 'BOATSHOW',
  BOAT_SERVICE: 'BOAT_SERVICE',
  ARTICLE: 'ARTICLE',
  SURVEYORSALESENGINEQR: 'SURVEYORSALESENGINEQR',
  BUYERSALESENGINEQR: 'BUYERSALESENGINEQR',
  SHIPPERSALESENGINEQR: 'SHIPPERSALESENGINEQR',
  OWNERSHIPCERT: 'OWNERSHIPCERT',
}

export const getModuleType = type => {
  switch (type) {
    case 'Boat':
      return wishlistModuleEnum.BOAT
    case 'BoatRent':
      return wishlistModuleEnum.BOAT_RENT
    case 'Marina':
      return wishlistModuleEnum.MARINA
    case 'Yacht':
      return wishlistModuleEnum.YACHT_SERVICE
    case 'BoatShow':
      return wishlistModuleEnum.BOAT_SHOW

    default:
      break
  }
}

export const dateFormat = 'DD-MM-YYYY'
export const timeFormat = 'hh:mm A'

export const feedbackSubTopics = {
  SITE_CONTENT: 'siteContent',
  TECHNICAL_FAULT: 'technicalFault',
  ONLINE_ACCOUNT: 'onlineAccount',
}

export const stateRequiredError = 'Please fill city field first, will fetch state from city.'

export const shareModule = {
  MARINA: 'MARINA',
  YACHT_SERVICE: 'YACHT',
  BOAT_RENT: 'BOAT_RENT',
  BOAT: 'BOAT',
  BOAT_SHOW: 'BOAT_SHOW',
  USER: 'USER',
}

export const auctionRoomStatus = {
  WIN: 'Win',
  LOSE: 'Lose',
  IN_PROCESS: 'In Process',
  AWARDED: 'Awarded',
  DECLINED: 'Declined',
  APPROVED: 'Approved',
  UNVERIFIED: 'Unverified',
  IN_AUCTION: 'In auction',
  COMING_SOON: 'Coming Soon',
  SOLD_OUT: 'Sold Out',
}

export const seeAllAuctionBidsAccessibleRoles = [
  auctionRoomStatus.IN_AUCTION,
  auctionRoomStatus.AWARDED,
  auctionRoomStatus.SOLD_OUT,
]

export const tripErrors = {
  alreadyOccupied: 'Hour is already occupied.',
  hourDuplication: 'You have already select this hour in another schedule. Please Select another',
  required: {
    maxGuest: 'Maximum Guest is Required',
    startTime: 'Start Time is Required',
    endTime: 'End Time is Required',
    startDate: 'Start Date is Required',
    endDate: 'End Date is Required',
    type: 'Please Select Trip Type',
    unit: 'Unit is Required',
    price: 'Price is Required',
  },
  privateDuplication: 'Your selected dates have conflicts with other. Please Select another',
  errorDisplay: 'There is some error in selection of time. Please check Schedules.',
  selectedDateErrorDisplay: 'You select some data which is already occupied. Please check Schedules',
  unitErrorDisplay: 'You have to add unit data in schedules. Please check Schedules',
}

export const dateFormatForTripDetails = 'DD-MM-YYYY'

export const sharePopupMessage = {
  loginToShareWithOthers: 'Log In to share with other AdamSea Users',
  noUsersFound: 'To build your list, visit the profiles of the users you want to add.',
}

export const activationType = {
  EMAIL: 1,
  MOBILE: 2,
}

export const radioEnum = {
  YES: 'Yes',
  NO: 'No',
}

export const timeFormats = {
  hours: 'Hours',
  days: 'days',
}

export const rentBoatTripForCard = {
  shared: 'Shared Trip',
  private: 'Private Trip',
  rentPerHour: 'Rent Per Hour',
}

export const EditorFormats = ['bold', 'italic', 'underline', 'blockquote', 'list', 'bullet', 'indent', 'link']
export const EditorModules = {
  toolbar: [
    // [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['clean'],
  ],
}

export const AddressInputTypeEnum = {
  OFFICE_ADDRESS: 'OFFICE_ADDRESS',
  COMMON_ADDRESS: 'COMMON_ADDRESS',
  TRIP_ADDRESS: 'TRIP_ADDRESS',
  GATHERING_POINT: 'GATHERING_POINT',
  JET_SKI_ADDRESS: 'JET_SKI_ADDRESS',
}

export const AddressTypeEnum = {
  OFFICE_ADDRESS: 'OFFICE_ADDRESS',
  COMMON_ADDRESS: 'COMMON_ADDRESS',
  TRIP_ADDRESS: 'TRIP_ADDRESS',
  GATHERING_POINT: 'GATHERING_POINT',
  JET_SKI_ADDRESS: 'JET_SKI_ADDRESS',
}

export const selectPaymentOptions = [
  {
    label: 'Credit or Debit Card',
    value: 'Credit Card',
  },
]

export const textCustomerReview = 'Customer Reviews'

export const textSeaTrial = 'Sea trial allowed by the owner'

export const passwordStrengthEnum = {
  POOR: 'Poor',
  WEAK: 'Weak',
  AVERAGE: 'Average',
  STRONG: 'Strong',
}

export const helpTextEnum = {
  priceInput: 'The price should be in USD ($).',
  negotiablePriceInput: 'Add the new total price that you would like to provide to the buyer.',
  taxMessage: 'If there is a government tax (VAT), you must include the value of this tax (VAT) in the total cost of the boat.',
  multiSelect: 'Select at least 2 options from the dropdown.',
  marina: {
    dockLayout: 'Upload your dock layout picture to display it in your Ad page',
    storageLayout: 'Upload your storage layout picture to display it in your Ad page',
    youtubeLinkVideo: 'Let Other user see video about your boat yard or storage',
  },
  surveyor: {
    mobileNumber:
      'Add Your correct mobile No. to be able to receive SMS notifications whenever you receive survey requirement into your branch',
  },
  boat: {
    boatReview: 'The information you add in this section  will display in your New Showroom Item next of the boat picture',
    HIN: 'Needed for ownership check up',
    ownershipAttachment:
      'Please attach any documents that prove your ownership of this vessel. These documents will be visible to the buyer, and in the event of an inspection, our surveyor will verify your ownership.',
    boatOwnershipId: 'Attach any piece of ID for internal use after closing the sale',
  },
  rent: {
    tripType: 'Select trip type from the dropdown',
  },

  userProfile: {
    addressHelpText: `You can update your country location when you move to another country. Simply update the 'City or Town' field with your current location, and the country will be automatically updated.`,
  },
}

export const ShipperCarousel = {
  isBrowser: 3,
  isTablet: 2,
  isMobile: 2,
}

export const noDataFound = 'No data found.'

export const noSurveyorMessage = (country = '', isBranch = false) =>
  country
    ? `No surveyor has registered in ${country}.`
    : isBranch
    ? 'No branches available.'
    : 'No registered surveyor available.'

export const noShipperMessage = 'No registered shipper available.'

export const searchType = {
  BOAT: 'BOAT',
  RENTBOAT: 'RENTBOAT',
  YACHT: 'YACHT',
  MARINA: 'MARINA',
  BOATSHOW: 'BOATSHOW',
  NEWBOAT: 'NEWBOAT',
  USEDBOAT: 'USEDBOAT',
}

export const marinaLayoutTypes = {
  STORAGE: 'Storage',
  DOCK: 'Marina',
  BOTH: 'Marina & Storage',
}
export const responsiveCarousel = {
  manufacture: {
    lg: 5,
    md: 5,
    sm: 2,
  },
  mostPopular: {
    lg: 5,
    md: 5,
    sm: 5,
  },
  mustBuy: {
    lg: 5,
    md: 5,
    sm: 5,
  },
  auctionRoom: {
    lg: 4,
    md: 4,
    sm: 4,
  },
  topRatedServices: {
    lg: 5,
    md: 5,
    sm: 5,
  },
  exploreAdamSeaRentServices: {
    lg: 6,
    md: 6,
    sm: 6,
  },
  // Rent
  rentMostPopular: {
    lg: 4,
    md: 3,
    sm: 2,
  },
  sharedTrip: {
    lg: 4,
    md: 3,
    sm: 2,
  },
  recommendedTrip: {
    lg: 4,
    md: 3,
    sm: 2,
  },
  experienceUs: {
    lg: 4,
    md: 4,
    sm: 4,
  },
  surveySalesEngine: {
    lg: 5,
    md: 5,
    sm: 5,
  },
}

export const boatShowStatus = {
  ON_GOING: 'On Going',
  COMING_SOON: 'Coming soon',
  OVER: 'Closed',
}

export const exploreAdamSea = [
  {
    type: 'home',
    name: 'Buy & Sell',
    url: '/',
  },
  {
    type: 'rent',
    name: 'Charter & Rent',
    url: '/rent',
  },
  {
    type: 'boatService',
    name: 'Service & Maintenance',
    url: '/boat-service',
  },
  {
    type: 'marinaStorage',
    name: 'Marina & Storage',
    url: '/marina-storage',
  },
  {
    type: 'boatShow',
    name: 'Boat Show',
    url: '/boat-show',
  },
  {
    type: 'boatMedia',
    name: 'Boat Media',
    url: '/boat-media',
  },
]
export const marginCarousel = {
  sharedTrip: {
    lg: 40,
    md: 20,
    sm: 15,
  },
  exploreService: {
    lg: 30,
    md: 20,
    sm: 15,
  },
  topRatedBoatService: {
    lg: 40,
    md: 20,
    sm: 15,
  },
}

export const manageBoatAuctionStatus = {
  IN_AUCTION: 'In Auction',
  AUCTION_CLOSED: 'Auction Closed',
  SALES_ENGINE_IS_RUNNING: 'Sales Engine is running',
  NOT_AVAILABLE: 'Not Available',
  IN_STOCK: 'In Stock',
  NO_STOCK: 'No Stock',
  WAITING_FOR_APPROVAL: 'Waiting for Approval',
}

export const timeEnum = {
  DAYS: 'Days',
  HOURS: 'Hours',
  MINUTES: 'Minutes',
  SECONDS: 'Seconds',
}
export const responsiveCarouselIcon = {
  lg: 38,
  md: 32,
  sm: 28,
}

export const noBidHistoryMessage = 'No Bids added yet.'

export const boatTypeUserProfile = {
  BUYER_SELLER: 'boat-owner',
  RENT_AND_CHARTER: 'rent-and-charter',
  SERVICE_AND_MAINTENANCE: 'service-and-maintenance',
  MARINA_AND_STORAGE: 'marina-and-storage',
}

export const highlightedIconForMap = require('../../assets/images/marker/map-hover.svg')

export const MEDIA_NAME = {
  BOATLAYOUT: 'BOATLAYOUT',
  BOATIMAGE: 'BOATIMAGE',
  BOATVIDEO: 'BOATVIDEO',
  USERIMAGE: 'USERIMAGE',
  USERCOMPANYLOGO: 'USERCOMPANYLOGO',
  RENTIMAGE: 'RENTIMAGE',
  RENTBOATLAYOUT: 'RENTBOATLAYOUT',
  YACHTIMAGE: 'YACHTIMAGE',
  YACHTSALESMANPIC: 'YACHTSALESMANPIC',
  MARINAPERSONIMAGE: 'MARINAPERSONIMAGE',
  MARINADOCKLAYOUT: 'MARINADOCKLAYOUT',
  MARINASTORAGELAYOUT: 'MARINASTORAGELAYOUT',
  MARINAIMAGES: 'MARINAIMAGES',
  BOATSHOWIMAGE: 'BOATSHOWIMAGE',
  BOATOWNERSHIPID: 'BOATOWNERSHIPID',
  BOATOWNERSHIPATTACHMENT: 'BOATOWNERSHIPATTACHMENT',
  USERCOMMERCIALLICENCE: 'USERCOMMERCIALLICENCE',
  USERGOVERNMENTID: 'USERGOVERNMENTID',
  SURVEYORREPORT: 'SURVEYORREPORT',
  SURVEYORBOATVERIFICATION: 'SURVEYORBOATVERIFICATION',
  SURVEYOROTHERDOCUMENT: 'SURVEYOROTHERDOCUMENT',
  SURVEYORREPORTIMAGES: 'SURVEYORREPORTIMAGES',
  SURVEYORREPORTVIDEO: 'SURVEYORREPORTVIDEO',
  ARTICLEFILE: 'ARTICLEFILE',
  SHIPPERPROPOSAL: 'SHIPPERPROPOSAL',
  BILLOFLOADING: 'BILLOFLOADING',
  CERTIFICATEOFORIGIN: 'CERTIFICATEOFORIGIN',
  INSURANCECERTIFICATE: 'INSURANCECERTIFICATE',
  RECEIVEDFROMSHIPMENT: 'RECEIVEDFROMSHIPMENT',
  INVOICEOFSHIPMENTPAYMENT: 'INVOICEOFSHIPMENTPAYMENT',
  SHIPMENTDOCUMENTOTHERDOCUMENT: 'SHIPMENTDOCUMENTOTHERDOCUMENT',
  BRANCHIMAGE: 'BRANCHIMAGE',
  JOBCV: 'JOBCV',
  JOBCOVERLATER: 'JOBCOVERLATER',
}

export const MEDIA_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  DOCUMENT: 'DOCUMENT',
}

export const MEDIA_TYPE_INT = {
  IMAGE: 1,
  VIDEO: 2,
  DOCUMENT: 3,
}

export const MEDIA_TYPE_INT_2_STR = {
  1: 'IMAGE',
  2: 'VIDEO',
  3: 'DOCUMENT',
}

export const CHANNEL_TYPE = {
  ALL: 'ALL',
  CONNECTIONS: 'CONNECTIONS',
  UNREAD: 'UNREAD',
  BLOCK: 'BLOCK',
}

export const META_TYPE = ['front', 'back']

export const hullColor = [
  {
    id: 'Black',
    value: 'Black',
  },
]

export const requiredProfileUpdate = 'Before you proceed, ensure your profile is completed.'
export const ftToMeterConversionValue = 0.3048

export const boatsRoutes = ['/manage-boats', '/add-boat', '/manage-auction-rooms', '/manage-auction-bids', '/edit-boat']
export const serviceRoutes = ['/add-boat-service']
export const marinaRoutes = ['/manage-marina-storage', '/add-marina-storage']
export const rentRoutes = [
  '/manage-boat-rents',
  '/manage-trip-details',
  '/add-rent-boat',
  '/edit-rent-boat',
  '/manage-rent-per-hour-trip-details',
  '/manage-rent-payouts',
]
export const dealersRoutes = ['add-dealer', '/manage-dealers', '/dealer-requests']
export const branchRoutes = ['/manage-branches', '/add-branch', '/add-surveyor-report', '/edit-branch', '/my-requests']
export const commonRoutes = [
  '/auction-room-bid-list',
  '/sales-engines',
  '/sales-engine-archives',
  '/manage-payment',
  '/manage-payouts',
  '/add-payment',
  '/my-requests',
]
export const updateFormsRoutes = ['/edit-boat', '/edit-branch', '/edit-rent-boat', '/add-surveyor-report']
export const articleRoutes = ['/create-article', '/manage-articles']
export const withoutDocumentRoutes = ['/create-article', '/manage-articles', '/manage-boat-shows', '/add-boat-show']
export const brokersRoute = ['/my-deals', '/request-manufacturer']

export const boatType = {
  kayak: 'Kayak',
  canoe: 'Canoe',
  jetSki: 'Jet-Ski & Machines',
  canoe: 'Canoe',
  newBuild: 'New Build',
  kayakType: 'Kayak Type',
  brandNew: 'BRAND NEW',
  used: 'USED',
  superYacht: 'Super Yacht',
  yacht: 'Yacht',
}

export const isKayakCanoeType = boat => {
  return boat?.boatType?.name === boatType.kayak || boat?.boatType?.name === boatType.canoe
}

export const isYachtType = (label = '') => {
  return String(label) === boatType.yacht || String(label) === boatType.superYacht
}

export const boatMetaType = 'Kayak'

export const boatVerifyMessage = 'under verification'
export const boatUnderVarification = 'Boat under verification'
export const boatDisabled = 'Boat is disabled by Admin'
export const boatSoldMessage = 'Boat already sold out to other User.'

export const surveyCarousel = {
  isBrowser: 5,
  isTablet: 5,
  isMobile: 5,
}

export const skeletonType = {
  grid: 'grid',
  mapGrid: 'mapGrid',
  innerPage: 'innerPage',
  table: 'table',
  userInfo: 'userInfo',
}

export const tableText = {
  archiveSalesEngine: 'ARCHIVE SALES ENGINE',
  needText: 'Do you need help!',
}

export const manufacturerStatus = {
  accepted: 'Accepted',
  requested: 'Requested',
  request: 'Request Manufacturer',
}

export const noStockAvailable = 'No Stock Available'
export const soldOut = 'Sold out'

export const dashboardSidebarMyStoreSubMenu = {
  myStore: 'My Store',
  myStoreArchive: 'My Store Archive',
  bidList: 'Auction Room Bid List',
  myRequests: 'My Requests',
}

export const marketVerifications = {
  marina: 'Marina under verification',
  boatService: 'Yacht under verification',
  boat: 'Boat under verification',
}

export const paymentMethodAccessibleRoles = [
  // 'boat-owner',
  // 'broker-and-dealer',
  // 'boat-manufacturer',
  // 'rent-and-charter',
  'service-and-maintenance',
  'marina-and-storage',
]

export const flagError = "You don't have access to update data."

export const iconBoldImages = {
  flag: require('../../assets/images/boldIcon2/report_10847636.svg'),
  heart: require('../../assets/images/boldIcon2/love_11209375.svg'),
  heart_black: require('../../assets/images/boldIcon2/love_11209375_black.svg'),
  heart_red: require('../../assets/images/boldIcon2/love_11209375_red.svg'),
  msg: require('../../assets/images/boldIcon2/msg-bold.png'),
  boat: require('../../assets/images/boldIcon2/nav-bold.png'),
  office: require('../../assets/images/boldIcon2/office-bold.png'),
  pdf: require('../../assets/images/boldIcon2/download-file_10531540.svg'),
  share: require('../../assets/images/boldIcon2/send_11971517.svg'),
  share_black: require('../../assets/images/boldIcon2/send_11971517_black.svg'),
  star: require('../../assets/images/boldIcon2/star-bold.png'),
  user: require('../../assets/images/boldIcon2/user-bold.png'),
  userPlus: require('../../assets/images/boldIcon2/user-plus-bold.png'),
  removeUserPlus: require('../../components/userProfile/image/Add_WhiteOnBlue.png'),
}
export const boatAccessibleRoles = [userRoles.BOAT_OWNER, userRoles.BOAT_MANUFACTURER, userRoles.BROKER_AND_DEALER]

export const tabArrow = require('../../assets/images/career/right-arrow.png')

export const careersJobImages = [
  require('../../assets/images/career/g_336.png'),
  require('../../assets/images/career/g_337.png'),
  require('../../assets/images/career/g_338.png'),
  require('../../assets/images/career/g_339.png'),
  require('../../assets/images/career/g_340.png'),
  require('../../assets/images/career/g_336.png'),
  require('../../assets/images/career/g_337.png'),
  require('../../assets/images/career/g_338.png'),
]

export const paymentTypes = {
  boatPayment: 'boatPayment',
  surveyorPayment: 'surveyorPayment',
  shipperPayment: 'shipperPayment',
}

export const stepTypeEnum = {
  previous: 'previous',
  next: 'next',
}

export const agentHelpText =
  'You can select and appoint an AdamSea Agent/Dealer to assist you in using the Sales Engine, guiding you through each step until your transaction is completed. An AdamSea Agent/Dealer will never provide advice on which boat to purchase. Furthermore, they will never request your credit card or debit card information for any transaction, including sending checks or using any payment service.'

export const paymentPolicy =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."

export const salesEngineIcon = {
  description: require('../../assets/images/salesEngine/description.png'),
  directions: require('../../assets/images/salesEngine/directions.png'),
  download: require('../../assets/images/salesEngine/download.svg'),
}

export const salesEngineAuctionWinnerText = {
  seller: 'Auction winner has 5 days to do the payment',
  buyer:
    "You've won the auction! You have a limited time to make the boat payment. In the event that you miss the payment deadline, the boat will be returned to the market, and the auction deposit will not be refundable",
}

export const disableStepperButtonMessage = 'Please complete required fields to move ahead'

export const titleEnums = {
  isCategoryWiseBoat: 'isCategoryWiseBoat',
  isCityWiseBoat: 'isCityWiseBoat',
  isBestDeal: 'isBestDeal',
  isRecentSellBoat: 'isRecentSellBoat',
  isBoatForSale: 'isBoatForSale',
  isLittleBoat: 'isLittleBoat',
  isFeature: 'isFeature',
  isExploreService: 'isExploreService',
}

export const selectBuyer = {
  agent: 'Please Select an agent to help you to buy this boat.',
  surveyor: 'Please Select a Surveyor to help you to Survey this boat.',

  dealer: 'Please Select dealer to help you to buy this boat.',
}

export const userAssigned = 'Already assigned with this user'

export const searchModuleType = {
  BOATS: 'Boats',
  NEW_BOATS: 'New Boats',
  USED_BOATS: 'Used Boats',
  MARINA_AND_STORAGE: 'Marina and Storage',
  BOAT_SHOW: 'Boat Shows',
  YATCH_SERVICES: 'Yatch Services',
  ARTICLES: 'Articles',
}

export const noDataResult = `Sorry, we couldn't find what you were looking for. Try something else?`

export const maximumDigits = {
  five: 99999,
}

export const minQuantityValidation = (availableQuantity, minPurchaseQuantity) => {
  return availableQuantity < minPurchaseQuantity
    ? 'The minimum purchase quantity you want to purchase are not available.'
    : `You must purchase at least ${minPurchaseQuantity} boat quantity.`
}

export const maxQuantityValidation = availableQuantity => {
  return `You can't purchase more than ${availableQuantity} boat quantity.`
}

export const searchTab = {
  All: 'All',
  New: 'New',
  Used: 'Used',
  CharterAndRent: 'Charter & Rent',
  YachtService: 'Services',
  MarinaAndStorage: 'Marina & Storage',
  BoatShow: 'Boat Show',
  Articles: 'Articles',
  Users: 'Users',
  Back: 'Back',
}

export const noResultFound = `Sorry, we couldn't find what you were looking for. Try something else?`

export const flagReportModuleTypeEnum = {
  review: 'REVIEW',
}

export const RentBookingStatus = {
  Pending: 'Pending',
  QRScanned: 'QRScanned',
  Started: 'Started',
  Ongoing: 'Ongoing',
  Settled: 'Settled',
  Expired: 'Expired',
  Cancelled: 'Cancelled',
}

export const RentBookingStatusBadgeClassname = {
  Pending: 'Decline',
  QRScanned: 'in--process',
  Started: 'in--process',
  Ongoing: 'in--process',
  Settled: 'Verified',
  Expired: 'Unverified',
  Cancelled: 'Unverified',
}

export const RentTripStatusLabels = {
  Pending: 'Booked',
  QRScanned: 'QR ✓',
  Started: 'Started',
  Ongoing: 'Started / QR ✓',
  Settled: 'Trip Completed',
  Expired: 'Expired',
  Cancelled: 'Cancelled',
}

export const emptyState = ({ data = null, message = null, loading = false, success = false, failure = false } = {}) => ({
  data,
  message,
  loading,
  success,
  failure,
})

export const PageType = {
  paymentPolicy: 'Payment Policy',
  cancellationPolicy: 'Cancellation Policy',
  deliveryPolicy: 'Delivery Policy',
}

export const STRIPE_FEE_FIXED = 0.3
export const STRIPE_FEE_NATIONAL_PERC = 0.029
export const STRIPE_FEE_INTERNATIONAL_PERC = STRIPE_FEE_NATIONAL_PERC + 0.006

export const DYNAMIC_PAGES_META = {
  'fraud-protection': {
    title: 'Fraud Protection',
    subTitle: 'AdamSea Help Center',
    filter: {
      type: 'help-section',
      slug: footerLinks.helpSection.fraudProtection,
    },
  },
  'buying-process': {
    title: 'Buying Process',
    subTitle: 'AdamSea Help Center',
    filter: {
      type: 'help-section',
      slug: footerLinks.helpSection.buyingProcess,
    },
  },
  'selling-process': {
    title: 'Selling Process',
    subTitle: 'AdamSea Help Center',
    filter: {
      type: 'help-section',
      slug: footerLinks.helpSection.sellingProcess,
    },
  },
  'be-a-partner': {
    title: 'Be A Partner',
    subTitle: 'Partnership',
    filter: {
      slug: footerLinks.Be_A_Partner,
    },
  },
  shipper: {
    title: 'Shipper',
    subTitle: 'Partnership',
    filter: {
      slug: footerLinks.Shipper,
    },
  },
  manufacturer: {
    title: 'Manufacturer',
    subTitle: 'Partnership',
    filter: {
      slug: footerLinks.ManuFacturer,
    },
  },
  surveyor: {
    title: 'Surveyor',
    subTitle: 'Partnership',
    filter: {
      slug: footerLinks.Surveyor,
    },
  },
  'survey-your-boat': {
    title: 'Surveyor Your Boat',
    subTitle: 'Partnership',
    filter: {
      slug: footerLinks.Survey_your_Boat,
    },
  },
  'our-market': {
    title: 'Our Market',
    subTitle: 'AdamSea.com',
    filter: {
      slug: footerLinks.Our_Market,
    },
  },
  policy: {
    title: 'Policy',
    subTitle: 'AdamSea.com',
    filter: {
      slug: footerLinks.Privacy_Policy,
    },
  },
  'rent-and-charter': {
    title: 'Rent and charter',
    subTitle: 'AdamSea.com',
    filter: {
      slug: footerLinks.Our_Process,
    },
  },
  'integrity-and-compliance': {
    title: 'Integrity & Compliance',
    subTitle: 'AdamSea.com',
    filter: {
      slug: footerLinks.Integrity_and_Compliances,
    },
  },
  'about-us': {
    title: 'About Us',
    subTitle: 'AdamSea.com',
    filter: {
      slug: footerLinks.About_Us,
    },
  },
  'boat-owner': {
    title: 'Boat Owner',
    subTitle: 'Sell your Boat',
    filter: {
      slug: footerLinks.Boat_Owner,
    },
  },
  broker: {
    title: 'Broker',
    subTitle: 'Sell your Boat',
    filter: {
      slug: footerLinks.Broker,
    },
  },
  'boat-builder-and-manufacture': {
    title: 'Boat Builder & Manufacture',
    subTitle: 'Sell your Boat',
    filter: {
      slug: footerLinks.Boat_Builder_And_Manufacture,
    },
  },
  'selling-process': {
    title: 'Selling Process',
    subTitle: 'Sell your Boat',
    filter: {
      slug: footerLinks.Selling_Process,
    },
  },
  'adamsea-community': {
    title: 'AdamSea Community',
    subTitle: 'AdamSea.com',
    filter: {
      slug: 'adamsea-community-754',
    },
  },
  'service-agreement': {
    title: 'Service Agreement',
    subTitle: 'AdamSea.com',
    filter: {
      slug: footerLinks.Service_Agreement,
    },
  },
  'dispute-rules': {
    title: 'Dispute Rules',
    subTitle: 'AdamSea.com',
    filter: {
      slug: footerLinks.Dispute_Rules,
    },
  },
  'privacy-policy': {
    title: 'Privacy Policy',
    subTitle: 'AdamSea.com',
    filter: {
      slug: footerLinks.Privacy_Policy,
    },
  },
  'product-listing-policy': {
    title: 'Product Listing Policy',
    subTitle: 'AdamSea.com',
    filter: {
      slug: footerLinks.Product_Listing_Policy,
    },
  },
  'terms-of-use': {
    title: 'Terms of use',
    subTitle: 'AdamSea.com',
    filter: {
      slug: footerLinks.Terms_Of_Use,
    },
  },
  'cookie-policy': {
    title: 'Cookie Policy',
    subTitle: 'AdamSea.com',
    filter: {
      slug: footerLinks.CookiePolicy,
    },
  },
}

export const rolesEnum = {
  member: 'Member',
  'boat-owner': 'Boat Owner',
  'broker-and-dealer': 'Broker and Dealer',
  'boat-manufacturer': 'Boat Manufacturer',
  'service-and-maintenance': 'Service & Maintenance',
  surveyor: 'SURVEYOR',
  'marina-and-storage': 'Marina & Storage',
  'rent-and-charter': 'Rent & Charter',
  'yacht-shipper': 'Yatch Shipper',
  agent: 'AGENT',
  administrator: 'Administrator',
  auditor: 'auditor',
  'account-user': 'Account User',
  'customers-support': 'CUSTOMERS SUPPORT',
  'sales-man': 'Salesman',
  'sub-administrator': 'SUB ADMINISTRATOR',
}

export const SalesEngineStepPaymentType = {
  1: 2,
  2: 2,
  3: 3,
  4: 1,
  5: 3,
}

export const MessageType = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  LOCATION: 'LOCATION',
  LIVE_LOCATION: 'LIVE_LOCATION',
  DOCUMENT: 'DOCUMENT',
}

export const MessageTypeEnum = {
  TEXT: 0,
  IMAGE: 1,
  AUDIO: 2,
  VIDEO: 3,
  LOCATION: 4,
  LIVE_LOCATION: 5,
  DOCUMENT: 6,
}

export const CustomerMessageTextType = {
  1: 'Image',
  2: 'Audio',
  3: 'Video',
  4: 'Location',
  5: 'Live Location',
  6: 'Document',
}
export const ChatAction = {
  Add: 'Add',
  Remove: 'Remove',
  SetAdmin: 'SetAdmin',
}
