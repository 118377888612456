import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { DashboardLayout, TableCard } from '../../components'
import { ButtonComponent } from '../../components/form/Button'
import { viewRentBoatHandler } from '../../helpers/boatHelper'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { clear_getRentPayouts_flag, getRentPayouts } from '../../redux/actions'
import { PDFExport, savePDF } from '@progress/kendo-react-pdf'

const ManageRentPayouts = ({
  getRentPayouts,
  rentPayoutsLoading,
  rentPayoutsSuccess,
  rentPayoutsFailure,
  rentPayoutsData,
  clear_getRentPayouts_flag,
  siteConfigurations
}) => {
  const exportReceiptPdf = useRef(null)
  const pdfDomElem = useRef(null)
  const [pdfData, setPdfdata] = useState({})
  const [tableData, setTableData] = useState([])
  const [printing, setPrinting] = useState(false)

  useEffect(() => {
    getRentPayouts();
  }, [])

  useEffect(() => {
    setTableData(rentPayoutsData)
  }, [rentPayoutsData])

  useEffect(() => {
      printing &&
      savePDF(pdfDomElem.current, {fileName: pdfData?.original?.ticketId?.toUpperCase()}, () => setPrinting(false))
  },[printing])

  useEffect(() => {
    if (rentPayoutsSuccess || rentPayoutsFailure) clear_getRentPayouts_flag()
  }, [rentPayoutsSuccess, rentPayoutsFailure])

  const tableCols = () => [
    {
      Header: 'Date',
      accessor: 'payment.releasedAt',
      Cell: ({ value }) => moment(value).format('ddd, MMM DD'),
      minWidth: 60,
    },
    {
      Header: 'Ad ID',
      accessor: 'boat.adId',
      minWidth: 60,
    },
    {
      Header: 'Trip Type',
      accessor: 'boat.tripType.alias',
      minWidth: 80,
    },
    {
      Header: 'Ticket Id',
      accessor: 'ticketId',
      minWidth: 70,
    },
    {
      Header: 'Payout ID',
      accessor: 'payment.releaseId',
      minWidth: 130,
    },
    {
      Header: 'Amount',
      accessor: 'payment.releasedAmount',
      Cell: ({ value }) => getConvertedPrice(value),
      minWidth: 50,
    },
    {
      minWidth:120,
      Cell: data => {
        return (
          <>
            <ButtonComponent className="btn mr-2" color="view" onClick={() => viewRentBoatHandler(data.original.boat)}>
              View Trip
            </ButtonComponent>
            <ButtonComponent
            onClick={() => {setPdfdata(data);setPrinting(true)}}
            className="btn mr-2"
            color="edit">
              Download Receipt
            </ButtonComponent>
          </>
        )
      },
    },
  ]

  return (
    <DashboardLayout>
            <div
        style={{
          position: "absolute",
          left: "-1000px",
          top: 0,
          zIndex:-1,
          opacity:0
        }}
      >
      {
        pdfData &&
        <PDFExport scale={0.6} ref={exportReceiptPdf}>
        <div ref={pdfDomElem} className='d-flex justify-content-center bg-light p-5 border-rounded'>
          <div style={{width:'609px'}} className='flex flex-column bg-white p-2 justify-content-center'>
            <img width={140} loading='lazy' src={siteConfigurations?.siteLogo?.url} alt="AdamSea" className="site-logo pt-4"/>
            <p className='my-4'>{window.location.pathname.includes('rent') ? 'RENT PAYMENT' : 'BOAT PAYMENT'}</p>
            <p className='mt-4'>AdamSea Inc. has initiated payout of {getConvertedPrice(pdfData?.original?.payment?.releasedAmount)}</p>
            <div className='d-flex mb-2 mt-4'>
              <div className='d-flex flex-column'>
                <p className='fontSize12'>STARTED</p><p className='text-dark'>{moment(pdfData?.original?.payment?.releaseAt).format('ddd, MMM DD YYYY')}</p>
              </div>
              <div className='d-flex flex-column ml-3'>
                <p className='fontSize12'>STRIPE ID</p><p className='text-dark'>{pdfData?.original?.payment?.releaseId}</p>
              </div>

            </div>
            <b className='py-3 fontSize12'>SUMMARY</b>
            <div className='p-2 mt-3 border-rounded' style={{backgroundColor:'#f8f9fa'}}>
              <div className='d-flex justify-content-between'>
                <p>Amount Paid to {pdfData?.original?.boat?.user?.firstName + ' ' + pdfData?.original?.boat?.user?.lastName}</p>
                <p>{getConvertedPrice(pdfData?.original?.payment?.releasedAmount)}</p>
              </div>
              <hr className='mt-0'/>
              <div className='text-right'>
              <p>Total Paid out</p><p className='m-0 text-dark'>{getConvertedPrice(pdfData?.original?.payment?.releasedAmount)}</p>
            </div>
            </div>
            <p>Your are receiving this receipt because you conducted as transaction with AdamSea Inc.</p>
            <p className='mt-2'>	If you have any questions, visit our support site at <a href='https://adamoceans.com/help' style={{textDecoration:'none', color: '#625afa '}}>https://adamoceans.com/help</a></p>
          </div>
        </div>
        </PDFExport>
      }
      </div>

      <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table">
        <TableCard
          title="Manage Payouts"
          action={getRentPayouts}
          noButton
          isSearch
          searchData={v => getRentPayouts({ search: v.input.searchTerm })}
          clearSearchDashboard={getRentPayouts}
          columns={tableCols()}
          loading={rentPayoutsLoading}
          data={tableData}
          total={tableData.length}
        />
      </div>
    </DashboardLayout>
  )
}

const mapStateToProps = state => ({
  siteConfigurations: state.loginReducer.siteConfigurations,
  rentPayoutsLoading: state.boatRentReducer.getRentPayoutsLoading,
  rentPayoutsSuccess: state.boatRentReducer.getRentPayoutsSuccess,
  rentPayoutsFailure: state.boatRentReducer.getRentPayoutsFailure,
  rentPayoutsData: state.boatRentReducer.getRentPayoutsData,
})

const mapDispatchToProps = dispatch => ({
  getRentPayouts: data => dispatch(getRentPayouts(data)),
  clear_getRentPayouts_flag: () => dispatch(clear_getRentPayouts_flag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageRentPayouts)
