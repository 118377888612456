import { put, takeLatest, all, takeEvery } from 'redux-saga/effects'
import { graphqlClient } from '../../helpers/graphqlClient'
import {
  ADD_BOAT_SERVICE,
  ADD_BOAT_SERVICE_SUCCESS,
  ADD_BOAT_SERVICE_FAILURE,
  CLEAR_ADD_BOAT_SERVICE_FLAGS,
  GET_TYPE_WISE_BOAT_SERVICE_SUCCESS,
  GET_TYPE_WISE_BOAT_SERVICE_FAILURE,
  GET_TYPE_WISE_BOAT_SERVICE,
  GET_RECENTLY_ADDEDD_SERVICE,
  GET_RECENTLY_ADDEDD_SERVICE_FAILURE,
  GET_RECENTLY_ADDEDD_SERVICE_SUCCESS,
  GET_USER_BOAT_SERVICE,
  GET_USER_BOAT_SERVICE_FAILURE,
  GET_USER_BOAT_SERVICE_SUCCESS,
  GET_MOST_VIEWED_BOAT_SERVICES,
  GET_MOST_VIEWED_BOAT_SERVICES_SUCCESS,
  GET_MOST_VIEWED_BOAT_SERVICES_FAILURE,
  SEARCH_YACHT_SERVICE,
  SEARCH_YACHT_SERVICE_SUCCESS,
  SEARCH_YACHT_SERVICE_FAILURE,
  CLEAR_SEARCH_YACHT_SERVICE_FLAG,
  GET_ALL_BOAT_SERVICE_TYPES,
  GET_ALL_BOAT_SERVICE_TYPES_SUCCESS,
  GET_ALL_BOAT_SERVICE_TYPES_FAILURE,
  EDIT_YACHT_SERVICE_SUCCESS,
  EDIT_YACHT_SERVICE_FAILURE,
  EDIT_YACHT_SERVICE,
  ERROR_MESSAGE_SHOW,
  NOTIFICATION_MESSAGE,
  GET_SERVICE_DETAIL,
  GET_SERVICE_DETAIL_FAILURE,
  GET_SERVICE_DETAIL_SUCCESS,
  TOGGLE_SERVICE_STATUS,
  TOGGLE_SERVICE_STATUS_SUCCESS,
  TOGGLE_SERVICE_STATUS_FAILURE,
  ERROR_NOTIFICATION_MESSAGE,
  ADD_SERVICE_TAG,
  ADD_SERVICE_TAG_FAILURE,
  ADD_SERVICE_TAG_SUCCESS,
  ADD_SERVICE_TAG__CLEAR,
  GET_SERVICE_TAGS,
  GET_SERVICE_TAGS_FAILURE,
  GET_SERVICE_TAGS_SUCCESS,
  GET_SERVICE_TAGS__CLEAR,
  CHOOSE_FOR_ESTIMATED_COST,
  CHOOSE_FOR_ESTIMATED_COST_SUCCESS,
  CHOOSE_FOR_ESTIMATED_COST_FAILURE,
  CHOOSE_FOR_ESTIMATED_COST__CLEAR,
  GET_SERVICE_PROFILE_FOR_ESTIMATED_COST,
  GET_SERVICE_PROFILE_FOR_ESTIMATED_COST_SUCCESS,
  GET_SERVICE_PROFILE_FOR_ESTIMATED_COST_FAILURE,
  GET_SERVICE_PROFILE_FOR_ESTIMATED_COST__CLEAR,
  IS_CHOSEN_FOR_ESTIMATED_COST_SUCCESS,
  IS_CHOSEN_FOR_ESTIMATED_COST_FAILURE,
  IS_CHOSEN_FOR_ESTIMATED_COST__CLEAR,
  IS_CHOSEN_FOR_ESTIMATED_COST,
  CHOOSE_FOR_YACHT_FIN,
  CHOOSE_FOR_YACHT_FIN_SUCCESS,
  CHOOSE_FOR_YACHT_FIN_FAILURE,
  CHOOSE_FOR_YACHT_FIN__CLEAR,
  IS_CHOSEN_FOR_YACHT_FIN,
  IS_CHOSEN_FOR_YACHT_FIN_SUCCESS,
  IS_CHOSEN_FOR_YACHT_FIN_FAILURE,
  IS_CHOSEN_FOR_YACHT_FIN__CLEAR,
  GET_SERVICE_PROFILE_FOR_YACHT_FINANCING,
  GET_SERVICE_PROFILE_FOR_YACHT_FINANCING_SUCCESS,
  GET_SERVICE_PROFILE_FOR_YACHT_FINANCING_FAILURE,
  GET_SERVICE_PROFILE_FOR_YACHT_FINANCING__CLEAR,
} from '../actionTypes'
import {
  createBoatService,
  typeWiseService,
  getRecentlyAddedService,
  getUserBoatService,
  getMostViewedBoatServices,
  searchYachtService,
  getAllBoatServiceTypes,
  EditYachtService,
  getServiceDetail,
  yachtChangeMarketStatus,
  addServiceTag_query,
  getServiceTags_query,
  chooseForEstimatedCost_query,
  getServiceProfileForEstimatedCost_query,
  getServiceProfileForYachtFinancing_query,
  isChosenForEstimatedCost_query,
  chooseForYachtFinancing_query,
  isChosenForYachtFinancing_query,
} from '../../graphql/boatServiceSchema'
import { notificationEnum, dynamicError } from '../../util/enums/notificationEnum'
import { query, mutation } from './apiHelper'
import { Yacht } from '../../graphql/schemaTypes/schemaTypes'

function addBoatServiceApi(input) {
  return graphqlClient
    .mutate({
      mutation: createBoatService,
      variables: { input },
    })
    .then(res => {
      return res
    })
    .catch(err => {
      return err?.networkError?.result ?? err
    })
}

function* addBoatService(action) {
  try {
    const res = yield addBoatServiceApi(action.payload)
    if (res?.errors?.length) {
      yield put({ type: ADD_BOAT_SERVICE_FAILURE, error: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    } else if (res?.data) {
      yield put({ type: ADD_BOAT_SERVICE_SUCCESS, payload: res.data.createOrUpdateYachtService })
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.BOAT_SERVICE_MODIFY })
    }
  } catch (error) {
    yield put({ type: ADD_BOAT_SERVICE_FAILURE, error })
    yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(error) })
  }
  yield put({ type: CLEAR_ADD_BOAT_SERVICE_FLAGS })
}

function* addBoatServiceSaga() {
  yield takeLatest(ADD_BOAT_SERVICE, addBoatService)
}

//get type wise service

function getTypeWiseServiceApi(input) {
  return graphqlClient
    .query({
      query: typeWiseService,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => err)
}

function* getTypeWiseBoatData(action) {
  try {
    const res = yield getTypeWiseServiceApi(action.payload)
    yield put({ type: GET_TYPE_WISE_BOAT_SERVICE_SUCCESS, payload: res.data.getYachtServiceByServiceType })
  } catch (error) {
    yield put({ type: GET_TYPE_WISE_BOAT_SERVICE_FAILURE, error })
  }
}

function* getTypeWiseService() {
  yield takeLatest(GET_TYPE_WISE_BOAT_SERVICE, getTypeWiseBoatData)
}

//get recently added service

function getRecentlyAddedServiceApi(input) {
  return graphqlClient
    .query({
      query: getRecentlyAddedService,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => {
      throw err
    })
}

function* getRecentlyAddedServiceData(action) {
  try {
    const data = yield getRecentlyAddedServiceApi(action.payload)
    yield put({ type: GET_RECENTLY_ADDEDD_SERVICE_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_RECENTLY_ADDEDD_SERVICE_FAILURE, error })
  }
}

function* getRecentlyAddedServiceSaga() {
  yield takeLatest(GET_RECENTLY_ADDEDD_SERVICE, getRecentlyAddedServiceData)
}

//get user boat service

function getUserBoatServiceApi(input) {
  return graphqlClient
    .query({
      query: getUserBoatService,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => err)
}

function* getUserBoatServiceData(action) {
  try {
    const data = yield getUserBoatServiceApi(action.payload)
    yield put({ type: GET_USER_BOAT_SERVICE_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_USER_BOAT_SERVICE_FAILURE, error })
  }
}

function* getUserBoatServiceSaga() {
  yield takeLatest(GET_USER_BOAT_SERVICE, getUserBoatServiceData)
}

//most viewed boat services

function mostViewedBoatServiceAPi(input) {
  return graphqlClient
    .query({
      query: getMostViewedBoatServices,
      variables: {
        page: input.page,
        limit: input.limit,
        country: input.country,
      },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => {
      throw err
    })
}

function* mostViewedBoatServiceData(action) {
  try {
    const data = yield mostViewedBoatServiceAPi(action.payload)
    yield put({ type: GET_MOST_VIEWED_BOAT_SERVICES_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_MOST_VIEWED_BOAT_SERVICES_FAILURE, error })
  }
}

function* mostViewedBoatServiceSaga() {
  yield takeLatest(GET_MOST_VIEWED_BOAT_SERVICES, mostViewedBoatServiceData)
}

// SEARCH YACHT SERVICE

function searchYachtServicesApi(input) {
  return graphqlClient
    .query({
      query: searchYachtService,
      variables: { input },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => err)
}

function* searchYachtServices(action) {
  try {
    const res = yield searchYachtServicesApi(action.payload)
    yield put({
      type: SEARCH_YACHT_SERVICE_SUCCESS,
      payload: res.data.searchYachtService,
    })
    yield put({ type: CLEAR_SEARCH_YACHT_SERVICE_FLAG })
  } catch (error) {
    yield put({ type: SEARCH_YACHT_SERVICE_FAILURE, error })
  }
}

function* searchYachtServicesSaga() {
  yield takeLatest(SEARCH_YACHT_SERVICE, searchYachtServices)
}

// Get All Boat-Service Types

function getAllBoatServiceApi(data) {
  return graphqlClient
    .query({
      query: getAllBoatServiceTypes,
      variables: { isAdmin: data.isAdmin },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => err)
}

function* getAllBoatService(action) {
  try {
    const res = yield getAllBoatServiceApi(action.payload)
    yield put({
      type: GET_ALL_BOAT_SERVICE_TYPES_SUCCESS,
      payload: res.data.getAllBoatServiceTypes,
      isAdmin: action.payload.isAdmin,
    })
  } catch (error) {
    yield put({ type: GET_ALL_BOAT_SERVICE_TYPES_FAILURE, error })
  }
}

function* getAllBoatServiceSaga() {
  yield takeEvery(GET_ALL_BOAT_SERVICE_TYPES, getAllBoatService)
}

export function editYachtServiceApi(data, basic = false) {
  return graphqlClient
    .query({
      query: EditYachtService,
      variables: { id: data, basic },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}

function* editYachtServiceData(action) {
  try {
    const data = yield editYachtServiceApi(action.payload)

    if (data.errors && data.errors.length) {
      yield put({ type: ERROR_MESSAGE_SHOW, payload: data.errors })
      yield put({ type: EDIT_YACHT_SERVICE_FAILURE, e: data.errors })
    } else {
      yield put({ type: EDIT_YACHT_SERVICE_SUCCESS, payload: data.data.EditYachtService })
    }
  } catch (e) {
    yield put({ type: EDIT_YACHT_SERVICE_FAILURE, e })
  }
  yield put({ type: CLEAR_SEARCH_YACHT_SERVICE_FLAG })
}

function* editYachtServiceSaga() {
  yield takeLatest(EDIT_YACHT_SERVICE, editYachtServiceData)
}

function* getServiceDetailApi() {
  try {
    const res = yield query(getServiceDetail)

    if (res.errors && res.errors.length) {
      yield put({ type: GET_SERVICE_DETAIL_FAILURE, error: res.errors })
    } else {
      yield put({ type: GET_SERVICE_DETAIL_SUCCESS, payload: res.data?.getServiceDetail })
    }
  } catch (error) {
    yield put({ type: GET_SERVICE_DETAIL_FAILURE, error })
  }
}

function* toggleServiceApi(action) {
  try {
    const res = yield mutation(yachtChangeMarketStatus, { input: action.payload })

    if (res.errors && res.errors.length) {
      yield put({ type: TOGGLE_SERVICE_STATUS_FAILURE, error: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    } else {
      yield put({ type: TOGGLE_SERVICE_STATUS_SUCCESS, payload: res.data?.yachtChangeMarketStatus })
    }
  } catch (error) {
    yield put({ type: TOGGLE_SERVICE_STATUS_FAILURE, error })
  }
}

function* getServiceDetailSaga() {
  yield takeLatest(GET_SERVICE_DETAIL, getServiceDetailApi)
}

function* toggleServiceSaga() {
  yield takeLatest(TOGGLE_SERVICE_STATUS, toggleServiceApi)
}

function* addServiceTag_saga() {
  yield takeLatest(ADD_SERVICE_TAG, addServiceTag_api)
}

function* addServiceTag_api(action) {
  try {
    const res = yield mutation(addServiceTag_query, action.payload);

    if (res.data.addServiceTag) {
      yield put({ type: ADD_SERVICE_TAG_SUCCESS })
      yield put({ type: EDIT_YACHT_SERVICE_SUCCESS, payload: res.data.addServiceTag })
    }
  } catch {
    yield put({ type: ADD_SERVICE_TAG_FAILURE })
  }

  yield put({ type: ADD_SERVICE_TAG__CLEAR })
}

function* getServiceTags_saga() {
  yield takeLatest(GET_SERVICE_TAGS, getServiceTags_api)
}

function* getServiceTags_api(action) {
  try {
    const res = yield mutation(getServiceTags_query, action.payload);

    if (res.data.getServiceTags) {
      yield put({ type: GET_SERVICE_TAGS_SUCCESS, payload: res.data.getServiceTags })
    }
  } catch {
    yield put({ type: GET_SERVICE_TAGS_FAILURE })
  }

  yield put({ type: GET_SERVICE_TAGS__CLEAR })
}

function* chooseForEstimatedCost_saga() {
  yield takeLatest(CHOOSE_FOR_ESTIMATED_COST, chooseForEstimatedCost_api)
}

function* chooseForEstimatedCost_api(action) {
  try {
    const res = yield mutation(chooseForEstimatedCost_query, action.payload);

    if (res.data.chooseForEstimatedCost) {
      yield put({ type: CHOOSE_FOR_ESTIMATED_COST_SUCCESS, payload: res.data.chooseForEstimatedCost })
    }
  } catch {
    yield put({ type: CHOOSE_FOR_ESTIMATED_COST_FAILURE })
  }

  yield put({ type: CHOOSE_FOR_ESTIMATED_COST__CLEAR })
}

function* isChosenForEstimatedCost_saga() {
  yield takeLatest(IS_CHOSEN_FOR_ESTIMATED_COST, isChosenForEstimatedCost_api)
}

function* isChosenForEstimatedCost_api(action) {
  try {
    const res = yield mutation(isChosenForEstimatedCost_query, action.payload);

    if (res.data.isChosenForEstimatedCost) {
      yield put({ type: IS_CHOSEN_FOR_ESTIMATED_COST_SUCCESS, payload: res.data.isChosenForEstimatedCost })
    }
  } catch {
    yield put({ type: IS_CHOSEN_FOR_ESTIMATED_COST_FAILURE })
  }

  yield put({ type: IS_CHOSEN_FOR_ESTIMATED_COST__CLEAR })
}

function* getServiceProfileForEstimatedCost_saga() {
  yield takeLatest(GET_SERVICE_PROFILE_FOR_ESTIMATED_COST, getServiceProfileForEstimatedCost_api)
}

function* getServiceProfileForEstimatedCost_api(action) {
  try {
    const res = yield mutation(getServiceProfileForEstimatedCost_query, action.payload);

    if (res.data.getServiceProfileForEstimatedCost) {
      yield put({ type: GET_SERVICE_PROFILE_FOR_ESTIMATED_COST_SUCCESS, payload: res.data.getServiceProfileForEstimatedCost })
    }
  } catch {
    yield put({ type: GET_SERVICE_PROFILE_FOR_ESTIMATED_COST_FAILURE })
  }

  yield put({ type: GET_SERVICE_PROFILE_FOR_ESTIMATED_COST__CLEAR })
}

function* chooseForYachtFinancing_saga() {
  yield takeLatest(CHOOSE_FOR_YACHT_FIN, chooseForYachtFinancing_api)
}

function* chooseForYachtFinancing_api(action) {
  try {
    const res = yield mutation(chooseForYachtFinancing_query, action.payload);

    if (res.data.chooseForYachtFinancing) {
      yield put({ type: CHOOSE_FOR_YACHT_FIN_SUCCESS, payload: res.data.chooseForYachtFinancing })
    }
  } catch {
    yield put({ type: CHOOSE_FOR_YACHT_FIN_FAILURE })
  }

  yield put({ type: CHOOSE_FOR_YACHT_FIN__CLEAR })
}

function* isChosenForYachtFinancing_saga() {
  yield takeLatest(IS_CHOSEN_FOR_YACHT_FIN, isChosenForYachtFinancing_api)
}

function* isChosenForYachtFinancing_api(action) {
  try {
    const res = yield mutation(isChosenForYachtFinancing_query, action.payload);

    if (res.data.isChosenForYachtFinancing) {
      yield put({ type: IS_CHOSEN_FOR_YACHT_FIN_SUCCESS, payload: res.data.isChosenForYachtFinancing })
    }
  } catch {
    yield put({ type: IS_CHOSEN_FOR_YACHT_FIN_FAILURE })
  }

  yield put({ type: IS_CHOSEN_FOR_YACHT_FIN__CLEAR })
}

function* getServiceProfileForYachtFinancing_saga() {
  yield takeLatest(GET_SERVICE_PROFILE_FOR_YACHT_FINANCING, getServiceProfileForYachtFinancing_api)
}

function* getServiceProfileForYachtFinancing_api(action) {
  try {
    const res = yield mutation(getServiceProfileForYachtFinancing_query, action.payload);

    if (res.data.getServiceProfileForYachtFinancing) {
      yield put({ type: GET_SERVICE_PROFILE_FOR_YACHT_FINANCING_SUCCESS, payload: res.data.getServiceProfileForYachtFinancing })
    }
  } catch {
    yield put({ type: GET_SERVICE_PROFILE_FOR_YACHT_FINANCING_FAILURE })
  }

  yield put({ type: GET_SERVICE_PROFILE_FOR_YACHT_FINANCING__CLEAR })
}

export default function* boatServiceSaga() {
  yield all([
    addBoatServiceSaga(),
    getTypeWiseService(),
    getRecentlyAddedServiceSaga(),
    getUserBoatServiceSaga(),
    mostViewedBoatServiceSaga(),
    searchYachtServicesSaga(),
    getAllBoatServiceSaga(),
    editYachtServiceSaga(),
    getServiceDetailSaga(),
    toggleServiceSaga(),
    addServiceTag_saga(),
    getServiceTags_saga(),
    chooseForEstimatedCost_saga(),
    isChosenForEstimatedCost_saga(),
    getServiceProfileForEstimatedCost_saga(),
    chooseForYachtFinancing_saga(),
    isChosenForYachtFinancing_saga(),
    getServiceProfileForYachtFinancing_saga(),
  ])
}
