import { graphqlClient, graphqlClientChat } from '../../helpers/graphqlClient'

export async function query(apiName, input = {}, cachePolicy = 'no-cache') {
  try {
    const res = await graphqlClient.query({
      query: apiName,
      variables: input,
      fetchPolicy: cachePolicy,
    })
    return res
  } catch (error) {
    return error?.networkError?.result ?? error
  }
}

export async function mutation(apiName, input = {}) {
  try {
    const res = await graphqlClient.mutate({
      mutation: apiName,
      variables: input,
    })
    return res
  } catch (error) {
    return error?.networkError?.result ?? error
  }
}
export async function mutationWithClient(apiName, input = {}) {
  try {
    const res = await graphqlClientChat.mutate({
      mutation: apiName,
      variables: input,
    })
    return res
  } catch (error) {
    return error?.networkError?.result ?? error
  }
}
