import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import './jobCard.scss'
// import { NextIconCarrer } from '../../styleComponent/styleComponent'
// import { careersJobImages } from '../../../util/enums/enums'
import { cityCountryNameFormatter } from '../../../helpers/jsxHelper'
// import UserContext from '../../../UserContext'
import { Link } from 'react-router-dom'

export const JobCard = React.memo(
  ({
    job: {
      id,
      title,
      experience,
      city,
      country,
    },
    index,
  }) => {
    // const { history } = useContext(UserContext)

    return (
      <Grid item xs={12} sm={6} lg={4} xl={3} spacing={1} className="py-2">
        <div className="job-card-grid h-100">
          <Link to={`/career/${id}`}>
            <div className="job-grid-title-value">{title}</div>
            <div className="job-grid-label-value">
              <div className="job-grid-label">Experience Required</div>
              <div className="job-grid-value">{experience}</div>
            </div>
            <div className="job-grid-label-value job-grid-location">
              <div className="job-grid-label">Job Location</div>
              <div className="job-grid-value">
                {country === null ? 'Any' : cityCountryNameFormatter(city, country?.name)}
              </div>
            </div>
          </Link>
        </div>

        {/* <NextIconCarrer className="job-next-icon" img={careersJobImages[index]} onClick={() => history.push(``)} /> */}
      </Grid>
    )
  }
)

JobCard.defaultProps = {}
