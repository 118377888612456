import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { IoIosClose, IoIosPin, IoIosSearch } from 'react-icons/io'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { snakeCase } from 'lodash'
import cn from 'classnames'
import { icons, rentBoatTripForCard } from '../../util/enums/enums'
import { getId } from '../../util/utilFunctions'
import PlacesAutocomplete from '../../components/places-autocomplete'
import styles from './hero-search.module.scss'

const FIELD = {
  TRIP: 1,
  TRIP_UNIT_COUNT: 2,
  LOCATION: 3,
  WHAT_TO_DO: 4,
  START_DATE: 5,
  END_DATE: 6,
}

const TRIP_UNIT_TITLE_LABEL = {
  [rentBoatTripForCard.private]: 'Boat Capacity',
  [rentBoatTripForCard.shared]: 'Guests',
  [rentBoatTripForCard.rentPerHour]: 'Watercrafts',
}

const TRIP_UNIT_PLACEHOLDER_LABEL = {
  [rentBoatTripForCard.private]: 'Add Guests',
  [rentBoatTripForCard.shared]: 'Add Passengers',
  [rentBoatTripForCard.rentPerHour]: 'Add Watercrafts',
}

const TRIP_UNIT_VALUE_LABEL = {
  [rentBoatTripForCard.private]: v => `${v} Guest${v > 1 ? 's' : ''}`,
  [rentBoatTripForCard.shared]: v => `${v} Guest${v > 1 ? 's' : ''}`,
  [rentBoatTripForCard.rentPerHour]: v => `${v} Watercraft${v > 1 ? 's' : ''}`,
}

const TRIP_UNIT_DD_LABEL = {
  [rentBoatTripForCard.private]: 'Group Size',
  [rentBoatTripForCard.shared]: 'Add Guests',
  [rentBoatTripForCard.rentPerHour]: 'Add Watercrafts',
}

function HeroSearch({ inHeader = false, close }) {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const trips = useSelector(state => state.boatRentReducer?.boatRentLookUps?.trip)
  const tripTypes = useSelector(state => state.rentReducer?.tripTypeLookups)

  const [activeField, setActiveField] = useState(null)
  const [locationSuggestions, setLocationSuggestions] = useState([])
  const [searchInputValues, setSearchInputValues] = useState({})
  const [fieldValues, setFieldValues] = useState({})

  const setSearchInputValue = useCallback((name, value) => {
    setSearchInputValues(prev => ({ ...prev, [name]: typeof value === 'function' ? value(prev[name]) : value }))
  }, [])

  const setFieldValue = useCallback((name, value) => {
    setFieldValues(prev => ({ ...prev, [name]: typeof value === 'function' ? value(prev[name]) : value }))
  }, [])

  const onPlaceSelected = useCallback(place => {
    setSearchInputValue(FIELD.LOCATION, place.description)
    setFieldValue(FIELD.LOCATION, place)
    setActiveField(FIELD.WHAT_TO_DO)
  }, [])

  const handleSearch = useCallback(() => {
    const tripMap = {
      [rentBoatTripForCard.private]: 'private',
      [rentBoatTripForCard.shared]: 'shared',
      [rentBoatTripForCard.rentPerHour]: 'rentPerHour',
    }

    const params = new URLSearchParams({
      country: fieldValues[FIELD.LOCATION]?.country || '',
      city: fieldValues[FIELD.LOCATION]?.city || '',
      address: fieldValues[FIELD.LOCATION]?.completeLabel || '',
      type: tripMap[fieldValues[FIELD.TRIP]?.lookUp.alias] || '',
      tripType: fieldValues[FIELD.WHAT_TO_DO]?.id || '',
      total: fieldValues[FIELD.TRIP_UNIT_COUNT] || '',
      startDate: '',
      endDate: '',
    })

    if (pathname === '/search-rent-boats') {
      history.push(`/search-rent-boats?${params.toString()}`)
    } else {
      window.open(`/search-rent-boats?${params.toString()}`)
    }
  }, [history, pathname, fieldValues])

  useEffect(() => {
    if (trips?.length && tripTypes?.length) {
      if (pathname === '/search-rent-boats') {
        const searchParams = new URLSearchParams(search)

        const country = searchParams.get('country')
        const city = searchParams.get('city')
        const address = searchParams.get('address')
        const tripAlias = searchParams.get('type')
        const tripTypeId = searchParams.get('tripType')
        const total = +searchParams.get('total')

        if (total) {
          setFieldValue(FIELD.TRIP_UNIT_COUNT, total)
        }

        if (country || city) {
          setSearchInputValue(FIELD.LOCATION, address || [city, country].filter(Boolean).join(', '))
          setFieldValue(FIELD.LOCATION, { country, city })
        }

        if (tripAlias) {
          const trip = trips.find(t => t.lookUp.alias === rentBoatTripForCard[tripAlias])
          setFieldValue(FIELD.TRIP, trip)
        } else {
          const trip = trips.find(t => t.lookUp.alias === rentBoatTripForCard.shared)
          setFieldValue(FIELD.TRIP, trip)
        }

        if (tripTypeId) {
          const tripType = tripTypes.find(t => getId(t) === tripTypeId)
          setFieldValue(FIELD.WHAT_TO_DO, tripType)
        }
      } else {
        const trip = trips.find(t => t.lookUp.alias === rentBoatTripForCard.shared)
        setFieldValue(FIELD.TRIP, trip)
      }
    }
  }, [pathname, search, trips, tripTypes])

  const tripValue = fieldValues[FIELD.TRIP]

  useEffect(() => {
    setFieldValue(FIELD.TRIP_UNIT_COUNT, 0)
  }, [tripValue])

  const tripTypesMod =
    tripTypes?.length > 0
      ? tripTypes.filter(item => item.tripId.map(item => item.alias).includes(fieldValues[FIELD.TRIP]?.lookUp.alias))
      : []

  return (
    <div className={cn(styles.heroSearch, inHeader && styles.heroSearch_inHeader)}>
      <div className={cn(styles.fieldsWrp, styles.fieldTripsWrp, styles.fieldsWrpActive)}>
        {trips?.length > 0 &&
          trips.map(trip => (
            <div className={cn(styles.fieldWrp, fieldValues[FIELD.TRIP]?.lookUp.id === trip.lookUp.id && styles.fieldActive)}>
              <div className={styles.fieldHead} onClick={() => setFieldValue(FIELD.TRIP, trip)}>
                <div className={styles.fieldHeadContent}>
                  <div className={styles.fieldHeadIcon}>
                    <img src={icons[snakeCase(trip.lookUp.alias)]} alt={trip.lookUp.alias} height={24} />
                  </div>
                  <div className={styles.fieldHeadLabel}>{trip.lookUp.alias}</div>
                </div>
              </div>
            </div>
          ))}
      </div>

      <OutsideClickHandler onOutsideClick={() => setActiveField(null)}>
        <div className={cn(styles.fieldsWrp, activeField && styles.fieldsWrpActive)}>
          <div className={cn(styles.fieldWrp, activeField === FIELD.TRIP_UNIT_COUNT && styles.fieldActive)}>
            <div className={styles.fieldHead} onClick={() => setActiveField(FIELD.TRIP_UNIT_COUNT)}>
              <div className={styles.fieldHeadContent}>
                <div className={styles.fieldHeadLabel}>
                  <div>{TRIP_UNIT_TITLE_LABEL[fieldValues[FIELD.TRIP]?.lookUp.alias]}</div>

                  <div className={styles.fieldResetBtnWrp}>
                    <button
                      className={styles.fieldResetBtn}
                      disabled={activeField !== FIELD.TRIP_UNIT_COUNT || !fieldValues[FIELD.TRIP_UNIT_COUNT]}
                      onClick={() => setFieldValue(FIELD.TRIP_UNIT_COUNT, null)}
                    >
                      <IoIosClose />
                    </button>
                  </div>
                </div>
                <div className={styles.fieldHeadSubtextWrp}>
                  {!fieldValues[FIELD.TRIP_UNIT_COUNT] ? (
                    <div className={styles.fieldHeadSubtextPlaceholder}>
                      {TRIP_UNIT_PLACEHOLDER_LABEL[fieldValues[FIELD.TRIP]?.lookUp.alias]}
                    </div>
                  ) : (
                    <div className={styles.fieldHeadSubtextValue}>
                      {TRIP_UNIT_VALUE_LABEL[fieldValues[FIELD.TRIP]?.lookUp.alias]?.(fieldValues[FIELD.TRIP_UNIT_COUNT])}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {activeField === FIELD.TRIP_UNIT_COUNT && (
              <div className={styles.fieldDdWrp}>
                <div className={styles.fieldDdContent}>
                  <div className={styles.fieldDdTitle}>{TRIP_UNIT_DD_LABEL[fieldValues[FIELD.TRIP]?.lookUp.alias]}</div>

                  <div className={styles.fieldIncDecWrp}>
                    <button
                      className={styles.fieldIncDecBtn}
                      onClick={() => setFieldValue(FIELD.TRIP_UNIT_COUNT, v => (v > 0 ? v - 1 : 0))}
                    >
                      <FaMinus />
                    </button>
                    <span className={styles.fieldIncDecVal}>{fieldValues[FIELD.TRIP_UNIT_COUNT] || 0}</span>
                    <button
                      className={styles.fieldIncDecBtn}
                      onClick={() => setFieldValue(FIELD.TRIP_UNIT_COUNT, v => (v || 0) + 1)}
                    >
                      <FaPlus />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={cn(styles.fieldWrp, activeField === FIELD.LOCATION && styles.fieldActive)}>
            <div
              className={styles.fieldHead}
              onClick={() => {
                setActiveField(FIELD.LOCATION)
                document.querySelector(`.${styles.fieldHeadInp}_googleAutocomplete`).focus()
              }}
            >
              <div className={styles.fieldHeadContent}>
                <div className={styles.fieldHeadLabel}>
                  <div>Where</div>
                  <div className={styles.fieldResetBtnWrp}>
                    <button
                      className={styles.fieldResetBtn}
                      disabled={activeField !== FIELD.LOCATION || !fieldValues[FIELD.LOCATION]}
                      onClick={() => {
                        setSearchInputValue(FIELD.LOCATION, null)
                        setFieldValue(FIELD.LOCATION, null)
                      }}
                    >
                      <IoIosClose />
                    </button>
                  </div>
                </div>
                <div className={styles.fieldHeadSubtextWrp}>
                  <PlacesAutocomplete
                    query={searchInputValues[FIELD.LOCATION] || ''}
                    setQuery={query => setSearchInputValue(FIELD.LOCATION, query)}
                    setSuggestions={setLocationSuggestions}
                    placeholder="Boat Location"
                    className={cn(styles.fieldHeadInp, `${styles.fieldHeadInp}_googleAutocomplete`)}
                  />
                </div>
              </div>
            </div>

            {activeField === FIELD.LOCATION && locationSuggestions.length > 0 && (
              <div className={styles.fieldDdWrp}>
                <div className={styles.fieldDdContent}>
                  <ul className={styles.fieldLocList}>
                    {locationSuggestions.map(sugg => (
                      <li key={sugg.place_id} className={styles.fieldLocListItem} onClick={() => onPlaceSelected(sugg)}>
                        <span className={styles.fieldLocIcon}>
                          <IoIosPin />
                        </span>{' '}
                        <span className={styles.fieldLocText}>{sugg.description}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className={cn(styles.fieldWrp, activeField === FIELD.WHAT_TO_DO && styles.fieldActive)}>
            <div className={styles.fieldHead} onClick={() => setActiveField(FIELD.WHAT_TO_DO)}>
              <div className={styles.fieldHeadContent}>
                <div className={styles.fieldHeadLabel}>
                  <div>What to do?</div>
                  <div className={styles.fieldResetBtnWrp}>
                    <button
                      className={styles.fieldResetBtn}
                      disabled={activeField !== FIELD.WHAT_TO_DO || !fieldValues[FIELD.WHAT_TO_DO]}
                      onClick={() => setFieldValue(FIELD.WHAT_TO_DO, null)}
                    >
                      <IoIosClose />
                    </button>
                  </div>
                </div>
                <div className={styles.fieldHeadSubtextWrp}>
                  {!fieldValues[FIELD.WHAT_TO_DO] ? (
                    <div className={styles.fieldHeadSubtextPlaceholder}>Select Type</div>
                  ) : (
                    <div className={styles.fieldHeadSubtextValue}>{fieldValues[FIELD.WHAT_TO_DO].alias}</div>
                  )}
                </div>
              </div>

              <div className={styles.fieldSubmitBtnWrp}>
                <button
                  className={styles.fieldSubmitBtn}
                  onClick={e => {
                    e.stopPropagation()
                    setActiveField(null)
                    handleSearch()
                    close?.()
                  }}
                >
                  <IoIosSearch />
                </button>
              </div>
            </div>

            {activeField === FIELD.WHAT_TO_DO && (
              <div className={cn(styles.fieldDdWrp, styles.fieldTripTypeDdWrp)}>
                <div className={styles.fieldTripTypeList}>
                  {tripTypesMod.map(t => (
                    <div
                      className={cn(
                        styles.fieldTripTypeListItem,
                        fieldValues[FIELD.WHAT_TO_DO]?.id === t.id && styles.fieldTripTypeListItemActive
                      )}
                    >
                      <button
                        className={styles.fieldTripTypeListItemBtn}
                        onClick={() => {
                          setFieldValue(FIELD.WHAT_TO_DO, t)
                          setActiveField(null)
                        }}
                      >
                        {t.alias}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default HeroSearch
