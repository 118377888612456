import React, { Component } from 'react'
import { connect } from 'react-redux'

import { confirmSubmitHandler, popUpMessage } from '../../helpers/confirmationPopup'
import { getUserBoatShows, clearBoatShowFlag, deleteBoatShow, toggleBoatShowStatus } from '../../redux/actions/boatShowAction'
import moment from 'moment'
import { DashboardLayout } from '../../components/layout/dashboardLayout'
import { pagination } from '../../util/enums/enums'
import '../../styles/manageDashboardTableResponsive.scss'
import { SuccessNotify } from '../../helpers/notification'
import { TableCard, Field } from '../../components'
import { cityCountryNameFormatter, getPagesValue } from '../../helpers/jsxHelper'
import { ButtonComponent } from '../../components/form/Button'
import { verifiedCheck } from '../../helpers/string'
import { clearSearchDashboard, searchInDashboard } from '../../redux/actions'

class ManageBoatShows extends Component {
  componentDidMount() {
    const { getUserBoatShows, clearSearchDashboard } = this.props
    clearSearchDashboard()
    getUserBoatShows({
      page: getPagesValue(),
      limit: pagination.PAGE_RECORD_LIMIT,
    })
  }

  static getDerivedStateFromProps(props, state) {
    const { clearBoatShowFlag, success, deleteSuccess } = props
    if (success || deleteSuccess) {
      clearBoatShowFlag()
    }
  }

  editBoatShow = record => {
    const { history } = this.props
    history.push('/add-boat-show', { record })
  }

  render() {
    const {
      deleteBoatShow,
      loading,
      toggleBoatShowStatus,
      isSearched,
      indexSearchData,
      clearSearchDashboard,
      searchInDashboard,
      boatShows,
      boatShowsTotal,
      getUserBoatShows,
    } = this.props

    const columns = [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: data => (
          <div className="boatShow-action-button justify-content-start text-wrap text-center">
            <span>{data.original.title}</span>
          </div>
        ),
      },
      {
        Header: 'Ad ID',
        accessor: 'adId',
        Cell: data => (
          <div className="boatShow-action-button justify-content-start text-wrap text-center">
            <span>{data.original.adId}</span>
          </div>
        ),
      },
      {
        Header: 'Country',
        accessor: 'address',
        Cell: ({ value }) => {
          const [address] = value

          return (
            <div className="boatShow-action-button justify-content-start text-wrap text-center">
              <span>{cityCountryNameFormatter(address.city, address.country)}</span>
            </div>
          )
        },
      },
      {
        Header: 'Duration',
        accessor: 'availableTripDays',
        Cell: data => {
          let date
          if (moment(data.original.endDate).diff(moment(data.original.startDate), 'hours') === 0) {
            date = moment(data.original.endDate).diff(moment(data.original.startDate), 'minutes').toString() + ' Min'
          } else if (moment(data.original.endDate).diff(moment(data.original.startDate), 'days') === 0) {
            date = moment(data.original.endDate).diff(moment(data.original.startDate), 'hours').toString() + ' Hours'
          } else {
            date = moment(data.original.endDate).diff(moment(data.original.startDate), 'days').toString() + ' Days'
          }
          return (
            <div className="boatShow-action-button">
              <span>{date}</span>
            </div>
          )
        },
      },
      {
        Header: 'Posted Date',
        accessor: 'createdAt',
        Cell: data => (
          <div className="boatShow-action-button justify-content-start text-wrap">
            <span>{moment(data.original.createdAt).format('DD-MM-YYYY hh:mm a')}</span>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: data => (
          <div className="d-flex justify-content-center">
            <Field
              type="switch"
              checked={data.value}
              value={data.value}
              onChange={() => toggleBoatShowStatus({ id: data.original.id, column: 'status', value: data.value })}
            />
          </div>
        ),
      },
      {
        Header: 'Ad Status',
        accessor: 'adStatus',
        Cell: data => (
          <span className={`bg-green-color font-13 text-capitalize m-auto ${verifiedCheck(data.row.adStatus)}`}>
            {verifiedCheck(data.row.adStatus)}
          </span>
        ),
      },
      {
        headerClassName: 'sales--engine--view reset-rt-th',
        Cell: row => (
          <div className="d-flex flex-row justify-content-around action">
            <ButtonComponent className="btn mr-2" color="edit" onClick={() => this.editBoatShow(row.original.id)}>
              Edit
            </ButtonComponent>

            <ButtonComponent
              className="btn mr-2"
              color="danger"
              onClick={() => confirmSubmitHandler(deleteBoatShow, row.original.id, 'Delete', 'Are you sure you want to delete this boat show? If you choose to delete, it cannot be retrieved again.')}
            >
              Delete
            </ButtonComponent>
          </div>
        ),
      },
    ]

    return (
      <DashboardLayout>
        {columns && (
                  <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table adam-dash-store adam-dash-manage-boat">
            <TableCard
              isSearch
              title="Manage Boat Shows"
              button={{ name: 'Add Boat Show', url: 'add-boat-show' }}
              buttonLg
              columns={columns}
              data={isSearched ? indexSearchData : boatShows}
              loading={loading}
              className="manage--boat--table manage--table--section--alignment"
              total={boatShowsTotal}
              searchData={searchInDashboard}
              searchType={'BOATSHOW'}
              searchResultKey={'boatShows'}
              action={getUserBoatShows}
              clearSearchDashboard={clearSearchDashboard}
            />
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.boatShowReducer && state.boatShowReducer.loading,
  boatShows: state.boatShowReducer && state.boatShowReducer.boatShows,
  success: state.boatShowReducer && state.boatShowReducer.success,
  deleteSuccess: state.boatShowReducer && state.boatShowReducer.deleteSuccess,
  deleteError: state.boatShowReducer && state.boatShowReducer.deleteError,
  createSuccess: state.boatShowReducer && state.boatShowReducer.createSuccess,
  createError: state.boatShowReducer && state.boatShowReducer.createError,
  updateSuccess: state.boatShowReducer && state.boatShowReducer.updateSuccess,
  boatShowsTotal: state.boatShowReducer && state.boatShowReducer.boatShowsTotal,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  isSearched: state?.dashboardReducer?.isSearched,
})

const mapDispatchToProps = dispatch => ({
  getUserBoatShows: data => dispatch(getUserBoatShows(data)),
  clearBoatShowFlag: () => dispatch(clearBoatShowFlag()),
  deleteBoatShow: data => dispatch(deleteBoatShow(data)),
  toggleBoatShowStatus: data => dispatch(toggleBoatShowStatus(data)),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
  searchInDashboard: data => dispatch(searchInDashboard(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageBoatShows)
