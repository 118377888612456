import gql from 'graphql-tag'
import {
  BoatShow,
  GeometricLocation,
  AdminUser,
  AddressType,
  ImageType,
  Media,
  ManageBoatShow,
  SellerUser,
} from './schemaTypes/schemaTypes'

export const createBoatShow = gql`
  mutation createBoatShow($input: BoatShowInput!) {
    createBoatShow(input: $input) {
      address ${AddressType}
      adId
      id
      title
      showDescription
      boatshowImages ${Media}
      status
      createdAt
      startDate
      endDate
    }
  }
`

export const getUserBoatShows = gql`
  query getAllBoatShowByUser($page: Int, $limit: Int, $sort: SortType, $sortKey: String) {
    getAllBoatShowByUser(page: $page, limit: $limit, sort: $sort, sortKey: $sortKey) {
      items ${ManageBoatShow}
      total
    }
  }
`

export const getAllBoatShows = gql`
  query getAllBoatShow($page: Int, $limit: Int, $country: String!, $isAdmin: Boolean = false, $latLng: CoordinateInput) {
    getAllBoatShow(page: $page, limit: $limit, country: $country, isAdmin: $isAdmin, latLng: $latLng) {
      items {
        id
        address ${AddressType}
        createdAt
        title
        startDate
        endDate
        showDescription
        boatshowImages ${Media}
        user ${SellerUser}
      }
      total
    }
  }
`

export const searchBoatShow = gql`
query searchBoatShow($city: String, $country: String, $query: String, $page: Float, $limit: Float, $latLng: CoordinateInput) {
  searchBoatShow(city: $city, country: $country, query: $query, page: $page, limit: $limit, latLng: $latLng) {
    items {
        id
        address ${AddressType}
        createdAt
        title
        startDate
        endDate
        showDescription
        user { 
          id
          firstName
          lastName
          image ${ImageType}
        }
        boatshowImages ${Media}
    }
    total
  }
}`

export const updateBoatShow = gql`
  mutation updateBoatShow($input: BoatShowInput!) {
    updateBoatShow(input: $input) {
      address ${AddressType}
      adId
      id
      title
      showDescription
      boatshowImages ${Media}
      status
      createdAt
      startDate
      endDate
    }
  }
`

export const getBoatShowById = gql`
  query editBoatShow($id: String!) {
    editBoatShow(id: $id) 
      ${BoatShow}
  }
`

export const deleteBoatShow = gql`
  mutation deleteBoatShow($id: String!) {
    deleteBoatShow(id: $id) {
      id
    }
  }
`

export const changeStatusBoatShow = gql`
  mutation changeStatusBoatShow($value: Boolean!, $column: String!, $id: String!) {
    changeStatusBoatShow(value: $value, column: $column, id: $id) {
      id
      message
    }
  }
`
