import React, { Component } from 'react'
import { connect } from 'react-redux'

import BoatListingsWithMap from '../../components/gridComponents/BoatListingsWithMap'
import { pagination } from '../../util/enums/enums'
import { urlParamsToObject } from '../../helpers/routeHelper'
import { searchBoatShow } from '../../redux/actions/boatShowAction'

class SearchRentBoats extends Component {
  render() {
    const { location } = this.props

    const urlParams = new URLSearchParams(location.search)
    const values = urlParamsToObject(urlParams)

    const { searchedBoatShowData, totalSearchedBoatShowData, searchBoatShow, isPageLoader } = this.props

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        isBoatShow
        isNotHome={false}
        boats={searchedBoatShowData}
        boatsType={`${totalSearchedBoatShowData || 0} Boat Show in ${values.country}`}
        boatsTypeCount={totalSearchedBoatShowData || 0}
        action={searchBoatShow}
        value={values}
      />
    )
  }
}

const mapStateToProps = state => ({
  searchedBoatShowData: state.boatShowReducer.searchedBoatShowData,
  totalSearchedBoatShowData: state.boatShowReducer.totalSearchedBoatShowData,
  isPageLoader: state.boatShowReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  searchBoatShow: data => dispatch(searchBoatShow(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchRentBoats)
