import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import { useDispatch, useSelector } from 'react-redux'
import { setImageModalView } from '../../redux/actions/chatAction'
import { getSelectUser } from '../../redux/selector/chat'
import { chatType } from '../../util/chat'
import { textTruncate } from '../../helpers/string'

import { CommonTooltip } from '../CommonTooltip'
import { MessageTypeEnum } from '../../util/enums/enums'

export const MessagesImagesAndVideo = ({ images, messageType,metaData, sender, me }) => {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isThumbnail, setIsThumbnail] = useState('')
    const imagesLength =images?.length 
    const selectUser = useSelector(getSelectUser)

    function downloadFilePDF(filePath,name) {
        // eslint-disable-next-line
        fetch(filePath).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = name;
                alink.click();
            })
        })
      }
    return (
        <React.Fragment>
            {selectUser.chatType === chatType.groupChat && !me && <div className={classNames('chat-thread--user', {
                'chat-thread--video': metaData?.isVideo
            })}>{sender?.name}</div>}
            {images?.length > 0 && (<>
                {images.slice(0, imagesLength).map((i, index) => {
                    if (messageType === MessageTypeEnum.DOCUMENT) {
                        return (
                            <div className='chat-file-document' key={`${i}-${index}`}>
                                <span className='chat-file-icon'>
                                    <i class="fa-solid fa-file fa"></i>
                                </span>
                                     <CommonTooltip component={ctProps => (
                                        <span data-tooltip-id={ctProps.id} data-tooltip-content={metaData?.docName} className='chat-file-name'>{textTruncate(metaData?.docName,70)}</span>
                                     )} />

                                <span className='chat-file-download' onClick={()=>{
                                    downloadFilePDF(i, metaData?.docName)
                                }}><i class="fa fa-solid fa-download"></i></span>

                            </div>
                        )
                    }
                    return (metaData?.isVideo || messageType === MessageTypeEnum.VIDEO )?
                        <div className='chat-video-size' key={i}>
                            {isThumbnail !== i && metaData.videoThumbnail?
                            <div className='chat-video-thumbnail' onClick={()=>{
                                setIsThumbnail(i)
                            }}>
                            <img loading="lazy" src={metaData.videoThumbnail} alt='adamsea' />
                            <PlayCircleOutlineIcon className="playVideo-icon" />
                            </div>:
                            <video width="170" height="170" controls>
                                <source src={i} type="video/mp4" />
                            </video>}
                        </div>
                        :
                        <img loading="lazy" className={classNames('chat--thread--images', {
                            'chat--thread--images--single': images.length === 1,
                            'chat--thread--images--more': index > 4,

                        })} onClick={() => {
                            setIsModalOpen(true)
                            dispatch(setImageModalView(true))
                        }} key={index} src={i} alt={i} height={245} />
                })}
                {!metaData?.isVideo && images?.length > 4 && <span className='chat--thread--images--more-count' onClick={() => {
                    setIsModalOpen(true)
                    dispatch(setImageModalView(true))
                    z
                }}>{'+'}{(images?.length - 3)}</span>}
            </>)}
            <ModalGateway>
                {isModalOpen ? <Modal onClose={() => {
                    setIsModalOpen(false)
                    dispatch(setImageModalView(false))
                }}>
                    <Carousel views={images.map((i) => {
                        return {
                            source: i,
                        }
                    })} />
                </Modal> : <></>}
            </ModalGateway>
        </React.Fragment>
    )
}
