import {Container, Row, Col} from 'react-bootstrap'
import { Layout } from '../../components'
import { footerScreenImages, icons } from '../../util/enums/enums'
import { Box, Stack } from '@mui/material'

import './manufacturer.scss'
import { data } from './data'
import { ButtonComponent } from '../../components/form/Button'

export default function Manufacturer(){
    return(
        <Layout>
            <div className='container100'>
                <Row className='py-4'>
                    <Col sm={12}>
                        <h1 className="r-h1">Boat Manufacturers</h1>
                    </Col>
                </Row>

                <Row className='py-4'>
                    <Col sm={6}>
                        <Stack>
                            <p className='mfg--subtitle'><span className='text-primary'>By partnering with us,</span> you'll have the opportunity to showcase your exceptional craftsmanship and innovative designs to a global audience of boat enthusiasts.</p>
                            <p className='mfg--text'>Manufacturers play a crucial role in the success of AdamSea.com as a leading online marketplace for boats.As a manufacturer on AdamSea.com, you'll have access to a platform that caters specifically to the needs of boat buyers and sellers. Our advanced features and user-friendly interface make it easy for buyers to explore and discover your range of boats, while also providing you with the tools to effectively manage your inventory and product listings.</p>
                        </Stack>
                    </Col>
                    <Col className='text-right' sm={6}>
                        <img  width={493} loading='lazy' src={footerScreenImages.mfg_parked_boat} alt='Boat'/>
                    </Col>
                </Row>
                <Box>
                <span className="mfg--title pl-4 my-4">Why Choose AdamSea as a Manufacturing partner</span>
                <Row className='my-4'>
                    {
                        data?.map(({ title, text }) => (
                            <Col lg={4} sm={12}>
                                <div className="mfg--card">
                                    <b className='mfg--subtitle my-1'>{title}</b>
                                    <p className='mfg--text text-dark'>{text}</p>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
                </Box>
                <Box className="my-5 text-center">
                    <Stack>
                    <span className='mfg--subtitle font-weight-normal'>Join us at AdamSea as a manufacturer and unlock the full potential of your business. Take advantage of our global reach, innovative platform, and dedicated support to showcase your boats to a worldwide audience of passionate boating enthusiasts. Together, let's shape the future of the boating industry and revolutionize the way boats are bought and sold.</span>
                    </Stack>
                </Box>
            </div>
        </Layout>
    )
}
