import useLinkPreview from "use-link-preview";
import { SkeletonComponent } from "../skeleton/SkeletonComponent";
import { textTruncate } from "../../helpers/string";

export const LinkPreview = ({ url }) => {
  const { metadata, isLoading, isError } = useLinkPreview(url);

  return (
    <>
      {isLoading && <SkeletonComponent width={170} height={170} />}
      {isError && <div className="chat-link-preview"><a className="chat-link-target" href={url} target="_blank">{String(url).replace('mailto:', '')}</a></div>}
      {metadata && (
        <div className="chat-link-preview">
          <div className="chat-link-title-with-image">
            {metadata.img && <div className="chat-link-image">
              <img loading="lazy" src={metadata.img} alt={metadata.domain} title={metadata.domain} />
            </div>}
            <div className="chat-link-detail">
              {metadata.title && <h3 className="chat-link-title" title={metadata.title}>{textTruncate(metadata.title, 80)}</h3>}
              {metadata.description && <p className="chat-link-description" title={metadata.description}>{textTruncate(metadata.description,135)}</p>}
            </div>
          </div>
          <a className="chat-link-target" href={url} target="_blank">{String(url).replace('mailto:', '')}</a>
        </div>
      )}
    </>
  );
}
