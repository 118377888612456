import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getAllBoatRentOfUser,
  deleteBoatRent,
  clearBoatRentDeleteFlag,
  updateBoatRent,
  clearRentBoatStatusFlag,
  changeBoatRentStatus,
} from '../../redux/actions/boatRentAction'
import UserContext from '../../UserContext'
import { verifiedCheck } from '../../helpers/string'
import { confirmSubmitHandler } from '../../helpers/confirmationPopup'
import { pagination, rentBoatTripForCard } from '../../util/enums/enums'
import { DashboardLayout } from '../../components/layout/dashboardLayout'
import { viewRentBoatHandler } from '../../helpers/boatHelper'
import '../../styles/manageDashboardTableResponsive.scss'
import { TableCard, Field } from '../../components'
import { ButtonComponent } from '../../components/form/Button'
import { getPagesValue } from '../../helpers/jsxHelper'
import moment from 'moment'
import { clearSearchDashboard, searchInDashboard } from '../../redux/actions'
import { get } from 'lodash'
import { Switch } from '@material-ui/core'

class ManageBoatRents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      maximumGuest: 0,
      columns: [
        {
          Header: 'Created',
          accessor: 'createdAt',
          minWidth: 100,
          Cell: ({ value }) => moment(value).format('MMM DD, YYYY'),
        },
        {
          Header: 'Model',
          accessor: 'model',
          minWidth: 100,
        },
        {
          Header: 'Trip',
          accessor: 'trip.alias',
          minWidth: 100,
        },
        {
          Header: 'Trip Type',
          accessor: 'tripType.alias',
          minWidth: 125,
        },
        {
          Header: 'Ad ID',
          accessor: 'adId',
          minWidth: 100,
          Cell: ({ value, original }) => (
            <a
              href="javascript:void(0)"
              className="Verified"
              style={{ backgroundColor: '#007bff' }}
              onClick={() => this.props.history.push(`/rent-inner/${original.id}/${original.model}`)}
            >
              {value}
            </a>
          ),
        },
        {
          Header: 'Manage Rental',
          accessor: 'id',
          minWidth: 130,
          Cell: data => (
            <div className="d-flex justify-content-center">
              <ButtonComponent
                className="btn mr-2"
                color="view"
                onClick={() => {
                  const isRent = data.original.trip?.alias === rentBoatTripForCard.rentPerHour
                  this.props.history.push({
                    pathname: isRent ? '/manage-rent-per-hour-trip-details' : '/manage-trip-details',
                    state: { boatDetails: data.original, pageState: { view: isRent ? 'groups' : 'slots' } },
                  })
                }}
              >
                Manage Trip Details
              </ButtonComponent>
            </div>
          ),
        },
        {
          Header: 'Status',
          accessor: 'boatRentStatus',
          // minWidth: 80,
          Cell: data => (
            <div className="d-flex justify-content-center">
              <Switch
                disabled={this.props.changeStatusLoading}
                checked={get(this.state, `cache.boatRentStatus.${data.original.id}`, null) ?? data.original.boatRentStatus}
                value={get(this.state, `cache.boatRentStatus.${data.original.id}`, null) ?? data.original.boatRentStatus}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={e => {
                  this.setState({ currentChangeStatus: { id: data.original.id, status: e.target.checked } })
                  props.changeBoatRentStatus({
                    id: data.original.id,
                    columnName: 'boatRentStatus',
                    value: e.target.checked,
                  })
                }}
              />
            </div>
          ),
        },
        {
          Header: 'Ad Status',
          accessor: 'adStatus',
          minWidth: 90,
          Cell: data => (
            <span className={`bg-green-color font-13 text-capitalize m-auto ${verifiedCheck(data.original.adStatus)}`}>
              {verifiedCheck(data.original.adStatus)}
            </span>
          ),
        },
        {
          headerClassName: 'sales--engine--view reset-rt-th',
          minWidth: 200,
          Cell: data => (
            <div className="d-flex flex-row justify-content-around action">
              <ButtonComponent className="btn mr-2" color="view" onClick={() => viewRentBoatHandler(data.original)}>
                View
              </ButtonComponent>
              <ButtonComponent disabled={data.original.bookings?.length} className="btn mr-2" color="edit" onClick={() => this.editRent(data.original)}>
                Edit
              </ButtonComponent>
              <ButtonComponent
                disabled={data.original.bookings?.length}
                className="btn mr-2"
                color="danger"
                onClick={() => {
                  confirmSubmitHandler(props.deleteBoatRent, data.original.id, undefined, 'Are you sure you want to delete this ad? If you choose to delete, it cannot be retrieved again.')
                  this.setState({ cache: null })
                }}
              >
                Delete
              </ButtonComponent>
            </div>
          ),
        },
      ],
      showDetailModal: false,
      rentBoatId: '',
      tripDetails: [],
    }
  }
  static contextType = UserContext

  componentDidMount() {
    const { getAllBoatRentOfUser } = this.props
    const data = {
      page: getPagesValue(),
      limit: pagination.PAGE_RECORD_LIMIT,
    }
    getAllBoatRentOfUser(data)
  }

  editRent = record => {
    const { history } = this.context

    record.id &&
      history.push(`/edit-rent-boat/${record.id}`, {
        tripType: record.tripType,
      })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { deleteSuccess, clearBoatRentDeleteFlag, changeStatusSuccess, clearRentBoatStatusFlag } = nextProps

    let newState = { ...prevState }

    if (deleteSuccess) {
      setTimeout(() => clearBoatRentDeleteFlag(), 3000)

      newState = {
        ...newState,
        message: 'Rent Boat deleted Successfully',
        messageType: 'success',
      }
    }

    if (changeStatusSuccess) {
      clearRentBoatStatusFlag()

      newState = {
        ...newState,
        cache: {
          ...newState.cache,
          boatRentStatus: {
            ...newState.cache?.boatRentStatus,
            [newState.currentChangeStatus.id]: newState.currentChangeStatus.status,
          },
        },
      }
    }

    if (!deleteSuccess) {
      newState = {
        ...newState,
        message: '',
      }
    }

    return newState
  }

  render() {
    const {
      isLoading,
      boatRents,
      history,
      totalBoatRents,
      getAllBoatRentOfUser,
      searchInDashboard,
      clearSearchDashboard,
      isSearched,
      indexSearchData,
      totalSearchedData,
    } = this.props

    const { columns, message, messageType } = this.state

    return (
      <DashboardLayout>
        {columns && (
          <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table">
            <TableCard
              isSearch
              title="Manage your rentals"
              button={{ name: 'Add new rental', url: 'add-rent-boat' }}
              columns={columns}
              data={isSearched ? indexSearchData : boatRents}
              message={message}
              messageType={messageType}
              loading={isLoading}
              className="manage--boat--table"
              total={isSearched ? totalSearchedData : totalBoatRents}
              action={getAllBoatRentOfUser}
              searchData={v => {
                searchInDashboard(v)
                this.setState({ cache: null })
              }}
              searchType="RENTBOAT"
              searchResultKey="rentBoats"
              clearSearchDashboard={clearSearchDashboard}
            />
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: state.boatRentReducer && state.boatRentReducer.isLoading,
  boatRents: state.boatRentReducer && state.boatRentReducer.boatRents,
  totalBoatRents: state.boatRentReducer && state.boatRentReducer.totalBoatRents,
  deleteSuccess: state.boatRentReducer && state.boatRentReducer.deleteSuccess,
  changeStatusLoading: state.boatRentReducer && state.boatRentReducer.changeStatusLoading,
  changeStatusSuccess: state.boatRentReducer && state.boatRentReducer.changeStatusSuccess,
  isSearched: state?.dashboardReducer?.isSearched,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
})

const mapDispatchToProps = dispatch => ({
  getAllBoatRentOfUser: data => dispatch(getAllBoatRentOfUser(data)),
  deleteBoatRent: data => dispatch(deleteBoatRent(data)),
  clearBoatRentDeleteFlag: () => dispatch(clearBoatRentDeleteFlag()),
  updateBoatRent: data => dispatch(updateBoatRent(data)),
  changeBoatRentStatus: data => dispatch(changeBoatRentStatus(data)),
  clearRentBoatStatusFlag: () => dispatch(clearRentBoatStatusFlag()),
  searchInDashboard: data => dispatch(searchInDashboard(data)),
  clearSearchDashboard: () => dispatch(clearSearchDashboard()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageBoatRents)
