import React from 'react'
import cn from 'classnames'

/**
 * @param {React.SVGProps<SVGSVGElement>} props
 */
export default function IconReview(props) {
  return (
    <svg
      {...props}
      className={cn('as-media-icon', props.className)}
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="currentColor"
    >
      <path d="M19.319 1.87551C18.2264 0.782967 16.5922 0.228508 14.4676 0.228508H6.51945C5.76783 0.185091 2.26884 0.494705 2.76422 1.64135C2.89376 1.90683 3.21405 2.01644 3.47882 1.8869C4.43471 1.46269 5.47459 1.26127 6.51945 1.29614H14.4676C21.1781 1.2812 19.7425 6.96031 19.8941 11.6914C19.9688 15.6146 17.7666 17.2929 13.9687 17.1178C13.4883 17.1185 13.0363 17.3448 12.7473 17.7285L11.2569 19.715C10.9266 20.2808 10.0569 20.2794 9.72663 19.715L8.23621 17.7292C7.83762 17.1939 7.14437 17.0701 6.51589 17.1178C2.25318 17.0772 1.18981 16.0075 1.08874 11.6906V6.72329C1.07736 5.93751 1.18198 5.15387 1.40049 4.3994C1.49373 4.11968 1.34213 3.81719 1.06312 3.72395C-0.000244872 3.41433 0.0766248 6.05139 0.0246666 6.72258C0.153495 12.6323 -1.25436 18.4224 6.51945 18.1847C6.78066 18.1876 7.20203 18.1335 7.38566 18.3691L8.87608 20.3556C9.46897 21.2495 10.6754 21.4937 11.5694 20.9008C12.0341 20.6389 13.2413 18.7869 13.605 18.3683C13.768 18.1135 14.2185 18.1954 14.4705 18.1847C18.719 18.1847 20.9653 15.9391 20.9653 11.6906V6.72258C20.9653 4.5987 20.4115 2.96806 19.319 1.87551Z" />
      <path d="M5.24669 7.51416C3.02957 7.57039 3.03099 10.8203 5.2474 10.8751C7.46239 10.8174 7.46239 7.56968 5.24669 7.51416ZM4.63458 9.19462C4.63814 8.39318 5.85667 8.39318 5.86023 9.19462C5.85667 9.99606 4.63814 9.99535 4.63458 9.19462Z" />
      <path d="M12.1734 9.19427C12.1172 6.97786 8.86802 6.97857 8.8125 9.19427C8.86873 11.4107 12.1158 11.4093 12.1734 9.19427ZM9.88014 9.19427C9.88369 8.39283 11.1022 8.39283 11.1058 9.19427C11.1022 9.99571 9.88441 9.99499 9.88014 9.19427Z" />
      <path d="M15.7401 7.51416C13.5237 7.56968 13.5237 10.8167 15.7387 10.8751C17.9544 10.8196 17.9558 7.57039 15.7401 7.51416ZM15.1266 9.19462C15.1309 8.39389 16.348 8.39389 16.3522 9.19462C16.3494 9.99677 15.1294 9.99677 15.1266 9.19462Z" />
    </svg>
  )
}
