import React from 'react'
import cn from 'classnames'

/**
 * @param {React.SVGProps<SVGSVGElement>} props
 */
export default function IconChat(props) {
  return (
    <svg
      {...props}
      className={cn('as-media-icon', props.className)}
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="currentColor"
    >
      <path d="M9.64429 22.2058C9.56262 22.2052 9.48212 22.1871 9.40804 22.1515C9.21962 22.0611 9.10004 21.8704 9.10004 21.6609V18.9391H7.46671C6.56604 18.9391 5.83337 18.2059 5.83337 17.3058V8.59427C5.83337 7.69419 6.56604 6.96094 7.46671 6.96094H20.5334C21.434 6.96094 22.1667 7.69419 22.1667 8.59427V17.3058C22.1667 18.2059 21.434 18.9391 20.5334 18.9391H13.919L9.98496 22.0862C9.88812 22.1638 9.76796 22.2058 9.64429 22.2058ZM7.46671 8.05002C7.16629 8.05002 6.92246 8.29385 6.92246 8.59427V17.3058C6.92246 17.6062 7.16629 17.85 7.46671 17.85H9.64429C9.94529 17.85 10.1891 18.0939 10.1891 18.3943V20.5287L13.3875 17.969C13.4844 17.892 13.604 17.85 13.7276 17.85H20.5334C20.8338 17.85 21.0776 17.6062 21.0776 17.3058V8.59427C21.0776 8.29385 20.8338 8.05002 20.5334 8.05002H7.46671ZM18.3558 12.4058H9.64429C9.34387 12.4058 9.10004 12.1619 9.10004 11.8609C9.10004 11.5605 9.34387 11.3167 9.64429 11.3167H18.3558C18.6562 11.3167 18.9 11.5605 18.9 11.8609C18.9 12.1619 18.6562 12.4058 18.3558 12.4058ZM14 14.5834H9.64429C9.34387 14.5834 9.10004 14.3395 9.10004 14.0391C9.10004 13.7381 9.34387 13.4943 9.64429 13.4943H14C14.3005 13.4943 14.5443 13.7381 14.5443 14.0391C14.5443 14.3395 14.3005 14.5834 14 14.5834Z" />
    </svg>
  )
}
