import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Switch } from '@material-ui/core'

import { TableCard, Field } from '../../../components'
import { getAllBranches, deleteBranch, changeBranchStatus, clearBranchFlag, clearSearchDashboard } from '../../../redux/actions'
import { confirmSubmitHandler } from '../../../helpers/confirmationPopup'
import { SuccessNotify } from '../../../helpers/notification'
import { DashboardLayout } from '../../../components/layout/dashboardLayout'
import '../../../styles/manageDashboardTableResponsive.scss'
import { BranchDetailModal } from '../../../components/modal/branchDetailModal'
import { verifiedCheck } from '../../../helpers/string'
import { getPagesValue } from '../../../helpers/jsxHelper'
import { pagination } from '../../../util/enums/enums'

class ManageBranch extends Component {
  state = {
    openDialog: false,
    selectedBranch: null,
  }

  static getDerivedStateFromProps(nextProps) {
    const { isBranchDeleted, clearBranchFlag, statusChanged } = nextProps

    if (isBranchDeleted) {
      clearBranchFlag()
      SuccessNotify('Branch deleted successfully')
    } else if (statusChanged) {
      clearBranchFlag()
      SuccessNotify('Branch status changed successfully')
    }
    return null
  }

  async componentDidMount() {
    const { getAllBranches } = this.props
    await getAllBranches({
      page: pagination.PAGE_COUNT,
      limit: pagination.PAGE_RECORD_LIMIT,
    })
  }

  editBranchHandler = id => {
    const { history } = this.props
    history && history.push(`/edit-branch/${id}`)
  }

  modalHandler = (value = null) => {
    this.setState(preState => ({ openDialog: !preState.openDialog, selectedBranch: value }))
  }

  render() {
    const { openDialog, selectedBranch } = this.state
    const {
      loading,
      branches,
      history,
      deleteBranch,
      changeBranchStatus,
      getAllBranches,
      branchesTotal,
      indexSearchData,
      totalSearchedData,
      clearSearchDashboard,
      isSearched,
    } = this.props
    const columns = [
      {
        Header: 'Contact Name',
        accessor: 'contactName',
      },
      {
        Header: 'City',
        accessor: 'address',
        Cell: ({ value: [{ city }] }) => <span>{city}</span>,
      },
      {
        Header: 'Verification',
        accessor: 'branchVerificationStatus',
        Cell: data => (
          <span className={`bg-green-color font-13 text-capitalize m-auto ${verifiedCheck(data.row.branchVerificationStatus)}`}>
            {verifiedCheck(data.row.branchVerificationStatus)}
          </span>
        ),
      },
      {
        Header: 'Status',
        accessor: 'branchStatus',
        Cell: data => (
          <div className="d-flex justify-content-center">
            <Field
              type="switch"
              checked={data.value}
              value={data.value}
              onChange={() => changeBranchStatus({ id: data.original.id, columnName: 'branchStatus', value: data.value })}
            />
          </div>
        ),
      },
      {
        Header: 'Survey Inquiries',
        accessor: 'requests',
        Cell: data => (
          <button
            type="button"
            className="btn btn-outline-info mr-2 new--request--icon--btn"
            onClick={() => history && history.push(`/my-requests?branchId=${data.original.id}&page=${1}`)}
            disabled={!data.original.branchVerificationStatus}
          >
            {`${data.value} Survey`}
            {data.original.hasNewRequests && (
              <span className="new--request--icon">
                <img loading="lazy" src={require('../../../assets/images/salesEngine/new1.svg')} alt="bell-icon" />
              </span>
            )}
          </button>
        ),
      },
      {
        headerClassName: 'sales--engine--view reset-rt-th',
        Cell: data => (
          <div className="d-flex flex-row justify-content-around action">
            {/* need for future use */}
            {!data.original.isMainBranch && (
              <button
                type="button"
                className="btn btn-outline-success mr-2"
                disabled={data.original.isMainBranch}
                onClick={() => this.editBranchHandler(data.original.id)}
              >
                Edit
              </button>
            )}
            {data.original.isMainBranch && <span className="text-capitalize m-auto main--branch--label">Main Branch</span>}

            <button
              type="button"
              className={`${data.original.isMainBranch ? 'ml-2' : 'ml-0'} btn btn-outline-info`}
              onClick={() => this.modalHandler(data.original)}
            >
              View
            </button>

            {/* need for future use */}
            {!data.original.isMainBranch && (
              <button
                type="button"
                className="btn btn-outline-danger ml-2"
                disabled={data.original.isMainBranch}
                onClick={() =>
                  confirmSubmitHandler(deleteBranch, data && data.row && data.row._original && data.row._original.id, 'Delete Branch', 'Are you sure you want to delete this branch? If you choose to delete, it cannot be retrieved again.')
                }
              >
                Delete
              </button>
            )}
          </div>
        ),
      },
    ]

    return (
      <DashboardLayout>
        {selectedBranch && selectedBranch.contactName && (
          <BranchDetailModal open={openDialog} close={this.modalHandler} value={selectedBranch} />
        )}

        {columns && (
          <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table">
            <TableCard
              isSearch
              title="Manage Request and Branches"
              button={{ name: 'Add Branch', url: 'add-branch' }}
              buttonLg
              columns={columns}
              data={isSearched ? indexSearchData : branches}
              loading={loading}
              className="manage--boat--table manage--table--section--alignment"
              total={isSearched ? totalSearchedData : branchesTotal}
              action={getAllBranches}
              clearSearchDashboard={clearSearchDashboard}
            />
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.branchReducer.loading,
  branches: state.branchReducer.branches,
  isBranchDeleted: state.branchReducer.isBranchDeleted,
  statusChanged: state.branchReducer.statusChanged,
  branchesTotal: state.branchReducer.branchesTotal,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
  isSearched: state?.dashboardReducer?.isSearched,
})

const mapDispatchToProps = dispatch => ({
  getAllBranches: data => dispatch(getAllBranches(data)),
  deleteBranch: data => dispatch(deleteBranch(data)),
  changeBranchStatus: data => dispatch(changeBranchStatus(data)),
  clearBranchFlag: () => dispatch(clearBranchFlag()),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageBranch)
