import React from 'react'
import '../footer/footer.scss'
import { Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { Layout } from '../layout/layout'

class CertifyBoat extends React.Component {
  render() {
    return (
      <>
        <Layout>
          <div className="contactFooter-bg">
            <Container maxWidth="lg" className="footerContact-container">
              <div className="h-100 contact-title">
                <div className="lines">
                  <h1 className="line1 mb-0">Certify Your Boat</h1>
                </div>
              </div>
            </Container>

            <div className="mt-2">
              <div>
                <Container maxWidth="lg">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <div className="contactDetail-box bg-gray pb-40 position-relative h-100">
                        <h3 className="font-weight-400">What Is The Certification Or Survey</h3>
                        <p className="text-justify font-14 contactDetail-style">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        </p>
                        <p className="contact-link">
                          <Link to="/">
                            <h5>Certification</h5>
                          </Link>
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="contactDetail-box bg-gray pb-40 position-relative h-100">
                        <h3 className="font-weight-400">Why Do I Need It</h3>
                        <p className="text-justify font-14 contactDetail-style">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          ut labore et dolore magna
                        </p>
                        <p className="contact-link">
                          <Link to="/">
                            <h5>Certification Need</h5>
                          </Link>
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <div className="contactDetail-box bg-gray pb-40 position-relative h-100">
                        <h3 className="font-weight-400">Types Of Inspection</h3>
                        <p className="text-justify font-14 contactDetail-style">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        </p>
                        <p className="contact-link">
                          <Link to="/">
                            <h5>Inspection Types</h5>
                          </Link>
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="contactDetail-box bg-gray pb-40 position-relative h-100">
                        <h3 className="font-weight-400">Inspection Points</h3>
                        <p className="text-justify font-14 contactDetail-style">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                        <p className="contact-link">
                          <Link to="/">
                            <h5>Inspection Points</h5>
                          </Link>
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <div className="contactDetail-box bg-gray pb-40 position-relative h-100">
                        <h3 className="font-weight-400">Inspect My Boat</h3>
                        <p className="text-justify font-14 contactDetail-style">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        </p>
                        <p className="contact-link">
                          <Link to="/">
                            <h5>Boat Inspect</h5>
                          </Link>
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="contactDetail-box bg-gray pb-40 position-relative h-100">
                        <h3 className="font-weight-400">Sample</h3>
                        <p className="text-justify font-14 contactDetail-style">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        </p>
                        <p className="contact-link">
                          <Link to="/">
                            <h5>Sample</h5>
                          </Link>
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <div className="contactDetail-box bg-gray pb-40 position-relative h-100">
                        <h3 className="font-weight-400">Rate</h3>
                        <p className="text-justify font-14 contactDetail-style">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam
                        </p>
                        <p className="contact-link">
                          <Link to="/">
                            <h5>Rate</h5>
                          </Link>
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="contactDetail-box bg-gray pb-40 position-relative h-100">
                        <h3 className="font-weight-400">Be Aware</h3>
                        <p className="text-justify font-14 contactDetail-style">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco ut labore et dolore
                          magna aliqua.
                        </p>
                        <p className="contact-link">
                          <Link to="/">
                            <h5> Be Aware</h5>
                          </Link>
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <div className="contactDetail-box bg-gray pb-40 position-relative h-100">
                        <h3 className="font-weight-400">How We Do It</h3>
                        <p className="text-justify font-14 contactDetail-style">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                        </p>
                        <p className="contact-link">
                          <Link to="/">
                            <h5>How to do</h5>
                          </Link>
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="contactDetail-box bg-gray pb-40 position-relative h-100">
                        <h3 className="font-weight-400">Who Will Do It</h3>
                        <p className="text-justify font-14 contactDetail-style">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        </p>
                        <p className="contact-link">
                          <Link to="/">
                            <h5>What will do it</h5>
                          </Link>
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default CertifyBoat
