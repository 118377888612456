import React from 'react'
import { useSelector } from 'react-redux'
import { getIsMessageLoading, getSelectUser } from '../../redux/selector/chat'
import { Loader } from '../loader/loader'


export const EmptyThread = ({ isThreadLength }) => {
    const isMessageLoading = useSelector(getIsMessageLoading)
    const selectUser = useSelector(getSelectUser)
    return (
        <React.Fragment>
            {!isThreadLength && !isMessageLoading ? (
                <div className="start--conversation--msg--div">
                    <p>{`Please start your conversation with ${selectUser?.name}`}</p>
                </div>
            ) : (
                !isThreadLength && <div className='chat--loader'>
                    <Loader isSmall />
                </div>
            )}
        </React.Fragment>
    )
}
