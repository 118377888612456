import {
  CREATE_ARTICLE,
  CLEAR_ARTICLE_FLAG,
  GET_USER_ALL_ARTICLES,
  GET_SINGLE_ARTICLE,
  UPDATE_ARTICLE,
  DELETE_ARTICLE,
  CHANGE_ARTICLE_STATUS,
} from '../actionTypes'

export const clearArticleFlag = () => ({
  type: CLEAR_ARTICLE_FLAG,
})

export const createArticle = data => ({
  type: CREATE_ARTICLE,
  payload: data,
})

export const getUserAllArticle = data => ({
  type: GET_USER_ALL_ARTICLES,
  payload: data,
})

export const getSingleArticle = data => ({
  type: GET_SINGLE_ARTICLE,
  payload: data,
})

export const updateArticle = data => ({
  type: UPDATE_ARTICLE,
  payload: data,
})

export const deleteArticle = data => ({
  type: DELETE_ARTICLE,
  payload: data,
})

export const changeArticleStatus = data => ({
  type: CHANGE_ARTICLE_STATUS,
  payload: data,
})
