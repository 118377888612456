import React from 'react'

import UserContext from '../../UserContext'
import { dimensionAspectRatio } from '../../util/utilFunctions'
import { Col, Row } from 'react-bootstrap'
import { dimension } from '../../util/enums/enums'
import { getHeightRatio, getRatio } from '../../helpers/ratio'
import { BoatMediaMainStyle } from '../styleComponent/styleComponent'
import { formattedDate } from '../../helpers/dateTimeHelper'
import { viewArticles } from '../../helpers/boatHelper'

export class BoatMediaPost extends React.Component {
  state = {
    height: 100,
    width: 100,
  }

  static contextType = UserContext

  componentDidMount() {
    const width = getRatio(dimension, 'boatMediaPost', '.section-heading')
    const height = getRatio(dimension, 'boatMediaPost', '.section-heading')

    this.setState({ width, height })
  }
  render() {
    const { value } = this.props
    const { height, width } = this.state

    return (
      <>
        <div className="d-flex media--post--main--div" onClick={() => viewArticles(value.id, value.title)} style={{cursor: 'pointer'}}>
          <div>
            <BoatMediaMainStyle bgHeight={height} bgWidth={width} img={value?.files?.[0]?.url}></BoatMediaMainStyle>
          </div>
          <div className="pr-0 pl-3 d-flex align-items-center">
            <div className="boat-media-aricle-user-desc d-flex flex-column">
              <span className="font-weight-500 aricle-user-title col-gray color-black boat--media--article--title">
                {value.title}
              </span>
              <span className="font-weight-400 aricle-user-title gray-light mt-2 font13 media--post-date">
                {formattedDate(value.createdAt)}
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
}
