import React, { Fragment } from 'react'

import { SkeletonGrid } from '../skeleton/SkeletonGrid'
import { SkeletonDefault } from '../skeleton/SkeletonDefault'
import { SkeletonMapGrid } from '../skeleton/SkeletonMapGrid'
import { skeletonType } from '../../util/enums/enums'

import '../../assets/css/component/skeletonLoader.scss'
import { SkeletonInnerPage } from '../skeleton/SkeletonInnerPage'
import { SkeletonTable } from '../skeleton/SkeletonTable'
import { SkeletonUserInfo } from '../skeleton/SkeletonUserInfo'

export const SkeletonLoader = ({ itemsLength, type, isCircle, isVerticle, count }) => {
  const SkeletonGridType = () => {
    switch (type) {
      case skeletonType.grid:
        return <SkeletonGrid itemsLength={itemsLength} count={count} />

      case skeletonType.mapGrid:
        return <SkeletonMapGrid itemsLength={itemsLength} count={count} />

      case skeletonType.innerPage:
        return <SkeletonInnerPage count={count} />

      case skeletonType.table:
        return <SkeletonTable count={count} />

      case skeletonType.userInfo:
        return <SkeletonUserInfo isVerticle={isVerticle} isCircle={isCircle} itemsLength={itemsLength} count={count} />

      default:
        return <SkeletonDefault itemsLength={itemsLength} />
    }
  }

  return <Fragment>{SkeletonGridType()}</Fragment>
}

SkeletonLoader.defualtProps = {
  itemsLength: 4,
  type: 'grid',
  isCircle: true,
}
