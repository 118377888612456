import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { clearAuthorizationFlag, forgetPasswordMail, clearErrorMessageShow } from '../../redux/actions'
import '../login/login.scss'
import { SuccessNotify } from '../../helpers/notification'
import { Notification } from '../../components'
import TermAndPolicy from '../../components/termAndPolicy'
import ErrorComponent from '../../components/error/errorComponent'
import { ButtonComponent } from '../../components/form/Button'
import Captcha from '../../components/helper/captcha'
import { trimmedValue } from '../../helpers/string'

class SendResetLink extends Component {
  state = {
    user: {
      email: '',
    },
    recaptchaRef: null,
    isVerifyCaptch: false,
    email: '',
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { forgotPasswordMailSent, history } = nextProps
    const { email } = prevState

    if (forgotPasswordMailSent) {
      setTimeout(() => SuccessNotify(`A link to reset your password has been sent to ${email}.`), 100)
      history && history.push('/login')
    }

    return null
  }
  componentDidMount() {
    const { clearAuthFlag, clearErrorMessageShow } = this.props
    clearAuthFlag()
    clearErrorMessageShow()
  }
  render() {
    const { user, recaptchaRef, isVerifyCaptch } = this.state
    const { forgetPasswordMail, errorMessage, isError, clearErrorMessageShow } = this.props

    return (
      <>
        <Notification />
        <Grid container className="flex-1" alignItems="center">
          <Grid item className="h100-vh custom-content-column img-holder" sm={6}>
            <div className="website-logo ml-50">
              <Link to="/">
                <div className="logo">
                  <img loading="lazy" alt="adamsea" className="logo-size" src={require('../../assets/images/login/logo-white.png')} />
                </div>
              </Link>
            </div>
            <Grid container className="flex-1" alignItems="center">
              <div
                style={{
                  display: 'flex',
                  flex: 0.5,
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  minHeight: '100vh',
                  flexDirection: 'column',
                  marginLeft: '3em',
                }}
              >
                <h3 style={{ color: 'white' }}>Welcome</h3>
                <h3 style={{ color: 'white' }}>Back To AdamSea</h3>

                <TermAndPolicy />
              </div>
            </Grid>
          </Grid>

          <Grid className="custom-form-column" item sm={6}>
            <Formik
              initialValues={user}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Email is invalid').required('This field is required'),
              })}
              onSubmit={async values => {
                const token = await recaptchaRef.current.execute()
                if (token) {
                  this.setState({ isVerifyCaptch: false, email: values.email })
                  clearErrorMessageShow()
                  forgetPasswordMail(values)
                } else {
                  this.setState({ isVerifyCaptch: true, email: values.email })
                }
              }}
              render={({ errors, status, touched, values, setFieldValue, handleSubmit }) => (
                <div className="form-holder">
                  <div className="form-content">
                    <div className="forgot-page-form">
                      <h1 className="r-h1">Get help signing in</h1>
                      <div className="page-links"></div>
                      <Form>
                        <p className="font-16 text-left mb-2">
                          Enter the email address associated with your account, and we’ll email you a link to reset your password.
                        </p>
                        <div className="position-relative">
                          <div className="mail-icon mt-10 mb-3">
                            <input
                              id="email"
                              name="email"
                              type="text"
                              className={
                                'form-control forgot-inputfiled-space font-16 ' +
                                (errors.email && touched.email ? ' is-invalid' : '')
                              }
                              value={values.email}
                              placeholder="Email Address"
                              onChange={e => setFieldValue('email', trimmedValue(e.target.value))}
                              required
                            />
                            <span className="form-side-icon forgot-email-icon">
                              <MailOutlineIcon className="forgot-icon-svg" />
                            </span>
                          </div>
                          <ErrorMessage name="email" component="div" className="invalid-feedback" />
                          <ButtonComponent
                            variant="outline"
                            color="success"
                            className="h-auto forgot-reset-btn btn--padding btn--font w-100 justify-content-center login--sign--up--btn"
                            id="submit"
                            onClick={handleSubmit}
                          >
                            Send Reset Link
                          </ButtonComponent>

                          <Captcha getRefValue={value => this.setState({ recaptchaRef: value })} />

                          {isError && <ErrorComponent errors={errorMessage} />}
                          {isVerifyCaptch && <div className="alert alert-danger">Invalid captach</div>}

                          <p className="font-16 text-left">
                            {'You need more help ?'}
                            <Link to="/contact-us" className="font-weight-500 ml-2">
                              {'Contact us'}
                            </Link>
                          </p>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              )}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => ({
  isError: state.loginReducer.isError,
  errorMessage: state.errorReducer.errorMessage,
  forgotPasswordMailSent: state.loginReducer.forgotPasswordMailSent,
  isAuthorized: state.loginReducer.isAuthorized,
})

const mapDispatchToProps = dispatch => ({
  forgetPasswordMail: data => dispatch(forgetPasswordMail(data)),
  clearAuthFlag: () => dispatch(clearAuthorizationFlag()),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SendResetLink)
