import {
  GET_ALL_PAGE_INFORMATION_BY_TYPE,
  CLEAR_PAGE_INFO_BY_TYPE_FLAG,
  GET_RENT_PAGES,
  CLEAR_RENT_PAGES_FLAG,
  GET_SALES_ENGINE_PAGES,
  GET_AGREEMENT,
  CLEAR__GET_AGREEMENT,
  CLEAR_MODEL_SIZE_FLAG,
  GET_STATIC_PAGES,
  CLEAR__GET_STATIC_PAGES,
} from '../actionTypes'

export const getAllPageInfoByType = (data, isModel = false) => ({
  type: GET_ALL_PAGE_INFORMATION_BY_TYPE,
  payload: data,
  isModel,
})

export const clearPageInfoByTypeFlag = () => ({
  type: CLEAR_PAGE_INFO_BY_TYPE_FLAG,
})

export const getRentPages = () => ({
  type: GET_RENT_PAGES,
})

export const clearRentPagesFlag = () => ({
  type: CLEAR_RENT_PAGES_FLAG,
})

export const getSalesEnginePages = payload => ({
  type: GET_SALES_ENGINE_PAGES,
  payload,
})

export const getAgreement = payload => ({
  type: GET_AGREEMENT,
  payload,
})

export const clear_getAgreement = () => ({
  type: CLEAR__GET_AGREEMENT,
})

export const getStaticPages = payload => ({
  type: GET_STATIC_PAGES,
  payload,
})

export const clear_getStaticPages = () => ({
  type: CLEAR__GET_STATIC_PAGES,
})

export const clearModelSizeFlags = () => ({
  type: CLEAR_MODEL_SIZE_FLAG,
})
