import { put, takeLatest, all } from 'redux-saga/effects'
import { graphqlClient } from '../../helpers/graphqlClient'
import {
  GET_SALES_ENGINE_BY_BUYER,
  GET_SALES_ENGINE_BY_BUYER_SUCCESS,
  GET_SALES_ENGINE_BY_BUYER_FAILURE,
  GET_SALES_ENGINE_BY_SELLER,
  GET_SALES_ENGINE_BY_SELLER_SUCCESS,
  GET_SALES_ENGINE_BY_SELLER_FAILURE,
  GET_SALES_ENGINE_BY_SURVEYOR,
  GET_SALES_ENGINE_BY_SURVEYOR_SUCCESS,
  GET_SALES_ENGINE_BY_SHIPPER,
  GET_SALES_ENGINE_BY_SURVEYOR_FAILURE,
  GET_SALES_ENGINE_BY_SHIPPER_SUCCESS,
  GET_SALES_ENGINE_BY_SHIPPER_FAILURE,
  GET_SINGLE_SALES_ENGINE,
  GET_SINGLE_SALES_ENGINE_FAILURE,
  GET_SINGLE_SALES_ENGINE_SUCCESS,
  CREATE_SALES_ENGINE,
  CREATE_SALES_ENGINE_SUCCESS,
  CREATE_SALES_ENGINE_FAILURE,
  ASSIGN_SURVEYOR,
  ASSIGN_SURVEYOR_SUCCESS,
  ASSIGN_SURVEYOR_FAILURE,
  PAYMENT_REQUEST,
  PAYMENT_REQUEST_SUCCESS,
  PAYMENT_REQUEST_FAILURE,
  SALES_ENGINE_ASSIGN_SHIPPER_REQUEST,
  SALES_ENGINE_ASSIGN_SHIPPER_REQUEST_FAILURE,
  SALES_ENGINE_ASSIGN_SHIPPER_REQUEST_SUCCESS,
  GET_AGREEMENTS_CONTENTS,
  GET_AGREEMENTS_CONTENTS_SUCCESS,
  GET_AGREEMENTS_CONTENTS_FAILURE,
  CHECK_AGREEMENT,
  CHECK_AGREEMENT_SUCCESS,
  CHECK_AGREEMENT_FAILURE,
  SALES_ENGINE_SURVEYOR_ACCEPT_BUYER,
  SALES_ENGINE_SURVEYOR_ACCEPT_BUYER_SUCCESS,
  SALES_ENGINE_SURVEYOR_ACCEPT_BUYER_FAILURE,
  SALES_ENGINE_SURVEYOR_DECLINE_BUYER,
  SALES_ENGINE_SURVEYOR_DECLINE_BUYER_SUCCESS,
  SALES_ENGINE_SURVEYOR_DECLINE_BUYER_FAILURE,
  CREATE_SURVEYOR_REPORT,
  CREATE_SURVEYOR_REPORT_SUCCESS,
  CREATE_SURVEYOR_REPORT_FAILURE,
  GET_SURVEYOR_REPORT_FAILURE,
  GET_SURVEYOR_REPORT_SUCCESS,
  GET_SURVEYOR_REPORT,
  SHIPPER_ACCEPT_SHIPMENT_REQUEST,
  SHIPPER_ACCEPT_SHIPMENT_REQUEST_SUCCESS,
  SHIPPER_ACCEPT_SHIPMENT_REQUEST_FAILURE,
  SHIPPER_DECLINE_SHIPMENT_REQUEST,
  SHIPPER_DECLINE_SHIPMENT_REQUEST_SUCCESS,
  SHIPPER_DECLINE_SHIPMENT_REQUEST_FAILURE,
  CLEAR_GET_SINGLE_SALES_ENGINE_FLAG,
  ADD_SHIPMENT_PROPOSAL,
  ADD_SHIPMENT_PROPOSAL_SUCCESS,
  ADD_SHIPMENT_PROPOSAL_FAILURE,
  GET_ALL_SHIPMENT_PROPOSAL,
  GET_ALL_SHIPMENT_PROPOSAL_SUCCESS,
  GET_ALL_SHIPMENT_PROPOSAL_FAILURE,
  ADD_NEGOTIABLE_PRICE_SUCCESS,
  ADD_NEGOTIABLE_PRICE_FAILURE,
  ADD_NEGOTIABLE_PRICE,
  CLEAR_SALES_ENGINE_MY_BOAT_FLAG,
  ADD_SURVEY_OPTION,
  ADD_SURVEY_OPTION_SUCCESS,
  ADD_SURVEY_OPTION_FAILURE,
  CLEAR_SALES_ENGINE_SURVEYOR_DASHBOARD_FLAG,
  CLEAR_SALES_ENGINE_SHIPPER_DASHBOARD_FLAG,
  CLEAR_PAYMENT_FLAG,
  SKIP_SHIPMENT,
  SKIP_SHIPMENT_SUCCESS,
  SKIP_SHIPMENT_FAILURE,
  DECLINE_SURVEYOR,
  DECLINE_SURVEYOR_SUCCESS,
  DECLINE_SURVEYOR_FAILURE,
  CLEAR_SALES_ENGINE_SHIPMENT_FLAG,
  ASSIGN_AGENT,
  ASSIGN_AGENT_SUCCESS,
  ASSIGN_AGENT_FAILURE,
  GET_SALES_ENGINE_BY_AGENT,
  GET_SALES_ENGINE_BY_AGENT_SUCCESS,
  GET_SALES_ENGINE_BY_AGENT_FAILURE,
  GET_DOCUMENT_LINKS,
  GET_DOCUMENT_LINKS_SUCCESS,
  GET_DOCUMENT_LINKS_FAILURE,
  ADD_BOAT_SHIPMENT_LOCATION,
  ADD_BOAT_SHIPMENT_LOCATION_SUCCESS,
  ADD_BOAT_SHIPMENT_LOCATION_FAILURE,
  GET_COST_ESTIMATE,
  GET_COST_ESTIMATE_SUCCESS,
  GET_COST_ESTIMATE_FAILURE,
  START_SHIPMENT,
  START_SHIPMENT_FAILURE,
  START_SHIPMENT_SUCCESS,
  COMPLETE_SHIPMENT_FAILURE,
  COMPLETE_SHIPMENT,
  COMPLETE_SHIPMENT_SUCCESS,
  ADD_SHIPPER_DOCUMENTS,
  ADD_SHIPPER_DOCUMENTS_FAILURE,
  ADD_SHIPPER_DOCUMENTS_SUCCESS,
  CLEAR_AGENT_FLAG,
  CLEAR_SHIPPER_REQUEST_FLAG,
  DISCARD_AGENT,
  DISCARD_AGENT_FAILURE,
  DISCARD_AGENT_SUCCESS,
  ASSIGN_SURVEYOR_CLEAR_FLAG,
  NOTIFICATION_MESSAGE,
  GET_SALES_ENGINE_AGENTS,
  GET_SALES_ENGINE_AGENTS_SUCCESS,
  GET_SALES_ENGINE_AGENTS_FAILURE,
  STOP_SALES_ENGINE_PROCESS,
  STOP_SALES_ENGINE_PROCESS_SUCCESS,
  STOP_SALES_ENGINE_PROCESS_FAILURE,
  ERROR_NOTIFICATION_MESSAGE,
  CLEAR_REPORT_FLAG,
  PRINT_PAYMENT_RECEIPT_SUCCESS,
  PRINT_PAYMENT_RECEIPT_FAILURE,
  PRINT_PAYMENT_RECEIPT,
  GET_SALES_ENGINE_STATIC_CONTENT,
  GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS,
  GET_SALES_ENGINE_STATIC_CONTENT_FAILURE,
  GET_SURVEY_DOCUMENT_DATA,
  GET_SURVEY_DOCUMENT_DATA_SUCCESS,
  GET_SURVEY_DOCUMENT_DATA_FAILURE,
  GET_SALES_ENGINE_BY_BOAT,
  GET_SALES_ENGINE_BY_BOAT_SUCCESS,
  GET_SALES_ENGINE_BY_BOAT_FAILURE,
  BUYER_REVIEW_SURVEY_REPORT,
  BUYER_REVIEW_SURVEY_REPORT_SUCCESS,
  BUYER_REVIEW_SURVEY_REPORT_FAILURE,
  CLEAR_REVIEW_REPORT_FLAG,
  UPDATE_SURVEYOR_REPORT,
  UPDATE_SURVEYOR_REPORT_SUCCESS,
  UPDATE_SURVEYOR_REPORT_FAILURE,
  SALES_ENGINE_STEP_CHANGE,
  SALES_ENGINE_STEP_CHANGE_SUCCESS,
  SALES_ENGINE_STEP_CHANGE_FAILURE,
  GET_DEALERS_SALES_ENGINE,
  GET_DEALERS_SALES_ENGINE_FAILURE,
  GET_DEALERS_SALES_ENGINE_SUCCESS,
  GET_SHIPPER_DOCUMENTS,
  GET_SHIPPER_DOCUMENTS_SUCCESS,
  GET_SHIPPER_DOCUMENTS_FAILURE,
  UPDATE_STOCK_QUANTITY,
  UPDATE_STOCK_QUANTITY_SUCCESS,
  UPDATE_STOCK_QUANTITY_FAILURE,
  UPDATE_SHIPPER_DOCUMENTS_SUCCESS,
  UPDATE_SHIPPER_DOCUMENTS_FAILURE,
  UPDATE_SHIPPER_DOCUMENTS,
  REQUEST_AGENT,
  REQUEST_AGENT_SUCCESS,
  REQUEST_AGENT_FAILURE,
  CLEAR_DISCARD_AGENT_FLAG,
  CLEAR_SHIPPER_DOCUMENTS_FLAG,
  SALES_ENGINE_DASHBOARD_VISIT_SUCCESS,
  SALES_ENGINE_DASHBOARD_VISIT_FAILURE,
  SALES_ENGINE_DASHBOARD_VISIT,
  CLEAR_SALES_ENGINE_DASHBOARD_FLAG,
  SALES_ENGINE_PAYMENT_DETAILS,
  SALES_ENGINE_PAYMENT_DETAILS_SUCCESS,
  SALES_ENGINE_PAYMENT_DETAILS_FAILURE,
  GET_COMMISSIONS,
  GET_COMMISSIONS_SUCCESS,
  GET_COMMISSIONS_FAILURE,
} from '../actionTypes'
import {
  getSalesEngineByBuyer,
  getSalesEngineBySeller,
  getSalesEngineBySurveyor,
  getSalesEngineByShipper,
  singleSalesEngine,
  createSalesEngine,
  paymentQuery,
  requestSurveyor,
  getAgreementContents,
  checkAgreement,
  salesEngineAssignShipperQuery,
  salesEngineSurveyorAcceptBuyerRequest,
  salesEngineSurveyorDeclineBuyerRequest,
  createSurveyorReport,
  getSurveyorReport,
  shipperAcceptShipmentRequest,
  shipperDeclineShipmentRequest,
  salesEngineShipperSubmitPrice,
  salesEngineShipperList,
  sellerAddNegotiableBoatPrice,
  skipSurveyor,
  skipShipper,
  declineSurveyor,
  assignAgent,
  getSalesEngineByAgent,
  generateAgreementAndSurvey,
  addBoatShipmentLocation,
  getCostEstimate,
  startShipment,
  shipmentComplete,
  createShipperDocument,
  discardAgent,
  salesEngineAgents,
  salesEngineStopProcess,
  getPaymentReceipt,
  getAllSalesEngineStaticContent,
  getSurveyDocument,
  salesEngineByBoat,
  buyerReviewSurveyorReport,
  updateSurveyorReport,
  stepChangeSchema,
  getDealersManufacturers,
  getShipperDocuments,
  updateStockQuantityQuery,
  updateShipperDocument,
  requestAgent,
  salesEngineDashboardVisit,
  salesEngineTransections,
  getCommissionsQuery,
} from '../../graphql/salesEngineSchema'
import { notificationEnum, dynamicError } from '../../util/enums/notificationEnum'
import { mutation, query } from './apiHelper'
import { ErrorNotify } from '../../helpers/notification'
import { get } from 'lodash'

function getBuyerSalesEnginesApi(input) {
  return graphqlClient
    .query({
      query: getSalesEngineByBuyer,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function getSellerSalesEnginesApi(input) {
  return graphqlClient
    .query({
      query: getSalesEngineBySeller,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function getSurveyorSalesEnginesApi(input) {
  return graphqlClient
    .query({
      query: getSalesEngineBySurveyor,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function getShipperSalesEnginesApi(input) {
  return graphqlClient
    .query({
      query: getSalesEngineByShipper,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function getSingleSalesEngineApi(input) {
  return graphqlClient
    .query({
      query: singleSalesEngine,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      return error?.networkError?.result ?? error
    })
}

function addSalesEngineApi(input) {
  return graphqlClient
    .mutate({
      mutation: createSalesEngine,
      variables: { input },
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}
function paymentApi(input) {
  return graphqlClient
    .mutate({
      mutation: paymentQuery,
      variables: { input },
    })
    .then(res => res)
    .catch(error => {
      get(error, 'errors[0].message', '') || get(error, 'networkError.result.errors[0].message', '')
    })
}
function salesEngineAssignShipperApi(input) {
  return graphqlClient
    .mutate({
      mutation: salesEngineAssignShipperQuery,
      variables: input,
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function assignSalesEngineSurveyorApi(input) {
  return graphqlClient
    .mutate({
      mutation: requestSurveyor,
      variables: input,
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function declineSalesEngineSurveyorApi(input) {
  return graphqlClient
    .mutate({
      mutation: declineSurveyor,
      variables: input,
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function getAllAgreementsContentsApi(input) {
  return graphqlClient
    .query({
      query: getAgreementContents,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function checkAgreementsApi(input) {
  return graphqlClient
    .mutate({
      mutation: checkAgreement,
      variables: input,
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function salesEngineSurveyorAcceptBuyerApi(input) {
  return graphqlClient
    .mutate({
      mutation: salesEngineSurveyorAcceptBuyerRequest,
      variables: { id: input.id, surveyorId: input.surveyorId },
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function salesEngineSurveyorDeclineBuyerApi(input) {
  return graphqlClient
    .mutate({
      mutation: salesEngineSurveyorDeclineBuyerRequest,
      variables: { id: input.id, surveyorId: input.surveyorId },
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}
function salesEngineSurveyorCreateReportApi(input) {
  return graphqlClient
    .mutate({
      mutation: createSurveyorReport,
      variables: { input: input },
    })
    .then(res => res)
    .catch(error => {
      ErrorNotify(get(error, 'errors[0].message', '') || get(error, 'networkError.result.errors[0].message', ''))
    })
}
function getSurveyorReportBySalesEngineApi(input) {
  return graphqlClient
    .query({
      query: getSurveyorReport,
      variables: { surveyorId: input.surveyorId, salesEngineId: input.salesEngineId },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function salesEngineShipperAcceptShipmentRequestApi(input) {
  return graphqlClient
    .mutate({
      mutation: shipperAcceptShipmentRequest,
      variables: { id: input },
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function salesEngineShipperDeclineShipmentRequestApi(input) {
  return graphqlClient
    .mutate({
      mutation: shipperDeclineShipmentRequest,
      variables: input,
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function addShipmentProposalApi(input) {
  return graphqlClient
    .mutate({
      mutation: salesEngineShipperSubmitPrice,
      variables: { input },
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function salesEngineGetAllShipmentProposalApi(input) {
  return graphqlClient
    .query({
      query: salesEngineShipperList,
      variables: { salesEngineId: input },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function addNegotiablePriceApi(input) {
  return graphqlClient
    .mutate({
      mutation: sellerAddNegotiableBoatPrice,
      variables: { input },
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function addSurveyOptionApi(input) {
  return graphqlClient
    .mutate({
      mutation: skipSurveyor,
      variables: input,
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function skipShipmentProcessApi(input) {
  return graphqlClient
    .mutate({
      mutation: skipShipper,
      variables: input,
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function assignAgentApi(input) {
  return graphqlClient
    .mutate({
      mutation: assignAgent,
      variables: { agentId: input.agentId, id: input.id },
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function requestAgentApi(input) {
  return graphqlClient
    .mutate({
      mutation: requestAgent,
      variables: { agentId: input.agentId, id: input.id },
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function getSalesEngineByAgentApi(input) {
  return graphqlClient
    .query({
      query: getSalesEngineByAgent,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function getSalesEngineDocumentLinksApi(input) {
  return graphqlClient
    .query({
      query: generateAgreementAndSurvey,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function boatShipmentLocationApi(input) {
  return graphqlClient
    .mutate({
      mutation: addBoatShipmentLocation,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function getCostEstimateApi(input) {
  return graphqlClient
    .query({
      query: getCostEstimate,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function startShipmentApi(input) {
  return graphqlClient
    .mutate({
      mutation: startShipment,
      variables: { id: input.id },
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function completeShipmentApi(input) {
  return graphqlClient
    .mutate({
      mutation: shipmentComplete,
      variables: { id: input.id },
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function createShipperDocumentApi(input) {
  return graphqlClient
    .mutate({
      mutation: createShipperDocument,
      variables: { input },
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function discardAgentApi(input) {
  return graphqlClient
    .mutate({
      mutation: discardAgent,
      variables: input,
    })
    .then(res => res)
    .catch(error => {
      return error.networkError ? error.networkError.result : error
    })
}

function getSalesEngineAgentsApi(input) {
  return query(salesEngineAgents, input)
}

function stopSalesEngineApi(input) {
  return query(salesEngineStopProcess, input)
}

function paymentReceiptApi(input) {
  return query(getPaymentReceipt, { input })
}

function salesEngineStaticContentApi() {
  return query(getAllSalesEngineStaticContent)
}

function getSurveyDocumentApi() {
  return query(getSurveyDocument)
}

function getSalesEngineByBoatApi(input) {
  return query(salesEngineByBoat, input)
}

function buyerReviewSurveyReportApi(input) {
  return query(buyerReviewSurveyorReport, input)
}

function updateSurveyorReportApi(input) {
  return query(updateSurveyorReport, { input })
}

function getDealersSalesEngineApi(input) {
  return query(getDealersManufacturers, input)
}

function getShipperDocumentApi(input) {
  return query(getShipperDocuments, input)
}

function updateStockQuantityApi(input) {
  return query(updateStockQuantityQuery, { input })
}

function updateShipperDocumentsApi(input) {
  return query(updateShipperDocument, { input })
}

function salesEngineDashboardVisitApi(input) {
  return mutation(salesEngineDashboardVisit, input)
}

function salesEnginePaymentDetailsApi(input) {
  return mutation(salesEngineTransections, input)
}

function getCommissionsAPI(input) {
  return graphqlClient.query({
    query: getCommissionsQuery,
    variables: input,
  })
}

function* getBuyerSalesEngines(action) {
  const res = yield getBuyerSalesEnginesApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: GET_SALES_ENGINE_BY_BUYER_SUCCESS, payload: res.data.getSalesEngineByBuyer })
  } else {
    yield put({ type: GET_SALES_ENGINE_BY_BUYER_FAILURE, errors: res.errors })
  }
}

function* getSellerSalesEngines(action) {
  const res = yield getSellerSalesEnginesApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: GET_SALES_ENGINE_BY_SELLER_SUCCESS, payload: res.data.getSalesEngineBySeller })
  } else {
    yield put({ type: GET_SALES_ENGINE_BY_SELLER_FAILURE, errors: res.errors })
  }
}

function* getSurveyorSalesEngines(action) {
  const res = yield getSurveyorSalesEnginesApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: GET_SALES_ENGINE_BY_SURVEYOR_SUCCESS, payload: res.data.getSalesEngineBySurveyor })
  } else {
    yield put({ type: GET_SALES_ENGINE_BY_SURVEYOR_FAILURE, errors: res.errors })
  }

  yield put({ type: CLEAR_SALES_ENGINE_DASHBOARD_FLAG })
}

function* getShipperSalesEngines(action) {
  const res = yield getShipperSalesEnginesApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: GET_SALES_ENGINE_BY_SHIPPER_SUCCESS, payload: res.data.getSalesEngineByShipper })
    yield put({ type: SALES_ENGINE_DASHBOARD_VISIT })
  } else {
    yield put({ type: GET_SALES_ENGINE_BY_SHIPPER_FAILURE, errors: res.errors })
  }
  yield put({ type: CLEAR_SALES_ENGINE_DASHBOARD_FLAG })
}

function* getSingleSalesEngine(action) {
  try {
    const res = yield getSingleSalesEngineApi(action.payload)
    if (res && res.data && !res.errors) {
      yield put({ type: GET_SINGLE_SALES_ENGINE_SUCCESS, payload: res.data.singleSalesEngine })
    } else {
      yield put({ type: GET_SINGLE_SALES_ENGINE_FAILURE, errors: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    }
  } catch (e) {
    yield put({ type: GET_SINGLE_SALES_ENGINE_FAILURE, e })
  }
  yield put({ type: CLEAR_GET_SINGLE_SALES_ENGINE_FLAG })
}

function* addSalesEngine(action) {
  const res = yield addSalesEngineApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: CREATE_SALES_ENGINE_SUCCESS, payload: res.data.createSalesEngine })
  } else {
    yield put({ type: CREATE_SALES_ENGINE_FAILURE, errors: res.errors })
  }
}

function* assignSalesEngineSurveyor(action) {
  const res = yield assignSalesEngineSurveyorApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: ASSIGN_SURVEYOR_SUCCESS, payload: res.data.salesEngineBuyerRequestSurveyor })
    yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.SURVEYOR_REQUEST })
  } else if (res?.errors) {
    yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    yield put({ type: ASSIGN_SURVEYOR_FAILURE, errors: res.errors })
  }
  yield put({ type: ASSIGN_SURVEYOR_CLEAR_FLAG })
}

function* declineSalesEngineSurveyor(action) {
  const res = yield declineSalesEngineSurveyorApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: DECLINE_SURVEYOR_SUCCESS, payload: res.data.buyerDeclineSurveyor })
  } else {
    yield put({ type: DECLINE_SURVEYOR_FAILURE, errors: res.errors })
  }
}

function* paymentData(action) {
  try {
    const res = yield paymentApi(action.payload)

    if (res && res.data && !res.hasOwnProperty('errors')) {
      yield put({ type: PAYMENT_REQUEST_SUCCESS, payload: res.data.salesEnginePayment })
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.SALES_ENGINE_PAYMENT })
    } else if (res?.errors) {
      yield put({ type: PAYMENT_REQUEST_FAILURE, errors: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    }
  } catch (e) {
    yield put({ type: PAYMENT_REQUEST_FAILURE, errors: e })
  }

  yield put({ type: CLEAR_PAYMENT_FLAG })
}

function* salesEngineAssignShipperData(action) {
  const res = yield salesEngineAssignShipperApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: SALES_ENGINE_ASSIGN_SHIPPER_REQUEST_SUCCESS, payload: res.data.salesEngineAssignShipper })
  } else {
    yield put({ type: SALES_ENGINE_ASSIGN_SHIPPER_REQUEST_FAILURE, errors: res.errors })
    yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
  }
  yield put({ type: CLEAR_SHIPPER_REQUEST_FLAG })
}

function* getAllAgreementsContents(action) {
  const res = yield getAllAgreementsContentsApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: GET_AGREEMENTS_CONTENTS_SUCCESS, payload: res.data.salesEngineAgreementContent })
  } else {
    yield put({ type: GET_AGREEMENTS_CONTENTS_FAILURE, errors: res.errors })
  }
}

function* checkAgreements(action) {
  const res = yield checkAgreementsApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: CHECK_AGREEMENT_SUCCESS, payload: res.data.salesEngineAgreementCheck })
  } else {
    yield put({ type: CHECK_AGREEMENT_FAILURE, errors: res.errors })
  }
}

function* salesEngineSurveyorAcceptBuyerData(action) {
  const res = yield salesEngineSurveyorAcceptBuyerApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: SALES_ENGINE_SURVEYOR_ACCEPT_BUYER_SUCCESS, payload: res.data.salesEngineSurveyorAcceptBuyerRequest })
  } else {
    yield put({ type: SALES_ENGINE_SURVEYOR_ACCEPT_BUYER_FAILURE, errors: res.errors })
  }
}

function* salesEngineSurveyorDeclineBuyerData(action) {
  const res = yield salesEngineSurveyorDeclineBuyerApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: SALES_ENGINE_SURVEYOR_DECLINE_BUYER_SUCCESS, payload: res.data.salesEngineSurveyorDeclineBuyerRequest })
  } else {
    yield put({ type: SALES_ENGINE_SURVEYOR_DECLINE_BUYER_FAILURE, errors: res.errors })
  }
}

function* salesEngineSurveyorCreateReportData(action) {
  const res = yield salesEngineSurveyorCreateReportApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: CREATE_SURVEYOR_REPORT_SUCCESS, payload: res })
  } else {
    yield put({ type: CREATE_SURVEYOR_REPORT_FAILURE, errors: res.errors })
  }
}

function* getSurveyorReportBySalesEngineData(action) {
  const res = yield getSurveyorReportBySalesEngineApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: GET_SURVEYOR_REPORT_SUCCESS, payload: res })
  } else {
    yield put({ type: GET_SURVEYOR_REPORT_FAILURE, errors: res.errors })
  }
  yield put({ type: CLEAR_REPORT_FLAG })
}

function* salesEngineShipperAcceptShipmentRequest(action) {
  const res = yield salesEngineShipperAcceptShipmentRequestApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: SHIPPER_ACCEPT_SHIPMENT_REQUEST_SUCCESS, payload: res.data.salesEngineShipperAcceptShipmentRequest })
  } else {
    yield put({ type: SHIPPER_ACCEPT_SHIPMENT_REQUEST_FAILURE, errors: res.errors })
  }
  yield put({ type: CLEAR_SALES_ENGINE_SHIPPER_DASHBOARD_FLAG })
}

function* salesEngineShipperDeclineShipmentRequest(action) {
  const res = yield salesEngineShipperDeclineShipmentRequestApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: SHIPPER_DECLINE_SHIPMENT_REQUEST_SUCCESS, payload: res.data.salesEngineShipperDeclineShipmentRequest })
  } else {
    yield put({ type: SHIPPER_DECLINE_SHIPMENT_REQUEST_FAILURE, errors: res.errors })
  }
  yield put({ type: CLEAR_SALES_ENGINE_SHIPPER_DASHBOARD_FLAG })
}

function* addShipmentProposal(action) {
  const res = yield addShipmentProposalApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: ADD_SHIPMENT_PROPOSAL_SUCCESS, payload: res.data.salesEngineShipperSubmitPrice })
  } else {
    yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    yield put({ type: ADD_SHIPMENT_PROPOSAL_FAILURE, errors: res.errors })
  }
}

function* salesEngineGetAllShipmentProposal(action) {
  const res = yield salesEngineGetAllShipmentProposalApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: GET_ALL_SHIPMENT_PROPOSAL_SUCCESS, payload: res.data.salesEngineShipperList })
  } else {
    yield put({ type: GET_ALL_SHIPMENT_PROPOSAL_FAILURE, errors: res.errors })
  }
}

function* addNegotiablePriceData(action) {
  const res = yield addNegotiablePriceApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: ADD_NEGOTIABLE_PRICE_SUCCESS, payload: res.data.sellerAddNegotiableBoatPrice })
  } else {
    yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    yield put({ type: ADD_NEGOTIABLE_PRICE_FAILURE, errors: res.errors })
  }
  yield put({ type: CLEAR_SALES_ENGINE_MY_BOAT_FLAG })
}

function* addSurveyOption(action) {
  const res = yield addSurveyOptionApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: ADD_SURVEY_OPTION_SUCCESS, payload: res.data.skipSurveyor })
  } else if (res?.errors) {
    yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    yield put({ type: ADD_SURVEY_OPTION_FAILURE, errors: res.errors })
  }
  yield put({ type: CLEAR_SALES_ENGINE_MY_BOAT_FLAG })
}

function* skipShipmentProcess(action) {
  const res = yield skipShipmentProcessApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: SKIP_SHIPMENT_SUCCESS, payload: res.data.skipShipper })
  } else {
    yield put({ type: SKIP_SHIPMENT_FAILURE, errors: res.errors })
  }
  yield put({ type: CLEAR_SALES_ENGINE_SHIPMENT_FLAG })
}

function* assignAgentData(action) {
  const res = yield assignAgentApi(action.payload)
  if (res && res.data && !res.hasOwnProperty('errors')) {
    if (action.managePageRequest) {
      yield put({
        type: NOTIFICATION_MESSAGE,
        payload: notificationEnum.AGENT_ACCEPT_REQUEST,
      })
    }
    yield put({ type: ASSIGN_AGENT_SUCCESS, payload: res.data.assignAgent })
  } else if (res?.errors) {
    yield put({ type: ASSIGN_AGENT_FAILURE, errors: res.errors })
    yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
  }

  yield put({ type: CLEAR_AGENT_FLAG })
}

function* requestAgentData(action) {
  const res = yield requestAgentApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: REQUEST_AGENT_SUCCESS, payload: res.data.requestAgent })
  } else if (res?.errors) {
    yield put({ type: REQUEST_AGENT_FAILURE, errors: res.errors })
    yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
  }

  yield put({ type: CLEAR_AGENT_FLAG })
}

function* getSalesEngineByAgentData(action) {
  const res = yield getSalesEngineByAgentApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: GET_SALES_ENGINE_BY_AGENT_SUCCESS, payload: res.data })
    yield put({ type: SALES_ENGINE_DASHBOARD_VISIT })
  } else {
    yield put({ type: GET_SALES_ENGINE_BY_AGENT_FAILURE, errors: res.errors })
  }
  yield put({ type: CLEAR_SALES_ENGINE_DASHBOARD_FLAG })
}

function* getSalesEngineDocumentLinks(action) {
  const res = yield getSalesEngineDocumentLinksApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: GET_DOCUMENT_LINKS_SUCCESS, payload: res.data.generateAgreementAndSurvey })
  } else {
    yield put({ type: GET_DOCUMENT_LINKS_FAILURE, errors: res.errors })
  }
}

function* boatShipmentLocation(action) {
  const res = yield boatShipmentLocationApi(action.payload)

  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({ type: ADD_BOAT_SHIPMENT_LOCATION_SUCCESS, payload: res.data.addBoatShipmentLocation })
  } else {
    yield put({ type: ADD_BOAT_SHIPMENT_LOCATION_FAILURE, errors: res.errors })
  }
  yield put({ type: CLEAR_SALES_ENGINE_SHIPMENT_FLAG })
}

function* getCostEstimateData() {
  try {
    const res = yield getCostEstimateApi()
    yield put({ type: GET_COST_ESTIMATE_SUCCESS, payload: res.data.getCostEstimate })
  } catch (error) {
    yield put({ type: GET_COST_ESTIMATE_FAILURE, error })
  }
}

function* startShipmentData(action) {
  try {
    const res = yield startShipmentApi(action.payload)
    yield put({ type: START_SHIPMENT_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: START_SHIPMENT_FAILURE, error })
  }
  yield put({ type: CLEAR_SALES_ENGINE_SHIPPER_DASHBOARD_FLAG })
}

function* completeShipmentData(action) {
  try {
    const res = yield completeShipmentApi(action.payload)
    yield put({ type: COMPLETE_SHIPMENT_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: COMPLETE_SHIPMENT_FAILURE, error })
  }
}

function* createShipperDocumentData(action) {
  try {
    const res = yield createShipperDocumentApi(action.payload)
    if (res?.data?.createShipperDocument) {
      yield put({ type: ADD_SHIPPER_DOCUMENTS_SUCCESS, payload: res.data.createShipperDocument })
    } else if (res?.errors) {
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
      yield put({ type: ADD_SHIPPER_DOCUMENTS_FAILURE, errors: res.errors })
    }
  } catch (error) {
    yield put({ type: ADD_SHIPPER_DOCUMENTS_FAILURE, error })
  }
}

function* discardAgentData(action) {
  try {
    const res = yield discardAgentApi(action.payload)
    if (action.managePageRequest) {
      yield put({
        type: NOTIFICATION_MESSAGE,
        payload: notificationEnum.AGENT_DECLINE_REQUEST,
      })
    }
    yield put({ type: DISCARD_AGENT_SUCCESS, payload: res.data.discardAgentProcess })
  } catch (error) {
    yield put({ type: DISCARD_AGENT_FAILURE, error })
  }
  yield put({ type: CLEAR_DISCARD_AGENT_FLAG })
}

function* getSalesEngineAgentsData(action) {
  try {
    const res = yield getSalesEngineAgentsApi(action.payload)
    yield put({ type: GET_SALES_ENGINE_AGENTS_SUCCESS, payload: res.data.getSalesEngineAgents })
  } catch (error) {
    yield put({ type: GET_SALES_ENGINE_AGENTS_FAILURE, error })
  }
}

function* stopSalesEngineData(action) {
  try {
    const res = yield stopSalesEngineApi(action.payload)
    yield put({ type: STOP_SALES_ENGINE_PROCESS_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: STOP_SALES_ENGINE_PROCESS_FAILURE, error })
  }
}

function* paymentReceiptData(action) {
  try {
    const res = yield paymentReceiptApi(action.payload)
    yield put({ type: PRINT_PAYMENT_RECEIPT_SUCCESS, payload: res.data.getPaymentReceipt })
  } catch (error) {
    yield put({ type: PRINT_PAYMENT_RECEIPT_FAILURE, error })
  }
}

function* salesEngineStaticContentData() {
  try {
    const res = yield salesEngineStaticContentApi()
    yield put({ type: GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS, payload: res.data.getAllSalesEngineStaticContent })
  } catch (error) {
    yield put({ type: GET_SALES_ENGINE_STATIC_CONTENT_FAILURE, error })
  }
}

function* getSurveyDocumentData(action) {
  try {
    const res = yield getSurveyDocumentApi()
    yield put({ type: GET_SURVEY_DOCUMENT_DATA_SUCCESS, payload: res.data.getSurveyData })
  } catch (error) {
    yield put({ type: GET_SURVEY_DOCUMENT_DATA_FAILURE, error })
  }
}

function* getSalesEngineByBoatData(action) {
  try {
    const res = yield getSalesEngineByBoatApi(action.payload)
    yield put({ type: GET_SALES_ENGINE_BY_BOAT_SUCCESS, payload: res.data.getSalesEngineByBoat })
  } catch (error) {
    yield put({ type: GET_SALES_ENGINE_BY_BOAT_FAILURE, error })
  }
}

function* buyerReviewSurveyReportData(action) {
  try {
    const res = yield buyerReviewSurveyReportApi(action.payload)
    yield put({ type: BUYER_REVIEW_SURVEY_REPORT_SUCCESS, payload: res.data.buyerReviewSurveyorReport })
  } catch (error) {
    yield put({ type: BUYER_REVIEW_SURVEY_REPORT_FAILURE, error })
  }
  yield put({ type: CLEAR_REVIEW_REPORT_FLAG })
}

function* updateSurveyorReportData(action) {
  try {
    const res = yield updateSurveyorReportApi(action.payload)
    yield put({ type: UPDATE_SURVEYOR_REPORT_SUCCESS, payload: res.data })
    // yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.SURVEY_REPORT_UPDATE });
  } catch (error) {
    yield put({ type: UPDATE_SURVEYOR_REPORT_FAILURE, error })
  }

  yield put({ type: CLEAR_REPORT_FLAG })
}

function* stepChangeHandlerApi(action) {
  try {
    const res = yield mutation(stepChangeSchema, { input: action.payload })
     if(res.data) {
      yield put({ type: SALES_ENGINE_STEP_CHANGE_SUCCESS, payload: res.data.stepChangeHandler })
    }
    if (res.errors.length && !res.data) {
      yield put({ type: SALES_ENGINE_STEP_CHANGE_FAILURE, error: res.errors[0].message })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: {message: res.errors[0].message} })
    }
  } catch (error) {
    yield put({ type: SALES_ENGINE_STEP_CHANGE_FAILURE, error })
  }
}

function* getDealersSalesEngineData(action) {
  try {
    const res = yield getDealersSalesEngineApi(action.payload)
    yield put({ type: GET_DEALERS_SALES_ENGINE_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: GET_DEALERS_SALES_ENGINE_FAILURE, error })
  }
}

function* getShipperDocumentData(action) {
  try {
    const res = yield getShipperDocumentApi(action.payload)
    yield put({ type: GET_SHIPPER_DOCUMENTS_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: GET_SHIPPER_DOCUMENTS_FAILURE, error })
  }
  yield put({ type: CLEAR_SHIPPER_DOCUMENTS_FLAG })
}

function* updateStockQuantityData(action) {
  try {
    const res = yield updateStockQuantityApi(action.payload)
    if (res?.data) {
      yield put({ type: UPDATE_STOCK_QUANTITY_SUCCESS, payload: res.data?.updateSalesEngineQuantity })
    } else if (res?.errors) {
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
      yield put({ type: UPDATE_STOCK_QUANTITY_FAILURE, errors: res.errors })
    }
  } catch (error) {
    yield put({ type: UPDATE_STOCK_QUANTITY_FAILURE, error })
  }
}

function* updateShipperDocumentsData(action) {
  try {
    const res = yield updateShipperDocumentsApi(action.payload)
    if (res?.data?.updateShipperDocument) {
      yield put({ type: UPDATE_SHIPPER_DOCUMENTS_SUCCESS, payload: res.data.updateShipperDocument })
    } else if (res?.errors) {
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
      yield put({ type: UPDATE_SHIPPER_DOCUMENTS_FAILURE, errors: res.errors })
    }
  } catch (error) {
    yield put({ type: UPDATE_SHIPPER_DOCUMENTS_FAILURE, error })
  }
}

function* salesEngineDashboardVisitData(action) {
  try {
    const res = yield salesEngineDashboardVisitApi(action.payload)
    if (res?.data) {
      yield put({ type: SALES_ENGINE_DASHBOARD_VISIT_SUCCESS, payload: res.data })
    } else if (res?.errors) {
      yield put({ type: SALES_ENGINE_DASHBOARD_VISIT_FAILURE, errors: res.errors })
    }
  } catch (error) {
    yield put({ type: SALES_ENGINE_DASHBOARD_VISIT_FAILURE, error })
  }
}

function* salesEnginePaymentDetailsData(action) {
  try {
    const res = yield salesEnginePaymentDetailsApi(action.payload)
    if (res?.data) {
      yield put({ type: SALES_ENGINE_PAYMENT_DETAILS_SUCCESS, payload: res.data?.getSalesEngineTransactions })
    } else if (res?.errors) {
      yield put({ type: SALES_ENGINE_PAYMENT_DETAILS_FAILURE, errors: res.errors })
    }
  } catch (error) {
    yield put({ type: SALES_ENGINE_PAYMENT_DETAILS_FAILURE, error })
  }
}

function* getCommissionsData(action) {
  try {
    const res = yield getCommissionsAPI(action.payload)
    yield put({ type: GET_COMMISSIONS_SUCCESS, payload: res.data?.getCommissions })
  } catch {
    yield put({ type: GET_COMMISSIONS_FAILURE, payload: 'Cannot get commission!' })
  }
}

function* getBuyerSalesEnginesSaga() {
  yield takeLatest(GET_SALES_ENGINE_BY_BUYER, getBuyerSalesEngines)
}

function* getSellerSalesEnginesSaga() {
  yield takeLatest(GET_SALES_ENGINE_BY_SELLER, getSellerSalesEngines)
}

function* getSurveyorSalesEnginesSaga() {
  yield takeLatest(GET_SALES_ENGINE_BY_SURVEYOR, getSurveyorSalesEngines)
}

function* getShipperSalesEnginesSaga() {
  yield takeLatest(GET_SALES_ENGINE_BY_SHIPPER, getShipperSalesEngines)
}

function* getSingleSalesEngineSaga() {
  yield takeLatest(GET_SINGLE_SALES_ENGINE, getSingleSalesEngine)
}

function* addSalesEngineSaga() {
  yield takeLatest(CREATE_SALES_ENGINE, addSalesEngine)
}

function* assignSalesEngineSurveyorSaga() {
  yield takeLatest(ASSIGN_SURVEYOR, assignSalesEngineSurveyor)
}

function* declineSalesEngineSurveyorSaga() {
  yield takeLatest(DECLINE_SURVEYOR, declineSalesEngineSurveyor)
}

function* paymentSaga() {
  yield takeLatest(PAYMENT_REQUEST, paymentData)
}

function* salesEngineAssignShipperSaga() {
  yield takeLatest(SALES_ENGINE_ASSIGN_SHIPPER_REQUEST, salesEngineAssignShipperData)
}
function* getAllAgreementsContentsSaga() {
  yield takeLatest(GET_AGREEMENTS_CONTENTS, getAllAgreementsContents)
}

function* checkAgreementsSaga() {
  yield takeLatest(CHECK_AGREEMENT, checkAgreements)
}

function* salesEngineSurveyorAcceptBuyerSaga() {
  yield takeLatest(SALES_ENGINE_SURVEYOR_ACCEPT_BUYER, salesEngineSurveyorAcceptBuyerData)
}

function* salesEngineSurveyorDeclineBuyerSaga() {
  yield takeLatest(SALES_ENGINE_SURVEYOR_DECLINE_BUYER, salesEngineSurveyorDeclineBuyerData)
}
function* salesEngineSurveyorCreateReportSaga() {
  yield takeLatest(CREATE_SURVEYOR_REPORT, salesEngineSurveyorCreateReportData)
}
function* getSurveyorReportBySalesEngineSaga() {
  yield takeLatest(GET_SURVEYOR_REPORT, getSurveyorReportBySalesEngineData)
}

function* salesEngineShipperAcceptShipmentRequestSaga() {
  yield takeLatest(SHIPPER_ACCEPT_SHIPMENT_REQUEST, salesEngineShipperAcceptShipmentRequest)
}

function* salesEngineShipperDeclineShipmentRequestSaga() {
  yield takeLatest(SHIPPER_DECLINE_SHIPMENT_REQUEST, salesEngineShipperDeclineShipmentRequest)
}

function* addShipmentProposalSaga() {
  yield takeLatest(ADD_SHIPMENT_PROPOSAL, addShipmentProposal)
}

function* salesEngineGetAllShipmentProposalSaga() {
  yield takeLatest(GET_ALL_SHIPMENT_PROPOSAL, salesEngineGetAllShipmentProposal)
}

function* addNegotiablePriceSaga() {
  yield takeLatest(ADD_NEGOTIABLE_PRICE, addNegotiablePriceData)
}

function* addSurveyOptionSaga() {
  yield takeLatest(ADD_SURVEY_OPTION, addSurveyOption)
}

function* skipShipmentProcessSaga() {
  yield takeLatest(SKIP_SHIPMENT, skipShipmentProcess)
}

function* assignAgentSaga() {
  yield takeLatest(ASSIGN_AGENT, assignAgentData)
}

function* requestAgentSaga() {
  yield takeLatest(REQUEST_AGENT, requestAgentData)
}

function* getSalesEngineByAgentSaga() {
  yield takeLatest(GET_SALES_ENGINE_BY_AGENT, getSalesEngineByAgentData)
}

function* getSalesEngineDocumentLinksSaga() {
  yield takeLatest(GET_DOCUMENT_LINKS, getSalesEngineDocumentLinks)
}

function* boatShipmentLocationSaga() {
  yield takeLatest(ADD_BOAT_SHIPMENT_LOCATION, boatShipmentLocation)
}

function* getCostEstimateSaga() {
  yield takeLatest(GET_COST_ESTIMATE, getCostEstimateData)
}

function* startShipmentSaga() {
  yield takeLatest(START_SHIPMENT, startShipmentData)
}

function* completeShipmentSaga() {
  yield takeLatest(COMPLETE_SHIPMENT, completeShipmentData)
}

function* createShipperDocumentSaga() {
  yield takeLatest(ADD_SHIPPER_DOCUMENTS, createShipperDocumentData)
}

function* discardAgentSaga() {
  yield takeLatest(DISCARD_AGENT, discardAgentData)
}

function* getSalesEngineAgentsSaga() {
  yield takeLatest(GET_SALES_ENGINE_AGENTS, getSalesEngineAgentsData)
}

function* stopSalesEngineSaga() {
  yield takeLatest(STOP_SALES_ENGINE_PROCESS, stopSalesEngineData)
}

function* paymentReceiptSaga() {
  yield takeLatest(PRINT_PAYMENT_RECEIPT, paymentReceiptData)
}

function* salesEngineStaticContentSaga() {
  yield takeLatest(GET_SALES_ENGINE_STATIC_CONTENT, salesEngineStaticContentData)
}

function* getSurveyDocumentSaga() {
  yield takeLatest(GET_SURVEY_DOCUMENT_DATA, getSurveyDocumentData)
}

function* getSalesEngineByBoatSaga() {
  yield takeLatest(GET_SALES_ENGINE_BY_BOAT, getSalesEngineByBoatData)
}

function* buyerReviewSurveyReportSaga() {
  yield takeLatest(BUYER_REVIEW_SURVEY_REPORT, buyerReviewSurveyReportData)
}

function* updateSurveyorReportSaga() {
  yield takeLatest(UPDATE_SURVEYOR_REPORT, updateSurveyorReportData)
}

function* stepChangeHandlerSaga() {
  yield takeLatest(SALES_ENGINE_STEP_CHANGE, stepChangeHandlerApi)
}

function* getDealersSalesEngineSaga() {
  yield takeLatest(GET_DEALERS_SALES_ENGINE, getDealersSalesEngineData)
}

function* getShipperDocumentSaga() {
  yield takeLatest(GET_SHIPPER_DOCUMENTS, getShipperDocumentData)
}

function* updateStockQuantitySaga() {
  yield takeLatest(UPDATE_STOCK_QUANTITY, updateStockQuantityData)
}

function* updateShipperDocumentsSaga() {
  yield takeLatest(UPDATE_SHIPPER_DOCUMENTS, updateShipperDocumentsData)
}

function* salesEngineDashboardVisitSaga() {
  yield takeLatest(SALES_ENGINE_DASHBOARD_VISIT, salesEngineDashboardVisitData)
}

function* salesEnginePaymentDetailsSaga() {
  yield takeLatest(SALES_ENGINE_PAYMENT_DETAILS, salesEnginePaymentDetailsData)
}

function* getCommissionsSaga() {
  yield takeLatest(GET_COMMISSIONS, getCommissionsData)
}

export default function* salesEngineSaga() {
  yield all([
    getBuyerSalesEnginesSaga(),
    getSellerSalesEnginesSaga(),
    getSurveyorSalesEnginesSaga(),
    getShipperSalesEnginesSaga(),
    getSingleSalesEngineSaga(),
    addSalesEngineSaga(),
    assignSalesEngineSurveyorSaga(),
    declineSalesEngineSurveyorSaga(),
    paymentSaga(),
    salesEngineAssignShipperSaga(),
    getAllAgreementsContentsSaga(),
    checkAgreementsSaga(),
    salesEngineSurveyorAcceptBuyerSaga(),
    salesEngineSurveyorDeclineBuyerSaga(),
    salesEngineSurveyorCreateReportSaga(),
    getSurveyorReportBySalesEngineSaga(),
    salesEngineShipperAcceptShipmentRequestSaga(),
    salesEngineShipperDeclineShipmentRequestSaga(),
    addShipmentProposalSaga(),
    salesEngineGetAllShipmentProposalSaga(),
    addNegotiablePriceSaga(),
    addSurveyOptionSaga(),
    skipShipmentProcessSaga(),
    assignAgentSaga(),
    requestAgentSaga(),
    getSalesEngineByAgentSaga(),
    getSalesEngineDocumentLinksSaga(),
    boatShipmentLocationSaga(),
    getCostEstimateSaga(),
    startShipmentSaga(),
    completeShipmentSaga(),
    createShipperDocumentSaga(),
    discardAgentSaga(),
    getSalesEngineAgentsSaga(),
    stopSalesEngineSaga(),
    paymentReceiptSaga(),
    salesEngineStaticContentSaga(),
    getSurveyDocumentSaga(),
    getSalesEngineByBoatSaga(),
    buyerReviewSurveyReportSaga(),
    updateSurveyorReportSaga(),
    stepChangeHandlerSaga(),
    getDealersSalesEngineSaga(),
    getShipperDocumentSaga(),
    updateStockQuantitySaga(),
    updateShipperDocumentsSaga(),
    salesEngineDashboardVisitSaga(),
    salesEnginePaymentDetailsSaga(),
    getCommissionsSaga()
  ])
}
