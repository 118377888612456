import React from 'react'

import UserContext from '../../UserContext'
import { dimensionAspectRatio } from '../../util/utilFunctions'
import { FaRegThumbsUp, FaRegEye } from 'react-icons/fa'
import Truncate from 'react-truncate'

import { BoatMediaMainStyle, BoatMediaWidthStyle } from '../styleComponent/styleComponent'
import { getRatio, getHeightRatio, getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { dimension, defaultImage } from '../../util/enums/enums'
import { cityCountryNameFormatter, getAddress, redirectToUserProfile } from '../../helpers/jsxHelper'
import ReadMore from '../helper/truncate'
import { displayDefaultValue, displayDefaultNumericValue, nameFormatter, formatCash } from '../../helpers/string'
import { viewArticles } from '../../helpers/boatHelper'
import { useHistory } from 'react-router'

// const history = useHistory()
export class BoatMediaArticle extends React.Component {
  state = {
    height: dimension.boatMediaMainArticles.height,
    width: dimension.boatMediaMainArticles.width,
    gridClassName: `grid--new--${dimension['boatMediaMainArticles'].divide}`,
    margin: 15,
  }

  static contextType = UserContext

  widthZoom = () => {
    const { isBottomRating, isBoatInner, isFeature, isUserProfile } = this.props
    const finalKey = this.props.isShowBoatMedia
      ? 'mediaArticles'
      : this.props.isMediaBoat
      ? 'boatMediaMainArticles'
      : this.props.isBoatMedia
      ? 'boatMediaArticle'
      : 'boatMediaArticle1'
    const width = getRatioZoomResponsive(dimension, finalKey, '.zoom-container')
    const height = getHeightZoomResponsive(dimension, finalKey, '.zoom-container')

    this.setState({ width: width.width, gridClassName: width.className, margin: width.margin, height })
  }
  componentDidMount() {
    this.widthZoom()
    window.addEventListener('resize', this.widthZoom)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.widthZoom)
  }

  createMarkup = text => {
    return { __html: text }
  }

  imageError = item => {
    this.setState({
      [item]: true,
    })
  }

  render() {
    const { value, onClick, className } = this.props
    const { height, width, gridClassName, margin } = this.state

    const { user } = value || {}
    const { address, image, firstName, lastName } = user || {}

    const {
      commonAddress: { city, country },
    } = getAddress(address)
    const createMarkup = text => {
      return { __html: text }
    }

    return (
      <>
        <div
          style={{ width: width, marginRight: margin }}
          className={`grid-layout-boat-view-all ${gridClassName} ${className}`}
        >
          <div className="boat-media-aricle-user mt-2 d-flex align-items-center boat--media--artical--user mb-2 font-16">
            <div className="boat-media-aricle-user-img boat-media-article-img-profile rounded-circle">
              <img
                src={image?.url || defaultImage}
                alt="article user"
                className="rounded-circle"
                style={{ width: '1.5em', height: '1.5em' }}
                onClick={() => redirectToUserProfile(value.user, this.context.history)}
              />
            </div>
            <div className="boat-media-aricle-user-des d-flex flex-column ml-2" style={{ flex: 1 }}>
              <span className="font-weight-400 aricle-user-title gray-dark">{nameFormatter([firstName, lastName])}</span>
              {/* <span className="font-weight-400 aricle-user-title light-silver">{cityCountryNameFormatter(city, country)}</span> */}
            </div>
            <div className="d-flex align-items-center gray-light media--artical--like--div">
              <img
                loading="lazy"
                src={require('../../assets/images/user/heart.png')}
                alt="like-icon"
                className="media--like--icons"
              />
              <span className="ml-2">{formatCash(value?.likes?.length || 0)}</span>
            </div>
          </div>
          <img
            alt={value.id}
            height={height}
            width={width}
            className="BoatMediaMainStyle img-boat-media-tag"
            src={value?.files?.[0]?.url}
            onClick={() => viewArticles(value.id, value.title)}
          />
          <BoatMediaWidthStyle className="boat-media-article-content" bgWidth={width}>
            <div className="mt-2 d-flex align-items-center gray-light font-16">
              <img
                loading="lazy"
                src={require('../../assets/images/user/reading.png')}
                alt="view-icon"
                className="media--view--icons"
              />
              <span className="ml-2">{displayDefaultNumericValue(value?.pageVisits?.length)}</span>
            </div>

            <div className="boat-media-article-desc mt-3 d-flex flex-column boat--media--article--spacing">
              <span className="article-desc-title boat--media--article--div--title">{value.title}</span>

              <p className="light-silver mt-2 mb-2 article-desc boat--media--article--desc">
                <div className="article--desc--style">
                  {/* <span>
                    {`${value.description.substring(0, 150)}...`}
                    <a href="#" onClick={(e) => {
                      e.preventDefault();
                      viewArticles(value.id)
                    }}>
                      Read more
                    </a>
                  </span> */}
                  <ReadMore className="text-justify" lines={2} link={true} onClick={() => viewArticles(value.id, value.title)}>
                    <span dangerouslySetInnerHTML={createMarkup(displayDefaultValue(value.shortDescription))}></span>
                  </ReadMore>
                </div>
              </p>

              <div className="d-flex mt-1 mb-1 media--artical--view--like">

              </div>
            </div>
          </BoatMediaWidthStyle>
        </div>
      </>
    )
  }
}
