import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ErrorIcon from '@material-ui/icons/Error'

import { ButtonComponent } from '../../../components/form/Button'
import { getSalesEngineBySeller, getSingleSalesEngine, getSalesEngineByBoat } from '../../../redux/actions/salesEngineAction'
import { TableCard, Field, DashboardLayout } from '../../../components'

import '../../../styles/manageDashboardTableResponsive.scss'
import { CommonTooltip } from '../../../components/CommonTooltip'
import { formattedDate } from '../../../helpers/dateTimeHelper'
import { pagination, userRoles } from '../../../util/enums/enums'
import { nameFormatter } from '../../../helpers/string'
import { searchInDashboard, clearSearchDashboard } from '../../../redux/actions'
import { getPagesValue } from '../../../helpers/jsxHelper'
import { clear_getStaticPages, getStaticPages } from '../../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'

class SalesEngineByBoat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      boatId: '',
      columns: [
        {
          Header: (
            <Fragment>
              <span className="hash--sign">#</span>{' '}
              <Field
                type="checkbox"
                // Note temporary null @ghanshyam
                onChangeText={null}
              />
            </Fragment>
          ),
          accessor: 'id',
          headerClassName: 'sales-engine-serial-no',
          className: 'sales-engine-serial-no',
          Cell: ({ value, index }) => (
            <Fragment>
              <span className="sr--no">{index + 1}</span>
              <Field type="checkbox" onChangeText={e => this.handleCheck(e, value)} />
            </Fragment>
          ),
        },
        {
          Header: 'Buyer Name',
          accessor: 'buyer',
          Cell: ({ value }) => nameFormatter([value?.firstName, value?.lastName]),
        },
        {
          Header: 'Created Date',
          accessor: 'createdAt',
          Cell: ({ value }) => formattedDate(value),
        },
        {
          Header: 'Status',
          accessor: 'buySellProcess',
          Cell: ({ value }) => <span className="sales-engine-status">{value?.[value.length - 1]}</span>,
        },
        {
          Header: 'Sales Engine',
          accessor: 'id',
          headerClassName: 'sales--engine--view',
          Cell: data => (
            <div className="d-flex flex-row justify-content-around view--with--new--btn">
              <div className="request--view--btn--div">
                <ButtonComponent
                  type="button"
                  variant="fill"
                  onClick={() => props.getSingleSalesEngine({ id: data.value })}
                  className="sales-engine--view"
                >
                  View
                </ButtonComponent>
              </div>
              <div className="request--new--btn--div">
                {!data?.original?.isViewedBySeller && (
                  <div className="new--request--icon">
                    <img loading="lazy" src={require('../../../assets/images/salesEngine/new1.svg')} alt="Icon" />
                  </div>
                )}
              </div>
            </div>
          ),
        },
      ],
      salesEngineIds: [],
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const { match, getSalesEngineSuccess, history, salesEngine } = nextProps
    const { params } = match && match

    if (getSalesEngineSuccess && salesEngine && salesEngine.id) {
      history && history.push(`/sales-engine-process/${salesEngine.id}`)
    }

    if (params && params.hasOwnProperty('boatId') && params.boatId) {
      return {
        boatId: params.boatId,
      }
    }

    return null
  }

  componentDidMount() {
    const { getSalesEngineByBoat } = this.props
    const { boatId } = this.state
    getSalesEngineByBoat({ boatId, limit: pagination.PAGE_RECORD_LIMIT, page: getPagesValue() })
    this.fetchHelpModalContent()
  }

  componentDidUpdate(prevProps, prevState) {
    const prevRole = get(prevProps.currentUser, 'role.aliasName')
    const currRole = get(this.props.currentUser, 'role.aliasName')

    if (prevRole !== currRole) {
      this.fetchHelpModalContent()
    }

    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }
  }

  fetchHelpModalContent = () => {
    const role = get(this.props.currentUser, 'role.aliasName') 
    if (!role) return
    this.props.getStaticPages({ 
      slug: role === userRoles.BOAT_MANUFACTURER 
      ? 'do-you-need-help-manufacturer-my-requests-page-2-247' 
      : role === userRoles.BOAT_OWNER
      ? 'do-you-need-help-boat-owner-my-requests-page-2-494'
      : role === userRoles.BROKER_AND_DEALER
      ? 'do-you-need-help-broker-and-dealer-my-requests-page-2-572'
      : ''
    })
  }

  handleCheck(e, salesEngineId) {
    this.setState(state => ({
      salesEngineIds: state.salesEngineIds.includes(salesEngineId)
        ? state.salesEngineIds?.filter(c => c !== salesEngineId)
        : [...state.salesEngineIds, salesEngineId],
    }))
  }

  renderTitle = () => {
    const { salesEnginesBoats } = this.props
    const [first, ...rest] = salesEnginesBoats
    const { adId, boatName, boatType } = first.boat
    return (
      <div className="d-flex flex-column">
        <div className="my--request--inner--title">
          <span>My Requests</span>
        </div>
        {(adId || boatName || boatType?.name) && (
          <div className="request--common--detail--section">
            {adId && (
              <div className="request--common--detail--div">
                <span className="request--common--detail--title">
                  Ad ID: <span className="request--common--detail--data">{adId}</span>
                </span>
              </div>
            )}
            {boatName && (
              <div className="request--common--detail--div">
                <span className="request--common--detail--title">
                  Model Name/No.: <span className="request--common--detail--data">{boatName}</span>
                </span>
              </div>
            )}
            {boatType?.name && (
              <div className="request--common--detail--div">
                <span className="request--common--detail--title">
                  Boat Type: <span className="request--common--detail--data">{boatType?.name}</span>
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  render() {
    const { columns, boatId } = this.state
    const {
      salesEnginesBoats,
      isLoading,
      totalSalesEnginesBoats,
      getSalesEngineByBoat,
      searchInDashboard,
      indexSearchData,
      totalSearchedData,
      clearSearchDashboard,
      isSearched,
    } = this.props
    return (
      <>
        <DashboardLayout className="sales-engin-layout-design ">
          {columns && (
            <div className="manage-dashboard-table manage--salesengine-table salesengine--by--boat dashboard--table--main--section dashboard--index--table">
              <TableCard
                isSearch
                title={salesEnginesBoats.length > 0 && salesEnginesBoats?.[0]?.boat && this.renderTitle()}
                columns={columns}
                loading={isLoading}
                isSalesEngine
                data={isSearched ? indexSearchData : salesEnginesBoats}
                className="manage--boat--table"
                noButton
                total={isSearched ? totalSearchedData : totalSalesEnginesBoats}
                action={getSalesEngineByBoat}
                clearSearchDashboard={clearSearchDashboard}
                searchParams={{ boatId }}
                helpModalContent={get(this.props.staticPages, 'data[0].pageContent', '')}
              />
            </div>
          )}
        </DashboardLayout>
      </>
    )
  }
}

const mapStateToProps = state => ({
  salesEnginesBoats: state.salesEngineReducer?.salesEnginesBoats,
  totalSalesEnginesBoats: state.salesEngineReducer?.totalSalesEnginesBoats,
  getSalesEngineSuccess: state.salesEngineReducer?.getSalesEngineSuccess,
  isLoading: state.salesEngineReducer?.isLoading,
  salesEngine: state.salesEngineReducer?.salesEngine,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
  isSearched: state?.dashboardReducer?.isSearched,
  staticPages: state.pageInfoByTypeReducer.staticPages,
  currentUser: state.loginReducer.currentUser,
})

const mapDispatchToProps = dispatch => ({
  getSingleSalesEngine: data => dispatch(getSingleSalesEngine(data)),
  getSalesEngineByBoat: data => dispatch(getSalesEngineByBoat(data)),
  searchInDashboard: data => dispatch(searchInDashboard(data)),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEngineByBoat)
