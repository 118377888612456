import { put, takeLatest, takeEvery, all } from 'redux-saga/effects'
import {
  CREATE_RENT_BOAT,
  CREATE_RENT_BOAT_SUCCESS,
  CREATE_RENT_BOAT_FAILURE,
  GET_BOAT_RENT_LOOKUPS_FAILURE,
  GET_BOAT_RENT_LOOKUPS_SUCCESS,
  GET_BOAT_RENT_LOOKUPS,
  GET_USER_BOAT_RENTS,
  GET_USER_BOAT_RENTS_SUCCESS,
  GET_USER_BOAT_RENTS_FAILURE,
  DELETE_BOAT_RENT,
  DELETE_BOAT_RENT_SUCCESS,
  DELETE_BOAT_RENT_FAILURE,
  GET_RENT_CATEGORY_WISE_BOATS,
  GET_RENT_CATEGORY_WISE_BOATS_SUCCESS,
  GET_RENT_CATEGORY_WISE_BOATS_FAILURE,
  GET_RENTS_INNER_BOAT_FAILURE,
  GET_RENTS_INNER_BOAT_SUCCESS,
  GET_RENTS_INNER_BOAT,
  GET_ALL_BOAT_RENT_TYPES_SUCCESS,
  GET_ALL_BOAT_RENT_TYPES_FAILURE,
  GET_ALL_BOAT_RENT_TYPES,
  GET_ALL_RENT_TYPES_SUCCESS,
  GET_ALL_RENT_TYPES_FAILURE,
  GET_ALL_RENT_TYPES,
  UPDATE_RENT_BOAT,
  UPDATE_RENT_BOAT_SUCCESS,
  UPDATE_RENT_BOAT_FAILURE,
  ERROR_MESSAGE_SHOW,
  GET_RENT_TRIP_CITY_WISE_SUCCESS,
  GET_RENT_TRIP_CITY_WISE_FAILURE,
  GET_RENT_TRIP_CITY_WISE,
  CHANGE_RENT_BOAT_STATUS,
  CHANGE_RENT_BOAT_STATUS_SUCCESS,
  CHANGE_RENT_BOAT_STATUS_FAILURE,
  GET_RENT_TICKET,
  GET_RENT_TICKET_SUCCESS,
  GET_RENT_TICKET_FAILURE,
  CANCEL_RENT_BOOKING,
  CANCEL_RENT_BOOKING_SUCCESS,
  CANCEL_RENT_BOOKING_FAILURE,
  UPDATE_RENT_BOAT_TRIP_DETAILS,
  UPDATE_RENT_BOAT_TRIP_DETAILS__SUCCESS,
  UPDATE_RENT_BOAT_TRIP_DETAILS__FAILURE,
  GET_RENT_BOAT_TRIP_DETAILS,
  GET_RENT_BOAT_TRIP_DETAILS__SUCCESS,
  GET_RENT_BOAT_TRIP_DETAILS__FAILURE,
  GET_RENT_PAYOUTS,
  GET_RENT_PAYOUTS_SUCCESS,
  GET_RENT_PAYOUTS_FAILURE,
} from '../actionTypes'
import { graphqlClient } from '../../helpers/graphqlClient'
import {
  createRentBoat,
  getBoatRentLookUps,
  getUserBoatRent,
  deleteBoatRent,
  rentCategoryWiseBoats,
  getRentBoatInnerQuery,
  getAllRentTypes,
  updateRentBoat,
  getSimilarBoatRents,
  updateTripDetails,
  boatRentChangeStatus,
  getRentTicketSchema,
  cancelRentBookingQuery,
  getRentPayoutsQuery,
  getRentBoatTripDetails_query,
  updateRentBoatTripDetails_query,
} from '../../graphql/boatRentSchema'
import { getAllRentTripType } from '../../graphql/rentSchema'
import { get } from 'lodash'

function createRentBoatApi(data) {
  const input = data.payload
  return graphqlClient
    .mutate({
      mutation: createRentBoat,
      variables: {
        input: input,
      },
    })
    .then(res => {
      return res
    })
    .catch(err => {
      return err.networkError.result
    })
}
function updateRentBoatApi(data) {
  const input = data.payload
  return graphqlClient
    .mutate({
      mutation: updateRentBoat,
      variables: {
        input: input,
      },
    })
    .then(res => {
      return res
    })
    .catch(err => {
      return err.networkError.result
    })
}

function getBoatRentLookUpApi(action) {
  return graphqlClient
    .query({
      query: getBoatRentLookUps,
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(err => {
      throw err
    })
}

function getUserBoatRentsApi(data) {
  return graphqlClient
    .query({
      query: getUserBoatRent,
      variables: { page: data.page, limit: data.limit },
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(err => {
      throw err
    })
}

function getAllRentTypesApi(data) {
  return graphqlClient
    .query({
      query: getAllRentTypes,
      variables: data,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => {
      throw error
    })
}

function deleteBoatRentApi(id) {
  return graphqlClient
    .mutate({
      mutation: deleteBoatRent,
      variables: { id },
    })
    .then(res => {
      return res
    })
    .catch(error => {
      throw error
    })
}

function getRentCategoryWiseBoatApi(input) {
  return graphqlClient
    .query({
      query: rentCategoryWiseBoats,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function getRentBoatInnerApi(data) {
  return graphqlClient
    .query({
      query: getRentBoatInnerQuery,
      variables: data,
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(err => {
      throw err
    })
}

function getRentTripCityWiseApi(data) {
  return graphqlClient
    .query({
      query: getSimilarBoatRents,
      variables: {
        id: data.id,
      },
    })
    .then(res => res)
    .catch(error => error)
}

function updateRentBoatTripDetails_api(data) {
  return graphqlClient.mutate({
    mutation: updateRentBoatTripDetails_query,
    variables: {
      id: data.id,
      input: data.data,
    },
  })
}

function* getRentTripCityWiseData(action) {
  try {
    const res = yield getRentTripCityWiseApi(action.payload)
    yield put({ type: GET_RENT_TRIP_CITY_WISE_SUCCESS, payload: res.data.getSimilarBoatRents })
  } catch (error) {
    yield put({ type: GET_RENT_TRIP_CITY_WISE_FAILURE, error })
  }
}

function* createRentBoatData(action) {
  try {
    const res = yield createRentBoatApi(action)
    if (get(res, 'data.createRentBoat', false)) {
      yield put({
        type: CREATE_RENT_BOAT_SUCCESS,
        payload: res.data.createRentBoat,
      })
    } else {
      yield put({
        type: CREATE_RENT_BOAT_FAILURE,
        payload:
          get(res, 'errors[0].message', '') || get(res, 'networkError.result.errors[0].message', '') || 'Cannot create boat!',
      })
    }
  } catch (e) {
    yield put({
      type: CREATE_RENT_BOAT_FAILURE,
      payload: get(e, 'errors[0].message', '') || get(e, 'networkError.result.errors[0].message', '') || 'Cannot create boat!',
    })
  }
}

function* updateRentBoatData(action) {
  try {
    const res = yield updateRentBoatApi(action)

    if (res.errors && res.errors.length) {
      yield put({ type: UPDATE_RENT_BOAT_FAILURE, res })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    } else {
      yield put({ type: UPDATE_RENT_BOAT_SUCCESS, payload: res })
    }
  } catch (e) {
    yield put({ type: UPDATE_RENT_BOAT_FAILURE, e })
  }
}

function* getBoatRentLookUpsData(action) {
  try {
    const data = yield getBoatRentLookUpApi(action)
    yield put({ type: GET_BOAT_RENT_LOOKUPS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_BOAT_RENT_LOOKUPS_FAILURE, e })
  }
}

function* getUserBoatRentsData(action) {
  try {
    const data = yield getUserBoatRentsApi(action.payload)
    yield put({ type: GET_USER_BOAT_RENTS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_USER_BOAT_RENTS_FAILURE, e })
  }
}

function* getAllRentTypesData(action) {
  try {
    const data = yield getAllRentTypesApi(action.payload)
    yield put({ type: GET_ALL_RENT_TYPES_SUCCESS, payload: data, isAdmin: action.payload.isAdmin })
  } catch (e) {
    yield put({ type: GET_ALL_RENT_TYPES_FAILURE, e })
  }
}

function* deleteBoatRentData(action) {
  try {
    const res = yield deleteBoatRentApi(action.payload)
    yield put({ type: DELETE_BOAT_RENT_SUCCESS, payload: res.data.deleteRentBoat })
  } catch (e) {
    yield put({ type: DELETE_BOAT_RENT_FAILURE, e })
  }
}

function* getRentCategoryWiseBoat(action) {
  try {
    const res = yield getRentCategoryWiseBoatApi(action.payload)
    yield put({
      type: GET_RENT_CATEGORY_WISE_BOATS_SUCCESS,
      payload: res.data.getRentBoatsByTripType,
    })
  } catch (error) {
    yield put({ type: GET_RENT_CATEGORY_WISE_BOATS_FAILURE, error })
  }
}

function* getRentBoatInnerData(action) {
  try {
    const data = yield getRentBoatInnerApi(action.payload)
    yield put({ type: GET_RENTS_INNER_BOAT_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_RENTS_INNER_BOAT_FAILURE, e })
  }
}

function* updateRentBoatTripDetails_data(action) {
  try {
    const res = yield updateRentBoatTripDetails_api(action.payload)

    if (get(res, 'data.updateRentBoatTripDetails', false)) {
      yield put({
        type: UPDATE_RENT_BOAT_TRIP_DETAILS__SUCCESS,
        payload: res.data.updateRentBoatTripDetails,
      })
    } else {
      yield put({
        type: UPDATE_RENT_BOAT_TRIP_DETAILS__FAILURE,
        payload:
          get(res, 'errors[0].message', '') ||
          get(res, 'networkError.result.errors[0].message', '') ||
          'Error while updating trip details',
      })
    }
  } catch (e) {
    yield put({
      type: UPDATE_RENT_BOAT_TRIP_DETAILS__FAILURE,
      payload:
        get(e, 'errors[0].message', '') ||
        get(e, 'networkError.result.errors[0].message', '') ||
        'Error while updating trip details',
    })
  }
}

function* createRentBoatSaga() {
  yield takeLatest(CREATE_RENT_BOAT, createRentBoatData)
}

function* getBoatRentLookUpsSaga() {
  yield takeLatest(GET_BOAT_RENT_LOOKUPS, getBoatRentLookUpsData)
}

function* getUserBoatRentSaga() {
  yield takeLatest(GET_USER_BOAT_RENTS, getUserBoatRentsData)
}

function* getAllRentTypesSaga() {
  yield takeEvery(GET_ALL_RENT_TYPES, getAllRentTypesData)
}

function* deleteBoatRentSaga() {
  yield takeLatest(DELETE_BOAT_RENT, deleteBoatRentData)
}
function* getRentCategoryWiseBoatSaga() {
  yield takeLatest(GET_RENT_CATEGORY_WISE_BOATS, getRentCategoryWiseBoat)
}

function* getRentBoatInner() {
  yield takeLatest(GET_RENTS_INNER_BOAT, getRentBoatInnerData)
}

function* getRentTripCityWiseSaga() {
  yield takeLatest(GET_RENT_TRIP_CITY_WISE, getRentTripCityWiseData)
}

function* updateRentBoatTripDetails_saga() {
  yield takeLatest(UPDATE_RENT_BOAT_TRIP_DETAILS, updateRentBoatTripDetails_data)
}

/**
 * ================================
 * get trip details
 * --------------------------------
 */

function* getRentBoatTripDetails_saga() {
  yield takeLatest(GET_RENT_BOAT_TRIP_DETAILS, getRentBoatTripDetails_data)
}

function* getRentBoatTripDetails_data(action) {
  try {
    const res = yield getRentBoatTripDetails_api(action.payload)

    if (get(res, 'data.getRentBoatTripDetails', null)) {
      yield put({
        type: GET_RENT_BOAT_TRIP_DETAILS__SUCCESS,
        payload: res.data.getRentBoatTripDetails,
      })
    } else {
      yield put({
        type: GET_RENT_BOAT_TRIP_DETAILS__FAILURE,
        payload: get(res, 'errors[0].message', '') || get(res, 'networkError.result.errors[0].message', ''),
      })
    }
  } catch (e) {
    yield put({
      type: GET_RENT_BOAT_TRIP_DETAILS__FAILURE,
      payload: get(e, 'errors[0].message', '') || get(e, 'networkError.result.errors[0].message', ''),
    })
  }
}

function getRentBoatTripDetails_api(input) {
  return graphqlClient.query({
    query: getRentBoatTripDetails_query,
    variables: {
      boatId: input.boatId,
      reqId: new Date().toString(),
    },
  })
}

/**
 * ================================
 */

//get all boat rent types
function getRentBoatTypeApi(data) {
  return graphqlClient
    .query({
      query: getAllRentTripType,
      variables: {
        page: data.page,
        limit: data.limit,
      },
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(err => {
      throw err
    })
}
function changeRentBoatStatusApi(data) {
  return graphqlClient
    .mutate({
      mutation: boatRentChangeStatus,
      variables: {
        value: data.value,
        columnName: data.columnName,
        id: data.id,
      },
    })
    .then(res => {
      return res
    })
    .catch(err => {
      throw err
    })
}

function* getRentBoatTypesData(action) {
  try {
    const data = yield getRentBoatTypeApi(action.payload)
    yield put({ type: GET_ALL_BOAT_RENT_TYPES_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_ALL_BOAT_RENT_TYPES_FAILURE, e })
  }
}

function* changeRentBoatStatusData(action) {
  try {
    const data = yield changeRentBoatStatusApi(action.payload)
    yield put({ type: CHANGE_RENT_BOAT_STATUS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: CHANGE_RENT_BOAT_STATUS_FAILURE, e })
  }
}

function* getRentBoatTypesSaga() {
  yield takeLatest(GET_ALL_BOAT_RENT_TYPES, getRentBoatTypesData)
}

function* updateRentBoatSaga() {
  yield takeLatest(UPDATE_RENT_BOAT, updateRentBoatData)
}

function* changeRentBoatStatusSaga() {
  yield takeLatest(CHANGE_RENT_BOAT_STATUS, changeRentBoatStatusData)
}

// view ticket

function* getRentTicketSaga() {
  yield takeLatest(GET_RENT_TICKET, getRentTicketData)
}

function* getRentTicketData(action) {
  try {
    const data = yield getRentTicketAPI(action.payload)
    if (data.data?.getRentTicket) yield put({ type: GET_RENT_TICKET_SUCCESS, payload: data.data?.getRentTicket })
    else yield put({ type: GET_RENT_TICKET_FAILURE })
  } catch (err) {
    console.log(err)
    yield put({ type: GET_RENT_TICKET_FAILURE })
  }
}

function getRentTicketAPI(bookingId) {
  return graphqlClient.query({
    query: getRentTicketSchema,
    variables: { bookingId },
  })
}

// cancel rent booking

function* cancelRentBookingSaga() {
  yield takeLatest(CANCEL_RENT_BOOKING, cancelRentBookingData)
}

function* cancelRentBookingData(action) {
  try {
    const data = yield cancelRentBookingAPI(action.payload)
    if (data.data?.cancelRentBooking) {
      yield put({
        type: CANCEL_RENT_BOOKING_SUCCESS,
        payload: data.data?.cancelRentBooking,
      })
    } else {
      yield put({
        type: CANCEL_RENT_BOOKING_FAILURE,
        payload: get(data, 'errors[0].message'),
      })
    }
  } catch (err) {
    yield put({
      type: CANCEL_RENT_BOOKING_FAILURE,
      payload: get(err, 'networkError.result.errors[0].message') || 'Cannot cancel Booking!',
    })
  }
}

function cancelRentBookingAPI(bookingId) {
  return graphqlClient.mutate({
    mutation: cancelRentBookingQuery,
    variables: { bookingId },
  })
}

// get rent payouts

function* getRentPayoutsSaga() {
  yield takeLatest(GET_RENT_PAYOUTS, getRentPayoutsData)
}

function* getRentPayoutsData(action) {
  try {
    const data = yield getRentPayoutsAPI(action.payload)
    if (data.data?.getRentPayouts) {
      yield put({
        type: GET_RENT_PAYOUTS_SUCCESS,
        payload: data.data?.getRentPayouts,
      })
    } else {
      yield put({
        type: GET_RENT_PAYOUTS_FAILURE,
        payload: get(data, 'errors[0].message'),
      })
    }
  } catch (err) {
    yield put({
      type: GET_RENT_PAYOUTS_FAILURE,
      payload: get(err, 'networkError.result.errors[0].message') || 'Cannot get your Payouts!',
    })
  }
}

function getRentPayoutsAPI(data) {
  return graphqlClient.mutate({
    mutation: getRentPayoutsQuery,
    variables: data,
  })
}

// export

export default function* boatRentSaga() {
  yield all([
    createRentBoatSaga(),
    getBoatRentLookUpsSaga(),
    getUserBoatRentSaga(),
    deleteBoatRentSaga(),
    getAllRentTypesSaga(),
    getRentCategoryWiseBoatSaga(),
    getRentBoatInner(),
    getRentBoatTypesSaga(),
    getRentBoatTypesSaga(),
    updateRentBoatSaga(),
    getRentTripCityWiseSaga(),
    updateRentBoatTripDetails_saga(),
    changeRentBoatStatusSaga(),
    getRentTicketSaga(),
    cancelRentBookingSaga(),
    getRentBoatTripDetails_saga(),
    getRentPayoutsSaga(),
  ])
}
