import {
  CREATE_ARTICLE,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FAILURE,
  CLEAR_ARTICLE_FLAG,
  GET_USER_ALL_ARTICLES,
  GET_USER_ALL_ARTICLES_SUCCESS,
  GET_USER_ALL_ARTICLES_FAILURE,
  GET_SINGLE_ARTICLE,
  GET_SINGLE_ARTICLE_SUCCESS,
  GET_SINGLE_ARTICLE_FAILURE,
  UPDATE_ARTICLE,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAILURE,
  DELETE_ARTICLE,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAILURE,
  CHANGE_ARTICLE_STATUS,
  CHANGE_ARTICLE_STATUS_SUCCESS,
  CHANGE_ARTICLE_STATUS_FAILURE,
  ADD_LIKE,
  ADD_LIKE_SUCCESS,
  SUBSCRIBE_USER,
  SUBSCRIBE_USER_SUCCESS,
  SUBSCRIBE_USER_FAILURE,
  CLEAR__SUBSCRIBE_USER,
} from '../actionTypes'
import { filterLikesReducerArray } from '../../helpers/string'

const InitialState = {
  loading: false,
  createSuccess: false,
  createError: false,
  articles: [],
  totalArticles: null,
  getOneSuccess: false,
  getOneError: false,
  isLoading: false,
  updateSuccess: false,
  updateError: false,
  article: {},
  deleteSuccess: false,
  deleteError: false,
  statusChanged: false,
  subscribeLoader: false,
  subscribeError: false,
}

export const articleReducer = (state = InitialState, action) => {
  const { article } = state
  switch (action.type) {
    case CREATE_ARTICLE:
      return {
        ...state,
        createSuccess: false,
        createError: false,
      }
    case CREATE_ARTICLE_SUCCESS:
      return {
        ...state,
        createSuccess: true,
      }
    case CREATE_ARTICLE_FAILURE:
      return {
        ...state,
        createError: true,
      }

    case CLEAR_ARTICLE_FLAG:
      return {
        ...state,
        getAllSuccess: false,
        error: false,
        createSuccess: false,
        createError: false,
        getOneSuccess: false,
        getOneError: false,
        updateSuccess: false,
        updateError: false,
        deleteSuccess: false,
        deleteError: false,
        statusChanged: false,
      }

    case GET_USER_ALL_ARTICLES:
      return {
        ...state,
        loading: true,
        getAllSuccess: false,
        error: false,
      }
    case GET_USER_ALL_ARTICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllSuccess: true,
        error: false,
        articles: action.payload.data.getAllArticlesByUser?.items,
        totalArticles: action.payload.data.getAllArticlesByUser?.total,
      }
    case GET_USER_ALL_ARTICLES_FAILURE:
      return {
        ...state,
        loading: false,
        getAllSuccess: false,
        error: true,
      }

    case GET_SINGLE_ARTICLE:
      return {
        ...state,
        getOneSuccess: false,
        getOneError: false,
        isLoading: true,
        article: {},
      }
    case GET_SINGLE_ARTICLE_SUCCESS:
      return {
        ...state,
        getOneSuccess: true,
        isLoading: false,
        article: action.payload,
      }
    case GET_SINGLE_ARTICLE_FAILURE:
      return {
        ...state,
        getOneError: true,
        isLoading: false,
        article: {},
      }
    case UPDATE_ARTICLE:
      return {
        ...state,
        updateSuccess: false,
        updateError: false,
        isLoading: true,
      }
    case UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        updateSuccess: true,
        isLoading: false,
      }
    case UPDATE_ARTICLE_FAILURE:
      return {
        ...state,
        updateError: true,
        isLoading: false,
      }
    case DELETE_ARTICLE:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
        isLoading: true,
      }
    case DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        isLoading: false,
      }
    case DELETE_ARTICLE_FAILURE:
      return {
        ...state,
        deleteError: true,
        isLoading: false,
      }

    case CHANGE_ARTICLE_STATUS:
      return {
        ...state,
        isLoading: true,
        statusChanged: false,
      }
    case CHANGE_ARTICLE_STATUS_SUCCESS:
      const { articles } = state
      return {
        ...state,
        isLoading: false,
        statusChanged: true,
        articles: articles.map(item => (item.id === action.payload.id ? { ...item, status: !item.status } : item)),
      }
    case CHANGE_ARTICLE_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case ADD_LIKE:
      return {
        ...state,
      }

    case ADD_LIKE_SUCCESS:
      return {
        ...state,
        article: {
          ...article,
          likes: filterLikesReducerArray(article.likes, action.payload, action.moduleType),
        },
      }

    //Subscribe to User from Article
    case SUBSCRIBE_USER:
      return {
        ...state,
        subscribeLoader: true,
        subscribeError: false,
      }

    case SUBSCRIBE_USER_SUCCESS:
      return {
        ...state,
        subscribeLoader: false,
        subscribeError: false,
        article: {
          ...state.article,
          user: {
            ...state.article.user,
            subscribers: action.payload.data,
          },
        },
      }

    case SUBSCRIBE_USER_FAILURE:
      return {
        ...state,
        subscribeLoader: false,
        subscribeError: true,
      }

    case CLEAR__SUBSCRIBE_USER:
      return {
        ...state,
        subscribeLoader: false,
        subscribeError: false,
      }

    default:
      return state
  }
}
