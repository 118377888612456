import { gql } from 'apollo-boost'
import { AddressType, LookUp, Media } from './schemaTypes/schemaTypes'

export const Branch = `{
    id
    address ${AddressType}

    branchName
    contactName
    mobileNumber
    emailAddress
    position ${LookUp}
    pricePerFt
    image ${Media}
    isMainBranch
    branchStatus
    branchVerificationStatus
    requests
    user {
      firstName
      lastName
      email
      id
      companyName
      image ${Media}
      companyLogo ${Media}
    }
    createdAt
}`

export const BranchList = `{
  id
  address ${AddressType}
  branchName
  contactName
  mobileNumber
  isMainBranch
  branchStatus
  branchVerificationStatus
  user {
    firstName
    lastName
    email
  }
  requests
  createdAt
  hasNewRequests
}`

export const createBranch = gql`
  mutation createBranch($input: SurveyorBranchInput!) {
    createBranch(input: $input) {
      id
    }
  }
`

export const editBranch = gql`
  query editBranch($id: String!) {
    editBranch(id: $id) ${Branch}
}`

export const updateBranch = gql`
  mutation updateBranch($input: SurveyorBranchInput!) {
    updateBranch(input: $input) {
      id
    }
  }
`

export const deleteBranch = gql`
  mutation deleteBranch($id: String!) {
    deleteBranch(id: $id) {
      id
    }
  }
`

export const changeBranchStatus = gql`
  mutation changeBranchStatus($id: String!, $value: Boolean!, $columnName: String!) {
    changeBranchStatus(id: $id, value: $value, columnName: $columnName)
      ${Branch}
}
`

export const getAllBranch = gql`
  query  branchesByUser($page: Int, $limit: Int, $searchTerm: String) {
    branchesByUser(page: $page, limit: $limit, searchTerm: $searchTerm) {
      items ${BranchList}
      total
    }
  }
`
