import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'
import { Grid } from '@material-ui/core'

import withAuth from '../../../hoc/withAuth'
import { SellerInformation, AgentSection } from '../../../components'
import PriceNegotiable from '../../../components/salesEngine/PriceNegotiable'
import { requestAgent, discardAgentProcess, getAuctionByBoatId } from '../../../redux/actions'

import { paymentStatus } from '../SalesEngineHelper'
import '../SalesEngine.scss'
import { AgentInfoCard } from '../../../components/salesEngine/AgentInfoCard'
import { userRoleTypes, userRoles, salesEngineAuctionWinnerText } from '../../../util/enums/enums'
import BoatInformation from '../../../components/salesEngine/BoatInformation'
import { PaymentDetail } from '../../../components/salesEngine/PaymentDetail'

class MyBoat extends Component {
  state = {
    isSurveyorSkip: null,
  }

  componentDidMount() {
    this.props.salesEngine?.isAuctionSalesEngine &&
      this.props.getAuctionByBoatId({
        id: this.props.salesEngine.boat?.id,
        includeClosed: true,
      })
  }

  setAgent = id => {
    const { salesEngine, requestAgent } = this.props
    requestAgent({ agentId: id, id: salesEngine.id })
  }

  renderSellerInformation = (xs = 4) => {
    const { salesEngine, isSeller, history, isAgent, isBuyer, currentUser } = this.props

    return (
      <SellerInformation
        xs={xs}
        salesEngine={salesEngine}
        isSeller={isAgent ? true : isSeller}
        history={history}
        isBuyer={isBuyer}
      />
    )
  }

  render() {
    const {
      isAgentsLoading,
      salesEngine,
      isSeller,
      agents,
      history,
      isBuyer,
      discardAgentProcess,
      isAgent,
      boatAuctionRoom,
      payments,
    } = this.props
    const agentType = salesEngine?.seller?.role?.aliasName === userRoles.BOAT_MANUFACTURER ? 'Dealer' : 'Agent'
    const boatCountry = salesEngine?.boat?.address?.length && salesEngine?.boat?.address[0].country
    const depositPayment = boatAuctionRoom?.payment?.find(p => p.transactionType === 'bidDeposit')

    return (
      <>
        {isBuyer && salesEngine && (
          <div className="my-boat-assign-agent">
            {isAgentsLoading ||
              (!agents?.length ? (
                <span className="alert alert-info-user">
                  No {agentType} available in {boatCountry}
                </span>
              ) : (
                <AgentSection
                  salesEngine={salesEngine}
                  agents={agents}
                  setAgent={this.setAgent}
                  history={history}
                  isBuyer={isBuyer}
                  discardAgentProcess={discardAgentProcess}
                  agentType={agentType}
                />
              ))}
          </div>
        )}

        {isSeller && !salesEngine?.boat?.isAuctionRoomCreated && (
          <>
            <PriceNegotiable
              salesEngine={salesEngine}
              priceClass={`${salesEngine?.boat?.isAuctionRoomCreated && 'boat--price--negotiable'}`}
            />
          </>
        )}

        {salesEngine?.boat?.isAuctionRoomCreated && (
          <div>
            <span className="auction--winner--info">
              {isBuyer ? salesEngineAuctionWinnerText.buyer : isSeller && salesEngineAuctionWinnerText.seller}
            </span>
          </div>
        )}

        {isBuyer && (
          <>
            {depositPayment && (
              <PaymentDetail salesEngine={salesEngine} isDepositPayment paymentItem={depositPayment} className="mt-0" />
            )}

            {salesEngine.boat &&
              paymentStatus(salesEngine) &&
              payments?.length > 0 &&
              payments.map(item => (
                <PaymentDetail
                  salesEngine={salesEngine}
                  isBoatPaymentDetail
                  payment={boatAuctionRoom?.payment}
                  paymentItem={item}
                  className="mt-0"
                />
              ))}
          </>
        )}

        <div>
          <Container fluid className="pl-0 pr-0 my--boat-information">
            <Grid container spacing={2}>
              {salesEngine?.boat && <BoatInformation salesEngine={salesEngine} isBuyer={isBuyer} />}

              {!isAgent && (
                <SellerInformation
                  xs={4}
                  isPaymentDone={salesEngine.boatPayment}
                  salesEngine={salesEngine}
                  isBuyer={isBuyer}
                  isSeller={isSeller}
                  history={history}
                />
              )}

              {isAgent && (
                <Grid xs={4}>
                  <SellerInformation
                    isPaymentDone={salesEngine.boatPayment}
                    xs={4}
                    salesEngine={salesEngine}
                    isBuyer={isBuyer}
                    isSeller={isSeller}
                    history={history}
                  />
                </Grid>
              )}
            </Grid>
          </Container>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  agents: state.salesEngineReducer?.agents,
  brokerAndDealer: state.boatReducer?.brokerAndDealer,
  salesEngine: state.salesEngineReducer?.salesEngine,
  isAgentsLoading: state.salesEngineReducer?.isAgentsLoading,
  currentUser: state.loginReducer?.currentUser,
  currentLocation: state.loginReducer?.currentLocation,
  boatAuctionRoom: state.boatReducer.boatAuctionRoom,
  payments: state.salesEngineReducer?.payments,
})

const mapDispatchToProps = dispatch => ({
  requestAgent: data => dispatch(requestAgent(data)),
  discardAgentProcess: data => dispatch(discardAgentProcess(data)),
  getAuctionByBoatId: data => dispatch(getAuctionByBoatId(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(MyBoat))
