import React from 'react'
import { KeyboardArrowRight } from '@material-ui/icons'

export const ShowAllLink = ({ data, itemsLength, totalLength, showAllText, url, className }) => {
  return (
    ((data?.length > 0 && data.length > itemsLength) || totalLength > itemsLength) && (
      <div className={`${className} mt-30`}>
        <h2 className="hover-text-only">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            className="show-link mb-0 text-decoration-unset home-show-all"
          >
            See all {totalLength || data.length} {showAllText}
            <KeyboardArrowRight />
          </a>
        </h2>
      </div>
    )
  )
}

ShowAllLink.defaultProps = {
  data: [],
  itemsLength: 5,
  showAllText: '',
  url: '',
  totalLength: 0,
}
