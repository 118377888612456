import React, { Component } from 'react'
import { connect } from 'react-redux'
import { rentCategoryWiseBoats } from '../../../redux/actions'
import BoatListingsWithMap from '../../../components/gridComponents/BoatListingsWithMap'
import { shareDetailsWithUsers } from '../../../redux/actions/shareAction'

class RentCategoryWiseBoats extends Component {
  state = {
    country: '',
    tripTypeId: '',
    showMap: false,
  }

  static getDerivedStateFromProps(nextProps) {
    const { match } = nextProps
    const { params } = match && match

    if (
      params &&
      params.hasOwnProperty('tripType') &&
      params.tripType &&
      params.hasOwnProperty('tripTypeId') &&
      params.tripTypeId &&
      params.hasOwnProperty('country') &&
      params.country
    ) {
      return {
        tripType: params.tripType,
        tripTypeId: params.tripTypeId,
        country: params.country,
      }
    }

    return null
  }

  render() {
    const { categoryWiseBoat, totalCategoryWiseBoats, isPageLoader, rentCategoryWiseBoats } = this.props
    const { country, tripTypeId, showMap, tripType } = this.state

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        isRent
        boatsTypeCount={totalCategoryWiseBoats || 0}
        boatsType={'Rent Boats in ' + tripType.replace('-', ' ')}
        showMap={showMap}
        toggleMapHandler={this.toggleMapHandler}
        boats={categoryWiseBoat}
        action={rentCategoryWiseBoats}
        value={{ country, tripTypeId }}
      />
    )
  }
}

const mapStateToProps = state => ({
  categoryWiseBoat: state.boatRentReducer.rentCategoryWiseBoat,
  totalCategoryWiseBoats: state.boatRentReducer.totalCategoryWiseBoats,
  isPageLoader: state.boatRentReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  rentCategoryWiseBoats: data => dispatch(rentCategoryWiseBoats(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RentCategoryWiseBoats)
