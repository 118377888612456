import { gql } from 'apollo-boost'

import { Media } from './schemaTypes/schemaTypes'

export const getAllJobs = gql`
    query getAllJobs($page: Int, $limit: Int) {
        getAllJobs(page: $page, limit: $limit) {
        items {
            id
            country{
                id
                name
            }
            city
            experience
            title
            description
            reference
            salaryPackage
            candidateProfile
            applicationInformation
            status
            documentUrl ${Media}
        }
        total
    }
}
`

export const applyForJob = gql`
  mutation createApplyJob($input: ApplyJobInput!) {
    createApplyJob(input: $input) {
      id
    }
  }
`

export const searchJob = gql`
query searchJob($searchTerm: String!) {
    searchJob(searchTerm: $searchTerm) {
            id
            country {
                id
                name
            }
            city
            title
            description
            reference
            salaryPackage
            candidateProfile
            applicationInformation
            status
            documentUrl ${Media}
        }
}
`
export const getJobById = gql`
query editJob($id: String!) {
    editJob(id: $id) {
            id
            country {
                id
                name
            }
            city
            experience
            title
            description
            reference
            salaryPackage
            candidateProfile
            applicationInformation
            status
            documentUrl ${Media}
        }
}
`

export const getJobsContent = gql`
  query getCareerContent {
    getCareerContent {
      id
      title
      description
    }
  }
`

export const getAllCountryList = gql`
  query getAllCountries($page: Int, $limit: Int) {
    getAllCountries(page: $page, limit: $limit) {
      items {
        id
        name
      }
    }
  }
`
