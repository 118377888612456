import React from 'react'

import { marketTypes } from '../../../util/enums/enums'
import { BoatSearchResults } from './BoatSearchResults'
import { RentBoatSearchResults } from './RentBoatSearchResults'
import { YachtSearchResults } from './YachtSearchResults'
import { MarinaStorageSearchResults } from './MarinaStorageSearchResults'

import '../../../containers/searchResult/searchResult.scss'

export const SearchResultCard = ({ data, type }) => {
  const renderTypeWiseCard = () => {
    switch (type) {
      case marketTypes.BOAT:
        return <BoatSearchResults data={data} />
      case marketTypes.RENT_BOAT:
        return <RentBoatSearchResults data={data} />
      case marketTypes.YACHT_SERVICE:
        return <YachtSearchResults data={data} />
      case marketTypes.MARINA_AND_STORAGE:
        return <MarinaStorageSearchResults data={data} />

      default:
        return
    }
  }

  return renderTypeWiseCard()
}
