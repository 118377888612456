import { gql } from 'apollo-boost'

export const getAllPageInformationByType = gql`
  query getPagesByTitle($title: String!) {
    getPagesByTitle(title: $title) {
      id
      type
      title
      pageContent
      titleSlug
    }
  }
`

export const getRentPages = gql`
  query getRentPages {
    getRentPages {
      id
      type
      title
      pageContent
      titleSlug
    }
  }
`

export const getSalesEnginePages = gql`
  query getSalesEnginePages {
    getSalesEnginePages {
      id
      type
      title
      pageContent
      titleSlug
    }
  }
`

export const getAgreementQuery = gql`
  query getAgreement($role: String!) {
    getAgreement(role: $role) {
      id
      role {
        id
        role
        aliasName
      }
      type
      title
      pageContent
      titleSlug
    }
  }
`

export const getStaticPagesQuery = gql`
  query getStaticPages($id: String, $type: String, $slug: [String!], $title: String, $role: String) {
    getStaticPages(id: $id, type: $type, slug: $slug, title: $title, role: $role) {
      id
      role {
        id
        role
        aliasName
      }
      type
      title
      pageContent
      titleSlug
    }
  }
`
