import {
  GET_SALES_ENGINE_BY_BUYER,
  GET_SALES_ENGINE_BY_SELLER,
  GET_SALES_ENGINE_BY_SURVEYOR,
  GET_SALES_ENGINE_BY_SHIPPER,
  GET_SINGLE_SALES_ENGINE,
  CLEAR_SALES_ENGINES,
  CREATE_SALES_ENGINE,
  CLEAR_CREATE_SALES_ENGINE_FLAG,
  ASSIGN_SURVEYOR,
  PAYMENT_REQUEST,
  SALES_ENGINE_ASSIGN_SHIPPER_REQUEST,
  GET_AGREEMENTS_CONTENTS,
  CHECK_AGREEMENT,
  CLEAR_GET_SINGLE_SALES_ENGINE_FLAG,
  SALES_ENGINE_SURVEYOR_ACCEPT_BUYER,
  SALES_ENGINE_SURVEYOR_DECLINE_BUYER,
  CREATE_SURVEYOR_REPORT,
  CLEAR_REPORT_FLAG,
  GET_SURVEYOR_REPORT,
  SHIPPER_ACCEPT_SHIPMENT_REQUEST,
  SHIPPER_DECLINE_SHIPMENT_REQUEST,
  ADD_SHIPMENT_PROPOSAL,
  GET_SINGLE_SHIPMENT_PROPOSAL,
  UPDATE_SHIPMENT_PROPOSAL,
  GET_ALL_SHIPMENT_PROPOSAL,
  CLEAR_SALES_ENGINE_SHIPPER_DASHBOARD_FLAG,
  ADD_NEGOTIABLE_PRICE,
  ADD_SURVEY_OPTION,
  CLEAR_PAYMENT_FLAG,
  SKIP_SHIPMENT,
  DECLINE_SURVEYOR,
  ASSIGN_AGENT,
  CLEAR_SALES_ENGINE_SURVEYOR_DASHBOARD_FLAG,
  GET_SALES_ENGINE_BY_AGENT,
  GET_DOCUMENT_LINKS,
  CLEAR_SALES_ENGINE_PAYMENT_FLAG,
  ADD_BOAT_SHIPMENT_LOCATION,
  GET_COST_ESTIMATE,
  START_SHIPMENT,
  COMPLETE_SHIPMENT,
  ADD_SHIPPER_DOCUMENTS,
  CLEAR_SHIPPER_DOCUMENT_FLAG,
  DISCARD_AGENT,
  GET_SALES_ENGINE_AGENTS,
  STOP_SALES_ENGINE_PROCESS,
  PRINT_PAYMENT_RECEIPT,
  GET_SALES_ENGINE_STATIC_CONTENT,
  GET_SURVEY_DOCUMENT_DATA,
  GET_SALES_ENGINE_BY_BOAT,
  BUYER_REVIEW_SURVEY_REPORT,
  UPDATE_SURVEYOR_REPORT,
  SALES_ENGINE_STEP_CHANGE,
  GET_DEALERS_SALES_ENGINE,
  GET_SHIPPER_DOCUMENTS,
  UPDATE_STOCK_QUANTITY,
  UPDATE_SHIPPER_DOCUMENTS,
  REQUEST_AGENT,
  CLEAR_DISCARD_AGENT_FLAG,
  SALES_ENGINE_DASHBOARD_VISIT,
  SALES_ENGINE_PAYMENT_DETAILS,
  GET_COMMISSIONS,
  GET_COMMISSIONS__CLEAR,
} from '../actionTypes'

// SALES ENGINE PROCESS

export const getSingleSalesEngine = data => ({
  type: GET_SINGLE_SALES_ENGINE,
  payload: data,
})

export const addNegotiablePrice = data => ({
  type: ADD_NEGOTIABLE_PRICE,
  payload: data,
})

export const addSurveyOption = data => ({
  type: ADD_SURVEY_OPTION,
  payload: data,
})

export const clearSalesEngines = () => ({
  type: CLEAR_SALES_ENGINES,
})

export const createSalesEngine = data => ({
  type: CREATE_SALES_ENGINE,
  payload: data,
})

export const clearCreateSalesEngineFlag = () => ({
  type: CLEAR_CREATE_SALES_ENGINE_FLAG,
})

export const clearGetSingleSalesEngineFlag = () => ({
  type: CLEAR_GET_SINGLE_SALES_ENGINE_FLAG,
})

export const requestSurveyor = data => ({
  type: ASSIGN_SURVEYOR,
  payload: data,
})

export const declineSurveyor = data => ({
  type: DECLINE_SURVEYOR,
  payload: data,
})

export const paymentRequest = data => ({
  type: PAYMENT_REQUEST,
  payload: data,
})
export const salesEngineAssignShipper = data => ({
  type: SALES_ENGINE_ASSIGN_SHIPPER_REQUEST,
  payload: data,
})
export const getAgreementContents = data => ({
  type: GET_AGREEMENTS_CONTENTS,
  payload: data,
})

export const checkAgreement = data => ({
  type: CHECK_AGREEMENT,
  payload: data,
})

export const getAllShipmentProposal = data => ({
  type: GET_ALL_SHIPMENT_PROPOSAL,
  payload: data,
})

export const skipShipment = data => ({
  type: SKIP_SHIPMENT,
  payload: data,
})

export const getDocumentLinks = data => ({
  type: GET_DOCUMENT_LINKS,
  payload: data,
})

// BUYER DASHBOARD

export const getSalesEngineByBuyer = data => ({
  type: GET_SALES_ENGINE_BY_BUYER,
  payload: data,
})

// SELLER DASHBOARD

export const getSalesEngineBySeller = data => ({
  type: GET_SALES_ENGINE_BY_SELLER,
  payload: data,
})

// SURVEYOR DASHBOARD

export const getSalesEngineBySurveyor = (data, isSearchAction) => ({
  type: GET_SALES_ENGINE_BY_SURVEYOR,
  payload: data,
  isSearchAction
})

export const surveyorAcceptBuyer = data => ({
  type: SALES_ENGINE_SURVEYOR_ACCEPT_BUYER,
  payload: data,
})

export const surveyorDeclineBuyer = data => ({
  type: SALES_ENGINE_SURVEYOR_DECLINE_BUYER,
  payload: data,
})

export const createSurveyorReport = data => ({
  type: CREATE_SURVEYOR_REPORT,
  payload: data,
})

export const getSurveyorReport = data => ({
  type: GET_SURVEYOR_REPORT,
  payload: data,
})

export const clearReportFlag = () => ({
  type: CLEAR_REPORT_FLAG,
})

// SHIPPER DASHBOARD

export const getSalesEngineByShipper = (data, isSearchAction) => ({
  type: GET_SALES_ENGINE_BY_SHIPPER,
  payload: data,
  isSearchAction
})

export const shipperAcceptShipmentRequest = data => ({
  type: SHIPPER_ACCEPT_SHIPMENT_REQUEST,
  payload: data,
})

export const shipperDeclineShipmentRequest = data => ({
  type: SHIPPER_DECLINE_SHIPMENT_REQUEST,
  payload: data,
})

export const addShipmentProposal = data => ({
  type: ADD_SHIPMENT_PROPOSAL,
  payload: data,
})

export const getSingleShipmentProposal = data => ({
  type: GET_SINGLE_SHIPMENT_PROPOSAL,
  payload: data,
})

export const updateShipmentProposal = data => ({
  type: UPDATE_SHIPMENT_PROPOSAL,
  payload: data,
})

export const clearSalesEngineShipperDashboardFlags = data => ({
  type: CLEAR_SALES_ENGINE_SHIPPER_DASHBOARD_FLAG,
  payload: data,
})

export const requestAgent = data => ({
  type: REQUEST_AGENT,
  payload: data,
})

export const assignAgent = (data, managePageRequest) => {
  return {
    type: ASSIGN_AGENT,
    payload: data,
    managePageRequest,
  }
}

export const clearSalesEngineSurveyorDashboardFlags = () => ({
  type: CLEAR_SALES_ENGINE_SURVEYOR_DASHBOARD_FLAG,
})

export const clearSalesEnginePaymentFlags = () => ({
  type: CLEAR_SALES_ENGINE_PAYMENT_FLAG,
})

export const getSalesEngineByAgent = (data, isSearchAction = false) => ({
  type: GET_SALES_ENGINE_BY_AGENT,
  payload: data,
  isSearchAction
})

export const addBoatShipmentLocation = data => ({
  type: ADD_BOAT_SHIPMENT_LOCATION,
  payload: data,
})

export const getCostEstimate = () => ({
  type: GET_COST_ESTIMATE,
})

export const startShipment = data => ({
  type: START_SHIPMENT,
  payload: data,
})

export const completeShipment = data => ({
  type: COMPLETE_SHIPMENT,
  payload: data,
})

export const addShipperDocuments = data => ({
  type: ADD_SHIPPER_DOCUMENTS,
  payload: data,
})

export const clearShipperDocument = () => ({
  type: CLEAR_SHIPPER_DOCUMENT_FLAG,
})

export const discardAgentProcess = (data, managePageRequest) => ({
  type: DISCARD_AGENT,
  payload: data,
  managePageRequest,
})

export const salesEngineAgents = data => ({
  type: GET_SALES_ENGINE_AGENTS,
  payload: data,
})

export const stopSalesEngineProcess = data => ({
  type: STOP_SALES_ENGINE_PROCESS,
  payload: data,
})

export const getPaymentReceipt = data => ({
  type: PRINT_PAYMENT_RECEIPT,
  payload: data,
})

export const getSalesEngineStaticContent = () => ({
  type: GET_SALES_ENGINE_STATIC_CONTENT,
})

export const getSurveyDocumentData = () => ({
  type: GET_SURVEY_DOCUMENT_DATA,
})

export const getSalesEngineByBoat = data => ({
  type: GET_SALES_ENGINE_BY_BOAT,
  payload: data,
})

export const buyerReviewSurveyReport = data => ({
  type: BUYER_REVIEW_SURVEY_REPORT,
  payload: data,
})

export const updateSurveyorReport = data => ({
  type: UPDATE_SURVEYOR_REPORT,
  payload: data,
})

export const salesEngineStepChange = data => ({
  type: SALES_ENGINE_STEP_CHANGE,
  payload: data,
})

export const getDealersSalesEngine = data => ({
  type: GET_DEALERS_SALES_ENGINE,
  payload: data,
})

export const getShipperDocument = data => ({
  type: GET_SHIPPER_DOCUMENTS,
  payload: data,
})

export const updateStockQuantity = data => ({
  type: UPDATE_STOCK_QUANTITY,
  payload: data,
})

export const updateShipperDocument = data => ({
  type: UPDATE_SHIPPER_DOCUMENTS,
  payload: data,
})

export const clearDiscardAgentFlag = () => ({
  type: CLEAR_DISCARD_AGENT_FLAG,
})

export const userVisitSalesEngineDashboard = data => ({
  type: SALES_ENGINE_DASHBOARD_VISIT,
  payload: data,
})

export const salesEnginPaymentInfo = (payload) => ({
  type: SALES_ENGINE_PAYMENT_DETAILS,
  payload
})

export const getCommissions = () => ({
  type: GET_COMMISSIONS,
})

export const getCommissions_clear = () => ({
  type: GET_COMMISSIONS__CLEAR,
})
