import gql from 'graphql-tag'
import { LookUp, Media, roleWithoutType } from './schemaTypes/schemaTypes'

export const searchUsers = gql`
    query searchUsers ($searchTerm: String! = "", $limit: Int, $page: Int ) {
        searchUsers(searchTerm: $searchTerm, limit: $limit, page: $page) {
            items {
              id
              email
              firstName
              lastName
              companyName
              profession ${LookUp}
              image ${Media}
              role ${roleWithoutType}
            }
            total
        }
    }
`

export const shareDetailViaMail = gql`
  mutation shareDetailViaMail($input: ShareInput!) {
    shareDetailViaMail(input: $input) {
      message
      statusCode
      id
    }
  }
`
