import { Layout } from '../../components'
import ImageBanner from '../../components/mainBanner/ImageBanner'
import { footerScreenImages } from '../../util/enums/enums'
import { Col, Image, Row } from 'react-bootstrap'
import classNames from 'classnames'
import wayCardsData from './way-cards.data'
import _styles from '../../styles/info-screens.module.scss'
import styles from './styles.module.scss'

const BeAPartner = ({}) => {
  return (
    <Layout noFooterMargin>
      <ImageBanner
        h1
        title="Be a Partner"
        subTitle="Are you looking to join the world's premier online marketplace for buying and selling boats?"
      />

      <div className={_styles.secPadding}>
        <div className="container100">
          <Row>
            <Col md lg={6}>
              <div>
                <Image src={footerScreenImages.be_a_partner__about_cover} alt="Be A Partner" fluid />
              </div>
            </Col>

            <Col md lg={6}>
              <p className={_styles.fz_p}>
                At AdamSea, we're always looking for new partners to join our growing community of boating enthusiasts. Whether
                you're a buyer, renter, rentee, marina operator, manufacturer, seller, or maintenance professional, we have
                opportunities for you to get involved. As a partner with AdamSea, you'll benefit from our global reach and
                our advanced platform, which makes it easy to connect with buyers and sellers from around the world.{' '}
              </p>

              <div className={styles.aboutSecPoint}>
                <div className={styles.aboutSecPointImg}>
                  <Image src={footerScreenImages.beAPartner_aboutSec_handShake} alt="hand-shake" fluid />
                </div>
                <p className={classNames(styles.aboutSecPointTxt, _styles.fz_p)}>
                  You'll also have access to our network of partners, including marinas, manufacturers, and maintenance
                  professionals, who can help you grow your business and take your boating experience to the next level. If
                  you're a buyer, you'll love our platform's easy and secure payment options.
                </p>
              </div>

              <div className={styles.aboutSecPoint}>
                <div className={styles.aboutSecPointImg}>
                  <Image src={footerScreenImages.beAPartner_aboutSec_card} alt="hand-shake" fluid />
                </div>
                <p className={classNames(styles.aboutSecPointTxt, _styles.fz_p)}>
                  You'll also have access to our network of partners, including marinas, manufacturers, and maintenance
                  professionals, who can help you grow your business and take your boating experience to the next level. If
                  you're a buyer, you'll love our platform's easy and secure payment options.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <ImageBanner
        title="Here are some of the ways you can become a partner with AdamSea"
        src={footerScreenImages.beAPartner_blueBoats}
      />

      <div className={_styles.secPadding}>
        <div className="container100">
          <Row className={styles.wayCardsCont}>
            {wayCardsData.map((data, idx) => (
              <Col lg={4} md={2} className={styles.wayCard_cont}>
                <div className={styles.wayCardWrp}>
                  <div
                    className={classNames(styles.wayCard, {
                      [styles.wayCard_bgDotsLast]: idx === wayCardsData.length - 1,
                    })}
                  >
                    <div className={styles.wayCardIcon}>
                      <Image src={data.icon} alt={data.title} fluid />
                    </div>
                    <h3 className={classNames(styles.wayCard_title, _styles.fz_h)}>{data.title}</h3>
                    <p className={classNames(styles.wayCard_txt, _styles.fz_p)}>{data.text}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      {/* <div className={classNames(_styles.waveCont, _styles.waveCont_grey)}> */}
        <div className={_styles.secPadding}>
          <div className="container100">
            <Row>
              <Col md={6}>
                <h2 className={_styles.fz_h}>Why Choose Us?</h2>
                <p className={classNames(_styles.fz_p, 'mt-30')}>
                  At AdamSea, we strive to provide an inclusive and supportive environment for all boating enthusiasts and
                  industry professionals. Join our community today and unlock a world of opportunities to buy, rent, sell, and
                  connect with like-minded individuals who share your passion for the water. So whether you're a buyer, renter,
                  rentee, marina operator, manufacturer, seller, or maintenance professional, there's a place for you in the
                  AdamSea community. Join us today and start experiencing the joys of boating like never before.
                </p>
              </Col>

              <Col md={6} className="text-right">
                <Image src={require('../../assets/images/info-screens/be-a-partner--why-choose.png')} alt="Why choose Us" fluid />
              </Col>
            </Row>
          </div>
        </div>
      {/* </div> */}
    </Layout>
  )
}

export default BeAPartner
