import React, { Component } from 'react'
import Popover from 'react-tiny-popover'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, ErrorMessage } from 'formik'

import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import { withRouter } from 'react-router'
import CheckBox from 'rc-checkbox'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from 'react-share'

import LoginModal from '../modal/loginModal'
import { icons, sharePopupMessage, pagination, skeletonType, iconBoldImages } from '../../util/enums/enums'
import { CommonModal } from '../modal/commonModal'
import { getUserList } from '../../redux/actions/shareAction'
import { getFirstCharacter, getCurrentUrl, redirectToUserProfile } from '../../helpers/jsxHelper'
import UserContext from '../../UserContext'
import { popUpMessage } from '../../helpers/confirmationPopup'
import { CommonTooltip } from '../CommonTooltip'
import { ButtonComponent } from '../form/Button'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { SkeletonLoader } from '../loader/SkeletonLoader'
import HelmetMetaData from '../helmetComponent/HelmetMeta'
import { getImgUrl } from '../../util/utilFunctions'
import { envConfig } from '../../config'
import { Box } from '@material-ui/core'

class SharePopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      selectedUsers: [],
      addedEmails: [],
      selectedUserDetailList: [],
      email: '',
      loginModal: false,
      selectedEmail: '',
      maxUserLimitReached: false,
      maxUserLimitReachedForAddedUser: false,
      loader: true,
      currentUrl: '',
    }
    this.timeout = 0
  }
  componentDidMount() {
    this.setState({
      currentUrl: window.location.href,
    })
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { shareUsers } = nextProps
    const { loader } = prevState

    if (shareUsers?.items?.length && loader) {
      return {
        loader: false,
      }
    }
    return null
  }

  handlerLoginModal = () => this.setState(prevState => ({ loginModal: !prevState.loginModal }))

  static contextType = UserContext

  addEmail = ({ email }, resetForm) => {
    const { addedEmails, maxUserLimitReachedForAddedUser, maxUserLimitReached } = this.state
    maxUserLimitReached && this.setState({ maxUserLimitReached: false })
    if (addedEmails.length <= 4) {
      maxUserLimitReachedForAddedUser && this.setState({ maxUserLimitReachedForAddedUser: false })
      if (email) {
        addedEmails.push(email)
        this.setState({ addedEmails })
        resetForm()
      }
    } else {
      this.setState({ maxUserLimitReachedForAddedUser: true })
      const interval = setInterval(() => {
        this.setState({ maxUserLimitReachedForAddedUser: false })
        clearInterval(interval)
      }, 2000)
    }
  }

  removeEmail = i => {
    const { addedEmails, maxUserLimitReachedForAddedUser, maxUserLimitReached } = this.state
    maxUserLimitReached && this.setState({ maxUserLimitReached: false })
    addedEmails.splice(i, 1)
    this.setState({ addedEmails })
    if (addedEmails.length <= 4) {
      maxUserLimitReachedForAddedUser && this.setState({ maxUserLimitReachedForAddedUser: false })
    }
  }

  modalHandler = () => {
    const { isOpen } = this.state
    const { isInner, singleHandleClick } = this.props
    isInner && singleHandleClick()
    const userId = getLocalStorageItem('userId')

    !userId && this.handlerLoginModal()

    userId && !isOpen && this.getSearchedUserList()
    userId && this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  getSearchedUserList = (searchTerm = '') => {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      const { getUserList, currentUser } = this.props
      currentUser && currentUser.id && getUserList({ searchTerm })
    }, 1000)
  }

  addRemoveUser = (user, setFieldValue) => {
    const { selectedUsers, maxUserLimitReached, maxUserLimitReachedForAddedUser, selectedUserDetailList } = this.state
    maxUserLimitReachedForAddedUser && this.setState({ maxUserLimitReachedForAddedUser: false })
    if (selectedUsers.includes(user.id)) {
      const index = selectedUsers.indexOf(user.id)
      const detailIndex = selectedUserDetailList.indexOf(user)

      selectedUsers.splice(index, 1)
      selectedUserDetailList.splice(detailIndex, 1)
      this.setState({ selectedUsers, selectedUserDetailList })
      maxUserLimitReached && this.setState({ maxUserLimitReached: false })
    } else {
      if (selectedUsers.length <= 4) {
        selectedUsers.push(user.id)
        selectedUserDetailList.push(user)
        setFieldValue('email', `${user.firstName}${' '}${user.lastName}`)
        this.setState({ selectedUsers, selectedUserDetailList })
      } else {
        this.setState({ maxUserLimitReached: true })
      }
    }
  }

  submitShareWithUsersHandler = data => {
    const { shareDetailsWithUsers } = this.context
    const { moduleId, moduleType } = this.props

    if (data.selectedUsers.length > 0 || data.addedEmails.length > 0) {
      shareDetailsWithUsers({
        module: moduleType,
        moduleId,
        receiverIds: data.selectedUsers,
        manualIds: data.addedEmails,
      })
      this.setState({ selectedEmail: '' })
      this.setState({ selectedUsers: [], addedEmails: [] })
      this.modalHandler()
    } else {
      popUpMessage('Please select atleast one user or add new email to share with', 'Alert')
    }
  }
  closeModalHandler = () => {
    const { singleHandleClick, isInner } = this.props
    this.setState({ isOpen: false })
    isInner && singleHandleClick()
  }

  render() {
    const {
      handleClick,
      index,
      selectedIndex,
      useOwnIcon,
      shareUsers,
      isTrue,
      className,
      currentUser,
      isLoading,
      containerClassName,
      isShipperList,
      history,
      shareBlack,
    } = this.props
    const {
      isOpen,
      selectedUsers,
      addedEmails,
      email,
      loginModal,
      selectedEmail,
      maxUserLimitReached,
      maxUserLimitReachedForAddedUser,
      loader,
      selectedUserDetailList,
    } = this.state

    const sharedUrl = this.props.sharedUrl || getCurrentUrl()

    return (
      <>
        <Popover
          disableReposition={true}
          isOpen={index === selectedIndex}
          position={'bottom'}
          onClickOutside={!useOwnIcon && handleClick}
          containerClassName={`react-tiny-popover-container react--pop--hover ${containerClassName}`}
          content={
            <div
              className={isTrue ? 'd-none' : ` ${className} socialPopupBoxBg`}
              onMouseLeave={() => handleClick(null, 'from on mouse leave')}
            >
              <div>
                {/* <p>{window.location.href}</p> */}
                  {/* <p>{window.location.href}</p> */}
                  <CommonTooltip component={ctProps => (
                    <FacebookShareButton
                      data-tooltip-id={ctProps.id}
                      data-tooltip-content="Facebook"
                      url={sharedUrl}
                      // media="https://aboutreact.com/wp-content/uploads/2019/09/share_post_on_facebook_from_react_native_app.png"
                      // image ="https://aboutreact.com/wp-content/uploads/2019/09/share_post_on_facebook_from_react_native_app.png"
                      quote="Checkout this item on AdamSea"
                      style={{ cursor: 'pointer', marginRight: 5 }}
                    >
                      <img src={require('../../assets/images/facebook.png')} className="cursor-pointer" alt="Facebook" />
                    </FacebookShareButton>
                  )} />
                  <CommonTooltip component={ctProps => (
                    <TwitterShareButton data-tooltip-id={ctProps.id} data-tooltip-content="Twitter" url={sharedUrl} style={{ cursor: 'pointer', marginRight: 5 }}>
                      <img src={require('../../assets/images/twitter.png')} className="cursor-pointer" alt="Facebook" />
                      {/* <TwitterIcon size={32} round={true} /> */}
                    </TwitterShareButton>
                  )} />
                  <CommonTooltip component={ctProps => (
                    <button
                      data-tooltip-id={ctProps.id}
                      data-tooltip-content="Messenger"
                      className="btn"
                      title="Messenger Share"
                      style={{ padding: 0, cursor: 'pointer', marginRight: 5 }}
                      onClick={() =>
                        window.open(
                          `https://www.facebook.com/dialog/send?app_id=${envConfig.RAZZLE_FACEBOOK_DEV_APPID}&link=${sharedUrl}&redirect_uri=${sharedUrl}`,
                          'Messenger',
                          'popup'
                        )
                      }
                    >
                      {/* <FacebookMessengerIcon size={32} round={true} /> */}
                      <img src={require('../../assets/images/messenger.png')} className="cursor-pointer" alt="Facebook" />
                    </button>
                  )} />
              </div>
              <div className="mt-4 d-flex align-center">
                {/* <GooglePlusShareButton url="https://plus.google.com/" style={{ cursor: 'pointer', marginRight: 5 }}>
                                <GooglePlusIcon size={32} round={true} />
                            </GooglePlusShareButton> */}
                  <CommonTooltip component={ctProps => (
                    <LinkedinShareButton data-tooltip-id={ctProps.id} data-tooltip-content="Linkedin" url={sharedUrl} style={{ cursor: 'pointer', marginRight: 5 }}>
                      <img src={require('../../assets/images/linkedin.png')} className="cursor-pointer" alt="Facebook" />
                    </LinkedinShareButton>
                  )} />
                  <CommonTooltip component={ctProps => (
                    <img data-tooltip-id={ctProps.id} data-tooltip-content="AdamShare" loading="lazy" src={require('../../assets/images/adamshare.png')} onClick={() => this.modalHandler()} className="cursor-pointer" alt="AdamSea" />
                  )} />
              </div>
            </div>
          }
        >
          {useOwnIcon ? (
            <div />
          ) : (
            <>
              {!isShipperList ? (
                <CommonTooltip component={ctProps => (
                  <div data-tooltip-id={ctProps.id} data-tooltip-content="Share" className="share-button" onClick={() => handleClick(null)}>
                    <IconButton className="p-0" aria-label="more" aria-controls="long-menu" aria-haspopup="true">
                      <i className="adam-share"></i>
                    </IconButton>
                  </div>
                )} />
              ) : (
                <img
                  src={shareBlack ? iconBoldImages.share_black : iconBoldImages.share}
                  className="user-profile-social-icon-div cursor-pointer"
                  alt="Profile"
                  onClick={() => handleClick(null)}
                />
              )}
            </>
          )}
        </Popover>

        <LoginModal open={loginModal} close={this.handlerLoginModal} />

        {isOpen && (
          <CommonModal
            className="adamsea--share--content noheadermt"
            open={isOpen}
            close={() => {
              this.closeModalHandler()
            }}
            title={
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <img loading="lazy" src={getImgUrl(this.props.siteConfigurations?.adamseaShareLogo)} alt='adamsea logo' height={20} />
                <span className="model-after-title-text">Share this listing with your contacts.</span>
              </div>
            }
            overflowAuto={false}
          >
            <Formik
              initialValues={{ email }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Only email allowed').required('Email is required'),
              })}
              onSubmit={(values, { resetForm }) => {
                this.addEmail(values, resetForm)
                this.getSearchedUserList()
              }}
              render={({ setFieldValue, values, errors, touched, handleSubmit }) => (
                <Form>
                  <div
                    className={`d-flex justify-content-between content-share-popup-rent-page ${
                      errors.email && touched.email ? 'mb-0' : 'mb--error'
                    }`}
                  >
                    <div className="with--error-model content-popup-rent-inner-div text-left mr-1 position-relative w-100">
                      <input
                        className="form-control h-auto adam--share--popup--mail--div font-12"
                        name="email"
                        value={values.email}
                        autocomplete="off"
                        placeholder="Search or add new email from here"
                        onChange={e => {
                          this.setState({ selectedEmail: '' })
                          setFieldValue('email', e.target.value)
                          this.getSearchedUserList(e.target.value)
                        }}
                      />

                      <ButtonComponent
                        className=" h-auto as--success--outline adam--share--modal--popup--add--btn btn--padding btn--font mr-imp-1"
                        onClick={handleSubmit}
                      >
                        Add Email
                      </ButtonComponent>

                      <ButtonComponent
                        className=" share-now-mobile h-auto as--success--outline btn--padding btn--font adam--share--modal--popup--btn btn--padding btn--font"
                        onClick={() => {
                          this.submitShareWithUsersHandler({ selectedUsers, addedEmails })
                        }}
                      >
                        <i className="adam-share mr-2 font-weight-bold "></i>
                        Share
                      </ButtonComponent>
                    </div>
                  </div>
                  {errors.email && touched.email && (
                    <ErrorMessage className="error-message mb--error" name="email" component="div" />
                  )}
                  {maxUserLimitReached && (
                    <div ref={el => el?.scrollIntoView({ behavior: 'smooth', block: 'end' })} className="alert alert-warning">
                      Maximum limit reached for AdamSea Users
                    </div>
                  )}
                  {maxUserLimitReachedForAddedUser && (
                    <div ref={el => el?.scrollIntoView({ behavior: 'smooth', block: 'end' })} className="alert alert-warning">
                      Maximum limit reached.
                    </div>
                  )}

                  {addedEmails &&
                    addedEmails.length > 0 &&
                    addedEmails.map((item, i) => {
                      return (
                        <div>
                          {item} <CancelIcon onClick={() => this.removeEmail(i)} />
                        </div>
                      )
                    })}

                  <div className="adamsea--users--share">
                    {selectedUsers?.length > 0 &&
                      selectedUserDetailList?.length > 0 &&
                      selectedUserDetailList.map((user, index) => {
                        return (
                          <div
                            className={`cursor-pointer  ${
                              selectedUsers.includes(user.id) && 'selected-adamsea--user'
                            } share-adamsea-user`}
                            onClick={() => this.addRemoveUser(user, setFieldValue)}
                          >
                            <CheckBox
                              className="opacity-none cursor-pointer"
                              id={user.id}
                              checked={selectedUsers.includes(user.id)}
                              onClick={() => {
                                this.addRemoveUser(user, setFieldValue)
                              }}
                            ></CheckBox>
                            <label for={user.id} className="d-flex mt-1 cursor-pointer">
                              <div className="d-flex align--flex--start share--adamse--logo">
                                {user.image ? (
                                  <img
                                    src={user.image?.url}
                                    className="view-service-img-salesman share--adamse--user--img"
                                    alt="Profile"
                                    onClick={() => redirectToUserProfile(user, history)}
                                  />
                                ) : (
                                  getFirstCharacter(user.firstName)
                                )}
                                <div className="share--name-with-email">
                                  <span className="share--adamse--user--name font--black-bold">
                                    {user.firstName} {user.lastName}
                                  </span>
                                  <span className="share--adamse--user--name">
                                    {user?.role?.role === 'SERVICE & MAINTENANCE' ? 'BOAT SERVICE' : user?.role?.role}
                                  </span>
                                  <span className="share--adamse--user--email">
                                    Working {user.companyName ? ` in ${user.companyName}` : `${user.profession?.alias}`}
                                  </span>
                                </div>
                              </div>
                            </label>
                          </div>
                        )
                      })}
                    {isLoading ? (  
                      <SkeletonLoader type={skeletonType.userInfo} itemsLength={10} count={6} />
                    ) : (
                      <>
                        {shareUsers?.items?.length ? (
                          shareUsers.items.map((user, index) => {
                            return (
                              !selectedUserDetailList.find(item => item.id === user.id) && (
                                <div
                                  key={index}
                                  className={`cursor-pointer  ${
                                    selectedUsers.includes(user.id) && 'selected-adamsea--user'
                                  } share-adamsea-user`}
                                  onClick={() => this.addRemoveUser(user, setFieldValue)}
                                >
                                  <CheckBox
                                    className="opacity-none cursor-pointer"
                                    id={user.id}
                                    checked={selectedUsers.includes(user.id)}
                                    onClick={() => {
                                      this.addRemoveUser(user, setFieldValue)
                                    }}
                                  ></CheckBox>
                                  <label for={user.id} className="d-flex mt-1 cursor-pointer">
                                    <div className="d-flex align--flex--start share--adamse--logo">
                                      {user.image ? (
                                        <img
                                          src={user.image?.url}
                                          className="view-service-img-salesman share--adamse--user--img"
                                          alt="Profile"
                                          onClick={() => redirectToUserProfile(user, history)}
                                        />
                                      ) : (
                                        getFirstCharacter(user.firstName)
                                      )}
                                      <div className="share--name-with-email">
                                        <span className="share--adamse--user--name font--black-bold">
                                          {user.firstName} {user.lastName}
                                        </span>
                                        <span className="share--adamse--user--name">
                                          {user?.role?.role === 'SERVICE & MAINTENANCE' ? 'BOAT SERVICE' : user?.role?.role}
                                        </span>
                                        <span className="share--adamse--user--email">
                                          Working {user.companyName ? ` in ${user.companyName}` : `${user.profession?.alias}`}
                                        </span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              )
                            )
                          })
                        ) : (
                          <>
                          { shareUsers?.total === 0 && 
                            <Box className='no--result--found--text'>
                              <img src={require('../../assets/images/search/no_data_found.jpeg')} alt='No data'/>
                              <p>To build your list, visit the profiles of the users you want to add.</p>
                            </Box>
                          }
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Form>
              )}
            />
          </CommonModal>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  shareUsers: state.shareReducer && state.shareReducer.shareUsers,
  isLoading: state.shareReducer && state.shareReducer.isLoading,
  currentUser: state.loginReducer.currentUser || {},
  siteConfigurations: state.loginReducer.siteConfigurations,
})

const mapDispatchToProps = dispatch => ({
  getUserList: data => dispatch(getUserList(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SharePopup))

SharePopup.defaultProps = {
  containerClassName: '',
  isShipperList: false,
}
