import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'

import { resultMessage, shareModule, userRoles, pagination, noSurveyorMessage, skeletonType } from '../../util/enums/enums'
import BoatListingsWithMap from '../../components/gridComponents/BoatListingsWithMap'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import { getUserListingLabel, shareWithUsers } from '../../helpers/jsxHelper'
import { getUserById, getItemsByUser } from '../../redux/actions'
import { SurveyorList } from '../../components/salesEngine/SurveyorList'
import { Layout, PaginationBar } from '../../components'
import { SkeletonLoader } from '../../components/loader/SkeletonLoader'

class RelatedBoats extends Component {
  state = {
    userId: null,
  }

  componentDidMount() {
    const {
      getUserById,
      getItemsByUser,
      match: {
        params: { id },
      },
    } = this.props
    id && getUserById(id, true)
    id &&
      getItemsByUser({
        page: pagination.PAGE_COUNT,
        limit: pagination.PAGE_RECORD_LIMIT,
        userId: id,
      })
    this.setState({ userId: id })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props?.userProfileDetail?.role?.aliasName &&
      prevProps?.userProfileDetail?.role?.aliasName !== this.props?.userProfileDetail?.role?.aliasName
    ) {
      this.props.getItemsByUser({
        page: pagination.PAGE_COUNT,
        limit: pagination.PAGE_RECORD_LIMIT,
        userId: this.props.match.params.id,
        type: [userRoles.BOAT_OWNER, userRoles.BOAT_MANUFACTURER, userRoles.BROKER_AND_DEALER].includes(
          userProfileDetai.role.aliasName
        )
          ? 'boats'
          : [userRoles.MARINA_AND_STORAGE].includes(userProfileDetai.role.aliasName)
          ? 'marinas'
          : [userRoles.SERVICE_AND_MAINTENANCE].includes(userProfileDetai.role.aliasName)
          ? 'yachts'
          : [userRoles.SURVEYOR].includes(userProfileDetai.role.aliasName)
          ? 'surveyorBranches'
          : [userRoles.RENT_AND_CHARTER].includes(userProfileDetai.role.aliasName)
          ? 'boatRents'
          : '',
      })
    }
  }

  shareBoatWithUsers = data => {
    const { shareDetailsWithUsers } = this.props
    const object = shareWithUsers(data, shareModule.boat)
    shareDetailsWithUsers(object)
  }

  render() {
    const {
      relatedItems,
      userProfileDetail,
      isLoading,
      isPageLoader,
      getItemsByUser,
      relatedTotal,
      match: {
        params: { role, type },
      },
    } = this.props
    const { userId } = this.state

    const isRent = userProfileDetail?.role?.aliasName === userRoles.RENT_AND_CHARTER
    const isMarinaStorage = userProfileDetail?.role?.aliasName === userRoles.MARINA_AND_STORAGE
    const isBoatService = userProfileDetail?.role?.aliasName === userRoles.SERVICE_AND_MAINTENANCE
    const isBoatShow = !!relatedItems?.boatShows.length && type === 'boat-shows'
    const isBoatRent = userProfileDetail?.role?.aliasName === userRoles.RENT_AND_CHARTER
    const isSurveyor = role === userRoles.SURVEYOR
    const relatedData = relatedItems
      ? isMarinaStorage
        ? relatedItems.marinas
        : isBoatService
        ? relatedItems.yachts
        : isBoatShow
        ? relatedItems.boatShows
        : isBoatRent
        ? relatedItems.boatRents
        : relatedItems.boats
      : []

    return (
      <>
        {isSurveyor ? (
          <Layout className="user-profile-layout">
            <div className="user--profile--listings--section">
              {isPageLoader ? (
                <SkeletonLoader type={skeletonType.grid} itemsLength={8} />
              ) : relatedItems?.surveyorBranches?.length > 0 ? (
                <Container className="user--profile--layout--Container user--profile--spacing pr-0 pl-0 pt-0">
                  <h4 className="color-black section-heading section--heading-user--profile mt-0 surveyor--branch--title font-32">
                    {getUserListingLabel(userProfileDetail, relatedTotal || 0)}
                  </h4>
                  <div className="boat--inner-surveyor surveyor-list-nearest-carousel ">
                    {relatedTotal > 0 &&
                      relatedItems.surveyorBranches.map(item => (
                        <SurveyorList key={item.id} userInfo={item} isBranchList inProfile />
                      ))}
                  </div>
                </Container>
              ) : (
                !isPageLoader && relatedItems?.surveyorBranches?.length === 0 && noSurveyorMessage('', true)
              )}

              {relatedTotal > pagination.PAGE_RECORD_LIMIT && (
                <div className="boat-pagination home--show--all--pagination">
                  <PaginationBar action={getItemsByUser} value={{ userId }} totalRecords={relatedTotal || 0} />
                </div>
              )}
            </div>
          </Layout>
        ) : (
          userId && (
            <BoatListingsWithMap
              isPageLoader={isPageLoader || isLoading}
              showAllTitle={isBoatShow && `${relatedItems?.totalBoatShows || 0} ${userProfileDetail?.firstName}'s Boat Shows`}
              boatsType={getUserListingLabel(userProfileDetail, relatedTotal || 0)}
              isRent={isRent}
              isMarinaStorage={isMarinaStorage}
              isBoatService={isBoatService}
              isBoatShow={isBoatShow}
              boatsTypeCount={(isBoatShow ? relatedItems?.totalBoatShows : relatedTotal) || 0}
              boats={relatedData}
              message={resultMessage.search}
              shareWithUsers={data => this.shareBoatWithUsers(relatedItems)}
              action={getItemsByUser}
              value={{
                page: pagination.PAGE_COUNT,
                limit: pagination.PAGE_RECORD_LIMIT,
                userId: userId,
              }}
            />
          )
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  relatedItems: state.loginReducer.relatedItems,
  relatedTotal: state.loginReducer.relatedTotal,
  user: state.loginReducer.userProfileDetail || {},
  isPageLoader: state.loginReducer.isPageLoader,
  isLoading: state.loginReducer.isLoading,
  userProfileDetail: state.loginReducer.userProfileDetail || {},
})

const mapDispatchToProps = dispatch => ({
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
  getUserById: (data, userProfile) => dispatch(getUserById(data, userProfile)),
  getItemsByUser: data => dispatch(getItemsByUser(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RelatedBoats)
