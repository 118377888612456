import React, { PureComponent } from 'react'
import moment from 'moment'
import StarIcon from '@material-ui/icons/Star'
import { viewRentBoatHandler } from '../../helpers/boatHelper'
import { percentageFormate, commonRentType } from '../../util/utilFunctions'
import { CommonAdamSeaStyle, RentGridStyle } from '../styleComponent/styleComponent'
import { rentIcon, wishlistModuleEnum, shareModule, rentBoatTripForCard } from '../../util/enums/enums'
import SharePopup from '../share/SharePopup'
import { displayDefaultNumericValue } from '../../helpers/string'
import UserContext from '../../UserContext'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { WishlistComponent } from '../wishlist/WishlistComponent'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { getAddress, cityCountryNameFormatter } from '../../helpers/jsxHelper'
import RatingComponent from '../rating/Rating'
import '../../assets/css/component/rentGrid.scss'

export class RentSharedTrip extends PureComponent {
  state = {
    selectedIndex: null,
  }

  static contextType = UserContext

  handleClick = index => {
    const { selectedIndex } = this.state
    this.setState({
      selectedIndex: selectedIndex !== index ? index : null,
    })
  }

  render() {
    const { selectedIndex } = this.state
    const {
      value,
      isPrivate,
      index,
      isRentPerHour,
      isCustomWidth,
      isTopLabel,
      width,
      height,
      className,
      gridClassName,
      isCarousal,
      margin,
    } = this.props

    const {
      id,
      model,
      price,
      endTime,
      startTime,
      maximumGuest,
      boatLength,
      trip,
      tripType,
      images,
      rating,
      available,
      address,
      tripDuration,
    } = value

    const {
      commonAddress: { city, country },
    } = getAddress(address) || {}

    const isRentTrip = trip?.alias === rentBoatTripForCard.rentPerHour
    const isShared = trip?.alias === rentBoatTripForCard.shared

    return (
      <RentGridStyle
        width={width}
        margin={margin}
        className={
          isCarousal
            ? 'w-100 position-relative rent-city-wise-trip-layout rent--trip--wise--grid--style'
            : `${gridClassName} ${className} rent-city-wise-trip-layout position-relative rent--trip--wise--grid--style`
        }
      >
        <div className="rent--logo--with--grid--icon">
          <CommonAdamSeaStyle
            height={height}
            width={width}
            className={`${isCustomWidth && 'w-100'} is-shared inner--trips rent-city-wise-inner-img trip--border--radius`}
            onClick={() => viewRentBoatHandler(value)}
            img={images && images.length > 0 && encodeURI(images[0]?.url)}
          />
          <div className="card-action">
            {value && isTopLabel && commonRentType(value)}
            <div className="share-icon">
              <WishlistComponent moduleId={id} moduleType={wishlistModuleEnum.BOAT_RENT} />
              {/* <SharePopup
                handleClick={() => this.handleClick(index)}
                index={index}
                selectedIndex={selectedIndex}
                moduleId={value.id}
                moduleType={shareModule.BOAT_RENT}
              /> */}
            </div>
          </div>
        </div>

        <div className="rent--new--content" onClick={() => viewRentBoatHandler(value)}>
          <div className="rent--price--with--label">
            {!isTopLabel && isPrivate && <div className="private--tag  tag---blue">Private</div>}

            <CurrencyContextConsumer>
              {({ currentCurrency }) => (
                <h6 className="boat-price-rent">
                  {getConvertedPrice(price, currentCurrency)}{' '}
                  <span className="hour--rent">
                    {isRentTrip
                      ? 'Hour'
                      : trip?.alias === 'Shared Trip'
                      ? 'Person'
                      : trip?.alias === 'Private Trip'
                      ? 'Trip'
                      : null}
                  </span>
                </h6>
              )}
            </CurrencyContextConsumer>

            <div className="rent--star ml-auto">
              <StarIcon /> {(+rating).toFixed(1)}
              {/* <RatingComponent rating={rating} className="rating-clr" /> */}
              {/* <span className="rating-count-rent">({percentageFormate(rating)})</span> */}
            </div>
          </div>
          <div className="rent--type--boat">
            <div className="rent-trip-type m-0">{tripType && tripType.alias}</div>
          </div>
          <div className="country-div-height">
            <div className="rent--boat--country">{cityCountryNameFormatter(city, country, ' • ')}</div>
          </div>
          {isRentPerHour || isRentTrip ? (
            <div className="alignCenter mr-3">
              <img loading="lazy" src={require('../../assets/images/rent/Piece-availible.svg')} className="width-height-icon-clock mr-2 img-auto" alt="Piece" />
              <span className="pieces-text">{displayDefaultNumericValue(available)} Pieces Available</span>
            </div>
          ) : (
            <div className="rent--detail--trip-value">
              <div className="alignCenter">
                <img loading="lazy" src={require('../../assets/images/rent/hourglass_212834.svg')} className="location-icon-rent width-height-icon-clock img-auto" alt="Clock" />
                <span style={{ fontSize: 14 }}>{tripDuration}</span>
              </div>
              <div className="alignCenter">
                <img loading="lazy" src={require('../../assets/images/rent/network_1321954.svg')} className="location-icon-rent width-height-icon-user-measure img-auto" alt="User" />
                <span style={{ fontSize: 14 }}>
                  {isShared ? displayDefaultNumericValue(available) : displayDefaultNumericValue(maximumGuest)}
                </span>
              </div>
              <div className="alignCenter">
                <img loading="lazy" src={require('../../assets/images/rent/measuring-tape_9717961.svg')} className="location-icon-rent width-height-icon-user-measure img-auto" alt="Measure" />
                <span style={{ fontSize: 14 }}>{displayDefaultNumericValue(boatLength)} ft</span>
              </div>
            </div>
          )}
        </div>
      </RentGridStyle>
    )
  }
}
RentSharedTrip.defaultProps = {
  isShowAllPage: false,
  isCustomWidth: false,
  isUserProfile: false,
  isTopLabel: true,
  key: 'popularTrip',
  isCarousal: false,
}
