import React, { useEffect, useState, useContext } from 'react'

import UserContext from '../../UserContext'
import { addToWishlist, getWishlistValue } from '../../helpers/methodHelper'
import { CommonTooltip } from '../CommonTooltip'
import { iconBoldImages } from '../../util/enums/enums'

export const WishlistComponent = ({ moduleId, moduleType, isInnerPage, isClickble, isServiceInner }) => {
  const { createWishlist, removeWishlist, allWishlists } = useContext(UserContext)

  const [wishlistValue, setWishlistValue] = useState(false)

  useEffect(() => {
    setWishlistValue(getWishlistValue(moduleId, allWishlists && allWishlists.all))
  }, [allWishlists, moduleId])

  const wishlistHandler = () => {
    const method = wishlistValue ? removeWishlist : createWishlist
    isClickble && addToWishlist(method, moduleId, moduleType)
    // setWishlistValue(!wishlistValue)
  }

  return (
    <CommonTooltip
      component={ctProps => (
        <div
          data-tooltip-id={ctProps.id}
          data-tooltip-content="Wishlist"
          className={
            `${wishlistValue && 'active-wishlist'} wishlist-div ${
              isInnerPage ? 'icon d-flex justify-content-center' : 'heart-button'
            }` + (isServiceInner ? ' icon-rev' : '')
          }
          onClick={wishlistHandler}
        >
          {!wishlistValue ? (
            !isInnerPage || isServiceInner ? (
              <img loading="lazy" src={iconBoldImages.heart_black} alt="adamsea" />
            ) : (
              <img loading="lazy" src={iconBoldImages.heart} alt="adamsea" />
            )
          ) : (
            <img loading="lazy" src={iconBoldImages.heart_red} alt="adamsea" />
          )}
        </div>
      )}
    />
  )
}

WishlistComponent.defaultProps = {
  moduleId: '',
  moduleType: '',
  isInnerPage: false,
  isClickble: true,
}
