import React, { useRef, useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { envConfig } from '../../config'

const Captcha = props => {
  const recaptchaRef = useRef()
  const [valueSent, setValueSent] = useState(false)

  useEffect(() => {
    if (!valueSent && recaptchaRef?.current) {
      props.getRefValue(recaptchaRef)
      setValueSent(true)
    }
  }, [recaptchaRef, props, valueSent])

  return <ReCAPTCHA sitekey={envConfig.RECAPTCHA_SITE_KEY} ref={recaptchaRef} size="invisible" />
}

export default Captcha
