import React, { useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import NavigateNextSharpIcon from '@material-ui/icons/NavigateNextSharp'
import { pagination } from '../../util/enums/enums'

import './pagination.scss'
import { getPagesValue } from '../../helpers/jsxHelper'
import { withRouter } from 'react-router'

export const PaginationBar = withRouter(
  React.memo(props => {
    const {
      action,
      value,
      totalRecords,
      pageLimit,
      history,
      location,
      searchTerm,
      searchAction,
      searchType,
      searchResultKey,
      dashboardSearchInput,
    } = props

    const handlePageChange = pageNumber => {
      const urlParams = new URLSearchParams(location.search)
      let searchParams
      if (urlParams.has('page')) {
        searchParams = location.search.split('page')
      }
      const search = searchParams?.length
        ? `${searchParams[0]}page=${pageNumber.selected + 1}`
        : `${location.search}&page=${pageNumber.selected + 1}`

      history.replace({
        pathname: location.pathname,
        search,
        state: location.state,
      })

      if (action) {
        if (searchAction && value.searchTerm) {
          searchAction({
            input: {
              ...dashboardSearchInput,
              searchTerm: value.searchTerm,
              type: searchType,
            },
            page: pageNumber.selected + 1,
            limit: pageLimit,
            searchType: searchResultKey,
          })
        } else {
          action({
            ...value,
            ...(searchTerm ? { searchTerm } : {}),
            page: pageNumber.selected + 1,
            limit: pageLimit,
          })
        }
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 500)
      }
    }

    return (
      <>
        {totalRecords > (pageLimit || pagination.INDEX_PAGE_LIMIT) && (
          <div className="show-all-pagination">
            <ReactPaginate
              previousLabel={<img loading="lazy" src={require('../../assets/images/Arrow/pagination-pre.png')} alt="prev btn" />}
              nextLabel={<img loading="lazy" src={require('../../assets/images/Arrow/pagination-next.png')} alt="next btn" />}
              // previousLabel={<NavigateNextSharpIcon />}
              // nextLabel={<NavigateNextSharpIcon />}
              pageCount={totalRecords / pageLimit}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              disableInitialCallback
              initialPage={getPagesValue() - 1}
              forcePage={getPagesValue() - 1}
            />
          </div>
        )}
      </>
    )
  })
)

PaginationBar.defaultProps = {
  value: {},
  isShipperPageLimit: false,
  pageLimit: pagination.PAGE_RECORD_LIMIT,
}
