import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { BoatMediaArticle } from './BoatMediaArticle'

export class BoatMediaArticles extends React.Component {
  render() {
    const { boatMediaArticles, dimension, itemsLength, isShowAll, isMediaBoat, total } = this.props
    return (
      <>
        <div className="d-flex flex-wrap mt-2 zoom-container section-heading">
          <Grid container className='boat-media-article-grid-layout'>
            {boatMediaArticles && boatMediaArticles.length ? (
              boatMediaArticles.map((value, index) => {
                return (
                  itemsLength > index && (
                    <Grid item sm={4} className='boat-media-article-inner-grid'>
                      <div key={value.id} className="mb-3 boat--media--article--section">
                        <BoatMediaArticle
                          value={value}
                          dimension={dimension}
                          isShowAll
                          isMediaBoat={isMediaBoat}
                          className="featured--boats--grid featured-img-header-div"
                        />
                      </div>
                    </Grid>
                  )
                )
              })
            ) : (
              <div item xs={12}>
                {' '}
                No Record Found{' '}
              </div>
            )}
          </Grid>
        </div>
        {!isShowAll && boatMediaArticles.length > itemsLength ? (
          <div className="">
            <h2>
              <Link
                to={'/media-all-articles'}
                className="show-link mb-0 text-decoration-unset hover-text-only media--show--all"
              >
                See all {total} articles
                <KeyboardArrowRight />
              </Link>
            </h2>
          </div>
        ) : (
          <></>
        )}
      </>
    )
  }
}
BoatMediaArticles.defaultProps = {
  dimension: {
    width: 360,
    height: 180,
  },
  isShowAll: false,
  total: 0,
}
