import { toast } from 'react-toastify'

export const SuccessNotify = message => {
  message &&
    toast.success(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      style: {
        textAlign: 'center'
      }
    })
}

export const ErrorNotify = message => {
  message &&
    toast.error(message, {
      position: 'top-center',
      autoClose: false,
      hideProgressBar: true,
      pauseOnHover: true,
      draggable: false,
      autoClose: 5000,
      style: {
        textAlign: 'center'
      }
    })
}

export const WarnNotify = message => {
  toast.warn(message, {
    position: 'top-center',
    className: 'warning--with-close',
    autoClose: 5000,
    pauseOnHover: true,
    hideProgressBar: true,
    style: {
      textAlign: 'center'
    }
  })
}

export const InfoNotify = message => {
  message &&
    toast.warn(message, {
      position: 'top-center',
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      autoClose: 5000,
      style: {
        textAlign: 'center'
      }
    })
}
