import React, { useState } from 'react'
import HelpIcon from '@material-ui/icons/Help'
import { CommonTooltip } from '../CommonTooltip'

export const HelpIconComponent = ({ helpText, containerClass }) => {
  return (
    helpText && (
      <>
        <CommonTooltip component={ctProps => (
          <div className={`help--icon--div ml-2 ${containerClass}`} data-tooltip-id={ctProps.id} data-tooltip-content={helpText}>
            <img src={require('../../assets/images/ques.png')} className="help--icon--root" />
          </div>
        )} />
      </>
    )
  )
}

HelpIconComponent.defaultProps = {
  helpText: '',
  containerClass: '',
}
