import { gql } from 'apollo-boost'

import { Media } from './schemaTypes/schemaTypes'

export const getAllDownloads = gql`
query getAllDownloads($page: Int, $limit: Int) {
    getAllDownloads(page: $page, limit: $limit) {
        items {
            id
            title
            description
            downloadUrl ${Media}
        }
    }
}
`
