import { useEffect, useRef } from 'react'

export default function RentSticky({ setIsActive }) {
  const ref = useRef(null)

  useEffect(() => {
    const handler = function () {
      setIsActive(ref.current?.getBoundingClientRect().bottom < 70)
    }

    window.addEventListener('scroll', handler)
    handler()

    return () => {
      window.removeEventListener('scroll', handler)
    }
  }, [])

  return <div ref={ref} />
}
