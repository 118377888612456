import React from 'react'
import { connect } from 'formik'

class ErrorFocus extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik
    const keys = Object.keys(errors)
    if (keys.length > 0 && isSubmitting && !isValidating) {
      // const bodyData = document.body.getBoundingClientRect()

      // const selector = [`[id="${keys[0]}"]`, `[name="${keys[0]}"]`].map(c => c + ':not(meta)').join(',');
      // console.log(document.querySelector(selector))
      // const errorElement = document.querySelector(selector).getBoundingClientRect();
      // const os = errorElement.top - bodyData.top
      // window.scrollTo(0, os - 100)

      if (document.querySelector(`.err-msg-${keys[0]}`)) {
        var bodyRect = document.body.getBoundingClientRect(),
          elemRect = document.querySelector(`.err-msg-${keys[0]}`).getBoundingClientRect(),
          offset = elemRect.top - bodyRect.top
        window.scrollTo(0, offset - 100)
      }
    }
  }

  render() {
    return null
  }
}

export default connect(ErrorFocus)
