import React, { PureComponent, Fragment } from 'react'

import { MostPopularStyle } from '../styleComponent/styleComponent'
import SharePopup from '../share/SharePopup'
import { commonMarinaType, percentageFormate } from '../../util/utilFunctions'
import { viewMarinaDetails } from '../../helpers/boatHelper'
import { cityCountryNameFormatter } from '../../helpers/jsxHelper'
import { wishlistModuleEnum, shareModule } from '../../util/enums/enums'
import { WishlistComponent } from '../wishlist/WishlistComponent'
import RatingComponent from '../rating/Rating'
import { textTruncate } from '../../helpers/string'
import '../../assets/css/component/StaticComponent/maringrid.scss'
export class SingleMarinaDetail extends PureComponent {
  state = {
    selectedIndex: null,
  }

  handleClick = index => {
    const { selectedIndex } = this.state
    this.setState({
      selectedIndex: selectedIndex !== index ? index : null,
    })
  }

  render() {
    const { selectedIndex } = this.state
    const { index, value, className, width, height, gridClassName, margin, isUserProfile } = this.props
    const {
      images,
      address: [{ city, country }],
      rating,
      facilities,
      id,
      provider,
    } = value

    return (
      <Fragment>
        <div
          style={{ width: width, marginRight: margin }}
          className={`${gridClassName} ${className} marina--grid--items storage-around cursor-pointer marina-ads-sections`}
        >
          <div className="position-relative ">
            <MostPopularStyle
              bgHeight={height}
              bgWidth={width}
              className='marina-card-img-height-static'
              onClick={() => viewMarinaDetails(value)}
              img={images && images.length > 0 && encodeURI(images[0]?.url)}
            />
            <div className="card-action card-action-marina justify-content-between">
              <div className="marina--type--dev" onClick={() => viewMarinaDetails(value)}>
                {commonMarinaType(provider)}
              </div>
              <div className="share-icon">
                <WishlistComponent moduleId={id} moduleType={wishlistModuleEnum.MARINA} />
                {/* <SharePopup
                  handleClick={() => this.handleClick(index)}
                  index={index}
                  selectedIndex={selectedIndex}
                  moduleId={id}
                  moduleType={shareModule.MARINA}
                /> */}
              </div>
            </div>
          </div>
          <div className="marina--information--main">
            <div className="marina--information--with--review">
              <div className="marina--information--name--with--city">
                <div className="marina--information--name">{value.name}</div>
                <div className="marina--information--city">{cityCountryNameFormatter(city, country)}</div>
              </div>
              {!isUserProfile && (
                <div className="marina--information--review">
                  <RatingComponent rating={rating} className="rating-clr" />
                  {/* <span className="rating-count-rent">({percentageFormate(rating)})</span> */}
                </div>
              )}
            </div>

            <div className="marina--information--description line-clamp color-black">{textTruncate(facilities, 122)}</div>

            {isUserProfile && (
              <div className="marina--user-profile--review">
                <RatingComponent rating={rating} className="rating-clr" />
                {/* <span className="rating-count-rent">({percentageFormate(rating)})</span> */}
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}
SingleMarinaDetail.defaultProps = {
  className: '',
}
