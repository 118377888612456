import { Row, Col, Image } from 'react-bootstrap'
import { Layout } from '../../components'
import { footerScreenImages, icons } from '../../util/enums/enums'

import './brokerInfo.scss'
import { Box, Stack } from '@mui/material'
import { Grid } from '@material-ui/core'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import _styles from '../../styles/info-screens.module.scss'

const BrokerInfo = () => {
  return (
    <Layout>
      <div className="container100">
        <Grid className="py-5">
          <Row>
            <Col sm={12}>
              <h1 className="r-h1">Boat Brokers & Dealers</h1>
            </Col>
          </Row>

         <Row>
            <Col sm={12} lg={6}>
              {/* <span className="broker--info--title">As a broker,</span> */}
              <span className="broker--info--text">
                AdamSea provides you with an easy sales process through our sales engine, allowing you to sell your used
                watercrafts or new dealership watercrafts with ease. Our platform allows you to manage your own inventory and
                open your own online store to sell your watercrafts to the entire world. With AdamSea, you'll have access to a
                global market and the tools you need to manage your sales efficiently.
              </span>
            </Col>
            <Col className="d-flex justify-content-end align-items-center" lg={6}>
              <div className="position-relative">
                <img
                  className="position-absolute dotted-yellow--bg"
                  loading="lazy"
                  src={footerScreenImages.yellow_dots_bg}
                  alt="Dots"
                />
                <img
                  className="rounded"
                  loading="lazy"
                  width={493}
                  height={300}
                  src={footerScreenImages.broker_parked_boat}
                  alt="Boat"
                />
              </div>
            </Col>
          </Row>
        </Grid>
        {/* <Box className="my-4">
          <p className="as-com-title">Sales Engine</p>
          <img style={{ width: '100%' }} src={footerScreenImages.sales_engine_process} loading="lazy" alt="Sales engine" />
        </Box> */}
        <Grid container spacing={3} className="pt-3">
          <Grid item sm={12} md={6} className="my-3">
            <div className="info-card">
              <Stack>
                <img className="mr-5" loading="lazy" width={64} src={icons.streaming_boat_sales} alt="Boat sales" />
                <span className="broker--info--subtitle my-3">Streamlining Boat Sales</span>
                <span className="broker--info--detail">
                  Our platform is designed to streamline the sales process, giving you more time to focus on what you do best –
                  selling boats. By using our sales engine, you can quickly list your watercrafts and manage all aspects of the
                  sales process, from negotiating with buyers to arranging shipping and handling payments.
                </span>
              </Stack>
            </div>
          </Grid>
          <Grid item sm={12} md={6} className="my-3">
            <div className="info-card">
              <Stack>
                <img className="mr-5" loading="lazy" width={64} src={icons.networking_and_collaboration} alt="Networking" />
                <span className="broker--info--subtitle my-3">Networking and Collaboration</span>
                <span className="broker--info--detail">
                  Additionally, brokers can benefit from the AdamSea community, which includes AS Chat and AdamSea Share. AS
                  Chat allows you to communicate with other users on the platform, while AdamSea Share brings the entire AdamSea
                  user body under one social platform. With AdamSea, you can expand your network and connect with other
                  professionals in the marine industry.
                </span>
              </Stack>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={classNames(_styles.secPadding)}>
        <div className="container100">
          <h2 className={_styles.fz_h}>How boat broker and dealer sell in AdamSea</h2>
          <p className={_styles.fz_p + ' mt-20'}>
            <Link to="/register/broker-and-dealer" className="btn btn-primary rounded-full">Sign up as broker and dealer</Link>
          </p>

          <ol className={'mt-30 ' + _styles.fz_ul_lg}>
            <li>
              <div>List unlimited used or new boats for sale, and manage all of them on one dashboard, manage new brand boat's stock and sell in bulk.</div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/broker-how-1.png')} alt="List unlimited used or new boats for sale" fluid />
              </div>
            </li>
            <li>
              <div>Receive one or more buyer requests for the same boat.</div>
              <div className={_styles.fz_p}>
                The buyer who completes the survey payment or the boat payment first will get the boat.
              </div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/broker-how-2.png')} alt="Receive one or more buyer requests for the same boat." fluid />
              </div>
            </li>
            <li>
              <div>Track which stages the buyer reaches in buying the boat.</div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/broker-how-3.png')} alt="Track which stages the buyer reaches in buying the boat." fluid />
              </div>
            </li>
            <li>
              <div>When the shipper picks up the boat from the seller, AdamSea releases the boat payment.</div>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  )
}

export default BrokerInfo
