import React, { Component } from 'react'
import { connect } from 'react-redux'

import { clearSalesEngines, clearSalesEngineShipperDashboardFlags } from '../../../redux/actions/salesEngineAction'
import { Layout } from '../../../components'
import { userRoleTypes, userRoles } from '../../../util/enums/enums'
import ShipperSalesEngine from './ShipperSalesEngine'
import SellerSalesEngine from './SellerSalesEngine'
import { SuccessNotify } from '../../../helpers/notification'
import AgentSalesEngine from './AgentSalesEngine'

import '../../../styles/manageDashboardTableResponsive.scss'
import SurveyorSalesEngine from './SurveyorSalesEngine'
import IconSEArrowLeft from '../process/left-arrow-svgrepo-com'

class SalesEngineRequest extends Component {
  state = {
    isBuyItNow: false,
    branchId: '',
    isDealerAgent: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      getSalesEngineSuccess,
      salesEngine,
      history,
      addShipmentProposalSuccess,
      clearSalesEngineShipperDashboardFlags,
      shipperDeclineShipperRequestSuccess,
      currentUser,
      location,
    } = nextProps

    if (getSalesEngineSuccess && salesEngine && salesEngine.id) {
      history && history.push(`/sales-engine-process/${salesEngine.id}`)
    }
    if (addShipmentProposalSuccess) {
      SuccessNotify('Shipment Proposal Sent Successfully.')
    }

    if (
      (currentUser && currentUser.role && currentUser.role.aliasName === userRoles.SURVEYOR) ||
      (currentUser && currentUser.role && currentUser.role.aliasName === userRoles.YACHT_SHIPPER)
    ) {
      if (shipperDeclineShipperRequestSuccess) {
        SuccessNotify('Request Decline Successfully.')
      }
    }

    if (location && location.search) {
      const urlParams = new URLSearchParams(location.search)

      if (urlParams.has('branchId')) {
        const branchId = urlParams.get('branchId')
        return { branchId }
      }
    }
    if (history.location && history.location.search) {
      return {
        isBuyItNow: history.location.search === '?buyItNow=true',
      }
    }

    return null
  }

  componentWillUnmount() {
    const { clearSalesEngines } = this.props
    clearSalesEngines()
  }

  render() {
    const { history, currentUser } = this.props
    const { branchId } = this.state
    return (
      <Layout
        className={`sales-engin-layout-design ${
          currentUser?.role?.aliasName === userRoles.SURVEYOR ? 'not--scroll-auto' : ''
        } `}
      >
        <div style={{ maxWidth: 1280, margin: 'auto' }}>
          <button className="btn btn-ghost" style={{ margin: '20px 40px' }} onClick={() => history.goBack()}>
            <IconSEArrowLeft style={{ height: "1em" }} /> Back
          </button>
          {currentUser && currentUser.role && currentUser.role.aliasName && (
            <>
              {currentUser.role.type === userRoleTypes.AGENT && <AgentSalesEngine history={history} />}
              {currentUser.role.type === userRoleTypes.SELLER && <SellerSalesEngine history={history} />}
              {currentUser.role.aliasName === userRoles.YACHT_SHIPPER && <ShipperSalesEngine history={history} />}
              {currentUser.role.aliasName === userRoles.SURVEYOR && (
                <SurveyorSalesEngine history={history} branchId={branchId} />
              )}
            </>
          )}
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser,
  salesEngine: state.salesEngineReducer.salesEngine,
  getSalesEngineSuccess: state.salesEngineReducer.getSalesEngineSuccess,
  addShipmentProposalSuccess: state.salesEngineReducer.addShipmentProposalSuccess,
  addShipmentProposalError: state.salesEngineReducer.addShipmentProposalError,
  surveyorAcceptSuccess: state.salesEngineReducer.surveyorAcceptSuccess,
  surveyorDeclineSuccess: state.salesEngineReducer.surveyorDeclineSuccess,
  shipperAcceptShipperRequestSuccess: state.salesEngineReducer.shipperAcceptShipperRequestSuccess,
  shipperDeclineShipperRequestSuccess: state.salesEngineReducer.shipperDeclineShipperRequestSuccess,
})

const mapDispatchToProps = dispatch => ({
  clearSalesEngines: () => dispatch(clearSalesEngines()),
  clearSalesEngineShipperDashboardFlags: data => dispatch(clearSalesEngineShipperDashboardFlags(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEngineRequest)
