import moment from 'moment'
import Datetime from 'react-datetime'

// FORMAT : Jan, 2020
export const monthYearDateFormat = date => {
  return date ? moment(date).format('MMM YYYY') : null
}

// FORMAT : January 2020
export const reviewDateFormate = date => {
  return moment(date).format('MMMM YYYY')
}

// FORMAT : 1st January, 2020
export const formattedDate = (date, isShortFormate = false) => {
  if (isShortFormate) {
    return date ? moment(date).format('ll') : null
  }
  return date ? moment(date).format('Do MMMM, YYYY') : null
}

// FORMAT : 1 January, 2020
export const formattedDateArticle = date => {
  return date ? moment(date).format('DD MMMM, YYYY') : null
}

// FORMAT : 26/12/2020
export const formattedRequestDate = date => {
  return date ? moment(date).format('DD MMM, YYYY') : null
}

// FORMAT: 14 Feb, 2020
export const rentDateDisplayFormat = date => {
  return moment(date).format('DD MMM, YYYY')
}

// FORMAT: 05 : 30 AM
export const chatTimeFormat = date => {
  return moment(date).format('hh:mm a')
}

// DATE FORMAT: 14 Feb 2020
// TIME FORMAT : 05 : 30 am
export const boatShowCardDateTimeFormat = (date, isTime = false) => {
  const format = isTime ? 'hh : mm a' : 'DD MMM YYYY'
  return moment(date).format(format)
}

export const bidHistoryTime = date => {
  return moment(date).format('hh : mm : ss')
}

// FORMAT: 01 Sep 20, 10 : 30 am
export const auctionTime = (date, format = 'DD MMM YY, hh : mm a') => {
  return moment(date).format(format)
}

export const disablePastDates = current => {
  return moment(current).isSameOrAfter(moment(), 'day');
}

export const datesFromToday = (current) => {
  const yesterday = Datetime.moment().subtract(1, 'day')
  const today = Datetime.moment()

  return current.isAfter(yesterday)
}

export const disableFutureDates = current => {
  return current.isBefore(Datetime.moment())
}

//check date is before current date or not
export const checkDateIsBeforeCurrent = date => {
  return moment(date).isBefore(moment(new Date()))
}

//check date is after current date or not
export const checkDateIsAfterCurrent = date => {
  return moment(date).isAfter(moment(new Date()))
}

//start date is before end date check
export const startDateIsBeforeEndDate = (startDate, endDate) => {
  return moment(startDate).isBefore(moment(endDate))
}

export const timeLeftForAuction = endTime => {
  const diff = moment(endTime) - moment(new Date())

  let timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }

  if (diff > 0) {
    timeLeft = {
      days: moment.duration(diff).days(),
      hours: moment.duration(diff).hours(),
      minutes: moment.duration(diff).minutes(),
      seconds: moment.duration(diff).seconds(),
    }
  }
  return timeLeft
}
