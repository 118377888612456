import React from 'react'
import Truncate from 'react-truncate'
import Rating from 'react-rating'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import RatingComponent from '../rating/Rating'
import { Grid } from '@material-ui/core'
import { getAddress, redirectToUserProfile } from '../../helpers/jsxHelper'
import ReadMore from '../helper/truncate'
import { Icons } from '../icons'
import { defaultProfileIcon } from '../../util/enums/enums'
import ReportModal from '../modal/reportModal'
import { reviewDateFormate } from '../../helpers/dateTimeHelper'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { withRouter } from 'react-router'

class UserReviewClass extends React.Component {
  state = {
    openReportModal: false,
  }

  setReportModal = data => {
    this.setState({
      openReportModal: data,
    })
  }

  render() {
    const { value } = this.props
    const {
      commonAddress: { city, country },
    } = value && getAddress(value.user && value.user.address)
    const { openReportModal } = this.state
    const userId = getLocalStorageItem('userId')

    return (
      <div className="boat-inner-responsive">
        <Grid container className="review--container">
          <Grid item xs={12}>
            <Grid item xs={12}>
              <div className="d-flex align-items-start">
                <div>
                  <div className="mr-4 ml-0 mb-0 mt-0 rentInnerReview-imgDiv">
                    <img
                      src={(value && value.user && value.user.image?.url) || defaultProfileIcon}
                      className="radius-4 h-100 width-100 rounded-circle"
                      alt="Profile"
                      onClick={() => redirectToUserProfile(value.user, this.props.history)}
                    />
                  </div>
                </div>

                <div className="width-100">
                  <div className="rentInnerReview-nameRating justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="rentInnerReview-time sm-font inner--reviwer--date">
                        {' '}
                        {reviewDateFormate(value && value.createdAt)}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between width-100">
                      <span className="review-star sm float-left review-star-section inner--review--star--section">
                        <RatingComponent rating={value.rating} className="rating-clr" />
                      </span>
                    </div>
                  </div>

                  <span className="review-desc review--description">
                    <ReadMore className="text-justify">{value.reviews}</ReadMore>
                  </span>

                  <div className=" mt-2 rent-charter-venu-view">
                    <p className="reviewer-name-section inner--reviewer-name">{value && value.user && value.user.firstName}</p>
                    <span className="mb-0 reviwer--city--country">
                      {city} • {country}
                    </span>
                    {userId && value?.user?.id !== userId && (
                      <span
                        className="ml-1 sm-font cursor-pointer inner--review--flag"
                        onClick={() => this.setReportModal(true)}
                      >
                        <Icons data="adam-country reviwer--flag" />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <ReportModal
          open={openReportModal}
          closeModal={() => this.setReportModal(false)}
          moduleId={value.id}
          moduleType={'REVIEW'}
        />
      </div>
    )
  }
}

export const UserReview = withRouter(UserReviewClass)
