import React from 'react'
import { Modal, Backdrop, Fade } from '@material-ui/core'
import './branchDetailModal.scss'
import { CommonTooltip } from '../CommonTooltip'

export const CommonModal = props => {
  const {
    open,
    close,
    title,
    className,
    titleIcon,
    modalClassName,
    hideCloseIcon,
    contentClassName,
    htmlData,
    overflowVisible = false,
    overflowAuto = true,
  } = props
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`${modalClassName} common-modal-ui`}
        open={open}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            className={`${className} ${
              overflowVisible ? 'overflow-visible' : 'overflow-hidden'
            } common-modal-paper common--modal--section d-flex flex-column`}
          >
            <div className={overflowAuto ? 'overflow-auto' : ''} style={{ flex: 1 }}>
              {title && (
                <h2
                  className={
                    titleIcon
                      ? 'd-flex align-items-center no--bg-common transition-modal-title-common text-center'
                      : 'no--bg-common transition-modal-title-common text-center'
                  }
                  id="transition-modal-title"
                >
                  {titleIcon && (
                    <img loading="lazy" src={require('../../assets/images/boatInner/deposit.png')} alt="Deposit" className="deposit--icon" />
                  )}
                  {title}

                  {!hideCloseIcon && (
                    <CommonTooltip component={ctProps => (
                      <img
                        data-tooltip-id={ctProps.id}
                        data-tooltip-content="Close"
                        src={require('../../assets/images/boatInner/close.svg')}
                        alt="Close icon"
                        onClick={close}
                        className="common--closee--img common--close"
                      />
                    )} />
                  )}
                </h2>
              )}
              <div
                className={['common-modal-content', contentClassName].filter(Boolean).join(' ')}
                dangerouslySetInnerHTML={htmlData ? { __html: htmlData } : undefined}
              >
                {htmlData ? undefined : props.children}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

CommonModal.defaultProps = {
  className: '',
  hideCloseIcon: false,
}
