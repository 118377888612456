import {
  CREATE_BOAT_SHOW,
  CREATE_BOAT_SHOW_SUCCESS,
  CREATE_BOAT_SHOW_FAILURE,
  CLEAR_BOAT_SHOW_FLAG,
  GET_USER_BOAT_SHOWS,
  GET_USER_BOAT_SHOWS_SUCCESS,
  GET_USER_BOAT_SHOWS_FAILURE,
  GET_ALL_BOAT_SHOW,
  GET_ALL_BOAT_SHOW_SUCCESS,
  GET_ALL_BOAT_SHOW_FAILURE,
  SEARCH_BOAT_SHOW,
  SEARCH_BOAT_SHOW_SUCCESS,
  SEARCH_BOAT_SHOW_FAILURE,
  UPDATE_BOAT_SHOW,
  UPDATE_BOAT_SHOW_FAILURE,
  UPDATE_BOAT_SHOW_SUCCESS,
  GET_SINGLE_BOAT_SHOW,
  GET_SINGLE_BOAT_SHOW_SUCCESS,
  GET_SINGLE_BOAT_SHOW_FAILURE,
  DELETE_BOAT_SHOW,
  DELETE_BOAT_SHOW_SUCCESS,
  DELETE_BOAT_SHOW_FAILURE,
  CLEAR_EDIT_BOAT_SHOW,
  CLEAR_SEARCH_BOAT_SHOW_FLAG,
  TOGGLE_BOAT_SHOW_STATUS,
  TOGGLE_BOAT_SHOW_STATUS_SUCCESS,
  TOGGLE_BOAT_SHOW_STATUS_FAILURE,
} from '../actionTypes'

const InitialState = {
  loading: false,
  createSuccess: false,
  createError: false,
  boatShows: [],
  success: false,
  error: false,
  allBoatShows: [],
  updateSuccess: false,
  updateError: false,
  deleteSuccess: false,
  deleteError: false,
  editBoatShow: {},
  getSuccess: false,
  getError: false,
  isLoading: false,

  searchedBoatShowData: [],
  totalSearchedBoatShowData: null,
  isBoatShowSearched: false,
}

export const boatShowReducer = (state = InitialState, action) => {
  const { boatShows } = state

  switch (action.type) {
    case CREATE_BOAT_SHOW:
      return {
        ...state,
        createSuccess: false,
        createError: false,
      }
    case CREATE_BOAT_SHOW_SUCCESS:
      return {
        ...state,
        createSuccess: true,
        createError: false,
      }
    case CREATE_BOAT_SHOW_FAILURE:
      return {
        ...state,
        createSuccess: false,
        createError: true,
      }
    case CLEAR_BOAT_SHOW_FLAG:
      return {
        ...state,
        createSuccess: false,
        success: false,
        error: false,
        updateSuccess: false,
        updateError: false,
        createError: false,
        getSuccess: false,
        getError: false,
        deleteSuccess: false,
        deleteError: false,
      }
    case GET_USER_BOAT_SHOWS:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
        boatShows: [],
      }
    case GET_USER_BOAT_SHOWS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        boatShows: action.payload.data.getAllBoatShowByUser.items,
        boatShowsTotal: action.payload.data.getAllBoatShowByUser.total,
      }
    case GET_USER_BOAT_SHOWS_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        boatShows: [],
      }

    case SEARCH_BOAT_SHOW:
      return {
        ...state,
        success: false,
        error: false,
        isLoading: true,
        isBoatShowSearched: false,
        // searchedBoatShowData: [],
        isPageLoader: true,
      }
    case SEARCH_BOAT_SHOW_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        isLoading: false,
        isBoatShowSearched: true,
        searchedBoatShowData: action.payload.items,
        totalSearchedBoatShowData: action.payload.total,
        isPageLoader: false,
      }
    case SEARCH_BOAT_SHOW_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        isLoading: false,
        isBoatShowSearched: false,
        searchedBoatShowData: [],
        isPageLoader: false,
      }
    case CLEAR_SEARCH_BOAT_SHOW_FLAG:
      return {
        ...state,
        isBoatShowSearched: false,
      }

    case GET_ALL_BOAT_SHOW:
      return {
        ...state,
        success: false,
        error: false,
        isPageLoader: true,
      }
    case GET_ALL_BOAT_SHOW_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        allBoatShows: action.payload.data.getAllBoatShow.items,
        allBoatShowCount: action.payload.data.getAllBoatShow.total,
        isPageLoader: false,
      }
    case GET_ALL_BOAT_SHOW_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        isPageLoader: false,
      }
    case UPDATE_BOAT_SHOW:
      return {
        ...state,
        updateSuccess: false,
        updateError: false,
      }
    case UPDATE_BOAT_SHOW_SUCCESS:
      return {
        ...state,
        updateSuccess: true,
        updateError: false,
      }
    case UPDATE_BOAT_SHOW_FAILURE:
      return {
        ...state,
        updateSuccess: false,
        updateError: true,
      }
    case GET_SINGLE_BOAT_SHOW:
      return {
        ...state,
        getSuccess: false,
        getError: false,
        isLoading: true,
        editBoatShow: {},
      }
    case GET_SINGLE_BOAT_SHOW_SUCCESS:
      return {
        ...state,
        getSuccess: true,
        getError: false,
        isLoading: false,
        editBoatShow: action.payload.data.editBoatShow,
      }
    case GET_SINGLE_BOAT_SHOW_FAILURE:
      return {
        ...state,
        getSuccess: false,
        getError: true,
        isLoading: false,
      }

    case DELETE_BOAT_SHOW:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
        isLoading: true,
      }
    case DELETE_BOAT_SHOW_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
        isLoading: false,
        boatShows: boatShows.filter(item => item.id !== action.payload.id),
      }
    case DELETE_BOAT_SHOW_FAILURE:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
        isLoading: false,
      }

    case TOGGLE_BOAT_SHOW_STATUS:
      return {
        ...state,
        statusChanged: false,
      }
    case TOGGLE_BOAT_SHOW_STATUS_SUCCESS:
      return {
        ...state,
        statusChanged: true,
        boatShows: boatShows.map(item => (item.id === action.payload.id ? { ...item, status: !item.status } : item)),
      }
    case TOGGLE_BOAT_SHOW_STATUS_FAILURE:
      return {
        ...state,
      }

    case CLEAR_EDIT_BOAT_SHOW:
      return {
        ...state,
        editBoatShow: {},
      }
    default:
      return state
  }
}
