import React from 'react'
import Select from 'react-select'

import './multiSelect.scss'
import { HelpIconComponent } from './helpIconComponent'

class SingleSelect extends React.Component {
  render() {
    const { className, name, selectedOption, onChange, options, placeholder, label, helpText, required, disabled, styles, cx } = this.props

    return (
      <div className={`${cx}`}>
        {label && (
          <label className={required ? 'required mr-1' : 'mr-1'}>
            <span className="help--icon--position--lb-tool">
              {label}
              <HelpIconComponent helpText={helpText} containerClass="single-select--help" />
            </span>
          </label>
        )}

        <Select
          name={name}
          isClearable={false}
          placeholder={placeholder}
          value={selectedOption}
          onChange={value => onChange(value || {})}
          options={options}
          classNamePrefix="single-select-control"
          className={className}
          styles={styles}
          isDisabled={disabled}
        />
      </div>
    )
  }
}

export default SingleSelect
SingleSelect.defaultProps = {
  placeholder: '',
  label: '',
  required: false,
  options: [],
  helpText: '',
  iconTextClasses: '',
  disabled: false,
}
