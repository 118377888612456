import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import { Box, Grid } from '@material-ui/core'

import { DashboardLayout, BoatCard, Layout } from '../../../components'
import { getAllWishlists, removeWishlist } from '../../../redux/actions'
import { viewBoatHandler, viewMarinaDetails, viewRentBoatHandler, viewServiceBoatHandler } from '../../../helpers/boatHelper'
import { getModuleType, noDataResult } from '../../../util/enums/enums'
import { addToWishlist } from '../../../helpers/methodHelper'
import { BoatServiceMapCard } from '../../../components/boat/boatServiceMapCard'
import { BoatShowCard } from '../../../components/boat/boatShowCard'
import './managewishlist.scss'
import { RentBoatMapCard } from '../../../components/boat/rentBoatMapCard'
import { MarinaStorageMapCard } from '../../../components/marinaStorage/marinaStorageMapCard'

class ManageWishlist extends Component {
  componentDidMount() {
    const { getAllWishlists } = this.props
    getAllWishlists()
  }

  removeFromList = (moduleId, moduleType) => {
    const { removeWishlist } = this.props

    const type = getModuleType(moduleType)

    addToWishlist(removeWishlist, moduleId, type)
  }

  componentDidUpdate() {
    const { allWishlists, history } = this.props
    if (allWishlists.totalCounts === 0) {
      history.push('/')
    }
  }

  render() {
    const { allWishlists, history, currentUser } = this.props

    const LayoutType = currentUser && currentUser.id ? DashboardLayout : Layout

    return (
      <LayoutType className="manage-wish-list-layout dashboard-layout-responsive adam-dash-mob-res manage-wish-list-layout">
        <Tabs defaultActiveKey="all" id="search-result" className="search-result-nav-div mt-10 ml-10 ">
          <Tab eventKey="all" title="All">
            <Grid item xs={12} className="all-grid-wishlist">
              <div className="mt-3 pb-3 rating-start-small-size search-result-nav-main-section">
                <Grid container className="mt-70 ">
                  {allWishlists && allWishlists.all && allWishlists.all.length ? (
                    allWishlists.all.map((value, index) => {
                      return (
                        <Fragment key={value.id}>
                          {/* Boat */}
                          {value.moduleType === 'Boat' && value.moduleId && (
                            <Grid item className="left-card boat--wishlist" xs={6}>
                              <BoatCard
                                index={index}
                                boat={value.moduleId}
                                onClick={() => viewBoatHandler(value.moduleId)}
                                isWishlist
                                removeFromList={() => this.removeFromList(value.moduleId.id, value.moduleType)}
                              />
                            </Grid>
                          )}
                          {/* Boat */}

                          {/* Marina */}
                          {value.moduleType === 'Marina' && value.moduleId && (
                            <Grid item xs={6} className="rating-start-small-size search-result-nav-main-section">
                              <div className="left-card is-dashboard-marina boat--wishlist boat--marina--wishlist">
                                <MarinaStorageMapCard
                                  marinaStorage={{ ...value.moduleId }}
                                  xs={12}
                                  index={index}
                                  isWishlist
                                  viewMarina={() => viewMarinaDetails(value.moduleId)}
                                  textColor="iconColor-marina"
                                  removeFromList={() => this.removeFromList(value.moduleId.id, value.moduleType)}
                                />
                              </div>
                            </Grid>
                          )}
                          {/* Marina */}

                          {/* Rent */}
                          {value.moduleType === 'BoatRent' && value.moduleId && (
                            <Grid
                              item
                              xs={6}
                              className="rating-start-small-size search-result-nav-main-section all-wishlists-rent"
                            >
                              <div container className=" boat--wishlist left-card">
                                <RentBoatMapCard
                                  index={index}
                                  boat={value.moduleId}
                                  isWishlist
                                  onClick={() => viewRentBoatHandler(value.moduleId)}
                                  removeFromList={() => this.removeFromList(value.moduleId.id, value.moduleType)}
                                />
                              </div>
                            </Grid>
                          )}
                          {/* Rent */}
                          {/* Boat Service */}
                          {value.moduleType === 'Yacht' && value.moduleId && (
                            <Grid
                              item
                              xs={6}
                              className="rating-start-small-size search-result-nav-main-section boat-service-wishlist-grid is-show-map"
                            >
                              <div className="left-card boat--wishlist  boat--service--wishlist">
                                {/* <div className="boat-card-overflow-visible"> */}
                                <BoatServiceMapCard
                                  value={value.moduleId}
                                  isWishlist
                                  removeFromList={() => this.removeFromList(value.moduleId.id, value.moduleType)}
                                  onClick={() => viewServiceBoatHandler(value.moduleId)}
                                />
                                {/* </div> */}
                              </div>
                            </Grid>
                          )}
                          {/* Boat Service */}

                          {value.moduleType === 'BoatShow' && value.moduleId && (
                            <Grid
                              item
                              xs={6}
                              className="rating-start-small-size search-result-nav-main-section boat-service-wishlist-grid is-show-map"
                            >
                              <div className="left-card  boat--wishlist boat--show--wishlist">
                                <BoatShowCard
                                  isWishlist
                                  boat={value.moduleId}
                                  removeFromList={() => this.removeFromList(value.moduleId.id, value.moduleType)}
                                />
                              </div>
                            </Grid>
                          )}
                        </Fragment>
                      )
                    })
                  ) : (
                    <Grid className="ml-3 pl-2" item xs={12}>
                      <Box className='no--result--found--text'>
                        <img src={require('../../../assets/images/search/no_data_found.jpeg')} alt='No data'/>
                        <p>{noDataResult}</p>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          </Tab>
          <Tab eventKey="boat" title="Boat">
            <Grid item xs={12}>
              <div className="mt-3 pb-3 rating-start-small-size search-result-nav-main-section">
                <Grid container className="mt-70 ">
                  {allWishlists && allWishlists.boat && allWishlists.boat.length ? (
                    allWishlists.boat.map((value, index) => {
                      return (
                        <Fragment key={value.id}>
                          {value.moduleId && (
                            <Grid item className="left-card boat--wishlist" xs={6}>
                              <BoatCard
                                index={index}
                                boat={value.moduleId}
                                onClick={() => viewBoatHandler(value.moduleId)}
                                isWishlist
                                removeFromList={() => this.removeFromList(value.moduleId.id, value.moduleType)}
                              />
                            </Grid>
                          )}
                        </Fragment>
                      )
                    })
                  ) : (
                    <Grid className="ml-3 pl-2" item xs={12}>
                      No records found
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          </Tab>
          <Tab eventKey="marina" title="Marina">
            <div className="mt-3 pb-3 rating-start-small-size search-result-nav-main-section">
              <Grid container spacing={2} className="left-card is-dashboard-marina boat--wishlist boat--marina--wishlist">
                {allWishlists && allWishlists.marina && allWishlists.marina.length ? (
                  allWishlists.marina.map((value, index) => {
                    return (
                      <Fragment key={value.id}>
                        {value.moduleId && (
                          <Grid item xs={6}>
                            <MarinaStorageMapCard
                              marinaStorage={{ ...value.moduleId }}
                              xs={12}
                              index={index}
                              isWishlist
                              viewMarina={() => viewMarinaDetails(value.moduleId)}
                              textColor="iconColor-marina"
                              removeFromList={() => this.removeFromList(value.moduleId.id, value.moduleType)}
                            />
                          </Grid>
                        )}
                      </Fragment>
                    )
                  })
                ) : (
                  <Grid className="ml-3 pl-2" item xs={12}>
                    <Box className='no--result--found--text'>
                      <img src={require('../../../assets/images/search/no_data_found.jpeg')} alt='No data'/>
                      <p>{noDataResult}</p>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </div>
          </Tab>
          <Tab eventKey="service" title="Service">
            <div className="mt-3 pb-3 rating-start-small-size search-result-nav-main-section boat-service-wishlist-grid is-show-map">
              <Grid container spacing={2} className="boat--wishlist left-card boat--service--wishlist">
                {allWishlists && allWishlists.yacht && allWishlists.yacht.length ? (
                  allWishlists.yacht.map((value, index) => {
                    return (
                      <Fragment key={value.id}>
                        {value.moduleId && (
                          <Grid className="boat-card-overflow-visible" item xs={6}>
                            <BoatServiceMapCard
                              value={value.moduleId}
                              isWishlist
                              removeFromList={() => this.removeFromList(value.moduleId.id, value.moduleType)}
                              onClick={() => viewServiceBoatHandler(value.moduleId)}
                            />
                          </Grid>
                        )}
                      </Fragment>
                    )
                  })
                ) : (
                  <Grid className="ml-3 pl-2" item xs={12}>
                    <Box className='no--result--found--text'>
                      <img src={require('../../../assets/images/search/no_data_found.jpeg')} alt='No data'/>
                      <p>{noDataResult}</p>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </div>
          </Tab>
          <Tab eventKey="rent" title="Rent">
            <div className="mt-3 pb-3 rating-start-small-size search-result-nav-main-section all-wishlists-rent">
              <Grid container spacing={2} className="left-card boat--wishlist">
                {allWishlists && allWishlists.boatrent && allWishlists.boatrent.length ? (
                  allWishlists.boatrent.map((value, index) => {
                    return (
                      <Fragment key={value.id}>
                        {value.moduleId && (
                          <Grid item xs={6}>
                            <RentBoatMapCard
                              index={index}
                              boat={value.moduleId}
                              isWishlist
                              onClick={() => viewRentBoatHandler(value.moduleId)}
                              removeFromList={() => this.removeFromList(value.moduleId.id, value.moduleType)}
                            />
                          </Grid>
                        )}
                      </Fragment>
                    )
                  })
                ) : (
                  <Grid className="ml-3 pl-2" item xs={12}>
                    <Box className='no--result--found--text'>
                      <img src={require('../../../assets/images/search/no_data_found.jpeg')} alt='No data'/>
                      <p>{noDataResult}</p>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </div>
          </Tab>
          <Tab eventKey="boatshow" title="Boat Show">
            <div className="mt-3 pb-3 rating-start-small-size search-result-nav-main-section all-wishlists-rent">
              <Grid container spacing={2} className="boat--wishlist left-card boat--show--wishlist">
                {allWishlists && allWishlists.boatshow && allWishlists.boatshow.length ? (
                  allWishlists.boatshow.map(value => {
                    return (
                      <Fragment key={value.id}>
                        {value.moduleId && (
                          <Grid item xs={6}>
                            <BoatShowCard
                              isWishlist
                              boat={value.moduleId}
                              removeFromList={() => this.removeFromList(value.moduleId.id, value.moduleType)}
                            />
                          </Grid>
                        )}
                      </Fragment>
                    )
                  })
                ) : (
                  <Grid className="ml-3 pl-2" item xs={12}>
                    <Box className='no--result--found--text'>
                      <img src={require('../../../assets/images/search/no_data_found.jpeg')} alt='No data'/>
                      <p>{noDataResult}</p>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </div>
          </Tab>
        </Tabs>
      </LayoutType>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser,
  allWishlists: state.dashboardReducer.allWishlists,
  removeWishlistSuccess: state.dashboardReducer && state.dashboardReducer.removeWishlistSuccess,
})

const mapDispatchToProps = dispatch => ({
  getAllWishlists: data => dispatch(getAllWishlists(data)),
  removeWishlist: data => dispatch(removeWishlist(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageWishlist)
