import React from 'react'
import cn from 'classnames'

/**
 * @param {React.SVGProps<SVGSVGElement>} props
 */
export default function IconPlus(props) {
  return (
    <svg
      {...props}
      className={cn('as-media-icon', props.className)}
      width="1.25em"
      height="1.25em"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M23,11H13V1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1V11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H11V23a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13H23a1,1,0,0,0,1-1h0A1,1,0,0,0,23,11Z" />
    </svg>
  )
}
