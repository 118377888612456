import { CREATE_REVIEW, GET_REVIEW_BY_MODULE_ID, CLEAR_REVIEW_FLAG, ADD_FEEDBACK, CLEAR_FEEDBACK_FLAG } from '../actionTypes'

export const createReview = data => ({
  type: CREATE_REVIEW,
  payload: data,
})

export const getReviewByModuleId = data => ({
  type: GET_REVIEW_BY_MODULE_ID,
  payload: data,
})

export const clearReviewFlag = () => ({
  type: CLEAR_REVIEW_FLAG,
})

export const addFeedback = data => ({
  type: ADD_FEEDBACK,
  payload: data,
})

export const clearFeedbackFlag = () => ({
  type: CLEAR_FEEDBACK_FLAG,
})
