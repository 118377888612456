import gql from 'graphql-tag'

export const getAllUserGuide = gql`
  query getAllUserGuidList($page: Int, $limit: Int) {
    getAllUserGuidList(page: $page, limit: $limit) {
      items {
        status
        id
        title
        description
      }
    }
  }
`
