import { dimensionAspectRatio } from '../util/utilFunctions'
import { isBrowser, isTablet } from 'react-device-detect'
import { getMarginGrid } from './jsxHelper'

/**
 *
 * Return same height and width
 *
 */
export const getRatio = (dimension, key, className) => {
  const width = document.querySelector(className)
  const actualWidth = width && width.offsetWidth / dimension[key].divide - dimension[key].margin
  return actualWidth
}
/**
 *
 * Return height ratio according window width
 *
 */
export const getHeightRatio = (dimension, key, className) => {
  const actualWidth = getRatio(dimension, key, className)
  const aspectRatio = dimensionAspectRatio(dimension[key].width, dimension[key].height)
  const newHeight = actualWidth / aspectRatio - dimension[key].marginHeight

  return newHeight
}
/**
 *
 * Return height ratio according width ratio
 *
 */
export const getHeightRatioRespectWidth = (dimension, key, className) => {
  const actualWidth = getWidthRatio(dimension, key, className)
  const aspectRatio = dimensionAspectRatio(dimension[key].respectRatioWidth, dimension[key].respectRatioHeight)
  const newHeight = actualWidth / aspectRatio

  return newHeight
}
/**
 *
 * Return width ratio according divided width
 *
 */
export const getWidthRatio = (dimension, key, className) => {
  const width = document.querySelector(className)
  const actualWidth = width && width.offsetWidth / dimension[key].divide - dimension[key].margin
  const widthAspectRatio = dimensionAspectRatio(dimension[key].width, dimension[key].height)
  const newWidth = actualWidth / widthAspectRatio
  return newWidth
}
export const getMarginRatio = (item, className) => {
  const width = document.querySelector(className)
  const actualWidth = width && width.offsetWidth
  const marginRation = dimensionAspectRatio(1680.3, 167.5)
  const newMargin = actualWidth / marginRation
  return newMargin / item
}

//Get carousal height:
export const getCarousalHeight = (dimension, items, customWidthItem, customWidth) => {
  const dividedBy = customWidth ? customWidthItem : isBrowser ? items.isBrowser : isTablet ? items.isTablet : items.isMobile
  const width = document.querySelector('#section-heading')
  const actcualWidth = width && width.offsetWidth / dividedBy
  /* dimensionAspectRatio(width, height) */
  const aspectRatio = dimensionAspectRatio(295, 230)
  const newHeight = actcualWidth / aspectRatio - 5

  return newHeight
}

/**
 *
 * Zoom Width
 *
 */
export const getRatioZoom = (dimension, key, className, isBestMarket) => {
  const width = document.querySelector('.zoom-container')
  const newWidth = width && width.offsetWidth + 14
  const newDivided = width && (newWidth < 1060 ? 3 : newWidth > 1345 ? dimension[key].divide : 4)
  const actualWidth = width && newWidth / newDivided
  const margin = 14 / newDivided
  const finalResult = actualWidth - margin
  return isBestMarket ? finalResult + 1 : finalResult - 1
}

/**
 *
 * Zoom height ratio according window width
 *
 */
export const getHeightZoom = (dimension, key, className, isBestMarket) => {
  const actualWidth = getRatioZoom(dimension, key, className)
  const aspectRatio = dimensionAspectRatio(dimension[key].width, dimension[key].height)
  const newHeight = actualWidth / aspectRatio

  return newHeight
}

/**
 *
 * Zoom Width with responsive
 *
 */
export const getRatioZoomResponsive = (dimension, key, className) => {
  const width = document.querySelector(className)
  const newWidth = width && width.offsetWidth
  const newDivided =
    width && (newWidth < 1060 ? dimension[key].smDivide : newWidth > 1345 ? dimension[key].divide : dimension[key].mdDivide)

  const responsiveMargin = width && getMarginGrid(width.offsetWidth, dimension[key].margin)
  const actualWidth = width && newWidth / newDivided
  const actualMargin = responsiveMargin / newDivided
  return {
    width: actualWidth - (responsiveMargin - actualMargin) - 0.2,
    className: `grid--new--${newDivided}`,
    margin: responsiveMargin,
  }
}

/**
 *
 * Zoom height with responsive
 *
 */
export const getHeightZoomResponsive = (dimension, key, className) => {
  const actualWidth = getRatioZoomResponsive(dimension, key, className)
  const aspectRatio = dimensionAspectRatio(dimension[key].width, dimension[key].height)
  const newHeight = actualWidth.width / aspectRatio

  return newHeight
}
