import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'

import { CommonModal } from '../modal/commonModal'
import { ButtonComponent } from '../form/Button'
import { skeletonType, sharePopupMessage, manufacturerStatus, userAssigned } from '../../util/enums/enums'
import { SkeletonLoader } from '../loader/SkeletonLoader'
import { getFirstCharacter, redirectToUserProfile } from '../../helpers/jsxHelper'
import { nameFormatter } from '../../helpers/string'
import { stringValidation } from '../../helpers/yupHelper'
import './SearchUser.scss'

const SearchUser = props => {
  const {
    isOpen,
    onClose,
    searchedUser,
    isLoading,
    currentUser,
    requestManufacturer,
    requestMultipleManufacturer,
    searchManufacturerRequest,
    history,
    requestSuccess,
    multipleRequestSuccess,
  } = props

  const findAvailability = user => {
    const dealer = user?.dealerRequests?.find(item => item?.dealer?.id === currentUser?.id)
    if (dealer?.accepted) {
      return manufacturerStatus.accepted
    } else if (dealer && !dealer.accepted) {
      return manufacturerStatus.requested
    } else {
      return manufacturerStatus.request
    }
  }

  useEffect(() => {
    searchManufacturerRequest('')
  }, [])

  const [selectedUsers, setSelectedUsers] = useState([])

  useEffect(() => {
    searchManufacturerRequest('')

    if (multipleRequestSuccess) {
      setSelectedUsers([])
    }
  }, [requestSuccess, multipleRequestSuccess])

  const handleAddUser = user => {
    if (user?.id && findAvailability(user) === manufacturerStatus.request) {
      if (selectedUsers.includes(user.id)) {
        setSelectedUsers(prev => prev.filter(userId => userId !== user.id))
      } else {
        setSelectedUsers(prev => [...prev, user.id])
      }
    }
  }

  return (
    <CommonModal
      className="inner--estimate--cost--div search--user--modal--section d-flex flex-column search--manufacture--modal"
      title="Search Manufacturers"
      open={isOpen}
      close={onClose}
      isAnimatedCloseIcon
    >
      <div className={`${isLoading ? 'skeleton--form--div' : ''}`}>
        <div className={`d-flex justify-content-between`}>
          <div className="with--error-model d-flex align-items-center w-100 manufacture--search--top--div">
            <input
              className="form-control h-auto adam--share--popup--mail--div font-16"
              name="searchTerm"
              placeholder="Search manufacturer here..."
              onChange={e => {
                searchManufacturerRequest(e.target.value)
              }}
            />
            <ButtonComponent
              onClick={() => {
                if (selectedUsers?.length) {
                  requestMultipleManufacturer(selectedUsers)
                }
              }}
              className="as--success--outline btn--padding ml-2 search--manufacture--btn"
            >
              {'Send Request'}
            </ButtonComponent>
          </div>
        </div>

        <div className={`${isLoading ? 'skeleton--parent--div' : ''} adamsea--users--share mt-3`}>
          {isLoading ? (
            <SkeletonLoader type={skeletonType.userInfo} itemsLength={8} count={5} />
          ) : (
            <>
              {searchedUser && searchedUser.length > 0
                ? searchedUser.map(user => {
                    return (
                      <div
                        className={`cursor-pointer ${
                          selectedUsers.includes(user.id) && 'selected-adamsea--user'
                        } share-adamsea-user`}
                        key={user.id}
                        onClick={() => handleAddUser(user)}
                      >
                        <label htmlFor={user.id} className="d-flex mt-1 cursor-pointer">
                          <div className="d-flex align--flex--start share--adamse--logo">
                            {user.image ? (
                              <img
                                src={user?.image?.url}
                                className="view-service-img-salesman share--adamse--user--img"
                                alt="user-icon"
                                onClick={() => redirectToUserProfile(user, history)}
                              />
                            ) : (
                              getFirstCharacter(user.firstName)
                            )}

                            <div className="share--name-with-email d-flex flex-column justify-content-center">
                              <span className="share--adamse--user--name">
                                {nameFormatter([user?.firstName, user?.lastName])}
                              </span>
                              <span className="share--adamse--user--email">
                                Working {user.companyName ? `in ${user.companyName}` : `${user.profession.alias}`}
                              </span>

                              {findAvailability(user) && (
                                <ButtonComponent
                                  className="request--manufacture--btn"
                                  onClick={e => {
                                    e.stopPropagation()
                                    requestManufacturer(user.id)
                                  }}
                                  disabled={findAvailability(user) !== manufacturerStatus.request}
                                  {...(findAvailability(user) === manufacturerStatus.accepted && { color: 'edit' })}
                                >
                                  {findAvailability(user)}
                                </ButtonComponent>
                              )}
                            </div>
                          </div>
                        </label>
                      </div>
                    )
                  })
                : currentUser && currentUser.id && <div className="no--user--found mt-3">{sharePopupMessage.noUsersFound}</div>}
            </>
          )}
        </div>
      </div>
    </CommonModal>
  )
}

export default withRouter(SearchUser)
