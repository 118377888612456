import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { getAllMediaArticles } from '../../redux/actions/mediaArticleAction'
import { Layout, PaginationBar } from '../../components'
import { ArticleGrid } from '../articles/articleGrid'
import { pagination, mediaCategory } from '../../util/enums/enums'

import '../../assets/css/component/articleListing.scss'
import { getModuleWiseBanners } from '../../redux/actions'
import { CommonBannerStyle } from '../../components/styleComponent/styleComponent'
import { Grid } from '@material-ui/core'
import CommonBanner from '../../components/mainBanner/commonBanner'

class BoatArticlesList extends Component {
  componentDidMount() {
    const { getAllMediaArticles, getModuleWiseBanners } = this.props
    getAllMediaArticles({ page: pagination.PAGE_COUNT, limit: pagination.ARTICLE_PAGE_LIMIT })
    getModuleWiseBanners({ type: mediaCategory.article.type, fieldName: mediaCategory.article.fieldName })
  }

  render() {
    const { articles, total, getAllMediaArticles, articleBanner } = this.props

    return (
      <Layout className="adamsea-article-layout">
        <div className="adamsea-article-main">
          <div className="adamsea-article-main-content">
            <div className="adamsea-article-left">
              {articleBanner?.length > 0 && <div className="adamsea-article-title">{articleBanner[0].title}</div>}
              <div className="adamsea-article-left-content">
                <Grid container>
                  <div className="zoom-container">
                    {articles?.length > 0 &&
                      articles.map(article => {
                        return (
                          <Grid item sm={6}>
                            <ArticleGrid article={article} />
                          </Grid>
                        )
                      })}
                  </div>
                </Grid>
                {total > pagination.PAGE_RECORD_LIMIT && (
                  <div className="mt-3">
                    <PaginationBar action={getAllMediaArticles} totalRecords={total} />
                  </div>
                )}
              </div>
            </div>
            <div className="adamsea-article-right">
              <div className="adamsea-article-right-content">
                <div className="adamsea--categories-title">Categories</div>
                <ul className="adamsea--categories-lists">
                  <li className="adamsea--categories-item">
                    Our News <span className="categories-count">(20)</span>
                  </li>
                  <li className="adamsea--categories-item">
                    Yacht Owner <span className="categories-count">(20)</span>
                  </li>
                  <li className="adamsea--categories-item">
                    Broker News <span className="categories-count">(20)</span>
                  </li>
                  <li className="adamsea--categories-item">
                    Boat Builder <span className="categories-count">(20)</span>
                  </li>
                  <li className="adamsea--categories-item">
                    Yacht Services <span className="categories-count">(20)</span>
                  </li>
                  <li className="adamsea--categories-item">
                    Boat Survey <span className="categories-count">(20)</span>
                  </li>
                  <li className="adamsea--categories-item">
                    Rent <span className="categories-count">(20)</span>
                  </li>
                  <li className="adamsea--categories-item">
                    Marina & Storage <span className="categories-count">(20)</span>
                  </li>
                  <li className="adamsea--categories-item">
                    Logistic <span className="categories-count">(20)</span>
                  </li>
                </ul>
                <div className="adamsea--article-add">
                  <Link to="/create-article">ADD articles</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  articles: state.mediaArticleReducer && state.mediaArticleReducer.articles,
  articleBanner: state.dashboardReducer[mediaCategory.article.fieldName],
  total: state.mediaArticleReducer && state.mediaArticleReducer.total,
})

const mapDispatchToProps = dispatch => ({
  getAllMediaArticles: data => dispatch(getAllMediaArticles(data)),
  getModuleWiseBanners: type => dispatch(getModuleWiseBanners(type)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatArticlesList)
