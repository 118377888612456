import React, { Component } from 'react'
import { connect } from 'react-redux'

import { GetRecentlyAddedMarinaAndStorage, getMostViewedMarinaStorage, getTopRatedMarinaAndStorage } from '../../redux/actions'
import { pagination, showAllMarina } from '../../util/enums/enums'
import BoatListingsWithMap from '../../components/gridComponents/BoatListingsWithMap'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import UserContext from '../../UserContext'
import { getLocalStorageItem } from '../../helpers/storageHelper'

class ShowAllMarinaAndStorage extends Component {
  state = {
    country: '',
    city: '',
    type: '',
    action: null,
    isAuction: false,
    name: 'Marina Boats',
    data: {
      limit: pagination.PAGE_RECORD_LIMIT,
    },
  }

  static getDerivedStateFromProps(nextProps, state) {
    const {
      match,
      GetRecentlyAddedMarinaAndStorage,
      getMostViewedMarinaStorage,
      getTopRatedMarinaAndStorage,
      totalRecentMarinaCount,
      mostViewedMarinaStorageTotal,
      topRatedMarinaTotal,
    } = nextProps
    const { params } = match && match
    const { isAuction } = state
    const country = getLocalStorageItem('country')
    if (params && params.type && !isAuction) {
      switch (params.type) {
        case showAllMarina.recommended:
          return {
            action: GetRecentlyAddedMarinaAndStorage,
            name: `Explore ${totalRecentMarinaCount || 0} marina and storage around ${country}`,
            isAuction: false,
            type: params.type,
          }

        case showAllMarina.mostPopular:
          return {
            action: getMostViewedMarinaStorage,
            name: `${mostViewedMarinaStorageTotal || 0} Marina facilities viewed by other users`,
            isAuction: false,
            type: params.type,
          }

        case showAllMarina.topRated:
          return {
            action: getTopRatedMarinaAndStorage,
            name: `Explore ${topRatedMarinaTotal || 0} Top Rated Marina And Storage`,
            isAuction: false,
            type: params.type,
          }

        default:
          return
      }
    }

    return null
  }

  getData = type => {
    switch (type) {
      case showAllMarina.recommended:
        return this.props.recentMarinaStorage
      case showAllMarina.mostPopular:
        return this.props.mostViewed
      case showAllMarina.topRated:
        return this.props.topRatedMarinaStorage

      default:
        return
    }
  }

  getTotal = type => {
    const { totalRecentMarinaCount, mostViewedMarinaStorageTotal, topRatedMarinaTotal } = this.props

    switch (type) {
      case showAllMarina.recommended:
        return totalRecentMarinaCount
      case showAllMarina.mostPopular:
        return mostViewedMarinaStorageTotal
      case showAllMarina.topRated:
        return topRatedMarinaTotal

      default:
        return
    }
  }

  render() {
    const { action, isAuction, name, type } = this.state
    const { isPageLoader } = this.props

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        isMarinaStorage
        boatsTypeCount={this.getTotal(type) || 0}
        boatsType={name}
        isShowAuction={isAuction}
        boats={this.getData(type)}
        action={action}
        mapGridClassName="marina--service--show--all"
      />
    )
  }
}

const mapStateToProps = state => ({
  user: state.loginReducer && state.loginReducer.currentUser,
  recentMarinaStorage: state.marinaAndStorageReducer && state.marinaAndStorageReducer.recentMarinaStorage,
  totalRecentMarinaCount: state.marinaAndStorageReducer && state.marinaAndStorageReducer.totalRecentMarinaCount,
  mostViewed: state.marinaAndStorageReducer && state.marinaAndStorageReducer.mostViewedMarinaStorage,
  mostViewedMarinaStorageTotal: state.marinaAndStorageReducer && state.marinaAndStorageReducer.mostViewedMarinaStorageTotal,
  topRatedMarinaStorage: state.marinaAndStorageReducer && state.marinaAndStorageReducer.topRatedMarinaStorage,
  topRatedMarinaTotal: state.marinaAndStorageReducer && state.marinaAndStorageReducer.topRatedMarinaTotal,
  isPageLoader: state.marinaAndStorageReducer && state.marinaAndStorageReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  GetRecentlyAddedMarinaAndStorage: data => dispatch(GetRecentlyAddedMarinaAndStorage(data)),
  getMostViewedMarinaStorage: data => dispatch(getMostViewedMarinaStorage(data)),
  getTopRatedMarinaAndStorage: data => dispatch(getTopRatedMarinaAndStorage(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllMarinaAndStorage)
