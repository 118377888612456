import { GET_ALL_USER_GUIDE, GET_ALL_USER_GUIDE_SUCCESS, GET_ALL_USER_GUIDE_FAILURE } from '../actionTypes'

const InitialState = {
  userguides: [],
  success: false,
  error: false,
}

export const userGuideReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_ALL_USER_GUIDE:
      return {
        ...state,
        success: false,
        error: false,
      }
    case GET_ALL_USER_GUIDE_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        userguides: action.payload.data.getAllUserGuidList.items,
      }
    case GET_ALL_USER_GUIDE_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }

    default:
      return state
  }
}
