import randomize from 'randomatic'
import {
  stepOneStatus,
  stepTwoStatus,
  stepThreeStatus,
  stepFourStatus,
  stepFiveStatus,
  likeModuleEnum,
} from '../util/enums/enums'
import { getLocalStorageItem } from './storageHelper'

export const lowerHypenCase = name => {
  // console.log(name)
  let newName = name?.replace(/\s+/g, ' ').trim()
  // console.log(newName)
  if (newName) {
    return newName.toLowerCase().split(' ').join('-')
  }
}

export const snakeCase = name => {
  if (name) {
    return name.toLowerCase().split(' ').join('_')
  }
}

export const camelCase = name => {
  if (name) {
    return name
      .toLowerCase()
      .split('-')
      .map((w, i) => (i === 0 ? w : w[0] && w[0].toUpperCase() + w.substr(1).toLowerCase()))
      .join('')
  }
}

export const readableString = name => {
  let newName = name.replace(/\s+/g, ' ').trim()

  if (newName) {
    return newName
      .replace(/\s+$/, '')
      .toLowerCase()
      .split(' ')
      .map(w => w[0] && w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join('-')
  }
}

export const TitleCase = name => {
  return (
    name &&
    name
      .split(' ')
      .map(w => w[0].toUpperCase() + w.slice(1))
      .join(' ')
  )
}

export const verifiedCheck = (status, customMessageForUnverified = 'Unverified') => {
  return status ? 'Verified' : customMessageForUnverified
}

export const randomAdId = (prefix = '') => {
  return prefix + randomize('A0', 16 - prefix.length)
}

export const sessionId = () => {
  return randomize('Aa0', 16)
}

export const requireMessage = (label = 'Field') => {
  return `${label} is required.`
}

export const positiveNumberHelper = (label = 'Field') => {
  return `${label} value should be positive number.`
}

export const displayDefaultValue = value => {
  return value || '-'
}

export const displayDefaultNumericValue = value => {
  return value || 0
}

export const displayDefaultReview = value => {
  return (
    value || {
      averageRating: 0,
      count: 0,
    }
  )
}

export const displayDefaultImage = value => {
  return value || require('../assets/images/default/no_image_png_935205.png')
}

export const sliderImageHelper = (images = [], sliceTo = 0) => {
  const image = sliceTo > 0 ? images.slice(0, sliceTo) : images

  return image.map(item => {
    return {
      original: item?.largeUrl || item?.url,
      thumbnail: item?.url,
    }
  })
}

export const renderSelectOptions = (data = [], labelKey, valueKey, isLookUp = false) => {
  return data && data.length > 0
    ? data.map(item => {
        return isLookUp
          ? { label: item.lookUp[labelKey], value: item.lookUp[valueKey] }
          : { label: item[labelKey], value: item[valueKey] }
      })
    : []
}

export const getIds = (data = []) => {
  return data && data.length > 0 ? data.map(item => item.value) : []
}

export const nameFormatter = (stringArray = [], separator = ' ') => {
  return stringArray.filter(Boolean).join(separator)
}

export const formatCash = n => {
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K+'
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M+'
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B+'
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T+'
}

export const isValidUrl = url => {
  var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  return regexp.test(url)
}

export const getLikeFlag = (likes = []) => {
  const userId = getLocalStorageItem('userId')

  if (!userId) return true

  const likeUsersId = likes.map(item => item.user && item.user.id)

  return userId && !likeUsersId.includes(userId)
}

export const filterLikesReducerArray = (moduleLikes = [], payload, moduleType) => {
  let likes = []

  if (payload.likeFlag) {
    likes = [...moduleLikes, payload]
  } else if (!payload.likeFlag) {
    likes = moduleLikes.filter(item => item.id !== payload.id)
  }

  if (moduleType === payload.likeModule) {
    return likes
  } else {
    return moduleLikes
  }
}

export const textTruncate = (text = '', length = 10) => {
  return text.length > length ? text.substring(0, length) + '...' : text
}

export const phoneRegExp = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/

export const validationMessages = {
  validMobileNumber: 'Please enter valid mobile number.',
  validMobileNumberLength: 16,
  mobileNumberLength: 'Mobile number should not exceed max length 16.',
}

export const textLength = (text = '', length = 80) => {
  return text.length > length
}

export const validateWords = (val, words = 20) => {
  val = val.replace(/[ ]{2,}/gi, ' ')
  return val.split(' ').length > words
}

export const validateMaxWords = (val, words = 5) => {
  val = val.replace(/[ ]{2,}/gi, ' ')
  return val.split(' ').length <= words
}

export const requiredWords = (label = 'Field', words = 20) => {
  return `${label} must be minimum of ${words} words`
}

export const requiredMaxWords = (limit = 5) => {
  return `Maximum ${limit} words are allowed`
}

export const replaceSlashToDash = name => {
  if (name) {
    return name.toLowerCase().split('/').join('-')
  }
}

export const trimmedValue = (value = '') => {
  return value.toString().trim()
}

export const requiredNumber = (label = 'Field', digits = 6) => {
  return `${label} must be less than ${digits} digits`
}

export const removePTag = (data = '') => {
  const first = data.replace('<p>', '')
  const sec = first.replace('</p>', '')
  return sec.replaceAll('&nbsp;', ' ')
}

export const maximumWordCount = (e, words = 60) => {
  const value = e.target.value
  const val = value.replace(/[ ]{2,}/gi, ' ')
  if (val.split(' ').length > words && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
    e.preventDefault()
  }
}

export const getSlug = (str) => {
  return str && String(str).toLowerCase().replace(/[^a-z0-9\-]+/g, '-')
}

export const thousandFormatter = num => {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
}