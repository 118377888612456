import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch } from '@material-ui/core'

import { getUserAllArticle, deleteArticle, clearArticleFlag, changeArticleStatus } from '../../../redux/actions/articlesAction'
import { pagination } from '../../../util/enums/enums'
import { DashboardLayout } from '../../../components/layout/dashboardLayout'

import './article.scss'
import '../../../styles/manageDashboardTableResponsive.scss'
import { confirmSubmitHandler, popUpMessage } from '../../../helpers/confirmationPopup'
import { SuccessNotify } from '../../../helpers/notification'
import { textTruncate, verifiedCheck } from '../../../helpers/string'
import { TableCard } from '../../../components'
import { viewArticles } from '../../../helpers/boatHelper'
import { ButtonComponent } from '../../../components/form/Button'
import { formattedDate } from '../../../helpers/dateTimeHelper'
import { Field } from '../../../components'
import { getPagesValue } from '../../../helpers/jsxHelper'
import { clearSearchDashboard, searchInDashboard } from '../../../redux/actions'

class ManageArticle extends Component {
  componentDidMount() {
    const { getUserAllArticle, clearSearchDashboard } = this.props
    clearSearchDashboard()

    getUserAllArticle({
      page: getPagesValue(),
      limit: pagination.PAGE_RECORD_LIMIT,
    })
  }

  static getDerivedStateFromProps(props, state) {
    const { deleteSuccess, clearArticleFlag, getUserAllArticle, statusChanged, getAllSuccess } = props

    if (getAllSuccess) {
      clearArticleFlag()
    }

    if (deleteSuccess) {
      const data = {
        page: pagination.PAGE_COUNT,
        limit: pagination.MANAGE_PAGE_LIMIT,
      }
      clearArticleFlag()
      getUserAllArticle(data)
    }

    if (statusChanged) {
      SuccessNotify('Article status changed successfully')
    }

    return null
  }

  editArticleHandler = data => {
    const { history } = this.props

    history.push('/create-article', { editId: data })
  }

  render() {
    const {
      loading,
      articles,
      searchInDashboard,
      deleteArticle,
      changeArticleStatus,
      getUserAllArticle,
      totalArticles,
      indexSearchData,
      isSearched,
      totalSearchedData,
      clearSearchDashboard,
    } = this.props

    const columns = [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: data => <span>{textTruncate(data.value, 25)} </span>,
      },
      {
        Header: 'Posted Date',
        accessor: 'createdAt',
        Cell: data => <span>{formattedDate(data.value)} </span>,
      },
      {
        Header: 'Ad ID',
        accessor: 'adId',
      },
      {
        Header: 'Article Status',
        accessor: 'status',
        Cell: data => (
          <div className="d-flex justify-content-center">
            <Field
              type="switch"
              checked={data.value}
              value={data.value}
              onChange={() => changeArticleStatus({ id: data.original.id, columnName: 'status', value: !data.value })}
            />
          </div>
        ),
      },
      {
        Header: 'Verification Status',
        accessor: 'articleApproved',
        Cell: data => (
          <span className={`bg-green-color font-13 text-capitalize m-auto ${verifiedCheck(data.row.articleApproved)}`}>
            {verifiedCheck(data.row.articleApproved, 'Under Verification')}
          </span>
        ),
      },
      {
        headerClassName: 'manage--boat-table-action sales--engine--view reset-rt-th',
        className: 'manage--boat-table-action',
        Cell: data => (
          <div className="d-flex flex-row justify-content-around action">
            <ButtonComponent className="btn mr-2" color="edit" onClick={() => this.editArticleHandler(data.original.id)}>
              Edit
            </ButtonComponent>
            <ButtonComponent className="btn mr-2" color="view" onClick={() => viewArticles(data.original.id, data.original.title)}>
              View
            </ButtonComponent>
            <ButtonComponent
              className="btn mr-2"
              color="danger"
              onClick={() => confirmSubmitHandler(deleteArticle, data.original.id, 'Delete Article', 'Are you sure you want to delete this article? If you choose to delete, it cannot be retrieved again.')}
            >
              Delete
            </ButtonComponent>
          </div>
        ),
      },
    ]

    return (
      <DashboardLayout>
        {columns && (
                   <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table adam-dash-manage-art">
            <TableCard
              isSearch
              title="Manage Articles"
              button={{ name: 'Add Article', url: 'create-article' }}
              columns={columns}
              data={isSearched ? indexSearchData : articles}
              loading={loading}
              className="manage--boat--table manage--article--table manage--table--section--alignment"
              action={getUserAllArticle}
              searchData={searchInDashboard}
              searchType={'ARTICLE'}
              searchResultKey={'articles'}
              total={isSearched ? totalSearchedData : totalArticles}
              clearSearchDashboard={clearSearchDashboard}
            />
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.articleReducer && state.articleReducer.loading,
  articles: state.articleReducer && state.articleReducer.articles,
  deleteSuccess: state.articleReducer && state.articleReducer.deleteSuccess,
  deleteError: state.articleReducer && state.articleReducer.deleteError,
  statusChanged: state.articleReducer && state.articleReducer.statusChanged,
  getAllSuccess: state.articleReducer && state.articleReducer.getAllSuccess,
  createSuccess: state.articleReducer && state.articleReducer.createSuccess,
  updateSuccess: state.articleReducer && state.articleReducer.updateSuccess,
  totalArticles: state.articleReducer && state.articleReducer.totalArticles,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  isSearched: state?.dashboardReducer?.isSearched,
})

const mapDispatchToProps = dispatch => ({
  searchInDashboard: data => dispatch(searchInDashboard(data)),
  getUserAllArticle: data => dispatch(getUserAllArticle(data)),
  deleteArticle: data => dispatch(deleteArticle(data)),
  clearArticleFlag: () => dispatch(clearArticleFlag()),
  changeArticleStatus: data => dispatch(changeArticleStatus(data)),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageArticle)
