export const data = [
    {
        title: <>Boat Transactions <br/> with Confidence</>,
        text: `When you sign up to be a purchase surveyor with Adamsea, you'll be able to conduct foot-by-foot surveys on every potential boat sale on the platform, giving buyers and sellers the confidence they need to make informed decisions. Our platform makes it easy to manage your surveys and communicate with boat buyers and sellers directly through our chat feature, ensuring a seamless experience for all parties involved.`
    },
    {
        title: 'Expand Globally as a Purchase Surveyor',
        text: `As a purchase surveyor with Adamsea, you'll also have the opportunity to expand your business by adding different offices in different countries. This gives you more access to potential sales that require surveying, while also providing a convenient way for buyers and sellers to find qualified purchase surveyors in their region.`
    },
    {
        title: 'Secure Payments and Effortless Management',
        text: `You'll receive payment for your services directly from the platform, ensuring a secure and hassle-free transaction process. And with our easy-to-use platform, managing your surveys and payments has never been easier.`
    }
]