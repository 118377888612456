import React, { useEffect, useContext, useState } from 'react'
import { connect } from 'react-redux'

import { pagination, skeletonType } from '../../util/enums/enums'
import { Layout, PaginationBar } from '../../components'
import '../../components/dialog/EstimateCost.scss'
import UserContext from '../../UserContext'
import { getUserAllArticle } from '../../redux/actions/articlesAction'
import { UserArticales } from '../../components/user/UserArticales'
import { SkeletonLoader } from '../../components/loader/SkeletonLoader'

const ArticleListing = props => {
  const { getUserAllArticle, articles, totalArticles, loading } = props
  const { match } = useContext(UserContext)
  const [userId, setUserId] = useState('')
  useEffect(() => {
    const {
      params: { id },
    } = match
    id &&
      getUserAllArticle({
        userId: id,
        page: pagination.PAGE_COUNT,
        limit: pagination.PAGE_RECORD_LIMIT,
      })
    id && setUserId(id)
  }, [])

  return (
    <Layout className="user--articles-layout user--articles-grid w-85vw ml-auto mr-auto">
      <h1 className="section-heading">
        <span>({totalArticles}) Articles</span>
      </h1>
      {loading ? (
        <SkeletonLoader type={skeletonType.grid} itemsLength={8} />
      ) : (
        <>
          <div className="zoom-container">
            {totalArticles ? (
              articles.map(artical => {
                return <UserArticales key={artical.id} artical={artical} />
              })
            ) : (
              <>No record found</>
            )}
          </div>
          {totalArticles > pagination.PAGE_RECORD_LIMIT && (
            <div className="boat-pagination w-100">
              <PaginationBar
                action={getUserAllArticle}
                value={{
                  userId: userId,
                  page: pagination.PAGE_COUNT,
                  limit: pagination.PAGE_RECORD_LIMIT,
                }}
                totalRecords={totalArticles}
              />
            </div>
          )}
        </>
      )}
    </Layout>
  )
}

const mapStateToProps = state => ({
  loading: state.articleReducer.loading,
  articles: state.articleReducer.articles,
  totalArticles: state.articleReducer.totalArticles,
})

const mapDispatchToProps = dispatch => ({
  getUserAllArticle: data => dispatch(getUserAllArticle(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticleListing)
