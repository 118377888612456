import gql from 'graphql-tag';

export const getAllSeoMetaTagsQuery = gql`
  query get($limit: Int, $page: Int, $active: String!, $pathname: String) {
    getAllSeoMetaTags(page: $page, limit: $limit, active: $active, pathname: $pathname) {
      total
      items {
        title
        active
        metaTitle
        keywords
        id
        page
        description
        canonicalLink
      }
    }
  }
`

export const searchSeoTagsQuery = gql`
  query get($limit: Int, $page: Int, $value: String!) {
    searchSeoTags(page: $page, limit: $limit, value: $value) {
      items {
        title
        active
        metaTitle
        keywords
        id
      }
    }
  }
`
