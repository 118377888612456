import React, { Component } from 'react'
import { Grid, Link, Box } from '@material-ui/core'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import Rating from 'react-rating'
import { Player, BigPlayButton } from 'video-react'

import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Truncate from 'react-truncate'
import CarouselGallery, { Modal, ModalGateway } from 'react-images'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'

import { Layout, rentInnerReview, rentInnerTrip, Field, Loader } from '../../components'
import InnerRating from '../../components/staticComponent/InnerRating'

import RentInnerReviews from '../../components/gridComponents/InnerReviews'
import { RentSharedTrips } from '../../components/gridComponents/rentSharedTrips'
import InnerReviews from '../../components/gridComponents/InnerReviews'
import './rentInner.scss'
import './rentInnerResponsive.scss'
import { getRentInnerBoat, getRentTripCityWise, pageVisit, addLikes, clearBoatRentData } from '../../redux/actions'
import { dateStringFormateByHour, prepareGalleryData, percentageFormate, getImgUrl, getId } from '../../util/utilFunctions'
import {
  icons,
  showAll,
  dimension,
  likeModuleEnum,
  defaultProfileIcon,
  wishlistModuleEnum,
  shareModule,
  textCustomerReview,
  AddressTypeEnum,
  defaultImage,
  rentIconImages,
  skeletonType,
} from '../../util/enums/enums'
import { snakeCase, displayDefaultReview, nameFormatter, getLikeFlag, thousandFormatter } from '../../helpers/string'
import GoogleMarker from '../../components/map/marker'
import { addFeedback, getReviewByModuleId } from '../../redux/actions/ReviewAction'
import { BoatInnerGallery, HomPageVideoStyle } from '../../components/styleComponent/styleComponent'
import moment from 'moment'
import ReadMore from '../../components/helper/truncate'
import { getRentMarker, getGatheringPointMarker } from '../../helpers/boatHelper'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import {
  cityCountryNameFormatter,
  redirectToUserProfile,
  getAvgRating,
  shareWithUsersInner,
  getTripType,
  getAddress,
} from '../../helpers/jsxHelper'
import { ShareAndWishlist } from '../../components/share/ShareAndWishlist'
import RatingForm from '../../components/rating/RatingForm'
import { FeedBackForm } from '../../components/feedback/FeedBackForm'
import { currencyEnum } from '../../util/enums/currencyEnums'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import { SuccessNotify } from '../../helpers/notification'
import RatingComponent from '../../components/rating/Rating'
import ReviewRating from '../../components/reviewRating/ReviewRating'
import { CommonModal } from '../../components/modal/commonModal'
import { VideoModel } from '../../components/videoComponent/videoModel'
import { UserCardWithFeedback } from '../../components/user/UserCardWithFeedback'
import { LikeComponent } from '../../components/like/LikeComponent'
import SmallCard from '../../components/boat/smallCard'
import LoginModal from '../../components/modal/loginModal'
import { getModulePdf } from '../../redux/actions/pdfAction'
import { SkeletonLoader } from '../../components/loader/SkeletonLoader'
import { ButtonComponent } from '../../components/form/Button'
import { get, isEmpty } from 'lodash'
import CopyAdId from '../../components/CopyAdId'
import { CommonTooltip } from '../../components/CommonTooltip'
import UserCardSale from '../../components/user/UserCardSale'
import RentSticky from './RentSticky'
import { toast } from 'react-toastify'
import UserCardService from '../../components/user/UserCardService'
import ChatCreateUse from '../../components/chats/ChatCreateUse'
import { TermWithIcon } from '../../components/salesEngine/TermWithIcon'
import BookingAdPage from '../reviewBooking/BookingAdPage'
import Carousel from 'react-images'

const pricePerUnitLabel = {
  'shared trip': 'Person',
  'private trip': 'Trip',
  'rent per hour': 'Boat',
}

class RentInner extends Component {
  state = {
    boatId: '',
    isGallery: false,
    visible: false,
    relatedVideoPopUp: false,

    openCurrencyPopup: false,
    selectedCurrency: getLocalStorageItem('currentCurrency'),
    popupMapAddress: '',

    loginModal: false,

    imageIndex: null,
    newThumbnail: '',
    newThumbnail_1: '',
    newThumbnail_2: '',

    isHeaderSticky: false,
    isVerificationPopupOpen: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { match, isReview, getReviewByModuleId } = nextProps
    const { params } = match && match
    const { boatId } = prevState

    let newState = {}

    if (isReview) {
      boatId && getReviewByModuleId({ userModuleId: boatId })
    }

    if (params && params.hasOwnProperty('id') && params.id) {
      newState = {
        ...newState,
        boatId: params.id,
      }
    }

    return newState
  }

  relatedVideoHandler = state => {
    this.setState(() => ({
      relatedVideoPopUp: state ?? 0,
    }))
  }

  openLightbox = index => {
    this.setState({ isGallery: true, imageIndex: index })
  }
  closeLightbox = () => {
    this.setState({ isGallery: false, imageIndex: null })
  }

  componentWillUnmount() {
    const { clearBoatRentData } = this.props
    clearBoatRentData()
  }

  componentDidMount() {
    const { getRentInnerBoat, getReviewByModuleId, getRentTripCityWise, pageVisit } = this.props
    const { boatId } = this.state

    boatId && pageVisit({ module: boatId, type: likeModuleEnum.BOAT_RENT, sessionId: getLocalStorageItem('sessionId') })
    boatId && getRentInnerBoat({ id: boatId })
    boatId && getReviewByModuleId({ userModuleId: boatId })
    boatId && getRentTripCityWise({ id: boatId })
  }

  componentDidUpdate(prevProps, prevState) {
    const { newThumbnail, newThumbnail_1, newThumbnail_2 } = prevState
    const { boatRentInnerData, isReview } = this.props
    if (boatRentInnerData && boatRentInnerData.youtubeLinkVideo !== '' && newThumbnail === '') {
      this.createThumbnail('newThumbnail', boatRentInnerData.youtubeLinkVideo)
    }
    if (boatRentInnerData && boatRentInnerData.youtubeLinkVideo_1 !== '' && newThumbnail_1 === '') {
      this.createThumbnail('newThumbnail_1', boatRentInnerData.youtubeLinkVideo_1)
    }
    if (boatRentInnerData && boatRentInnerData.youtubeLinkVideo_2 !== '' && newThumbnail_2 === '') {
      this.createThumbnail('newThumbnail_2', boatRentInnerData.youtubeLinkVideo_2)
    }
    if (isReview) {
      document.querySelector('.rating-reviews-cont')?.scrollIntoView({ block: 'center' })
    }
  }

  createThumbnail = (key, video) => {
    if (video) {
      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      var result = video?.match(regExp)
      if (result === null || result === undefined) {
        this.setState({ [key]: defaultImage })
      } else {
        var youtube_video_id = result.pop()
        if (youtube_video_id?.length == 11) {
          var video_thumbnail = `//img.youtube.com/vi/${youtube_video_id}/0.jpg`
          this.setState({ [key]: video_thumbnail })
        }
      }
    }
  }

  handlerLoginModal = () => this.setState(prevState => ({ loginModal: !prevState.loginModal }))

  bookRent = () => {
    const { history, boatRentInnerData } = this.props

    if (
      getId(this.props.currentUser)
        ? !this.props.currentUser.documentVerification.emailVerified ||
          !this.props.currentUser.documentVerification.mobileVerified
        : false
    ) {
      this.setState({ isVerificationPopupOpen: true })
    } else {
      history.push(`/review-booking/${boatRentInnerData.id}`)
    }
  }

  currencyPopupHandler = () => {
    this.setState(prevState => ({
      openCurrencyPopup: !prevState.openCurrencyPopup,
    }))
  }

  renderBuyNowSection = () => {
    const { openCurrencyPopup, selectedCurrency } = this.state
    const { boatRentInnerData, currentUser, countryDetails } = this.props
    const { price, user, trip, adStatus, boatRentStatus } = boatRentInnerData

    return (
      <div className="buy-inner">
        <div
          className={`d-flex buy-right-button h-100 pl-0 pt-3 pb-3 pr-0 inner--boat--header--div ${
            +price ? '' : 'fully-booked'
          }`}
        >
          <figcaption>
            {+price ? (
              <>
                {/* <Link
                  className="btn btn-xs btn-link-info pl-0 font-weight-400 text-right p-0 d-flex justify-content-end align-items-center rent--inner--skyblue rent--inner--currency--converter"
                  to="#"
                  data-toggle="modal"
                  data-target="#currency-modal"
                  onClick={this.currencyPopupHandler}
                >
                  USE CURRENCY CONVERTER
                </Link> */}
                {openCurrencyPopup && (
                  <>
                    <Field
                      type="select"
                      value={selectedCurrency}
                      onChangeText={e => this.setState({ selectedCurrency: e.target.value })}
                      options={currencyEnum.map(item => {
                        return { id: item.label, value: item.name }
                      })}
                      labelClass="d-flex mb-2"
                    />
                  </>
                )}
                <div className="price-wrap rent-inner-price-wrap mb-1">
                  <span className="price-new">
                    {getConvertedPrice(price, selectedCurrency)}{' '}
                    <span style={{ fontSize: '0.9em', fontWeight: 'normal' }}>
                      {' '}
                      {pricePerUnitLabel[trip?.alias.toLowerCase()]}
                    </span>
                  </span>
                </div>
              </>
            ) : null}
          </figcaption>

          <div className="btn-book-rent-inner">
            {currentUser &&
              user &&
              currentUser.id !== user.id &&
              adStatus &&
              boatRentStatus &&
              new URLSearchParams(this.props.location.search).get('from_admin') !== '1' && (
                <button
                  className="btn btn-md btn-rounded f-500 w-100 inner-header-button light-sky-blue rent-inner-card-hovered-button-effect rent--inner--book--btn"
                  onClick={() => this.bookRent()}
                >
                  Book Now
                </button>
              )}
          </div>
        </div>
      </div>
    )
  }

  viewMap = type => {
    const { boatRentInnerData } = this.props
    const addressData = boatRentInnerData.address.find(item => item.type === type)
    addressData && this.setState({ popupMapAddress: addressData })
    this.setState(prevState => ({
      showMap: !prevState.showMap,
      showMapType: type,
    }))
  }

  render() {
    const {
      boatRentInnerData,
      history,
      currentUser,
      rentTripCityWise,
      addFeedback,
      isLoading,
      addLikes,
      loading,
      getPdfSuccess,
      pdf,
      getModulePdf,
      reviews,
    } = this.props

    const {
      address,
      adId,
      bathrooms,
      bedroomAndCabins,
      boatLength,
      descriptionOfOurTrip,
      captainName,
      startTime,
      id,
      endTime,
      maximumGuest,
      tripDuration,
      model,
      seatsOnBoat,
      images,
      boatLayout,
      trip,
      tripType,
      user,
      pageVisits,
      youtubeLinkVideo,
      youtubeLinkVideo_1,
      youtubeLinkVideo_2,
      deckAndEntertainment,
      promotionOnSite,
      likes,
    } = boatRentInnerData || {}

    const { isGallery, showMap, popupMapAddress, relatedVideoPopUp, loginModal, newThumbnail, newThumbnail_1, newThumbnail_2 } =
      this.state

    const {
      commonAddress: { address1, city: boatCity },
      tripAddress: { address1: tripAddress, country: destinationCountry, city: destinationCity },
      gatheringPoint,
    } = getAddress(address)

    const isRentPerHour = tripType && tripType.tripId && tripType.tripId.length === 1

    return (
      <Layout className="rent-inner-layout rent--inner--responsive not--scroll-auto">
        <LoginModal open={loginModal} close={this.handlerLoginModal} />
        {isLoading || loading ? (
          <Grid container className="container-fluid">
            <SkeletonLoader type={skeletonType.innerPage} />
          </Grid>
        ) : (
          <>
            <Grid
              container
              className="container-fluid clr-fluid sticky-on-top rent-inner-contain inner-sticky-on-top"
              style={
                this.state.isHeaderSticky
                  ? {
                      position: 'sticky',
                      top: this.state.isHeaderSticky_top,
                      maxWidth: '90vw',
                      borderRadius: 16,
                      border: '1px solid #0003',
                    }
                  : {}
              }
            >
              <div className="rent-rating-first-section h-100 w-85vw rent--inner--header--section py-3">
                <Grid item className="h-70 rent-inner-rating-section rent-inner-bottom-space-mob d-flex pr-0 pl-0" sm={5}>
                  <div className="width-100 align-self-center rent-first-side-text">
                    <h1 className="d-flex align-items-center mb-0">
                      <span className="font-22 rent--inner--header--boat--name">
                        {tripType?.alias} {isRentPerHour ? 'in' : 'starts from'} {boatCity}
                      </span>
                    </h1>
                    <p>
                      <span class="color-black rentinner--header--badge">{trip?.alias}</span>
                      {/* <span class="color-black rentinner--header--badge"> {tripType && tripType?.alias}</span> */}
                    </p>
                  </div>
                </Grid>

                <div>
                  {!+boatRentInnerData.price && (
                    <div
                      className="sea--trial-text"
                      style={{ marginBottom: '1em', borderColor: '#018abc', color: '#018abc', fontSize: '18px' }}
                    >
                      All trips for this boat or watercraft are full
                    </div>
                  )}
                </div>

                <Grid item className="sticky-on-top rent-inner-rating-last-child d-flex" sm={5}>
                  {/* {boatRentInnerData && this.renderBuyNowSection()} */}

                  <div className="ml-auto">
                    {this.state.isHeaderSticky && (
                      <ButtonComponent
                        className="btn-black rounded-full"
                        onClick={() => document.querySelector('.booking-ad-page-wrp')?.scrollIntoView({ inline: 'center' })}
                      >
                        Book Now
                      </ButtonComponent>
                    )}

                    {reviews && reviews.reviews && (
                      <div
                        className="font-12 newRatingBlack"
                        style={{ marginTop: 5, marginLeft: 'auto', justifyContent: 'flex-start' }}
                      >
                        <div>
                          <span>{reviews.reviews.length}</span> <span>Reviews</span>
                        </div>
                        <div style={{ marginLeft: '1em', paddingLeft: '1em', borderLeft: '1px solid #000' }}>
                          <span>
                            <StarIcon style={{ width: '1em', height: '1em', fontSize: '1.25em' }} />{' '}
                            {percentageFormate(getAvgRating(reviews.reviews))}
                          </span>{' '}
                          <span>Rating</span>
                        </div>
                      </div>

                      // <div className="d-flex align-items-center">
                      //   <div className="d-flex mr-2 mb-1">
                      //     <RatingComponent
                      //       className="rating-clr rating-small-size"
                      //       rating={percentageFormate(getAvgRating(reviews.reviews))}
                      //     />
                      //   </div>
                      //   <h6 className="mr-3 m-0 font13 font-weight-400  rent--inner--customer--review">
                      //     {percentageFormate(getAvgRating(reviews.reviews))}
                      //   </h6>
                      //   <span className="font13 gray-light font-weight-400 rent--inner--customer--review">
                      //     {reviews.reviews.length} {textCustomerReview}
                      //   </span>
                      // </div>
                    )}
                  </div>
                </Grid>
              </div>
            </Grid>

            <Grid
              container
              className="w-85vw mx-auto position-relative rent-galley-image-grid cursor-pointer rent--inner--banner"
            >
              {/* <LikeComponent likes={likes} moduleId={id} likeModule={likeModuleEnum.BOAT_RENT} addLikes={addLikes} />
              <div className="position-absolute inner-banner-top-social-div inner--banner--top--div">
                <div className="inner-banner-views-div mr-2 d-flex font13 gray-dark img-show-text boat--inner--banner--likes rent--inner--views--div">
                  <div className="banner-count-text inner--banner--count rent--inner--banner--count">
                    {pageVisits && thousandFormatter(pageVisits.length)} VIEWS
                  </div>
                </div>
                <div className="inner-banner-views-div d-flex font13 gray-dark img-show-text boat--inner--banner--likes rent--inner--views--div">
                  <div className="d-flex justify-content-center align-items-center mr-2  inner--banner--icons-div mr-2">
                    <div className="inner--banner--icons d-flex">
                      <img loading="lazy" src={require('../../assets/images/rentInner/image-count.png')} className="h-100" alt="Count" />
                    </div>
                  </div>
                  <div className="banner-count-text inner--banner--count rent--inner--banner--count">
                    {images && images.length}
                  </div>
                </div>
              </div> */}

              <Grid item xs={3} className="rent-all-grid-part">
                <Grid container className="h-100 ">
                  <Grid item xs={12}>
                    <Grid container className="h-100">
                      <Grid
                        item
                        xs={12}
                        className="hidden border-radius-padding-img rent--inner--banner--section rent--inner--banner--second--section"
                      >
                        <BoatInnerGallery
                          onClick={() => {
                            this.openLightbox(1)
                            this.setState({ showLayoutImages: false })
                          }}
                          img={
                            images && images.length > 1
                              ? encodeURI(getImgUrl(images[1]))
                              : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container className="h-100">
                      <Grid
                        item
                        xs={12}
                        className="hidden border-radius-padding-img  rent--inner--banner--section rent--inner--banner--fourth--section"
                      >
                        <BoatInnerGallery
                          onClick={() => {
                            this.openLightbox(3)
                            this.setState({ showLayoutImages: false })
                          }}
                          img={
                            images && images.length > 3
                              ? encodeURI(getImgUrl(images[3]))
                              : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={6}
                className="hidden border-radius-padding-img rent--inner--banner--section rent--inner--banner--first--section"
              >
                <BoatInnerGallery
                  onClick={() => {
                    this.openLightbox(0)
                    this.setState({ showLayoutImages: false })
                  }}
                  img={
                    images && images.length > 0
                      ? encodeURI(getImgUrl(images[0]))
                      : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                  }
                />
              </Grid>

              <Grid item xs={3} className="rent-all-grid-part">
                <Grid container className="h-100 ">
                  <Grid item xs={12}>
                    <Grid container className="h-100">
                      <Grid
                        item
                        xs={12}
                        className="hidden border-radius-padding-img h-100 rent--inner--banner--section rent--inner--banner--third--section"
                      >
                        <BoatInnerGallery
                          onClick={() => {
                            this.openLightbox(2)
                            this.setState({ showLayoutImages: false })
                          }}
                          img={
                            images && images.length > 2
                              ? encodeURI(getImgUrl(images[2]))
                              : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container className="h-100">
                      <Grid
                        item
                        xs={12}
                        className="hidden border-radius-padding-img position-relative h-100 rent--inner--banner--section rent--inner--banner--fifth--section"
                      >
                        <BoatInnerGallery
                          onClick={() => {
                            this.openLightbox(4)
                            this.setState({ showLayoutImages: false })
                          }}
                          img={
                            images && images.length > 4
                              ? encodeURI(getImgUrl(images[4]))
                              : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                          }
                        />

                        <div
                          className="more-gallery-imgs-btn"
                          onClick={() => {
                            this.openLightbox(4)
                            this.setState({ showLayoutImages: false })
                          }}
                        >
                          + {images?.length - 4} more
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {boatRentInnerData && boatRentInnerData.id && (
                <div className="inner--action--btns">
                  <ShareAndWishlist
                    noTopSpace
                    moduleId={boatRentInnerData.id}
                    moduleType={wishlistModuleEnum.BOAT_RENT}
                    ownerId={boatRentInnerData?.user?.id}
                    getPdfSuccess={getPdfSuccess}
                    pdf={pdf}
                    getModulePdf={getModulePdf}
                    isInner
                    hideFlag
                    hidePDF
                  />
                </div>
              )}
            </Grid>

            <Grid container className="w-85vw mx-auto">
              <Grid item xs={12} className="d-flex">
                <div className="banner-b-btns-wrp">
                  <div className="banner-b-btn">{thousandFormatter(pageVisits?.length || 0)} VIEWS</div>
                  <LikeComponent
                    likes={likes}
                    moduleId={id}
                    likeModule={likeModuleEnum.BOAT_RENT}
                    addLikes={addLikes}
                    noAbsolute
                  />
                  {/* <CommonTooltip
                    component={ctProps => (
                      <div data-tooltip-id={ctProps.id} data-tooltip-content="Photos" className="banner-b-btn">
                        <i className="fas fa-images"></i>
                        {images?.length}
                      </div>
                    )}
                  /> */}
                </div>
              </Grid>
            </Grid>

            <div className="rent-inner-contain">
              <div className="container-fluid clr-fluid w-85vw p-0">
                <Grid container className="p-sec">
                  <Grid item xs={6}>
                    <div>
                      <span className="title-sm inner--main--title">About {user?.firstName}'s Trip</span>
                      <p className="mt-3 a-inner-fix rent--inner--our--trip--desc">
                        <ReadMore className="text-justify" lines={5}>
                          {descriptionOfOurTrip}
                        </ReadMore>
                      </p>
                    </div>

                    {user && (
                      <div className="mt-5">
                        <UserCardService
                          isRent
                          seller={user}
                          boat={{
                            rating_count: user.reviews?.reviews?.length || 0,
                            rating: getAvgRating(user.reviews?.reviews) || 0,
                          }}
                        />
                      </div>
                    )}

                    <hr className="mt-4" />

                    {!isRentPerHour && captainName?.trim && captainName.trim() && (
                      <div className="mt-4 mb-4 inner--boat--systems-title rent--inner--our--trip--title">
                        <span className="title-sm inner--main--title">
                          With Captain {(captainName?.trim && captainName.trim()) || 'No Captain'}
                        </span>
                        <p className="mt-3 a-inner-fix rent--inner--our--trip--desc">
                          A captain is provided by the listing owner to host and operate the trip.
                        </p>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <div className="booking-ad-page-wrp">
                      {boatRentInnerData?.id && <BookingAdPage boatRentInnerData={boatRentInnerData} />}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} className="rent--our--trip h-100">
                    <Grid container className="h-100">
                      <Grid item xs={12}>
                        <div style={{ padding: 15 }}>
                          {promotionOnSite && (
                            <div className="mt-5 rent--inner--info--desc">
                              <h6 className="title-sm mb-20 inner--main--title">Promotion</h6>
                              <p className="mt-3 mb-0 rent--inner--info--div--desc">{promotionOnSite}</p>
                            </div>
                          )}
                        </div>

                        {/* <div className="card border d-flex justify-content-center width-100 rent-inner-border m-0 rent--inner--payment--div">
                          <div className="d-flex">
                            <div className="d-flex flex-column rent--inner--payment">
                              <span className="font-weight-500 rent--inner--skyblue rent--inner--payment--title">
                                Payment & Booking
                              </span>
                              <span className="sub-title-sm rent--inner--payment--desc">
                                Neque Porro quisquam dolorem ipsum
                              </span>
                            </div>

                            <div className="d-flex align-items-center rent--inner--payment">
                              <div className="d-flex">
                                <i className="adam-creditcard credit-icon text-black inner-payment-icon mr-3 rent--inner--skyblue font-weight-500 rent--inner--payment--icon"></i>
                              </div>
                              <div className="d-flex flex-column">
                                <span className="font-weight-500 rent--inner--skyblue rent--inner--payment--title">
                                  Credit Card Payment
                                </span>
                                <span className="sub-title-sm rent--inner--payment--desc">Neque Porro quisquam</span>
                              </div>
                            </div>

                            <div className="d-flex align-items-center rent--inner--payment rent--inner--payment--last--div">
                              <div>
                                <div className="d-flex justify-content-center align-items-center mr-3 payment--image">
                                  <img
                                    src={require('../../assets/images/rentInner/payment.png')}
                                    className="inner-payment-cash-icon rent--inner--cash--icon"
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column">
                                <span className="rent--inner--skyblue font-weight-500 rent--inner--payment--title">
                                  Cash on Arrival
                                </span>
                                <span className="sub-title-sm rent--inner--payment--desc">Neque Porro quisquam</span>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <RentSticky
                  setIsActive={isActive =>
                    this.setState({
                      isHeaderSticky: isActive,
                      isHeaderSticky_top: getId(currentUser)
                        ? innerWidth > 1366
                          ? 70
                          : innerWidth > 1280
                          ? 59
                          : 59
                        : innerWidth > 1366
                        ? 101
                        : innerWidth > 1280
                        ? 101
                        : 101,
                    })
                  }
                />
                <div style={{ borderBottom: '1px solid #000' }} />
                <Grid container className="p-sec">
                  <Grid item xs={12} className="rent--inner--location--section full-width-991">
                    <div className="">
                      <div className="d-flex align-items-center inner--boat--systems-title flex-wrap">
                        <span className="title-sm inner--main--title">Boat Location</span>
                        <span className="boat-inner-info-desc boat--inner--info--div--desc mb-0 ml-2">
                          <span style={{ fontSize: '0.8em' }}>
                            {cityCountryNameFormatter(get(address, '[0].city', ''), get(address, '[0].country', '', ' • '))}
                          </span>
                        </span>
                      </div>

                      <div className="boat-inner-map-div rent--inner--boat--location">
                        <GoogleMarker
                          isInnerPage={false}
                          markers={getRentMarker(boatRentInnerData)}
                          geometricLocation={get(address, '[0].geometricLocation', '')}
                          isRedirectToMap
                          className="boat-inner-map-height"
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="mt-5 full-width-991 rent--inner--trip--detail--section">
                    <div className="width-100 pr-3 boat-inner-info-title d-flex align-items-center inner--boat--systems-title">
                      <span className="inner--main--title">Details About the Trip</span>
                    </div>
                    <div className="row rent-inner-details-row">
                      <div className="col-md-6 rent-inner-boat-info-padding rent--inner--info--desc">
                        <div className="mb-1 d-flex">
                          <span className="mr-2">Trip Destination</span> {destinationCity}, {destinationCountry}
                        </div>
                        <div className="text-left  f-500  d-block mt-2 rent--inner--view--map--tag">
                          <span
                            className="d-flex align-items-center cursor-pointer rent--inner--view--map"
                            onClick={() => this.viewMap(AddressTypeEnum.TRIP_ADDRESS)}
                            style={{ padding: '8px 12px 6px', borderRadius: 999, border: '2px solid #000' }}
                          >
                            Destination on Map
                          </span>
                        </div>
                      </div>

                      {/* <div className="col-md-3 rent--inner--info--desc">
                      <h6 className="mb-1 title-sm title-description font-weight-400 gray-dark">
                        Available Trip Days
                      </h6>
                      <p className="sub-title-sm ">
                        Su, Mo, Tu, We, Th, Fr, Sa
                      </p>
                    </div> */}
                      {/* <div className="col-md-3 rent--inner--info--desc">
                      <h6 className="mb-1 title-sm title-description font-weight-400 gray-dark">
                        Trip Duration
                      </h6> */}
                      {/* <span className="sub-title-sm ">
                        {tripDuration}
                      </span> */}
                      {/* </div> */}

                      <div className="col-md-6 rent-inner-boat-info-padding rent--inner--info--desc">
                        <div class="mb-1 d-flex">
                          <span className="mr-2">Gathering Point</span> {gatheringPoint?.city}, {gatheringPoint?.country}
                        </div>
                        <div className="text-left  f-500  d-block mt-2 rent--inner--view--map--tag">
                          <span
                            className="d-flex align-items-center cursor-pointer rent--inner--view--map"
                            onClick={() => this.viewMap(AddressTypeEnum.GATHERING_POINT)}
                            style={{ padding: '8px 12px 6px', borderRadius: 999, border: '2px solid #000' }}
                          >
                            Gathering Point on Map
                          </span>
                        </div>
                      </div>

                      <div className="col-md-6 rent-inner-boat-info-padding rent--inner--info--desc">
                        {!!startTime && (
                          <div className="mt-3 mb-1 d-flex">
                            <span className="mr-2">Starts at</span> {moment(startTime).utc().format('hh:mm A')}
                          </div>
                        )}
                        {!!endTime && (
                          <div className="mt-3 mb-1 d-flex">
                            <span className="mr-2">Ends at</span> {moment(endTime).utc().format('hh:mm A')}
                          </div>
                        )}
                        {(!!startTime || !!endTime) && (
                          <div className="mt-3 mb-1 d-flex">
                            <span className="mr-2">Duration</span> {tripDuration}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 rent-inner-boat-info-padding rent--inner--info--desc">
                        {!isRentPerHour && (
                          <div className="mt-3 mb-1 d-flex">
                            <span className="mr-2">Captain</span> {(captainName?.trim && captainName.trim()) || 'No Captain'}
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div style={{ borderBottom: '1px solid #000' }} />
                <Grid container className="p-sec check-bottom-scroll">
                  <Grid item md={8} className="rent--inner--boat--info">
                    <div>
                      <div className="width-100 pr-3 boat-inner-info-title d-flex align-items-center inner--boat--systems-title">
                        <span className="inner--main--title">Boat Information</span>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-4 rent--inner--info--desc rent--inner--boat--info--desc">
                          <p className="sub-title-sm   rent--inner--info--div--desc mb-0">{model}</p>
                          <h6 className="mb-3 title-description inner--info--title gray-dark title-sm">Boat Model</h6>
                        </div>
                        <div className="col-md-4 rent--inner--info--desc rent--inner--boat--info--desc">
                          <p className="sub-title-sm   rent--inner--info--div--desc mb-0">{boatLength} Feet</p>
                          <h6 className="mb-3 title-description inner--info--title gray-dark title-sm">Boat Length</h6>
                        </div>
                        <div className="col-md-4 rent--inner--info--desc rent--inner--boat--info--desc">
                          <p className="sub-title-sm   rent--inner--info--div--desc mb-0">{maximumGuest}</p>
                          <h6 className="mb-3 title-description inner--info--title gray-dark title-sm">Maximum Guests</h6>
                        </div>
                        {!isRentPerHour && (
                          <>
                            <div className="col-md-4 rent--inner--info--desc rent--inner--boat--info--desc">
                              <p className="sub-title-sm   rent--inner--info--div--desc mb-0">{seatsOnBoat}</p>
                              <h6 className="mb-3 title-description inner--info--title gray-dark title-sm">Seats on Board</h6>
                            </div>
                            <div className="col-md-4 rent--inner--info--desc rent--inner--boat--info--desc">
                              <p className="sub-title-sm   rent--inner--info--div--desc mb-0">{bathrooms}</p>
                              <h6 className="mb-3 title-description inner--info--title gray-dark title-sm">Bathroom</h6>
                            </div>
                            <div className="col-md-4 rent--inner--info--desc rent--inner--boat--info--desc">
                              <p className="sub-title-sm   rent--inner--info--div--desc mb-0">{bedroomAndCabins}</p>
                              <h6 className="mb-3 title-description inner--info--title gray-dark title-sm">
                                Bedrooms & Cabins
                              </h6>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Grid>

                  <Grid item md={4} className="rent--inner--layout--img--section">
                    {boatLayout?.length > 0 && (
                      <>
                        <div>
                          <div className="d-flex align-items-center inner--boat--systems-title">
                            <div className="inner--title--icons">
                              <img
                                src={require('../../assets/images/boatInner/Boat layout.svg')}
                                alt="Layout"
                                className="d-flex h-100"
                              />
                            </div>
                            <span className="inner--main--title">Boat Layout</span>
                          </div>
                        </div>
                        <div className="boat-layout-gallery">
                          <div className="boat-layout-gallery-col">
                            <div
                              className="boat-layout-gallery-row"
                              onClick={() => {
                                this.openLightbox(0)
                                this.setState({ showLayoutImages: true })
                              }}
                            >
                              <img src={getImgUrl(boatLayout[0])} alt="" />
                            </div>
                            {boatLayout.length > 3 && (
                              <div
                                className="boat-layout-gallery-row"
                                onClick={() => {
                                  this.openLightbox(3)
                                  this.setState({ showLayoutImages: true })
                                }}
                              >
                                <img src={getImgUrl(boatLayout[3])} alt="" />
                              </div>
                            )}
                          </div>

                          {boatLayout.length > 1 && (
                            <div className="boat-layout-gallery-col">
                              <div
                                className="boat-layout-gallery-row"
                                onClick={() => {
                                  this.openLightbox(1)
                                  this.setState({ showLayoutImages: true })
                                }}
                              >
                                <img src={getImgUrl(boatLayout[1])} alt="" />
                              </div>
                              {boatLayout.length > 2 && (
                                <div
                                  className="boat-layout-gallery-row"
                                  onClick={() => {
                                    this.openLightbox(2)
                                    this.setState({ showLayoutImages: true })
                                  }}
                                >
                                  <img src={getImgUrl(boatLayout[2])} alt="" />
                                </div>
                              )}
                            </div>
                          )}

                          {boatLayout.length > 4 && (
                            <div
                              className="boat-layout-gallery-see-more"
                              onClick={() => {
                                this.openLightbox(4)
                                this.setState({ showLayoutImages: true })
                              }}
                            >
                              See more images
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {/* {boatLayout && boatLayout.length ? (
                      <div>
                        <div className="d-flex align-items-center inner--boat--systems-title">
                          <div className="inner--title--icons">
                            <img
                              src={require('../../assets/images/boatInner/boat-layout.png')}
                              alt="Layout"
                              className="d-flex h-100"
                            />
                          </div>
                          <span className="inner--main--title">Boat Layout</span>
                        </div>
                        <Carousel className="br-10 overflow-hidden">
                          {boatLayout &&
                            boatLayout.length &&
                            boatLayout.map((image, idx) => {
                              return (
                                <Carousel.Item
                                  className="rent--inner--layout--img"
                                  onClick={() => {
                                    this.openLightbox(idx)
                                    this.setState({ showLayoutImages: true })
                                  }}
                                >
                                  <img loading="lazy" className="d-block br-10 w-100 h-100" src={image?.url} alt="First slide" />
                                </Carousel.Item>
                              )
                            })}
                        </Carousel>
                      </div>
                    ) : (
                      <> </>
                    )} */}
                  </Grid>
                  <Grid item xs={12}>
                    {!isRentPerHour && (
                      <div className="mt-5">
                        <div className="d-flex align-items-center inner--boat--systems-title mb-0">
                          <span className="inner--main--title">What this trip offers</span>
                        </div>

                        <div>
                          <Grid container>
                            <div className="width-100 pl-0 d-flex flex-wrap">
                              {deckAndEntertainment &&
                                deckAndEntertainment.length > 0 &&
                                deckAndEntertainment.map((item, index) => {
                                  return (
                                    <div className="mb-0 mr-4 mt-4 ml-0 rent--inner--entertainment--types">
                                      <SmallCard
                                        index={index}
                                        renderIcon={item && icons[snakeCase(item.alias.replace(/[{()}]/g, ''))]}
                                        elseDynamicRenderImage={item.icon === null ? null : encodeURI(item.icon?.url)}
                                        elseRenderImage={rentIconImages[snakeCase(item.alias.replace(/[{()}]/g, ''))]}
                                        title={item.alias}
                                        height="20px"
                                      />
                                    </div>
                                  )
                                })}
                            </div>
                          </Grid>
                        </div>
                      </div>
                    )}
                  </Grid>
                  {boatRentInnerData?.user && (
                    <Grid item xs={8}>
                      <div className="mt-5 d-flex justify-content-between align-items-start" style={{ gap: 30 }}>
                        <div className="book-user-card">
                          <div className="book-user-card-col">
                            <div className="book-user-card-img-wrp">
                              <img src={boatRentInnerData.user.image?.url || defaultProfileIcon} alt="User Img" />
                              {boatRentInnerData.user?.isVerified && (
                                <div className="book-user-card-verif">
                                  <img src={require('../../assets/images/verif.svg')} alt="verified user" />
                                </div>
                              )}
                            </div>
                            <div className="book-user-card-name">{boatRentInnerData.user.firstName}</div>
                          </div>
                          <div className="book-user-card-col">
                            <ChatCreateUse
                              bookRentCard
                              id={boatRentInnerData.user.id}
                              image={boatRentInnerData.user.image?.url}
                            />
                          </div>
                        </div>
                        <div style={{ flex: 1 }}>
                          <TermWithIcon
                            title={`${boatRentInnerData.user.firstName} Policy`}
                            titleClassName="title-sm inner--main--title"
                            descriptions={boatRentInnerData.policy}
                            popup
                          />

                          {boatRentInnerData.whatToBring && (
                            <div className="mt-5">
                              <h3 className="title-sm inner--main--title">
                                What {boatRentInnerData.user.firstName} wants you to bring
                              </h3>
                              <p className="mt-3 a-inner-fix rent--inner--our--trip--desc color-black">
                                {boatRentInnerData.whatToBring}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
                <div style={{ borderBottom: '1px solid #000' }} />
                <Grid container className="p-sec">
                  {youtubeLinkVideo && (
                    <div className="d-flex align-items-center inner--boat--systems-title boat--inner--related--video">
                      <div className="inner--title--icons">
                        <img
                          src={require('../../assets/images/boatInner/Related Videos.svg')}
                          alt="Videos from our trips"
                          className="d-flex h-100"
                        />
                      </div>
                      <span className="inner--main--title">Videos from our trips</span>
                    </div>
                  )}
                  <Grid container className="rent--inner--related--videos--div">
                    {youtubeLinkVideo && (
                      <Grid item sm={4} className="p-2">
                        <>
                          <HomPageVideoStyle
                            img={newThumbnail}
                            className="boat-inner-related-video rent--inner--related--videos"
                            onClick={() => this.relatedVideoHandler(1)}
                          >
                            <PlayCircleOutlineIcon className="playVideo-icon w-auto cursor-pointer" />
                          </HomPageVideoStyle>
                          <VideoModel
                            videoFlag={relatedVideoPopUp === 1}
                            videoUrl={youtubeLinkVideo}
                            thumbnail={newThumbnail}
                            closeVideo={() => this.relatedVideoHandler(0)}
                          />
                        </>
                      </Grid>
                    )}
                    {youtubeLinkVideo_1 && (
                      <Grid item sm={4} className="p-2">
                        <div>
                          <HomPageVideoStyle
                            img={newThumbnail_1}
                            className="boat-inner-related-video rent--inner--related--videos"
                            onClick={() => this.relatedVideoHandler(2)}
                          >
                            <PlayCircleOutlineIcon className="playVideo-icon w-auto cursor-pointer" />
                          </HomPageVideoStyle>
                          <VideoModel
                            videoFlag={relatedVideoPopUp === 2}
                            videoUrl={youtubeLinkVideo_1}
                            thumbnail={newThumbnail_1}
                            closeVideo={() => this.relatedVideoHandler(0)}
                          />
                        </div>
                      </Grid>
                    )}
                    {youtubeLinkVideo_2 && (
                      <Grid item sm={4} className="p-2">
                        <>
                          <HomPageVideoStyle
                            img={newThumbnail_2}
                            className="boat-inner-related-video rent--inner--related--videos"
                            onClick={() => this.relatedVideoHandler(3)}
                          >
                            <PlayCircleOutlineIcon className="playVideo-icon w-auto cursor-pointer" />
                          </HomPageVideoStyle>
                          <VideoModel
                            videoFlag={relatedVideoPopUp === 3}
                            videoUrl={youtubeLinkVideo_2}
                            thumbnail={newThumbnail_2}
                            closeVideo={() => this.relatedVideoHandler(0)}
                          />
                        </>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid container className="rating-section">
                  {reviews && reviews.reviews && (
                    <ReviewRating isRent reviews={reviews} currentUser={currentUser} boat={boatRentInnerData} />
                  )}
                </Grid>

                {/* Feedback */}
                {user && (
                  <div className="inner--add--feedback w-100 d-flex flex-column align-items-end">
                    {user?.id !== currentUser.id && (
                      <div
                        className={`text-right f-14 mb-0 cursor-pointer add-feedback-mt add--feedback color hover-scale-1_1`}
                        style={{ padding: 15 }}
                        onClick={() => {
                          currentUser?.id
                            ? this.setState(prev => ({ feedbackModal: !prev.feedbackModal }))
                            : this.setState(prev => ({ loginModal: !prev.loginModal }))
                        }}
                      >
                        <img
                          loading="lazy"
                          src={require(`../../assets/images/rentInner/feedback.png`)}
                          className="inner-feedback-img mr-2"
                          alt="Feedback"
                        />
                        <span className="add-feedback-text">Send us feedback</span>
                      </div>
                    )}

                    <LoginModal
                      open={this.state.loginModal}
                      close={() => this.setState(prev => ({ loginModal: !prev.loginModal }))}
                    />
                    <FeedBackForm
                      open={this.state.feedbackModal}
                      closeModal={() => this.setState(prev => ({ feedbackModal: !prev.feedbackModal }))}
                      submitValues={addFeedback}
                    />

                    <div className="rent-text-color font-weight-500 inner--adid--section">
                      <span className="inner-adid font-weight-400 color-black">
                        <CopyAdId adId={adId} />
                      </span>

                      {boatRentInnerData && boatRentInnerData.id && (
                        <ShareAndWishlist
                          noTopSpace
                          moduleId={boatRentInnerData.id}
                          moduleType={wishlistModuleEnum.BOAT_RENT}
                          ownerId={boatRentInnerData?.user?.id}
                          getPdfSuccess={getPdfSuccess}
                          pdf={pdf}
                          getModulePdf={getModulePdf}
                          isInner
                          hideShare
                          hideSave
                        />
                      )}
                    </div>
                  </div>
                )}

                {/* Don't delete below code @miraj */}
                {rentTripCityWise && rentTripCityWise.length > 0 && (
                  <div className="container-fluid rent-layout p-sec pr-0 pl-0 rent--inner--similar--trip--div">
                    <Grid item xs={12} className="rent--inner--similar--trip--section">
                      <div className="rent--inner--main--title--margin section-heading mt-0 rent--inner--similar--trip--title--section">
                        <span className="gray-dark inner--main--title rent--inner--similar--trip--title">
                          Similar Trip in {get(address, '[0].country', '')}
                        </span>
                      </div>
                      <div className="rent--inner--similar--trip">
                        <RentSharedTrips
                          itemsLength={8}
                          dimensionKey="privateTrip"
                          className="grid--rent--private--trip"
                          showType={showAll.privateTrip}
                          rentSharedTrips={rentTripCityWise}
                        />
                      </div>
                    </Grid>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-5 w-85vw mx-auto" style={{ textAlign: 'center' }}>
              <h3 style={{ fontSize: 32, fontWeight: 'bold' }}>How it works</h3>
              <div className="mt-5 mb-5 d-flex justify-content-center">
                <div>
                  <div
                    style={{
                      flex: 3,
                      padding: '8px 16px',
                      borderRadius: 999,
                      background: '#fff',
                      border: '1px solid #aaa',
                      fontSize: 14,
                    }}
                  >
                    Select Trip & Time
                  </div>
                  <div className="mt-2 font-14">Step 1</div>
                  <div className="mt-2" style={{ fontSize: 12, color: '#999' }}>
                    Choose your trip and preferred slot
                  </div>
                </div>
                <div style={{ position: 'relative', flex: 2, maxWidth: 70, height: 1, marginTop: 16, background: '#aaa' }}>
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 25,
                      transform: 'translateY(-50%)',
                      color: '#aaa',
                      fontSize: 24,
                    }}
                  ></div>
                </div>
                <div>
                  <div
                    style={{
                      flex: 3,
                      padding: '8px 16px',
                      borderRadius: 999,
                      background: '#fff',
                      border: '1px solid #aaa',
                      fontSize: 14,
                    }}
                  >
                    Pay
                  </div>
                  <div className="mt-2 font-14">Step 2</div>
                  <div className="mt-2" style={{ fontSize: 12, color: '#999' }}>
                    Secure your booking
                  </div>
                </div>
                <div style={{ position: 'relative', flex: 2, maxWidth: 70, height: 1, marginTop: 16, background: '#aaa' }}>
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 25,
                      transform: 'translateY(-50%)',
                      color: '#aaa',
                      fontSize: 24,
                    }}
                  ></div>
                </div>
                <div>
                  <div
                    style={{
                      flex: 3,
                      padding: '8px 16px',
                      borderRadius: 999,
                      background: '#fff',
                      border: '1px solid #aaa',
                      fontSize: 14,
                    }}
                  >
                    Get Ticket
                  </div>
                  <div className="mt-2 font-14">Step 3</div>
                  <div className="mt-2" style={{ fontSize: 12, color: '#999' }}>
                    Receive your e-ticket instantly
                  </div>
                </div>
                <div style={{ position: 'relative', flex: 3, maxWidth: 70, height: 1, marginTop: 16, background: '#aaa' }}>
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 25,
                      transform: 'translateY(-50%)',
                      color: '#aaa',
                      fontSize: 24,
                    }}
                  ></div>
                </div>
                <div>
                  <div
                    style={{
                      flex: 3,
                      padding: '8px 16px',
                      borderRadius: 999,
                      background: '#fff',
                      border: '1px solid #aaa',
                      fontSize: 14,
                    }}
                  >
                    Scan & Go
                  </div>
                  <div className="mt-2 font-14">Step 4</div>
                  <div className="mt-2" style={{ fontSize: 12, color: '#999' }}>
                    Arrive and enjoy your trip
                  </div>
                </div>
              </div>
            </div>

            <CommonModal
              open={showMap}
              close={this.viewMap}
              title={this.state.showMapType === AddressTypeEnum.TRIP_ADDRESS ? 'Destination' : 'Gathering Point'}
              className="feed--back--content inner--open--map--div"
              contentClassName="view-map-model"
            >
              <div className="boat-inner-map-div rent--inner--boat--location">
                <GoogleMarker
                  className="boat-inner-map-height"
                  markers={popupMapAddress && getGatheringPointMarker(popupMapAddress)}
                  geometricLocation={get(popupMapAddress, 'geometricLocation', '')}
                  isRedirectToMap
                />
              </div>
            </CommonModal>

            {images && images.length > 0 && isGallery && (
              <ModalGateway>
                <Modal onClose={this.closeLightbox}>
                  <Carousel
                    views={prepareGalleryData(this.state.showLayoutImages ? boatLayout : images, this.state.imageIndex)}
                    // styles={{ container: base => ({ ...base, background: '#0007' }) }}
                    // trackProps={{ infinite: true, instant: true }}
                  />
                </Modal>
              </ModalGateway>
            )}

            <CommonModal
              hideCloseIcon
              open={this.state.isVerificationPopupOpen}
              close={() => this.setState({ isVerificationPopupOpen: false })}
              title="Verify Your Account"
            >
              <div className="p-4">
                <p className="font-16 text-center">Please verify your email and phone number to proceed with the booking.</p>
                <div className="d-flex justify-content-around align-item-center">
                  <button className="btn btn-o-black" onClick={() => this.setState({ isVerificationPopupOpen: false })}>
                    Will do it later
                  </button>
                  <button className="btn btn-black" onClick={() => history.push('/profile')}>
                    Let's do it
                  </button>
                </div>
              </div>
            </CommonModal>
          </>
        )}
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  boatRentInnerData: state.boatRentReducer && state.boatRentReducer.boatRentInnerData,
  currentUser: state.loginReducer && state.loginReducer.currentUser,
  isReview: state.reviewReducer && state.reviewReducer.isReview,
  reviews: state.reviewReducer && state.reviewReducer.reviews,
  rentTripCityWise: state.boatRentReducer && state.boatRentReducer.rentTripCityWise,
  isLoading: state.boatRentReducer && state.boatRentReducer.isLoading,
  rentPdf: state.rentReducer && state.rentReducer.rentPdf,
  getRentPdfSuccess: state.rentReducer && state.rentReducer.getRentPdfSuccess,
  getPdfSuccess: state.pdfReducer && state.pdfReducer.getPdfSuccess,
  // loading: state.pdfReducer && state.pdfReducer.isLoading,
  pdf: state.pdfReducer && state.pdfReducer.pdf,
  countryDetails: state.dashboardReducer.countryDetails,
})

const mapDispatchToProps = dispatch => ({
  getRentInnerBoat: data => dispatch(getRentInnerBoat(data)),
  pageVisit: data => dispatch(pageVisit(data)),
  getRentTripCityWise: data => dispatch(getRentTripCityWise(data)),
  addLikes: data => dispatch(addLikes(data)),
  addFeedback: data => dispatch(addFeedback(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
  clearBoatRentData: () => dispatch(clearBoatRentData()),
  getReviewByModuleId: data => dispatch(getReviewByModuleId(data)),
  getModulePdf: data => dispatch(getModulePdf(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RentInner)
