import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import { Grid } from '@material-ui/core'
import * as Yup from 'yup'

import ErrorFocus from '../../../components/ws/ErrorFocus'
import { CommonModal } from '../../../components/modal/commonModal'
import { Field } from '../../../components'
import { ButtonComponent } from '../../../components/form/Button'
import { MEDIA_NAME } from '../../../util/enums/enums'
import { requireMessage, renderSelectOptions } from '../../../helpers/string'
import { getAddMedia } from '../../../helpers/s3FileUpload'
import './applyJobPopUp.scss'
import { stringValidation } from '../../../helpers/yupHelper'
import SingleSelect from '../../helper/singleSelect'

export const ApplyJobPopUp = props => {
  const { open, onClose, submitValues, job, getDocumentUrl, countries, history } = props

  const [initValues] = useState({
    country: '',
    appliedFor: job ? job.title : '',
    jobReference: job ? job.reference : '',
    fullName: '',
    email: '',
    coverLetter: '',
    cvUrl: '',
    experience: '',
    addMedia: {
      coverLetter: [],
      cvUrl: [],
    },
    removeMedia: [],
  })

  return (
    <CommonModal open={open} close={onClose} title="Apply for job" className="common-apply-job">
      <div className="auction-custom-ui">
        <Formik
          initialValues={{ ...initValues, appliedFor: job?.title, jobReference: job?.reference }}
          onSubmit={values => {
            values.addMedia = getAddMedia(values.addMedia)
            const { cvUrl, coverLetter, ...submitData } = values
            submitData.country = submitData.country.value
            submitData.job = job.id
            submitValues(submitData)
            onClose()
            history.push('/careers')
          }}
          validationSchema={Yup.object().shape({
            country: Yup.string().required(requireMessage('Country')),
            appliedFor: stringValidation.required(requireMessage('Job Applying For')),
            fullName: stringValidation.required(requireMessage('Full Name')),
            email: Yup.string().required(requireMessage('Email')),
            cvUrl: Yup.string().required(requireMessage('Cv')),
            experience: Yup.string().required(requireMessage('Experience')),
          })}
          render={({ setFieldValue, values, handleSubmit, errors }) => (
            <Form>
              <ErrorFocus />
              <Grid container>
                <Grid item sm={12} className="mb-3">
                  <div className="boatShow-field">
                    <SingleSelect
                      selectedOption={values.country}
                      options={renderSelectOptions(countries, 'name', 'id')}
                      placeholder={'Select County'}
                      onChange={select => {
                        setFieldValue('country', select)
                      }}
                    />
                    {/* <Field
                                            label="Select Country"
                                            id={"country"}
                                            name={"country"}
                                            isCountrySelector
                                            type="autocomplete"
                                            value={values.country}
                                            placeholder={values.country}
                                            onChangeText={place => {
                                                setFieldValue("country", place);
                                            }}
                                            required
                                        /> */}
                    <ErrorMessage component="div" name="country" className="error-message" />
                  </div>
                </Grid>

                <Grid item sm={12} className="mb-3">
                  <Field
                    label="Job Applying For"
                    name="appliedFor"
                    value={values.appliedFor}
                    type="text"
                    className="form-control"
                    required
                    disabled
                  />
                  <ErrorMessage
                    className="invalid-feedback ant-typography-danger d-block"
                    name={'appliedFor'}
                    component="span"
                  />
                </Grid>
                <Grid item sm={12} className="mb-3">
                  <Field
                    label="Job Reference"
                    name="jobReference"
                    value={values.jobReference}
                    type="text"
                    className="form-control"
                    disabled
                  />
                  <ErrorMessage
                    className="invalid-feedback ant-typography-danger d-block"
                    name={'jobReference'}
                    component="span"
                  />
                </Grid>
                <Grid item sm={12} className="mb-3">
                  <Field
                    label="Full Name"
                    name="fullName"
                    value={values.fullName}
                    type="text"
                    className="form-control"
                    onChangeText={e => setFieldValue('fullName', e.target.value)}
                    required
                  />
                  <ErrorMessage className="invalid-feedback ant-typography-danger d-block" name={'fullName'} component="span" />
                </Grid>
                <Grid item sm={12} className="mb-3">
                  <Field
                    label="Email"
                    name="email"
                    value={values.email}
                    type="text"
                    className="form-control"
                    onChangeText={e => setFieldValue('email', e.target.value)}
                    required
                  />
                  <ErrorMessage className="invalid-feedback ant-typography-danger d-block" name={'email'} component="span" />
                </Grid>
                <Grid item sm={12} className="mb-3">
                  <Field
                    label="How many years of experience?"
                    name="experience"
                    value={values.experience}
                    type="text"
                    className="form-control"
                    onChangeText={e => setFieldValue('experience', e.target.value)}
                    required
                  />
                  <ErrorMessage
                    className="invalid-feedback ant-typography-danger d-block"
                    name={'experience'}
                    component="span"
                  />
                </Grid>
                <Grid item sm={12} className="hr-line"></Grid>
                <Grid item sm={6} className="overflow-hidden">
                  <div className="mb-2">
                    <Field
                      label="Upload Cover Letter"
                      buttonText="Upload Cover Letter"
                      name="coverLetter"
                      type="single-document"
                      value={values.coverLetter}
                      className="form-control"
                      onChangeText={setFieldValue}
                      deleteIds={values.removeMedia}
                      verify
                      otherDoc
                      addMedia={values.addMedia}
                      mediaName={MEDIA_NAME.JOBCOVERLATER}
                      getDocumentUrl={getDocumentUrl}
                    />
                  </div>
                </Grid>
                <Grid item sm={6} className="overflow-hidden">
                  <div className="mb-2 job--apply--upload--cv">
                    <Field
                      label="Upload CV"
                      buttonText="Upload CV"
                      name="cvUrl"
                      type="single-document"
                      value={values.cvUrl}
                      className="form-control"
                      onChangeText={setFieldValue}
                      deleteIds={values.removeMedia}
                      verify
                      otherDoc
                      addMedia={values.addMedia}
                      mediaName={MEDIA_NAME.JOBCV}
                      getDocumentUrl={getDocumentUrl}
                      required
                    />
                    <ErrorMessage className="invalid-feedback ant-typography-danger d-block" name={'cvUrl'} component="span" />
                  </div>
                </Grid>

                <Grid item sm={12} className="apply-btn-mt">
                  <div className="d-flex justify-content-center">
                    <ButtonComponent className=" h-auto as--success--outline btn--padding btn--font" onClick={handleSubmit}>
                      {'Apply Now'}
                    </ButtonComponent>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        />
      </div>
    </CommonModal>
  )
}
