import React from 'react'
import { Field } from '../../components'


export const SearchThreadText = ({ search, setSearch }) => {
    return (
        <div className="chat--search--div--spacing d-flex justify-content-center">
            <div className="chat--right--search--div">
                <div className="chat--search--img">
                    <img loading="lazy" src={require('../../assets/images/chat/search.svg')} alt="Search" />
                </div>

                <div className="chat--input--div chat--search--text">
                    <Field
                        value={search}
                        name="rightSearchInput"
                        onChangeText={({ target }) => {
                            setSearch(target.value)
                        }}
                        type="text" placeholder="Search in Conversation" />
                </div>
            </div>
        </div>
    )
}
