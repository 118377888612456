import React from 'react'

export default function ChatInput({accept, handleFileChange,multiple=true, className=''}) {
    const inputRef = React.useRef();

    const handleChoose = () => {
        inputRef.current.click();
    };
    return (
        <>
            <input
                ref={inputRef}
                className="chat-media--video"
                multiple={multiple}
                type="file"
                onChange={handleFileChange}
                accept={accept}
            />
            <div className={`chat-media-video--btn ${className}`} onClick={handleChoose} />
        </>

    )
}