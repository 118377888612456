import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { Video } from '../popUp/video'
import { CommonTooltip } from '../CommonTooltip'
import { defaultImage } from '../../util/enums/enums'

export const VideoModel = ({ closeVideo, videoFlag, videoUrl, thumbnail }) => {
  return (
    <>
      {videoFlag && videoUrl && (
        <>
          <div className="video-modal open-video-modal">
            <OutsideClickHandler onOutsideClick={closeVideo}>
              <div className="video-modal-cross" onClick={closeVideo}>
                <CommonTooltip component={ctProps => (
                  <img data-tooltip-id={ctProps.id} data-tooltip-content="Close" loading="lazy" src={require('../../assets/images/close-wishlist-icon.png')} alt="Close" />
                )} />
              </div>
              <div className="video--modal-content vidnohid">
                <Video videoUrl={videoUrl} thumbnail={thumbnail} isOpen />
              </div>
            </OutsideClickHandler>
          </div>
          <div className="bg-video-popup"></div>
        </>
      )}
    </>
  )
}

VideoModel.defaultProps = {
  thumbnail: defaultImage,
}
