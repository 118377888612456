import {
  GET_ALL_MEDIA_ARTICLES_SUCCESS,
  GET_ALL_MEDIA_ARTICLES_FAILURE,
  GET_ALL_MEDIA_RECORD_SUCCESS,
  GET_ALL_MEDIA_RECORD_FAILURE,
  GET_ALL_MEDIA_RECORD,
} from '../actionTypes'

const InitialState = {
  articles: [],
  boatMedia: {},
}

export const mediaArticleReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_ALL_MEDIA_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload.items,
        total: action.total,
      }

    case GET_ALL_MEDIA_ARTICLES_FAILURE:
      return {
        ...state,
        articles: [],
      }

    case GET_ALL_MEDIA_RECORD:
      return {
        ...state,
        boatMedia: {},
        loading: true,
      }

    case GET_ALL_MEDIA_RECORD_SUCCESS:
      return {
        ...state,
        boatMedia: action.payload,
        loading: false,
      }

    case GET_ALL_MEDIA_RECORD_FAILURE:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
