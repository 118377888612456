import React, { Component, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { AiFillMessage } from 'react-icons/ai'
import { Col } from 'react-bootstrap'
import { FaUser } from 'react-icons/fa'
import { BoatMediaArticles } from '../../components/gridComponents/boatMediaArticles'
import { BoatMediaPosts } from '../../components/gridComponents/boatMediaPosts'
import { BoatMediaReviews } from '../../components/gridComponents/boatMediaReviews'
import { dimension, pagination, mediaCategory } from '../../util/enums/enums'
import UserContext from '../../UserContext'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import { getAllReviews } from '../../redux/actions/mediaReviewsAction'
import { Box } from '@material-ui/core'
import { VideoComponent } from '../../components/videoComponent/videoComponent'
import { VideoModel } from '../../components/videoComponent/videoModel'
import { Layout, mediaReview } from '../../components'
import './boatMedia.scss'
import { getAllMedia } from '../../redux/actions/mediaArticleAction'
import { displayDefaultNumericValue } from '../../helpers/string'
import ServicesMarinaStorage from '../../components/staticComponent/servicesMarinaStorage'
import CommonBanner from '../../components/mainBanner/commonBanner'
import { getModuleWiseBanners } from '../../redux/actions'

class BoatMedia extends Component {
  state = {
    setVideoFlag: false,
    videoUrl: '',
    videoThumbnail: '',
    height: dimension.boatMediaMainDiv.height,
    width: dimension.boatMediaMainDiv.width,
    videoWidth: dimension.boatMediaVideo.width,
    videoHeight: dimension.boatMediaVideo.height,
  }

  static contextType = UserContext

  playVideo = (url, thumbnail) => {
    this.setState(prevState => ({
      setVideoFlag: !prevState.setVideoFlag,
      videoUrl: url,
      videoThumbnail: thumbnail,
    }))
  }

  closeVideo = () => {
    this.setState({ setVideoFlag: false })
  }

  getMediaCount = type => {
    const { boatMedia } = this.props
    const { category } = boatMedia
    switch (type) {
      case 'articles':
        const articleCount = category.find(item => item.label === type)
        return articleCount?.count

      default:
        return ''
    }
  }

  componentDidMount() {
    const width = getRatio(dimension, 'boatMediaMainDiv', '.boat-media-main-section')
    const height = getHeightRatio(dimension, 'boatMediaMainDiv', '.boat-media-main-section')
    const videoWidth = getRatio(dimension, 'marinaStorageVideo', 'body')
    const videoHeight = getHeightRatio(dimension, 'mediaBoatVideo', 'body')

    this.setState({ width, height, videoWidth, videoHeight })

    const { getAllReviews, getAllMedia, getModuleWiseBanners } = this.props

    getModuleWiseBanners({
      type: mediaCategory.boatMedia.type,
      fieldName: mediaCategory.boatMedia.fieldName,
      isBanner: true,
    })

    getAllReviews()
    getAllMedia({ limit: pagination.MARKET_PAGE_LIMIT, page: pagination.PAGE_COUNT })
  }

  render() {
    const { allReviews, articlesTotal, boatMedia, totalMediaArticles, boatMediaBanner } = this.props
    const { articles, category, popularPosts, adMedia, howToMedia, adamseaMedia } = boatMedia

    const { setVideoFlag, videoUrl, videoWidth, videoHeight, videoThumbnail } = this.state

    return (
      <Layout className="boat-media-layout">
        <Grid container className=" position-relative">
          <Grid container>
            <Grid item xs={12}>
              <div className="boat-service-img">
                {boatMediaBanner && boatMediaBanner.length > 0 && (
                  <>
                    <CommonBanner data={boatMediaBanner} />
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid container className="w-85vw m-auto boat--media--pages--link--section">
          <Grid item xs={12} className='boat-media-parent-comp'>
            <h1 className="r-h1 mb-0">
              <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                Explore AdamSea
              </Box>
            </h1>
            <ServicesMarinaStorage type="boatMedia" className="mb-20" />
          </Grid>
        </Grid> */}

        <div className="w-85vw m-auto boat--media--main--div">
          <div className="boat-media-main-section d-flex justify-content-between">
            {adamseaMedia &&
              adamseaMedia.map((video, index) => {
                if (index < 6) {
                  return (
                    <Fragment key={video.id}>
                      <VideoComponent
                        setVideoUrlHandler={thum =>
                          this.playVideo(video?.url?.[0]?.url, video.thumbnail?.length > 0 ? video?.thumbnail?.[0]?.url : thum)
                        }
                        video={video}
                        videoWidth={videoWidth}
                        videoHeight={videoHeight}
                        isBoatMedia
                      />
                    </Fragment>
                  )
                }
              })}
          </div>
        </div>
        {articles && articles.length !== 0 && (
          <div className="w-85vw m-auto">
            <Grid container>
              <div className="width-100 d-flex flex-row">
                <Col xs={9} className="boat-media-article-div">
                  <div className="media--artical--div artical--media">
                    <h4 className="font-weight-500 section-heading mt-0">
                      Articles
                    </h4>

                    <div className="">
                      <BoatMediaArticles
                        itemsLength={15}
                        boatMediaArticles={articles}
                        dimension={dimension.boatMediaMainArticles}
                        isMediaBoat
                        // need for future use
                        total={this.getMediaCount('articles')}
                        // total={totalMediaArticles}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={3} className="media--categories--posts--div pr-0 cus-popular-post">
                  {/* <div className="media--artical--div">
                    <div className="d-flex">
                      <h4 className="font-weight-500 gray-dark media--div--title">Categories</h4>
                    </div>
                    <div className="boat-inner-facilities-div d-flex align-items-center boat--media--categories">
                      {category.length > 0 && (
                        <ul className="rent pl-0 d-flex width-100 flex-wrap mb-0">
                          {category.map(({ count, label }) => {
                            return (
                              <li className="font-weight-400 dark-silver">
                                {label} ({displayDefaultNumericValue(count)})
                              </li>
                            )
                          })}
                        </ul>
                      )}
                    </div>
                  </div> */}

                  <div className="media--artical--div boat--media--post--div">
                    <h4 className="font-weight-500 section-heading">Popular Posts</h4>

                    <div className="boat-media-popular-posts-div">
                      {/* <Grid container className=""> */}
                      <BoatMediaPosts boatMediaPosts={popularPosts} xs={12} sm={12} dimension={dimension.boatMediaPost} />
                      {/* </Grid> */}
                    </div>
                  </div>
                </Col>
              </div>
            </Grid>
          </div>
        )}

        {adMedia && adMedia.length > 0 && (
          <div item xs={12} className="rent-video-section w-85vw m-auto pl-0 pr-0">
            <Box
              className="section-heading w-100 media--div--title boat--media--videos--title boat-media-main-section"
              fontSize={24}
              fontWeight={500}
              letterSpacing={0.5}
            >
              Videos
            </Box>
            <div className="media--review--section media--boat--video--div">
              <Grid container className="boat--media--videos--section" spacing={6}>
                {adMedia &&
                  adMedia.map((video, index) => {
                    if (index < 6) {
                      return (
                        <Fragment key={video.id}>
                          <VideoComponent
                            setVideoUrlHandler={thum =>
                              this.playVideo(
                                video?.url?.[0]?.url,
                                video.thumbnail?.length > 0 ? video?.thumbnail?.[0]?.url : thum
                              )
                            }
                            video={video}
                            videoWidth={videoWidth}
                            videoHeight={videoHeight}
                            isBoatMedia
                          />
                        </Fragment>
                      )
                    }
                  })}
              </Grid>
              {adMedia.length > 6 ? (
                <div className="mt-4 mb-0">
                  <h2>
                    <Link to={'#'} className="show-link mb-0 text-decoration-unset hover-text-only media--show--all">
                      See all {adMedia.length} medias
                      <KeyboardArrowRight />
                    </Link>
                  </h2>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}

        {/* API changes will add, commented due to new design changes */}

        {/* {allReviews && allReviews.length > 0 &&  */}
        {/* <div className="w-85vw m-auto">
          <Grid container>
            <div className="width-100">
              {boatMedia.reviews?.length && (
                <div className="width-100 boat-media-review-section media--artical--div media--review--section">
                  <h4 className="font-weight-500 section-heading">Reviews</h4>
                  <BoatMediaReviews itemsLength={8} boatMediaReviews={boatMedia.reviews} xs={6} sm={6} />
                </div>
              )}
            </div>
          </Grid>
        </div> */}
        {/* } */}

        {howToMedia && howToMedia.length > 0 && (
          <div item xs={12} className="rent-video-section w-85vw m-auto pl-0 pr-0 mt-3">
            <div className="media--review--section media--boat--video--div">
              <Grid container className="boat-media-last-video-section video-gapping-space-common" >
                {howToMedia &&
                  howToMedia.map((video, index) => {
                    if (index < 3) {
                      return (
                        <Fragment key={video.id}>
                          <VideoComponent
                            setVideoUrlHandler={thum =>
                              this.playVideo(
                                video?.url?.[0]?.url,
                                video.thumbnail?.length > 0 ? video?.thumbnail?.[0]?.url : thum
                              )
                            }
                            video={video}
                            videoWidth={videoWidth}
                            videoHeight={videoHeight}
                            isBoatMedia
                          />
                        </Fragment>
                      )
                    }
                  })}
              </Grid>
              {howToMedia.length > 3 ? (
                <div className="mt-4 mb-0">
                  <h2>
                    <Link to={'#'} className="show-link mb-0 text-decoration-unset hover-text-only media--show--all">
                      See all {howToMedia.length} medias
                      <KeyboardArrowRight />
                    </Link>
                  </h2>
                </div>
              ) : (
                <></>
              )}
            </div>

            <VideoModel videoFlag={setVideoFlag} videoUrl={videoUrl} thumbnail={videoThumbnail} closeVideo={this.closeVideo} />
          </div>
        )}
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  boatMedia: state.mediaArticleReducer && state.mediaArticleReducer.boatMedia,
  allReviews: state?.mediaReviewsReducer?.reviews,
  totalMediaArticles: state.mediaArticleReducer && state.mediaArticleReducer.total,
  boatMediaBanner: state.dashboardReducer && state.dashboardReducer[mediaCategory.boatMedia.fieldName],
})

const mapDispatchToProps = dispatch => ({
  getAllReviews: () => dispatch(getAllReviews()),
  getAllMedia: data => dispatch(getAllMedia(data)),
  getModuleWiseBanners: type => dispatch(getModuleWiseBanners(type)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatMedia)
