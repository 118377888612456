import {
  GET_CATEGORY_WISE_ADVERTISEMENTS,
  GET_CATEGORY_WISE_ADVERTISEMENTS_SUCCESS,
  GET_CATEGORY_WISE_ADVERTISEMENTS_FAILURE,
} from '../actionTypes'

const InitialState = {
  getAdvertisementSuccess: false,
  getAdvertisementError: false,
  advertisements: {},
}

export const advertisementReducer = (state = InitialState, action) => {
  switch (action.type) {
    // START

    case GET_CATEGORY_WISE_ADVERTISEMENTS:
      return {
        ...state,
        getAdvertisementSuccess: false,
        getAdvertisementError: false,
        advertisements: [],
      }

    case GET_CATEGORY_WISE_ADVERTISEMENTS_SUCCESS:
      return {
        ...state,
        getAdvertisementSuccess: true,
        getAdvertisementError: false,
        advertisements: action.payload.data.categoryWiseAd,
      }

    case GET_CATEGORY_WISE_ADVERTISEMENTS_FAILURE:
      return {
        ...state,
        getAdvertisementSuccess: false,
        getAdvertisementError: true,
        advertisements: [],
      }

    // END

    default:
      return state
  }
}
