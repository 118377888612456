import styles from './GetApp.module.scss'
import { useEffect, useState } from 'react'
import GetAppContent from './GetAppContent'

export default function GetApp() {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleClick = evt => {
      if (
        evt.target instanceof Element &&
        !evt.target.closest('.' + styles.getappBtn) &&
        !evt.target.closest('.' + styles.getappDdWrp)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div className={styles.getappWrp}>
      <button className={styles.getappBtn} onClick={() => setIsOpen(isOpen => !isOpen)}>
        Get the App
      </button>

      {isOpen && (
        <div className={styles.getappDdWrp}>
          <GetAppContent />
        </div>
      )}
    </div>
  )
}
