import gql from 'graphql-tag'

export const closeAccount = {
  name: 'closeAccount',
  query: gql`
    mutation closeAccount($input: CloseAccountInput!) {
      closeAccount(input: $input)
    }
  `,
}
