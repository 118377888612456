/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import { Grid, TextField } from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Rating from 'react-rating'

import UserContext from '../../UserContext'
import { ratingEnums, shareModule } from '../../util/enums/enums'
import { getId, ratingUserWiseValidation, reviewType } from '../../util/utilFunctions'
import ErrorFocus from '../ws/ErrorFocus'
import { CommonModal } from '../modal/commonModal'
import { ButtonComponent } from '../form/Button'
import { stringValidation } from '../../helpers/yupHelper'
import '../../components/home/icon.scss'
import './ReviewRating.scss'
import '../../containers/boatInner/BoatInner.scss'
import { toast } from 'react-toastify'
import { graphqlClient } from '../../helpers/graphqlClient'
import { createReview } from '../../graphql/ReviewSchema'

const InnerRatingModal = props => {
  const { match } = useContext(UserContext)

  const [visible, setVisible] = useState(false)

  const [open, setOpen] = useState(false)
  const [reviewError, setReviewError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(false)

  const action_createReview = async (input) => {
    setReviewError(false)
    setLoading(true)

    try {
      const res = await graphqlClient.mutate({
        mutation: createReview,
        variables: { input, inProfile: true }
      })

      if (res.data.createReview) {
        toast.success('Review posted successfully')
        setData(res.data.createReview)
        closeReviewModal()
      } else {
        throw null
      }
    } catch (e) {
      const err = get(e, 'networkError.result.errors[0].message', 'Cannot post review')
      toast.error(err)
    } finally {
      setLoading(false)
    }
  }

  const closeReviewModal = () => {
    setVisible(!visible)
    setReviewError(false)
  }

  const [review, setReview] = useState({})

  useEffect(() => {
    if (props.initVals) {
      setData(props.initVals)
    }
  }, [props.initVals])

  useEffect(() => {
    if (data) {
      setReview({
        rating: data?.rating || 0,
        reviews: data?.reviews || '',
        reviewWithTypes: [],
        ...data?.reviewWithTypes?.reduce((a, c) => ({ ...a, [c.type]: c.rating }), {}),
      })
    }
  }, [data])

  const { currentUser, userModule, module, AddReviewButton } = props
  const renderTypeWiseRatings = (values, setFieldValue) => {
    const { userProfileDetail, module } = props
    const ratingTypes = module?.role ? reviewType(module.role) : reviewType(module?.seller?.role)
    return (
      <div className="ratingQuality width-100 d-flex justify-content-around flex-column">
        {ratingTypes &&
          ratingTypes.length > 0 &&
          ratingTypes.map(item => (
            <>
              <div className="d-flex rating--info--div" key={item.name + item.key}>
                <span className="review-quality profile-add-review">{item.name}</span>

                <Field name={item.key}>
                  {({ field, form: { setFieldValue, errors } }) => (
                    <div className="d-flex flex-column align-items-end w-100">
                      <Rating
                        className="rating-clr profile-add-rating ratting--main--div"
                        initialRating={field.value}
                        onClick={value => {
                          setFieldValue(item.key, value)
                        }}
                        emptySymbol={<StarBorderIcon />}
                        placeholderSymbol={<StarIcon />}
                        fullSymbol={<StarIcon />}
                      />
                      {reviewError && <p className="error-message mb-0 rating--error--message">{errors[item.key]}</p>}
                    </div>
                  )}
                </Field>
              </div>
            </>
          ))}
      </div>
    )
  }

  return (
    <>
      {currentUser &&
        currentUser.id &&
        (AddReviewButton ? (
          <AddReviewButton onClick={closeReviewModal} />
        ) : (
          <button className="btn btn-o-black btn-o-ghost-black" onClick={closeReviewModal}>
            <img
              loading="lazy"
              src={require('../../assets/images/boatInner/boatinner-like.png')}
              className="inner-feedback-img mr-2"
              alt="Review"
            />
            <span className="add-feedback-text">{data ? 'Edit' : 'Add'} Review</span>
          </button>
        ))}
      <CommonModal open={visible} close={closeReviewModal} title={data ? 'Edit' : 'Add'} className="add--review--modal">
        <Formik
          enableReinitialize
          initialValues={{ ...review }}
          onSubmit={values => {
            const { currentUser, module, subModuleId, moduleType } = props
            const { role } = module
            let newValues = {
              ...values,
              moduleId: getId(module),
              user: getId(currentUser),
              userModuleId: getId(userModule),
              moduleType: moduleType || shareModule.USER,
            }
            if (subModuleId) newValues = { ...newValues, subModuleId }
            const { userProfileDetail } = props
            const ratingTypes = role && reviewType(role)
            const selectedKeys = ratingTypes?.length && ratingTypes.map(item => item.key).filter(Boolean)
            let arr = []
            for (const key in ratingEnums) {
              if (ratingEnums.hasOwnProperty(key) && selectedKeys && selectedKeys?.includes(key)) {
                arr.push({
                  type: key,
                  rating: newValues[key],
                })
              }
              delete newValues[key]
            }
            newValues.reviewWithTypes = arr
            newValues.rating = arr.reduce((acc, el) => acc + el.rating, 0) / arr.length
            if (newValues.reviewWithTypes && newValues.reviewWithTypes.length) {
              if (newValues.user && newValues.moduleId) {
                action_createReview(newValues)
              }
            }
          }}
          validationSchema={Yup.object().shape({
            recommended: stringValidation.required('Please add your recommended rating.'),
            ...ratingUserWiseValidation(module?.role),

            reviews: stringValidation.required('Please add your reviews.'),
          })}
          render={({ setFieldValue, values, handleSubmit, errors }) => (
            <Form>
              <ErrorFocus />
              <div className="review-form ">
                <Grid container className="review--content mobile-view-rating">
                  <Grid item sm={12}>
                    <div className="d-flex align-items-center review--rating--section">
                      {renderTypeWiseRatings(values, setFieldValue)}
                    </div>
                  </Grid>
                  <Grid item sm={12} className="gutter-box mt-10 review--add--text--box--div ">
                    <TextField
                      name="reviews"
                      className="form-control review--add--text--box h-auto add--review-text--area"
                      placeholder="Review"
                      value={values.reviews}
                      onChange={e => setFieldValue('reviews', e.target.value)}
                      multiline
                      rows={5}
                      variant="filled"
                    />
                    <ErrorMessage component="div" name="reviews" className="error-message rating--error--message" />
                  </Grid>
                  <div className="w-100 float-left text-center">
                    <Grid item sm={12} className="mt-10 review-button d-flex justify-content-center">
                      <button
                        type="submit"
                        style={{ padding: '12px 24px !important' }}
                        className="btn btn-o-black btn-o-ghost-black"
                        onClick={() => setReviewError(true)}
                        disabled={loading}
                      >
                        Post Review
                      </button>
                    </Grid>
                  </div>
                </Grid>
              </div>
            </Form>
          )}
        />
      </CommonModal>
    </>
  )
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser || {},
  userProfileDetail: state.loginReducer.userProfileDetail || {},
})

export default connect(mapStateToProps)(InnerRatingModal)
