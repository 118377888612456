import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getRecentlyAddedServices } from '../../redux/actions'
import BoatListingsWithMap from './BoatListingsWithMap'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import { pagination } from '../../util/enums/enums'
import UserContext from '../../UserContext'
import { getLocalStorageItem } from '../../helpers/storageHelper'

class ShowAllService extends Component {
  static contextType = UserContext

  constructor(props, context) {
    super(props, context)
  }

  render() {
    const { recentlyAddedService, totalRecentlyAddedService, getRecentlyAddedServices, isPageLoader } = this.props
    const { country } = this.context
    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        isBoatService
        boatsTypeCount={totalRecentlyAddedService || 0}
        boatsType={`Explore  ${totalRecentlyAddedService || 0} new added boat services in ${country}`}
        boats={recentlyAddedService}
        action={getRecentlyAddedServices}
        mapGridClassName="service--show--all--grid"
      />
    )
  }
}

const mapStateToProps = state => ({
  recentlyAddedService: state.boatServiceReducer && state.boatServiceReducer.recentlyAddedService,
  totalRecentlyAddedService: state.boatServiceReducer && state.boatServiceReducer.totalRecentlyAddedService,
  isPageLoader: state.boatServiceReducer && state.boatServiceReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  getRecentlyAddedServices: data => dispatch(getRecentlyAddedServices(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllService)
