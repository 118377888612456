const data = [
  {
    title: 'Global shipping company',
    text: `In addition to expanding your business and reaching a wider customer base, partnering with AdamSea as a global shipping company also provides a valuable opportunity for networking and collaboration within the marine industry. You'll have the chance to connect with other industry professionals, exchange knowledge and experiences, and stay up-to-date with the latest trends and developments.`,
  },

  {
    title: 'Shipping Solutions Provider',
    text: `By joining AdamSea as a shipper, you become an integral part of our mission to provide a comprehensive and reliable platform for the entire boating community. Whether you specialize in local or international shipping, our platform offers the exposure and tools you need to thrive in the marine industry.`,
  },

  {
    title: 'Shipping Success',
    text: `Don't miss out on the opportunity to join our network of trusted shipping companies. Sign up today and start leveraging the power of AdamSea to grow your business and provide exceptional shipping services to boat owners worldwide.`,
  },
]

export default data
