import React, { Fragment } from 'react'
import '../home/home.scss'
import UserContext from '../../UserContext'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { dimension } from '../../util/enums/enums'
import { ShowAllLink } from '../helper/showAllLink'
import { BoatDetail } from '../home/singleComponents/boatDetail'

export class BoatGrid extends React.Component {
  static contextType = UserContext
  state = {
    width: 380,
    height: 280,
    gridClassName: `grid--new--${dimension['homeMostPopular'].divide}`,
    margin: 15,
  }
  widthZoom = () => {
    const { isBottomRating, isBoatInner, isFeature, isUserProfile } = this.props
    //Need Refactor I will below code .
    const key = isUserProfile
      ? 'boatUserProfile'
      : isFeature
      ? 'featureBoat'
      : isBottomRating
      ? 'homeMostPopular'
      : 'homeRecommended'
    const finalKey = isBoatInner ? 'boatInnerGrid' : key
    const width = getRatioZoomResponsive(dimension, finalKey, '.zoom-container')
    const height = getHeightZoomResponsive(dimension, finalKey, '.zoom-container')

    this.setState({ width: width.width, gridClassName: width.className, margin: width.margin, height })
  }
  componentDidMount() {
    this.widthZoom()
    window.addEventListener('resize', this.widthZoom)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.widthZoom)
  }
  render() {
    const {
      boatGrid,
      itemsLength,
      showType,
      route,
      isTopLabel,
      isBottomRating,
      className,
      total,
      isFeature,
      isBestDeal,
      isRecentSellBoat,
      isLittleBoat,
      showAllText
    } = this.props
    const { width, height, gridClassName, margin } = this.state

    const routeName = route ? 'show-all' : 'show-all-boats'

    return (
      <>
        <div className="zoom-container home-grid-layout-boat">
          {boatGrid && boatGrid.length
            ? boatGrid.map((value, index) => {
                return (
                  <Fragment key={value.id}>
                    {itemsLength > index && (
                      <BoatDetail
                        value={value}
                        isTopLabel={isTopLabel}
                        isBottomRating={isBottomRating}
                        width={width}
                        height={height}
                        margin={margin}
                        index={index}
                        className={className}
                        gridClassName={gridClassName}
                      />
                    )}
                  </Fragment>
                )
              })
            : 'There is no boats found.'}
        </div>

        {showType && (
          <ShowAllLink
            totalLength={total}
            className="boat--grid-show--all"
            data={boatGrid}
            itemsLength={itemsLength}
            url={`/${routeName}/${showType}`}
            showAllText={showAllText}
          />
        )}
      </>
    )
  }
}

BoatGrid.defaultProps = {
  isTopLabel: false,
  isBottomRating: false,
  isCustomWidth: false,
  isBoatInner: false,
  isUserProfile: false,
  className: '',
  key: 'homeMostPopular',
}
