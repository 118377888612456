import React from 'react'
import { CommonAdamSeaStyle } from '../styleComponent/styleComponent'
import { percentageFormate } from '../../util/utilFunctions'
import { viewServiceBoatHandler } from '../../helpers/boatHelper'
import UserContext from '../../UserContext'
import SharePopup from '../share/SharePopup'
import { wishlistModuleEnum, shareModule, dimension, defaultProfileIcon } from '../../util/enums/enums'
import { cityCountryNameFormatter, redirectToUserProfile } from '../../helpers/jsxHelper'
import { WishlistComponent } from '../wishlist/WishlistComponent'
import RatingComponent from '../rating/Rating'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { textTruncate } from '../../helpers/string'
import '../../assets/css/component/boatServiceGrid.scss'
import { withRouter } from 'react-router'
class BoatAddedService extends React.Component {
  state = {
    selectedIndex: null,
  }

  static contextType = UserContext

  handleClick = index => {
    const { selectedIndex } = this.state

    this.setState({
      selectedIndex: selectedIndex !== index ? index : null,
    })
  }

  render() {
    const {
      value,
      index,
      width,
      height,
      gridClassName,
      margin,
      match: { params },
      location,
    } = this.props
    const { selectedIndex } = this.state

    const {
      images,
      id,
      address: [{ city, country }],
      service,
      rating,
      serviceProvide,
      user,
    } = value

    const urlParams = new URLSearchParams(location.search)

    const selectedTypeId =
      serviceProvide?.length && params?.typeId && params?.typeName
        ? params.typeId
        : urlParams.has('service')
        ? urlParams.get('service')
        : null

    const displayServiceProvide = selectedTypeId
      ? serviceProvide.find(item => item.id === selectedTypeId)?.name
      : serviceProvide[0].name
    return (
      <div className={`recently--added--services ${gridClassName}`} style={{ width: width, marginRight: margin }}>
        <div className="recently--grid--img--boat">
          <CommonAdamSeaStyle
            className="recently--grid--img--radius"
            img={encodeURI(images && images.length > 0 && images[0]?.url)}
            height={height}
            onClick={() => viewServiceBoatHandler(value)}
            width={width}
          />
          <div className="card-action">
            {user && (
              <div className="company--logo--recently--img">
                <img
                  loading="lazy"
                  className="rounded-circle"
                  onClick={() => redirectToUserProfile(user, this.context.history)}
                  src={user?.image?.url ? encodeURI(user.image.url) : defaultProfileIcon}
                  alt="Profile"
                />
              </div>
            )}
            <span></span>
            <div className="share-icon">
              <WishlistComponent moduleId={id} moduleType={wishlistModuleEnum.YACHT_SERVICE} />
              {/* <SharePopup
                handleClick={() => this.handleClick(index)}
                index={index}
                selectedIndex={selectedIndex}
                moduleId={id}
                moduleType={shareModule.YACHT_SERVICE}
              /> */}
            </div>
          </div>
        </div>
        <div className="recently--grid--boat--content">
          <div className="recently--grid--boat--service--with--review">
            <div className="recently--grid--boat--service">{displayServiceProvide}</div>
            <div className="d-flex justify-content-between recently--grid--boat--review">
              <span className="review-star sm float-left d-flex boat-service-added-rating">
                <RatingComponent rating={rating} className="rating-clr d-flex boat-added-service-rating-align" />
                <span className="rating--count gray-light">({percentageFormate(rating) || `${percentageFormate(0)}`})</span>
              </span>
            </div>
          </div>
          <h5 className="place-city place--city--color">
            <span>{cityCountryNameFormatter(city, country)}</span>
          </h5>

          <h5 className="recently--grid--service--text line-clamp">{textTruncate(service, 120)}</h5>

          {user && user.companyName && <div className="recently--grid--company--name">{user.companyName}</div>}
        </div>
      </div>
    )
  }
}
BoatAddedService.defaultProps = {
  isShowAll: false,
  dividedBySize: 5,
}

export default withRouter(BoatAddedService)
