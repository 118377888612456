import React, { Fragment } from 'react'
import { SingleMarinaDetail } from '../marinaStorage/singleMarinaDetail'
import { ShowAllLink } from '../helper/showAllLink'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { dimension } from '../../util/enums/enums'

export class RecommendedMarinaStorages extends React.Component {
  state = {
    width: dimension.marinaMostPopular.width,
    height: dimension.marinaMostPopular.height,
    gridClassName: `grid--new--${dimension['marinaMostPopular'].divide}`,
    margin: 15,
  }
  widthZoom = () => {
    const { dimensionKey } = this.props

    const width = getRatioZoomResponsive(dimension, dimensionKey, '.zoom-container')
    const height = getHeightZoomResponsive(dimension, dimensionKey, '.zoom-container')

    this.setState({ width: width.width, gridClassName: width.className, height, margin: width.margin })
  }
  componentDidMount() {
    this.widthZoom()
    window.addEventListener('resize', this.widthZoom)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.widthZoom)
  }
  render() {
    const { data, itemsLength, isShowLink, url, isUserProfile, total } = this.props

    const { width, height, gridClassName, margin } = this.state
    return (
      <>
        <div className="zoom-container marina--grid-style-main--div">
          {data?.length > 0 &&
            data.map((value, index) => {
              return (
                <Fragment key={value.id}>
                  {index < itemsLength ? (
                    <SingleMarinaDetail
                      value={value}
                      index={index}
                      width={width}
                      height={height}
                      margin={margin}
                      isUserProfile={isUserProfile}
                      gridClassName={gridClassName}
                    />
                  ) : (
                    <> </>
                  )}
                </Fragment>
              )
            })}
        </div>
        {isShowLink && (
          <ShowAllLink data={data} className="marina-boat-show-all" itemsLength={itemsLength} totalLength={total} url={url} showAllText={this.props.showAllText} />
        )}
      </>
    )
  }
}
RecommendedMarinaStorages.defaultProps = {
  itemsLength: 8,
  isShowLink: true,
  url: '',
  dimensionKey: 'marinaMostPopular',
  data: [],
  isUserProfile: false,
}
