import { combineReducers } from 'redux'
import { loginReducer } from './loginReducer'
import { errorReducer } from './errorReducers'
import { dashboardReducer } from './dashboardReducer'
import { boatReducer } from './boatReducer'
import { marinaAndStorageReducer } from './marinaAndStorageReducer'
import { branchReducer } from './branchReducer'
import { boatRentReducer } from './boatRentReducer'
import { articleReducer } from './articleReducer'
import { rentReducer } from './rentReducer'
import { boatServiceReducer } from './boatServiceReducer'
import { boatShowReducer } from './boatShowReducer'
import { videoReducer } from './VideoReducer'
import { reviewReducer } from './ReviewReducer'
import { advertisementReducer } from './advertisementReducer'
import { salesEngineReducer } from './salesEngineReducer'
import { mediaReviewsReducer } from './mediaReviewsReducer'
import { mediaArticleReducer } from './mediaArticleReducer'
import { userGuideReducer } from './userGuideReducer'
import { userFaqReducer } from './userFaqReducer'
import { pageInfoByTypeReducer } from './pageInfoByTypeReducer'
import { bannerReducer } from './bannerReducer'
import { boatRentBookingReducer } from './boatRentBookingReducer'
import { shareReducer } from './shareReducer'
import { dealersReducer } from './dealersReducer'
import { paymentReducer } from './paymentReducer'
import { pdfReducer } from './pdfReducer'
import notificationsSlice from '../slices/notificationsSlice'
import { reportReducer } from './reportReducer'
import { jobsReducer } from './jobsReducer'
import { downloadsReducer } from './downloadsReducer'
import { mapReducer } from './mapReducer'
import { archiveSalesEngineReducer } from './archiveSalesProcessReducer'
import { chatReducer } from './chatReducer'
import { seoReducer } from './seoReducer'
import accountSlice from '../slices/accountSlice'

export default combineReducers({
  loginReducer,
  dashboardReducer,
  boatReducer,
  marinaAndStorageReducer,
  branchReducer,
  boatRentReducer,
  articleReducer,
  rentReducer,
  boatServiceReducer,
  boatShowReducer,
  videoReducer,
  reviewReducer,
  advertisementReducer,
  errorReducer,
  salesEngineReducer,
  mediaReviewsReducer,
  mediaArticleReducer,
  userGuideReducer,
  userFaqReducer,
  pageInfoByTypeReducer,
  bannerReducer,
  boatRentBookingReducer,
  shareReducer,
  dealersReducer,
  paymentReducer,
  pdfReducer,
  notificationsReducer: notificationsSlice.reducer,
  reportReducer,
  jobsReducer,
  downloadsReducer,
  mapReducer,
  archiveSalesEngineReducer,
  chatReducer,
  seoReducer,
  accountReducer: accountSlice.reducer,
})
