import {
  GET_ALL_JOBS,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_JOBS_FAILURE,
  CLEAR_JOBS_FLAG,
  APPLY_FOR_JOB,
  APPLY_FOR_JOB_SUCCESS,
  APPLY_FOR_JOB_FAILURE,
  SEARCH_JOBS,
  SEARCH_JOBS_SUCCESS,
  SEARCH_JOBS_FAILURE,
  GET_JOBS_CONTENT,
  GET_JOBS_CONTENT_SUCCESS,
  GET_JOBS_CONTENT_FAILURE,
  GET_SIGLE_JOB_FAILURE,
  GET_SIGLE_JOB_SUCCESS,
  GET_SIGLE_JOB,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAILURE,
} from '../actionTypes'

const InitialState = {
  getSuccess: false,
  getError: false,

  applyJobSuccess: false,
  applyJobFailure: false,

  isLoading: false,

  jobs: [],
  jobsContent: {},
}

export const jobsReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_ALL_JOBS:
      return {
        ...state,
        isLoading: true,
      }

    case GET_ALL_JOBS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getSuccess: true,
        jobs: action.payload.items,
        total: action.payload.total,
      }

    case GET_ALL_JOBS_FAILURE:
      return {
        ...state,
        isLoading: false,
        getError: true,
        jobs: [],
      }

    case CLEAR_JOBS_FLAG:
      return {
        ...state,
        isLoading: false,
        getSuccess: false,
        getError: false,
      }

    case APPLY_FOR_JOB:
      return {
        ...state,
      }

    case APPLY_FOR_JOB_SUCCESS:
      return {
        ...state,
        applyJobSuccess: true,
      }

    case APPLY_FOR_JOB_FAILURE:
      return {
        ...state,
        applyJobFailure: true,
      }

    case SEARCH_JOBS:
      return {
        ...state,
      }

    case SEARCH_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.payload,
      }

    case SEARCH_JOBS_FAILURE:
      return {
        ...state,
      }

    case GET_JOBS_CONTENT:
      return {
        ...state,
      }

    case GET_JOBS_CONTENT_SUCCESS:
      return {
        ...state,
        jobsContent: action.payload,
      }

    case GET_JOBS_CONTENT_FAILURE:
      return {
        ...state,
        jobsContent: {},
      }

    case GET_SIGLE_JOB:
      return {
        ...state,
        singleJob: {},
        isLoading: true,
      }

    case GET_SIGLE_JOB_SUCCESS:
      return {
        ...state,
        singleJob: action.payload,
        isLoading: false,
      }

    case GET_SIGLE_JOB_FAILURE:
      return {
        ...state,
        singleJob: {},
        isLoading: false,
      }

    case GET_COUNTRY_LIST:
      return {
        ...state,
        isLoading: true,
      }

    case GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.payload.items,
      }

    case GET_COUNTRY_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
