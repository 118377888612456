import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import '../../../styles/manageDashboardTableResponsive.scss'

import { getSalesEngineByBuyer, getSingleSalesEngine } from '../../../redux/actions/salesEngineAction'
import { TableCard, Field } from '../../../components'
import { formattedDate } from '../../../helpers/dateTimeHelper'
import { ButtonComponent } from '../../../components/form/Button'
import { pagination } from '../../../util/enums/enums'
import { dashboardIndex, getPagesValue } from '../../../helpers/jsxHelper'
import { searchInDashboard, clearSearchDashboard } from '../../../redux/actions'
import { graphqlClient } from '../../../helpers/graphqlClient'
import { archiveSalesProcess, deleteSalesEngine } from '../../../graphql/archiveSalesEngineSchema'
import { toast } from 'react-toastify'
import { MdArchive, MdDelete } from 'react-icons/md'
import { clear_getStaticPages, getStaticPages } from '../../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'
import { CommonModal } from '../../../components/modal/commonModal'

class BuyerSalesEngine extends Component {
  state = {
    isDeleteModalOpen: null,
  }

  archive(SalesEngineId) {
    return graphqlClient.query({
      query: archiveSalesProcess,
      variables: { SalesEngineId, status: true },
    })
  }

  delete(id) {
    return graphqlClient.mutate({
      mutation: deleteSalesEngine,
      variables: { id },
    })
  }

  toggleDeleteModal = value => {
    this.setState(prevState => ({
      isDeleteModalOpen: prevState.isDeleteModalOpen ? null : value,
    }))
  }

  constructor(props) {
    super(props)

    this.state = {
      columns: [
        {
          Header: 'Boat Type',
          accessor: 'boat.boatType.name',
        },
        {
          Header: 'Ad ID',
          accessor: 'boat.adId',
        },
        {
          Header: 'Seller',
          accessor: 'seller.firstName',
          Cell: data =>
            data.original?.seller === null ? null : (
              <span>
                {data.original?.seller?.firstName} {data.original?.seller?.lastName}
              </span>
            ),
        },
        {
          Header: 'Requested',
          accessor: 'createdAt',
          Cell: ({ value }) => formattedDate(value, true),
        },
        {
          Header: 'Status',
          accessor: 'salesEngineStatus',
          Cell: data => (
            <span className="sales-engine-status">
              {data?.original?.isPaymentDue ? 'Boat back to market' : data.original?.salesEngineStatus}
            </span>
          ),
        },
        {
          Header: '',
          accessor: 'id',
          headerClassName: 'sales-engine-action sales--engine--view',
          className: 'sales-engine-action',
          Cell: ({ value, original }) => {
            const canArchiveOnly =
              original.isAuctionSalesEngine || original.surveyorPayment || original.boatPayment || original.shipperPayment

            return (
              <div className="d-flex flex-row justify-content-around">
                <ButtonComponent
                  type="button"
                  variant="fill"
                  onClick={() => props.getSingleSalesEngine({ id: value })}
                  className="sales-engine--view"
                >
                  View
                </ButtonComponent>

                <ButtonComponent
                  type="button"
                  variant="fill"
                  color="danger"
                  className="sales-engine--danger ml-2 font-16"
                  onClick={() => this.toggleDeleteModal(value)}
                >
                  {canArchiveOnly ? (
                    <span className="hoverable-img-wrp" title="Archive">
                      <img
                        loading="lazy"
                        src={require('../../../assets/images/archive-icon.png')}
                        className="img-on-nohover"
                        alt="Archive"
                      />
                      <img
                        loading="lazy"
                        src={require('../../../assets/images/archive-icon.gif')}
                        className="img-on-hover"
                        alt="Archive"
                      />
                    </span>
                  ) : (
                    <span className="hoverable-img-wrp" title="Delete">
                      <img
                        loading="lazy"
                        src={require('../../../assets/images/delete-icon.png')}
                        className="img-on-nohover"
                        alt="Delete"
                      />
                      <img
                        loading="lazy"
                        src={require('../../../assets/images/delete-icon.gif')}
                        className="img-on-hover"
                        alt="Delete"
                      />
                    </span>
                  )}
                </ButtonComponent>

                <CommonModal
                  className="shipment--own--pick--main--modal"
                  open={this.state.isDeleteModalOpen === value}
                  close={() => this.toggleDeleteModal(value)}
                  hideCloseIcon={true}
                  title={canArchiveOnly ? 'Are you sure you want to archive?' : 'Are you sure you want to delete?'}
                >
                  <div className="shipment--own--pick--button">
                    <ButtonComponent
                      className=" h-auto as--success--outline btn--padding btn--font mr-3"
                      onClick={async () => {
                        try {
                          const res = await (canArchiveOnly ? this.archive(original.id) : this.delete(original.id))

                          if (canArchiveOnly ? res.data?.archive : res.data?.deleteSalesEngine) {
                            toast.success(canArchiveOnly ? 'Added to archive' : 'Deleted successfully', { autoClose: 5000 })

                            this.props.getSalesEngineByBuyer({
                              isBuyItNow: this.props.isBuyItNow,
                              page: getPagesValue(),
                              limit: pagination.PAGE_RECORD_LIMIT,
                            })
                          } else {
                            toast.error(canArchiveOnly ? 'Cannot add to archive' : 'Cannot delete', { autoClose: 5000 })
                          }
                        } catch (err) {
                          console.log(err)
                          toast.error(canArchiveOnly ? 'Cannot add to archive' : 'Cannot delete', { autoClose: 5000 })
                        }
                      }}
                    >
                      Yes
                    </ButtonComponent>

                    <ButtonComponent
                      className=" h-auto as--cancel--outline btn--padding btn--font"
                      onClick={() => this.toggleDeleteModal(value)}
                    >
                      No
                    </ButtonComponent>
                  </div>
                </CommonModal>
              </div>
            )
          },
        },
      ],
      salesEngineIds: [],
    }
  }

  handleCheck(e, salesEngineId) {
    this.setState(state => ({
      salesEngineIds: state.salesEngineIds.includes(salesEngineId)
        ? state.salesEngineIds.filter(id => id !== salesEngineId)
        : [...state.salesEngineIds, salesEngineId],
    }))
  }

  allSelected = () => {
    this.setState(state => ({
      isAllSelected: !state.isAllSelected,
    }))
  }

  componentDidMount() {
    const { getSalesEngineByBuyer, isBuyItNow, clearSearchDashboard, getStaticPages } = this.props
    clearSearchDashboard()

    getSalesEngineByBuyer({
      isBuyItNow,
      page: getPagesValue(),
      limit: pagination.PAGE_RECORD_LIMIT,
    })

    getStaticPages({ slug: 'do-you-need-help-store-952' })
  }

  componentDidUpdate() {
    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }
  }

  render() {
    const { columns } = this.state
    const {
      salesEngines,
      isLoading,
      salesEnginesTotal,
      getSalesEngineByBuyer,
      searchInDashboard,
      clearSearchDashboard,
      indexSearchData,
      totalSearchedData,
      isBuyItNow,
      isSearched,
    } = this.props

    return (
      <>
        {columns && (
          <div className="manage-dashboard-table manage--salesengine-table dashboard--table--main--section dashboard--index--table adam-dash-store">
            <TableCard
              isSearch
              title="My Store"
              columns={columns}
              loading={isLoading}
              isSalesEngine
              showArchiveSalesEngine
              data={isSearched ? indexSearchData : salesEngines}
              className="manage--boat--table"
              noButton
              total={isSearched ? totalSearchedData : salesEnginesTotal}
              action={getSalesEngineByBuyer}
              searchData={searchInDashboard}
              searchType={'BUYERSALESENGINE'}
              searchResultKey={'buyerSalesEngine'}
              clearSearchDashboard={clearSearchDashboard}
              extraValues={{
                isBuyItNow,
              }}
              dashboardSearchInput={{ isUserDashboard: true }}
              helpModalContent={get(this.props.staticPages, 'data[0].pageContent', '')}
            />
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  salesEngines: state.salesEngineReducer.buyerSalesEngines,
  isLoading: state.salesEngineReducer.isLoading,
  salesEnginesTotal: state.salesEngineReducer.salesEnginesTotal,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
  isSearched: state?.dashboardReducer?.isSearched,
  staticPages: state.pageInfoByTypeReducer.staticPages,
})

const mapDispatchToProps = dispatch => ({
  getSalesEngineByBuyer: data => dispatch(getSalesEngineByBuyer(data)),
  getSingleSalesEngine: data => dispatch(getSingleSalesEngine(data)),
  searchInDashboard: data => dispatch(searchInDashboard(data)),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BuyerSalesEngine)
