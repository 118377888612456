import { GET_PAYMENT_METHODS, CREATE_PAYMENT_METHOD, DELETE_PAYMENT_METHOD, CREATE_PAYMENT } from '../actionTypes'

export const getPaymentMethods = () => ({
  type: GET_PAYMENT_METHODS,
})

export const createPaymentMethod = data => ({
  type: CREATE_PAYMENT_METHOD,
  payload: data,
})

export const deletePaymentMethod = data => ({
  type: DELETE_PAYMENT_METHOD,
  payload: data,
})

export const createPayment = data => ({
  type: CREATE_PAYMENT,
  payload: data,
})
