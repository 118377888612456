import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { DashboardLayout, TableCard } from '../../components'
import { ButtonComponent } from '../../components/form/Button'
import { CommonModal } from '../../components/modal/commonModal'
import InnerRatingModal from '../../components/reviewRating/InnerRatingModal'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
import {
  cancelRentBooking,
  clearSearchDashboard,
  clear_cancelRentBooking_flag,
  clear_getRentBookingsByCustomer_flag,
  getRentBookingsByCustomer,
} from '../../redux/actions'
import { clearRentPagesFlag, getRentPages } from '../../redux/actions/pageInfoByTypeAction'
import {
  footerLinks,
  pagination,
  RentBookingStatus,
  RentBookingStatusBadgeClassname,
  RentTripStatusLabels,
} from '../../util/enums/enums'
import { getRentStatusMod } from '../../util/utilFunctions'

const MyBookings = ({
  history,
  location,
  currentUser,
  getBookings,
  bookings,
  bookingsTotal,
  bookingsLoading,
  bookingsSuccess,
  bookingsFailure,
  clear_bookings_flag,
  getRentPages,
  clearRentPagesFlag,
  rentPages,
  rentPagesSuccess,
  rentPagesFailure,
  cancelBooking,
  cancelBookingLoading,
  cancelBookingSuccess,
  cancelBookingFailure,
  clear_cancelBooking_flag,
  isReview,
}) => {
  const [tableData, setTableData] = useState([])
  const [content, setContent] = useState({})
  const [popupData, setPopupData] = useState('')
  const [activeId, setActiveId] = useState('')
  const [config, setConfig] = useState({ page: pagination.PAGE_COUNT, limit: pagination.PAGE_RECORD_LIMIT, reqId: new Date().valueOf() })

  useEffect(() => {
    getRentPages()
  }, [])

  useEffect(() => {
    if (currentUser?.id && !bookingsLoading) {
      getBookings({ ...config, customer: currentUser.id })
    }
  }, [currentUser?.id, config])

  useEffect(() => {
    if (bookingsSuccess) {
      const _bookings = (bookings || [])
        .map(({ __typename, slot, ...booking }) => ({
          ...booking,
          id: booking.id,
          slot: booking.boat.tripDetails?.find(s => s.id === slot),
        }))
        .filter(b => b.slot)
        .map(b => ({
          ...b,
          slot: {
            ...b.slot,
            startDate: moment(b.slot.startDate).toISOString(),
            startTime: moment(b.slot.startTime).toISOString(),
            endDate: moment(b.slot.endDate).toISOString(),
            endTime: moment(b.slot.endTime).toISOString(),
          },
        }))
        .sort((a, b) => moment(b.bookedAt).local().valueOf() - moment(a.bookedAt).local().valueOf())

      setTableData(_bookings || [])
    }

    if (bookingsFailure) {
      toast.error('Cannot get your bookings', { autoClose: 5000 })
    }

    if (bookingsSuccess || bookingsFailure) clear_bookings_flag()
  }, [bookingsSuccess, bookingsFailure])

  useEffect(() => {
    if (rentPagesSuccess && rentPages) {
      const pages = {}
      rentPages.forEach(p => (pages[p.titleSlug] = p))
      setContent(pages)
    }

    if (rentPagesSuccess || rentPagesFailure) clearRentPagesFlag()
  }, [rentPagesSuccess])

  useEffect(() => {
    if (cancelBookingSuccess && currentUser?.id) {
      setConfig(config => ({ ...config, reqId: new Date().valueOf() }));
    }

    if (cancelBookingSuccess || cancelBookingFailure) clear_cancelBooking_flag()
  }, [currentUser, cancelBookingSuccess, cancelBookingFailure])

  useEffect(() => {
    if (isReview && currentUser?.id && !bookingsLoading) {
      setConfig(config => ({ ...config, reqId: new Date().valueOf() }));
    }
  }, [isReview])

  const getCols = () => [
    {
      Header: 'Ad Id',
      minWidth: 'auto',
      accessor: 'boat.adId',
      Cell: ({ value, original }) => (
        <a
          href="javascript:void(0)"
          className="Verified"
          style={{ backgroundColor: '#444' }}
          onClick={() => history.push(`/rent-inner/${original.boat.id}/${original.boat.model}`)}
        >
          {value}
        </a>
      ),
    },
    {
      Header: 'Trip',
      minWidth: 'auto',
      accessor: 'boat.trip.alias',
    },
    {
      Header: 'Trip Type',
      minWidth: 'auto',
      accessor: 'boat.tripType.alias',
    },
    {
      Header: 'Start Date',
      minWidth: 'auto',
      accessor: 'slot.startDate',
      Cell: ({ value }) => moment(value).utc().format('MMM DD, YYYY'),
    },
    {
      Header: 'Start Time',
      minWidth: 'auto',
      accessor: 'slot.startTime',
      Cell: ({ value }) => moment(value).utc().format('hh:mm A'),
    },
    {
      Header: 'Renter',
      minWidth: 'auto',
      Cell: data => (
        <a
          href="javascript:void(0)"
          className="Verified"
          style={{ backgroundColor: '#007bff' }}
          onClick={() => redirectToUserProfile(data.original.boat.user, history)}
        >
          {data.original.boat.user.firstName || ''} {data.original.boat.user.lastName || ''}
        </a>
      ),
    },
    {
      Header: 'Units',
      minWidth: 'auto',
      accessor: 'units',
    },
    {
      Header: 'Status',
      minWidth: 'auto',
      accessor: 'status',
      Cell: ({ value, original: { isQRScanned, isCancelled } }) => {
        const status = getRentStatusMod(value, isQRScanned, isCancelled)
        return <span className={RentBookingStatusBadgeClassname[status]}>{RentTripStatusLabels[status]}</span>
      },
    },
    {
      headerClassName: 'reset-rt-th',
      minWidth: 'auto',
      maxWidth: '200',
      Cell: ({ original: { id, slot, boat, status, isCancelled, reviews } }) => {
        const t = moment().utc()
        const s = moment(slot.startTime).utc()

        const canView = status !== RentBookingStatus.Settled
        const canCancel =
          t.isBefore(s.subtract(3, 'days')) &&
          ![RentBookingStatus.Settled, RentBookingStatus.Expired].includes(status) &&
          !isCancelled
        const canRate = status === RentBookingStatus.Settled

        reviews = reviews?.reviews?.find(r => String(r?.subModuleId) === String(id))

        return (
          <>
            {canView && (
              <ButtonComponent
                className="btn mr-2"
                color="view"
                onClick={() => history.push(`/view-rent-ticket/${id}`)}
                disabled={cancelBookingLoading || bookingsLoading}
              >
                Ticket
              </ButtonComponent>
            )}
            {canCancel && (
              <ButtonComponent
                className="btn mr-2"
                color="danger"
                onClick={() => {
                  setActiveId(id)
                  setPopupData(content && content[footerLinks.rent.bookingCancellation]?.pageContent)
                }}
                disabled={cancelBookingLoading || bookingsLoading}
              >
                Cancel
              </ButtonComponent>
            )}
            {canRate && (
              <InnerRatingModal
                module={boat.user}
                userModule={boat}
                subModuleId={id}
                moduleType="BOATRENT"
                initVals={reviews}
                AddReviewButton={({ onClick }) => (
                  <ButtonComponent
                    className="success"
                    color="orange"
                    onClick={onClick}
                    disabled={cancelBookingLoading || bookingsLoading}
                  >
                    {reviews ? 'Edit' : 'Add'} Review
                  </ButtonComponent>
                )}
              />
            )}
          </>
        )
      },
    },
  ]

  return (
    <>
      <DashboardLayout>
        <div className="p-4 bookings-table">
          <TableCard
            isSearch
            title="My Bookings"
            columns={getCols()}
            data={tableData}
            action={setConfig}
            total={bookingsTotal}
            loading={bookingsLoading}
            noButton
            clearSearchDashboard={clearSearchDashboard}
          />
        </div>
      </DashboardLayout>

      <CommonModal open={!!popupData} close={() => setPopupData('')} title="Booking Cancellation">
        <div dangerouslySetInnerHTML={{ __html: popupData }} />

        <div className="mt-4 d-flex justify-content-center">
          <ButtonComponent
            className="mr-2"
            color="view"
            onClick={() => {
              cancelBooking(activeId)
              setPopupData('')
            }}
          >
            Yes
          </ButtonComponent>
          <ButtonComponent color="danger" onClick={() => setPopupData('')}>
            No
          </ButtonComponent>
        </div>
      </CommonModal>
    </>
  )
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer?.currentUser,
  bookings: state.rentReducer.rentBookingsByCustomer,
  bookingsTotal: state.rentReducer.rentBookingsByCustomerTotal,
  bookingsLoading: state.rentReducer.rentBookingsByCustomerLoading,
  bookingsSuccess: state.rentReducer.rentBookingsByCustomerSuccess,
  bookingsFailure: state.rentReducer.rentBookingsByCustomerFailure,
  rentPages: state.pageInfoByTypeReducer?.rentPages,
  rentPagesSuccess: state.pageInfoByTypeReducer?.rentPagesSuccess,
  rentPagesFailure: state.pageInfoByTypeReducer?.rentPagesFailure,
  cancelBookingLoading: state.boatRentReducer.cancelRentBookingLoading,
  cancelBookingSuccess: state.boatRentReducer.cancelRentBookingSuccess,
  cancelBookingFailure: state.boatRentReducer.cancelRentBookingFailure,
  isReview: state.reviewReducer && state.reviewReducer.isReview,
})

const mapDispatchToProps = dispatch => ({
  getBookings: data => dispatch(getRentBookingsByCustomer(data)),
  clear_bookings_flag: () => dispatch(clear_getRentBookingsByCustomer_flag()),
  getRentPages: () => dispatch(getRentPages()),
  clearRentPagesFlag: () => dispatch(clearRentPagesFlag()),
  cancelBooking: id => dispatch(cancelRentBooking(id)),
  clear_cancelBooking_flag: () => dispatch(clear_cancelRentBooking_flag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MyBookings)
