import React, { createContext, useEffect, useState } from 'react';
import {  useSelector } from 'react-redux';
import { getChatsME } from '../redux/selector/chat';

export const ChatContext = createContext({});

console.log('version 0.0.09')
export const ChatContextProvider = ({ children }) => {
    const currentUser = useSelector(getChatsME)
    return (
        <ChatContext.Provider
            value={{
                currentUser
            }}
        >
            {children}
        </ChatContext.Provider>
    );
}