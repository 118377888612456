import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import cn from 'classnames'
import { enqueueSnackbar } from 'notistack'
import * as Icons from '../../assets/icons'
import { Routes } from '../../lib/consts'
import { getCommentReplies as qGetCommentReplies, likePostComment as qLikePostComment } from '../../lib/queries'
import { useSMDb } from '../../contexts/smdb-ctx'
import { useAuth } from '../../contexts/auth-ctx'
import LoginModal from '../overlays/login-modal'
import styles from './comment.module.scss'
import { formatNumber } from '../../lib/utils'

export default function Comment({ post, comment, handleReply, disabled }) {
  const { get: smdbGet, set: smdbSet, merge: smdbMerge } = useSMDb()
  const auth = useAuth()

  const [isLikeLoading, setIsLikeLoading] = useState(false)
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)

  const likePostComment = useCallback(async () => {
    setIsLikeLoading(true)

    try {
      const res = await qLikePostComment(comment._id)
      smdbMerge(comment._id, res)
    } catch (er) {
      enqueueSnackbar(er)
    }

    setIsLikeLoading(false)
  }, [comment, smdbMerge])

  const getCommentReplies = useCallback(async () => {
    try {
      const res = await qGetCommentReplies(comment._id, { pagination: {} })
      smdbSet(comment._id, 'replies', res)
    } catch (er) {
      enqueueSnackbar(er)
    }
  }, [comment, smdbSet])

  return (
    <>
      <div className={styles.comment}>
        <Link to={Routes.User(comment.user._id)} className={styles.commentAuthorAvatar}>
          <img src={comment.user.image?.largeUrl || require('../../assets/avatar-sample.png')} alt={comment.user.firstName} />
        </Link>

        <div className={styles.commentDetsWrp}>
          <div className={styles.commentAuthorNameWrp}>
            <Link to={Routes.User(comment.user._id)} className={styles.commentAuthorName}>
              {comment.user.firstName}
            </Link>
            <span className={styles.bullet} />
            <span className={styles.commentTime}>{moment(comment.createdAt).fromNow()}</span>
          </div>
          <div className={styles.commentContent}>
            {comment.parentComment && (
              <>
                <Link to={Routes.User(post.user._id)}>
                  <b>@{comment.parentComment?.user.firstName || post.user.firstName}</b>
                </Link>{' '}
                &nbsp;
              </>
            )}
            {comment.content}
          </div>
          <div className={styles.commentActionsWrp}>
            <button
              className={cn(styles.commentActionBtn, smdbGet(comment._id, 'liked', comment.liked) && styles.commentLikeActive)}
              onClick={() => (auth.isLoggedIn ? likePostComment() : setIsLoginModalOpen(true))}
              disabled={disabled || isLikeLoading}
            >
              {smdbGet(comment._id, 'liked', comment.liked) ? <Icons.HeartSolid /> : <Icons.Heart />}{' '}
              {formatNumber(smdbGet(comment._id, 'likesCount', comment.likesCount))}
            </button>

            {handleReply && (
              <button
                className={styles.commentActionBtn}
                onClick={() => (auth.isLoggedIn ? handleReply(comment) : setIsLoginModalOpen(true))}
              >
                <Icons.Message /> Reply
              </button>
            )}
          </div>

          {!!smdbGet(comment._id, 'repliesCount', comment.repliesCount) &&
            (!smdbGet(comment._id, 'replies')?.length ? (
              <button className={styles.commentRepliesBtn} onClick={getCommentReplies}>
                View {comment.repliesCount} {comment.repliesCount > 1 ? 'replies' : 'reply'}
              </button>
            ) : (
              !comment.parentComment &&
              smdbGet(comment._id, 'replies')?.map(reply => <Comment post={post} comment={reply} handleReply={handleReply} />)
            ))}
        </div>
      </div>

      {comment.parentComment &&
        smdbGet(comment._id, 'replies')?.map(reply => <Comment post={post} comment={reply} handleReply={handleReply} />)}

      {isLoginModalOpen && <LoginModal close={() => setIsLoginModalOpen(false)} />}
    </>
  )
}
