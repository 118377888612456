import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { ErrorMessage, Formik, Form } from 'formik'
import { Grid, Box, Container, CardContent, TextField, Button } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import { FaEyeSlash } from 'react-icons/fa'
import { FaEye } from 'react-icons/fa'

import './dealers.scss'
import { Row, Col, Card } from 'react-bootstrap'
import { DashboardLayout } from '../../components/layout/dashboardLayout'
import ErrorFocus from '../../components/ws/ErrorFocus'
import { popUpMessage } from '../../helpers/confirmationPopup'
import { Field } from '../../components'
import { renderErrorMessage } from '../../helpers/jsxHelper'
import PasswordValidation from '../../components/passwordValidations/passwordValidation'
import { passwordStrengthEnum } from '../../util/enums/enums'
import { ButtonComponent } from '../../components/form/Button'
import SearchComplete from '../../components/map/SearchComplete'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

class AddDealer extends Component {
  state = {
    infoMobile: false,
    isDisplayHint: false,
    errorMessages: {},
    passwordStrength: '',
    type: 'password',
    wording: <AiOutlineEye />,
    dealer: {
      country: '',
      companyName: '',
      firstName: '',
      lastName: '',
      contactNumber: '',
      email: '',
      position: '',
      password: '',
      confirmPassword: '',
      companyLogo: '',
    },
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return null
  }

  changeState = () => {
    const { type } = this.state

    const newType = type === 'password' ? 'text' : 'password'
    const newWord = type === 'password' ? <AiOutlineEyeInvisible /> : <AiOutlineEye />

    this.setState({ type: newType, wording: newWord })
  }

  onChangeText = (data, setFieldValue) => {
    setFieldValue('country', data)
  }

  validate = (e, setValue, email) => {
    const { errorMessages } = this.state
    let password = e.target.value
    let capsCount, smallCount, symbolCount, numberCount, userEmail, uniqPassword
    if (password.length < 8) {
      errorMessages.passwordLength = true
    } else {
      errorMessages.passwordLength = false
    }
    userEmail = email.toLowerCase()
    uniqPassword = password.toLowerCase()
    capsCount = (password.match(/[A-Z]/g) || []).length
    smallCount = (password.match(/[a-z]/g) || []).length
    symbolCount = (password.match(/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/) || []).length
    numberCount = (password.match(/[0-9]/g) || []).length

    if (capsCount < 1) {
      errorMessages.capsCount = true
    } else {
      errorMessages.capsCount = false
    }
    if (smallCount < 1) {
      errorMessages.smallCount = true
    } else {
      errorMessages.smallCount = false
    }
    if ((symbolCount && numberCount) < 1) {
      errorMessages.symbolOrNumberCount = true
    } else {
      errorMessages.symbolOrNumberCount = false
    }

    this.setState({
      errorMessages,
    })
    this.measureStrength(password, setValue)
  }

  measureStrength = (password, setValue) => {
    let score = 0
    let passwordStrength
    let regexPositive = ['[A-Z]', '[a-z]', '[0-9]', '\\W']
    regexPositive.forEach((regex, index) => {
      if (new RegExp(regex).test(password)) {
        score += 1
      }
    })
    switch (score) {
      case 1:
        passwordStrength = passwordStrengthEnum.POOR
        break

      case 2:
        passwordStrength = passwordStrengthEnum.POOR
        break

      case 3:
        passwordStrength = passwordStrengthEnum.AVERAGE
        setValue('password', password)
        break

      case 4:
        passwordStrength = passwordStrengthEnum.STRONG
        setValue('password', password)
        break

      default:
        passwordStrength = passwordStrengthEnum.WEAK
    }
    this.setState({
      passwordStrength,
      isDisplayHint: true,
    })
  }

  render() {
    const { isDisplayHint, errorMessages, passwordStrength, type, wording } = this.state
    return (
      <DashboardLayout className="add-branch-dashboard-layout">
        {/* {isUpdate && branch && !branch.id ? <Loader isPageLoader/> : */}
        <div className="pl-3 pr-3">
          <Formik
            initialValues={{ deleteIds: [] }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required(),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm new password is required'),
            })}
            onSubmit={values => {}}
            render={({ errors, setFieldValue, values, handleSubmit }) => (
              <Form>
                <div className="pl-2 pt-3 pb-3 map-div map-title-bg">
                  <Box fontSize={20} letterSpacing={1} fontWeight={600} className="map-title">
                    {`Add Dealer`}
                  </Box>
                </div>
                <Row>
                  <Col xs={3} className="">
                    <Card className="boatShow-body h-100 m-0 border-top-right-radius-0 border-top-left-radius-0 border-bottom-right-radius-2 border-bottom-left-radius-2">
                      <Card.Title>Dealer Information</Card.Title>
                      <Row className="boatShow-container addBoatShow-fields pt-3 pb-3 mt-auto mb-auto">
                        <Col>
                          <div className="boatShow-field mt-0">
                            <Field
                              label="Company Name "
                              id={'companyName'}
                              name={'companyName'}
                              type="text"
                              value={values.companyName}
                              onChangeText={e => {
                                e.target.value?.length <= 15 && setFieldValue('companyName', e.target.value)
                              }}
                              required
                            />
                            <ErrorMessage component="div" name="companyName" className="error-message" />
                          </div>
                          <div className="boatShow-field">
                            <label className="required" htmlFor="country">
                              Assign Broker To Country
                            </label>
                            <SearchComplete
                              placeHolder={'search country'}
                              isRegion
                              className="form-control font-14"
                              name={'country'}
                              getPlaceName={data => this.onChangeText(data, setFieldValue)}
                            />
                            <ErrorMessage component="div" name="country" className="error-message" />
                          </div>
                        </Col>
                      </Row>
                      <Row></Row>
                    </Card>
                  </Col>
                  <Col xs={9} className="pl-0">
                    <Card className="boatShow-body h-100 m-0 border-top-right-radius-0 border-top-left-radius-0 border-bottom-right-radius-2 border-bottom-left-radius-2">
                      <Card.Title>Contact Information</Card.Title>

                      <Row className="boatShow-container addBoatShow-fields pt-3 pb-3 mt-auto mb-auto">
                        <Col>
                          <div className="boatShow-field mt-0">
                            <Field
                              label="First Name"
                              id={'firstName'}
                              name={'firstName'}
                              type="text"
                              value={values.firstName}
                              onChangeText={e => {
                                setFieldValue('firstName', e.target.value)
                              }}
                              required
                            />
                            <ErrorMessage component="div" name="firstName" className="error-message" />
                          </div>
                          <div className="boatShow-field">
                            <Field
                              label="Last Name"
                              id={'lastName'}
                              name={'lastName'}
                              type="text"
                              value={values.lastName}
                              onChangeText={e => {
                                setFieldValue('lastName', e.target.value)
                              }}
                              required
                            />
                            <ErrorMessage component="div" name="lastName" className="error-message" />
                          </div>
                          <div className="boatShow-field">
                            <Field
                              label="Email Address"
                              id={'email'}
                              name={'email'}
                              type="text"
                              value={values.email}
                              onChangeText={e => {
                                setFieldValue('email', e.target.value)
                              }}
                              required
                            />
                            <ErrorMessage component="div" name="email" className="error-message" />
                          </div>
                        </Col>
                        <Col>
                          <div className="boatShow-field">
                            <Field
                              label="Contact Number"
                              id={'contactNumber'}
                              name={'contactNumber'}
                              type="text"
                              value={values.contactNumber}
                              onChangeText={e => {
                                setFieldValue('contactNumber', e.target.value)
                              }}
                              required
                            />
                            <ErrorMessage component="div" name="contactNumber" className="error-message" />
                          </div>
                          <div className="boatShow-field">
                            <Field
                              label="Position"
                              id={'position'}
                              name={'position'}
                              type="text"
                              value={values.position}
                              onChangeText={e => {
                                setFieldValue('position', e.target.value)
                              }}
                              required
                            />
                            <ErrorMessage component="div" name="position" className="error-message" />
                          </div>
                        </Col>
                      </Row>
                      <Row></Row>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={6} className="">
                    <Card className="boatShow-body h-100 m-0 border-top-right-radius-0 border-top-left-radius-0 border-bottom-right-radius-2 border-bottom-left-radius-2">
                      <Card.Title>Dealer Information</Card.Title>

                      <Row className="boatShow-container addBoatShow-fields pt-3 pb-3 mt-auto mb-auto">
                        <Col>
                          <div className="d-flex flex-wrap justify-content-center width-100 m-auto h-100">
                            <div className="text-center m-auto">
                              <label className="required mb-3">Dealer's Company Logo</label>
                              <Field
                                id="companyLogo"
                                name="companyLogo"
                                type="single-image"
                                deleteIds={values.deleteIds}
                                value={values.companyLogo}
                                onChangeText={setFieldValue}
                              />
                              <ErrorMessage component="div" name="companyLogo" className="error-message" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={6} className="">
                    <Card className="boatShow-body h-100 m-0 border-top-right-radius-0 border-top-left-radius-0 border-bottom-right-radius-2 border-bottom-left-radius-2">
                      <Card.Title>Password Information</Card.Title>

                      <Row className="boatShow-container addBoatShow-fields pt-3 pb-3 mt-auto mb-auto">
                        <Col>
                          <div className="boatShow-field">
                            <label className={'required'} htmlFor={'password'}>
                              Password
                            </label>
                            <input
                              id="password"
                              name="password"
                              type={type}
                              className={'form-control font-16'}
                              placeholder="Password"
                              onChange={e => {
                                setFieldValue('password', e.target.value)
                                this.validate(e, setFieldValue, values.email || '')
                              }}
                              required
                            ></input>
                            <span className="form-side-icon password-show-icon reg-side-icon" onClick={this.changeState}>
                              {wording}
                            </span>
                            {isDisplayHint && (
                              <PasswordValidation errorMessages={errorMessages} passwordStrength={passwordStrength} />
                            )}
                            <ErrorMessage component="div" name="password" className="error-message" />
                          </div>
                        </Col>
                        <Col>
                          <div className="boatShow-field">
                            <label className={'required'} htmlFor={'password'}>
                              Confirm new password
                            </label>
                            <input
                              id="confirmPassword"
                              name="confirmPassword"
                              type={type}
                              className={'form-control font-16'}
                              placeholder="Confirm Password"
                              onChange={e => {
                                setFieldValue('confirmPassword', e.target.value)
                              }}
                              required
                            ></input>
                            <ErrorMessage component="div" name="confirmPassword" className="error-message" />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <br />
                <div className="d-flex justify-content-center main--add--forms--btn">
                  <ButtonComponent
                    variant="outline"
                    color="success"
                    className=" h-auto btn--padding btn--font mr-3"
                    onClick={handleSubmit}
                  >
                    {'Save'}
                  </ButtonComponent>

                  <ButtonComponent
                    variant="outline"
                    color="cancel"
                    className=" h-auto btn--padding btn--font"
                    onClick={this.cancelHandler}
                  >
                    {' '}
                    {'Cancel'}
                  </ButtonComponent>
                </div>
              </Form>
            )}
          ></Formik>
        </div>
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AddDealer)
