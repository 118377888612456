import { useContext, useEffect } from 'react'
import { resetStore } from '../../helpers/graphqlClient'
import { getLocalStorageItem } from '../../helpers/storageHelper'

import UserContext from '../../UserContext'

const Logout = () => {
  const { logout, history } = useContext(UserContext)

  const isAuthenticated = getLocalStorageItem('isAuthenticated')

  const logoutHandler = () => {
    // [NOTE]: For chat issue
    resetStore()
    logout()
    return null
  }

  useEffect(() => {
    // [NOTE]: For chat issue
    resetStore()
    setTimeout(()=>{
      const urlParams = new URLSearchParams(window.location.search)
      window.location.href=urlParams.get('redirect_to') || '/'
    },100)
    // history && !isAuthenticated && history.push('/')
  }, [history, isAuthenticated])

  return getLocalStorageItem('isAuthenticated') && logoutHandler()
}

export default Logout
