import { 
  CREATE_CONTACT_US_COMPLAINT,
  CREATE_CONTACT_US_COMPLAINT_FAILURE,
  CREATE_CONTACT_US_COMPLAINT_SUCCESS,
  CREATE_CONTACT_US_SERVICE,
  CREATE_CONTACT_US_SERVICE_FAILURE,
  CREATE_CONTACT_US_SERVICE_SUCCESS,
  CREATE_CONTACT_US_SUGGESTION, 
  CREATE_CONTACT_US_SUGGESTION_FAILURE, 
  CREATE_CONTACT_US_SUGGESTION_SUCCESS, 
  GET_ALL_USER_FAQ, 
  GET_ALL_USER_FAQ_FAILURE, 
  GET_ALL_USER_FAQ_SUCCESS 
} from '../actionTypes'
import { 
  createComplaintSchema,
  createServiceSchema,
  createSuggestionSchema, 
  getAllUserFaq 
} from '../../graphql/userFaqSchema'
import { put, takeLatest, all } from 'redux-saga/effects'
import { graphqlClient } from '../../helpers/graphqlClient'

function getAllUserFaqApi(data) {
  return graphqlClient
    .query({
      query: getAllUserFaq,
      fetchPolicy: 'no-cache',
      variables: { page: data.page, limit: data.limit },
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(e => {
      throw e
    })
}

function* getAllUserFaqData(action) {
  try {
    const data = yield getAllUserFaqApi(action.payload)
    yield put({ type: GET_ALL_USER_FAQ_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_ALL_USER_FAQ_FAILURE, e })
  }
}

function* getAllUserFaqSaga() {
  yield takeLatest(GET_ALL_USER_FAQ, getAllUserFaqData)
}

function createSuggestionApi(input) {
  return graphqlClient
    .mutate({
      mutation: createSuggestionSchema,
      variables: { input },
    })
    .then(res => {
      return res
    })
    .catch(error => {
      return error.networkError.result
    })
}

function* createSuggestionData(action) {
  try {
    const data = yield createSuggestionApi(action.payload)
    yield put({ type: CREATE_CONTACT_US_SUGGESTION_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: CREATE_CONTACT_US_SUGGESTION_FAILURE, e })
  }
}

function* createSuggestionSaga() {
  yield takeLatest(CREATE_CONTACT_US_SUGGESTION, createSuggestionData)
}

function createServiceApi(input) {
  return graphqlClient
    .mutate({
      mutation: createServiceSchema,
      variables: { input },
    })
    .then(res => {
      return res
    })
    .catch(error => {
      return error.networkError.result
    })
}

function* createServiceData(action) {
  try {
    const data = yield createServiceApi(action.payload)
    yield put({ type: CREATE_CONTACT_US_SERVICE_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: CREATE_CONTACT_US_SERVICE_FAILURE, e })
  }
}

function* createServiceSaga() {
  yield takeLatest(CREATE_CONTACT_US_SERVICE, createServiceData)
}

function createComplaintApi(input) {
  return graphqlClient
    .mutate({
      mutation: createComplaintSchema,
      variables: { input },
    })
    .then(res => {
      return res
    })
    .catch(error => {
      return error.networkError.result
    })
}

function* createComplaintData(action) {
  try {
    const data = yield createComplaintApi(action.payload)
    yield put({ type: CREATE_CONTACT_US_COMPLAINT_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: CREATE_CONTACT_US_COMPLAINT_FAILURE, e })
  }
}

function* createComplaintSaga() {
  yield takeLatest(CREATE_CONTACT_US_COMPLAINT, createComplaintData)
}

export default function* userFaqSaga() {
  yield all([
    getAllUserFaqSaga(),
    createSuggestionSaga(),
    createServiceSaga(),
    createComplaintSaga()
  ])
}
