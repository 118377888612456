import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'

// import "./boatCard.scss";
import {
  dimension,
  defaultImage,
  wishlistModuleEnum,
  shareModule,
  boatVerifyMessage,
  boatShowStatus,
} from '../../util/enums/enums'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import { SectionWithShowMapStyleBoat, MapShowGridStyleGrid } from '../styleComponent/styleComponent'
import ImageGalleryComponent from '../imageHelper/imageGalleryComponent'
import { WishlistComponent } from '../wishlist/WishlistComponent'
import SharePopup from '../share/SharePopup'
import { cityCountryNameFormatter, redirectToUserProfile, findBoatShowDuration } from '../../helpers/jsxHelper'
import { ButtonComponent } from '../form/Button'
import { boatShowCardDateTimeFormat } from '../../helpers/dateTimeHelper'
import { textTruncate, textLength } from '../../helpers/string'
import './boatShowMapCard.scss'
import WishListButton from '../staticComponent/wishListButton'
import { WarnNotify } from '../../helpers/notification'
import UserContext from '../../UserContext'

export const BoatShowCard = props => {
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [isArrow, setIsArrow] = useState(true)
  const [expanded, setExpanded] = useState(true)

  const { boat, onClick, isWishlist, removeFromList, index, sliceTo } = props
  const { history } = useContext(UserContext)
  const {
    id,
    title,
    address: [{ city, country }],
    startDate,
    endDate,
    boatshowImages,
    showDescription,
    adStatus,
    user,
  } = boat
  const [height, setHeight] = useState(dimension.featureBoat.height)
  const [width, setWidth] = useState(dimension.featureBoat.width)
  const onGoingBoatShow = findBoatShowDuration(startDate, endDate) === boatShowStatus.ON_GOING
  const comingSoonBoatShow = findBoatShowDuration(startDate, endDate) === boatShowStatus.COMING_SOON
  const overBoatShow = findBoatShowDuration(startDate, endDate) === boatShowStatus.OVER

  const zoomWidth = () => {
    const className = isWishlist ? '.dashboard-layout-responsive' : '.grid--w-100--map'
    const widthR = getRatio(dimension, 'showMapRatio', className)
    const heightR = getHeightRatio(dimension, 'showMapRatio', className)
    widthR && setWidth(widthR)
    heightR && setHeight(heightR)
  }
  const toggleLines = () => {
    setExpanded(!expanded)
  }
  useEffect(() => {
    zoomWidth()
    window.addEventListener('resize', zoomWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = index => {
    setSelectedIndex(selectedIndex !== index ? index : null)
  }

  return (
    <div className="boat--show--card--gird" onMouseEnter={() => props.onBoatHover(boat)} onMouseLeave={props.onBoatHover}>
      <div
        className="boat--show--card--img"
        style={{ width: `${width}px` }}
        onMouseLeave={() => {
          setIsArrow(false)
        }}
        onMouseOver={() => {
          setIsArrow(true)
        }}
      >
        {isWishlist ? (
          <SectionWithShowMapStyleBoat
            width={width}
            height={height}
            img={(boatshowImages && boatshowImages[0]?.url) || defaultImage}
            onClick={() => !adStatus && WarnNotify(boatVerifyMessage)}
          />
        ) : (
          boatshowImages &&
          boatshowImages.length > 0 && (
            <ImageGalleryComponent width={width} height={height} isArrow={isArrow} images={boatshowImages} sliceTo={sliceTo} />
          )
        )}

        <div className="boat--show--map--card--action card-action">
          <div className="opacity--0"></div>
          <div className="share-icon">
            <WishlistComponent moduleId={id} moduleType={wishlistModuleEnum.BOAT_SHOW} />
            {/* <SharePopup
              handleClick={() => handleClick(index)}
              index={index}
              selectedIndex={selectedIndex}
              moduleId={id}
              moduleType={shareModule.BOAT_SHOW}
            /> */}
          </div>
        </div>
      </div>

      <MapShowGridStyleGrid width={width} className="boat--show--main-with-btn">
        <div className="boat--show--card--details--main">
          <div className="boat--show--title">{title}</div>

          <div className="boat--show--card--place--city">{cityCountryNameFormatter(city, country)}</div>

          <h6 className="boat--show--card--date">
            <span className="d-flex align-items-center">
              <i className="adam-calendar1 location-icon boat-show-icon"></i> {moment(startDate).format('DD-MM-YYYY')} -{' '}
              {moment(endDate).format('DD-MM-YYYY')}
            </span>
          </h6>
          <h6 className="boat--show--card--time">
            <span className="d-flex align-items-center">
              <i className="adam-calendar1 location-icon boat-show-icon"></i>{' '}
              {`${boatShowCardDateTimeFormat(startDate, true)} - ${boatShowCardDateTimeFormat(endDate, true)}`}
            </span>
          </h6>

          <h5 className="boat--show--card--desc">
            {expanded ? textTruncate(showDescription, 85) : showDescription}{' '}
            {textLength(showDescription, 85) && (
              <span class="a--tag-link" onClick={toggleLines}>
                {expanded ? 'Read More' : 'Read Less'}
              </span>
            )}
          </h5>
          <div className="boat--show--card--posted">
            <span className='header-user-role dark-silver header--user--role--div boat-show-card-wrapper' >
                <span className="boat--show--card--posted--by font-weight-400">
                  Posted By <span onClick={() => redirectToUserProfile(user, history)}>{user && user.firstName}</span>{' '}
                </span>
            </span>
          </div>
          <div className="boat--show--card--button">
            <ButtonComponent
              color={comingSoonBoatShow ? 'green' : onGoingBoatShow ? 'orange' : overBoatShow ? 'subscribe' : ''}
              className="boat--show--btn"
            >
              {comingSoonBoatShow
                ? boatShowStatus.COMING_SOON
                : overBoatShow
                ? boatShowStatus.OVER
                : onGoingBoatShow
                ? boatShowStatus.ON_GOING
                : '-'}
            </ButtonComponent>
          </div>
        </div>
        {isWishlist && (
          <div className="manage-boat-card-button">
            <WishListButton viewOnclick={onClick} removeOnclick={removeFromList} />
          </div>
        )}
      </MapShowGridStyleGrid>
    </div>
  )
}

BoatShowCard.defaultProps = {
  isWishlist: false,
  onBoatHover: () => null,
  onClick: () => null,
  sliceTo: 0,
}
