import React from 'react'
import cn from 'classnames'

/**
 * @param {React.SVGProps<SVGSVGElement>} props
 */
export default function IconEmoji(props) {
  return (
    <svg
      {...props}
      className={cn('as-media-icon', props.className)}
      width="24"
      height="24"
      viewBox="0 0 512 512"
      fill="currentColor"
    >
      <path d="M368,256H144c-8.837,0-16,7.163-16,16c0,70.692,57.308,128,128,128s128-57.308,128-128C384,263.163,376.837,256,368,256z      M256,368c-46.869,0.031-86.909-33.787-94.72-80h189.44C342.909,334.213,302.869,368.031,256,368z" />
      <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,480     C132.288,480,32,379.712,32,256S132.288,32,256,32s224,100.288,224,224S379.712,480,256,480z" />
      <circle cx="176" cy="176" r="32" />
      <circle cx="336" cy="176" r="32" />
    </svg>
  )
}
