import styled from 'styled-components'

const StyledBanner = styled.div`
    background-image: ${props => props.src ? `linear-gradient(rgba(0, 0, 6, 0.5), rgba(0, 0, 0, 0.5) ), url(${props => props.src})` : null};
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 311px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    & > div > p {
        font-size: 48px;
        font-weight: 500;
        color: #000
    };
    & > div > span {
        font-size: 18px;
        font-weight: 400;
        color: #000
    }
`
const ImageBanner = ({src, h1 = false, title, subTitle}) => {
    return(
        <StyledBanner src={src}>
            <div className="container100">
                {h1 ? <h1 className="r-h1">{title}</h1> : <h2>{title}</h2>}
                <span>{subTitle}</span>
            </div>
        </StyledBanner>
    )
}

export default ImageBanner
