import gql from 'graphql-tag'

export const getChatUserLists = gql`
query GetUsers($limit: Int, $getUsersId: String, $searchTerm: String) {
  getUsers(limit: $limit, id: $getUsersId, searchTerm: $searchTerm) {
    createdAt
    appUserId
    id
    isConfirmed
    isOwner
    last4Emoji
    metaData
    name
    notificationSetting
    profileImage
    updatedAt
  }
}
`
export const getChatLists = gql`
query ChatList($limit: Int, $searchTerm: String, $chatListId: String) {
  chatList(limit: $limit, searchTerm: $searchTerm, id: $chatListId) {
    channelUrl
    chatType
    description
    id
    metaData
    name
    onlineUsers
    unreadCounts
    imageUrl
    isBlocked
    lastActiveTimeList {
      lastActive
      userId
    }
    lastMsgId {
      id
      messageBody
      mediaUrl
      isOneTimeMessage
      isDelivered
      messageType
      metaData
      status
      updatedAt
      createdAt
      location {
        isLiveLocation
        coordinates
      }
    }
    createdBy {
      appUserId
      name
      profileImage
      metaData
      id
    }
    updatedAt
    users {
      id
      name
      metaData
      appUserId
      freezed
    }
  }
}
`
export const getMe = gql`
query Me {
  me {
    id
    name
    metaData
    profileImage
    appUserId
    last4Emoji
  }
}
`
export const createChatByID = gql`
mutation CreateChat($members: [String!]!, $channelUrl: String!, $chatType: ChatType, $name: String, $imageUrl: String, $description: String, $metaData: JsonScalar) {
  createChat(members: $members, channelUrl: $channelUrl, chatType: $chatType, name: $name, imageUrl: $imageUrl, description: $description, metaData: $metaData) {
    data
    message
    statusCode
  }
}
`
// mutation CreateChat($membersId: [String!], $name: String, $image: String, $chatChoice: ChatChoice) {
//   createChat(membersId: $membersId, name: $name,image:$image, chatChoice: $chatChoice) {
//     chat {
//       _id
//       chatChoice
//       createdBy {
//           _id
//           name
//       }
//       isGroupChat
//       lastMessage
//       members {
//         _id
//         appUserId
//         name
//         userMeta
//         image
//         isOnline
//         fcmToken
//         lastActiveTime
//         isUserBlocked
//       }
//       image
//       name
//       unreadCount
//   }
//   error {
//       message
//       path
//   }
//   }
// }

export const blockUserByID = gql`
mutation BlockUnblockUser($blockedUserId: String!, $action: BlockUnblockUserAction!) {
  blockUnblockUser(blockedUserId: $blockedUserId, action: $action)
}`

export const clearChatByID = gql`
mutation ClearChat($chatId: String!) {
  clearChat(chatId: $chatId)
}
`

export const deleteChatByID = gql`
mutation DeleteChats($chatIds: [String!]!) {
  deleteChats(chatIds: $chatIds)
}
`
export const deleteMessageChatByID = gql`mutation DeleteMessage($msgIds: [String!]!) {
  deleteMessage(msgIds: $msgIds) {
    ids
    error {
      message
    }
  }
}`

export const createMessageByChatID = gql`
mutation CreateMessage($chatId: String!, $messageBody: String, $coordinates: [Float!], $isLiveLocation: Boolean, $mediaUrl: String, $isOneTimeMessage: Boolean, $messageType: Float) {
  createMessage(chatId: $chatId, messageBody: $messageBody, coordinates: $coordinates, isLiveLocation: $isLiveLocation, mediaUrl: $mediaUrl, isOneTimeMessage: $isOneTimeMessage, messageType: $messageType) {
    id
    isOneTimeMessage
    isReadByAll
    mediaUrl
    messageBody
    messageType
    metaData
    reactions {
      reaction
      userIds
    }
    sender {
      appUserId
      id
      last4Emoji
      name
      profileImage
    }
    status
  }
}
`
export const getMessageByChatID = gql`
query MessageList($chatId: String!, $messageListId: String, $limit: Int, $searchTerm: String) {
  messageList(chatId: $chatId, id: $messageListId, limit: $limit, searchTerm: $searchTerm) {
    id
    isOneTimeMessage
    isReadByAll
    location {
      coordinates
    }
    mediaUrl
    messageBody
    isDelivered
    messageType
    metaData
    reactions {
      reaction
      userIds
    }
    status
    updatedAt
    createdAt
    sender {
      appUserId
      id
      last4Emoji
      name
      profileImage
    }
    parentMsgId {
      id
      createdAt
      isOneTimeMessage
      location {
        coordinates
      }
      mediaUrl
      messageBody
      messageType
      metaData
      sender {
        appUserId
        id
        createdAt
        last4Emoji
        name
        profileImage
        updatedAt
      }
      status
      updatedAt
    }
  }
}
`
export const readMessageByChatID = gql`
mutation Mutation($chatId: String!) {
  readMessages(chatId: $chatId)
}
`

export const addOrRemoveUsersFromChat = gql`
mutation AddOrRemoveUsersInChat($chatId: String!, $users: [String!]!, $action: ChatUserAction!) {
  addOrRemoveUsersInChat(chatId: $chatId, users: $users, action: $action)
}
`
export const getUserFriendListGraphQl = gql`
query getUserFriendList {
  getUserFriendList{
    _id
    id
    redisId
    image{
      largeUrl
      smallUrl
      url
      id
    }
    firstName
    middleName
    lastName
    role{
      aliasName
      role
      type
    }
    createdAt
  }
}
`

export const getAllOnlineUsers_query = gql`
  query getUsersDetails($userIds: [String!]!) {
    getUsersDetails(userIds: $userIds) {
      id
      appUserId
      isOnline
    }
  }
`

export const updateChat=gql`
mutation UpdateChat($chatId: String!, $name: String, $imageUrl: String, $description: String, $metaData: JsonScalar) {
  updateChat(chatId: $chatId, name: $name, imageUrl: $imageUrl, description: $description, metaData: $metaData) {
    data
    message
    statusCode
  }
}
`

export const getChatUsers= gql`
query ChatUsers($chatId: String!) {
  chatUsers(chatId: $chatId) {
    id
    appUserId
    name
    profileImage
    metaData
    isOwner
    freezed
  }
}
`