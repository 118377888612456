import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILURE,
  SHARE_DETAILS_WITH_USERS,
  SHARE_DETAILS_WITH_USERS_SUCCESS,
  SHARE_DETAILS_WITH_USERS_FAILURE,
  CLEAR_SHARE_FLAG,
} from '../actionTypes'

const InitialState = {
  shareUsers: [],

  getSuccess: false,
  getError: false,

  shareSuccess: false,
  shareError: false,

  isLoading: false,
}

export const shareReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_USER_LIST:
      return {
        ...state,
        shareUsers: [],
        isLoading: true,
        getSuccess: false,
        getError: false,
      }

    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        getSuccess: true,
        shareUsers: action.payload,
        isLoading: false,
      }

    case GET_USER_LIST_FAILURE:
      return {
        ...state,
        getError: true,
        isLoading: false,
      }

    case SHARE_DETAILS_WITH_USERS:
      return {
        ...state,
        shareSuccess: false,
        shareError: false,
      }
    case SHARE_DETAILS_WITH_USERS_SUCCESS:
      return {
        ...state,
        shareSuccess: true,
        shareError: false,
      }
    case SHARE_DETAILS_WITH_USERS_FAILURE:
      return {
        ...state,
        shareSuccess: false,
        shareError: true,
      }

    case CLEAR_SHARE_FLAG:
      return {
        shareSuccess: false,
        shareError: false,
        getSuccess: false,
        getError: false,
        isLoading: false,
      }
    default:
      return state
  }
}
