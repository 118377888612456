import React, { useEffect, useState } from 'react'
import Select, { components } from "react-select";
import { useDispatch, useSelector } from 'react-redux'

import { getChatUsers, getIsGroupChatCreateScreen, getSelectUser, getSelectedGroupIds } from '../../redux/selector/chat'
import { addOrRemoveUsersFromChat, clearUserSearch, getUserListsByCurrentUser, setSelectedGroupIds, setUserList } from '../../redux/actions/chatAction';

import { LeftUserList } from './leftUserList';
import { Loader } from '../loader/loader';
import { socket } from '../../socket';
import { socketEvent } from '../../util/enums/socketEnums';
import { ChatAction } from '../../util/enums/enums';

export const ChatMultiSelect = ({height}) => {
    const [searchPeopleOrGroup, setSearchPeopleOrGroup] = useState('')
    // const [isSearchStart, setIsSearchStart] = useState(false)
    const dispatch = useDispatch()
    const options = useSelector(getChatUsers)
    const selectUser = useSelector(getSelectUser)
    const selectedGroupIds = useSelector(getSelectedGroupIds)
    const isGroup = useSelector(getIsGroupChatCreateScreen)
    // const isLeftSideLoader = useSelector(getIsLeftSideLoader)
   
    useEffect(() => {
        if(searchPeopleOrGroup){
            dispatch(getUserListsByCurrentUser({  searchTerm: searchPeopleOrGroup.trimStart() }))
        }
    }, [searchPeopleOrGroup])

    const MultiValueRemove = (props) => {
        return (
            <components.MultiValueRemove {...props}>
                <div>x</div>
            </components.MultiValueRemove>
        );
    };

    const MultiValueLabel = (props) => {
        const { label } = props.data
        return (
            <components.MultiValueLabel {...props}>
                {label}
            </components.MultiValueLabel>
        );
    };
    // innerProps,innerRef,isDisabled,data
    
    const Option = (OptionData) => {
         if(OptionData.isDisabled) return null;

        return (
            <components.Option {...OptionData}>
                <LeftUserList {...OptionData.data}  />
            </components.Option>
        );
    };
    const MenuList = (OptionData) => {
        return (
            <div className='chat-multiselect--overflow' style={{ height: `calc(100vh - ${height + 135}px)` }}>
                <components.MenuList {...OptionData} >
                    {OptionData.children}
                </components.MenuList>
            </div>
        );
    };

    const handleChange = (selectedOption) => {  
        // console.log(selectedGroupIds,selectedOption);
        if(!selectedOption){
            dispatch(addOrRemoveUsersFromChat({chatId:selectUser.chatId,users:[selectedGroupIds[0]?.value],action:ChatAction.Remove}))
            
        }else{
            let removedArray=[]
            let AddedArray=[]
            selectedGroupIds.map((ids)=>{
                if(selectedOption?.findIndex((id)=>id.value==ids.value)==-1){
                    removedArray.push(ids.id);
                }
            })
            selectedOption.map((ids)=>{
                if(selectedGroupIds?.findIndex((id)=>id.value==ids.value)==-1){
                    AddedArray.push(ids.id);
                }
            })
            
            console.log(removedArray,AddedArray);
            if(removedArray.length>0 ||AddedArray.length>0 ){
                dispatch(addOrRemoveUsersFromChat({chatId:selectUser.chatId,users:removedArray.length>0?removedArray:AddedArray,action:removedArray.length>0?ChatAction.Remove:ChatAction.Add}))
            }
        }


        dispatch(setSelectedGroupIds(selectedOption ?? []))
        dispatch(clearUserSearch())
        setSearchPeopleOrGroup('')
    }
    
    const defaultValue = selectedGroupIds.map((i) => {
        return {
            label: i.label,
            value: i.id
        }
    })

    const handleInputValue = (str) => {
        setSearchPeopleOrGroup(str);
    };

    const onMenuClose = () => {
        setSearchPeopleOrGroup(searchPeopleOrGroup);
    };

    const newOptions= options.map((option)=>({...option,label:option.name,value:option.id}))

    return (
        <React.Fragment>
             {/* {isLeftSideLoader && <div className='left-side-loader'> <Loader isSmall /></div>} */}
            <Select
                components={{ MenuList ,Option, MultiValueLabel,MultiValueRemove,NoOptionsMessage: () => null, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                options={newOptions}
                isClearable={false}
                defaultValue={defaultValue}
                inputValue={searchPeopleOrGroup}
                isMulti
                placeholder={'Type user name'}
                onChange={handleChange}
                onInputChange={handleInputValue}
                onMenuClose={onMenuClose}
                menuIsOpen
                className="chat-multiselect-wrapper"
                classNamePrefix="chat-multiselect"
            />
        </React.Fragment>
    )
}
