import { Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Layout } from '../../components'
import './Sitemap.scss'
import { showAll, showAllMarina, showAllService } from '../../util/enums/enums'

export default function Sitemap() {
  return (
    <Layout className="sitemap-layout">
      <div className="container100">
        <h1>
          <Box fontSize={24} fontWeight={500} letterSpacing={0.5} textAlign={'center'}>
            Sitemap
          </Box>
        </h1>

        <div className="mt-4 sitemap-lists-cont">
          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">Explore AdamSea</h3>
            <ul typeof="circle">
              <li>
                <Link to="/rent">
                  <span className="font-16">Charter and Rent</span>
                </Link>
              </li>
              <li>
                <Link to="/boat-service">
                  <span className="font-16">Service and Maintenance</span>
                </Link>
              </li>
              <li>
                <Link to="/marina-storage">
                  <span className="font-16">Marina and Storage</span>
                </Link>
              </li>
              <li>
                <Link to="/boat-show">
                  <span className="font-16">Boat Show</span>
                </Link>
              </li>
              <li>
                <Link to="/boat-media">
                  <span className="font-16">Boat Media</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">About AdamSea</h3>
            <ul typeof="circle">
              <li>
                <Link to="/info/our-market">
                  <span className="font-16">Our Market</span>
                </Link>
              </li>
              <li>
                <Link to="/info/adamsea-community">
                  <span className="font-16">AdamSea Community</span>
                </Link>
              </li>
              <li>
                <Link to="/info/about-us">
                  <span className="font-16">About Us</span>
                </Link>
              </li>
              <li>
                <Link to="/info/integrity-and-compliance">
                  <span className="font-16">Integrity And Compliance</span>
                </Link>
              </li>
              <li>
                <Link to="/fraud-protection">
                  <span className="font-16">Fraud Protection</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">AdamSea - Contact Us</h3>
            <ul typeof="circle">
              <li>
                <Link to="/careers">
                  <span className="font-16">Careers</span>
                </Link>
              </li>
              <li>
                <Link to="/contact-us">
                  <span className="font-16">Contact Us</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">AdamSea - Registration</h3>
            <ul typeof="circle">
              <li>
                <Link to="/register/member">
                  <span className="font-16">Member</span>
                </Link>
              </li>
              <li>
                <Link to="/register/boat-owner">
                  <span className="font-16">Boat Owner</span>
                </Link>
              </li>
              <li>
                <Link to="/register/broker-and-dealer">
                  <span className="font-16">Broker and Dealer</span>
                </Link>
              </li>
              <li>
                <Link to="/register/boat-manufacturer">
                  <span className="font-16">Boat Manufacturer</span>
                </Link>
              </li>
              <li>
                <Link to="/register/service-and-maintenance">
                  <span className="font-16">Service and Maintenance</span>
                </Link>
              </li>
              <li>
                <Link to="/register/surveyor">
                  <span className="font-16">Boat Surveyor</span>
                </Link>
              </li>
              <li>
                <Link to="/register/marina-and-storage">
                  <span className="font-16">Marina and Storage</span>
                </Link>
              </li>
              <li>
                <Link to="/register/rent-and-charter">
                  <span className="font-16">Rent</span>
                </Link>
              </li>
              <li>
                <Link to="/register/yacht-shipper">
                  <span className="font-16">Shipper</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">AdamSea - Direct from the Manufacture</h3>
            <ul typeof="circle">
              <li>
                <Link to="/category-wise-boats/jet-ski-&-machines/5eecb9d22d6c67513845f7fe">
                  <span className="font-16">Jet Ski and Machines</span>
                </Link>
              </li>
              <li>
                <Link to="/category-wise-boats/canoe/5f7197fad94c856897bc11a1">
                  <span className="font-16">Canoe</span>
                </Link>
              </li>
              <li>
                <Link to="/category-wise-boats/kayak/5f7197ebd94c856897bc119a">
                  <span className="font-16">Kayak</span>
                </Link>
              </li>
              <li>
                <Link to="/category-wise-boats/cuddy-cabin/5eecb9b02d6c67513845f7f0">
                  <span className="font-16">Cuddy Cabin</span>
                </Link>
              </li>
              <li>
                <Link to="/category-wise-boats/bow-boat/5eecb9962d6c67513845f7e3">
                  <span className="font-16">Bow Boat</span>
                </Link>
              </li>
              <li>
                <Link to="/category-wise-boats/power-boat/5eecb97c2d6c67513845f7d1">
                  <span className="font-16">Power Boat</span>
                </Link>
              </li>
              <li>
                <Link to="/category-wise-boats/sailing-boat/5eecb9622d6c67513845f7c6">
                  <span className="font-16">Sailing Boat</span>
                </Link>
              </li>
              <li>
                <Link to="/category-wise-boats/motor-yacht/5eec562b2ae89e383f9a57ae">
                  <span className="font-16">Motor Yacht</span>
                </Link>
              </li>
              <li>
                <Link to="/category-wise-boats/super-yacht/5ede27b7744ef04518957b7f">
                  <span className="font-16">Super Yacht</span>
                </Link>
              </li>
              <li>
                <Link to="/category-wise-boats/fishing-boat/5ece4895c83bad561550abb4">
                  <span className="font-16">Fishing Boat</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">AdamSea - Help</h3>
            <ul typeof="circle">
              <li>
                <Link to="/user-guide">
                  <span className="font-16">User Guide</span>
                </Link>
              </li>
              <li>
                <a href="/contact-us-complaint">
                  <span className="font-16">Submit a complaint</span>
                </a>
              </li>
              <li>
                <a href="/contact-us-suggestions">
                  <span className="font-16">Suggestions</span>
                </a>
              </li>
              <li>
                <a href="/contact-us-services">
                  <span className="font-16">Customer Service</span>
                </a>
              </li>
              <li>
                <Link to="/faq">
                  <span className="font-16">FAQ</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">AdamSea - Partnership</h3>
            <ul typeof="circle">
              <li>
                <Link to="/info/be-a-partner">
                  <span className="font-16">Be a Partner</span>
                </Link>
              </li>
              <li>
                <Link to="/info/shipper">
                  <span className="font-16">Shipper</span>
                </Link>
              </li>
              <li>
                <Link to="/info/manufacturer">
                  <span className="font-16">Manufacturer</span>
                </Link>
              </li>
              <li>
                <Link to="/info/surveyor">
                  <span className="font-16">Surveyor</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">AdamSea - For Sale</h3>
            <ul typeof="circle">
              <li>
                <Link to="/show-all/featured-boats">
                  <span className="font-16">Featured Boats</span>
                </Link>
              </li>
              <li>
                <Link to="/boats-for-sell-around-the-world">
                  <span className="font-16">Boat for sale around the world</span>
                </Link>
              </li>
              <li>
                <Link to="/show-all/best-deal-boats">
                  <span className="font-16">Must Buy</span>
                </Link>
              </li>
              <li>
                <Link to="/show-all-auction-rooms">
                  <span className="font-16">Auction Room</span>
                </Link>
              </li>
              <li>
                <Link to="/show-all-footer-link/most-viewed-boats">
                  <span className="font-16">Most Viewed</span>
                </Link>
              </li>
              <li>
                <Link to="/show-all-boats/recently-added-boats">
                  <span className="font-16">Recently Added Boats</span>
                </Link>
              </li>
              <li>
                <Link to="/show-all/must-buy-boats">
                  <span className="font-16">Little Boats for Sale</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">AdamSea - Sell Your Boat</h3>
            <ul typeof="circle">
              <li>
                <Link to="/info/boat-owner">
                  <span className="font-16">Boat Owner</span>
                </Link>
              </li>
              <li>
                <Link to="/info/broker">
                  <span className="font-16">Broker and Dealer</span>
                </Link>
              </li>
              <li>
                <Link to="/info/boat-builder-and-manufacture">
                  <span className="font-16">Manufacturer</span>
                </Link>
              </li>
              <li>
                <Link to="/info/selling-process">
                  <span className="font-16">Selling Process</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">AdamSea - World Boat Show</h3>
            <ul typeof="circle">
              <li>
                <Link to="/boat-show">
                  <span className="font-16">List Of Boats Show</span>
                </Link>
              </li>
              <li>
                <Link to="/add-boat-show">
                  <span className="font-16">Add Your Boat Show</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">AdamSea - Rent Market</h3>
            <ul typeof="circle">
              <li>
                <Link to={'/show-all-rent/' + showAll.recommended}>
                  <span className="font-16">AdamSea Recommended Trips</span>
                </Link>
              </li>
              <li>
                <Link to={'/rent-type/' + showAll.sharedTrip}>
                  <span className="font-16">AdamSea Shared Trips with Other</span>
                </Link>
              </li>
              <li>
                <Link to={'/rent-type/' + showAll.privateTrip}>
                  <span className="font-16">Private Trips</span>
                </Link>
              </li>
              <li>
                <Link to={'/rent-type/' + showAll.tripsPerHour}>
                  <span className="font-16">AdamSea RENT Per Hour</span>
                </Link>
              </li>
              <li>
                <Link to={'/show-all-rent/' + showAll.mostPopular}>
                  <span className="font-16">Most popular trips</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">AdamSea - Service and Maintenance</h3>
            <ul typeof="circle">
              <li>
                <Link to={'/show-all-service/' + showAllService.recentlyAdded}>
                  <span className="font-16">Recent Added Services</span>
                </Link>
              </li>
              <li>
                <Link to={'#'}>
                  <span className="font-16">Top Rated AdamSea Boat Services</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="mb-3 sitemap-list-cont">
            <h3 className="font-18">AdamSea - Marina & Storage</h3>
            <ul typeof="circle">
              <li>
                <Link to="/show-all-marina-and-storage/recommended">
                  <span className="font-16">Recommended Marina & Storage</span>
                </Link>
              </li>
              <li>
                <Link to={'/show-all-marina-and-storage/' + showAllMarina.mostPopular}>
                  <span className="font-16">Most Popular Marina Facility</span>
                </Link>
              </li>
              <li>
                <Link to={'/show-all-marina-and-storage/' + showAllMarina.topRated}>
                  <span className="font-16">Top Rated AdamSea Marina & Storage Services</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}
