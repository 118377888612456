import { Box } from '@material-ui/core'
import { ErrorMessage, Form, Formik } from 'formik'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { DashboardLayout, Field, TableCard } from '../../components'
import { ButtonComponent } from '../../components/form/Button'
import { CommonModal } from '../../components/modal/commonModal'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
import {
  clear_getRentBoatTripDetails,
  clear_updateRentBoatTripDetails,
  getRentBoatTripDetails,
  updateRentBoatTripDetails,
} from '../../redux/actions'
import { helpTextEnum, RentBookingStatusBadgeClassname, RentTripStatusLabels } from '../../util/enums/enums'
import { copyObj, getRentStatusMod } from '../../util/utilFunctions'

const ManageRentPerHourTripDetails = ({
  history,
  location: { pathname, search, state: { boatDetails, pageState = { view: 'groups' } } = {} },
  getTripDetails,
  getTripDetails_data,
  clear_getTripDetails,
  updateTripDetails,
  updateTripDetails_data,
  clear_updateTripDetails,
}) => {
  const [refresh, setRefresh] = useState(0)
  const [slots, setSlots] = useState([])
  const [groupTableData, setGroupTableData] = React.useState([])
  const [slotsTableData, setSlotsTableData] = React.useState([])
  const [bookingsTableData, setBookingsTableData] = React.useState([])
  const [showSlotsTable, setShowSlotsTable] = React.useState(false)
  const [showBookingsTable, setShowBookingsTable] = React.useState(false)
  const [tz, setTz] = useState('')
  const [utcOffset, setUtcOffset] = useState(0)
  const [titleContent, setTitleContent] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({ page: 1, limit: 20 })
  const [createModalOpen, setCreateModalOpen] = React.useState(false)
  const [editModalOpen, setEditModalOpen] = React.useState(false)
  const [editModalData, setEditModalData] = React.useState({})
  const [message, setMessage] = React.useState({ text: '', type: '' })

  useEffect(() => {
    boatDetails && getTripDetails({ boatId: boatDetails.id })
  }, [boatDetails, refresh, showSlotsTable, showBookingsTable])

  useEffect(() => {
    if (getTripDetails_data.success) {
      const _tz = getTripDetails_data.data.timezoneName
      setTz(_tz)

      const _utcOffset = moment().tz(_tz).utcOffset()
      setUtcOffset(_utcOffset)

      const _slots = (getTripDetails_data.data.slots || []).map(({ __typename, ...s }) => ({
        ...s,
        date: moment.utc(s.startDate).startOf('day').toISOString(),
        bookings: (getTripDetails_data.data.bookings || []).filter(b => String(b.slot) === String(s._id)),
      }))

      setSlots(_slots)

      const uniqueDates = [...new Set(_slots.map(s => s.date))]

      const _groupTableData = uniqueDates.map(uniqueDate => {
        const _slotsTableData = _slots.filter(s => s.date === uniqueDate)

        return {
          date: uniqueDate,
          startTime: moment.min(_slotsTableData.map(s => moment.utc(s.startTime))),
          slotsTableData: _slotsTableData,
        }
      })

      setGroupTableData(_groupTableData)

      switch (pageState.view) {
        case 'groups': {
          setTitleContent([])
          setShowSlotsTable(false)
          setSlotsTableData([])
          setShowBookingsTable(false)
          setBookingsTableData([])
          break
        }

        case 'slots': {
          const group = _groupTableData.find(g => g.date === pageState.groupDate)

          if (group) {
            setTitleContent([[{ label: 'Date', value: moment.utc(group.date).format('MMM DD, YYYY') }]])
            setSlotsTableData(group.slotsTableData)
            setShowSlotsTable(true)
            setShowBookingsTable(false)
            setBookingsTableData([])
          } else {
            setTitleContent([])
            setShowSlotsTable(false)
            setSlotsTableData([])
            setShowBookingsTable(false)
            setBookingsTableData([])
            history.replace({ pathname, state: { boatDetails, pageState: { view: 'groups' } } })
          }

          break
        }

        case 'bookings': {
          const group = _groupTableData.find(g => g.date === pageState.groupDate)

          if (group) {
            const slot = group.slotsTableData.find(s => s._id === pageState.slotId)

            if (slot) {
              setTitleContent([
                [
                  { label: 'Date', value: moment.utc(group.date).format('MMM DD, YYYY') },
                  { label: 'Start Time', value: moment.utc(slot.startTime).format('hh:mm A') },
                  { label: 'End Time', value: moment.utc(slot.endTime).format('hh:mm A') },
                ],
              ])

              setShowSlotsTable(false)
              setSlotsTableData(group.slotsTableData)
              setBookingsTableData(slot.bookings)
              setShowBookingsTable(true)
            } else {
              setTitleContent([[{ label: 'Date', value: moment.utc(group.date).format('MMM DD, YYYY') }]])
              setShowBookingsTable(false)
              setBookingsTableData([])
              setSlotsTableData(group.slotsTableData)
              setShowSlotsTable(true)
              history.replace({
                pathname,
                state: { boatDetails, pageState: { view: 'slots', groupDate: pageState.groupDate } },
              })
            }
          }

          break
        }

        default: {
          setTitleContent([])
          setShowSlotsTable(false)
          setSlotsTableData([])
          setShowBookingsTable(false)
          setBookingsTableData([])
          history.replace({ pathname, state: { boatDetails, pageState: { view: 'groups' } } })
        }
      }
    }

    if (getTripDetails_data.failure) {
      toast.error(getTripDetails_data.message || 'Error while fetching trip details', { autoClose: 5000 })
    }

    if (getTripDetails_data.success || getTripDetails_data.failure) {
      clear_getTripDetails()
    }
  }, [getTripDetails_data])

  useEffect(() => {
    if (updateTripDetails_data.success) {
      toast.success(updateTripDetails_data.message, { autoClose: 5000 })
      setCreateModalOpen(false)
      setEditModalOpen(null)
      setMessage({ type: '', text: '' })
      setRefresh(r => r + 1)
    }

    if (updateTripDetails_data.failure) {
      setMessage({
        text: updateTripDetails_data.message,
        type: 'error',
      })
    }

    if (updateTripDetails_data.success || updateTripDetails_data.failure) {
      clear_updateTripDetails()
    }
  }, [updateTripDetails_data])

  useEffect(() => {
    const page = new URLSearchParams(search).get('page') || 1
    const tableData =
      pageState.view === 'groups' ? groupTableData : pageState.view === 'slots' ? slotsTableData : bookingsTableData

    setPaginationConfig(cfg => ({
      ...cfg,
      page: page * cfg.limit <= tableData.length ? page : Math.ceil(tableData.length / cfg.limit),
    }))
  }, [search, groupTableData, slotsTableData, bookingsTableData])

  useEffect(() => {
    setPaginationConfig(cfg => ({ ...cfg, page: 1 }))
  }, [pageState])

  if (!boatDetails) {
    history.replace('/manage-boat-rents')
    return null
  }

  const getGroupTableCols = () => [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: data => moment.utc(data.value).format('MMM DD, YYYY'),
    },
    {
      Header: 'Start Time',
      accessor: 'startTime',
      Cell: data => moment.utc(data.value).format('hh:mm A'),
    },
    {
      Header: 'Action',
      accessor: 'date',
      Cell: data => (
        <div className="d-flex justify-content-start">
          <ButtonComponent
            className="btn mr-2"
            onClick={() => {
              setTitleContent([[{ label: 'Date', value: moment.utc(data.value).format('MMM DD, YYYY') }]])
              setSlotsTableData(data.original.slotsTableData)
              setShowSlotsTable(true)
              history.replace({ pathname, state: { boatDetails, pageState: { view: 'slots', groupDate: data.value } } })
            }}
          >
            Manage Slots
          </ButtonComponent>
          {data.original.slotsTableData.some(s => s.bookings.length) || (
            <ButtonComponent
              className="btn mr-2"
              color="danger"
              onClick={() => deleteAllSlots(data.original.slotsTableData.filter(s => !s.bookings.length).map(s => s._id))}
            >
              Delete All Slots
            </ButtonComponent>
          )}
        </div>
      ),
    },
  ]

  const getSlotsTableCols = () =>
    [
      {
        Header: 'Start Time',
        accessor: 'startTime',
        Cell: data => moment.utc(data.value).format('hh:mm A'),
      },
      {
        Header: 'End Time',
        accessor: 'endTime',
        Cell: data => moment.utc(data.value).format('hh:mm A'),
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: data => getConvertedPrice(data.value),
      },
      {
        Header: 'Total Boats',
        accessor: 'total',
      },
      {
        Header: 'Available',
        accessor: 'available',
        minWidth: 150,
      },
      {
        minWidth: 250,
        Cell: data => {
          return (
            <div className="d-flex justify-content-center">
              {data.original.bookings.length ? (
                <ButtonComponent
                  className="btn mr-2"
                  color={data.original.bookings.some(b => b.isCancelled) ? 'danger' : 'view'}
                  onClick={() => {
                    setTitleContent([
                      [
                        { label: 'Date', value: moment.utc(data.original.date).format('MMM DD, YYYY') },
                        { label: 'Start Time', value: moment.utc(data.original.startTime).format('hh:mm A') },
                        { label: 'End Time', value: moment.utc(data.original.endTime).format('hh:mm A') },
                      ],
                    ])

                    setShowSlotsTable(false)
                    setBookingsTableData(data.original.bookings)
                    setShowBookingsTable(true)

                    history.replace({
                      pathname,
                      state: {
                        boatDetails,
                        pageState: { view: 'bookings', groupDate: pageState.groupDate, slotId: data.original._id },
                      },
                    })
                  }}
                >
                  View Bookings
                </ButtonComponent>
              ) : (
                <>
                  <ButtonComponent
                    className="btn mr-2"
                    color="edit"
                    onClick={() => {
                      setEditModalData(data.original)
                      setEditModalOpen(true)
                    }}
                  >
                    Edit
                  </ButtonComponent>

                  <ButtonComponent className="btn" color="danger" onClick={() => deleteSlot(data.original._id)}>
                    Delete
                  </ButtonComponent>
                </>
              )}
            </div>
          )
        },
      },
    ].filter(Boolean)

  const getBookingsTableCols = () => [
    {
      Header: 'Booked Date',
      accessor: 'bookedAt',
      Cell: data => moment(data.value).format('MMM DD, YYYY'),
    },
    {
      Header: 'Booked Time',
      accessor: 'bookedAt',
      Cell: data => moment(data.value).format('hh:mm A'),
    },
    {
      Header: 'Customer',
      Cell: ({ original }) => (
        <a
          href="javascript:void(0)"
          className="Verified"
          style={{ backgroundColor: '#007bff' }}
          onClick={() => redirectToUserProfile(original.customer, history)}
        >
          {original.customer.firstName || ''} {original.customer.lastName || ''}
        </a>
      ),
    },
    {
      Header: 'Ticket ID',
      accessor: 'ticketId',
      minWidth: 200,
    },
    {
      Header: 'Boats',
      accessor: 'units',
    },
    {
      Header: 'Total Cost',
      accessor: 'payment.totalAmount',
      Cell: data => getConvertedPrice(data.value),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value, original: { isQRScanned, isCancelled } }) => {
        const status = getRentStatusMod(value, isQRScanned, isCancelled)
        return <span className={RentBookingStatusBadgeClassname[status]}>{RentTripStatusLabels[status]}</span>
      },
    },
  ]

  const getCreateFormInitValues = () => ({
    date: null,
    startTime: null,
    endTime: null,
    duration: null,
    price: null,
    total: null,
  })

  const getEditFormInitValues = () => {
    const data = editModalData || {}

    return {
      _id: data._id || null,
      price: data.price || null,
      total: data.total || null,
    }
  }

  const createFormValidationSchema = yup.object().shape({
    date: yup.string().required('Date is required').nullable(),
    startTime: yup.string().required('Start Time is required').nullable(),
    endTime: yup.string().required('End Time is required').nullable(),
    duration: yup
      .number()
      .typeError('Duration must be Number')
      .required('Duration is required')
      .moreThan(0, `Duration must be greater than 0`)
      .nullable(),
    price: yup
      .number()
      .typeError('Price must be a number')
      .required('Price is required')
      .moreThan(0, `Price must be greater than 0`)
      .nullable(),
    total: yup
      .number()
      .typeError(`Total Boats must be a number`)
      .required(`Total Boats is required`)
      .min(1, `Boats must be greater than 0`)
      .nullable(),
  })

  const editFormValidationSchema = yup.object().shape({
    price: yup
      .number()
      .typeError('Price must be a number')
      .required('Price is required')
      .moreThan(0, `Price must be greater than 0`)
      .nullable(),
    total: yup
      .number()
      .typeError(`Total Boats must be a number`)
      .required(`Total Boats is required`)
      .min(1, `Boats must be greater than 0`)
      .nullable(),
  })

  const createSlots = (values, actions) => {
    let err = false

    const d = moment(values.date).utc().add(utcOffset, 'minutes').startOf('days')
    const st = moment(values.startTime).utc().add(utcOffset, 'minutes').startOf('minutes')
    const et = moment(values.endTime).utc().add(utcOffset, 'minutes').startOf('minutes')

    const s = d.clone().hour(st.hour()).minute(st.minute()).startOf('minute')
    const e = d.clone().hour(et.hour()).minute(et.minute()).startOf('minute')

    const t = moment.utc().add(utcOffset, 'minutes')

    if (d.isBefore(t, 'days')) {
      actions.setFieldError('date', 'Start Date must be today or later')
      err = true
    }

    if (s.isBefore(t)) {
      actions.setFieldError('startTime', 'Start Time must be later than current time')
      err = true
    }

    if (!e.isAfter(s)) {
      actions.setFieldError('endTime', 'End Time must be later than Start Time')
      err = true
    }

    if (err) return

    setMessage({ type: '', text: '' })

    const dur = moment.duration(+values.duration, 'minutes')
    const newSlots = []

    while (true) {
      const slot = {
        type: 'rentPerHour',
        startDate: s.toISOString(),
        startTime: s.toISOString(),
        endDate: s.add(dur).toISOString(),
        endTime: s.toISOString(),
        price: parseFloat(values.price),
        total: +values.total,
        available: +values.total,
      }

      if (s.isAfter(e)) break
      else newSlots.push(slot)
    }

    updateTripDetails({
      id: boatDetails.id,
      data: { tripDetails: newSlots },
    })
  }

  const editSlot = values => {
    const idx = slots.findIndex(({ _id }) => _id === values._id)

    setMessage({ type: '', text: '' })

    const tripDetails = copyObj(slots).map(({ date, bookings, ...s }) => s)
    tripDetails[idx].price = parseFloat(values.price)
    tripDetails[idx].total = +values.total
    tripDetails[idx].available = +values.total

    updateTripDetails({
      id: boatDetails.id,
      data: { tripDetails: [tripDetails[idx]] },
    })
  }

  const onCreateModalClose = () => {
    setCreateModalOpen(false)
  }

  const onEditModalClose = () => {
    setEditModalOpen(false)
  }

  const deleteSlot = slotId => {
    let tripDetails = copyObj(slots).map(({ date, bookings, ...s }) => s)
    tripDetails = tripDetails.filter(t => t._id === slotId).map(t => ({ ...t, available: -1 }))

    updateTripDetails({
      id: boatDetails.id,
      data: { tripDetails },
    })
  }

  const deleteAllSlots = slotIdstoDelete => {
    let tripDetails = copyObj(slots).map(({ date, bookings, ...s }) => s)
    tripDetails = tripDetails.filter(t => slotIdstoDelete.includes(t._id)).map(t => ({ ...t, available: -1 }))

    updateTripDetails({
      id: boatDetails.id,
      data: { tripDetails },
    })
  }

  const onBack = () => {
    if (showSlotsTable) {
      setTitleContent([])
      setShowSlotsTable(false)
      setSlotsTableData([])
      history.replace({ pathname, state: { boatDetails, pageState: { view: 'groups' } } })
    } else if (showBookingsTable) {
      setTitleContent([[{ label: 'Date', value: moment.utc(pageState.groupDate).format('MMM DD, YYYY') }]])
      setShowBookingsTable(false)
      setBookingsTableData([])
      setShowSlotsTable(true)
      history.replace({ pathname, state: { boatDetails, pageState: { view: 'slots', groupDate: pageState.groupDate } } })
    }
  }

  const getPaginatedData = (data, { page = 1, limit = 20 } = {}) => {
    return data.slice((page - 1) * limit, page * limit)
  }

  return (
    <DashboardLayout>
      <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table">
        <TableCard
          className="manage--boat--table"
          title={titleJSX({ boatDetails, back: (showSlotsTable || showBookingsTable) && onBack })}
          headerContent={
            <>
              <h3 className="h3">Trip Details</h3>

              {titleContent.map(sec => (
                <>
                  <div className="d-flex">
                    {sec.map(line => (
                      <div className="d-flex" style={{ marginLeft: '25px' }}>
                        <span style={{ fontWeight: 'bold' }}>
                          {line.label}
                          <br />
                          <span style={{ color: '#061e9c', fontSize: '1.1em' }}>{line.value}</span>
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              ))}
            </>
          }
          button={{ name: 'Add Time Slot' }}
          openModal
          setModalOpen={() => setCreateModalOpen(true)}
          columns={showSlotsTable ? getSlotsTableCols() : showBookingsTable ? getBookingsTableCols() : getGroupTableCols()}
          data={
            showSlotsTable
              ? getPaginatedData(slotsTableData, paginationConfig)
              : showBookingsTable
              ? getPaginatedData(bookingsTableData, paginationConfig)
              : getPaginatedData(groupTableData, paginationConfig)
          }
          loading={getTripDetails_data.loading || updateTripDetails_data.loading}
          total={showSlotsTable ? slotsTableData.length : showBookingsTable ? bookingsTableData.length : groupTableData.length}
          action={setPaginationConfig}
        />
      </div>

      <CommonModal
        open={createModalOpen}
        close={onCreateModalClose}
        title="Add Trip Details"
        className="manage--boat-rent-modal-root"
      >
        <Box>
          <Formik
            initialValues={getCreateFormInitValues()}
            validationSchema={createFormValidationSchema}
            validateOnChange
            onSubmit={createSlots}
            enableReinitialize
          >
            {({ setFieldValue, values, handleSubmit }) => (
              <>
                <div className="rent--form--detail-modal">
                  <Form>
                    <Row>
                      <Col xs={6}>
                        <div className="mb-2">
                          <label className="required mr-1">
                            <span>Date of rent</span>
                          </label>

                          <Field
                            label="Date of rent"
                            type="select-date-time"
                            name="date"
                            isTimeFormatFalse
                            timezoneName={tz}
                            placeholder="Date of rent"
                            value={values.date}
                            className="mb-0"
                            isUpdate
                            required
                            onChange={val => setFieldValue('date', val)}
                          />

                          <ErrorMessage name="date" component="div" className="error-message" />
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="mb-2">
                          <label className="required mr-1">
                            <span>When does the first rental start?</span>
                          </label>

                          <Field
                            label="When does the first rental start?"
                            type="select-date-time"
                            name="startTime"
                            isDateFormatFalse
                            timezoneName={tz}
                            placeholder="When does the first rental start?"
                            value={values.startTime}
                            className="mb-0"
                            isUpdate
                            required
                            onChange={val => setFieldValue('startTime', val)}
                          />

                          <ErrorMessage name="startTime" component="div" className="error-message" />
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="mb-2">
                          <Field
                            label="What is the duration of the ride in minutes?"
                            type="number"
                            name="duration"
                            placeholder="What is the duration of the ride in minutes?"
                            value={values.duration}
                            required
                            onChangeText={e => setFieldValue('duration', e.target.value)}
                            labelClass="modLabelDisp"
                          />

                          <ErrorMessage name="duration" component="div" className="error-message" />
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="mb-2">
                          <label className="required mr-1">
                            <span>When does the last rental end?</span>
                          </label>

                          <Field
                            label="When does the last rental end?"
                            type="select-date-time"
                            name="endTime"
                            isDateFormatFalse
                            timezoneName={tz}
                            placeholder="When does the last rental end?"
                            value={values.endTime}
                            className="mb-0"
                            isUpdate
                            required
                            onChange={val => setFieldValue('endTime', val)}
                          />

                          <ErrorMessage name="endTime" component="div" className="error-message" />
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="mb-2">
                          <Field
                            label={`What is the cost of each ride?`}
                            helpText={helpTextEnum.priceInput}
                            type="number"
                            name="price"
                            placeholder="What is the cost of each ride?"
                            value={values.price}
                            required
                            onChangeText={e => setFieldValue('price', e.target.value)}
                            labelClass="modLabelDisp"
                          />

                          <ErrorMessage name="price" component="div" className="error-message" />
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="mb-2">
                          <Field
                            label="How many Boats do you have for rent"
                            type="number"
                            name="total"
                            placeholder="How many Boats do you have for rent"
                            value={values.total}
                            required
                            onChangeText={e => setFieldValue('total', e.target.value)}
                            labelClass="modLabelDisp"
                          />

                          <ErrorMessage name="total" component="div" className="error-message" />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>

                <div className="manage--rb--btn-footer justify-content-end">
                  <ButtonComponent
                    color="success"
                    variant="outline"
                    className="boat--rent--manage--details"
                    onClick={handleSubmit}
                  >
                    Add
                  </ButtonComponent>
                </div>
              </>
            )}
          </Formik>
        </Box>
      </CommonModal>

      <CommonModal open={editModalOpen} close={onEditModalClose} title="Edit Slot" className="manage--boat-rent-modal-root">
        <Box>
          <Formik
            initialValues={getEditFormInitValues()}
            validationSchema={editFormValidationSchema}
            validateOnChange
            onSubmit={editSlot}
            enableReinitialize
          >
            {({ setFieldValue, values, handleSubmit }) => (
              <>
                <div className="rent--form--detail-modal">
                  <Form>
                    <Row>
                      <Col xs={6}>
                        <div className="mb-2">
                          <Field
                            label="Price per Boat"
                            type="number"
                            name="price"
                            placeholder="Price per Boat"
                            value={values.price}
                            required
                            onChangeText={e => setFieldValue('price', e.target.value)}
                          />

                          <ErrorMessage name="price" component="div" className="error-message" />
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="mb-2">
                          <Field
                            label="How many Boats do you have for rent"
                            type="number"
                            name="total"
                            placeholder="How many Boats do you have for rent"
                            value={values.total}
                            required
                            onChangeText={e => setFieldValue('total', e.target.value)}
                          />

                          <ErrorMessage name="total" component="div" className="error-message" />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>

                <div className="manage--rb--btn-footer justify-content-between">
                  <div>
                    {message.text && message.type === 'error' && <div className="boat--rent--err-msg">{message.text}</div>}
                  </div>
                  <ButtonComponent
                    color="success"
                    variant="outline"
                    className="boat--rent--manage--details"
                    onClick={handleSubmit}
                  >
                    Edit
                  </ButtonComponent>
                </div>
              </>
            )}
          </Formik>
        </Box>
      </CommonModal>
    </DashboardLayout>
  )
}

const mapStateToProps = state => ({
  getTripDetails_data: state.boatRentReducer.getTripDetails_data,
  updateTripDetails_data: state.boatRentReducer.updateTripDetails_data,
})

const mapDispatchToProps = dispatch => ({
  getTripDetails: data => dispatch(getRentBoatTripDetails(data)),
  clear_getTripDetails: () => dispatch(clear_getRentBoatTripDetails()),
  updateTripDetails: data => dispatch(updateRentBoatTripDetails(data)),
  clear_updateTripDetails: data => dispatch(clear_updateRentBoatTripDetails(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageRentPerHourTripDetails)

const titleJSX = ({ boatDetails, back }) => (
  <div className="d-flex flex-column">
    {/* <div className="my--request--inner--title">
      <span>Trip Details</span>
    </div> */}

    <div className="request--common--detail--section">
      <div className="d-flex">
        <div>
          <div className="request--common--detail--div">
            <span className="request--common--detail--title">
              Ad ID: <span className="request--common--detail--data">{boatDetails.adId}</span>
            </span>
          </div>
          <div className="request--common--detail--div">
            <span className="request--common--detail--title">
              Trip: <span className="request--common--detail--data">{boatDetails.trip.alias}</span>
            </span>
          </div>
          <div className="request--common--detail--div">
            <span className="request--common--detail--title">
              Trip Type: <span className="request--common--detail--data">{boatDetails.tripType.alias}</span>
            </span>
          </div>
        </div>
      </div>

      {back && (
        <div className="mt-4 request--common--detail--div">
          <button className="btn btn-outline-primary py-1 px-4" onClick={back}>
            Back
          </button>
        </div>
      )}
    </div>
  </div>
)
