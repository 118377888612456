import React from 'react'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { Route, Router, Switch } from 'react-router-dom'
import withAuth from '../hoc/withAuth'
import checkRole from '../hoc/checkRole'
import withProps from '../hoc/withProps'
// import Home from '../containers/home/home'
import Dashboard from '../containers/dashboard/dashboard'
import Profile from '../containers/profile/profile'
import UserStripeProfileSuccess from '../containers/profile/userStripeProfileSuccess'
import UserStripeProfileReauth from '../containers/profile/userStripeProfileReauth'
import BoatServices from '../containers/boatServices/boatService'
import BoatShow from '../containers/boatShow/boatShow'
import addMarinaStorage from '../containers/dashboard/addMarinaStorage/addMarinaStorage'
import ManageMarinaStorage from '../containers/dashboard/addMarinaStorage/ManageMarinaStorage'
import Rent from '../containers/rent/rent'
import Login from '../containers/login/login'
import Register from '../containers/register/register'
import BoatInner from '../containers/boatInner/BoatInner'
import AddBoat from '../containers/dashboard/manageBoats/AddBoat'
import AddBranch from '../containers/dashboard/branch/addBranch'
import SearchBoats from '../containers/boat/searchBoats'
import MarinaStorage from '../containers/marinaStorage/marinaStorage'
import MarinaStorageView from '../containers/marinaStorage/MarinaStorageView'
import SendResetLink from '../containers/forgetPassword/sendResetLink'
import forgetPassword from '../containers/forgetPassword/forgetPassword'
import CreateRentBoat from '../containers/boat/CreateRentBoat'
import FooterContact from '../components/footer/footerContact'
import ContactUsSuggestions from '../components/footer/contactUsSuggestions'
import ContactUsServices from '../components/footer/contactUsServices'
import ContactUsComplaint from '../components/footer/contactUsComplaint'
import CertifyBoat from '../components/footer/certifyBoat'
import ManageBoats from '../containers/dashboard/manageBoats/manageBoats'
import RentInner from '../containers/rentInner/rentInner'
import MarinaStorageInner from '../containers/marinaStorageInner/marinaStorageInner'
import boatMedia from '../containers/boatMedia/boatMedia'
import SMFeeds from '../containers/social-media/routes/feeds'
import SMPost from '../containers/social-media/routes/post'
import SMUser from '../containers/social-media/routes/user'
import SM from '../containers/sm/routes'
import CreateArticle from '../containers/dashboard/article/createArticle'
import ManageArticle from '../containers/dashboard/article/ManageArticle'
import ManageBoatRents from '../containers/boat/ManageBoatRents'
import ManageTripDetails from '../containers/boat/ManageTripDetails'
import ManageRentPerHourTripDetails from '../containers/boat/ManageRentPerHourTripDetails'
import ManageBranch from '../containers/dashboard/branch/ManageBranch'
import Help from '../components/header/headerHelp'
import ScrollToTop from '../components/SrollToTop'
import CityWiseBoats from '../containers/boat/listings/CityWiseBoats'
import AddBoatService from '../containers/boatServices/AddBoatService'
import AddBoatShow from '../containers/boatShow/addBoatShow'
import manageBoatShows from '../containers/boatShow/manageBoatShows'
import boatServiceView from '../containers/boatServices/boatServiceView'
import showAll from '../containers/showAll/showAll'
import showAllMarinaAndStorage from '../containers/showAll/showAllMarinaAndStorage'
import ShowAllMarinaAndStorageSimilar from '../containers/showAll/showAllMarinaAndStorageSimilar'
import ShowAllBoats from '../containers/boat/listings/showAllBoats'
import CategoryWiseBoats from '../containers/boat/listings/CategoryWiseBoats'
import RentCategoryWiseBoats from '../containers/boat/listings/RentCategoryWiseBoats'
import UserProfile from '../components/userProfile/userProfile'
import ShowAllBoatServiceGallery from '../components/gridComponents/showAllBoatServiceGallery'
import recommended from '../containers/boat/listings/recommended'
import ChangePassword from '../containers/profile/changePassword'
import ManageAuctionRoom from '../containers/auctionRoom/manageAuctionRoom'
import manageAuctionBids from '../containers/auctionRoom/manageAuctionBids'
import SearchBoatServices from '../containers/boatServices/SearchBoatServices'
import SearchMarinaStorage from '../containers/marinaStorage/SearchMarinaStorage'
import searchResult from '../containers/searchResult/searchResult'
import ShowAllAuctionRooms from '../components/gridComponents/showAllAuctionRooms'
import MarinServicesView from '../components/marinaStorage/marinServicesView'
import SalesEngine from '../containers/salesEngine/listings/SalesEngine'
import SalesEngineArchive from '../containers/salesEngine/listings/SalesEngineArchive'
import SalesEngineProcess from '../containers/salesEngine/process/SalesEngineProcess'
import BoatServiceInner from '../containers/boatServicesInner/boatServicesInner'
import UserGuide from '../containers/userGuide/userGuide'
import UserFaq from '../containers/userFaq/userFaq'
import Logout from '../components/helper/logout'
import rentCityWiseBoats from '../containers/boat/listings/rentCityWiseBoats'
import SearchBoatShow from '../containers/boatShow/searchBoatShow'
import searchRentBoats from '../containers/boat/listings/searchRentBoats'
import BoatShipper from '../containers/boatInner/BoatShipper'
import createSurveyorReport from '../containers/dashboard/branch/createSurveyorReport'
import showAllService from '../components/gridComponents/showAllService'
import showAllBoatShow from '../components/gridComponents/showAllBoatShow'
import RelatedBoats from '../containers/boat/RelatedBoats'
import rentTypeBoat from '../containers/boat/listings/rentTypeBoat'
import ReviewBooking from '../containers/reviewBooking/ReviewBooking'
import MyBookings from '../containers/bookings/MyBookings'
import ManageWishlist from '../containers/dashboard/manageWishlist/ManageWishlist'
import BoatArticlesList from '../containers/boatMedia/BoatArticlesList'
import manageAuctionBidList from '../containers/auctionRoom/manageAuctionBidList'
import manageDealers from '../containers/dealers/manageDealers'
import addDealer from '../containers/dealers/addDealer'
import articleInner from '../containers/articles/articleInner'
import SalesEngineShipperDetail from '../containers/salesEngine/process/SalesEngineShipperDetail'
import Chat from '../containers/chat/chat'
import BoatSurveyor from '../containers/boatInner/BoatSurveyor'
import ArticleListing from '../containers/articles/articleListing'
import SalesEngineRequest from '../containers/salesEngine/listings/SalesEngineRequest'
import SalesEngineDeals from '../containers/salesEngine/listings/SalesEngineDeals'
import dealersRequests from '../containers/dealers/dealersRequests'
import requestedManufacturer from '../containers/dealers/requestedManufacturer'
import Careers from '../components/home/careers/careers'
import checkAdminAccess from '../hoc/checkAdminAccess'
import BoatForSellAroundWorld from '../containers/boat/listings/BoatForSellAroundWorld'
import Download from '../containers/download/download'
import SingleJobDetail from '../components/home/careers/SingleJobDetail'
import SalesEngineByBoats from '../containers/salesEngine/listings/SalesEngineByBoats'
import DealersSalesEngine from '../containers/salesEngine/listings/DealersSalesEngine'
import ShipmentDocument from '../containers/salesEngine/listings/ShipmentDocument'
import Notification from '../containers/notification/notification'
import { BoatMediaArticle } from '../components/gridComponents/BoatMediaArticle'
import BoatMediaAllArticles from '../components/gridComponents/boatMediaAllArticles'
import GlobalSearch from '../containers/globalSearch/globalSearch'
import FooterLinksCommonComponent from '../containers/footerLink/footerLinkCommonComponent'
import ManagePayment from '../containers/dashboard/managePayment/ManagePayment'
import ManagePayouts from '../containers/dashboard/managePayment/ManagePayouts'
import loadable from '@loadable/component'
import { Loader } from '../components'
import HelmetMetaData from '../components/helmetComponent/HelmetMeta'
import { envConfig } from '../config'
import FeedBackForm from '../components/feedback/FeedBackForm'
import ContactForm from '../components/Contact-form/ContactForm'
import CreateEditTripDetail from '../containers/boat/CreateEditTripDetail'
import ViewRentTicket from '../containers/bookings/ViewRentTicket'
import ManageRentPayouts from '../containers/boat/ManageRentPayouts'
import DynamicPage from '../containers/DynamicPage/DynamicPage'
import RouteWithSeo from './RouteWithSeo'
import CloseAccount from '../containers/profile/closeAccount'
import Sitemap from '../containers/sitemap'
import PrivacyPolicy from '../containers/privacyPolicy/PrivacyPolicy'
import Market from '../containers/market/Market'
import IntegrityCompliance from '../containers/integrityCompliance/IntegrityCompliance'
import BeAPartner from '../containers/be-a-partner'
import AboutUs from '../containers/about-us'
import ShipperInfo from '../containers/shipperInfo'
import RentCharter from '../containers/rent-and-charter'
// import AboutUs from '../containers/aboutUs/AboutUs'
import BrokerInfo from '../containers/brokerInfo/BrokerInfo'
import BuilderManufacturer from '../containers/builderManufacturer/BuilderManufacturer'
import BoatOwner from '../containers/boatOwners/BoatOwners'
import SurveyorInfo from '../containers/surveyourInfo/SurveyorInfo'
import Manufacturer from '../containers/manufacturer'
import AdamseaCommunity from '../containers/adamsea-community'
import SellingProcess from '../containers/selling-process'
import FraudProtection from '../containers/fraud-protection'
import SurveyYourBoat from '../containers/surveyYourBoat'
import BuyingProcess from '../containers/buying-process'
import PageNotFound from '../containers/page-not-found/PageNotFound'
import AddPayment from '../containers/dashboard/managePayment/AddPayment'

const fallBackProp = { fallback: <></> }

const Home = loadable(() => import('../containers/home/home'), fallBackProp)
// const BoatInner = loadable(() => import('../containers/boatInner/BoatInner'), fallBackProp)
// const Dashboard = loadable(() => import('../containers/dashboard/dashboard'), fallBackProp)
// const Profile = loadable(() => import('../containers/profile/profile'), fallBackProp)
// const UserStripeProfileSuccess = loadable(() => import('../containers/profile/userStripeProfileSuccess'), fallBackProp)
// const UserStripeProfileReauth = loadable(() => import('../containers/profile/userStripeProfileReauth'), fallBackProp)
// const BoatServices = loadable(() => import('../containers/boatServices/boatService'), fallBackProp)
// const BoatShow = loadable(() => import('../containers/boatShow/boatShow'), fallBackProp)
// const addMarinaStorage = loadable(() => import('../containers/dashboard/addMarinaStorage/addMarinaStorage'), fallBackProp)
// const ManageMarinaStorage = loadable(() => import('../containers/dashboard/addMarinaStorage/ManageMarinaStorage'), fallBackProp)
// const Rent = loadable(() => import('../containers/rent/rent'), fallBackProp)
// const Login = loadable(() => import('../containers/login/login'), fallBackProp)
// const Register = loadable(() => import('../containers/register/register'), fallBackProp)
// const AddBoat = loadable(() => import('../containers/dashboard/manageBoats/AddBoat'), fallBackProp)
// const AddBranch = loadable(() => import('../containers/dashboard/branch/addBranch'), fallBackProp)
// const SearchBoats = loadable(() => import('../containers/boat/searchBoats'), fallBackProp)
// const MarinaStorage = loadable(() => import('../containers/marinaStorage/marinaStorage'), fallBackProp)
// const MarinaStorageView = loadable(() => import('../containers/marinaStorage/MarinaStorageView'), fallBackProp)
// const SendResetLink = loadable(() => import('../containers/forgetPassword/sendResetLink'), fallBackProp)
// const forgetPassword = loadable(() => import('../containers/forgetPassword/forgetPassword'), fallBackProp)
// const CreateRentBoat = loadable(() => import('../containers/boat/CreateRentBoat'), fallBackProp)
// const ManageBoats = loadable(() => import('../containers/dashboard/manageBoats/manageBoats'), fallBackProp)
// const RentInner = loadable(() => import('../containers/rentInner/rentInner'), fallBackProp)
// const MarinaStorageInner = loadable(() => import('../containers/marinaStorageInner/marinaStorageInner'), fallBackProp)
// const boatMedia = loadable(() => import('../containers/boatMedia/boatMedia'), fallBackProp)
// const CreateArticle = loadable(() => import('../containers/dashboard/article/createArticle'), fallBackProp)
// const ManageArticle = loadable(() => import('../containers/dashboard/article/ManageArticle'), fallBackProp)
// const ManageBoatRents = loadable(() => import('../containers/boat/ManageBoatRents'), fallBackProp)
// const ManageTripDetails = loadable(() => import('../containers/boat/ManageTripDetails'), fallBackProp)
// const ManageRentPerHourTripDetails = loadable(() => import('../containers/boat/ManageRentPerHourTripDetails'), fallBackProp)
// const ManageBranch = loadable(() => import('../containers/dashboard/branch/ManageBranch'), fallBackProp)
// const CityWiseBoats = loadable(() => import('../containers/boat/listings/CityWiseBoats'), fallBackProp)
// const AddBoatService = loadable(() => import('../containers/boatServices/AddBoatService'), fallBackProp)
// const AddBoatShow = loadable(() => import('../containers/boatShow/addBoatShow'), fallBackProp)
// const manageBoatShows = loadable(() => import('../containers/boatShow/manageBoatShows'), fallBackProp)
// const boatServiceView = loadable(() => import('../containers/boatServices/boatServiceView'), fallBackProp)
// const showAll = loadable(() => import('../containers/showAll/showAll'), fallBackProp)
// const showAllMarinaAndStorage = loadable(() => import('../containers/showAll/showAllMarinaAndStorage'), fallBackProp)
// const ShowAllMarinaAndStorageSimilar = loadable(() => import('../containers/showAll/showAllMarinaAndStorageSimilar'), fallBackProp)
// const ShowAllBoats = loadable(() => import('../containers/boat/listings/showAllBoats'), fallBackProp)
// const CategoryWiseBoats = loadable(() => import('../containers/boat/listings/CategoryWiseBoats'), fallBackProp)
// const RentCategoryWiseBoats = loadable(() => import('../containers/boat/listings/RentCategoryWiseBoats'), fallBackProp)
// const recommended = loadable(() => import('../containers/boat/listings/recommended'), fallBackProp)
// const ChangePassword = loadable(() => import('../containers/profile/changePassword'), fallBackProp)
// const ManageAuctionRoom = loadable(() => import('../containers/auctionRoom/manageAuctionRoom'), fallBackProp)
// const manageAuctionBids = loadable(() => import('../containers/auctionRoom/manageAuctionBids'), fallBackProp)
// const SearchBoatServices = loadable(() => import('../containers/boatServices/SearchBoatServices'), fallBackProp)
// const SearchMarinaStorage = loadable(() => import('../containers/marinaStorage/SearchMarinaStorage'), fallBackProp)
// const searchResult = loadable(() => import('../containers/searchResult/searchResult'), fallBackProp)
// const SalesEngine = loadable(() => import('../containers/salesEngine/listings/SalesEngine'), fallBackProp)
// const SalesEngineArchive = loadable(() => import('../containers/salesEngine/listings/SalesEngineArchive'), fallBackProp)
// const SalesEngineProcess = loadable(() => import('../containers/salesEngine/process/SalesEngineProcess'), fallBackProp)
// const BoatServiceInner = loadable(() => import('../containers/boatServicesInner/boatServicesInner'), fallBackProp)
// const UserGuide = loadable(() => import('../containers/userGuide/userGuide'), fallBackProp)
// const UserFaq = loadable(() => import('../containers/userFaq/userFaq'), fallBackProp)
// const rentCityWiseBoats = loadable(() => import('../containers/boat/listings/rentCityWiseBoats'), fallBackProp)
// const SearchBoatShow = loadable(() => import('../containers/boatShow/searchBoatShow'), fallBackProp)
// const searchRentBoats = loadable(() => import('../containers/boat/listings/searchRentBoats'), fallBackProp)
// const BoatShipper = loadable(() => import('../containers/boatInner/BoatShipper'), fallBackProp)
// const createSurveyorReport = loadable(() => import('../containers/dashboard/branch/createSurveyorReport'), fallBackProp)
// const RelatedBoats = loadable(() => import('../containers/boat/RelatedBoats'), fallBackProp)
// const rentTypeBoat = loadable(() => import('../containers/boat/listings/rentTypeBoat'), fallBackProp)
// const ReviewBooking = loadable(() => import('../containers/reviewBooking/ReviewBooking'), fallBackProp)
// const MyBookings = loadable(() => import('../containers/bookings/MyBookings'), fallBackProp)
// const ManageWishlist = loadable(() => import('../containers/dashboard/manageWishlist/ManageWishlist'), fallBackProp)
// const BoatArticlesList = loadable(() => import('../containers/boatMedia/BoatArticlesList'), fallBackProp)
// const manageAuctionBidList = loadable(() => import('../containers/auctionRoom/manageAuctionBidList'), fallBackProp)
// const manageDealers = loadable(() => import('../containers/dealers/manageDealers'), fallBackProp)
// const addDealer = loadable(() => import('../containers/dealers/addDealer'), fallBackProp)
// const articleInner = loadable(() => import('../containers/articles/articleInner'), fallBackProp)
// const SalesEngineShipperDetail = loadable(() => import('../containers/salesEngine/process/SalesEngineShipperDetail'), fallBackProp)
// const Chat = loadable(() => import('../containers/chat/chat'), fallBackProp)
// const BoatSurveyor = loadable(() => import('../containers/boatInner/BoatSurveyor'), fallBackProp)
// const ArticleListing = loadable(() => import('../containers/articles/articleListing'), fallBackProp)
// const SalesEngineRequest = loadable(() => import('../containers/salesEngine/listings/SalesEngineRequest'), fallBackProp)
// const SalesEngineDeals = loadable(() => import('../containers/salesEngine/listings/SalesEngineDeals'), fallBackProp)
// const dealersRequests = loadable(() => import('../containers/dealers/dealersRequests'), fallBackProp)
// const requestedManufacturer = loadable(() => import('../containers/dealers/requestedManufacturer'), fallBackProp)
// const BoatForSellAroundWorld = loadable(() => import('../containers/boat/listings/BoatForSellAroundWorld'), fallBackProp)
// const Download = loadable(() => import('../containers/download/download'), fallBackProp)
// const SalesEngineByBoats = loadable(() => import('../containers/salesEngine/listings/SalesEngineByBoats'), fallBackProp)
// const DealersSalesEngine = loadable(() => import('../containers/salesEngine/listings/DealersSalesEngine'), fallBackProp)
// const ShipmentDocument = loadable(() => import('../containers/salesEngine/listings/ShipmentDocument'), fallBackProp)
// const Notification = loadable(() => import('../containers/notification/notification'), fallBackProp)
// const GlobalSearch = loadable(() => import('../containers/globalSearch/globalSearch'), fallBackProp)
// const FooterLinksCommonComponent = loadable(() => import('../containers/footerLink/footerLinkCommonComponent'), fallBackProp)
// const ManagePayment = loadable(() => import('../containers/dashboard/managePayment/ManagePayment'), fallBackProp)
// const ManagePayouts = loadable(() => import('../containers/dashboard/managePayment/ManagePayouts'), fallBackProp)
// const CreateEditTripDetail = loadable(() => import('../containers/boat/CreateEditTripDetail'), fallBackProp)
// const ViewRentTicket = loadable(() => import('../containers/bookings/ViewRentTicket'), fallBackProp)
// const ManageRentPayouts = loadable(() => import('../containers/boat/ManageRentPayouts'), fallBackProp)
// const DynamicPage = loadable(() => import('../containers/DynamicPage/DynamicPage'), fallBackProp)
// const CloseAccount = loadable(() => import('../containers/profile/closeAccount'), fallBackProp)
// const Sitemap = loadable(() => import('../containers/sitemap'), fallBackProp)

export const AppRoutes = props => {
  const BUILD_TARGET = envConfig.BUILD_TARGET

  const serverCondition = BUILD_TARGET === 'server' ? createMemoryHistory() : createBrowserHistory()
  return (
    <Router history={serverCondition}>
      <ScrollToTop>
        <Switch>
          <RouteWithSeo path="/" exact component={withProps(checkAdminAccess(Home))} />
          <RouteWithSeo path="/login" exact component={Login} />
          <RouteWithSeo path="/register" exact component={Register} />
          <RouteWithSeo path="/register/:type" exact component={Register} />
          <RouteWithSeo path="/logout" exact component={withProps(Logout)} />

          {/* Forgot Password */}
          <RouteWithSeo path="/send-reset-password-link" exact component={SendResetLink} />
          <RouteWithSeo path="/forget-password" exact component={withProps(forgetPassword)} />

          {/* Dashboard */}
          <RouteWithSeo path="/dashboard" exact component={withAuth(withProps(Dashboard))} />
          <RouteWithSeo path="/profile" exact component={withAuth(withProps(Profile))} />
          <RouteWithSeo path="/stripe-connect-return" exact component={withAuth(withProps(UserStripeProfileSuccess))} />
          <RouteWithSeo path="/reauth" exact component={withAuth(withProps(UserStripeProfileReauth))} />
          <RouteWithSeo path="/change-password" exact component={withAuth(withProps(ChangePassword))} />
          <RouteWithSeo path="/close-account" exact component={withAuth(withProps(CloseAccount))} />
          <RouteWithSeo path="/manage-boats" exact component={withAuth(checkRole(withProps(ManageBoats)))} />
          <RouteWithSeo path="/manage-boat-rents" exact component={withAuth(checkRole(withProps(ManageBoatRents)))} />
          <RouteWithSeo path="/manage-trip-details" exact component={withAuth(checkRole(withProps(ManageTripDetails)))} />
          <RouteWithSeo
            path="/manage-rent-per-hour-trip-details"
            exact
            component={withAuth(checkRole(withProps(ManageRentPerHourTripDetails)))}
          />
          <RouteWithSeo path="/manage-articles" exact component={withAuth(checkRole(withProps(ManageArticle)))} />
          <RouteWithSeo path="/manage-branches" exact component={withAuth(checkRole(withProps(ManageBranch)))} />
          <RouteWithSeo path="/manage-marina-storage" exact component={withAuth(checkRole(withProps(ManageMarinaStorage)))} />
          <RouteWithSeo path="/manage-auction-rooms" exact component={withAuth(checkRole(withProps(ManageAuctionRoom)))} />
          <RouteWithSeo path="/manage-auction-bids" exact component={withAuth(checkRole(withProps(manageAuctionBids)))} />
          <RouteWithSeo path="/manage-boat-shows" exact component={withAuth(checkRole(withProps(manageBoatShows)))} />
          <RouteWithSeo path="/manage-wishlist" exact component={withProps(ManageWishlist)} />
          <RouteWithSeo path="/sales-engines" exact component={withAuth(checkRole(withProps(SalesEngine)))} />
          <RouteWithSeo path="/my-requests" exact component={withAuth(checkRole(withProps(SalesEngineRequest)))} />
          <RouteWithSeo path="/my-deals" exact component={withAuth(checkRole(withProps(SalesEngineDeals)))} />
          <RouteWithSeo path="/deals/:id" exact component={withAuth(checkRole(withProps(DealersSalesEngine)))} />
          <RouteWithSeo path="/sales-engine-archives" exact component={withAuth(checkRole(withProps(SalesEngine)))} />
          <RouteWithSeo path="/manage-payment" exact component={withAuth(checkRole(withProps(ManagePayment)))} />
          <RouteWithSeo path="/add-payment" exact component={withAuth(checkRole(withProps(AddPayment)))} />
          <RouteWithSeo path="/manage-payouts" exact component={withAuth(checkRole(withProps(ManagePayouts)))} />
          <RouteWithSeo path="/manage-rent-payouts" exact component={withAuth(checkRole(withProps(ManageRentPayouts)))} />
          <RouteWithSeo path="/auction-room-bid-list" exact component={withAuth(checkRole(withProps(manageAuctionBidList)))} />
          <RouteWithSeo path="/manage-dealers" exact component={withAuth(checkRole(withProps(manageDealers)))} />
          <RouteWithSeo path="/dealer-requests" exact component={withAuth(checkRole(withProps(dealersRequests)))} />
          <RouteWithSeo path="/request-manufacturer" exact component={withAuth(checkRole(withProps(requestedManufacturer)))} />

          {/* Forms */}
          <RouteWithSeo path="/add-marina-storage" exact component={withAuth(checkRole(withProps(addMarinaStorage)))} />
          {/* <Route path='/add-boat' exact component={withAuth(checkRole(withProps(AddBoat)))} /> */}
          <RouteWithSeo path="/add-boat" exact component={withAuth(withProps(AddBoat))} />

          <RouteWithSeo path="/edit-boat" exact component={withAuth(checkRole(withProps(AddBoat)))} />
          <RouteWithSeo path="/add-branch" exact component={withAuth(checkRole(withProps(AddBranch)))} />
          <RouteWithSeo path="/edit-branch/:id" exact component={withAuth(checkRole(withProps(AddBranch)))} />
          <RouteWithSeo path="/add-rent-boat" exact component={withAuth(checkRole(withProps(CreateRentBoat)))} />
          <RouteWithSeo path="/edit-rent-boat/:id" exact component={withAuth(checkRole(withProps(CreateRentBoat)))} />
          <RouteWithSeo path="/create-article" exact component={withAuth(checkRole(withProps(CreateArticle)))} />
          <RouteWithSeo path="/add-boat-service" exact component={withAuth(checkRole(withProps(AddBoatService)))} />
          <RouteWithSeo path="/add-boat-show" exact component={withAuth(checkRole(withProps(AddBoatShow)))} />
          <RouteWithSeo
            path="/add-surveyor-report/:salesEngineId/:branchId"
            exact
            component={withAuth(checkRole(withProps(createSurveyorReport)))}
          />
          <RouteWithSeo path="/add-dealer" exact component={withAuth(checkRole(withProps(addDealer)))} />

          {/* Boat */}
          <RouteWithSeo path="/boat-inner/:role/:id/:name" component={withProps(checkAdminAccess(BoatInner))} />
          <RouteWithSeo path="/search-boats" exact component={withProps(SearchBoats)} />
          <RouteWithSeo path="/city-wise-boats/:country" exact component={withProps(CityWiseBoats)} />
          {/* <RouteWithSeo path="/city-wise-boats/:city/:country" exact component={withProps(CityWiseBoats)} /> */}
          <Route path="/show-all/:type" exact component={withProps(showAll)} />
          <Route path="/show-all-boats/:type" exact component={withProps(ShowAllBoats)} />
          <RouteWithSeo path="/category-wise-boats/:categoryType/:categoryId" exact component={withProps(CategoryWiseBoats)} />
          <RouteWithSeo path="/related-boats/:id/:role/:type" exact component={withProps(RelatedBoats)} />
          <RouteWithSeo path="/boats-for-sell-around-the-world" exact component={withProps(BoatForSellAroundWorld)} />

          {/* Rent */}
          <RouteWithSeo path="/rent" exact component={withProps(Rent)} />
          <RouteWithSeo path="/rent-inner/:id/:name" exact component={withProps(RentInner)} />
          <RouteWithSeo
            path="/rent-category-wise-boats/:tripType/:tripTypeId/:country"
            exact
            component={withProps(RentCategoryWiseBoats)}
          />
          <RouteWithSeo path="/rent-city-wise-boats/:city/:country" exact component={withProps(rentCityWiseBoats)} />
          <RouteWithSeo path="/search-rent-boats" exact component={withProps(searchRentBoats)} />
          <RouteWithSeo path="/show-all-rent/:type" exact component={withProps(recommended)} />
          <RouteWithSeo path="/rent-type/:type" exact component={withProps(rentTypeBoat)} />

          {/* Rent Booking */}
          <RouteWithSeo path="/my-bookings" exact component={withProps(MyBookings)} />
          <RouteWithSeo path="/review-booking/:id" exact component={withProps(ReviewBooking)} />
          <RouteWithSeo path="/view-rent-ticket/:id" exact component={withAuth(withProps(ViewRentTicket))} />

          {/* Marina & Storage */}
          <RouteWithSeo path="/marina-storage" exact component={withProps(MarinaStorage)} />
          <RouteWithSeo path="/marina-storage-view/" exact component={withProps(MarinaStorageView)} />
          <RouteWithSeo
            path="/marina-storage-inner/:id/:name"
            exact
            component={withProps(checkAdminAccess(MarinaStorageInner))}
          />
          <RouteWithSeo path="/search-marina-storage" exact component={withProps(SearchMarinaStorage)} />
          <RouteWithSeo path="/show-all-marina-and-storage/:type" exact component={withProps(showAllMarinaAndStorage)} />
          <RouteWithSeo path="/marina-and-storage/similar/:id" exact component={withProps(ShowAllMarinaAndStorageSimilar)} />
          <RouteWithSeo path="/marina-storage-service-view/:serviceId/:name" exact component={withProps(MarinServicesView)} />

          {/* Boat Service */}
          <RouteWithSeo path="/boat-service" exact component={withProps(BoatServices)} />
          <RouteWithSeo path="/boat-service-inner/:id/:name" exact component={withProps(checkAdminAccess(BoatServiceInner))} />
          <RouteWithSeo path="/category-wise-boat-service/:typeId/:typeName" exact component={withProps(boatServiceView)} />
          <RouteWithSeo path="/show-all-boat-service-gallery" exact component={withProps(ShowAllBoatServiceGallery)} />
          <RouteWithSeo path="/search-boat-services" exact component={withProps(SearchBoatServices)} />
          <RouteWithSeo path="/show-all-service/:typeId" exact component={withProps(showAllService)} />

          {/* Boat Show */}
          <RouteWithSeo path="/boat-show" exact component={withProps(BoatShow)} />
          <RouteWithSeo path="/search-boat-show" exact component={withProps(SearchBoatShow)} />
          <RouteWithSeo path="/show-all-boat-show" exact component={withProps(showAllBoatShow)} />

          {/*  Articles */}
          <RouteWithSeo path="/article/:id" exact component={withProps(articleInner)} />
          <RouteWithSeo path="/article/:title/:id" exact component={withProps(articleInner)} />

          {/* Other */}
          <RouteWithSeo path="/user-profile/:id/:name" exact component={withProps(UserProfile)} />
          <RouteWithSeo path="/search-result" exact component={withProps(searchResult)} />
          <RouteWithSeo path="/show-all-auction-rooms" exact component={withProps(ShowAllAuctionRooms)} />
          <RouteWithSeo path="/contact-us" exact component={withProps(FooterContact)} />
          <RouteWithSeo path="/contact-us-suggestions" exact component={withProps(ContactUsSuggestions)} />
          <RouteWithSeo path="/contact-us-services" exact component={withProps(ContactUsServices)} />
          <RouteWithSeo path="/contact-us-complaint" exact component={withProps(ContactUsComplaint)} />
          <RouteWithSeo path="/contact-form" exact component={ContactForm} />
          <RouteWithSeo path="/help" exact component={withProps(Help)} />
          <RouteWithSeo path="/info/:pageName" exact component={withProps(DynamicPage)} />
          <RouteWithSeo path="/privacy-policy" exact component={withProps(PrivacyPolicy)} />
          <RouteWithSeo path="/market" exact component={withProps(Market)} />
          <RouteWithSeo path="/integrity-and-compliance" exact component={withProps(IntegrityCompliance)} />
          <RouteWithSeo path="/be-a-partner" exact component={withProps(BeAPartner)} />
          <RouteWithSeo path="/about-us" exact component={withProps(AboutUs)} />
          <RouteWithSeo path="/shipper-info" exact component={withProps(ShipperInfo)} />
          <RouteWithSeo path="/rent-and-charter" exact component={withProps(RentCharter)} />
          <RouteWithSeo path="/detail/broker-and-dealer" exact component={withProps(BrokerInfo)} />
          <RouteWithSeo path="/detail/builder-and-manufacturer" exact component={withProps(BuilderManufacturer)} />
          <RouteWithSeo path="/detail/boat-owner" exact component={withProps(BoatOwner)} />
          <RouteWithSeo path="/detail/surveyor" exact component={withProps(SurveyorInfo)} />
          <RouteWithSeo path="/detail/manufacturer" exact component={withProps(Manufacturer)} />
          <RouteWithSeo path="/adamsea-community" exact component={withProps(AdamseaCommunity)} />
          <RouteWithSeo path="/selling-process" exact component={withProps(SellingProcess)} />
          <RouteWithSeo path="/fraud-protection" exact component={withProps(FraudProtection)} />
          <RouteWithSeo path="/survey-your-boat" exact component={withProps(SurveyYourBoat)} />
          <RouteWithSeo path="/buying-process" exact component={withProps(BuyingProcess)} />

          {/* <Route path="/downloads" exact component={withProps(Help)} /> */}
          <RouteWithSeo path="/user-guide" exact component={withProps(UserGuide)} />
          <RouteWithSeo path="/faq" exact component={withProps(UserFaq)} />
          <RouteWithSeo path="/certify-boat" exact component={CertifyBoat} />

          {/* ------ */}
          <RouteWithSeo path="/boat-shipper/:boatId/:country" exact component={withProps(BoatShipper)} />
          <RouteWithSeo path="/boat-surveyor/:boatId/:country" exact component={withProps(BoatSurveyor)} />
          <RouteWithSeo path="/articles/:id/:name" exact component={withProps(ArticleListing)} />
          <RouteWithSeo path="/careers" exact component={withProps(Careers)} />
          <RouteWithSeo path="/career/:id" exact component={withProps(SingleJobDetail)} />
          <RouteWithSeo path="/downloads" exact component={withProps(Download)} />
          <RouteWithSeo path="/notification" exact component={withAuth(withProps(Notification))} />

          {/* Boat Media */}
          <RouteWithSeo path="/articles" exact component={withProps(BoatArticlesList)} />
          <RouteWithSeo path="/media-all-articles" exact component={withProps(BoatMediaAllArticles)} />

          <RouteWithSeo path="/boat-media" exact={!envConfig.SM_ENABLE} component={withProps(envConfig.SM_ENABLE ? SM : boatMedia)} />

          {/* Sales Engine */}
          <RouteWithSeo path="/sales-engine-process/:id" exact component={withAuth(withProps(SalesEngineProcess))} />
          <RouteWithSeo path="/shipper-sales-engines/:id" exact component={withAuth(withProps(SalesEngineShipperDetail))} />
          <RouteWithSeo path="/boat-sales-engine/:boatId" exact component={withAuth(withProps(SalesEngineByBoats))} />
          <RouteWithSeo path="/shipment-document/:id" exact component={withAuth(withProps(ShipmentDocument))} />

          {/* Global Search */}
          <RouteWithSeo path="/search" exact component={withProps(GlobalSearch)} />

          {/* Footer Links */}
          <RouteWithSeo path="/show-all-footer-link/:type" exact component={withProps(FooterLinksCommonComponent)} />
          <RouteWithSeo path="/sitemap" exact component={withProps(Sitemap)} />

          {/* 404 page  */}
          <Route path="*" component={withProps(PageNotFound)} />
        </Switch>
      </ScrollToTop>
    </Router>
  )
}
