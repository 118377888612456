import React from 'react'
import IconDownloadNew from '../../assets/images/salesEngine/download'
import './Document.scss'

export const DownloadComponent = props => {
  const { documentName, documentClassName, onCLick } = props
  return (
    <div className={` ${documentClassName} download--agreement--section cursor-pointer`} onClick={onCLick}>
      <span className="download--agreement--div">
        {documentName}
        <div className="download--document--icon--div">
          <IconDownloadNew className="download--document--icon" />
        </div>
      </span>
    </div>
  )
}
