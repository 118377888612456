import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form, ErrorMessage } from 'formik'
import { Grid, Card, RadioGroup, FormControlLabel, FormControl, FormLabel, Radio } from '@material-ui/core'

import ErrorFocus from '../ws/ErrorFocus'
import { Field } from '../ws/Field'
import {
  feedbackSubTopicOptions,
  feedbackSiteOptions,
  feedbackTopicOptions,
  feedBackMemberOption,
} from '../../helpers/jsxHelper'
import { CommonModal } from '../modal/commonModal'
import { requireMessage } from '../../helpers/string'

import './FeedBackForm.scss'
import { ButtonComponent } from '../form/Button'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { Link } from 'react-router-dom'

export const FeedBackForm = props => {
  const { open, closeModal, submitValues } = props

  const [feedback] = useState({
    isAdamSeaMember: '',
    feedBackAboutSite: '',
    feedBackTopic: '',
    feedSubTopic: '',
    description: '',
    user: '',
  })

  const renderRadioButtons = (options, feedbackTopic = '') => {
    const option = options(feedbackTopic)
    return option.map(item => {
      return (
        <div className="feedback--emoji">
          {item.image && (
            <div className="feedback--emoji--img">
              <img loading="lazy" src={item.image} className="h-100 w-100" alt="adamsea" />
            </div>
          )}
          <div className="feedback--emoji--title">
            <FormControlLabel control={<Radio value={item.id} />} label={item.value} />
          </div>
        </div>
      )
    })
  }

  const userId = getLocalStorageItem('userId')

  return (
    <>
      <CommonModal
        open={open}
        close={closeModal}
        title="Feedback"
        className="feed--back--content inner--feedback--from--section"
      >
        <Formik
          initialValues={feedback}
          onSubmit={values => {
            values.isAdamSeaMember = values.isAdamSeaMember === 'Yes' ? true : false

            if (userId) {
              values.user = userId
            }

            submitValues(values)
            closeModal()
          }}
          validationSchema={Yup.object().shape({
            isAdamSeaMember: Yup.string().required(requireMessage()),
            feedBackAboutSite: Yup.string().required(requireMessage()),
            feedBackTopic: Yup.string().required(requireMessage()),
            feedSubTopic: Yup.string().required(requireMessage()),
          })}
          render={({ setFieldValue, values, handleSubmit }) => (
            <Form className="feedback--form">
              <div className="feed--back--form">
                <ErrorFocus />
                <Card className="review-form p-0 box-shadow-none">
                  <Grid container className="p-0">
                    <Grid item sm={12} className="feedback--form--subtitle--div">
                      <div className="ant-form-item-required feedback--form--subtitle" title="Rating">
                        AdamSea would appreciate your time by completing the survey below.
                      </div>
                    </Grid>
                    <Grid item sm={12} className="gutter-box mt-10 feedback--div d-flex flex-column">
                      <FormControl component="fieldset">
                        <FormLabel className="addMarinaStorage-info feedback--label required" component="legend">
                          1. Are you a AdamSea User?
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label="1. Are you a AdamSea User?"
                          name="isAdamSeaMember"
                          value={values.isAdamSeaMember}
                          className="radioButton"
                          onChange={e => {
                            setFieldValue('isAdamSeaMember', e.target.value)
                          }}
                        >
                          <div className="radio-button-type-font d-flex">{renderRadioButtons(feedBackMemberOption)}</div>
                        </RadioGroup>
                      </FormControl>
                      <ErrorMessage className="error-message" name={`isAdamSeaMember`} component="div" />
                      <hr className="feedback--form--hr" />
                      <FormControl component="fieldset">
                        <FormLabel className="addMarinaStorage-info feedback--label required" component="legend">
                          2. How Do You Feel About Our Site?
                        </FormLabel>
                        <RadioGroup
                          row
                          placeholder="2. How Do You Feel About Our Site?"
                          aria-label="2. How Do You Feel About Our Site?"
                          name="feedBackAboutSite"
                          value={values.feedBackAboutSite}
                          className="radioButton emoji--feedback"
                          onChange={e => {
                            setFieldValue('feedBackAboutSite', e.target.value)
                          }}
                        >
                          <div className="radio-button-type-font d-flex feedback--emoji--section">
                            {renderRadioButtons(feedbackSiteOptions)}
                          </div>
                        </RadioGroup>
                      </FormControl>
                      <ErrorMessage className="error-message" name={`feedBackAboutSite`} component="div" />
                      <hr className="feedback--form--hr" />
                      <FormControl component="fieldset">
                        <FormLabel className="addMarinaStorage-info feedback--label required" component="legend">
                          3. Select Your Feedback Topic?
                        </FormLabel>
                        <RadioGroup
                          row
                          placeholder="3. Select Your Feedback Topic?"
                          aria-label="3. Select Your Feedback Topic?"
                          name="feedBackTopic"
                          value={values.feedBackTopic}
                          className="radioButton"
                          onChange={e => {
                            setFieldValue('feedBackTopic', e.target.value)
                          }}
                        >
                          <div className="radio-button-type-font d-flex">{renderRadioButtons(feedbackTopicOptions)}</div>
                        </RadioGroup>
                      </FormControl>
                      <ErrorMessage className="error-message" name={`feedBackTopic`} component="div" />
                      {values.feedBackTopic && (
                        <div className="feedback--radio mt-2">
                          <FormControl component="fieldset">
                            <FormLabel className="addMarinaStorage-info feedback--label required" component="legend">
                              And (Select Sub Topic)
                            </FormLabel>
                            <RadioGroup
                              row
                              placeholder="And (Select Sub Topic)"
                              aria-label="And (Select Sub Topic)"
                              name="feedSubTopic"
                              value={values.feedSubTopic}
                              className="radioButton"
                              onChange={e => {
                                setFieldValue('feedSubTopic', e.target.value)
                              }}
                            >
                              <div className="radio-button-type-font d-flex flex-wrap">
                                {renderRadioButtons(feedbackSubTopicOptions, values.feedBackTopic)}
                              </div>
                            </RadioGroup>
                          </FormControl>
                          <ErrorMessage className="error-message" name={`feedSubTopic`} component="div" />
                        </div>
                      )}

                      {values.feedBackTopic && values.feedSubTopic && (
                        <>
                          <Field
                            label="Description"
                            id={'description'}
                            name={'description'}
                            type="textarea"
                            value={values.description}
                            onChangeText={e => {
                              setFieldValue('description', e.target.value)
                            }}
                          />
                          <ErrorMessage className="error-message" name={`description`} component="div" />
                        </>
                      )}
                    </Grid>

                    <div className="w-100 float-left text-center">
                      <Grid item sm={12} className="d-flex justify-content-between feedback--from--btn">
                        <ButtonComponent className="save--form--outline--btn as--submit--outline h-auto ml-0" type="submit">
                          {' '}
                          SUBMIT
                        </ButtonComponent>

                        <ButtonComponent
                          className=" h-auto as--cancel--outline cancel--form--outline--btn"
                          onClick={closeModal}
                        >
                          CANCEL
                        </ButtonComponent>
                      </Grid>
                    </div>
                  </Grid>
                </Card>
              </div>
              {/* <hr className="mb-0"/> */}
              <div className="feed--back--form feedback--form--note">
                <p className="mt-2 feed--back-note">
                  {' '}
                  If you would like a response to your feedback please <a href="/contact-us-suggestions">Contact Us</a>, as we are unable to respond
                  to comments posted here
                </p>
              </div>
            </Form>
          )}
        />
      </CommonModal>
    </>
  )
}
