import React, { useContext, useState, useEffect } from 'react'

import { UserReview } from '../userProfile/userReview'

import { CommonModal } from '../modal/commonModal'

const UserProfileAllReviews = props => {
  const { reviews, close, open } = props
  return (
    <CommonModal
      open={open}
      close={close}
      title="All Reviews"
      className="notify-content-override user-profile--review w-90 user-all-reviews"
    >
      {reviews.reviews.map((item, index) => {
        return <UserReview key={item.id} value={item} isBorder={reviews.reviews.length - 1 === index} />
      })}
    </CommonModal>
  )
}

export default UserProfileAllReviews
