import { useContext, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { chatToggle, createChatById } from '../../redux/actions/chatAction'
import UserContext from '../../UserContext'
import { v4 as uuid } from 'uuid'
import { CommonTooltip } from '../CommonTooltip'
import { chatType } from '../../util/chat'
import VerificationModal from '../../containers/verificationModal'
import LoginModal from '../modal/loginModal'
import { chatLIcon, iconBoldImages } from '../../util/enums/enums'
import { toggleCookiePolicyModal } from '../../redux/actions'

function ChatUserCreate({ id ,image, chatTooltipContent, onChatClick, noFriendIcon,cookiePolicyStatus, toggleCookiePolicyModal,bookRentCard}) {
  const dispatch = useDispatch()
  const {
    addOrRemoveFriend,
    currentUser: { friends },
  } = useContext(UserContext)

  const currentUser = useSelector(state => state.loginReducer.currentUser)

  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)
  const [loginModal, setLoginModal] = useState(false)

  useEffect(() => {
    isVerificationModalOpen && setIsVerificationModalOpen(false)
  }, [isVerificationModalOpen])

  const isFriend = friends?.map(friend => (typeof friend === 'object' ? friend?.id : friend)).includes(id)

  const handlerLoginModal = () => setLoginModal(!loginModal)

  const handleAddRemoveFriend = () => {
    id && addOrRemoveFriend(id)
  }

  return (
    <>
     <LoginModal open={loginModal} close={handlerLoginModal} />
      <CommonTooltip component={ctProps => (
        <img
          data-tooltip-id={ctProps.id}
          data-tooltip-content={chatTooltipContent || "Chat"}
          src={chatLIcon}
          className="user-profile-social-icon-div cursor-pointer"
          alt="Chat"
          onClick={() => {
            onChatClick && onChatClick();

            if(!currentUser?.id){
              handlerLoginModal()
              return
            }
            if (
              !currentUser?.documentVerification?.emailVerified ||
              !currentUser?.documentVerification?.mobileVerified
            ) {
              setIsVerificationModalOpen(true)
            } else if (cookiePolicyStatus !== 1) {
              toggleCookiePolicyModal(true)
            } else if (id) {
              dispatch(createChatById({
                members: [id] ,
                imageUrl:image??"",
               description:"",
               channelUrl:uuid(),
               chatType: chatType.normalchat}))

               dispatch(chatToggle(true))
            }
          }}
        />
      )} />
      <VerificationModal open={isVerificationModalOpen} content="before starting chat with someone" />
      {bookRentCard && <div style={{ height: 1, margin: '10px 0', border: '1px solid #0003' }} />}
      {noFriendIcon || <CommonTooltip component={ctProps => (
        <img
          data-tooltip-id={ctProps.id}
          data-tooltip-content={isFriend ? 'Remove from my list' : 'Add to my list'}
          src={isFriend ? require('../userProfile/image/Add_WhiteOnBlue.png') : iconBoldImages.userPlus}
          className="user-profile-social-icon-div cursor-pointer"
          onClick={handleAddRemoveFriend}
          alt={isFriend ? 'Remove from my list' : 'Add to my list'}
        />
      )} />}
    </>
  )
}

const mapStateToProps = state => ({
  cookiePolicyStatus: state.loginReducer.cookiePolicyStatus,
})

const mapDispatchToProps = dispatch => ({
  toggleCookiePolicyModal: data => dispatch(toggleCookiePolicyModal(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatUserCreate)
