import React, { useState } from 'react'
import { HomeRecommendedContentStyle } from '../../styleComponent/styleComponent'
import { commonBoatType, percentageFormate } from '../../../util/utilFunctions'
import SharePopup from '../../share/SharePopup'
import { cityCountryNameFormatter, getBoatTypeName, checkKayakType, checkJetSkiType } from '../../../helpers/jsxHelper'
import { viewBoatHandler } from '../../../helpers/boatHelper'
import { getConvertedPrice } from '../../../helpers/currencyConverterHelper'
import { wishlistModuleEnum, shareModule, defaultImage } from '../../../util/enums/enums'
import { WishlistComponent } from '../../wishlist/WishlistComponent'
import { CurrencyContextConsumer } from '../../../CurrencyContext'
import RatingComponent from '../../rating/Rating'

export const BoatDetail = React.memo(
  ({ value, isBottomRating, isTopLabel, index, width, height, history, gridClassName, className, margin }) => {
    const [selectedIndex, setSelectedIndex] = useState(null)

    const handleClick = index => {
      setSelectedIndex(selectedIndex !== index ? index : null)
    }

    const {
      lengthInFt,
      boatType,
      yearBuilt,
      price,
      address: [{ city, country }],
      images,
      rating,
      boatStatus: { alias },
      manufacturedBy,
      id,
      seller,
      isSalesEngineCreated,
    } = value || {}

    const boatTypeName = getBoatTypeName(value)
    const isKayakType = checkKayakType(value)
    const isJetSkiType = checkJetSkiType(value)

    return (
      <div style={{ width: width, marginRight: margin }} className={`${gridClassName} ${className}`}>
        <div className={`position-relative cursor-pointer zoom-grid-img-boat-cont`}>
          <div className="zoom--grid--img--boat">
            <HomeRecommendedContentStyle
              bgWidth={width}
              id="zoom-grid-img-height-static"
              bgHeight={height}
              onClick={() => viewBoatHandler(value)}
              img={(images && images.length > 0 && encodeURI(images[0]?.url)) || defaultImage}
            />
            {isSalesEngineCreated && <span className="brand-new-tag boat--with--map--used under--selling">Under Selling</span>}
            <div className={`${isTopLabel ? 'card-action justify-content-between' : 'card-action'}`}>
              {isTopLabel && <div>{commonBoatType(alias)}</div>}
              <div className="share-icon">
                <WishlistComponent moduleId={id} moduleType={wishlistModuleEnum.BOAT} />
                {/* <SharePopup
                  handleClick={() => handleClick(index)}
                  index={index}
                  selectedIndex={selectedIndex}
                  moduleId={id}
                  moduleType={shareModule.BOAT}
                /> */}
              </div>
            </div>
          </div>

          <div className="boat-info-grid mt-3 px-2 d-flex justify-content-between">
            <div className={!isBottomRating && 'price-with-rating'}>
              {!isBottomRating && (
                <div className="d-flex align-center flex-none">
                  <div className="mr-1">
                    <RatingComponent rating={rating} className="rating-clr" />
                  </div>
                  {/* <span className="rating-count">({percentageFormate(rating)})</span> */}
                </div>
              )}
              <div className="new-boat-label">
                {!isTopLabel && (
                  <div className="d-flex align-items-center used-new-build-font-line-height flex-none">
                    {commonBoatType(alias)}
                  </div>
                )}
                <CurrencyContextConsumer>
                  {({ currentCurrency }) => (
                    <h6 className="mb-0 home-boat-price">{getConvertedPrice(price, currentCurrency)}</h6>
                  )}
                </CurrencyContextConsumer>
              </div>
            </div>
            <h5
              className="place-city boat-name place-city-text-wrap-inner-page"
              style={{
                webkitBoxOrient: 'vertical',
              }}
            >
              {yearBuilt} {boatTypeName}
              {!isKayakType && !isJetSkiType && <> <span className="bullet" /> {lengthInFt} ft</>}
            </h5>
            <h5 className="place-city place--city--color place-city-text-wrap-inner-page"
             style={{
              webkitBoxOrient: 'vertical',
            }}
            >
              <span>{cityCountryNameFormatter(city, country)}</span>
            </h5>
            <div>
              <h6 className={`manufactured-by manufactured--by--fix--color ${!isBottomRating ? 'mb-0' : ''}`}>
                <span>by</span> <span className="text-capitalize">{manufacturedBy?.alias || seller?.companyName}</span>
              </h6>
            </div>
            {isBottomRating && (
              <div className="grid-boat-rating-layout align-center">
                <div className="mr-1">
                  <RatingComponent rating={rating} className="rating-clr" />
                </div>
                {/* <span className="rating-count">({percentageFormate(rating)})</span> */}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
)

BoatDetail.defaultProps = {
  isTopLabel: false,
  isBottomRating: false,
  isCarousel: false,
  gridClassName: '',
  className: '',
}
