import { get } from 'lodash'
import { emptyState } from '../../util/enums/enums'
import {
  GET_ALL_PAGE_INFORMATION_BY_TYPE,
  GET_ALL_PAGE_INFORMATION_BY_TYPE_SUCCESS,
  GET_ALL_PAGE_INFORMATION_BY_TYPE_FAILURE,
  CLEAR_PAGE_INFO_BY_TYPE_FLAG,
  GET_RENT_PAGES,
  GET_RENT_PAGES_SUCCESS,
  GET_RENT_PAGES_FAILURE,
  CLEAR_RENT_PAGES_FLAG,
  GET_SALES_ENGINE_PAGES,
  GET_SALES_ENGINE_PAGES_SUCCESS,
  GET_SALES_ENGINE_PAGES_FAILURE,
  GET_AGREEMENT,
  GET_AGREEMENT_SUCCESS,
  GET_AGREEMENT_FAILURE,
  CLEAR__GET_AGREEMENT,
  GET_STATIC_PAGES,
  GET_STATIC_PAGES_SUCCESS,
  GET_STATIC_PAGES_FAILURE,
  CLEAR__GET_STATIC_PAGES,
} from '../actionTypes'

const InitialState = {
  pageInfoByType: [],
  success: false,
  error: false,
  rentPages: null,
  rentPagesSuccess: null,
  rentPagesFailure: null,
  isModelSuccess: false,
}

export const pageInfoByTypeReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_ALL_PAGE_INFORMATION_BY_TYPE:
      return {
        ...state,
        success: false,
        error: false,
      }

    case GET_ALL_PAGE_INFORMATION_BY_TYPE_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        isModelSuccess: action.isModel,
        pageInfoByType: action.payload.data.getPagesByTitle,
        isLargeModal: action.isLargeModal || false,
      }

    case GET_ALL_PAGE_INFORMATION_BY_TYPE_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }

    case CLEAR_PAGE_INFO_BY_TYPE_FLAG:
      return {
        ...state,
        success: false,
        error: false,
        isModelSuccess: false,
      }

    case CLEAR_PAGE_INFO_BY_TYPE_FLAG:
      return {
        ...state,
        isLargeModal: false,
      }

    case GET_RENT_PAGES:
      return {
        ...state,
        rentPagesSuccess: false,
        rentPagesFailure: false,
      }

    case GET_RENT_PAGES_SUCCESS:
      return {
        ...state,
        rentPagesSuccess: true,
        rentPagesFailure: false,
        rentPages: action.payload,
      }

    case GET_RENT_PAGES_FAILURE:
      return {
        ...state,
        rentPagesSuccess: false,
        rentPagesFailure: true,
      }

    case GET_SALES_ENGINE_PAGES:
      return {
        ...state,
        salesEnginePagesSuccess: false,
        salesEnginePagesFailure: false,
      }

    case GET_SALES_ENGINE_PAGES_SUCCESS:
      return {
        ...state,
        salesEnginePagesSuccess: true,
        salesEnginePagesFailure: false,
        salesEnginePages: action.payload,
      }

    case GET_SALES_ENGINE_PAGES_FAILURE:
      return {
        ...state,
        salesEnginePagesSuccess: false,
        salesEnginePagesFailure: true,
      }

    case CLEAR_RENT_PAGES_FLAG:
      return {
        ...state,
        rentPagesSuccess: false,
        rentPagesFailure: false,
      }

    case GET_AGREEMENT: {
      return {
        ...state,
        agreement: {
          loading: true,
          success: false,
          failure: false,
          data: get(state, 'agreement.data', null),
        },
      }
    }

    case GET_AGREEMENT_SUCCESS: {
      return {
        ...state,
        agreement: {
          loading: true,
          success: true,
          failure: false,
          data: action.payload,
        },
      }
    }

    case GET_AGREEMENT_FAILURE: {
      return {
        ...state,
        agreement: {
          loading: true,
          success: false,
          failure: true,
          data: get(state, 'agreement.data', null),
        },
      }
    }

    case CLEAR__GET_AGREEMENT: {
      return {
        ...state,
        agreement: {
          loading: false,
          success: false,
          failure: false,
          data: get(state, 'agreement.data', null),
        },
      }
    }

    case GET_STATIC_PAGES: {
      return {
        ...state,
        staticPages: {
          loading: true,
          success: false,
          failure: false,
          data: get(state, 'staticPages.data', null),
        },
      }
    }

    case GET_STATIC_PAGES_SUCCESS: {
      return {
        ...state,
        staticPages: {
          loading: true,
          success: true,
          failure: false,
          data: action.payload,
        },
      }
    }

    case GET_STATIC_PAGES_FAILURE: {
      return {
        ...state,
        staticPages: {
          loading: true,
          success: false,
          failure: true,
          data: get(state, 'staticPages.data', null),
        },
      }
    }

    case CLEAR__GET_STATIC_PAGES: {
      return {
        ...state,
        staticPages: {
          loading: false,
          success: false,
          failure: false,
          data: get(state, 'staticPages.data', null),
        },
      }
    }

    default:
      return state
  }
}
