import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getAllBoatByUser,
  toggleBoatStatus,
  deleteBoat,
  clearExistingBoat,
  createAuctionRoom,
  clearAuctionFlag,
  clearUpdateBoatFlag,
  clearErrorMessageShow,
  searchInDashboard,
  clearSearchDashboard,
} from '../../../redux/actions'
import { verifiedCheck, readableString } from '../../../helpers/string'
import { viewBoatHandler } from '../../../helpers/boatHelper'
import { SuccessNotify } from '../../../helpers/notification'
import { confirmSubmitHandler } from '../../../helpers/confirmationPopup'

import '../../../styles/common.scss'
import { DashboardLayout } from '../../../components/layout/dashboardLayout'
import { pagination, manageBoatAuctionStatus, auctionRoomAccessibleRoles, boatType, userRoles } from '../../../util/enums/enums'
import './AddBoat.scss'
import '../../../styles/manageDashboardTableResponsive.scss'
import { getConvertedPrice } from '../../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../../CurrencyContext'
import { TableCard, Field } from '../../../components'
import { CreateAuctionRoomModal } from '../../../components/modal/createAuctionRoomModal'
import { ButtonComponent } from '../../../components/form/Button'
import { formattedDate } from '../../../helpers/dateTimeHelper'
import { auctionBrokerAndDealerRestrictions, getPagesValue } from '../../../helpers/jsxHelper'
import { CommonModal } from '../../../components/modal/commonModal'
import { graphqlClient } from '../../../helpers/graphqlClient'
import { gql } from 'apollo-boost'

class ManageBoats extends Component {
  state = {
    auctionRoomModal: false,
    selectedBoatId: '',
    messageType: '',
    sortingInfo: [],
    isSubscriptionModalOpen: false,
  }

  static getDerivedStateFromProps(nextProps) {
    const { isBoatDeleted, auctionSuccess, history, clearAuctionFlag, clearUpdateBoatFlag, byUserSuccess } = nextProps

    if (auctionSuccess) {
      setTimeout(() => SuccessNotify('Auction room created successfully'), 100)
      clearAuctionFlag()
      history.push('/manage-auction-rooms')
    }

    if (byUserSuccess) {
      clearUpdateBoatFlag()
    }

    return null
  }

  componentDidMount() {
    const { getAllBoatByUser, clearSearchDashboard } = this.props
    clearSearchDashboard()
    getAllBoatByUser({
      page: getPagesValue(),
      limit: pagination.PAGE_RECORD_LIMIT,
    })

    this.getTimerSettings()
  }

  async getTimerSettings() {
    try {
      const res = await graphqlClient.query({
        query: gql`
          query getTimerSettings {
            getTimerSettings {
              surveyorTime
              surveyorUnit
              boatTime
              boatUnit
              auctionAddTime
              auctionAddUnit
              auctionTime
              auctionUnit
              auctionStartGapTime
              auctionStartGapUnit
            }
          }
        `,
        fetchPolicy: 'no-cache',
      })

      if (res.data.getTimerSettings) {
        this.setState({ timerSettings: res.data.getTimerSettings })
      }
    } catch (err) {
      console.error(err)
    }
  }

  handleAuctionRoomModal = (value = '') => {
    const { clearErrorMessageShow } = this.props
    clearErrorMessageShow()
    this.setState(prevState => ({ auctionRoomModal: !prevState.auctionRoomModal, selectedBoatId: value }))
  }

  handleSubscriptionModal = () => {
    this.setState(prevState => ({ isSubscriptionModalOpen: !prevState.isSubscriptionModalOpen }))
  }

  editBoatHandler = boat => {
    const { boatName, _original } = boat
    const { history, clearExistingBoat } = this.props
    clearExistingBoat()
    history.push({
      pathname: '/edit-boat',
      search: `?id=${_original.id}&name=${readableString(boatName)}`,
      state: { manageBoats_page: getPagesValue() },
    })
  }

  render() {
    const {
      userBoats,
      history,
      loading,
      deleteBoat,
      createAuctionRoom,
      isError,
      errorMessage,
      clearErrorMessageShow,
      currentUser,
      totalUserBoats,
      getAllBoatByUser,
      searchInDashboard,
      indexSearchData,
      totalSearchedData,
      clearSearchDashboard,
      isSearched,
    } = this.props

    const columns = [
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: data => (
          <span className="react--table--name--row">
            {/* {console.log(data)} */}
            {formattedDate(data.value, true)}
          </span>
        ),
      },
      {
        Header: 'Model',
        accessor: 'boatName',
        Cell: data => <span className="react--table--name--row">{data.row.boatName}</span>,
      },
      {
        Header: 'Type',
        accessor: 'boatType.name',
        headerClassName: 'manage--boat-table-ad-id',
        className: 'manage--boat-table-ad-id',
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: data => (
          <CurrencyContextConsumer>
            {({ currentCurrency }) => <span>{getConvertedPrice(data.value, currentCurrency)} </span>}
          </CurrencyContextConsumer>
        ),
      },
      {
        Header: 'Ad Status',
        accessor: 'adStatus',
        Cell: data => (
          <span className={`bg-green-color font-13 text-capitalize m-auto ${verifiedCheck(data.row.adStatus)}`}>
            {verifiedCheck(data.row.adStatus)}
          </span>
        ),
      },
      {
        Header: auctionRoomAccessibleRoles.includes(currentUser?.role?.aliasName)
          ? 'Auction'
          : `${manageBoatAuctionStatus.IN_STOCK}`,
        accessor: 'isAuctionRoomCreated',
        headerClassName: 'manage--boat-table-auction',
        className: 'manage--boat-table-auction',
        Cell: data => (
          <>
            {!data.original.adStatus ? (
              manageBoatAuctionStatus.WAITING_FOR_APPROVAL
            ) : data.original.isSalesEngineCreated ? (
              <span>
                {data.original.salesEngineStatus ? data.original.salesEngineStatus : data.original?.auctionRoom?.status}
              </span>
            ) : currentUser?.role?.aliasName === userRoles.BOAT_OWNER && data.original?.stockQuantity <= 0 ? (
              <span>Sold Out</span>
            ) : auctionBrokerAndDealerRestrictions(data.original, currentUser) &&
              auctionRoomAccessibleRoles.includes(currentUser?.role?.aliasName) ? (
              data.value ? (
                <span> {data.original?.auctionRoom?.status ?? manageBoatAuctionStatus.IN_AUCTION} </span>
              ) : data.original.winnerAssigned ? (
                <span> {manageBoatAuctionStatus.AUCTION_CLOSED} </span>
              ) : data.original.salesEngineStatus ? (
                <span>{data.original.salesEngineStatus}</span>
              ) : (
                data.original.adStatus &&
                !data.value &&
                !data.original.winnerAssigned && (
                  <div className="d-flex flex-row w-100 justify-center auction">
                    <button
                      type="button"
                      disabled={
                        data.original?.boatType?.name === boatType.superYacht ||
                        data.original?.boatType?.name === boatType.yacht
                          ? false
                          : !data.original.isEnabledForAuction
                      }
                      onClick={() =>
                        data.original.id &&
                        (data.original?.boatType?.name !== boatType.superYacht &&
                        data.original?.boatType?.name !== boatType.yacht
                          ? this.handleAuctionRoomModal(data.original.id)
                          : this.handleSubscriptionModal())
                      }
                      className={`btn ${
                        data.original?.boatType?.name !== boatType.superYacht &&
                        data.original?.boatType?.name !== boatType.yacht
                          ? 'btn-outline-primary'
                          : 'btn-outline-accent'
                      }`}
                      style={{ minWidth: 100 }}
                    >
                      {data.original?.boatType?.name === boatType.superYacht || data.original?.boatType?.name === boatType.yacht
                        ? 'Free'
                        : 'Add Auction'}
                    </button>
                  </div>
                )
              )
            ) : currentUser?.role?.aliasName === userRoles.BOAT_OWNER ||
              (currentUser?.role?.aliasName === userRoles.BROKER_AND_DEALER &&
                data.original?.boatStatus?.alias === boatType.used) ? (
              manageBoatAuctionStatus.NOT_AVAILABLE
            ) : (
              <span className="Unverified">
                {data.original.stockQuantity ? `Qty ${data.original.stockQuantity}` : manageBoatAuctionStatus.NO_STOCK}
              </span>
            )}
          </>
        ),
      },
      {
        headerClassName: 'manage--boat-table-action sales--engine--view reset-rt-th',
        className: 'manage--boat-table-action',
        Cell: data => (
          <div className="d-flex flex-row justify-content-around action">
            <ButtonComponent
              className="btn mr-2"
              color="edit"
              onClick={() => this.editBoatHandler(data && data.row)}
              disabled={
                data.original.isSalesEngineCreated ||
                data.original.isAuctionRoomCreated ||
                (currentUser?.role?.aliasName === userRoles.BOAT_OWNER && data.original?.stockQuantity <= 0)
              }
            >
              Edit
            </ButtonComponent>
            <ButtonComponent className="btn mr-2" color="view" onClick={() => viewBoatHandler(data.original)}>
              View
            </ButtonComponent>
            <ButtonComponent
              className="btn mr-2"
              color="danger"
              onClick={() =>
                confirmSubmitHandler(
                  deleteBoat,
                  data.original.id,
                  undefined,
                  'Are you sure you want to delete this ad? If you choose to delete, it cannot be retrieved again.'
                )
              }
              disabled={data.original.isSalesEngineCreated || data.original.isAuctionRoomCreated}
            >
              Delete
            </ButtonComponent>
          </div>
        ),
      },
    ]

    const { auctionRoomModal, selectedBoatId, isSubscriptionModalOpen, sort } = this.state

    return (
      <DashboardLayout>
        <CommonModal
          open={isSubscriptionModalOpen}
          close={() => this.handleSubscriptionModal()}
          hideCloseIcon
          title="Yacht Listing Pricing Update"
        >
          <div className="email--verification--desc--div">
            <div>
              <p className="email--verification--desc">
                All posts of super yacht and yacht listings will be available as free listings on AdamSea. This feature allows
                you to create a yacht profile without any additional listing fees.
              </p>
            </div>
          </div>
        </CommonModal>
        <CreateAuctionRoomModal
          open={auctionRoomModal}
          close={this.handleAuctionRoomModal}
          selectedBoatId={selectedBoatId}
          timerSettings={this.state.timerSettings}
          createAuctionRoom={createAuctionRoom}
          isError={isError}
          errorMessage={errorMessage}
          clearErrorMessageShow={clearErrorMessageShow}
          loading={this.props.isLoading}
        />

        {columns && (
          <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table">
            <TableCard
              isSearch
              title="Manage Boats"
              button={{ name: 'Add Boat', url: 'add-boat' }}
              buttonLg
              columns={columns}
              data={isSearched ? indexSearchData : userBoats}
              loading={loading}
              className="manage--boat--table manage--table--section--alignment manage--boats--table--style"
              total={isSearched ? totalSearchedData : totalUserBoats}
              action={getAllBoatByUser}
              searchData={searchInDashboard}
              searchType={'BOAT'}
              searchResultKey={'boats'}
              clearSearchDashboard={clearSearchDashboard}
            />
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.boatReducer.loading,
  isLoading: state.boatReducer.isLoading,
  userBoats: state.boatReducer.userBoats,
  isBoatDeleted: state.boatReducer.isBoatDeleted,
  auctionSuccess: state.boatReducer && state.boatReducer.auctionSuccess,
  isError: state.boatReducer.isError,
  byUserSuccess: state.boatReducer.byUserSuccess,
  errorMessage: state.errorReducer.errorMessage,
  currentUser: state.loginReducer.currentUser,
  totalUserBoats: state.boatReducer.totalUserBoats,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
  isSearched: state?.dashboardReducer?.isSearched,
})

const mapDispatchToProps = dispatch => ({
  getAllBoatByUser: data => dispatch(getAllBoatByUser(data)),
  toggleBoatStatus: data => dispatch(toggleBoatStatus(data)),
  deleteBoat: data => dispatch(deleteBoat(data)),
  clearExistingBoat: () => dispatch(clearExistingBoat()),
  createAuctionRoom: data => dispatch(createAuctionRoom(data)),
  clearAuctionFlag: () => dispatch(clearAuctionFlag()),
  clearUpdateBoatFlag: () => dispatch(clearUpdateBoatFlag()),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  searchInDashboard: data => dispatch(searchInDashboard(data)),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageBoats)
