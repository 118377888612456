import React, { Component } from 'react'
import { connect } from 'react-redux'
import { resultMessage, pagination } from '../../util/enums/enums'
import UserContext from '../../UserContext'
import BoatListingsWithMap from '../../components/gridComponents/BoatListingsWithMap'
import { getMoreServices, getTypeWiseLookup, searchMarinaStorage } from '../../redux/actions'
import { urlParamsToObject } from '../../helpers/routeHelper'

class SearchMarinaStorage extends Component {
  async componentDidMount() {
    this.props.getTypeWiseLookup('You are An')
    this.props.getMoreServices({ isAdmin: false })
  }

  render() {
    const {
      searchedMarinaStorage,
      totalSearchedMarinaStorage,
      searchMarinaStorage,
      isPageLoader,
      location,
      marinaTypeWiseLookUps,
    } = this.props

    const urlParams = new URLSearchParams(location.search)
    const { address, ...values } = urlParamsToObject(urlParams)
    const { serviceProviderAlias, providerAlias, ...otherValues } = values

    const title = `${totalSearchedMarinaStorage || 0} ${serviceProviderAlias ? serviceProviderAlias : providerAlias} in ${
      values.country
    } `

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        boatsType={title}
        boatsTypeCount={totalSearchedMarinaStorage || 0}
        isMarinaStorage
        boats={searchedMarinaStorage}
        message={resultMessage.search}
        action={searchMarinaStorage}
        value={otherValues}
      />
    )
  }
}

const mapStateToProps = state => ({
  searchedMarinaStorage: state.marinaAndStorageReducer.searchedMarinaStorage,
  isPageLoader: state.marinaAndStorageReducer.isPageLoader,
  totalSearchedMarinaStorage: state.marinaAndStorageReducer.totalSearchedMarinaStorage,
  marinaTypeWiseLookUps: state.marinaAndStorageReducer.marinaTypeWiseLookUps,
})

const mapDispatchToProps = dispatch => ({
  searchMarinaStorage: data => dispatch(searchMarinaStorage(data)),
  getTypeWiseLookup: data => dispatch(getTypeWiseLookup(data)),
  getMoreServices: data => dispatch(getMoreServices(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchMarinaStorage)
