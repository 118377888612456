import { put, takeLatest, all, takeEvery } from 'redux-saga/effects'
import {
  GET_ALL_BOAT_LOOKUPS,
  GET_ALL_BOAT_LOOKUPS_SUCCESS,
  GET_ALL_BOAT_LOOKUPS_FAILURE,
  ADD_BOAT,
  ADD_BOAT_FAILURE,
  ADD_BOAT_SUCCESS,
  GET_ALL_BOATS_BY_USER,
  GET_ALL_BOATS_BY_USER_SUCCESS,
  GET_ALL_BOATS_BY_USER_FAILURE,
  SEARCH_BOAT,
  SEARCH_BOAT_SUCCESS,
  SEARCH_BOAT_FAILURE,
  GET_BOAT_TYPE_SUCCESS,
  GET_BOAT_TYPE_FAILURE,
  GET_BOAT_TYPE,
  RECENT_SEARCH,
  RECENT_SEARCH_SUCCESS,
  RECENT_SEARCH_FAILURE,
  MULTI_SEARCH,
  MULTI_SEARCH_SUCCESS,
  MULTI_SEARCH_FAILURE,
  GET_SINGLE_BOAT,
  GET_SINGLE_BOAT_SUCCESS,
  GET_SINGLE_BOAT_FAILURE,
  TOGGLE_BOAT_STATUS,
  TOGGLE_BOAT_STATUS_SUCCESS,
  TOGGLE_BOAT_STATUS_FAILURE,
  DELETE_BOAT,
  DELETE_BOAT_SUCCESS,
  DELETE_BOAT_FAILURE,
  GET_BOAT_BY_TYPE,
  GET_BOAT_BY_TYPE_SUCCESS,
  GET_BOAT_BY_TYPE_FAILURE,
  GET_LATEST_BOATS,
  GET_LATEST_BOATS_SUCCESS,
  GET_LATEST_BOATS_FAILURE,
  GET_POPULAR_BOATS,
  GET_POPULAR_BOATS_SUCCESS,
  GET_POPULAR_BOATS_FAILURE,
  GET_TOP_RATED_BOATS,
  GET_TOP_RATED_BOATS_SUCCESS,
  GET_TOP_RATED_BOATS_FAILURE,
  UPDATE_BOAT,
  UPDATE_BOAT_SUCCESS,
  UPDATE_BOAT_FAILURE,
  CLEAR_BOAT_ADD_FLAG,
  CLEAR_BOAT_UPDATE_FLAG,
  GET_CITY_WISE_BOATS,
  GET_CITY_WISE_BOATS_SUCCESS,
  GET_CITY_WISE_BOATS_FAILURE,
  GET_CATEGORY_WISE_BOATS,
  GET_CATEGORY_WISE_BOATS_SUCCESS,
  GET_CATEGORY_WISE_BOATS_FAILURE,
  CREATE_AUCTION_ROOM_SUCCESS,
  CREATE_AUCTION_ROOM_FAILURE,
  CREATE_AUCTION_ROOM,
  GET_USER_AUCTION_ROOMS,
  GET_USER_AUCTION_ROOMS_SUCCESS,
  GET_USER_AUCTION_ROOMS_FAILURE,
  GET_ALL_AUCTION_BID,
  GET_ALL_AUCTION_BID_SUCCESS,
  GET_ALL_AUCTION_BID_FAILURE,
  GET_ALL_AUCTION_ROOMS,
  GET_ALL_AUCTION_ROOMS_SUCCESS,
  GET_ALL_AUCTION_ROOMS_FAILURE,
  CREATE_AUCTION_BID,
  CREATE_AUCTION_BID_SUCCESS,
  CREATE_AUCTION_BID_FAILURE,
  ERROR_MESSAGE_SHOW,
  BOAT_BY_CITIES,
  BOAT_BY_CITIES_SUCCESS,
  BOAT_BY_CITIES_FAILURE,
  GET_BOAT_SEARCH_MINIMUM_VALUE,
  GET_BOAT_SEARCH_MINIMUM_VALUE_SUCCESS,
  GET_BOAT_SEARCH_MINIMUM_VALUE_FAILURE,
  CLEAR_SEARCH_BOAT_FLAG,
  GET_BOAT_COST_ESTIMATION,
  GET_BOAT_COST_ESTIMATION_FAILURE,
  GET_BOAT_COST_ESTIMATION_SUCCESS,
  MAIL_BOAT_COST_ESTIMATION,
  MAIL_BOAT_COST_ESTIMATION_SUCCESS,
  MAIL_BOAT_COST_ESTIMATION_FAILURE,
  GET_AUCTION_ROOM_BY_BID_USER,
  GET_AUCTION_ROOM_BY_BID_USER_SUCCESS,
  GET_AUCTION_ROOM_BY_BID_USER_FAILURE,
  GET_AUCTION_BY_BOAT_ID,
  GET_AUCTION_BY_BOAT_ID_SUCCESS,
  GET_AUCTION_BY_BOAT_ID_FAILURE,
  CLEAR_FLAG_AUCTION_BID,
  NOTIFICATION_MESSAGE,
  GET_USERS_BY_COUNTRY,
  GET_USERS_BY_COUNTRY_SUCCESS,
  GET_USERS_BY_COUNTRY_FAILURE,
  GET_BOAT_CONVERTED_COST_ESTIMATION,
  GET_BOAT_CONVERTED_COST_ESTIMATION_SUCCESS,
  GET_BOAT_CONVERTED_COST_ESTIMATION_FAILURE,
  ERROR_NOTIFICATION_MESSAGE,
  GET_AUCTION_SALES_ENGINE,
  GET_AUCTION_SALES_ENGINE_SUCCESS,
  GET_AUCTION_SALES_ENGINE_FAILURE,
  CLEAR_AUCTION_SALES_ENGINE,
  CHECK_FOR_QUANTITY_QUERY,
  CHECK_FOR_QUANTITY_QUERY_SUCCESS,
  CHECK_FOR_QUANTITY_QUERY_FAILURE,
  ADD_DEPOSIT_FOR_BID,
  ADD_DEPOSIT_FOR_BID_SUCCESS,
  ADD_DEPOSIT_FOR_BID_FAILURE,
  CLEAR_ADD_DEPOSIT_FOR_BID,
  GET_ALL_FOOTER_LINK,
  GET_ALL_FOOTER_LINK_FAILURE,
  GET_ALL_FOOTER_LINK_SUCCESS,
  GET_YACHTS,
  GET_YACHTS_SUCCESS,
  GET_YACHTS_FAILURE,
} from '../actionTypes'
import { graphqlClient } from '../../helpers/graphqlClient'
import {
  getBoatLookUps,
  addBoat,
  searchBoat,
  globalBoatSearch,
  getAllBoatByUser,
  getBoatTypes,
  getRecentSearch,
  multiSearch,
  editBoat,
  boatChangeStatus,
  deleteBoat,
  getBoatsByType,
  getLatestBoats,
  getMostPopularBoats,
  getTopRatedBoats,
  updateBoat,
  getCityWiseBoats,
  categoryWiseBoats,
  createAuctionRoom,
  getUserAuctionRooms,
  getAuctionAllBids,
  getAllAuctionRooms,
  createAuctionBidQuery,
  boatByCitiesSchema,
  getBoatsSearchValues,
  getCostEstimation,
  sendBoatCostEstimationPdf,
  getAuctionByBidUser,
  getBoatAuctionRoom,
  getUsersByCountryQuety,
  getBoatConvertedCostEstimation,
  getAuctionSalesEngine,
  checkForQuantityQuery,
  makeAuctionPayment,
  footerLinkBoats,
  editBoat_seller,
  getYachtsQuery,
} from '../../graphql/boatSchema'
import { createAuctionBid } from '../actions'
import { dynamicError, notificationEnum } from '../../util/enums/notificationEnum'
import { mutation, query } from './apiHelper'

function getBoatLookUpCall() {
  return graphqlClient
    .query({
      query: getBoatLookUps,
    })
    .then(res => res)
    .catch(error => {
      throw error
    })
}

function boatByCitiesApi(input) {
  return graphqlClient
    .query({
      query: boatByCitiesSchema,
      variables: input,
    })
    .then(res => res)
    .catch(error => error)
}

function saveBoatCall(input) {
  return graphqlClient
    .mutate({
      mutation: addBoat,
      variables: {
        boat: input,
      },
    })
    .then(res => res)
    .catch(e => {
      throw e.networkError.result.errors
    })
}

function getAllBoatsApi(input) {
  return graphqlClient
    .query({
      query: getAllBoatByUser,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function getAllFooterLinkApi(input) {
  return graphqlClient
    .query({
      query: footerLinkBoats,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function searchBoatApi(input) {
  return graphqlClient
    .mutate({
      mutation: searchBoat,
      variables: { input },
    })
    .then(res => res)
    .catch(error => error)
}

function getBoatTypesApi(input) {
  return graphqlClient
    .query({
      query: getBoatTypes,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function recentSearchApi() {
  return graphqlClient
    .query({
      query: getRecentSearch,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function multipleSearchApi(input) {
  return graphqlClient
    .query({
      query: multiSearch,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

export function getSingleBoatApi(input) {
  return graphqlClient
    .query({
      query: editBoat,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

export function getSingleBoatSellerApi(input) {
  return graphqlClient
    .query({
      query: editBoat_seller,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function getUsersByCountryApi(input) {
  return graphqlClient
    .query({
      query: getUsersByCountryQuety,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function deleteSingleBoatApi(input) {
  return graphqlClient
    .mutate({
      mutation: deleteBoat,
      variables: { id: input },
    })
    .then(res => res)
    .catch(error => error?.networkError?.result)
}

function updateExistingBoatApi(input) {
  return graphqlClient
    .mutate({
      mutation: updateBoat,
      variables: { input },
    })
    .then(res => res)
    .catch(e => {
      throw e.networkError.result.errors
    })
}

function toggleBoatStatusApi(input) {
  return graphqlClient
    .mutate({
      mutation: boatChangeStatus,
      variables: input,
    })
    .then(res => res)
    .catch(error => error)
}

function getBoatByTypeApi(input) {
  return graphqlClient
    .query({
      query: getBoatsByType,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function getLatestBoatApi(input) {
  return graphqlClient
    .query({
      query: getLatestBoats,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function getPopularBoatApi(input) {
  return graphqlClient
    .query({
      query: getMostPopularBoats,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function getTopRatedBoatApi(input) {
  return graphqlClient
    .query({
      query: getTopRatedBoats,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function getCityWiseBoatApi(input) {
  return graphqlClient
    .query({
      query: getCityWiseBoats,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function getCategoryWiseBoatApi(input) {
  return graphqlClient
    .query({
      query: categoryWiseBoats,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function createAuctionRoomApi(auctionRoom) {
  return graphqlClient
    .mutate({
      mutation: createAuctionRoom,
      variables: { auctionRoom },
    })
    .then(res => res)
    .catch(err => err?.networkError?.result)
}
function getUserAuctionRoomApi(data) {
  return graphqlClient
    .query({
      query: getUserAuctionRooms,
      variables: data,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => {
      throw err
    })
}
function getAllAuctionBidsApi(data) {
  return graphqlClient
    .query({
      query: getAuctionAllBids,
      variables: data,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => {
      throw err
    })
}
function getAllActionRoomsApi(data) {
  return graphqlClient
    .query({
      query: getAllAuctionRooms,
      variables: { page: data.page, limit: data.limit, isAdmin: false },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => {
      throw err
    })
}

function boatSearchMinimumValuesApi() {
  return graphqlClient
    .query({
      query: getBoatsSearchValues,
    })
    .then(res => res)
    .catch(err => err)
}

function createAuctionBidApi(auctionBid) {
  return graphqlClient
    .mutate({
      mutation: createAuctionBidQuery,
      variables: { input: auctionBid },
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}

function getBoatCostEstimationApi(data) {
  return graphqlClient
    .query({
      query: getCostEstimation,
      variables: { id: data },
    })
    .then(res => res)
    .catch(err => {
      throw err
    })
}

function mailBoatCostEstimationApi(data) {
  return graphqlClient
    .mutate({
      mutation: sendBoatCostEstimationPdf,
      variables: { id: data.id, email: data.email },
    })
    .then(res => res)
    .catch(err => {
      throw err
    })
}

function getAuctionRoomByBidUserApi(data) {
  return graphqlClient
    .query({
      query: getAuctionByBidUser,
      variables: data,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => {
      throw err
    })
}

function getAuctionRoomByBoatIdApi(input) {
  return graphqlClient
    .query({
      query: getBoatAuctionRoom,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => err)
}
function boatConvertedCostEstimationApi(input) {
  return graphqlClient
    .query({
      query: getBoatConvertedCostEstimation,
      variables: { input },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => err)
}

function getAuctionSalesEngineApi(input) {
  return query(getAuctionSalesEngine, { id: input.id })
}

function checkForQuantityApi(input) {
  return query(checkForQuantityQuery, input)
}

function* createAuctionData(action) {
  try {
    const data = yield createAuctionRoomApi(action.payload)

    if (data.errors && data.errors.length) {
      yield put({ type: CREATE_AUCTION_ROOM_FAILURE, payload: data.errors })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: data.errors })
    } else if (data.data) {
      yield put({ type: CREATE_AUCTION_ROOM_SUCCESS, payload: data })
    }
  } catch (e) {
    yield put({ type: CREATE_AUCTION_ROOM_FAILURE, e })
  }
}

function* getBoatTypeData(action) {
  // NOTE: isAllTypes is default true , pass isAllTypes: false when need of only created boat types

  try {
    const res = yield getBoatTypesApi(action.payload)
    yield put({
      type: GET_BOAT_TYPE_SUCCESS,
      payload: res.data?.getAllBoatTypes,
      isAdmin: action.payload.isAllTypes,
    })
  } catch (error) {
    yield put({ type: GET_BOAT_TYPE_FAILURE, error })
  }
}

function* getBoatLookUpData(action) {
  try {
    const data = yield getBoatLookUpCall(action.payload)
    yield put({ type: GET_ALL_BOAT_LOOKUPS_SUCCESS, data: data })
  } catch (error) {
    yield put({ type: GET_ALL_BOAT_LOOKUPS_FAILURE, error })
  }
}

function* getBoatByCity(action) {
  try {
    const res = yield boatByCitiesApi(action.payload)
    yield put({ type: BOAT_BY_CITIES_SUCCESS, payload: res.data.boatByCities })
  } catch (error) {
    yield put({ type: BOAT_BY_CITIES_FAILURE, error })
  }
}

function* saveBoatData(action) {
  try {
    const data = yield saveBoatCall(action.payload)
    yield put({ type: ADD_BOAT_SUCCESS, data: data })
    yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.BOAT_CREATE })
  } catch (error) {
    yield put({ type: ERROR_MESSAGE_SHOW, payload: error })
    yield put({ type: ADD_BOAT_FAILURE, error })
  }

  yield put({ type: CLEAR_BOAT_UPDATE_FLAG })
}

function* getAllBoatsData(action) {
  try {
    const res = yield getAllBoatsApi(action.payload)
    yield put({
      type: GET_ALL_BOATS_BY_USER_SUCCESS,
      payload: res.data.getAllBoatByUser,
    })
  } catch (error) {
    yield put({ type: GET_ALL_BOATS_BY_USER_FAILURE, error })
  }
}

function* getAllFooterLinkData(action) {
  try {
    const res = yield getAllFooterLinkApi(action.payload)
    yield put({
      type: GET_ALL_FOOTER_LINK_SUCCESS,
      payload: res.data.footerLinkBoats,
    })
  } catch (error) {
    yield put({ type: GET_ALL_FOOTER_LINK_FAILURE, error })
  }
}

function* searchBoatData(action) {
  try {
    const res = yield searchBoatApi(action.payload)
    yield put({ type: SEARCH_BOAT_SUCCESS, payload: res.data.searchBoat })
  } catch (error) {
    yield put({ type: SEARCH_BOAT_FAILURE, error })
  }
  yield put({ type: CLEAR_SEARCH_BOAT_FLAG })
}

function* recentSearch() {
  try {
    const res = yield recentSearchApi()
    yield put({
      type: RECENT_SEARCH_SUCCESS,
      payload: res.data.getRecentSearch,
    })
  } catch (error) {
    yield put({ type: RECENT_SEARCH_FAILURE, error })
  }
}

function* multipleSearch(action) {
  try {
    const res = yield multipleSearchApi(action.payload)
    yield put({ type: MULTI_SEARCH_SUCCESS, payload: res.data.multiSearch })
  } catch (error) {
    yield put({ type: MULTI_SEARCH_FAILURE, error })
  }
}

function* getSingleBoat(action) {
  try {
    const res = yield getSingleBoatApi(action.payload)
    if (res.errors && res.errors.length) {
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
      yield put({ type: GET_SINGLE_BOAT_FAILURE, errors: res.errors })
    } else {
      const uRes = yield getSingleBoatSellerApi({ boatId: action.payload.id })

      if (uRes.errors?.length) {
        yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
        yield put({ type: GET_SINGLE_BOAT_FAILURE, errors: res.errors })
      } else  {
        let payload = res.data.editBoat
        payload.seller = uRes.data.editBoat_seller
        yield put({ type: GET_SINGLE_BOAT_SUCCESS, payload })
      }
    }
  } catch (error) {
    yield put({ type: GET_SINGLE_BOAT_FAILURE, error })
  }
  yield put({ type: CLEAR_BOAT_UPDATE_FLAG })
}
function* getUsersByCountryData(action) {
  try {
    const res = yield getUsersByCountryApi(action.payload)
    yield put({ type: GET_USERS_BY_COUNTRY_SUCCESS, payload: res.data.getUsersByCountry, roleType: action.payload.roleType })
  } catch (error) {
    yield put({ type: GET_USERS_BY_COUNTRY_FAILURE, error })
  }
}

function* deleteSingleBoat(action) {
  try {
    const res = yield deleteSingleBoatApi(action.payload)
    if (res.data) {
      yield put({ type: DELETE_BOAT_SUCCESS, payload: res.data.deleteBoat })
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.BOAT_DELETE })
    } else if (res.errors) {
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    }
  } catch (error) {
    yield put({ type: DELETE_BOAT_FAILURE, error })
  }
}

function* updateExistingBoat(action) {
  try {
    const res = yield updateExistingBoatApi(action.payload)
    yield put({ type: UPDATE_BOAT_SUCCESS, payload: res.data.updateBoat })
    yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.BOAT_UPDATE })
  } catch (error) {
    yield put({ type: UPDATE_BOAT_FAILURE, error })
    yield put({ type: ERROR_MESSAGE_SHOW, payload: error })
  }
}

function* toggleBoatStatus(action) {
  try {
    const res = yield toggleBoatStatusApi(action.payload)
    yield put({
      type: TOGGLE_BOAT_STATUS_SUCCESS,
      payload: res.data.boatChangeStatus,
    })
  } catch (error) {
    yield put({ type: TOGGLE_BOAT_STATUS_FAILURE, error })
  }
}

function* getBoatByType(action) {
  try {
    const res = yield getBoatByTypeApi(action.payload)
    yield put({
      type: GET_BOAT_BY_TYPE_SUCCESS,
      payload: res.data.getBoatsByType,
      fieldName: action.payload.input.fieldName,
    })
  } catch (error) {
    yield put({ type: GET_BOAT_BY_TYPE_FAILURE, error })
  }
}

function* getLatestBoat(action) {
  try {
    const res = yield getLatestBoatApi(action.payload)
    yield put({
      type: GET_LATEST_BOATS_SUCCESS,
      payload: res.data.getLatestBoats,
      total: res.data.total,
    })
  } catch (error) {
    yield put({ type: GET_LATEST_BOATS_FAILURE, error })
  }
}

function* getPopularBoat(action) {
  try {
    const res = yield getPopularBoatApi(action.payload)
    yield put({
      type: GET_POPULAR_BOATS_SUCCESS,
      payload: res.data.getMostPopularBoats,
    })
  } catch (error) {
    yield put({ type: GET_POPULAR_BOATS_FAILURE, error })
  }
}

function* getTopRatedBoat(action) {
  try {
    const res = yield getTopRatedBoatApi(action.payload)
    yield put({
      type: GET_TOP_RATED_BOATS_SUCCESS,
      payload: res.data.getTopRatedBoats,
    })
  } catch (error) {
    yield put({ type: GET_TOP_RATED_BOATS_FAILURE, error })
  }
}

function* getCityWiseBoat(action) {
  try {
    const res = yield getCityWiseBoatApi(action.payload)
    yield put({
      type: GET_CITY_WISE_BOATS_SUCCESS,
      payload: res.data.getCityWiseBoats,
    })
  } catch (error) {
    yield put({ type: GET_CITY_WISE_BOATS_FAILURE, error })
  }
}
function* getCategoryWiseBoat(action) {
  try {
    const res = yield getCategoryWiseBoatApi(action.payload)
    yield put({
      type: GET_CATEGORY_WISE_BOATS_SUCCESS,
      payload: res.data.categoryWiseBoats,
    })
  } catch (error) {
    yield put({ type: GET_CATEGORY_WISE_BOATS_FAILURE, error })
  }
}
function* getUserAuctionRoomData(action) {
  try {
    const res = yield getUserAuctionRoomApi(action.payload)
    yield put({
      type: GET_USER_AUCTION_ROOMS_SUCCESS,
      payload: res,
    })
  } catch (error) {
    yield put({ type: GET_USER_AUCTION_ROOMS_FAILURE, error })
  }
}
function* getAllAuctionBidData(action) {
  try {
    const res = yield getAllAuctionBidsApi(action.payload)
    yield put({
      type: GET_ALL_AUCTION_BID_SUCCESS,
      payload: res.data.getAuctionBids,
    })
  } catch (error) {
    yield put({ type: GET_ALL_AUCTION_BID_FAILURE, error })
  }
}
function* getAllAuctionRoomData(action) {
  try {
    const res = yield getAllActionRoomsApi(action.payload)
    yield put({
      type: GET_ALL_AUCTION_ROOMS_SUCCESS,
      payload: res,
    })
  } catch (error) {
    yield put({ type: GET_ALL_AUCTION_ROOMS_FAILURE, error })
  }
}
function* createAuctionBidData(action) {
  try {
    const res = yield createAuctionBidApi(action.payload)
    if (res.errors && res.errors.length) {
      yield put({ type: CREATE_AUCTION_BID_FAILURE, res })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    } else if (res.data) {
      yield put({ type: CREATE_AUCTION_BID_SUCCESS, payload: res })
    }
  } catch (error) {
    yield put({ type: CREATE_AUCTION_BID_FAILURE, error })
  }
  yield put({ type: CLEAR_FLAG_AUCTION_BID })
}

function* boatSearchMinimumValues(action) {
  try {
    const res = yield boatSearchMinimumValuesApi(action.payload)
    yield put({ type: GET_BOAT_SEARCH_MINIMUM_VALUE_SUCCESS, payload: res.data.getBoatsSearchValues })
  } catch (error) {
    yield put({ type: GET_BOAT_SEARCH_MINIMUM_VALUE_FAILURE, error })
  }
}

function* getBoatCostEstimationData(action) {
  try {
    const res = yield getBoatCostEstimationApi(action.payload)
    yield put({ type: GET_BOAT_COST_ESTIMATION_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: GET_BOAT_COST_ESTIMATION_FAILURE, error })
  }
}

function* mailBoatCostEstimationData(action) {
  try {
    const res = yield mailBoatCostEstimationApi(action.payload)
    yield put({ type: MAIL_BOAT_COST_ESTIMATION_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: MAIL_BOAT_COST_ESTIMATION_FAILURE, error })
  }
}

function* getAuctionRoomByBidUserData(action) {
  try {
    const res = yield getAuctionRoomByBidUserApi(action.payload)
    yield put({ type: GET_AUCTION_ROOM_BY_BID_USER_SUCCESS, payload: res.data.getAuctionBidByUser })
  } catch (error) {
    yield put({ type: GET_AUCTION_ROOM_BY_BID_USER_FAILURE, error })
  }
}

function* getAuctionRoomByBoatId(action) {
  try {
    const res = yield getAuctionRoomByBoatIdApi(action.payload)
    yield put({ type: GET_AUCTION_BY_BOAT_ID_SUCCESS, payload: res.data.getBoatAuctionRoom })
  } catch (error) {
    yield put({ type: GET_AUCTION_BY_BOAT_ID_FAILURE, error })
  }
}

function* boatConvertedCostEstimation(action) {
  try {
    const res = yield boatConvertedCostEstimationApi(action.payload)
    yield put({ type: GET_BOAT_CONVERTED_COST_ESTIMATION_SUCCESS, payload: res.data.getBoatConvertedCostEstimation })
  } catch (error) {
    yield put({ type: GET_BOAT_CONVERTED_COST_ESTIMATION_FAILURE, error })
  }
}

function* getAuctionSalesEngineData(action) {
  try {
    const res = yield getAuctionSalesEngineApi(action.payload)
    yield put({ type: GET_AUCTION_SALES_ENGINE_SUCCESS, payload: res.data.getAuctionSalesEngine })
  } catch (error) {
    yield put({ type: GET_AUCTION_SALES_ENGINE_FAILURE, error })
  }
  yield put({ type: CLEAR_AUCTION_SALES_ENGINE })
}

function* checkForQuantityData(action) {
  try {
    const res = yield checkForQuantityApi(action.payload)
    if (res?.data) {
      yield put({ type: CHECK_FOR_QUANTITY_QUERY_SUCCESS, payload: res.data.checkForQuantity })
    } else if (res?.errors) {
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
      yield put({ type: CHECK_FOR_QUANTITY_QUERY_FAILURE, error: res.errors })
    }
  } catch (error) {
    yield put({ type: CHECK_FOR_QUANTITY_QUERY_FAILURE, error })
  }
}

function* addDepositForBidApi(action) {
  try {
    const res = yield mutation(makeAuctionPayment, { input: action.payload })
    if (res?.data) {
      yield put({ type: ADD_DEPOSIT_FOR_BID_SUCCESS, payload: res.data.makeAuctionPayment })
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.DEPOSIT_ADDED })
    } else if (res?.errors) {
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
      yield put({ type: ADD_DEPOSIT_FOR_BID_FAILURE, error: res.errors })
    }
  } catch (error) {
    yield put({ type: ADD_DEPOSIT_FOR_BID_FAILURE, error })
  }
  yield put({ type: CLEAR_ADD_DEPOSIT_FOR_BID })
}

function* getAllBoatLookUps() {
  yield takeLatest(GET_ALL_BOAT_LOOKUPS, getBoatLookUpData)
}

function* getBoatByCitiesSaga() {
  yield takeLatest(BOAT_BY_CITIES, getBoatByCity)
}

function* getBoatTypeSaga() {
  yield takeEvery(GET_BOAT_TYPE, getBoatTypeData)
}

function* saveBoatSaga() {
  yield takeLatest(ADD_BOAT, saveBoatData)
}

function* getAllBoatsSaga() {
  yield takeLatest(GET_ALL_BOATS_BY_USER, getAllBoatsData)
}

function* getAllFooterLinkSaga() {
  yield takeLatest(GET_ALL_FOOTER_LINK, getAllFooterLinkData)
}

function* searchBoatSaga() {
  yield takeEvery(SEARCH_BOAT, searchBoatData)
}

function* recentSearchSaga() {
  yield takeLatest(RECENT_SEARCH, recentSearch)
}

function* multipleSearchSaga() {
  yield takeLatest(MULTI_SEARCH, multipleSearch)
}

function* getSingleBoatSaga() {
  yield takeLatest(GET_SINGLE_BOAT, getSingleBoat)
}

function* getUsersByCountrySaga() {
  yield takeEvery(GET_USERS_BY_COUNTRY, getUsersByCountryData)
}

function* deleteSingleBoatSaga() {
  yield takeLatest(DELETE_BOAT, deleteSingleBoat)
}

function* updateExistingBoatSaga() {
  yield takeLatest(UPDATE_BOAT, updateExistingBoat)
}

function* toggleBoatStatusSaga() {
  yield takeLatest(TOGGLE_BOAT_STATUS, toggleBoatStatus)
}

function* getBoatByTypeSaga() {
  yield takeEvery(GET_BOAT_BY_TYPE, getBoatByType)
}

function* getLatestBoatSaga() {
  yield takeLatest(GET_LATEST_BOATS, getLatestBoat)
}

function* getPopularBoatSaga() {
  yield takeLatest(GET_POPULAR_BOATS, getPopularBoat)
}

function* getTopRatedBoatSaga() {
  yield takeLatest(GET_TOP_RATED_BOATS, getTopRatedBoat)
}

function* getCityWiseBoatSaga() {
  yield takeLatest(GET_CITY_WISE_BOATS, getCityWiseBoat)
}
function* getCategoryWiseBoatSaga() {
  yield takeLatest(GET_CATEGORY_WISE_BOATS, getCategoryWiseBoat)
}

function* createAuctionRoomSaga() {
  yield takeLatest(CREATE_AUCTION_ROOM, createAuctionData)
}

function* getUserAuctionRoomsSaga() {
  yield takeLatest(GET_USER_AUCTION_ROOMS, getUserAuctionRoomData)
}

function* getAllBidOfAuctionSaga() {
  yield takeLatest(GET_ALL_AUCTION_BID, getAllAuctionBidData)
}

function* getAllAuctionRoomSaga() {
  yield takeLatest(GET_ALL_AUCTION_ROOMS, getAllAuctionRoomData)
}

function* createAuctionBidSaga() {
  yield takeLatest(CREATE_AUCTION_BID, createAuctionBidData)
}

function* boatSearchMinimumValuesSaga() {
  yield takeLatest(GET_BOAT_SEARCH_MINIMUM_VALUE, boatSearchMinimumValues)
}

function* getBoatCostEstimationSaga() {
  yield takeLatest(GET_BOAT_COST_ESTIMATION, getBoatCostEstimationData)
}

function* mailBoatCostEstimationSaga() {
  yield takeLatest(MAIL_BOAT_COST_ESTIMATION, mailBoatCostEstimationData)
}

function* getAuctionRoomByBidUserSaga() {
  yield takeLatest(GET_AUCTION_ROOM_BY_BID_USER, getAuctionRoomByBidUserData)
}

function* getAuctionRoomByBoatIdSaga() {
  yield takeLatest(GET_AUCTION_BY_BOAT_ID, getAuctionRoomByBoatId)
}

function* getBoatConvertedCostEstimationSaga() {
  yield takeLatest(GET_BOAT_CONVERTED_COST_ESTIMATION, boatConvertedCostEstimation)
}

function* getAuctionSalesEngineSaga() {
  yield takeLatest(GET_AUCTION_SALES_ENGINE, getAuctionSalesEngineData)
}

function* checkForQuantitySaga() {
  yield takeLatest(CHECK_FOR_QUANTITY_QUERY, checkForQuantityData)
}

function* addDepositForBidSaga() {
  yield takeLatest(ADD_DEPOSIT_FOR_BID, addDepositForBidApi)
}

function* getYachtsSaga() {
  yield takeLatest(GET_YACHTS, getYachtsData)
}

function* getYachtsData(action) {
  try {
    const res = yield getYachtsApi(action.payload)
    yield put({
      type: GET_YACHTS_SUCCESS,
      payload: res.data.getYachts,
    })
  } catch (error) {
    yield put({ type: GET_YACHTS_FAILURE, error })
  }
}

async function getYachtsApi(input) {
  return graphqlClient
    .query({
      query: getYachtsQuery,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

export default function* boatSaga() {
  yield all([
    getAllBoatLookUps(),
    saveBoatSaga(),
    getAllBoatsSaga(),
    getAllFooterLinkSaga(),
    searchBoatSaga(),
    getBoatTypeSaga(),
    recentSearchSaga(),
    multipleSearchSaga(),
    getSingleBoatSaga(),
    deleteSingleBoatSaga(),
    updateExistingBoatSaga(),
    toggleBoatStatusSaga(),
    getBoatByTypeSaga(),
    getLatestBoatSaga(),
    getPopularBoatSaga(),
    getTopRatedBoatSaga(),
    getCityWiseBoatSaga(),
    getCategoryWiseBoatSaga(),
    createAuctionRoomSaga(),
    getUserAuctionRoomsSaga(),
    getAllBidOfAuctionSaga(),
    getAllAuctionRoomSaga(),
    createAuctionBidSaga(),
    getBoatByCitiesSaga(),
    boatSearchMinimumValuesSaga(),
    getBoatCostEstimationSaga(),
    mailBoatCostEstimationSaga(),
    getAuctionRoomByBidUserSaga(),
    getAuctionRoomByBoatIdSaga(),
    getUsersByCountrySaga(),
    getBoatConvertedCostEstimationSaga(),
    getAuctionSalesEngineSaga(),
    checkForQuantitySaga(),
    addDepositForBidSaga(),
    getYachtsSaga(),
  ])
}
