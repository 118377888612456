import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import SaveAltIcon from '@material-ui/icons/SaveAlt'

import { CommonInformationCard } from './CommonInformationCard'
import { CommonSalesEnginTitle } from './CommonSalesEnginTitle'
import { nameFormatter } from '../../helpers/string'
import { formattedDate } from '../../helpers/dateTimeHelper'
import '../../assets/css/component/salesEngine/surveyorReport.scss'
import { redirectRouteHandler } from '../../helpers/routeHelper'
import { CommonModal } from '../modal/commonModal'
import { downloadFile, downloadSurveyImagesZipFile, prepareGalleryData } from '../../util/utilFunctions'
import { Button } from 'react-bootstrap'
import { ArrowRight } from '@material-ui/icons'
import IconSEArrowRight from '../../containers/salesEngine/process/right-arrow-svgrepo-com'
import styles from './SurveyReportGallery.module.scss'
import cn from 'classnames'
import VideoPlayer from '../../containers/sm/components/post/video-player'

export const SurveyorSubmittedReport = props => {
  const { surveyorReport, salesEngine, getDocumentUrl, confirmButton } = props

  const [show, setShow] = useState(false)
  const [isGalleryOpen, setIsGalleryOpen] = useState(-1)

  const SurveyorModalHandler = () => setShow(!show)

  return (
    <div className="surveyor--submitted--report--main">
      <CommonSalesEnginTitle isReport className="surveyor--submitted--title" />
      <CommonInformationCard className="surveyor--submitted--report">
        <div className="mb-3">
          <span class="surveyor--submitted--date">
            Report submitted by{' '}
            {salesEngine?.surveyor?.user &&
              nameFormatter([salesEngine.surveyor.user.firstName, salesEngine.surveyor.user.lastName])}
            on {salesEngine?.surveySubmitted && formattedDate(salesEngine.surveySubmitted)}
          </span>
        </div>
        <div className={styles.gallery}>
          {surveyorReport?.images?.length > 0 && (
            <>
              <div>
                <p className="font-bold">Report images</p>
                <div className={cn(styles.images, styles['images-' + Math.max(surveyorReport.images.length, 4)])}>
                  {surveyorReport.images.slice(0, 4).map((img, idx) => (
                    <div className={cn(styles.image, styles['image-' + idx])}>
                      <img src={img.url} alt="" onClick={() => setIsGalleryOpen(idx)} />
                      {surveyorReport.images.length > 4 && idx === 3 && (
                        <button className={styles.moreImages} onClick={() => setIsGalleryOpen(3)}>
                          + {surveyorReport.images.length - 3}
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {isGalleryOpen > -1 && (
                <ModalGateway>
                  <Modal onClose={() => setIsGalleryOpen(-1)}>
                    <Carousel views={prepareGalleryData(surveyorReport.images, isGalleryOpen)} />
                  </Modal>
                </ModalGateway>
              )}
            </>
          )}
          {surveyorReport?.video && (
            <div>
              <p className="font-bold">Report video</p>
              <div className={styles.video}>
                <VideoPlayer src={surveyorReport.video.url} controls />
              </div>
            </div>
          )}
        </div>
        <div className="surveyor--report--50 mt-5 surveyor--report--last">
          {/* {surveyorReport?.comment && (
            // <span className="download--document video--download--document">Surveyor Comment: {surveyorReport?.comment}</span>
            <>
              <Button
                variant="outline"
                type="submit"
                color="secondary"
                className="btn btn-black rounded-md mr-3 surveyor--comment--btn"
                onClick={SurveyorModalHandler}
              >
                {'Surveyor Comment'} <IconSEArrowRight style={{ height: '1em' }} />
              </Button>
              <CommonModal
                className="surveyor--comment--modal"
                open={show}
                close={SurveyorModalHandler}
                title="Comment From Surveyor"
              >
                <p className="download--document video--download--document">{surveyorReport?.comment}</p>
              </CommonModal>
            </>
          )} */}
          {surveyorReport?.images?.length > 0 && (
            <span
              className="download--document image--download--document"
              onClick={() => {
                downloadSurveyImagesZipFile(surveyorReport?.images)
              }}
              download
              target="_blank"
            >
              Report Images ({surveyorReport?.images?.length})
              <span className="download--document--icon">
                <SaveAltIcon />
              </span>
            </span>
          )}
          <span
            className="download--document video--download--document"
            onClick={() => downloadFile(surveyorReport?.video?.origUrl || surveyorReport?.video?.url, 'Report Video')}
            download
            target="_blank"
          >
            Report Video{' '}
            <span className="download--document--icon">
              <SaveAltIcon />
            </span>
          </span>
          <span
            className="download--document verification--download--document"
            onClick={() => getDocumentUrl(surveyorReport?.boatVerifications?.id)}
            download
          >
            Boat Verification Document{' '}
            <span className="download--document--icon">
              <SaveAltIcon />
            </span>
          </span>
          <span
            className="download--document survey--download--document"
            onClick={() => getDocumentUrl(surveyorReport?.report?.id)}
            download
          >
            Survey Report{' '}
            <span className="download--document--icon">
              <SaveAltIcon />
            </span>
          </span>
          {surveyorReport?.otherDocument && (
            <span
              className="download--document survey--download--document"
              onClick={() => getDocumentUrl(surveyorReport?.otherDocument?.id)}
              download
            >
              Other Documents{' '}
              <span className="download--document--icon">
                <SaveAltIcon />
              </span>
            </span>
          )}
        </div>

        {surveyorReport?.comment && (
          <div className="d-flex mt-4" style={{ gap: 40 }}>
            <div
              className="p-4 d-flex justify-content-center align-items-center rounded-md font-bold"
              style={{ background: '#edeeef' }}
            >
              Surveyor Comment
            </div>
            <div className="flex-1">{surveyorReport?.comment}</div>
          </div>
        )}

        {confirmButton}
      </CommonInformationCard>
    </div>
  )
}
