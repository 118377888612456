export const getMySalesEngineStatus = salesEngine => {
  const { stepperInfo } = salesEngine
  if (salesEngine.buySellProcess.length) {
    const status = salesEngine.buySellProcess[salesEngine.buySellProcess.length - 1]

    if (status === salesEngineStatus.negotiationPending) {
      return 'Seller has not added final price for boat.'
    }
    if (stepperInfo?.activeStep?.step === 1 && status === salesEngineStatus.negotiation && !salesEngine.isSurveyorSkip) {
      return 'My Boat'
    }
    if (salesEngine?.requestedSurveyor?.length > 0 && !salesEngine.surveyor && !salesEngine.isSurveyorSkip) {
      return 'No surveyor has accepted the request yet, wait until one of them accept to do the survey'
    }

    if (status === salesEngineStatus.surveyorPaymentCompleted) {
      return 'Waiting for surveyor to upload the reports'
    }
    if (status === salesEngineStatus.agreementPending) {
      if (salesEngine?.isAuctionSalesEngine) return 'Boat Payment from Auction';
      else return 'Boat Payment';
    }
    if (
      stepperInfo?.activeStep?.step === 2 &&
      !salesEngine?.requestedSurveyor?.length &&
      !salesEngine.isSurveyorSkip &&
      !salesEngine?.surveyor
    ) {
      return 'Select Surveyor'
    }
    if (salesEngine?.surveyor && !salesEngine?.surveyorPayment) {
      return 'Surveyor payment process is left'
    }
    if (
      stepperInfo?.activeStep?.step === 3 &&
      salesEngine?.surveyor &&
      salesEngine?.surveyorPayment &&
      !salesEngine?.surveyorReport
    ) {
      return 'Surveyor has not uploaded report yet.'
    }
    if (stepperInfo?.activeStep?.step === 3 && salesEngine?.surveyorReport) {
      return 'Surveyor Report Uploaded'
    }
    if (stepperInfo?.activeStep?.step === 4 && !salesEngine?.boatPayment) {
      return 'Boat payment is left'
    }
    if (stepperInfo?.activeStep?.step === 5 && salesEngine?.isShipperSkip) {
      return 'Pickup your Boat'
    }
    if (stepperInfo?.activeStep?.step === 5 && !salesEngine?.isShipperSkip && !salesEngine?.shipmentLocation) {
      return 'Shipment Location is pending'
    }
    if (!salesEngine?.isShipperSkip && salesEngine?.shipmentLocation && !salesEngine?.shipper) {
      return 'Select Shipper'
    } else {
      return status
    }
  }
}

export const assignAgentCheckBox = salesEngine => {
  return (
    salesEngine.buySellProcess &&
    salesEngine.buySellProcess.length > 0 &&
    (salesEngine.buySellProcess[salesEngine.buySellProcess.length - 1] === salesEngineStatus.myBoat ||
      salesEngine.buySellProcess[salesEngine.buySellProcess.length - 1] === salesEngineStatus.negotiation)
  )
}
export const paymentStatus = salesEngine => {
  return (
    salesEngine.buySellProcess &&
    salesEngine.buySellProcess.length > 0 &&
    salesEngine.buySellProcess.includes(salesEngineStatus.paymentCompleted)
  )
}

// Steps Enum
export const salesEngineSteps = (skipSurvey = false) => {
  return [
    { step: 1, name: 'My Boat', isActivated: true },
    { step: 2, name: 'Survey My Boat', isActivated: true },
    { step: 3, name: 'Survey Report', isActivated: true },
    { step: 4, name: 'Boat Payment', isActivated: true },
    { step: 5, name: 'Shipment', isActivated: true },
  ]
}

export const salesEngineStatus = {
  myBoat: 'My Boat',
  negotiation: 'Negotiation',
  surveyorSkipped: 'Surveyor Skipped',
  surveyMyBoat: 'Survey My Boat',
  surveyorRequested: 'Surveyor Requested',
  surveyorAccepted: 'Surveyor Accepted',
  surveyorPaymentPending: 'Surveyor Payment Pending',
  surveyorPaymentCompleted: 'Surveyor Payment Completed',
  surveyNotStarted: 'Survey Not Started',
  surveytStarted: 'Survey Started',
  surveyorReportUpload: 'Surveyor Report Uploaded',
  agreementPending: 'Agreement Pending',
  buyerAcceptAgreement: 'Buyer Accept Agreement',
  sellerAcceptAgreement: 'Seller Accept Agreement',
  agreement: 'Agreement Completed',
  paymentPending: 'Boat Payment Pending',
  paymentCompleted: 'Boat Payment Completed',
  shipperAccepted: 'Shipper Accepted',
  buyerAddedShipmentLocationPending: 'Buyer Added Shipment Location Pending',
  buyerAddedShipmentLocationCompleted: 'Buyer Added Shipment Location Completed',
  shipperPaymentPending: 'Shipment Payment Pending',
  shipperPaymentCompleted: 'Shipment Payment Completed',
  shipmentNotStarted: 'Shipment Not Started',
  shipmentStarted: 'Shipment Started',
  shipmentPending: 'Shipment Pending',
  shipmentCompleted: 'Shipment Completed',

  shipperAddedDocument: 'Shipper Uploaded Document',

  inspectionPayment: 'Inspection Payment',
  negotiationPending: 'Negotiation Pending',
  certifyMyBoat: 'Certify My Boat',
  report: 'Report',

  shipment: 'Shipment',
  completed: 'Completed',
  stopProcess: 'Process Stopped',
  // auditor : "Auditor",
  auditorConfirm: 'Auditor Confirm',
  auditorNotConfirm: 'Auditor Not Confirm',
  // accounter : "Accounter",
  accounterConfirm: 'Accounter Confirm',
  accounterNotConfirm: 'Accounter Not Confirm',
  lostDeal: 'Lost Deal',
  boatReceived: "Boat Received"
}

export const stepOneStatus = [salesEngineStatus.myBoat, salesEngineStatus.negotiation]

export const stepTwoStatus = [
  salesEngineStatus.agreementPending,
  salesEngineStatus.surveyMyBoat,
  salesEngineStatus.surveyorRequested,
  salesEngineStatus.surveyorAccepted,
  salesEngineStatus.surveyorPaymentPending,
  salesEngineStatus.surveyorPaymentCompleted,
]

export const stepThreeStatus = [salesEngineStatus.surveyorReportUpload]

export const stepFourStatus = [
  salesEngineStatus.surveyorSkipped,
  salesEngineStatus.agreementPending,
  salesEngineStatus.buyerAcceptAgreement,
  salesEngineStatus.sellerAcceptAgreement,
  salesEngineStatus.agreement,
  salesEngineStatus.paymentPending,
]

export const stepFiveStatus = [
  salesEngineStatus.paymentCompleted,
  salesEngineStatus.shipperAccepted,
  salesEngineStatus.buyerAddedShipmentLocationCompleted,
  salesEngineStatus.buyerAddedShipmentLocationPending,
  salesEngineStatus.shipmentPending,
  salesEngineStatus.shipperPaymentPending,
  salesEngineStatus.shipperPaymentCompleted,
  salesEngineStatus.shipmentCompleted,
]

export const salesEngineStatusCheck = (salesEngine, skipSurveyor) => {
  const currentStatus = salesEngine?.buySellProcess?.length && salesEngine.buySellProcess[salesEngine.buySellProcess.length - 1]

  const stepsArray = skipSurveyor
    ? [stepOneStatus, stepFourStatus, stepFiveStatus]
    : [stepOneStatus, stepTwoStatus, stepThreeStatus, stepFourStatus, stepFiveStatus]

  return (
    currentStatus &&
    stepsArray.reduce((accum, step, index) => {
      return step.includes(currentStatus) ? index + 1 : accum
    }, 1)
  )
}

export const getSurveyorTotal = salesEngine => {
  const boatLength = salesEngine?.boat?.lengthInFt
  const surveyorPrice = salesEngine?.surveyor?.pricePerFt
  return surveyorPrice * boatLength
}

export const getSalesEngineCountDescription = userType => {
  const messages = {
    buyerPaymentDueBuyer:
      'You need to do the boat payment if you like to buy it and in case you miss the timeline then the transaction will be canceled, and the boat will be returned back to the market',
    buyerPaymentDueSeller:
      'Buyer will need to do the boat payment before the timeline expired, and in case the Buyer missed the payment before the timeline then the boat will return to the market for sale again',
    surveyorMessageBuyer:
      'Surveyor needs to visit the boat and run the Survey before the timeline end and in case the surveyor missed the timeline then the transaction will be canceled and the buyer can pick up another surveyor, the payment will be refunded for the canceled Survey transaction',
    surveyorMessage:
      'The surveyor needs to visit the boat, conduct the survey, and upload the required survey reports before the timeline ends. In the event that the surveyor misses the timeline, the transaction will be canceled, and the buyer can choose another surveyor.',
  }

  switch (userType) {
    case 'buyerBoatPayment':
      return messages.buyerPaymentDueBuyer

    case 'sellerBoatPayment':
    case 'agentBoatPayment':
      return messages.buyerPaymentDueSeller

    case 'surveyorPayment':
      return messages.surveyorMessage

    case 'surveyor':
      return messages.surveyorMessage

    default:
      return
  }
}
