import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { InputBase } from '@material-ui/core'
import './ManagePayment.scss'

import { getTransactionByUserPayouts } from '../../../redux/actions'
import { getPagesValue } from '../../../helpers/jsxHelper'
import PayoutCardList from './PayoutCardList'

class PayoutsPaymentDetail extends Component {
  componentDidMount() {
    const { getTransactionByUser } = this.props
    getTransactionByUser({
      page: getPagesValue(),
      limit: 100,
      type: 'payouts',
    })
  }

  render() {
    const { transactionLoader, userTransactionDetails } = this.props

    return (
      <div className="manage--payment--main--section">
        <div className="d-flex justify-content-between align-items-center">
          <div className="manage--payment--title--section">
            <p className="manage--payment--title mb-0">Payouts</p>
          </div>
          <div className="search--data--input">
            <InputBase
              placeholder="Search Here..."
              className="search-input"
              onChange={e => {
                this.props.getTransactionByUser({
                  page: getPagesValue(),
                  limit: 100,
                  type: 'payouts',
                  text: e.target.value,
                })
              }}
            />
            <img loading="lazy" src={require('../../../assets/images/salesEngine/search.svg')} alt="Search" className="search--icon" />
          </div>
        </div>
        {userTransactionDetails &&
          userTransactionDetails.length > 0 &&
          userTransactionDetails.map(item => {
            return <PayoutCardList data={item} />
          })}
        {!transactionLoader && userTransactionDetails && userTransactionDetails.length === 0 && (
          <div className="card">
            <div className="card-body m-0">
              <div className="d-flex justify-content-center align-items-center">No Transaction Found</div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  transactionLoader: state.loginReducer.transactionPayoutsLoader,
  userTransactionDetails: state.loginReducer.userTransactionPayoutsDetails,
  userTransactionDetailsTotal: state.loginReducer.userTransactionPayoutsDetailsTotal,
})

const mapDispatchToProps = dispatch => ({
  getTransactionByUser: data => dispatch(getTransactionByUserPayouts(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PayoutsPaymentDetail))
