import React from 'react'
import { SkeletonComponent } from './SkeletonComponent'

export const SkeletonDefault = props => {
  return (
    <div className="skeleton--default">
      <SkeletonComponent className="skeleton--default-mb" height={20} count={2} />

      <div className="skeleton--default-middle mt-3">
        <SkeletonComponent count={4} height={20} />
      </div>
      <SkeletonComponent className="skeleton--default-mb" height={20} count={4} />
    </div>
  )
}
