import React from 'react'
import { SkeletonLoader } from '../loader/SkeletonLoader'
import { skeletonType } from '../../util/enums/enums'
import { SkeletonComponent } from './SkeletonComponent'

export const SkeletonInnerPage = props => {
  return (
    <div className="skeleton--inner-page">
      <div className="skeleton--inner-page-five-images">
        <div className="skeleton--inner-page-first-image">
          <SkeletonComponent className="skeleton--inner-main-first-image border-radius-10" height={600} />
        </div>

        <div className="skeleton--inner-page-four-image">
          <SkeletonComponent className="default-img-padding border-radius-10" height={300} count={4} />
        </div>
      </div>

      <SkeletonLoader />

      <div className="inner-related-product">
        <SkeletonLoader type={skeletonType.grid} itemsLength={4} />
      </div>
    </div>
  )
}

SkeletonInnerPage.defualtProps = {
  itemsLength: 4,
  isWishlist: false,
}
