import {
  GET_SEO_PAGES,
  GET_SEO_PAGES_SUCCESS,
  GET_SEO_PAGES_FAILURE,
  GET_SEO_PAGES_CLEAR
} from '../actionTypes'

const InitialState = {
  seoItems: [],
  fetchLoading: null,
  fetchSuccess: false,
  fetchError: false,
}

export const seoReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_SEO_PAGES:
      return {
        ...state,
        seoItems: [],
        fetchLoading: true,
        fetchSuccess: false,
        fetchError: false,
      }

    case GET_SEO_PAGES_SUCCESS:
      return {
        ...state,
        seoItems: action.payload,
        fetchSuccess: true,
        fetchError: false,
        fetchLoading: false,
      }

    case GET_SEO_PAGES_FAILURE:
      return {
        ...state,
        fetchError: true,
        fetchLoading: false,
      }

    case GET_SEO_PAGES_CLEAR:
      return {
        seoItems: [],
        fetchSuccess: false,
        fetchError: false,
        fetchLoading: false,
      }
    default:
      return state
  }
}
