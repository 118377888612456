import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Layout } from '../../components'
import { Tabs, Tab } from 'react-bootstrap'
import { SearchResultCard } from '../../components/staticComponent/searchResultCard/SearchResultCard'
import { marketTypes, pagination } from '../../util/enums/enums'
import { globalSearch } from '../../redux/actions'

import './searchResult.scss'
import { urlParamsToObject } from '../../helpers/routeHelper'

const noResult = 'NO RESULTS FOUND.'

class searchResult extends Component {
  componentDidMount() {
    const {
      globalSearch,
      location: { search },
    } = this.props
    const urlParams = new URLSearchParams(search)

    const values = urlParamsToObject(urlParams)

    values &&
      values.query &&
      globalSearch({
        query: values.query,
        page: pagination.PAGE_COUNT,
        limit: pagination.PAGE_RECORD_LIMIT,
      })
  }

  render() {
    const { multipleSearchResults } = this.props

    return (
      <Layout>
        <div className="container100 pt-5 search-result-div">
          <Tabs defaultActiveKey="all" id="search-result" className="search-result-nav-div">
            <Tab eventKey="all" title="ALL">
              {multipleSearchResults &&
              multipleSearchResults.hasOwnProperty('boats') &&
              multipleSearchResults.boats.length > 0 ? (
                multipleSearchResults.boats.map(item => (
                  <div className="mt-3 pb-3 rating-start-small-size search-result-nav-main-section" key={item.id}>
                    <SearchResultCard data={item} type={marketTypes.BOAT} />
                    <hr />
                  </div>
                ))
              ) : (
                <div className="mt-3">{noResult}</div>
              )}
            </Tab>

            <Tab eventKey="new" title="NEW">
              {multipleSearchResults &&
              multipleSearchResults.hasOwnProperty('newBoats') &&
              multipleSearchResults.newBoats.length > 0 ? (
                multipleSearchResults.newBoats.map(item => (
                  <div className="mt-3 pb-3 rating-start-small-size search-result-nav-main-section" key={item.id}>
                    <SearchResultCard data={item} type={marketTypes.BOAT} />
                    <hr />
                  </div>
                ))
              ) : (
                <div className="mt-3">{noResult}</div>
              )}
            </Tab>

            <Tab eventKey="used" title="USED">
              {multipleSearchResults &&
              multipleSearchResults.hasOwnProperty('usedBoats') &&
              multipleSearchResults.usedBoats.length > 0 ? (
                multipleSearchResults.usedBoats.map(item => (
                  <div className="mt-3 pb-3 rating-start-small-size search-result-nav-main-section" key={item.id}>
                    <SearchResultCard data={item} type={marketTypes.BOAT} />
                    <hr />
                  </div>
                ))
              ) : (
                <div className="mt-3">{noResult}</div>
              )}
            </Tab>

            <Tab eventKey="charter & rent" title="CHARTER & RENT">
              {multipleSearchResults &&
              multipleSearchResults.hasOwnProperty('rentBoats') &&
              multipleSearchResults.rentBoats.length > 0 ? (
                multipleSearchResults.rentBoats.map(item => (
                  <div className="mt-3 pb-3 rating-start-small-size search-result-nav-main-section" key={item.id}>
                    <SearchResultCard data={item} type={marketTypes.RENT_BOAT} />
                    <hr />
                  </div>
                ))
              ) : (
                <div className="mt-3">{noResult}</div>
              )}
            </Tab>

            <Tab eventKey="yacht services" title="YACHT SERVICES">
              {multipleSearchResults &&
              multipleSearchResults.hasOwnProperty('yachtService') &&
              multipleSearchResults.yachtService.length > 0 ? (
                multipleSearchResults.yachtService.map(item => (
                  <div className="mt-3 pb-3 rating-start-small-size search-result-nav-main-section" key={item.id}>
                    <SearchResultCard data={item} type={marketTypes.YACHT_SERVICE} />
                    <hr />
                  </div>
                ))
              ) : (
                <div className="mt-3">{noResult}</div>
              )}
            </Tab>

            <Tab eventKey="marina & storage" title="MARINA & STORAGE">
              {multipleSearchResults &&
              multipleSearchResults.hasOwnProperty('marinaStorage') &&
              multipleSearchResults.marinaStorage.length > 0 ? (
                multipleSearchResults.marinaStorage.map(item => (
                  <div className="mt-3 pb-3 rating-start-small-size search-result-nav-main-section" key={item.id}>
                    <SearchResultCard data={item} type={marketTypes.MARINA_AND_STORAGE} />
                    <hr />
                  </div>
                ))
              ) : (
                <div className="mt-3">{noResult}</div>
              )}
            </Tab>
          </Tabs>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  multipleSearchResults: state.dashboardReducer.globalSearchData,
})

const mapDispatchToProps = dispatch => ({
  globalSearch: data => dispatch(globalSearch(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(searchResult)
