import React from 'react'
import Skeleton from 'react-loading-skeleton'

export const SkeletonComponent = React.memo(({ height, width, circle, duration, count, className }) => {
  return <Skeleton height={height} width={width} circle={circle} duration={duration} className={className} count={count} />
})

SkeletonComponent.defaultProps = {
  width: null,
  height: 20,
  circle: false,
  count: 1,
  duration: 1.2,
}
