import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../containers/login/login.scss'
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'
import { connect } from 'react-redux'
import { getAllPageInfoByType, clearPageInfoByTypeFlag } from '../redux/actions/pageInfoByTypeAction'
import { footerLinks } from '../util/enums/enums'
import { StyledTransparentBtn } from './styleComponent/styleComponent'
import { envConfig } from '../config'

const TermAndPolicy = props => {
  const [privacyModal, setPrivacyModal] = useState(false)

  const createMarkup = data => {
    return { __html: data }
  }

  const privacyPolicyHandler = () => {
    setPrivacyModal(!privacyModal)
  }

  useEffect(() => {
    props.getAllPageInfoByType({ title: footerLinks.Privacy_Policy })
  }, [getAllPageInfoByType])

  useEffect(() => {
    const { success, clearPageInfoByTypeFlag } = props
    if (success) {
      clearPageInfoByTypeFlag()
    } else {
      setPrivacyModal(false)
    }
  }, [props])

  return (
    <div className="term-and-policy-div">
        <div className="login-link mr-3">
          <a href={`${envConfig.WEB_ORIGIN}faq?id=6570ff1dfde7050015660a18`} target='_blank'>
          <StyledTransparentBtn>Which user type should I register in</StyledTransparentBtn>
          </a>
        </div>
      {/* <Link to="#">
        <div className="login-link mr-3">
          <span className="mr-3" onClick={privacyPolicyHandler}>
            Privacy Policy
          </span>
          <Dialog
            open={privacyModal}
            onClose={privacyPolicyHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" className="pb-0">
              {props.pageInfoByType.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div dangerouslySetInnerHTML={createMarkup(props.pageInfoByType.pageContent)} />
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <span>|</span>
        </div>
      </Link>
      <Link to="#">
        <div className="login-link">
          <span>Terms of Use Sitemap</span>
        </div>
      </Link> */}
    </div>
  )
}
// export default TermAndPolicy;
const mapStateToProps = state => ({
  pageInfoByType: state.pageInfoByTypeReducer && state.pageInfoByTypeReducer.pageInfoByType,
  success: state.pageInfoByTypeReducer && state.pageInfoByTypeReducer.success,
})

const mapDispatchToProps = dispatch => ({
  getAllPageInfoByType: data => dispatch(getAllPageInfoByType(data)),
  clearPageInfoByTypeFlag: data => dispatch(clearPageInfoByTypeFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TermAndPolicy)
