import React, { Component } from 'react'
import { connect } from 'react-redux'

import { clearSalesEngines } from '../../../redux/actions/salesEngineAction'
import { DashboardLayout } from '../../../components'
import BuyerSalesEngine from './BuyerSalesEngine'

import '../../../styles/manageDashboardTableResponsive.scss'
import SalesEngineArchive from './SalesEngineArchive'

class SalesEngine extends Component {
  state = {
    isBuyItNow: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { getSalesEngineSuccess, salesEngine, history } = nextProps

    if (getSalesEngineSuccess && salesEngine && salesEngine.id) {
      history && history.push(`/sales-engine-process/${salesEngine.id}`)
    }

    if (history.location && history.location.search) {
      return {
        isBuyItNow: history.location.search === '?buyItNow=true',
      }
    }

    return null
  }

  componentWillUnmount() {
    const { clearSalesEngines } = this.props
    clearSalesEngines()
  }

  render() {
    const { isBuyItNow } = this.state
    const { history } = this.props

    return (
      <DashboardLayout className="sales-engin-layout-design">
        {history.location.pathname === '/sales-engines' ? (
          <BuyerSalesEngine history={history} isBuyItNow={isBuyItNow} location={history.location} />
        ) : (
          history.location.pathname === '/sales-engine-archives' && (
            <SalesEngineArchive history={history} isBuyItNow={isBuyItNow} location={history.location} />
          )
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  salesEngine: state.salesEngineReducer.salesEngine,
  getSalesEngineSuccess: state.salesEngineReducer.getSalesEngineSuccess,
})

const mapDispatchToProps = dispatch => ({
  clearSalesEngines: () => dispatch(clearSalesEngines()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEngine)
