export const ratingStaticData = [
  {
    name: 'Communication',
    averageRating: 5,
  },
  {
    name: 'Recommended',
    averageRating: 5,
  },
  {
    name: 'Service',
    averageRating: 5,
  },
  {
    name: 'Organization',
    averageRating: 5,
  },
  {
    name: 'Good Value',
    averageRating: 5,
  },
]

export const documentName = [
  {
    name: 'Bill of Lading',
    keyName: 'billOfLoading',
  },
  {
    name: 'Certificate of Origin',
    keyName: 'certificateOfOrigin',
  },
  {
    name: 'Insurance Certificate',
    keyName: 'insuranceCertificate',
  },
  {
    name: 'Received for Shipment',
    keyName: 'receivedForShipment',
  },
  {
    name: 'Invoice of Shipment Payment',
    keyName: 'invoiceOfShipmentPayment',
  },
  {
    name: 'Shipment Other Document',
    keyName: 'otherDocument',
  },
]

export const searchHeaderData = [
  {
    url: require('../../assets/images/search/all-search.png'),
    name: 'All',
  },
  {
    url: require('../../assets/images/search/new-search.png'),
    name: 'New',
  },
  {
    url: require('../../assets/images/search/used-search.png'),
    name: 'Used',
  },
  {
    url: require('../../assets/images/search/rent-search.png'),
    name: 'Charter & Rent',
  },
  {
    url: require('../../assets/images/search/service-search.png'),
    name: 'Services',
  },
  {
    url: require('../../assets/images/search/marina-search.png'),
    name: 'Marina & Storage',
  },
  {
    url: require('../../assets/images/search/articles-search.png'),
    name: 'Articles',
  },
  {
    url: require('../../assets/images/search/user-search.png'),
    name: 'Users',
  },
]

export const mediaReview = [
  {
    url: require('../../assets/images/search/user1.jpg'),
    createdAt: 'October 2019',
    reviews:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua., sed do eiusmod tempor incididunt ut labore et dolore mag Ut enim ad minim veniam, quis nostrud exercitation ullamco',
    firstName: 'Melinda',
    lastName: 'Williamson',
    reviewerType: 'User Type',
  },
  {
    url: require('../../assets/images/search/user1.jpg'),
    createdAt: 'October 2019',
    reviews:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua., sed do eiusmod tempor incididunt ut labore et dolore mag Ut enim ad minim veniam, quis nostrud exercitation ullamco',
    firstName: 'Melinda',
    lastName: 'Williamson',
    reviewerType: 'User Type',
  },
  {
    url: require('../../assets/images/search/user1.jpg'),
    createdAt: 'October 2019',
    reviews:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua., sed do eiusmod tempor incididunt ut labore et dolore mag Ut enim ad minim veniam, quis nostrud exercitation ullamco',
    firstName: 'Melinda',
    lastName: 'Williamson',
    reviewerType: 'User Type',
  },
  {
    url: require('../../assets/images/search/user1.jpg'),
    createdAt: 'October 2019',
    reviews:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua., sed do eiusmod tempor incididunt ut labore et dolore mag Ut enim ad minim veniam, quis nostrud exercitation ullamco',
    firstName: 'Melinda',
    lastName: 'Williamson',
    reviewerType: 'User Type',
  },
  {
    url: require('../../assets/images/search/user1.jpg'),
    createdAt: 'October 2019',
    reviews:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua., sed do eiusmod tempor incididunt ut labore et dolore mag Ut enim ad minim veniam, quis nostrud exercitation ullamco',
    firstName: 'Melinda',
    lastName: 'Williamson',
    reviewerType: 'User Type',
  },
  {
    url: require('../../assets/images/search/user1.jpg'),
    createdAt: 'October 2019',
    reviews:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua., sed do eiusmod tempor incididunt ut labore et dolore mag Ut enim ad minim veniam, quis nostrud exercitation ullamco',
    firstName: 'Melinda',
    lastName: 'Williamson',
    reviewerType: 'User Type',
  },
]
