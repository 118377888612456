import React, { Component } from 'react'
import { connect } from 'react-redux'

import { DashboardLayout } from '../../../components'
import AgentSalesEngine from './AgentSalesEngine'

import '../../../styles/manageDashboardTableResponsive.scss'

class DealersSalesEngine extends Component {
  state = {
    manufacturerId: null,
  }

  static getDerivedStateFromProps(nextProps) {
    const { match } = nextProps
    const { params } = match && match

    if (params && params.hasOwnProperty('id') && params.id) {
      return {
        manufacturerId: params.id,
      }
    }

    return null
  }

  render() {
    const { history } = this.props
    const { manufacturerId } = this.state

    return (
      <DashboardLayout className="sales-engin-layout-design ">
        <AgentSalesEngine history={history} isDealer manufacturerId={manufacturerId} />
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(DealersSalesEngine)
