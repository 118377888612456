import { Layout } from '../../components'
import ImageBanner from '../../components/mainBanner/ImageBanner'
import { Col, Image, Row } from 'react-bootstrap'
import classNames from 'classnames'
// import data from './data'
import _styles from '../../styles/info-screens.module.scss'
import styles from './styles.module.scss'
import data from './data'
import { Link } from 'react-router-dom'

const RentCharter = ({}) => {
  return (
    <Layout noFooterMargin>
      <div className={_styles.secPadding}>
        <div className="container100">
          <Row>
            <Col sm={12}>
              <h1 className="r-h1">Rent Boat For Private Trips</h1>
            </Col>
          </Row>

         <Row style={{ alignItems: 'center' }}>
            <Col md={8}>
              <h1 className={_styles.fz_t}>Rent and Charter</h1>
              <p className={classNames(_styles.fz_p, 'mt-20')}>
                <span style={{ color: '#018BBC' }}>AdamRent</span> enables boat and yacht owners, or maybe whether they own a
                canoe or a jet ski, to list unlimited boats for rent and charter. Boat owners can sell tickets for private trip,
                shared trip or per-hour trip, turning their boats into a monthly profitable income. AdamSea charges small fees
                for each transaction when the ticket is sold out. The best part is that the boat owner will set up each trip
                with the available date and time slot. This allows the boat owner to schedule his trips for the whole month or
                the year, get all his trips booked in advance, and sell these trips online as tickets. We validate each ticket
                that is sold out by allowing the boat owner to scan the QR code on each ticket before the trip starts.
              </p>
              <p className={classNames(_styles.fz_p, 'mt-20')}>
                Our goal is to keep your boat generating revenue when it is stored and not in use. allowing easy way to pay boat
                mortgage and get a return on your investment faster then ever.
              </p>
            </Col>

            <Col md={4}>
              <div className="">
                <Image src={require('../../assets/images/info-screens/rent-charter--side.svg')} alt="Rent Charter" fluid />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className={_styles.secPadding}>
        <div className="container100">
          <h2 className={_styles.fz_h} style={{ color: '#018BBC' }}>
            Boat owners can sell three different tickets
          </h2>

          <Row>
            <Row>
              {data.map(col => (
                <Col md={6} lg={4}>
                  <div className={styles.rentTypeCardWrp}>
                    <div className={styles.rentTypeCard}>
                      <div>
                        <Image src={col.icon} alt={col.title} fluid style={{ height: 48 }} />
                      </div>
                      <h3 className={classNames(_styles.fz_h, 'mt-20')}>{col.title}</h3>
                      <p className={_styles.fz_p}>{col.text}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Row>
        </div>
      </div>

      <div className={classNames(_styles.secPadding)}>
        <div className="container100">
          <h2 className={_styles.fz_h}>How does boat rental work in AdamRent?</h2>
          <p className={_styles.fz_p + ' mt-20'}>
            <Link to="/register/rent-and-charter" className="btn btn-primary rounded-full">Sign up as rent and charter</Link>
          </p>

          <ol className={'mt-30 ' + _styles.fz_ul_lg}>
            <li>
              <div>
                As a boat owner, you can list your boat, yacht, jet ski, canoe or kayak for rent on AdamRent. You can set up
                your boat details, trip description, price, and policy. You can choose what kind of trip you offer: per hour,
                shared, or private. You can also decide when your boat is available for rent.
              </div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/rent-how-1.png')} alt="Rent boat" fluid />
              </div>
            </li>
            <li>
              <div>Add trip detail. Your boat can have a several trip schedules it can be spread across several months.</div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/rent-how-2.png')} alt="Add trip detail" fluid />
              </div>
            </li>
            <li>
              <div>Check each trip details and each ticket status.</div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/rent-how-3.png')} alt="Check each trip details and each ticket status" fluid />
              </div>
            </li>
            <li>
              <div>Receive payment for every trip you complete and keep track of your earnings.</div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/rent-how-4.png')} alt="Receive payment for every trip you complete and keep track of your earnings" fluid />
              </div>
            </li>
          </ol>
        </div>
      </div>

      <div className={classNames(_styles.secPadding)}>
        <div className="container100">
          <h2 className={_styles.fz_h}>What is included in an AdamRent ticket and how does it look like?</h2>
          <p className={_styles.fz_p + ' mt-20'}>
            An AdamRent ticket is a digital document that allows you to rent a boat, yacht, jet ski, canoe or kayak from a boat
            owner. It contains the following information:
          </p>

          <ul className={'mt-30 ' + _styles.fz_ul_lg}>
            <li>The name and contact details of the boat owner and the renter</li>
            <li>The type and description of the boat</li>
            <li>The date and time of the trip</li>
            <li>The duration and price of the trip</li>
            <li>The trip policy and cancellation terms</li>
            <li>A QR code that can be scanned by the boat owner to validate the ticket</li>
          </ul>

          <div className="mt-50 d-flex justify-content-center">
            <img
              src={require('../../assets/images/image-20240122-071440.png')}
              alt="Rent Ticket"
              style={{ maxWidth: 'auto', maxHeight: 300 }}
            />
          </div>
        </div>
      </div>

      <div className={_styles.secPadding}>
        <div className="container100">
          <Row style={{ alignItems: 'center' }}>
            <Col md={4}>
              <div className="">
                <Image src={require('../../assets/images/info-screens/27637677_7347896.svg')} alt="Rent Charter" fluid />
              </div>
            </Col>
            <Col md={8}>
              <h1 className={_styles.fz_t}>Your Gateway to Global Boat Rentals</h1>
              <p className={classNames(_styles.fz_p, 'mt-20')}>
                At AdamSea, we make it easy for boat owners to rent out their watercrafts and reach a global audience. With our
                secure payment processing and user-friendly platform, boat owners can easily manage their bookings and
                communicate with their guests directly. Plus, our community of boating enthusiasts and businesses ensures that
                your watercrafts will be seen by a wide audience of potential renters.
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default RentCharter
