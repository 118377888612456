import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Layout, PaginationBar } from '../../../components'
import { getLatestBoats, getPopularBoats, getTopRatedBoats } from '../../../redux/actions'
import { pagination, shareModule, titleEnums } from '../../../util/enums/enums'
import BoatListingsWithMap from '../../../components/gridComponents/BoatListingsWithMap'
import { shareDetailsWithUsers } from '../../../redux/actions/shareAction'
import { shareWithUsers, showAllTitle } from '../../../helpers/jsxHelper'

class ShowAllBoats extends Component {
  state = {
    type: '',
    name: '',
    isFlag: true,
    isData: true,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isFlag, type, isData } = prevState
    const { match } = nextProps
    const { params } = match && match

    if (params && params.type && params.type && isFlag) {
      return {
        isFlag: false,
        type: params.type,
      }
    }

    if (type && isData) {
      switch (type) {
        case 'recently-added-boats':
          return { name: 'Our Market Boats', isData: false }
        case 'most-popular-boats':
          return { name: 'Popular Boats', isData: false }
        case 'top-related-boats':
          return { name: 'Top Rated Boats', isData: false }

        default:
          break
      }
    }

    return null
  }

  checkType = type => {
    const { getLatestBoats, getPopularBoats, getTopRatedBoats } = this.props

    switch (type) {
      case 'recently-added-boats':
        getLatestBoats({ page: pagination.PAGE_COUNT, limit: pagination.PAGE_RECORD_LIMIT })
        break

      case 'most-popular-boats':
        getPopularBoats({ page: pagination.PAGE_COUNT, limit: pagination.PAGE_RECORD_LIMIT })
        break

      case 'top-related-boats':
        getTopRatedBoats({ page: pagination.PAGE_COUNT, limit: pagination.PAGE_RECORD_LIMIT })
        break

      default:
        break
    }
  }

  checkAction = () => {
    const { type } = this.state
    const { getLatestBoats, getPopularBoats, getTopRatedBoats } = this.props
    switch (type) {
      case 'recently-added-boats':
        return getLatestBoats
      case 'most-popular-boats':
        return getPopularBoats
      case 'top-related-boats':
        return getTopRatedBoats

      default:
        break
    }
  }

  fetchTotalRecord = () => {
    const { type } = this.state
    const { totalLatestBoats, totalPopularBoats, totalTopRatedBoats } = this.props

    switch (type) {
      case 'recently-added-boats':
        return totalLatestBoats
      case 'most-popular-boats':
        return totalPopularBoats
      case 'top-related-boats':
        return totalTopRatedBoats

      default:
        break
    }
  }

  fetchData = () => {
    const { type } = this.state
    const { latestBoats, popularBoats, topRatedBoats } = this.props

    switch (type) {
      case 'recently-added-boats':
        return latestBoats
      case 'most-popular-boats':
        return popularBoats
      case 'top-related-boats':
        return topRatedBoats

      default:
        break
    }
  }

  shareBoatWithUsers = data => {
    const { shareDetailsWithUsers } = this.props
    const object = shareWithUsers(data, shareModule.boat)
    shareDetailsWithUsers(object)
  }

  render() {
    const { name, type } = this.state
    const { isPageLoader } = this.props

    const title = showAllTitle(
      type === 'recently-added-boats' ? titleEnums.isRecentSellBoat : titleEnums.isBoatForSale,
      this.fetchTotalRecord() || 0,
      name
    )
    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        boatsTypeCount={this.fetchTotalRecord() || 0}
        boatsType={name}
        boats={this.fetchData()}
        shareWithUsers={data => this.shareBoatWithUsers(data)}
        action={this.checkAction()}
        showAllTitle={title}
      />
    )
  }
}

const mapStateToProps = state => ({
  latestBoats: state.boatReducer.latestBoats,
  popularBoats: state.boatReducer.popularBoats,
  topRatedBoats: state.boatReducer.topRatedBoats,
  totalLatestBoats: state.boatReducer.totalLatestBoats,
  totalPopularBoats: state.boatReducer.totalPopularBoats,
  totalTopRatedBoats: state.boatReducer.totalTopRatedBoats,
  isPageLoader: state.boatReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  getLatestBoats: data => dispatch(getLatestBoats(data)),
  getPopularBoats: data => dispatch(getPopularBoats(data)),
  getTopRatedBoats: data => dispatch(getTopRatedBoats(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllBoats)
