import React, { useState } from 'react'
import '../../containers/salesEngine/TermWithIcon.scss'
import { textTruncate } from '../../helpers/string'
import { CommonModal } from '../modal/commonModal'

export const TermWithIcon = React.memo(
  ({
    title,
    titleIsLink,
    icon: Icon,
    image,
    imgWidth,
    imgHeight,
    className,
    descriptions,
    url,
    isDescHTML = false,
    popup = false,
    titleClassName,
    modalClassName,
  }) => {
    const [popupData, setPopupData] = useState('')

    return (
      <>
        <div className={`${className} term--and--condition--icon`}>
          {Icon && <div className="term---icon term--main--icon">{Icon}</div>}
          {image && (
            <div className="term---icon">
              <img loading="lazy" src={image} width={imgWidth} height={imgHeight} alt='Term'/>
            </div>
          )}
          <div className={(titleClassName || "term---title my-3") + (titleIsLink && ' know-more-title-eff')} onClick={() => {
            if (titleIsLink) {
              setPopupData(descriptions)
            }
          }}>{title}</div>
          {titleIsLink || <div className="term---descriptions" dangerouslySetInnerHTML={isDescHTML ? { __html: descriptions } : undefined}>
            {isDescHTML ? undefined : textTruncate(descriptions, 150)}
          </div>}
          {titleIsLink || <div className="term---link--more mt-3">
            {popup ? (
              <a href="javascript:void(0)" className='know-more-eff' style={{ textDecoration: 'underline', color: 'black', fontWeight: 500, fontSize: '1.15em' }} onClick={() => setPopupData(descriptions)}>
                Know more
              </a>
            ) : (
              <a href={url}>Know More</a>
            )}
          </div>}
        </div>

        <CommonModal
          open={!!popupData}
          close={() => setPopupData('')}
          title={title}
          htmlData={isDescHTML ? popupData : undefined}
          className={modalClassName}
        >
          {isDescHTML ? undefined : popupData}
        </CommonModal>
      </>
    )
  }
)

TermWithIcon.defaultProps = {
  icon: null,
}
