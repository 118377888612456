import React, { Fragment } from 'react'
import { CarouselWrapper } from './carouselWrapper'
import { ShowAllLink } from '../helper/showAllLink'
import { BoatServiceDetail } from '../boatService/boatServiceDetail'

import '../home/home.scss'
import { dimension, responsiveCarousel, marginCarousel } from '../../util/enums/enums'
import { getHeightZoomResponsive, getRatioZoomResponsive } from '../../helpers/ratio'
import { getMarginGrid } from '../../helpers/jsxHelper'

export class GalleryCarousal extends React.Component {
  state = {
    width: 275,
    height: 350,
    gutter: 40,
  }

  getNewWidth = () => {
    const width = getRatioZoomResponsive(dimension, 'topRatedBoatServices', '.section-heading')
    const gridWidth = document.querySelector('.section-heading')
    const gutter = getMarginGrid(gridWidth.offsetWidth, marginCarousel.topRatedBoatService)
    this.setState({ width: width.width, gutter })
  }

  getNewHeight = () => {
    const height = getHeightZoomResponsive(dimension, 'topRatedBoatServices', '.section-heading')
    this.setState({ height })
  }

  componentDidMount() {
    this.getNewWidth()
    this.getNewHeight()
    window.addEventListener('resize', this.getNewWidth)
    window.addEventListener('resize', this.getNewHeight)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.getNewWidth)
    window.removeEventListener('resize', this.getNewHeight)
  }
  createChildren = () => {
    const { carouselData } = this.props
    const { width, height } = this.state
    return (
      carouselData &&
      carouselData.map((value, index) => (
        <Fragment key={value.id}>
          <BoatServiceDetail value={value} height={height} width={width} index={index} />
        </Fragment>
      ))
    )
  }

  render() {
    const { carouselData, items, showAllText } = this.props
    const { height, gutter } = this.state

    return (
      <>
        <CarouselWrapper
          items={items}
          height={height}
          isZoom
          responsiveNumber={responsiveCarousel.topRatedServices}
          gutter={gutter}
          classes={{
            itemsWrapper: "adam-card-1-inner"
          }}
        >
          {this.createChildren()}
        </CarouselWrapper>

        <ShowAllLink
          className="service--link--color"
          data={carouselData}
          itemsLength={5}
          showAllText={showAllText}
          url="/show-all-boat-service-gallery"
        />
      </>
    )
  }
}
