export const CARD_ELEMENT_OPTIONS = {
  style: {
    // hidePostalCode: true,
    base: {
      color: '#303238',
      fontSize: '1em',
      fontFamily: 'sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },
}
