import React, { Component, Fragment } from 'react'
import { Grid, Box } from '@material-ui/core'
import '../../../node_modules/video-react/styles/scss/video-react.scss'
import { connect } from 'react-redux'

import {
  getRecentlyAddedServices,
  getMostViewedBoatServices,
  searchYachtService,
  getAllBoatServiceTypes,
  getModuleWiseBanners,
  getExperiences,
  notifyMe,
  clearServiceTypesFlag,
} from '../../redux/actions'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import { getCategoryWiseAdvertisements } from '../../redux/actions/advertisementAction'

import { Layout } from '../../components/layout/layout'
import BoatServicesAdded from '../../components/gridComponents/boatAddedServices'
import RegisterCard from '../../components/staticComponent/registerCard'
import { GalleryCarousal } from '../../components/carouselComponent/relatedGalleryCarousel'
import BoatServiceCard from '../../components/staticComponent/boatServiceCard'
import ExploreAdamSeaIndia from '../../components/carouselComponent/exploreAdamSeaIndia'
import ServicesMarinaStorage from '../../components/staticComponent/servicesMarinaStorage'
import { VideoModel } from '../../components/videoComponent/videoModel'
import { VideoComponent } from '../../components/videoComponent/videoComponent'
import CommonBanner from '../../components/mainBanner/commonBanner'

import {
  pagination,
  moduleCategory,
  mediaCategory,
  dimension,
  advertisementCategory,
  showAllService,
} from '../../util/enums/enums'
import { getCategoryWiseVideos } from '../../redux/actions/VideoAction'
import UserContext from '../../UserContext'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import { objectToQuerystring, redirectRouteHandler } from '../../helpers/routeHelper'
import { lowerHypenCase } from '../../helpers/string'

import './boatServices.scss'
import '../../../src/styles/boatServiceResponsive.scss'
import { clear_getStaticPages, getStaticPages } from '../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'
import HeroSearch from './hero-search'

const galleryCarouselItem = {
  isBrowser: 5,
  isTablet: 2,
  isMobile: 1,
}

const ExploreAdamSeaBoatCarousel = {
  isBrowser: 6,
  isTablet: 2,
  isMobile: 1,
}

class BoatService extends Component {
  static contextType = UserContext

  constructor(props, context) {
    super(props, context)
    this.newShortcuts = [
      {
        keyCode: 32, // spacebar
        disable: false,
        handle: function () {}, // a function that does nothing
      },
    ]
  }
  state = {
    setVideoFlag: false,
    videoUrl: '',
    videoThumbnail: '',
    data: {
      page: pagination.PAGE_COUNT,
      limit: pagination.PAGE_RECORD_LIMIT,
    },
    videoHeight: dimension.boatServiceVideo.height,
    videoWidth: dimension.boatServiceVideo.width,

    queryResult: '',
    isAdmin: true,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isYachtSearched, history } = nextProps
    const { queryResult } = prevState

    if (isYachtSearched) {
      history.push(`/search-boat-services${queryResult && queryResult}`)
    }
    return null
  }

  searchYachtServiceHandler = values => {
    const { searchYachtService } = this.props
    this.setState({ queryResult: objectToQuerystring(values) })
    const { serviceAlias, ...searchValues } = values
    searchYachtService(searchValues)
  }

  playVideo = (url, thumbnail) => {
    this.setState(prevState => ({
      setVideoFlag: !prevState.setVideoFlag,
      videoUrl: url,
      videoThumbnail: thumbnail,
    }))
  }

  closeVideo = () => {
    this.setState({ setVideoFlag: false })
  }
  async componentDidMount() {
    const {
      getAllBoatServiceTypes,
      getRecentlyAddedServices,
      getCategoryWiseVideos,
      getMostViewedBoatServices,
      getModuleWiseBanners,
      getCategoryWiseAdvertisements,
      getExperiences,
    } = this.props
    const { data } = this.state
    getCategoryWiseVideos({
      type: moduleCategory.BOAT_SERVICE,
      metatype: 'video',
    })
    getModuleWiseBanners({
      type: mediaCategory.boatService.type,
      fieldName: mediaCategory.boatService.fieldName,
      isBanner: true,
    })
    getExperiences('service')

    await getAllBoatServiceTypes({ isAdmin: true })
    await getAllBoatServiceTypes({ isAdmin: false })
    if (data) {
      await getRecentlyAddedServices(data)
      await getMostViewedBoatServices(data)
    }
    getCategoryWiseAdvertisements(advertisementCategory.BOAT_SERVICE)
    this.videoWidthZoom()
    window.addEventListener('resize', this.videoWidthZoom)

    this.props.getStaticPages({ slug: 'service-homepage-bottom-651' })
  }
  componentDidUpdate() {
    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }
  }
  videoWidthZoom = () => {
    const videoWidth = getRatio(dimension, 'boatServiceVideo', '.section-heading')

    const videoHeight = getHeightRatio(dimension, 'boatServiceVideo', '.section-heading')
    this.setState({ videoWidth, videoHeight })
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.videoWidthZoom)
  }
  redirectHandler = value => {
    value && redirectRouteHandler(`/category-wise-boat-service/${value.id}/${lowerHypenCase(value.name)}`)
  }

  render() {
    const {
      providedServices,
      totalRecentlyAddedService,
      recentlyAddedService,
      categoryVideos,
      mostViewedBoatServices,
      boatServiceBanner,
      mostViewedBoatServicesTotal,
      notifyMe,
      notifyInput,
      providedAdminServices,
      advertisements,
    } = this.props
    const { setVideoFlag, videoUrl, videoThumbnail, videoWidth, videoHeight } = this.state
    const { country } = this.context
    return (
      <Layout isHeader="boat-service-logo" className="boat-service-layout rent-header-top-space">
        <Grid container>
          <Grid item xs={12}>
            <div className="boat-service-img boat-service-top-space-mob">
              {boatServiceBanner && boatServiceBanner.length > 0 && (
                <>
                  <CommonBanner data={boatServiceBanner} />
                  {/* <div className="service-card-banner">
                    <BoatServiceCard
                      iconBg="boatInner-btnBg"
                      search={this.searchYachtServiceHandler}
                      services={providedAdminServices}
                      notifyMe={notifyMe}
                      notifyInput={notifyInput}
                    />
                  </div> */}

                  <div className="service-card-banner-2">
                    <HeroSearch />
                  </div>
                </>
              )}
            </div>
          </Grid>
        </Grid>
        <div className="container100">
          <Grid container>
            {/* <Grid item xs={12}>
              <h1 className="r-h1 mb-0">
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  Explore AdamSea
                </Box>
              </h1>
              <ServicesMarinaStorage type="boatService" className="mb-20" />
            </Grid> */}

            {providedServices && providedServices.length > 0 && (
              <Grid className="boat-service-arrow boat-service-explore-india-div adam-card-6" item xs={12}>
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  Explore boat services in {country}
                </Box>

                <div className="adam-ex-grid-6 cor-wrp-up">
                  <ExploreAdamSeaIndia
                    customWidthItem={5}
                    items={ExploreAdamSeaBoatCarousel}
                    carouselData={providedServices}
                    btnColor="boatInner-btnBg"
                    gutter={40}
                    getCategoryWiseBoats={value => this.redirectHandler(value)}
                  />
                </div>
              </Grid>
            )}

            {recentlyAddedService && recentlyAddedService.length > 0 && (
              <Grid item xs={12} className="adam-grid-4">
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  Recently added services in {country}
                </Box>
                <BoatServicesAdded
                  boatAddedServices={recentlyAddedService}
                  iconColor="iconColor-boatInner"
                  itemLength={12}
                  total={totalRecentlyAddedService}
                  showType={showAllService.recentlyAdded}
                  showAllText={`recently added services in ${country}`}
                />
              </Grid>
            )}

            {advertisements && advertisements.length > 0 && (
              <Grid item xs={12} className="registerCardCont">
                <Grid container>
                  <Grid item xs={12} className="adam-card-banner">
                    <div>
                      <RegisterCard adData={advertisements[0]} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {mostViewedBoatServices && mostViewedBoatServices.length > 0 && (
              <Grid item xs={12} className="adam-card-1">
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  Top Rated AdamSea Boat Services
                </Box>
                <GalleryCarousal
                  items={galleryCarouselItem}
                  total={mostViewedBoatServicesTotal}
                  carouselData={mostViewedBoatServices}
                  showAllText={`top rated AdamSea boat services`}
                />
              </Grid>
            )}

            {advertisements && advertisements.length > 1 && (
              <Grid item xs={12} className="registerCardCont">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className="double-card">
                      <RegisterCard isDouble isSpace adData={advertisements[1]} />
                    </div>
                  </Grid>
                  {advertisements && advertisements.length > 2 && (
                    <Grid item xs={6}>
                      <div className="double-card">
                        <RegisterCard isDouble isSpace2 adData={advertisements[2]} />
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}

            {categoryVideos && categoryVideos.length > 0 && (
              <Grid item xs={12} className="adam-mov-card-3">
                <Box className="section-heading hide-card-homepage" fontSize={24} fontWeight={500} letterSpacing={0.5}></Box>

                <Grid item xs={12} className="boat-service-video-div">
                  <Grid container spacing={2} className="video-component-grid-layout-service-side video-gapping-space-common">
                    {categoryVideos &&
                      categoryVideos.map((video, index) => {
                        if (index < 3) {
                          return (
                            <Fragment key={video.id}>
                              <VideoComponent
                                setVideoUrlHandler={thum =>
                                  this.playVideo(
                                    video?.url?.[0]?.url,
                                    video.thumbnail?.length > 0 ? video?.thumbnail?.[0]?.url : thum
                                  )
                                }
                                video={video}
                                videoWidth={videoWidth}
                                videoHeight={videoHeight}
                              />
                            </Fragment>
                          )
                        }
                      })}
                  </Grid>
                  <VideoModel
                    videoFlag={setVideoFlag}
                    videoUrl={videoUrl}
                    thumbnail={videoThumbnail}
                    closeVideo={this.closeVideo}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          {get(this.props.staticPages, 'data[0].pageContent', '') && (
            <div style={{ marginTop: 30, padding: '16px 24px', borderRadius: 8, background: '#dff0f2' }}>
              <div className="d-flex">
                <div style={{ flexShrink: 0 }}>
                  <img src={require('../../assets/images/SERVICE.svg')} alt="bell" height={28} style={{ height: 28 }} />
                </div>
                <div style={{ margin: 0, marginLeft: 20, fontSize: 16 }} dangerouslySetInnerHTML={{ __html: get(this.props.staticPages, 'data[0].pageContent', '') }} />
              </div>
            </div>
          )}
        </div>
      </Layout>
    )
  }
}
const mapStateToProps = state => ({
  providedServices: state.boatServiceReducer && state.boatServiceReducer.providedServices,
  recentlyAddedService: state.boatServiceReducer && state.boatServiceReducer.recentlyAddedService,
  totalRecentlyAddedService: state.boatServiceReducer && state.boatServiceReducer.totalRecentlyAddedService,
  user: state.loginReducer && state.loginReducer.currentUser,
  categoryVideos: state.videoReducer && state.videoReducer.categoryVideos,
  mostViewedBoatServices: state.boatServiceReducer && state.boatServiceReducer.mostViewedBoatServices,
  mostViewedBoatServicesTotal: state.boatServiceReducer && state.boatServiceReducer.total,
  isYachtSearched: state.boatServiceReducer.isYachtSearched,
  experience: state.dashboardReducer.experience,
  boatServiceBanner: state.dashboardReducer && state.dashboardReducer[mediaCategory.boatService.fieldName],
  notifyInput: state.dashboardReducer.notifyInput,
  getTypesSuccess: state.boatServiceReducer && state.boatServiceReducer.getTypesSuccess,
  providedAdminServices: state.boatServiceReducer && state.boatServiceReducer.providedAdminServices,
  advertisements: state.dashboardReducer && state.dashboardReducer[mediaCategory.boatService.adField],
  staticPages: state.pageInfoByTypeReducer.staticPages,
})

const mapDispatchToProps = dispatch => ({
  getAllBoatServiceTypes: data => dispatch(getAllBoatServiceTypes(data)),
  getRecentlyAddedServices: data => dispatch(getRecentlyAddedServices(data)),
  getCategoryWiseVideos: data => dispatch(getCategoryWiseVideos(data)),
  getMostViewedBoatServices: data => dispatch(getMostViewedBoatServices(data)),
  searchYachtService: data => dispatch(searchYachtService(data)),
  getModuleWiseBanners: type => dispatch(getModuleWiseBanners(type)),
  getExperiences: data => dispatch(getExperiences(data)),
  notifyMe: data => dispatch(notifyMe(data)),
  clearServiceTypesFlag: () => dispatch(clearServiceTypesFlag()),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
  getCategoryWiseAdvertisements: data => dispatch(getCategoryWiseAdvertisements(data)),
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatService)
