import { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { enqueueSnackbar } from 'notistack'
import cn from 'classnames'
import { uploadFile } from '../../../../helpers/s3FileUpload'
import { MEDIA_TYPE, MEDIA_TYPE_INT_2_STR } from '../../../../util/enums/enums'
import IconClose from '../../assets/icons/close'
import IconGallery from '../../assets/icons/gallery'
import { createPost as qCreatePost } from '../../lib/queries'
import { useBodyScroll } from '../../contexts/body-scroll'
import { useSMDb } from '../../contexts/smdb-ctx'
import EmojiBtn from '../common/emoji-btn'
import compStyles from '../../styles/components.module.scss'
import styles from './create-post-modal.module.scss'
import VideoPlayer from '../post/video-player'

export default function CreatePostModal({ editMode = false, data = null, close }) {
  const { enable: enableBodyScroll, disable: disableBodyScroll } = useBodyScroll()
  const { set: setSMDb } = useSMDb()

  const dMedia = data?.media && [
    ...data.media.map(m => ({
      ...m,
      mediaType: MEDIA_TYPE_INT_2_STR[m.mediaType],
    })),
  ]

  const [content, setContent] = useState(editMode ? data.content : '')
  const [contentErr, setContentErr] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [media, setMedia] = useState(editMode ? dMedia : [])
  const [isCreateLoading, setIsCreateLoading] = useState(false)
  const [mediaUploadPerc, setMediaUploadPerc] = useState(0)

  const textareaRef = useRef(null)

  const adjustHeight = useCallback(() => {
    const textarea = textareaRef.current

    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight > 150 ? 150 : textarea.scrollHeight}px`
    }
  }, [])

  const addEmoji = useCallback(
    emoji => {
      const textarea = textareaRef.current
      const cursorPosition = textarea.selectionStart

      const newContent = content.slice(0, cursorPosition) + emoji + content.slice(cursorPosition)
      setContent(newContent)

      textarea.focus()

      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = cursorPosition + emoji.length
      }, 0)
    },
    [content]
  )

  const selectMedia = useCallback(() => {
    const el = document.createElement('input')
    el.type = 'file'
    el.multiple = true
    el.accept = 'image/*,video/*'
    el.click()

    el.addEventListener('change', async e => {
      if (e.target.files?.length) {
        setIsUploading(true)

        try {
          const perc = Array(e.target.files?.length).fill(0)

          const uploaded = await Promise.all(
            [...e.target.files].map(async (file, i) => {
              if (file.type.startsWith('image/')) {
                const [uploaded] = await uploadFile([file], null, MEDIA_TYPE.IMAGE, false, { maxImageSize: 0 }, p => {
                  perc[i] = p
                  setMediaUploadPerc(perc.reduce((a, b) => a + b, 0) / perc.length)
                })
                return { ...uploaded, mediaType: MEDIA_TYPE.IMAGE }
              } else if (file.type.startsWith('video/')) {
                const [uploaded] = await uploadFile([file], null, MEDIA_TYPE.VIDEO, false, { maxImageSize: 0 }, p => {
                  perc[i] = p
                  setMediaUploadPerc(perc.reduce((a, b) => a + b, 0) / perc.length)
                })
                return { ...uploaded, mediaType: MEDIA_TYPE.VIDEO }
              }
            })
          )

          setMediaUploadPerc(0)

          setMedia(media => [...media, ...uploaded])
        } catch (err) {
          console.log(err)
          enqueueSnackbar("Couldn't upload media")
        }

        setIsUploading(false)
      }
    })
  }, [])

  const removeMedia = useCallback(key => {
    setMedia(m => {
      const i = m.findIndex(_m => _m.key === key)
      m.splice(i, 1)
      return [...m]
    })
  }, [])

  const createPost = useCallback(async ({ _id, content, media }) => {
    if (!content) {
      setContentErr('Please describe your thought!')
      return
    } else {
      setContentErr('')
    }

    setIsCreateLoading(true)

    try {
      await qCreatePost({
        _id,
        content,
        media: media.map(({ url, largeUrl, __typename, ...m }) => m),
      })
      enqueueSnackbar('Posted successfully')
      setSMDb('CreatePost', 'created', true)
      close?.()
    } catch (err) {
      enqueueSnackbar("Couldn't create post")
    }

    setIsCreateLoading(false)
  }, [])

  useEffect(() => {
    adjustHeight()
  }, [])

  useEffect(() => {
    disableBodyScroll()
    return enableBodyScroll
  }, [enableBodyScroll, disableBodyScroll])

  useEffect(() => {
    return () => setSMDb('CreatePost', 'created', false)
  }, [setSMDb])

  return ReactDOM.createPortal(
    <div className={compStyles.overlay}>
      <div className={cn(compStyles.modal, styles.modal)}>
        <div className={compStyles.modalHead}>
          <h2 className={compStyles.modalTitle}>{editMode ? 'Edit' : 'Create'} Post</h2>
          <button className={compStyles.modalCloseBtn} onClick={close}>
            <IconClose className={compStyles.modalCloseBtnIcon} />
          </button>
        </div>

        <div className={styles.modalBody}>
          <textarea
            ref={textareaRef}
            placeholder="What's on your mind?"
            className={styles.contentInp}
            value={content}
            onChange={e => {
              adjustHeight()
              setContent(e.target.value)
            }}
            disabled={isCreateLoading}
          />
          {contentErr && <p className={styles.contentInpErr}>{contentErr}</p>}
        </div>

        <div className={cn(compStyles.modalFooter, styles.modalFooter)}>
          <div className={styles.uploadPerc} style={{ width: mediaUploadPerc + '%' }} />

          <div className={styles.postActBtnsWrp}>
            <button type="button" className={styles.galleryBtn} onClick={selectMedia} disabled={isUploading}>
              {isUploading ? <span className={compStyles.spinner} /> : <IconGallery />}
            </button>
            <EmojiBtn className={styles.emojiBtn} pickerWrpClassname={styles.emojiBtnPickerWrp} addEmoji={addEmoji} />
            <button
              type="button"
              className={styles.postBtn}
              onClick={() => createPost({ _id: editMode ? data._id : null, content, media })}
              disabled={isCreateLoading}
            >
              {isCreateLoading && <span className={compStyles.spinner} />} Post
            </button>
          </div>

          {media.length > 0 && (
            <div className={styles.mediaWrp}>
              {media.map(m => (
                <div key={m.key} className={styles.media}>
                  {m.mediaType === MEDIA_TYPE.IMAGE ? (
                    <img src={m.url} alt={m.key} />
                  ) : (
                    <VideoPlayer src={m.url} className={styles.dashPlayer} muted autoPlay loop />
                  )}
                  <button type="button" className={styles.closeBtn} onClick={() => removeMedia(m.key)}>
                    <IconClose className={styles.icon} />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>,
    document.querySelector('.__as_sm')
  )
}
