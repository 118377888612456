import { gql } from 'apollo-boost'
import {
  TypeCrud,
  Yacht,
  GeometricLocation,
  AddressType,
  Media,
  RatingReview,
  RecentServicesInner,
  YachtListing,
  ServiceProvide,
  ImageType,
  Role,
} from './schemaTypes/schemaTypes'

export const createBoatService = gql`
  mutation createOrUpdateYachtService($input: YachtInput!) {
    createOrUpdateYachtService(input: $input)
       ${Yacht}
  }
`

export const typeWiseService = gql`
  query getYachtServiceByServiceType($page: Int!, $limit: Int!, $typeId: String!, $country: String!, $latLng: CoordinateInput) {
    getYachtServiceByServiceType(page: $page, limit: $limit, typeId: $typeId, country: $country, latLng: $latLng) {
      items ${YachtListing}
      total
    }
  }
`

export const getRecentlyAddedService = gql`
  query getAllYachtServicesByCountry($page: Int!, $limit: Int!, $latLng: CoordinateInput, $id: String) {
    getAllYachtServicesByCountry(page: $page, limit: $limit, latLng: $latLng, id: $id) {
      items ${YachtListing}
      total
    }
  }
`

export const getUserBoatService = gql`
  {
    yachtServiceByUser {
      address ${AddressType}
      id
      service
      featuresAndAdvantages
      qualityOfService
      youtubeUrl
      adId
      status
      adStatus
      rating
      images ${Media}
      serviceProvide ${TypeCrud}
      user {
        companyName
      }
      createdAt
      meta_tags
    }
  }
`

export const getMostViewedBoatServices = gql`
  query mostViewedYachtServices($page: Int!,$limit: Int!) {
    mostViewedYachtServices(page: $page, limit: $limit) {
      items ${YachtListing}
      total
    }
  }
`

export const searchYachtService = gql`
  query searchYachtService($input: YachtSearchInput!) {
    searchYachtService(input: $input) {
      items ${YachtListing}
      total
    }
  }
`

export const getAllBoatServiceTypes = gql`
  query getAllBoatServiceTypes($isAdmin: Boolean = false) {
    getAllBoatServiceTypes(isAdmin: $isAdmin, limit: 1000, page: 1) {
        items ${TypeCrud}
        total
    }
  }
`

export const EditYachtService = gql`
  query EditYachtService($id: String!, $basic: Boolean){
    EditYachtService(id: $id, basic: $basic)
      ${Yacht}

  }`

export const getServiceDetail = gql`
  {
    getServiceDetail
  }
`

export const yachtChangeMarketStatus = gql`
  mutation yachtChangeMarketStatus($input: YachtMarketStatusInput!) {
    yachtChangeMarketStatus(input: $input) {
      id
    }
  }
`

export const addServiceTag_query = gql`
  mutation addServiceTag($serviceId: String!, $serviceProvideId: [String!]!) {
    addServiceTag(serviceId: $serviceId, serviceProvideId: $serviceProvideId) ${Yacht}
  }
`

export const getServiceTags_query = gql`
  query getServiceTags($boatId: String!) {
    getServiceTags(boatId: $boatId) {
      id
      serviceProvide ${ServiceProvide}
      taggedServiceProvide ${ServiceProvide}
      user {
        id
        firstName
        lastName
        companyName
        companyLogo ${ImageType}
      }
    }
  }
`

export const chooseForEstimatedCost_query = gql`
  mutation chooseForEstimatedCost($userId: String!, $chosen: Boolean) {
    chooseForEstimatedCost(userId: $userId, chosen: $chosen)
  }
`

export const isChosenForEstimatedCost_query = gql`
  query isChosenForEstimatedCost($userId: String!) {
    isChosenForEstimatedCost(userId: $userId)
  }
`

export const getServiceProfileForEstimatedCost_query = gql`
  query getServiceProfileForEstimatedCost($boatId: String!) {
    getServiceProfileForEstimatedCost(boatId: $boatId) {
      id
      firstName
      lastName
      address ${AddressType}
      companyName
      companyLogo ${ImageType}
      role ${Role}
      companyWebsite
      image ${ImageType}
    }
  }
`

export const getServiceProfileForYachtFinancing_query = gql`
  query getServiceProfileForYachtFinancing {
    getServiceProfileForYachtFinancing {
      id
      firstName
      lastName
      address ${AddressType}
      companyName
      companyLogo ${ImageType}
      role ${Role}
      companyWebsite
      image ${ImageType}
    }
  }
`

export const chooseForYachtFinancing_query = gql`
  mutation chooseForYachtFinancing($userId: String!, $chosen: Boolean) {
    chooseForYachtFinancing(userId: $userId, chosen: $chosen)
  }
`

export const isChosenForYachtFinancing_query = gql`
  query isChosenForYachtFinancing($userId: String!) {
    isChosenForYachtFinancing(userId: $userId)
  }
`
