import { wishlistModuleEnum } from '../util/enums/enums'
import { getLocalStorageItem } from './storageHelper'

export const addToWishlist = (createWishlist, moduleId = '', moduleType = wishlistModuleEnum.BOAT) => {
  moduleId &&
    createWishlist({
      moduleId,
      moduleType,
      sessionId: getLocalStorageItem('sessionId'),
    })
}

export const getWishlistValue = (id, data = []) => {
  const moduleIds = data && data.length > 0 && data.map(item => item.moduleId?.id)

  return id && moduleIds.length > 0 ? moduleIds.includes(id) : false
}
