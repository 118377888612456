import { useCallback, useEffect, useRef, useState } from 'react'
import { Slide } from 'react-slideshow-image'
import cn from 'classnames'
import * as Icons from '../../assets/icons'
import { MEDIA_TYPE_INT } from '../../../../util/enums/enums'
import { usePostVisible } from '../../contexts/visible-post-ctx'
import { useMuted } from '../../contexts/muted-ctx'
import VideoPlayer from './video-player'
import styles from './media.module.scss'

export default function Media({ post }) {
  const { visiblePostId, onPostVisible, onPostNotVisible } = usePostVisible()
  const { muted, toggle: toggleMuted } = useMuted()

  const [currentSlide, setCurrentSlide] = useState(0)
  const [fullscreen, setFullscreen] = useState(false)

  const isVisibleRef = useRef(visiblePostId === post._id)

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const mediaRef = useRef()

  /** @type {React.MutableRefObject<import('react-slideshow-image').SlideshowRef>} */
  const sliderRef = useRef()

  /** @type {React.MutableRefObject<number>} */
  const sliderTimerRef = useRef()

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const progressBarRef = useRef()

  const onSlideChange = useCallback(
    async (prev, next) => {
      clearTimeout(sliderTimerRef.current)

      const prevSlide = post.media[prev]

      if (prevSlide.mediaType === MEDIA_TYPE_INT.VIDEO) {
        /** @type {HTMLVideoElement} */
        const video = document.querySelector(`video[data-id="post-${post._id}-video-${prev}"]`)

        if (video) {
          video.pause()
        }
      }

      if (progressBarRef.current) {
        progressBarRef.current.style.transitionDuration = '0s'
        progressBarRef.current.style.width = 0
      }

      setCurrentSlide(next)

      const currentSlide = post.media[next]

      if (currentSlide.mediaType === MEDIA_TYPE_INT.VIDEO) {
        /** @type {HTMLVideoElement} */
        const video = document.querySelector(`video[data-id="post-${post._id}-video-${next}"]`)

        if (video) {
          await video.play()

          if (progressBarRef.current) {
            progressBarRef.current.style.transitionDuration = `${video.duration}s`
            progressBarRef.current.style.width = 'calc(100% - 40px)'
          }

          video.onended = null
          video.onended = () => {
            if (isVisibleRef.current && next + 1 < post.media.length) {
              sliderRef.current.goNext()
            } else {
              sliderRef.current.goTo(0)
            }
          }
        }
      } else {
        if (progressBarRef.current) {
          setTimeout(() => {
            progressBarRef.current.style.transitionDuration = '3s'
            progressBarRef.current.style.width = 'calc(100% - 40px)'
          }, 0)
        }

        sliderTimerRef.current = setTimeout(() => {
          if (isVisibleRef.current) {
            if (next + 1 >= post.media.length) {
              sliderRef.current.goTo(0)
            } else {
              sliderRef.current.goNext()
            }
          }
        }, 3000)
      }
    },
    [post]
  )

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.7) {
            onPostVisible(post._id)
          } else {
            onPostNotVisible(post._id)
          }
        })
      },
      { threshold: 0.7 }
    )

    if (mediaRef.current) {
      observer.observe(mediaRef.current)
    }

    return () => {
      if (mediaRef.current) {
        observer.unobserve(mediaRef.current)
      }
    }
  }, [post, onPostVisible, onPostNotVisible, mediaRef])

  useEffect(() => {
    isVisibleRef.current = visiblePostId === post._id

    if (isVisibleRef.current) {
      sliderRef.current.goTo(0)
    } else {
      if (progressBarRef.current) {
        progressBarRef.current.style.transitionDuration = '0s'
        progressBarRef.current.style.width = 0
      }

      const videos = mediaRef.current.querySelectorAll('video')

      videos.forEach(video => {
        if (video) {
          video.pause()
          video.currentTime = 0
        }
      })
    }
  }, [visiblePostId, post])

  useEffect(() => {
    return () => clearTimeout(sliderTimerRef.current)
  }, [])

  return (
    <div ref={mediaRef} className={styles.postMediasWrp}>
      <Slide
        ref={sliderRef}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
        infinite={false}
        autoplay={false}
        transitionDuration={300}
        canSwipe={post.media.length > 1}
        onChange={onSlideChange}
      >
        {post.media.map((m, i) => (
          <div key={m._id} className={styles.postMediaWrp} style={{ maxHeight: fullscreen ? '100vh' : '90vh' }}>
            {m.mediaType === MEDIA_TYPE_INT.IMAGE ? (
              <img src={m.largeUrl} alt={post.user.firstName} className={styles.postMedia} />
            ) : (
              <VideoPlayer
                src={visiblePostId === post._id && m.url}
                muted={muted}
                data-id={`post-${post._id}-video-${i}`}
                className={styles.postMedia}
              />
            )}
          </div>
        ))}
      </Slide>

      <div className={styles.mediaActBtns}>
        {post.media.length > 1 && (
          <div className={styles.mediaActBtnGrp}>
            <button
              className={cn(styles.mediaActBtn, styles.mediaActIconBtn)}
              onClick={() => {
                if (currentSlide == 0) {
                  sliderRef.current.goTo(post.media.length - 1)
                } else {
                  sliderRef.current.goBack()
                }
              }}
            >
              <Icons.ArrowLeft className={styles.mediaActBtnIcon} />
            </button>

            <button className={cn(styles.mediaActBtn, styles.mediaActBtnSlidesCount, styles.mediaActBtnStatic)}>
              {currentSlide + 1} / {post.media.length}
            </button>

            <button
              className={cn(styles.mediaActBtn, styles.mediaActIconBtn)}
              onClick={() => {
                if (currentSlide + 1 >= post.media.length) {
                  sliderRef.current.goTo(0)
                } else {
                  sliderRef.current.goNext()
                }
              }}
            >
              <Icons.ArrowRight className={styles.mediaActBtnIcon} />
            </button>
          </div>
        )}

        {post.media[currentSlide]?.mediaType === MEDIA_TYPE_INT.VIDEO && (
          <button className={cn(styles.mediaActBtn, styles.mediaActIconBtn)} onClick={toggleMuted}>
            {muted ? (
              <Icons.VolumeMute className={styles.mediaActBtnIcon} />
            ) : (
              <Icons.Volume className={styles.mediaActBtnIcon} />
            )}
          </button>
        )}

        <button
          className={cn(styles.mediaActBtn, styles.mediaActIconBtn)}
          onClick={() => {
            const element = mediaRef.current

            const isFullscreen =
              document.fullscreenElement ||
              document.mozFullScreenElement ||
              document.webkitFullscreenElement ||
              document.msFullscreenElement

            if (!isFullscreen) {
              if (element.requestFullscreen) {
                element.requestFullscreen()
              } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen()
              } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen()
              } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen()
              }

              setFullscreen(true)
            } else {
              if (document.exitFullscreen) {
                document.exitFullscreen()
              } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
              } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
              } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
              }

              setFullscreen(false)
            }
          }}
        >
          <Icons.Expand className={styles.mediaActBtnIcon} />
        </button>
      </div>

      {(post.media.length > 1 || post.media?.[0]?.mediaType === MEDIA_TYPE_INT.VIDEO) && (
        <div ref={progressBarRef} className={styles.progressBar} />
      )}
    </div>
  )
}
