import {
  CLEAR_MARINA_FLAG,
  ADD_MARINA_AND_STORAGE,
  ADD_MARINA_AND_STORAGE_SUCCESS,
  ADD_MARINA_AND_STORAGE_FAILURE,
  GET_ALL_MARINA,
  GET_ALL_MARINA_SUCCESS,
  GET_ALL_MARINA_FAILURE,
  GET_TYPE_WISE_LOOKUP_SUCCESS,
  GET_TYPE_WISE_LOOKUP_FAILURE,
  GET_TYPE_WISE_LOOKUP,
  GET_EXPLORE_MARINA,
  GET_EXPLORE_MARINA_SUCCESS,
  GET_EXPLORE_MARINA_FAILURE,
  GET_USER_MARINA_STORAGE,
  GET_USER_MARINA_STORAGE_SUCCESS,
  GET_USER_MARINA_STORAGE_FAILURE,
  GET_RECENTLY_ADDED_MARINA_STORAGE,
  GET_RECENTLY_ADDED_MARINA_STORAGE_SUCCESS,
  GET_RECENTLY_ADDED_MARINA_STORAGE_FAILURE,
  GET_SINGLE_MARINA_STORAGE,
  GET_SINGLE_MARINA_STORAGE_SUCCESS,
  GET_SINGLE_MARINA_STORAGE_FAILURE,
  UPDATE_MARINA_STORAGE,
  UPDATE_MARINA_STORAGE_SUCCESS,
  UPDATE_MARINA_STORAGE_FAILURE,
  CLEAR_EDIT_MARINA_FLAG,
  DELETE_MARINA_STORAGE,
  DELETE_MARINA_STORAGE_SUCCESS,
  DELETE_MARINA_STORAGE_FAILURE,
  GET_MOST_VIEWED_MARINA_STORAGE,
  GET_MOST_VIEWED_MARINA_STORAGE_SUCCESS,
  GET_MOST_VIEWED_MARINA_STORAGE_FAILURE,
  SEARCH_MARINA_AND_STORAGE,
  SEARCH_MARINA_AND_STORAGE_SUCCESS,
  SEARCH_MARINA_AND_STORAGE_FAILURE,
  CLEAR_SEARCH_MARINA_AND_STORAGE_FLAG,
  GET_MORE_SERVICE,
  GET_MORE_SERVICE_SUCCESS,
  GET_MORE_SERVICE_FAILURE,
  GET_TOP_RATED_MARINA_STORAGE,
  GET_TOP_RATED_MARINA_STORAGE_SUCCESS,
  GET_TOP_RATED_MARINA_STORAGE_FAILURE,
  GET_MARINA_STORAGE_All_SERVICES,
  GET_MARINA_STORAGE_All_SERVICES_SUCCESS,
  GET_MARINA_STORAGE_All_SERVICES_FAILURE,
  GET_MARINA_BY_TYPE,
  GET_MARINA_BY_TYPE_SUCCESS,
  GET_MARINA_BY_TYPE_FAILURE,
  ADD_LIKE,
  ADD_LIKE_SUCCESS,
  ADD_LIKE_FAILURE,
  CHANGE_MARINA_STATUS,
  CHANGE_MARINA_STATUS_SUCCESS,
  CHANGE_MARINA_STATUS_FAILURE,
} from '../actionTypes'
import { lookupTypes } from '../../util/enums/enums'
import { filterLikesReducerArray } from '../../helpers/string'

const InitialState = {
  loading: false,

  editMarina: {},

  success: false,
  error: false,
  createError: false,
  createSuccess: false,
  marinaStorage: [],
  totalMarinaStorage: null,
  getSuccess: false,
  getError: false,
  marinaTypeWiseLookUps: [],
  exploreMarina: [],
  totalExploreMarina: null,
  getRecentlySuccess: false,
  getRecentlyError: false,
  recentMarinaStorage: [],
  singleSuccess: false,
  singleError: false,
  isLoading: false,
  updateSuccess: false,
  updateError: false,
  mostViewedSuccess: false,
  mostViewedFailure: false,

  searchedMarinaStorage: [],
  totalSearchedMarinaStorage: null,
  isMarinaStorageSearched: false,

  moreMarinaService: [],
  topRatedMarinaStorage: [],
  topRatedMarinaTotal: 0,
  marinaServiceList: [],
  mostViewedMarinaStorage: [],
  marinaServiceListTotal: 0,
  marinaByType: [],
  changeStatusSuccess: false,
  changeStatusError: false,
  totalRecentMarinaCount: 0,
}

export const marinaAndStorageReducer = (state = InitialState, action) => {
  const { editMarina, marinaStorage } = state

  switch (action.type) {
    // START

    case GET_TYPE_WISE_LOOKUP:
      return {
        ...state,
        success: false,
        error: false,
      }

    case GET_TYPE_WISE_LOOKUP_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        marinaTypeWiseLookUps: action.value === lookupTypes.YOU_ARE_AN ? action.payload : [],
      }

    case GET_TYPE_WISE_LOOKUP_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }

    // END

    // START GET EXPLORE MARINA

    case GET_EXPLORE_MARINA:
      return {
        ...state,
        success: false,
        error: false,
        isPageLoader: true,
      }

    case GET_EXPLORE_MARINA_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        exploreMarina: action.payload.data.getMarinasByType.items,
        totalExploreMarina: action.payload.data.getMarinasByType.total,
        isPageLoader: false,
      }

    case GET_EXPLORE_MARINA_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        isPageLoader: false,
      }

    // END GET MARINA EXPLORE

    case ADD_MARINA_AND_STORAGE:
      return {
        ...state,
        createSuccess: false,
        createError: false,
      }

    case ADD_MARINA_AND_STORAGE_SUCCESS:
      return {
        ...state,
        createSuccess: true,
        createError: false,
      }

    case ADD_MARINA_AND_STORAGE_FAILURE:
      return {
        ...state,
        createSuccess: false,
        createError: true,
      }
    case CLEAR_MARINA_FLAG:
      return {
        ...state,
        isLoading: false,
        createSuccess: false,
        createError: false,

        success: false,
        error: false,

        getRecentlySuccess: false,
        getRecentlyError: false,

        singleSuccess: false,
        singleError: false,

        updateSuccess: false,
        updateError: false,

        deleteSuccess: false,
        deleteError: false,

        mostViewedSuccess: false,
        mostViewedFailure: false,

        changeStatusSuccess: false,
        changeStatusError: false,
      }
    case GET_ALL_MARINA:
      return {
        ...state,
        success: false,
        error: false,
      }
    case GET_ALL_MARINA_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
      }
    case GET_ALL_MARINA_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }

    case GET_USER_MARINA_STORAGE:
      return {
        ...state,
        loading: true,
        getSuccess: false,
        getError: false,
      }
    case GET_USER_MARINA_STORAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        getSuccess: true,
        getError: false,
        marinaStorage: action.payload.data.marinaStorageByUser.items,
        totalMarinaStorage: action.payload.data.marinaStorageByUser.total,
      }
    case GET_USER_MARINA_STORAGE_FAILURE:
      return {
        ...state,
        loading: false,
        getSuccess: false,
        getError: true,
      }

    case GET_RECENTLY_ADDED_MARINA_STORAGE:
      return {
        ...state,
        getRecentlySuccess: false,
        getRecentlyError: false,
        isPageLoader: true,
      }
    case GET_RECENTLY_ADDED_MARINA_STORAGE_SUCCESS:
      return {
        ...state,
        getRecentlySuccess: true,
        getRecentlyError: false,
        recentMarinaStorage: action.payload.data.nearestMarinaByCountry.items,
        totalRecentMarinaCount: action.payload.data.nearestMarinaByCountry.total,
        isPageLoader: false,
      }
    case GET_RECENTLY_ADDED_MARINA_STORAGE_FAILURE:
      return {
        ...state,
        getRecentlySuccess: false,
        getRecentlyError: true,
        isPageLoader: false,
      }
    case GET_SINGLE_MARINA_STORAGE:
      return {
        ...state,
        isLoading: true,
        singleSuccess: false,
        singleError: false,
        editMarina: {},
      }
    case GET_SINGLE_MARINA_STORAGE_SUCCESS:
      return {
        ...state,
        singleSuccess: true,
        isLoading: false,
        singleError: false,
        editMarina: action.payload.data.editMarina,
      }
    case GET_SINGLE_MARINA_STORAGE_FAILURE:
      return {
        ...state,
        singleSuccess: false,
        isLoading: false,
        singleError: true,
        editMarina: {},
      }
    case UPDATE_MARINA_STORAGE:
      return {
        ...state,
        isLoading: true,
        updateSuccess: false,
        updateError: false,
      }
    case UPDATE_MARINA_STORAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateSuccess: true,
        updateError: false,
      }
    case UPDATE_MARINA_STORAGE_FAILURE:
      return {
        ...state,
        updateSuccess: false,
        updateError: true,
        isLoading: false,
      }
    case DELETE_MARINA_STORAGE:
      return {
        ...state,
        isLoading: true,
        deleteSuccess: true,
        deleteError: false,
        marinaStorage: marinaStorage.filter(item => item.id !== action.payload.id),
      }
    case DELETE_MARINA_STORAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteSuccess: true,
        deleteError: false,
        marinaStorage: marinaStorage.filter(item => item.id !== action.payload.id),
      }
    case DELETE_MARINA_STORAGE_FAILURE:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
        isLoading: false,
      }
    case CLEAR_EDIT_MARINA_FLAG:
      return {
        ...state,
        editMarina: {},
      }
    case GET_MOST_VIEWED_MARINA_STORAGE:
      return {
        ...state,
        mostViewedSuccess: false,
        mostViewedFailure: false,
        isPageLoader: true,
      }
    case GET_MOST_VIEWED_MARINA_STORAGE_SUCCESS:
      return {
        ...state,
        mostViewedSuccess: true,
        mostViewedFailure: false,
        mostViewedMarinaStorage: action.payload.getMostPopularMarina.items,
        mostViewedMarinaStorageTotal: action.payload.getMostPopularMarina.total,
        isPageLoader: false,
      }
    case GET_MOST_VIEWED_MARINA_STORAGE_FAILURE:
      return {
        ...state,
        mostViewedSuccess: false,
        mostViewedFailure: true,
        isPageLoader: false,
      }

    case SEARCH_MARINA_AND_STORAGE:
      return {
        ...state,
        isMarinaStorageSearched: false,
        // searchedMarinaStorage: [],
        isPageLoader: true,
      }

    case SEARCH_MARINA_AND_STORAGE_SUCCESS:
      return {
        ...state,
        isMarinaStorageSearched: true,
        searchedMarinaStorage: action.payload.items,
        totalSearchedMarinaStorage: action.payload.total,
        isPageLoader: false,
      }

    case SEARCH_MARINA_AND_STORAGE_FAILURE:
      return {
        ...state,
        isMarinaStorageSearched: false,
        searchedMarinaStorage: [],
        isPageLoader: false,
      }

    case CLEAR_SEARCH_MARINA_AND_STORAGE_FLAG:
      return {
        ...state,
        isMarinaStorageSearched: false,
      }
    case GET_MORE_SERVICE:
      return {
        ...state,
        moreMarinaService: [],
      }
    case GET_MORE_SERVICE_SUCCESS:
      return {
        ...state,
        moreMarinaService: action.payload,
      }
    case GET_MORE_SERVICE_FAILURE:
      return {
        ...state,
        moreMarinaService: [],
      }

    case GET_TOP_RATED_MARINA_STORAGE:
      return {
        ...state,
        topRatedMarinaStorage: [],
        topRatedMarinaTotal: 0,
        isPageLoader: true,
      }
    case GET_TOP_RATED_MARINA_STORAGE_SUCCESS:
      return {
        ...state,
        topRatedMarinaStorage: action.payload.items,
        topRatedMarinaTotal: action.payload.total,
        isPageLoader: false,
      }
    case GET_TOP_RATED_MARINA_STORAGE_FAILURE:
      return {
        ...state,
        topRatedMarinaStorage: [],
        topRatedMarinaTotal: 0,
        isPageLoader: false,
      }

    /** Listing of services  */
    case GET_MARINA_STORAGE_All_SERVICES:
      return {
        ...state,
        marinaServiceListTotal: 0,
        isPageLoader: true,
      }
    case GET_MARINA_STORAGE_All_SERVICES_SUCCESS:
      return {
        ...state,
        marinaServiceList: action.payload ? action.payload.items : [],
        marinaServiceListTotal: action.payload ? action.payload.total : 0,
        isPageLoader: false,
      }
    case GET_MARINA_STORAGE_All_SERVICES_FAILURE:
      return {
        ...state,
        marinaServiceList: [],
        marinaServiceListTotal: 0,
        isPageLoader: false,
      }

    case GET_MARINA_BY_TYPE:
      return {
        ...state,
        success: false,
        error: false,
        // marinaByType: [],
        isLoading: true,
      }

    case GET_MARINA_BY_TYPE_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        marinaByType: action.payload.items,
        total: action.payload.total,
        isLoading: false,
      }

    case GET_MARINA_BY_TYPE_FAILURE:
      return {
        ...state,
        success: false,
        error: false,
        isLoading: false,
      }

    case ADD_LIKE:
      return {
        ...state,
      }

    case ADD_LIKE_SUCCESS:
      return {
        ...state,
        editMarina: {
          ...editMarina,
          likes: filterLikesReducerArray(editMarina.likes, action.payload, action.moduleType),
        },
      }

    case ADD_LIKE_FAILURE:
      return {
        ...state,
      }

    case CHANGE_MARINA_STATUS:
      return {
        ...state,
      }

    case CHANGE_MARINA_STATUS_SUCCESS:
      const marinaId = action.payload?.id
      return {
        ...state,
        marinaStorage: marinaStorage.map(item => (item.id === marinaId ? { ...item, status: !item.status } : item)),
        changeStatusSuccess: true,
      }

    case CHANGE_MARINA_STATUS_FAILURE:
      return {
        ...state,
        changeStatusError: true,
      }

    default:
      return state
  }
}
