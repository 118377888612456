import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Truncate from 'react-truncate'
import Rating from 'react-rating'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

import { defaultImage, marketTypes } from '../../../util/enums/enums'
import { formattedDate } from '../../../helpers/dateTimeHelper'
import RatingComponent from '../../rating/Rating'

export const YachtSearchResults = ({ data }) => {
  const {
    images,
    service,
    featuresAndAdvantages,
    createdAt,
    user,
    address: [{ country }],
    rating,
    pageVisits,
  } = data

  return (
    <Row>
      <Col xs={2}>
        <div className="d-flex justify-content-center m-auto">
          <div className="boat-search-result-img d-flex justify-content-center flex-column align-items-center">
            <img loading="lazy" src={(images && images[0]?.url) || defaultImage} alt="boat" className="h-100 width-100" />
          </div>
        </div>
      </Col>

      <Col xs={6}>
        <div className="d-flex flex-column justify-content-around h-100">
          <div>
            <span className="boat-title">
              <Truncate lines={2} ellipsis={<span>..</span>}>
                {service}
              </Truncate>
            </span>
          </div>
          <div>
            <span className="boat-country">
              <Truncate lines={2} ellipsis={<span>..</span>}>
                {featuresAndAdvantages}
              </Truncate>
            </span>
          </div>
          <div>
            <span className="font-14 font-weight-400 search-div-date">
              {`${formattedDate(createdAt)} -`} {user && `Posted By ${user.firstName} ${user.lastName}`}
            </span>
          </div>
          <div>
            <span className="font-14 font-weight-500 search-div-country">{country}</span>
          </div>
        </div>
      </Col>

      <Col xs={1}>
        <div className="d-flex justify-content-center search-result-market-type-section">
          <span className="font-weight-400 search-result-market-type">{marketTypes.YACHT_SERVICE}</span>
        </div>
      </Col>

      <Col xs={1}>
        <div className="d-flex justify-content-center">
          <div className="d-flex">
            <div className="mr-1 search-result-rate-section-margin">
              <RatingComponent className="rating-clr search-result-rate-section" rating={rating} />
            </div>
            {/* <span className="rating-count">{rating}</span> */}
          </div>
        </div>
      </Col>

      <Col xs={2}>
        <div className="d-flex justify-content-center">
          <span className="search-result-views-count">views {pageVisits && pageVisits.length}</span>
        </div>
      </Col>
    </Row>
  )
}
