import React, { useContext, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import ImageUploader from 'react-images-upload'
import { useDispatch, useSelector } from 'react-redux'
import { getIsAddGroupName, getIsEditGroupScreen, getIsFriendView, getIsGroupChatCreateScreen, getIsUpdateGroup, getSelectedGroupIds, getSelectUser } from '../../redux/selector/chat'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { addOrRemoveUsersFromChat, backToChatList, chatToggle, clearUserSearch, createChatById, editGroupScreen, getChatListsByCurrentUser, getFriendUsersByCurrentUser, setGroupChatFlag, setIsAddGroupName, setSelectedGroupIds, setSelectUser, updateChat } from '../../redux/actions/chatAction';
import { getFirstCharacter } from '../../helpers/jsxHelper';
import { ChatContext } from '../../contexts/ChatContext';
import classNames from 'classnames';
import { lowerHypenCase, nameFormatter } from '../../helpers/string';
import OutsideClickHandler from 'react-outside-click-handler';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CheckIcon from '@material-ui/icons/Check';
import { Field } from '../ws/Field';
import { uploadChatImages } from '../../helpers/s3FileUpload';
import { Loader } from '../loader/loader';
import { chatType } from '../../util/chat';
import { ChatAction, rolesEnum } from '../../util/enums/enums';
import UserContext from '../../UserContext';
import { CommonTooltip } from '../CommonTooltip';
import { socket } from '../../socket'
import { socketEvent } from '../../util/enums/socketEnums'
import { Chat, ChatOutlined, Contacts, ContactsOutlined, GroupAdd, GroupAddOutlined, NotificationsActive, NotificationsOffOutlined } from '@mui/icons-material'
import { getLocalStorageItem, setLocalStorageItem } from '../../helpers/storageHelper'

export const CurrentUser = () => {
    const { currentUser } = useContext(ChatContext);
    const { history } = useContext(UserContext)
    const isUpdateGroup = useSelector(getIsUpdateGroup)
    const notify = getLocalStorageItem('isNotification') === "on"
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [isAllMessageOpen, setIsAllMessageOpen] = useState(false)
    const [groupName, setGroupName] = useState('')
    const [isMaxLength, setIsMaxLength] = useState(false)
    const [groupImage, setGroupImage] = useState(null)
    const [notification, setNotification] = useState(notify)
    const selectedGroupIds = useSelector(getSelectedGroupIds)
    const selectUser = useSelector(getSelectUser)
    const isGroup = useSelector(getIsGroupChatCreateScreen)
    const isAddGroupName = useSelector(getIsAddGroupName)
    const isEditGroupScreen = useSelector(getIsEditGroupScreen)
    const isFriendView = useSelector(getIsFriendView)

    const toggleAllMessageHandler = () => setIsAllMessageOpen(isAllMessageOpen => !isAllMessageOpen)

    const notificationHandler = () => {
        setLocalStorageItem('isNotification', !notification ? "on" : "off")
        setNotification(!notification)
    }

       useEffect(() => {
        if (isEditGroupScreen) {
            setGroupName(selectUser?.name)
            setGroupImage({blobImage : selectUser?.imageUrl})
        }
    },[selectUser, isEditGroupScreen])

    useEffect(()=>{
        if(isGroup && !isEditGroupScreen) {
            setGroupName('')
        }
    },[isGroup])

        useEffect(()=>{
            if(isUpdateGroup){
                dispatch(getChatListsByCurrentUser({ limit: 1000, searchTerm: '' }))
            }
        },[isUpdateGroup])

    const uploadMultipleImages = async (files) => {
        setIsLoading(true)
        const res = await uploadChatImages(files, '', 'image')
        setIsLoading(false)
        if (res.length) {
            setGroupImage({
                blobImage: res[0].url,
                image: res[0].name,
            })

        }
    };

    return (
        <React.Fragment>
            <div
            id='chat-left-space-mobile-handel'
            className="chat--left--div--spacing">
                {isGroup ?
                    <React.Fragment>
                        {isAddGroupName ?
                            <div
                             className='chat-group--profile'>
                                <CommonTooltip component={ctProps => (
                                    <div className='chat-group--icon' onClick={() => {
                                        dispatch(setIsAddGroupName(false))
                                    }} data-tooltip-id={ctProps.id} data-tooltip-content="Back">
                                        <KeyboardBackspaceIcon />
                                    </div>
                                )} />
                                <div
                                 className='chat-group--image'>
                                        <span className='chat-group--image__text'>Group Image</span>
                                    <div className={`chat-img-uploader upload-img-circle single-image dashboard--form--img--uploader`}>
                                        {groupImage?.blobImage ? <img loading="lazy" src={groupImage.blobImage} alt='Image' /> :
                                            <img loading="lazy" src={require('../ws/Images/placeholder.png')} alt='placeholder image' />}
                                        <ImageUploader
                                            withIcon={false}
                                            withLabel={false}
                                            buttonStyles={{
                                                'backgroundImage': `url(${require('../ws/Images/camera.png')})`,
                                            }}
                                            fileTypeError=""
                                            singleImage
                                            buttonText=''
                                            onChange={uploadMultipleImages}
                                            accept="image/*"
                                            // imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                            maxFileSize={5242880}
                                        />
                                        {isLoading && <div className='chat-img-loader-img'>

                                     <Loader isSmall />
                                        </div>}
                                    </div>
                                </div>
                                <div className='chat-group--input'>
                                    <Field
                                        type='text'
                                        label='Add a group name'
                                        value={groupName}
                                        onChangeText={(e) => {
                                            if(e.target.value.length === 16) {
                                                setIsMaxLength(true)
                                                return
                                            }
                                            setGroupName(e.target.value)
                                            setIsMaxLength(false)
                                        }}
                                    />
                                    {isMaxLength && <span className='error-message'>Maximum 15 character allowed.</span>}
                                    {groupName && (
                                        <CommonTooltip component={ctProps => (
                                            <div className='group-create--icon' onClick={() => {
                                                if(isEditGroupScreen){
                                                    dispatch(updateChat({
                                                        chatId: selectUser.chatId,
                                                        name: groupName,
                                                        ...groupImage?.image&&({imageUrl: groupImage?.image}),
                                                    }))
                                                 }else{
                                                    dispatch(createChatById({
                                                        name: groupName,
                                                        imageUrl: groupImage?.image ?? '',
                                                        // chatId:selectUser.chatId,
                                                        description:"",
                                                        channelUrl:uuid(),
                                                        chatType: chatType.groupChat,
                                                        members: selectedGroupIds.map((i) => i.id)
        
                                                    }))
                                                    dispatch(setSelectUser({}))
                                                }
                                                dispatch(setGroupChatFlag(false))
                                                dispatch(setIsAddGroupName(false))
                                                setGroupImage(null)
                                                setGroupName('')
        
                                            }} data-tooltip-id={ctProps.id} data-tooltip-content="Save">
                                                <CheckIcon />
                                            </div>
                                        )} />
                                    )}
                                </div>
                            </div>
                            :
                            <div className='chat-group-text'>
                                <CommonTooltip component={ctProps => (
                                     <div className='chat-group--icon' onClick={() => {
                                        dispatch(setGroupChatFlag(false))
                                        if(isEditGroupScreen){
                                            dispatch(clearUserSearch())
                                            dispatch(editGroupScreen(false))
                                        }
                                        currentUser?.id && dispatch(getChatListsByCurrentUser({ limit: 1000, searchTerm: '' }))
                                        dispatch(setSelectedGroupIds([]))
                                        dispatch(clearUserSearch())
                                    }} data-tooltip-id={ctProps.id} data-tooltip-content="Back">
                                        <KeyboardBackspaceIcon />
                                    </div>
                                )} />
                                <div className='chat-group--label'>Add Group Participants</div>
                                {selectedGroupIds?.length > 0 && (
                                    <CommonTooltip component={ctProps => (
                                        <div
                                            className='create--chat-group-name'
                                            onClick={() => dispatch(setIsAddGroupName(true))}
                                            data-tooltip-id={ctProps.id}
                                            data-tooltip-content="Next">
                                            <NavigateNextIcon />
                                        </div>
                                    )} />
                                )}
                            </div>}
                    </React.Fragment>
                    : <div id='left-head-div-mobile'
                    className="chat--left--div--title">
                        <div className="chat--owner--info">
                            <div className="chat--owner--image" onClick={() => {

                                history.push(`/user-profile/${currentUser?.appUserId}/${lowerHypenCase(currentUser.name)}`)
                                socket.emit(socketEvent.LeaveChannel,selectUser?.chatId)
                                dispatch(chatToggle(false))
                            }
                                }>
                                {currentUser?.profileImage ? (
                                    <img loading="lazy" src={currentUser.profileImage} alt="profile" />
                                ) : (
                                    getFirstCharacter(currentUser?.name)
                                )}
                                <div className="chat--online">
                                    <div className="chat--online">

                                        <div className={classNames("online-div-user", {
                                            // currentUser?.isOnline
                                            'disconnect': false,
                                        })}></div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="chat--owner--name">
                                <span>{nameFormatter([currentUser?.name])}</span>
                                <span>{rolesEnum[currentUser?.metaData?.userType]}</span>
                            </div> */}
                        </div>
                        <div>
                            <div className="chat-left-icn-btns-grp">
                                <CommonTooltip component={ctProps => (
                                    <button data-tooltip-id={ctProps.id} data-tooltip-content="Notification" className="chat-left-icn-btn"
                                    onClick={() => notificationHandler()}
                                    >
                                        {notification ? <NotificationsActive /> : <NotificationsOffOutlined />}
                                    </button>
                                )} />
                                <CommonTooltip component={ctProps => (
                                    <button data-tooltip-id={ctProps.id} data-tooltip-content="Chat List" className="chat-left-icn-btn" onClick={() => {
                                        isFriendView ? dispatch(backToChatList(false)) : dispatch(getFriendUsersByCurrentUser())
                                    }}>
                                        {isFriendView ? <ChatOutlined /> : <Chat className="chat-left-icn-btn--icn-active" />}
                                    </button>
                                )} />
                                <CommonTooltip component={ctProps => (
                                    <button data-tooltip-id={ctProps.id} data-tooltip-content="Contacts List" className="chat-left-icn-btn" onClick={() => {
                                        isFriendView ? dispatch(backToChatList(false)) : dispatch(getFriendUsersByCurrentUser())
                                    }}>
                                        {isFriendView ? <Contacts className="chat-left-icn-btn--icn-active" /> : <ContactsOutlined />}
                                    </button>
                                )} />
                                <CommonTooltip component={ctProps => (
                                    <button data-tooltip-id={ctProps.id} data-tooltip-content="Create Group" className="chat-left-icn-btn" onClick={() => {
                                        dispatch(setGroupChatFlag(true))
                                        dispatch(setSelectedGroupIds([]))
                                        dispatch(setSelectUser({}))
                                        dispatch(clearUserSearch())
                                    }}>
                                        <GroupAddOutlined />
                                    </button>
                                )} />
                            </div>

                            {/* <div className="chat--nav chat-dropdown-all-messages cursor-pointer">
                                <img loading="lazy" src={require('../../assets/images/chat/nav.svg')} alt="" onClick={toggleAllMessageHandler} />
                                <OutsideClickHandler display="contents" onOutsideClick={() => setIsAllMessageOpen(false)}>
                                    {isAllMessageOpen && (
                                        <div className="chat--opened--dropdown">
                                            <ul className="chat--opened--dropdown--ul">
                                                <li className='mobile-view-create-group' onClick={() => {
                                                    dispatch(setGroupChatFlag(true))
                                                    dispatch(setSelectedGroupIds([]))
                                                    dispatch(setSelectUser({}))
                                                    dispatch(clearUserSearch())
                                                    setIsAllMessageOpen(false)
                                                }}>Create Group</li>
                                                <li className='mobile-view-contact-list' onClick={() => {
                                                    setIsAllMessageOpen(false)
                                                    isFriendView ? dispatch(backToChatList(false)) : dispatch(getFriendUsersByCurrentUser())
                                                }}>{isFriendView ? 'Chat List':'Contact List'}</li>
                                            </ul>
                                        </div>
                                    )}
                                </OutsideClickHandler>
                            </div> */}
                        </div>
                    </div>}
            </div>
        </React.Fragment>
    )
}
