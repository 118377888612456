import React from 'react'
import { SkeletonComponent } from './SkeletonComponent'

export const SkeletonTable = props => {
  return (
    <div className="skeleton--table">
      <SkeletonComponent className="skeleton--table-row" height={35} count={10} />
    </div>
  )
}
