import {
  ARCHIVE_SALES_PROCESS_TYPES,
  ARCHIVE_SALES_PROCESS_TYPES_FAILURE,
  ARCHIVE_SALES_PROCESS_TYPES_SUCCESS,
} from '../actionTypes'

const InitialState = {
  archiveSalesProcessSuccess: false,
  archiveSalesProcessError: false,
  isLoading: false,
}

export const archiveSalesEngineReducer = (state = InitialState, action) => {
  switch (action.type) {
    // START

    case ARCHIVE_SALES_PROCESS_TYPES_SUCCESS:
      return {
        ...state,
        archiveSalesProcessSuccess: true,
        archiveSalesProcessError: false,
      }
    case ARCHIVE_SALES_PROCESS_TYPES:
      return {
        ...state,
        archiveSalesProcessSuccess: false,
        archiveSalesProcessError: false,
        isLoading: true,
      }

    case ARCHIVE_SALES_PROCESS_TYPES_FAILURE:
      return {
        ...state,
        archiveSalesProcessSuccess: false,
        archiveSalesProcessError: true,
      }

    // END

    default:
      return state
  }
}
