import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Grid, Box } from '@material-ui/core'

import { pagination, dimension, skeletonType } from '../../util/enums/enums'
import { Layout } from '../layout/layout'
import { getAllAuctionRooms } from '../../redux/actions'
import { PaginationBar } from '../pagination/PaginationBar'
import { AuctionDetail } from '../home/singleComponents/auctionDetail'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import { getAllMedia } from '../../redux/actions/mediaArticleAction'
import { BoatMediaArticle } from './BoatMediaArticle'
import { Loader } from '../loader/loader'
import { SkeletonLoader } from '../loader/SkeletonLoader'

class BoatMediaAllArticles extends React.Component {
  componentDidMount() {
    const { getAllMedia } = this.props

    getAllMedia({
      page: pagination.PAGE_COUNT,
      limit: pagination.PAGE_RECORD_LIMIT,
    })
  }

  render() {
    const { getAllMedia, isLoader, boatMedia, loading } = this.props

    const { articles, category } = boatMedia

    const articlesTotal = category?.length > 0 && category.find(item => item.label === 'articles')?.count

    return (
      <Fragment>
        <Layout className="home-page home-layout-page">
          {loading ? (
            <SkeletonLoader type={skeletonType.grid} itemsLength={8} />
          ) : (
            <div className="container100 mt-3 show--all-auction--room parent-grid-use-flex-layout">
              <Grid container className="auction--show--all--section auctionlist-show-all-article">
                <Grid item xs={12} className="auction--show--all--heading--grid article-grid-layout-show-all">
                  <h1>
                    <Box
                      className="section-heading mt-3 auction--show--all--heading"
                      fontSize={24}
                      fontWeight={500}
                      letterSpacing={0.5}
                    >
                      Articles on AdamSea
                    </Box>
                  </h1>
                  <h2 className="font-16">Total Articles ({`${articlesTotal}`})</h2>
                </Grid>

                <div className="pt-30 zoom-container section-heading show-all-boat-action-grid">
                  {articles && articles.length ? (
                    articles.map((value, index) => {
                      return <BoatMediaArticle value={value} dimension={dimension.mediaArticles} isShowBoatMedia />
                    })
                  ) : (
                    <Grid item xs={12}>
                      {' '}
                      No Record Found
                    </Grid>
                  )}
                </div>
              </Grid>
            </div>
          )}

          {articlesTotal > pagination.PAGE_RECORD_LIMIT && articlesTotal > 0 && (
            <div className="mt-3">
              <PaginationBar action={getAllMedia} totalRecords={articlesTotal} />
            </div>
          )}
        </Layout>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  boatMedia: state.mediaArticleReducer && state.mediaArticleReducer.boatMedia,
  isLoader: state.boatReducer && state.boatReducer.isLoader,
  loading: state.mediaArticleReducer && state.mediaArticleReducer.loading,
})

const mapDispatchToProps = dispatch => ({
  getAllMedia: data => dispatch(getAllMedia(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatMediaAllArticles)
