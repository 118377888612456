import { Container, Row, Col} from "react-bootstrap"
import { Stack } from "@mui/material"
import { footerScreenImages, icons } from "../../util/enums/enums"
import { Layout } from "../../components"

import './market.scss'
import '../../styles/common.scss'

const Market = () => {
    return (
        <>
        <Layout>
            <div className="container100">
                <Row>
                    <Col sm={12}>
                        <h1 className="mt-30 r-h1">Sell Your Boat in Marine Marketplace</h1>
                    </Col>
                </Row>


                <Row>
                    <Col sm={6}>
                        <span className="market-title mt-4 mb-4">Discover the AdamSea Market</span>
                        <span className='market-text'>At Adamsea, our market covers everything that touches the sea. We are committed to providing a comprehensive platform that connects buyers and sellers from around the world, with a mission to cover the entire world and all seven seas. Our main market covers a wide range of services including marina and storage, yacht and boat services and maintenance, charter and rent globally, and selling/buying used and new boats. We aim to provide a one-stop-shop for all your boating needs, giving you access to a wide range of services and resources to support your boating journey.</span>
                        <span className='market-text'>Whether you're looking to buy or sell a boat, find a place to store your vessel, or need maintenance services, our platform has everything you need to make informed decisions and get the best deals. </span>

                    </Col>
                    <Col sm={6}>
                        <img className="pad-2" loading="lazy" src={require('../../assets/images/info-screens/market-parked-boat.png')} alt='Boat'/>
                    </Col>
                </Row>
                </div>
            <div className="container100">

                    <div className="text-center p-5">
                        <span className="market-title">Market Insights</span>
                        <span className="market-text"> Buy, sell, and connect with the global marine community</span>

                    </div>
                    <div className="d-flex">
                        <Row>
                            <Col sm={12} lg={4}>
                            <Stack className='p-3'>
                            <img loading="lazy" width={70} src={icons.boat_solutions_bulb} alt='Solutions'/>
                            <span className="market-subtitle py-3">Boat Solutions</span>
                            <span className="market-detail">Whether you're looking to buy or sell a boat, find a place to store your vessel, or need maintenance services, our platform has everything you need to make informed decisions and get the best deals.</span>
                        </Stack>
                            </Col>
                            <Col sm={12} lg={4}>
                            <Stack className='p-3'>
                            <img loading="lazy" width={70} src={icons.global_boat_browsing_ship} alt='Global'/>
                            <span className="market-subtitle py-3">Global Boat Browsing</span>
                            <span className="market-detail">Our global network of buyers and sellers ensures that you have access to a diverse range of products and services that you can buy from the comfort of your home through AdamSea’s sales engine, no matter where you are in the world.</span>
                        </Stack>
                            </Col>
                            <Col sm={12} lg={4}>
                            <Stack className='p-3'>
                            <img loading="lazy" width={70} src={icons.comprehensive_platform} alt='Comprehensive platform'/>
                            <span className="market-subtitle py-3">Comprehensive Platform</span>
                            <span className="market-detail">Boating should be accessible to everyone, which is why we are constantly expanding our platform. With our comprehensive platform, secure payment processing, and commitment to customer satisfaction, we are proud to be the go-to platform for boating enthusiasts and businesses alike.</span>
                        </Stack>
                            </Col>
                        </Row>
                    </div>
                    </div>
            </Layout>
        </>
    )
}

export default Market
