import { put, takeLatest, all, takeEvery } from 'redux-saga/effects'
import {
  GET_RENT_BOAT_BY_TRIP,
  GET_RENT_BOAT_BY_TRIP_SUCCESS,
  GET_RENT_BOAT_BY_TRIP_FAILURE,
  GET_RENT_BOAT_MOST_POPULAR_FAILURE,
  GET_RENT_BOAT_MOST_POPULAR_SUCCESS,
  GET_RENT_BOAT_MOST_POPULAR,
  GET_RECOMMENDED_TRIPS,
  GET_RECOMMENDED_TRIPS_SUCCESS,
  GET_RECOMMENDED_TRIPS_FAILURE,
  GET_RENT_BOAT_TRIP_CITIES,
  GET_RENT_BOAT_TRIP_CITIES_SUCCESS,
  GET_RENT_BOAT_TRIP_CITIES_FAILURE,
  GET_RENT_CITY_WISE_BOATS,
  GET_RENT_CITY_WISE_BOATS_SUCCESS,
  GET_RENT_CITY_WISE_BOATS_FAILURE,
  SEARCH_BOAT_RENT,
  SEARCH_BOAT_RENT_SUCCESS,
  SEARCH_BOAT_RENT_FAILURE,
  CLEAR_SEARCH_BOAT_RENT_FLAG,
  GET_ALL_TRIP_TYPES,
  GET_ALL_TRIP_TYPES_SUCCESS,
  GET_ALL_TRIP_TYPES_FAILURE,
  CREATE_RENT_BOOKING,
  CREATE_RENT_BOOKING_SUCCESS,
  CREATE_RENT_BOOKING_FAILURE,
  CLEAR_CREATE_RENT_BOOKING,
  GET_RENT_BOOKINGS_BY_CUSTOMER,
  GET_RENT_BOOKINGS_BY_CUSTOMER_FAILURE,
  GET_RENT_BOOKINGS_BY_CUSTOMER_SUCCESS,
  CHECK_IF_CAN_BOOK_RENT,
  CHECK_IF_CAN_BOOK_RENT__FAILURE,
  CHECK_IF_CAN_BOOK_RENT__SUCCESS,
} from '../actionTypes'
import { graphqlClient } from '../../helpers/graphqlClient'
import {
  getRentBoatsByTrip,
  getMostPopularBoatRents,
  recommendedBoatRents,
  getBoatRentTripCities,
  getRentCityWiseBoats,
  searchBoatRent,
  getExperiences,
  getAllTripTypes_query,
  createRentBookingQuery,
  getRentBookingsByCustomerQuery,
  checkIfCanBookRentQuery,
} from '../../graphql/rentSchema'
import { functionsIn, get } from 'lodash'

function searchBoatRentApi(input) {
  return graphqlClient
    .mutate({
      mutation: searchBoatRent,
      variables: {
        input: input,
      },
    })
    .then(res => res)
    .catch(err => err)
}

function* searchBoatRentData(action) {
  try {
    const res = yield searchBoatRentApi(action.payload)
    yield put({ type: SEARCH_BOAT_RENT_SUCCESS, payload: res.data.searchBoatRent })
  } catch (e) {
    yield put({ type: SEARCH_BOAT_RENT_FAILURE, e })
  }
  yield put({ type: CLEAR_SEARCH_BOAT_RENT_FLAG })
}

function* searchBoatRentSaga() {
  yield takeLatest(SEARCH_BOAT_RENT, searchBoatRentData)
}

function getRentBoatsByTripApi(data) {
  return graphqlClient.query({
    query: getRentBoatsByTrip,
    variables: data,
  })
}

function getRentBoatMostPopularApi(data) {
  return graphqlClient
    .query({
      query: getMostPopularBoatRents,
      variables: data,
    })
    .then(res => res)
    .catch(err => err)
}

function getRecommendedTripApi(input) {
  return graphqlClient
    .query({
      query: recommendedBoatRents,
      variables: input,
    })
    .then(res => res)
    .catch(err => err)
}

function getRentBoatTripCitiesApi(input) {
  return graphqlClient
    .query({
      query: getBoatRentTripCities,
      variables: 5,
    })
    .then(res => res)
    .catch(err => err)
}

function* getRentBoatsByTripData(action) {
  try {
    const data = yield getRentBoatsByTripApi(action.payload)
    yield put({
      type: GET_RENT_BOAT_BY_TRIP_SUCCESS,
      payload: data.data.getRentBoatsByTrip.items,
      alias: action.payload.trip,
      total: data.data.getRentBoatsByTrip.total,
    })
  } catch (e) {
    yield put({ type: GET_RENT_BOAT_BY_TRIP_FAILURE, e })
  }
}

function* getRentBoatMostPopularData(action) {
  try {
    const data = yield getRentBoatMostPopularApi(action.payload)
    yield put({ type: GET_RENT_BOAT_MOST_POPULAR_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_RENT_BOAT_MOST_POPULAR_FAILURE, e })
  }
}

function* getRecommendedTrip(action) {
  try {
    const res = yield getRecommendedTripApi(action.payload)
    yield put({
      type: GET_RECOMMENDED_TRIPS_SUCCESS,
      payload: res.data.recommendedBoatRents,
    })
  } catch (e) {
    yield put({ type: GET_RECOMMENDED_TRIPS_FAILURE, e })
  }
}

function* getRentBoatTripCities(action) {
  try {
    const res = yield getRentBoatTripCitiesApi(action.payload)
    yield put({ type: GET_RENT_BOAT_TRIP_CITIES_SUCCESS, payload: res.data.getBoatRentTripCities })
  } catch (error) {
    yield put({ type: GET_RENT_BOAT_TRIP_CITIES_FAILURE, error })
  }
}

function* getRentCityWiseBoatSaga() {
  yield takeLatest(GET_RENT_CITY_WISE_BOATS, getCityWiseBoat)
}

function* getCityWiseBoat(action) {
  try {
    const res = yield getCityWiseBoatApi(action.payload)
    yield put({
      type: GET_RENT_CITY_WISE_BOATS_SUCCESS,
      payload: res.data.getBoatRentTripCityWise,
    })
  } catch (error) {
    yield put({ type: GET_RENT_CITY_WISE_BOATS_FAILURE, error })
  }
}

function getCityWiseBoatApi(input) {
  return graphqlClient
    .query({
      query: getRentCityWiseBoats,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function* getRentBoatsByTripSaga() {
  yield takeEvery(GET_RENT_BOAT_BY_TRIP, getRentBoatsByTripData)
}

function* getRentBoatMostPopularSaga() {
  yield takeLatest(GET_RENT_BOAT_MOST_POPULAR, getRentBoatMostPopularData)
}

function* getRecommendedTripSaga() {
  yield takeLatest(GET_RECOMMENDED_TRIPS, getRecommendedTrip)
}

function* getRentBoatTripCitiesSaga() {
  yield takeLatest(GET_RENT_BOAT_TRIP_CITIES, getRentBoatTripCities)
}

function getAllTripTypes_API(input) {
  return graphqlClient.query({
    query: getAllTripTypes_query,
    variables: {
      isAdmin: false,
      typeId: '5da839bf131dda1ab49a1592',
      ...input,
    },
  })
}

function* getAllTripTypes(action) {
  try {
    const res = yield getAllTripTypes_API(action.payload)

    yield put({
      type: GET_ALL_TRIP_TYPES_SUCCESS,
      payload: res.data.getAllTripTypes,
    })
  } catch (error) {
    yield put({
      type: GET_ALL_TRIP_TYPES_FAILURE,
      error,
    })
  }
}

function* getAllTripTypes_saga() {
  yield takeLatest(GET_ALL_TRIP_TYPES, getAllTripTypes)
}

// check if can book rent

function* checkIfCanBookRentSaga() {
  yield takeLatest(CHECK_IF_CAN_BOOK_RENT, checkIfCanBookRentData)
}

function* checkIfCanBookRentData(action) {
  try {
    const res = yield checkIfCanBookRentAPI(action.payload)

    if (get(res, 'data.checkIfCanBookRent', false)) {
      yield put({
        type: CHECK_IF_CAN_BOOK_RENT__SUCCESS,
      })
    } else {
      yield put({
        type: CHECK_IF_CAN_BOOK_RENT__FAILURE,
        payload: get(res, 'data.checkIfCanBookRent', false) === false ? 'All slots booked for this trip!' : 'Cannot book slot!',
      })
    }
  } catch (err) {
    yield put({
      type: CHECK_IF_CAN_BOOK_RENT__FAILURE,
      payload: 'Cannot book slot!',
    })
  }
}

function checkIfCanBookRentAPI(data) {
  return graphqlClient.query({
    query: checkIfCanBookRentQuery,
    variables: { ...data, reqId: new Date().valueOf().toString() },
  })
}

// --------------

function createRentBookingAPI(input) {
  return graphqlClient.mutate({
    mutation: createRentBookingQuery,
    variables: { input },
  })
}

function* createRentBooking(action) {
  try {
    const res = yield createRentBookingAPI(action.payload)

    if (get(res, 'errors.length', 0) || get(res, 'networkError.result.errors.length', 0)) {
      yield put({
        type: CREATE_RENT_BOOKING_FAILURE,
        payload: get(res, 'errors[0].message', ''),
      })
    } else {
      yield put({ type: CREATE_RENT_BOOKING_SUCCESS, payload: res.data })
    }
  } catch (e) {
    yield put({
      type: CREATE_RENT_BOOKING_FAILURE,
      payload: get(e, 'networkError.result.errors[0].message', ''),
    })
  }
}

function* createRentBookingSaga() {
  yield takeLatest(CREATE_RENT_BOOKING, createRentBooking)
}

function getRentBookingsByCustomerAPI(data) {
  return graphqlClient.query({
    query: getRentBookingsByCustomerQuery,
    variables: { ...data, reqId: new Date().valueOf() },
  })
}

function* getRentBookingsByCustomer(action) {
  try {
    const res = yield getRentBookingsByCustomerAPI(action.payload)

    if (res.data?.getRentBookingsByCustomer) {
      yield put({
        type: GET_RENT_BOOKINGS_BY_CUSTOMER_SUCCESS,
        payload: res.data.getRentBookingsByCustomer,
      })
    } else {
      yield put({
        type: GET_RENT_BOOKINGS_BY_CUSTOMER_FAILURE,
        payload: get(res, 'errors[0].message', '') || get(res, 'networkError.result.errors[0].message', ''),
      })
    }
  } catch (e) {
    console.log({ ...e })
    yield put({
      type: GET_RENT_BOOKINGS_BY_CUSTOMER_FAILURE,
      payload: get(e, 'networkError.result.errors[0].message', ''),
    })
  }
}

function* getRentBookingsByCustomerSaga() {
  yield takeLatest(GET_RENT_BOOKINGS_BY_CUSTOMER, getRentBookingsByCustomer)
}

export default function* rentSaga() {
  yield all([
    getRentBoatsByTripSaga(),
    getRentBoatMostPopularSaga(),
    getRecommendedTripSaga(),
    getRentBoatTripCitiesSaga(),
    getRentCityWiseBoatSaga(),
    searchBoatRentSaga(),
    getAllTripTypes_saga(),
    createRentBookingSaga(),
    getRentBookingsByCustomerSaga(),
    checkIfCanBookRentSaga(),
  ])
}
