import React from 'react'

import { dimension, marginCarousel, responsiveCarousel } from '../../util/enums/enums'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { MarketServiceDetail } from '../marketService/marketServiceDetail'
import { CarouselWrapper } from './carouselWrapper'
import { getMarginGrid } from '../../helpers/jsxHelper'
import '../../assets/css/component/carousel/ExploreAdamSeaService.scss'
export default class ExploreAdamSeaIndia extends React.Component {
  state = {
    height: 160,
    width: 160,
    gutter: 35,
  }

  getNewWidth = () => {
    const width = getRatioZoomResponsive(dimension, 'exploreAdamSeaRentServices', '.section-heading')
    const gridWidth = width && document.querySelector('.section-heading')
    const gutter = getMarginGrid(gridWidth.offsetWidth, marginCarousel.topRatedBoatService)
    this.setState({ width: width.width, gutter })
  }

  getNewHeight = () => {
    const height = getHeightZoomResponsive(dimension, 'exploreAdamSeaRentServices', '.section-heading')
    this.setState({ height })
  }

  componentDidMount() {
    this.getNewWidth()
    this.getNewHeight()
    window.addEventListener('resize', this.getNewWidth)
    window.addEventListener('resize', this.getNewHeight)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.getNewWidth)
    window.removeEventListener('resize', this.getNewHeight)
  }

  createChildren = () => {
    const { carouselData, getCategoryWiseBoats } = this.props
    const { height } = this.state
    return (
      carouselData &&
      carouselData.length > 0 &&
      carouselData.map(value => {
        return (
          <MarketServiceDetail
            value={value}
            key={value.id}
            getService={getCategoryWiseBoats}
            className="explore--adamsea--service"
            height={height}
          />
        )
      })
    )
  }

  render() {
    const { height, gutter } = this.state
    const { items } = this.props
    return (
      <>
        <CarouselWrapper
          items={items}
          height={height}
          isZoom
          responsiveNumber={responsiveCarousel.exploreAdamSeaRentServices}
          gutter={gutter}
          classes={{
            itemsWrapper: "adam-card-6-inner pt-4"
          }}
        >
          {this.createChildren()}
        </CarouselWrapper>
      </>
    )
  }
}
ExploreAdamSeaIndia.defaultProps = {
  gutter: 30,
}
