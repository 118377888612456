import React from 'react'
import cn from 'classnames'

/**
 * @param {React.SVGProps<SVGSVGElement>} props
 */
export default function IconSearch(props) {
  return (
    <svg
      {...props}
      className={cn('as-media-icon', props.className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7.66683 14.0002C11.1646 14.0002 14.0002 11.1646 14.0002 7.66683C14.0002 4.16903 11.1646 1.3335 7.66683 1.3335C4.16903 1.3335 1.3335 4.16903 1.3335 7.66683C1.3335 11.1646 4.16903 14.0002 7.66683 14.0002Z" />
      <path d="M14.6668 14.6668L12.3335 12.3335" />
    </svg>
  )
}
