import React, { useState, useEffect } from 'react'
import OtpInput from 'react-otp-input'

import { CommonModal } from './commonModal'
import ErrorComponent from '../error/errorComponent'
import { Button, Link } from '@material-ui/core'
import { ButtonComponent } from '../form/Button'

import './confirmationMobile.scss'

export const ConfirmMobileOTP = props => {
  const {
    open,
    close,
    mobileNumberSentForOTP,
    activateUserDetail,
    resendOTP,
    isError,
    errorMessage,
    isMobileVerifySuccess,
    message,
  } = props

  const OTPLength = 6
  const [OTP, setOTP] = useState('')

  const SECONDS_BEFORE_RESEND = 60 * 5

  const [toResend, setToResend] = useState(false)
  const [secondsBeforeResend, setSecondsBeforeResend] = useState(SECONDS_BEFORE_RESEND)

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsBeforeResend(seconds => Math.max(0, seconds - 1))
    }, 1000);

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (message && resend && toResend) {
      setSecondsBeforeResend(SECONDS_BEFORE_RESEND)
      setToResend(false)
    }
  }, [message, resend, toResend])

  useEffect(() => {
    if (+OTP && String(OTP).length === OTPLength) {
      activateUserDetail(OTP)
    }
  }, [OTP])

  const [resend, resendMethod] = useState(false)

  useEffect(() => {
    isMobileVerifySuccess && setOTP('')
  }, [isMobileVerifySuccess])

  const handleCloseModal = () => {
    setOTP('')
    close()
  }

  return (
    <>
      <CommonModal open={open} close={handleCloseModal} className="verification--otp" title="Enter Verification Code">
        <p className="verification--otp--mob--number">Message Sent To: {mobileNumberSentForOTP}</p>

        <div className="verification--otp--div">
          <OtpInput
            value={OTP}
            onChange={value => {
              resend && resendMethod(false)
              setOTP(value)
            }}
            numInputs={OTPLength}
            hasErrored={isError}
            shouldAutoFocus
            separator={<span>-</span>}
          />
          <div className="verification--otp--resend--message">
            <p className="mb-0">{message && resend && 'OTP sent successfully'}</p>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <ButtonComponent
              color="danger"
              className="h-auto as--cancel--outline btn--padding btn--font mr-3 verify--otp--clear--btn"
              onClick={() => setOTP('')}
            >
              {'Clear'}
            </ButtonComponent>
            {/* <ButtonComponent
              onClick={() => activateUserDetail(OTP)}
              className="h-auto as--success--outline btn--padding btn--font"
              disabled={OTP.length < OTPLength}
            >
              {'Verify'}
            </ButtonComponent> */}
          </div>
          <div>
            {!secondsBeforeResend ? (
              <Link
                onClick={() => {
                  setToResend(true)
                  resendMethod(true)
                  resendOTP()
                  setOTP('')
                }}
                className="cursor-pointer confirmation-a-tag verification--otp--resend"
              >
                Re-send One Time Pin
              </Link>
            ) : (
              <div className="confirmation-a-tag">Resend OTP in {(() => {
                var minutes = Math.floor(secondsBeforeResend / 60);
                var seconds = secondsBeforeResend % 60;
                return minutes + " mins " + seconds + " secs";
              })()}</div>
            )}
          </div>
        </div>

        {isError && <ErrorComponent errors={errorMessage} />}
      </CommonModal>
    </>
  )
}
