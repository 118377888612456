import { ERROR_MESSAGE_SHOW, CLEAR_ERROR_MESSAGE } from '../actionTypes'

export const errorMessageShow = message => ({
  type: ERROR_MESSAGE_SHOW,
  payload: message,
})

export const clearErrorMessageShow = () => ({
  type: CLEAR_ERROR_MESSAGE,
})
