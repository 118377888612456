import {Link, Redirect} from 'react-router-dom'
import { Layout } from "../../components";
import { ButtonComponent } from "../../components/form/Button";
import { useEffect } from 'react';

export default function PageNotFound(){
    useEffect(() => {
        window.location.href = '/'
    }, []);

    return null;

    return(
        <Layout>
            <div className="d-flex flex-column align-items-center">
                <img style={{maxWidth:'250px'}} src={require('../../assets/images/404-not-found.jpg')} alt="not found error" />
                <ButtonComponent className="background-dark-blue">
                    <Link className='text-decoration-none text-white' to='/'>GO TO HOME PAGE</Link>
                </ButtonComponent>
            </div>
        </Layout>
    )
}
