import {
  GET_ALL_DEALERS,
  DEALERS_REQUEST_MANUFACTURER,
  ACCEPT_OR_REJECT_DEALER_REQUEST,
  SEARCH_MANUFACTURER,
  DEALERS_SENT_REQUESTS,
  DEALERS_REQUEST_MULTIPLE_MANUFACTURER,
} from '../actionTypes'

export const getAllDealers = data => ({
  type: GET_ALL_DEALERS,
  payload: data,
})

export const dealerRequestsManufacturer = data => ({
  type: DEALERS_REQUEST_MANUFACTURER,
  payload: data,
})

export const acceptOrRejectDealerRequest = data => ({
  type: ACCEPT_OR_REJECT_DEALER_REQUEST,
  payload: data,
})

export const searchManufacturerRequest = data => ({
  type: SEARCH_MANUFACTURER,
  payload: data,
})

export const getDealersSentRequests = data => ({
  type: DEALERS_SENT_REQUESTS,
  payload: data,
})

export const requestMultipleManufacturer = data => ({
  type: DEALERS_REQUEST_MULTIPLE_MANUFACTURER,
  payload: data,
})
