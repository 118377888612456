import {
  GET_CATEGORIES_WISE_BANNER,
  GET_CATEGORIES_WISE_BANNER_SUCCESS,
  GET_CATEGORIES_WISE_BANNER_FAILURE,
} from '../actionTypes'

const initialState = {
  isHomeBannerCreated: false,
  isHomeBannerError: false,
  deleteHomeBannerSuccess: false,
  deleteHomeBannerError: false,
  isHomeBannerUpdateSuccess: false,
  isHomeBannerUpdateError: false,
}

export function bannerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES_WISE_BANNER:
      const fieldName = action.payload.fieldName

      return {
        ...state,
        [fieldName]: [],
        isHomeBannerError: false,
        isHomeBannerCreated: false,
        deleteHomeBannerSuccess: false,
        deleteHomeBannerError: false,
        isHomeBannerUpdateSuccess: false,
        isHomeBannerUpdateError: false,
      }
    case GET_CATEGORIES_WISE_BANNER_SUCCESS:
      const fieldNameSuccess = action.payload.fieldName
      return {
        ...state,
        [fieldNameSuccess]: action.payload && action.payload.data.getBannerByModule.items,
        isHomeBannerError: false,
        isHomeBannerCreated: false,
        deleteHomeBannerSuccess: false,
        deleteHomeBannerError: false,
      }
    case GET_CATEGORIES_WISE_BANNER_FAILURE:
      const fieldNameFail = action.payload
      return {
        ...state,
        [fieldNameFail]: [],
        isHomeBannerError: false,
        isHomeBannerCreated: false,
        deleteHomeBannerSuccess: false,
        deleteHomeBannerError: false,
      }
    default:
      return state
  }
}
