import React, { Component } from 'react'
import BoatAddedService from './boatAddedService'
import { ShowAllLink } from '../helper/showAllLink'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { dimension } from '../../util/enums/enums'

class BoatServicesAdded extends Component {
  state = {
    gridClassName: `grid--new--${dimension['boatServices'].divide}`,
    margin: 15,
    width: dimension.boatServices.width,
    height: dimension.boatServices.height,
  }
  zoomWidth = () => {
    const width = getRatioZoomResponsive(dimension, 'boatServices', '.zoom-container')
    const height = getHeightZoomResponsive(dimension, 'boatServices', '.zoom-container')
    width &&
      height &&
      this.setState({
        width: width.width,
        gridClassName: width.className,
        height,
        margin: width.margin,
      })
  }

  componentDidMount() {
    this.zoomWidth()
    window.addEventListener('resize', this.zoomWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.zoomWidth)
  }
  render() {
    const { iconColor, boatAddedServices, showType, showAllText, inInner, itemLength, total } = this.props
    const { margin, gridClassName, height, width } = this.state
    return (
      <>
        <div className="recently--added--services--main  zoom-container">
          {boatAddedServices &&
            boatAddedServices.length &&
            boatAddedServices.map((value, index) => {
              return (
                <>
                  {index < itemLength && (
                    <BoatAddedService
                      index={index}
                      value={value}
                      width={width}
                      height={height}
                      margin={margin}
                      gridClassName={gridClassName}
                      iconColor={iconColor}
                    />
                  )}
                </>
              )
            })}
          {!inInner && (
            <ShowAllLink
              data={boatAddedServices}
              className="service--link--color mt-0 w-100"
              itemsLength={itemLength}
              totalLength={total}
              showAllText={showAllText}
              url={`/show-all-service/${showType}`}
            />
          )}
        </div>
      </>
    )
  }
}

export default BoatServicesAdded
