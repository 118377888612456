import gql from 'graphql-tag'
import { AddressType, ImageType, PageVisit, Like, Media } from './schemaTypes/schemaTypes'

export const getAllMediaArticles = gql`
query getAllArticles($page: Int, $limit: Int) {
    getAllArticles(page: $page, limit: $limit) {
    items {
      id
      user {
        address ${AddressType}
        firstName
        lastName
        id
        email
        image ${ImageType}
      }
      title
      titleSlug
      description
      files ${ImageType}
      articleViewCount
			pageVisits ${PageVisit}
			likes ${Like}
      articleApproved
      status
      adId
      shortDescription
      createdAt
      updatedAt
    }
    total
  }
}
`
export const getAdamseaMedia = gql`
query getAdamseaMedia($limit: Int, $page: Int) {
  getAdamseaMedia(limit: $limit, page: $page) {
    popularPosts {
      id
      title
      files ${Media}
      createdAt
    }
    articles {
      id
      title
      articleApproved
      description
      shortDescription
      files ${Media}
      articleViewCount
			pageVisits ${PageVisit}
      likes {
        id
      }
      user{
        image ${Media}
        firstName
        lastName
        id
        companyName
        address{
          city
        }
      }
    }
    adamseaMedia{
      id
      url ${ImageType}
      title
      description
      thumbnail ${ImageType}
      }
    howToMedia {
      id
      url ${ImageType}
      title
      description
      thumbnail ${ImageType}
      }
    adMedia {
      id
      url ${ImageType}
      title
      description
      thumbnail ${ImageType}
      }
    reviews {
      moduleId {
        id
        firstName
        lastName
        image ${Media}
      }
      user {
        id
        _id
        firstName
        lastName
        image ${Media}
      }
      reviews
    }
    category
  }
}

`
