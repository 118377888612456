import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Truncate from 'react-truncate'

export default class ReadMore extends Component {
  constructor(...args) {
    super(...args)

    this.state = {
      expanded: false,
      truncated: false,
    }

    this.handleTruncate = this.handleTruncate.bind(this)
    this.toggleLines = this.toggleLines.bind(this)
  }

  handleTruncate(truncated) {
    if (this.state.truncated !== truncated) {
      this.setState({
        truncated,
      })
    }
  }

  toggleLines(event) {
    event.preventDefault()
    const { link, onClick } = this.props
    if (link !== undefined && link === true) {
      onClick()
    } else {
      this.setState({
        expanded: !this.state.expanded,
      })
    }
  }

  render() {
    const { children, more, less, lines, renderFlag } = this.props

    const { expanded, truncated } = this.state

    return (
      <div>
        <Truncate
          lines={!expanded && lines}
          ellipsis={
            <span style={{textDecoration: 'underline', display: 'inline-block'}} className="color-black mx-2 font-weight-bold cursor-pointer" onClick={this.toggleLines}>
              {more}<img className='ml-1' style={{padding: '1px', width: '12px'}} alt="right arrow" src={require('../../assets/images/Arrow/show-more-right-arrow.svg')}/>
            </span>
          }
          onTruncate={this.handleTruncate}
        >
          {children}
        </Truncate>
        {!truncated && expanded && (
          <span style={{textDecoration: 'underline', display: 'inline-block'}} className="color-black mr-px-10 font-weight-bold cursor-pointer" onClick={this.toggleLines}>
            {less}<img className='ml-1' style={{padding: '1px', width: '17px'}} alt="up arrow" src={require('../../assets/images/Arrow/up.svg')}/>
          </span>
        )}
      </div>
    )
  }
}

ReadMore.defaultProps = {
  lines: 3,
  more: 'Show More',
  less: 'Show Less',
  renderFlag: false,
}

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
}
