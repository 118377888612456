import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { FaEyeSlash } from 'react-icons/fa'
import { FaEye } from 'react-icons/fa'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

import {
  login,
  clearAuthorizationFlag,
  getCategoriesWiseBanners,
  clearErrorMessageShow,
  convertWishlistsFromSessionToUser,
  clearAgreementCreateSuccessMessage,
  clearRegisterFlag,
} from '../../redux/actions'
import { Notification } from '../../components'
import TermAndPolicy from '../../components/termAndPolicy'
import Captcha from '../../components/helper/captcha'

import './login.scss'
import ErrorComponent from '../../components/error/errorComponent'
import { LogInSignupBanner } from '../../components/styleComponent/styleComponent'
import ErrorFocus from '../../components/ws/ErrorFocus'
import { requireMessage, trimmedValue } from '../../helpers/string'
import { ButtonComponent } from '../../components/form/Button'
import { Video } from '../../components/popUp/video'
import { getImgUrl } from '../../util/utilFunctions'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {
        username: '',
        password: '',
      },
      showPassword: false,

      recaptchaRef: null,
      isVerifyCaptch: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isAuthorized, history, clearAuthFlag, convertWishlistsFromSessionToUser, location, isRentInline } = nextProps

    if (isAuthorized) {
      !isRentInline && (location && location.state && location.state.callbackLink ? history.push(location.state.callbackLink) : history.push('/'))
      clearAuthFlag()
      convertWishlistsFromSessionToUser()
    }

    return null
  }

  componentWillUnmount() {
    const { clearRegisterFlag } = this.props
    clearRegisterFlag()
  }

  closeErrorDisplay = () => {
    const { clearRegisterFlag } = this.props
    clearRegisterFlag()
  }

  componentDidMount() {
    const { clearAuthFlag, getCategoriesWiseBanners, clearErrorMessageShow } = this.props

    clearAuthFlag()
    const logInInput = {
      type: 'login',
      fieldName: 'logInBanner',
    }
    getCategoriesWiseBanners(logInInput)
    clearErrorMessageShow()
  }

  changeState = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }))
  }

  render() {
    const { user, recaptchaRef, isVerifyCaptch, showPassword } = this.state
    const { login, isError, errorMessage, logInBanners, clearErrorMessageShow, isAgreementUser, clearRegisterFlag } = this.props
    return (
      <>
        <Notification />
        <Grid container className="flex-1 overflow-hidden login-responsive" alignItems="center">
          {!this.props.isRentInline && (
            <Grid item className="h100-vh p-3 img-holder custom-content-column no-bg-blue" sm={6}>
              {logInBanners?.length > 0 && logInBanners[0].metatype === 'image' ? (
                <LogInSignupBanner
                  img={logInBanners && logInBanners.length > 0 ? encodeURI(getImgUrl(logInBanners[0].thumbnail[0])) : null}
                  className="h-100 d-flex flex-column"
                  style={{ borderRadius: 48 }}
                >
                  <div className="website-logo ml-50">
                    <Link to="/">
                      <div className="logo">
                        <img
                          loading="lazy"
                          alt="Adamsea"
                          className="logo-size"
                          src={require('../../assets/images/login/logo-white.png')}
                        />
                      </div>
                    </Link>
                  </div>
                  <Grid container className="flex-1" alignItems="center">
                    <div className="d-flex justify-content-center align-items-start flex-column loginMain-div">
                      <h3 className="text-white">Welcome</h3>
                      <h3 className="text-white">Back To AdamSea</h3>

                      {/* <span className="text-white loginSide-text">Login to see your favorite boats,</span>
                    <span className="text-white loginOffer-text">exclusive offers and more</span>
                    <TermAndPolicy /> */}
                    </div>
                  </Grid>
                </LogInSignupBanner>
              ) : (
                <div className="login--video-component">
                  <div className="logo">
                    <Link to="/">
                      <img
                        loading="lazy"
                        alt="Adamsea"
                        className="logo-size"
                        src={require('../../assets/images/login/logo-white.png')}
                      />
                    </Link>
                  </div>
                  {logInBanners?.length > 0 && logInBanners[0].url?.length > 0 && (
                    <Video
                      videoUrl={logInBanners[0].url[0].url}
                      thumbnail={logInBanners[0].thumbnail?.length > 0 && logInBanners[0].thumbnail[0].url}
                      isOpen
                      controls={false}
                      loop
                    />
                  )}
                </div>
              )}
            </Grid>
          )}

          <Grid item sm={this.props.isRentInline ? 12 : 6} className="custom-form-column">
            <Formik
              initialValues={user}
              validationSchema={Yup.object().shape({
                username: Yup.string().email('Email is invalid.').required(requireMessage('Email')),
                password: Yup.string()
                  // .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, "Password Must be of 8 Character")
                  .required(requireMessage('Password')),
              })}
              onSubmit={async values => {
                const token = await recaptchaRef.current.execute()
                if (token) {
                  this.setState({ isVerifyCaptch: false })
                  clearErrorMessageShow()
                  login({ username: values.username, password: values.password })
                } else {
                  this.setState({ isVerifyCaptch: true })
                }
              }}
              render={({ errors, status, touched, setFieldValue, handleSubmit, values }) => (
                <div className="form-holder">
                  <div className="form-content d-flex flex-column">
                    {isAgreementUser ? (
                      <>
                        <div className="d-flex text-left agreement--success--div">
                          <div className="agreement--success--image d-flex justify-content-center">
                            <div className="agreement--success--icon d-flex">
                              <img
                                loading="lazy"
                                src={require('../../assets/images/bells_9083538.png')}
                                className="h-100 w-100"
                                alt="Success"
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column font-16">
                            <div>Thank you for registering with us.</div>
                            <div className="mt-4">
                              We'll send you a code via email and SMS to activate your account. After receiving it, go to the
                              Sign Up page, select your user, and use the code.
                            </div>
                          </div>
                          {/* <div className="agreement--close--icon--div cursor-pointer" onClick={() => this.closeErrorDisplay()}>
                          <div className="agreement--close--icon d-flex">
                            <img
                              loading="lazy"
                              src={require('../../assets/images/login/close-icon.svg')}
                              className="h-100 w-100"
                              alt="Close"
                            />
                          </div>
                        </div> */}
                        </div>
                        <div className="mt-4 w-100 d-flex text-left agreement--success--div font-16">
                          <div className="agreement--success--image d-flex justify-content-center">
                            <div className="agreement--success--icon d-flex">
                              <img
                                loading="lazy"
                                src={require('../../assets/images/megaphone_9551884.svg')}
                                className="h-100 w-100"
                                alt="Success"
                              />
                            </div>
                          </div>
                          <div>
                            <strong>Note:</strong> Users from the USA will receive the code by email only.
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="form-items login-form-width">
                        <h1 className="r-h1">Log in</h1>

                        <Form>
                          <ErrorFocus />
                          <div className="position-relative login-field">
                            <div className="mail-icon ">
                              <input
                                id="username"
                                name="username"
                                type="text"
                                autoCorrect="off"
                                autoFocus
                                autoComplete="off"
                                className={
                                  'form-control inputfiled-space font-16 rounded-full' +
                                  (errors.username && touched.username ? ' is-invalid' : '')
                                }
                                placeholder="Email Address"
                                value={values.username}
                                onChange={e => setFieldValue('username', trimmedValue(e.target?.value))}
                                required
                              />
                              {/* <span className="form-side-icon password-login-icon login-side-space-icon login-form-side-icon">
                              <MailOutlineIcon />
                            </span> */}
                            </div>
                            {errors.username && touched.username && (
                              <ErrorMessage name="username" component="div" className="invalid--feedback--error w-100" />
                            )}
                          </div>
                          <div className="position-relative login-field">
                            <input
                              id="password"
                              name="password"
                              autoCorrect="off"
                              autoComplete="off"
                              type={showPassword ? 'text' : 'password'}
                              value={values.password}
                              className={
                                'form-control mt-10 font-16 inputfiled-space password--spacing rounded-full' +
                                (errors.password && touched.password ? ' is-invalid' : '')
                              }
                              placeholder="Password"
                              onChange={e => setFieldValue('password', e.target.value)}
                              required
                            ></input>
                            <span
                              className="form-side-icon eye-forget-psw password-login-icon login-side-space-icon"
                              onClick={this.changeState}
                            >
                              {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                            </span>
                          </div>
                          {errors.password && touched.password && (
                            <ErrorMessage name="password" component="div" className="invalid--feedback--error w-100" />
                          )}

                          <div className="position-relative login-field error-captcha vidnohid">
                            <Captcha getRefValue={value => this.setState({ recaptchaRef: value })} />
                          </div>

                          <ButtonComponent
                            variant="outline"
                            color="success"
                            className="h-auto btn--padding login-btn btn--font w-100 justify-content-center login--sign--up--btn rounded-full"
                            id="submit"
                            onClick={handleSubmit}
                          >
                            Log in
                          </ButtonComponent>
                          {isVerifyCaptch && <div className="alert alert-danger">Invalid captach</div>}
                          {isError && <ErrorComponent errors={errorMessage} />}

                          <div className="page-links mt-10">
                            <p className="font-16 font-normal">
                              Don't have an account?{' '}
                              {this.props.isRentInline ? (
                                <a className="font-weight-500 cursor-pointer" onClick={() => this.props.goToRegister?.()}>
                                  Sign up
                                </a>
                              ) : (
                                <Link to="/register" className="font-weight-500">
                                  Sign up
                                </Link>
                              )}
                            </p>
                          </div>

                          <Grid container className="mt-10 mb-10">
                            <Grid item xs>
                              <div className="clearfix"></div>
                            </Grid>
                            <Link to="/send-reset-password-link" className="float-right font-normal forgetTextStyle font-14">
                              Forgot password?
                            </Link>
                          </Grid>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              )}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => ({
  isError: state.loginReducer.isError,
  errorMessage: state.errorReducer.errorMessage,
  isAuthorized: state.loginReducer.isAuthorized,
  logInBanners: state.bannerReducer && state.bannerReducer['logInBanner'],
  isAgreementCreateSuccess: state.loginReducer?.isAgreementCreateSuccess,
  isAgreementUser: state.loginReducer?.isAgreementUser,
  registerSuccess: state.loginReducer?.registerSuccess,
})

const mapDispatchToProps = dispatch => ({
  login: data => dispatch(login(data)),
  clearAuthFlag: () => dispatch(clearAuthorizationFlag()),
  getCategoriesWiseBanners: data => dispatch(getCategoriesWiseBanners(data)),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  convertWishlistsFromSessionToUser: () => dispatch(convertWishlistsFromSessionToUser()),
  clearAgreementCreateSuccessMessage: () => dispatch(clearAgreementCreateSuccessMessage()),
  clearRegisterFlag: () => dispatch(clearRegisterFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
