import React, { useContext } from 'react'
import { Row, Col } from 'react-bootstrap'
import Truncate from 'react-truncate'
import Rating from 'react-rating'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'

import { defaultImage, marketTypes } from '../../../util/enums/enums'
import { formattedDate } from '../../../helpers/dateTimeHelper'
import { viewBoatHandler } from '../../../helpers/boatHelper'
import UserContext from '../../../UserContext'
import { getConvertedPrice } from '../../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../../CurrencyContext'
import RatingComponent from '../../rating/Rating'

export const BoatSearchResults = ({ data }) => {
  const {
    images,
    boatName,
    boatType,
    description,
    createdAt,
    seller,
    address: [{ country }],
    manufacturedBy,
    rating,
    price,
  } = data
  const { history } = useContext(UserContext)

  return (
    <Row onClick={() => viewBoatHandler(data)}>
      <Col xs={2}>
        <div className="d-flex justify-content-center m-auto">
          <div className="boat-search-result-img d-flex justify-content-center flex-column align-items-center">
            <img loading="lazy" src={(images && images[0]?.url) || defaultImage} alt="boat" className="h-100 width-100" />
          </div>
        </div>
      </Col>

      <Col xs={6}>
        <div className="d-flex flex-column justify-content-around h-100">
          <div>
            <span className="boat-title">{boatName}</span>
            {boatType && (
              <span className="ml-2 boat-country font-12 search-result-trip-type-bg text-white p-1">{boatType.name}</span>
            )}
          </div>
          <div>
            <span className="boat-country">
              <Truncate lines={2} ellipsis={<span>..</span>}>
                {description}
              </Truncate>
            </span>
          </div>
          <div>
            <span className="font-14 font-weight-400 search-div-date">
              {`${formattedDate(createdAt)} -`} {seller && `Posted By ${seller.firstName} ${seller.lastName}`}
            </span>
          </div>
          <div>
            <span className="font-14 font-weight-500 search-div-country">
              {`${country} |`} {manufacturedBy.alias}
            </span>
          </div>
        </div>
      </Col>

      <Col xs={1}>
        <div className="d-flex justify-content-center search-result-market-type-section">
          <span className="font-weight-400 search-result-market-type">{marketTypes.BOAT}</span>
        </div>
      </Col>

      <Col xs={1}>
        <div className="d-flex justify-content-center">
          <div className="d-flex">
            <div className="mr-1 search-result-rate-section-margin">
              <RatingComponent className="rating-clr search-result-rate-section" rating={rating} />
            </div>
            {/* <span className="rating-count">{rating}</span> */}
          </div>
        </div>
      </Col>

      <Col xs={2}>
        <CurrencyContextConsumer>
          {({ currentCurrency }) => (
            <div className="d-flex justify-content-center">
              <span className="search-result-price-section">{getConvertedPrice(price, currentCurrency)}</span>
            </div>
          )}
        </CurrencyContextConsumer>
      </Col>
    </Row>
  )
}
