import {
  GET_RENT_BOAT_BY_TRIP,
  GET_RENT_BOAT_BY_TRIP_SUCCESS,
  GET_RENT_BOAT_BY_TRIP_FAILURE,
  GET_RENT_BOAT_MOST_POPULAR_FAILURE,
  GET_RENT_BOAT_MOST_POPULAR_SUCCESS,
  GET_RENT_BOAT_MOST_POPULAR,
  GET_TYPE_WISE_LOOKUP_SUCCESS,
  GET_RECOMMENDED_TRIPS_SUCCESS,
  GET_RENT_BOAT_TRIP_CITIES,
  GET_RENT_BOAT_TRIP_CITIES_SUCCESS,
  GET_RENT_BOAT_TRIP_CITIES_FAILURE,
  GET_RENT_CITY_WISE_BOATS,
  GET_RENT_CITY_WISE_BOATS_SUCCESS,
  GET_RENT_CITY_WISE_BOATS_FAILURE,
  SEARCH_BOAT_RENT,
  SEARCH_BOAT_RENT_SUCCESS,
  SEARCH_BOAT_RENT_FAILURE,
  CLEAR_SEARCH_BOAT_RENT_FLAG,
  GET_RECOMMENDED_TRIPS,
  GET_RECOMMENDED_TRIPS_FAILURE,
  GET_ALL_TRIP_TYPES,
  GET_ALL_TRIP_TYPES_SUCCESS,
  GET_ALL_TRIP_TYPES_FAILURE,
  CREATE_RENT_BOOKING,
  CLEAR_CREATE_RENT_BOOKING,
  CREATE_RENT_BOOKING_FAILURE,
  CREATE_RENT_BOOKING_SUCCESS,
  RENT_BOOKING_PAYMENT,
  GET_RENT_BOOKINGS_BY_CUSTOMER,
  GET_RENT_BOOKINGS_BY_CUSTOMER_SUCCESS,
  GET_RENT_BOOKINGS_BY_CUSTOMER_FAILURE,
  CLEAR__GET_RENT_BOOKINGS_BY_CUSTOMER__FLAG,
  CHECK_IF_CAN_BOOK_RENT,
  CHECK_IF_CAN_BOOK_RENT__SUCCESS,
  CHECK_IF_CAN_BOOK_RENT__FAILURE,
  CLEAR__CHECK_IF_CAN_BOOK_RENT__FLAG,
} from '../actionTypes'
import { lookupTypes, rentBoatTrip } from '../../util/enums/enums'
import { toast } from 'react-toastify'

const InitialState = {
  success: false,
  error: false,
  rentSharedTrips: [],
  privateTrips: [],
  recommendedTripsPerHour: [],
  tripTypes: [],
  tripLookups: [],
  tripTypeLookups: [],
  recommendedTrips: [],
  totalRecommendedTrips: null,
  rentBoatTripCities: [],
  boatType: {},
  searchedBoatRent: [],
  totalSearchedBoatRent: null,
  isBoatRentSearched: false,
  rentMostPopularTrips: [],
  allTripTypes: [],
  rentBookingsByCustomer: [],
  rentBookingsByCustomerLoading: false,
  rentBookingsByCustomerSuccess: false,
  rentBookingsByCustomerFailure: false,
  rentBookingsByCustomerTotal: null,
}

export const rentReducer = (state = InitialState, action) => {
  switch (action.type) {
    // get boat rent by trip start

    case GET_RENT_BOAT_BY_TRIP:
      return {
        ...state,
        success: false,
        error: false,
        isPageLoader: true,
      }

    case GET_RENT_BOAT_BY_TRIP_SUCCESS:
      switch (action.alias) {
        case rentBoatTrip.SHARED_TRIP:
          return {
            ...state,
            success: true,
            error: false,
            rentSharedTrips: action.payload,
            rentSharedTripsTotal: action.total,
            isPageLoader: false,
          }

        case rentBoatTrip.PRIVATE_TRIP:
          return {
            ...state,
            success: true,
            error: false,
            privateTrips: action.payload,
            privateTripsTotal: action.total,
            isPageLoader: false,
          }

        case rentBoatTrip.RENT_PER_HOUR:
          return {
            ...state,
            success: true,
            error: false,
            isPageLoader: false,
            recommendedTripsPerHour: action.payload,
            tripsPerHourTotal: action.total,
          }

        default:
          return {
            ...state,
          }
      }

    case SEARCH_BOAT_RENT:
      return {
        ...state,
        searchedBoatRent: [],
        isBoatRentSearched: false,
        isPageLoader: true,
      }

    case SEARCH_BOAT_RENT_SUCCESS:
      return {
        ...state,
        searchedBoatRent: action.payload.items,
        totalSearchedBoatRent: action.payload.total,
        isBoatRentSearched: true,
        isPageLoader: false,
      }

    case SEARCH_BOAT_RENT_FAILURE:
      return {
        ...state,
        searchedBoatRent: [],
        isBoatRentSearched: false,
        isPageLoader: false,
      }

    case CLEAR_SEARCH_BOAT_RENT_FLAG:
      return {
        ...state,
        isBoatRentSearched: false,
      }

    case GET_RENT_BOAT_BY_TRIP_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }

    // end
    // get boat rent most popular

    case GET_RENT_BOAT_MOST_POPULAR:
      return {
        ...state,
        isMostPopularSuccess: false,
        isMostPopularError: false,
        isPageLoader: true,
      }

    case GET_RENT_BOAT_MOST_POPULAR_SUCCESS:
      return {
        ...state,
        isMostPopularSuccess: true,
        isMostPopularError: false,
        rentMostPopularTrips: action.payload.data.getMostPopularBoatRents.items,
        rentMostPopularTripsTotal: action.payload.data.getMostPopularBoatRents.total,
        isPageLoader: false,
      }

    case GET_RENT_BOAT_MOST_POPULAR_FAILURE:
      return {
        ...state,
        isMostPopularSuccess: false,
        isMostPopularError: true,
        isPageLoader: false,
      }

    case GET_TYPE_WISE_LOOKUP_SUCCESS:
      return {
        ...state,
        tripTypes: action.value === lookupTypes.TRIP ? action.payload : [...state.tripTypes],
        tripLookups: action.value === lookupTypes.TRIP ? action.payload : [...state.tripLookups],
        tripTypeLookups: action.value === lookupTypes.TRIP_TYPE ? action.payload : [...state.tripTypeLookups],
      }

    case GET_RECOMMENDED_TRIPS:
      return {
        ...state,
        isPageLoader: true,
      }
    case GET_RECOMMENDED_TRIPS_SUCCESS:
      return {
        ...state,
        recommendedTrips: action.payload.items,
        totalRecommendedTrips: action.payload.total,
        isPageLoader: false,
      }
    case GET_RECOMMENDED_TRIPS_FAILURE:
      return {
        ...state,
        isPageLoader: false,
      }

    case GET_RENT_BOAT_TRIP_CITIES:
      return {
        ...state,
        rentBoatTripCities: [],
      }

    case GET_RENT_BOAT_TRIP_CITIES_SUCCESS:
      return {
        ...state,
        rentBoatTripCities: action.payload,
      }

    case GET_RENT_BOAT_TRIP_CITIES_FAILURE:
      return {
        ...state,
        rentBoatTripCities: [],
      }

    case GET_RENT_CITY_WISE_BOATS:
      return {
        ...state,
        cityWiseBoats: [],
        isPageLoader: true,
      }

    case GET_RENT_CITY_WISE_BOATS_SUCCESS:
      return {
        ...state,
        cityWiseBoats: action.payload.items,
        totalCityWiseBoats: action.payload.total,
        isPageLoader: false,
      }

    case GET_RENT_CITY_WISE_BOATS_FAILURE:
      return {
        ...state,
        cityWiseBoats: [],
        isPageLoader: false,
      }

    case GET_ALL_TRIP_TYPES:
      return {
        ...state,
        allTripTypes: [],
        allTripTypes_success: false,
        allTripTypes_error: false,
      }

    case GET_ALL_TRIP_TYPES_SUCCESS:
      return {
        ...state,
        allTripTypes: action.payload,
        allTripTypes_success: true,
        allTripTypes_error: false,
      }

    case GET_ALL_TRIP_TYPES_FAILURE:
      return {
        ...state,
        allTripTypes_success: false,
        allTripTypes_error: true,
      }

    case RENT_BOOKING_PAYMENT:
      return {
        ...state,
        rentBookingPaymentLoading: action.payload,
      }

    case CHECK_IF_CAN_BOOK_RENT:
      return {
        ...state,
        canBookRentLoading: true,
        canBookRentSuccess: false,
        canBookRentFailure: false,
      }

    case CHECK_IF_CAN_BOOK_RENT__SUCCESS:
      return {
        ...state,
        canBookRentSuccess: true,
        canBookRentFailure: false,
      }

    case CHECK_IF_CAN_BOOK_RENT__FAILURE:
      toast.error(action.payload, { autoClose: 5000 })

      return {
        ...state,
        canBookRentSuccess: false,
        canBookRentFailure: true,
      }

    case CLEAR__CHECK_IF_CAN_BOOK_RENT__FLAG:
      return {
        ...state,
        canBookRentLoading: false,
        canBookRentSuccess: false,
        canBookRentFailure: false,
      }

    case CREATE_RENT_BOOKING:
      return {
        ...state,
        createRentBookingLoading: true,
        createRentBookingSuccess: false,
        createRentBookingFailure: false,
      }

    case CREATE_RENT_BOOKING_SUCCESS:
      return {
        ...state,
        createRentBookingFailure: false,
        createRentBookingSuccess: true,
        createRentBookingData: action.payload,
      }

    case CREATE_RENT_BOOKING_FAILURE:
      return {
        ...state,
        createRentBookingFailure: true,
        createRentBookingSuccess: false,
      }

    case CLEAR_CREATE_RENT_BOOKING:
      return {
        ...state,
        createRentBookingLoading: false,
        createRentBookingFailure: false,
        createRentBookingSuccess: false,
        createRentBookingData: null,
      }

    case GET_RENT_BOOKINGS_BY_CUSTOMER:
      return {
        ...state,
        rentBookingsByCustomerLoading: true,
        rentBookingsByCustomerSuccess: false,
        rentBookingsByCustomerFailure: false,
      }

    case GET_RENT_BOOKINGS_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        rentBookingsByCustomerLoading: false,
        rentBookingsByCustomer: action.payload.items,
        rentBookingsByCustomerTotal: action.payload.total,
        rentBookingsByCustomerSuccess: true,
        rentBookingsByCustomerFailure: false,
      }

    case GET_RENT_BOOKINGS_BY_CUSTOMER_FAILURE:
      return {
        ...state,
        success: false,
        rentBookingsByCustomerLoading: false,
        rentBookingsByCustomerSuccess: false,
        rentBookingsByCustomerFailure: true,
        error: true,
      }

    case CLEAR__GET_RENT_BOOKINGS_BY_CUSTOMER__FLAG:
      return {
        ...state,
        rentBookingsByCustomerLoading: false,
        rentBookingsByCustomerSuccess: false,
        rentBookingsByCustomerFailure: false,
      }

    default:
      return state
  }
}
