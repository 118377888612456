import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Route } from 'react-router-dom'
import { extractSubdomainPath } from '../util/common'
import { getSeoPages } from '../redux/actions/seoActions'

const RouteWithSeo = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const seoItems = useSelector(state => state.seoReducer.seoItems || [])
  const fetchLoading = useSelector(state => state.seoReducer.fetchLoading)

  let pageMeta = seoItems.find(item => {
    return [extractSubdomainPath(item.page), rest.location.pathname].includes(rest.location.pathname)
  })

  pageMeta = pageMeta || {}
  pageMeta.title = pageMeta.title || 'AdamSea - World is yours to explore'
  pageMeta.description =
    pageMeta.description ||
    'AdamSea is an Online Marine Market Place for Buyers and Sellers. outstanding selection of boats and yacht for sale. Online rent and charter. online marina and storage. Hair online yacht services'

  useEffect(() => {
    dispatch(getSeoPages({ pathname: rest.location.pathname }))
  }, [rest.location.pathname])

  return (
    <React.Fragment>
      <Helmet>
        {fetchLoading === false && (
          <>
            {pageMeta.title && <title>{pageMeta.title}</title>}
            {pageMeta.metaTitle && <meta name="title" content={pageMeta.metaTitle || ''} />}
            {pageMeta.description && <meta name="description" content={pageMeta.description} />}
            {pageMeta.keywords && <meta name="keywords" content={pageMeta.keywords ? pageMeta.keywords.join(',') : ''} />}
            {pageMeta.canonicalLink && <link rel="canonical" href={window.location.host + (pageMeta.canonicalLink || '') }/>}
          </>
        )}
      </Helmet>
      <Route {...rest} component={Component} />
    </React.Fragment>
  )
}

export default RouteWithSeo
