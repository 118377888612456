import gql from 'graphql-tag'

import { Media, AddressType, PageVisit, Like } from './schemaTypes/schemaTypes'

export const createArticle = gql`
  mutation createArticle($input: ArticleInput!) {
    createArticle(input: $input) {
      id
    }
  }
`

export const getAllArticles = gql`
	query getAllArticlesByUser($page: Int!, $limit: Int!,$userId:String, $sort: SortType, $sortKey: String) {
		getAllArticlesByUser(userId:$userId ,page: $page, limit: $limit, sort: $sort, sortKey: $sortKey) {
			items {
				id
				title
				adId
				description
				shortDescription
				titleSlug
				files ${Media}
				user {
					id,
				}
				articleApproved
				status
				createdAt
				pageVisits ${PageVisit}
			    likes ${Like}
			}
		
			total,
		}
	}
`

export const getArticleById = gql`
	query getArticleById ($id: String!) {
		getArticleById(id: $id) {
			id
			title
			adId
			user{
				id
				firstName
				lastName
				companyName
				companyLogo ${Media}
				image ${Media}
				address ${AddressType}
				createdAt
				role{
					role
				}
				subscribers {
					id
				}
			}
			description
			meta_tags
			shortDescription
			titleSlug
			files ${Media}
			articleViewCount
			articleApproved
			status
			pageVisits ${PageVisit}
			likes ${Like}
			userTotalArticles
			createdAt
			updatedAt
		}
	}
`
export const deleteArticle = gql`
  mutation deleteArticle($id: String!) {
    deleteArticle(id: $id) {
      id
    }
  }
`

export const updateArticle = gql`
  mutation updateArticle($input: ArticleInput!) {
    updateArticle(input: $input) {
      id
    }
  }
`

export const articleChangeStatus = gql`
  mutation articleChangeStatus($value: Boolean!, $columnName: String!, $id: String!) {
    articleChangeStatus(value: $value, columnName: $columnName, id: $id) {
      id
      message
    }
  }
`
