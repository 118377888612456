import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { CheckCircleOutline } from '@material-ui/icons'
import { get } from 'lodash'
import moment from 'moment'
import QRCode from 'react-qr-code'
import { PDFExport, savePDF } from '@progress/kendo-react-pdf'
import { Layout } from '../../components'
import { clearGetRentTicketFlags, getRentTicket } from '../../redux/actions'
import { AiOutlineCloudDownload, AiOutlineLoading3Quarters, AiOutlinePrinter, AiOutlineWarning } from 'react-icons/ai'
import { footerLinks, TripTypeCategory } from '../../util/enums/enums'
import { getAddress, redirectToUserProfile } from '../../helpers/jsxHelper'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import './ViewRentTicket.scss'
import { clearRentPagesFlag, getRentPages } from '../../redux/actions/pageInfoByTypeAction'
import { googleDirectionUrl } from '../../helpers/boatHelper'
import { prettifyPhoneNumber } from '../../helpers/validationHelper'
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa'

const ViewRentTicket = ({
  history,
  match: { params },
  siteConfigurations,
  getTicket,
  clearTicketFlags,
  ticket,
  ticketLoading,
  ticketSuccess,
  ticketFailure,
  getRentPages,
  clearRentPagesFlag,
  rentPages,
  rentPagesSuccess,
  rentPagesFailure,
}) => {
  const [slot, setSlot] = useState({})
  const [content, setContent] = useState({})
  const [printing, setPrinting] = useState(false)
  const [data, setData] = useState({})

  const pdfExportComponent = useRef(null)
  const pdfDOMElem = useRef(null)

  useEffect(() => {
    if (params.id) getTicket(params.id)
  }, [params])

  useEffect(() => {
    if (ticketSuccess) {
      const _slot = get(ticket, 'boat.tripDetails', []).find(s => String(s._id) === get(ticket, 'slot', ''))
      setSlot(_slot)

      let _data = {}
      _data._id = get(ticket, '_id', '')
      _data.userName = get(ticket, 'boat.user.firstName', '') + ' ' + get(ticket, 'boat.user.lastName', '')
      _data.userEmail = get(ticket, 'boat.user.email', '')
      _data.userPic = get(ticket, 'boat.user.image.url', '')
      _data.userPhone = prettifyPhoneNumber(get(ticket, 'boat.user.mobileNumber', ''))
      _data.siteLogo = siteConfigurations?.siteLogo?.url
      _data.customerName = get(ticket, 'customer.firstName', '') + ' ' + get(ticket, 'customer.lastName', '')
      _data.slotStartDate = moment(_slot.startTime).utc().format('ddd, MMM DD')
      _data.slotStartTime = moment(_slot.startTime).utc().format('hh:mm A')
      _data.slotEndTime = moment(_slot.endTime).utc().format('hh:mm A')
      _data.units = get(ticket, 'units', '')
      _data.unitsLabel = slot.type === TripTypeCategory.rentPerHour ? 'Boat' : 'Passenger'
      _data.ticketId = get(ticket, 'ticketId', '')
      _data.boatModel = get(ticket, 'boat.model', '')
      _data.gatheringPoint = getAddress(get(ticket, 'boat.address', []))?.gatheringPoint?.address1?.replace(
        /(^[A-Z0-9]+\+[A-Z0-9]+\,?\s?)?/,
        ''
      )
      _data.captainName = get(ticket, 'boat.captainName', '')
      _data.tripType = get(ticket, 'boat.tripType.alias', '')
      _data.price = getConvertedPrice(get(ticket, 'payment.totalAmount', 0))
      _data.boatAdId = get(ticket, 'boat.adId', '')
      _data.deposit = get(ticket, 'boat.costOfDeposit', '')
        ? get(ticket, 'boat.costOfDeposit', '') + ' USD'
        : get(ticket, 'boat.deposit.alias', '-')
      _data.isCancelled = get(ticket, 'isCancelled', false)
      _data.qrCode = ''
      _data.whatToBring = get(ticket, 'boat.whatToBring', '-')
      _data.policy = get(ticket, 'boat.policy', '-')
      _data.depositPageContent = content && content[footerLinks.rent.deposit]?.pageContent
      _data.whatToDoPageContent = content && content[footerLinks.rent.bookingWhatToDo]?.pageContent

      setData(_data)
    }

    if (ticketFailure) toast.error('Unable to load the ticket.', { autoClose: 5000 })

    if (ticketSuccess || ticketFailure) clearTicketFlags()
  }, [ticketSuccess, ticketFailure])

  useEffect(() => {
    getRentPages()
  }, [])

  useEffect(() => {
    const pages = {}
    ;(rentPages || []).forEach(p => (pages[p.titleSlug] = p))
    setContent(pages)

    if (rentPagesSuccess || rentPagesFailure) clearRentPagesFlag()
  }, [rentPagesSuccess, rentPagesFailure, rentPages])

  useEffect(() => {
    printing &&
      savePDF(pdfDOMElem.current, { fileName: data.customerName.toLowerCase().replace(' ', '-') }, () => setPrinting(false))
  }, [printing])

  return (
    <>
      <Layout>
        <div className="rent-ticket-cont adam-dash-booking">
          <div className="cont-header">
            <div>
              {data.isCancelled ? <FaRegTimesCircle className="crossmark" /> : <FaRegCheckCircle className="checkmark" />}
            </div>
            <h3>Your booking is {data.isCancelled ? 'cancelled' : 'confimed'}.</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: content && content[footerLinks.rent.bookingConfirmed]?.pageContent,
              }}
            />
          </div>

          <div className="cont-body">
            {printing || (
              <div className="ticket-actions">
                <button className="ticket-action" onClick={() => setPrinting(true)}>
                  <AiOutlineCloudDownload /> Download Ticket
                </button>
              </div>
            )}

            <PDFExport ref={pdfExportComponent} paperSize="auto">
              <div ref={pdfDOMElem}>
                <div className={'rent-ticket ' + (printing ? 'printing' : '')}>
                  {ticketLoading ? (
                    <div className="ticket-loading">
                      <AiOutlineLoading3Quarters className="ticket-spinner" />
                    </div>
                  ) : ticketFailure ? (
                    <div className="ticket-error">
                      <AiOutlineWarning /> Unable to load the ticket.
                    </div>
                  ) : (
                    <>
                      <div className="ticket-header">
                        <div className="ticket-header-l">
                          <div
                            className="user-pic"
                            onClick={() => redirectToUserProfile(get(ticket, 'boat.user', null), history)}
                          >
                            <img loading="lazy" src={data.userPic} alt='User'/>
                          </div>
                          <div className="user-name-cont">
                            <div
                              className="user-name"
                              onClick={() => redirectToUserProfile(get(ticket, 'boat.user', null), history)}
                            >
                              {data.userName}
                            </div>
                            <div className="user-phone">{data.userPhone}</div>
                          </div>
                        </div>
                        <div className="ticket-header-r">
                          <div>
                            <img loading="lazy" src={data.siteLogo} alt="AdamSea" className="site-logo" />
                          </div>
                        </div>
                      </div>

                      <div className="ticket-body">
                        <div className="body-row body-row-top">
                          <div>
                            <div className="customer-name">{data.customerName}</div>
                            <div className="booking-dets">
                              <span>{data.slotStartDate}</span>
                              <span className="px-2">|</span>
                              <span>
                                {data.slotStartTime} - {data.slotEndTime}
                              </span>
                              <span className="px-2">|</span>
                              <span>
                                {data.units} {data.unitsLabel}
                              </span>
                            </div>
                          </div>
                          <div className="text-right">
                            <div className="ticket-id-label">Ticket ID</div>
                            <div className="ticket-id-value">{data.ticketId}</div>
                          </div>
                        </div>

                        <div className="body-row body-row-middle">
                          <div className="boat-det">
                            <div className="boat-det-label txt-no-break">Boat Model</div>
                            <div className="boat-det-value txt-no-break">{data.boatModel}</div>
                          </div>
                          <div className="boat-det">
                            <div className="boat-det-label txt-no-break">Gathering Point</div>
                            <a
                              href={googleDirectionUrl(
                                getAddress(get(ticket, 'boat.address', []))?.gatheringPoint?.geometricLocation
                              )}
                              target="_blank"
                              className="boat-det-value"
                            >
                              {data.gatheringPoint}
                            </a>
                          </div>
                          <div className="boat-det">
                            <div className="boat-det-label txt-no-break">Captain Name</div>
                            <div className="boat-det-value txt-no-break">{data.captainName}</div>
                          </div>
                          <div className="boat-det">
                            <div className="boat-det-label txt-no-break">Trip Type</div>
                            <div className="boat-det-value txt-no-break">{data.tripType}</div>
                          </div>
                        </div>

                        <div className="body-row body-row-bottom">
                          <div className="ticket-price">
                            <div className="price-label">Total Price</div>
                            <div className="price-value">{data.price}</div>
                            <br />
                            <div className="price-value">AD ID: {data.boatAdId}</div>
                          </div>
                          <div className="ticket-price">
                            <div className="price-label">Trip Deposit</div>
                            <div className="price-value">{data.deposit}</div>
                          </div>
                          {data.isCancelled ||
                            (data._id && (
                              <div className="ticket-qr">
                                <QRCode value={data._id} size={100} />
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className={'ticket-dets ' + (printing ? 'printing' : '')}>
                  <div className="dets-list">
                    <div className="list-label">What to bring</div>
                    <div className="list-value">
                      <p>{data.whatToBring}</p>
                    </div>
                  </div>

                  <div style={{ flex: 1 }}></div>

                  <div className="dets-list">
                    <div className="list-label">{data.userName}'s Policy</div>
                    <div className="list-value">
                      <p>{data.policy}</p>
                    </div>
                  </div>

                  <div style={{ flex: 1 }}></div>

                  <div className="dets-list">
                    <div className="list-label">
                      Deposit <strong>({data.deposit})</strong>
                    </div>
                    <div className="list-value">
                      <p dangerouslySetInnerHTML={{ __html: data.depositPageContent }} />
                    </div>
                  </div>

                  <div style={{ flex: 1 }}></div>

                  <div className="dets-list">
                    <div className="list-label">What to do?</div>
                    <div className="list-value">
                      <p dangerouslySetInnerHTML={{ __html: data.whatToDoPageContent }} />
                    </div>
                  </div>
                </div>

                {printing && (
                  <div className="ticket-footer text-grey">
                    <p>
                      Never send or wire money to sellers or buyers. This includes mailing a cheque or using payment services
                      like PayPal, Amazon Payments, Google Wallet/ Checkout, Canada Post, any international post, Western Union
                      or MoneyGram. Typically, scammers will use these services and will even falsify documents from a
                      legitimate company. It is best to avoid them entirely and keep things local in your AdamSea.com account!
                      AdamSea will not be responsible for any transactions that may take place out of AdamSea.com.
                    </p>
                    <p class="footer-logo">
                      Powered by
                      <img loading="lazy" src={data.siteLogo} alt='Logo'/>
                    </p>
                  </div>
                )}
              </div>
            </PDFExport>
          </div>
        </div>
      </Layout>
    </>
  )
}

const mapStateToProps = state => ({
  siteConfigurations: state.loginReducer.siteConfigurations,
  ticket: state.boatRentReducer.rentTicket,
  ticketLoading: state.boatRentReducer.rentTicketLoading,
  ticketSuccess: state.boatRentReducer.rentTicketSuccess,
  ticketFailure: state.boatRentReducer.rentTicketFailure,
  rentPages: state.pageInfoByTypeReducer?.rentPages,
  rentPagesSuccess: state.pageInfoByTypeReducer?.rentPagesSuccess,
  rentPagesFailure: state.pageInfoByTypeReducer?.rentPagesFailure,
})

const mapDispatchToProps = dispatch => ({
  getTicket: bookingId => dispatch(getRentTicket(bookingId)),
  clearTicketFlags: () => dispatch(clearGetRentTicketFlags()),
  getRentPages: () => dispatch(getRentPages()),
  clearRentPagesFlag: () => dispatch(clearRentPagesFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewRentTicket)
