import { sessionId } from './string'

// Local Storage
export const setLoggedUser = (currentUser, isUpdate = false) => {
  if (!isUpdate) {
    setLocalStorageItem('token', currentUser.token)
    window.dispatchEvent( new Event('storage') ) 
    setLocalStorageItem('isAuthenticated', true)
    currentUser.id && setLocalStorageItem('userId', currentUser.id)
    currentUser.role && setLocalStorageItem('role', JSON.stringify(currentUser.role))
    currentUser.image?.url && setLocalStorageItem('userImage', currentUser.image.url)
    currentUser.firstName && setLocalStorageItem('firstName', currentUser.firstName)
    setLocalStorageItem('isVerified', currentUser.isVerified)
    setLocalStorageItem('isProfileCompleted', currentUser.isProfilecompleted)
    currentUser.documentVerification && setLocalStorageItem('documentStatus', JSON.stringify(currentUser.documentVerification))
    currentUser.provider && setLocalStorageItem('provider', JSON.stringify(currentUser.provider))
  }
  currentUser.image?.url && setLocalStorageItem('userImage', currentUser.image.url)
  currentUser.firstName && setLocalStorageItem('firstName', currentUser.firstName)
  setLocalStorageItem('isVerified', currentUser.isVerified)
  setLocalStorageItem('isProfileCompleted', currentUser.isProfilecompleted)
  currentUser.documentVerification && setLocalStorageItem('documentStatus', JSON.stringify(currentUser.documentVerification))
}

export const clearLocalStorage = () => {
  removeLocalStorageItem('token')
  removeLocalStorageItem('isAuthenticated')
  removeLocalStorageItem('userId')
  removeLocalStorageItem('role')
  removeLocalStorageItem('userImage')
  removeLocalStorageItem('firstName')
  removeLocalStorageItem('isVerified')
  removeLocalStorageItem('isProfileCompleted')
  removeLocalStorageItem('documentStatus')
  removeLocalStorageItem('provider')
  removeLocalStorageItem('adminLoginDetected')
  removeZohoChatWidget()

}

export const setUserLocationDetails = ({ country, countryCode, ipAddress, currencyCode }) => {
  country && setLocalStorageItem('expiry', getTime(24)) // 24 hours
  country && setLocalStorageItem('country', country)
  countryCode && setLocalStorageItem('countryCode', countryCode)
  ipAddress && setLocalStorageItem('ipAddress', ipAddress)
  currencyCode && setLocalStorageItem('currentCurrency', currencyCode)
}

export const getLocalStorageExist = () => {
  return typeof localStorage !== 'undefined'
}

export const getLocalStorageItem = (key = '') => {
  return getLocalStorageExist() ? localStorage.getItem(key) || '' : ''
}

export const setLocalStorageItem = (key = '', value = '') => {
  getLocalStorageExist() ? localStorage.setItem(key, value) : null
}

export const removeLocalStorageItem = (key = '') => {
  getLocalStorageExist() ? localStorage.removeItem(key) : null
}

export const localStorageClear = () => {
  getLocalStorageExist() ? localStorage.clear() : null
}

export const getLocalInfo = (key = '') => {
  return getLocalStorageExist() ? (localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) || '') : '') : null
}

// Session Storage
export const getSessionStorageItem = (key = '') => {
  return sessionStorage.getItem(key)
}

export const setSessionStorageItem = (key = '', data = null) => {
  return sessionStorage.setItem(key, data)
}

export const setSessionId = async () => {
  setLocalStorageItem('sessionId', sessionId())
}

export const getTime = hours => {
  const now = new Date().getTime()
  if (hours) {
    return now + 1000 * 60 * 60 * hours
  } else {
    return now
  }
}

export const isExpiredLocalStorage = () => {
  return getTime() > +getLocalStorageItem('expiry')
}

export const removeZohoChatWidget = () => {
  try {
    const widgetDiv = document.getElementsByClassName('zsiq_flt_rel')

    if(widgetDiv.length){
      widgetDiv[0].style.display = 'none'
    }
    $zoho.salesiq.reset()
  } catch (error) {
    console.log(error)
  }
}

export const resetZohoChatCookies = () => {
  $zoho.salesiq.reset()
}