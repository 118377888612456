const notificationType = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  MODIFY: 'MODIFY',
  CHANGE: 'CHANGE',
  SUBMIT: 'SUBMIT',
  REQUEST: 'REQUEST',
  ACCEPT: 'ACCEPT',
  DECLINE: 'DECLINE',
  UNDER_VERIFICATION: 'UNDER_VERIFICATION',
}

export const messageFormat = (message, type, isModifiedMessage = false) => {
  switch (type) {
    case notificationType.CREATE:
      return `${message} is added successfully`

    case notificationType.UPDATE:
      return `${message} is updated successfully`

    case notificationType.DELETE:
      return `${message} is deleted successfully`

    case notificationType.MODIFY:
      return `${message} is modified successfully`

    case notificationType.CHANGE:
      return `${message} status changed successfully`

    case notificationType.SUBMIT:
      return `${message} is submitted successfully`

    case notificationType.REQUEST:
      return `${message} is requested successfully`

    case notificationType.ACCEPT:
      return `${message} is accepted successfully`

    case notificationType.DECLINE:
      return `${message} is declined ${isModifiedMessage ? '' : 'successfully'}`

    case notificationType.UNDER_VERIFICATION:
      return `${message} is Under Verification`

    default:
      return message
  }
}

export const notificationEnum = {
  BOAT_CREATE: {
    message: messageFormat('Boat', notificationType.CREATE),
    url: '/manage-boats',
  },
  BOAT_DELETE: {
    message: messageFormat('Boat', notificationType.DELETE),
    url: '/manage-boats',
  },
  BOAT_UPDATE: {
    message: messageFormat('Boat', notificationType.UPDATE),
    url: '/manage-boats',
  },
  ARTICLE_CREATE: {
    message: messageFormat('Article', notificationType.CREATE),
    url: '/manage-articles',
  },
  ARTICLE_UPDATE: {
    message: messageFormat('Article', notificationType.UPDATE),
    url: '/manage-articles',
  },
  ARTICLE_DELETE: {
    message: messageFormat('Article', notificationType.DELETE),
    url: '/manage-articles',
  },
  BOAT_SHOW_CREATE: {
    message: messageFormat('Boat Show', notificationType.CREATE),
    url: '/manage-boat-shows',
  },
  BOAT_SHOW_UPDATE: {
    message: messageFormat('Boat Show', notificationType.UPDATE),
    url: '/manage-boat-shows',
  },
  BOAT_SHOW_DELETE: {
    message: messageFormat('Boat Show', notificationType.DELETE),
    url: '/manage-boat-shows',
  },
  BOAT_SHOW_STATUS_CHANGE: {
    message: messageFormat('Boat Show', notificationType.CHANGE),
  },
  EMAIL_VERIFY: {
    message: messageFormat('Your Email is verified, please login to continue'),
  },
  EMAIL_VERIFY_FAIL: {
    message: messageFormat('Email verification is failed'),
  },
  MOBILE_VERIFY: {
    message: messageFormat('Your Mobile Number is verified'),
  },
  SURVEYOR_REQUEST: {
    message: messageFormat('Surveyor requested successfully'),
  },
  MARINA_CREATE: {
    message: messageFormat('Marina & Storage', notificationType.CREATE),
    url: '/manage-marina-storage',
  },
  MARINA_UPDATE: {
    message: messageFormat('Marina & Storage', notificationType.UPDATE),
    url: '/manage-marina-storage',
  },
  MARINA_STATUS_CHANGE: {
    message: messageFormat('Marina & Storage', notificationType.CHANGE),
  },
  MARINA_DELETE: {
    message: messageFormat('Marina & Storage', notificationType.DELETE),
    url: '/manage-marina-storage',
  },
  BOAT_SERVICE_MODIFY: {
    message: messageFormat('Boat Service', notificationType.MODIFY),
  },
  FEEDBACK_SUBMITTED: {
    message: messageFormat('Feedback', notificationType.SUBMIT),
  },
  ARTICLE_UNDER_VERIFICATION: {
    message: messageFormat('Article', notificationType.UNDER_VERIFICATION),
  },
  SURVEY_REPORT_UPDATE: {
    message: messageFormat('Survey Report', notificationType.UPDATE),
  },
  DEPOSIT_ADDED: {
    message: messageFormat('Deposit', notificationType.CREATE),
  },
  CREATE_REVIEW: {
    message: messageFormat('Review', notificationType.CREATE),
  },
  DEALER_REQUEST_MANUFACTURER: {
    message: messageFormat('Manufacturer', notificationType.REQUEST),
  },
  DEALER_REQUEST_MANUFACTURERS: {
    message: messageFormat('All selected Manufacturers are requested successfully'),
  },
  MANUFACTURE_ACCEPT_DEALER: {
    message: messageFormat('Dealer', notificationType.ACCEPT),
  },
  MANUFACTURE_DECLINE_DEALER: {
    message: messageFormat('Dealer', notificationType.DECLINE),
  },
  MANUFACTURE_ENABLE_DISABLE_DEALER: {
    message: messageFormat('Dealer', notificationType.CHANGE),
  },
  AGENT_ACCEPT_REQUEST: {
    message: messageFormat('Request', notificationType.ACCEPT),
  },
  AGENT_DECLINE_REQUEST: {
    message: messageFormat('Request', notificationType.DECLINE, true),
  },
  SALES_ENGINE_PAYMENT: {
    message: messageFormat('Payment Success'),
  },
}

export const dynamicError = (errors, def = null) => {
  return {
    message: errors?.[0]?.message || def || 'Some error occurred',
  }
}
