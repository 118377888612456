import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChatContext } from '../../contexts/ChatContext'
import { getFirstCharacter } from '../../helpers/jsxHelper'
import { nameFormatter } from '../../helpers/string'
import {
  addIdInMessage,
  addMessageByChatId,
  clearChatThreadIfNotChatId,
  getChatUsers,
  getMessageByChatId,
  isTextFalse,
  readNewMessageByChatId,
  setDeliveredMessage,
  setMessagePayload,
  setReadMessage,
  setSelectUser,
  setUnreadCount,
  setUserList,
} from '../../redux/actions/chatAction'
import {
  getChatsList,
  getHasChatCreated,
  getIsEditGroupScreen,
  getIsFriendView,
  getIsGroupChatCreateScreen,
  getSelectUser,
  getUserProfileChatId,
} from '../../redux/selector/chat'
import { chatDateWiseLabel, chatType } from '../../util/chat'
import { socketEvent } from '../../util/enums/socketEnums'
import { socket } from '../../socket'
import { CustomerMessageTextType, MessageTypeEnum } from '../../util/enums/enums'
// import UserContext from '../../UserContext'

export const LeftUserList = member => {
  const {  currentUser } = useContext(ChatContext)
  const dispatch = useDispatch()

  const selectUser = useSelector(getSelectUser)
  const chats = useSelector(getChatsList)
  const isEditGroupScreen = useSelector(getIsEditGroupScreen)
  const hasChatCreated=useSelector(getHasChatCreated)
  const isGroup = useSelector(getIsGroupChatCreateScreen)
  const userProfileChatId = useSelector(getUserProfileChatId)
  const isFriendView = useSelector(getIsFriendView)

  const [freezed, setFreezed] = useState(false)

  useEffect(() => {
    if (member.chatType === chatType.normalchat) {
      setFreezed(member.users?.some(u => u.id === member.userId && u.freezed))
    }
  }, [])

   const selectUserHandle = () => {
    dispatch(setMessagePayload(''))

    if (isEditGroupScreen) return
    if (isGroup) return
    if (selectUser?.chatId !== member.chatId || !Object.keys(selectUser)?.length || !member?.chatId) {
        socket.emit(socketEvent.LeaveChannel,selectUser?.chatId)
        socket.emit(socketEvent.JoinChannel,member.chatId)
        socket.emit(socketEvent.ReadMessages,member.chatId )
        socket.on(socketEvent.ReadMessages,(data)=>{
          dispatch(setReadMessage(data))
        })
        dispatch( setSelectUser({
          ...member,
          id: member.id,
          userId: member.userId,
          membersId: [member.id],
          chatId: member.chatId,
          userMeta: member?.metaData,
          isOnline: member?.isOnline,
          name: member.name,
          isBlocked: member?.isBlocked,
          groupName: member?.members?.map(i => i.name),
        }))
        dispatch(setUnreadCount({chatId:member.chatId,unreadCounts:0}))

    }

    if (chats?.length && member.chatId) {

      if (selectUser?.chatId !== member.chatId) {
        const hasOnMessageListner = socket.hasListeners(socketEvent.Message)
        if(!hasOnMessageListner){
        socket.on(socketEvent.Message,(data)=>{
          if(data.sender.id !== currentUser.id || data.messageType===MessageTypeEnum.AUDIO){
            dispatch(addMessageByChatId(data))
            data.messageType===MessageTypeEnum.AUDIO && dispatch(isTextFalse())
            }
            else{
              dispatch(addIdInMessage(data))
              dispatch(isTextFalse())
            }
        })
      }
        // .on(socketEvent.UserChats,(data)=>{
        //   dispatch(setUserList(data))
        //   dispatch(setUnreadCount(data))
      // })
      dispatch(getChatUsers({chatId:member.chatId}))
        dispatch(getMessageByChatId({ chatId: member.chatId ,limit:25}))
        dispatch(readNewMessageByChatId({ chatId: member.chatId ,limit:25}))
      }
    } else {
      // dispatch(clearChatThreadIfNotChatId())
    }

    const child = document.getElementById('chat-left-user-info-chat')
    child.parentElement.classList.add('Chat-opend')

    const child2 = document.getElementById('chat-left-space-mobile-handel')
    child2.classList.add('Chat-opend')

    document.getElementById("chat-right-user-profile")?.classList?.remove('Chat-closed');
    document.getElementById("chat-rigt-div-display-mobile")?.classList?.remove('Chat-closed');
  }

  useEffect(()=>{
    if(hasChatCreated){
      dispatch(getChatUsers({chatId:selectUser.chatId}))
      dispatch(getMessageByChatId({ chatId: selectUser.chatId ,limit:25}))
      dispatch(readNewMessageByChatId({ chatId: selectUser.chatId ,limit:25}))
    }
  },[hasChatCreated])

  useEffect(() => {
    if (userProfileChatId) {
      socket.emit(socketEvent.Message,JSON.stringify({ chatId: member.chatId }))
      dispatch(setReadMessage(data))
      dispatch(getChatUsers({chatId:userProfileChatId}))
      dispatch(getMessageByChatId({ chatId: userProfileChatId }))
      dispatch(readNewMessageByChatId({ chatId: userProfileChatId }))

    }
  }, [userProfileChatId])
  const message = member?.lastMsgId?.messageType === MessageTypeEnum.TEXT?(member?.lastMsgId.messageBody):CustomerMessageTextType[member?.lastMsgId?.messageType]

  return (
    <div
      className={classNames('left--chat--div--spacing', {
        'active--chat': selectUser?.chatId === member.id,
      })}
      key={`${member?.id}-${member.name}`}
      onClick={selectUserHandle}
    >
      <div className="chat--left--user--info">
        <div className="chat--left--user--image">
          {freezed ? <img src={require('../../assets/images/user_9136017.svg')} alt='user' /> : member?.imageUrl ? <img loading="lazy" src={member.imageUrl} alt="Profile" /> : (member?.profileImage&&!member?.chatId)?<img loading="lazy" src={member.profileImage} alt="Profile" />:getFirstCharacter(member?.name)}
          {member.chatType === chatType.normalchat && !isFriendView && (
            <div className="chat--online">
              <div
                className={classNames('online-div-user', {
                  disconnect: !member.isOnline,
                })}
              ></div>
            </div>
          )}
        </div>
        <div className="chat--left--user--desc">
          <div className="chat--left--user--name--date">
            <p className="chat--left--user--name mb-0">{freezed ? 'Deleted User' : nameFormatter([member?.name])}</p>
            <p className="chat--left--user--city chat--left--user--role--city mb-0">{message}</p>
          </div>
          <div className="d-flex justify-content-between flex-column">
            {!isFriendView && (
              <div className="chat--left--user--date chat--notification--div--height">
                <p className="mb-0">{member?.lastUpdateTime?chatDateWiseLabel(member?.lastUpdateTime, true):""}</p>
              </div>
            )}
            {member.unreadCounts > 0 && (
              <div className="chat--notification--div--height d-flex align-items-center">
                <span className="message--no--notification mb-0">{member.unreadCounts}</span>
              </div>
            )}
            {member?.isBlocked && (
              <span className="left-block-user">
                <img loading="lazy" src={require('../ws/Images/blockUser.png')} alt='block user'/>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
