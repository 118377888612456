import classNames from 'classnames'
import React from 'react'
import Linkify from 'react-linkify';
import { useSelector } from 'react-redux'

import { LinkPreview } from './chatPreview'
import { getFirstCharacter } from '../../helpers/jsxHelper'
import { getSelectUser } from '../../redux/selector/chat'
import { chatType } from '../../util/chat'


export const MessagesAudioAndText = ({ audioVideo, sender, text, me }) => {
    const selectUser = useSelector(getSelectUser)

    const createMarkup = data => {
        return { __html: data.replace(/\n/g, '<br />') }
    }
    
    let match = /\r|\n/.exec(text?.replace(/^\s+|\s+$/g, ''));
    return (
        <React.Fragment>
            {audioVideo ?
                <div className={classNames('chat-thread--audio', {
                    'chat-thread--audio-group': selectUser.chatType === chatType.groupChat
                })}>
                    {selectUser.chatType === chatType.groupChat && !me ? <div className='chat-thread--user'>{sender?.name}</div> :
                        <>{selectUser.chatType === chatType.normalchat && false && <div className='chat-thread--audio--user'>{getFirstCharacter(sender?.name)}</div>}</>
                    }
                    <audio controls>
                        <source src={audioVideo} />
                    </audio>
                </div>
                : <span className='chat-thread-text'>
                    {selectUser.chatType === chatType.groupChat && !me && <div className='chat-thread--user'>{sender?.name}</div>}
                    
                        {!match ? <Linkify componentDecorator={(url) => {
                            return (<div className='chat-link-preview--wrapper'>
                                <LinkPreview url={url} />
                            </div>)
                        }}>
                            {text}
                        </Linkify>: <div dangerouslySetInnerHTML={createMarkup(text)} />}
                </span>
            }
        </React.Fragment>
    )
}
