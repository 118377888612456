import { put, takeLatest, all } from 'redux-saga/effects'
import {
  ADD_BRANCH,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAILURE,
  CLEAR_BRANCH_FLAG,
  GET_ALL_BRANCHES,
  GET_ALL_BRANCHES_FAILURE,
  GET_ALL_BRANCHES_SUCCESS,
  DELETE_BRANCH,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAILURE,
  ERROR_MESSAGE_SHOW,
  UPDATE_BRANCH,
  GET_SINGLE_BRANCH,
  CHANGE_BRANCH_STATUS,
  GET_SINGLE_BRANCH_SUCCESS,
  GET_SINGLE_BRANCH_FAILURE,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAILURE,
  CHANGE_BRANCH_STATUS_SUCCESS,
  CHANGE_BRANCH_STATUS_FAILURE,
} from '../actionTypes'
import { graphqlClient } from '../../helpers/graphqlClient'
import {
  createBranch,
  getAllBranch,
  deleteBranch,
  editBranch,
  changeBranchStatus,
  updateBranch,
} from '../../graphql/branchSchema'

function addBranchApi(input) {
  return graphqlClient
    .mutate({
      mutation: createBranch,
      variables: { input },
    })
    .then(res => {
      return res
    })
    .catch(error => {
      throw error.networkError.result.errors
    })
}

function getAllBranchedApi(input) {
  return graphqlClient
    .query({
      query: getAllBranch,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function deleteSingleBranchApi(input) {
  return graphqlClient
    .mutate({
      mutation: deleteBranch,
      variables: { id: input },
    })
    .then(res => res)
    .catch(error => error)
}

function editBranchApi(input) {
  return graphqlClient
    .query({
      query: editBranch,
      variables: { id: input },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function updateBranchApi(input) {
  return graphqlClient
    .mutate({
      mutation: updateBranch,
      variables: { input },
    })
    .then(res => res)
    .catch(error => {
      throw error.networkError.result.errors
    })
}

function toggleBranchStatusApi(input) {
  return graphqlClient
    .mutate({
      mutation: changeBranchStatus,
      variables: input,
    })
    .then(res => res)
    .catch(error => error)
}

function* addBranch(action) {
  try {
    yield addBranchApi(action.payload)
    yield put({ type: ADD_BRANCH_SUCCESS })
    yield put({ type: CLEAR_BRANCH_FLAG })
  } catch (error) {
    yield put({ type: ERROR_MESSAGE_SHOW, payload: error })
    yield put({ type: ADD_BRANCH_FAILURE, error })
  }
}

function* getAllBranchesData(action) {
  try {
    const res = yield getAllBranchedApi(action.payload)
    yield put({ type: GET_ALL_BRANCHES_SUCCESS, payload: res.data.branchesByUser })
  } catch (error) {
    yield put({ type: GET_ALL_BRANCHES_FAILURE, error })
  }
}

function* deleteSingleBranch(action) {
  try {
    const res = yield deleteSingleBranchApi(action.payload)
    yield put({ type: DELETE_BRANCH_SUCCESS, payload: res.data.deleteBranch })
    yield put({ type: CLEAR_BRANCH_FLAG })
  } catch (error) {
    yield put({ type: DELETE_BRANCH_FAILURE, error })
  }
}

function* editBranches(action) {
  try {
    const res = yield editBranchApi(action.payload)
    yield put({ type: GET_SINGLE_BRANCH_SUCCESS, payload: res.data.editBranch })
  } catch (error) {
    yield put({ type: GET_SINGLE_BRANCH_FAILURE, error })
  }
}

function* updateBranches(action) {
  try {
    const res = yield updateBranchApi(action.payload)
    yield put({ type: UPDATE_BRANCH_SUCCESS, payload: res.data.updateBranch })
    yield put({ type: CLEAR_BRANCH_FLAG })
  } catch (error) {
    yield put({ type: ERROR_MESSAGE_SHOW, payload: error })
    yield put({ type: UPDATE_BRANCH_FAILURE, error })
  }
}

function* toggleBranchStatus(action) {
  try {
    const res = yield toggleBranchStatusApi(action.payload)
    yield put({ type: CHANGE_BRANCH_STATUS_SUCCESS, payload: res.data.changeBranchStatus })
  } catch (error) {
    yield put({ type: CHANGE_BRANCH_STATUS_FAILURE, error })
  }
}

function* addBranchSaga() {
  yield takeLatest(ADD_BRANCH, addBranch)
}

function* getAllBranches() {
  yield takeLatest(GET_ALL_BRANCHES, getAllBranchesData)
}

function* deleteSingleBranchSaga() {
  yield takeLatest(DELETE_BRANCH, deleteSingleBranch)
}

function* editBranchSaga() {
  yield takeLatest(GET_SINGLE_BRANCH, editBranches)
}

function* updateBranchSaga() {
  yield takeLatest(UPDATE_BRANCH, updateBranches)
}

function* toggleBranchStatusSaga() {
  yield takeLatest(CHANGE_BRANCH_STATUS, toggleBranchStatus)
}

export default function* branchSaga() {
  yield all([
    addBranchSaga(),
    getAllBranches(),
    deleteSingleBranchSaga(),
    editBranchSaga(),
    updateBranchSaga(),
    toggleBranchStatusSaga(),
  ])
}
