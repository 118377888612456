import { put, takeLatest, all } from 'redux-saga/effects'

import { graphqlClient } from '../../helpers/graphqlClient'

import {
  ERROR_MESSAGE_SHOW,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  CREATE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAILURE,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAILURE,
  CREATE_PAYMENT,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILURE,
} from '../actionTypes'

import { paymentMethodList, createPaymentMethods, removePaymentMethod, createPayment } from '../../graphql/paymentSchema'
import { query, mutation } from './apiHelper'

function paymentMethodsApi(input) {
  return query(paymentMethodList, input)
}

function addPaymentMethodApi(input) {
  return mutation(createPaymentMethods, { input })
}

function deletePaymentMethodApi(input) {
  const payload = { paymentId: input }
  return mutation(removePaymentMethod, payload)
}

function initiatePaymentApi(input) {
  return mutation(createPayment, { input })
}

function* paymentMethods(action) {
  try {
    const res = yield paymentMethodsApi(action.payload)

    if (res.errors && res.errors.length) {
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
      yield put({ type: GET_PAYMENT_METHODS_FAILURE, payload: res.errors })
    } else if (res.data) {
      yield put({ type: GET_PAYMENT_METHODS_SUCCESS, payload: res.data.paymentMethodList })
    }
  } catch (error) {
    yield put({ type: GET_PAYMENT_METHODS_FAILURE, error })
  }
}

function* addPaymentMethod(action) {
  try {
    const res = yield addPaymentMethodApi(action.payload)

    if (res.errors && res.errors.length) {
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
      yield put({ type: CREATE_PAYMENT_METHOD_FAILURE, payload: res.errors })
    } else if (res.data) {
      yield put({ type: CREATE_PAYMENT_METHOD_SUCCESS, payload: res.data.createPaymentMethods })
    }
  } catch (error) {
    yield put({ type: CREATE_PAYMENT_METHOD_FAILURE, error })
  }
}

function* deletePaymentMethod(action) {
  try {
    const res = yield deletePaymentMethodApi(action.payload)

    if (res.errors && res.errors.length) {
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
      yield put({ type: DELETE_PAYMENT_METHOD_FAILURE, payload: res.errors })
    } else if (res.data) {
      yield put({ type: DELETE_PAYMENT_METHOD_SUCCESS, payload: res.data.removePaymentMethod })
    }
  } catch (error) {
    yield put({ type: DELETE_PAYMENT_METHOD_FAILURE, error })
  }
}

function* initiatePayment(action) {
  try {
    const res = yield initiatePaymentApi(action.payload)

    if (res.errors && res.errors.length) {
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
      yield put({ type: CREATE_PAYMENT_FAILURE, payload: res.errors })
    } else if (res.data) {
      yield put({ type: CREATE_PAYMENT_SUCCESS, payload: res.data.createPayment })
    }
  } catch (error) {
    yield put({ type: CREATE_PAYMENT_FAILURE, error })
  }
}

function* paymentMethodsSaga() {
  yield takeLatest(GET_PAYMENT_METHODS, paymentMethods)
}

function* addPaymentMethodSaga() {
  yield takeLatest(CREATE_PAYMENT_METHOD, addPaymentMethod)
}

function* deletePaymentMethodSaga() {
  yield takeLatest(DELETE_PAYMENT_METHOD, deletePaymentMethod)
}

function* initiatePaymentSaga() {
  yield takeLatest(CREATE_PAYMENT, initiatePayment)
}

export default function* paymentSaga() {
  yield all([paymentMethodsSaga(), addPaymentMethodSaga(), deletePaymentMethodSaga(), initiatePaymentSaga()])
}
