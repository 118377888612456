import React from 'react'
import Grid from '@material-ui/core/Grid'

import { RentSharedTrip } from './rentSharedTrip'
import { ShowAllLink } from '../helper/showAllLink'

import '../../containers/rent/rent.scss'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { dimension } from '../../util/enums/enums'

export class RentSharedTrips extends React.Component {
  state = {
    width: null,
    height: null,
    gridClassName: `grid--new--${dimension['privateTrip'].divide}`,
    margin: 15,
  }
  widthZoom = () => {
    const { dimensionKey } = this.props
    const width = getRatioZoomResponsive(dimension, dimensionKey, '.zoom-container')
    const height = getHeightZoomResponsive(dimension, dimensionKey, '.zoom-container')

    this.setState({ width: width.width, gridClassName: width.className, margin: width.margin, height })
  }
  componentDidMount() {
    this.widthZoom()
    window.addEventListener('resize', this.widthZoom)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.widthZoom)
  }
  render() {
    const {
      rentSharedTrips,
      isPrivate,
      itemsLength,
      showType,
      isRentPerHour,
      showAllText,
      isTopLabel,
      isShared,
      dimensionKey,
      className,
      isUserProfile,
    } = this.props
    const { width, height, gridClassName, margin } = this.state
    const url = `/rent-type/${showType}`
    return (
      <>
        <div className="zoom-container rent-city-wise-grid-layout">
          {rentSharedTrips &&
            rentSharedTrips.length &&
            rentSharedTrips.map((value, index) => {
              return (
                itemsLength > index && (
                  <RentSharedTrip
                    value={value}
                    index={index}
                    key={value.id}
                    width={width}
                    margin={margin}
                    height={height}
                    isTopLabel={isTopLabel}
                    isShared={isShared}
                    dimension={dimension}
                    isPrivate={isPrivate}
                    isRentPerHour={isRentPerHour}
                    isUserProfile={isUserProfile}
                    gridClassName={gridClassName}
                    className={className}
                  />
                )
              )
            })}
        </div>

        {showType && (
          <ShowAllLink
            className="grid--rent--show--all"
            data={rentSharedTrips}
            itemsLength={itemsLength}
            showAllText={showAllText}
            url={url}
          />
        )}
      </>
    )
  }
}
RentSharedTrips.defaultProps = {
  dimension: {
    width: 380,
    height: 280,
  },
  isMostPopular: false,
  className: '',
  key: 'popularTrip',
}
