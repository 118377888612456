import QRCode from 'react-qr-code'
import { envConfig } from '../../config'
import styles from './GetAppContent.module.scss'

export default function GetAppContent({ inChat = false, isRent = false }) {
  return (
    <div style={inChat ? { paddingBottom: 50 } : {}}>
      <div className={styles.getappDdTxtWrp} style={inChat ? { background: 'none' } : isRent ? { background: '#eee' } : {}}>
        <h2 className={styles.getappDdTxt1}>Scan this QR code to get AS CHATS App</h2>
        <p className={styles.getappDdTxt2}>Stay connected with your contacts even while away from your desk.</p>
      </div>

      <div className={styles.getappActionsWrp}>
        <div className={styles.getappQRWrp}>
          <QRCode value={envConfig.BASE_URL.replace(/\/?$/, '/') + 'getapp'} size={100} />
        </div>

        <div className={styles.getappStoreBtnsWrp}>
          <div className={styles.getappStoreBtnWrp}>
            <a
              href="https://play.google.com/store/apps/details?id=com.adamsea.chat"
              target="_blank"
              className={styles.getappStoreBtn}
            >
              <img src={require('./play-store.png')} alt="AdamSea on Play Store" />
            </a>
          </div>

          <div className={styles.getappStoreBtnWrp}>
            <a href="https://apps.apple.com/ca/app/adamsea/id6446269602" target="_blank" className={styles.getappStoreBtn}>
              <img src={require('./app-store.png')} alt="AdamSea on App Store" />
            </a>
          </div>

          {!isRent && (
            <div className={styles.getappStoreReadMoreBtnWrp}>
              <a
                href="https://www.adamsea.com/faq?id=665595854fb4780014c60037"
                target="_blank"
                className={styles.getappStoreBtn}
                style={inChat ? { textDecoration: 'underline' } : {}}
              >
                Read More
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
