import { 
  GET_ALL_USER_FAQ, 
  GET_ALL_USER_FAQ_SUCCESS, 
  GET_ALL_USER_FAQ_FAILURE, 
  CREATE_CONTACT_US_SUGGESTION, 
  CREATE_CONTACT_US_SUGGESTION_SUCCESS, 
  CREATE_CONTACT_US_SUGGESTION_FAILURE, 
  CREATE_CONTACT_US_SERVICE,
  CREATE_CONTACT_US_SERVICE_SUCCESS,
  CREATE_CONTACT_US_SERVICE_FAILURE,
  CREATE_CONTACT_US_COMPLAINT,
  CREATE_CONTACT_US_COMPLAINT_SUCCESS,
  CREATE_CONTACT_US_COMPLAINT_FAILURE
} from '../actionTypes'

const InitialState = {
  userfaqs: [],
  success: false,
  error: false,
}

export const userFaqReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_ALL_USER_FAQ:
      return {
        ...state,
        success: false,
        error: false,
      }
    case GET_ALL_USER_FAQ_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        userfaqs: action.payload.data.getAllFaqList.items,
      }
    case GET_ALL_USER_FAQ_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }

    case CREATE_CONTACT_US_SUGGESTION:
      return {
        ...state
      }
    case CREATE_CONTACT_US_SUGGESTION_SUCCESS:
      return {
        ...state
      }
    case CREATE_CONTACT_US_SUGGESTION_FAILURE:
      return {
        ...state
      }

    case CREATE_CONTACT_US_SERVICE:
      return {
        ...state
      }
    case CREATE_CONTACT_US_SERVICE_SUCCESS:
      return {
        ...state
      }
    case CREATE_CONTACT_US_SERVICE_FAILURE:
      return {
        ...state
      }

    case CREATE_CONTACT_US_COMPLAINT:
      return {
        ...state
      }
    case CREATE_CONTACT_US_COMPLAINT_SUCCESS:
      return {
        ...state
      }
    case CREATE_CONTACT_US_COMPLAINT_FAILURE:
      return {
        ...state
      }

    default:
      return state
  }
}
