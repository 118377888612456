import gql from 'graphql-tag'

export const archiveSalesProcess = gql`
  query archive($SalesEngineId: String!, $status: Boolean) {
    archive(SalesEngineId: $SalesEngineId, status: $status) {
      id
      isArchive
    }
  }
`

export const deleteSalesEngine = gql`
  mutation deleteSalesEngine($id: String!) {
    deleteSalesEngine(id: $id) {
      id
    }
  }
`
