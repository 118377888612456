import React, { Component } from 'react'
import { connect } from 'react-redux'
import InnerRating from '../staticComponent/InnerRating'
import InnerReviews from '../gridComponents/InnerReviews'
import { displayDefaultReview } from '../../helpers/string'
import { userRoles } from '../../util/enums/enums'

export default class ReviewRating extends Component {
  render() {
    const { boat, forUser, currentUser, reviews, isRent, userDetails } = this.props

    return (
      <div className="rating-reviews-cont container ">
        <div className={"review--ratting--section" + (this.props.noTopMargin ? ' mt-0' : '')}>
          <div className="col-lg-12 col-md-12 col-sm-12 p-0">
            {/* <div className="d-flex align-items-center inner--boat--systems-title">
              <h4 className="section-heading section--heading-user--profile mt-0 surveyor--branch--title font-32">{isRent ? (forUser ? (userDetails?.companyName || userDetails?.firstName) + '\'s' : 'Trip\'s') : ''} Reviews and Ratings</h4>
            </div> */}
            {reviews && (
              <>
                <div className="review--ratting--width mx-auto">
                  <div>
                    <InnerRating
                      iconColor="iconColor-boatInner"
                      btnColor="boatInner-btnBg"
                      btnBlue="boatInner-btnOrange"
                      moduleId={boat.id}
                      userId={currentUser.id}
                      reviewUser={boat.seller || boat.user || boat}
                      progressColor="boat-progress"
                      data={displayDefaultReview(reviews.averageRating)}
                      reviews={reviews.reviews}
                      averageRating={reviews.averageRating}
                    />
                  </div>

                  <div className="mt-30">{this.props.innerRatingModal}</div>
                </div>

                {this.props.hideInnerReviews || (
                  <div className="inner--review--main--section">
                    <InnerReviews xs={12} sm={12} iconColor="iconColor-boatInner" data={reviews.reviews} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}
