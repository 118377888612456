import React, { useState } from 'react'
import { connect } from 'react-redux'
import { ErrorMessage, Formik } from 'formik'
import * as Yup from 'yup'
import { Grid } from '@material-ui/core'

import '../../containers/salesEngine/BoatInformation.scss'

import { CurrencyContextConsumer } from '../../CurrencyContext'
import { SaleEngineLabelAndValue } from './SaleEngineLabelAndValue'
import { getBoatTypeName, cityCountryNameFormatter, isSurveyorAvailable } from '../../helpers/jsxHelper'
import { CommonModal } from '../modal/commonModal'
import { ButtonComponent } from '../form/Button'
import { Field } from '../ws/Field'

import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { viewBoatHandler } from '../../helpers/boatHelper'
import { positiveNumberHelper, requireMessage } from '../../helpers/string'
import { getDocumentUrl, updateStockQuantity } from '../../redux/actions'
import { maxQuantityValidation, minQuantityValidation, salesEngineIcon, wishlistModuleEnum } from '../../util/enums/enums'
import { getId } from '../../util/utilFunctions'
import { getModulePdf } from '../../redux/actions/pdfAction'

const BoatInformation = props => {
  const { salesEngine, xs, isBuyer, updateStockQuantity } = props
  const { boat, boatPayment, surveyorPayment } = salesEngine

  const boatTypeAlias = getBoatTypeName(boat)
  const quantity = salesEngine.stockQuantity || 1

  const [{ city = null, country = null }] = boat?.address

  const [show, setShow] = useState(false)
  const qtyModalHandler = () => setShow(!show)

  const availableQuantity = boat?.stockQuantity || 0
  const minPurchaseQuantity = boat?.minPurchaseQuantity || 0

  return (
    <>
      <Grid item xs={xs} className="stepper-info-boat stepper-info-boat-margin">
        <div className="stepper-info-boat-section position-relative">
          <div
            style={{
              position: 'absolute',
              top: 10,
              left: 10,
              zIndex: 9,
              background: '#fff',
              padding: '6px 10px',
              borderRadius: 999,
              border: '1px solid #000',
              fontSize: 12,
            }}
          >
            {boat?.boatStatus?.alias}
          </div>

          <div className="stepper--boat-info-img" onClick={() => viewBoatHandler(boat, false, true)}>
            <img loading="lazy" src={boat.images.length > 0 && boat.images[0]?.url} className="h-100 width-100" alt="Boat" />
          </div>
          <div className="section--stepper-info-boat-div position-relative d-flex flex-column">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column flex-1">
                <span className="stepper-boat-info-name">
                  {boat.yearBuilt} {boatTypeAlias} {boat.lengthInFt ? `${boat.lengthInFt} ft` : ''}
                </span>

                <CurrencyContextConsumer>
                  {({ currentCurrency }) => (
                    <>
                      <span className="stepper-boat-info-price">
                        Boat Price <span style={{ fontSize: '1.5em' }}>{getConvertedPrice(boat.price, currentCurrency)}</span>
                      </span>
                      <div className="stepper-boat-info-price d-flex justify-content-between align-items-center">
                        <span>
                          Quantity <span style={{ fontSize: '1.5em' }}>{quantity}</span>
                        </span>

                        <>
                          {isBuyer &&
                            !boatPayment &&
                            !isSurveyorAvailable(salesEngine.boat.boatStatus, salesEngine.seller?.role) && (
                              <div>
                                <ButtonComponent
                                  type="button"
                                  name="next"
                                  className="btn btn-o-black rounded-full"
                                  onClick={qtyModalHandler}
                                >
                                  {'Change Quantity'}
                                </ButtonComponent>

                                <Formik
                                  initialValues={{ stockQuantity: salesEngine.stockQuantity }}
                                  validationSchema={Yup.object().shape({
                                    stockQuantity: Yup.number()
                                      .required(requireMessage('Quantity'))
                                      .positive(positiveNumberHelper('Quantity'))
                                      .min(minPurchaseQuantity, minQuantityValidation(availableQuantity, minPurchaseQuantity))
                                      .max(availableQuantity, maxQuantityValidation(availableQuantity)),
                                  })}
                                  onSubmit={values => {
                                    updateStockQuantity({
                                      id: salesEngine.id,
                                      stockQuantity: parseInt(values.stockQuantity),
                                    })
                                  }}
                                  render={({ setFieldValue, values, handleSubmit }) => (
                                    <CommonModal
                                      className="add--quantity--modal"
                                      open={show}
                                      close={qtyModalHandler}
                                      title="Change Quantity"
                                    >
                                      <div className="available--quantity--count">
                                        <div>
                                          Available Quantity <span className="font-22">{availableQuantity}</span>
                                        </div>
                                        <div className="mt-2">
                                          Minimum Purchase Quantity <span className="font-22">{minPurchaseQuantity}</span>
                                        </div>
                                      </div>

                                      <div className="available--quantity--add--input">
                                        <Field
                                          type="number"
                                          name="stockQuantity"
                                          id="stockQuantity"
                                          placeholder="Enter Quantity"
                                          value={values.stockQuantity}
                                          onChangeText={e => setFieldValue('stockQuantity', e.target.value)}
                                        />
                                        <ErrorMessage component="div" className="error-message" name="stockQuantity" />
                                      </div>
                                      <div className="quantity--btn--section">
                                        <ButtonComponent className="btn btn-black rounded-full" onClick={() => handleSubmit()}>
                                          {'Submit'}
                                        </ButtonComponent>
                                      </div>
                                    </CommonModal>
                                  )}
                                ></Formik>
                              </div>
                            )}
                        </>
                      </div>
                      <span className="stepper-boat-info-price">
                        Total Price{' '}
                        <span style={{ fontSize: '1.5em' }}>{getConvertedPrice(quantity * boat.price, currentCurrency)}</span>
                      </span>
                      {salesEngine.negotiatedBoatPrice && (
                        <span className="stepper-boat-info-price">
                          Counter Price{' '}
                          <span style={{ fontSize: '1.5em' }}>
                            {getConvertedPrice(salesEngine.negotiatedBoatPrice, currentCurrency)}
                          </span>
                        </span>
                      )}
                    </>
                  )}
                </CurrencyContextConsumer>
              </div>
            </div>
            <div className="stepper--boat-info-containt flex-1">
              {/* <div className="boat--info-lb-val">
                <SaleEngineLabelAndValue label="Year Built" value={boat.yearBuilt} />
              </div>

              <div className="boat--info-lb-val">
                <SaleEngineLabelAndValue label="Boat Model" value={boat.boatName} />
              </div>
              <div className="boat--info-lb-val">
                <SaleEngineLabelAndValue label="Boat Length" value={boat.lengthInFt ? `${boat.lengthInFt} ft` : '-'} />
              </div>

              <div className="boat--info-lb-val">
                <SaleEngineLabelAndValue label="Trailer" value={boat.trailer} />
              </div>

              <div className="boat--info-lb-val">
                <SaleEngineLabelAndValue label="Boat Status" value={boat.boatStatus && boat.boatStatus.alias} />
              </div>

              <div className="boat--info-lb-val">
                <SaleEngineLabelAndValue label="Boat Type" value={boatTypeAlias} />
              </div>

              {boat.engineManufacturer && (
                <div className="boat--info-lb-val">
                  <SaleEngineLabelAndValue label="Engine Manufacturer" value={boat.engineManufacturer} />
                </div>
              )} */}

              <div className="boat--info-lb-val label-with--city-country">
                <img loading="lazy" alt="location pin" src={require('../../assets/images/boatInner/Location.svg')} />
                <div>
                  <strong>Boat Location</strong> <span>{cityCountryNameFormatter(city, country)}</span>
                </div>
              </div>

              <div className="mt-2 d-flex justify-content-between align-items-center w-100">
                {(surveyorPayment || boatPayment) && (
                  <ButtonComponent
                    color="surveyor"
                    className="owner-cert-dl-btn"
                    disabled={props.isPdfLoading}
                    onClick={() =>
                      props.getModulePdf({
                        id: getId(boat),
                        type: wishlistModuleEnum.OWNERSHIPCERT,
                        filename: 'Ownership',
                        zipFilename: 'ownership-certs',
                      })
                    }
                  >
                    <img
                      loading="lazy"
                      alt="salesengine description"
                      className="download-surveyor-modal--left-icon"
                      src={salesEngineIcon.description}
                    />
                    <span className="download-surveyor-modal--text ownership-text-mobile-view-hide">Ownership</span>
                    <span className="download-surveyor-modal--text ownership-text-mobile-view-show"></span>
                    <div className="download-surveyor-modal--download">
                      <img
                        loading="lazy"
                        className="download-surveyor-modal--download-icon"
                        src={salesEngineIcon.download}
                        alt="Download"
                      />
                    </div>
                  </ButtonComponent>
                )}
                <div>
                  <strong>Ad ID</strong> {boat.adId}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  )
}
BoatInformation.defaultProps = {
  xs: 8,
  isBuyer: false,
}

const mapStateToProps = state => ({
  salesEngine: state.salesEngineReducer?.salesEngine,
  isPdfLoading: state.pdfReducer?.isLoading,
})

const mapDispatchToProps = dispatch => ({
  updateStockQuantity: data => dispatch(updateStockQuantity(data)),
  getDocumentUrl: data => dispatch(getDocumentUrl(data)),
  getModulePdf: data => dispatch(getModulePdf(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatInformation)
