import React from 'react'

import { confirmAlert } from 'react-confirm-alert'

import 'react-confirm-alert/src/react-confirm-alert.css'
import { CommonTooltip } from '../components/CommonTooltip'
import { ButtonComponent } from '../components/form/Button'
import { profileRedirection } from './boatHelper'

export const confirmSubmitHandler = (
  fn,
  value,
  title = 'Delete Ads Alert',
  message = 'Are you sure you want to delete these attachments? If you choose to delete them, they cannot be retrieved.'
) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="popup--message--card">
          <div className="custom-modal-paper common--modal--section">
            <h2 className={'no--bg-common transition-modal-title-common text-center'} id="transition-modal-title">
              {title}
              <CommonTooltip component={ctProps => (
                <img
                  data-tooltip-id={ctProps.id}
                  data-tooltip-content="Close"
                  src={require('../assets/images/boatInner/close.svg')}
                  onClick={onClose}
                  alt="Close"
                  className="common--closee--img common--close"
                />
              )} />
            </h2>
            <div className="common-modal-content">
              <span className="popup--main--message d-flex justify-content-center delete--popup--message">{message}</span>
              <div className="popup--message--card--btn d-flex justify-content-center">
                <ButtonComponent
                  className="h-auto as--success--outline btn--padding btn--font mr-3"
                  onClick={() => {
                    fn(value)
                    onClose()
                  }}
                >
                  Yes
                </ButtonComponent>
                <ButtonComponent className="h-auto as--cancel--outline btn--padding btn--font" onClick={onClose}>
                  No
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      )
    },
  })
}

export const popUpMessage = (
  message = '',
  type = 'Error',
  error = true,
  buttonText = '',
  history = null,
  closeAction = null
) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="popup--message--card alert--popup--message--card">
          <div className="custom-modal-paper common--modal--section">
            {type && (
              <h2 className={'no--bg-common transition-modal-title-common text-center'} id="transition-modal-title">
                {error ? type : ''}
                  <CommonTooltip component={ctProps => (
                    <img
                      data-tooltip-id={ctProps.id}
                      data-tooltip-content="Close"
                      src={require('../assets/images/boatInner/close.svg')}
                      onClick={() => {
                        closeAction && closeAction()
                        onClose()
                      }}
                      alt="close-icon"
                      className="common--closee--img common--close"
                    />
                  )} />
              </h2>
            )}
            <div className="common-modal-content">
              <div className="popup--main--message">{message}</div>
              <div className="popup--message--card--btn">
                <ButtonComponent
                  color="success"
                  className="h-auto btn--font m-auto"
                  onClick={() => {
                    buttonText && profileRedirection(history)
                    closeAction && closeAction()
                    onClose()
                  }}
                >
                  {buttonText ? buttonText : `OK`}
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      )
    },
  })
}
export const PasswordExpirePopUp = (
  fn,
  value,
  title = 'Delete Ads Alert',
  message = 'Are you sure you want to delete these attachments? If you choose to delete them, they cannot be retrieved.'
) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="popup--message--card">
          <div className="custom-modal-paper common--modal--section">
            <h2 className={'no--bg-common transition-modal-title-common text-center'} id="transition-modal-title">
              {title}
              {/* <CommonTooltip title="Close">
                <img loading="lazy" src={require("../assets/images/boatInner/close.svg")} onClick={onClose} alt="" className="common--closee--img common--close" />
              </CommonTooltip> */}
            </h2>
            <div className="common-modal-content">
              <span className="popup--main--message d-flex justify-content-center delete--popup--message">{message}</span>
              <div className="popup--message--card--btn d-flex justify-content-center">
                <ButtonComponent
                  className="h-auto as--success--outline btn--padding btn--font mr-3"
                  onClick={() => {
                    fn(value)
                    onClose()
                  }}
                >
                  OK
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      )
    },
  })
}
