import React from 'react'

import { getLocalStorageItem, getLocalInfo } from './helpers/storageHelper'

const CurrencyContext = React.createContext({
  currentCurrency: getLocalStorageItem('currentCurrency'),
  currencyRates: getLocalInfo('currencyRates'),
})

export const CurrencyContextProvider = CurrencyContext.Provider
export const CurrencyContextConsumer = CurrencyContext.Consumer

export default CurrencyContext
