import { GET_ALL_JOBS, APPLY_FOR_JOB, SEARCH_JOBS, GET_SIGLE_JOB, GET_JOBS_CONTENT, GET_COUNTRY_LIST } from '../actionTypes'

export const getAllJobs = data => ({
  type: GET_ALL_JOBS,
  payload: data,
})

export const applyForJob = data => ({
  type: APPLY_FOR_JOB,
  payload: data,
})

export const searchJobs = data => ({
  type: SEARCH_JOBS,
  payload: data,
})

export const getJobsContent = () => ({
  type: GET_JOBS_CONTENT,
})

export const getSingleJob = data => ({
  type: GET_SIGLE_JOB,
  payload: data,
})

export const getAllCountries = data => ({
  type: GET_COUNTRY_LIST,
  payload: data,
})
