import React, { Component } from 'react'
import { connect } from 'react-redux'
import BoatListingsWithMap from '../../components/gridComponents/BoatListingsWithMap'
import { getAddress } from '../../helpers/jsxHelper'

import { getAllFooterLink } from '../../redux/actions/boatAction'
import UserContext from '../../UserContext'

class FooterLinksCommonComponent extends Component {
  static contextType = UserContext

  render() {
    const { footerBoatsLink, totalFooterBoatsLink, getAllFooterLink, isPageLoader, match } = this.props

    const { country } = this.context

    const { params } = match && match

    const mostViewedBoats = params.type === 'most-viewed-boats'
    const brokerOrManufacture =
      params.type === 'broker-and-dealer'
        ? 'BROKERANDDELAER'
        : params.type === 'boat-manufacture' || params.type === 'most-viewed-boats'
        ? 'BOATMANUFACTURER'
        : ''

    const content = params.type === 'most-viewed-boats' && 'The most viewed and seen boats on AdamSea marketplace. These boats are recognized for their exceptional features.'

    let payload = { mostViewed: mostViewedBoats }

    if (brokerOrManufacture) {
      payload = {
        ...payload,
        type: brokerOrManufacture,
      }
    }

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        boatsTypeCount={totalFooterBoatsLink || 0}
        boatsType={`Explore  ${totalFooterBoatsLink || 0} Boats in ${country}`}
        boats={footerBoatsLink}
        content={content}
        action={getAllFooterLink}
        mapGridClassName="service--show--all--grid"
        value={payload}
      />
    )
  }
}

const mapStateToProps = state => ({
  footerBoatsLink: state.boatReducer && state.boatReducer.footerBoatsLink,
  totalFooterBoatsLink: state.boatReducer && state.boatReducer.totalFooterBoatsLink,
  isPageLoader: state.boatReducer && state.boatReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  getAllFooterLink: data => dispatch(getAllFooterLink(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FooterLinksCommonComponent)

FooterLinksCommonComponent.defaultProps = {
  mostViewedBoats: false,
}
