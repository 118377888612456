import React, { useEffect, useState } from 'react'
import { CommonTooltip } from '../CommonTooltip'
import { formatCash, getLikeFlag } from '../../helpers/string'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { InnerBannerLikesDiv } from '../styleComponent/styleComponent'
import LoginModal from '../modal/loginModal'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR__ADD_LIKE } from '../../redux/actionTypes'
import VerificationModal from '../../containers/verificationModal'

export const LikeComponent = ({ likes, moduleId, likeModule, addLikes, isArticle, noAbsolute }) => {
  const [loginModal, setLoginModal] = useState(false)

  const handlerLoginModal = () => setLoginModal(!loginModal)

  const like = () => {
    const userId = getLocalStorageItem('userId')

    !userId && handlerLoginModal()

    moduleId &&
      userId &&
      addLikes({
        moduleId,
        user: userId,
        likeModule,
      })
  }

  const addLikeError = useSelector(state => state.boatReducer.addLikeError)

  const dispatch = useDispatch()

  useEffect(() => {
    if (addLikeError) dispatch({ type: CLEAR__ADD_LIKE })
  }, [addLikeError])

  return (
    <>
      <LoginModal open={loginModal} close={handlerLoginModal} />

      <CommonTooltip component={ctProps => (
        isArticle ? (
          <div data-tooltip-id={ctProps.id} data-tooltip-content="Likes" className="artical-icon font-16" onClick={like}>
            <span className="count d-flex align-items-center">{getLikeFlag(likes) ? (
              <i className="far fa-heart mr-3"></i>
            ) : (
              <i className="fas fa-heart mr-3"></i>
            )} {formatCash(likes?.length)}</span>
          </div>
        ) : (
          <InnerBannerLikesDiv
            data-tooltip-id={ctProps.id} 
            data-tooltip-content="Likes" 
            className="banner-b-btn banner-b-btn-clickable"
            onClick={like}
          >
            {getLikeFlag(likes) ? (
              <i className="far fa-thumbs-up"></i>
            ) : (
              <i className="fas fa-thumbs-up" style={{ color: '#018abc' }}></i>
            )}
            {formatCash(likes.length)}
          </InnerBannerLikesDiv>
        )
      )} />
      <VerificationModal open={addLikeError} content="before you can add likes" />
    </>
  )
}

LikeComponent.defaultProps = {
  likes: [],
  isArticle: false,
}
