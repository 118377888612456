import React, { Component } from 'react'
import { CardContent, Card } from '@material-ui/core'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import SearchComplete from '../../components/map/SearchComplete'
import { BoatShowBannerFormStyle } from '../../components/styleComponent/styleComponent'

import '../../containers/boatServices/boatServices.scss'
import { NotifyModal } from '../../components/modal/notifyModal'
import { likeModuleEnum, notifyMeEnums } from '../../util/enums/enums'

class BoatShowSearchCard extends Component {
  state = {
    openDialog: false,
  }

  setPlaceHandler = ({ country, city }, setFieldValue) => {
    setFieldValue('country', country)
    setFieldValue('city', city)
  }

  modalHandler = e => {
    e && e.preventDefault()
    this.setState(preState => ({ openDialog: !preState.openDialog }))
  }

  notifyMeHandler = (formValues, values) => {
    const { notifyMe } = this.props

    notifyMe({
      ...formValues,
      ...values,
      type: notifyMeEnums.BOAT_SHOW,
    })

    this.modalHandler()
  }

  render() {
    const { openDialog } = this.state
    const { search, notifyInput } = this.props

    return (
      <Formik
        initialValues={{
          country: '',
          city: '',
          // query: ""
        }}
        validationSchema={Yup.object().shape({
          country: Yup.string().required('Location is required.'),
        })}
        onSubmit={values => {
          search(values)
        }}
        render={({ errors, values, setFieldValue, handleSubmit }) => (
          <>
            <Form className="bg-transparent-form" onSubmit={e => e.preventDefault()}>
              <BoatShowBannerFormStyle className="boat--show--card">
                <div>
                  <div className="boat--show-search--card card p-0 br-10">
                      <div className="boat-show-banner-card-info-div p-2">
                        {/* <div className="pb-1 boat-show-banner-card-info-grid mb-2">

                          <input
                            id="query"
                            name="query"
                            type="text"
                            className="form-control font-14 boat-show-banner-form-field"
                            placeholder="What would you like to search?"
                            onChange={e => setFieldValue("query", e.target.value)}
                            required
                          />
                          <ErrorMessage
                            component="div"
                            name="query"
                            className="error-message"
                          ></ErrorMessage>
                        </div> */}

                        <div className="pb-1 boat-show-banner-card-info-grid mt-2">
                        <div style={{position:'relative'}}>
                          <SearchComplete
                            style={{backgroundColor:'#fff7',backdropFilter:'blur(2px)',color:'white'}}
                            requiredFieldLabel={errors.country ? <ErrorMessage component="div" name="country" className="error-message"></ErrorMessage> : <span style={{ color: 'white' }}>Search country or city</span>}
                            className="form-control font-14 boat-show-banner-form-field"
                            name="country"
                            getPlaceName={place => {
                              this.setPlaceHandler(place, setFieldValue)
                              search({ country: place.country, city: place.city })
                            }}
                          />
                      <i style={{position:'absolute',right:'10px', top:'10px', color:'#061e9c', fontSize:'20px'}} className='adam-search-2'></i>
                          </div>
                        </div>
                        {/* <div className="boat-show--banner-btn">
                          <button
                            type="button"
                            className={`btn font-weight-600 border--radius--20 box--shadow--2 search-background boat-show-banner-form-search common-hovered-blue-button-effect text-white search-button-width`}
                            onClick={handleSubmit}
                          >
                            Search Now
                          </button>
                        </div> */}

                        <div className="d-flex justify-content-between flex-row-reverse mt-3 mb-0">
                          <br />
                          {/* <a
                            className="btn btn-link-info float-right notify-color pl-0 pr-0 flex-none"
                            href="/#"
                            onClick={this.modalHandler}
                          >
                            <u className="font-12 boat-show-banner-form-notify font-weight-500 boatshow-notify">NOTIFY ME</u>
                          </a> */}
                          {/* <NotifyModal
                            open={openDialog}
                            value={notifyInput}
                            closeModal={this.modalHandler}
                            onSubmit={formValues => this.notifyMeHandler(formValues, values)}
                          /> */}
                        </div>
                      </div>
                  </div>
                </div>
              </BoatShowBannerFormStyle>
            </Form>
          </>
        )}
      />
    )
  }
}

export default BoatShowSearchCard
