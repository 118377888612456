import { Row, Col, Image } from 'react-bootstrap'
import { Layout } from '../../components'
import { footerScreenImages, icons } from '../../util/enums/enums'

import '../brokerInfo/brokerInfo.scss'
import { Box, Stack } from '@mui/material'
import { Grid } from '@material-ui/core'
import _styles from '../../styles/info-screens.module.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

const BoatOwner = () => {
  return (
    <Layout>
      <div className="container100">
        <Grid className="py-5">
          <Row>
            <Col sm={12}>
              <h1 className="r-h1">Boat Owner and Dealer</h1>
            </Col>
          </Row>

         <Row>
            <Col sm={12} lg={6}>
              <span className="broker--info--title">As a boat owner,</span>
              <span className="broker--info--text">
                AdamSea provides you with a convenient and hassle-free way to list your boat on our platform and make it
                accessible to potential buyers from all over the world. Our platform’s sales engine offers a one-stop-shop
                solution for buyers to assign their dealer, surveyor, and choose the shipper for the boat, making the buying
                process easier for everyone involved. All payments are handled by Stripe, a secure and trusted payment platform.
              </span>
            </Col>
            <Col className="d-flex justify-content-end align-items-center" lg={6}>
              <div className="position-relative">
                <img
                  className="position-absolute dotted-yellow--bg"
                  loading="lazy"
                  src={footerScreenImages.yellow_dots_bg}
                  alt="Dots"
                />
                <img
                  className="rounded"
                  loading="lazy"
                  width={493}
                  height={300}
                  src={footerScreenImages.boat_owner_parked_boat}
                  alt="Boat"
                />
              </div>
            </Col>
          </Row>
        </Grid>
        {/* <Box className="my-4">
          <p className="as-com-title">Sales Engine</p>
          <img style={{ width: '100%' }} src={footerScreenImages.sales_engine_process} loading="lazy" />
        </Box> */}
        <Grid container spacing={4} className="py-5">
          <Grid item lg={4}>
            <Stack>
              <img width={70} src={icons.smooth_boat_selling} alt="Boat" />
              <span className="broker--info--text my-2 font-weight-bold">Smooth Boat Selling</span>
              <span className="broker--info--detail">
                To sell your boat on AdamSea, you will need to follow our innovative sales engine process, which ensures
                transparency and safety for all parties involved, including the seller, buyer, shipper, and surveyor. As a
                seller, you will be alerted to any requests coming from potential buyers and can communicate with them directly
                through our platform's messaging system.
              </span>
            </Stack>
          </Grid>
          <Grid item lg={4}>
            <Stack>
              <img width={70} src={icons.accelarate_boat_sale} alt="Boat" />
              <span className="broker--info--text my-2 font-weight-bold">Accelerate Your Boat Sale</span>
              <span className="broker--info--detail">
                In addition, boat owners have the option of moving their watercraft to an auction to accelerate the selling
                process. Our auction feature provides a faster way to sell your boat while also ensuring that you get the best
                possible price for it.
              </span>
            </Stack>
          </Grid>
          <Grid item lg={4}>
            <Stack>
              <img width={70} src={icons.sale_boat_hassle_free} alt="Boat" />
              <span className="broker--info--text my-2 font-weight-bold">Sell Your Boat Hassle-Free </span>
              <span className="broker--info--detail">
                At AdamSea, we are committed to making the boat selling process as easy and stress-free as possible for boat
                owners. So if you're looking to sell your boat, list it on our platform today and let us take care of the rest.
              </span>
            </Stack>
          </Grid>
        </Grid>
      </div>

      <div className={classNames(_styles.secPadding)}>
        <div className="container100">
          <h2 className={_styles.fz_h}>How boat owner sell in AdamSea</h2>
          <p className={_styles.fz_p + ' mt-20'}>
            <Link to="/register/boat-owner" className="btn btn-primary rounded-full">Sign up as boat owner</Link>
          </p>

          <ol className={'mt-30 ' + _styles.fz_ul_lg}>
            <li>
              <div>List unlimited boats for sale and manage all of them on one dashboard.</div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/boat-owner-how-1.png')} alt="List unlimited boats for sale and manage all of them on one dashboard." fluid />
              </div>
            </li>
            <li>
              <div>Receive one or more buyer requests for the same boat.</div>
              <div className={_styles.fz_p}>The buyer who completes the survey payment or the boat payment first will get the boat.</div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/boat-owner-how-2.png')} alt="Receive one or more buyer requests for the same boat." fluid />
              </div>
            </li>
            <li>
              <div>Track which stages the buyer reaches in buying the boat.</div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/boat-owner-how-3.png')} alt="Track which stages the buyer reaches in buying the boat." fluid />
              </div>
            </li>
            <li>
              <div>When the shipper picks up the boat from the seller, AdamSea releases the boat payment.</div>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  )
}

export default BoatOwner
