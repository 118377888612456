import {
  SEND_CONFIRM_MAIL_LINK,
  CLEAR_EMAIL_FLAGS,
  GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS,
  GET_MODULE_WISE_BANNERS,
  GET_DASHBOARD_COUNT,
  GET_EXPERIENCES,
  MODULE_PAGE_VISIT,
  ADD_LIKE,
  GET_CURRENCY_RATES,
  SET_CURRENT_CURRENCY,
  NOTIFY_ME,
  CREATE_WISHLIST,
  REMOVE_WISHLIST,
  GET_WISHLISTS,
  CLEAR_WISHLIST_FLAG,
  CONVERT_LIKES_FROM_SESSION_TO_USER,
  CONVERT_WISHLISTS_FROM_SESSION_TO_USER,
  MENU_CHANGE,
  GLOBAL_SEARCH,
  ADD_FLAG_REPORT,
  EMAIL_VERIFICATION_CHECK,
  CLOSE_EMAIL_VERIFICATION_MODAL,
  IS_VISIT_FIRST_TIME,
  SET_DASHBOARD_UNAUTHORIZED_ACCESS,
  CLEAR_DASHBOARD_UNAUTHORIZED_ACCESS_FLAG,
  GET_ALL_PAYMENT_METHODS,
  INDEX_SEARCH,
  CLEAR_INDEX_SEARCH,
  CLEAR_MODULE_PAGE_VISIT,
  GET_COUNTRY_DETAILS,
} from '../actionTypes'

export const sendConfirmationLink = data => ({
  type: SEND_CONFIRM_MAIL_LINK,
  payload: data,
})

export const clearEmailFlag = () => ({
  type: CLEAR_EMAIL_FLAGS,
})

export const getGlobalMinimumPriceBoats = data => ({
  type: GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS,
  payload: data,
})

export const getModuleWiseBanners = data => ({
  type: GET_MODULE_WISE_BANNERS,
  payload: data,
})

export const getDashBoardCount = data => ({
  type: GET_DASHBOARD_COUNT,
  payload: data,
})

export const getExperiences = data => ({
  type: GET_EXPERIENCES,
  payload: data,
})

export const pageVisit = data => ({
  type: MODULE_PAGE_VISIT,
  payload: data,
})

export const clearModulePageVisit = () => ({
  type: CLEAR_MODULE_PAGE_VISIT,
})

export const addLikes = data => ({
  type: ADD_LIKE,
  payload: data,
})

export const getCurrencyRates = () => ({
  type: GET_CURRENCY_RATES,
})

export const setCurrentCurrency = data => ({
  type: SET_CURRENT_CURRENCY,
  payload: data,
})

export const notifyMe = data => ({
  type: NOTIFY_ME,
  payload: data,
})

// Wishlist
export const createWishlist = data => ({
  type: CREATE_WISHLIST,
  payload: data,
})

export const removeWishlist = data => ({
  type: REMOVE_WISHLIST,
  payload: data,
})

export const getAllWishlists = data => ({
  type: GET_WISHLISTS,
  payload: data,
})

export const clearWishlistFlag = () => ({
  type: CLEAR_WISHLIST_FLAG,
})

export const convertLikesFromSessionToUser = () => ({
  type: CONVERT_LIKES_FROM_SESSION_TO_USER,
})

export const convertWishlistsFromSessionToUser = () => ({
  type: CONVERT_WISHLISTS_FROM_SESSION_TO_USER,
})

export const onMenuItemClick = (data, subMenu = '') => ({
  type: MENU_CHANGE,
  payload: data,
  subMenu,
})

export const globalSearch = data => ({
  type: GLOBAL_SEARCH,
  payload: data,
})

export const AddFlagReport = data => ({
  type: ADD_FLAG_REPORT,
  payload: data,
})

export const emailVerificationCheck = data => ({
  type: EMAIL_VERIFICATION_CHECK,
  payload: data,
})

export const closeEmailVerificationModal = data => ({
  type: CLOSE_EMAIL_VERIFICATION_MODAL,
})

export const isVisitFirstTimeForEmail = data => ({
  type: IS_VISIT_FIRST_TIME,
})

export const setDashboardUnauthorizedAccess = data => ({
  type: SET_DASHBOARD_UNAUTHORIZED_ACCESS,
  payload: data,
})

export const clearDashboardUnauthorizedAccessFlag = () => ({
  type: CLEAR_DASHBOARD_UNAUTHORIZED_ACCESS_FLAG,
})

export const getAllPaymentTypes = () => ({
  type: GET_ALL_PAYMENT_METHODS,
})

export const searchInDashboard = data => ({
  type: INDEX_SEARCH,
  payload: data,
})

export const clearSearchDashboard = () => ({
  type: CLEAR_INDEX_SEARCH,
})

export const getCountryDetails = data => ({
  type: GET_COUNTRY_DETAILS,
  payload: data
})
