import React from 'react'

import { CommonAdamSeaStyle } from '../styleComponent/styleComponent'
import { wishlistModuleEnum, shareModule, defaultProfileIcon, boatShowStatus } from '../../util/enums/enums'
import SharePopup from '../share/SharePopup'
import { WishlistComponent } from '../wishlist/WishlistComponent'
import { cityCountryNameFormatter, findBoatShowDuration, limitTextWithEllipsis, redirectToUserProfile } from '../../helpers/jsxHelper'
import { textTruncate, textLength } from '../../helpers/string'

import { boatShowCardDateTimeFormat } from '../../helpers/dateTimeHelper'
import ReadMore from '../helper/truncate'
import { ButtonComponent } from '../form/Button'
import '../../assets/css/component/boatShowGrid.scss'
import ImageGalleryComponent from '../imageHelper/imageGalleryComponent'
import UserContext from '../../UserContext'
import { Link } from '@material-ui/core'
import { borderRadius } from 'react-select/src/theme'
export class ShowBoatCard extends React.Component {
  state = {
    selectedIndex: false,
    expanded: true,
  }
  static contextType = UserContext

  handleClick = index => {
    const { selectedIndex } = this.state
    this.setState({
      selectedIndex: selectedIndex !== index ? index : null,
    })
  }

  toggleLines = () => {
    this.setState(prevState => ({ expanded: !prevState.expanded }))
  }
  render() {
    const { value, index, width, height, margin, gridClassName, inProfile, sliceTo } = this.props
    const { selectedIndex, expanded } = this.state
    const {
      address: [{ city, country }],
      user,
    } = value
    const { history } = this.context
    const onGoingBoatShow = findBoatShowDuration(value.startDate, value.endDate) === boatShowStatus.ON_GOING
    const comingSoonBoatShow = findBoatShowDuration(value.startDate, value.endDate) === boatShowStatus.COMING_SOON
    const overBoatShow = findBoatShowDuration(value.startDate, value.endDate) === boatShowStatus.OVER

    return (
      value && (
        <div style={{ width: width, marginRight: margin }} className={`${gridClassName} boat--show--grid`}>
          <div className="boat--show--grid--img">
            {/* <CommonAdamSeaStyle
            height={height}
            width={width}
            className="w-100 border-radius-boat-show"
            img={encodeURI(value.boatshowImages[0]?.url)}
          /> */}
            {value.boatshowImages && value.boatshowImages.length > 0 && (
              <ImageGalleryComponent isInnerPage={true} width={width} height={height} images={value.boatshowImages} sliceTo={sliceTo} />
            )}
            <div className="card-action">
              <div className="boat--show--logo">
                {user && 
                <img 
                className="rounded-circle"
                onClick={() => redirectToUserProfile(user, this.context.history)} 
                loading="lazy" src={user.image?.url || defaultProfileIcon} alt={user.firstName}
                 />}
              </div>
              <div className="share-icon">
                <WishlistComponent moduleId={value.id} moduleType={wishlistModuleEnum.BOAT_SHOW} />
                {/* <SharePopup
                  handleClick={() => this.handleClick(index)}
                  index={index}
                  selectedIndex={selectedIndex}
                  moduleId={value.id}
                  moduleType={shareModule.BOAT_SHOW}
                /> */}
              </div>
            </div>
          </div>

          <div className="boat--show--content">
            <div className="boat--show--title boat--show--title--desc">
              {limitTextWithEllipsis(value.title)}
            </div>
            <div className="boat--show--img--value show--city--country--grid">
              <img loading="lazy" src={require('../../assets/images/location/location-icon.png')} className="boat--show--img" alt="Location" />
              <span className="boat--show--value">{cityCountryNameFormatter(city, country)}</span>
            </div>

            <div className="boat--show--img--value">
              <img loading="lazy" src={require('../../containers/boatShow/image/calender.png')} className="boat--show--img" alt="Calender" />
              <span className="boat--show--value d-flex">
                {`${boatShowCardDateTimeFormat(value.startDate)} - ${boatShowCardDateTimeFormat(value.endDate)}`}
              </span>
            </div>

            <div className="boat--show--img--value">
              <img loading="lazy" src={require('../../containers/boatShow/image/clock.png')} className="boat--show--img" alt="Clock" />
              <span className="boat--show--value d-flex" style={{alignItems:"center"}}>
                {`${boatShowCardDateTimeFormat(value.startDate, true)} - ${boatShowCardDateTimeFormat(value.endDate, true)}`}
                  <ButtonComponent
                    color={comingSoonBoatShow ? 'green' : onGoingBoatShow ? 'orange' : overBoatShow ? 'subscribe' : ''}
                    variant="outline"
                    className={`boat--show--btn ml-3 h-50 w-30 ${overBoatShow ? 'no-hover' : ''}`}
                  >
                    {comingSoonBoatShow
                      ? boatShowStatus.COMING_SOON
                      : overBoatShow
                        ? boatShowStatus.OVER
                        : onGoingBoatShow
                          ? boatShowStatus.ON_GOING
                          : '-'}
                  </ButtonComponent>
              </span>
            </div>

            <div className="boat--show--desc">
              {/* <ReadMore>{value.showDescription}</ReadMore> */}
              {expanded ? textTruncate(value.showDescription, 115) : value.showDescription}{' '}
              {textLength(value.showDescription, 115) && (
                <span 
                  className="color-black cursor-pointer font-weight-bold" 
                  onClick={this.toggleLines} 
                  style={{textDecoration: 'underline', display: 'inline-block'}}>
                    {expanded ? 'Show More' : 'Show Less'}
                  <img style={{padding: '1px'}} width={expanded ? 12 : 15} src={
                    expanded ? require('../../assets/images/Arrow/show-more-right-arrow.svg') : require('../../assets/images/Arrow/up.svg')}/> 
                </span>
              )}
            </div>
            {/* {!inProfile && (
              <div className="posted--by--boat--show">
                <span className='header-user-role dark-silver header--user--role--div' >
                  <span className="posted--by">Posted by</span>&nbsp;
                  <span className="posted--value cursor-pointer" onClick={() => redirectToUserProfile(value.user, history)}>
                    {value.user && value.user.firstName}
                  </span>
                </span>
              </div>
            )} */}

            {/* {
              <ButtonComponent
                color={comingSoonBoatShow ? 'green' : onGoingBoatShow ? 'orange' : overBoatShow ? 'subscribe' : ''}
                variant="outline"
                className={`boat--show--btn ${overBoatShow ? 'no-hover' : ''}`}
              >
                {comingSoonBoatShow
                  ? boatShowStatus.COMING_SOON
                  : overBoatShow
                  ? boatShowStatus.OVER
                  : onGoingBoatShow
                  ? boatShowStatus.ON_GOING
                  : '-'}
              </ButtonComponent>
            } */}
          </div>
        </div>
      )
    )
  }
}
ShowBoatCard.defaultProps = {
  isSearch: false,
  gridClassName: '',
  className: '',
  sliceTo: 0,
}
