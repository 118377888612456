import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaStar } from 'react-icons/fa'
import { getId, getOnlineUsers } from '../../util/utilFunctions'
import { defaultProfileIcon } from '../../util/enums/enums'
import { formatCash } from '../../helpers/string'
import { SET_ONLINE_USERS } from '../../redux/actionTypes'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
import UserContext from '../../UserContext'
import ChatUserCreate from '../chats/ChatCreateUse'
import styles from './UserCardSale.module.scss'
import classNames from 'classnames'

export default function UserCardSale({ boat, seller, isRent, hideCompany }) {
  const onlineUsers = useSelector(state => state.chatReducer.onlineUsers)
  const dispatch = useDispatch()

  const { history } = useContext(UserContext)

  useEffect(() => {
    getId(seller) && getOnlineUsers([getId(seller)], payload => dispatch({ type: SET_ONLINE_USERS, payload }))
  }, [seller])

  if (!boat || !seller) {
    return null
  }

  return (
    <>
      <div className="inner--main--title mb-3">
        Boat listed by <span style={{ textTransform: 'capitalize' }}>{seller?.role?.role?.toLowerCase()}</span>
      </div>
      <div className={styles.userCardCont} style={{ gap: 25 }}>
        <div className={styles.userCardWrp}>
          <div className={classNames(styles.userCard, 'h-100')}>
            {seller?.isVerified && (
              <div className={classNames(styles.verifIcon, isRent && styles.verifIconInside)}>
                <img src={require('../../assets/images/verif.svg')} alt="verified user" />
              </div>
            )}

            <div className={styles.userInfoWrp}>
              <div className={styles.userImg} onClick={() => redirectToUserProfile(seller, history)}>
                <img src={seller.image?.url || defaultProfileIcon} alt="profile pic" />
                <div className="stepper-user-online">
                  <div className={classNames('online-div-user', { disconnect: !onlineUsers[getId(seller)] })} />
                </div>
              </div>

              <div className={styles.userName}>{seller.firstName}</div>
            </div>

            <div className={styles.userDetailsActionsWrp}>
              <div className="d-flex align-items-center">
                <ChatUserCreate id={seller.id} image={seller.image?.url} />
              </div>

              <div className={styles.vSep} />

              <div className={styles.userRWrp}>
                <div className={styles.userRValue}>
                  {boat.rating_count && formatCash(boat.rating_count)} {boat.rating_count > 1 ? 'Reviews' : 'Review'}
                </div>
              </div>

              <div className={styles.vSep} />

              <div className={styles.userRWrp}>
                <div className={styles.userRValue}>
                  <FaStar style={{ height: '1em' }} /> {(+boat.rating || 0).toFixed(1)}
                </div>
              </div>
            </div>

            {!isRent && seller.companyName && !hideCompany && (
              <div className={styles.userCompWrp}>
                {seller.companyLogo?.url && (
                  <div className={styles.userCompLogo}>
                    <img src={seller.companyLogo?.url} alt="company logo" />
                  </div>
                )}

                <div className={styles.userCompName}>{seller.companyName}</div>
              </div>
            )}
          </div>
        </div>

        {isRent && seller.companyName && (
          <div className={styles.userCardWrp}>
            <div className={styles.userCard}>
              <div className={styles.userCompWrp}>
                {seller.companyLogo?.url && (
                  <div className={styles.userCompLogo}>
                    <img src={seller.companyLogo?.url} alt="company logo" />
                  </div>
                )}

                <div className={styles.userCompName}>{seller.companyName}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
