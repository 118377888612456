import {
  GET_ALL_BOAT_LOOKUPS,
  ADD_BOAT,
  GET_ALL_BOATS_BY_USER,
  SEARCH_BOAT,
  GET_BOAT_TYPE,
  GET_BOAT_TYPE_SUCCESS,
  GET_BOAT_TYPE_FAILURE,
  RECENT_SEARCH,
  MULTI_SEARCH,
  GET_SINGLE_BOAT,
  TOGGLE_BOAT_STATUS,
  DELETE_BOAT,
  GET_BOAT_BY_TYPE,
  GET_LATEST_BOATS,
  GET_POPULAR_BOATS,
  GET_TOP_RATED_BOATS,
  UPDATE_BOAT,
  CLEAR_EXISTING_BOAT,
  CLEAR_SEARCHED_RESULTS,
  GET_CITY_WISE_BOATS,
  CLEAR_CITY_WISE_BOATS,
  GET_CATEGORY_WISE_BOATS,
  CLEAR_CATEGOEY_WISE_BOATS,
  CREATE_AUCTION_ROOM,
  CLEAR_AUCTION_FLAG,
  GET_USER_AUCTION_ROOMS,
  GET_ALL_AUCTION_BID,
  GET_ALL_AUCTION_ROOMS,
  CREATE_AUCTION_BID,
  CLEAR_BOAT_ADD_FLAG,
  CLEAR_BOAT_UPDATE_FLAG,
  BOAT_BY_CITIES,
  BOAT_BY_CITIES_CLEAR,
  GET_BOAT_SEARCH_MINIMUM_VALUE,
  GET_BOAT_COST_ESTIMATION,
  MAIL_BOAT_COST_ESTIMATION,
  CLEAR_ESTIMATED_MAIL,
  GET_AUCTION_ROOM_BY_BID_USER,
  GET_AUCTION_BY_BOAT_ID,
  GET_USERS_BY_COUNTRY,
  GET_BOAT_CONVERTED_COST_ESTIMATION,
  GET_AUCTION_SALES_ENGINE,
  CHECK_FOR_QUANTITY_QUERY,
  CLEAR_CHECK_FOR_QUANTITY_QUERY,
  ADD_DEPOSIT_FOR_BID,
  GET_ALL_FOOTER_LINK,
  GET_YACHTS,
} from '../actionTypes'

export function getAllBoatLookUps() {
  return {
    type: GET_ALL_BOAT_LOOKUPS,
  }
}

export function saveBoat(data) {
  return {
    type: ADD_BOAT,
    payload: data,
  }
}

export function getAllBoatByUser(data) {
  return {
    type: GET_ALL_BOATS_BY_USER,
    payload: data,
  }
}

export function getAllFooterLink(data) {
  return {
    type: GET_ALL_FOOTER_LINK,
    payload: data,
  }
}

export function searchBoat(data) {
  return {
    type: SEARCH_BOAT,
    payload: data,
  }
}

export function getBoatTypeStart(data) {
  return {
    type: GET_BOAT_TYPE,
    payload: data,
  }
}

export const getRecentSearch = () => ({
  type: RECENT_SEARCH,
})

export const multiSearch = data => ({
  type: MULTI_SEARCH,
  payload: data,
})

export const getBoatById = (data, isComingFromSalesEngine = false) => ({
  type: GET_SINGLE_BOAT,
  payload: data,
  isComingFromSalesEngine,
})

export const getUsersByCountry = data => ({
  type: GET_USERS_BY_COUNTRY,
  payload: data,
})

export const toggleBoatStatus = data => ({
  type: TOGGLE_BOAT_STATUS,
  payload: data,
})

export const deleteBoat = data => ({
  type: DELETE_BOAT,
  payload: data,
})

export const updateBoat = data => ({
  type: UPDATE_BOAT,
  payload: data,
})

export const clearExistingBoat = () => ({
  type: CLEAR_EXISTING_BOAT,
})

export const getBoatByType = data => ({
  type: GET_BOAT_BY_TYPE,
  payload: data,
})

export const getLatestBoats = data => ({
  type: GET_LATEST_BOATS,
  payload: data,
})

export const getPopularBoats = data => ({
  type: GET_POPULAR_BOATS,
  payload: data,
})

export const getTopRatedBoats = data => ({
  type: GET_TOP_RATED_BOATS,
  payload: data,
})

export const clearSearchedResults = () => ({
  type: CLEAR_SEARCHED_RESULTS,
})

export const getCityWiseBoats = data => ({
  type: GET_CITY_WISE_BOATS,
  payload: data,
})

export const clearCityWiseBoats = () => ({
  type: CLEAR_CITY_WISE_BOATS,
})

export const clearCategoryWiseBoats = () => ({
  type: CLEAR_CATEGOEY_WISE_BOATS,
})

export const categoryWiseBoats = data => ({
  type: GET_CATEGORY_WISE_BOATS,
  payload: data,
})

export const createAuctionRoom = data => ({
  type: CREATE_AUCTION_ROOM,
  payload: data,
})

export const clearAuctionFlag = () => ({
  type: CLEAR_AUCTION_FLAG,
})

export const getUserAuctionRooms = data => ({
  type: GET_USER_AUCTION_ROOMS,
  payload: data,
})

export const getAllAuctionBids = data => ({
  type: GET_ALL_AUCTION_BID,
  payload: data,
})

export const getAllAuctionRooms = data => ({
  type: GET_ALL_AUCTION_ROOMS,
  payload: data,
})

export const createAuctionBid = data => ({
  type: CREATE_AUCTION_BID,
  payload: data,
})

export const clearAddBoatFlag = () => ({
  type: CLEAR_BOAT_ADD_FLAG,
})
export const clearEstimatedMail = () => ({
  type: CLEAR_ESTIMATED_MAIL,
})
export const clearUpdateBoatFlag = () => ({
  type: CLEAR_BOAT_UPDATE_FLAG,
})

export function getBoatByCities(data) {
  return {
    type: BOAT_BY_CITIES,
    payload: data,
  }
}

export function clearBoatByCity() {
  return {
    type: BOAT_BY_CITIES_CLEAR,
  }
}

export const getBoatSearchMinimumValues = () => ({
  type: GET_BOAT_SEARCH_MINIMUM_VALUE,
})

export const getBoatCostEstimation = data => ({
  type: GET_BOAT_COST_ESTIMATION,
  payload: data,
})

export const mailBoatCostEstimation = data => ({
  type: MAIL_BOAT_COST_ESTIMATION,
  payload: data,
})

export const getAuctionByBidUser = data => ({
  type: GET_AUCTION_ROOM_BY_BID_USER,
  payload: data,
})

export const getAuctionByBoatId = data => ({
  type: GET_AUCTION_BY_BOAT_ID,
  payload: data,
})

export const getBoatConvertedCostEstimation = data => ({
  type: GET_BOAT_CONVERTED_COST_ESTIMATION,
  payload: data,
})

export const getAuctionSalesEngine = data => ({
  type: GET_AUCTION_SALES_ENGINE,
  payload: data,
})

export const checkForQuantity = data => ({
  type: CHECK_FOR_QUANTITY_QUERY,
  payload: data,
})

export const clearCheckForQuantity = data => ({
  type: CLEAR_CHECK_FOR_QUANTITY_QUERY,
  payload: data,
})

export const addDepositForBid = data => ({
  type: ADD_DEPOSIT_FOR_BID,
  payload: data,
})

export const getYachts = data => ({
  type: GET_YACHTS,
  payload: data,
})
