import React from 'react'

const SmallCard = props => {
  const { index, renderIcon, elseRenderImage, title, height, elseDynamicRenderImage } = props
  const renderIcons = () => {
    if (elseDynamicRenderImage === null || elseDynamicRenderImage === undefined) {
      if (renderIcon) {
        return <i className={renderIcon} />
      } else {
        return <img loading="lazy" src={elseRenderImage} alt={title} height={height} />
      }
    } else {
      return <img loading="lazy" src={elseDynamicRenderImage} alt={title} height={height} />
    }
  }
  return (
    <div className="inner-type-div m-auto" key={index}>
      <div className="inner-trip-type-icon-div mb-2 mt-1 inner--trip--type--icon">{renderIcons()}</div>
      <div className="inner-trip-type-text-div">
        <span className="font-14 trip-type-inner sub-title-sm inner--trip--type--text">{title}</span>
      </div>
    </div>
  )
}
export default SmallCard
