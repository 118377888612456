import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Box } from '@material-ui/core'

import { clearCityWiseBoats, getGlobalMinimumPriceBoats } from '../../../redux/actions'
import SellAroundCards from '../../../components/staticComponent/sellAroundCards'
import { Layout, PaginationBar } from '../../../components'
import { pagination } from '../../../util/enums/enums'

class BoatForSellAroundWorld extends Component {
  componentDidMount() {
    const { getGlobalMinimumPriceBoats } = this.props
    getGlobalMinimumPriceBoats({ page: pagination.PAGE_COUNT, limit: pagination.ARTICLE_PAGE_LIMIT })
  }

  render() {
    const { cityLists, getGlobalMinimumPriceBoats, total } = this.props

    return (
      <Layout>
        <div className="without-show-map">
          {cityLists && cityLists.length > 0 && (
            <Grid item xs={12} className="zoom--gird--15">
              <Box className="section-heading sell--boat--show--all--title" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                <h1 className="r-h1">Boat for sell around the world</h1>
              </Box>
              {cityLists.length > 0 && (
                <SellAroundCards
                  showAllSellCard
                  data={cityLists}
                  isShowAll={false}
                  clearCityWiseBoats={clearCityWiseBoats}
                  limit={cityLists.length}
                />
              )}
            </Grid>
          )}

          {total > pagination.PAGE_RECORD_LIMIT && (
            <div className="boat-pagination home--show--all--pagination">
              <PaginationBar
                action={getGlobalMinimumPriceBoats}
                value={{ page: pagination.PAGE_COUNT, limit: pagination.ARTICLE_PAGE_LIMIT }}
                totalRecords={cityLists.length}
              />
            </div>
          )}
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  cityLists: state.dashboardReducer.cityLists,
  total: state.dashboardReducer.total,
})

const mapDispatchToProps = dispatch => ({
  clearCityWiseBoats: () => dispatch(clearCityWiseBoats()),
  getGlobalMinimumPriceBoats: data => dispatch(getGlobalMinimumPriceBoats(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatForSellAroundWorld)
