const data = [
  {
    icon: require('../../assets/images/info-screens/rent-charter--per-hour.png'),
    title: 'Rent per Hour Trip',
    text: `It covers all small watercrafts such as jet skis, kayaks, canoes, and more. Boat owners can rent out their watercrafts on an hourly basis to guests who want to enjoy a short ride on the water.`,
  },

  {
    icon: require('../../assets/images/info-screens/rent-charter--private.png'),
    title: 'Private Trip',
    text: `Here, boat owners can rent out their watercrafts privately for an agreed-upon number of hours. The boat can be equipped by staff and F&B, and one guest can rent it out on behalf of a big group.`,
  },

  {
    icon: require('../../assets/images/info-screens/rent-charter--shared.png'),
    title: 'Shared Trip',
    text: `Boat owners can sell tickets to their watercraft to several different guests, allowing them to share the cost and enjoy a group trip on the water. The same guest can buy several tickets on the same trip.`,
  },
]

export default data
