import { useContext } from 'react';
import UserContext from '../UserContext';
import { getLocalStorageItem } from '../helpers/storageHelper';
import { envConfig } from '../config';

const useZohoSalesIQ = () => {
  const { currentUser } = useContext(UserContext)
  const isAuth = getLocalStorageItem('isAuthenticated')
  const script = document.createElement('script');
  script.setAttribute("type", "text/javascript");

  const code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${envConfig.ZOHO_API_KEY}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.ca/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`

  const initChatWidget = () => {
    const widgetDiv = document.getElementsByClassName('zsiq_flt_rel')
    if (widgetDiv.length) {
      widgetDiv[0].style.display = 'block'
    } else {
      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
      
      if (isAuth) {
        setTimeout(() => {
          try {
            if ($zoho.salesiq.visitor) {
              $zoho.salesiq.visitor.name(currentUser?.firstName + ' ' + currentUser?.lastName);
              $zoho.salesiq.visitor.email(currentUser?.email);
              $zoho.salesiq.visitor.info({"Role" : currentUser?.role?.role});
            }
          } catch (err) {
            console.log(err)
          }
        }, 1000)
      }
    }
  }

  const removeChatWidget = () => {
    const widgetDiv = document.getElementsByClassName('zsiq_flt_rel')

    if (widgetDiv.length) {
      widgetDiv[0].style.display = 'none'
    }
  };

  const endChatSession = async () => {
    await $zoho.salesiq.chat.complete()
    $zoho.salesiq.reset();
  }

  return {
    initChatWidget,
    removeChatWidget,
    endChatSession
  }
}

export default useZohoSalesIQ
