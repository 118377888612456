import {
  GET_RENT_BOAT_BY_TRIP,
  GET_RENT_BOAT_MOST_POPULAR,
  GET_RECOMMENDED_TRIPS,
  GET_RENT_CATEGORY_WISE_BOATS,
  GET_RENT_BOAT_TRIP_CITIES,
  GET_RENT_CITY_WISE_BOATS,
  SEARCH_BOAT_RENT,
} from '../actionTypes'

export const getRentBoatByTripAction = data => ({
  type: GET_RENT_BOAT_BY_TRIP,
  payload: data,
})

export const getRentBoatMostPopularTripAction = data => ({
  type: GET_RENT_BOAT_MOST_POPULAR,
  payload: data,
})

export const searchBoatRent = data => ({
  type: SEARCH_BOAT_RENT,
  payload: data,
})

export const getRecommendedTrips = data => ({
  type: GET_RECOMMENDED_TRIPS,
  payload: data,
})

export const rentCategoryWiseBoats = data => ({
  type: GET_RENT_CATEGORY_WISE_BOATS,
  payload: data,
})

export const getRentBoatTripCities = data => ({
  type: GET_RENT_BOAT_TRIP_CITIES,
  payload: data,
})
export const getRentCityWiseBoats = data => ({
  type: GET_RENT_CITY_WISE_BOATS,
  payload: data,
})
