import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import Rating from 'react-rating'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'

import '../../containers/rentInner/rentInner.scss'
import { SvgIcon, Card, Grid, TextField } from '@material-ui/core'
import { createReview } from '../../redux/actions/ReviewAction'
import { reviewType, percentageFormate, priceFormat } from '../../util/utilFunctions'
import ErrorFocus from '../ws/ErrorFocus'
import { ratingEnums } from '../../util/enums/enums'
import { getAvgRating } from '../../helpers/jsxHelper'
import { CommonModal } from '../modal/commonModal'
import { ratingStaticData } from '../home'
import RatingComponent from '../rating/Rating'
import { ButtonComponent } from '../form/Button'
import { stringValidation } from '../../helpers/yupHelper'

class InnerRating extends Component {
  state = {
    visible: false,
    ratingError: false,
    review: {
      rating: 0,
      reviews: '',
      reviewWithTypes: [],
      communication: 0,
      recommended: 0,
      organization: 0,
      goodValue: 0,
      goodQuality: 0,
      service: 0,
      servicesQuality: 0,
      surveyQuality: 0,
      valueForMoney: 0,
      safety: 0,
    },
  }

  toggleMenu = () => {
    this.setState(prevState => ({ visible: !prevState.visible }))
  }

  countRatingPercentage = (star, total) => {
    return star && (100 * star) / total
  }
  renderProgressBar = (star, total) => {
    const { progressColor } = this.props
    return (
      <Progress
        className={`progress-bar ${progressColor}`}
        symbolClassName="symbol-progress-none"
        percent={this.countRatingPercentage(star, total)}
      />
    )
  }
  renderRatingByReviews = () => {
    const { btnBlue, reviews } = this.props

    let data = {
      total: reviews.length,
      stars: {
        oneStar: 0,
        twoStar: 0,
        threeStar: 0,
        fourStar: 0,
        fiveStar: 0,
      },
    }

    reviews &&
      reviews.length > 0 &&
      reviews.map(review => {
        switch (Math.round(review.rating)) {
          case 1:
            return (data.stars.oneStar += 1)
          case 2:
            return (data.stars.twoStar += 1)

          case 3:
            return (data.stars.threeStar += 1)

          case 4:
            return (data.stars.fourStar += 1)

          case 5:
            return (data.stars.fiveStar += 1)

          default:
            return null
        }
      })

    return (
      <div className="ratingLength width-100">
        <div className="d-flex align-items-center inner--rating">
          <div className="d-flex align-items-center">
            {/* <i className="adam-star-filled rating-clr  inner--rting--star--line"></i> */}
            <span className="inner--rating--star--line--number">
              <p className="mb-0 color-black">5</p>
            </span>
          </div>
          <div className="progress flex-1">{this.renderProgressBar(data.stars.fiveStar, data.total)}</div>
          {/* <div className="ml-3 rating-count color-black">{data.stars.fiveStar}</div> */}
        </div>

        <div className="d-flex align-items-center inner--rating">
          <div className="d-flex align-items-center">
            {/* <i className="adam-star-filled rating-clr inner--rting--star--line"></i> */}
            <span className="inner--rating--star--line--number">
              <p className="mb-0 color-black">4</p>
            </span>
          </div>
          <div className="progress flex-1">{this.renderProgressBar(data.stars.fourStar, data.total)}</div>
          {/* <div className="ml-3 rating-count color-black">{data.stars.fourStar}</div> */}
        </div>

        <div className="d-flex align-items-center inner--rating">
          <div className="d-flex align-items-center">
            {/* <i className="adam-star-filled rating-clr inner--rting--star--line"></i> */}
            <span className="inner--rating--star--line--number">
              <p className="mb-0 color-black">3</p>
            </span>
          </div>
          <div className="progress flex-1">{this.renderProgressBar(data.stars.threeStar, data.total)}</div>
          {/* <div className="ml-3 rating-count color-black">{data.stars.threeStar}</div> */}
        </div>

        <div className="d-flex align-items-center inner--rating">
          <div className="d-flex align-items-center">
            {/* <i className="adam-star-filled rating-clr inner--rting--star--line"></i> */}
            <span className="inner--rating--star--line--number">
              <p className="mb-0 color-black">2</p>
            </span>
          </div>
          <div className="progress flex-1">{this.renderProgressBar(data.stars.twoStar, data.total)}</div>
          {/* <div className="ml-3 rating-count color-black">{data.stars.twoStar}</div> */}
        </div>

        <div className="d-flex align-items-center inner--rating">
          <div className="d-flex align-items-center">
            {/* <i className="adam-star-filled rating-clr inner--rting--star--line"></i> */}
            <span className="inner--rating--star--line--number">
              <p className="mb-0 color-black">1</p>
            </span>
          </div>
          <div className="progress flex-1">{this.renderProgressBar(data.stars.oneStar, data.total)}</div>
          {/* <div className="ml-3 rating-count color-black">{data.stars.oneStar}</div> */}
        </div>
      </div>
    )
  }

  renderTypeWiseRatings = (values, setFieldValue) => {
    const { reviewUser } = this.props
    const ratingTypes = reviewUser && reviewUser.role && reviewType(reviewUser.role)

    return (
      <div className="ratingQuality width-100 d-flex justify-content-around flex-column align-items-center">
        {ratingTypes &&
          ratingTypes.length > 0 &&
          ratingTypes.map(item => (
            <>
              <span className="review-quality">{item.name}</span>
              <Rating
                className="rating-clr"
                initialRating={0}
                onClick={value => {
                  setFieldValue(item.key, value)
                }}
                emptySymbol={<StarBorderIcon />}
                placeholderSymbol={<StarIcon />}
                fullSymbol={<StarIcon />}
                placeholderRating={values[item.key]}
              />
            </>
          ))}
      </div>
    )
  }

  renderTypeWiseRating = () => {
    const { reviews, reviewUser } = this.props
    let arr = []
    const ratingStaticData = [
      {
        name: 'Communication',
        key: 'communication',
      },
      {
        name: 'Recommended',
        key: 'recommended',
      },
      {
        name: 'Service',
        key: 'service',
      },
      {
        name: 'Organization',
        key: 'organization',
      },
      {
        name: 'Good Value',
        key: 'goodValue',
      },
      {
        name: 'Good Quality',
        key: 'goodQuality',
      },
      {
        name: 'Survey quality',
        key: 'surveyQuality',
      },
      {
        name: 'Services quality',
        key: 'servicesQuality',
      },
      {
        name: 'Value for money',
        key: 'valueForMoney',
      },
      {
        name: 'Safety',
        key: 'safety',
      },
      {
        name: 'Time respond',
        key: 'timeRespond',
      },
      {
        name: 'Good survey',
        key: 'goodSurvey',
      },
    ]
    if (reviews && reviews.length > 0) {
      reviews &&
        reviews.map(review => {
          if (arr.length > 0) {
            review.reviewWithTypes.map(item => {
              arr.map(arrItem => {
                if (arrItem.type === item.type) {
                  let avg = (arrItem.rating + item.rating) / 2
                  arrItem.rating = avg
                }
              })
            })
          } else {
            arr = [...review.reviewWithTypes]
          }
        })
      return arr.map(item => {
        return (
          <span className="review-star sm">
            <div className="inner--rating--star--name color-black">
              <span>{ratingStaticData.filter(el => el.key === item.type)[0]['name']}</span>
            </div>
            <RatingComponent
              className="rating-clr quality-rating-row inner--rating--desc--star text-right"
              rating={item.rating}
            />
          </span>
        )
      })
    } else {
      const ratingTypes = reviewUser && reviewUser.role && reviewType(reviewUser.role)
      return (
        ratingTypes &&
        ratingTypes.map(item => {
          return (
            <span className="review-star sm">
              <div className="inner--rating--star--name">
                <span>{item.name}</span>
              </div>
              <RatingComponent className="rating-clr quality-rating-row inner--rating--desc--star text-right" rating={0} />
            </span>
          )
        })
      )
    }
  }

  render() {
    const { btnColor, createReview, data, inProfile, averageRating, reviews } = this.props
    const { review, ratingError } = this.state
    const avgRating = (inProfile ? averageRating.averageRating : getAvgRating(reviews)) || 0

    return (
      <>
        <div className="inner--rating--main--div inner--rating--section">
          <div className="">
            <div className=" feedback-rating">
              {/* 1st section start */}
              <div className="inner--rating--first--div d-flex">
                <div className="rating-num d-flex flex-column align-items-center width-100 m-auto">
                  <span className="inner--rating--count color-black">{percentageFormate(avgRating)}</span>
                  <div className="inner--rating--star">
                    <RatingComponent className="rating-clr rating-start-icon" rating={percentageFormate(avgRating)} />
                  </div>
                  <span className="based-on-review based--on--review color-black">Based on {averageRating?.count || 0} Reviews</span>
                </div>
              </div>
              {/* 1st section end */}

              {/* 2nd section start */}
              <div className="inner--rating--second--div">{this.renderRatingByReviews()}</div>
              {/* 2nd section end */}

              {/* 3rd section start */}
              <div className="inner--rating--third--div">
                <div className="d-flex justify-content-around width-100">
                  <div className="ratingStar width-100 d-flex justify-content-around flex-column align-items-center inner--rating--desc">
                    {/* {ratingStaticData && ratingStaticData.length ? ratingStaticData.map((item) => {
                      return <span className="review-star sm">
                        <div className="inner--rating--star--name">
                          <span>{item.name}</span>
                        </div>
                        <RatingComponent
                          className="rating-clr quality-rating-row inner--rating--desc--star text-right"
                          rating={item.averageRating}
                        />
                      </span>
                    }) : <> </>} */}
                    {this.renderTypeWiseRating()}
                  </div>
                </div>
              </div>
              {/* 3rd section end */}
            </div>

            {/* 4th section start */}
            {/* temporary commented,, button for add review start */}
            {/* {!inProfile &&
               <div className="ratingButton width-100 d-flex justify-content-around flex-column align-items-center">
              {this.props.userId && <button
                type="button"
                className={`btn btn-primary w-auto ${btnColor}`}
                onClick={this.toggleMenu}
              >
                Write Reviews
              </button>}
              <span className="rentInner-reviewLink">
              </span>
              </div>
              } */}
            {/* button for add review end */}

            {/* 4th section end */}
          </div>
        </div>
        <CommonModal open={this.state.visible} close={this.toggleMenu} title="Add Review" className="feed--back--content">
          <Formik
            initialValues={{ ...review }}
            onSubmit={values => {
              this.setState({ ratingError: false })
              const { moduleId, user } = this.props
              values.moduleId = moduleId
              values.user = user && user.id

              for (const key in ratingEnums) {
                if (ratingEnums.hasOwnProperty(key)) {
                  values[key] &&
                    values.reviewWithTypes.push({
                      type: key,
                      rating: values[key],
                    })
                  delete values[key]
                }
              }

              if (values.rating === 0) {
                this.setState({ ratingError: true })
              } else {
                if (values.user && values.moduleId) {
                  createReview(values)
                  this.toggleMenu()
                }
              }
            }}
            validationSchema={Yup.object().shape({
              reviews: stringValidation.required('Please Add Your Reviews.'),
            })}
            render={({ setFieldValue, values, handleSubmit }) => (
              <Form>
                <ErrorFocus />
                <Card className="review-form">
                  <Grid container className="p-10">
                    <Grid item sm={6}>
                      <div className="ant-form-item-required" title="Rating">
                        {'Add Review'}
                      </div>
                      <Grid item sm={12} className="gutter-box mt-10">
                        <Rating
                          className="rating-clr"
                          initialRating={0}
                          onClick={value => {
                            this.setState({ ratingError: false })
                            setFieldValue('rating', value)
                          }}
                          emptySymbol={<StarBorderIcon />}
                          placeholderSymbol={<StarIcon />}
                          fullSymbol={<StarIcon />}
                          placeholderRating={values.rating}
                        />
                        {ratingError && <span className="error-message">Please Add Your Ratings</span>}
                      </Grid>
                    </Grid>
                    <Grid item sm={12} className="gutter-box mt-10">
                      <TextField
                        name="reviews"
                        className="form-control review--add--text--box h-auto add--review-text--area"
                        placeholder="Review"
                        value={values.reviews}
                        onChange={e => setFieldValue('reviews', e.target.value)}
                        multiline
                        rows={5}
                        variant="filled"
                      />

                      <ErrorMessage component="div" name="reviews" className="error-message" />
                    </Grid>
                    <div className="w-100 float-left text-center">
                      <Grid item sm={12} className="mt-10 review-button">
                        <ButtonComponent
                          className={`h-auto as--success--outline btn--padding btn--font ${btnColor}`}
                          type="submit"
                        >
                          Save
                        </ButtonComponent>

                        <ButtonComponent
                          className={`h-auto as--cancel--outline btn--padding btn--font ${btnColor}`}
                          onClick={this.toggleMenu}
                        >
                          Cancel
                        </ButtonComponent>
                      </Grid>
                    </div>
                  </Grid>
                </Card>
              </Form>
            )}
          />
        </CommonModal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  createSuccess: state.reviewReducer && state.reviewReducer.createSuccess,
  user: state.loginReducer && state.loginReducer.currentUser,
})

const mapDispatchToProps = dispatch => ({
  createReview: data => dispatch(createReview(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InnerRating)
