import React, { Component, Fragment } from 'react'

import UserContext from '../../UserContext'

import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { dimension } from '../../util/enums/enums'
import { MarketServiceDetail } from '../../components/marketService/marketServiceDetail'
import { redirectRouteHandler } from '../../helpers/routeHelper'

class MarinaMoreServices extends Component {
  static contextType = UserContext
  state = {
    height: 230,
    width: 230,
    margin: 20,
    gridClassName: dimension.marinaMoreServices.divide,
  }

  widthZoom = () => {
    const width = getRatioZoomResponsive(dimension, 'marinaMoreServices', '.more--marina--service')
    const height = getHeightZoomResponsive(dimension, 'marinaMoreServices', '.more--marina--service')

    this.setState({ width: width.width, gridClassName: width.className, height, margin: width.margin })
  }
  componentDidMount() {
    this.widthZoom()
    window.addEventListener('resize', this.widthZoom)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.widthZoom)
  }

  listingMarinaStorageByService = (value, name) => {
    value && redirectRouteHandler(`/marina-storage-service-view/${value}/${name}`)
  }

  render() {
    const { height, width, gridClassName, margin } = this.state
    const { limit, moreServices } = this.props
    return (
      <div className="more-marina-service marina-services-card-view more--marina--service">
        {moreServices &&
          moreServices.length &&
          moreServices.map((service, index) => {
            return (
              <Fragment key={service.id}>
                {index < limit && (
                  <MarketServiceDetail
                    value={service}
                    width={width}
                    height={height}
                    margin={margin}
                    getService={() => this.listingMarinaStorageByService(service.id, service.name)}
                    className="more-marina-content more--marina--zoom--content"
                    gridClassName={gridClassName}
                  />
                )}
              </Fragment>
            )
          })}
      </div>
    )
  }
}

export default MarinaMoreServices
