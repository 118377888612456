import React from 'react'

import { createMarkup } from '../../helpers/jsxHelper'
import '../../containers/globalSearch/globalSearch.scss'
import { redirectRouteHandler } from '../../helpers/routeHelper'
import { readableString } from '../../helpers/string'
import { viewBoatHandler } from '../../helpers/boatHelper'
import { defaultImage } from '../../util/enums/enums'

const SearchComponent = props => {
  const {
    src,
    name,
    subtitle,
    module,
    searchDataDescription,
    userName,
    userId,
    boatName,
    boatProp,
    history,
    onClick,
    topUserFirstName,
    topUserLastName,
    companyName,
    marketType,
    city,
    userRole,
    userOnClick,
  } = props

  const createMarkup = text => {
    return { __html: text }
  }

  return (
    <div className="global--search--section">
      {src && (
        <div className="global--search--section--img cursor-pointer" onClick={onClick}>
          <div className="global--search--section--img--div">
            <img loading="lazy" src={src || defaultImage} className="" alt='Search'/>
          </div>
        </div>
      )}
      <div className="global--search--section--data">
        {name && (
          <div className="global--search--section--data--name d-flex align-items-center">
            <p className="global--search--section--data--name--info">{name}</p>
            {marketType && (
              <div className="market--type">
                <span className='market--type--mobile'>{marketType}</span>
              </div>
            )}
          </div>
        )}
        {subtitle && (
          <div className="global--search--section--data--name d-flex align-items-center">
            <p className="global--search--section--data--name--info">{subtitle}</p>
          </div>
        )}
        {(topUserFirstName || topUserLastName) && (
          <div className="d-flex align-items-center">
            <div className="global--search--section--data--top--userName cursor-pointer d-flex align-items-center" onClick={userOnClick}>
              <p className="global--search--section--data--top--userName--info">
                {topUserFirstName} {topUserLastName}
              </p>
            </div>
            {city && (
              <div className="market--type">
                <span>{city}</span>
              </div>
            )}
          </div>
        )}
        {userRole && String(userRole).toLowerCase() !== 'member' && (
          <div className="global--search--section--data--role">
            <p className="global--search--section--data--role--info">{userRole}</p>
          </div>
        )}
        {companyName && (
          <div className="global--search--section--data--company--name">
            <p className="global--search--section--data--company--name--info">{companyName}</p>
          </div>
        )}
        {module && (
          <div className="global--search--section--data--module">
            <p className="global--search--section--data--module--info">in {module}</p>
          </div>
        )}
        <div className="global--search--section--data--desc">
          <p className="global--search--section--data--desc--info text-wrap">
            {/* {`${searchDataDescription?.substring(0, 150)}.........`} */}
            {/* <a href="#" onClick={viewArticles(value.id)}>Read more</a> */}
            <div className='search--desc--style'>
              <div className="text">
                {searchDataDescription}
              </div>
            </div>
          </p>
        </div>
        {userName && (
          <div className="global--search--section--data--userName">
            <p className="global--search--section--data--userName--info dark-silver">By {userName}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchComponent

SearchComponent.defaultProps = {
  searchDataDescription: '',
}
