import { ERROR_MESSAGE_SHOW, CLEAR_ERROR_MESSAGE } from '../actionTypes'

const InitialState = {
  errorMessage: false,
}

export const errorReducer = (state = InitialState, action) => {
  switch (action.type) {
    case ERROR_MESSAGE_SHOW:
      return {
        ...state,
        errorMessage: action.payload,
      }

    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null,
      }
    default:
      return state
  }
}
