import React from 'react'
import { SellAroundStyle } from '../styleComponent/styleComponent'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../CurrencyContext'

export const CityWiseComponent = React.memo(
  ({ value, minimumPrice, width, margin, height, city, country, img, getCityWiseBoats, gridClassName }) => {
    return (
      <SellAroundStyle
        width={width}
        className={` city-component-height ${gridClassName}`}
        onClick={() => getCityWiseBoats()}
        height={height}
        img={img}
        margin={margin}
      >
        <div className="card-content-position cursor-pointer">
          <h2 className="mb-0 country-name">
            {city}, {country}
          </h2>
          <CurrencyContextConsumer>
            {({ currentCurrency }) => (
              <p className="price1">Prices start from {minimumPrice && getConvertedPrice(minimumPrice, currentCurrency)}</p>
            )}
          </CurrencyContextConsumer>
        </div>
      </SellAroundStyle>
    )
  }
)
