import { put, takeLatest, all } from 'redux-saga/effects'

import { graphqlClient } from '../../helpers/graphqlClient'
import {
  DEALERS_REQUEST_MANUFACTURER_SUCCESS,
  DEALERS_REQUEST_MANUFACTURER_FAILURE,
  DEALERS_REQUEST_MANUFACTURER,
  SET_DEALER_IN_MANUFACTURER,
  ACCEPT_OR_REJECT_DEALER_REQUEST,
  ACCEPT_OR_REJECT_DEALER_REQUEST_SUCCESS,
  ACCEPT_OR_REJECT_DEALER_REQUEST_FAILURE,
  SET_MANUFACTURER,
  GET_DEALERS_REQUESTS,
  SEARCH_MANUFACTURER_SUCCESS,
  SEARCH_MANUFACTURER_FAILURE,
  SEARCH_MANUFACTURER,
  DEALERS_SENT_REQUESTS,
  DEALERS_SENT_REQUESTS_SUCCESS,
  GET_ALL_DEALERS,
  CLEAR_DEALERS_REQUEST_FLAG,
  DEALERS_REQUEST_MULTIPLE_MANUFACTURER_SUCCESS,
  DEALERS_REQUEST_MULTIPLE_MANUFACTURER_FAILURE,
  DEALERS_REQUEST_MULTIPLE_MANUFACTURER,
  NOTIFICATION_MESSAGE,
  GET_ALL_DEALERS_FAILURE,
  GET_ALL_DEALERS_SUCCESS,
  ERROR_NOTIFICATION_MESSAGE,
} from '../actionTypes'
import {
  getAllDealers,
  dealerRequestManufacturer,
  manufacturerAcceptRejectDealerRequest,
  searchManufacturer,
  dealerSentRequests,
  requestMultipleManufacturer,
  getManufacturerAllDealers,
} from '../../graphql/dealersSchema'
import { mutation, query } from './apiHelper'
import { dynamicError, notificationEnum } from '../../util/enums/notificationEnum'

function dealerRequestsManufacturerApi(input) {
  return mutation(dealerRequestManufacturer, input)
}

function requestsMultipleManufacturerApi(input) {
  return mutation(requestMultipleManufacturer, { manufacturerIds: input })
}

function acceptOrRejectDealerRequestApi(input) {
  return mutation(manufacturerAcceptRejectDealerRequest, input)
}

function searchManufacturerApi(input) {
  return query(searchManufacturer, input)
}

function dealerSentRequestsApi(input) {
  return mutation(dealerSentRequests, input)
}

function getAllDealersApi(input) {
  return query(getManufacturerAllDealers, input)
}

function* dealerRequestsManufacturerData(action) {
  try {
    const res = yield dealerRequestsManufacturerApi(action.payload)
    if (res?.data?.dealerRequestManufacturer) {
      yield put({ type: DEALERS_REQUEST_MANUFACTURER_SUCCESS, payload: res.data })
      yield put({ type: SET_DEALER_IN_MANUFACTURER, payload: res.data.dealerRequestManufacturer })
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.DEALER_REQUEST_MANUFACTURER })
    } else if (res?.errors) {
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
      yield put({ type: DEALERS_REQUEST_MANUFACTURER_FAILURE, errors: res.errors })
    }
  } catch (error) {
    yield put({ type: DEALERS_REQUEST_MANUFACTURER_FAILURE, error })
  }

  yield put({ type: CLEAR_DEALERS_REQUEST_FLAG })
}

function* requestsMultipleManufacturerData(action) {
  try {
    const res = yield requestsMultipleManufacturerApi(action.payload)

    if (res?.data?.requestMultipleManufacturer) {
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.DEALER_REQUEST_MANUFACTURERS })
      yield put({ type: DEALERS_REQUEST_MULTIPLE_MANUFACTURER_SUCCESS, payload: res.data.requestMultipleManufacturer })
    } else if (res?.errors) {
      yield put({ type: DEALERS_REQUEST_MULTIPLE_MANUFACTURER_FAILURE, errors: res.errors })
    }
  } catch (error) {
    yield put({ type: DEALERS_REQUEST_MULTIPLE_MANUFACTURER_FAILURE, error })
  }

  yield put({ type: CLEAR_DEALERS_REQUEST_FLAG })
}

function* acceptOrRejectDealerRequestData(action) {
  try {
    const res = yield acceptOrRejectDealerRequestApi(action.payload)
    if (res?.data?.manufacturerAcceptRejectDealerRequest) {
      const notificationPayload =
        action.payload.columnName === 'status'
          ? notificationEnum.MANUFACTURE_ENABLE_DISABLE_DEALER
          : action.payload.columnName === 'accepted'
          ? action.payload.value
            ? notificationEnum.MANUFACTURE_ACCEPT_DEALER
            : notificationEnum.MANUFACTURE_DECLINE_DEALER
          : null

      if (notificationPayload) {
        yield put({
          type: NOTIFICATION_MESSAGE,
          payload: notificationPayload,
        })
      }

      yield put({ type: ACCEPT_OR_REJECT_DEALER_REQUEST_SUCCESS, payload: res.data })
      yield put({ type: SET_MANUFACTURER, payload: res.data.manufacturerAcceptRejectDealerRequest })
    } else if (res?.errors) {
      yield put({ type: ACCEPT_OR_REJECT_DEALER_REQUEST_FAILURE, errors: res.errors })
    }
  } catch (error) {
    yield put({ type: ACCEPT_OR_REJECT_DEALER_REQUEST_FAILURE, error })
  }
}

function* searchManufacturerData(action) {
  try {
    const res = yield searchManufacturerApi(action.payload)
    yield put({ type: SEARCH_MANUFACTURER_SUCCESS, payload: res.data.searchBoatManufacturer })
  } catch (error) {
    yield put({ type: SEARCH_MANUFACTURER_FAILURE, error })
  }
}

function* dealerSentRequestsData(action) {
  try {
    const res = yield dealerSentRequestsApi(action.payload)
    yield put({ type: DEALERS_SENT_REQUESTS_SUCCESS, payload: res.data.dealerSentRequests })
  } catch (error) {
    yield put({ type: DEALERS_REQUEST_MANUFACTURER_FAILURE, error })
  }
}

function* getAllDealersData(action) {
  try {
    const res = yield getAllDealersApi(action.payload)
    yield put({ type: GET_ALL_DEALERS_SUCCESS, payload: res.data.getManufacturerAllDealers })
  } catch (error) {
    yield put({ type: GET_ALL_DEALERS_FAILURE, error })
  }
}

function* dealerRequestManufacturerSaga() {
  yield takeLatest(DEALERS_REQUEST_MANUFACTURER, dealerRequestsManufacturerData)
}

function* requestsMultipleManufacturerSaga() {
  yield takeLatest(DEALERS_REQUEST_MULTIPLE_MANUFACTURER, requestsMultipleManufacturerData)
}

function* acceptOrRejectDealerRequestSaga() {
  yield takeLatest(ACCEPT_OR_REJECT_DEALER_REQUEST, acceptOrRejectDealerRequestData)
}

function* searchManufacturerSaga() {
  yield takeLatest(SEARCH_MANUFACTURER, searchManufacturerData)
}

function* dealerSentRequestsSaga() {
  yield takeLatest(DEALERS_SENT_REQUESTS, dealerSentRequestsData)
}

function* getAllDealersSaga() {
  yield takeLatest(GET_ALL_DEALERS, getAllDealersData)
}

export default function* dealersSaga() {
  yield all([
    dealerRequestManufacturerSaga(),
    acceptOrRejectDealerRequestSaga(),
    searchManufacturerSaga(),
    dealerSentRequestsSaga(),
    requestsMultipleManufacturerSaga(),
    getAllDealersSaga(),
  ])
}
