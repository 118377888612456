import React, { Component } from 'react'
import { connect } from 'react-redux'

import { DashboardLayout, TableCard } from '../../components'

import '../../styles/common.scss'
import { clearSearchDashboard, getAuctionByBidUser, getAuctionSalesEngine } from '../../redux/actions'
import { pagination, boatAccessibleRoles } from '../../util/enums/enums'
import '../../styles/manageDashboardTableResponsive.scss'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { CommonTooltip } from '../../components/CommonTooltip'
import { formattedDate } from '../../helpers/dateTimeHelper'
import { ButtonComponent } from '../../components/form/Button'
import { viewBoatHandler } from '../../helpers/boatHelper'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { getPagesValue } from '../../helpers/jsxHelper'

class ManageAuctionBidList extends Component {
  componentDidMount() {
    const { getAuctionByBidUser } = this.props

    getAuctionByBidUser({
      page: getPagesValue(),
      limit: pagination.PAGE_RECORD_LIMIT,
    })
  }

  componentDidUpdate() {
    const { auctionSalesEngineId, getAuctionSalesEngineSuccess, history } = this.props
    if (getAuctionSalesEngineSuccess) {
      history && history.push(`sales-engine-process/${auctionSalesEngineId}`)
    }
  }

  render() {
    const { getAuctionSalesEngine, isSearched, clearSearchDashboard, totalSearchedData, indexSearchData } = this.props
    const columns = [
      {
        Header: 'Bid Date',
        minWidth: 70,
        accessor: 'createdAt',
        Cell: data => <span>{formattedDate(data.value)} </span>,
      },
      {
        Header: 'Boat Type',
        minWidth: 60,
        accessor: 'auctionRoom.boat.boatType.name',
      },
      {
        Header: 'Ad ID',
        minWidth: 60,
        accessor: 'auctionRoom.boat.adId',
      },
      {
        Header: 'Original Price',
        minWidth: 60,
        accessor: 'auctionRoom.boat.price',
        Cell: ({ value }) => (
          <CurrencyContextConsumer>
            {({ currentCurrency }) => <span>{value && getConvertedPrice(value, currentCurrency)} </span>}
          </CurrencyContextConsumer>
        ),
      },
      {
        Header: 'Bid Price',
        accessor: 'price',
        minWidth: 70,
        Cell: ({ value }) => (
          <CurrencyContextConsumer>
            {({ currentCurrency }) => <span>{value && getConvertedPrice(value, currentCurrency)} </span>}
          </CurrencyContextConsumer>
        ),
      },
      {
        Header: 'Status',
        accessor: 'auctionRoom',
        minWidth: 70,
        Cell: ({ value }) =>
          value?.auctionWinner ? (
            value.auctionWinner?.id === getLocalStorageItem('userId') ? (
              <ButtonComponent
               className="btn border--radius--20"
               color="edit"
               onClick={() => {
                getAuctionSalesEngine({ id: value?.boat?.id })
              }}>WON IN MY STORE</ButtonComponent>
            ) : (
              <ButtonComponent className="btn border--radius--20 cursor-default" color="danger">LOST BID</ButtonComponent>
            )
          ) : (
            <ButtonComponent className='border--radius--20 cursor-default'>AUCTION ACTIVE</ButtonComponent>
          ),
      },
      {
        minWidth: 60,
        headerClassName: 'sales--engine--view reset-rt-th',
        Cell: ({ original }) => {
          const { currentUser } = this.props
          return (
            //             !boatAccessibleRoles.includes(currentUser?.role?.aliasName) ?
            //                 <div className="d-flex flex-row justify-content-around">
            //                     <CommonTooltip title="Only owner can see all bids">
            //                         <button
            //                             type="button"
            //                             onClick={() =>
            //                                 history && history.push("/manage-auction-bids", {
            //                                     auctionId: value.id
            //                                 })
            //                             }
            //                             className="btn btn-outline-primary"
            //                             disabled={!boatAccessibleRoles.includes(currentUser?.role?.aliasName)}
            //                         >
            //                             View
            //                             </button>
            //                     </CommonTooltip>
            //                 </div>
            //                 :
            <div className="d-flex flex-row justify-content-around">
              <ButtonComponent className="btn" color="view" onClick={() => viewBoatHandler(original?.auctionRoom?.boat)}>
                {'View Boat'}
              </ButtonComponent>
            </div>
          )
        },
      },
    ]

    const { isLoading, userAuctions, totalUserAuctions, getAuctionByBidUser } = this.props

    return (
      <DashboardLayout className="sales-engin-layout-design">
        {columns && (
                 <div className="manage-dashboard-table dashboard--table--main--section dashboard--index--table adam-dash-store adam-dash-auc-room">
            <TableCard
              isSearch
              title="Auction Bids"
              columns={columns}
              data={isSearched ? indexSearchData : userAuctions}
              loading={isLoading}
              className="manage--boat--table manage--table--section--alignment"
              noButton
              total={isSearched ? totalSearchedData : totalUserAuctions}
              action={getAuctionByBidUser}
              clearSearchDashboard={clearSearchDashboard}
            />
          </div>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  userAuctions: state.boatReducer?.userAuctions,
  isLoading: state.boatReducer?.isLoading,
  currentUser: state.loginReducer?.currentUser,
  auctionSalesEngineId: state.boatReducer?.auctionSalesEngineId,
  getAuctionSalesEngineSuccess: state.boatReducer?.getAuctionSalesEngineSuccess,
  totalUserAuctions: state.boatReducer && state.boatReducer.totalUserAuctions,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
  isSearched: state?.dashboardReducer?.isSearched,
})

const mapDispatchToProps = dispatch => ({
  getAuctionByBidUser: data => dispatch(getAuctionByBidUser(data)),
  getAuctionSalesEngine: data => dispatch(getAuctionSalesEngine(data)),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAuctionBidList)
