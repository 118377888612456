import React from 'react'
import {
  SectionWithShowMapStyleBoatCarousal,
  ArrowCarousel,
  RightArrowStyle,
  LeftArrowStyle,
} from '../styleComponent/styleComponent'
import ImageGallery from 'react-image-gallery'
import { sliderImageHelper } from '../../helpers/string'
import { arrows } from '../../util/enums/enums'

export default class ImageGalleryComponent extends React.Component {
  createChildren = item => {
    const { height, width, onClick, isInnerPage } = this.props
    return (
      <SectionWithShowMapStyleBoatCarousal
        style={isInnerPage ? {cursor:'default'} : {cursor:'pointer'}}
        width={width}
        onClick={onClick}
        img={encodeURI(item.original)}
        height={height}
        className={isInnerPage ? 'inner--carousal--img' : 'cursor-pointer'}
      />
    )
  }
  renderRightNav = (onClick, disabled) => {
    const { height } = this.props

    return (
      <>
        <ArrowCarousel top={height / 2 - 12} onClick={onClick} className={`left--right--single--arrow right-arrow `}>
          <RightArrowStyle img={arrows.right} className="carousel-right-arrow" />
        </ArrowCarousel>
      </>
    )
  }
  renderLeftNav = (onClick, disabled) => {
    const { height } = this.props

    return (
      <>
        <ArrowCarousel top={height / 2 - 12} onClick={onClick} className={`left--right--single--arrow left-arrow`}>
          <LeftArrowStyle img={arrows.left} className="carousel-left-arrow" />
        </ArrowCarousel>
      </>
    )
  }
  render() {
    const { images, sliceTo } = this.props

    return (
      images &&
      images.length > 0 && (
        <ImageGallery
          items={sliderImageHelper(images, sliceTo)}
          showFullscreenButton={false}
          showPlayButton={false}
          originalAlt="boat image"
          showBullets={images.length>1}
          showThumbnails={false}
          renderItem={this.createChildren}
          renderLeftNav={this.renderLeftNav}
          renderRightNav={this.renderRightNav}
          disableKeyDown
        />
      )
    )
  }
}
ImageGalleryComponent.defaultProps = {
  isInnerPage: false,
  sliceTo: 0,
}
