import { all, put, takeLatest } from 'redux-saga/effects'
import { graphqlClient } from '../helpers/graphqlClient'
import { get } from 'lodash'

export function createSlice(name, queries) {
  let actionTypes = {}
  let actions = {}
  let initialState = {}
  let sagas = []

  for (const query in queries) {
    actionTypes[query] = {
      main: `${name}_${query}`,
      success: `${name}_${query}_success`,
      failure: `${name}_${query}_failure`,
      clear: `${name}_${query}_clear`,
    }

    actions[query] = {
      main: payload => ({ type: actionTypes[query].main, payload }),
      clear: payload => ({ type: actionTypes[query].clear, payload }),
    }

    initialState[query] = {
      loading: null,
      success: false,
      failure: false,
      message: null,
      data: null,
    }

    sagas.push(function* () {
      yield takeLatest(actionTypes[query].main, data)

      function* data({ payload }) {
        try {
          const result = yield api(payload?.input)

          if (get(result, 'errors[0]', null)) {
            yield put({
              type: actionTypes[query].failure,
              payload: {
                data: { cache: payload?.cache },
                message: result.errors[0].message,
              },
            })
          } else if (get(result, 'data.' + queries[query].schema.name, null)) {
            yield put({
              type: actionTypes[query].success,
              payload: {
                data: {
                  cache: payload?.cache,
                  result: result.data[queries[query].schema.name],
                },
              },
            })
          } else {
            yield put({
              type: actionTypes[query].failure,
              payload: {
                data: { cache: payload?.cache },
                message: null,
              },
            })
          }
        } catch (err) {
          console.log(err)

          yield put({
            type: actionTypes[query].failure,
            payload: {
              data: { cache: payload?.cache },
              message: get(err, 'networkError.result.errors[0].message', null),
            },
          })
        }
      }

      function api(input) {
        return graphqlClient[queries[query].type === 'query' ? 'query' : 'mutate']({
          [queries[query].type]: queries[query].schema.query,
          variables: { input },
          fetchPolicy: 'no-cache',
        })
      }
    })
  }

  function* saga() {
    yield all(sagas.map(saga => saga()))
  }

  function reducer(state = initialState, action) {
    for (const actionType in actionTypes) {
      switch (action.type) {
        case actionTypes[actionType].main: {
          return {
            ...state,
            [actionType]: { ...state[actionType], loading: true },
          }
        }

        case actionTypes[actionType].success: {
          return {
            ...state,
            [actionType]: { ...state[actionType], success: true, data: action.payload.data },
          }
        }

        case actionTypes[actionType].failure: {
          return {
            ...state,
            [actionType]: { ...state[actionType], failure: true, message: action.payload.message, data: action.payload.data },
          }
        }

        case actionTypes[actionType].clear: {
          return {
            ...state,
            [actionType]: { ...state[actionType], loading: false, success: false, failure: false },
          }
        }
      }
    }

    return state
  }

  return { actions, saga, reducer }
}
