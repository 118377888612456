import gql from 'graphql-tag'
import { BrokerType, dealerUserSchema, AdminUser, ImageType, LookUp, AddressType } from './schemaTypes/schemaTypes'

export const getAllDealers = gql`
query getAllBrokerByUser($limit: Int, $page: Int) {
    getAllBrokerByUser(limit: $limit, page: $page) {
        items ${BrokerType}
    }
}
`

export const dealerRequestManufacturer = gql`
  mutation dealerRequestManufacturer($manufacturerId: String!) {
    dealerRequestManufacturer(manufacturerId: $manufacturerId){
      dealer ${dealerUserSchema}
      accepted
    }
  }
  `

export const requestMultipleManufacturer = gql`
  mutation requestMultipleManufacturer($manufacturerIds: [String!]!) {
    requestMultipleManufacturer(manufacturerIds: $manufacturerIds) {
      message
    }
  }
`

export const manufacturerAcceptRejectDealerRequest = gql`
  mutation manufacturerAcceptRejectDealerRequest($dealerId: String!, $columnName: String!, $value: Boolean!) {
    manufacturerAcceptRejectDealerRequest(dealerId: $dealerId, columnName: $columnName, value: $value) 
    ${AdminUser}
}
  `

export const searchManufacturer = gql`
query searchBoatManufacturer($searchTerm: String!) {
  searchBoatManufacturer(searchTerm: $searchTerm) {
      items {
        id
        firstName
        image ${ImageType}
        lastName
        email
        mobileNumber
        companyName
        profession ${LookUp}
        dealerRequests {
          dealer {    
          id
          firstName
          lastName
          isVerified
        }
        accepted
        status
        }
    }
      total
  }
}`
export const dealerSentRequests = gql`
query dealerSentRequests($page: Int, $limit: Int, $searchTerm: String) {
  dealerSentRequests(page: $page, limit: $limit, searchTerm: $searchTerm) {
      items {
        id
        firstName
        image ${ImageType}
        companyLogo ${ImageType}
        lastName
        address ${AddressType}
        email
        mobileNumber
        isVerified
        companyName
      }
      total
  }
}`

export const getManufacturerAllDealers = gql`
query getManufacturerAllDealers($page: Int, $limit: Int, $searchTerm: String) {
  getManufacturerAllDealers(page: $page, limit: $limit, searchTerm: $searchTerm) {
    items {
      dealer ${dealerUserSchema}
      accepted
      status
    }
    total
  }
}
`
