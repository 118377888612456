import React from 'react'
import Truncate from 'react-truncate'
import RatingComponent from '../rating/Rating'
import moment from 'moment'
import { cityCountryNameFormatter } from '../../helpers/jsxHelper'
import { reviewDateFormate } from '../../helpers/dateTimeHelper'
import { defaultImage } from '../../util/enums/enums'

export class ReviewSection extends React.Component {
  render() {
    const { review, isBorder } = this.props
    const {
      address: [{ city, country }],
      firstName,
      lastName,
      image,
    } = review.user

    return (
      <>
        <div className="d-flex flex-column mr-5">
          <p className="review-date">{reviewDateFormate(review && review.createdAt)}</p>
          <h5 className="font-weight-400 rentInner-userTextH4 dark-silver font-16 text-capitalize main--review">
            <Truncate lines={3} ellipsis={<span>..</span>}>
              {review.reviews}
            </Truncate>
          </h5>

          <div className="d-flex justify-content-start align-items-center">
            <div>
              <img
                src={review.user && review.user.image ? image?.url : defaultImage}
                className="view-service-img-salesman share--adamse--user--img"
                alt="Profile"
              />
            </div>
            <div className="d-flex justify-content-start align-items-start flex-column ml-2">
              <div className="d-flex justify-content-start align-items-center">
                {review.user && (
                  <h5 className="font-weight-400 rentInner-userTextH4 dark-silver font-14 mb-1 mr-1 text-dark">
                    {firstName} {lastName}
                  </h5>
                )}
                {review.user && (
                  <div className="d-flex justify-content-start align-items-center">
                    <h5 className="font-weight-400 rentInner-userTextH4 font-14 mb-1 mr-1 text-light">
                      {cityCountryNameFormatter(city, country)}
                    </h5>
                  </div>
                )}
              </div>
              <div className="justify-content-start">
                {review.user && (
                  <h5 className="font-weight-400 rentInner-userTextH4 dark-silver font-14 mb-1">
                    Joined in {moment(review.user.createdAt).format('YYYY')}
                  </h5>
                )}
              </div>
            </div>
          </div>

          {/* <RatingComponent className="rating-clr" rating={review.rating} /> */}
        </div>
        {!isBorder && <hr className="user-profile-bottom-border" />}
      </>
    )
  }
}
