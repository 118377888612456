import React from 'react'
import { Grid } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import { Icons } from '../icons'

import '../../containers/rentInner/rentInner.scss'
import { defaultProfileIcon, flagReportModuleTypeEnum } from '../../util/enums/enums'
import ReadMore from '../helper/truncate'
import RatingComponent from '../rating/Rating'
import { getAddress, redirectToUserProfile } from '../../helpers/jsxHelper'
import ReportModal from '../modal/reportModal'
import { reviewDateFormate } from '../../helpers/dateTimeHelper'
import { getLocalStorageItem } from '../../helpers/storageHelper'

class InnerReview extends React.Component {
  state = {
    openReportModal: false,
  }

  setReportModal = data => {
    this.setState({
      openReportModal: data,
    })
  }

  render() {
    const userId = getLocalStorageItem('userId')
    const { value, history } = this.props
    const {
      commonAddress: { city = '', country = '' },
    } = getAddress(value.user && value.user.address)
    const { openReportModal } = this.state

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <div className="d-flex align-items-start">
              <div onClick={() => redirectToUserProfile(value.user, history)}>
                <div className="mr-4 ml-0 mb-0 mt-0 rentInnerReview-imgDiv">
                  <img
                    src={(value && value.user && value.user.image?.url) || defaultProfileIcon}
                    className="radius-4 h-100 width-100 rounded-circle"
                    alt="Profile"
                  />
                </div>
              </div>

              <div className="width-100">
                <div className="font-16">{value && value.user && value.user.firstName.trim()}</div>
                <div className="mb-1">
                  <div className="d-flex flex-wrap align-items-center width-100" style={{ gap: 4 }}>
                    <span className="review-star sm float-left review-star-section inner--review--star--section">
                      <RatingComponent rating={value.rating} className="rating-clr" />
                    </span>
                    <span style={{ marginTop: 3, color: 'darkslategray', fontSize: 14 }}>
                      {reviewDateFormate(value && value.createdAt)} | {city}, {country}
                    </span>
                    <span>
                      {userId && value?.user?.id !== userId && (
                        <span
                          className="ml-1 sm-font cursor-pointer inner--review--flag"
                          onClick={() => this.setReportModal(true)}
                        >
                          <Icons data="adam-country reviwer--flag  inner--review--flag-mobile" />
                        </span>
                      )}
                    </span>
                  </div>
                </div>

                <span className="review-desc review--description">
                  <ReadMore className="text-justify">{value.reviews}</ReadMore>
                </span>
              </div>
            </div>
          </Grid>
          <ReportModal
            open={openReportModal}
            closeModal={() => this.setReportModal(false)}
            moduleId={value.id}
            moduleType={flagReportModuleTypeEnum.review}
          />
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(InnerReview)
