import { Box, Grid } from "@material-ui/core";
import { Layout } from "../../components";
import '../../styles/footerScreens.scss'
import { footerScreenImages, icons } from "../../util/enums/enums";
import { Stack } from "@mui/material";

export default function BuyingProcess () {
    return(
        <Layout>
            <div className="container100 py-5">
            <Box className='my-4'>
                    <p className="as-com-title">Sales Engine</p>
                    <img style={{width: '100%'}} src={footerScreenImages.sales_engine_process} loading='lazy' alt='Sales engine'/>
                </Box>
            <p className="info-title text-primary text-center pt-5">The Buying Process Starts With</p>
            <Grid container>
                <Grid className="m-auto" items md={6} sm={12}>
                    <Stack>
                        <img width={120} loading='lazy' src={icons.sales_process_my_boat} alt='Sales process'/>
                        <span className="info-text my-3">
                        In this step, buyers and sellers engage in negotiations to agree on the terms of the boat purchase. This involves discussing price, conditions, and other important details before finalizing the deal.
                        </span>
                    </Stack>
                </Grid>
                <Grid className="text-right" items md={6} sm={12}>
                    <img  loading="lazy" src={footerScreenImages.buying_process_my_boat} alt='Buying process'/>
                </Grid>
                <Grid items md={6} sm={12}>
                <img  loading="lazy" src={footerScreenImages.buying_process_survey_boat} alt='Buying process'/>
                </Grid>
                <Grid className="m-auto" items md={6} sm={12}>
                    <Stack>
                        <img width={145} loading='lazy' src={icons.sales_process_survey_boat} alt='Sales process'/>
                        <span className="info-text my-3">
                        Buyers have the option to request a boat survey, choosing from nearby surveyors. Once a surveyor accepts the request, the buyer proceeds with payment. The survey report, boat images, videos, and a complete purchase survey report are included in this step, ensuring buyers have comprehensive information about the boat's condition.
                        </span>
                    </Stack>
                </Grid>
                <Grid className="m-auto py-5 py-md-0" items md={6} sm={12}>
                    <Stack>
                        <img width={145} loading='lazy' src={icons.sales_process_survey_report} alt='Sales process'/>
                        <span className="info-text my-3">
                        The surveyor is given a specific timeline to complete the purchase survey and upload the survey report. This step ensures that the buyer receives a comprehensive report on the boat's condition.
                        </span>
                    </Stack>
                </Grid>
                <Grid className="text-center" items md={6} sm={12}>
                    <img  loading="lazy" src={footerScreenImages.buying_process_survey_report} alt='Buying process'/>
                </Grid>
                <Grid items md={6} sm={12}>
                <img  loading="lazy" src={footerScreenImages.buying_process_boat_payment} alt="boat payment" />
                </Grid>
                <Grid className="m-auto" items md={6} sm={12}>
                    <Stack>
                        <img width={145} loading='lazy' src={icons.sales_process_boat_payment} alt='Sales process'/>
                        <span className="info-text my-3">
                        If the buyer is satisfied with the boat after reviewing the survey report, they can proceed to make the payment to secure the purchase.
                        </span>
                    </Stack>
                </Grid>
                <Grid className="m-auto py-5 py-md-0" items md={6} sm={12}>
                    <Stack>
                        <img width={120} loading='lazy' src={icons.sales_process_shipment} alt='Sales process'/>
                        <span className="info-text my-3">
                        Buyers have two choices for boat shipment: they can either arrange to pick up the boat themselves or select one of our recommended boat shippers, with the shipping costs being paid online. This step simplifies the logistics of getting the boat to the buyer's desired location.
                        </span>
                    </Stack>
                </Grid>
                <Grid className="text-center" items md={6} sm={12}>
                    <img  loading="lazy" src={footerScreenImages.buying_process_shipment} alt='Buying process'/>
                </Grid>
            </Grid>
            </div>
        </Layout>
    )
}
