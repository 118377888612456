import { put, takeLatest, all } from 'redux-saga/effects'
import { GET_ALL_REVIEWS, GET_ALL_REVIEWS_SUCCESS, GET_ALL_REVIEWS_FAILURE } from '../actionTypes'
import { graphqlClient } from '../../helpers/graphqlClient'
import { getAllReviews } from '../../graphql/mediaReviewsSchema'

function getAllReviewApi() {
  return graphqlClient
    .query({
      query: getAllReviews,
    })
    .then(res => res)
    .catch(error => {
      if (error.networkError) {
        return error.networkError.result
      }
    })
}

function* getAllReview() {
  const res = yield getAllReviewApi()
  if (res && res.data && !res.hasOwnProperty('errors')) {
    yield put({
      type: GET_ALL_REVIEWS_SUCCESS,
      payload: res.data.getAllReviews,
    })
  } else {
    yield put({ type: GET_ALL_REVIEWS_FAILURE, error: res.errors })
  }
}

function* getAllReviewSaga() {
  yield takeLatest(GET_ALL_REVIEWS, getAllReview)
}

export default function* mediaReviewSaga() {
  yield all([getAllReviewSaga()])
}
