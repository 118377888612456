import { put, takeLatest, all, takeEvery } from 'redux-saga/effects'
import {
  ADD_MARINA_AND_STORAGE,
  ADD_MARINA_AND_STORAGE_FAILURE,
  ADD_MARINA_AND_STORAGE_SUCCESS,
  GET_ALL_MARINA,
  GET_ALL_MARINA_SUCCESS,
  GET_ALL_MARINA_FAILURE,
  GET_TYPE_WISE_LOOKUP,
  GET_TYPE_WISE_LOOKUP_SUCCESS,
  GET_TYPE_WISE_LOOKUP_FAILURE,
  GET_EXPLORE_MARINA_SUCCESS,
  GET_EXPLORE_MARINA_FAILURE,
  GET_EXPLORE_MARINA,
  GET_USER_MARINA_STORAGE_SUCCESS,
  GET_USER_MARINA_STORAGE_FAILURE,
  GET_USER_MARINA_STORAGE,
  GET_RECENTLY_ADDED_MARINA_STORAGE,
  GET_RECENTLY_ADDED_MARINA_STORAGE_SUCCESS,
  GET_RECENTLY_ADDED_MARINA_STORAGE_FAILURE,
  GET_SINGLE_MARINA_STORAGE,
  GET_SINGLE_MARINA_STORAGE_SUCCESS,
  GET_SINGLE_MARINA_STORAGE_FAILURE,
  UPDATE_MARINA_STORAGE_SUCCESS,
  UPDATE_MARINA_STORAGE_FAILURE,
  UPDATE_MARINA_STORAGE,
  DELETE_MARINA_STORAGE,
  DELETE_MARINA_STORAGE_SUCCESS,
  DELETE_MARINA_STORAGE_FAILURE,
  GET_MOST_VIEWED_MARINA_STORAGE,
  GET_MOST_VIEWED_MARINA_STORAGE_SUCCESS,
  GET_MOST_VIEWED_MARINA_STORAGE_FAILURE,
  SEARCH_MARINA_AND_STORAGE,
  SEARCH_MARINA_AND_STORAGE_SUCCESS,
  SEARCH_MARINA_AND_STORAGE_FAILURE,
  CLEAR_SEARCH_MARINA_AND_STORAGE_FLAG,
  GET_MORE_SERVICE,
  GET_MORE_SERVICE_SUCCESS,
  GET_MORE_SERVICE_FAILURE,
  GET_TOP_RATED_MARINA_STORAGE,
  GET_TOP_RATED_MARINA_STORAGE_SUCCESS,
  GET_TOP_RATED_MARINA_STORAGE_FAILURE,
  GET_MARINA_STORAGE_All_SERVICES_SUCCESS,
  GET_MARINA_STORAGE_All_SERVICES_FAILURE,
  GET_MARINA_STORAGE_All_SERVICES,
  ERROR_MESSAGE_SHOW,
  GET_MARINA_BY_TYPE_SUCCESS,
  GET_MARINA_BY_TYPE_FAILURE,
  GET_MARINA_BY_TYPE,
  CHANGE_MARINA_STATUS,
  CHANGE_MARINA_STATUS_SUCCESS,
  CHANGE_MARINA_STATUS_FAILURE,
  CLEAR_MARINA_FLAG,
  NOTIFICATION_MESSAGE,
  ERROR_NOTIFICATION_MESSAGE,
} from '../actionTypes'
import { graphqlClient } from '../../helpers/graphqlClient'
import {
  addMarinaAndStorage,
  getAllMarinaStorage,
  getTypeWiseLookup,
  getMarinasByType,
  marinaStorageByUser,
  getRecentlyAddedMarinaStorage,
  getSingleMarinaStorage,
  updateMarinaStorage,
  deleteMarinaStorage,
  getMostViewedMarinaStorage,
  searchMarinaStorage,
  getMoreServicesQuery,
  getTopRatedMarinaStorageQuery,
  getMarinaStorageServiceListQuery,
  getSimilarMarinaList,
  marinaChangeMarketStatus,
} from '../../graphql/marinaAndStorageSchema'
import { notificationEnum, dynamicError } from '../../util/enums/notificationEnum'
import { mutation } from './apiHelper'

// start

function getTypeWiseLookupApi(type) {
  return graphqlClient
    .query({
      query: getTypeWiseLookup,
      variables: { type: type },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function* getTypeWiseLookupData(action) {
  try {
    const res = yield getTypeWiseLookupApi(action.payload)
    yield put({
      type: GET_TYPE_WISE_LOOKUP_SUCCESS,
      payload: res.data.getTypeWiseLookup,
      value: action.payload,
    })
  } catch (error) {
    yield put({ type: GET_TYPE_WISE_LOOKUP_FAILURE, error })
  }
}

function* getTypeWiseLookupSaga() {
  yield takeEvery(GET_TYPE_WISE_LOOKUP, getTypeWiseLookupData)
}

// end

function createMarinaStorageApi(data) {
  return graphqlClient
    .mutate({
      mutation: addMarinaAndStorage,
      variables: {
        input: data.payload,
      },
    })
    .then(res => {
      return res
    })
    .catch(error => {
      throw error.networkError.result.errors
    })
}

function* createMarinStorageData(data) {
  try {
    const res = yield createMarinaStorageApi(data)
    yield put({ type: ADD_MARINA_AND_STORAGE_SUCCESS, payload: res })
    yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.MARINA_CREATE })
  } catch (error) {
    yield put({ type: ERROR_MESSAGE_SHOW, payload: error })
    yield put({ type: ADD_MARINA_AND_STORAGE_FAILURE, error })
  }
}

function* crateMarinaStorageSaga() {
  yield takeLatest(ADD_MARINA_AND_STORAGE, createMarinStorageData)
}

function getAllMarinaApi(data) {
  return graphqlClient
    .query({
      query: getAllMarinaStorage,
    })
    .then(res => res)
    .catch(error => error)
}
function* getAllMarinaData(action) {
  try {
    const data = yield getAllMarinaApi(action)
    yield put({ type: GET_ALL_MARINA_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_ALL_MARINA_FAILURE, error })
  }
}

function* getAllMarinaSaga() {
  yield takeLatest(GET_ALL_MARINA, getAllMarinaData)
}

// start explore marina

function getExploreMarinaApi(data) {
  return graphqlClient
    .query({
      query: getMarinasByType,
      variables: data,
    })
    .then(res => res)
    .catch(error => error)
}

function* getExploreMarinaData(action) {
  try {
    const res = yield getExploreMarinaApi(action.payload)
    yield put({ type: GET_EXPLORE_MARINA_SUCCESS, payload: res })
  } catch (error) {
    yield put({ type: GET_EXPLORE_MARINA_FAILURE, error })
  }
}

function* getExploreMarinaSaga() {
  yield takeLatest(GET_EXPLORE_MARINA, getExploreMarinaData)
}

function getUserMarinaStorageApi(data) {
  return graphqlClient
    .query({
      query: marinaStorageByUser,
      variables: data,
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(err => {
      throw err
    })
}

function* getUserMarinaStorageData(action) {
  try {
    const data = yield getUserMarinaStorageApi(action.payload)
    yield put({ type: GET_USER_MARINA_STORAGE_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_USER_MARINA_STORAGE_FAILURE, e })
  }
}

function* getUserMarinaStorageSaga() {
  yield takeLatest(GET_USER_MARINA_STORAGE, getUserMarinaStorageData)
}

//get recently added marina and storage

function getRecentlyAddedMarinaAndStorageApi(data) {
  return graphqlClient
    .query({
      query: getRecentlyAddedMarinaStorage,
      variables: data,
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(err => {
      throw err
    })
}

function* getRecentlyAddedMarinaAndStorageData(action) {
  try {
    const data = yield getRecentlyAddedMarinaAndStorageApi(action.payload)
    yield put({
      type: GET_RECENTLY_ADDED_MARINA_STORAGE_SUCCESS,
      payload: data,
    })
  } catch (e) {
    yield put({ type: GET_RECENTLY_ADDED_MARINA_STORAGE_FAILURE, e })
  }
}

function* getRecentlyAddedMarinaAndStorageSaga() {
  yield takeLatest(GET_RECENTLY_ADDED_MARINA_STORAGE, getRecentlyAddedMarinaAndStorageData)
}

//get single marina

export function getSingleMarinaApi(data, basic = false) {
  return graphqlClient
    .query({
      query: getSingleMarinaStorage,
      variables: { id: data, basic },
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(err => {
      return err.networkError.result
    })
}

function* getSingleMarinaData(action) {
  try {
    const data = yield getSingleMarinaApi(action.payload)
    if (data?.errors?.length) {
      yield put({ type: GET_SINGLE_MARINA_STORAGE_FAILURE, e: data.errors })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: data.errors })
    } else if (data.data) {
      yield put({ type: GET_SINGLE_MARINA_STORAGE_SUCCESS, payload: data })
    }
  } catch (e) {
    yield put({ type: GET_SINGLE_MARINA_STORAGE_FAILURE, e })
    yield put({ type: ERROR_MESSAGE_SHOW, payload: e })
  }
  yield put({ type: CLEAR_MARINA_FLAG })
}

function* getSingleMarinaSaga() {
  yield takeLatest(GET_SINGLE_MARINA_STORAGE, getSingleMarinaData)
}

///update marina

function updateMarinaStorageAPi(data) {
  return graphqlClient
    .mutate({
      mutation: updateMarinaStorage,
      variables: { input: data },
    })
    .then(res => {
      return res
    })
    .catch(err => {
      throw err.networkError.result.errors
    })
}

function* updateMarinaStorageData(action) {
  try {
    const data = yield updateMarinaStorageAPi(action.payload)
    yield put({ type: UPDATE_MARINA_STORAGE_SUCCESS, payload: data })
    yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.MARINA_UPDATE })
  } catch (e) {
    yield put({ type: UPDATE_MARINA_STORAGE_FAILURE, e })
    yield put({ type: ERROR_MESSAGE_SHOW, payload: e })
  }
}

function* updateMarinaStorageSaga() {
  yield takeLatest(UPDATE_MARINA_STORAGE, updateMarinaStorageData)
}

//delete marina storage

function deleteMarinaStorageApi(data) {
  return graphqlClient
    .mutate({
      mutation: deleteMarinaStorage,
      variables: { id: data },
    })
    .then(res => {
      return res
    })
    .catch(err => {
      throw err.networkError.result.errors
    })
}

function* deleteMarinaStorageData(action) {
  try {
    const res = yield deleteMarinaStorageApi(action.payload)
    yield put({ type: DELETE_MARINA_STORAGE_SUCCESS, payload: res.data.deleteMarina })
    yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.MARINA_DELETE })
  } catch (e) {
    yield put({ type: DELETE_MARINA_STORAGE_FAILURE, e })
  }
}

function* deleteMarinaStorageSaga() {
  yield takeLatest(DELETE_MARINA_STORAGE, deleteMarinaStorageData)
}

//get most viewed marina and storage

function getMostViewedMarinaStorageApi(data) {
  return graphqlClient
    .query({
      query: getMostViewedMarinaStorage,
      variables: data,
    })
    .then(res => {
      return res
    })
    .catch(err => {
      throw err
    })
}

function* getMostViewedMarinaStorageData(action) {
  try {
    const res = yield getMostViewedMarinaStorageApi(action.payload)
    yield put({
      type: GET_MOST_VIEWED_MARINA_STORAGE_SUCCESS,
      payload: res.data,
    })
  } catch (e) {
    yield put({ type: GET_MOST_VIEWED_MARINA_STORAGE_FAILURE, e })
  }
}

function* getMostViewedMarinaStorageSaga() {
  yield takeLatest(GET_MOST_VIEWED_MARINA_STORAGE, getMostViewedMarinaStorageData)
}

// SEARCH MARINA & STORAGE

function searchMarinaStoragesApi(input) {
  return graphqlClient
    .mutate({
      mutation: searchMarinaStorage,
      variables: { input },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => err)
}

function* searchMarinaStorages(action) {
  try {
    const res = yield searchMarinaStoragesApi(action.payload)
    yield put({
      type: SEARCH_MARINA_AND_STORAGE_SUCCESS,
      payload: res.data.searchMarinaStorage,
    })
  } catch (error) {
    yield put({ type: SEARCH_MARINA_AND_STORAGE_FAILURE, error })
  }
  yield put({ type: CLEAR_SEARCH_MARINA_AND_STORAGE_FLAG })
}

function* searchMarinaStoragesSaga() {
  yield takeLatest(SEARCH_MARINA_AND_STORAGE, searchMarinaStorages)
}

/** Get More marina service */

function* getMoreMarinaServiceSaga() {
  yield takeLatest(GET_MORE_SERVICE, getMoreServiceData)
}

function getMoreServiceApi(data) {
  return graphqlClient
    .query({
      query: getMoreServicesQuery,
      variables: { isAdmin: data.isAdmin },
    })
    .then(res => res)
    .catch(error => error)
}

function* getMoreServiceData(action) {
  try {
    const data = yield getMoreServiceApi(action.payload)
    yield put({
      type: GET_MORE_SERVICE_SUCCESS,
      payload: data.data.getAllMarinaTypes.items,
    })
  } catch (error) {
    yield put({ type: GET_MORE_SERVICE_FAILURE, error })
  }
}
/** Get Top rated marina service */

function* getTopRatedMarinaStorageSaga() {
  yield takeLatest(GET_TOP_RATED_MARINA_STORAGE, getTopRatedStorageData)
}

function getTopRatedStorageApi(input) {
  return graphqlClient
    .query({
      query: getTopRatedMarinaStorageQuery,
      variables: input,
    })
    .then(res => res)
    .catch(error => error)
}

function* getTopRatedStorageData(action) {
  try {
    const data = yield getTopRatedStorageApi(action.payload)
    yield put({
      type: GET_TOP_RATED_MARINA_STORAGE_SUCCESS,
      payload: data.data.getTopRatedMarinaList,
    })
  } catch (error) {
    yield put({ type: GET_TOP_RATED_MARINA_STORAGE_FAILURE, error })
  }
}
/** Get marina storage by service  */

function* getMarinaServicesSaga() {
  yield takeLatest(GET_MARINA_STORAGE_All_SERVICES, getMarinaServicesListingData)
}

function getMarinaServicesListingApi(input) {
  return graphqlClient
    .query({
      query: getMarinaStorageServiceListQuery,
      variables: input,
    })
    .then(res => res)
    .catch(error => error)
}

function* getMarinaServicesListingData(action) {
  try {
    const data = yield getMarinaServicesListingApi(action.payload)
    yield put({
      type: GET_MARINA_STORAGE_All_SERVICES_SUCCESS,
      payload: data.data.marinaStorageByService,
    })
  } catch (error) {
    yield put({ type: GET_MARINA_STORAGE_All_SERVICES_FAILURE, error })
  }
}

function getMarinaByTypeApi(data) {
  return graphqlClient
    .query({
      query: getSimilarMarinaList,
      variables: data,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function* getMarinaByTypeSaga(action) {
  try {
    const res = yield getMarinaByTypeApi(action.payload)
    yield put({ type: GET_MARINA_BY_TYPE_SUCCESS, payload: res.data.getSimilarMarinaList })
  } catch (error) {
    yield put({ type: GET_MARINA_BY_TYPE_FAILURE, error })
  }
}

function* getRentTripCityWiseSaga() {
  yield takeLatest(GET_MARINA_BY_TYPE, getMarinaByTypeSaga)
}

// change marina storage status
function changeMarinaStatusApi(input) {
  return mutation(marinaChangeMarketStatus, { input })
}

function* changeMarinaStatusData(action) {
  try {
    const res = yield changeMarinaStatusApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: CHANGE_MARINA_STATUS_FAILURE, error: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    } else if (res.data) {
      yield put({ type: CHANGE_MARINA_STATUS_SUCCESS, payload: res.data.marinaChangeMarketStatus })
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.MARINA_STATUS_CHANGE })
    }
  } catch (error) {
    yield put({ type: CHANGE_MARINA_STATUS_FAILURE, error })
  }
  yield put({ type: CLEAR_MARINA_FLAG })
}

function* changeMarinaStatusSaga() {
  yield takeLatest(CHANGE_MARINA_STATUS, changeMarinaStatusData)
}

export default function* marinaAndStorageSaga() {
  yield all([
    crateMarinaStorageSaga(),
    getAllMarinaSaga(),
    getTypeWiseLookupSaga(),
    getExploreMarinaSaga(),
    getUserMarinaStorageSaga(),
    getRecentlyAddedMarinaAndStorageSaga(),
    getSingleMarinaSaga(),
    updateMarinaStorageSaga(),
    deleteMarinaStorageSaga(),
    getMostViewedMarinaStorageSaga(),
    searchMarinaStoragesSaga(),
    getMoreMarinaServiceSaga(),
    getTopRatedMarinaStorageSaga(),
    getMarinaServicesSaga(),
    getRentTripCityWiseSaga(),
    changeMarinaStatusSaga(),
  ])
}
