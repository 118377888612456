import {
  ADD_BOAT_SERVICE,
  GET_TYPE_WISE_BOAT_SERVICE,
  GET_RECENTLY_ADDEDD_SERVICE,
  CLEAR_SERVICE_FLAG,
  GET_USER_BOAT_SERVICE,
  CLEAR_EDIT_SERVICE,
  GET_MOST_VIEWED_BOAT_SERVICES,
  SEARCH_YACHT_SERVICE,
  GET_ALL_BOAT_SERVICE_TYPES,
  EDIT_YACHT_SERVICE,
  CLEAR_SERVICE_TYPES_FLAG,
  GET_SERVICE_DETAIL,
  TOGGLE_SERVICE_STATUS,
  ADD_SERVICE_TAG,
  GET_SERVICE_TAGS,
  CHOOSE_FOR_ESTIMATED_COST,
  GET_SERVICE_PROFILE_FOR_ESTIMATED_COST,
  IS_CHOSEN_FOR_ESTIMATED_COST,
  CHOOSE_FOR_YACHT_FIN,
  IS_CHOSEN_FOR_YACHT_FIN,
  GET_SERVICE_PROFILE_FOR_YACHT_FINANCING,
} from '../actionTypes'

export const addBoatService = data => ({
  type: ADD_BOAT_SERVICE,
  payload: data,
})

export const getTypeWiseService = data => ({
  type: GET_TYPE_WISE_BOAT_SERVICE,
  payload: data,
})

export const getRecentlyAddedServices = data => ({
  type: GET_RECENTLY_ADDEDD_SERVICE,
  payload: data,
})

export const clearServiceFlag = () => ({
  type: CLEAR_SERVICE_FLAG,
})

export const getUserBoatService = () => ({
  type: GET_USER_BOAT_SERVICE,
})

export const clearEditFlag = () => ({
  type: CLEAR_EDIT_SERVICE,
})

export const getMostViewedBoatServices = data => ({
  type: GET_MOST_VIEWED_BOAT_SERVICES,
  payload: data,
})

export const searchYachtService = data => ({
  type: SEARCH_YACHT_SERVICE,
  payload: data,
})

export const getAllBoatServiceTypes = data => ({
  type: GET_ALL_BOAT_SERVICE_TYPES,
  payload: data,
})

export const editYachtService = (data, fromProfile = true) => ({
  type: EDIT_YACHT_SERVICE,
  payload: data,
  fromProfile,
})

export const clearServiceTypesFlag = () => ({
  type: CLEAR_SERVICE_TYPES_FLAG,
})

export const getServiceDetail = () => ({
  type: GET_SERVICE_DETAIL,
})

export const toggleYachtServiceStatus = data => ({
  type: TOGGLE_SERVICE_STATUS,
  payload: data,
})

export const addServiceTag = data => ({
  type: ADD_SERVICE_TAG,
  payload: data
})

export const getServiceTags = data => ({
  type: GET_SERVICE_TAGS,
  payload: data
})

export const chooseForEstimatedCost = data => ({
  type: CHOOSE_FOR_ESTIMATED_COST,
  payload: data
})

export const isChosenForEstimatedCost = data => ({
  type: IS_CHOSEN_FOR_ESTIMATED_COST,
  payload: data
})

export const getServiceProfileForEstimatedCost = data => ({
  type: GET_SERVICE_PROFILE_FOR_ESTIMATED_COST,
  payload: data
})

export const getServiceProfileForYachtFinancing = data => ({
  type: GET_SERVICE_PROFILE_FOR_YACHT_FINANCING,
  payload: data
})

export const chooseForYachtFinancing = data => ({
  type: CHOOSE_FOR_YACHT_FIN,
  payload: data
})

export const isChosenForYachtFinancing = data => ({
  type: IS_CHOSEN_FOR_YACHT_FIN,
  payload: data
})
