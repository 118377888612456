import { footerScreenImages } from '../../util/enums/enums'

const wayCardsData = [
  {
    icon: footerScreenImages.beAPartner_way_buyers,
    title: 'Buyers',
    text: `As a buyer on AdamSea, you'll not only have access to a wide range of boat listings, but you'll also benefit from our dedicated support team. We understand that buying a boat is a significant investment, and our team is here to assist you throughout the entire process. Whether you have questions about a listing, need assistance with the payment process, or require guidance on choosing the right surveyor or shipper, we're here to ensure a smooth and seamless experience.`,
  },

  {
    icon: footerScreenImages.beAPartner_way_renters,
    title: 'Renters',
    text: `Renting a boat has never been easier than with AdamSea. Our platform provides a user-friendly interface where you can browse through a diverse selection of boats available for rent. Additionally, we offer advanced search filters that allow you to specify your desired location, size, and other preferences. With transparent rental terms and secure payment options, you can confidently book your boat rental and embark on unforgettable adventures on the water.`,
  },

  {
    icon: footerScreenImages.beAPartner_way_rentees,
    title: 'Rentees',
    text: `As a boat owner looking to rent out your vessel, AdamSea offers a comprehensive solution to streamline the rental process. Our platform allows you to showcase your boat to a global audience of potential renters. You'll have full control over your rental rates, availability, and booking management. Moreover, our secure payment system provides peace of mind, ensuring that you receive timely and hassle-free payments for your boat rentals.`,
  },

  {
    icon: footerScreenImages.beAPartner_way_marinas,
    title: 'Marinas',
    text: `Partnering with AdamSea as a marina operator opens up new avenues for business growth and exposure. By listing your marina on our platform, you can attract boaters from around the world who are seeking exceptional docking facilities and marina services. We provide marketing support, including featured marina listings, targeted promotions, and increased visibility to help you maximize occupancy and enhance the boating experience for your customers.`,
  },

  {
    icon: footerScreenImages.beAPartner_way_builders,
    title: 'Manufacturers',
    text: `AdamSea offers boat manufacturers an unparalleled opportunity to showcase their craftsmanship and reach a global audience of boating enthusiasts. By creating a manufacturer profile on our platform, you can present your brand, highlight your boat models, and engage directly with potential buyers. Our marketing tools and partnership programs ensure that your boats receive the attention they deserve, enabling you to expand your customer base and drive sales worldwide.`,
  },

  {
    icon: footerScreenImages.beAPartner_way_sellers,
    title: 'Sellers',
    text: `Selling your boat on AdamSea is a seamless process that puts you in control. With our intuitive listing creation tools, you can easily upload detailed information, high-quality images, and pricing for your boat. Our platform's extensive reach and user-friendly interface enable you to connect with motivated buyers from all corners of the globe. We provide resources and guidance throughout the selling journey, ensuring that you achieve a successful sale and maximize the value of your boat.`,
  },

  {
    icon: footerScreenImages.beAPartner_way_profs,
    title: 'Maintenance professionals',
    text: `As a maintenance professional, partnering with AdamSea allows you to showcase your expertise and connect with boat owners who are in need of your services. By joining our platform, you gain access to a vast network of boaters actively seeking maintenance and repair solutions. You can showcase your qualifications, highlight your services, and communicate directly with potential clients. Our platform serves as a bridge, connecting you with boat owners worldwide and fostering lasting relationships within the boating community.`,
  },
]

export default wayCardsData
