import {
  DEALERS_REQUEST_MANUFACTURER,
  DEALERS_REQUEST_MANUFACTURER_SUCCESS,
  DEALERS_REQUEST_MANUFACTURER_FAILURE,
  SEARCH_MANUFACTURER,
  SEARCH_MANUFACTURER_SUCCESS,
  SEARCH_MANUFACTURER_FAILURE,
  DEALERS_SENT_REQUESTS,
  DEALERS_SENT_REQUESTS_SUCCESS,
  DEALERS_SENT_REQUESTS_FAILURE,
  CLEAR_DEALERS_REQUEST_FLAG,
  DEALERS_REQUEST_MULTIPLE_MANUFACTURER,
  DEALERS_REQUEST_MULTIPLE_MANUFACTURER_SUCCESS,
  DEALERS_REQUEST_MULTIPLE_MANUFACTURER_FAILURE,
  GET_ALL_DEALERS_SUCCESS,
  GET_ALL_DEALERS_FAILURE,
  GET_ALL_DEALERS,
} from '../actionTypes'

const InitialState = {
  getSuccess: false,
  getError: false,

  requestSuccess: false,
  requestError: false,

  multipleRequestSuccess: false,
  multipleRequestError: false,

  dealers: [],
  searchedManufacturers: [],

  searchSuccess: false,
  searchError: false,

  isLoading: false,
}

export const dealersReducer = (state = InitialState, action) => {
  switch (action.type) {
    case DEALERS_REQUEST_MANUFACTURER:
      return {
        ...state,
        isLoading: true,
        requestSuccess: false,
        requestError: false,
      }

    case DEALERS_REQUEST_MANUFACTURER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requestSuccess: true,
      }

    case DEALERS_REQUEST_MANUFACTURER_FAILURE:
      return {
        ...state,
        isLoading: false,
        requestError: true,
      }

    case CLEAR_DEALERS_REQUEST_FLAG:
      return {
        ...state,
        requestSuccess: false,
        requestError: false,
      }

    case SEARCH_MANUFACTURER:
      return {
        ...state,
        isLoading: true,
        searchedManufacturers: [],
      }

    case SEARCH_MANUFACTURER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        searchedManufacturers: action.payload.items,
        searchSuccess: true,
      }

    case SEARCH_MANUFACTURER_FAILURE:
      return {
        ...state,
        isLoading: false,
        searchedManufacturers: [],
        searchError: true,
      }

    case DEALERS_SENT_REQUESTS:
      return {
        ...state,
        dealersSentRequestsData: [],
        isLoading: true,
      }

    case DEALERS_SENT_REQUESTS_SUCCESS:
      return {
        ...state,
        dealersSentRequestsData: action.payload.items,
        totalDealersSentRequestsData: action.payload.total,
        isLoading: false,
      }

    case DEALERS_SENT_REQUESTS_FAILURE:
      return {
        ...state,
        dealersSentRequestsData: [],
        isLoading: false,
      }

    case DEALERS_REQUEST_MULTIPLE_MANUFACTURER:
      return {
        ...state,
        isLoading: true,
        multipleRequestSuccess: false,
        multipleRequestError: false,
      }

    case DEALERS_REQUEST_MULTIPLE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        multipleRequestSuccess: true,
      }

    case DEALERS_REQUEST_MULTIPLE_MANUFACTURER_FAILURE:
      return {
        ...state,
        isLoading: false,
        multipleRequestError: true,
      }

    case GET_ALL_DEALERS:
      return {
        ...state,
        getSuccess: false,
        isLoading: true,
        getError: false,
      }

    case GET_ALL_DEALERS_SUCCESS:
      return {
        ...state,
        getSuccess: true,
        isLoading: false,
        manufacturerDealers: action.payload.items,
        totalDealers: action.payload.total,
      }

    case GET_ALL_DEALERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        getError: true,
        manufacturerDealers: [],
        totalDealers: 0,
      }

    default:
      return state
  }
}
