import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import './button.scss'

/**
 * type : button or submit
 * variant : fill and outline
 * color : success, info, danger(delete), adamsea view and adamsea edit
 */

export const ButtonComponent = React.memo(
  ({ children, type, className, color, onClick, variant, disabled, capitalize, loader }) => {
    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={`${className} loader-${loader} as--btn as--btn--${color} ${
          capitalize ? 'as--btn--capitalize' : ''
        } as--${color}--${variant} adamsea--button--common`}
      >
        {loader && <CircularProgress />}
        {children}
      </button>
    )
  }
)

ButtonComponent.defaultProps = {
  color: 'success',
  variant: 'outline',
  type: 'button',
  className: '',
  disabled: false,
  capitalize: true,
  loader: false,
}
