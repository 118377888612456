import React from 'react'

export const Loader = ({ isSmall, isPageLoader, isSearchLoader, className }) => {
  const renderLoaderImage = () => (
    <img
      className={isSmall ? 'loader--transparent' : ''}
      src={require('../../assets/images/loader/loading-gif.gif')}
      alt="Loading..."
    />
  )

  return isPageLoader ? (
    <span className="loader--page-fixed">{renderLoaderImage()}</span>
  ) : isSearchLoader ? (
    <div className={`${className} search--loader--main--div`}>
      <span className="loader--search--page-fixed">{renderLoaderImage()}</span>
    </div>
  ) : (
    renderLoaderImage()
  )
}

Loader.defaultProps = {
  isSmall: false,
  isPageLoader: false,
  isSearchLoader: false,
  className: '',
}
