import { useEffect, useState } from 'react'
import { CommonModal } from '../components/modal/commonModal'
import { ButtonComponent } from '../components/form/Button'
import { useHistory } from 'react-router-dom'

export default function VerificationModal({ open: _open = false, content = 'first' }) {
  const [open, setOpen] = useState(_open)

  const history = useHistory()

  useEffect(() => {
    if (_open) setOpen(_open)
  }, [_open]);

  return (
    <CommonModal
      open={open}
      close={() => setOpen(false)}
      title="Email and mobile verification"
      className="email--verification--dialogue"
      hideCloseIcon
    >
      <div className="email--verification--desc--div">
        <div>
          <p className="email--verification--desc">You need to verify your email address and mobile number {content}.</p>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <ButtonComponent
          className="email--verification--resend--btn h-auto as--view--outline"
          onClick={() => {
            setOpen(false)
            history.push('/profile')
          }}
        >
          GO TO PROFILE
        </ButtonComponent>

        <ButtonComponent className="email--verification--resend--btn h-auto as--cancel--outline" onClick={() => setOpen(false)}>
          CANCEL
        </ButtonComponent>
      </div>
    </CommonModal>
  )
}
