import React, { useState, useEffect } from 'react'

import { SectionWithShowMapStyleBoat, MapBoatGridStyleGrid } from '../styleComponent/styleComponent'
import { commonBoatShowAllType, percentageFormate } from '../../util/utilFunctions'
import SharePopup from '../share/SharePopup'
import { cityCountryNameFormatter, getBoatTypeName, checkKayakType } from '../../helpers/jsxHelper'
import { wishlistModuleEnum, shareModule, defaultImage, dimension, boatVerifyMessage } from '../../util/enums/enums'

import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { WishlistComponent } from '../wishlist/WishlistComponent'
import { CurrencyContextConsumer } from '../../CurrencyContext'

import RatingComponent from '../rating/Rating'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import ImageGalleryComponent from '../imageHelper/imageGalleryComponent'
import './boatCardMap.scss'
import { viewBoatHandler } from '../../helpers/boatHelper'
import WishListButton from '../staticComponent/wishListButton'
import { WarnNotify } from '../../helpers/notification'

export const BoatCard = props => {
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [isArrow, setIsArrow] = useState(true)

  const { boat, onClick, index, isWishlist, removeFromList, sliceTo } = props
  const {
    id,
    price,
    address: [{ city, country }],
    images,
    rating,
    adStatus,
  } = boat
  const [height, setHeight] = useState(dimension.featureBoat.height)
  const [width, setWidth] = useState(dimension.featureBoat.width)

  const zoomWidth = () => {
    const className = isWishlist ? '.dashboard-layout-responsive' : '.grid--w-100--map'
    const widthR = getRatio(dimension, 'showMapRatio', className)
    const heightR = getHeightRatio(dimension, 'showMapRatio', className)
    widthR && setWidth(widthR)
    heightR && setHeight(heightR)
  }

  useEffect(() => {
    zoomWidth()
    window.addEventListener('resize', zoomWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = index => {
    setSelectedIndex(selectedIndex !== index ? index : null)
  }

  const boatTypeName = getBoatTypeName(boat)
  const isKayakType = checkKayakType(boat)

  return (
    <div className="boat--card--show--gird" onMouseEnter={() => props.onBoatHover(boat)} onMouseLeave={props.onBoatHover}>
      <div
        className={`boat--card--show--img`}
        onClick={() => !adStatus && WarnNotify(boatVerifyMessage)}
        style={{ width: `${width}px` }}
        onMouseLeave={() => {
          setIsArrow(false)
        }}
        onMouseOver={() => {
          setIsArrow(true)
        }}
      >
        {isWishlist ? (
          <SectionWithShowMapStyleBoat
            width={width}
            height={height}
            className="cursor-pointer"
            img={(images && images.length && encodeURI(images[0]?.url)) || defaultImage}
            onClick={() => (adStatus ? onClick() : null)}
          />
        ) : (
          <>
            {images && images.length > 0 && (
              <ImageGalleryComponent
                width={width}
                height={height}
                onClick={() => viewBoatHandler(boat)}
                isArrow={isArrow}
                images={images}
                sliceTo={sliceTo}
              />
            )}
          </>
        )}

        <div className="card-action">
          {/* {commonBoatShowAllType(boat)} [NOTE]:client confirmation */}
          <div className="opacity--0"></div>
          <div className="share-icon">
            <WishlistComponent moduleId={id} moduleType={wishlistModuleEnum.BOAT} />
            {/* <SharePopup
              handleClick={() => handleClick(index)}
              index={index}
              selectedIndex={selectedIndex}
              moduleId={id}
              moduleType={shareModule.BOAT}
              sharedUrl={viewBoatHandler(boat, true, false, true)}
            /> */}
          </div>
        </div>
      </div>

      <MapBoatGridStyleGrid width={width} className="boat--main-with-btn">
        <div className="boat--card--details--main">
          {isWishlist && <div className="boat--type-map">{commonBoatShowAllType(boat)}</div>}
          <div className="boat--card--details--price">
            <CurrencyContextConsumer>
              {({ currentCurrency }) => (
                <span className="boat--card--show-map--price">{getConvertedPrice(price, currentCurrency)}</span>
              )}
            </CurrencyContextConsumer>
          </div>
          <div className="boat--name">
            {boat.yearBuilt} {boatTypeName}
            {!isKayakType && ` / ${boat.lengthInFt} ft`}
          </div>
          <div className="boat--card--place--city--color">{cityCountryNameFormatter(city, country)}</div>

          <h6 className="boat--card--manufactured--by--fix--color">
            <span>by</span>{' '}
            <span className="text-capitalize">
              {boat.manufacturedBy
                ? boat.manufacturedBy.alias
                : boat.seller?.companyName || [boat.seller?.firstName, boat.seller?.lastName].filter(Boolean).join(' ')}
            </span>
          </h6>
          <div className="d-flex align-center flex-none">
            <div className="boat--card--rating--margin">
              <RatingComponent rating={rating} className="rating-clr" />
            </div>
            {/* <span className="rating-count">({percentageFormate(rating)})</span> */}
          </div>
        </div>
        {isWishlist && (
          <div className="manage-boat-card-button">
            <WishListButton viewOnclick={() => viewBoatHandler(boat)} removeOnclick={removeFromList} />
          </div>
        )}
      </MapBoatGridStyleGrid>
    </div>
  )
}

BoatCard.defaultProps = {
  isWishlist: false,
  onBoatHover: () => null,
  sliceTo: 0,
}
