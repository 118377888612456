import React from 'react'
import ChatWrapper from '../../components/chats/chatWrapper'
import { ChatContextProvider } from '../../contexts/ChatContext'
import classNames from 'classnames'

const AdamseaChat = props => {
  return (
    <ChatContextProvider>
      <div
        className={classNames('chat-wrapper-height', props.className)}
        style={{
          top: `${props?.height}px`,
          height: `calc(100vh - ${props?.height}px)`,
        }}
      >
        <ChatWrapper height={props?.height} />
      </div>
    </ChatContextProvider>
  )
}

export default AdamseaChat
