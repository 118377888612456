import React from 'react'
import { Grid } from '@material-ui/core'

import UserContext from '../../../UserContext'
import { dimension } from '../../../util/enums/enums'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../../helpers/ratio'
import { ShowAllLink } from '../../helper/showAllLink'
import { AuctionDetail } from '../../home/singleComponents/auctionDetail'

import '../../home/home.scss'

export class AuctionCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isTrue: false,
      height: 380,
      width: 280,
      margin: 40,
    }
  }

  static contextType = UserContext
  zoomWidth = () => {
    const width = getRatioZoomResponsive(dimension, 'auctionRoom', '.zoom-container')
    const height = getHeightZoomResponsive(dimension, 'auctionRoom', '.zoom-container')

    this.setState({ width: width.width, gridClassName: width.className, margin: width.margin, height })
  }

  componentDidMount() {
    this.zoomWidth()
    window.addEventListener('resize', this.zoomWidth)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.zoomWidth)
  }

  createChildren = () => {
    const { width, height, margin, gridClassName } = this.state
    const { carouselData, itemsLength } = this.props
    const { history } = this.context

    return carouselData && carouselData.length > 0 ? (
      carouselData.slice(0, itemsLength).map(value => (
        <div key={value.id} style={{ width: width, marginRight: margin, marginBottom: margin }} className={`${gridClassName}`}>
          <AuctionDetail value={value} history={history} height={height} width={width} />
        </div>
      ))
    ) : (
      <Grid item xs={12}>
        No Record Found
      </Grid>
    )
  }

  render() {
    const { itemsLength, showAllText, carouselData, total } = this.props
    return (
      <>
        <div className="zoom-container grid-box-time-card">{this.createChildren()}</div>

        <ShowAllLink
          className="show--all-auction--rooms"
          totalLength={total}
          data={carouselData}
          itemsLength={itemsLength}
          showAllText={showAllText}
          url={`/show-all-auction-rooms`}
        />
      </>
    )
  }
}
