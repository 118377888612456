import React, { useState, useEffect } from 'react'

import { cityCountryNameFormatter, redirectToUserProfile } from '../../helpers/jsxHelper'
import { SectionWithShowMapStyleBoat, MapServiceGridStyleGrid } from '../styleComponent/styleComponent'
import { percentageFormate } from '../../util/utilFunctions'
import RatingComponent from '../rating/Rating'
import { dimension, defaultImage, wishlistModuleEnum, shareModule, boatVerifyMessage } from '../../util/enums/enums'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import ImageGalleryComponent from '../imageHelper/imageGalleryComponent'
import { WishlistComponent } from '../wishlist/WishlistComponent'
import SharePopup from '../share/SharePopup'
import { textTruncate } from '../../helpers/string'
import WishListButton from '../staticComponent/wishListButton'
import { WarnNotify } from '../../helpers/notification'
import './boatServiceMapCard.scss'
import { viewServiceBoatHandler } from '../../helpers/boatHelper'

export const BoatServiceMapCard = props => {
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [isArrow, setIsArrow] = useState(true)

  const { value, onClick, isWishlist, removeFromList, index, sliceTo } = props

  const {
    images,
    address: [{ city, country }],
    serviceCountry,
    service,
    serviceDesc,
    rating,
    id,
    adStatus,
    user,
  } = value

  const [height, setHeight] = useState(dimension.featureBoat.height)
  const [width, setWidth] = useState(dimension.featureBoat.width)

  const zoomWidth = () => {
    const className = isWishlist ? '.dashboard-layout-responsive' : '.grid--w-100--map'
    const widthR = getRatio(dimension, 'showMapRatio', className)
    const heightR = getHeightRatio(dimension, 'showMapRatio', className)
    widthR && setWidth(widthR)
    heightR && setHeight(heightR)
  }

  useEffect(() => {
    zoomWidth()
    window.addEventListener('resize', zoomWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = index => {
    setSelectedIndex(selectedIndex !== index ? index : null)
  }

  return (
    <div
      className="service--boat--card--show--gird cursor-pointer"
      onMouseEnter={() => props.onBoatHover(value)}
      onMouseLeave={props.onBoatHover}
    >
      <div
        className="service--boat--card--show--img"
        style={{ width: `${width}px` }}
        onMouseLeave={() => {
          setIsArrow(false)
        }}
        onMouseOver={() => {
          setIsArrow(true)
        }}
      >
        {isWishlist ? (
          <SectionWithShowMapStyleBoat
            width={width}
            height={height}
            img={(images && images.length && encodeURI(images[0]?.url)) || defaultImage}
            onClick={() => (adStatus ? onClick() : WarnNotify(boatVerifyMessage))}
          />
        ) : (
          <>
            {images && images.length > 0 && (
              <ImageGalleryComponent
                width={width}
                height={height}
                onClick={onClick}
                isArrow={isArrow}
                sliceTo={sliceTo}
                images={images}
              />
            )}
          </>
        )}

        <div className="card-action service--with--map--card--action">
          <div className="opacity--0"></div>
          <div className="share-icon">
            <WishlistComponent moduleId={id} moduleType={wishlistModuleEnum.YACHT_SERVICE} />
            {/* <SharePopup
              handleClick={() => handleClick(index)}
              index={index}
              selectedIndex={selectedIndex}
              moduleId={id}
              moduleType={shareModule.YACHT_SERVICE}
              sharedUrl={viewServiceBoatHandler(value, true, true)}
            /> */}
          </div>
        </div>
      </div>

      <MapServiceGridStyleGrid
        width={width}
        className={`boat--main-with-btn d-flex ${isWishlist ? 'position-relative cursor-default' : ''}`}
      >
        <div className="service--boat--card--details--main">
          <div
            className={isWishlist ? 'marina--wishlist-calc' : 'w-100'}
            onClick={() => {
              !isWishlist && onClick()
            }}
          >
            <div className="service--map--card--salesman--name header-user-role dark-silver header--user--role--div py-1">By <a href={redirectToUserProfile(user, null, null, true)} target="_blank" onClick={e => e.stopPropagation()}>{user?.companyName}</a></div>

            <div className="service--map--card--city">{cityCountryNameFormatter(city, country || serviceCountry)}</div>

            <h5 className="service--boat--card--desc">{textTruncate(service || serviceDesc, 80)}</h5>

            <div className="d-flex align-center flex-none">
              <div className="mr-1">
                <RatingComponent rating={rating} className="rating-clr" />
              </div>
              {/* <span className="rating-count">({percentageFormate(rating)})</span> */}
            </div>
          </div>
        </div>

        {isWishlist && (
          <div className="manage-boat-card-button service--manage--boat--card--btn">
            <WishListButton viewOnclick={onClick} removeOnclick={removeFromList} />
          </div>
        )}
      </MapServiceGridStyleGrid>
    </div>
  )
}

BoatServiceMapCard.defaultProps = {
  isWishlist: false,
  onBoatHover: () => null,
  sliceTo: 0,
}
