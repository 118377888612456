import * as schemas from '../../graphql/accountSchema'
import { createSlice } from '../helper'

const accountSlice = createSlice('account', {
  closeAccount: {
    type: 'mutation',
    schema: schemas.closeAccount,
  },
})

export default accountSlice
