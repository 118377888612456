import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getSalesEngineByShipper,
  shipperAcceptShipmentRequest,
  shipperDeclineShipmentRequest,
  addShipmentProposal,
  clearSalesEngineShipperDashboardFlags,
  startShipment,
  completeShipment,
  addShipperDocuments,
  clearShipperDocument,
  getAgreementContents,
  userVisitSalesEngineDashboard,
} from '../../../redux/actions/salesEngineAction'

import { PaginationBar, ShipmentProposal, Loader } from '../../../components'
import { ShipmentDocument } from '../../../components/salesEngine/ShipmentDocument'
import { SuccessNotify } from '../../../helpers/notification'
import { pagination, tableText } from '../../../util/enums/enums'
import { SalesEngineDashboardCardListing } from '../../../components/salesEngine/SalesEngineDashboardCardListing'
import { getDocumentUrl } from '../../../redux/actions'
import { getPagesValue } from '../../../helpers/jsxHelper'
import { CommonSalesEnginTitle } from '../../../components/salesEngine/CommonSalesEnginTitle'
import { DashboardSearchSection } from '../../../components/salesEngine/DashboardSearchSection'
import { SkeletonLoader } from '../../../components/loader/SkeletonLoader'
import { CommonModal } from '../../../components/modal/commonModal'
import { ButtonComponent } from '../../../components/form/Button'
import { clear_getStaticPages, getStaticPages } from '../../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'

class ShipperSalesEngine extends Component {
  constructor(props) {
    super(props)
    this.state = {
      proposalModal: false,
      documentModal: false,
      locationModal: false,
      salesEngineModal: false,
      selectedSalesEngineId: '',
      shipmentLocation: null,
      shipperReport: null,
      searchTerm: '',
      helpModalShow: false,
    }
  }

  componentDidMount() {
    const { getSalesEngineByShipper, getStaticPages } = this.props
    getSalesEngineByShipper({
      page: getPagesValue(),
      limit: pagination.SHIPPER_PAGE_LIMIT,
    })
    getStaticPages({ slug: 'do-you-need-help-shipper-767' })
  }

  componentDidUpdate() {
    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      shipperAcceptShipperRequestSuccess,
      getSalesEngineByShipper,
      clearSalesEngineShipperDashboardFlags,
      shipperDocumentSuccess,
      clearShipperDocument,
      startShipmentSuccess,
      completeShipmentSuccess,
      addShipmentProposalSuccess,
    } = nextProps

    if (startShipmentSuccess || completeShipmentSuccess || addShipmentProposalSuccess) {
      getSalesEngineByShipper()
      clearSalesEngineShipperDashboardFlags()
    }

    if (shipperAcceptShipperRequestSuccess) {
      getSalesEngineByShipper()
      SuccessNotify('Request accepted successfully')
      clearSalesEngineShipperDashboardFlags()
    }

    if (shipperDocumentSuccess) {
      SuccessNotify('Document uploaded successfully.')
      clearShipperDocument()
      getSalesEngineByShipper()
    }

    return null
  }

  helpModalHandler = () => this.setState(prevState => ({ helpModalShow: !prevState.helpModalShow }))

  modalHandler = (key, selectedSalesEngineId = '') => {
    const { salesEngines, currentUser } = this.props
    if (selectedSalesEngineId) {
      const selectedSalesEngine = selectedSalesEngineId && salesEngines?.find(item => item?.id === selectedSalesEngineId)
      const shipperReport = selectedSalesEngine?.shipperRequested?.length
        ? selectedSalesEngine?.shipperRequested.find(item => item?.shipper?.id === currentUser?.id)
        : null
      this.setState(prevState => ({ [key]: !prevState[key], selectedSalesEngineId, shipperReport }))
    } else {
      this.setState(prevState => ({ [key]: !prevState[key] }))
      this.setState({ selectedSalesEngineId: '', shipperReport: null })
    }
  }

  submitProposalHandler = async values => {
    const { selectedSalesEngineId } = this.state
    const { addShipmentProposal } = this.props
    await addShipmentProposal({ ...values, id: selectedSalesEngineId })
    this.modalHandler('proposalModal')
  }

  submitDocumentHandler = values => {
    const { currentUser, addShipperDocuments } = this.props
    const { selectedSalesEngineId } = this.state

    values.shipper = currentUser?.id
    values.salesEngine = selectedSalesEngineId
    addShipperDocuments(values)
    this.modalHandler('documentModal')
  }

  getShipmentProposalValue = (selectedSalesEngineId = '') => {
    const { salesEngines, currentUser } = this.props

    const requestedShipper =
      selectedSalesEngineId &&
      salesEngines
        .find(item => item?.id === selectedSalesEngineId)
        .shipperRequested.find(user => user.shipper?.id === currentUser?.id)

    if (requestedShipper) {
      const { shipper, ...proposalValue } = requestedShipper

      return proposalValue && proposalValue.price ? proposalValue : null
    }
    return null
  }

  setSearchValue = value => {
    this.setState({ searchTerm: value })
  }

  render() {
    const { proposalModal, documentModal, selectedSalesEngineId, shipperReport, searchTerm, helpModalShow } = this.state
    const {
      salesEngines,
      history,
      shipperAcceptShipmentRequest,
      shipperDeclineShipmentRequest,
      shipperTotal,
      getSalesEngineByShipper,
      currentUser,
      completeShipment,
      startShipment,
      isLoading,
      getDocumentUrl,
      location,
    } = this.props

    return (
      <>
        <ShipmentProposal
          history={history}
          open={proposalModal}
          selectedSalesEngineId={selectedSalesEngineId}
          value={() => this.getShipmentProposalValue(selectedSalesEngineId)}
          closeModal={() => this.modalHandler('proposalModal')}
          onSubmit={this.submitProposalHandler}
          getDocumentUrl={getDocumentUrl}
          shipperReport={shipperReport}
        />
        <ShipmentDocument
          history={history}
          open={documentModal}
          value={null}
          closeModal={() => this.modalHandler('documentModal')}
          submitDocument={this.submitDocumentHandler}
          getDocumentUrl={getDocumentUrl}
        />
        <div className="agent--main--title--section pb-0 d-flex">
          {/* <CommonSalesEnginTitle title={'My Requests'} /> */}
          {salesEngines && salesEngines.length > 0 && (
            <div className="total--request--div pt-0">
              <p className="total--request">{`${shipperTotal} Requests`}</p>
            </div>
          )}
          <DashboardSearchSection action={getSalesEngineByShipper} setSearchValue={this.setSearchValue} />
          <div className="middle--content-table">
            <div className="do-you-help-content" onClick={this.helpModalHandler}>
              <span className="cursor-pointer">{tableText.needText}</span>
            </div>
          </div>

          <CommonModal
            className="sales-engine-header-help-dialog dashboard--table--help--section w-90"
            open={helpModalShow}
            close={this.helpModalHandler}
            title="Help"
          >
            <p
              className="mb-3 dashboard--table--help--desc"
              dangerouslySetInnerHTML={{ __html: get(this.props.staticPages, 'data[0].pageContent', '') || '' }}
            />

            <ButtonComponent
              className="m-auto h-auto as--cancel--outline btn--padding btn--font "
              onClick={this.helpModalHandler}
            >
              Close
            </ButtonComponent>
          </CommonModal>
        </div>
        {salesEngines?.length ? (
          <SalesEngineDashboardCardListing
            salesEngines={salesEngines}
            history={history}
            accept={shipperAcceptShipmentRequest}
            decline={shipperDeclineShipmentRequest}
            openModal={this.modalHandler}
            startShipment={startShipment}
            completeShipment={completeShipment}
            isShipper
            currentUserId={currentUser.id}
            isLoading={isLoading}
            location={history.location}
          />
        ) : (
          !isLoading && <div className="no--record-found">No record Found.</div>
        )}

        {!isLoading && salesEngines.length > 0 && shipperTotal > pagination.SHIPPER_PAGE_LIMIT && (
          <div className="mt-3">
            <PaginationBar
              action={getSalesEngineByShipper}
              totalRecords={shipperTotal}
              pageLimit={pagination.SHIPPER_PAGE_LIMIT}
              searchTerm={searchTerm}
            />
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser,
  salesEngines: state.salesEngineReducer.shipperSalesEngines,
  isLoading: state.salesEngineReducer.isLoading,
  shipperTotal: state.salesEngineReducer.shipperTotal,
  shipperAcceptShipperRequestSuccess: state.salesEngineReducer.shipperAcceptShipperRequestSuccess,
  shipperDeclineShipperRequestSuccess: state.salesEngineReducer.shipperDeclineShipperRequestSuccess,
  shipperDocumentSuccess: state.salesEngineReducer.shipperDocumentSuccess,
  agreementsContents: state.salesEngineReducer.agreementsContents,
  startShipmentSuccess: state.salesEngineReducer.startShipmentSuccess,
  completeShipmentSuccess: state.salesEngineReducer.completeShipmentSuccess,
  addShipmentProposalSuccess: state.salesEngineReducer?.addShipmentProposalSuccess,
  staticPages: state.pageInfoByTypeReducer.staticPages,
})

const mapDispatchToProps = dispatch => ({
  getSalesEngineByShipper: (data, isSearchAction) => dispatch(getSalesEngineByShipper(data, isSearchAction)),
  shipperAcceptShipmentRequest: data => dispatch(shipperAcceptShipmentRequest(data)),
  shipperDeclineShipmentRequest: data => dispatch(shipperDeclineShipmentRequest(data)),
  addShipmentProposal: data => dispatch(addShipmentProposal(data)),
  clearSalesEngineShipperDashboardFlags: () => dispatch(clearSalesEngineShipperDashboardFlags()),
  startShipment: data => dispatch(startShipment(data)),
  completeShipment: data => dispatch(completeShipment(data)),
  addShipperDocuments: data => dispatch(addShipperDocuments(data)),
  clearShipperDocument: () => dispatch(clearShipperDocument()),
  getAgreementContents: data => dispatch(getAgreementContents(data)),
  getDocumentUrl: data => dispatch(getDocumentUrl(data)),
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShipperSalesEngine)
