import { parsePhoneNumberFromString } from 'libphonenumber-js'

// const validator = require('validator');
// return validator.isMobilePhone(value)

export const isValidMobileNumber = (value = '', validation = true) => {
  const mobileValue = value.includes('+') ? value : `+${value}`

  let phoneNumber = parsePhoneNumberFromString(mobileValue)

  if (validation) {
    // return phoneNumber ? phoneNumber?.nationalNumber && phoneNumber.nationalNumber.length === 10 : false
    return phoneNumber && phoneNumber.countryCallingCode && phoneNumber.nationalNumber
  } else {
    return phoneNumber?.number
  }
}

export const mobileNumberFormat = (value = '') => {
  return value.toString().trim().replace(' ', '')
}

export const prettifyPhoneNumber = (phoneNumber = '') => {
  if (!phoneNumber) return null

  const parsed = parsePhoneNumberFromString(phoneNumber.replace(/^\+?/, '+'))

  if (!parsed) return phoneNumber

  const code = '+' + parsed.countryCallingCode
  const idx = parseInt(parsed.nationalNumber.length / 2)
  const left = parsed.nationalNumber.slice(0, idx)
  const right = parsed.nationalNumber.slice(idx)

  return code + ' ' + left + ' ' + right
}
