import React, { useState, useEffect } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import withAuth from '../../hoc/withAuth'
import { CommonModal } from './commonModal'
import lightClose from '../../assets/images/boatInner/light-close.png'
import checkMark from '../../assets/images/header/accept.svg'

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { Field } from '../ws/Field'
import { AddFlagReport } from '../../redux/actions/dashboardAction'
import { clearErrorMessageShow } from '../../redux/actions'
import { connect } from 'react-redux'
import ErrorFocus from '../ws/ErrorFocus'
import { requireMessage } from '../../helpers/string'
import { ButtonComponent } from '../form/Button'
import ErrorComponent from '../error/errorComponent'
import { flagReportModuleTypeEnum, wishlistModuleEnum } from '../../util/enums/enums'

const renderList = (type = 'ad') => [
  `This ${type} looks like a scam or is selling a prohibited item`,
  `This is a duplicate ${type} or looks like spam`,
  `This ${type} is no longer relevant`,
  `This ${type} is miscategorized`,
  `Other`,
]

const ReportModal = props => {
  const { open, closeModal, moduleId, moduleType, flagReportSuccess, isError, errorMessage, report } = props

  const [reportType, setReportType] = useState()
  const [onSuccess, setSuccess] = useState(report?.id === moduleId)

  const [initValue] = useState({
    reportType: '',
    message: '',
  })

  useEffect(() => {
    const type = moduleType === flagReportModuleTypeEnum.review ? 'review' : 'ad'
    setReportType(type)
  }, [moduleType])

  const formSubmitHandler = values => {
    const { AddFlagReport } = props
    AddFlagReport({ ...values, moduleId, moduleType })
  }

  useEffect(() => {
    if (flagReportSuccess || report?.id) {
      setSuccess(true)
    }
  }, [flagReportSuccess, report])

  const closeReportModal = () => {
    props.clearErrorMessageShow()
    closeModal()
  }

  return (
    <CommonModal open={open} close={closeReportModal} className="inner--flag--report--modal" title={`Report ${reportType}`}>
      <div className="report-box">
        {/* <div className="mb-10 cursor-pointer w-25 flag--report--modal--close--icon" onClick={closeReportModal}>
          <img loading="lazy" src={lightClose} alt="" />
        </div> */}
        {onSuccess ? (
          <div>
            <div className="inner--flag--report--modal--done--img">
              <img loading="lazy" src={checkMark} className="h-100 w-100" tintColor="green" alt="checkmark" />
            </div>
            <h1 className="report-title">We got your report</h1>
            <p className="report--list--text">
              Thanks for taking the time to let us know what’s going on. Reports like yours help keep the AdamSea community safe
              and secure.
            </p>
            <div className="text-right mt-4 mb-0">
              <ButtonComponent
                className=" h-auto as--success--outline btn--padding btn--font ml-auto"
                onClick={() => {
                  closeReportModal()
                }}
              >
                OK
              </ButtonComponent>
            </div>
          </div>
        ) : (
          <>
            <Formik
              initialValues={initValue}
              onSubmit={values => formSubmitHandler(values)}
              validationSchema={Yup.object().shape({
                reportType: Yup.string().required('Please select any one of above.'),
              })}
              render={({ values, setFieldValue, handleSubmit }) => (
                <Form className="overflow-hidden">
                  <ErrorFocus />
                  <h1 className="report-title">Why is this {reportType} being reported?</h1>

                  <RadioGroup
                    name="reportType"
                    value={values.reportType}
                    onChange={e => setFieldValue('reportType', e.target.value)}
                  >
                    {renderList(reportType).map(sentence => (
                      <div className="d-flex justify-content-center inner--flag--report--modal--info" key={sentence}>
                        <p className="report-list-text">{sentence}</p>
                        <FormControlLabel value={sentence} control={<Radio color="primary" />} />
                      </div>
                    ))}
                  </RadioGroup>
                  <div style={{ height: 0 }}>
                    <ErrorMessage component="div" name="reportType" className="error-message m-0 pt-1" />
                  </div>
                  <div className="mt-3 report--desc--div">
                    <p className="report-desc">Tell us more</p>
                  </div>
                  <Field
                    name="message"
                    id="message"
                    type="textarea"
                    value={values.message}
                    onChangeText={e => setFieldValue('message', e.target.value)}
                  />

                  <div className="text-right mt-4 mb-0">
                    <ButtonComponent
                      className=" h-auto as--success--outline btn--padding btn--font ml-auto"
                      onClick={handleSubmit}
                    >
                      Report about this {reportType}
                    </ButtonComponent>
                  </div>
                  <div className="flag--error">{isError && <ErrorComponent type="alert-info" errors={errorMessage} />}</div>
                </Form>
              )}
            />
          </>
        )}
      </div>
    </CommonModal>
  )
}

const mapStateToProps = state => ({
  flagReportSuccess: state.dashboardReducer.flagReportSuccess,
  flagReportError: state.dashboardReducer.flagReportError,
  isError: state.dashboardReducer.isError,
  errorMessage: state.errorReducer.errorMessage,
  report: state.reportReducer?.report,
})

const mapDispatchToProps = dispatch => ({
  AddFlagReport: data => dispatch(AddFlagReport(data)),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(ReportModal))
