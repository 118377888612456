import {
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  CREATE_PAYMENT_METHOD,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAILURE,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAILURE,
  CREATE_PAYMENT,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILURE,
} from '../actionTypes'

const InitialState = {
  isError: false,

  getPaymentMethodSuccess: false,
  getPaymentMethodError: false,
  paymentMethods: [],

  createPaymentMethodSuccess: false,
  createPaymentMethodError: false,

  removePaymentMethodSuccess: false,
  removePaymentMethodError: false,

  paymentSuccess: false,
  paymentError: false,
}

export const paymentReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_METHODS:
      return {
        ...state,
        getPaymentMethodSuccess: false,
        getPaymentMethodError: false,
        paymentMethods: [],
      }

    case GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        getPaymentMethodSuccess: true,
        paymentMethods: action.payload,
      }

    case GET_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        getPaymentMethodError: true,
      }

    case CREATE_PAYMENT_METHOD:
      return {
        ...state,
        createPaymentMethodSuccess: false,
        createPaymentMethodError: false,
      }

    case CREATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        createPaymentMethodSuccess: true,
      }

    case CREATE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        createPaymentMethodError: true,
        isError: true,
      }

    case DELETE_PAYMENT_METHOD:
      return {
        ...state,
        removePaymentMethodSuccess: false,
        removePaymentMethodError: false,
      }

    case DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        removePaymentMethodSuccess: true,
      }

    case DELETE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        removePaymentMethodError: true,
        isError: true,
      }

    case CREATE_PAYMENT:
      return {
        ...state,
        paymentSuccess: false,
        paymentError: false,
      }

    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentSuccess: true,
      }

    case CREATE_PAYMENT_FAILURE:
      return {
        ...state,
        paymentError: true,
        isError: true,
      }

    default:
      return state
  }
}
