import React, { PureComponent } from 'react'

import { ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'

import { ButtonComponent } from '../form/Button'
import { requireMessage } from '../../helpers/string'
import { CommonModal } from '../modal/commonModal'
import { Field } from '../ws/Field'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { stringValidation } from '../../helpers/yupHelper'
import ErrorFocus from '../ws/ErrorFocus'
import { connect } from 'react-redux'
import { getAllPageInfoByType } from '../../redux/actions/pageInfoByTypeAction'
import { footerLinks } from '../../util/enums/enums'
import withStripe from '../../hoc/withStripe'
import { CARD_ELEMENT_OPTIONS } from '../../util/enums/stripeEnum'
import { ErrorNotify } from '../../helpers/notification'

class AddDepositModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      depositeLoading: false,
    }
  }

  termsHandler = e => {
    e.preventDefault()
    const { getAllPageInfoByType } = this.props
    getAllPageInfoByType({ title: footerLinks.Terms_and_Conditions, isLargeModal: true })
  }

  handleChange = (value, setFieldValue, setFieldError, setFieldTouched) => {
    const { elementType, complete, empty, error } = value

    if (error) {
      setFieldError(elementType, error.message)
      setFieldTouched(elementType, true, false)
    } else if (complete) {
      setFieldError(elementType, '')
    }

    setFieldValue(elementType, !empty || '', false)
  }

  render() {
    const initValues = {
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      nameOfHolder: '',
      agreementCheck: '',
    }

    const { open, close, clearErrorMessageShow, boatAuctionRoom, addDepositForBid, stripe, paymentProcessing } = this.props
    const { depositeLoading } = this.state

    return (
      <>
        <CommonModal
          className="add--deposite--modal"
          modalClassName="add--deposit--main--modal"
          titleIcon
          open={open}
          close={close}
          title="ADD Auction Deposit"
        >
          <div>
            <div className="auction--deposit--top--div">
              <div className="d-flex auction--deposit--price--div">
                <div className="boat--price--deposit--title--div">
                  <div className="boat--price--deposit--title">
                    <span>{'Boat Price'}</span>
                  </div>
                  <div className="boat--price--deposit--amount">
                    <CurrencyContextConsumer>
                      {({ currentCurrency }) => (
                        <span className="boat--card--show-map--price">
                          {getConvertedPrice(boatAuctionRoom?.startPrice, currentCurrency)}
                        </span>
                      )}
                    </CurrencyContextConsumer>
                  </div>
                </div>
                <div className="boat--price--deposit--title--div">
                  <div className="boat--price--deposit--title">
                    <span>{boatAuctionRoom.depositAdded ? 'Deposited Amount' : 'Deposit Amount'}</span>
                  </div>
                  <div className="boat--price--deposit--amount">
                    <CurrencyContextConsumer>
                      {({ currentCurrency }) => (
                        <span className="boat--card--show-map--price">
                          {getConvertedPrice(boatAuctionRoom?.depositAmount, currentCurrency)}
                        </span>
                      )}
                    </CurrencyContextConsumer>
                  </div>
                </div>
              </div>
              <div className="auction--deposit--desc--div">
                <p>
                  In order to join the bid, AdamSea will hold 2.5% of the total boat’s value including the transfer fee, in case
                  you lose the bid then the full deducted amount will be returned back to your account but in case you win the
                  bid then the Deposited amount will be adjusted on the boat price during the boat payment.
                </p>
              </div>
            </div>
            <div>
              <Formik
                initialValues={initValues}
                onSubmit={async values => {
                  clearErrorMessageShow()
                  let isSubmit
                  if (!isSubmit) {
                    isSubmit = true
                    this.setState({ depositeLoading: true })
                    const { stripe, elements, addDepositForBid, boatAuctionRoom, paymentProcessing } = this.props
                    if (paymentProcessing) {
                      return false
                    }
                    const { error, paymentMethod } = await stripe.createPaymentMethod({
                      type: 'card',
                      card: elements.getElement(CardNumberElement),
                      billing_details: { name: values.nameOfHolder },
                    })

                    if (error?.message) {
                      ErrorNotify(error.message)
                      this.setState({ depositeLoading: false })
                      return
                    }

                    if (!error) {
                      addDepositForBid({
                        srcToken: paymentMethod.id,
                        nameOfHolder: values.nameOfHolder,
                        auctionId: boatAuctionRoom?.id,
                      })
                      this.setState({ depositeLoading: false })
                    }
                    isSubmit = false
                  }
                }}
                validationSchema={Yup.object().shape({
                  cardNumber: stringValidation.required(requireMessage('card number')),
                  cardExpiry: stringValidation.required(requireMessage('card expiry')),
                  cardCvc: stringValidation.required(requireMessage('card cvc')),
                  nameOfHolder: stringValidation.required(requireMessage("Card holder's name")),
                  agreementCheck: Yup.mixed()
                    .oneOf(['true', true], 'Please check agreement.')
                    .required('Please check agreement.'),
                })}
                render={({ setFieldValue, setFieldError, setFieldTouched, handleSubmit, values, errors }) => (
                  <Form onSubmit={handleSubmit}>
                    <ErrorFocus />

                    {!boatAuctionRoom.depositAdded && (
                      <>
                        <div className="auction--deposit--input--section">
                          <label className="auction--deposit--input--title">Enter Amount</label>
                          <CurrencyContextConsumer>
                            {({ currentCurrency }) => (
                              <Field
                                type="text"
                                className="form-control auction--deposit--amount"
                                placeholder="Shipment Country"
                                value={getConvertedPrice(boatAuctionRoom?.depositAmount, currentCurrency)}
                                disabled
                              />
                            )}
                          </CurrencyContextConsumer>
                        </div>

                        <div className="card--num--div auction--deposit--input--section">
                          <label className="auction--deposit--input--title">Enter Card Number</label>
                          <CardNumberElement
                            className="form-control auction--stripe--input"
                            name="cardNumber"
                            onBlur={value => this.handleChange(value, setFieldValue, setFieldError, setFieldTouched)}
                            onChange={value => this.handleChange(value, setFieldValue, setFieldError, setFieldTouched)}
                            {...CARD_ELEMENT_OPTIONS}
                          />
                          <ErrorMessage component="div" name="cardNumber" className="error-message" />
                        </div>
                        <div className="d-flex cvv--exp--section">
                          <div className="exp--date--div auction--deposit--input--section">
                            <label className="auction--deposit--input--title">Exp. Date</label>
                            <CardExpiryElement
                              className="form-control auction--stripe--input"
                              onBlur={value => this.handleChange(value, setFieldValue, setFieldError, setFieldTouched)}
                              onChange={value => this.handleChange(value, setFieldValue, setFieldError, setFieldTouched)}
                              {...CARD_ELEMENT_OPTIONS}
                            />
                            <ErrorMessage component="div" name="cardExpiry" className="error-message" />
                          </div>
                          <div className="cvv--div auction--deposit--input--section">
                            <label className="auction--deposit--input--title">CVC</label>
                            <CardCvcElement
                              className="form-control auction--stripe--input"
                              onBlur={value => this.handleChange(value, setFieldValue, setFieldError, setFieldTouched)}
                              onChange={value => this.handleChange(value, setFieldValue, setFieldError, setFieldTouched)}
                              {...CARD_ELEMENT_OPTIONS}
                            />
                            <ErrorMessage component="div" name="cardCvc" className="error-message" />
                          </div>
                        </div>
                        <div className="card--holder--div auction--deposit--input--section">
                          <label className="auction--deposit--input--title">Name of the Card Holder</label>
                          <Field
                            type="text"
                            name="nameOfHolder"
                            value={values.nameOfHolder}
                            className="form-control"
                            placeholder="Card Holder Name"
                            onChangeText={e => setFieldValue('nameOfHolder', e.target.value)}
                          />
                          <ErrorMessage component="div" name="nameOfHolder" className="error-message" />
                        </div>
                      </>
                    )}
                    {boatAuctionRoom.depositAdded && (
                      <div className="deposit--payment--completed--div">
                        <p>Deposit payment completed</p>
                      </div>
                    )}
                    {/* end */}
                    <div className="deposit--terms--submit--section">
                      {!boatAuctionRoom.depositAdded && (
                        <div className="deposit--terms--div">
                          <Field
                            type="checkbox"
                            name="agreementCheck"
                            checked={values.agreementCheck}
                            label={
                              <span className="cursor-default">
                                Accept AdamSea Auction{' '}
                                <span className="deposit--terms--link cursor-pointer" onClick={this.termsHandler}>
                                  Terms and Conditions
                                </span>
                              </span>
                            }
                            onChangeText={() => setFieldValue('agreementCheck', !values.agreementCheck)}
                          />
                          <ErrorMessage component="div" name="agreementCheck" className="error-message" />
                        </div>
                      )}

                      <div className={`${boatAuctionRoom.depositAdded ? 'w-100' : ''} deposit--submit--div`}>
                        {boatAuctionRoom.depositAdded ? (
                          <ButtonComponent
                            className="h-auto as--view--outline btn--padding btn--font auction--bid--now--button"
                            onClick={close}
                          >
                            {'CLOSE'}
                          </ButtonComponent>
                        ) : (
                          <ButtonComponent
                            className="h-auto as--view--outline btn--padding btn--font auction--bid--now--button"
                            type="submit"
                            disabled={depositeLoading || !stripe || paymentProcessing}
                            loader={depositeLoading || paymentProcessing}
                          >
                            {'ADD AMOUNT'}
                          </ButtonComponent>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </CommonModal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  paymentProcessing: state.boatReducer?.isDepositePaymentLoading,
})

const mapDispatchToProps = dispatch => ({
  getAllPageInfoByType: data => dispatch(getAllPageInfoByType(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStripe(AddDepositModal))
