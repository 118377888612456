import React, { Component } from 'react'

import { getLocalStorageItem } from '../helpers/storageHelper'
import { connect } from 'react-redux'
import { adminLoginAsUser, clearAdminLoginFlag } from '../redux/actions'
import { Loader } from '../components'

export default function (ComposedComponent) {
  class CheckAdminAccess extends Component {
    state = {
      adminLoginDetected: false,
    }

    componentDidMount() {
      const { location, adminLoginAsUser } = this.props

      if (location && location.search) {
        const urlParams = new URLSearchParams(location.search)
        if (urlParams && urlParams.has('autoLoginToken')) {
          const autoLoginToken = urlParams.get('autoLoginToken')
          adminLoginAsUser(autoLoginToken)
          this.setState({ adminLoginDetected: true })
        }
      }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const { adminLoginAsUserSuccess, history, clearAdminLoginFlag, location } = nextProps

      if (adminLoginAsUserSuccess) {
        clearAdminLoginFlag()
        history.replace(location.pathname)
      }

      return null
    }

    render() {
      const { location, adminLoginAsUserSuccess } = this.props
      const urlParams = new URLSearchParams(location.search)
      const adminLoginDetected = urlParams && urlParams.has('autoLoginToken')

      return adminLoginDetected ? (
        adminLoginAsUserSuccess ? (
          <ComposedComponent {...this.props} />
        ) : (
          <Loader isPageLoader />
        )
      ) : (
        <ComposedComponent {...this.props} />
      )
      // <> </>
    }
  }

  const mapStateToProps = state => ({
    adminLoginAsUserSuccess: state.loginReducer.adminLoginAsUserSuccess,
  })

  const mapDispatchToProps = dispatch => ({
    adminLoginAsUser: data => dispatch(adminLoginAsUser(data)),
    clearAdminLoginFlag: () => dispatch(clearAdminLoginFlag()),
  })

  return connect(mapStateToProps, mapDispatchToProps)(CheckAdminAccess)
}
