import { boatType } from '../util/enums/enums'

export const getSubmitValues = (values, type, kind, update = false) => {
  let updateObject = {}

  if (update) {
    updateObject = {
      ...updateObject,
      id: values.id,
    }
  }

  const common = {
    ...updateObject,
    address: values.address,
    boatName: values.boatName,
    hullColor: values.hullColor,
    seats: values.seats,
    trailer: values.trailer,
    engineModel: values.engineModel,
    heightInFt: values.heightInFt,
    widthInFt: values.widthInFt,
    lengthInFt: values.lengthInFt,
    weightInKg: values.weightInKg,
    price: values.price,
    addMedia: values.addMedia,
    removeMedia: values.removeMedia,
    lastMaintenance: values.lastMaintenance,
    repairHistory: values.repairHistory,
    video: values.video,
    numberOfHeads: values.numberOfHeads,
    yearBuilt: values.yearBuilt,
    boatType: values.boatType,
    hullMaterial: values.hullMaterial,
    boatStatus: values.boatStatus,
    listedBy: values.listedBy,
    modelYear: values.modelYear,
    noOfEngines: values.noOfEngines,
    seaTrial: values.seaTrial,
    engineManufacturer: values.engineManufacturer,
    stockQuantity: values.stockQuantity,
    minPurchaseQuantity: values.minPurchaseQuantity,
    description: values.description,
    boatReview: values.boatReview,
    manufacturedBy: values.manufacturedBy || null,
    internationalWarranty: values.internationalWarranty,
    internationalWarrantyValidity: values.internationalWarrantyValidity,
    internationalWarrantyTerms: values.internationalWarrantyTerms,
  }
  const typeAlias = type?.label

  switch (typeAlias) {
    case boatType.jetSki:
      return {
        ...common,
        complianceLabelNumber: values.complianceLabelNumber,
        licenceNo: values.licenceNo,
        hullSerialNumber: values.hullSerialNumber,
        vehicleIdentificationNumber: values.vehicleIdentificationNumber,
        ownershipAttachment: values.ownershipAttachment,
        hullMaterial: values.hullMaterial,
        fuelCapacity: values.fuelCapacity,
        engineHp: values.engineHp,
        fuelType: values.fuelType,
        inStock: values.inStock,
        accidentDescribe: values.accidentDescribe,
        accidentHistory: values.accidentHistory,
        usage: values.usage,
        seats: values.seats,
        usedHours: values.usedHours,
      }

    case boatType.kayak:
      return {
        ...common,
        inStock: values.inStock,
        kayakType: values.kayakType ? values.kayakType : null,
        weightCapacity: values.weightCapacity,
        seats: values.seats,
      }

    case boatType.canoe:
      return {
        ...common,
        inStock: values.inStock,
        weightCapacity: values.weightCapacity,
        seats: values.seats,
      }

    default:
      return values
  }
}
