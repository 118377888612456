import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { IoIosClose, IoIosPin, IoIosSearch } from 'react-icons/io'
import { toast } from 'react-toastify'
import cn from 'classnames'
import { getId } from '../../util/utilFunctions'
import PlacesAutocomplete from '../../components/places-autocomplete'
import styles from './hero-search.module.scss'

const FIELD = {
  LOCATION: 1,
  TYPE: 2,
}

function HeroSearch({ inHeader = false, close }) {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const types = useSelector(state => state.marinaAndStorageReducer.marinaTypeWiseLookUps)
  const marinas = useSelector(state => state.marinaAndStorageReducer.moreMarinaService)

  const [activeField, setActiveField] = useState(null)
  const [locationSuggestions, setLocationSuggestions] = useState([])
  const [searchInputValues, setSearchInputValues] = useState({})
  const [fieldValues, setFieldValues] = useState({})

  const setSearchInputValue = useCallback((name, value) => {
    setSearchInputValues(prev => ({ ...prev, [name]: typeof value === 'function' ? value(prev[name]) : value }))
  }, [])

  const setFieldValue = useCallback((name, value) => {
    setFieldValues(prev => ({ ...prev, [name]: typeof value === 'function' ? value(prev[name]) : value }))
  }, [])

  const onPlaceSelected = useCallback(place => {
    setSearchInputValue(FIELD.LOCATION, place.description)
    setFieldValue(FIELD.LOCATION, place)
    setActiveField(FIELD.TYPE)
  }, [])

  const handleSearch = useCallback(() => {
    if (!fieldValues[FIELD.LOCATION]) {
      return toast.error('Please select a Location!')
    }

    const params = new URLSearchParams({
      country: fieldValues[FIELD.LOCATION]?.country || '',
      city: fieldValues[FIELD.LOCATION]?.city || '',
      address: fieldValues[FIELD.LOCATION]?.completeLabel || '',
      serviceProvide: fieldValues[FIELD.TYPE]?.id || '',
      serviceProvideAlias: fieldValues[FIELD.TYPE]?.name || '',
      provider: types?.[0]?.id,
      providerAlias: types?.[0]?.alias,
    })

    if (pathname === '/search-marina-storage') {
      history.push(`/search-marina-storage?${params.toString()}`)
    } else {
      window.open(`/search-marina-storage?${params.toString()}`)
    }
  }, [history, pathname, fieldValues])

  useEffect(() => {
    if (pathname === '/search-marina-storage' && marinas?.length) {
      const searchParams = new URLSearchParams(search)

      const country = searchParams.get('country')
      const city = searchParams.get('city')
      const address = searchParams.get('address')
      const marinaId = searchParams.get('serviceProvide')

      if (country || city) {
        setSearchInputValue(FIELD.LOCATION, address || [city, country].filter(Boolean).join(', '))
        setFieldValue(FIELD.LOCATION, { country, city })
      }

      if (marinaId) {
        const marina = marinas.find(s => getId(s) === marinaId)
        setFieldValue(FIELD.TYPE, marina)
      }
    }
  }, [pathname, search, marinas])

  return (
    <OutsideClickHandler onOutsideClick={() => setActiveField(null)}>
      <div className={cn(styles.heroSearch, inHeader && styles.heroSearch_inHeader)}>
        <div className={cn(styles.fieldsWrp, activeField && styles.fieldsWrpActive)}>
          <div className={cn(styles.fieldWrp, activeField === FIELD.LOCATION && styles.fieldActive)}>
            <div
              className={styles.fieldHead}
              onClick={() => {
                setActiveField(FIELD.LOCATION)
                document.querySelector(`.${styles.fieldHeadInp}_googleAutocomplete`)?.focus()
              }}
            >
              <div className={styles.fieldHeadContent}>
                <div className={styles.fieldHeadLabel}>
                  <div>Where</div>
                  <div className={styles.fieldResetBtnWrp}>
                    <button
                      className={styles.fieldResetBtn}
                      disabled={activeField !== FIELD.LOCATION || !fieldValues[FIELD.LOCATION]}
                      onClick={() => {
                        setSearchInputValue(FIELD.LOCATION, null)
                        setFieldValue(FIELD.LOCATION, null)
                      }}
                    >
                      <IoIosClose />
                    </button>
                  </div>
                </div>
                <div className={styles.fieldHeadSubtextWrp}>
                  <PlacesAutocomplete
                    query={searchInputValues[FIELD.LOCATION] || ''}
                    setQuery={query => setSearchInputValue(FIELD.LOCATION, query)}
                    setSuggestions={setLocationSuggestions}
                    placeholder="Marina Location"
                    className={cn(styles.fieldHeadInp, `${styles.fieldHeadInp}_googleAutocomplete`)}
                  />
                </div>
              </div>
            </div>

            {activeField === FIELD.LOCATION && locationSuggestions.length > 0 && (
              <div className={styles.fieldDdWrp}>
                <div className={styles.fieldDdContent}>
                  <ul className={styles.fieldLocList}>
                    {locationSuggestions.map(sugg => (
                      <li key={sugg.place_id} className={styles.fieldLocListItem} onClick={() => onPlaceSelected(sugg)}>
                        <span className={styles.fieldLocIcon}>
                          <IoIosPin />
                        </span>{' '}
                        <span className={styles.fieldLocText}>{sugg.description}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className={cn(styles.fieldWrp, activeField === FIELD.TYPE && styles.fieldActive)}>
            <div className={styles.fieldHead} onClick={() => setActiveField(FIELD.TYPE)}>
              <div className={styles.fieldHeadContent}>
                <div className={styles.fieldHeadLabel}>
                  <div>Service</div>
                  <div className={styles.fieldResetBtnWrp}>
                    <button
                      className={styles.fieldResetBtn}
                      disabled={activeField !== FIELD.TYPE || !fieldValues[FIELD.TYPE]}
                      onClick={() => setFieldValue(FIELD.TYPE, null)}
                    >
                      <IoIosClose />
                    </button>
                  </div>
                </div>
                <div className={styles.fieldHeadSubtextWrp}>
                  {!fieldValues[FIELD.TYPE] ? (
                    <div className={styles.fieldHeadSubtextPlaceholder}>Select Type</div>
                  ) : (
                    <div className={styles.fieldHeadSubtextValue}>{fieldValues[FIELD.TYPE].name}</div>
                  )}
                </div>
              </div>

              <div className={styles.fieldSubmitBtnWrp}>
                <button
                  className={styles.fieldSubmitBtn}
                  onClick={e => {
                    e.stopPropagation()
                    setActiveField(null)
                    handleSearch()
                    close?.()
                  }}
                >
                  <IoIosSearch />
                </button>
              </div>
            </div>

            {activeField === FIELD.TYPE && (
              <div className={styles.fieldDdWrp}>
                <div className={styles.fieldDdContent}>
                  <div className={styles.fieldTypeList}>
                    {marinas.map(t => (
                      <div
                        className={cn(
                          styles.fieldTypeListItem,
                          fieldValues[FIELD.TYPE]?.id === t.id && styles.fieldTypeListItemActive
                        )}
                      >
                        <button
                          className={styles.fieldTypeListItemBtn}
                          onClick={() => {
                            setFieldValue(FIELD.TYPE, t)
                            setActiveField(null)
                          }}
                        >
                          {t.name}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default HeroSearch
