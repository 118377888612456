import React from 'react'

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, PinterestShareButton, FacebookMessengerIcon } from 'react-share'
import ShareLink from 'react-google-plus-share-link'
import { FaFacebookMessenger } from "react-icons/fa";
import { CommonTooltip } from '../CommonTooltip'
import { getCurrentUrl } from '../../helpers/jsxHelper'
import { copyCodeToClipboard } from '../../util/utilFunctions'
import { envConfig } from '../../config'

export const ArticleShareIcon = ({ onClick }) => {
  return (
    <ul className="list-unstyled">
      <li onClick={onClick}>
        <CommonTooltip component={ctProps => (
          <img width={16} loading="lazy" src={require('../../assets/images/adamshare-grey.png')} alt="adamshare" data-tooltip-id={ctProps.id} data-tooltip-content="AdamShare" />
        )} />
      </li>
      <li>
        <CommonTooltip component={ctProps => (
          <FacebookShareButton url={getCurrentUrl()} quote="Upcoming Events" data-tooltip-id={ctProps.id} data-tooltip-content="Facebook">
            {/* <i  style={{fontSize:'28px', color: '#66828f'}} className="adam-facebook footer-icon-effect"></i> */}
           <img loading="lazy" src={require('../../assets/images/facebook-grey.png')} alt="facebook" />
          </FacebookShareButton>
        )} />
      </li>
      <li>
        <CommonTooltip component={ctProps => (
          <TwitterShareButton url={getCurrentUrl()} data-tooltip-id={ctProps.id} data-tooltip-content="Twitter">
            <img loading="lazy" src={require('../../assets/images/twitter-grey.png')} alt="twitter" />
            {/* <a width={16}>
              <svg className='small-screen-font-15 footer-icon-effect' viewBox="12 12.769 24 22.462">
                <path fill="#66828f" d=" M 12.058 12.769 L 21.325 25.158 L 12 35.231 L 14.099 35.231 L 22.263 26.412 L 28.858 35.231 L 36 35.231 L 26.212 22.145 L 34.892 12.769 L 32.793 12.769 L 25.275 20.891 L 19.2 12.769 L 12.058 12.769 Z  M 15.145 14.314 L 18.426 14.314 L 32.913 33.686 L 29.632 33.686 L 15.145 14.314 Z " />
              </svg> */}
            {/* <i className="adam-twitter-fill"></i> */}
            {/* </a> */}
          </TwitterShareButton>
        )} />
      </li>
      <li>
        <CommonTooltip component={ctProps => (
          <LinkedinShareButton url={getCurrentUrl()} data-tooltip-id={ctProps.id} data-tooltip-content="Linkedin">
            <img loading="lazy" src={require('../../assets/images/linkedin-grey.png')} alt="linkedin" />
            {/* <a style={{fontSize:'28px'}}>
              <i style={{color: '#66828f'}} className="adam-linkedin footer-icon-effect"></i>
            </a> */}

          </LinkedinShareButton>
        )} />
      </li>
      <li>
        <CommonTooltip component={ctProps => (
          <button
            className='btn'
            style={{ padding: 0, cursor: 'pointer' }}
            onClick={() => window.open(`https://www.facebook.com/dialog/send?app_id=${envConfig.RAZZLE_FACEBOOK_DEV_APPID}&link=${getCurrentUrl()}&redirect_uri=${getCurrentUrl()}`, 'Messenger', 'popup')}
            data-tooltip-id={ctProps.id}
            data-tooltip-content="Messenger"
          >
            <img loading="lazy" src={require('../../assets/images/messenger-grey.png')} alt="messenger" />
            {/* <FaFacebookMessenger color="#66828f" width={16}/> */}
          </button>
        )} />
      </li>
      <li className='mr-0' onClick={() => copyCodeToClipboard(getCurrentUrl())}>
        <CommonTooltip component={ctProps => (
          <img width={16} loading="lazy" src={require('../../assets/images/socialIcon/link.png')} alt="social icon" data-tooltip-id={ctProps.id} data-tooltip-content="Link" />
        )} />
      </li>
    </ul>
  )
}
