import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getMarinaAndStorageAllServices } from '../../redux/actions'
import BoatListingsWithMap from '../gridComponents/BoatListingsWithMap'

import './marinaStorage.scss'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import UserContext from '../../UserContext'

class MarinServicesView extends Component {
  static contextType = UserContext

  constructor(props, context) {
    super(props, context)
  }
  state = {
    serviceId: null,
    typeName: '',
  }

  static getDerivedStateFromProps(nextProps) {
    const { match } = nextProps
    const { params } = match && match

    if (params && params.hasOwnProperty('serviceId') && params.serviceId) {
      return {
        serviceId: params.serviceId,
        typeName: params.name,
      }
    }

    return null
  }

  render() {
    const { serviceId, typeName } = this.state
    const { marinaServiceList, marinaServiceListTotal, isPageLoader, getMarinaAndStorageAllServices } = this.props
    const { country } = this.context

    return (
      <>
        {serviceId && (
          <BoatListingsWithMap
            isPageLoader={isPageLoader}
            isMarinaStorage
            serviceId={serviceId}
            boatsTypeCount={marinaServiceListTotal || 0}
            boatsType={`Explore ${marinaServiceListTotal || 0} ${typeName} facilities listed in ${country}`}
            isShowAuction
            boats={marinaServiceList}
            action={getMarinaAndStorageAllServices}
            value={{ serviceId }}
            mapGridClassName="marina--service--show--all"
          />
        )}
      </>
    )
  }
}
const mapStateToProps = state => ({
  marinaServiceList: state.marinaAndStorageReducer && state.marinaAndStorageReducer.marinaServiceList,
  marinaServiceListTotal: state.marinaAndStorageReducer && state.marinaAndStorageReducer.marinaServiceListTotal,
  isPageLoader: state.marinaAndStorageReducer && state.marinaAndStorageReducer.isPageLoader,
})
const mapDispatchToProps = dispatch => ({
  getMarinaAndStorageAllServices: data => dispatch(getMarinaAndStorageAllServices(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarinServicesView)
