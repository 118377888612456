import { put, takeLatest, all } from 'redux-saga/effects'
import {
  SEND_CONFIRM_MAIL_LINK,
  SEND_CONFIRM_MAIL_LINK_SUCCESS,
  SEND_CONFIRM_MAIL_LINK_FAILURE,
  GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS,
  GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS_SUCCESS,
  GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS_FAILURE,
  GET_MODULE_WISE_BANNERS_SUCCESS,
  GET_MODULE_WISE_BANNERS,
  GET_MODULE_WISE_BANNERS_FAILURE,
  GET_DASHBOARD_COUNT,
  GET_DASHBOARD_COUNT_SUCCESS,
  GET_DASHBOARD_COUNT_FAILURE,
  GET_EXPERIENCES_SUCCESS,
  GET_EXPERIENCES_FAILURE,
  GET_EXPERIENCES,
  MODULE_PAGE_VISIT,
  MODULE_PAGE_VISIT_SUCCESS,
  MODULE_PAGE_VISIT_FAILURE,
  NOTIFY_ME,
  NOTIFY_ME_SUCCESS,
  NOTIFY_ME_FAILURE,
  ADD_LIKE,
  ADD_LIKE_SUCCESS,
  ADD_LIKE_FAILURE,
  GET_CURRENCY_RATES,
  GET_CURRENCY_RATES_SUCCESS,
  GET_CURRENCY_RATES_FAILURE,
  CREATE_WISHLIST,
  REMOVE_WISHLIST,
  GET_WISHLISTS,
  CREATE_WISHLIST_SUCCESS,
  CREATE_WISHLIST_FAILURE,
  REMOVE_WISHLIST_SUCCESS,
  REMOVE_WISHLIST_FAILURE,
  GET_WISHLISTS_SUCCESS,
  GET_WISHLISTS_FAILURE,
  CLEAR_WISHLIST_FLAG,
  CLEAR_NOTIFY_ME_FLAG,
  CONVERT_LIKES_FROM_SESSION_TO_USER,
  CONVERT_LIKES_FROM_SESSION_TO_USER_SUCCESS,
  CONVERT_LIKES_FROM_SESSION_TO_USER_FAILURE,
  CONVERT_WISHLISTS_FROM_SESSION_TO_USER,
  CONVERT_WISHLISTS_FROM_SESSION_TO_USER_SUCCESS,
  CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FAILURE,
  ERROR_MESSAGE_SHOW,
  CLEAR_CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FLAG,
  GLOBAL_SEARCH,
  GLOBAL_SEARCH_SUCCESS,
  GLOBAL_SEARCH_FAILURE,
  CLEAR_GLOBAL_SEARCH,
  ADD_FLAG_REPORT,
  ADD_FLAG_REPORT_SUCCESS,
  ADD_FLAG_REPORT_FAILURE,
  CLEAR_FLAG_REPORT,
  UPDATE_EMAIL_SUCCESS,
  CLEAR_EMAIL_FLAGS,
  GET_ALL_PAYMENT_METHODS,
  GET_ALL_PAYMENT_METHODS_SUCCESS,
  GET_ALL_PAYMENT_METHODS_FAILURE,
  GET_USER_REPORT_FOR_MODULE_SUCCESS,
  ERROR_NOTIFICATION_MESSAGE,
  INDEX_SEARCH,
  INDEX_SEARCH_FAILURE,
  INDEX_SEARCH_SUCCESS,
  GET_COUNTRY_DETAILS_SUCCESS,
  GET_COUNTRY_DETAILS_FAILURE,
  GET_COUNTRY_DETAILS,
} from '../actionTypes'
import { graphqlClient } from '../../helpers/graphqlClient'
import {
  sendEmailLink,
  getGlobalMinimumPriceBoats,
  getExperiences,
  pageVisit,
  getCurrencyRates,
  searchTermNotify,
  addLikes,
  createWishlist,
  removeWishList,
  getWishlistByUser,
  addUserOfWishListedBySession,
  addUserOfLikeBySession,
  globalSearch,
  createOrUpdateFlagreport,
  getAllPaymentMethodTypes,
  dashboardSearch,
  getCountryDetailsSchema,
} from '../../graphql/dashboardSchema'
import { getBannerByModuleQuery, getUserDashBoardCountQuery } from '../../graphql/boatSchema'
import { setLocalStorageItem, getLocalStorageItem } from '../../helpers/storageHelper'
import { query } from './apiHelper'
import { dynamicError } from '../../util/enums/notificationEnum'
import { get } from 'lodash'

function sendConfirmationMailApi(input) {
  return graphqlClient
    .mutate({
      mutation: sendEmailLink,
      variables: input,
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}

function getMinimumPriceBoatsApi(input) {
  return graphqlClient
    .query({
      query: getGlobalMinimumPriceBoats,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function getRentExperienceApi(moduleName) {
  return graphqlClient
    .query({
      query: getExperiences,
      variables: { moduleName },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function modulePageVisitApi(input) {
  return graphqlClient
    .mutate({
      mutation: pageVisit,
      variables: { input },
    })
    .then(res => res)
    .catch(error => error)
}

function likeApi(input) {
  return graphqlClient.mutate({
    mutation: addLikes,
    variables: { input },
  })
}

function currencyRatesApi() {
  return graphqlClient
    .query({
      query: getCurrencyRates,
    })
    .then(res => res)
    .catch(error => error)
}

function searchNotifyApi(input) {
  return graphqlClient
    .mutate({
      mutation: searchTermNotify,
      variables: { input },
    })
    .then(res => res)
    .catch(error => error)
}

function addWishlistApi(input) {
  return graphqlClient
    .mutate({
      mutation: createWishlist,
      variables: { input },
    })
    .then(res => res)
    .catch(error => error?.networkError?.result ?? error)
}

function deleteWishlistApi(input) {
  return graphqlClient
    .mutate({
      mutation: removeWishList,
      variables: { input },
    })
    .then(res => res)
    .catch(error => error)
}

function getWishlistsApi(input) {
  return graphqlClient
    .query({
      query: getWishlistByUser,
      variables: { sessionId: getLocalStorageItem('sessionId') },
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function convertLikesApi() {
  return graphqlClient
    .mutate({
      mutation: addUserOfLikeBySession,
      variables: { sessionId: getLocalStorageItem('sessionId') },
    })
    .then(res => res)
    .catch(error => error)
}

function convertWishlistsApi() {
  return graphqlClient
    .mutate({
      mutation: addUserOfWishListedBySession,
      variables: { sessionId: getLocalStorageItem('sessionId') },
    })
    .then(res => res)
    .catch(error => error)
}

function globalMainSearchApi(input) {
  return graphqlClient
    .query({
      query: globalSearch,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function createFlagReportApi(input) {
  return graphqlClient
    .mutate({
      mutation: createOrUpdateFlagreport,
      variables: { input },
    })
    .then(res => res)
    .catch(error => error.networkError.result)
}

function searchIndexApi(input) {
  const { searchType, ...data } = input
  return query(dashboardSearch, data)
}

function* getRentExperienceData(action) {
  try {
    const data = yield getRentExperienceApi(action.payload)
    yield put({
      type: GET_EXPERIENCES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    yield put({ type: GET_EXPERIENCES_FAILURE, error })
  }
}

function* sendConfirmationMail(action) {
  try {
    const res = yield sendConfirmationMailApi(action.payload)
    if (res.errors && res.errors.length) {
      yield put({ type: SEND_CONFIRM_MAIL_LINK_FAILURE, payload: res.errors })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    } else {
      yield put({ type: UPDATE_EMAIL_SUCCESS, payload: res.data.sendEmailLink, activationType: action.payload.activationType })
      yield put({
        type: SEND_CONFIRM_MAIL_LINK_SUCCESS,
        payload: res.data.sendEmailLink,
        activationType: action.payload.activationType,
      })
    }
  } catch (error) {
    yield put({ type: SEND_CONFIRM_MAIL_LINK_FAILURE, error })
    yield put({ type: ERROR_MESSAGE_SHOW, payload: get(error, 'networkError.result.errors[0].message', 'Cannot send OTP!') })
  }
  yield put({ type: CLEAR_EMAIL_FLAGS })
}

function* getMinimumPriceBoats(action) {
  try {
    const res = yield getMinimumPriceBoatsApi(action.payload)
    yield put({
      type: GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS_SUCCESS,
      payload: res.data.getGlobalMinimumPriceBoats,
      total: res.data.getGlobalMinimumPriceBoats.total,
    })
  } catch (error) {
    yield put({ type: GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS_FAILURE, error })
  }
}

function* modulePageVisit(action) {
  try {
    const res = yield modulePageVisitApi(action.payload)
    yield put({ type: MODULE_PAGE_VISIT_SUCCESS, payload: res.data.modulePageVisit })
  } catch (error) {
    yield put({ type: MODULE_PAGE_VISIT_FAILURE, error })
  }
}

function* like(action) {
  try {
    const res = yield likeApi(action.payload)
    if (res.errors?.length) {
      yield put({ type: ADD_LIKE_FAILURE, error: dynamicError(res.errors, 'Unable to like ad') })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors, 'Unable to like ad') })
    } else if (res.data) {
      yield put({ type: ADD_LIKE_SUCCESS, payload: res.data.addLikes, moduleType: action.payload.likeModule })
    }
  } catch (error) {
    yield put({ type: ADD_LIKE_FAILURE, error: get(error, 'networkError.result.errors[0].message', '') })
  }
}

function* currencyRates(action) {
  try {
    const res = yield currencyRatesApi(action.payload)
    if (res.data.getCurrencyRates) {
      setLocalStorageItem('currencyRates', JSON.stringify(res.data.getCurrencyRates.data))
      yield put({ type: GET_CURRENCY_RATES_SUCCESS, payload: res.data.getCurrencyRates })
    }
  } catch (error) {
    yield put({ type: GET_CURRENCY_RATES_FAILURE, error })
  }
}

function* searchNotify(action) {
  try {
    const res = yield searchNotifyApi(action.payload)
    yield put({ type: NOTIFY_ME_SUCCESS, payload: res.data.userSearchTermNotify })
  } catch (error) {
    yield put({ type: NOTIFY_ME_FAILURE, error })
  }
  yield put({ type: CLEAR_NOTIFY_ME_FLAG })
}

function* addWishlist(action) {
  try {
    const res = yield addWishlistApi(action.payload)
    if (res?.errors?.length) {
      yield put({ type: CREATE_WISHLIST_FAILURE, e: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    } else if (res.data) {
      yield put({ type: CREATE_WISHLIST_SUCCESS, payload: res.data.createWishlist })
    }
  } catch (error) {
    yield put({ type: CREATE_WISHLIST_FAILURE, error })
  }
  yield put({ type: CLEAR_WISHLIST_FLAG })
}

function* deleteWishlist(action) {
  try {
    const res = yield deleteWishlistApi(action.payload)
    yield put({ type: REMOVE_WISHLIST_SUCCESS, payload: res.data.removeWishList })
  } catch (error) {
    yield put({ type: REMOVE_WISHLIST_FAILURE, error })
  }
  yield put({ type: CLEAR_WISHLIST_FLAG })
}

function* getWishlists(action) {
  try {
    const res = yield getWishlistsApi(action.payload)
    yield put({ type: GET_WISHLISTS_SUCCESS, payload: res.data.getWishlistByUser })
  } catch (error) {
    yield put({ type: GET_WISHLISTS_FAILURE, error })
  }
}

function* convertLikes(action) {
  try {
    const res = yield convertLikesApi()
    yield put({ type: CONVERT_LIKES_FROM_SESSION_TO_USER_SUCCESS, payload: res.data.addUserOfLikeBySession })
  } catch (error) {
    yield put({ type: CONVERT_LIKES_FROM_SESSION_TO_USER_FAILURE, error })
  }
}

function* convertWishlists(action) {
  try {
    const res = yield convertWishlistsApi()
    if (res && res.data) {
      yield put({ type: CONVERT_WISHLISTS_FROM_SESSION_TO_USER_SUCCESS, payload: res?.data?.addUserOfWishListedBySession })
    } else {
      yield put({ type: CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FAILURE, payload: res?.errors })
    }
  } catch (error) {
    yield put({ type: CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FAILURE, error })
  }
  yield put({ type: CLEAR_CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FLAG })
}

function* globalMainSearch(action) {
  try {
    const res = yield globalMainSearchApi(action.payload)
    yield put({ type: GLOBAL_SEARCH_SUCCESS, payload: res.data.globalSearch, searchTerm: action.payload.query })
  } catch (error) {
    yield put({ type: GLOBAL_SEARCH_FAILURE, error })
  }
  yield put({ type: CLEAR_GLOBAL_SEARCH })
}

function* createFlagReport(action) {
  try {
    const res = yield createFlagReportApi(action.payload)
    if (res.errors && res.errors.length) {
      yield put({ type: ADD_FLAG_REPORT_FAILURE, res })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    } else if (res && res.data) {
      yield put({ type: ADD_FLAG_REPORT_SUCCESS, payload: res.data.createOrUpdateFlagreport })
      yield put({ type: GET_USER_REPORT_FOR_MODULE_SUCCESS, payload: res.data.createOrUpdateFlagreport })
    }
  } catch (error) {
    yield put({ type: ADD_FLAG_REPORT_FAILURE, error })
  }
  yield put({ type: CLEAR_FLAG_REPORT })
}

function* searchIndexData(action) {
  try {
    const res = yield searchIndexApi(action.payload)
    if (res.errors && res.errors.length) {
      yield put({ type: INDEX_SEARCH_FAILURE, res })
      yield put({ type: ERROR_MESSAGE_SHOW, payload: res.errors })
    } else if (res && res.data) {
      yield put({ type: INDEX_SEARCH_SUCCESS, payload: res.data.dashboardSearch, searchType: action.payload.searchType })
    }
  } catch (error) {
    yield put({ type: INDEX_SEARCH_FAILURE, error })
  }
}

function* getRentExperienceSaga() {
  yield takeLatest(GET_EXPERIENCES, getRentExperienceData)
}

function* sendConfirmationMailSaga() {
  yield takeLatest(SEND_CONFIRM_MAIL_LINK, sendConfirmationMail)
}

function* getMinimumPriceBoatsSaga() {
  yield takeLatest(GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS, getMinimumPriceBoats)
}

function* getModuleWiseBannerSaga() {
  yield takeLatest(GET_MODULE_WISE_BANNERS, getModuleWiseBannerData)
}

function* modulePageVisitSaga() {
  yield takeLatest(MODULE_PAGE_VISIT, modulePageVisit)
}

function* likeSaga() {
  yield takeLatest(ADD_LIKE, like)
}

function* currencyRatesSaga() {
  yield takeLatest(GET_CURRENCY_RATES, currencyRates)
}

function* searchNotifySaga() {
  yield takeLatest(NOTIFY_ME, searchNotify)
}

function* addWishlistSaga() {
  yield takeLatest(CREATE_WISHLIST, addWishlist)
}

function* deleteWishlistSaga() {
  yield takeLatest(REMOVE_WISHLIST, deleteWishlist)
}

function* getWishlistsSaga() {
  yield takeLatest(GET_WISHLISTS, getWishlists)
}

function* convertLikesSaga() {
  yield takeLatest(CONVERT_LIKES_FROM_SESSION_TO_USER, convertLikes)
}

function* convertWishlistsSaga() {
  yield takeLatest(CONVERT_WISHLISTS_FROM_SESSION_TO_USER, convertWishlists)
}

function* globalMainSearchSaga() {
  yield takeLatest(GLOBAL_SEARCH, globalMainSearch)
}

function* getCountryDetailsSaga() {
  yield takeLatest(GET_COUNTRY_DETAILS, getCountryDetails)
}

function* getCountryDetails(action) {
  try {
    const res = yield getCountryDetailsApi(action.payload)
    yield put({ type: GET_COUNTRY_DETAILS_SUCCESS, payload: res.data.getCountryDetail })
  } catch (error) {
    yield put({ type: GET_COUNTRY_DETAILS_FAILURE, error })
  }
}

function getCountryDetailsApi(input) {
  return graphqlClient
    .query({
      query: getCountryDetailsSchema,
      variables: {countryName: input},
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(error => error)
}

function* createFlagReportSaga() {
  yield takeLatest(ADD_FLAG_REPORT, createFlagReport)
}

function* searchIndexSaga() {
  yield takeLatest(INDEX_SEARCH, searchIndexData)
}

function* getModuleWiseBannerData(action) {
  try {
    const res = yield getModuleWiseBannerApi(action.payload)
    yield put({
      type: GET_MODULE_WISE_BANNERS_SUCCESS,
      payload: res,
      fieldName: action.payload.fieldName,
      ads: `${action.payload.fieldName}Ads`,
    })
  } catch (error) {
    yield put({
      type: GET_MODULE_WISE_BANNERS_FAILURE,
      error,
      fieldName: action.payload.fieldName,
    })
  }
}
function getModuleWiseBannerApi(input) {
  //   const isBanner = input.hasOwnProperty("isBanner");
  return graphqlClient
    .query({
      query: getBannerByModuleQuery,
      variables: { input: { type: input.type, isBanner: true } },
      fieldName: input.fieldName,
    })
    .then(res => res)
    .catch(err => {
      throw err
    })
}

function* getDashBoardCountSaga() {
  yield takeLatest(GET_DASHBOARD_COUNT, getDashBoardCountData)
}
function* getDashBoardCountData(action) {
  try {
    const res = yield getDashBoardCountApi(action.payload)
    yield put({
      type: GET_DASHBOARD_COUNT_SUCCESS,
      payload: res.data.getUserDashBoardCount,
    })
  } catch (error) {
    yield put({ type: GET_DASHBOARD_COUNT_FAILURE, error })
  }
}
function getDashBoardCountApi(input) {
  return graphqlClient
    .query({
      query: getUserDashBoardCountQuery,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => {
      throw err
    })
}

function* getAllPaymentTypesSaga() {
  yield takeLatest(GET_ALL_PAYMENT_METHODS, getAllPaymentTypesData)
}

function* getAllPaymentTypesData(action) {
  try {
    const res = yield getAllPaymentTypesApi(action.payload)
    yield put({ type: GET_ALL_PAYMENT_METHODS_SUCCESS, payload: res.data.getAllPaymentMethodTypes })
  } catch (e) {
    yield put({ type: GET_ALL_PAYMENT_METHODS_FAILURE, e })
  }
}

function getAllPaymentTypesApi() {
  return query(getAllPaymentMethodTypes, {})
}

export default function* dashboardSaga() {
  yield all([
    sendConfirmationMailSaga(),
    getMinimumPriceBoatsSaga(),
    getModuleWiseBannerSaga(),
    getDashBoardCountSaga(),
    getRentExperienceSaga(),
    modulePageVisitSaga(),
    currencyRatesSaga(),
    searchNotifySaga(),
    likeSaga(),
    addWishlistSaga(),
    deleteWishlistSaga(),
    getWishlistsSaga(),
    convertLikesSaga(),
    convertWishlistsSaga(),
    globalMainSearchSaga(),
    createFlagReportSaga(),
    getAllPaymentTypesSaga(),
    searchIndexSaga(),
    getCountryDetailsSaga(),
  ])
}
