import {
  BOAT_RENT_BOOKING,
  BOAT_RENT_BOOKING_SUCCESS,
  BOAT_RENT_BOOKING_FAILURE,
  GET_USER_BOOKINGS,
  GET_USER_BOOKINGS_SUCCESS,
  GET_USER_BOOKINGS_FAILURE,
  CLEAR_BOOKING_FLAG,
} from '../actionTypes'

const InitialState = {
  bookSuccess: false,
  bookError: false,
  getSuccess: false,
  getError: false,
  booking: {},
  bookings: [],
}

export const boatRentBookingReducer = (state = InitialState, action) => {
  switch (action.type) {
    //create booking
    case BOAT_RENT_BOOKING:
      return {
        ...state,
        bookSuccess: false,
        bookError: false,
      }

    case BOAT_RENT_BOOKING_SUCCESS:
      return {
        ...state,
        bookSuccess: true,
        bookError: false,
      }
    case BOAT_RENT_BOOKING_FAILURE:
      return {
        ...state,
        bookSuccess: false,
        bookError: true,
      }

    //get all booking
    case GET_USER_BOOKINGS:
      return {
        ...state,
        getSuccess: false,
        getError: false,
        isLoading: true,
      }
    case GET_USER_BOOKINGS_SUCCESS:
      return {
        ...state,
        getSuccess: true,
        getError: false,
        bookings: action.payload.getUserRentBoatBooking,
        bookingsTotal: action.payload.getUserRentBoatBooking.total,
        isLoading: false,
      }
    case GET_USER_BOOKINGS_FAILURE:
      return {
        ...state,
        getSuccess: false,
        getError: true,
        isLoading: false,
      }

    case CLEAR_BOOKING_FLAG:
      return {
        ...state,
        getSuccess: false,
        getError: false,
        bookSuccess: false,
        bookError: false,
      }
    default:
      return state
  }
}
