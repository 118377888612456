import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { InputBase } from '@material-ui/core'
import { IoIosSearch } from 'react-icons/io'
import cn from 'classnames'
import { pagination } from '../../util/enums/enums'
import { getRecentSearch, globalSearch } from '../../redux/actions'
import Tooltip from '../../containers/dashboard/tooltip'
import SearchDropdown from './searchDropdown'
import styles from './global-search.module.scss'

export default function GlobalSearch({ inPopup = false }) {
  const history = useHistory()

  const dispatch = useDispatch()

  const [query, setQuery] = useState('')
  const [queryError, setQueryError] = useState('')
  const [queryChanged, setQueryChanged] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const openSearchedResults = useCallback(() => {
    if (history.location.pathname == '/search') {
      setDropdownOpen(false)
      return false
    }

    query && query.length >= 3 && setDropdownOpen(true)
  }, [query])

  useEffect(() => {
    if (history.location.pathname == '/search') {
      setDropdownOpen(false)
    }

    const timer = setTimeout(() => {
      const payload = {
        query,
        page: pagination.PAGE_COUNT,
        limit: pagination.HEADER_SEARCH_BOX_LIMIT,
      }

      setQueryError('')

      if (query && query.length >= 3) {
        setDropdownOpen(true)
        dispatch(globalSearch(payload))
        dispatch(getRecentSearch())
      } else if (query.length === 0) {
        setDropdownOpen(false)
      }

      if (history.location.pathname == '/search' && !queryChanged) {
        let arr = history.location.search.split('=')
        setQuery(decodeURIComponent(arr[arr.length - 1]))
        setDropdownOpen(false)
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [query, globalSearch, getRecentSearch])

  return (
    <div className={cn('h-100', inPopup && styles.globalSearch)}>
      <OutsideClickHandler onOutsideClick={() => setDropdownOpen(false)}>
        <div className="width-search h-100">
          <div className="main-home-search h-100">
            <span className="pl-10" style={{ color: '#777' }}>
              <IoIosSearch style={{ width: 16, height: 16 }} />
            </span>
            <InputBase
              placeholder="Search anything"
              inputProps={{ 'aria-label': 'search google maps' }}
              className="search-input search-mobile"
              value={query}
              onChange={e => {
                setQuery(e.target.value)
                setQueryChanged(true)
              }}
              onClick={openSearchedResults}
            />
            {/* <div className="d-flex">
              <img
                src={require('../../assets/images/AdamSea-Gray.png')}
                alt="AdamSea Logo Gray"
                style={{ height: 14, maxWidth: 'max-content', marginRight: 10 }}
              />
            </div> */}
          </div>
          <span className="error-message error-search-message position-absolute">{!dropdownOpen && queryError}</span>
        </div>

        {!(history.location.pathname == '/search') && dropdownOpen && (
          <SearchDropdown query={query} setRecentQuery={query => setQuery(query)} history={history} />
        )}
      </OutsideClickHandler>

      <div className="header-not-0">
        <Tooltip
          key={Math.random()}
          position="bottom"
          visible={history.location.state?.showFirstTimeGuide === 7}
          content={
            <div id="showFirstTimeGuide-content-7" className="dashboard-tooltip-guide">
              <div>Search for anything using keywords: Users, Trips, Boats, Services, Articles, Marine, and more.</div>
              <div className="dashboard-tooltip-guide--btns-wrp">
                <button
                  className="btn dashboard-tooltip-guide--btn"
                  onClick={() => {
                    localStorage.setItem('showFirstTimeGuide', 8)
                    history.replace({ ...history.location, state: { showFirstTimeGuide: 8 } })
                  }}
                >
                  Got it
                </button>
              </div>
            </div>
          }
        >
          <div />
        </Tooltip>
      </div>
    </div>
  )
}
