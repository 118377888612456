import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getExploreMarina } from '../../redux/actions/marinaAndStorageAction'
import { getMarinaType } from '../../helpers/marinaHelper'
import BoatListingsWithMap from '../../components/gridComponents/BoatListingsWithMap'

class MarinaStorageView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      alias: props.location && props.location.state && props.location.state.alias,
    }
  }

  getType = () => {
    const { alias } = this.state
    const { typeWiseLookup } = this.props
    const lookUp = typeWiseLookup && typeWiseLookup.length && typeWiseLookup.find(item => item.alias === alias)
    return getMarinaType(lookUp)
  }

  render() {
    const { getExploreMarina, exploreMarina, isPageLoader } = this.props

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        isMarinaStorage
        boats={exploreMarina}
        action={getExploreMarina}
        value={{ typeId: this.getType() }}
      />
    )
  }
}

const mapStateToProps = state => ({
  exploreMarina: state.marinaAndStorageReducer && state.marinaAndStorageReducer.exploreMarina,
  isPageLoader: state.marinaAndStorageReducer && state.marinaAndStorageReducer.isPageLoader,
  totalExploreMarina: state.marinaAndStorageReducer && state.marinaAndStorageReducer.totalExploreMarina,
  typeWiseLookup: state.marinaAndStorageReducer && state.marinaAndStorageReducer.marinaTypeWiseLookUps,
})

const mapDispatchToProps = dispatch => ({
  getExploreMarina: data => dispatch(getExploreMarina(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarinaStorageView)
