import { put, takeLatest, all } from 'redux-saga/effects'
import {
  CREATE_BOAT_SHOW,
  CREATE_BOAT_SHOW_SUCCESS,
  CREATE_BOAT_SHOW_FAILURE,
  GET_USER_BOAT_SHOWS,
  GET_USER_BOAT_SHOWS_FAILURE,
  GET_USER_BOAT_SHOWS_SUCCESS,
  GET_ALL_BOAT_SHOW,
  GET_ALL_BOAT_SHOW_SUCCESS,
  GET_ALL_BOAT_SHOW_FAILURE,
  UPDATE_BOAT_SHOW,
  UPDATE_BOAT_SHOW_SUCCESS,
  UPDATE_BOAT_SHOW_FAILURE,
  GET_SINGLE_BOAT_SHOW,
  GET_SINGLE_BOAT_SHOW_FAILURE,
  GET_SINGLE_BOAT_SHOW_SUCCESS,
  SEARCH_BOAT_SHOW,
  SEARCH_BOAT_SHOW_SUCCESS,
  SEARCH_BOAT_SHOW_FAILURE,
  DELETE_BOAT_SHOW,
  DELETE_BOAT_SHOW_SUCCESS,
  DELETE_BOAT_SHOW_FAILURE,
  CLEAR_SEARCH_BOAT_FLAG,
  CLEAR_SEARCH_BOAT_SHOW_FLAG,
  ERROR_MESSAGE_SHOW,
  NOTIFICATION_MESSAGE,
  TOGGLE_BOAT_SHOW_STATUS,
  TOGGLE_BOAT_SHOW_STATUS_SUCCESS,
  TOGGLE_BOAT_SHOW_STATUS_FAILURE,
} from '../actionTypes'
import { graphqlClient } from '../../helpers/graphqlClient'
import {
  createBoatShow,
  getUserBoatShows,
  getAllBoatShows,
  updateBoatShow,
  getBoatShowById,
  deleteBoatShow,
  searchBoatShow,
  changeStatusBoatShow,
} from '../../graphql/boatShowSchema'
import { notificationEnum } from '../../util/enums/notificationEnum'
import { mutation } from './apiHelper'

//create boat show

function createBoatShowApi(input) {
  return graphqlClient
    .mutate({
      mutation: createBoatShow,
      variables: {
        input: input,
      },
    })
    .then(res => {
      return res
    })
    .catch(e => {
      throw e.networkError.result.errors
    })
}

function* createBoatShowData(action) {
  try {
    const data = yield createBoatShowApi(action.payload)
    if (data.errors && data.errors.length) {
      yield put({ type: ERROR_MESSAGE_SHOW, payload: data.errors })
      yield put({ type: CREATE_BOAT_SHOW_FAILURE, e: data.errors })
    } else {
      yield put({ type: CREATE_BOAT_SHOW_SUCCESS, payload: data })
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.BOAT_SHOW_CREATE })
    }
  } catch (e) {
    yield put({ type: CREATE_BOAT_SHOW_FAILURE, e })
  }
}

function* createBoatShowSaga() {
  yield takeLatest(CREATE_BOAT_SHOW, createBoatShowData)
}

// get user boat show

function getUserBoatShowsApi(input) {
  return graphqlClient
    .query({
      query: getUserBoatShows,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(e => {
      throw e
    })
}

function* getUserBoatShowsData(action) {
  try {
    const data = yield getUserBoatShowsApi(action.payload)
    yield put({ type: GET_USER_BOAT_SHOWS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_USER_BOAT_SHOWS_FAILURE, e })
  }
}

function* getUserBoatShowSaga() {
  yield takeLatest(GET_USER_BOAT_SHOWS, getUserBoatShowsData)
}

// SEARCH BOAT SHOW

function searchBoatShowApi(data) {
  return graphqlClient
    .query({
      query: searchBoatShow,
      variables: data,
      fetchPolicy: 'no-cache',
    })
    .then(res => res)
    .catch(err => err)
}

function* searchBoatShowData(action) {
  try {
    const res = yield searchBoatShowApi(action.payload)
    yield put({
      type: SEARCH_BOAT_SHOW_SUCCESS,
      payload: res.data.searchBoatShow,
    })
    yield put({ type: CLEAR_SEARCH_BOAT_SHOW_FLAG })
  } catch (error) {
    yield put({ type: SEARCH_BOAT_SHOW_FAILURE, error })
  }
}

function* searchBoatShowSaga() {
  yield takeLatest(SEARCH_BOAT_SHOW, searchBoatShowData)
}

// get all boat show

function getAllBoatShowApi(input) {
  return graphqlClient
    .query({
      query: getAllBoatShows,
      variables: input,
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(e => {
      throw e
    })
}

function* getAllBoatShowData(action) {
  try {
    const data = yield getAllBoatShowApi(action.payload)
    yield put({ type: GET_ALL_BOAT_SHOW_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_ALL_BOAT_SHOW_FAILURE, e })
  }
}

function* getAllBoatShowsSaga() {
  yield takeLatest(GET_ALL_BOAT_SHOW, getAllBoatShowData)
}

// update boat show

function updateBoatShowApi(input) {
  const { user, ...newInput } = input
  return graphqlClient
    .mutate({
      mutation: updateBoatShow,
      variables: {
        input: newInput,
      },
    })
    .then(res => {
      return res
    })
    .catch(e => {
      throw e.networkError.result.errors
    })
}

function* updateBoatShowData(action) {
  try {
    const data = yield updateBoatShowApi(action.payload)
    if (data.errors && data.errors.length) {
      yield put({ type: ERROR_MESSAGE_SHOW, payload: data.errors })
      yield put({ type: UPDATE_BOAT_SHOW_FAILURE, e: data.errors })
    } else {
      yield put({ type: UPDATE_BOAT_SHOW_SUCCESS, payload: data })
      yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.BOAT_SHOW_UPDATE })
    }
  } catch (e) {
    yield put({ type: UPDATE_BOAT_SHOW_FAILURE, e })
  }
}

function* updateBoatShowSaga() {
  yield takeLatest(UPDATE_BOAT_SHOW, updateBoatShowData)
}

//get boatshow by id

function getBoatShowByIdApi(input) {
  return graphqlClient
    .query({
      query: getBoatShowById,
      variables: {
        id: input,
      },
      fetchPolicy: 'no-cache',
    })
    .then(res => {
      return res
    })
    .catch(e => {
      throw e
    })
}

function* getBoatShowByIdData(action) {
  try {
    const data = yield getBoatShowByIdApi(action.payload)
    yield put({ type: GET_SINGLE_BOAT_SHOW_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_SINGLE_BOAT_SHOW_FAILURE, e })
  }
}

function* getBoatShowByIdSaga() {
  yield takeLatest(GET_SINGLE_BOAT_SHOW, getBoatShowByIdData)
}

//delete boat show
function deleteBoatShowAPi(input) {
  return graphqlClient
    .mutate({
      mutation: deleteBoatShow,
      variables: {
        id: input,
      },
    })
    .then(res => {
      return res
    })
    .catch(e => {
      throw e
    })
}

function* deleteBoatShowData(action) {
  try {
    const res = yield deleteBoatShowAPi(action.payload)
    yield put({ type: DELETE_BOAT_SHOW_SUCCESS, payload: res.data.deleteBoatShow })
    yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.BOAT_SHOW_DELETE })
  } catch (e) {
    yield put({ type: DELETE_BOAT_SHOW_FAILURE, e })
  }
}

function* deleteBoatShowSaga() {
  yield takeLatest(DELETE_BOAT_SHOW, deleteBoatShowData)
}

// toggle boat show status
function* toggleBoatShowStatusApi(action) {
  try {
    const res = yield mutation(changeStatusBoatShow, action.payload)
    yield put({ type: TOGGLE_BOAT_SHOW_STATUS_SUCCESS, payload: res.data.changeStatusBoatShow })
    yield put({ type: NOTIFICATION_MESSAGE, payload: notificationEnum.BOAT_SHOW_STATUS_CHANGE })
  } catch (e) {
    yield put({ type: TOGGLE_BOAT_SHOW_STATUS_FAILURE, e })
  }
}

function* toggleBoatShowStatusSaga() {
  yield takeLatest(TOGGLE_BOAT_SHOW_STATUS, toggleBoatShowStatusApi)
}

export default function* boatShowSaga() {
  yield all([
    createBoatShowSaga(),
    getUserBoatShowSaga(),
    getAllBoatShowsSaga(),
    updateBoatShowSaga(),
    getBoatShowByIdSaga(),
    deleteBoatShowSaga(),
    searchBoatShowSaga(),
    toggleBoatShowStatusSaga(),
  ])
}
