import { put, takeLatest, all } from 'redux-saga/effects'

import {
  GET_USER_REPORT_FOR_MODULE,
  GET_USER_REPORT_FOR_MODULE_SUCCESS,
  GET_USER_REPORT_FOR_MODULE_FAILURE,
} from '../actionTypes'
import { query } from './apiHelper'
import { getUserFlagReportForModule } from '../../graphql/reportSchema'

function getUserFlagReportForModuleApi(data) {
  return query(getUserFlagReportForModule, data)
}

function* getUserFlagReportForModuleData(action) {
  try {
    const res = yield getUserFlagReportForModuleApi(action.payload)
    yield put({ type: GET_USER_REPORT_FOR_MODULE_SUCCESS, payload: res.data.singleFlagReport })
  } catch (e) {
    yield put({ type: GET_USER_REPORT_FOR_MODULE_FAILURE, e })
  }
}

function* getUserFlagReportForModuleSaga() {
  yield takeLatest(GET_USER_REPORT_FOR_MODULE, getUserFlagReportForModuleData)
}

export default function* reportSaga() {
  yield all([getUserFlagReportForModuleSaga()])
}
