import React from 'react'
import { viewBoatHandler } from '../../../helpers/boatHelper'
import { AuctionRoomStyle } from '../../styleComponent/styleComponent'
import { Countdown } from '../countdown'
import { Menu, Grid, MenuItem } from '@material-ui/core'
import { auctionCarouselOptions } from '../../../util/enums/enums'
import { getConvertedPrice } from '../../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../../CurrencyContext'
import { nameFormatter } from '../../../helpers/string'
import { checkDateIsAfterCurrent } from '../../../helpers/dateTimeHelper'
import { Badge } from 'react-bootstrap'

export const AuctionDetail = React.memo(({ value, history, height, width }) => {
  const { boat, endTime, startTime, startPrice, auctionCurrentBid, isOpen, status } = value

  const {
    images,
    address: [{ country }],
    price,
    yearBuilt,
    boatType,
    lengthInFt,
    seller,
  } = boat

  return (
    <div className="boat-box" onClick={() => viewBoatHandler(boat)}>
      <div className="boat-box-shadow cursor-pointer">
        <div className="boat-auction-img-box">
          <AuctionRoomStyle
            img={boat && images && images.length && encodeURI(images[0]?.url)}
            bgHeight={height}
            bgWidth={width}
            className="boat--auction--img--box"
          >
            <Badge className="auction--coming--soon" variant="light">
              {status}
            </Badge>
            {/* {checkDateIsAfterCurrent(startTime) && <Badge className="auction--coming--soon" variant="light">COMING SOON</Badge>} */}
            <Countdown endTime={checkDateIsAfterCurrent(startTime) ? startTime : endTime} />
            <CurrencyContextConsumer>
              {({ currentCurrency }) => (
                <div className="auction-boat-price">{boat && getConvertedPrice(price, currentCurrency)}</div>
              )}
            </CurrencyContextConsumer>
          </AuctionRoomStyle>
        </div>

        <div className="boat-info">
          <div className="boat-info-inner border-bottom">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex align-items-center flex-wrap">
                <h5 className="mt-0  mb-0 boat-country-auction justify-content-end d-flex">{country}</h5>
                <h4 className="boat-title-auction mt-0 mb-0">
                  {boat && yearBuilt} {boat && boatType && boatType.name} <span className='bullet' /> {boat && lengthInFt} {'ft'}
                </h4>
              </div>
              <div className="justify-content-end d-flex dFlex-1 flex-column">
                <h4 className="boat-title-auction mt-0 mb-0 justify-content-end d-flex boat-title-auction-bold">
                  {boat?.address?.length ? boat.address[0].city : ''}
                </h4>
              </div>
            </div>

            <Grid container>
              <Grid item sm={12}>
                <h4 className="margin-0 boat-owner-auction">{nameFormatter([seller?.firstName, seller?.lastName])}</h4>
              </Grid>
            </Grid>
          </div>

          <Grid container className="bid-pricing">
            <CurrencyContextConsumer>
              {({ currentCurrency }) => (
                <>
                  <Grid item sm={6} className="border-right starting-pricing">
                    <h5 className="mt-0 mb-0 bid-price-auction">Starting Price</h5>
                    <h5 className="mt-0 auction-font-center mb-0 boat-price auction-price-font">
                      {getConvertedPrice(startPrice, currentCurrency)}
                    </h5>
                  </Grid>

                  <Grid item sm={6} className="pl-50 122 current-pricing">
                    <h5 className="mt-0 mb-0 bid-price-auction">Current BID</h5>
                    <h5 className="mt-0 mb-0 boat-price auction-price-font">
                      {getConvertedPrice(auctionCurrentBid, currentCurrency)}
                    </h5>
                  </Grid>
                </>
              )}
            </CurrencyContextConsumer>
          </Grid>
        </div>

        <Menu id="long-menu" keepMounted open={isOpen} onClose={() => this.setState({ isTrue: false })}>
          {auctionCarouselOptions &&
            auctionCarouselOptions.map(option => (
              <MenuItem key={option} onClick={() => this.setState({ isTrue: false })} selected={option === 'Pyxis'}>
                {option}
              </MenuItem>
            ))}
        </Menu>
      </div>
    </div>
  )
})
