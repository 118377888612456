import { ON_MAP_ITEM_HOVER } from '../actionTypes'

const InitialState = {
  highlightBoatId: null,
}

export const mapReducer = (state = InitialState, action) => {
  switch (action.type) {
    case ON_MAP_ITEM_HOVER:
      return {
        ...state,
        highlightBoatId: action.payload,
      }

    default:
      return state
  }
}
