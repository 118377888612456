import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ErrorIcon from '@material-ui/icons/Error'

import { ButtonComponent } from '../../../components/form/Button'
import { getSalesEngineBySeller, getSingleSalesEngine } from '../../../redux/actions/salesEngineAction'
import { TableCard, Field } from '../../../components'

import '../../../styles/manageDashboardTableResponsive.scss'
import { CommonTooltip } from '../../../components/CommonTooltip'
import { formattedDate } from '../../../helpers/dateTimeHelper'
import { pagination, userRoles } from '../../../util/enums/enums'
import { dashboardIndex, getPagesValue } from '../../../helpers/jsxHelper'
import { searchInDashboard, clearSearchDashboard } from '../../../redux/actions'
import { clear_getStaticPages, getStaticPages } from '../../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'

class SellerSalesEngine extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [
        {
          Header: 'Boat Type',
          accessor: 'boatType.name',
        },
        {
          Header: 'Model Name/No',
          accessor: 'boatName',
        },
        {
          Header: 'Ad ID',
          accessor: 'adId',
        },
        {
          Header: 'View Inquires',
          accessor: 'id',
          headerClassName: 'sales--engine--view',
          Cell: records => {
            const isBellIconVisible = records.original?.isViewedBySeller?.length
              ? records.original?.isViewedBySeller?.includes(false)
              : false

            return (
              <div className="d-flex flex-row justify-content-around seller-action-button-container">
                <ButtonComponent
                  type="button"
                  variant="fill"
                  onClick={() => props.history.push(`/boat-sales-engine/${records.original.id}`)}
                  className="sales-engine--view"
                >
                  View
                </ButtonComponent>
                <div className={`${!isBellIconVisible && 'vw-2'} request--new--btn--div`}>
                  <div className="new--request--icon">
                    <img
                      className={`${!isBellIconVisible && 'd-none'}`}
                      src={require('../../../assets/images/salesEngine/new1.svg')}
                      alt="Bell icon"
                    />
                  </div>
                </div>
              </div>
            )
          },
        },
      ],
      salesEngineIds: [],
    }
  }

  componentDidMount() {
    const { getSalesEngineBySeller, getStaticPages } = this.props
    getSalesEngineBySeller({
      page: getPagesValue(),
      limit: pagination.PAGE_RECORD_LIMIT,
    })

    const role = get(this.props.currentUser, 'role.aliasName')
    getStaticPages({
      slug:
        role === userRoles.BOAT_MANUFACTURER
          ? 'do-you-need-help-manufacturer-my-requests-page-1-936'
          : role === userRoles.BOAT_OWNER
          ? 'do-you-need-help-boat-owner-my-requests-page-1-342'
          : role === userRoles.BROKER_AND_DEALER
          ? 'do-you-need-help-broker-and-dealer-my-requests-page-1-150'
          : '',
    })
  }

  componentDidUpdate() {
    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }
  }

  handleCheck(e, salesEngineId) {
    this.setState(state => ({
      salesEngineIds: state.salesEngineIds.includes(salesEngineId)
        ? state.salesEngineIds?.filter(c => c !== salesEngineId)
        : [...state.salesEngineIds, salesEngineId],
    }))
  }

  render() {
    const { columns } = this.state
    const {
      salesEnginesBoats,
      isLoading,
      salesEnginesBoatsTotal,
      getSalesEngineBySeller,
      indexSearchData,
      totalSearchedData,
      isSearched,
      searchInDashboard,
      clearSearchDashboard,
    } = this.props

    return (
      <>
        {columns && (
          <div className="manage-dashboard-table manage--salesengine-table dashboard--table--main--section dashboard--index--table">
            <TableCard
              isSearch
              title="My Requests"
              columns={columns}
              loading={isLoading}
              isSalesEngine
              data={isSearched ? indexSearchData : salesEnginesBoats}
              className="manage--boat--table"
              noButton
              total={isSearched ? totalSearchedData : salesEnginesBoatsTotal}
              action={getSalesEngineBySeller}
              clearSearchDashboard={clearSearchDashboard}
              helpModalContent={get(this.props.staticPages, 'data[0].pageContent', '')}
            />
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser,
  salesEnginesBoats: state.salesEngineReducer.salesEnginesBoats,
  isLoading: state.salesEngineReducer.isLoading,
  salesEnginesBoatsTotal: state.salesEngineReducer.salesEnginesBoatsTotal,
  indexSearchData: state?.dashboardReducer?.indexSearchData,
  totalSearchedData: state?.dashboardReducer?.totalSearchedData,
  isSearched: state?.dashboardReducer?.isSearched,
  staticPages: state.pageInfoByTypeReducer.staticPages,
})

const mapDispatchToProps = dispatch => ({
  getSalesEngineBySeller: data => dispatch(getSalesEngineBySeller(data)),
  getSingleSalesEngine: data => dispatch(getSingleSalesEngine(data)),
  searchInDashboard: data => dispatch(searchInDashboard(data)),
  clearSearchDashboard: data => dispatch(clearSearchDashboard(data)),
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SellerSalesEngine)
