import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import HeroSearch from '../../containers/boatServices/hero-search'
import ShortSearch from './short-search'

export default function ServiceShortSearch() {
  const { pathname, search } = useLocation()

  const [fields, setFields] = useState([])

  useEffect(() => {
    if (pathname === '/search-boat-services') {
      const searchParams = new URLSearchParams(search)

      const country = searchParams.get('country')
      const city = searchParams.get('city')
      const address = searchParams.get('address')
      const serviceAlias = searchParams.get('serviceAlias')

      const fields = []

      fields.push(country || city ? address || [city, country].filter(Boolean).join(', ') : 'Anywhere')
      fields.push(serviceAlias || 'Any Service')

      setFields(fields)
    } else {
      setFields(['Anywhere', 'Any Service'])
    }
  }, [pathname, search])

  return <ShortSearch fields={fields} HeroSearch={HeroSearch} />
}
