import React, { Component } from 'react'
import { ShowBoatCard } from './showBoatCard'
import { KeyboardArrowRight } from '@material-ui/icons'
import { getRatioZoomResponsive, getHeightZoomResponsive } from '../../helpers/ratio'
import { dimension } from '../../util/enums/enums'

class ShowBoatCards extends Component {
  state = {
    width: dimension.boatShowGrid.width,
    height: dimension.boatShowGrid.height,
    gridClassName: `grid--new--${dimension['boatShowGrid'].divide}`,
    margin: 15,
    defaultSlice: 5,
  }
  widthZoom = () => {
    const width = getRatioZoomResponsive(dimension, 'boatShowGrid', '.zoom-container')
    const height = getHeightZoomResponsive(dimension, 'boatShowGrid', '.zoom-container')

    this.setState({ width: width.width, gridClassName: width.className, margin: width.margin, height })
  }
  componentDidMount() {
    this.widthZoom()
    window.addEventListener('resize', this.widthZoom)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.widthZoom)
  }
  render() {
    const { showLength, showBoatCards, isSearch, showAllText, isShowAll, inProfile } = this.props
    const { gridClassName, width, height, margin, defaultSlice } = this.state

    const boatShows = showLength && showBoatCards?.length ? showBoatCards?.slice(0, showLength) : showBoatCards

    return (
      <div className="zoom-container adam-marina-top-space-bot-show bot-show-grid-view-layout">
        {boatShows?.length > 0 &&
          boatShows.map(value => {
            return (
              <ShowBoatCard
                key={value.id}
                value={value}
                isSearch={isSearch}
                inProfile={inProfile}
                gridClassName={gridClassName}
                width={width}
                height={height}
                margin={margin}
                sliceTo={defaultSlice}
              />
            )
          })}

        {isShowAll && showBoatCards && showBoatCards.length && showBoatCards.length > showLength ? (
          <div className="w-100">
            <h5 className="mt-0">
              <a href={`show-all-boat-show`} target="_blank" className="show-link mb-0 text-decoration-unset hover-text-only">
                See all {showBoatCards && showBoatCards.length} {showAllText}
                <KeyboardArrowRight />
              </a>
            </h5>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  }
}

export default ShowBoatCards

ShowBoatCard.defaultProps = {
  isSearch: false,
  isShowAll: false,
  inProfile: false,
}
