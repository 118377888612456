import { put, takeLatest, all } from 'redux-saga/effects'

import { graphqlClient } from '../../helpers/graphqlClient'
import {
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILURE,
  SHARE_DETAILS_WITH_USERS,
  SHARE_DETAILS_WITH_USERS_SUCCESS,
  SHARE_DETAILS_WITH_USERS_FAILURE,
  CLEAR_SHARE_FLAG,
  ERROR_MESSAGE_SHOW,
  ERROR_NOTIFICATION_MESSAGE,
} from '../actionTypes'
import { searchUsers, shareDetailViaMail } from '../../graphql/shareSchema'
import { query, mutation } from './apiHelper'
import { dynamicError } from '../../util/enums/notificationEnum'

function getAllUserListApi(input) {
  return query(searchUsers, input)
}

function shareDetailsToUsersApi(input) {
  return mutation(shareDetailViaMail, { input })
}

function* getAllUserListData(action) {
  try {
    const res = yield getAllUserListApi(action.payload)
    yield put({ type: GET_USER_LIST_SUCCESS, payload: res.data.searchUsers })
  } catch (error) {
    yield put({ type: GET_USER_LIST_FAILURE, error })
  }
}

function* shareDetailsToUsersData(action) {
  try {
    const res = yield shareDetailsToUsersApi(action.payload)

    if (res?.errors?.length) {
      yield put({ type: SHARE_DETAILS_WITH_USERS_FAILURE, payload: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    } else if (res?.data) {
      yield put({ type: SHARE_DETAILS_WITH_USERS_SUCCESS, payload: res })
    }
    yield put({ type: CLEAR_SHARE_FLAG })
  } catch (error) {
    yield put({ type: SHARE_DETAILS_WITH_USERS_FAILURE, error })
  }
}

function* getAllUserListSaga() {
  yield takeLatest(GET_USER_LIST, getAllUserListData)
}

function* shareDetailsToUsersSaga() {
  yield takeLatest(SHARE_DETAILS_WITH_USERS, shareDetailsToUsersData)
}

export default function* shareSaga() {
  yield all([getAllUserListSaga(), shareDetailsToUsersSaga()])
}
